import angular from "angular";
import dashboardProCtrl from "./dashboard-pro.controller";
import dashboardProRouteConfig from "./dashboard-pro.route";
import "angular-ui-router";
import "common/intercom/intercomModule";

export default angular
    .module("sbApp.dashboardProModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.intercom",
        "sbApp.directives",
    ])
    .config(dashboardProRouteConfig)
    .controller("DashboardProCtrl", dashboardProCtrl);
