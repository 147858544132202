import _ from "lodash";
import {
    ProjectSource,
    ProjectSourceEvent,
} from "./../../../domain/sb_project_source.class";

export default function (
    $sbProjectApi,
    $sbSourcesApi,
    $sbCurrentProject,
    $sbCalendarRepository
) {
    "ngInject";

    return {
        getCurrent: $sbCurrentProject.get,
        getProjectSource: getProjectSource,
        getCalendar: _getCalendar,
        getCurrentProjectId: $sbCurrentProject.pick.bind(this, "id"),
        refreshCurrent: _refreshCurrent,
    };

    function getProjectSource(projectId, key) {
        return $sbSourcesApi
            .getCollection(projectId)
            .then(({ sources }) => {
                return sources.map(({ id, type, data, events, usage }) => {
                    const projectSource = new ProjectSource(id, type, data);
                    projectSource.addEvents(
                        events.map(
                            ({ name, at, by }) =>
                                new ProjectSourceEvent(name, at, by)
                        )
                    );
                    projectSource.setProject($sbCurrentProject.get());
                    projectSource.setUsage(usage);
                    return projectSource;
                });
            })
            .then((sources) => {
                if (key) {
                    return _.find(sources, ["id", key]);
                }
                return sources.sort((sourceA, sourceB) => {
                    if (sourceA.usage.active === 0) {
                        return 1;
                    }
                    if (sourceB.usage.active === 0) {
                        return -1;
                    }
                    const nameA = _.get(sourceA, "data.fileName", "");
                    const nameB = _.get(sourceB, "data.fileName", "");
                    return nameA.localeCompare(nameB);
                });
            });
    }

    function _refreshCurrent() {
        return $sbProjectApi.refresh($sbCurrentProject.pick("id"));
    }

    function _getCalendar(projectId) {
        return $sbCalendarRepository.get(
            projectId || $sbCurrentProject.pick("id")
        );
    }
}
