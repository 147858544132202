import commercialHtml from "./commercial.html";

export default function ($stateProvider) {
    "ngInject";

    $stateProvider.state("sablono.project.commercial", {
        templateUrl: commercialHtml,
        controller: "CommercialCtrl",
        controllerAs: "commercial",
        url: "/commercial",
        gaPageTrack: "/commercial", // google analytics extension
        data: {
            title: "SECTION_COMMERCIAL_PAGE_TITLE",
            category: "REVIEW", // PLAN or WORK
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";

            intercomService.track(TRACKED_EVENTS.ENTERED_COMMERCIAL_PAGE);
        },
    });
}
