import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sb_filter_date.html";
import DateViewFilter from "common/services/oDataService/odata_date_view_filter.class";
export default angular
    .module("sbApp.sbFilterDate", [])
    .directive("sbFilterDate", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "filterDate",
            templateUrl: htmlTemplate,
            replace: true,
            //transclude: true,
            scope: {
                /**
                 * @typeof DateViewFilter
                 */
                viewFilter: "=",

                // inform parent
                onChange: "=",
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "filterDate.viewFilter.value",
                    function (newValue, oldValue) {
                        // support clear viewFilter from outside -> newValue is undefined
                        if (newValue === undefined && newValue !== oldValue) {
                            controller.activate();
                        }
                    }
                );
            },
            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],
            //compile : function(tElement, tAttrs) {},
            controller: function FilterDateCtrl() {
                var vm = this;

                vm.ANY_OPTION = "__ANY";
                vm.CUSTOM_OPTION = "__CUSTOM";

                vm.activate = activate;
                vm.handleChange = handleChange;
                vm.handleCustom = handleCustom;

                activate();

                ///////////
                //
                //  IMPL
                //
                ///////////

                function activate() {
                    var initialOption = vm.viewFilter.collectActiveOptions();
                    if (initialOption && initialOption.length > 0) {
                        initialOption = initialOption[0].name;
                    } else if (vm.viewFilter.isFilterInactive()) {
                        initialOption = vm.ANY_OPTION;
                    } else {
                        initialOption = vm.CUSTOM_OPTION;
                    }

                    vm.tmp = {
                        option: initialOption,
                        valueFrom: vm.viewFilter.valueFrom
                            ? new Date(vm.viewFilter.valueFrom)
                            : new Date(),
                        valueTo: vm.viewFilter.valueTo
                            ? new Date(vm.viewFilter.valueTo)
                            : new Date(),
                    };
                }

                function handleChange(value) {
                    if (value === vm.ANY_OPTION) {
                        vm.viewFilter.clear();
                    } else if (value === vm.CUSTOM_OPTION) {
                        vm.handleCustom(vm.tmp.valueFrom, vm.tmp.valueTo);
                    } else {
                        vm.viewFilter.setValue(value);
                    }

                    vm.onChange(vm.viewFilter.key, vm.viewFilter);
                }

                function handleCustom(from, to) {
                    vm.viewFilter.setValueFrom(
                        angular.isDate(from)
                            ? moment(from).startOf("day").toDate()
                            : undefined
                    );

                    vm.viewFilter.setValueTo(
                        angular.isDate(to)
                            ? moment(to).endOf("day").toDate()
                            : undefined
                    );

                    vm.onChange(vm.viewFilter.key, vm.viewFilter);
                }
            },
        };
    });
