import moment from "moment";
import _ from "lodash";

export default function sbStructureActivityDetailsPanelController(
    $filter,
    mdPanelRef,
    $sbLeanBoard,
    $sbLookAheadModification,
    $sbTracking,
    USER_INTERACTION,
    $document,
    $sbActivityDetailsPanelCommonService,
    $sbLastPlannedSelectionStore
) {
    "ngInject";
    var vm = this;

    // filters
    var _asStateIconFilter = $filter("asStateIcon");
    var _translate = $filter("translate");

    vm.onClose = () => $sbActivityDetailsPanelCommonService.onClose(mdPanelRef);
    vm.onStartSession = onStartSession;
    vm.onEditActivity = onEditActivity;
    vm.toggleIsDetailsShowing = toggleIsDetailsShowing;

    vm.hasSessionPermission =
        $sbActivityDetailsPanelCommonService.hasPermission();
    vm.isStartingSession = false;

    $init();

    function $init() {
        vm.isLoading = true;
        vm.panelModel = _toPanelViewModel(vm.activity);
    }

    function _toPanelViewModel(activity) {
        const dateConfigForActuals = {
            translationKeyIfNotSet: null,
        };

        return {
            id: activity.templateId + activity.laneRecord.location,
            state: activity.state,
            icon: _asStateIconFilter(activity.state.current),
            badges: {
                infos: activity.noteStatistic.info,
                claims: activity.noteStatistic.openClaims,
                obstructions: activity.noteStatistic.openObstructions,
            },
            texts: {
                name: activity.name,
                location: activity.laneRecord.location,
                assignedTeamName: activity.workTeam.getDisplayName(),
                confirmationTeamName: _mapConfirmationTeamName(activity),
                reviewTeamsNames: _mapReviewTeam(activity),
                startDate: _getDateRepresentation(activity.startDate),
                endDate: _getDateRepresentation(activity.endDate),
                startDateOrigin: activity.startDateOrigin,
                endDateOrigin: activity.endDateOrigin,
                baselineStartDate: _getDateRepresentation(
                    activity.schedule.baseline.start
                ),
                baselineDueDate: _getDateRepresentation(
                    activity.schedule.baseline.end
                ),
                lastCommittedStartDate: _getDateRepresentation(
                    activity.schedule.sessionLookAhead.start
                ),
                lastCommittedEndDate: _getDateRepresentation(
                    activity.schedule.sessionLookAhead.end
                ),
                forecastLastPlannedStartDate: _getDateRepresentation(
                    activity.schedule.forecast.start
                ),
                forecastLastPlannedEndDate: _getDateRepresentation(
                    activity.schedule.forecast.end
                ),
                lastPlannedChangeCount: activity.lastPlannedChangeCount,
                actualStart: _getDateRepresentation(
                    activity.schedule.actual.started,
                    dateConfigForActuals
                ),
                actualFinished: _getDateRepresentation(
                    activity.schedule.actual.finished,
                    dateConfigForActuals
                ),
                actualConfirmed: _getDateRepresentation(
                    activity.schedule.actual.confirmed,
                    dateConfigForActuals
                ),
                numberOfDeliverables: activity.numberOfDeliverables,
                numberOfForecastedActivities:
                    activity.numberOfForecastedActivities,
                requiredProductivity: roundToTwoDecimals(
                    activity.numberOfDeliverables /
                        activity.displayedDuration.value
                ),
            },
            durations: {
                plannedDuration: activity.schedule.baseline.duration,
                plannedDurationUnit:
                    "_" + activity.schedule.baseline.durationUnit,
                lastCommittedDuration: roundToTwoDecimals(
                    activity.schedule.sessionLookAhead.duration
                ),
                lastPlannedDuration:
                    activity.schedule.publishedLookAhead.duration,
                displayedDuration: activity.displayedDuration.value,
                displayedDurationUnit: "_" + activity.displayedDuration.unit,
            },
            dates: {
                startDate: activity.startDate,
                endDate: activity.endDate,
                baselineStartDate: activity.schedule.baseline.start,
                baselineDueDate: activity.schedule.baseline.end,
                lastCommittedStartDate:
                    activity.schedule.sessionLookAhead.start,
                lastCommittedEndDate: activity.schedule.sessionLookAhead.end,
                forecastLastPlannedStartDate: activity.schedule.forecast.start,
                forecastLastPlannedEndDate: activity.schedule.forecast.end,
            },
            timeline: {
                lastCheckedBy: _getLastCheckedByRepresentation(activity),
                lastCheckedOn: _getDateRepresentation(
                    activity.progressChangeTime,
                    {
                        translationKeyIfNotSet: "INFO_NO_INSPECTIONS",
                    }
                ),
            },
            plannedLabour: activity.plannedLabour,
            isBehind: activity.isBehind,
            isEditable: !activity.state.isCompleted(),
            isWorkInProgress: activity.state.isWorkInProgress(),
            hasActualDates:
                activity.schedule.actual.started ||
                activity.schedule.actual.finished ||
                activity.schedule.actual.confirmed,
            hasChangedInCurrentSession:
                activity.schedule.hasChangedInCurrentSession(),
        };
    }

    function _getDateRepresentation(
        dateAsMoment,
        { translationKeyIfNotSet = "_UNSET", format = "L" } = {}
    ) {
        if (dateAsMoment && vm.calendar && vm.calendar.TIMEZONE) {
            var displayableMoment = moment.tz(
                parseFloat(dateAsMoment.valueOf()),
                vm.calendar.tz()
            );
            return displayableMoment.isValid()
                ? displayableMoment.format(format)
                : _translate(translationKeyIfNotSet);
        }
        return _translate(translationKeyIfNotSet);
    }

    function _getLastCheckedByRepresentation(activity) {
        var lastCheckedBy = _.get(activity, "progressChangeAuthor.displayName");
        return lastCheckedBy || _translate("INFO_NO_INSPECTIONS");
    }

    function _mapConfirmationTeamName(activity) {
        if (!activity.confirmationTeam) {
            return "ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED";
        }

        const teamName = activity.confirmationTeam.getDisplayName();

        if (teamName === "_UNRESTRICTED_TEAM") {
            return "ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED";
        }

        return teamName;
    }

    function _mapReviewTeam(activity) {
        return (activity.reviewTeams || [])
            .map((responsibleTeam) => responsibleTeam.getDisplayName())
            .sort();
    }

    function roundToTwoDecimals(number) {
        // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
        return Math.round((number + Number.EPSILON) * 100) / 100;
    }

    function onEditActivity() {
        $sbTracking.leanBoard.activity.onMoveWBS();
        vm.onClose();

        let activity = $sbLastPlannedSelectionStore.makeStoreActivityFromDomain(
            vm.activity
        );
        activity = _.omit(activity, "actualStart");

        activity.templateId = vm.activity.templateId;

        vm.onDateChange(activity).finally(vm.closeSelectionMode);
    }

    function onStartSession() {
        vm.isStartingSession = true;
        vm.onCreateNewSession();
    }

    function toggleIsDetailsShowing() {
        vm.isDetailsShowing = !vm.isDetailsShowing;
        if (vm.isDetailsShowing) {
            $sbTracking.leanBoard.activity.onShowActivityDetails();
        } else {
            $sbTracking.leanBoard.activity.onHideActivityDetails();
        }

        $sbActivityDetailsPanelCommonService.repositionPanel(
            vm.panelModel.id,
            mdPanelRef
        );
    }
}
