import angular from "angular";
import htmlTemplate from "./sb_timezone_selector.html";
/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbTimezoneSelector", [])
    .directive("sbTimezoneSelector", function () {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            transclude: true,
            scope: {
                model: "=",
                parentForm: "=form",
                ngDisabled: "=",
            },
            controllerAs: "timezone",
            controller: function ($sbTimezone, $q) {
                var vm = this;
                var timezones = $sbTimezone.getZonesWithCity();

                vm.querySearch = querySearch;
                vm.getItemText = getItemText;

                /**
                 * getItemText - Transform the zone object in a human-readable string
                 *
                 * @param  {Object} zone - The zone object to be converted
                 * @return {String}      - The converted human-readable string
                 */
                function getItemText(zone) {
                    return zone.country + " - " + zone.city;
                }

                /**
                 * querySearch - Filters in the timezones the one searched
                 *
                 * @param  {String} searchText - The string to be searched
                 * @return {Promise}           - An always resolving promise that returns the filtered timezones
                 */
                function querySearch(searchText) {
                    if (searchText) {
                        var filteredTimezones = timezones.filter(
                            function (zone) {
                                var lwrText = searchText.toLowerCase();
                                return (
                                    zone.city.toLowerCase().indexOf(lwrText) !==
                                        -1 ||
                                    zone.country
                                        .toLowerCase()
                                        .indexOf(lwrText) !== -1 ||
                                    (
                                        zone.country.toLowerCase() +
                                        " - " +
                                        zone.city.toLowerCase()
                                    ).indexOf(lwrText) !== -1
                                );
                            }
                        );
                        return $q.resolve(filteredTimezones);
                    } else {
                        return $q.resolve(timezones);
                    }
                }
            },
        };
    });
