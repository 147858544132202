import htmlTemplate from "./freemium_landing.html";

export default function ($stateProvider, SABLONO_STATES) {
    "ngInject";

    $stateProvider.state(SABLONO_STATES.freemiumLanding, {
        templateUrl: htmlTemplate,
        url: "/freemium_landing",
        controllerAs: "$ctrl",
        controller: "FreemiumLandingCtrl",
        data: {
            title: "FREEMIUM",
            category: "REVIEW", // PLAN or WORK
        },
        gaPageTrack: "/freemium_landing", // google analytics extension
    });
}
