import angular from "angular";
import ViewSelectionProvider from "common/plainModels/ViewSelectionProvider";
import htmlTemplate from "./sb_checkbox_list.html";

export default function sbCheckboxList() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        replace: true,
        scope: {},
        bindToController: {
            // list of items to populate the list
            items: "=",
            // headers to display as fixed header of the table
            headers: "=",
            // output of selected items
            selectedItems: "=selected",
            // properties from the object we want to display
            propertiesToDisplay: "=properties",
            // if present, adds a separator in the position provided.
            // properties 'position' and 'icon' needed
            separator: "=",
            // type of list in case we want to style the selected items
            // for the meantime, 'delete' or 'select' (red or green)
            type: "=",
            // if present, adds another line to the tooltip with
            // additional information
            additionalTooltip: "=tooltips",
        },
        //link: function postLink(scope) {},
        controllerAs: "checkboxList",
        controller: function sbCheckboxListCtrl() {
            var vm = this;

            /////////////////////
            //
            //      SCOPE properties
            //
            /////////////////////

            vm.isSelectAllChecked = false;

            vm.toggleCheckbox = onToggleCheckbox;
            vm.isCheckboxSelected = isCheckboxSelected;
            vm.isCheckboxIndeterminate = isCheckboxIndeterminate;
            vm.areAllChecked = areAllChecked;
            vm.toggleAll = toggleAll;

            /**
             * Holds the selection state
             * @type {ViewSelectionProvider}
             */
            vm.selectionProvider = new ViewSelectionProvider();

            /////////////////////
            //
            //      IMPL
            //
            /////////////////////

            activate();

            function activate() {
                vm.selectionProvider.selections(vm.selectedItems);
            }

            function onToggleCheckbox(item, $event) {
                vm.selectionProvider.selectionChange(item, $event);
                vm.isSelectAllChecked = areAllChecked();
            }

            function isCheckboxSelected(item) {
                return vm.selectionProvider.isSelected(item);
            }

            function isCheckboxIndeterminate() {
                return (
                    vm.selectionProvider.hasActiveSelections() &&
                    !areAllChecked()
                );
            }

            function areAllChecked() {
                return (
                    vm.selectionProvider.selections().length === vm.items.length
                );
            }

            function toggleAll() {
                if (areAllChecked()) {
                    vm.selectedItems = vm.selectionProvider.clearSelection();
                    vm.isSelectAllChecked = false;
                } else {
                    vm.selectedItems = vm.selectionProvider
                        .selections(vm.items.slice(0))
                        .selections();
                    vm.isSelectAllChecked = true;
                }
            }
        },
    };
}
