import angular from "angular";
import htmlTemplate from "./sb_tree.html";
import _ from "lodash";
import "./simplifyTreeModel/simplifyTreeModel";
import "./sbTreeRow/sb_tree_row.directive";

/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 *
 * The tree model should look like this:
 * [
 *  {
 *    key: "value",
 *    anotherKey: "anotherValue",
 *    childs: [
 *        {
 *            key: "value",
 *            anotherKey: "anotherValue",
 *        }
 *    ]
 *  }
 * ]
 *
 * There are a couple of attributes we need in this directive
 * leftKey, rightKey: they determinate which key of the tree object will be displayed on the left / right side
 * childKey: you chose which key of the tree object contains child elements with the same structur
 *
 * Optional attributes are
 * childIndention: margin left for child nodes, default 10
 * childIndention: margin left for child nodes, default 10
 * minWidth: space between the left and the right key, default 150
 * showDepth: depth which is initially opened
 *
 *
 * @example <sb-tree left-key="code" right-key="name" child-key="substructure" tree="tree" show-depth="2" child-indention="10" min-width="300"></sb-tree>
 *
 *
 */
export default angular
    .module("sbApp.sbTree", ["sbApp.SimplifyTreeModel", "sbApp.sbTreeRow"])
    .directive("sbTree", function (SimplifyTreeModel) {
        return {
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            scope: {
                tree: "=",
                list: "=",
                rowTemplate: "=",
                onLeafClick: "=",
                showSelected: "=",
            },
            replace: true,
            controller: function ($scope, $element, $attrs) {
                $scope.childIndention = $attrs.childIndention || 40;
                $scope.minWidth = $attrs.minWidth || 150;

                var simplifiedTree = {};

                function simplifyTree() {
                    if (angular.isDefined($scope.tree)) {
                        var t = $scope.tree;
                        simplifiedTree = new SimplifyTreeModel(
                            $attrs.showDepth
                        );
                        simplifiedTree.simplifyTree(t, $attrs.childKey, 0);
                        $scope.simplifiedTree =
                            simplifiedTree.getSimplifiedTree();
                    } else if (angular.isDefined($scope.list)) {
                        simplifiedTree = new SimplifyTreeModel(
                            $attrs.showDepth
                        );
                        simplifiedTree.simplifyTreeList(
                            $scope.list,
                            $attrs.parentKey,
                            $attrs.elementKey
                        );
                        $scope.simplifiedTree =
                            simplifiedTree.getSimplifiedTree();
                    } else {
                        $scope.simplifiedTree = undefined;
                    }

                    $scope.simplifiedTreeUIModel =
                        configureSimplifiedTreeToUIModel($scope.simplifiedTree);
                }

                function configureSimplifiedTreeToUIModel(simplifiedTree) {
                    return _.cloneDeep(simplifiedTree).map((entry) => {
                        if (entry.children) {
                            entry.children = [];
                        }
                        return entry;
                    });
                }

                simplifyTree();

                $scope.$watch("tree", simplifyTree);
                $scope.$watch("list", simplifyTree);

                $scope.toggleState = function ($event, node) {
                    $event.stopPropagation();
                    if (node.level === 0 && $scope.onLeafClick) {
                        $scope.onLeafClick(node);
                    }

                    toggleState($scope.simplifiedTreeUIModel, node);
                };

                function toggleState(simplifiedTreeUIModel, node) {
                    node.open = !node.open;
                    const allBranchRelatedNodes = { [node.ext]: node };

                    for (let j = 0; j < simplifiedTreeUIModel.length; j++) {
                        const child = simplifiedTreeUIModel[j];
                        const parent = allBranchRelatedNodes[child.parentExt];

                        if (parent) {
                            allBranchRelatedNodes[child.ext] = child;

                            if (!node.open) {
                                child.show = false;
                            } else {
                                child.show = parent.open;
                            }
                        }
                    }
                }

                $scope.calcMinWidth = function (depth) {
                    return $scope.minWidth - $scope.calcMargin(depth);
                };

                $scope.calcMargin = function (depth) {
                    return $scope.childIndention * depth;
                };
            },
        };
    });
