import createjs from "createjs";

/**
 * Event that is dispatched when scale was changed
 * @param {number} scale - New Scale
 * @constructs ScaleChangedEndEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function ScaleChangedEndEvent(scale) {
    createjs.Event.call(this, "scaleChangedEndEvent", false, false);
    this.scale = scale;
}

ScaleChangedEndEvent.prototype = Object.create(createjs.Event.prototype);

export default ScaleChangedEndEvent;
