import _ from "lodash";

export default function UserSettingsStoreService($window) {
    "ngInject";
    var AREA_MANAGER_FILTER_SETTING = "AREA_MANAGER_FILTER_SETTING";

    return {
        saveAreaManagerFilter: saveAreaManagerFilter,
        getAreaManagerFilter: getAreaManagerFilter,
        clearAreaManagerFilter: clearAreaManagerFilter,
    };

    /**
     *
     * @param {Boolean} areaManagerFilterIsActive
     */
    function saveAreaManagerFilter(areaManagerFilterIsActive) {
        $window.localStorage.setItem(
            AREA_MANAGER_FILTER_SETTING,
            angular.toJson(areaManagerFilterIsActive)
        );
    }

    function clearAreaManagerFilter() {
        $window.localStorage.removeItem(AREA_MANAGER_FILTER_SETTING);
    }

    /**
     *
     * @returns {Boolean} areaManagerFilterIsActive
     */
    function getAreaManagerFilter() {
        var areaManagerFilterIsActive = $window.localStorage.getItem(
            AREA_MANAGER_FILTER_SETTING
        );

        if (_.isString(areaManagerFilterIsActive)) {
            return angular.fromJson(areaManagerFilterIsActive);
        } else {
            return true;
        }
    }
}
