import imagesOverlayTemplate from "./imagesOverlay/images_overlay.html";
import imagesOverlayController from "./imagesOverlay/images_overlay.controller";

export default function ($mdDialog) {
    "ngInject";
    function open($event, images, index) {
        $mdDialog.show({
            templateUrl: imagesOverlayTemplate,
            targetEvent: $event,
            locals: {
                images: images,
                currentIndex: index,
            },
            controller: imagesOverlayController,
            controllerAs: "overlay",
        });
    }

    return {
        open: open,
    };
}
