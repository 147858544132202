import leanboardCtrl from "./lean_board.controller";
import leanBoardTemplateHtml from "./lean_board.html";

export default function ($stateProvider, SABLONO_STATES) {
    "ngInject";
    $stateProvider.state(SABLONO_STATES.leanboard, {
        templateUrl: leanBoardTemplateHtml,
        controller: leanboardCtrl,
        controllerAs: "leanboard",
        url: "/leanboard?from&to&format&late&selectedTeam&zoomSize&columnTimespan&structureId&selectedProcessTemplate&showActualDates&viewBy&{page:int}",
        params: {
            page: {
                value: 1,
                dynamic: true, // avoids reload of controller on change
            },
        },
        gaPageTrack: "/leanboard", // google analytics extension
        data: {
            title: "SECTION_LEANBOARD_TITLE",
            category: "WORK",
            isSubBarHidden: false,
            searchEnabled: true,
            searchPlaceholder: "_FILTER_SEARCH_DELIVERABLE_PLACEHOLDER",
        },
    });
}
