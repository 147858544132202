import ODataFilterFactory from "./odata_filter_factory.class";
import moment from "moment";

export default function oDataFilterService() {
    return {
        odataUnscheduled: odataUnscheduled,

        odataHas: odataHas,

        odataDateToday: odataDateToday,
        odataDateTenAgo: odataDateTenAgo,
        odataDateLastMonth: odataDateLastMonth,
        odataDateNextMonth: odataDateNextMonth,
        odataDateLastWeek: odataDateLastWeek,
        odataDateNextWeek: odataDateNextWeek,

        odataRangeToday: odataRangeToday,
        odataRangeLastWeek: odataRangeLastWeek,
        odataRangeLastMonth: odataRangeLastMonth,
        odataRangeNextWeek: odataRangeNextWeek,
        odataRangeNextMonth: odataRangeNextMonth,

        createODataExprForInTimeRange: createODataExprForInTimeRange,
    };

    function odataDateTenAgo(key, formatter = (date) => date.valueOf() + "L") {
        const now = moment().endOf("minute");
        const tenDaysAgo = moment().startOf("day").subtract(10, "days");

        return {
            label: "_TEN_DAYS_AGO",
            name: "tenAgo", // URL
            value: {
                from: tenDaysAgo,
                to: now,
            },
            odata: function (filterFactory) {
                return filterFactory.gt(
                    key,
                    formatter(tenDaysAgo),
                    formatter(now)
                );
            },
        };
    }

    function odataDateLastMonth(
        key,
        formatter = (date) => date.valueOf() + "L"
    ) {
        const now = moment().endOf("minute");
        const lastMonth = moment().startOf("day").subtract(30, "days");

        return {
            label: "_DAYS_BETWEEN_TO_30_DAYS",
            name: "lastMonth", // URL
            value: {
                from: lastMonth,
                to: now,
            },
            odata: function (filterFactory) {
                return filterFactory.between(
                    key,
                    formatter(lastMonth),
                    formatter(now)
                );
            },
        };
    }

    function odataDateNextMonth(
        key,
        formatter = (date) => date.valueOf() + "L"
    ) {
        const todayMorning = moment().startOf("day");
        const nextMonth = moment().endOf("day").add(1, "months");
        return {
            label: "_DAYS_BETWEEN_AFTER_30_DAYS",
            name: "nextMonth", // URL
            value: {
                from: todayMorning,
                to: nextMonth,
            },
            odata: function (filterFactory) {
                return filterFactory.between(
                    key,
                    formatter(todayMorning),
                    formatter(nextMonth)
                );
            },
        };
    }

    function odataDateLastWeek(
        key,
        formatter = (date) => date.valueOf() + "L"
    ) {
        const now = moment().endOf("minute");
        const lastWeek = moment().startOf("day").subtract(7, "days");

        return {
            label: "_DAYS_BETWEEN_TO_7_DAYS",
            name: "lastWeek", // URL
            value: {
                from: lastWeek,
                to: now,
            },
            odata: function (filterFactory) {
                return filterFactory.between(
                    key,
                    formatter(lastWeek),
                    formatter(now)
                );
            },
        };
    }

    function odataDateToday(key, formatter = (date) => date.valueOf() + "L") {
        const todayMorning = moment().startOf("day");
        const todayEvening = moment().endOf("day");

        return {
            label: "_TODAY",
            name: "today", // URL
            value: {
                from: todayMorning,
                to: todayEvening,
            },
            odata: function (filterFactory) {
                return filterFactory.between(
                    key,
                    formatter(todayMorning),
                    formatter(todayEvening)
                );
            },
        };
    }

    function odataDateNextWeek(
        key,
        formatter = (date) => date.valueOf() + "L"
    ) {
        const todayMorning = moment().startOf("day");
        const after7Days = moment().add(7, "days").endOf("day");

        return {
            label: "_DAYS_BETWEEN_AFTER_7_DAYS",
            name: "nextWeek", // URL
            value: {
                from: todayMorning,
                to: after7Days,
            },
            odata: function (filterFactory) {
                return filterFactory.between(
                    key,
                    formatter(todayMorning),
                    formatter(after7Days)
                );
            },
        };
    }

    function odataRangeToday() {
        var today = _today();

        return {
            label: "_TODAY",
            name: "today", // URL
            odata: _odataHasInRange(today.morning, today.evening),
        };
    }

    function odataRangeLastWeek() {
        return _createRangeFor("_DAYS_BETWEEN_TO_7_DAYS", "lastWeek", -7);
    }

    function odataRangeLastMonth() {
        return _createRangeFor("_DAYS_BETWEEN_TO_30_DAYS", "lastMonth", -30);
    }

    function odataRangeNextWeek() {
        return _createRangeFor("_DAYS_BETWEEN_AFTER_7_DAYS", "nextWeek", 7);
    }

    function odataRangeNextMonth() {
        return _createRangeFor("_DAYS_BETWEEN_AFTER_30_DAYS", "nextMonth", 30);
    }

    function _createRangeFor(label, name, days) {
        var now = _now();
        var datesInLong = [now, now + days * 24 * 60 * 60 * 1000]; // time range

        // change direction of time
        //
        if (days < 0) {
            datesInLong = datesInLong.reverse();
        }

        return {
            label: label,
            name: name, // URL
            odata: _odataHasInRange(datesInLong[0], datesInLong[1]),
        };
    }

    function _odataHasInRange(start, end) {
        return function (filterFactory) {
            return createODataExprForInTimeRange(filterFactory, start, end);
        };
    }

    /**
     * Creates an OData expression for being  in a time range.
     *
     * (A) | --- time ----> | (B)
     *
     * !(END < A) AND !(START > B)
     *
     * @param {Integer} start - Positive Long representing start datetime.
     * @param {Integer} end - Positive Long representing end datetime.
     *
     * @returns {ODataFilterFactory} Chainable OData filter factory
     */
    function createODataExprForInTimeRange(filterFactory, start, end) {
        return filterFactory.block(
            new ODataFilterFactory()
                .not()
                .lt("CD", start + "L", "SD")
                .and()
                .not()
                .gt("SD", end + "L", "CD")
        );
    }

    function odataUnscheduled(filterFactory) {
        return filterFactory.block(
            new ODataFilterFactory().eq("CD", null).and().eq("SD", null)
        );
    }

    function odataHas(type) {
        return function (filterFactory) {
            return filterFactory.gt(type, 0);
        };
    }

    function _now() {
        // "now" must only change per minute to make the filter more stable.
        return Math.floor(new Date().getTime() / 60000) * 60000;
    }

    function _today() {
        var morning = new Date();
        morning.setHours(0);
        morning.setMinutes(0);
        morning.setSeconds(0);
        morning.setMilliseconds(0);
        morning = morning.getTime();

        var evening = new Date();
        evening.setHours(23);
        evening.setMinutes(59);
        evening.setSeconds(59);
        evening = evening.getTime();

        return {
            morning: morning,
            evening: evening,
        };
    }
}
