import _ from "lodash";
import ViewSelectionProvider from "common/plainModels/ViewSelectionProvider";

export default function ProcessViewerCtrl(
    SbTeam,
    $sbTeam,
    $scope,
    $state,
    $log,
    $sbCurrentProject,
    $sbStructure,
    $sbMembership,
    $sbDetailsOverlay,
    $sbFeatureDecisions,
    $rootScope,
    $timeout,
    $mdToast,
    $mdDialog,
    $sbProcessViewerProgress,
    $sbPermission,
    $sbProcessViewerLoader,
    $sbProcessViewerPaginationService,
    EVENTS,
    SbProcessTemplate,
    PROCESS_VIEWER_HIDE_FINISHED_ACTIVITES_KEY,
    SABLONO_STATES,
    $sbActivities,
    $sbErrorPresenter,
    $sbTracking,
    $window,
    Analytics,
    $sbBusyIndication
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    let structureMapper = new Map();
    let teamMap = new Map();
    let teamMapper = {
        get: (id) => teamMap.get(id) || SbTeam.createUnrestrictedTeam(),
    };

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.teams = [];
    vm.odataSourceSetting = {};
    vm.isLoading = true;
    vm.isEmptyProject = false;
    vm.teamOfCurrentUser = $sbMembership.currentTeam();
    vm.selectionProvider = new ViewSelectionProvider();
    vm.selectedHighlighting = "TEAM";

    // menu for area manager toggle switch
    //
    vm.filterMenu = {
        onToggleAreaManagerFilter: onToggleAreaManagerFilter,
        onViewModelSetUpComplete: loadFiltersAndUpdateLoader,
        lastCallbackEvent: undefined,
    };

    vm.selectionMode = {
        name: "SELECTION",
        onClick: function (activity, $event) {
            if (!vm.selectionProvider.hasActiveSelections()) {
                $sbTracking.detailedDependencies.assignment.started();
            }

            vm.selectionProvider.selectionChange(activity, $event);
        },
    };

    vm.predecessorAssignmentMode = {
        name: "PREDECESSORS_ASSIGNMENT",
        onClick: function (activity) {
            if (vm.selectionProvider.isSelected(activity)) {
                return _showCycleErrorToast();
            }
            return _assignAs(
                vm.selectionProvider.selections(),
                activity,
                "SUCCESSOR"
            );
        },
        getEdgesBy: function (selections, selectedActivity) {
            return selections.map(function (selection) {
                return _predecessorsMapper(selectedActivity, selection);
            });
        },
    };

    vm.successorAssignmentMode = {
        name: "SUCCESSORS_ASSIGNMENT",
        onClick: function (activity) {
            if (vm.selectionProvider.isSelected(activity)) {
                return _showCycleErrorToast();
            }
            return _assignAs(
                vm.selectionProvider.selections(),
                activity,
                "PREDECESSOR"
            );
        },
        getEdgesBy: function (selections, selectedActivity) {
            return selections.map(function (selection) {
                return _successorsMapper(selectedActivity, selection);
            });
        },
    };

    vm.mode = {
        active: vm.selectionMode,
        getModeByName: function (name) {
            return _.defaultTo(
                _.find(
                    [
                        vm.selectionMode,
                        vm.successorAssignmentMode,
                        vm.predecessorAssignmentMode,
                    ],
                    ["name", name]
                ),
                vm.selectionMode
            );
        },
        setModeByName: function setModeByName(name) {
            this.active = this.getModeByName(name);
        },
        reset: function () {
            _onCancelDependenciesCreation();
        },
    };

    vm.pagination = {
        from: 0,
        records: 3,
        next: goNextPage,
        hasNext: $sbProcessViewerPaginationService.hasNextPage,
        previous: goPreviousPage,
        hasPrevious: $sbProcessViewerPaginationService.hasPreviousPage,
    };

    vm.activate = activate;
    vm.onFilterChanged = onFilterChanged;
    vm.onShowDeliverableDetails = onShowDeliverableDetails;
    vm.clearAllFilters = broadcastFilterClearEvent;
    vm.onCountChange = onCountChange;
    vm.startAssigningSelectionAsPredecessors =
        startAssigningSelectionAsPredecessors;
    vm.startAssigningSelectionAsSuccessors =
        startAssigningSelectionAsSuccessors;
    vm.onCancel = onCancel;
    vm.displayActionText = displayActionText;
    vm.onActivityClicked = callThroughIfAtLeastManager(onActivityClicked);
    vm.changeState = $sbProcessViewerProgress.changeState;
    vm.createNoteOnActivity = $sbProcessViewerProgress.createNoteOnActivity;
    vm.goToToolsPage = goToToolsPage;
    vm.hideDoneActivites = _shouldHideDoneActivitesOnInit();
    vm.onHideActivitesSwitchChange = onHideActivitesSwitchChange;
    vm.onToggleAreaManagerFilter = onToggleAreaManagerFilter;
    vm.onPageSizeChange = onPageSizeChange;
    vm.collectActivities = collectActivities;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    var unWatchStateChange = $rootScope.$on(
        EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
        onStateUpdated
    );

    $scope.$on("$destroy", function $onDestroy() {
        unWatchStateChange();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    vm.activate();

    function activate() {
        fetchTeamsOfCurrentProject();
        fetchTotalCountOfDeliverablesInProject();
        prepareStructureMapper();
    }

    function loadFiltersAndUpdateLoader($bgMenuEvent) {
        _setLastEvent($bgMenuEvent);

        return _loadFilters()
            .then(function (filters) {
                _updateLoader(filters, $bgMenuEvent);
            })
            .finally(function () {
                vm.isLoading = false;
            });
    }

    function _setLastEvent($bgMenuEvent) {
        vm.filterMenu.lastCallbackEvent = $bgMenuEvent;
    }

    function _loadFilters() {
        return $sbProcessViewerLoader.setupFilters(
            $sbCurrentProject.pick("id")
        );
    }

    function _updateLoader(filters, $bgMenuEvent) {
        // apply area manager filter based on background menu settings
        //
        var areaManagerViewFilter = findFilterByKey(
            filters,
            "areaManagerFilter"
        );

        _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter);

        vm.odataSourceSetting.filters = filters;

        updateLoader(vm.odataSourceSetting.filters);
    }

    function _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter) {
        if ($bgMenuEvent.hasAreaManagerRestrictions && $bgMenuEvent.isActive) {
            areaManagerViewFilter.setValue($bgMenuEvent.userName);
        } else {
            areaManagerViewFilter.clear();
        }
    }

    function onToggleAreaManagerFilter($bgMenuEvent) {
        $sbTracking.filterMenu.byConstructionManager("Process Tracker");

        _setLastEvent($bgMenuEvent);

        var areaManagerViewFilter = findFilterByKey(
            vm.odataSourceSetting.filters,
            "areaManagerFilter"
        );

        _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter);

        onFilterChanged();
    }

    function prepareStructureMapper() {
        return $sbStructure
            .asMap($sbCurrentProject.pick("id"))
            .then((map) => (structureMapper = map));
    }

    function fetchTotalCountOfDeliverablesInProject() {
        return $sbProcessViewerLoader
            .fetchTotalCountOfDeliverablesInProject()
            .then(function updateTotalCount(totalCount) {
                vm.odataSourceSetting.numOfDeliverables = totalCount;
            });
    }

    function fetchTeamsOfCurrentProject() {
        return $sbTeam
            .getTeams($sbCurrentProject.pick("id"))
            .then(function (teams) {
                teamMap = new Map(teams.map((team) => [team.id, team]));
                return (vm.teams = teams);
            });
    }

    function displayActionText() {
        if (vm.mode.active.name === vm.selectionMode.name) {
            return "_CANCEL";
        }

        return "_FINISH";
    }

    /**
     * Handler for filter change event.
     *
     * @param label
     * @param filter
     */
    function onFilterChanged(label, filter) {
        updateLoader(vm.odataSourceSetting.filters);

        //save filters to URL and local storage
        //
        $sbProcessViewerLoader.setFilterParams(vm.odataSourceSetting.filters);
    }

    function onCancel($event) {
        $sbTracking.detailedDependencies.assignment.canceled();
        vm.mode.reset($event);
    }

    /**
     * Toggle details overlay for deliverable.
     *
     * @param deliverable
     */
    function onShowDeliverableDetails(deliverable) {
        $sbDetailsOverlay.toggleView("deliverable", deliverable.id);
    }

    /**
     * Handler for state update event.
     *
     * @param $event
     * @param activityId
     * @param toState
     */
    function onStateUpdated($event, activityId, toState) {
        const deliverables = vm.odataSourceSetting.loader._items;

        $sbProcessViewerProgress.updateStateForActivity(
            deliverables,
            activityId,
            toState
        );
    }

    function onCountChange(loader) {
        vm.odataSourceSetting.numOfFilteredDeliverables = loader.getLength();
    }

    function fnOnDeliverablesLoaded(deliverables) {
        if (deliverables && deliverables.length > 0) {
            $sbProcessViewerPaginationService.calculateMaxNumberOfPages(
                deliverables
            );
        }
    }

    function onHideActivitesSwitchChange(hideDoneActivites) {
        var gaLabel = hideDoneActivites
            ? "Hide activites done"
            : "Show activites done";

        _trackUiModeChanges(gaLabel);
        _saveShowDoneActivitiesState(
            PROCESS_VIEWER_HIDE_FINISHED_ACTIVITES_KEY,
            hideDoneActivites
        );
    }

    function _trackUiModeChanges(label) {
        Analytics.trackEvent("Process tracker", "UI modes", label);
    }

    function _saveShowDoneActivitiesState(storageKey, value) {
        $window.localStorage.setItem(storageKey, value);
    }

    function _loadShowDoneActivitiesState(storageKey) {
        return $window.localStorage.getItem(storageKey);
    }

    function _shouldHideDoneActivitesOnInit() {
        return (
            _loadShowDoneActivitiesState(
                PROCESS_VIEWER_HIDE_FINISHED_ACTIVITES_KEY
            ) === "true"
        );
    }

    function startAssigningSelectionAsPredecessors() {
        $sbTracking.detailedDependencies.assignment.as("Predecessor");

        vm.mode.setModeByName(vm.predecessorAssignmentMode.name);
    }

    function startAssigningSelectionAsSuccessors() {
        $sbTracking.detailedDependencies.assignment.as("Successor");

        vm.mode.setModeByName(vm.successorAssignmentMode.name);
    }

    function onActivityClicked(activity, $event) {
        vm.mode.active.onClick(activity, $event);
    }

    function findFilterByKey(viewFilters, key) {
        return _.find(viewFilters, function (viewFilter) {
            return viewFilter.key === key;
        });
    }

    /**
     * Create new loader with changed/updated view filters.
     *
     * @param filters
     */
    function updateLoader(filters) {
        try {
            vm.odataSourceSetting.loader = $sbProcessViewerLoader.createLoader(
                filters,
                { structureMapper, teamMapper },
                { fnOnCountChange: vm.onCountChange, fnOnDeliverablesLoaded }
            );
        } catch (e) {
            if (e.name === "UrlLimitExceededException") {
                handleUrlLimitExceededException(filters);
            } else {
                $log.error(e);
                throw e;
            }
        }
    }

    function _resetStructureFilterAndUpdate() {
        var structureFilter = findFilterByKey(
            vm.odataSourceSetting.filters,
            "structure"
        );
        structureFilter.value = [];

        onFilterChanged();
        activate();
        loadFiltersAndUpdateLoader(vm.filterMenu.lastCallbackEvent);
    }

    function handleUrlLimitExceededException() {
        if (vm.filterPanelRef) {
            vm.filterPanelRef.close();
        }

        return showUrlLimitExceededAlert().then(_resetStructureFilterAndUpdate);
    }

    function broadcastFilterClearEvent() {
        $rootScope.$broadcast(EVENTS.ODATA_LIST__FILTERS_CLEAR);
    }

    function _onCancelDependenciesCreation() {
        vm.selectionProvider.clearSelection();
        vm.mode.setModeByName(vm.selectionMode.name);
    }

    function _assignAs(selections, selectedActivity) {
        var edges = vm.mode.active.getEdgesBy(selections, selectedActivity);

        return $sbBusyIndication.show(
            $sbActivities.edges
                .create(edges)
                .then(function onAssignmentSuccess() {
                    $sbTracking.detailedDependencies.assignment.completedWith(
                        (edges || []).length
                    );
                    _showAssignmentSuccessToast();
                })
                .catch(function (error) {
                    $sbErrorPresenter.catch(error);
                }),
            "DIALOG_CREATE_DEPENDENCY_LOADING_TEXT",
            "DIALOG_CREATE_DEPENDENCY_LOADING_TITLE"
        );
    }

    function showUrlLimitExceededAlert() {
        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_TITLE")
                .content("DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_CONTENT")
        );
    }

    function goToToolsPage() {
        $state.go(SABLONO_STATES.tools, {
            open: "export",
        });
    }

    function _showAssignmentSuccessToast() {
        var successToast = $mdToast.simple().content("EDGE_CREATE_SUCCESSFUL"); // TODO needs better translation

        return $mdToast.show(successToast);
    }

    function _predecessorsMapper(selectedActivity, predecessor) {
        return {
            source: predecessor,
            target: selectedActivity,
        };
    }

    function _successorsMapper(selectedActivity, successor) {
        return {
            source: selectedActivity,
            target: successor,
        };
    }

    function _showCycleErrorToast() {
        return $mdToast.show(
            $mdToast.simple().content("ERROR_EDGE_WOULD_INTRODUCE_CYCLE")
        );
    }

    function getProjectPrivileges() {
        return $sbCurrentProject.pick("privileges");
    }

    function callThroughIfAtLeastManager(fn) {
        if ($sbPermission.hasPlanPermissions(getProjectPrivileges())) {
            return fn;
        }

        return angular.noop;
    }

    function goPreviousPage() {
        $sbTracking.processTracker.navigation.previous();
        vm.pagination.from = $sbProcessViewerPaginationService.goPreviousPage();
    }

    function goNextPage() {
        $sbTracking.processTracker.navigation.next();
        vm.pagination.from = $sbProcessViewerPaginationService.goNextPage();
    }

    function onPageSizeChange(changes) {
        if (
            _.get(changes, "pageSize.previousValue") !==
            _.get(changes, "pageSize.currentValue")
        ) {
            vm.pagination.from =
                $sbProcessViewerPaginationService.setRecordsPerPage(
                    _.get(changes, "pageSize.currentValue")
                );
            vm.pagination.records = _.get(changes, "pageSize.currentValue");
        }
    }

    function collectActivities(deliverable) {
        var activities = _.result(deliverable, "getActivities", []);
        if (vm.hideDoneActivites) {
            return activities.filter((a) => !a.state.isCompleted());
        } else {
            return activities;
        }
    }
}
