export default function DateChooserCtrl(mdPanelRef) {
    "ngInject";
    var vm = this;
    vm.selectedDate = vm.previousDate;

    vm.cancel = mdPanelRef.close.bind(mdPanelRef);
    vm.save = save;

    function save(selectedDate, previousDate) {
        vm.onSelectionChange(selectedDate, previousDate);
        vm.cancel({
            current: selectedDate,
            previous: previousDate,
        });
    }
}
