import "../styles/compile.less";
import "../styles/states.less";
import "core-js";
import "common/polyfill/all";
import angular from "angular";
import browserUpdate from "browser-update";
import app from "./app";

//http://code.angularjs.org/1.2.1/docs/guide/bootstrap#overview_deferred-bootstrap

//AngularJS bootstrap element
window.NG_BOOTSTRAP_ELEMENT_ID = "ngbootstrap"; // eslint-disable-line angular/window-service

var $bootstrapElement = angular.element(
    document.getElementById(window.NG_BOOTSTRAP_ELEMENT_ID)
); // eslint-disable-line angular/window-service,angular/document-service

// Check browser type
browserUpdate({
    required: {
        e: 12, // all versions of Edge (old / new engine)
        f: 70, // Firefox -> released 2019-10-22
        o: 64, // Opera -> released 2019-10-07
        o_a: -2, // Opera (Android)
        s: 13, // Safari -> released 2019-09-19
        c: 79, // Chrome -> released 2019-10-22
        samsung: 0, // Samsung Internet (always latest)
    },
    reminder: 0,
    reminderClosed: 24,
    newwindow: true,
    no_permanent_hide: false,
    noclose: true,
});

$bootstrapElement.ready(function () {
    angular.bootstrap($bootstrapElement, [app.name], {
        /**
         * Strict DI Mode
         * https://docs.angularjs.org/guide/production
         * enable strict DI mode to improve angular's performance when injecting dependencies in your injectable functions
         * only enable it when it builded
         */
        strictDi: true,
    });
});
