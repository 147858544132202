import createjs from "createjs";
/**
 * Event that is dispatched when Settings of WbsEditor are changed
 * @param {Object.<String, Boolean>} newSettings - New Settings from {@link WBS.WbsEditor}
 * @param {Object.<String, Boolean>} oldSettings - Old Settings from {@link WBS.WbsEditor}
 * @constructs SettingsChangedEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function SettingsChangedEvent(newSettings, oldSettings) {
    createjs.Event.call(this, "settingsChanged", false, false);
    this.newSettings = newSettings;
    this.oldSettings = oldSettings;
}

SettingsChangedEvent.prototype = Object.create(createjs.Event.prototype);

SettingsChangedEvent.prototype.hasChanged = function (settingsKey) {
    return this.newSettings[settingsKey] !== this.oldSettings[settingsKey];
};

SettingsChangedEvent.prototype.isEnabled = function (settingsKey) {
    return !!this.newSettings[settingsKey];
};

SettingsChangedEvent.prototype.isDisabled = function (settingsKey) {
    return !this.newSettings[settingsKey];
};

SettingsChangedEvent.prototype.isEnabledAndHasChanged = function (settingsKey) {
    return this.hasChanged(settingsKey) && this.isEnabled(settingsKey);
};

SettingsChangedEvent.prototype.isDisabledAndHasChanged = function (
    settingsKey
) {
    return this.hasChanged(settingsKey) && this.isDisabled(settingsKey);
};

export default SettingsChangedEvent;
