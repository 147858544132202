import moment from "moment";

export default function (
    $q,
    SbCalendar,
    $sbCalendarsApi,
    $sbTimezone,
    $sbJobApi,
    $sbCurrentProject
) {
    "ngInject";

    // what about caching the actual promise to solve "raise" situations
    //
    let calendarCache = {};

    return {
        get: get,
        update: update,
        clear: clear,
    };

    /**
     * Get the working calendar based on the given id.
     * Read from cache if possible.
     *
     * @param {String} projectId
     * @return {Promise<SbCalendar>}
     */
    function get(projectId) {
        if (calendarCache[projectId]) {
            return $q.resolve(calendarCache[projectId]);
        }

        return _fetchProjectCalendar(projectId)
            .then((calendar) => {
                const sbCalendar = new SbCalendar(
                    calendar.id,
                    calendar.working_days.map((is) => (is ? 1 : 0)),
                    calendar.working_shifts,
                    $sbTimezone.getZoneFromTimezone(calendar.timezone)
                );

                sbCalendar.PROJECT_ID = projectId;

                sbCalendar.exceptionDates = calendar.off_days.map(
                    ({ date, label }) => {
                        return {
                            date: moment.utc(date, moment.ISO_8601),
                            label,
                        };
                    }
                );

                return sbCalendar;
            })
            .then(function (calendar) {
                return _cacheProjectCalendar(calendar, projectId);
            });
    }

    /**
     * update - Send the new calendar to the server to update it
     *
     * @param  {SbCalendar} calendar    - the calendar object
     * @return {Promise<SbCalendar>}    - the updated calendar
     */
    function update(calendar) {
        const working_shifts = calendar.BLOCKS;
        const working_days = calendar.DAYS.map((day) => !!day);

        const off_days = calendar.exceptionDates.map(({ date, label }) => {
            return {
                date: date.format("YYYY-MM-DD"),
                label,
            };
        });

        const timezone = calendar.TIMEZONE.name;

        return $sbCalendarsApi
            .update(calendar.PROJECT_ID, calendar.KEY, {
                working_shifts,
                off_days,
                working_days,
                timezone,
            })
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            })
            .then(() => {
                // clear the cache
                calendarCache[calendar.PROJECT_ID] = undefined;
                // return and cache the updated version
                return get(calendar.PROJECT_ID);
            });
    }

    function clear() {
        calendarCache = {};
    }

    /**
     * Plain http call
     *
     * @param {String} projectId
     * @return {Promise<Object>}
     * @private
     */
    function _fetchProjectCalendar(projectId) {
        return $sbCalendarsApi
            .getCollection(projectId)
            .then(({ calendars }) => calendars[0]);
    }

    function _cacheProjectCalendar(calendar, projectId) {
        calendarCache[projectId] = calendar;
        return calendar;
    }
}
