class LookAheadSessionsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
        this.STATUS_OPEN = "open";
        this.STATUS_CLOSED = "closed";
        this.ACTION_RELEASE = "release";
        this.ACTION_DISCARD = "discard";
    }

    /**
     * This endpoint returns a look ahead session representation for a given project.
     *
     * @param projectId {string}    UUID
     * @param byStatus {string}     Status of the session (open or close)
     */
    getCollection(projectId, byStatus = this.STATUS_OPEN) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/look-ahead-sessions`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                by_status: byStatus,
            },
        });
    }

    /**
     * This endpoint returns a look ahead session representation for a given project.
     *
     * @param projectId {string}    UUID
     * @param sessionId {number}    The unique identifier of the session you are requesting a resource for.
     */
    get(projectId, sessionId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/look-ahead-sessions/${sessionId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint starts a look ahead session for a given project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.type {string}    Name of the job
     * @param body.definition {object}    Detailed description about the job setup
     *
     */
    create(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/look-ahead-sessions`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
        });
    }

    /**
     * This endpoint release and save all changes made during the session or discard them all and release the session.
     *
     * @param projectId {string}    UUID
     * @param sessionId {number}    The unique identifier of the session you are requesting a resource for.
     * @param action {string}       Action to be applied to the look ahead session.
     */
    update(projectId, sessionId, action) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/look-ahead-sessions/${sessionId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: { action: action },
        });
    }
}

export default LookAheadSessionsWebService;
