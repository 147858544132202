import "angular";
import { ProjectSource } from "../../domain/sb_project_source.class";

export default function ExportCtrl(
    moment,
    $state,
    $stateParams,
    $sbErrorPresenter,
    $sbCurrentProject,
    $log,
    $sbCsv,
    exportSrcObject, // resolve variable from export state.
    downloadCenterService,
    Analytics
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.hasError = false;

    activate();

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function activate() {
        // The exportSrcObject is resolved before entering any export state. The request could fail and
        // will result in a error. The error is best handled here.
        //
        if (exportSrcObject instanceof Error) {
            _showError(exportSrcObject);
        } else {
            _srcBasedNavigation(exportSrcObject);
        }
    }

    function _srcBasedNavigation(srcObject) {
        if (srcObject instanceof ProjectSource) {
            Analytics.trackConversion("data exported");
            Analytics.trackEvent("Progress export", "click", srcObject.type);
            switch (srcObject.type) {
                case ProjectSource.TYPES.MSP:
                    return _downloadSourceCSV(srcObject);
                case ProjectSource.TYPES.ASTA:
                    return _downloadSourceCSV(srcObject);
                case ProjectSource.TYPES.PRIMAVERA:
                    return _navigateIfStateIsCurrent(
                        "sablono.project.export.file",
                        $stateParams
                    );
                case ProjectSource.TYPES.EXCEL:
                    return _downloadSourceCSV(srcObject);
            }
        }

        if (srcObject.NAME === "PROJECT") {
            Analytics.trackConversion("data exported");
            srcObject.type = "EXCEL";
            return _downloadProjectCSV();
        }

        $log.error(
            new TypeError(
                'IllegalArgumentException: The argument is not of type "ProjectSource"'
            ),
            srcObject
        );

        // if there is no src object. The user did weird things..
        _showError();
    }

    /**
     * Trigger a file download for the given project source
     *
     * @param {ProjectSource} srcObject
     * @return {Promise}
     * @private
     */
    function _downloadSourceCSV(srcObject) {
        return $sbCsv
            .fetchSourceProgressCSV(
                srcObject.id,
                srcObject.type,
                $stateParams.projectId
            )
            .then(_handleFetchedProgressFile)
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                _showError();
            });
    }

    function _downloadProjectCSV() {
        return $sbCsv
            .fetchProjectProgressCSV($stateParams.projectId)
            .then(_handleFetchedProgressFile)
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                _showError();
            });
    }

    function _handleFetchedProgressFile(response) {
        const contentRows = response.getResponseHeader("content-rows");
        const downloadKey = downloadCenterService.store(
            response.responseText,
            "text/csv"
        );

        const fileName =
            downloadCenterService.getFilenameFromContentDispositionHeader(
                response
            ) || _createCsvFileName();

        return _navigateIfStateIsCurrent(
            "sablono.project.export.download",
            {
                downloadKey: downloadKey,
                exportStats: {
                    platformTotal: Number.parseInt(contentRows, 10),
                },
                fileName: fileName,
            },
            {
                location: "replace",
            }
        );
    }

    function _showError() {
        vm.hasError = true;
    }

    function _createCsvFileName() {
        return (
            moment().format("YYYY_M_D_H_m") +
            "_" +
            $sbCurrentProject.pick("name") +
            ".csv"
        );
    }

    /**
     * To prevent unintended navigation check if the current state is this.
     *
     */
    function _navigateIfStateIsCurrent(stateName, stateParams, options) {
        if ($state.current.name === "sablono.project.export") {
            return $state.go(stateName, stateParams, options);
        }
    }
}
