export default function (
    $q,
    $sbRequest,
    $sbCurrentProject,
    $sbPrimavera,
    $mdDialog,
    exportPrimaveraService,
    $sbReportingDeliverablesApi
) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        fetchProgressMap: fetchProgressMap,
        updateProgressValues: updateProgressValues,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Get current progress of project from the server
     * @params {String} projectId - Id of current project
     */
    function fetchProgressMap({ id, type }, projectId) {
        //If project ID is not given get the current project id from sbProject service
        const project = projectId || $sbCurrentProject.pick("id");

        //Get the data
        return $sbReportingDeliverablesApi.exportCollection(project, {
            by_source_id: id,
            by_source_type: type,
        });
    }

    /**
     * Method to update all the progress values based on updated progress service
     * -> Goes through each task
     *   -> Checks for progress changes
     *      -> If there's a progress change, record the stats and update the progress
     *
     * @param {XMLDocumentObject} oXML - XML Object got from file uploaded by the user
     * @param {Object} updatedProgress - Object containing key, value pairs against each deliverable available in our project
     *
     * @return {Promise}
     */
    function updateProgressValues(oXML, updatedProgress) {
        if (oXML.getElementsByTagName("APIBusinessObjects").length > 0) {
            const progressMap = _convertPRIMAVERACsvToMap(updatedProgress);
            return $q
                .resolve(
                    exportPrimaveraService.exportProgressValues(
                        oXML,
                        progressMap
                    )
                )
                .then(function (updatedFileContentAndStats) {
                    return {
                        updatedXmlFileString:
                            new XMLSerializer().serializeToString(
                                updatedFileContentAndStats.oXML.documentElement
                            ),
                        exportStats: updatedFileContentAndStats.exportStats,
                        fileSource: "PRIMAVERA",
                    };
                });
        } else {
            return $q.reject("NOTHING_UPDATED");
        }
    }

    /**
     * Method that receives a text like:
     *
     *    "Unique ID"	PercentComplete
     *     {05244641-D176-3649-A7B2-742C9866918A}	0
     *     {06325BF1-A66E-6543-87FA-72405573CC98}	0
     *     {064DF25D-C94D-AC47-9730-26BD24A96A7E}	0
     *
     * a generates a map with (id, progress)
     * @param updatedProgress: string
     * @returns {Map<string, number>}
     * @private
     */
    function _convertPRIMAVERACsvToMap(updatedProgress) {
        const updatedProgressAsArray = updatedProgress.split("\r\n");
        // Remove header
        updatedProgressAsArray.shift();
        const progressMap = new Map();
        updatedProgressAsArray.forEach((row) => {
            let activity = row.split("\t");
            const id = activity[0];
            const progress = Number.parseInt(activity[1]);
            progressMap.set(id, progress);
        });
        return progressMap;
    }
}
