import angular from "angular";
import deliverableWorkflowComponentDefinition from "./deliverable_workflow.component";
import activityTreeComponentDefinition from "./sbActivityTree/activity_tree.component";
import workflowActivityComponentDefinition from "./sbWorkflowActivity/workflow_activity.component";
import workflowActivityDetailComponentDefinition from "./sbWorkflowActivityDetail/workflow_activity_detail.component";
import workflowGroupComponentDefinition from "./sbWorkflowGroup/workflow_group.component";
import deliverableWorkflowService from "./deliverable_workflow.service";
import ServiceModule from "common/services/services";
import DialogModule from "common/dialog/dialog";
import TemplateSelectorDialogModule from "common/dialog/templateSelector/template_selector.dialog";
import "common/toast/loadingToast";

export default angular
    .module("sbApp.sbDeliverableWorkflow", [
        ServiceModule.name,
        TemplateSelectorDialogModule.name,
        "sbApp.sbPropertiesSourceIcon",
        "sbApp.loadingToast",
        DialogModule.name,
    ])
    .constant("PADDING_SIZES", {
        initial: 24,
        increment: 48,
        initialDetail: 104,
    })
    .factory("sbDeliverableWorkflowService", deliverableWorkflowService)
    .component("sbDeliverableWorkflow", deliverableWorkflowComponentDefinition)
    .component("sbActivityTree", activityTreeComponentDefinition)
    .component("sbWorkflowActivity", workflowActivityComponentDefinition)
    .component(
        "sbWorkflowActivityDetail",
        workflowActivityDetailComponentDefinition
    )
    .component("sbWorkflowGroup", workflowGroupComponentDefinition);
