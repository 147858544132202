import EditorStage from "./EditorStage";
import Viewport from "../common/Viewport";
import CommandReceiver from "../common/CommandReceiver";
window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame;

/**
 * Class for Editor
 *
 * @param {Object} canvas - Canvas Element on which is drawn
 * @param {boolean} debug - is Debug Mode enabled
 * @constructs Editor
 * @memberof WBS
 */
function Editor(canvas, debug) {
    /**
     * Determiens whether Debug Mode is activated
     * @type {boolean}
     */
    this.debug = debug;

    /**
     * @type {WBS.EditorStage}
     */
    this.stage = this.createStage(canvas);

    /**
     * @type {WBS.Viewport}
     */
    this.viewport = new Viewport(this.stage);
    this.stage.viewport = this.viewport;

    this.stage.addChild(this.viewport);

    /**
     *
     * @type {WBS.CommandReceiver}
     */
    this.commandReceiver = new CommandReceiver();

    this.onResize = this._onResize.bind(this);

    this.update = this._update.bind(this);

    this.sizeChanged = true;

    this.onResize();

    window.addEventListener("resize", this.onResize);

    this._requestId = window.requestAnimationFrame(this.update);
}

/**
 * Sets Model for i18n
 * @param {Object} i18nModel - Hash with key -> text for i18n
 */
Editor.prototype.setI18n = function (i18nModel) {
    this.i18n = this.stage.setI18n(i18nModel);
};

/**
 * Creates a new Editor Stage on Canvas
 * @param {Object} canvas - Canvas on which is drawn
 * @returns {EditorStage}
 */
Editor.prototype.createStage = function (canvas) {
    return new EditorStage(canvas);
};

/**
 * Stop animation frame loop and save state.
 */
Editor.prototype.destroy = function () {
    window.cancelAnimationFrame(this._requestId);
    this._requestId = undefined;
};

/**
 * called every frame
 * @private
 */
Editor.prototype._update = function () {
    this._requestId = window.requestAnimationFrame(this.update);

    this.stage.update();
};

/**
 * called then window resize event triggered
 * @private
 */
Editor.prototype._onResize = function () {
    var parent = this.stage.canvas.parentNode;
    var width = parent.clientWidth;
    var height = parent.clientHeight;
    this.stage.setSize(width, height);
};

/**
 * Enabled Editing Mode
 */
Editor.prototype.enableEditorMode = function () {
    this.stage.enableEditorMode();
};

/**
 * Disables Editing Mode
 */
Editor.prototype.disableEditorMode = function () {
    this.stage.disableEditorMode();
};

export default Editor;
