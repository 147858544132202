import angular from "angular";
import _ from "lodash";

export default function ProjectSelectionCtrl(
    EVENTS,
    $scope,
    $window,
    $filter,
    $mdSidenav,
    $mdDialog,
    $state,
    $mdToast,
    $sbProjectApi,
    $sbCalendarRepository,
    $q,
    $sbErrorPresenter,
    $sbMembership,
    PROJECT_CONFIG,
    $sbPageAccess,
    $sbPageNavigation,
    $timeout,
    intercomService,
    TRACKED_EVENTS,
    Analytics,
    $translate,
    $http,
    SABLONO_STATES,
    $log,
    $sbReportingProjectsApi,
    $sbCurrentProject
) {
    "ngInject";

    var vm = this;

    vm.isLoading = true;
    vm.isInvitationsLoading = true;

    vm.projects = [];
    vm.createProject = false;
    vm.projectSearch = "";
    vm.showWelcomeCard = localStorage.welcomeCardIsRemoved !== "true";

    vm.openIntercomChat = intercomService.show;
    vm.openNewProjectDialog = openNewProjectDialog;
    vm.removeWelcomeCard = removeWelcomeCard;
    vm.openProjectSettingsPage = openProjectSettingsPage;
    vm.contactProjectManager = contactProjectManager;

    vm.onProjectInviteAccept = acceptProjectInvite;
    vm.onProjectInviteDecline = declineProjectInvite;

    vm.pendingInvites = [];

    vm.PROJECT_TYPES = [
        {
            type: "import",
            name: "ACTION_NAV_TO_IMPORT_SELECTION",
            icon: "mdi mdi-file-import",
            class: "sb-create-project-excel",
            headline: "SECTION_CREATE_FROM_IMPORT",
            importClass: "sb-select-excel-import",
            key: "IMPORT",
            stateName: "sablono.project.import.selection",
            tooltip: "ACTION_PROJECT_FROM_IMPORT_TOOLTIP",
            clickHandler: openNewProjectDialog,
        },
        {
            type: "empty",
            name: "ACTION_NAV_TO_IMPORT_EMPTY_PROJECT",
            headline: "SECTION_CREATE_EMPTY",
            class: "sb-create-project-empty",
            importClass: "sb-select-empty-import",
            icon: "mdi mdi-file",
            key: "EMPTY",
            stateName: "sablono.project.ngdeliverables",
            tooltip: "ACTION_CREATE_EMPTY_TOOLTIP",
            clickHandler: openNewProjectDialog,
        },
    ];

    vm.filters = [
        {
            key: "name",
            text: "NAME",
            iconAsc: "mdi-sort-ascending",
            iconDesc: "mdi-sort-descending",
        },
        {
            key: "code",
            text: "CODE",
            iconAsc: "mdi-sort-ascending",
            iconDesc: "mdi-sort-descending",
        },
        {
            key: "progress",
            text: "PROJECT_SELECTION_FILTERS_STATUS",
            iconAsc: "mdi-sort-ascending",
            iconDesc: "mdi-sort-descending",
        },
    ];

    activate();

    function activate() {
        $sbMembership.clearCache();

        const projectSelectionEl = document.getElementById(
            "sbAppProjectSelection"
        );
        projectSelectionEl.navigateToTracker = navigateToTracker;

        // start fetching projects and reporting information concurrently
        //
        const projectsPromise = fetchAndDisplayProjects().catch(
            $sbErrorPresenter.catch
        );
        const trackingPromise = trackingInfoByProjectRequest();

        fetchAndDisplayInvitations();

        // but display projects already when available and
        // lazily integrate the reporting information
        // when they become available
        //
        return $q
            .all([
                projectsPromise,
                trackingPromise.catch((err) =>
                    $log.error("Fetching project reporting data failed!", err)
                ),
            ])
            .then(([, trackingInfo]) => {
                vm.projects = mergeTrackingIntoProjects(trackingInfo);
            });
    }

    function fetchAndDisplayInvitations() {
        $sbMembership
            .getMembershipInvitationsForUser()
            .then((data) => (vm.pendingInvites = data))
            .finally(function () {
                vm.isInvitationsLoading = false;
            });
    }

    function fetchAndDisplayProjects() {
        return $sbProjectApi
            .list()
            .then((projects) => {
                vm.projects = projects.map((sbProject) => {
                    sbProject.isEditable = $sbPageAccess.isAccessible(
                        SABLONO_STATES.projectSettings,
                        sbProject.privileges
                    );
                    return sbProject;
                });
            })
            .finally(function () {
                vm.isLoading = false;
            });
    }

    function acceptProjectInvite(invitation) {
        vm.isInvitationsLoading = true;

        $sbMembership.acceptMembershipInvitation(invitation.id).then((res) => {
            showSimpleToast("PROJECT_SELECTION_ACCEPT_INVITATION");

            fetchAndDisplayInvitations();
            fetchAndDisplayProjects();
        });
    }

    function declineProjectInvite(invitation) {
        vm.isInvitationsLoading = true;

        $sbMembership.rejectMembershipInvitation(invitation.id).then((res) => {
            showSimpleToast("PROJECT_SELECTION_DECLINE_INVITATION");

            fetchAndDisplayInvitations();
        });
    }

    function showSimpleToast(message) {
        $mdToast.show($mdToast.simple().content(message).position("top right"));
    }

    function mergeTrackingIntoProjects(trackingInfo) {
        return vm.projects.map((projectViewModel) => {
            const projectTrackingInfo = _.defaultTo(
                _.find(trackingInfo, ["id", projectViewModel.id]),
                {} // default is just an empty object
            );

            projectViewModel.progress = Math.round(
                projectTrackingInfo.progress || 0
            );
            projectViewModel.claimOpen =
                projectTrackingInfo.open_quality_issues || 0;
            projectViewModel.obstructionOpen =
                projectTrackingInfo.open_obstructions || 0;

            return projectViewModel;
        });
    }

    function trackingInfoByProjectRequest() {
        return $sbReportingProjectsApi.getCollection();
    }

    function openProjectSettingsPage(projectId) {
        $state.go(SABLONO_STATES.projectSettings, {
            projectId: projectId,
        });
    }

    function removeWelcomeCard() {
        localStorage.welcomeCardIsRemoved = true;
        vm.showWelcomeCard = false;
    }

    function contactProjectManager() {
        var translations = $translate.instant([
            "PROJECT_SELECTION_WELCOME_CARD_EMAIL_SUBJECT",
            "PROJECT_SELECTION_WELCOME_CARD_EMAIL_BODY",
        ]);
        $window.location.href =
            "mailto:?subject=" +
            translations.PROJECT_SELECTION_WELCOME_CARD_EMAIL_SUBJECT +
            "&body=" +
            translations.PROJECT_SELECTION_WELCOME_CARD_EMAIL_BODY;
    }

    //////////////////////////////////////////////
    function initSorting(jsonConfig) {
        var defaultConfig = {
            criteria: "name",
            direction: "+",
        };

        var storedConfig = {};

        if (jsonConfig) {
            storedConfig = angular.fromJson(jsonConfig);
        }

        vm.sorting = _.assign(defaultConfig, storedConfig);
    }

    // inti with results from local store
    initSorting(localStorage.getItem("sb.project.selection.view.config"));

    vm.createOrderBy = function (config) {
        return config.direction + config.criteria;
    };

    vm.changeFilter = function (newSortBy) {
        var newConfig = changeSortConfigFromCriteria(newSortBy);
        persistSortConfig(newConfig);
    };

    vm.addProject = function () {
        vm.createProject = !vm.createProject;
    };

    function changeSortConfigFromCriteria(criteria) {
        //filter already set, just toggle the sort order
        if (vm.sorting.criteria === criteria) {
            vm.sorting.direction = vm.sorting.direction === "+" ? "-" : "+";
        } else {
            vm.sorting.direction = "+";
            vm.sorting.criteria = criteria;
        }

        //for progress, always invert the sortOrder
        if (criteria === "PROGRESS") {
            vm.sorting.direction = vm.sorting.direction === "+" ? "-" : "+";
        }

        return vm.sorting;
    }

    function persistSortConfig(config) {
        return localStorage.setItem(
            "sb.project.selection.view.config",
            angular.toJson({
                criteria: config.criteria,
                direction: config.direction,
            })
        );
    }

    function openNewProjectDialog($event, type) {
        if (!type) {
            type = vm.PROJECT_TYPES[0];
        }

        $mdDialog
            .show(
                $mdDialog
                    .createNewProject()
                    .type(type)
                    .targetEvent($event)
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
            )
            .then(function (newProject) {
                var busyDialog = $mdDialog
                    .busyIndication()
                    .title("DIALOG_CREATING_PROJECT")
                    .text("DIALOG_BUSY_TAKE_TIME");
                return $mdDialog.show(
                    busyDialog.allPromises([
                        _createNewProject(newProject, type),
                    ])
                );
            });
    }

    function navigateToTracker(projectId) {
        return $state.go(SABLONO_STATES.tracker, {
            projectId,
        });
    }

    function _createNewProject(newProject, type) {
        const project = {
            name: newProject.name,
            code: newProject.code,
            desc: newProject.desc,
            type: newProject.otherType ? newProject.otherType : newProject.type,
            start_date: newProject.startDate.toISOString(),
            end_date: newProject.endDate.toISOString(),
            language: _.get(newProject, "language", "").toLowerCase(),
            inspect_app_config: {
                block_agg_progress: true,
                geolocation_permission: false,
                sign_qa_checklist_work_team: true,
                sign_qa_checklist_inspection_team: true,
            },
            scheduling: {
                mode: "DATES",
            },
            quality_feature_config: {
                limited_note_visibility: false,
            },
            notification_config: {
                activate_email_daily_update: false,
                activate_email_realtime_update: false,
                activate_email_weekly_reminder: false,
                activate_email_weekly_lookahead: false,
            },
        };
        const suggestedLanguage = $translate.use().toLowerCase();
        if (suggestedLanguage !== project.LANGUAGE) {
            //Language has been changed by user let's keep track of this development
            Analytics.trackEvent(
                "Project Language Suggestion (Create)",
                "User Changed Language",
                "From " + suggestedLanguage + " to " + project.LANGUAGE
            );
        }

        var addedProject;
        return $sbProjectApi
            .create(project)
            .then(function (serviceProject) {
                addedProject = serviceProject;
                return $sbCalendarRepository.get(serviceProject.id); //Get the calendar of the project
            })
            .then(function (calendar) {
                calendar.TIMEZONE = newProject.TIMEZONE;
                return $sbCalendarRepository.update(calendar); //Updates the calendar with the selected calendar
            })
            .then(function () {
                Analytics.trackConversion("project created");
                intercomService.track(TRACKED_EVENTS.PROJECT_CREATED);
                vm.projects.unshift(addedProject);
                $sbCurrentProject.set(addedProject);
                $timeout(
                    () => $sbPageNavigation.goToLandingPage(addedProject),
                    10
                );
            })
            .catch($sbErrorPresenter.catch);
    }

    //Implementing the search broadcast reciever
    var searchListener = $scope.$on(
        EVENTS.GLOBAL_SEARCH_CHANGE,
        function (event, eventData) {
            vm.projectSearch = eventData.searchTerm;
        }
    );

    $scope.$on("$destroy", function () {
        searchListener();
    });
}
