import _ from "lodash";
import VirtualODataLoader from "./virtual_odata_loader.class";
import naturalSortFn from "../../filters/natural_sort.pojo";

export default function (
    $log,
    $sbReportingDeliverablesApi,
    SbDeliverable,
    SbActivity,
    $sbErrorPresenter,
    $sbCurrentProject
) {
    "ngInject";
    var ProcessLazyLoader = function (
        filter,
        orderBy,
        { structureMapper, teamMapper }
    ) {
        VirtualODataLoader.call(this, filter, orderBy);
        this.structureMapper = structureMapper;
        this.teamMapper = teamMapper;
    };

    ProcessLazyLoader.prototype = Object.create(VirtualODataLoader.prototype);

    /**
     * @inheritDoc
     *
     * @param {Object} query
     * @param {ODataFilterFactory} query.filter
     * @param {String} query.orderBy
     * @param {Number} from - load data block from number
     * @param {Number} to - to number
     * @returns {$q} - the resolved OData request with the data array
     */
    ProcessLazyLoader.prototype.loadODataResource = function (
        { filter, orderBy = "SD,CD,NAME" },
        from = 0,
        to = 100
    ) {
        const selects = [
            "ID",
            "STRUCTURE_ID",
            "PROJECT_ID",
            "NAME",
            "CODE",
            "AREA_MANAGER_USER_NAME",
        ];

        return $sbReportingDeliverablesApi
            .getCollection($sbCurrentProject.pick("id"), {
                filter: filter ? filter.get() : undefined,
                select: selects.join(","),
                orderby: orderBy,
                top: to - from,
                skip: from,
                expand: "ACTIVITIES",
            })
            .then(({ meta, deliverables = [] }) => {
                return {
                    count: meta.count_all,
                    items: deliverables.map((deliverableData) => {
                        const deliverable =
                            SbDeliverable.createFromOdataObject(
                                deliverableData
                            );
                        deliverable.structure = this.structureMapper.get(
                            deliverable.structureId
                        );
                        const activities = (
                            deliverableData.ACTIVITIES || []
                        ).map(SbActivity.createFromOdataObject);
                        activities.forEach((a) => {
                            a.assignedTeam = this.teamMapper.get(
                                a.assignedTeam.id
                            );
                            a.confirmationTeam = this.teamMapper.get(
                                a.confirmationTeam.id
                            );
                        });

                        activities.sort((actA, actB) => {
                            const byTopologicalIndex =
                                actA.topologicalIndex - actB.topologicalIndex;
                            if (byTopologicalIndex !== 0) {
                                return byTopologicalIndex;
                            }
                            return naturalSortFn(actA.name, actB.name);
                        });
                        deliverable.setChildren(activities);
                        return deliverable;
                    }),
                };
            })
            .then(function (response) {
                $log.log("lazy loader for PROCESS", response);
                return response;
            })
            .catch(function (e) {
                $sbErrorPresenter.catch(e); // handle the error
                throw e; // throw again to inform the lazy loader
            });
    };

    return ProcessLazyLoader;
}
