import moment from "moment";
import _ from "lodash";
import { EndBeforeStartError } from "../../../../../common/errors/EndBeforeStartError";

export default function sbActivityDetailsPanelController(
    $timeout,
    $filter,
    mdPanelRef,
    $sbUserInteraction,
    $sbLeanBoard,
    $sbLookAheadModification,
    $sbTracking,
    USER_INTERACTION,
    $mdPanel,
    $document,
    $sbActivityDetailsPanelCommonService,
    Analytics
) {
    "ngInject";
    const vm = this;

    // filters
    const _asStateIconFilter = $filter("asStateIcon");
    const _translate = $filter("translate");

    const { applyNewStartDate, applyNewEndDate } = $sbLookAheadModification.use(
        vm.calendar
    );

    // form
    vm.isUpdateDependentsChecked = true;

    vm.hasSessionPermission =
        $sbActivityDetailsPanelCommonService.hasPermission();

    vm.onClose = vm.onClose = () =>
        $sbActivityDetailsPanelCommonService.onClose(mdPanelRef);
    vm.onStartDateChanged = onStartDateChanged;
    vm.onEndDateChanged = onEndDateChanged;
    vm.onDurationChange = onDurationChange;
    vm.onSave = onSave;
    vm.isSaveDisabled = isSaveDisabled;
    vm.onStartSession = onStartSession;
    vm.onCommitForecast = onCommitForecast;
    vm.toggleIsDetailsShowing = toggleIsDetailsShowing;
    vm.isLookAheadChangeEnabled = isLookAheadChangeEnabled;

    $init();

    function $init() {
        vm.isLoading = true;
        vm.panelModel = _toPanelViewModel(vm.activity);

        $sbUserInteraction.clear(); // clear old state if starting with new panel

        vm.fetchAndExtendActivity(vm.activity)
            .then(function (data) {
                vm.panelModel = _toPanelViewModel(data);
            })
            .finally(function () {
                $timeout(() => {
                    vm.isLoading = false;
                }, 10);
            });
    }

    function onSave() {
        vm.onClose();

        if ($sbUserInteraction.hasHappened()) {
            vm.onChangeActivityDates(_transformToApiRequest(vm.panelModel), {
                updateDependants: vm.isUpdateDependentsChecked,
                userDefinedChanges: $sbUserInteraction.get(),
            })
                .then(() => {
                    Analytics.trackConversion("dates changed");
                })
                .then($sbUserInteraction.trackGA);
        }
    }

    function isSaveDisabled() {
        return vm.isLoading || (vm.activityForm && vm.activityForm.$invalid);
    }

    function currentModificationModel() {
        return {
            state: vm.panelModel.state,
            startDate: vm.panelModel.dates.startDate,
            endDate: vm.panelModel.dates.endDate,
            actualStart:
                vm.activity.schedule.actual.started ??
                vm.activity.schedule.actual.finished,
            duration: _getLastValidDuration(),
        };
    }

    function onDurationChange(newDuration) {
        if (newDuration) {
            $sbUserInteraction.changeDuration();
            vm.panelModel.durations.lastCommittedDuration =
                _stringToFloat(newDuration);

            const startDateToUse =
                vm.panelModel.dates.actualStart ||
                vm.panelModel.dates.startDate;
            onStartDateChanged(startDateToUse);
        }
    }

    function onStartDateChanged(newStartDate) {
        if (newStartDate && newStartDate.isValid()) {
            $sbUserInteraction.changeStartDate();
            vm.panelModel.durations.newDuration = _getLastValidDuration();
            const [updatedActivity] = applyNewStartDate(newStartDate, [
                currentModificationModel(),
            ]);
            if (updatedActivity) {
                _updateDates(updatedActivity);
                updateFormValidity();
            }
        }
    }

    function onEndDateChanged(newEndDate) {
        if (newEndDate && newEndDate.isValid()) {
            $sbUserInteraction.changeEndDate();
            vm.panelModel.durations.newDuration = _getLastValidDuration();
            const activity = currentModificationModel();
            try {
                applyNewEndDate(newEndDate, activity);
                _updateDates(activity);
                updateFormValidity();

                // work in progress activities will get their duration changed when specifying a new end date
                if (activity.state.isWorkInProgress()) {
                    $sbUserInteraction.changeDuration();
                    $sbUserInteraction.changeStartDate();
                    vm.panelModel.durations.lastCommittedDuration =
                        _stringToFloat(activity.duration);
                    vm.panelModel.durations.newDuration =
                        _getLastValidDuration();
                }
            } catch (err) {
                updateFormValidity(err);
            }
        }
    }

    function updateFormValidity(err) {
        vm.activityForm.$setValidity(
            "endbeforestart",
            !(err && err instanceof EndBeforeStartError)
        );
    }

    function isLookAheadChangeEnabled() {
        return vm.panelModel.isEditable && vm.hasOpenSession();
    }

    function _getLastValidDuration() {
        const lastCommittedDuration = _.get(
            vm.panelModel,
            "durations.lastCommittedDuration"
        );
        const lastPlannedDuration = _.get(
            vm.panelModel,
            "durations.lastPlannedDuration"
        );
        const plannedDuration = _.get(
            vm.panelModel,
            "durations.plannedDuration"
        );

        if (lastCommittedDuration) {
            return lastCommittedDuration;
        } else if (lastPlannedDuration) {
            return lastPlannedDuration;
        } else {
            return plannedDuration;
        }
    }

    function _updateDates({ startDate, endDate }) {
        vm.panelModel.dates.startDate = startDate;
        vm.panelModel.dates.endDate = endDate;
        vm.panelModel.dates.lastCommittedStartDate = startDate.clone();
        vm.panelModel.dates.lastCommittedEndDate = endDate.clone();

        if (startDate) {
            vm.panelModel.dates.newStartDate =
                vm.panelModel.dates.lastCommittedStartDate;
        }

        if (endDate) {
            vm.panelModel.dates.newEndDate =
                vm.panelModel.dates.lastCommittedEndDate;
        }
    }

    function _toPanelViewModel(activity) {
        const dateConfigForActuals = {
            translationKeyIfNotSet: null,
        };

        return {
            id: activity.id,
            state: activity.state,
            icon: _asStateIconFilter(activity.state.current),
            badges: {
                infos: activity.noteStatistic.info,
                claims: activity.noteStatistic.openClaims,
                obstructions: activity.noteStatistic.openObstructions,
            },
            texts: {
                name: activity.name,
                assignedTeamName: activity.workTeam.getDisplayName(),
                confirmationTeamName: _mapConfirmationTeamName(activity),
                reviewTeamsNames: _mapReviewTeam(activity),
                baselineStartDate: _getDateRepresentation(
                    activity.schedule.baseline.start
                ),
                baselineDueDate: _getDateRepresentation(
                    activity.schedule.baseline.end
                ),
                lastCommittedStartDate: _getDateRepresentation(
                    activity.schedule.sessionLookAhead.start
                ),
                startDate: _getDateRepresentation(activity.startDate),
                endDate: _getDateRepresentation(activity.endDate),
                lastCommittedEndDate: _getDateRepresentation(
                    activity.schedule.sessionLookAhead.end
                ),
                forecastLastPlannedStartDate: _getDateRepresentation(
                    activity.schedule.forecast.start
                ),
                forecastLastPlannedEndDate: _getDateRepresentation(
                    activity.schedule.forecast.end
                ),
                lastPlannedChangeCount: activity.lastPlannedChangeCount,
                actualStart: _getDateRepresentation(
                    activity.schedule.actual.started,
                    dateConfigForActuals
                ),
                actualFinished: _getDateRepresentation(
                    activity.schedule.actual.finished,
                    dateConfigForActuals
                ),
                actualConfirmed: _getDateRepresentation(
                    activity.schedule.actual.confirmed,
                    dateConfigForActuals
                ),
                startDateOrigin: activity.startDateOrigin,
                endDateOrigin: activity.endDateOrigin,
            },
            durations: {
                plannedDuration: activity.schedule.baseline.duration,
                plannedDurationUnit:
                    "_" + activity.schedule.baseline.durationUnit,
                lastCommittedDuration:
                    activity.schedule.sessionLookAhead.duration,
                lastPlannedDuration:
                    activity.schedule.publishedLookAhead.duration,
                displayedDuration: activity.displayedDuration.value,
                displayedDurationUnit: "_" + activity.displayedDuration.unit,
            },
            dates: {
                startDate: activity.startDate,
                endDate: activity.endDate,
                actualStart: activity.schedule.actual.started,
                baselineStartDate: activity.schedule.baseline.start,
                baselineDueDate: activity.schedule.baseline.end,
                lastCommittedStartDate:
                    activity.schedule.sessionLookAhead.start,
                lastCommittedEndDate: activity.schedule.sessionLookAhead.end,
                forecastLastPlannedStartDate: activity.schedule.forecast.start,
                forecastLastPlannedEndDate: activity.schedule.forecast.end,
            },
            timeline: {
                lastCheckedBy: _getLastCheckedByRepresentation(activity),
                lastCheckedOn: _getDateRepresentation(
                    activity.progressChangeTime,
                    {
                        translationKeyIfNotSet: "INFO_NO_INSPECTIONS",
                    }
                ),
            },
            plannedLabour: activity.plannedLabour,
            isBehind: activity.isBehind,
            isEditable: !activity.state.isCompleted(),
            isWorkInProgress: activity.state.isWorkInProgress(),
            hasActualDates:
                activity.schedule.actual.started ||
                activity.schedule.actual.finished ||
                activity.schedule.actual.confirmed,
            hasLookAheadDates:
                activity.schedule.sessionLookAhead.start ||
                activity.schedule.sessionLookAhead.end ||
                activity.schedule.sessionLookAhead.duration,
            hasBaselineDates:
                activity.schedule.baseline.start ||
                activity.schedule.baseline.end ||
                activity.schedule.baseline.duration,
            isForecastStartShown:
                !activity.state.isCompleted() &&
                vm.hasOpenSession() &&
                activity.isForecasted &&
                !activity.state.isWorkInProgress(),
            isForecastEndShown:
                !activity.state.isCompleted() &&
                vm.hasOpenSession() &&
                activity.isForecasted &&
                activity.state.isWorkInProgress(),
            hasChangedInCurrentSession:
                activity.schedule.hasChangedInCurrentSession(),
        };
    }

    function _getDateRepresentation(
        dateAsMoment,
        { translationKeyIfNotSet = "_UNSET", format = "L" } = {}
    ) {
        if (dateAsMoment && vm.calendar && vm.calendar.TIMEZONE) {
            var displayableMoment = moment.tz(
                parseFloat(dateAsMoment.valueOf()),
                vm.calendar.tz()
            );
            return displayableMoment.isValid()
                ? displayableMoment.format(format)
                : _translate(translationKeyIfNotSet);
        }
        return _translate(translationKeyIfNotSet);
    }

    function _getLastCheckedByRepresentation(activity) {
        var lastCheckedBy = _.get(activity, "progressChangeAuthor.displayName");
        return lastCheckedBy || _translate("INFO_NO_INSPECTIONS");
    }

    function _mapConfirmationTeamName(activity) {
        if (!activity.confirmationTeam) {
            return "ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED";
        }

        const teamName = activity.confirmationTeam.getDisplayName();

        if (teamName === "_UNRESTRICTED_TEAM") {
            return "ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED";
        }

        return teamName;
    }

    function _mapReviewTeam(activity) {
        return (activity.reviewTeams || [])
            .map((responsibleTeam) => responsibleTeam.getDisplayName())
            .sort();
    }

    function _transformToApiRequest(panelModel) {
        var duration = null;
        if ($sbUserInteraction.contains(USER_INTERACTION.DURATION)) {
            duration =
                panelModel.durations.lastCommittedDuration ||
                panelModel.durations.lastPlannedDuration;
        }

        return {
            id: panelModel.id,
            startDate: panelModel.dates.startDate,
            endDate: panelModel.dates.endDate,
            lastPlannedDuration: duration,
        };
    }

    function _stringToFloat(value) {
        var number = parseFloat(value);
        return isNaN(number) ? undefined : number;
    }

    function onCommitForecast() {
        $sbTracking.leanBoard.activity.onCommitForecastedDate();
        if (vm.panelModel.isWorkInProgress) {
            onEndDateChanged(
                moment(Number(vm.panelModel.dates.forecastLastPlannedEndDate))
            );
        } else {
            onStartDateChanged(
                moment(Number(vm.panelModel.dates.forecastLastPlannedStartDate))
            );
        }
    }

    function onStartSession() {
        vm.isStartingSession = true;
        vm.onCreateNewSession().then(() =>
            $sbActivityDetailsPanelCommonService.repositionPanel(
                vm.panelModel.id,
                mdPanelRef
            )
        );
    }

    function toggleIsDetailsShowing() {
        vm.isDetailsShowing = !vm.isDetailsShowing;
        if (vm.isDetailsShowing) {
            $sbTracking.leanBoard.activity.onShowActivityDetails();
        } else {
            $sbTracking.leanBoard.activity.onHideActivityDetails();
        }

        $sbActivityDetailsPanelCommonService.repositionPanel(
            vm.panelModel.id,
            mdPanelRef
        );
    }
}
