/**
 * Class to maintain the sort state for a list of things.
 *
 * In the current version:
 *  # you can set and change the sort property
 *  # you can set and switch the direction
 *
 *  TODO: Add multi sort. -> primary sort criteria, secondary, third ..
 *
 */

/**
 * ADD multi sort later
 */

function ViewSortModel(property, direction) {
    this._sortProperty = property;
    this._sortDirection = !!direction;
}

/**
 * Set or Get
 * @param {String} [newValue] - the new value or nothing
 * @returns {String|ViewSortModel} - as getter a string as setter this
 */
ViewSortModel.prototype.sortProperty = function (newValue) {
    if (arguments.length > 0) {
        this._sortProperty = newValue;
        return this;
    } else {
        return this._sortProperty;
    }
};

/**
 * Set or Get (true is asc)
 * @param {boolean} [newValue] - the new value or nothing
 * @returns {boolean|ViewSortModel} - as getter a boolean as setter this
 */
ViewSortModel.prototype.sortDirection = function (newValue) {
    if (arguments.length > 0) {
        this._sortDirection = newValue;
        return this;
    } else {
        return this._sortDirection;
    }
};

/**
 * Change the sort direction of the current property.
 *
 * @returns {boolean} - the new sort direction flag
 */
ViewSortModel.prototype.changeDirection = function () {
    this._sortDirection = !this._sortDirection;
    return this.sortDirection();
};

/**
 * Change the sorting to the given property or (if it is already the sorting property) change the direction.
 *
 * @param {String} property - the new sorting property
 */
ViewSortModel.prototype.changeTo = function (property) {
    if (this.sortProperty() === property) {
        this.changeDirection();
    } else {
        this.sortProperty(property).sortDirection(true);
    }
};

/**
 * Convert the boolean direction to a string direction
 *
 * @returns {string} - asc or desc corresponding to the direction
 */
ViewSortModel.prototype.sortDirectionAsString = function () {
    return this.sortDirection() ? "asc" : "desc";
};

export default ViewSortModel;
