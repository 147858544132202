import moment from "moment";
const _ = require("lodash");

function LeanboardSession(id) {
    this.id = id;

    this.openedBy = {
        id: "",
        displayName: "",
        initials: "",
        userName: "",
    };

    this.openedAt = undefined;

    this.closedAt = undefined;
}

LeanboardSession.createFromServerObject = function (serverObject) {
    var leanboardSession = new LeanboardSession(serverObject.id);

    if (serverObject.opened) {
        leanboardSession.openedBy.id = _.get(serverObject, "opened.by.id");
        leanboardSession.openedBy.displayName = _.get(
            serverObject,
            "opened.by.name"
        );
        leanboardSession.openedBy.initials = _.get(
            serverObject,
            "opened.by.initials"
        );
        leanboardSession.openedBy.userName = _.get(
            serverObject,
            "opened.by.id"
        );
    }

    if (_.has(serverObject, "opened.at")) {
        leanboardSession.openedAt = moment(_.get(serverObject, "opened.at"));
    }

    if (_.has(serverObject, "closed_at")) {
        leanboardSession.closedAt = moment(_.get(serverObject, "closed_At"));
    }

    return leanboardSession;
};

LeanboardSession.prototype.isOpenFor = function (user) {
    return this.isStarted() && this.isUserSessionOwner(user) && !this.isEnded();
};

LeanboardSession.prototype.isStarted = function () {
    return !!this.openedAt;
};

LeanboardSession.prototype.isEnded = function () {
    return !!this.closedAt;
};

LeanboardSession.prototype.isUserSessionOwner = function (user) {
    return this.openedBy.id === user.userName;
};

export default LeanboardSession;
