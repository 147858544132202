import _ from "lodash";

export default function EditCostGroupMembershipCtrl(
    $mdDialog,
    $sbTeam,
    $sbProject,
    $sbUserSuggestion,
    $sbTracking,
    $sbMembership,
    allowSingleManagerOnly
) {
    "ngInject";
    var vm = this;

    var _pristineManagerList;
    var _pristineReviewerList;

    vm.canRemoveManagers = false;

    vm.allUsersInProject = [];
    vm.selectedManagers = [];
    vm.selectedReviewers = [];

    vm.suggestUsersFrom = suggestUsersFrom;
    vm.submit = submit;
    vm.onManagerAdded = onManagerAdded;
    vm.onManagerRemoved = onManagerRemoved;
    vm.onReviewerAdded = onReviewerAdded;
    vm.onReviewerRemoved = onReviewerRemoved;

    vm.allowSingleManagerOnly = allowSingleManagerOnly;
    vm.closeDialog = $mdDialog.cancel;

    vm.showError = isSingleManagerViolationErrorDisplayed;

    activate();

    function activate() {
        $sbTeam
            .getUsers($sbProject.getCurrentProjectId())
            .then(_setUsers)
            .then(_deriveStateFromCurrentMembership)
            .then(_setPristineState)
            .then(_updateCanRemoveManagers);
    }

    function _setUsers(users) {
        vm.allUsersInProject = users.filter(({ removedAt }) => !removedAt);
    }

    function _deriveStateFromCurrentMembership() {
        _findAndSetUsers(vm.currentManagers, vm.selectedManagers);
        _findAndSetUsers(vm.currentReviewers, vm.selectedReviewers);
    }

    function _setPristineState() {
        _pristineManagerList = _.clone(vm.selectedManagers);
        _pristineReviewerList = _.clone(vm.selectedReviewers);
    }

    function _findAndSetUsers(dbNames, targetList) {
        _.forEach(dbNames, function dbNameToUser(dbName) {
            var user = _.find(vm.allUsersInProject, ["username", dbName]);
            if (user) {
                targetList.push(user);
            }
        });
    }

    function suggestUsersFrom(searchText) {
        return $sbUserSuggestion.suggestUsersFrom(
            searchText,
            vm.allUsersInProject,
            vm.selectedManagers.concat(vm.selectedReviewers)
        );
    }

    function submit() {
        $mdDialog.hide({
            hasRemovedSelf: _hasUserRemovedSelf(),
            selectedManagers: vm.selectedManagers,
            selectedReviewers: vm.selectedReviewers,
        });
    }

    function _hasUserRemovedSelf() {
        var currentUserName = $sbMembership.currentUser().userName;

        var wasManager = _.find(_pristineManagerList, [
            "username",
            currentUserName,
        ]);
        var isNowManager = _.find(vm.selectedManagers, [
            "username",
            currentUserName,
        ]);

        var wasReviewer = _.find(_pristineReviewerList, [
            "username",
            currentUserName,
        ]);
        var isNowReviewer = _.find(vm.selectedReviewers, [
            "username",
            currentUserName,
        ]);

        return (wasManager || wasReviewer) && !isNowManager && !isNowReviewer;
    }

    function onManagerAdded() {
        _updateCanRemoveManagers();

        $sbTracking.commercial.onMembershipEdit("Commercial Manager added");
    }

    function onManagerRemoved() {
        _updateCanRemoveManagers();

        $sbTracking.commercial.onMembershipEdit("Commercial Manager removed");
    }

    function onReviewerAdded() {
        $sbTracking.commercial.onMembershipEdit("Commercial Reviewer added");
    }

    function onReviewerRemoved() {
        $sbTracking.commercial.onMembershipEdit("Commercial Reviewer removed");
    }

    function _updateCanRemoveManagers() {
        var hasMoreThanOneSelected = vm.selectedManagers.length > 1;
        var hadNoneToBeginWith = _pristineManagerList.length === 0;

        vm.canRemoveManagers = hasMoreThanOneSelected || hadNoneToBeginWith;
    }

    function isSingleManagerViolationErrorDisplayed() {
        return vm.allowSingleManagerOnly && vm.selectedManagers.length > 1;
    }
}
