import angular from "angular";
import ViewSortModel from "common/plainModels/ViewSortModel";
import ViewSelectionProvider from "common/plainModels/ViewSelectionProvider";

export default function NoteTemplatesCtrl(
    EVENTS,
    $scope,
    sbConfigCard,
    $sbErrorPresenter,
    $mdToast,
    $mdDialog,
    $sbCodeRecommendation,
    $q,
    $interval,
    $mdMedia,
    $document,
    $timeout,
    $state,
    NoteTemplates,
    noteTemplatesService,
    loadingToast
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /**
     * Holds the sort state
     * @type {ViewSortModel}
     */
    var viewSortModel = new ViewSortModel("CREATION_DATE", false);

    /**
     * Holds the selection state
     * @type {ViewSelectionProvider}
     */
    var selectionProvider = new ViewSelectionProvider();

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    /**
     * Holds the selection state
     * @type {ViewSelectionProvider}
     */
    vm.selectionProvider = selectionProvider;

    /**
     * The List of elements to be displayed.
     * @type {NoteTemplates}
     */
    vm.noteTemplates = undefined;

    vm.dataState = {};

    /**
     * A text representing the global search state
     * @type {string}
     */
    vm.searchQuery = "";

    /**
     * Determine the display mode to manage loading, empty hint and list states
     * @type {string}
     */
    vm.displayMode = "LOADING";

    /**
     * Change the sorting of the list
     * @type {function}
     */
    vm.onHeaderClick = changeListSorting;

    /**
     * Merge card changes to the list model.
     * @type {function}
     */
    vm.onCardDataSave = onEditNotTemplate;

    /**
     * Get a css class to add an item indication to a header button
     * @type {function}
     */
    vm.getSortIndication = getSortIndication;

    /**
     * Trigger on checkboxes per row
     * @type {function}
     */
    vm.selectRow = addOrRemoveRowSelection;

    /**
     * Trigger the deletion of all elements in the selection model.
     * @type {function}
     */
    vm.deleteSelectedRows = deleteSelectedRows;

    /**
     * Check if there is at least one element selected.
     * @return {boolean} - True if one is selected or if the user uses a phone or a tablet
     */
    vm.isSelectionActive = function () {
        return selectionProvider.selections().length > 0;
    };

    /**
     * Check if an element is selected.
     * @param {Object} row - the oData like object
     * @returns {boolean} - true if element is selected
     */
    vm.isRowSelected = function (row) {
        return selectionProvider.isSelected(row);
    };

    /**
     * Open the dialog for adding a template
     * @type {function}
     */
    vm.onOpenDialogClicked = openNoteTemplateCreationDialog;

    /**
     * Switch between select all and clear selection
     * @type {function}
     */
    vm.toggleSelectAll = toggleSelectAll;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /**
     * Watch for changes in data and show the right message
     */
    var watchForNoteTemplateChanges = $scope.$watch(
        "noteTemplates.noteTemplates._length",
        updateListMode
    );
    var watchForNoteTemplateChangesLoading = $scope.$watch(
        "noteTemplates.noteTemplates.isLoading",
        updateListMode
    );

    /**
     * Watch for state param related changes only
     */
    var sbConfigCardChangeListener = $scope.$watch(function () {
        return $state.params;
    }, refreshNoteTemplates);

    /**
     * In case window is resized, reset the object
     *
     * resizing is a difficult topic. Our current version of angular-material doesn't support proper resizing
     * of the virtual repeater. But they want to implement it on 1.0.0 release. For now I destroy the noteTemplates
     * reference completely. In the HTML there is a ng-if destorying the virtual repeat if noteTemplates is falsy.
     * After refreshing the noteNoteTemplates they are valid again and the virtual repeater is recreated with
     * proper height.
     *          -> Solved :D
     */
    var windowResizeListener = $scope.$on(
        "sb.global.window.resized",
        function () {
            vm.noteTemplates = null;
            $timeout(function () {
                refreshNoteTemplates();
            }, 0);
        }
    );

    /**
     * Global search service receiver
     */
    var searchListener = $scope.$on(
        EVENTS.GLOBAL_SEARCH_CHANGE,
        function (event, eventData) {
            vm.searchQuery = eventData.searchTerm;
            refreshNoteTemplates();
        }
    );

    $scope.$on("$destroy", function () {
        searchListener();
        windowResizeListener();
        sbConfigCardChangeListener();
        sbConfigCard.destroy();
        watchForNoteTemplateChanges();
        watchForNoteTemplateChangesLoading();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Super heavy complete refresh call -> collect the filter and the sorting state and rebuild the table content.
     */
    function refreshNoteTemplates() {
        setDisplayMode("LOADING");

        selectionProvider.clearSelection();

        // build the filters from URL and serach query.
        // set the order query from the sortProvider.
        if (vm.noteTemplates) {
            vm.noteTemplates
                .filter(_getFilterAndSearchCondition())
                .order(_getODataOrderString())
                .reload();
        } else {
            vm.noteTemplates = new NoteTemplates(
                _getFilterAndSearchCondition(),
                _getODataOrderString()
            );
        }
    }

    /**
     * Build and return a order string based on the view properties.
     *
     * @returns {string} - a string that represents the sorting state of the view (in OData format)
     * @private
     */
    function _getODataOrderString() {
        return (
            viewSortModel.sortProperty() +
            " " +
            viewSortModel.sortDirectionAsString()
        );
    }

    /**
     * Build and return a filter string based on the view properties.
     *
     * @returns {string} - a string that represents the filter state of the view (in OData format)
     * @private
     */
    function _getFilterAndSearchCondition() {
        //Remove the project id from recieved params
        //Took value instead of reference
        const stateParams = angular.copy($state.params);
        delete stateParams.projectId;

        const lowercaseSearchTerm = formatSearchTerm(vm.searchQuery);

        // check if both strings are valid and concat them with AND -> result is the final query.
        //
        return Object.assign({}, stateParams, {
            SEARCH_TERM: lowercaseSearchTerm,
        });
    }

    /**
     * Change the displayed page content.
     *
     *  - LOADING
     *  - LIST
     *  - EMPTY_HINT
     *  - LIST_FILTERED_EMPTY
     *
     * @param {String} mode - The mode you want to state as active
     */
    function setDisplayMode(mode) {
        if (
            ["LOADING", "LIST", "EMPTY_HINT", "LIST_FILTERED_EMPTY"].indexOf(
                mode.toUpperCase()
            ) > -1
        ) {
            vm.displayMode = mode.toUpperCase();

            if (mode === "LOADING") {
                vm.dataState.isNormal = false;
                vm.dataState.isLoading = true;
            }
            if (mode === "EMPTY_HINT") {
                vm.dataState.isNormal = false;
                vm.dataState.isEmpty = true;
                vm.dataState.isLoading = false;
                vm.dataState.isFiltered = false;
            }
            if (mode === "LIST") {
                vm.dataState.isNormal = true;
                vm.dataState.isLoading = false;
                vm.dataState.isFiltered = false;
                vm.dataState.isEmpty = false;
            }
            if (mode === "LIST_FILTERED_EMPTY") {
                vm.dataState.isNormal = false;
                vm.dataState.isFiltered = true;
                vm.dataState.isEmpty = true;
                vm.dataState.isLoading = false;
            }
        }
    }

    /**
     * Set the List mode dependent on the number of items that are displayed.
     * @param {Number} val - number of items.
     */
    function updateListMode() {
        if (!vm.noteTemplates) {
            return;
        }
        var val = vm.noteTemplates.getLength();

        if (vm.noteTemplates.isLoading) {
            setDisplayMode("LOADING");
        } else {
            // at least one request (either count or fetch should be finished)
            // and so we know that there should be a length.
            if (val > 0) {
                setDisplayMode("LIST");
            } else {
                // we can determine the note templates state here. But we know there are no elements in the result
                if (
                    Object.values(vm.noteTemplates.filter()).some(
                        (value) => !!value
                    )
                ) {
                    setDisplayMode("LIST_FILTERED_EMPTY");
                } else {
                    setDisplayMode("EMPTY_HINT");
                }
            }
        }
    }

    function changeListSorting(typeKey) {
        viewSortModel.changeTo(typeKey);
        refreshNoteTemplates();
    }

    function getSortIndication(typeKey) {
        if (viewSortModel.sortProperty() === typeKey) {
            return viewSortModel.sortDirection()
                ? "mdi mdi-sort-ascending"
                : "mdi mdi-sort-descending";
        } else {
            return "";
        }
    }

    /**
     * Function called by the checkbox. Its only purpose it's to avoid the propagation so that the row doesn't open
     * @param  {Object} $event - The event called by the checkbox
     * @param {Object} row - Selected row object
     */
    function addOrRemoveRowSelection($event, row) {
        selectionProvider.selectionChange(row);
        $event.stopPropagation();
    }

    /**
     * Ask the user if he agrees
     *  -> trigger the delete on the server
     *      -> remove the items from the list
     *          -> refresh the card
     */
    function deleteSelectedRows() {
        // the ODATA like plain objects
        var toBeDeleted;
        if (vm.selectionProvider.isSelectAllActive()) {
            toBeDeleted = vm.noteTemplates._items;
        } else {
            toBeDeleted = vm.selectionProvider.selections();
        }

        // start with the dialog
        $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .content("CONFIRM_DELETE_NOTE_TEMPLATE_MESSAGE")
                    .contentValues({
                        value: toBeDeleted.length,
                    })
            )
            .then(function () {
                // loading indication
                loadingToast.show("INFO_NOTE_TEMPLATE_LOADING_REMOVE_MESSAGE");

                // trigger the deletion on the server
                noteTemplatesService
                    .deleteNotes(toBeDeleted)
                    .then(function (responses) {
                        // Collect all KEYS of elements that are successfully deleted.
                        //
                        var reallyDeleted = [];
                        responses.forEach(function (elem, index) {
                            if (elem.status === "fulfilled") {
                                reallyDeleted.push(toBeDeleted[index]);
                            }
                        });

                        //Delete from the view -> filter all elements with an KEY in the deleted key list
                        removeTemplatesFromRow(reallyDeleted);
                        sbConfigCard.update();

                        // show success toast
                        $mdToast.show(
                            $mdToast
                                .simple()
                                .content("INFO_NOTE_TEMPLATE_REMOVE_MESSAGE")
                                .contentValues({
                                    success: reallyDeleted.length,
                                    errors:
                                        toBeDeleted.length -
                                        reallyDeleted.length,
                                })
                                .position("top right")
                        );
                    });
            });
    }

    /**
     * Create and open the dialog for template creation.
     * @param {object} $event - event object once dialog is clicked
     */
    function openNoteTemplateCreationDialog($event) {
        $mdDialog
            .show(createNoteTemplateDialog($event)) // prepare dialog
            .then(function handleDialogInput(dialogResponse) {
                return createNoteTemplates(
                    dialogResponse.newNote,
                    dialogResponse.prevCreated
                )
                    .then(addTemplatesToRows)
                    .catch($sbErrorPresenter.catch);
            })
            .catch(function handleDialogAbort(allCreatedTemplates) {
                //if the dialog is closed by escape or click on the backdrop
                //
                if (
                    angular.isUndefined(allCreatedTemplates) ||
                    allCreatedTemplates.length === 0
                ) {
                    return [];
                }

                // add eventually previously created to rows
                //
                return addTemplatesToRows(allCreatedTemplates);
            });
    }

    /**
     * Add created templates to rows for displaying in list.
     *
     * @param {Array.<Object>} allCreatedTemplates - A list of created templates.
     *
     * @return {Array.<Object>} Passes "allCreatedTemplates" through.
     */
    function addTemplatesToRows(allCreatedTemplates) {
        viewSortModel.sortDirection(false).sortProperty("CREATION_DATE");
        sbConfigCard.update();
        refreshNoteTemplates();
        return allCreatedTemplates;
    }

    /**
     * Remove some elements from the view list (for example after deletion)
     *
     * @param {Array<Object>} objectList - All KEYS of elements that should be removed from the visible list
     */
    function removeTemplatesFromRow(objectList) {
        objectList.forEach(function (object) {
            selectionProvider.deselect(object);
        });
        refreshNoteTemplates();
    }

    /**
     * Makes a server call to create a new template by given input.
     * Also manages previously created templates and adds newly created to them.
     *
     * @param {Object} newNoteTmpl - Input for PUT/OPST request to server.
     * @param {Array.<Object>} previouslyCreatedTmpl - All successful previously created templates.
     *
     * @return {Array.<Object>} All created templates as array.
     */
    function createNoteTemplates(newNoteTmpl, previouslyCreatedTmpl) {
        //Set current project id before calling service method
        return noteTemplatesService
            .createNote(newNoteTmpl)
            .then(function showCreationSuccessToast(createdNote) {
                $mdToast.show(
                    $mdToast
                        .simple()
                        .content("INFO_TEMPLATE_ADD_SUCCESS_TITLE")
                        .position("top right")
                );
                return createdNote;
            })
            .then(function handleMultiCreation(createdNote) {
                if (!previouslyCreatedTmpl) {
                    return [createdNote];
                }
                return [createdNote].concat(previouslyCreatedTmpl);
            });
    }

    /**
     * Prepare the note templates creation dialog for displaying.
     * Calculates the note template code from actual codes and last created code.
     *
     * The event is needed for correct open and close animation of dialog.
     *
     * @param {object} $event - Event called by dialog
     * @return {*}
     */
    function createNoteTemplateDialog($event) {
        var lastCreatedCodes = noteTemplatesService.getLastCreatedCodesByTypes(
            vm.noteTemplates._items
        );

        return $mdDialog
            .createNoteTemplate()
            .targetEvent($event)
            .lastCreatedCodes(lastCreatedCodes)
            .clickOutsideToClose(false)
            .escapeToClose(false);
    }

    /**
     * Handle the card was saved case. Merge to list.
     * @param {Object} template - the origin
     * @param {Object} edited - the dirty state
     * @returns {$q} - the edit promise
     */
    function onEditNotTemplate(template, edited) {
        return noteTemplatesService
            .editNote(edited)
            .then(function () {
                template.CODE = edited.CODE;
                template.TYPE = edited.TYPE;
                template.TEXT = edited.TEXT;
            })
            .catch($sbErrorPresenter.catch);
    }

    /**
     * format given search term into query
     * @param  {string} searchTerm search term
     * @return {string} oData query
     */
    function formatSearchTerm(searchTerm) {
        return angular.isString(searchTerm) && searchTerm.length > 0
            ? searchTerm.toLowerCase()
            : undefined;
    }

    function toggleSelectAll() {
        if (
            vm.selectionProvider.isSelectAllActive() ||
            vm.selectionProvider.hasActiveSelections()
        ) {
            vm.selectionProvider.clearSelection();
        } else {
            vm.selectionProvider.selectAll();
        }
    }
}
