import RootContainer from "./RootContainer";
import NodeContainer from "./NodeContainer";
import HorizontalBranch from "../edge/HorizontalBranch";

/**
 * @param {WBS.StructureNode} node - Node displayed in Container
 * @constructs ProjectContainer
 * @extends WBS.NodeContainer
 * @memberof WBS
 */
function ProjectContainer(node) {
    NodeContainer.call(this, node, HorizontalBranch);
}

ProjectContainer.prototype = Object.create(NodeContainer.prototype);

/**
 *
 * @type {boolean}
 */
ProjectContainer.prototype.checkIsOnStage = false;

/**
 * positions the node you want to add first and then add it to this node
 * @param {WBS.RootContainer} element the node you want to add
 */
ProjectContainer.prototype.addChild = function (element) {
    if (element instanceof RootContainer) {
        NodeContainer.prototype.addChild.call(this, element);
    } else {
        NodeContainer.prototype.addChild.call(this, element);
    }
};

/**
 * Creates a Child of Node that is displayed in Container
 * @param {WBS.StructureNode} node - New Child Node
 */
ProjectContainer.prototype.createChild = function (node) {
    var containerElement = new RootContainer(node);

    node.entity.setGeometry(containerElement);
    this.addChild(containerElement);
};

/**
 * recalculates the layout
 */
ProjectContainer.prototype.refreshLayout = function () {
    var lastNodeContainer;
    var nodeBounds = this.node.getBounds();

    for (var i = 0; i < this.children.length; i++) {
        var child = this.children[i];
        if (child instanceof NodeContainer) {
            if (lastNodeContainer) {
                var lastChildBoundingBox = lastNodeContainer.getBounds();

                child.y = child.margin.y;
                child.x =
                    lastNodeContainer.x +
                    lastChildBoundingBox.width +
                    child.margin.x;
            } else {
                child.y = child.margin.y;
                child.x = nodeBounds.width - 200;
            }

            lastNodeContainer = child;
        }
    }

    this.branch.calcBranch();
};

export default ProjectContainer;
