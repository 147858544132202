export default function LocaleService(
    amMoment,
    $window,
    $locale,
    $translate,
    $sbExternalService
) {
    "ngInject";
    var CURRENCY_PATTERN_EN = {
        gSize: 3,
        lgSize: 3,
        maxFrac: 2,
        minFrac: 2,
        minInt: 1,
        negPre: "-\u00a4",
        negSuf: "",
        posPre: "\u00a4",
        posSuf: "",
    };

    var CURRENCY_PATTERN_DE = {
        gSize: 3,
        lgSize: 3,
        maxFrac: 2,
        minFrac: 2,
        minInt: 1,
        negPre: "",
        negSuf: "-\u00a4",
        posPre: "",
        posSuf: "\u00a4",
    };

    return {
        de_DE: "de",
        current: current,
        init: init,
        is: is,
        use: use,
        internationaliseNumber: internationaliseNumber,
    };

    /**
     * return the current locale
     *
     * @returns {string}
     */
    function current() {
        return $translate.use();
    }

    function is(languageCode) {
        return current() === languageCode;
    }

    function init() {
        var preferredLanguage = $translate.preferredLanguage();
        use(preferredLanguage);
    }

    function use(languageKey) {
        $translate.use(languageKey);

        // use british english instead of default american english
        //
        var isEnglishLocale = languageKey.toLowerCase() === "en";
        amMoment.changeLocale(isEnglishLocale ? "en-gb" : languageKey);

        $window.localStorage.setItem("languageKey", languageKey);
        $window.localStorage.setItem("i18nextLng", languageKey);
        $sbExternalService.translateServiceTriggerChange(languageKey);
        // set the angular internal number locale definition
        //
        if (isEnglishLocale) {
            // en
            $locale.NUMBER_FORMATS.GROUP_SEP = ",";
            $locale.NUMBER_FORMATS.DECIMAL_SEP = ".";
            $locale.NUMBER_FORMATS.PATTERNS[1] = CURRENCY_PATTERN_EN;
        } else {
            // de , es
            $locale.NUMBER_FORMATS.GROUP_SEP = ".";
            $locale.NUMBER_FORMATS.DECIMAL_SEP = ",";
            $locale.NUMBER_FORMATS.PATTERNS[1] = CURRENCY_PATTERN_DE;
        }
    }

    /**
     * Returns a string representation of the number formatted according to locale
     *
     * @param {number} number
     *
     * @returns {string}
     */
    function internationaliseNumber(number) {
        return number
            .toString()
            .replace(/,/g, $locale.NUMBER_FORMATS.GROUP_SEP)
            .replace(/\./g, $locale.NUMBER_FORMATS.DECIMAL_SEP);
    }
}
