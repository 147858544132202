import SbTeam from "../../../domain/sb_team.class";

function Membership(role, hasAreaManagerRestrictions) {
    this.role = role;
    this.hasAreaManagerRestrictions = hasAreaManagerRestrictions;
}

Membership.prototype.setUser = function (user) {
    this.user = user;
};

Membership.prototype.setTeam = function (team) {
    this.team = new SbTeam(team.id, team.name);
    this.team.setColor(team.color);
    this.team.setIsProjectTeam(team.isProjectTeam);
    this.team.projectId = team.projectId;
};

Membership.prototype.setCommercialAccess = function (commercialAccess) {
    this.commercialAccess = commercialAccess;
};

export default Membership;
