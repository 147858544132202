/**
 *  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
 *
 * Function for deciding the correct font color based on a given background.
 * Not sure how accurate it is when moving away from black and white fonts
 *
 * @param {String} bgColor - hex color as a string "#123456"
 * @param {String} lightColor
 * @param {String} darkColor
 *
 * @returns {string}
 */
export default function pickTextColorBasedOnBgColorW3C(
    bgColor,
    lightColor = "white",
    darkColor = "black"
) {
    if (!bgColor || bgColor.charAt(0) !== "#" || bgColor.length !== 7) {
        console.warn("Unsupported color format. Returning default dark color"); // eslint-disable-line
        return darkColor;
    }

    const color = bgColor.substring(1, 7);
    const r = Number.parseInt(color.substring(0, 2), 16); // hexToR
    const g = Number.parseInt(color.substring(2, 4), 16); // hexToG
    const b = Number.parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
}
