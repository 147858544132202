export default function ODataLoaderProvider() {
    var loaders = {};

    this.addResourceLoader = addResourceLoader;

    function addResourceLoader(resource, loader) {
        loaders[resource] = loader;
    }

    this.$get = function ODataLoaderService(
        DeliverablesLazyLoader,
        DeliverablesSimpleLazyLoader,
        DeliverableProcessLazyLoader,
        NoteTemplates,
        IssuesLazyLoader,
        InspectionsLazyLoader
    ) {
        "ngInject";
        addResourceLoader("DELIVERABLES", DeliverablesSimpleLazyLoader);
        addResourceLoader("DELIVERABLES_PROGRESS", DeliverablesLazyLoader);
        addResourceLoader("DELIVERABLE_PROCESS", DeliverableProcessLazyLoader);
        addResourceLoader("TEMPLATE", NoteTemplates);
        addResourceLoader("ISSUES", IssuesLazyLoader);
        addResourceLoader("INSPECTION", InspectionsLazyLoader);

        // real service
        //
        return {
            getLoaderByResource: getLoaderByResource,
        };

        function getLoaderByResource(resource) {
            return loaders[resource];
        }
    };
}
