import angular from "angular";
import htmlTemplate from "./odata_list.html";

/**
 * Directive to display a list of items connected to an odata resource.
 * You provide a template for a list item which will be used to generate
 * the list.
 *
 * The specified name in bind-to gives access to the data object bind
 * to one list item. Inside the given list item template you can access
 * it by this name. Default is 'item'.
 *
 * If the odata list is updated through its bindings the on-count-change
 * function is called with the updated number of currently displayed items.
 *
 * @example
 *     <sb-odata-list resource="DELIVERABLES_PROGRESS"
 *                    filter-factory="ctrl.filterFactory"
 *                    order-by="ctrl.orderBy"
 *                    on-count-change="ctrl.onCountChange(numOfFilteredItems)"
 *
 *                    bind-to="item">
 *
 *          <sb-deliverable-row
 *                  deliverable="item"
 *                  ng-click="deliverables.clickRow(item.ID)">
 *          </sb-deliverable-row>
 *
 *     </sb-odata-list>
 *
 */
export default function sbOdataListDirective(
    $compile,
    $templateCache,
    sbOdataLoader
) {
    "ngInject";
    return {
        restrict: "E",
        // scope: {}, // isolated scope
        bindToController: {
            // with props bound to controller
            onCountChange: "&?",
            onAllFetched: "&?",
            onFetch: "&?",
            resource: "=",
            filterFactory: "=",
            orderBy: "=?",
            noDataMessage: "@?",
            bindTo: "@?", // specify alternative binding name for template - default is item
        },
        controllerAs: "oDataList",
        controller: function sbOdataListCtrl($rootScope, EVENTS) {
            "ngInject";
            var vm = this;

            vm.noDataMessage = vm.noDataMessage || "INFO_NO_FILTERED_DATA";

            vm.updateLoader = updateLoader;
            vm.updateFilteredItemCounter = updateFilteredItemCounter;
            vm.getDeliverablesLoader = getResourceLoader;
            vm.setFetchAllHandler = setFetchAllHandler;
            vm.setOnLoadDataFunction = setOnLoadDataFunction;
            vm.clearAllFilters = clearAllFilters;

            function clearAllFilters() {
                $rootScope.$broadcast(EVENTS.ODATA_LIST__FILTERS_CLEAR);
            }

            function updateFilteredItemCounter(loader) {
                return loader.countReady(function (loader) {
                    if (vm.onCountChange) {
                        vm.onCountChange({
                            numOfFilteredItems: loader.getLength(),
                        });
                    }
                });
            }

            function setFetchAllHandler(loader) {
                return loader.allFetched(function (allItems) {
                    if (vm.onAllFetched) {
                        vm.onAllFetched({
                            allItems: allItems,
                        });
                    }
                });
            }

            function setOnLoadDataFunction(loader) {
                return loader.onLoadData(function (chunkOfItems) {
                    if (vm.onFetch) {
                        vm.onFetch({
                            items: chunkOfItems,
                        });
                    }
                });
            }

            function updateLoader(resource, filterFactory, orderBy) {
                if (resource) {
                    const Loader = getResourceLoader(resource);
                    return new Loader(filterFactory, orderBy);
                }
            }

            function getResourceLoader(resource) {
                return sbOdataLoader.getLoaderByResource(resource);
            }
        },
        /**
         * Here we manipulate the input HTML and merge it with the directives template.
         * We inject the row template HTML into the virtual repeater and prepare everything
         * for the template to be compiled in the link function.
         *
         * @param tElement - HTML of directive defined outside by user.
         *
         * @returns {Function} linking function of angular
         */
        compile: function compile(tElement, tAttr) {
            var children = tElement.children(); // inner HTML of sb-odata-list tag
            var template = angular.element($templateCache.get(htmlTemplate)); // make HTML template string into usable

            // get injection point and inject outer HTML into template
            var rowContainer = angular.element(
                template[0].querySelector("#sb-template-container")
            );
            rowContainer.append(children);

            if (tAttr.bindTo) {
                // name of bound scope value for virtual-repeat item
                rowContainer.attr(
                    "md-virtual-repeat",
                    tAttr.bindTo + " in oDataList.loader"
                );
            }

            tElement.empty(); // clean up template element
            return function link(scope, element, attrs, ctrl) {
                var linkFn = $compile(template);
                var content = linkFn(scope); // connect scope to template -> makes all the bindings work
                element.append(content); // put it back into DOM

                scope.$watchGroup(
                    [
                        "oDataList.resource",
                        "oDataList.filterFactory",
                        "oDataList.orderBy",
                    ],
                    function (newBindings) {
                        var resource = newBindings[0],
                            filterFactory = newBindings[1],
                            orderBy = newBindings[2];

                        if (resource && filterFactory) {
                            ctrl.loader = ctrl.updateLoader(
                                resource,
                                filterFactory,
                                orderBy
                            );
                            ctrl.updateFilteredItemCounter(ctrl.loader);
                            ctrl.setFetchAllHandler(ctrl.loader);
                            ctrl.setOnLoadDataFunction(ctrl.loader);
                        }
                    }
                );
            };
        },
    };
}
