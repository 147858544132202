import Project from "./sb_project.class";
import _ from "lodash";
import moment from "moment";

export class ProjectSource {
    constructor(id, type, data) {
        this.id = id;
        this.type = type;
        this.data = data;
        this.events = [];
        this.usage = {
            active: 0,
            removed: 0,
        };

        this.project = undefined;
    }

    setProject(project) {
        if (project instanceof Project) {
            this.project = project;
        } else {
            throw new TypeError(
                'IllegalArgumentException: The argument is not of type "SbProject".'
            );
        }
    }

    addEvents(events) {
        this.events = this.events.concat(events);
    }

    firstEvent(type) {
        return _.head(_.filter(this.events, ["type", type]));
    }

    lastEvent(type) {
        return _.last(_.filter(this.events, ["type", type]));
    }

    setUsage(usage) {
        this.usage = usage;
    }
}

ProjectSource.TYPES = {
    USER: "USER",
    EXCEL: "EXCEL",
    MSP: "MSP",
    ASTA: "ASTA",
    PRIMAVERA: "PRIMAVERA",
};

export class ProjectSourceEvent {
    constructor(type, time, user) {
        this.type = type;
        this.at = moment(time);
        this.by = user;
    }
}

ProjectSourceEvent.TYPES = {
    IMPORT: "IMPORT",
    UPDATE: "UPDATE",
};
