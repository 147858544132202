import angular from "angular";
import htmlTemplate from "./sb_user_badge.html";

export default angular
    .module("sbApp.sbUserBadge", [])
    .directive("sbUserBadge", function () {
        "ngInject";
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "userBadge",
            templateUrl: htmlTemplate,
            replace: true,
            scope: {
                role: "=",
                user: "=",

                // if it has edit functionality, we provide a callback,
                // tooltip text and a flag
                onEditClick: "&?",
                editTooltip: "@",
                customMdiIcon: "@",
                isEditAllowed: "=",

                // if it's our current user badge, to listen to role changes
                isCurrentUser: "=",
            },
            controller: angular.noop,
        };
    });
