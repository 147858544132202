import angular from "angular";
import htmlTemplate from "./sb_tile.html";

/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbTile", [])
    .directive("sbTile", function sbTileDirective() {
        return {
            restrict: "E",
            templateUrl: htmlTemplate, //HTML Template as String
            transclude: true,
            replace: true,
            link: function linkTile(
                scope,
                iElement,
                iAttrs,
                controller,
                transcludeFn
            ) {
                // add class to tile if there's title and subtitle
                //
                var titleElement = iElement.find("h2");
                var subTitleElement = iElement.find("h3");

                if (
                    angular.isDefined(titleElement) &&
                    angular.isDefined(subTitleElement)
                ) {
                    iElement.addClass("sb-tile-just-text");
                }
            },
        };
    });
