import angular from "angular";
import ConstantsModule from "./../../constants/index";
import associateService from "./processTemplateAssignmentJobs/associate.service";
import reassociateService from "./processTemplateAssignmentJobs/reassociate.service";
import pullUpdateService from "./processTemplateAssignmentJobs/pull_update.service";
import bootstrapOverlayService from "./bootstrapOverlayService/bootstrapOverlayService";
import codeRecommendationService from "./codeRecommendationService/codeRecommendation.service";
import colorService from "./colorService/color.service";
import activityService from "./componentService/activity.service";
import deliverableService from "./componentService/deliverable.service";
import componentService from "./componentService/componentService";
import detailsOverlayService from "./detailsOverlayService/details_overlay.service";
import errorPresenterService from "./errorPresenterService/errorPresenterService";
import oDataFilterService from "./oDataService/odata_filter.service";
import oDataViewFilter from "./oDataService/odata_view_filter.service";
import oDataViewFilterStore from "./oDataService/odata_view_filter_store.service";
import projectService from "./projectService/projectService";
import projectApiService from "./projectService/project.api.service";
import calendarRepoService from "./projectService/calendar.repository.service";
import currentProjectService from "./projectService/project.current.service";
import issueService from "./issueService/issueService";
import issuesFilterService from "./issuesFilterService/issues_filter.service";
import templateService from "./templateService/template.service";
import requestSentryService from "./requestSentryService/request_sentry.service";
import requestService from "./api/network/requestService";
import siteInspectionsService from "./siteInspectionsService/site_inspections.service";
import teamService from "./teamService/team.service";
import teamDialogService from "./teamService/teams_dialog.service";
import structureService from "./structureService/structure.service";
import xmlService from "./xmlService/xml.service";
import parseXmlService from "./parseXmlService/parseXmlService";
import parseExcelService from "./parseExcelService/parse_excel.service";
import fileReaderService from "./fileReaderService/fileReaderService";
import primaveraService from "./primaveraService/primaveraService";
import mspService from "./mspService/mspService";
import permissionService from "./permissionService/permissions";
import schedulingService from "./schedulingService/scheduling.service";
import calendarService from "./schedulingService/calendar.service";
import SimpleGraph from "./schedulingService/simple_graph.model";
import GraphNode from "./schedulingService/graph_node.model";
import apiConstants from "./apiConstants/api";
import timezoneService from "./timezoneService/timezone.service";
import imagesOverlayService from "./imagesOverlayService/images_overlay.service";
import inspectionRequestService from "./inspectionRequest/inspection_request.service";
import DeliverablesLazyLoader from "./lazyLoading/deliverables_lazy_loader.service";
import DeliverablesSimpleLazyLoader from "./lazyLoading/deliverables_simple_lazy_loader.service";
import NoteTemplatesLazyLoader from "./lazyLoading/note_templates_lazy_loader.service";
import IssuesLazyLoader from "./lazyLoading/issues_lazy_loader.service";
import InspectionsLazyLoader from "./lazyLoading/inspection_lazy_loader.service";
import DeliverableProcessLazyLoader from "./lazyLoading/process_lazy_loader.service";
import localeService from "./localeService/locale.service";
import deliverablesFilterService from "./deliverablesFilterService/deliverables_filter.service";
import deliverableWorkflowService from "./deliverableWorkflowService/deliverable_workflow.service";
import dependencyService from "./dependencyService/dependency.services";
import datesService from "./dates/dates.service";
import errorHandlerService from "./errorHandlerService/errorHandler.service";
import imageUploadService from "./fileUploadService/image_upload.service";
import importActivitiesService from "./importActivitiesService/import_activities.service";
import analyticsService from "./trackingService/analytics.service";
import gaTrackingService from "./trackingService/tracking.service";
import csvService from "./csvService/csv.service";
import overwriteUserDefinedDatesService from "./overwriteUserDefinedDatesService/overwrite_user_defined_dates.service";
import systemStatusService from "./systemStatusService/system_status.service";
import checklistTemplateService from "./checklistTemplateService/checklist_template.service";
import userSettingsStoreService from "./userSettingsStoreService/user_settings_store.service";
import membershipService from "./membershipService/membership.service";
import filterMenuService from "./filterMenuService/filter_menu.service";
import filterStoreService from "./filterStoreService/filter_store.service";
import paginationService from "./paginationService/pagination.service";
import userSuggestionService from "./userSuggestionService/userSuggestion.service";
import keyboardWatcherService from "./keyboardWatcher/keyboard_watcher.service";
import ActivityStateTransitionViewModelService from "./ui/activity_state_transition.service";
import CsRequestService from "./support/cs-request.service";
import "common/dialog/resetActivity/reset_activity.dialog";
import "angular-ui-router";
import "angular-translate";
import "angular-moment";
import "common/intercom/intercomModule";
import "domain/domain.module";
import coreModule from "./../../core/core.module";
import filtersModule from "./../../common/filters/filters";
import apiModule from "./api/api.module";
import FeatureFlagsModule from "../feature_flags/feature_flags.module";
import ProjectCurrentSettingsService from "./projectService/project-current-settings.service";
import ExternalServices from "./external_services.service";
import MissingTranslationHandlerService from "./missing_translation_handler.service";
import legacyExcelImportService from "./functionalityForwardedToAngular/legacy_excel_import.service";

var servicesModule = angular
    .module("sbApp.services", [
        // eslint-disable-line angular/di
        "ngMaterial",
        "angularMoment",
        "sbApp.intercom",
        "ui.router",
        "pascalprecht.translate",
        "sbApp.domain",
        "sbApp.sbResetActivityDialog",
        FeatureFlagsModule.name,
        filtersModule.name,
        ConstantsModule.name,
        coreModule.name,
        apiModule.name,
    ])
    .factory("$sbExternalService", ExternalServices)
    .factory("$sbAssociate", associateService)
    .factory("$sbReassociate", reassociateService)
    .factory("$sbPullUpdate", pullUpdateService)
    .factory("$sbBootstrapOverlay", bootstrapOverlayService)
    .factory("$sbCodeRecommendation", codeRecommendationService)
    .factory("$sbColor", colorService)
    .factory("$sbActivities", activityService)
    .factory("$sbDeliverables", deliverableService)
    .factory("$sbComponent", componentService)
    .factory("$sbDependency", dependencyService)
    .factory("$sbDetailsOverlay", detailsOverlayService)
    .factory("$sbProject", projectService)
    .factory("$sbProjectApi", projectApiService)
    .factory("$sbCalendarRepository", calendarRepoService)
    .factory("$sbCurrentProject", currentProjectService)
    .factory("$sbIssue", issueService)
    .factory("$sbIssuesFilter", issuesFilterService)
    .factory("$sbTemplate", templateService)
    .factory("$sbODataFilter", oDataFilterService)
    .factory("$sbODataViewFilter", oDataViewFilter)
    .factory("$sbODataViewFilterStore", oDataViewFilterStore)
    .factory("$sbTeam", teamService)
    .factory("$sbTeamDialogs", teamDialogService)
    .factory("$sbSiteInspections", siteInspectionsService)
    .factory("$sbStructure", structureService)
    .factory("$sbXML", xmlService)
    .factory("$sbFileReader", fileReaderService)
    .factory("$sbPrimavera", primaveraService)
    .factory("$sbMsp", mspService)
    .factory("$sbParseXml", parseXmlService)
    .factory("$sbParseExcel", parseExcelService)
    .factory("$sbPermission", permissionService)
    .factory("$sbScheduling", schedulingService)
    .factory("$sbCalendar", calendarService)
    .value("$sbGraph", {
        Graph: SimpleGraph,
        GraphNode: GraphNode,
    })
    .factory("$sbTimezone", timezoneService)
    .factory("$sbImagesOverlay", imagesOverlayService)
    .factory("$sbInspectionRequest", inspectionRequestService)
    .factory("$sbImageUpload", imageUploadService)
    .factory("$sbImportActivities", importActivitiesService)
    .factory("$sbDeliverablesFilter", deliverablesFilterService)
    .factory("$sbDeliverableWorkflow", deliverableWorkflowService)
    .factory("DeliverablesLazyLoader", DeliverablesLazyLoader)
    .factory("DeliverablesSimpleLazyLoader", DeliverablesSimpleLazyLoader)
    .factory("NoteTemplates", NoteTemplatesLazyLoader)
    .factory("IssuesLazyLoader", IssuesLazyLoader)
    .factory("InspectionsLazyLoader", InspectionsLazyLoader)
    .factory("DeliverableProcessLazyLoader", DeliverableProcessLazyLoader)
    .factory("$sbLocale", localeService)
    .factory("$sbDates", datesService)
    .factory("$sbErrorHandler", errorHandlerService)
    .factory("$sbOverwriteUserDefinedDates", overwriteUserDefinedDatesService)
    .factory("$sbRequestSentry", requestSentryService)
    .provider("$sbErrorPresenter", errorPresenterService)
    .provider("$sbRequest", requestService)
    .factory("Analytics", analyticsService)
    .factory("$sbTracking", gaTrackingService)
    .factory("$sbCsv", csvService)
    .factory("$sbSystemStatus", systemStatusService)
    .factory("$sbChecklistTemplateWebService", checklistTemplateService)
    .factory("$sbUserSettings", userSettingsStoreService)
    .factory("$sbMembership", membershipService)
    .factory("$sbFilterMenu", filterMenuService)
    .factory("$sbFilterStore", filterStoreService)
    .factory("$sbPagination", paginationService)
    .factory("$sbUserSuggestion", userSuggestionService)
    .factory("$sbKeyboardWatcher", keyboardWatcherService)
    .factory("$sbCustomerSuccessRequests", CsRequestService)
    .factory("$sbProjectCurrentSettings", ProjectCurrentSettingsService)
    .factory("$sbLegacyExcelImport", legacyExcelImportService)
    .service(
        "$sbActivityStateTransitionUI",
        ActivityStateTransitionViewModelService
    )
    .factory(
        "$sbMissingTranslationHandlerService",
        MissingTranslationHandlerService
    )
    .config(function ($provide) {
        "ngInject";

        // extend $q with an allSettled method
        //
        $provide.decorator("$q", function extendQ($delegate) {
            function allSettled(promises) {
                var deferred = $delegate.defer(),
                    counter = 0,
                    results = angular.isArray(promises) ? [] : {};

                angular.forEach(promises, function (promise, key) {
                    counter++;
                    $delegate.when(promise).then(
                        function (value) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    results,
                                    key
                                )
                            ) {
                                return;
                            }
                            results[key] = {
                                status: "fulfilled",
                                value: value,
                            };
                            if (!--counter) deferred.resolve(results);
                        },
                        function (reason) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    results,
                                    key
                                )
                            ) {
                                return;
                            }
                            results[key] = {
                                status: "rejected",
                                reason: reason,
                            };
                            if (!--counter) deferred.resolve(results);
                        }
                    );
                });

                if (counter === 0) {
                    deferred.resolve(results);
                }

                return deferred.promise;
            }
            $delegate.allSettled = allSettled;
            return $delegate;
        });
    });

apiConstants(servicesModule);

export default servicesModule;
