import angular from "angular";
import exportRouteConfig from "./export.route";
import exportCtrl from "./export.controller";
import exportService from "./services/export.service";
import primaveraService from "./services/export_primavera.service";
import downloadService from "./services/download_center.service";
import downloadController from "./states/download/download.controller";
import fileController from "./states/fileBased/file_based.controller";
import "common/services/services";
import "configs/all";
import "../../common/ui-elements/directives/sbFileInput/sb_file_input.directive";

export default angular
    .module("sbApp.exportModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.constants",
        "sbApp.services",
        "sbApp.sbFileInput",
    ])
    .config(exportRouteConfig)
    .controller("ExportCtrl", exportCtrl)
    .controller("DownloadCtrl", downloadController)
    .controller("FileBasedCtrl", fileController)
    .factory("exportService", exportService)
    .factory("exportPrimaveraService", primaveraService)
    .factory("downloadCenterService", downloadService);

/* declaration */
