export default function ($sbXML, $sbPrimavera) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        exportProgressValues: writeProgressValues,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Export progress values based on locale type
     */
    function writeProgressValues(oXML, progressFromServer) {
        return $sbPrimavera.updateFileWithServerProgressValues(
            oXML,
            progressFromServer
        ); //Update the current oXML by reference
    }
}
