/**
 *
 * Basic functionality to interact with XML document objects
 *
 */

export default function () {
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        getXmlNodeTextContent: getXmlNodeTextContent,
        getXmlTagTextContent: getXmlTagTextContent,
        setXmlTagTextContent: setXmlTagTextContent,
        getXmlTagTextContentFromSelector: getXmlTagTextContentFromSelector,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Read a DOM Node and extract more than one elements by tag name. Return the inner HTML or the default values.
     *
     * @private
     *
     * @param  {Object} oTask          The DOM Node.
     * @param  {string} sQuerySelector Tag selector to look for.
     *
     * @return {Array}                 innerHTML
     */
    function getXmlTagTextContentFromSelector(oTask, sQuerySelector) {
        var aNodes = Array.prototype.slice.call(
            oTask.querySelectorAll(sQuerySelector),
            0
        );
        return aNodes.map(function (oNode) {
            return oNode.childNodes[0].wholeText;
        });
    }

    /**
     * Set the text content of the first tag inside the given xml element.
     * If the tag doesn't exist it is created as direct child of the xml element.
     *
     * @param {Element} xml
     * @param {String} tag
     * @param {String} value
     */
    function setXmlTagTextContent(xml, tag, value) {
        var matchingElements = xml.getElementsByTagName(tag);

        if (matchingElements.length > 0) {
            // here we expect that it is a text node
            //
            matchingElements[0].childNodes[0].textContent = value;
        } else {
            // create a new tag with the value as text node and append it to the xml
            //
            var newTag = document.createElementNS("", tag);
            var newText = document.createTextNode(value);
            newTag.appendChild(newText);
            xml.appendChild(newTag);
        }
    }

    /**
     * Get the text content of the first tag inside the given xml element.
     *
     * @param {Element} xml
     * @param {String} tag
     * @param {String} defaultValue - default value is returned if no text content was found
     */
    function getXmlTagTextContent(xml, tag, defaultValue) {
        if (!xml.getElementsByTagName) {
            return xml.wholeText || defaultValue;
        }

        var aTempNodes = xml.getElementsByTagName(tag);

        if (aTempNodes.length > 0) {
            return getXmlNodeTextContent(aTempNodes[0], defaultValue);
        }

        return defaultValue;
    }

    /**
     * Get the text content the given xml element.
     *
     * @param {Element} xml
     * @param {String} defaultValue - default value is returned if no text content was found
     */
    function getXmlNodeTextContent(xml, defaultValue) {
        if (xml.childNodes.length > 0) {
            return xml.childNodes[0].wholeText;
        }

        return defaultValue;
    }
}
