import htmlTemplate from "./sbToolbarSearchBar.html";
export default {
    name: "sbToolbarSearchBar",
    templateUrl: htmlTemplate,
    controller: function (EVENTS, $rootScope, $state) {
        "ngInject";

        var vm = this;

        /**
         * Global Search
         * @description
         * Detects the current state and broadcast search results according to current state
         */
        vm.hasSearchBar = false;
        vm.searchPlaceholder = "";
        vm.searchPlaceholderSmall = "";
        vm.searchTerm = "";

        vm.onSearchTermChange = onSearchTermChange;
        vm.clearSearchBar = clearSearchBar;

        vm.$onInit = $onInit;

        $rootScope.$on("$stateChangeStart", function () {
            clearSearchBar();
        });

        $rootScope.$on("$stateChangeSuccess", function (event, toState) {
            globalSearchEnabled(toState);
        });

        $rootScope.$on(EVENTS.GLOBAL_SEARCH_UNSET, function () {
            clearSearchBar();
        });

        function $onInit() {
            hideToolbar();
            globalSearchEnabled();
        }

        function hideToolbar() {
            vm.hasSearchBar = false;
            vm.searchPlaceholder = "";
            vm.searchPlaceholderSmall = "";
            vm.searchTerm = "";
        }

        function globalSearchEnabled(toState) {
            if (!toState) {
                toState = $state.current;
            }
            if (toState.data && toState.data.searchEnabled) {
                vm.hasSearchBar = true;
                vm.searchPlaceholder = toState.data.searchPlaceholder;
                vm.searchPlaceholderSmall = toState.data.searchPlaceholderSmall;
            } else {
                hideToolbar();
            }
        }

        function onSearchTermChange(searchTerm) {
            _broadCastSearch(searchTerm);
        }

        function clearSearchBar(notify) {
            vm.searchTerm = "";
            if (notify) {
                _broadCastSearch(vm.searchTerm);
            }
        }

        function _broadCastSearch(term) {
            $rootScope.$broadcast(EVENTS.GLOBAL_SEARCH_CHANGE, {
                currentState: $state.current.name,
                searchTerm: term,
            });
        }
    },
};
