import createjs from "createjs";

/**
 * Event to toggle a Toast
 * @param {string} id Identifier
 * @param {boolean} open - open or close the toast
 * @param {string} content - the message
 * @constructs ToggleToastEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function ToggleToastEvent(id, open, content) {
    createjs.Event.call(this, "toggleToast", false, false);
    this.id = id;
    this.open = open;
    this.content = content || "";
}

ToggleToastEvent.prototype = Object.create(createjs.Event.prototype);

export default ToggleToastEvent;
