import html from "./single_action_card.html";

export default {
    templateUrl: html,
    bindings: {
        i18n: "<",
        tutorialUrl: "<",
        icon: "@",
        ngClick: "&",
    },
    controller: function SingleActionCardCtrl() {
        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        vm.onClick = onClick;

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        function onClick($event) {
            $event.stopPropagation();

            vm.ngClick({
                $event: $event,
            });
        }
    },
};
