import angular from "angular";
import htmlTemplate from "./sort_item.html";
export default angular
    .module("sbApp.sbSortItemDirective", [])
    .directive("sbSortItem", function sbSortItemDirective($log) {
        "ngInject";
        return {
            restrict: "E",
            require: ["^^sbHeaderBar", "^sbSortItem"],
            templateUrl: htmlTemplate,
            scope: {},
            controllerAs: "sort",
            bindToController: true,
            controller: function SortItemCtrl($attrs) {
                "ngInject";
                var vm = this;
                vm.onSortClick = onSortClick;
                vm.init = init;

                function init(element, parentCtrl) {
                    vm.$element = element;
                    vm.label = $attrs.label;

                    // reach through callback of sort change
                    vm.onSortChange = parentCtrl.onSortChange;

                    // remove the click event if sort is disabled
                    if (angular.isDefined($attrs.noSort)) {
                        vm.onSortClick = null;
                    }
                }

                function onSortClick(field) {
                    field.order = toggleSortDirection(field.order);

                    vm.onSortChange(field.key, field.order);
                    vm.clearPreviousSorting(field.key);
                }

                /**
                 * Change state of sort direction of given order.
                 *
                 * @param {Number(-1|0|1)} order - Order of sorting, asc | unsorted | desc
                 * @returns {number} The new calculated order.
                 */
                function toggleSortDirection(order) {
                    if (order === 0) {
                        // from unsorted to ascending
                        return 1;
                    } else {
                        // from ascending to descending and back
                        return order * -1;
                    }
                }
            },
            link: function (scope, element, attrs, ctrls) {
                var headerBarCtrl = ctrls[0];
                var sortItemCtrl = ctrls[1];

                sortItemCtrl.init(element, headerBarCtrl);

                if (angular.isDefined(attrs.noSort) || !attrs.field) {
                    element.addClass("sb-sort-item--unsortable");
                }

                if (angular.isDefined(attrs.noLabel) || !attrs.field) {
                    sortItemCtrl.isNoLabel = true;
                }

                if (attrs.field) {
                    sortItemCtrl.clearPreviousSorting = headerBarCtrl.clear;

                    var field = headerBarCtrl.columns[attrs.field];

                    if (!field) {
                        $log.error(
                            "Key " +
                                attrs.field +
                                " must be contained in column map of 'sb-header-bar'."
                        );
                    }

                    field.key = attrs.field;
                    sortItemCtrl.field = field;
                }
            },
        };
    });
