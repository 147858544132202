import _ from "lodash";
import angular from "angular";

export default function filterStoreService($sbCurrentProject, $window) {
    "ngInject";

    var service = {
        getLastFilterSelection: getLastFilterSelection,
        setLastFilterSelection: setLastFilterSelection,
        clearSavedSelection: clearSavedSelection,
    };

    return service;

    function getLastFilterSelection(defaultValue) {
        return _retrieveValue(
            _getKeyForLastFilterSelectionOfProject(),
            defaultValue
        );
    }

    function setLastFilterSelection(filters) {
        _.forOwn(filters, (val) => {
            if (val !== null && !angular.isString) {
                throw new TypeError(
                    "Filter properties should be of type string or null"
                );
            }
        });

        return _storeValue(_getKeyForLastFilterSelectionOfProject(), filters);
    }

    function _getKeyForLastFilterSelectionOfProject() {
        return "sb.common.filter.selection" + $sbCurrentProject.pick("id");
    }

    function _retrieveValue(key, defaultValue) {
        return _.defaultTo(
            angular.fromJson($window.localStorage.getItem(key)),
            defaultValue
        );
    }

    function _storeValue(key, value) {
        return $window.localStorage.setItem(key, angular.toJson(value));
    }

    function clearSavedSelection() {
        _removeValue(_getKeyForLastFilterSelectionOfProject());
    }

    function _removeValue(key) {
        return $window.localStorage.removeItem(key);
    }
}
