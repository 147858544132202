export default class WorkFeedWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint will return a list of deliverables with activities where:
 1. where I have operate/confirm access and which are due in the next 7 days
 2. where I have operate/confirm access and which are late (behind schedule)
 3. where I have operate/confirm access and which have all predecessors marked as done
 4. where a note is attached that I have to resolve
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId, { structureId, deliverableCode }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/work-feed`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                structure_id: structureId,
                deliverable_code: deliverableCode,
            },
        });
    }
}
