import angular from "angular";
import _ from "lodash";
import NoArgumentsError from "common/errors/NoArgumentsError";
import "canvas-to-blob";
import ExifReader from "exifreader";

export default function fileReader($q, $window, $log) {
    "ngInject";
    var service = {
        readAsDataURL: readAsDataURL,
        readFile: readFile,
        parseXml: fnParseXml,
        hasAllowedFileExtension: hasAllowedFileExtension,
        readAndResizeImage: readAndResizeImage,
        resizeImageFromDataURL: resizeImageFromDataURL,
        resize: resize,
        loadImageBySrc: loadImageBySrc,
        extractImageMetadata: extractImageMetadata,
    };

    return service;

    /**
     * Read a file using FileReader class and return a promise with the raw data.
     *
     * @param {File} oFile - Native File object @link{https://developer.mozilla.org/en-US/docs/Web/API/File|File Interface API}
     * @param {string} [sFileReaderReadFunction=readAsText] - a FileReader function name to use (how to read the file)
     *
     * @returns {Promise<{ fileName: string,  rawData: string | ArrayBuffer, type: string }>} Resolves with Object containing "fileName {string}" and "rawData {Object}"
     */
    function readFile(oFile, sFileReaderReadFunction) {
        return $q(function (resolve, reject) {
            if (angular.isUndefined(oFile)) {
                return reject(
                    new NoArgumentsError(
                        "No file specified! See docs for required arguments!"
                    )
                );
            }

            if (angular.isUndefined(oFile.name)) {
                return reject(
                    new NoArgumentsError(
                        "No file name specified! See docs for required arguments!"
                    )
                );
            }

            var oFileReader = new FileReader(),
                sFileName = oFile.name;

            oFileReader.onload = function (oEvent) {
                var oRawData = oEvent.target.result;
                return resolve({
                    fileName: sFileName,
                    rawData: oRawData,
                    type: oFile.type,
                });
            };

            oFileReader.onerror = function (oEvent) {
                reject(oEvent);
            };

            oFileReader.onabort = function (oEvent) {
                reject(oEvent);
            };

            if (angular.isDefined(sFileReaderReadFunction)) {
                if (angular.isFunction(oFileReader[sFileReaderReadFunction])) {
                    oFileReader[sFileReaderReadFunction](oFile);
                } else {
                    oFileReader.readAsArrayBuffer(oFile);
                }
            } else {
                oFileReader.readAsText(oFile);
            }
        });
    }

    /**
     * Convert canvas to blob.
     * @param canvas {HTMLCanvasElement}
     * @returns {Blob|null}
     * @private
     */
    function _canvasToBlob(canvas) {
        let fileType = undefined;
        return $q(function (resolve) {
            canvas.toBlob(
                function (blob) {
                    fileType = blob.type;
                    resolve(blob);
                },
                fileType || "image/jpeg",
                0.7
            );
        });
    }

    /**
     * Create a resized image from a file selection.
     *
     * @param oFile {File}
     * @param maxWidth {number}
     * @param maxHeight {number}
     * @param keepMetadata {boolean}
     * @returns {Promise<{ data: { fileName: string, rawData: Blob, type: string}, imageMetadata: Object|undefined }>}
     */
    function readAndResizeImage(oFile, maxWidth, maxHeight, keepMetadata) {
        return $q
            .all([
                keepMetadata && service.extractImageMetadata(oFile),
                service.readAsDataURL(oFile),
            ])
            .then(([metadata, imageData]) => {
                return service
                    .resizeImageFromDataURL(imageData, maxWidth, maxHeight)
                    .then((resizedImageData) => {
                        return {
                            data: resizedImageData,
                            imageMetadata: metadata,
                        };
                    });
            });
    }

    /**
     * Basic image resizing.
     *
     * @param data {Object}
     * @param data.rawData {string}
     * @param data.fileName {string}
     * @param data.type {string}
     *
     * @param maxWidth {number}
     * @param maxHeight {number}
     *
     * @returns {Promise<{ fileName: string, rawData: Blob, type: string }>}
     */
    function resizeImageFromDataURL(data, maxWidth, maxHeight) {
        return service
            .loadImageBySrc(data.rawData)
            .then(function (image) {
                return service.resize(image, {
                    width: maxWidth,
                    height: maxHeight,
                });
            })
            .then(_canvasToBlob)
            .then(function (blob) {
                data.rawData = blob;
                return data;
            });
    }

    function _scale(from, to) {
        var MAX_WIDTH = to.width || 800;
        var MAX_HEIGHT = to.height || 600;
        var imageWidth = from.width;
        var imageHeight = from.height;

        if (imageWidth > imageHeight) {
            if (imageWidth > MAX_WIDTH) {
                imageHeight *= MAX_WIDTH / imageWidth;
                imageWidth = MAX_WIDTH;
            }
        } else {
            if (imageHeight > MAX_HEIGHT) {
                imageWidth *= MAX_HEIGHT / imageHeight;
                imageHeight = MAX_HEIGHT;
            }
        }

        return {
            height: imageHeight,
            width: imageWidth,
        };
    }

    /**
     * Resize image on canvas element.
     *
     * @param img {HTMLImageElement}
     * @param options {Object}
     * @param options.width {number}
     * @param options.height {number}
     *
     * @returns {Promise<HTMLCanvasElement>}
     */
    function resize(img, options) {
        var defaultOptions = {
            width: 800,
            height: 600,
        };

        options = _.extend({}, defaultOptions, options);

        return $q(function (resolve, reject) {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");

            var dimension = _scale(img, options);

            canvas.width = dimension.width;
            canvas.height = dimension.height;

            try {
                ctx.drawImage(img, 0, 0, dimension.width, dimension.height);
            } catch (err) {
                reject(err);
            }

            return resolve(canvas);
        });
    }

    /**
     * Load image by src URL.
     *
     * @param imageSrc
     * @returns {Promise<HTMLImageElement>}
     */
    function loadImageBySrc(imageSrc) {
        return $q(function (resolve, reject) {
            var img = document.createElement("img");

            img.addEventListener(
                "load",
                function () {
                    resolve(this);
                },
                false
            );

            img.addEventListener(
                "error",
                function () {
                    reject(this);
                },
                false
            );

            img.src = imageSrc;
        });
    }

    /**
     * Parse XML from string using environment dependant capabilities.
     *
     * @param {String} sXmlString - XML formatted text
     * @returns {Promise}
     */
    function fnParseXml(sXmlString) {
        return $q(function (resolve, reject) {
            var parseXml;

            if ($window.DOMParser) {
                parseXml = function (xmlStr) {
                    try {
                        return new $window.DOMParser().parseFromString(
                            xmlStr,
                            "text/xml"
                        );
                    } catch (err) {
                        $log.error(err);
                        reject(new Error("Error parsing XML"));
                    }
                };
            } else {
                if (
                    typeof $window.ActiveXObject !== "undefined" &&
                    new $window.ActiveXObject("Microsoft.XMLDOM")
                ) {
                    parseXml = function (xmlStr) {
                        var xmlDoc = new $window.ActiveXObject(
                            "Microsoft.XMLDOM"
                        );
                        xmlDoc.async = "false";
                        var isSucceeded = xmlDoc.loadXML(xmlStr);

                        if (!isSucceeded) {
                            reject(Error("Error parsing XML"));
                        }

                        return xmlDoc;
                    };
                } else {
                    reject(new Error("No XML parser!"));
                }
            }

            resolve(parseXml(sXmlString));
        });
    }

    /**
     * Read file as dataURL.
     *
     * @param oFile {File}
     * @returns {Promise<{ fileName: string, rawData: string, type: string }>}
     */
    function readAsDataURL(oFile) {
        return service.readFile(oFile, "readAsDataURL");
    }

    /**
     * Checks if the given extension is contained in the allowed extension array.
     * Comparision is case insensitive.
     *
     * @param {String} extension - Checked for.
     * @param {Array.<String>} allowedExtensions - Array of allowed extension strings.
     *
     * @returns {boolean} True if at least one is contained.
     */
    function hasAllowedFileExtension(extension, allowedExtensions) {
        if (
            !angular.isArray(allowedExtensions) ||
            !angular.isString(extension)
        ) {
            return false;
        }

        return allowedExtensions.some(function (allowedExtension) {
            return (
                allowedExtension
                    .toLowerCase()
                    .indexOf(extension.toLowerCase()) !== -1
            );
        });
    }

    /**
     * @param oFile {File}
     * @returns {Object|undefined}
     */
    function extractImageMetadata(oFile) {
        return ExifReader.load(oFile, {
            expanded: true,
        })
            .then((imageMetadata) => {
                const imageType = oFile.type;
                const gpsAltitude = imageMetadata.gps?.Altitude;
                const gpsLatitude = imageMetadata.gps?.Latitude;
                const gpsLongitude = imageMetadata.gps?.Longitude;
                const dateTimeOriginal =
                    imageMetadata.exif?.DateTimeOriginal?.description;

                const extractedImageMetadata = {
                    ...(imageType && { imageType }),
                    ...(gpsAltitude && { gpsAltitude }),
                    ...(gpsLatitude && { gpsLatitude }),
                    ...(gpsLongitude && { gpsLongitude }),
                    ...(dateTimeOriginal && { dateTimeOriginal }),
                };

                return Object.keys(extractedImageMetadata).length
                    ? extractedImageMetadata
                    : undefined;
            })
            .catch(function (error) {
                $log.error(error);
                return undefined;
            });
    }
}
