import angular from "angular";
import htmlTemplate from "./sb_loading.html";
export default angular
    .module("sbApp.sbLoading", [])
    .directive("sbLoading", function () {
        return {
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            //transclude: true,

            scope: {
                mini: "=",
            },

            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

            //compile : function(tElement, tAttrs) {},

            //link : function(scope, iElement, iAttrs, controller) {},

            //controller : function($scope, $element) {},
        };
    });
