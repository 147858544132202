import _ from "lodash";
import moment from "moment";

export default function ($sbNoteTemplateApi, $sbCurrentProject) {
    "ngInject";

    return {
        getNotes: getNotes,
        getNotesCount: getNotesCount,
        deleteNotes: deleteNotes,
        editNote: editNote,
        createNote: createNote,
        getLastCreatedCodesByTypes: getLastCreatedCodesByTypes,
    };

    /**
     * Get all the notes from the database
     *
     * @param {int} from - position of the element that should be first in the result set
     * @param {int} to - position of the element that should be last in the result set
     * @param {Object} filters - An array of filter objects from Filters class (with type, query, prefix & selected attrs)
     * @param {string} order - Sort order string
     * @return {$q} - the OData resolve promise.
     */
    function getNotes(filters = {}, order = "CREATION_DATE desc") {
        const projectId = $sbCurrentProject.pick("id");

        const { sortBy, inDirection } = _sortAndOrderFrom(order);
        const { filterFor, searchFor } = _filterAndSearchFrom(filters);

        return $sbNoteTemplateApi.all().then(({ note_templates = [] }) => {
            return _.chain(note_templates)
                .map((noteTemplate) =>
                    _mapToODataInterface(projectId, noteTemplate)
                )
                .filter(filterFor)
                .filter((noteTemplate) => {
                    if (!searchFor.TERM) {
                        return noteTemplate;
                    }

                    return (
                        _.includes(
                            noteTemplate.CODE.toLowerCase(),
                            searchFor.TERM
                        ) ||
                        _.includes(
                            noteTemplate.TEXT.toLowerCase(),
                            searchFor.TERM
                        )
                    );
                })
                .orderBy(sortBy, (inDirection || "").toLowerCase())
                .value();
        });
    }

    /**
     * Get all last created codes by their type. Returns an object with types as keys and
     * the latest code as value.
     *
     * @param {Array.<Object>} templates - Array with all templates to search through.
     * @param {String} templates.TYPE - Must be present, for analysing.
     * @param {Date} templates.CREATION_DATE - is used to determine the latest created code.
     *
     * @return {Object} Result hash with <type, code>.
     */
    function getLastCreatedCodesByTypes(templates) {
        return _(templates)
            .groupBy("TYPE")
            .mapValues(function (subTemplates) {
                // get the last created template code
                //
                return _.reduce(subTemplates, function (previous, value) {
                    if (
                        moment(value.CREATION_DATE).isAfter(
                            previous.CREATION_DATE
                        )
                    ) {
                        return value;
                    }
                    return previous;
                }).CODE;
            })
            .value();
    }

    /**
     *  Get total number of available notes
     *  @param {filters} filters - An array of filter objects from Filters class (with type, query, prefix & selected attrs)
     *  @return {Promise}
     */
    function getNotesCount(filters) {
        return getNotes(filters).then((body) => {
            return {
                status: 200,
                data: body.length,
            };
        });
    }

    /**
     * Given an array of notes it tries to delete them.
     * @param  {Object} notes - All the notes that should be deleted
     * @return {Promise}      - an all settled promise
     */
    function deleteNotes(notes) {
        return $sbNoteTemplateApi.removeAll(notes.map((note) => note.KEY));
    }

    /**
     * Given a note templates it tries to update it.
     * @param  {Object} note - An object with already edited properties
     * @return {Promise}
     */
    function editNote(note) {
        return $sbNoteTemplateApi.update(note.KEY, {
            code: note.CODE,
            text: note.TEXT,
            type: note.TYPE,
        });
    }

    /**
     * Create a new note templates
     * @param  {Object} note - The new note template that will be created
     * @return {Promise}
     */
    function createNote(note) {
        return $sbNoteTemplateApi.add({
            code: note.CODE,
            text: note.TEXT,
            type: note.TYPE,
        });
    }

    function _sortAndOrderFrom(order) {
        const [sortBy, inDirection] = order.split(" ");
        return {
            sortBy,
            inDirection,
        };
    }
    function _filterAndSearchFrom(filters) {
        const filterFor = JSON.parse(
            JSON.stringify({
                CREATOR_DB_NAME: filters.CREATOR_DB_NAME,
                TYPE: filters.TYPE,
            })
        );

        const searchFor = JSON.parse(
            JSON.stringify({
                TERM: filters.SEARCH_TERM,
            })
        );

        return {
            filterFor,
            searchFor,
        };
    }

    function _mapToODataInterface(projectId, noteTemplate) {
        return {
            CODE: noteTemplate.code,
            CREATION_DATE: noteTemplate.created_at,
            CREATOR_DB_NAME: _.get(noteTemplate, "created_by.id"),
            CREATOR_DISPLAY_NAME: _.get(noteTemplate, "created_by.name"),
            CREATOR_INITIALS: _.get(noteTemplate, "created_by.initials"),
            KEY: noteTemplate.key,
            PROJECT_ID: projectId,
            STATUS: "A",
            TEXT: noteTemplate.text,
            TYPE: noteTemplate.type,
        };
    }
}
