export default function imageUpload($sbImageApi, $q, $window, $sbFileReader) {
    "ngInject";

    const service = {
        uploadImages: uploadImages,
        transformAndUploadImage: transformAndUploadImage,
    };

    // new pictures max ratios
    const MAX_ISSUE_ATTACHMENT_IMAGE_SIZE = 1200;
    const MAX_ISSUE_ATTACHMENT_IMAGE__THUMB_SIZE = 300;

    return service;

    /**
     * Change the project image
     *
     * @param  {string} fileName      - the name of the image
     * @param  {Blob[]} blobPair      - the Image as data blob
     * @param  {string} projectId     - the project id where this image belongs to
     * @param  {Object} imageMetadata - the metadata of the image
     * @return {Promise}
     */
    function uploadImages(fileName, blobPair, projectId, imageMetadata) {
        return $sbImageApi
            .upload(projectId, blobPair, {
                filename: fileName,
                imageMetadata,
            })
            .then(function ({ id, url }) {
                return {
                    url: {
                        remote: url,
                    },
                    id,
                };
            });
    }

    /**
     * Take an image and resize it.
     * Return a resolved promise with the picture resized
     *
     * @param {object} image                                     - as handled by sbFileReader
     * @param {{maxSize: number, keepMetadata: boolean}} options - options with pixel based size of the new picture and if metadata is kept
     * @returns {Promise}
     */
    function _resizeImageAndAddOnArray(image, { maxSize, keepMetadata }) {
        return $sbFileReader.readAndResizeImage(
            image,
            maxSize,
            maxSize,
            keepMetadata
        );
    }

    /**
     * Take a file transform it to thumb blob and full blob and upload it.
     *
     * @param {string} projectId
     * @param {File} file
     * @returns {Promise}
     * @private
     */
    function transformAndUploadImage(projectId, file, keepMetadata = false) {
        return $q
            .all([
                _resizeImageAndAddOnArray(file, {
                    maxSize: MAX_ISSUE_ATTACHMENT_IMAGE_SIZE,
                    keepMetadata,
                }),
                _resizeImageAndAddOnArray(file, {
                    maxSize: MAX_ISSUE_ATTACHMENT_IMAGE__THUMB_SIZE,
                    keepMetadata: false, // not needed for thumb
                }),
            ])
            .then((images) => {
                const blobPair = images.map((image) => image.data.rawData);
                const imageMetadata = images[0].imageMetadata;

                return uploadImages(
                    undefined,
                    blobPair,
                    projectId,
                    imageMetadata
                );
            });
    }
}
