import angular from "angular";
import htmlTemplate from "./sb_filter_workflow.html";
import ViewFilter from "common/services/oDataService/odata_view_filter.class";

export default angular
    .module("sbApp.sbFilterWorkflow", [])
    .directive("sbFilterWorkflow", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "filterWorkflow",
            templateUrl: htmlTemplate,
            replace: true,
            //transclude: true,
            scope: {
                /**
                 * @typeof ViewFilter
                 */
                viewFilter: "=",

                // inform parent
                onChange: "=",

                //
                placeholder: "@",
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "filterWorkflow.viewFilter.value",
                    function (newValue, oldValue) {
                        // support clear viewFilter from outside -> newValue is undefined
                        if (newValue === undefined && newValue !== oldValue) {
                            controller.activate();
                        }
                    }
                );
            },
            controller: function FilterWorkflowCtrl($q) {
                "ngInject";
                var vm = this;

                vm.querySearch = querySearch;
                vm.onSelectionChange = onSelectionChange;
                vm.selectUnassigned = selectUnassigned;
                vm.isFilterForUnstructuredAllowed = true;
                vm.activate = activate;

                activate();

                ///////////
                //
                //  IMPL
                //
                ///////////

                function activate() {
                    vm.isFilterForUnstructuredAllowed =
                        vm.viewFilter.isNullConditionEnabled;

                    const activeOptions = vm.viewFilter.collectActiveOptions();
                    if (activeOptions && activeOptions.length > 0) {
                        vm.selectedItem = activeOptions[0];
                    } else if (vm.viewFilter.isNullCondition()) {
                        vm.isUnstructuredOnly = true;
                    } else {
                        vm.selectedItem = undefined;
                    }
                }

                /**
                 * querySearch - triggered when searching for a workflow
                 *
                 * @param  {String} searchText - The string to be searched
                 * @return {Promise}           - An always resolving promise that returns the filtered workflows
                 */
                function querySearch(searchText) {
                    var options = vm.viewFilter.options;

                    if (searchText) {
                        var lwrText = searchText.toLowerCase();
                        return $q.resolve(
                            options.filter(function (option) {
                                return (
                                    option.label
                                        .toLowerCase()
                                        .indexOf(lwrText) !== -1
                                );
                            })
                        );
                    } else {
                        return $q.resolve(options);
                    }
                }

                function selectUnassigned(active) {
                    if (active) {
                        vm.viewFilter.setValue(ViewFilter.NULL_CONDITION);
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    } else {
                        onSelectionChange(vm.selectedItem);
                    }
                }

                function onSelectionChange(selectedItem) {
                    if (selectedItem) {
                        vm.viewFilter.setSelectedOption(selectedItem.name);
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    } else if (vm.viewFilter.hasValue()) {
                        vm.viewFilter.clear();
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    }
                }
            },
        };
    });
