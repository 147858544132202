import { aws } from "../../globals";

export default function ($sbAuthProvider) {
    "ngInject";

    $sbAuthProvider.init({
        REGION: aws.region,
        USER_POOL_ID: aws.cognito.userPoolId,
        APP_CLIENT_ID: aws.cognito.appClientId,
    });
}
