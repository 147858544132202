import createjs from "createjs";

/**
 * Event that is dispatched when something is blurred (e.g. leaves Focus)
 * @constructs BlurEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function BlurEvent() {
    createjs.Event.call(this, "blur", true, true);
}

BlurEvent.prototype = Object.create(createjs.Event.prototype);

export default BlurEvent;
