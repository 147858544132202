import angular from "angular";
import deliverablesRouteConfig from "./deliverables.route";
import deliverablesCtrl from "./deliverables.controller";
import deliverableDateChangeService from "./services/deliverable_date_change.service";
import bulkProcessOperationsService from "./services/bulk_process_operations.service";
import deliverableTemplateAssignService from "./services/deliverable_template_assign.service";
import sbDeliverableRow from "./directives/sbDeliverableRow/deliverable_row.component";
import PickProjectMemberDialogPresets from "./dialogs/pickProjectMember/pick_project_member_dialog.config";
import AssignTypeConfig from "./dialogs/assignType/assign_type_dialog.config";
import BulkStateChangeDialogPresets from "./dialogs/bulkStateChange/bulk_state_change.config";
import BulkCreateNoteDialogPresets from "./dialogs/bulkCreateNote/bulk_create_note.config";
import ActivitySetConfigurationPresets from "./dialogs/activitySetConfiguration/activity_set_configuration.config";
import DeliverableSetConfigurationConfig from "./dialogs/deliverableSetConfiguration/deliverable_set_configuration.config";
import BulkChecklistPdfExportPresets from "./dialogs/bulkChecklistPdfExport/bulk_checklist_pdf_export.config";
import DeliverableChecklistsPdfExportPresets from "./dialogs/deliverableChecklistsPdfExport/deliverable_checklists_pdf_export.config";
import DirectivesModule from "common/ui-elements/ui-elements";
import PickFromChoicesDialogModule from "common/dialog/pickFromChoices/pick_from_choices_dialog.module";
import FeatureFlagsModule from "common/feature_flags/index";
import sbRequestActionDialog from "./dialogs/requestAction/request_action_dialog.component";
import "common/dialog/printReport/print_report.dialog";
import "common/dialog/assignStructure/assign_structure.dialog";
import "common/dialog/templateSelector/template_selector.dialog";
import "common/dialog/createNewItems/create_new_items.dialog";
import "common/dialog/busyIndication/busyIndication";
import "common/dialog/printQRCodes/print_qrcodes.dialog";
import "common/dialog/createIssueComment/create_issue_comment.dialog";
import "common/dialog/resetActivity/reset_activity.dialog";
import "common/services/services";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.deliverables", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.filters",
        "sbApp.domain",
        DirectivesModule.name,
        "sbApp.constants",
        "sbApp.sbPrintReportDialog",
        "sbApp.sbTemplateSelectorDialog",
        "sbApp.sbAssignStructureDialog",
        "sbApp.sbCreateNewItemsDialog",
        "sbApp.sbCreateIssueCommentDialog",
        "sbApp.sbBusyIndicationDialog",
        "sbApp.sbPrintQRCodesDialog",
        "sbApp.sbResetActivityDialog",
        PickFromChoicesDialogModule.name,
        FeatureFlagsModule.name,
    ])
    .config(deliverablesRouteConfig)
    .config(PickProjectMemberDialogPresets)
    .config(BulkStateChangeDialogPresets)
    .config(BulkCreateNoteDialogPresets)
    .config(ActivitySetConfigurationPresets)
    .config(DeliverableSetConfigurationConfig)
    .config(AssignTypeConfig)
    .config(BulkChecklistPdfExportPresets)
    .config(DeliverableChecklistsPdfExportPresets)
    .controller("DeliverablesCtrl", deliverablesCtrl)
    .factory("sbDeliverableDateChangeService", deliverableDateChangeService)
    .factory("$sbBulkProcessOperationsService", bulkProcessOperationsService)
    .factory("$sbDeliverableTemplateAssign", deliverableTemplateAssignService)
    .component(sbDeliverableRow.name, sbDeliverableRow)
    .component("sbRequestActionDialog", sbRequestActionDialog);
/* declaration */
