export default function ($q, $sbRequest, $sbFileReader, API_VERSION) {
    "ngInject";

    return {
        upload,
        download,
        downloadThumbImage,
        response: {
            transformToDataURL,
        },
    };

    function download(projectId, imageId, options) {
        if (!projectId || typeof projectId !== "string") {
            return $q.reject(new Error("MissingRequiredParameter"));
        }

        if (!imageId || typeof imageId !== "number") {
            return $q.reject(new Error("MissingRequiredParameter"));
        }

        const endpointUrl = `/api/${API_VERSION}/api/projects/${projectId}/images/${imageId}`;
        return $sbRequest.image(endpointUrl, options);
    }

    function downloadThumbImage(projectId, imageId) {
        return download(projectId, imageId, {
            transformResponse: [transformToDataURL],
            query: {
                fit: "thumb",
            },
        }).then((image) => {
            return image.dataUrl;
        });
    }

    function transformToDataURL({ response: blob }) {
        // needed for $sbFileReader to work
        //
        blob.name = null;

        return $sbFileReader.readAsDataURL(blob).then((data) => {
            return {
                blob,
                dataUrl: data.rawData,
            };
        });
    }

    /**
     * Upload an image (File/Blob)
     *
     * API POST call to /projects/<project-id>/images
     *
     * @param  {string} projectId                       - the project id where this image belongs to
     * @param  {Blob[]} blobPair                        - the Image as data blob
     * @param  {Object} options
     * @param  {string} options.filename                - the name of the image
     * @param  {Object|undefined} options.imageMetadata - the metadata of the image
     *
     * @return {Promise}
     */
    function upload(
        projectId,
        blobPair,
        { filename = "image", imageMetadata } = {}
    ) {
        if (!projectId || typeof projectId !== "string") {
            return $q.reject(new Error("MissingRequiredParameter"));
        }

        const endpointUrl = `/api/${API_VERSION}/api/projects/${projectId}/images`;
        const fd = new FormData();

        const [fullBlob, thumbBlob] = blobPair;
        if (!(fullBlob instanceof Blob)) {
            return $q.reject(new Error("MissingRequiredParameter"));
        }

        fd.append("full", fullBlob, filename);

        if (thumbBlob) {
            if (!(thumbBlob instanceof Blob)) {
                return $q.reject(new Error("MissingRequiredParameter"));
            }
            fd.append(
                "thumb",
                thumbBlob,
                filename ? "thumb_" + filename : undefined
            );
        }

        // imageMetadata TODO: SAB-7032 - connect with actual API
        if (imageMetadata) {
            // see -> https://app.getguru.com/card/iMn5k6jT/RFC-Image-geotagging
            fd.append("longitude", imageMetadata.gpsLongitude);
            fd.append("latitude", imageMetadata.gpsLatitude);
            fd.append("altitude", imageMetadata.gpsAltitude);
            fd.append("timestamp", imageMetadata.dateTimeOriginal);
        }

        return $sbRequest.multiPart(endpointUrl, fd).then(function (xhr) {
            const location = xhr.getResponseHeader("Location");
            const id = Number.parseInt(
                location.substring(location.lastIndexOf("/") + 1),
                10
            );
            const url = endpointUrl + "/" + id;

            return {
                url,
                id,
            };
        });
    }
}
