import importExportToolsTemplateHtml from "./tools.html";

export default function ($stateProvider) {
    "ngInject";

    $stateProvider.state("sablono.project.tools", {
        templateUrl: importExportToolsTemplateHtml,
        url: "/tools",
        params: {
            // can be 'export' or 'update'
            //
            open: "",
        },
        gaPageTrack: "/tools", // google analytics extension
        controller: "ToolsCtrl",
        controllerAs: "tools",
        data: {
            title: "SECTION_TOOLS_TITLE",
            isSubBarHidden: true,
        },
    });
}
