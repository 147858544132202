import angular from "angular";
import templateHtml from "./activity_card.html";
import panelHtml from "./activity_progress_menu.html";
import progresslayoutPartial from "./activity_card_progress_layout.prtl.html";
import teamLayoutPartial from "./activity_card_team_layout.prtl.html";
import Activity from "../../../domain/sb_activity.class";

export default {
    name: "sbProcessActivityCard",
    templateUrl: templateHtml,
    bindings: {
        activity: "<",
        allowedTeam: "<",
        isSelected: "<",
        onCreateNote: "&",
        onChangeState: "&",
        highlight: "@",
    },
    controller: ProcessActivityCard,
};

function ProcessActivityCard(
    ACTIVITY_STATES,
    ACTIVITY_TRANSITIONS,
    $element,
    $mdPanel,
    $sbColor,
    $sbMembership,
    $sbActivityStateTransitionUI
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    const opacity = {
        MEDIUM: 50,
        LIGHT: 20,
    };

    var previousProgressValue = undefined;
    var currentProgressValue = undefined;

    /////////////////////
    //
    //      Lifecycle Hooks
    //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
    //
    /////////////////////

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.$doCheck = $doCheck;

    /**
     * All initialization code for the controller is placed in here.
     *
     * Inits the mapping from highlighted layout to the
     * HTML partials in the template cache.
     *
     * Inits the card colors to show for background, left border and selection.
     */
    function $onInit() {
        vm.templates = {
            TEAM: teamLayoutPartial,
            STATUS: progresslayoutPartial,
        };

        vm.updateCardColors();

        vm.coloredElement = $element.children()[0];
    }

    /**
     * Called whenever one-way bindings are updated.
     *
     * Currently only used detecting switch of layout by highlight binding.
     *
     * @param changes
     */
    function $onChanges(changes) {
        if (changes.highlight && !changes.highlight.isFirstChange()) {
            if (
                changes.highlight.previousValue !==
                changes.highlight.currentValue
            ) {
                vm.updateCardColors();
                vm.applyColorStylesTo(vm.coloredElement, vm.highlight);
            }
        }

        if (changes.isSelected && !changes.isSelected.isFirstChange()) {
            if (
                changes.isSelected.previousValue !==
                changes.isSelected.currentValue
            ) {
                vm.isSelected = changes.isSelected.currentValue;

                vm.updateCardColors();
                vm.applyColorStylesTo(vm.coloredElement, vm.highlight);
            }
        }
    }

    /**
     * Runs on every digest !!!
     *
     * Keeps track of the progress changes outside
     * of the normal $onChanges binding triggers.
     */
    function $doCheck() {
        previousProgressValue = currentProgressValue;
        currentProgressValue = Activity.deriveProgressFromState(vm.activity);

        if (previousProgressValue !== currentProgressValue) {
            vm.updateCardColors();
            vm.applyColorStylesTo(vm.coloredElement, vm.highlight);
        }
    }

    /////////////////////
    //
    //      Controller methods
    //
    /////////////////////

    vm.transitionToActivityStatus = transitionToActivityStatus;
    vm.openPanel = openPanel;

    vm.getLeftBorderColorBy = getLeftBorderColorBy;
    vm.getBackgroundColorBy = getBackgroundColorBy;
    vm.getColor = getColor;

    vm.updateCardColors = updateCardColors;
    vm.applyColorStylesTo = applyColorStylesTo;

    vm.canUserReportProgress = canUserReportProgress;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function openPanel($event) {
        $event.stopPropagation();

        return $mdPanel.open({
            attachTo: angular.element(document.body),
            templateUrl: panelHtml,
            focusOnOpen: false,
            zIndex: 4,
            controller: function ProgressPanelCtrl(mdPanelRef) {
                "ngInject";

                var vm = this;

                vm.onClick = onClick;

                function onClick($event, option) {
                    vm.onSelectOption(option);

                    mdPanelRef.close();
                }
            },
            bindToController: true,
            controllerAs: "$ctrl",
            clickOutsideToClose: true,
            escapeToClose: true,
            position: $mdPanel
                .newPanelPosition()
                .relativeTo($event.target)
                .addPanelPosition(
                    $mdPanel.xPosition.ALIGN_END,
                    $mdPanel.yPosition.BELOW
                ),
            locals: {
                state: $sbActivityStateTransitionUI.selectViewModelFor(
                    vm.activity
                ),
                ACTIVITY_TRANSITIONS,
                ACTIVITY_STATES,
                onSelectOption: function (option) {
                    vm.transitionToActivityStatus(
                        vm.activity,
                        vm.activity.state.current,
                        option
                    );
                },
            },
        });
    }

    function transitionToActivityStatus(activity, fromState, toState) {
        if (toState === ACTIVITY_TRANSITIONS.COULD_NOT_START) {
            return vm.onCreateNote({
                activity,
            });
        } else {
            return vm.onChangeState({
                activity,
                fromState,
                toState,
            });
        }
    }

    function updateCardColors() {
        var teamColor = vm.activity.assignedTeam.color;
        var progressColor = $sbColor.color.fromProgress(currentProgressValue);

        vm.activityColors = {
            TEAM: teamColor,
            PROGRESS: progressColor,
            SELECTED: "#4A688E",
        };
    }

    /**
     * Apply styling on elements inside controller to avoid ngStyle directive.
     * This is reducing the number of watchers created from within the template.
     *
     * @param {HTMLElement} htmlElement
     * @param {String} highlight
     */
    function applyColorStylesTo(htmlElement, highlight) {
        htmlElement.style.backgroundColor = vm.getBackgroundColorBy(highlight);
        htmlElement.style.borderColor = vm.getLeftBorderColorBy(highlight);
        htmlElement.style.color = vm.getColor();
    }

    function getColor() {
        return vm.isSelected ? "white" : "inherit";
    }

    function getLeftBorderColorBy(highlight) {
        var borderColor = vm.activityColors.TEAM;

        if (highlight === "TEAM") {
            borderColor = vm.activityColors.PROGRESS;
        }

        return borderColor;
    }

    function getBackgroundColorBy(highlight) {
        var backgroundColor;

        // overwrite mode with selection
        //
        if (vm.isSelected) {
            highlight = "SELECTED";
        }

        switch (highlight) {
            case "TEAM":
                backgroundColor = $sbColor.hexToRgba(
                    vm.activityColors.TEAM,
                    opacity.MEDIUM
                );
                break;
            case "SELECTED":
                backgroundColor = vm.activityColors.SELECTED;
                break;
            default:
                backgroundColor = $sbColor.hexToRgba(
                    vm.activityColors.PROGRESS,
                    opacity.LIGHT
                );
        }

        return backgroundColor;
    }

    function canUserReportProgress(activity) {
        // in this case (as by data loader) -> activity parent is always the deliverable
        var userIsAllowedToReport = $sbMembership.canReportProgressFor(
            activity.getParent(),
            activity,
            $sbMembership.current()
        );

        return userIsAllowedToReport;
    }
}
