import angular from "angular";
import _ from "lodash";
import html from "./filter_menu_list.html";
import FilterMenuListItem from "./model/filter_menu_list_item.class";

export default angular
    .module("sbApp.common.components.filterMenuList", [])
    .component("sbFilterMenuList", {
        templateUrl: html,
        controllerAs: "vm",
        bindings: {
            allItemsSelection: "<",
            items: "<",
            selectedItem: "<",
            onFilterByItem: "&",
            titleTranslation: "@",
            allItemsTranslation: "@",
            comparisonPath: "<?",
        },
        controller: function FilterMenuListCtrl() {
            let vm = this;

            vm.$onChanges = $onChanges;
            vm.noSelection = new FilterMenuListItem(
                vm.allItemsTranslation,
                vm.allItemsSelection
            );

            function $onChanges(changes) {
                vm.items = vm.items || [];

                const inboundAllItem = _.get(
                    changes,
                    "allItemsSelection.currentValue"
                );
                const inboundAllItemText = _.get(
                    changes,
                    "allItemsTranslation.currentValue"
                );
                if (inboundAllItem || inboundAllItemText) {
                    vm.noSelection.name = inboundAllItemText;
                    vm.noSelection.value = inboundAllItem;
                }

                const inboundItems = _.get(changes, "items.currentValue");
                if (inboundItems) {
                    vm.activeSelection = vm.noSelection;
                }

                const inboundSelection = _.get(
                    changes,
                    "selectedItem.currentValue"
                );
                if (inboundSelection) {
                    _setSelectionFromOptionItem(
                        inboundSelection,
                        vm.comparisonPath
                    );
                }
            }

            function _setSelectionFromOptionItem(
                inboundSelection,
                comparePath
            ) {
                const isItemSelected = vm.items.some((item) => {
                    if (angular.isString(comparePath)) {
                        const itemId = _.get(item.value, comparePath);
                        const selectionId = _.get(
                            inboundSelection,
                            comparePath
                        );
                        if (itemId === selectionId) {
                            vm.activeSelection = item;
                            return true;
                        }
                    } else {
                        if (item.value === inboundSelection) {
                            vm.activeSelection = item;
                            return true;
                        }
                    }
                    return false;
                });
                if (!isItemSelected) {
                    vm.activeSelection = vm.noSelection;
                }
            }
        },
    });
