import angular from "angular";
import htmlTemplate from "./fill_checklist_panel.html";

export default angular
    .module("sbApp.sbFillChecklistPanel", [])
    .directive("sbFillChecklistPanel", function () {
        return {
            restrict: "E",
            replace: true,
            bindToController: true,
            controllerAs: "$ctrl",
            templateUrl: htmlTemplate,
            scope: {
                activityId: "<",
                activityName: "<",
                projectId: "<",
                isOpen: "=",
                fromState: "<",
                activityState: "<",
                toState: "<",
                closeFillChecklistPanel: "<",
                updateActivityWorkflowIcon: "<",
            },
            controller: function FillChecklistCtrl() {
                "ngInject";
                const vm = this;
                vm.activate = activate;

                function activate() {
                    if (vm.isOpen) {
                        const el = document.getElementById("angular-checklist");
                        el.updateActivityWorkflowIcon =
                            vm.updateActivityWorkflowIcon;
                        el.closeFillChecklistPanel = vm.closeFillChecklistPanel;
                        el.toState = vm.toState.toLowerCase();
                        el.activityId = vm.activityId;
                        el.activityName = vm.activityName;
                        el.activityState = vm.activityState.toLowerCase();
                    }
                }
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch("$ctrl.isOpen", function () {
                    controller.activate();
                });
            },
        };
    });
