import _ from "lodash";

function ModeManager() {
    this.modes = [];
    this.active = null;
    this.default = "";

    this.onModeChange = function () {};
}

ModeManager.prototype.getModeByName = function (name) {
    var defaultMode = _.find(this.modes, ["name", this.default]);

    if (_.isUndefined(name)) {
        return defaultMode;
    }

    return _.defaultTo(_.find(this.modes, ["name", name]), defaultMode);
};

ModeManager.prototype.switchTo = function (nameOfMode) {
    var oldMode = this.active;
    this.active = this.getModeByName(nameOfMode);

    this.onModeChange(_.get(oldMode, "name"), _.get(this.active, "name"));
};

ModeManager.prototype.addTransitionHandler = function (fn) {
    this.onModeChange = fn;
};

ModeManager.prototype.isActiveModeOneOf = function (modes) {
    var _this = this;

    if (!Array.isArray(modes)) {
        modes = [modes];
    }

    return _.some(modes, function (mode) {
        return mode.name === _this.active.name;
    });
};

export default ModeManager;
