import angular from "angular";
import _ from "lodash";

/**
 * give me your Contructor function, and angular will just 'new' MyService()
 */
export default angular
    .module("sbApp.SimplifyTreeModel", [])
    .service("SimplifyTreeModel", function () {
        /**
         * Class to simplify complex tree structures
         * @param {string |number} displayedDepth  depth which will initially displayed
         * @constructs Simplifier
         */
        var Simplifier = function (displayedDepth) {
            if (displayedDepth) {
                this.displayedDepth = Number.parseInt(displayedDepth);
            }
            if (!this.displayedDepth) {
                this.displayedDepth = 0;
            }
            this.simplifiedTree = [];
        };

        /**
         * recursive method which simplifies the tree model by bringing down the whole model into one array
         * and link just the direct children to a parent
         * the tree will contain a depth, an indication if the node is opened and if the node is currently displayed
         * @param {Array} branch contains a set of children
         * @param {string} nestedKey  name of the key which contains the children
         * @param {number} depth the current depth
         * @param {Object} [parent] if not set, it will be ignored. If set, the direct children will push themselves into the child array of this parent
         */
        Simplifier.prototype.simplifyTree = function (
            branch,
            nestedKey,
            depth,
            parent
        ) {
            this._simplifyTree(branch, nestedKey, depth || 0, parent);

            // clean empty children
            //
            this.simplifiedTree.forEach(function (n) {
                if (n.children && n.children.length === 0) {
                    n.children = undefined;
                }
            });
        };

        Simplifier.prototype._simplifyTree = function (
            branch,
            nestedKey,
            depth,
            parent
        ) {
            for (var i = 0; i < branch.length; i++) {
                var node = branch[i];
                node.depth = depth;

                var copiedNode = _.clone(node);
                delete copiedNode[nestedKey];

                if (this.displayedDepth >= depth) {
                    copiedNode.show = true;
                    if (parent) {
                        parent.open = true;
                    }
                } else {
                    copiedNode.show = false;
                    if (parent) {
                        parent.open = false;
                    }
                }
                this.simplifiedTree.push(copiedNode);

                if (parent) {
                    if (parent.children) {
                        parent.children.push(copiedNode);
                    } else {
                        parent.children = [copiedNode];
                    }
                }

                var nodeTree = node[nestedKey];
                if (nodeTree && nodeTree.length > 0) {
                    this._simplifyTree(
                        nodeTree,
                        nestedKey,
                        depth + 1,
                        copiedNode
                    );
                }
            }
        };

        /**
         * Nodes will
         *  # get a depth
         *  # open parents
         *  # be shown (if matched criteria)
         *  # they create a bidirectional reference..
         *
         * In the end we actually create a nested list representation from the flat list,
         * where "sub" is the children array property. Afterwards we hand the tree to the
         * simplifyTree method, which will handle the directive specific tree generation.
         *
         * @param list
         * @param parentKey
         */
        Simplifier.prototype.simplifyTreeList = function (
            list,
            parentKey,
            elementKey
        ) {
            var tree = []; // array of root elements
            var helpMap = {}; // map id -> element

            list.forEach(function (element) {
                var sParentId = element[parentKey];
                if (helpMap[element[elementKey]]) {
                    var eChildren = helpMap[element[elementKey]].subs;
                    element.subs = eChildren;
                } else {
                    element.subs = [];
                }

                helpMap[element[elementKey]] = element;

                if (!sParentId) {
                    // root element
                    //
                    tree.push(element);
                } else {
                    if (helpMap[sParentId]) {
                        helpMap[sParentId].subs.push(element);
                    } else {
                        helpMap[sParentId] = {
                            subs: [element],
                        };
                    }
                }
            });

            this.simplifyTree(tree, "subs", 0);
        };

        /**
         * returns the simplified tree model
         * @returns {Array}
         */
        Simplifier.prototype.getSimplifiedTree = function () {
            return this.simplifiedTree;
        };

        return Simplifier;
    });
