import has from "has";

export default function (module) {
    module.config([
        "$compileProvider",
        function ($compileProvider) {
            // eslint-disable-line angular/di
            /**
             * https://docs.angularjs.org/guide/production
             *
             * improve performance by only enabling debug information in development mode
             *
             * use angular.reloadWithDebugInfo(); to enable it on the fly
             */
            $compileProvider.debugInfoEnabled(has("development"));
            $compileProvider.aHrefSanitizationWhitelist(
                /^\s*(local|http|https|sbdaily):|data:image\//
            );
        },
    ]);
}
