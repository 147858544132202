import Element from "./Element";

/**
 * base class for all Selectable Elements
 * @constructs SelectableElement
 * @extends WBS.Element
 * @abstract
 * @memberof WBS
 */
function SelectableElement() {
    Element.call(this);

    this.on("selected", this.__onSelected);
    this.on("deselected", this.__onDeselected);
}

SelectableElement.prototype = Object.create(Element.prototype);

/**
 * Determines if the Element is Selectable
 * @type {boolean}
 */
SelectableElement.prototype.selectable = true;

/**
 * Determines if the Element is Selected
 * @type {boolean}
 */
SelectableElement.prototype.selected = false;

/**
 * Handler for onSelected
 * @private
 */
SelectableElement.prototype.__onSelected = function () {
    this.selected = true;
};

/**
 * Handler for onDeselected
 * @private
 */
SelectableElement.prototype.__onDeselected = function () {
    this.selected = false;
};

export default SelectableElement;
