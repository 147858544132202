import angular from "angular";
import noteTemplatesState from "./noteTemplates.route";
import noteTemplatesFilter from "./noteTemplates.filters";
import NoteTemplatesCtrl from "./NoteTemplatesCtrl";
import noteTemplateConfigCardService from "./directives/sbNoteTemplateConfigCard/sbNoteTemplateConfigCard.service";
import ConfigCardSharedService from "./directives/sbNoteTemplateConfigCard/sbConfigCard.service";
import NoteTemplateConfigCard from "./directives/sbNoteTemplateConfigCard/sbNoteTemplateConfigCard.directive";
import noteTemplateEditCardService from "./directives/sbNoteTemplateEditCard/sbNoteTemplateEditCard.service";
import NoteTemplateEditCard from "./directives/sbNoteTemplateEditCard/sbNoteTemplateEditCard.directive";
import noteTemplateListManipulation from "./directives/sbNoteTemplateListManipulation/sbNoteTemplateListManipulation";
import noteTemplateVirtualList from "./directives/sbNoteTemplateVirtualList/sb_note_template_virtual_list.directive";
import dataState from "./directives/sbDataState/sb_data_state.directive";
import noteTemplatesService from "./services/noteTemplatesService";
import noteTemplatesApiService from "./services/note_templates.api.service";
import "angular-ui-router";
import "common/services/services";
import "common/dialog/addTemplate/addTemplateDialog";
import "./dialogs/createNoteTemplate/createNoteTemplate";
import "common/toast/loadingToast";
import "v-accordion";

export default angular
    .module("sbApp.NoteTemplatesModule", [
        "ui.router",

        "ngMaterial",
        "ngMessages",

        "sbApp.services",
        "sbApp.sbCreateNoteTemplateDialog",
        "sbApp.loadingToast",
        "vAccordion",
    ])

    .config(noteTemplatesState)

    .factory("noteTemplatesService", noteTemplatesService)
    .factory("$sbNoteTemplateApi", noteTemplatesApiService)
    .factory("sbConfigCard", ConfigCardSharedService)

    .service("noteTemplateConfigCardService", noteTemplateConfigCardService)
    .service("noteTemplateEditCardService", noteTemplateEditCardService)

    .directive("sbNoteTemplateConfigCard", NoteTemplateConfigCard)
    .directive("sbNoteTemplateEditCard", NoteTemplateEditCard)
    .directive("sbNoteTemplateListManipulation", noteTemplateListManipulation)
    .directive("sbNoteTemplateVirtualList", noteTemplateVirtualList)
    .directive("sbDataState", dataState)

    .filter("filterTemplates", noteTemplatesFilter.filterTemplates)
    .filter("toDate", noteTemplatesFilter.toDate)

    .controller("NoteTemplatesCtrl", NoteTemplatesCtrl);
