import _ from "lodash";
import moment from "moment";
import "moment-timezone";

import ModeBuilder from "../../../../common/plainModels/modeBuilder/mode_builder.class";

export default function BulkChecklistPdfExportCtrl(
    $mdDialog,
    $sbErrorPresenter,
    $sbTracking,
    $sbActivities,
    $sbBulkProcessOperationsService,
    $sbExternalService
) {
    "ngInject";

    var vm = this;

    var _selectedActivities = [];

    vm.submit = submit;
    vm.closeDialog = $mdDialog.cancel;
    vm.onActivitySelectionChange = onActivitySelectionChange;
    vm.onDateChange = onDateChange;
    vm.onAddAdditionalEmails = onAddAdditionalEmails;

    vm.loadingMode = {
        name: "LOADING",
        submit: angular.noop,
    };

    vm.sendToOthersMode = {
        name: "SEND_REPORT_TO_ADDITIONAL_EMAILS",
        submit() {
            return submit("BY_ADDITIONAL_EMAILS");
        },
    };

    vm.emptyMode = {
        name: "EMPTY",
        submit: angular.noop,
    };

    vm.activeSelectionMode = {
        name: "ACTIVE_SELECTION",
        submit() {
            return submit("BY_STAGES");
        },
    };

    vm.activeDateSelectionMode = {
        name: "ACTIVE_DATE_SELECTION",
        submit() {
            return submit("BY_DATE");
        },
    };

    vm.noSelectionMode = {
        name: "NO_SELECTION",
        submit: angular.noop,
    };

    vm.noDateSelectionMode = {
        name: "NO_DATE_SELECTION",
        submit: angular.noop,
    };

    vm.successMode = {
        name: "SUCCESS",
        submit: angular.noop,
    };

    vm.errorMode = {
        name: "ERROR",
        submit: angular.noop,
    };

    $onInit();
    function $onInit() {
        _chooseModeManager();
    }

    function submit(type) {
        _switchTo(vm.loadingMode);

        $sbTracking.deliverablesList.bulk.export.pdfReports(type);
        vm.submitError = null;

        let bulkExport;
        if (type === "BY_STAGES") {
            bulkExport = $sbBulkProcessOperationsService.bulkExportChecklistPDF(
                vm.deliverableSelection,
                _selectedActivities
            );
        } else if (type === "BY_ADDITIONAL_EMAILS") {
            const subscription = $sbExternalService
                .emailChange$()
                .subscribe((email) => {
                    if (email.forwardTo.length > 0) {
                        $sbTracking.deliverablesList.bulk.export.trackEmailAdded();
                    }
                    if (email.text) {
                        $sbTracking.deliverablesList.bulk.export.trackTextAdded();
                    }
                    bulkExport =
                        $sbBulkProcessOperationsService.bulkExportChecklistPDF(
                            vm.deliverableSelection,
                            _selectedActivities,
                            {
                                forwardTo: email.forwardTo,
                                text: email.text,
                            }
                        );
                });
            subscription.unsubscribe();
        } else {
            bulkExport =
                $sbBulkProcessOperationsService.bulkExportChecklistPDFsByDate(
                    vm.selectedDate
                );
        }

        return bulkExport
            .then(function (response) {
                if (response.qaReports) {
                    vm.userEmail = response.requester.email;
                    vm.numberOfReports = response.qaReports;
                    _switchTo(vm.successMode);
                } else {
                    _switchTo(vm.emptyMode);
                }
            })
            .catch(function (error) {
                vm.submitError = error;
                _switchTo(vm.errorMode);
            });
    }

    function onActivitySelectionChange(selectedActivities) {
        _selectedActivities = selectedActivities;

        if (_hasActivitiesSelected()) {
            _switchTo(vm.activeSelectionMode);
        } else {
            _switchTo(vm.noSelectionMode);
        }
    }

    function _hasActivitiesSelected() {
        return _selectedActivities.length > 0;
    }

    function _switchTo(mode) {
        vm.modeManager.switchTo(mode.name);
    }

    /**
     * Handler triggered when user changes selection of date.
     * @param datetime {moment.Moment}
     */
    function onDateChange(datetime) {
        vm.selectedDate = datetime;

        if (moment.isMoment(datetime) && datetime.isValid()) {
            _switchTo(vm.activeDateSelectionMode);
        } else {
            _switchTo(vm.noDateSelectionMode);
        }
    }

    function _chooseModeManager() {
        if (_.isString(vm.templateId)) {
            vm.modeManager = _buildModeManagerForActivityTemplateSelection();
            return _prepareActivitySelectionMode();
        } else {
            vm.modeManager = _buildModeManagerForDateSelection();
            return _prepareDateSelectionMode();
        }
    }

    function _prepareActivitySelectionMode() {
        return $sbActivities
            .getAllActivitiesByDeliverableTemplateID({
                templateId: vm.templateId,
            })
            .then(function (activities) {
                if (!activities) {
                    activities = [];
                }

                vm.activities = activities;

                if (vm.activities.length === 0) {
                    _switchTo(vm.emptyMode);
                } else {
                    _switchTo(vm.noSelectionMode);
                }
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                _switchTo(vm.emptyMode);
            });
    }

    function _prepareDateSelectionMode() {
        vm.selectedDate = moment();
        vm.timezone = moment.tz.guess();
        _switchTo(vm.activeDateSelectionMode);
    }

    function _buildModeManagerForActivityTemplateSelection() {
        return ModeBuilder.create()
            .addMode(vm.loadingMode.name, vm.loadingMode)
            .addMode(vm.emptyMode.name, {
                ...vm.emptyMode,
                text: "DIALOG_BULK_PDF_EXPORT_EMPTY_TEXT",
            })
            .addMode(vm.activeSelectionMode.name, vm.activeSelectionMode)
            .addMode(vm.noSelectionMode.name, vm.noSelectionMode)
            .addMode(vm.successMode.name, vm.successMode)
            .addMode(vm.errorMode.name, vm.errorMode)
            .addMode(vm.sendToOthersMode.name, vm.sendToOthersMode)
            .setDefault(vm.loadingMode.name)
            .start();
    }

    function _buildModeManagerForDateSelection() {
        return ModeBuilder.create()
            .addMode(vm.loadingMode.name, vm.loadingMode)
            .addMode(vm.emptyMode.name, {
                ...vm.emptyMode,
                text: "DIALOG_BULK_PDF_EXPORT_BY_DATE_EMPTY_TEXT",
            })
            .addMode(
                vm.activeDateSelectionMode.name,
                vm.activeDateSelectionMode
            )
            .addMode(vm.noDateSelectionMode.name, vm.noDateSelectionMode)
            .addMode(vm.successMode.name, vm.successMode)
            .addMode(vm.errorMode.name, vm.errorMode)
            .setDefault(vm.loadingMode.name)
            .start();
    }

    function onAddAdditionalEmails() {
        _switchTo(vm.sendToOthersMode);
    }
}
