import htmlTemplate from "./activity_labour.html";

export default function registerComponentTo(module) {
    module.component("sbActivityLabour", {
        templateUrl: htmlTemplate,
        require: {
            activityFormCtrl: "^sbActivityCreator",
        },
        bindings: {
            parent: "<",
            activity: "<?",
        },
        controller: "ActivityCommonInputCtrl",
    });
}
