import angular from "angular";
import BulkChecklistPdfExportCtrl from "./bulk_checklist_pdf_export.controller";
import dialogTemplate from "./bulk_checklist_pdf_export.html";

export default function BulkChecklistPdfExportConfig($mdDialogProvider) {
    "ngInject";

    $mdDialogProvider.addPreset("bulkChecklistPdfExport", {
        methods: ["deliverableSelection", "templateId"],
        options: function () {
            return {
                controller: BulkChecklistPdfExportCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
