export default class ProjectsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a collection of projects
     */
    getCollection() {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns a specific project by given id.
     *
     * @param projectId {string}    UUID
     */
    get(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint allows to modify an existing project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.code {string}        User defined code of the project (short identifier).
     * @param body.name {string}        User defined name of the project.
     * @param body.desc {string}        Any extra details of the project.
     * @param body.language {string}    The used language for this project (emails are send in this language)
     * @param body.image {object}       The attached project image
     * @param body.inspect_app_config {object}    The mobile app can be configured on project level through the following toggles.
     * @param body.scheduling {object}    Scheduling mode is configured at the project level.
     * @param body.quality_feature_config {object}    Some quality features (state, checklist, notes) can be customised on a project level
     * @param body.notification_config {object}    Notifications can be configured on project level though the following toggles.
     * @param body.status {string}      One of: live or archived -> fallback to live
     */
    update(
        projectId,
        {
            code,
            name,
            desc,
            language,
            image,
            inspect_app_config,
            scheduling,
            quality_feature_config,
            notification_config,
            status,
            currency_code,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: {
                code,
                name,
                desc,
                language,
                image,
                inspect_app_config,
                scheduling,
                quality_feature_config,
                notification_config,
                status,
                currency_code,
            },
        });
    }

    /**
     * This endpoint allows to create a new project.
     *
     * @param body {Object}    Request body
     * @param body.code {string}        User defined code of the project (short identifier).
     * @param body.name {string}        User defined name of the project.
     * @param body.desc {string}        Any extra details of the project.
     * @param body.language {string}    The used language for this project (emails are send in this language)
     * @param body.inspect_app_config {object}    The mobile app can be configured on project level through the following toggles.
     * @param body.scheduling {object}    Scheduling mode is configured at the project level.
     */
    create({
        code,
        name,
        desc,
        type,
        start_date,
        end_date,
        language,
        inspect_app_config,
        scheduling,
    }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects`;

        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: {
                code,
                name,
                desc,
                type,
                start_date,
                end_date,
                language,
                inspect_app_config: {
                    block_agg_progress: inspect_app_config.block_agg_progress,
                    geolocation_permission:
                        inspect_app_config.geolocation_permission,
                },
                scheduling,
            },
        });
    }

    /**
     * This endpoint allows to remove a project.
     *
     * @param projectId {string}    UUID
     */
    delete(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
