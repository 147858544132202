import angular from "angular";
import BulkCreateNoteCtrl from "./bulk_create_note.controller";
import dialogTemplate from "./bulk_create_note.html";

export default function BulkCreateNoteConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("bulkCreateNote", {
        methods: ["class", "templateId"],
        options: function () {
            return {
                controller: BulkCreateNoteCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
