import StructureNode from "./StructureNode";

/**
 * Class for the Root Node
 *
 * @param {Object} data - Data displayed in the Node
 * @param {WBS.NodeManager} nodeManager - Object that Manages Node
 * @constructs ProjectNode
 * @extends WBS.StructureNode
 * @memberof WBS
 */
function ProjectNode(data, nodeManager) {
    // call to parent constructor
    //
    StructureNode.call(this, data, nodeManager);
}

/**
 * Setup prototypal inheritance.
 * ProjectNode inherits from StructureNode.
 *
 * @type {StructureNode}
 */
ProjectNode.prototype = Object.create(StructureNode.prototype);

/**
 * @default
 * @type {string}
 */
ProjectNode.prototype.backgroundColor = "#36B381";

/**
 * @default
 * @type {string}
 */
ProjectNode.prototype.backgroundColorHover = "#2B8F67";

/**
 * @default
 * @type {boolean}
 */
ProjectNode.prototype.isProjectNode = true;

export default ProjectNode;
