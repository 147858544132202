import profilePanelTemplate from "./sb_profile_panel.html";
import _ from "lodash";

export default function PanelProviderConfig($mdPanelProvider) {
    "ngInject";
    $mdPanelProvider.definePreset("$sbProfilePanel", {
        controller: PanelMenuCtrl,
        controllerAs: "$ctrl",
        templateUrl: profilePanelTemplate,
        panelClass: "profile-card-container",
        focusOnOpen: false,
        zIndex: 100,
        clickOutsideToClose: true,
        escapeToClose: true,
    });
}

function PanelMenuCtrl(
    mdPanelRef,
    $sbLocale,
    SABLONO_STATES,
    $window,
    $rootScope,
    $state,
    $stateParams,
    $sbAuth,
    $sbAuthStore,
    $sbMembership,
    $mdDialog,
    $sbEulaService,
    AVAILABLE_LANGUAGE_KEYS,
    AVAILABLE_LANGUAGE_CONFIG
) {
    "ngInject";
    var vm = this;

    vm.languageKey = $sbLocale.current();
    vm.AVAILABLE_LANGUAGE_KEYS = AVAILABLE_LANGUAGE_KEYS;
    vm.AVAILABLE_LANGUAGE_CONFIG = AVAILABLE_LANGUAGE_CONFIG;

    $rootScope.$on("$stateChangeSuccess", _setRole);

    vm.session = $sbAuthStore.getLastSuccessfulSignInIdentity();
    vm.eula;

    // init
    init();

    function init() {
        _setRole();
        _loadEula();
    }

    vm.logout = function () {
        if (mdPanelRef) {
            mdPanelRef.close();
        }
        $sbAuth.signOut();
    };

    vm.goToEdit = function () {
        $state.go(SABLONO_STATES.profile);
        if (mdPanelRef) {
            mdPanelRef.close();
        }
    };

    vm.changeLanguage = function () {
        var currentIndex = AVAILABLE_LANGUAGE_KEYS.indexOf(vm.languageKey);
        var newLanguageKey = AVAILABLE_LANGUAGE_KEYS[currentIndex];

        $sbLocale.use(newLanguageKey);

        vm.languageKey = newLanguageKey;

        $rootScope.$emit("onLanguageChange");
    };

    vm.promptForEULA = function () {
        return $mdDialog.show(
            $mdDialog
                .eula()
                .eulaAcceptKey(vm.eula.eulaAcceptKey)
                .activeDeadlineToAccept(
                    vm.eula.activeDeadlineToAccept.format("L")
                )
        );
    };

    function _setRole() {
        vm.role = _.get($sbMembership.currentRole(), "name", "default");
    }

    function _loadEula() {
        $sbEulaService.getEula().then((eula) => {
            vm.eula = eula;
        });
    }
}
