import IssueReportDialogCtrl from "./issue_report.controller";
import dialogTemplate from "./issue_report.html";

export default function IssueReportDialogConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("issueReportDialog", {
        methods: [
            "issueCount",
            "filter",
            "oDataFilter",
            "filterDescription",
            "choice",
        ],
        options: function () {
            return {
                controller: IssueReportDialogCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                clickOutsideToClose: true,
                bindToController: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
