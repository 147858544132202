import angular from "angular";
export default angular
    .module("sbApp.sbBlurOnEnter", [])
    .directive("sbBlurOnEnter", function blurOnEnterDirective() {
        return {
            link: function (scope, element, attrs) {
                element.bind("keypress", function (event) {
                    if (event.which === 13) {
                        element.blur();
                        event.preventDefault();
                    }
                });
            },
        };
    });
