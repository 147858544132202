import angular from "angular";
import processViewerRouteConfig from "./tracker.route";
import trackerCtrl from "./tracker.controller";

import busyIndicationModule from "../../common/dialog/busyIndication/busyIndication";
import DirectivesModule from "../../common/ui-elements/ui-elements";
import ServiceModule from "../../common/services/services";
import ConfigModule from "../../configs/all";
import FeatureFlagsModule from "../../common/feature_flags/index";
import "common/services/oDataService/odata_filter_factory.class";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.tracker", [
        "ngMaterial",
        "ui.router",
        ServiceModule.name,
        DirectivesModule.name,
        ConfigModule.name,
        FeatureFlagsModule.name,
        busyIndicationModule.name,
    ])
    .config(processViewerRouteConfig)
    .controller("TrackerCtrl", trackerCtrl);
/* declaration */
