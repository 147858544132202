import _ from "lodash";
import ChecklistItemHandover from "./sb_checklist_item_handover.model";

function ChecklistHandover(executionChecklist, qualityPipeline) {
    this.name = executionChecklist.details.name;

    this.executionChecklist = executionChecklist.details;
    this.confirmationChecklist = undefined;
    this.reviewChecklists = [];
    this.qualityPipeline = qualityPipeline;
}

ChecklistHandover.prototype.setReviewChecklists = function (checklists) {
    this.reviewChecklists = checklists.map((checklist) => checklist.details);
    return this;
};

ChecklistHandover.prototype.setExecutionChecklist = function (checklist) {
    this.executionChecklist = checklist.details;
    return this;
};

ChecklistHandover.prototype.setConfirmationChecklist = function (checklist) {
    this.confirmationChecklist = checklist.details;
    return this;
};

ChecklistHandover.prototype.getHandoverItems = function () {
    return this._calculateChecklistItemHandovers();
};

ChecklistHandover.prototype.getExecutionItems = function () {
    if (this.executionChecklist) {
        return this.executionChecklist.getItems() || [];
    }
    return [];
};

ChecklistHandover.prototype.getReviewItems = function () {
    if (!_.isEmpty(this.reviewChecklists)) {
        return this.reviewChecklists.reduce((acc, reviewChecklist) => {
            acc.push(reviewChecklist.getItems());
            return acc;
        }, []);
    }
    return [];
};

ChecklistHandover.prototype.getConfirmationItems = function () {
    if (this.confirmationChecklist) {
        return this.confirmationChecklist.getItems() || [];
    }
    return [];
};

/**
 * Combine executionChecklist and confirmationChecklist to provide
 * handoverItems
 *
 * @return Array<ChecklistItemHandover>
 * @private
 */
ChecklistHandover.prototype._calculateChecklistItemHandovers = function () {
    const executionItems = this.getExecutionItems();
    const confirmationItems = this.getConfirmationItems();
    const itemsFromMultipleReviews = this.getReviewItems();

    const handoverItems = executionItems.map((executionItem, index) => {
        const item = new ChecklistItemHandover(
            executionItem.description,
            index + 1,
            this.qualityPipeline.teams.length
        );
        item.setExecutionItem(executionItem);

        return item;
    });

    itemsFromMultipleReviews.forEach(function (singleReviewItems) {
        singleReviewItems.forEach((reviewItem, index) => {
            const partner = handoverItems[index];
            if (partner) {
                partner.addReviewItem(reviewItem);
            }
        });
    });

    confirmationItems.forEach(function (confirmationItem, index) {
        const partner = handoverItems[index];
        if (partner) {
            partner.setConfirmationItem(confirmationItem);
        }
    });

    return handoverItems;
};

export default ChecklistHandover;
