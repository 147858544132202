/**
 * !!!IMPORTANT!!!
 * don't inherit with prototype, this class is designed
 * for multiple inheritance and require a EventDispatcher as super class
 * only inherit by calling the constructor like this:
 * HoverableElement.call(this);
 * @constructs HoverableElement
 * @abstract
 * @memberof WBS
 */
function HoverableElement() {
    this.hoverable = true;
    this.mouseIn = false;
    this.on("mouseIn", HoverableElement.prototype.__onMouseIn);
    this.on("mouseOut", HoverableElement.prototype.__onMouseOut);
}

HoverableElement.prototype.mouseIn = false;
HoverableElement.prototype.hoverable = true;

/**
 * EventHandler for MouseIn
 * @private
 */
HoverableElement.prototype.__onMouseIn = function () {
    this.mouseIn = true;
};

/**
 * EventHandler for MouseOut
 * @private
 */
HoverableElement.prototype.__onMouseOut = function () {
    this.mouseIn = false;
};

export default HoverableElement;
