/**
 * Service that defines all view filter that are used in combination with issues/notes.
 * Every functions returns an instance of ViewFilter
 *              ('common/services/oDataService/odata_view_filter.class')
 */
import moment from "moment";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";
import ViewFilter from "common/services/oDataService/odata_view_filter.class";
import DateViewFilter from "common/services/oDataService/odata_date_view_filter.class";
import ODataEqOption from "../../../common/services/oDataService/odata_eq_option";

export default function issuesFilterService(
    $sbODataViewFilter,
    $sbTemplate,
    $sbFilterMenu,
    $sbODataFilter
) {
    "ngInject";
    ////////
    //
    //  PUBLIC API
    //
    ////////

    return {
        issueTextFilter: issueTextFilter,
        issueKeyFilter: issueKeyFilter,
        issueTypeFilter: issueTypeFilter,
        issueStatusFilter: issueStatusFilter,
        issueAuthorFilter: issueAuthorFilter,
        lastIssueUpdateDateFilter: lastIssueUpdateDateFilter,
        issueCreatedDateFilter: issueCreatedDateFilter,
        issueTemplateKeyFilter: issueTemplateKeyFilter,
        structureFilter: structureFilter,
        deliverableNameCodeDescFilter: deliverableNameCodeDescFilter,
        deliverableTypeFilter: typeFilter,
        deliverableProcessFilter: templateFilter,
        deliverableNameFilter: deliverableNameFilter,
        deliverableCodeFilter: deliverableCodeFilter,
        deliverableDescFilter: deliverableDescFilter,
        activityNameDescFilter: activityNameDescFilter,
        activityNameFilter: activityNameFilter,
        activityDescFilter: activityDescFilter,
        responsibleTeamFilter: responsibleTeamFilter,
        confirmationTeamFilter: confirmationTeamFilter,
        areaManagerFilter: areaManagerFilter,
        removedIssuesFilter: removedIssuesFilter,
    };

    ////////
    //
    //  IMPLEMENTATION
    //
    ////////

    function issueTextFilter(isPrimaryFilter = false) {
        var vf = ViewFilter.createTextFilter(
            "INPUT_ISSUE_TEXT_SEARCH_TITLE",
            "search",
            "ISSUE_TEXT"
        );

        vf.placeholder = "INPUT_ISSUE_TEXT_SEARCH_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function issueKeyFilter(isPrimaryFilter = false) {
        var vf = ViewFilter.createTextFilter(
            "INPUT_ISSUE_KEY_SEARCH_LABEL",
            "key",
            "ISSUE_NAME"
        );

        vf.placeholder = "INPUT_ISSUE_KEY_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    /**
     * Create a view filter for responsible team
     * @param {string} projectId The id of the project this team view filter should represent
     * @returns {*}
     */
    function responsibleTeamFilter(projectId, isPrimaryFilter = false) {
        return $sbODataViewFilter
            .teamFilter(
                projectId,
                "_NOTE_RESPONSIBLE_TEAM",
                "responsibleTeam",
                "RESPONSIBLE_TEAM_ID" // odata key
            )
            .then(function (vf) {
                vf.placeholder =
                    "INPUT_ISSUE_ISSUE_RESPONSIBLE_TEAM_PLACEHOLDER";
                vf.isInReducedFilters = isPrimaryFilter;
                return vf;
            });
    }

    /**
     * Create a view filter for confirmation team
     * @param {string} projectId The id of the project this team view filter should represent
     * @returns {*}
     */
    function confirmationTeamFilter(projectId, isPrimaryFilter = false) {
        return $sbODataViewFilter
            .teamFilter(
                projectId,
                "_NOTE_CONFIRMATION_TEAM",
                "confirmationTeam",
                "CONFIRMATION_TEAM_ID" // odata key
            )
            .then(function (vf) {
                vf.placeholder =
                    "INPUT_ISSUE_ISSUE_CONFIRMATION_TEAM_PLACEHOLDER";
                vf.isInReducedFilters = isPrimaryFilter;
                return vf;
            });
    }

    function issueTypeFilter(isPrimaryFilter = false) {
        var issueTypeColumn = "ISSUE_TYPE";
        var vf = new ViewFilter("INPUT_ISSUE_TYPE_LABEL", "type").setOptions([
            {
                label: "_CLAIM",
                odata: _eq(issueTypeColumn, "CLAIM"),
                name: "claim", // URL
            },
            {
                label: "_OBSTRUCTION",
                odata: _eq(issueTypeColumn, "OBSTRUCTION"),
                name: "obstruction", // URL
            },
            {
                label: "_ISSUE_TYPE_INFO",
                odata: _eq(issueTypeColumn, "INFO"),
                name: "info", // URL
            },
        ]);
        vf.type = ViewFilter.TYPE.CHECKBOX;
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function issueStatusFilter(isPrimaryFilter = false) {
        var issueStatusColumn = "ISSUE_STATUS";
        var vf = new ViewFilter(
            "INPUT_ISSUE_STATUS_LABEL",
            "status"
        ).setOptions([
            {
                label: "_ISSUE_STATUS_OPEN",
                odata: _in(issueStatusColumn, ["A", "OPEN"]),
                name: "open", // URL
            },
            {
                label: "_ISSUE_STATUS_CLOSED",
                odata: _in(issueStatusColumn, ["D", "CLOSED", "CONFIRMED"]),
                name: "closed", // URL
            },
            {
                label: "_ISSUE_STATUS_WFC",
                odata: _in(issueStatusColumn, ["WAITING_FOR_CONFIRMATION"]),
                name: "waiting_for_confirmation", // URL
            },
            {
                label: "_ISSUE_STATUS_REJECTED",
                odata: _in(issueStatusColumn, ["REJECTED"]),
                name: "rejected", // URL
            },
        ]);
        vf.type = ViewFilter.TYPE.CHECKBOX;
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function _eq(columnKey, value) {
        return function (filterFactory) {
            return filterFactory.eq(columnKey, value);
        };
    }

    function _ne(columnKey, value) {
        return function (filterFactory) {
            return filterFactory.ne(columnKey, value);
        };
    }

    function _in(columnKey, values) {
        return function (filterFactory) {
            return filterFactory.in(columnKey, values);
        };
    }

    function issueTemplateKeyFilter(isPrimaryFilter = false) {
        var vf = new ViewFilter(
            "INPUT_ISSUE_TEMPLATE_KEY_LABEL",
            "templateKey"
        );
        vf.setODataHandler(function (factory, value) {
            return factory.block(
                new ODataFilterFactory()
                    .like("ISSUE_CODE", value)
                    .and()
                    .ne("ISSUE_CODE", null)
            );
        });

        vf.placeholder = "INPUT_ISSUE_TEMPLATE_KEY_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function issueAuthorFilter(isPrimaryFilter = false) {
        var vf = new ViewFilter(
            "INPUT_ISSUE_AUTHOR_SEARCH_LABEL",
            "issueAuthor"
        );
        vf.setODataHandler(function (factory, value) {
            return factory.block(
                new ODataFilterFactory()
                    .like("AUTHOR_DB_NAME", value)
                    .or()
                    .like("AUTHOR_DISPLAY_NAME", value)
            );
        });
        vf.type = ViewFilter.TYPE.USER;
        vf.placeholder = "INPUT_ISSUE_AUTHOR_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function lastIssueUpdateDateFilter(isPrimaryFilter = false) {
        var vf = DateViewFilter.createDateFilter(
            "_LAST_INSPECTION",
            "inspection",
            "LAST_UPDATE_TIME",
            [
                $sbODataFilter.odataDateToday("LAST_UPDATE_TIME"),
                $sbODataFilter.odataDateLastWeek("LAST_UPDATE_TIME"),
                $sbODataFilter.odataDateLastMonth("LAST_UPDATE_TIME"),
            ],
            moment().subtract(14, "days")._d,
            new Date()
        ).setClass("sb-filter-inspection");

        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function issueCreatedDateFilter(isPrimaryFilter = false) {
        const vf = DateViewFilter.createDateFilter(
            "_CREATION_DATE",
            "createdAt",
            "ISSUE_TIME_CREATED",
            [
                $sbODataFilter.odataDateToday("ISSUE_TIME_CREATED"),
                $sbODataFilter.odataDateLastWeek("ISSUE_TIME_CREATED"),
                $sbODataFilter.odataDateLastMonth("ISSUE_TIME_CREATED"),
            ],
            moment().subtract(14, "days")._d,
            new Date()
        ).setClass("sb-filter-inspection");

        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function structureFilter(isPrimaryFilter = false) {
        const vf = $sbODataViewFilter.structureFilter(
            "_STRUCTURE",
            "structure",
            "ROOT_COMPONENT_STRUCTURE_SPAN_INDEX"
        );

        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    /**
     * Create view filter for process templates.
     *
     * @returns {PromiseLike<ViewFilter>}
     */
    function templateFilter(isPrimaryFilter = false) {
        return $sbTemplate.getProjectBasedList().then((templates) => {
            const vf = new ViewFilter(
                "INPUT_ISSUE_DELIVERABLE_PROCESS_SEARCH_LABEL",
                "deliverableWorkflow"
            )
                .setOptions(
                    templates.map(function (template) {
                        return new ODataEqOption(
                            template.ID,
                            template.NAME,
                            "ROOT_COMPONENT_TEMPLATE_ID"
                        );
                    })
                )
                .setClass("sb-filter-template");

            vf.type = ViewFilter.TYPE.WORKFLOW;
            vf.isInReducedFilters = isPrimaryFilter;
            return vf;
        });
    }

    function typeFilter(isPrimaryFilter = false) {
        return $sbFilterMenu.fetchDeliverableTypes().then((types) => {
            const vf = new ViewFilter("_DELIVERABLE_TYPE", "deliverableType");
            vf.setOptions(
                types.map(function (type) {
                    return new ODataEqOption(
                        type.name,
                        type.name,
                        "ROOT_COMPONENT_TYPE_NAME"
                    );
                })
            );

            vf.type = ViewFilter.TYPE.SELECT;
            vf.placeholder = "_FILTER_TYPE_TEXT_PLACEHOLDER";
            vf.isInReducedFilters = isPrimaryFilter;
            return vf;
        });
    }

    function deliverableNameCodeDescFilter(isPrimaryFilter = false) {
        const vf = new ViewFilter(
            "INPUT_ISSUE_DELIVERABLE_SEARCH_LABEL",
            "deliverableSearch"
        );
        vf.setODataHandler(function (factory, value) {
            return factory.block(
                new ODataFilterFactory()
                    //deliverable
                    .like("ROOT_COMPONENT_NAME", value)
                    .or()
                    .like("ROOT_COMPONENT_DESC", value)
                    .or()
                    .like("ROOT_COMPONENT_CODE", value)
            );
        });

        vf.placeholder = "INPUT_ISSUE_DELIVERABLE_SEARCH_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function deliverableNameFilter(isPrimaryFilter = false) {
        var vf = ViewFilter.createTextFilter(
            "INPUT_DELIVERABLE_NAME_LABEL",
            "deliverableName",
            "ROOT_COMPONENT_NAME"
        );
        vf.placeholder = "INPUT_DELIVERABLE_NAME_PLACEHOLDER";

        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function deliverableCodeFilter(isPrimaryFilter = false) {
        var vf = ViewFilter.createTextFilter(
            "INPUT_DELIVERABLE_CODE_LABEL",
            "deliverableCode",
            "ROOT_COMPONENT_CODE"
        );
        vf.placeholder = "INPUT_DELIVERABLE_CODE_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function deliverableDescFilter(isPrimaryFilter = false) {
        var vf = ViewFilter.createTextFilter(
            "INPUT_DELIVERABLE_DESC_LABEL",
            "deliverableDesc",
            "ROOT_COMPONENT_DESC"
        );
        vf.placeholder = "INPUT_DELIVERABLE_DESC_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function activityNameDescFilter(isPrimaryFilter = false) {
        var vf = new ViewFilter(
            "INPUT_ACTIVITY_SEARCH_LABEL",
            "activitySearch"
        );
        vf.setODataHandler(function (factory, value) {
            return factory.block(
                new ODataFilterFactory()
                    .like("REF_COMPONENT_NAME", value)
                    .or()
                    .like("REF_COMPONENT_DESC", value)
            );
        });

        vf.placeholder = "INPUT_ACTIVITY_SEARCH_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function activityNameFilter(isPrimaryFilter = false) {
        var vf = ViewFilter.createTextFilter(
            "INPUT_ACTIVITY_NAME_LABEL",
            "activityName",
            "REF_COMPONENT_NAME"
        );
        vf.placeholder = "INPUT_ACTIVITY_NAME_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function activityDescFilter(isPrimaryFilter = false) {
        var vf = ViewFilter.createTextFilter(
            "INPUT_ACTIVITY_DESC_LABEL",
            "activityDesc",
            "REF_COMPONENT_DESC"
        );
        vf.placeholder = "INPUT_ACTIVITY_DESC_PLACEHOLDER";
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function areaManagerFilter(isPrimaryFilter = false) {
        var vf = new ViewFilter("_FILTER_AREA_MANAGER", "areaManagerFilter");

        vf.setODataHandler(function (factory, value) {
            return factory.block(
                new ODataFilterFactory()
                    .eq("ROOT_COMPONENT_AREA_MANAGER_USER_NAME", value)
                    .or()
                    .eq("ROOT_COMPONENT_AREA_MANAGER_USER_NAME", null)
            );
        });

        vf.isDisplayable = false;
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }

    function removedIssuesFilter(isPrimaryFilter = false) {
        var issueStatusColumn = "ISSUE_STATUS";
        var vf = new ViewFilter(
            "INPUT_INCLUDE_REMOVED_ISSUES_LABEL",
            "removed"
        ).setOptions([
            {
                label: "_ISSUE_STATUS_REMOVED",
                odata: _eq(issueStatusColumn, "REMOVED"),
                name: "removed", // URL
            },
            {
                label: "_ISSUE_STATUS_NOT_REMOVED",
                odata: _ne(issueStatusColumn, "REMOVED"),
                name: "notRemoved", // URL
            },
        ]);
        vf.type = ViewFilter.TYPE.CHECKBOX;
        vf.isValueDisplayable = function () {
            const value = this.getValue();
            if (!Array.isArray(value)) {
                // no selection -> show
                return true;
            }
            return value.indexOf("notRemoved") < 0;
        };
        vf.getValuesTranslatableForChips = function () {
            if (this.getTranslatableValue() === undefined) {
                return ["_ALL"]; // i18n key for the no selection case.
            } else {
                return this.getTranslatableValue();
            }
        };
        vf.isInReducedFilters = isPrimaryFilter;
        return vf;
    }
}
