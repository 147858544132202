export default function ($mdDialog, $translate, $log, $sce, Analytics) {
    "ngInject";
    return {
        openAddUserDialog: openAddUserDialog,
        openEditUserDialog: openEditUserDialog,
        openPendingInvitationDialog: openPendingInvitationDialog,
        openCreateTeamDialog: openCreateTeamDialog,
        openEditTeamDialog: openEditTeamDialog,
        openConfirmTeamOverwriteDialog: openConfirmTeamOverwriteDialog,
        openConfirmLossOfAdminRights: openConfirmLossOfAdminRights,
        openConfirmDeleteYourselfDialog: openConfirmDeleteYourselfDialog,
        openConfirmLossOfConstructionManager:
            openConfirmLossOfConstructionManager,
        openConfirmLossOfProjectManagerRights:
            openConfirmLossOfProjectManagerRights,
    };

    function _sortByRole(a, b) {
        try {
            return Number.parseInt(b.ROLE_MASK) - Number.parseInt(a.ROLE_MASK);
        } catch (err) {
            $log.error("Could not convert ROLE_MASK of ", a, b);
            return 0;
        }
    }

    function openAddUserDialog(team, availableRoles) {
        var teamName = team.getDisplayName();

        // translate project team i18n key
        //
        if (team.isProjectTeam) {
            teamName = $sce.getTrustedHtml($translate.instant(teamName));
        }

        return $mdDialog
            .show(
                $mdDialog
                    .addUserToTeam()
                    .title("SECTION_TEAMS_ADD_USER_DIALOG_TITLE")
                    .titleValue(teamName)
                    .titleIcon("mdi mdi-account-multiple")
                    .text("SECTION_TEAMS_ADD_USER_DIALOG_TEXT")
                    .selectRoleText(
                        "SECTION_TEAMS_ADD_USERS_DIALOG_CHANGE_ROLE"
                    )
                    .ok("SECTION_TEAMS_ADD_USER_DIALOG_OK")
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .roles(availableRoles.sort(_sortByRole)) // the available roles
            )
            .then(function (result) {
                Analytics.trackConversion("user invited");
                return result.after.map(function (membership) {
                    return {
                        roleName: membership.roleName,
                        userEmail: membership.userEmail,
                        teamId: team.id,
                    };
                });
            });
    }

    function _sortByTeamName(team1, team2) {
        if (team1.isProjectTeam) {
            return -1;
        } else if (team2.isProjectTeam) {
            return 1;
        }

        return team1.name < team2.name ? -1 : 1;
    }

    function openEditUserDialog(user, availableTeams, availableRoles) {
        return $mdDialog.show(
            $mdDialog
                .addUserToTeam()
                .title("SECTION_TEAMS_EDIT_USER_DIALOG_TITLE")
                .titleValue(user.displayName)
                .titleIcon("mdi mdi-account-multiple")
                .selectRoleText("SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_ROLE")
                .ok("SECTION_TEAMS_EDIT_USER_DIALOG_OK")
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .user(user) // the pre-selected user
                .teams(availableTeams.sort(_sortByTeamName)) // the available teams
                .roles(availableRoles.sort(_sortByRole)) // the available roles
        );
    }

    function openPendingInvitationDialog(
        invitation,
        availableTeams,
        availableRoles
    ) {
        return $mdDialog.show(
            $mdDialog
                .modifyPendingInvitation()
                .title("SECTION_TEAMS_EDIT_INVITATION_DIALOG_TITLE")
                .titleValue(invitation.contact.email)
                .titleIcon("mdi mdi-account-multiple")
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .invitation(invitation)
                .teams(availableTeams.sort(_sortByTeamName)) // the available teams
                .roles(availableRoles.sort(_sortByRole)) // the available roles
        );
    }

    function openCreateTeamDialog() {
        return $mdDialog.show(
            $mdDialog
                .editOrAddTeam()
                .title("SECTION_TEAMS_ADD_NEW_TITLE")
                .titleIcon("mdi mdi-account-multiple")
                .text("SECTION_TEAMS_ADD_NEW_TEXT")
                .ok("ACTION_SAVE")
                .clickOutsideToClose(true)
                .escapeToClose(true)
        );
    }

    function openEditTeamDialog(team) {
        return $mdDialog.show(
            $mdDialog
                .editOrAddTeam()
                .title("SECTION_TEAMS_EDIT_TITLE")
                .titleIcon("mdi mdi-pencil")
                .text("SECTION_TEAMS_EDIT_TEXT")
                .ok("ACTION_SAVE")
                .team(team)
                .clickOutsideToClose(true)
                .escapeToClose(true)
        );
    }

    function _createConfirmDialog(i18n) {
        return $mdDialog
            .confirm()
            .title(i18n.title)
            .titleIcon("mdi mdi-alert")
            .content(i18n.content)
            .cancel("NO")
            .ok("YES");
    }

    function openConfirmTeamOverwriteDialog() {
        return $mdDialog.show(
            _createConfirmDialog({
                title: "CONFIRM_TEAM_OVERWRITE_CONFIRM_TITLE",
                content: "CONFIRM_TEAM_OVERWRITE_CONFIRM_TEXT",
            })
        );
    }

    function openConfirmLossOfAdminRights() {
        return $mdDialog.show(
            _createConfirmDialog({
                title: "SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TITLE",
                content: "SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TEXT",
            })
        );
    }

    function openConfirmLossOfProjectManagerRights() {
        return $mdDialog.show(
            _createConfirmDialog({
                title: "SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TITLE",
                content: "SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TEXT",
            })
        );
    }

    function openConfirmDeleteYourselfDialog() {
        return $mdDialog.show(
            _createConfirmDialog({
                title: "SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TITLE",
                content: "SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TEXT",
            })
        );
    }

    function openConfirmLossOfConstructionManager() {
        return $mdDialog.show(
            _createConfirmDialog({
                title: "SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TITLE",
                content:
                    "SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TEXT",
            })
        );
    }
}
