import angular from "angular";
import moment from "moment";
import _ from "lodash";
import SbTeam from "../../domain/sb_team.class";

export default function ProjectSettingsCtrl(
    $q,
    $mdDialog,
    $mdToast,
    $state,
    $sbPermission,
    $sbErrorPresenter,
    $sbProjectSettingsService,
    $sbCurrentProject,
    $sbProjectApi,
    $sbProjectCurrentSettings,
    $sbCalendarRepository,
    $sbTracking,
    $sbDialog,
    $sbMembership,
    loadingToast,
    Analytics,
    PROJECT_SCHEDULING_MODES,
    $sbTeam,
    $sbColor,
    $sbExternalService,
    EnvironmentFlags
) {
    "ngInject";
    var vm = this;

    vm.ready = false;
    vm.isSaveInProgress = false;
    vm.EnvironmentFlags = EnvironmentFlags;
    vm.isProjectModified = isProjectModified;
    vm.deleteProject = deleteProject;
    vm.archiveProject = archiveProject;
    vm.checkCalendar = checkCalendar;
    vm.getCalendarErrorMessages = getCalendarErrorMessages;
    vm.onAddExceptionDay = onAddExceptionDay;
    vm.onDeleteExceptionDay = onDeleteExceptionDay;
    vm.onChangeExceptionDay = onChangeExceptionDay;
    vm.onModeChanged = onModeChanged;

    vm.submitCalendar = submitCalendar;
    vm.submitProjectInformation = submitProjectInformation;
    vm.submitSchedulingMode = submitSchedulingMode;
    vm.submitInspectSettings = updateProject;
    vm.submitQualityPrivacySettings = updateProject;
    vm.submitEmailNotificationsSettings = updateProject;
    vm.submitIssueConfirmationSettings = updateProject;
    vm.trackQualityPrivacyChangeSetting = trackQualityPrivacyChangeSetting;
    vm.trackEmailChangeSetting = trackEmailChangeSetting;
    vm.trackMobileChangeSetting = trackMobileChangeSetting;
    vm.onIssueMandatorinessChanged = onIssueMandatorinessChanged;
    vm.onIssueConfirmationTeamChanged = onIssueConfirmationTeamChanged;
    vm.trackNoteConfirmationChangeSetting = trackNoteConfirmationChangeSetting;

    vm.detailsProperties = [
        "code",
        "name",
        "desc",
        "language",
        "startDate",
        "dueDate",
        "currencyCode",
    ];
    vm.inspectProperties = [
        "inspectAllowAggProgress",
        "activateSignatureForWorkTeam",
        "activateSignatureForInspectionTeam",
        "geolocationPermission",
    ];
    vm.qualityPrivacyProperties = ["activateLimitedNoteVisibility"];
    vm.emailNotificationsProperties = [
        "activateDailyUpdate",
        "activateRealTimeUpdate",
        "activateWeeklyReminder",
        "activateWeeklyLookAhead",
        "activateObstructionNotifications",
        "activateQualityNotifications",
    ];

    vm.issueConfirmationWorkflowProperties = [
        "isObstructionConfirmationMandatory",
        "obstructionTeamId",
        "isQualityConfirmationMandatory",
        "qualityTeamId",
    ];

    vm.$onInit = _activate;
    vm.teams = [];
    const noDefaultTeam = SbTeam.createNoDefaultPreselectedConfirmationTeam(
        $sbColor.color.TRANSPARENT
    );
    const reportersTeam = SbTeam.createReportersTeam(
        $sbColor.color.TRANSPARENT
    );

    function _activate() {
        // we create a copy of the current project, so we can modify it and
        // save only if we press SAVE
        $sbProjectApi
            .refresh($sbCurrentProject.pick("id"))
            .then((project) => {
                vm.currentProject = project;

                vm.modifiedProject = angular.copy(vm.currentProject);

                vm.iAmAdmin = $sbPermission.hasAdminPermissions(
                    vm.currentProject.privileges
                );

                vm.iAmInProjecteam = $sbMembership.currentTeam().isProjectTeam;

                vm.iCanChangeConfiguration = vm.iAmAdmin && vm.iAmInProjecteam;

                vm.hasDuplicateDates = false;

                setInitialSchedulingModeState(vm.modifiedProject);

                // We get the calendar and check for user defined custom dates on deliverable activities
                return $q
                    .all([_getProjectCalendar(), _getTeams()])
                    .then(([calendar, teams]) => {
                        vm.currentCalendar = calendar;
                        vm.modifiedCalendar = angular.copy(calendar);

                        vm.teams = [noDefaultTeam, reportersTeam, ...teams];

                        vm.ready = true;
                    });
            })
            .catch($sbErrorPresenter.catch);
    }

    function _getProjectCalendar() {
        return $sbCalendarRepository.get(vm.currentProject.id);
    }

    function _getTeams() {
        return $sbTeam.getTeams(vm.currentProject.id);
    }

    /**
     * getCalendarErrorMessages - Check if the calendar is correct
     *
     * @param  {Object} form      - The form calendar passed from the view model
     * @return {String|Boolean}   - If there is an error the error string is passed, otherwise it returns false
     */
    function getCalendarErrorMessages(form) {
        if (_noWorkingDaysSelected()) {
            return "ERROR_NO_WORKDAYS_SELECTED_MESSAGE";
        } else if (form.$invalid && form.timezone.$valid) {
            return "ERROR_NO_WORKHOURS_SELECTED_MESSAGE";
        } else if (form.$invalid && !form.timezone.$valid) {
            return "ERROR_NO_TIMEZONE_SELECTED_MESSAGE";
        } else if (!vm.modifiedCalendar.TIMEZONE) {
            return "ERROR_INVALID_TIMEZONE_SELECTED_MESSAGE";
        } else {
            return false;
        }
    }

    /**
     * Submit the calendar object to the server
     */
    function submitCalendar() {
        var newCalendar = angular.copy(vm.modifiedCalendar);

        loadingToast.show("INFO_CALENDAR_EDIT_LOADING_TITLE");

        $sbCalendarRepository
            .update(newCalendar)
            .then(function () {
                vm.currentCalendar = angular.copy(vm.modifiedCalendar);
                loadingToast.hide();
                $mdToast.show(
                    $mdToast
                        .simple()
                        .content("INFO_CALENDAR_EDIT_SUCCESS_TITLE")
                        .position("top right")
                );
            })
            .catch(function (error) {
                // we can't use finally and hide the toast.
                // the success toast will be dismissed instead
                loadingToast.hide();
                throw error;
            })
            .catch(function (error) {
                if (
                    error.message ===
                    "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION"
                ) {
                    $sbTracking.leanBoard.session.concurrentSession(
                        "Tried to change a deliverable that has been changed in another session"
                    );
                    return $sbDialog.openModifiedInConcurrentSessionDialog(
                        error
                    );
                } else {
                    $sbErrorPresenter.catch(error);
                }
            })
            .finally(function () {
                vm.isSaveInProgress = false;
            });
    }

    /**
     * isCalendarCorrect - Checks if the calendar changed,
     * if the form is valid and
     * if there is at least one working day selected
     *
     * @param  {Object} form - The calendar form to check
     * @return {Boolean}     - true if the calendar is correct
     */
    function checkCalendar(form) {
        return (
            (!angular.equals(vm.modifiedCalendar, vm.currentCalendar) ||
                _isCalendarExceptionsChanged()) &&
            !getCalendarErrorMessages(form) &&
            !form.$invalid &&
            !vm.hasDuplicateDates
        );
    }

    /**
     * Handle basic information update in project and ask for custom date treatment
     * if the planned project start date changed.
     */
    function submitProjectInformation() {
        return updateProject();
    }

    /**
     * Handle scheduling mode update in project and ask for custom date treatment.
     */
    function submitSchedulingMode() {
        var selectedMode = vm.scheduling.selected;

        return updateProject().then(function () {
            if (selectedMode === PROJECT_SCHEDULING_MODES.EDGES) {
                Analytics.trackEvent(
                    "Scheduling",
                    "Mode",
                    "Changed to automated"
                );
            }

            if (selectedMode === PROJECT_SCHEDULING_MODES.DATES) {
                Analytics.trackEvent("Scheduling", "Mode", "Changed to manual");
            }
        });
    }

    /**
     * Submit the project object to the server
     */
    function updateProject() {
        vm.isSaveInProgress = true;

        var backendReadyProject = angular.copy(vm.modifiedProject);

        loadingToast.show("INFO_PROJECT_EDIT_LOADING_TITLE");

        return $sbProjectApi
            .edit(backendReadyProject)
            .then(function (newProject) {
                //Updating project on toolbar and navbar
                vm.currentProject = angular.copy(newProject);
                vm.modifiedProject = angular.copy(vm.currentProject);

                setInitialSchedulingModeState(vm.modifiedProject);

                $mdToast.show(
                    $mdToast
                        .simple()
                        .content("INFO_PROJECT_EDIT_SUCCESS_TITLE")
                        .position("top right")
                );
                $sbExternalService.setActiveProject(newProject);

                return newProject;
            })
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                loadingToast.hide();
                vm.isSaveInProgress = false;
                $sbProjectCurrentSettings.clear();
            });
    }

    /**
     * Delete the current project
     */
    function deleteProject() {
        $mdDialog
            .show(
                $sbProjectSettingsService.prepareDeleteProjectDialog(
                    vm.currentProject.name,
                    vm.currentProject.id
                )
            )
            .catch((err) => {
                if (err) {
                    $sbErrorPresenter.catch(err);
                }
            });
    }

    function archiveProject() {
        $sbTracking.projectSettings.changeStatus("Archive Project");

        var confirmDialog = $mdDialog
            .confirm()
            .content("ARCHIVE_PROJECT_CONFIRMATION");

        $mdDialog
            .show(confirmDialog)
            .then(function () {
                return $sbProjectApi.archive(vm.currentProject);
            })
            .then(function () {
                $state.go("sablono.projectSelection");
            })
            .catch((err) => {
                if (err) {
                    $sbErrorPresenter.catch(err);
                }
            });
    }

    /**
     * Check if the project object changed
     */
    function isProjectModified(properties) {
        if (properties) {
            return !angular.equals(
                _.pick(vm.currentProject, properties),
                _.pick(vm.modifiedProject, properties)
            );
        } else {
            return !angular.equals(vm.currentProject, vm.modifiedProject);
        }
    }

    function onAddExceptionDay() {
        vm.modifiedCalendar.exceptionDates.push({
            label: "",
            date: moment.utc().startOf("day"),
        });
        _checkDuplicatedExceptionDays();
    }

    function onDeleteExceptionDay(day) {
        _.remove(vm.modifiedCalendar.exceptionDates, function (exceptionDay) {
            return exceptionDay === day;
        });
        _checkDuplicatedExceptionDays();
    }

    function onChangeExceptionDay(dayIndex, day) {
        vm.modifiedCalendar.exceptionDates[dayIndex] = day;
        _checkDuplicatedExceptionDays();
    }

    function _checkDuplicatedExceptionDays() {
        // Convert dates to timestamps to make the duplicates check easier
        var exceptionDatesTimestamps = _.map(
            vm.modifiedCalendar.exceptionDates,
            function (exceptionDate) {
                return exceptionDate.date.unix();
            }
        );

        // Check length of array after removing duplicated timestamps
        vm.hasDuplicateDates =
            _.uniq(exceptionDatesTimestamps).length !==
            exceptionDatesTimestamps.length;
    }

    function _isCalendarExceptionsChanged() {
        var isCalendarExceptionsChanged = false;
        if (
            vm.currentCalendar.exceptionDates.length !==
            vm.modifiedCalendar.exceptionDates.length
        ) {
            isCalendarExceptionsChanged = true;
        } else {
            _.forEach(
                vm.currentCalendar.exceptionDates,
                function (exceptionDay, index) {
                    if (
                        !_.isEqualWith(
                            exceptionDay,
                            vm.modifiedCalendar.exceptionDates[index],
                            compareLabelAndDate
                        )
                    ) {
                        isCalendarExceptionsChanged = true;
                        return false; // break foreach loop - the exception days list changed
                    }
                }
            );
        }

        return isCalendarExceptionsChanged;
    }

    /**
     * Check if the exception days have been editied from the original saved exception days
     * as part of the condition to enable the save button for that section
     */
    function compareLabelAndDate(objA, objB) {
        var isEqualLabel = objA.label === objB.label;
        var isEqualMoment = moment(objA.date).isSame(objB.date, "day");

        return isEqualLabel && isEqualMoment;
    }

    /**
     * Check if there are working day selected
     */
    function _noWorkingDaysSelected() {
        return (
            !vm.modifiedCalendar.DAYS ||
            !vm.modifiedCalendar.DAYS.some(function (v) {
                return v === true;
            })
        );
    }

    function onModeChanged(mode) {
        setSelectedSchedulingMode(mode);
        updateSchedulingModeChangeIndicator(mode);
    }

    function setInitialSchedulingModeState(project) {
        // scheduling mode initial setting
        //
        vm.scheduling = {
            initialMode: project.schedulingMode,
            selected: project.schedulingMode,
            hasChanged: false,
            isUpdating: false,
        };
    }

    function updateSchedulingModeChangeIndicator(mode) {
        vm.scheduling.hasChanged = mode !== vm.scheduling.initialMode;
    }

    function setSelectedSchedulingMode(mode) {
        vm.scheduling.selected = mode;
        vm.modifiedProject.schedulingMode = mode;
    }

    function trackQualityPrivacyChangeSetting(action, isOn) {
        const onOrOff = !isOn ? "ON" : "OFF";
        return ["Project Settings", "Quality privacy", action + " " + onOrOff];
    }
    function trackEmailChangeSetting(action, isOn) {
        const onOrOff = !isOn ? "ON" : "OFF";
        return ["Project Settings", "Emails", action + " " + onOrOff];
    }

    function trackMobileChangeSetting(action, isOn) {
        const onOrOff = !isOn ? "ON" : "OFF";
        return ["Project Settings", "Mobile App", action + " " + onOrOff];
    }

    function trackNoteConfirmationChangeSetting(action, isOn) {
        const onOrOff = !isOn ? "ON" : "OFF";
        return [
            "Project Settings",
            "Issue confirmation",
            action + " " + onOrOff,
        ];
    }

    function onIssueConfirmationTeamChanged(issueType, team) {
        switch (issueType) {
            case "obstruction":
                _updateObstructionTeam(team);
                break;
            case "quality":
                _updateQualityTeam(team);
                break;
        }
    }

    function _updateObstructionTeam(team) {
        vm.modifiedProject.obstructionTeamId = team.id;
    }

    function _updateQualityTeam(team) {
        vm.modifiedProject.qualityTeamId = team.id;
    }

    function onIssueMandatorinessChanged(issueType) {
        switch (issueType) {
            case "obstruction":
                _onObstructionMandatorinessChanged();
                break;
            case "quality":
                _onQualityMandatorinessChanged();
                break;
        }
    }

    function _onObstructionMandatorinessChanged() {
        let defaultObstructionTeam;
        if (vm.modifiedProject.isObstructionConfirmationMandatory) {
            defaultObstructionTeam = reportersTeam;
        } else {
            defaultObstructionTeam = noDefaultTeam;
        }
        _updateObstructionTeam(defaultObstructionTeam);
    }

    function _onQualityMandatorinessChanged() {
        let defaultQualityTeam;
        if (vm.modifiedProject.isQualityConfirmationMandatory) {
            defaultQualityTeam = $sbTeam.findProjectTeam(vm.teams);
        } else {
            defaultQualityTeam = noDefaultTeam;
        }
        _updateQualityTeam(defaultQualityTeam);
    }
}
