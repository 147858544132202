import angular from "angular";
import has from "has";
import intercomEventConstants from "./intercomEventConstants";
import intercomService from "./intercomService";
import intercomChatService from "./intercomChatService";

// use different intercom app ID based on system
//
var INTERCOM_EU_APP_ID = "h98bkap4"; // prod
var INTERCOM_UK_APP_ID = "h98bkap4"; // prod (currently EU and UK are the same)
var INTERCOM_TEST_APP_ID = "per6cs0q"; // testing app

let INTERCOM_APP_ID = INTERCOM_TEST_APP_ID;
if (has("app_sablono_com")) {
    INTERCOM_APP_ID = INTERCOM_EU_APP_ID;
}
if (has("uk_sablono_com")) {
    INTERCOM_APP_ID = INTERCOM_UK_APP_ID;
}

console.debug("Setup intercom app ID: %c" + INTERCOM_APP_ID, "color: #FF4500"); // eslint-disable-line

export default angular
    .module("sbApp.intercom", ["angular-intercom"])
    .constant("INTERCOM_APP_ID", INTERCOM_APP_ID)
    .constant("TRACKED_EVENTS", intercomEventConstants)
    .factory("intercomService", intercomService)
    .factory("intercomChatService", intercomChatService)
    .config(function ($intercomProvider, INTERCOM_APP_ID) {
        "ngInject";

        // Either include your app_id here or later on boot
        $intercomProvider.appID(INTERCOM_APP_ID);

        // you can include the Intercom's script yourself or use the built in async loading feature
        $intercomProvider.asyncLoading(true);
    });
