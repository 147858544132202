import angular from "angular";
import moment from "moment";
import htmlTemplate from "./date_range_picker.tmpl.html";

export default angular
    .module("sbApp.common.components.dateRangePicker", [
        "sbApp.services",
        "sbApp.constants",
    ])
    .component("sbDateRangePicker", {
        templateUrl: htmlTemplate,
        bindings: {
            startDate: "<",
            endDate: "<",
            unsetUnavailable: "<",
            hasRange: "<",
            show: "@",
            onDatesChanged: "&",
            unsetLabel: "@?",
        },
        controller: DateRangePickerCtrl,
    });

function DateRangePickerCtrl($sbDates, $sbProject, $stateParams, DATE_TYPES) {
    "ngInject";
    var vm = this;

    vm.DATE_TYPES = DATE_TYPES;

    vm.$onInit = $onInit;
    vm.onDateChange = onDateChange;

    function $onInit() {
        enableDatePickers(vm.show);

        var durationInDays = $sbDates.durationBetweenStartAndEndDate(
            vm.startDate,
            vm.endDate,
            "days"
        );
        var isRangeEnabled =
            vm.hasRange &&
            vm.show === DATE_TYPES.START_AND_DUE_DATE &&
            hasStartAndEndDate(vm.startDate, vm.endDate) &&
            durationInDays !== 0;

        vm.keepRange = {
            isEnabled: isRangeEnabled,
            isChecked: false,
            isActive: hasStartAndEndDate(vm.startDate, vm.endDate),
            duration: durationInDays,
        };

        return _fetchProjectCalendar($stateParams.projectId);
    }

    function _fetchProjectCalendar(projectId) {
        return $sbProject.getCalendar(projectId).then(function (calendar) {
            vm.timezone = calendar.TIMEZONE;
            vm.calendar = calendar;
            return calendar;
        });
    }

    function hasStartAndEndDate(startDate, endDate) {
        return startDate !== null && endDate !== null;
    }

    /**
     * MUTATES the time of start and end date.
     *
     * Start date gets the earliest working hour of the first shift.
     * End date gets latest working hour of the last shift.
     *
     * @param {moment} startDate
     * @param {moment} endDate
     */
    function setWorkingHoursFor(startDate, endDate) {
        if (startDate !== null) {
            var startOfWork = vm.calendar.calcStartHourOfWorkday(
                startDate.isoWeekday()
            );
            startDate
                .hours(startOfWork[0])
                .startOf("hour")
                .minutes(startOfWork[1]);
        }

        if (endDate !== null) {
            var endOfWork = vm.calendar.calcEndHourOfWorkday(
                endDate.isoWeekday()
            );
            endDate.hours(endOfWork[0]).startOf("hour").minutes(endOfWork[1]);
        }
    }

    function enableDatePickers(type) {
        switch (type) {
            case DATE_TYPES.START_AND_DUE_DATE:
                vm.hasStartDate = true;
                vm.hasEndDate = true;
                break;
            case DATE_TYPES.START_DATE:
                vm.hasStartDate = true;
                vm.hasEndDate = false;
                vm.endDate = null;
                break;
            case DATE_TYPES.DUE_DATE:
                vm.hasStartDate = false;
                vm.hasEndDate = true;
                vm.startDate = null;
                break;
            case DATE_TYPES.STAGE_DUE_DATE:
                vm.hasStartDate = false;
                vm.hasEndDate = true;
                vm.startDate = null;
                break;
            default:
                vm.hasStartDate = true;
                vm.hasEndDate = true;
        }
    }

    function onDateChange(datetime, type) {
        if (type === DATE_TYPES.START_DATE) {
            vm.startDate = datetime;
        }

        if (type === DATE_TYPES.DUE_DATE) {
            vm.endDate = datetime;
        }

        vm.keepRange.isActive = hasStartAndEndDate(vm.startDate, vm.endDate);

        if (
            vm.keepRange.isEnabled &&
            vm.keepRange.isActive &&
            vm.keepRange.isChecked
        ) {
            if (type === DATE_TYPES.START_DATE) {
                vm.startDate = moment(datetime);
                vm.endDate = moment(datetime).add(
                    vm.keepRange.duration,
                    "days"
                );
            }

            if (type === DATE_TYPES.DUE_DATE) {
                vm.startDate = moment(datetime).subtract(
                    vm.keepRange.duration,
                    "days"
                );
                vm.endDate = moment(datetime);
            }
        }

        setWorkingHoursFor(vm.startDate, vm.endDate);

        vm.onDatesChanged({
            start: vm.startDate,
            end: vm.endDate,
        });
    }
}
