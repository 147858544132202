import lateActivitiesTemplate from "./sb_late_activities_panel.html";

export default function PanelProviderConfig($mdPanelProvider) {
    "ngInject";
    $mdPanelProvider.definePreset("$sbLateActivitiesPanel", {
        controller: function (mdPanelRef) {
            "ngInject";
            var vm = this;
            vm.panelRef = mdPanelRef;

            vm.onClose = function () {
                mdPanelRef.close();
            };
        },
        controllerAs: "$ctrl",
        templateUrl: lateActivitiesTemplate,
        focusOnOpen: false,
        zIndex: 50,
        clickOutsideToClose: true,
        escapeToClose: true,
        hasBackdrop: true,
    });
}
