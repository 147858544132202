export default class MembershipInvitationsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns membership invites by project id
     *
     * @param projectId {string}    UUID
     */
    getByProject(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/membership-invitations`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns membership invites for current user
     */
    getForCurrentUser() {
        const endpointUrl = `/api/${this.API_VERSION}/api/membership-invitations`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint accepts pending invite for current user
     */
    accept(invitationId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/membership-invitations/${invitationId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: { status: "accepted" },
        });
    }

    /**
     * This endpoint rejects pending invite for current user
     */
    reject(invitationId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/membership-invitations/${invitationId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }

    /**
     * This endpoint invites a new user to the project
     */
    create(projectId, { email, team_id, role_name }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/membership-invitations`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: {
                contact: {
                    email,
                },
                membership: {
                    team_id,
                    role_name,
                },
            },
        });
    }
}
