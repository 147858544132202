import moment from "moment";
import ViewFilter from "common/services/oDataService/odata_view_filter.class";
import DateViewFilter from "common/services/oDataService/odata_date_view_filter.class";

export default function ($sbODataFilter) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        createProjectFilter: createProjectFilter,
        createAuthorFilter: createAuthorFilter,
        createUploadDateFilter: createUploadDateFilter,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function createProjectFilter(projectId) {
        return new ViewFilter("", "projectId")
            .setODataHandler(function (factory, value) {
                return factory.eq("PROJECT_ID", value);
            })
            .enableNullCondition("PROJECT_ID")
            .setDisplayable(false)
            .setValue(projectId);
    }

    function createAuthorFilter() {
        var authorFilter = ViewFilter.createTextFilter(
            "INSPECTION_DETAIL_FILTER_AUTHOR",
            "author",
            "AUTHOR_DISPLAY_NAME"
        );
        authorFilter.isInReducedFilters = true;
        return authorFilter;
    }

    function createUploadDateFilter() {
        var vf = DateViewFilter.createDateFilter(
            "INSPECTION_DETAIL_FILTER_TIMESTAMP",
            "inspection",
            "CREATED_AT",
            [
                $sbODataFilter.odataDateToday("CREATED_AT"),
                $sbODataFilter.odataDateLastWeek("CREATED_AT"),
                $sbODataFilter.odataDateLastMonth("CREATED_AT"),
            ],
            moment().subtract(14, "days")._d,
            new Date()
        );
        vf.isInReducedFilters = true;
        return vf;
    }
}
