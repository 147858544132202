export default function (
    $scope,
    PROJECT_CONFIG,
    $timeout,
    $sbPermission,
    $sbProject,
    $sbFeatureDecisions,
    EnvironmentFlags
) {
    "ngInject";
    let vm = this;

    const importTypes = [
        {
            name: "ACTION_NAV_TO_IMPORT_EXCEL",
            icon: "sb-icon-excel_outline sb-icon-excel",
            stateName: "sablono.project.import.excel",
        },
        //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
        // {
        //     name: "ACTION_NAV_TO_IMPORT_PRIMAVERA",
        //     image: "images/import/primavera.png",
        //     stateName: 'sablono.project.import.xml({src: "PRIMAVERA"})',
        // },
        // {
        //     name: "ACTION_NAV_TO_IMPORT_MSPROJECT",
        //     icon: "sb-icon-msproject_outline sb-icon-msproject",
        //     stateName: 'sablono.project.import.xml({src: "MSP"})',
        // },
    ];

    const project = $sbProject.getCurrent();

    if ($sbPermission.hasPlanPermissions(project.privileges)) {
        importTypes.push({
            name: "SECTION_TOOLS_IMPORT_PACKAGE",
            icon: "sb-icon-excel_outline sb-icon-excel",
            stateName: "sablono.project.import.packages",
        });

        importTypes.push({
            name: "SECTION_TOOLS_IMPORT_ACTIVITIES",
            icon: "sb-icon-excel_outline sb-icon-excel",
            stateName: "sablono.project.import.activityData",
        });
    }

    importTypes.push({
        name: "ACTION_NAV_TO_IMPORT_ASTA",
        image: "images/import/asta.png",
        stateName: 'sablono.project.import.xml({src: "ASTA"})',
    });

    if ($sbFeatureDecisions.isFeatureEnabled(EnvironmentFlags.ShowOldImports)) {
        importTypes.push(
            {
                name: "SECTION_TOOLS_IMPORT_STATES",
                icon: "sb-icon-excel_outline sb-icon-excel",
                stateName: "sablono.project.import.progress",
            },
            {
                name: "SECTION_TOOLS_IMPORT_DATES",
                icon: "sb-icon-excel_outline sb-icon-excel",
                stateName: "sablono.project.import.dates",
            }
        );
    }

    vm.importTypes = importTypes;
}
