import Branch from "./Branch";
/**
 * Class for Horizontal Branches (Hierarchy Connection between Nodes with depth > 1)
 *
 * @param {Object} rootNode - Root Node of Branch
 * @param {Array} structureNodeList - Elements connected to Root Node via Branch
 * @constructs HorizontalBranch
 * @extends WBS.Branch
 * @memberof WBS
 */
function HorizontalBranch(rootNode, structureNodeList) {
    // call constructor of parent class
    //
    Branch.call(this, rootNode, structureNodeList);
}

/**
 * Setup prototypal inheritance.
 * HorizontalBranch inherits from Branch.
 *
 * @type {WBS.Branch}
 */
HorizontalBranch.prototype = Object.create(Branch.prototype);

/**
 * Calculates and draws the Branch
 */
HorizontalBranch.prototype.calcBranch = function () {
    // call method of parent class
    //
    Branch.prototype.calcBranch.call(this);

    if (!this.rootNode || this.structureNodeList.length === 0) {
        return;
    }

    var allX = this.getAllX(),
        allY = this.getAllY(),
        minX,
        maxX,
        minY,
        maxY,
        centerY,
        g;

    allX.push(0);
    minX = Math.min.apply(Math, allX);
    maxX = Math.max.apply(Math, allX);

    allY.push(0);
    minY = Math.min.apply(Math, allY);
    maxY = Math.max.apply(Math, allY);

    centerY = (minY + maxY) / 2;

    g = this.branch.graphics;

    //draw hoizontal line
    //
    g.moveTo(minX, centerY).lineTo(maxX, centerY);

    //draw line from root
    //
    g.moveTo(0, this.rootNode.height / 2 + 1).lineTo(0, centerY);

    //draw lines to structure nodes
    //
    this.structureNodeList.forEach(function (node) {
        g.moveTo(node.x, node.y - node.node.height / 2 - 1).lineTo(
            node.x,
            centerY
        );
    }, this);
};

export default HorizontalBranch;
