import angular from "angular";
import $sbServices from "./../../common/services/services";
import intercomModule from "./../../common/intercom/intercomModule";
import calendarExceptionModule from "./sbCalendarDaysList/calendar_days_list.component";
import schedulingModeModule from "./sbSchedulingMode/scheduling_mode.component";
import ProjectSettingsCtrl from "./project_settings.controller";
import ProjectSettingsService from "./project_settings.service";
import projectSettingsRoute from "./project_settings.route";
import loadingToastModule from "./../../common/toast/loadingToast";
import "angular-ui-router";
import confirmDestructiveActionDialogModule from "../../common/dialog/confirmDestructiveActionDialog/confirm_destructive_action.module";

// define module
//
export default angular
    .module("sbApp.ProjectSettingsModule", [
        "ui.router",
        $sbServices.name,
        intercomModule.name,
        loadingToastModule.name,
        calendarExceptionModule.name,
        schedulingModeModule.name,
        confirmDestructiveActionDialogModule.name,
    ])
    .config(projectSettingsRoute)
    .service("$sbProjectSettingsService", ProjectSettingsService)
    .controller("ProjectSettingsCtrl", ProjectSettingsCtrl);
