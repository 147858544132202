import activityDetailsPanelTemplate from "./sb_deliverable_activity_details_panel.html";
import ActivityDetailsPanelController from "./sb_deliverable_activity_details_panel.controller";

export default function PanelProviderConfig($mdPanelProvider) {
    "ngInject";
    $mdPanelProvider.definePreset("$sbActivityDetailsPanel", {
        controller: ActivityDetailsPanelController,
        controllerAs: "$ctrl",
        templateUrl: activityDetailsPanelTemplate,
        focusOnOpen: false,
        zIndex: 100,
        clickOutsideToClose: true,
        escapeToClose: true,
        hasBackdrop: true,
    });
}
