import _ from "lodash";
import visualizationListTemplateHtml from "./visualizations.html";
import sketchVisualizationTemplateHtml from "./states/sketchVisualization/sketch_visualization.html";
import visualizationConfigTemplateHtml from "./states/visualizationConfig/visualization_config.html";

export default function ($stateProvider, SABLONO_STATES) {
    "ngInject";

    $stateProvider.state(SABLONO_STATES.visualization, {
        templateUrl: visualizationListTemplateHtml,
        controller: "visualizationListCtrl",
        controllerAs: "$ctrl",
        url: "/visualization",
        gaPageTrack: "/visualization", // google analytics extension
        data: {
            title: "SECTION_VISUALIZATION_PAGE_TITLE",
            category: "REVIEW", // PLAN or WORK
            searchEnabled: true,
            searchPlaceholder: "INFO_PLACEHOLDER_SEARCH_BAR_VISUALIZATION",
        },
    });

    $stateProvider.state(SABLONO_STATES.visualizationConfiguration, {
        url: "/configure",
        gaPageTrack: "/visualization/configuration", // google analytics extension
        templateUrl: visualizationConfigTemplateHtml,
        controller: "VisualizationConfigCtrl",
        controllerAs: "config",
        data: {
            category: "REVIEW",
            title: "SECTION_VISUALIZATION_PAGE_TITLE",
            icon: "sb-icon-room",
            hasBackButton: true,
            backButtonTarget: SABLONO_STATES.visualization,
            searchEnabled: false,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";

            intercomService.track(TRACKED_EVENTS.ENTERED_VISUALIZATION_PAGE);
        },
    });

    var commonStateConfig = {
        templateUrl: sketchVisualizationTemplateHtml,
        controller: "SketchVisualizationCtrl",
        controllerAs: "sketch",
        data: {
            category: "REVIEW",
            title: "SECTION_VISUALIZATION_PAGE_TITLE",
            icon: "sb-icon-room",
            hasBackButton: true,
            backButtonTarget: SABLONO_STATES.visualization,
            searchEnabled: false,
        },
        onEnter: function (
            intercomService,
            TRACKED_EVENTS,
            $state,
            visualizationService
        ) {
            "ngInject";

            visualizationService.determineStrategyByState(this.name);
            intercomService.track(TRACKED_EVENTS.ENTERED_VISUALIZATION_PAGE);
        },
    };

    var visualizationGeneratedStateConfig = _.assign(
        {
            gaPageTrack: "/visualization/generated", // google analytics extension
            url: "/generated/:id?mode",
        },
        commonStateConfig
    );

    var visualizationUploadedStateConfig = _.assign(
        {
            gaPageTrack: "/visualization/upload", // google analytics extension
            url: "/upload/:id?mode",
        },
        commonStateConfig
    );

    $stateProvider.state(
        SABLONO_STATES.visualizationGenerated,
        visualizationGeneratedStateConfig
    );
    $stateProvider.state(
        SABLONO_STATES.visualizationSketch,
        visualizationUploadedStateConfig
    );
}
