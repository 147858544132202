import PresentableError from "../../../../common/errors/PresentableError";
import { combineXlsContentWithServerResponse } from "../../helpers/import/combine_xls_content_with_server_response";
import { mapToRequestModel } from "../../helpers/import/map_xls_to_request_model";

export default function ImportProgressFacadeService(
    $sbFileReader,
    $sbActivityJobsApi,
    $sbParseExcel,
    $sbProject
) {
    "ngInject";

    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        readFile: readFile,
        sendExcelDataToServer: sendExcelDataToServer,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function readFile(file) {
        return $sbFileReader
            .readFile(file, "readAsBinaryString")
            .then(_parseExcelFile)
            .catch(() => {
                throw new PresentableError(
                    "",
                    "DIALOG_READING_FILE_ERROR_TITLE",
                    "DIALOG_READING_FILE_ERROR_CONTENT"
                );
            });
    }

    function _parseExcelFile(fileData) {
        try {
            var workbook = $sbParseExcel.loadFile(fileData.rawData);
            var excelData = $sbParseExcel.readSheet(
                workbook,
                $sbParseExcel.ROW
            );

            return _setRawDataPreview(excelData);
        } catch {
            throw new PresentableError(
                "",
                "DIALOG_IMPORT_FILE_ERROR_TITLE",
                "DIALOG_IMPORT_FILE_ERROR_CONTENT"
            );
        }
    }

    function _setRawDataPreview(excelData) {
        if (!excelData) return;

        const headers = _deriveHeaders(excelData);
        return {
            headers,
            content: excelData.data,
            // This is setting the property that should be sorted in the header.
            // We have to map this if we want headers to be sortable on
            // sbTable directive.
            sortable: headers.map(function (header) {
                return header.field;
            }),
        };
    }

    function _deriveHeaders(excelData) {
        return excelData.headers.map(function (fieldHead, index) {
            return {
                name: fieldHead,
                field: index.toString(),
            };
        });
    }

    function sendExcelDataToServer(xls) {
        const projectId = $sbProject.getCurrentProjectId();
        return $sbActivityJobsApi
            .create(projectId, {
                type: "BULK_IMPORT_USER_DEFINED_DATES",
                definition: {
                    deliverables: mapToRequestModel(
                        xls,
                        ([
                            // Expected excel template format:
                            deliverableCode, // 0
                            activityName, // 1
                            startDate, // 2
                            endDate, // 3
                            ,
                            duration, // 5
                            unit, // 6
                            projectId, // 7
                        ]) => ({
                            projectId,
                            deliverable: {
                                code: deliverableCode,
                                projectId,
                            },
                            activity: {
                                name: activityName,
                                start_date:
                                    $sbParseExcel.sanitizeAndFormatDate(
                                        startDate
                                    ),
                                end_date:
                                    $sbParseExcel.sanitizeAndFormatDate(
                                        endDate
                                    ),
                                duration: {
                                    value: $sbParseExcel.sanitizeField(
                                        duration
                                    ),
                                    unit: $sbParseExcel.sanitizeField(unit),
                                },
                            },
                        })
                    ),
                },
            })

            .then((response) => {
                const combinedContent = combineXlsContentWithServerResponse(
                    xls.content,
                    response,
                    (activity, deliverable) => [
                        activity.result.code,
                        deliverable.code,
                        activity.name,
                        activity.start_date,
                        activity.end_date,
                        activity.duration.value,
                        activity.duration.unit,
                        projectId,
                    ]
                );

                const allUpdatesSuccessful = combinedContent.every(
                    ([result]) => result === "IMPORT_SUCCESS"
                );

                xls.sortable.push(xls.sortable.length + "");

                return {
                    allUpdatesSuccessful,
                    updatedXls: {
                        headers: includeResultInXlsHeaders(xls.headers),
                        content: combinedContent,
                        sortable: xls.sortable,
                    },
                };
            });
    }

    function includeResultInXlsHeaders(headers) {
        let result = [].concat(headers);
        result.splice(4, 1);
        result.unshift({ name: "Result", flex: 2 });
        result.forEach((header, index) => (header.field = index + ""));
        return result;
    }
}
