import mergeTemplateHtml from "./merge.html";

export default function ($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.merge", {
        templateUrl: mergeTemplateHtml,
        controller: "MergeCtrl",
        controllerAs: "merge",
        url: "/merge/{src:[0-9]*}", // a merge is always source key related
        gaPageTrack: "/merge", // google analytics extension
        data: {
            title: "SECTION_MERGE_TITLE",
            subBarTitle: "SECTION_MERGE_TOOLBAR_TITLE",
            category: "PLAN",
        },
        resolve: {
            resolvedSourceObject: function ($sbProject, $stateParams, project) {
                "ngInject";

                return $sbProject
                    .getProjectSource(
                        project.id,
                        Number.parseInt($stateParams.src, 10)
                    )
                    .catch(function (error) {
                        return error;
                    });
            },
        },
    });
}
