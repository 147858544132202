import angular from "angular";
import html from "./user_create.html";

export default {
    templateUrl: html,
    controllerAs: "userCreate",
    bindings: {
        teamName: "@",
        onAddUsers: "&",
        customLabel: "@",
    },
    controller: function UserCreate() {
        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = angular.noop;
        vm.$onChanges = angular.noop;
        vm.$onDestroy = angular.noop;

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////
    },
};
