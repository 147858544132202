import angular from "angular";
import domainService from "./domain.service";
import Activity from "./sb_activity.class";
import Group from "./sb_group.class";
import Deliverable from "./sb_deliverable.class";
import BaseComponent from "./sb_base_component.class";
import Note from "./sb_note.class";
import Calendar from "./sb_calendar.class";
import Team from "./sb_team.class";
import ProcessTemplate from "./sb_process_template.class";
import StructureNode from "./sb_structure_node.class";
import Project from "./sb_project.class";
import { ProjectSource } from "./sb_project_source.class";
import ActivityState from "./sb_activity_state.class";

export default angular
    .module("sbApp.domain", [])
    .constant("ACTIVITY_TRANSITIONS", {
        COULD_NOT_START: BaseComponent.COULD_NOT_START_ENUM,
        REVIEW_CHECKLIST: "REVIEW_CHECKLIST",
        NOT_STARTED: BaseComponent.NOT_STARTED_ENUM,
        IN_PROGRESS: BaseComponent.IN_PROGRESS_ENUM,
        DONE: BaseComponent.DONE_ENUM,
    })
    .constant("ACTIVITY_STATES", {
        NOT_STARTED: ActivityState.NOT_STARTED,
        STARTED: ActivityState.STARTED,
        DONE: ActivityState.DONE,
        WAITING_FOR_CONFIRMATION: ActivityState.WAITING_FOR_CONFIRMATION,
        REJECTED: ActivityState.REJECTED,
        CONFIRMED: ActivityState.CONFIRMED,
    })
    .factory("$sbDomain", domainService)
    .value("SbActivity", Activity)
    .value("SbActivityGroup", Group)
    .value("SbDeliverable", Deliverable)
    .value("SbBaseComponent", BaseComponent)
    .value("SbNote", Note)
    .value("SbCalendar", Calendar)
    .value("SbTeam", Team)
    .value("SbProject", Project)
    .value("SbProjectSource", ProjectSource)
    .value("SbStructureNode", StructureNode)
    .value("SbProcessTemplate", ProcessTemplate);
