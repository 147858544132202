import angular from "angular";

export default function (
    $mdSidenav,
    SB_DETAILS_OVERLAY_ID,
    $q,
    $log,
    $timeout,
    $rootScope,
    $state,
    Analytics,
    EVENTS
) {
    "ngInject";

    const DEFAULT_TAB = 0;

    /**
     * Delay broadcasting a overlay state change event for x seconds if the new state is "closed"
     * @type {number}
     */
    const CACHE_OVERLAY_CONTENT_MS = 5000;

    let currentId = undefined;
    let currentSelectedTab = DEFAULT_TAB;
    let currentViewType = undefined;
    let cacheTimer = undefined;

    $rootScope.$on(
        EVENTS.DELIVERABLE_OVERLAY_SELECTED_TAB_DID_CHANGE,
        function ($event, state) {
            if (currentViewType === "deliverable") {
                const selectedTab = state.currentSelectedTab;
                if (currentSelectedTab !== selectedTab) {
                    _broadcastAndSaveOverlayState(
                        currentId,
                        "deliverable",
                        selectedTab
                    );
                }
            }
        }
    );

    /**
     * always saves the current opened view and the selected tab in the state params
     */
    $rootScope.$on(
        EVENTS.DETAILS_OVERLAY_STATE_CHANGED,
        function ($event, { current }) {
            const navigationOptions = {
                notify: false,
                location: "replace",
            };
            const params = {
                open: undefined,
                selectedTab: undefined,
            };
            if (current.id && current.viewType) {
                params.open = `${current.viewType}:${current.id}`;
                params.selectedTab = current.selectedTab || undefined;
            }
            $state.go($state.$current.name, params, navigationOptions);
        }
    );

    $rootScope.$on(
        EVENTS.DETAILS_OVERLAY_STATE_CHANGED,
        function ($event, { current = {}, previous = {} }) {
            if (!current.id || current.id === previous.id) {
                return;
            }
            if (current.viewType === "activity") {
                Analytics.trackEvent(
                    "sb_details_overlay",
                    "click",
                    "open activity details overlay"
                );
            } else if (current.viewType === "deliverable") {
                Analytics.trackEvent(
                    "Details Overlay",
                    "Open",
                    "from " + $state.current.name
                );
            }
        }
    );

    /**
     * opens the overlay if it finds an id and view type in the given state params and
     * selects the tab defined in the params
     * @param {{open: string, selectedTab: number}} stateParams - url state params
     * @returns {Promise}
     */
    function openFromStateParamsIfNeeded(stateParams) {
        if (!angular.isString(stateParams.open)) {
            return $q.reject();
        }
        const [type, id] = stateParams.open.split(":");
        if (!id) {
            // fallback to classic where open is just an id
            return toggleView(
                "deliverable",
                stateParams.open,
                stateParams.selectedTab
            );
        }
        return toggleView(type, id, stateParams.selectedTab);
    }

    /**
     * if the overlay is open and shows the given view, the overlay is closed.
     * Otherwise, the overlay is opened for the request view and id
     *
     * @param {String} viewType      - One of deliverable | activity
     * @param {String} id            - The id of the deliverable | activity | checklist to show information of.
     * @param {Number} [selectedTab] - Selected tab id in the overlay(default to ACTIVITIES)
     * @param {String} [mode]        - If the overlay should be shown in narrow mode
     * @return {Promise}             - Resolved when the sidenav has finished opening or has finished closing
     */
    function toggleView(viewType, id, selectedTab, mode = "full") {
        if (isOpen() && currentId === id) {
            return close();
        }

        if (["activity", "deliverable", "checklist"].indexOf(viewType) === -1) {
            $log.error("Invalid viewType", viewType);
            return close();
        }

        if (["full", "narrow"].indexOf(mode) === -1) {
            $log.error("Invalid mode", mode);
            return close();
        }

        return _open(id, viewType, selectedTab, mode);
    }

    /**
     * open - Open the overlay
     *
     * @param {String} id            - The id of the deliverable | activity | checklist to show information of.
     * @param {String} viewType      - One of deliverable | activity
     * @param {Number} [selectedTab] - Selected tab id in the overlay(default to ACTIVITIES)
     * @param {String} [mode]        - If the overlay should be shown in narrow mode
     * @return {Promise}             - Resolved when the sidenav has finished opening
     */
    function _open(id, viewType, selectedTab = currentSelectedTab, mode) {
        // stop a potential clear cache event to be emitted
        //
        if (cacheTimer) {
            $timeout.cancel(cacheTimer);
            cacheTimer = undefined;
        }

        // emit that a new state is requested to be shown in the overlay
        //
        _broadcastAndSaveOverlayState(id, viewType, selectedTab, mode);

        return $mdSidenav(SB_DETAILS_OVERLAY_ID).open();
    }

    function _broadcastAndSaveOverlayState(
        id,
        viewType,
        selectedTab = DEFAULT_TAB,
        mode = "full"
    ) {
        const previousId = currentId;
        const previousSelectedTab = currentSelectedTab;
        const previousViewType = currentViewType;
        currentId = id;
        currentSelectedTab = selectedTab;
        currentViewType = viewType;
        $rootScope.$broadcast(EVENTS.DETAILS_OVERLAY_STATE_CHANGED, {
            previous: {
                id: previousId,
                viewType: previousViewType,
                selectedTab: previousSelectedTab,
            },
            current: {
                id,
                selectedTab,
                viewType,
                mode,
            },
        });
    }

    /**
     * close - Close the overlay
     *
     * @return {Promise}              - Resolved when the sidenav has finished closing
     */
    function close() {
        if (cacheTimer) {
            $timeout.cancel(cacheTimer);
            cacheTimer = undefined;
        }

        cacheTimer = $timeout(() => {
            _broadcastAndSaveOverlayState(undefined, undefined, 0);
        }, CACHE_OVERLAY_CONTENT_MS);

        $rootScope.$broadcast(EVENTS.DETAILS_OVERLAY_CLOSED);

        return $mdSidenav(SB_DETAILS_OVERLAY_ID).close();
    }

    /**
     * isOpen - Checks if the overlay is open
     *
     * @return {Boolean}              - Resolved when the sidenav has finished closing
     */
    function isOpen() {
        return $mdSidenav(SB_DETAILS_OVERLAY_ID).isOpen();
    }

    function onClose(callback) {
        return $mdSidenav(SB_DETAILS_OVERLAY_ID).onClose(function () {
            if (angular.isFunction(callback)) {
                callback();
            }
        });
    }

    return {
        openFromStateParamsIfNeeded: openFromStateParamsIfNeeded,
        toggleView: toggleView,
        close: close,
        isOpen: isOpen,
        onClose: onClose,
        _currentStateForTest: () => ({
            currentId,
            currentSelectedTab,
            currentViewType,
        }),
    };
}
