import _ from "lodash";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";
import validator from "./validation_helpers";

export default function (
    $sbCurrentProject,
    $sbDeliverableJobsApi,
    $q,
    $sbJobApi
) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    var service = {
        forDeliverables: associateForDeliverables,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function associateForDeliverables(processTemplateId, deliverables) {
        if (!_.isArray(deliverables) && !_.isString(deliverables)) {
            throw new Error(
                "Invalid parameter. Wrong type for parameter 'deliverables'"
            );
        }

        if (_.isArray(deliverables)) {
            validator.throwOnMissingKeys(deliverables, [
                "ID",
                "PI_TEMPLATE_ID",
            ]);

            deliverables = _filterForApplicableDeliverables(deliverables);

            if (_.isEmpty(deliverables)) {
                return $q.resolve({});
            }
        }

        if (_.isString(deliverables)) {
            deliverables = _extendQueryToMatchOnlyApplicableDeliverables(
                processTemplateId,
                deliverables
            );
        }

        return $sbDeliverableJobsApi
            .createAssignProcessJob($sbCurrentProject.pick("id"), {
                strategy: $sbDeliverableJobsApi.ASSIGN_STRATEGY.ASSOCIATE,
                deliverable_set: deliverables,
                process_template_id: processTemplateId,
            })
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            });
    }

    /**
     *
     * Filter for all deliverables which have no process template associated to them.
     * Return the list of deliverable Ids (UUID).
     *
     * @param {Object[]} deliverables
     * @param {string} deliverables.ID - UUID
     * @param {string} deliverables.PI_TEMPLATE_ID - UUID
     *
     * @returns {string[]}
     * @private
     */
    function _filterForApplicableDeliverables(deliverables) {
        return (deliverables || [])
            .filter(function (deliverable) {
                return deliverable.PI_TEMPLATE_ID === null;
            })
            .map(function (deliverable) {
                return deliverable.ID;
            });
    }

    function _extendQueryToMatchOnlyApplicableDeliverables(
        processTemplateId,
        queryString
    ) {
        var noAssociatedProcessTemplateFilter = new ODataFilterFactory()
            .and()
            .eq("TEMPLATE_ID", null);

        return queryString + " " + noAssociatedProcessTemplateFilter.get();
    }
}
