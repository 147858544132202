import angular from "angular";
import CombineCostGroupActivitiesCtrl from "./combine_cost_group_activities.controller";
import dialogTemplate from "./combine_cost_group_activities.html";

export default function CombineCostGroupActivitiesConfig($mdDialogProvider) {
    "ngInject";

    $mdDialogProvider.addPreset("combineCostGroupActivities", {
        methods: ["costGroup"],
        options: function () {
            return {
                controller: CombineCostGroupActivitiesCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
                preserveScope: true,
            };
        },
    });
}
