import siteDiaryHtml from "./states/site_diary.html";

export default function ($stateProvider, SABLONO_STATES) {
    "ngInject";

    $stateProvider.state(SABLONO_STATES.siteDiary, {
        url: "/site-diary?entry?format",
        templateUrl: siteDiaryHtml,
        controller: "SiteDiaryCtrl",
        controllerAs: "$ctrl",
        gaPageTrack: "/site-diary",
        data: {
            category: "WORK", // REVIEW or PLAN
            title: "ACTION_NAV_TO_SITE_DIARY",
        },
    });
}
