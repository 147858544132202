import angular from "angular";
import htmlTemplate from "./sb_note_template_virtual_list.html";

export default function sbNoteTemplateVirtualList() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        scope: {
            dvrLoader: "=",
            selectionProvider: "=",
            onCardDataSave: "=",
        },
        link: function (scope) {},
        controllerAs: "noteTemplateVirtualList",
        controller: function sbNoteTemplateVirtualListCtrl(
            $scope,
            ISSUE_TEMPLATE_CONFIG
        ) {
            "ngInject";

            var vm = this;

            vm.ISSUE_TYPES_CONFIG = ISSUE_TEMPLATE_CONFIG.TYPES.OPTIONS;
            $scope.$watch("dvrLoader", function (dvrLoader) {
                vm.dvrLoader = dvrLoader;
            });
            $scope.$watch("selectionProvider", function (sProv) {
                vm.selectionProvider = sProv;
            });
            $scope.$watch("onCardDataSave", function (callBack) {
                vm.onCardDataSave = callBack;
            });

            /**
             * The expanded list element
             * @type {Object}
             */
            vm.currentExpandedRow = {};

            vm.getRowTypeClass = function (row) {
                if (row) {
                    return (
                        vm.ISSUE_TYPES_CONFIG[row.TYPE].ICON +
                        " " +
                        vm.ISSUE_TYPES_CONFIG[row.TYPE].CLASS
                    );
                }
            };

            /**
             * Check if an element is expanded.
             * @param {Object} row - the plain JS object (oData notation)
             * @return {boolean} - True if the element is the current expanded one.
             */
            vm.isRowExpanded = function (row) {
                return row ? vm.currentExpandedRow === row : false;
            };

            /**
             * Expand a row element to display details.
             * @type {function}
             */
            vm.expandRow = expandRow;

            vm.dataAvailable = function (loader) {
                return loader && loader._items.length > 0;
            };

            /**
             * Set or unset the current expanded row property of the scope.
             * Add a index value because we need it for reopening on scrolling.
             * @param  {Object} row - The row that will be opened
             * @param  {Number} index - The row index
             */
            function expandRow(row, index) {
                row.index = index;
                if (vm.currentExpandedRow === row) {
                    vm.currentExpandedRow = {};
                } else {
                    vm.currentExpandedRow = row;
                }
            }
        },
    };
}
