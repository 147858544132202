/**
 * Service to make suggestions for input fields in excel data import.
 *
 * @author RIEMI
 *
 * @module inputSuggestion
 */
import angular from "angular";

export default function inputSuggestionFactory(
    ACCEPTED_DATETIME_FORMATS,
    validator,
    $q
) {
    "ngInject";
    var NAME = "name",
        CODE = "code",
        DESCRIPTION = "description",
        COMPLETION_DATE = "completionDate",
        START_DATE = "startDate",
        oValidationMap = {
            name: validator.isArrayOfNotEmptyStrings,
            code: validator.isAllUniqueStringOrNumbers,
            description: validator.isArrayWithAnything,
            completionDate: validator.isArrayOfDates,
            startDate: validator.isArrayOfDates,
        };

    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        generateSuggestion: generateSuggestion,
        getSuggestionBy: getSuggestionBy,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * The Object contains the mapping of components properties to validated columns.
     * Each attribute contains an array with column header strings, which represent
     * the possible columns to choose from.
     *
     * @typedef oSuggestionMap
     * @type {Object}
     *
     * @property {String[]} name - Columns which validate to name schema.
     * @property {String[]} code - Columns which validate to name schema.
     * @property {String[]} description - Columns which validate to name schema.
     * @property {String[]} completionDate - Columns which validate to name schema..
     *
     */

    /**
     * Validate and extract relevant columns from the excel worksheet and map them to
     * the given input values (Name, Code, Description, Completion Date, Priority).
     *
     * @param {Object} oExcelData - The object encapsulating the data of the table.
     * @param {String[]} oExcelData.headers - The table headers of each column.
     * @param {Array[]} oExcelData.data - An data array containing arrays which holds strings with table information column wise.
     *
     * @returns {oSuggestionMap} Map of input fields to array of column headers.
     */
    function generateSuggestion(oExcelData) {
        return $q(function (resolve, reject) {
            var promise = $q.all([
                getSuggestionBy(NAME, oExcelData),
                getSuggestionBy(CODE, oExcelData),
                getSuggestionBy(DESCRIPTION, oExcelData),
                getSuggestionBy(COMPLETION_DATE, oExcelData),
                getSuggestionBy(START_DATE, oExcelData),
            ]);

            promise.then(
                function (aResults) {
                    var oSuggestionMap = {
                        name: [],
                        code: [],
                        description: [],
                        completionDate: [],
                        startDate: [],
                    };

                    Object.keys(oSuggestionMap).forEach(
                        function (sKey, nIndex) {
                            oSuggestionMap[sKey] = aResults[nIndex];
                        }
                    );

                    oSuggestionMap.structure = oExcelData.headers;

                    //console.debug("Suggestion MAP:", oSuggestionMap);
                    //console.groupEnd();
                    resolve(oSuggestionMap);
                },
                function (error) {
                    reject(error);
                }
            );
        });
    }

    /**
     * The algorithm which traverses the data and validates it for the specific input value.
     * It goes column wise over every entry of the array using the validator service.
     *
     * @param {String} sSearchValue - Search value can be one of (name, code, description, completionDate, priority).
     * @param {Array} aheaders - Array containing table headers (String).
     * @param {Array} aColumns - Array containing arrays with column cells (String).
     * @param {Function} resolve - Callback to resolve promise.
     *
     * @private
     */
    function getXyzColumnSuggestion(sSearchValue, aheaders, aColumns, resolve) {
        //console.debug("Suggestion input:", aheaders, aColumns);
        //console.group("CHECK for '" + sSearchValue + "'");

        var aSuggestions = [];

        aColumns.forEach(function (aCells, nIndex) {
            var aResult = oValidationMap[sSearchValue].call(
                this,
                aCells,
                ACCEPTED_DATETIME_FORMATS
            );

            if (angular.isArray(aResult)) {
                if (aResult[0] === true) {
                    // console.debug("VALID column '" + aheaders[nIndex].replace(/(\r\n|\n|\r)/gm, "") + "'");

                    aSuggestions.push(aheaders[nIndex]);
                } else {
                    // console.debug("INVALID column '" + aheaders[nIndex].replace(/(\r\n|\n|\r)/gm, "") + "'");
                }
            } else {
                if (aResult === true) {
                    // console.debug("VALID column '" + aheaders[nIndex].replace(/(\r\n|\n|\r)/gm, "") + "'");

                    aSuggestions.push(aheaders[nIndex]);
                } else {
                    // console.debug("INVALID column '" + aheaders[nIndex].replace(/(\r\n|\n|\r)/gm, "") + "'");
                }
            }
        });

        // console.log("Suggestion for " + sSearchValue + ":", aSuggestions);
        //console.groupEnd();
        resolve(aSuggestions);
    }

    /**
     * This is a helper function to split up the excel data and delegate work to next
     * function (@see getXyzColumnSuggestion).
     *
     * @param {String} sSearchValue - Search value can be one of (name, code, description, completionDate, priority).
     *
     * @param {Object} oExcelData - The object encapsulating the data of the table.
     * @param {String[]} oExcelData.headers - The table headers of each column.
     * @param {Array[]} oExcelData.data - An data array containing arrays which holds strings with table information column wise.
     *
     * @param {Function} resolve - Callback to resolve promise.
     *
     * @private
     */
    function _get(sSearchValue, oExcelData, resolve) {
        var aheaders = oExcelData.headers,
            aColumns = oExcelData.data;

        getXyzColumnSuggestion.call(
            this,
            sSearchValue,
            aheaders,
            aColumns,
            resolve
        );
    }

    /**
     * Get suggestion for given input as array of table header strings.
     *
     * @param {String} sSearchValue - Search value can be one of (name, code, description, completionDate, priority).
     *
     * @param {Object} oExcelData - The object encapsulating the data of the table.
     * @param {String[]} oExcelData.headers - The table headers of each column.
     * @param {Array[]} oExcelData.data - An data array containing arrays which holds strings with table information column wise.
     *
     * @returns {Promise} The promise resolves with an array of strings.
     */
    function getSuggestionBy(sSearchValue, oExcelData) {
        return $q(function (resolve) {
            //console.group("getNameColumnSuggestion");

            _get(sSearchValue, oExcelData, resolve);

            //console.groupEnd();
        });
    }
}
