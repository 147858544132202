import angular from "angular";
import processViewerRouteConfig from "./process_viewer.route";
import processViewerCtrl from "./process_viewer.controller";
import ProcessViewerProgressService from "./process_viewer_progress.service";
import ProcessViewerLoaderService from "./process_viewer_loader.service";
import ProcessViewerPaginationService from "./process_viewer_pagination.service";
import sbActivityCardComponent from "./sbActivityCard/activity_card.component";
import sbDeliverableCardComponent from "./sbDeliverableCard/deliverable_card.component";
import sbPaginatePageSize from "./paginate/page-size.directive";
import busyIndicationModule from "../../common/dialog/busyIndication/busyIndication";
import DirectivesModule from "../../common/ui-elements/ui-elements";
import ServiceModule from "../../common/services/services";
import ConfigModule from "../../configs/all";
import FeatureFlagsModule from "../../common/feature_flags/index";
import "common/services/oDataService/odata_filter_factory.class";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.processViewer", [
        "ngMaterial",
        "ui.router",
        ServiceModule.name,
        DirectivesModule.name,
        ConfigModule.name,
        FeatureFlagsModule.name,
        busyIndicationModule.name,
    ])
    .config(processViewerRouteConfig)
    .controller("ProcessViewerCtrl", processViewerCtrl)
    .service("$sbProcessViewerProgress", ProcessViewerProgressService)
    .service("$sbProcessViewerLoader", ProcessViewerLoaderService)
    .service(
        "$sbProcessViewerPaginationService",
        ProcessViewerPaginationService
    )
    .component(sbActivityCardComponent.name, sbActivityCardComponent)
    .component(sbDeliverableCardComponent.name, sbDeliverableCardComponent)
    .directive("sbPaginatePageSize", sbPaginatePageSize);
/* declaration */
