function SbEditorTreeNode(data) {
    this.model = data;

    this.children = [];
    this.parent = undefined;

    this.layer = 0;
    this.visible = false;
    this.collapsed = true;
}

SbEditorTreeNode.prototype.setModel = function (data) {
    this.model = data;
};

/**
 *
 * @param {Array<SbEditorTreeNode>} children
 */
SbEditorTreeNode.prototype.setChildren = function (children) {
    this.children = children;
    children.forEach(function (node) {
        node.parent = this;
    }, this);
};

SbEditorTreeNode.prototype.refreshLayer = function (layer) {
    this.layer = layer;
    this.children.forEach(function (c) {
        c.refreshLayer(layer + 1);
    });
};

SbEditorTreeNode.prototype.expand = function () {
    this.collapsed = false;
    return this;
};

SbEditorTreeNode.prototype.collapse = function () {
    this.collapsed = true;
    return this;
};

SbEditorTreeNode.prototype.show = function () {
    this.visible = true;
    return this;
};

SbEditorTreeNode.prototype.hide = function () {
    this.visible = false;
    return this;
};

SbEditorTreeNode.prototype.isExpanded = function () {
    return !this.collapsed;
};

SbEditorTreeNode.prototype.isCollapsed = function () {
    return this.collapsed;
};

export default SbEditorTreeNode;
