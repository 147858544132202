import moment from "moment";

function CostGroupStage(id, name, weight) {
    this.id = id;
    this.name = name;
    this.weight = weight;

    this.namePrefix = undefined;
    this.isConfirmable = true;

    this.inProgress = 0;
    this.isWaitingForConfirmation = 0;
    this.isDone = 0;
    this.total = 0;
    this.asReportedAt = moment();

    this.cost = 0;

    this.processTemplateId = 0;
    this.processTemplateName = "";
}

/**
 * Set stat properties onto stage
 *
 * @param {Object} stats
 * @param {number} stats.started
 * @param {number} stats.waitingForConfirmation
 * @param {number} stats.confirmed
 * @param {number} stats.rejected
 * @param {number} stats.done
 * @param {number} stats.total
 * @param {moment} asReportedAt - based on which time the stats are calculated
 */
CostGroupStage.prototype.setStats = function (stats, asReportedAt) {
    this.asReportedAt = asReportedAt;
    this.inProgress = (stats.started || 0) + (stats.rejected || 0);
    this.isWaitingForConfirmation = stats.waitingForConfirmation || 0;
    this.isDone = (stats.confirmed || 0) + (stats.done || 0);
    this.total = stats.total || 0;
};

/**
 * Set the stage's cost of the total cost group budget
 *
 * @param {number} costGroupBudget
 *
 */
CostGroupStage.prototype.setCostFrom = function (costGroupBudget) {
    this.cost = (this.weight * costGroupBudget) / 100;
};

/**
 * Set the stage's name prefix. The name prefix is considered to be the
 * concatenated name of all parent names. In the world of activities this
 * would be the name of the groups and the name of the process template.
 *
 * @param {string} namePrefix
 *
 */
CostGroupStage.prototype.setNamePrefix = function (namePrefix) {
    this.namePrefix = namePrefix;
};

/**
 * Set the stage's confirmation state. Confirmation can be active or
 * inactive.
 *
 * @param {boolean} isConfirmable
 *
 */
CostGroupStage.prototype.setConfirmable = function (isConfirmable) {
    this.isConfirmable = isConfirmable;
};

/**
 * Get the number of activities in a given state as a percentage of the total number of activities
 *
 * @param {number} numberOfActivitiesInState
 *
 * @returns {number}
 */
CostGroupStage.prototype.getStateAsPercentageOfTotal = function (
    numberOfActivitiesInState
) {
    if (this.total > 0) {
        return (numberOfActivitiesInState / this.total) * 100;
    }

    return 0;
};

/**
 * Check if the stats of this stage are from today
 *
 * @return {boolean}
 */
CostGroupStage.prototype.isReportedAtToday = function () {
    return this.asReportedAt.isSame(moment(), "day");
};

/**
 * Calculate the stage cost for activities which are in progress
 *
 * @returns {number}
 */
CostGroupStage.prototype.calcCostOfBudgetStarted = function () {
    return (
        (this.getStateAsPercentageOfTotal(this.inProgress) * this.cost) / 100
    );
};

/**
 * Calculate the stage cost for activities which are waiting for confirmation
 *
 * @returns {number}
 */
CostGroupStage.prototype.calcCostOfBudgetWaitingForConfirmation = function () {
    return (
        (this.getStateAsPercentageOfTotal(this.isWaitingForConfirmation) *
            this.cost) /
        100
    );
};

/**
 * Calculate the stage cost for activities which are done
 *
 * @returns {number}
 */
CostGroupStage.prototype.calcCostOfBudgetCompleted = function () {
    return (this.getStateAsPercentageOfTotal(this.isDone) * this.cost) / 100;
};

/**
 * Calculate the stage cost for activities which are done as a percentage of the total budget
 *
 * @returns {number}
 */
CostGroupStage.prototype.calcPercentageOfTotalBudgetSpent = function (budget) {
    if (this.total > 0 && budget > 0) {
        return (this.calcCostOfBudgetCompleted() / budget) * 100;
    }

    return 0;
};

/**
 * Set the stage's process template info
 *
 * @param {{id:number,name:string}} processTemplateInfo
 *
 */
CostGroupStage.prototype.setProcessTemplate = function (processTemplateInfo) {
    this.processTemplateId = processTemplateInfo.id;
    this.processTemplateName = processTemplateInfo.name;
};

export default CostGroupStage;
