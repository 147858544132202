import angular from "angular";
import zoomElement from "./content_zoom.html";
export default angular
    .module("sbApp.sbContentZoom", [])
    .directive("sbContentZoom", function ($compile, $timeout, $templateCache) {
        "ngInject";

        return {
            restrict: "A",
            controllerAs: "zoom",
            bindToController: {
                minZoom: "<", // minimum zoom value in %
                maxZoom: "<", // maximum zoom value in %
                step: "<", // button step
                parentContainerId: "@", // content parent to bind ctrl key + scroll event
                viewBox: "<",
            },
            controller: function () {
                var zoom = this;

                zoom.step = zoom.step || 10;
                zoom.minZoom = zoom.minZoom || 1;
                zoom.maxZoom = zoom.maxZoom || 1000;
                zoom.sliderStep = 0.001;
                // default zoom percentage is 100
                zoom.zoom = 100;
            },
            link: function (scope, element, attr, ctrl) {
                /////////////////////
                //
                //      Variables
                //
                /////////////////////

                var container = angular.element(element[0]);
                var parentElement = document.getElementById(
                    ctrl.parentContainerId
                );

                ctrl.zoomIn = zoomIn;
                ctrl.zoomOut = zoomOut;

                /////////////////////
                //
                //      WATCHER
                //
                /////////////////////

                scope.$watch("zoom.zoom", function (value) {
                    _updateZoom(value);
                });

                if (parentElement) {
                    angular
                        .element(parentElement)
                        .on("mousewheel DOMMouseScroll", _onScroll);
                }

                const svgRenderedWatcher = scope.$watch(
                    "zoom.viewBox",
                    function (viewBox) {
                        if (!viewBox || !parentElement) {
                            // svg not yet rendered
                            return;
                        }
                        const ASPECT_RATIO_16_9 = 16 / 9;
                        if (
                            viewBox.height * ASPECT_RATIO_16_9 >
                            viewBox.width
                        ) {
                            ctrl.zoom = _calculateDefaultZoom(
                                parentElement,
                                viewBox
                            );
                        }
                        // Remove watcher
                        svgRenderedWatcher();
                    }
                );

                _init();

                /////////////////////
                //
                //      IMPL
                //
                /////////////////////

                function _init() {
                    var buttons = angular.element("<div></div>");

                    buttons.addClass("sb-content-zoom");
                    buttons.html($templateCache.get(zoomElement));

                    $compile(buttons)(scope);
                    // Insert buttons after the container
                    container.after(buttons);

                    _updateZoom(ctrl.zoom);
                }

                function _calculateDefaultZoom(svgContainer, svgViewbox) {
                    const initialScalar =
                        svgContainer.offsetWidth / svgViewbox.width; //what has already scaled the svg
                    const currentSvgHeight = initialScalar * svgViewbox.height;
                    const newScalar =
                        svgContainer.offsetHeight / currentSvgHeight; // by what factor is the current height diff than the offset one
                    return 100 * newScalar; // turn the scalar to a percentage
                }

                /**
                 * This function handles the ctrl key + scroll event - to zoom the svg accordingly
                 */
                function _onScroll(event) {
                    if (event.ctrlKey) {
                        event.preventDefault();
                        $timeout(function () {
                            if (event.wheelDelta > 0) {
                                zoomIn();
                            } else {
                                zoomOut();
                            }
                        }, 0);
                    }
                }

                /**
                 * Updates css properties width and height with given value
                 */
                function _updateZoom(value) {
                    container.css("width", value + "%");
                    container.css("height", value + "%");
                }

                /**
                 * Increase / decrease the zoom value, by a step factor
                 */
                function zoomIn() {
                    if (ctrl.zoom + ctrl.step < ctrl.maxZoom) {
                        ctrl.zoom += ctrl.step;
                    } else {
                        ctrl.zoom = ctrl.maxZoom;
                    }
                }

                function zoomOut() {
                    if (ctrl.zoom - ctrl.step > ctrl.minZoom) {
                        ctrl.zoom -= ctrl.step;
                    } else {
                        ctrl.zoom = ctrl.minZoom;
                    }
                }
            },
        };
    });
