export default function KeyboardWatcherService($document) {
    "ngInject";

    let _currentKey;

    $document.on("keydown", _onKeyDown);
    $document.on("keyup", _onKeyUp);

    return {
        isCurrentKey,
        getCurrentKey,
    };

    function isCurrentKey() {
        return !!_currentKey;
    }

    function getCurrentKey() {
        return _currentKey;
    }

    function _onKeyUp(/* event */) {
        _currentKey = undefined;
    }

    function _onKeyDown(event) {
        _currentKey = event;
    }
}
