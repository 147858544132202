import angular from "angular";
import SbDateController from "./sb_date.controller";
import htmlTemplate from "./sb_date.html";

/**
 * It represents one line of a deliverable date in the
 * deliverable details as part of the deliverable overlay.
 *
 * E.g. the start or end date with an icon and a descriptive label.
 *
 * Additionally the planned vs scheduled state (isConflicting, isPlanned, isScheduled)
 * should be backed into this component later.
 */
export default angular
    .module("sbApp.components.sbDate", [
        "sbApp.sbPropertiesSourceIcon",
        "sbApp.components.sbDates",
    ])
    .component("sbDate", {
        controller: SbDateController,
        templateUrl: htmlTemplate,
        require: {
            datesCtrl: "^sbDates",
        },
        bindings: {
            icon: "@",
            label: "@",
            dateParam: "@",
        },
    });
