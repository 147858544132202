import angular from "angular";

export default angular
    .module("sbApp.autoFocus", [])
    .directive("sbAutoFocus", function autoFocusDirective($timeout) {
        /**
         * from github (bower angular-autofocus)
         * but not working after minification
         * https://github.com/bitliner/angular-auto-focus
         */
        return {
            restrict: "A", // only activate on element attribute
            link: function (scope, element, attrs) {
                var attrExp = attrs.sbAutoFocus || attrs.sbAutofocus;

                var hasMdInputIdAttr = angular.isString(attrs.mdInputId);
                var focusDelay =
                    Number.parseInt(attrs.sbAutoFocusDelay, 10) || 0;

                if (attrExp) {
                    scope.$watch(
                        attrExp,
                        function (value) {
                            if (value || value === "true") {
                                $timeout(setFocus, focusDelay);
                            }
                        },
                        true
                    );
                }

                function setFocus() {
                    var elementToFocus = element[0];
                    var isMdAutoCompleteElement =
                        elementToFocus.tagName === "MD-AUTOCOMPLETE";

                    if (isMdAutoCompleteElement && hasMdInputIdAttr) {
                        var input = element[0].querySelector(
                            "#" + attrs.mdInputId
                        );

                        var isInputPresent = !(
                            angular.isUndefined(input) || input === null
                        );
                        if (isInputPresent) {
                            elementToFocus = input;
                        }
                    }

                    elementToFocus.focus();
                }
            },
        };
    });
