import authTemplateHtml from "./auth.html";
import has from "../../../scripts/common/has/has";

export default function ($stateProvider) {
    "ngInject";

    $stateProvider.state("auth", {
        templateUrl: authTemplateHtml,
        controller: "AuthCtrl",
        controllerAs: "$ctrl",
        url: "/auth",

        resolve: {
            // We have to check the system health to show the maintenance message in case the system is down.
            //
            systemStatus: function checkIfSystemIsAlive($sbSystemStatus) {
                "ngInject";

                return $sbSystemStatus.getSystemStatus();
            },
        },
        onEnter: function ($sbSystemStatus, systemStatus, $state) {
            "ngInject";
            const isOperational = $sbSystemStatus.isOperational(systemStatus);
            if (!isOperational) {
                $state.transition.finally(() =>
                    $state.go("account.maintenance")
                );
            }

            if (has("app_sablono_com") || has("uk_sablono_com")) {
                $state.go("account.signIn");
            }
        },
    });

    $stateProvider.state("auth.otherwise", {
        url: "/*path",
        onEnter: function ($state) {
            $state.go("auth");
        },
    });
}
