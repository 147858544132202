import angular from "angular";
import templateHtml from "./diary_entry.html";

export default {
    name: "sbDiaryEntry",
    templateUrl: templateHtml,
    replace: true,
    bindings: {
        entry: "<",
    },
    controller: angular.noop,
};
