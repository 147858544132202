import templateHtml from "./teams.html";
import "angular-ui-router";

/**
 * This is the configuration for project team state.
 * It uses ui-router to configure the route.
 *
 * @param {Object} $stateProvider - $stateProvider
 */
export default function projectSettingsStateConfig($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.teams", {
        url: "/teams",
        gaPageTrack: "/teams", // google analytics extension
        templateUrl: templateHtml,
        controller: "TeamsCtrl",
        controllerAs: "teams",
        data: {
            category: "PLAN",
            title: "ACTION_NAV_TO_TEAMS",
            icon: "mdi mdi-cogs",
            isSubBarHidden: false,
            searchEnabled: true,
            searchPlaceholder: "_FILTER_SEARCH_TEAM_MEMBERS_PLACEHOLDER",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";

            intercomService.track(TRACKED_EVENTS.ENTERED_PROJECT_TEAM_PAGE);
        },
    });
}
