import NodeContainer from "./NodeContainer";
import VerticalBranch from "../edge/VerticalBranch";

/**
 * Container for Elements with Depth > 1
 * @param {WBS.StructureNode} node - Node inside Contaoner
 * @constructs MinorContainer
 * @extends WBS.NodeContainer
 * @memberof WBS
 */
function MinorContainer(node) {
    NodeContainer.call(this, node, VerticalBranch);
}

MinorContainer.prototype = Object.create(NodeContainer.prototype);
MinorContainer.prototype.checkIsOnStage = true;

/**
 * Creates a Sibling for Node in MinorContainer
 * @param {WBS.StructureNode} node - New Sibling Node
 */
MinorContainer.prototype.createSibling = function (node) {
    var containerElement = new MinorContainer(node);
    node.entity.setGeometry(containerElement);
    this.parent.addChild(containerElement);
    this.refreshLayout();
};

/**
 * Creates a Child for Node in MinorContainer
 * @param {WBS.StructureNode} node - New Child Node
 */
MinorContainer.prototype.createChild = function (node) {
    var containerElement = new MinorContainer(node);
    node.entity.setGeometry(containerElement);
    this.addChild(containerElement);
    this.refreshLayout();
};

export default MinorContainer;
