import angular from "angular";
import moment from "moment";
import _ from "lodash";
import domainModule from "domain/domain.module";
import naturalSortFn from "./natural_sort.pojo";
import pickTextColorBasedOnBgColorW3CFn from "./pickTextColorBasedOnBgColorW3C";
import { transformTextToTextWithHyperlinks } from "../services/textTransform/textTransform";

var languages = {
    DE: "Deutsch (DE)",
    EN: "English (EN)",
    ES: "Español (ES)",
    FR: "Français (FR)",
};

export default angular
    .module("sbApp.filters", [domainModule.name])
    .run(function configMoment() {
        // Override the moment config for the "LTS" format
        //  See: https://momentjs.com/docs/#/customization/long-date-formats/
        //
        // default would be: "Mar 22, 2017" -> but we use it as "3/22"
        //
        //  current usage:
        //      # LeanBoard Header
        //
        moment.updateLocale("es", {
            longDateFormat: {
                LTS: "DD/MM",
            },
        });
        moment.updateLocale("de", {
            longDateFormat: {
                LTS: "D.M",
            },
        });
        moment.updateLocale("en-gb", {
            longDateFormat: {
                LTS: "D/M",
            },
        });
        moment.updateLocale("fr", {
            longDateFormat: {
                LTS: "D/M",
            },
        });
    })
    .filter("shadedHexColor", function shadedHexColor() {
        return function (hex, percent) {
            var hexColorRegex = /^(#)*(([A-Fa-f0-9]{3}){1,2})$/;
            var regexResult = hexColorRegex.exec(hex);
            var c;

            if (regexResult) {
                c = regexResult[2].split("");

                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = c.join("");

                if (angular.isUndefined(percent) || percent >= 1) {
                    return "#" + c;
                }

                // For an amazing explanation check:
                // http://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
                //
                var f = Number.parseInt(c, 16),
                    t = percent < 0 ? 0 : 255,
                    p = percent < 0 ? percent * -1 : percent,
                    R = f >> 16,
                    G = (f >> 8) & 0x00ff,
                    B = f & 0x0000ff;

                var newColor =
                    0x1000000 +
                    (Math.round((t - R) * p) + R) * 0x10000 +
                    (Math.round((t - G) * p) + G) * 0x100 +
                    (Math.round((t - B) * p) + B);

                return "#" + newColor.toString(16).slice(1);
            } else {
                return hex;
            }
        };
    })
    .filter("stripedBackground", function stripedBackground($filter) {
        var shadedHexColorFilter = $filter("shadedHexColor");

        return function (color, isStriped) {
            if (isStriped) {
                return (
                    "repeating-linear-gradient(" +
                    "45deg," +
                    color +
                    "," +
                    color +
                    " 10px," +
                    shadedHexColorFilter(color, 0.4) +
                    " 10px," +
                    shadedHexColorFilter(color, 0.4) +
                    " 20px" +
                    ")"
                );
            } else {
                return shadedHexColorFilter(color);
            }
        };
    })
    .filter("joinBy", function joinBy() {
        return function (input, delimiter) {
            return (input || []).join(delimiter || ",");
        };
    })
    .filter("ellipse", function ellipse() {
        return function (input, size) {
            if (!_.isString(input)) {
                return input;
            }
            if (size >= 0) {
                return input.length > size
                    ? input.slice(0, size) + "..."
                    : input;
            } else {
                if (input.length <= -size) {
                    return input;
                } else {
                    var reducedString = input.slice(
                        input.length + size,
                        input.length
                    );
                    // find next "whitespace"
                    var betterCut = reducedString.indexOf(" ");
                    return "... " + reducedString.slice(betterCut + 1);
                }
            }
        };
    })
    .filter("taskCountFilter", function taskCountFilter() {
        return function (taskCount) {
            if (angular.isNumber(taskCount)) {
                return taskCount;
            }
            return 0;
        };
    })
    .filter("issueCountFilter", function issueCountFilter() {
        return function (issueCount) {
            if (angular.isNumber(issueCount)) {
                return issueCount;
            }
            return 0;
        };
    })
    .filter("imageCountFilter", function imageCountFilter() {
        return function (imageCount) {
            if (angular.isNumber(imageCount)) {
                return imageCount;
            }
            return 0;
        };
    })
    .filter("nospace", function noSpace() {
        return function (value) {
            return !value ? "" : value.replace(/ /g, "");
        };
    })
    .filter("startFrom", function startFrom() {
        return function (input, start) {
            start = +start;
            return input.slice(start);
        };
    })
    .filter("sortCaseInsensitive", function sortCaseInsensitive() {
        return function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        };
    })
    .filter("numberToK", function numberToK($filter) {
        return function (input) {
            if (input > 1000) {
                var integerPart = Number.parseInt(input / 1000, 10);
                var fractionPart = Number.parseInt(
                    (parseFloat(input / 1000) - integerPart) * 10,
                    10
                );
                return (
                    $filter("translate")("_DECIMAL", {
                        n: integerPart,
                        m: fractionPart,
                    }) + "K"
                );
            }
            return input;
        };
    })
    .filter("numberToComma", function numberToComma($translate, $filter) {
        return function (input) {
            var myNumber = $filter("number")(input, 0);
            if ($translate.use().indexOf("de") > -1) {
                myNumber = myNumber.replace(",", ".");
            }
            return myNumber;
        };
    })
    .filter(
        "localizedDecimalNumber",
        function localizedDecimalNumber($translate) {
            return function (input) {
                if (input) {
                    var number = input.toString();
                    if ($translate.use().indexOf("de") > -1) {
                        number = number.replace(".", ",");
                    } else {
                        number = number.replace(",", ".");
                    }
                    return number;
                }
            };
        }
    )
    .filter("asLongLanguage", function asLongLanguage() {
        return function (languageKey) {
            return languages[languageKey];
        };
    })
    .filter("i18n", function i18n() {
        return function (input) {
            return {
                wd: "_wd_ABBREVIATION",
                wh: "_wh_ABBREVIATION",
                d: "_d_ABBREVIATION",
                h: "_h_ABBREVIATION",
            }[input];
        };
    })
    .filter("asSourceIcon", function asSourceIcon() {
        return function (input) {
            switch (input) {
                case "EXCEL":
                    return "images/excel.png";
                case "ASTA":
                    return "images/asta.png";
                //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
                // case "PRIMAVERA":
                //     return "images/primavera.png";
                // case "MSP":
                //     return "images/msp.png";
                case "BIM":
                    return "images/bimplus.png";
                case "BIMplus": //Alias
                    return "images/bimplus.png";
                case "CLONE":
                    return "images/source-cloned.png";
                case "USER":
                case "SABLONO":
                default:
                    return "images/sablono_logo.svg";
            }
        };
    })
    .filter("asStateIcon", function asStateIcon() {
        return function (state) {
            switch (state) {
                case "NOT_STARTED":
                case "OPEN":
                    return "sb-todo mdi mdi-checkbox-blank-circle-outline";
                case null:
                case undefined:
                    return "mdi mdi-minus-circle-outline";
                case "DONE":
                case "CLOSED":
                    return "sb-done mdi mdi-check";
                case "WAITING_FOR_CONFIRMATION":
                    return "sb-wfc mdi mdi-check-all";
                case "CONFIRMED":
                    return "sb-done mdi mdi-check-all";
                case "REJECTED":
                    return "sb-rejected mdi mdi-close";
                case "STARTED":
                default:
                    return "sb-in-progress mdi mdi-sync mdi-rotate-90";
            }
        };
    })
    .filter("asProgressIcon", function asProgressIcon() {
        return function (progress, isConfirmed, confirmationRequired) {
            if (angular.isString(progress)) {
                progress = parseFloat(progress);
            }

            switch (progress) {
                case 0:
                    return "sb-todo mdi mdi-checkbox-blank-circle-outline";
                case 100:
                    if (isConfirmed) {
                        return "sb-done mdi mdi-check-all";
                    }
                    if (confirmationRequired) {
                        return "sb-wfc mdi mdi-check-all";
                    }
                    return "sb-done mdi mdi-check";
                case null:
                case undefined:
                    return "mdi mdi-minus-circle-outline";
                default:
                    return "sb-in-progress mdi mdi-sync mdi-rotate-90";
            }
        };
    })
    .filter("asIssueIcon", function asIssueIcon() {
        return function (input) {
            switch (input) {
                case "CLAIM":
                case "QUALITY_ISSUE":
                    return "sb-claim mdi mdi-alert";
                case "OBSTRUCTION":
                    return "sb-obstruction mdi mdi-texture mdi-rotate-90";
                case "INFO":
                    return "sb-info mdi mdi-information";
                default:
                    return "mdi mdi-alert-circle";
            }
        };
    })
    .filter("asIcon", function asIcon() {
        return function (input) {
            switch (input) {
                case "CLAIM":
                case "QUALITY_ISSUE":
                    return "sb-claim mdi mdi-alert";
                case "OBSTRUCTION":
                    return "sb-obstruction mdi mdi-texture mdi-rotate-90";
                case "INFO":
                    return "sb-info mdi mdi-information";
                case 0:
                case "_PROGRESS_0":
                case "todo":
                    return "sb-todo mdi mdi-checkbox-blank-circle-outline";
                case 100:
                case "_PROGRESS_100":
                case "done":
                    return "sb-done mdi mdi-check";
                case 50:
                case "_PROGRESS_50":
                case "inProgress":
                    return "sb-in-progress mdi mdi-sync mdi-rotate-90";
                case "TOTAL":
                    return "mdi mdi-sigma";
                case "behind":
                    return "sb-task-behind mdi mdi-calendar-clock";
                case "on-time":
                    return "sb-task-on-time mdi mdi-calendar-check";
                case "unknown":
                case "unscheduled":
                    return "sb-task-unknown mdi mdi-calendar-remove";
                case null:
                    return "mdi mdi-minus-circle-outline";
                case undefined:
                    return "mdi mdi-minus-circle-outline";
                default:
                    return "-";
            }
        };
    })
    .filter("i18nRole", function i18nRole() {
        return function (role, type) {
            if (angular.isString(role)) {
                role = role.toUpperCase();
            }

            switch (type) {
                case "initials":
                    return "_ROLE_INITIALS_" + role;

                case "description":
                    return "_ROLE_DESCRIPTION_" + role;
                default:
                    return "_ROLE_" + role;
            }
        };
    })
    .filter("asRoleClass", function asRoleClass() {
        return function (role, type) {
            role = role || "default";
            return type
                ? "sb-role-" + role.toLowerCase() + "-" + type
                : "sb-role-" + role.toLowerCase();
        };
    })
    .filter("asProgressClass", function asProgressClass($sbColor, SbNote) {
        /**
         * @param {Deliverable} deliverable - A sablono Deliverable instance
         */
        return function (deliverable) {
            return $sbColor.classes.fromStatus(
                deliverable.progress,
                deliverable.isBehind(),
                deliverable.getNumberOf(SbNote.QUALITY_ISSUE),
                deliverable.getNumberOf(SbNote.OBSTRUCTION)
            );
        };
    })
    .filter("asPathToParent", function asPathToParent() {
        return function (node, pathAttribute) {
            if (node && node[pathAttribute]) {
                var arrayOfNodes = node[pathAttribute];

                // an array of nodes is concatenated -> string
                if (angular.isArray(arrayOfNodes)) {
                    return arrayOfNodes.join(" / ");
                }

                // if for some reason it is already a string -> return it
                if (angular.isString(arrayOfNodes)) {
                    return arrayOfNodes;
                }
            }
            return "";
        };
    })
    .filter("naturalSort", function naturalSort() {
        return function (items, key1, key2) {
            return items.sort(function (a, b) {
                // try to sort simple values instead
                //
                if (angular.isUndefined(key1)) {
                    if (angular.isString(a) && angular.isString(b)) {
                        return naturalSortFn(a, b);
                    }
                }

                const sortedByKey1 = naturalSortFn(a[key1], b[key1]);

                if (!key2) {
                    return sortedByKey1;
                }

                return sortedByKey1 || naturalSortFn(a[key2], b[key2]);
            });
        };
    })
    .filter("mathAbs", function mathAbs() {
        return function (val) {
            return Math.abs(val);
        };
    })
    .filter(
        "pickTextColorBasedOnBgColorW3C",
        function pickTextColorBasedOnBgColorW3C() {
            return pickTextColorBasedOnBgColorW3CFn;
        }
    )
    .filter("withoutFileExtension", function withoutFileExtension() {
        return function (fileName) {
            return fileName.replace(/\.[^/.]+$/, "");
        };
    })
    .filter("textWithHyperlinks", function textWithHyperlink() {
        return function (text) {
            return transformTextToTextWithHyperlinks(text);
        };
    });
