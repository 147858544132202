import ConstantsModule from "./constants.module";
import "./version.constant";
import "./environment.constant";
import "./odata.constant";
import "./local_storage.constant";
import "./languages.constants";
import "./events.constant";
import "./enumerations.constant";
import "./app_category.constant";
import "./qr_code_template.constant";
import "./sablono_objects.constant";
import "./template.constant";
import "./available_languages.constants";
import "./browser_restrictions.constant";
import "./accepted_datetime_formats.constants";

export default ConstantsModule;
