import angular from "angular";
import htmlTemplate from "./header_bar.html";
export default angular
    .module("sbApp.sbHeaderBarDirective", [])
    .directive("sbHeaderBar", function sbHeaderBarDirective() {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            scope: {
                onSortChange: "=",
                columns: "=",
                groupedBy: "=",
                direction: "=",
            },
            transclude: true,
            replace: true,
            controllerAs: "header",
            bindToController: true,
            controller: function HeaderBarCtrl() {
                var vm = this;
                vm.clear = clearAllSortIndicationExceptFor;

                activate();

                /**
                 * Clear all column sorting except for newly set.
                 *
                 * @param columnKey - The key of the field to exclude from resetting
                 */
                function clearAllSortIndicationExceptFor(columnKey) {
                    var criterias = vm.columns;

                    Object.keys(criterias).forEach(function (key) {
                        if (key !== columnKey) {
                            criterias[key].order = 0;
                        }
                    });
                }

                function activate() {
                    clearAllSortIndicationExceptFor(vm.groupedBy);
                }
            },
        };
    });
