import angular from "angular";
import htmlTemplate from "./sb_deliverable_checklists.html";
import DeliverableChecklistsCtrl from "./sb_deliverable_checklists.controller";
import deliverableChecklistsService from "./sb_deliverable_checklists.service";
import ChecklistItemHandover from "./model/sb_checklist_item_handover.model";
import ChecklistItem from "./model/sb_checklist_item.model";
import Checklist from "./model/sb_checklist.model";
import ChecklistHandover from "./model/sb_checklist_handover.model";
import ChecklistDeliverableItemComponent from "./sbDeliverableCheklistItem/sb_deliverable_checklist_item.component";

export default angular
    .module("sbApp.sbDeliverableChecklists", [
        "sbApp.domain",
        "sbApp.directives",
        "sbApp.services",
        "sbApp.sbDeliverableOverlay",
    ])
    .controller("DeliverableChecklistsCtrl", DeliverableChecklistsCtrl)
    .service("$sbDeliverableChecklists", deliverableChecklistsService)
    .value("ChecklistItemHandover", ChecklistItemHandover)
    .value("ChecklistItem", ChecklistItem)
    .value("Checklist", Checklist)
    .value("ChecklistHandover", ChecklistHandover)
    .component("sbDeliverableChecklists", {
        name: "sbDeliverableChecklists",
        templateUrl: htmlTemplate,
        controller: DeliverableChecklistsCtrl,
        bindings: {
            deliverable: "<",
            teams: "<",
            selectedTeam: "<",
            isLoading: "<",
            onSelectedTeamChange: "&",
        },
    })
    .component("sbDeliverableChecklistItem", ChecklistDeliverableItemComponent);
