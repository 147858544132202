import angular from "angular";
import htmlTemplate from "./attachment_gallery.html";
import _ from "lodash";
import {
    DocumentAttachment,
    ImageAttachment,
} from "./gallery_attachment.model";
import PresentableError from "../../../errors/PresentableError";

/**
 * Default supported file extensions for attachments
 * @type {Set<string>}
 */
const FILE_EXTENSIONS = new Set([".png", ".jpg", ".jpeg", ".gif", ".pdf"]);

export default angular
    .module("sbApp.common.components.attachmentGallery", [])
    .component("sbAttachmentGallery", {
        templateUrl: htmlTemplate,
        bindings: {
            attachmentsVisible: "<?",
            attachments: "<",
            filterExtensions: "<",
            onDelete: "&?",
            onAdd: "&?",
            isUploading: "&?",
        },
        controllerAs: "gallery",
        controller: function AttachmentGalleryController(
            $sbImagesOverlay,
            $sbFilesApi,
            $q,
            $sbImageApi,
            downloadCenterService,
            $sbCurrentProject,
            $sbProjectCurrentSettings,
            $sbErrorPresenter,
            $sbImageUpload,
            $sbFeatureDecisions,
            FeatureFlags
        ) {
            const DEFAULT_GALLERY_SIZE = 6;

            const vm = this;

            let _previousEvent;

            vm.$onInit = $onInit;
            vm.$onChanges = $onChanges;

            vm.isShowable = !vm.onAdd; // by definition when adding is enabled "showing" is disabled
            vm.show = showAttachment;
            vm.scrollRight = scrollRight;
            vm.scrollLeft = scrollLeft;
            vm.isArrowRightActive = isArrowRightActive;
            vm.isArrowLeftActive = isArrowLeftActive;
            vm.onAttachFile = onAttachFile;
            vm.numberOfUploads = 0;

            vm.isImage = (attachment) => attachment instanceof ImageAttachment;
            vm.isDocument = (attachment) =>
                attachment instanceof DocumentAttachment;

            /**
             * Filter the [FILE_EXTENSIONS]
             * @type {*|(function(*): *)}
             */
            vm.filterExtensions =
                vm.filterExtensions ||
                function (params) {
                    return params;
                };

            function $onInit() {
                vm.photoOffset = 0;

                vm.attachmentsVisible =
                    vm.attachmentsVisible || DEFAULT_GALLERY_SIZE;
                vm.photoSize = Math.ceil(100 / vm.attachmentsVisible) + "%";
                if (!vm.isShowable) {
                    vm.attachmentsVisible--;
                }
                vm.ellipsedTextMaxSize = 80;
                vm.allowedExtension = vm.filterExtensions(
                    Array.from(FILE_EXTENSIONS)
                );

                $sbProjectCurrentSettings
                    .getGeolocationSetting()
                    .then((setting) => {
                        vm.keepMetadata = setting;
                    });
            }

            function $onChanges(changes) {
                if (
                    _.get(changes, "attachments.currentValue") !==
                    _.get(changes, "attachments.previousValue")
                ) {
                    const images = changes.attachments.currentValue.filter(
                        this.isImage
                    );
                    images
                        .filter((image) => !image.isThumbDownloaded())
                        .forEach((image) => {
                            downloadThumbImage(image.id).then((dataUri) => {
                                image.thumbDataUri = dataUri;
                            });
                        });
                }
            }

            function downloadThumbImage(imageId) {
                const projectId = $sbCurrentProject.pick("id");
                return $sbImageApi
                    .download(projectId, imageId, {
                        transformResponse: [
                            $sbImageApi.response.transformToDataURL,
                        ],
                        query: {
                            fit: "thumb",
                        },
                    })
                    .then((image) => image.dataUrl)
                    .catch($sbErrorPresenter.catch);
            }

            function onAttachFile($event, file) {
                // This solves the IE duplicating attachments issue
                if (
                    _previousEvent &&
                    _previousEvent.hwTimestamp &&
                    _previousEvent.hwTimestamp === $event.hwTimestamp
                ) {
                    return false;
                } else {
                    _previousEvent = $event;
                }
                switch (file.type) {
                    case "application/pdf":
                        return onAttachDocument($event, file);
                    case "image/jpeg":
                    case "image/png":
                    case "image/gif":
                        return onAttachImage($event, file);
                    default:
                        throw new Error("Unsupported MIME type: " + file.type);
                }
            }

            function onAttachDocument($event, file) {
                const fileSizeInMB = file.size / 1024 / 1024;
                if (fileSizeInMB > 50) {
                    $sbErrorPresenter.catch(
                        new PresentableError("", "", "PDF_EXCEEDS_LIMIT")
                    );
                    return;
                }
                const upload = $sbFilesApi
                    .upload($sbCurrentProject.pick("id"), file, {
                        filename: file.name,
                    })
                    .then(
                        (asset) => new DocumentAttachment(asset.id, file.name)
                    );

                return _doAttach($event, upload);
            }

            function onAttachImage($event, file) {
                const upload = $sbImageUpload
                    .transformAndUploadImage(
                        $sbCurrentProject.pick("id"),
                        file,
                        vm.keepMetadata
                    )
                    .then((image) => new ImageAttachment(image.id));

                return _doAttach($event, upload);
            }

            function _doAttach($event, uploadPromise) {
                onImageUploadStart();
                return uploadPromise
                    .then((newAttachment) => {
                        return vm.onAdd({
                            $event: $event,
                            attachment: newAttachment,
                        });
                    })
                    .catch((err) => {
                        $sbErrorPresenter.catch(err); // handle the error
                    })
                    .finally(() => {
                        return onImageUploadFinished();
                    });
            }

            function onImageUploadStart() {
                vm.isUploading({
                    isUploading: true,
                });
                vm.numberOfUploads++;
                return vm.numberOfUploads;
            }

            function onImageUploadFinished() {
                if (vm.numberOfUploads > 0) {
                    vm.numberOfUploads--;
                }
                if (vm.numberOfUploads < 1) {
                    vm.isUploading({
                        isUploading: false,
                    });
                }
                return vm.numberOfUploads;
            }

            function showAttachment($event, attachment) {
                if (!vm.isShowable) {
                    return;
                }

                if (attachment instanceof ImageAttachment) {
                    return openPhoto($event, attachment);
                }
                if (attachment instanceof DocumentAttachment) {
                    return downloadAttachment($event, attachment);
                }
            }

            function openPhoto($event, attachment) {
                // open the overlay showing images only.
                //  -> focus should be the clicked image.
                const assetId = attachment.id;
                const images = vm.attachments
                    .filter(
                        (attachment) => attachment instanceof ImageAttachment
                    )
                    .map((image) => {
                        return {
                            id: image.id,
                        };
                    });
                const indexOfPhoto = _.findIndex(images, ["id", assetId]);

                $sbImagesOverlay.open($event, images, indexOfPhoto);
            }

            function downloadAttachment($event, attachment) {
                return $sbFilesApi
                    .download($sbCurrentProject.pick("id"), attachment.id, {})
                    .then((xhr) =>
                        downloadCenterService.downloadFrom(
                            xhr,
                            (backendFilename) =>
                                backendFilename || attachment.name
                        )
                    )
                    .catch($sbErrorPresenter.catch);
            }

            function scrollRight() {
                if (isArrowRightActive()) {
                    vm.photoOffset++;
                }
            }

            function scrollLeft() {
                if (isArrowLeftActive()) {
                    vm.photoOffset--;
                }
            }

            function isArrowRightActive() {
                return (
                    vm.attachments &&
                    vm.attachments.length >
                        vm.photoOffset +
                            vm.attachmentsVisible -
                            vm.numberOfUploads
                );
            }

            function isArrowLeftActive() {
                return vm.photoOffset > 0;
            }
        },
    });
