import _ from "lodash";
import moment from "moment";

export default function ($sbFileReader, $sbParseExcel) {
    "ngInject";

    return {
        excelBinaryToRows: excelBinaryToRows,
    };

    function excelBinaryToRows(file, timezone) {
        const xlsData = {
            headers: [],
            content: [],
            sortable: [],
        };

        return $sbFileReader
            .readFile(file, "readAsBinaryString")
            .then((fileData) => {
                try {
                    const workbook = $sbParseExcel.loadFile(fileData.rawData);
                    const rowData = $sbParseExcel.readSheet(
                        workbook,
                        $sbParseExcel.ROW
                    );

                    if (!rowData) return;

                    xlsData.headers = rowData.headers.map(
                        function (fieldHead, index) {
                            return {
                                name: fieldHead,
                                //convert to string for angular filters
                                field: index.toString(),
                            };
                        }
                    );
                    xlsData.content = rowData.data;

                    /* Convert all date fields to Moment with project timezone, and replace
                     * them in-place. This is to have correct times in dates and also to have
                     * them shown correctly in the import preview page.
                     */
                    xlsData.content.forEach((row) =>
                        row.forEach((field, columnIndex) => {
                            row[columnIndex] = dateToMomentWithTimezone(
                                field,
                                timezone
                            );
                        })
                    );

                    // This is setting the property that should be sorted in the header.
                    // We have to map this if we want headers to be sortable on
                    // sbTable directive.
                    xlsData.sortable = xlsData.headers.map(function (header) {
                        return header.field;
                    });

                    return xlsData;
                } catch {
                    const er = new Error("DIALOG_READING_FILE_ERROR_CONTENT");
                    er.name = "DIALOG_READING_FILE_ERROR_TITLE";
                    throw er;
                }
            })
            .catch(() => {
                const er = new Error("DIALOG_IMPORT_FILE_ERROR_CONTENT");
                er.name = "DIALOG_IMPORT_FILE_ERROR_TITLE";
                throw er;
            });

        /**
         * Converts a date field to Moment, with given timezone
         * @param {*} field
         * @param {string | undefined} timezone
         * @returns {moment.Moment | *}
         */
        function dateToMomentWithTimezone(field, timezone) {
            if (!_.isDate(field)) return field;

            return timezone
                ? moment.parseZone(field).tz(timezone, true)
                : moment(field);
        }
    }
}
