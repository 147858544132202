import _ from "lodash";
import moment from "moment";

export default function (
    $sbCurrentProject,
    $q,
    $filter,
    $sbRequest,
    $sbProcessTemplatesApi,
    $sbPerformanceApi
) {
    "ngInject";
    const naturalSort = $filter("naturalSort");

    // this cache is used to cache information as long as no page change occurred
    //
    var cache = null;

    // API
    //
    return {
        get: get,
        getTemplates: getTemplates,
        getTemplate: getTemplate,
        deleteTemplates: deleteTemplates,
        clearCache: clearCache,
        getProjectBasedList: getProjectBasedList,
        asMap: asMap,
        getTemplateDetails: getTemplateDetails,
        getTemplatesStageSuggestions: getTemplatesStageSuggestions,
        getTemplatesForProjects: getTemplatesForProjects,
        getListOfAllActivitiesOfAllTemplates:
            getListOfAllActivitiesOfAllTemplates,
        parseTemplates: parseTemplates,
        addTemplate: addTemplate,
        addTemplateUsingOnlyTheNewInterfaces:
            addTemplateUsingOnlyTheNewInterfaces,
        createTemplateRevision: createTemplateRevision,
    };

    /**
     * Fetch all the content from the given templateId template
     *
     * @param templateId
     */
    function getTemplateDetails(templateId) {
        const projectId = $sbCurrentProject.pick("id");
        return $sbProcessTemplatesApi
            .getLatestRevision(projectId, templateId)
            .then(_mapToOldRevisionInterface);
    }

    /**
     * Get all the templates
     * @return {Promise}
     */
    function getTemplates() {
        return getProjectBasedList();
    }

    function getTemplate(id) {
        return getProjectBasedList().then((templates) =>
            _.find(templates, ["ID", id])
        );
    }

    /**
     * Given a template it tries to delete it
     * @param  {Object} templates - The template to be deleted
     * @return {Promise}
     */
    function deleteTemplates(templates) {
        const projectId = $sbCurrentProject.pick("id");
        const deletePromises = templates.map((template) =>
            $sbProcessTemplatesApi.delete(projectId, template.ID)
        );

        return $q.allSettled(deletePromises);
    }

    /**
     * Get all the templates
     * @param {String} [projectId]- optional
     *
     * @return {PromiseLike}
     */
    function getProjectBasedList(projectId) {
        if (_hasCachedState()) {
            return $q.resolve(cache);
        } else {
            return getTemplatesByProject({ id: projectId })
                .then(function (templates) {
                    return templates.map(_postProcess);
                })
                .then(_addToCache);
        }
    }

    function asMap(projectId) {
        return getProjectBasedList(projectId).then(
            (list) => new Map(list.map((item) => [item.ID, item]))
        );
    }

    function getTemplatesByProject({ id, name }) {
        const projectId = id || $sbCurrentProject.pick("id");
        return $sbProcessTemplatesApi
            .getCollection(projectId)
            .then(({ process_templates }) => {
                const templates = process_templates.map((processTemplate) =>
                    _parseProcessTemplateResponseToOldInterface(
                        { projectId, projectName: name },
                        processTemplate
                    )
                );
                return naturalSort(templates, "NAME");
            });
    }

    function getTemplatesForProjects(projects) {
        const getPromises = projects.map((project) =>
            getTemplatesByProject({ id: project.id, name: project.name })
        );

        return $q.allSettled(getPromises).then((promises) => {
            return promises.reduce((arr, row) => {
                return arr.concat(row.value);
            }, []);
        });
    }

    function addTemplate(template) {
        const project = $sbCurrentProject.get();
        return $sbProcessTemplatesApi
            .create(project.id, {
                name: template.NAME,
                code: template.CODE,
                description: template.DESC,
                category: template.CATEGORY.toLowerCase(),
                language: project.language.toLowerCase(),
            })
            .then(function (template) {
                return _parseProcessTemplateResponseToOldInterface(
                    { projectId: project.id },
                    template
                );
            })
            .then(_addToCache);
    }

    function addTemplateUsingOnlyTheNewInterfaces(template) {
        const project = $sbCurrentProject.get();
        return $sbProcessTemplatesApi
            .create(project.id, {
                name: template.name,
                code: template.code,
                description: template.desc,
                category: template.category.toLowerCase(),
                language: project.language.toLowerCase(),
            })
            .then((template) => {
                // TODO for the next time touching this code.
                // refactor tha caching from the old to the new model
                _addToCache(
                    _parseProcessTemplateResponseToOldInterface(
                        { projectId: project.id },
                        template
                    )
                );
                return template;
            });
    }

    /**
     * Get a template by templateId
     * id {String}
     * config {Object} - [optional]
     * config.shouldCacheBust {boolean}
     *
     * @return {Promise}
     */
    function get(id, config) {
        if (!id) {
            return $q.resolve(undefined);
        }

        return getTemplates().then(() => {
            // find in cache
            //
            let template;
            if (!_.get(config, "shouldCacheBust")) {
                template = _fromCache(id);
            }
            return $q.resolve(template);
        });
    }

    function _fetchStageSuggestionsOfProjectBy(projectId) {
        return $sbPerformanceApi.getCollection(projectId, {
            date_range: {
                start_date: Number.NEGATIVE_INFINITY.toString(),
                end_date: moment().startOf("day").format(),
            },
            requests: [
                {
                    metric: {
                        expression: "sb::count-activity",
                    },
                    dimension: {
                        name: "sb::activity-template-id",
                    },
                    pivot: {
                        metric: {
                            expression: "sb::count-activity",
                        },
                    },
                },
            ],
        });
    }

    function _filterForActivities(suggestions) {
        return suggestions.filter(function (suggestion) {
            return suggestion.stage.category === "ACTIVITY";
        });
    }

    function _mapToAggregatedItem(suggestions) {
        return suggestions.map(function (suggestion) {
            // map to a nice data representation
            return {
                rootId: suggestion.templateId,
                rootName: suggestion.templateName,
                name: suggestion.stage.name,
                id: suggestion.stage.id,
                topologicalIndex: suggestion.stage.topologicalIndex,
            };
        });
    }

    function _sortByNameFirstAndTopoIndexSecond(suggestions) {
        return suggestions.sort(function (a, b) {
            // sort by type of template then topological index then name
            if (a.rootName === b.rootName) {
                if (a.topologicalIndex === b.topologicalIndex) {
                    return a.name.localeCompare(b.name);
                } else {
                    return a.topologicalIndex - b.topologicalIndex;
                }
            } else {
                return a.rootName.localeCompare(b.rootName);
            }
        });
    }

    function getListOfAllActivitiesOfAllTemplates(projectId) {
        return _fetchStageSuggestionsOfProjectBy(projectId)
            .then(_filterForActivities)
            .then(_mapToAggregatedItem)
            .then(_sortByNameFirstAndTopoIndexSecond);
    }

    function getTemplatesStageSuggestions(
        excludeGroups,
        { byStructureId, byOnlyMyArea } = {}
    ) {
        const kpiRequest = {
            date_range: {
                start_date: Number.NEGATIVE_INFINITY.toString(10),
                end_date: new Date().toISOString(),
            },
            requests: [
                {
                    metric: {
                        expression: "sb::count-activity",
                    },
                    dimension: {
                        name: "sb::activity-template-id",
                    },
                    pivot: {
                        metric: {
                            expression: "sb::count-activity",
                        },
                    },
                },
            ],
        };

        // TODO enable:  isAbortable: true,
        return $sbPerformanceApi
            .getCollection($sbCurrentProject.pick("id"), kpiRequest, {
                byStructureId,
                byOnlyMyArea,
            })
            .then((suggestions) => {
                if (excludeGroups) {
                    suggestions = _.filter(
                        suggestions,
                        function filterGroupsOut(suggestion) {
                            return suggestion.stage.category === "ACTIVITY";
                        }
                    );
                }

                return suggestions
                    .map(function (suggestion) {
                        // map to a nice data representation
                        return {
                            rootId: suggestion.templateId,
                            rootName: suggestion.templateName,
                            name:
                                suggestion.templateName +
                                " | " +
                                suggestion.stage.name,
                            id: suggestion.stage.id,
                            topologicalIndex: suggestion.stage.topologicalIndex,
                            teamId: suggestion.stage.teamId,
                        };
                    })
                    .sort(function (a, b) {
                        // sort by type of template then topological index then name
                        if (a.rootName === b.rootName) {
                            if (a.topologicalIndex === b.topologicalIndex) {
                                return a.name.localeCompare(b.name);
                            } else {
                                return a.topologicalIndex - b.topologicalIndex;
                            }
                        } else {
                            return a.rootName.localeCompare(b.rootName);
                        }
                    });
            });
    }

    function parseTemplates(templates) {
        return templates.map(function (template) {
            return {
                activitiesCount: template.ACTIVITIES_COUNT,
                category: template.CATEGORY,
                code: template.CODE,
                content: template.CONTENT,
                creationDate: template.CREATION_DATE,
                creatorDbName: template.CREATOR_DB_NAME,
                creatorDisplayName: template.CREATOR_DISPLAY_NAME,
                creatorInitials: template.CREATOR_INITIALS,
                desc: template.DESC,
                id: template.ID,
                language: template.LANGUAGE,
                lastChangeDate: template.LAST_CHANGE_DATE,
                name: template.NAME,
                project: template.PROJECT,
                projectId: template.PROJECT_ID,
                status: template.STATUS,
                templateId: template.TEMPLATE_ID,
                topologicalIndex: template.TOPOLOGICAL_INDEX,
                usedInComponents: template.USED_IN_COMPONENTS,
            };
        });
    }

    function _postProcess(template) {
        template.ACTIVITIES_COUNT = template.ACTIVITIES_COUNT || 0;
        return template;
    }

    function clearCache() {
        cache = null;
    }

    function _addToCache(template) {
        if (_.isArray(template)) {
            cache = [].concat(template);
        } else {
            if (_hasCachedState()) {
                cache.push(template);
            } else {
                cache = [template];
            }
        }
        return template;
    }

    function _fromCache(id) {
        if (!_hasCachedState()) {
            return undefined;
        }

        var index = _.findIndex(cache, function (o) {
            return o.ID === id;
        });
        if (index > -1) {
            return cache[index];
        } else {
            return undefined;
        }
    }

    function _hasCachedState() {
        return cache !== null;
    }

    function _parseProcessTemplateResponseToOldInterface(
        { projectId, projectName },
        processTemplate
    ) {
        if (!processTemplate) {
            return;
        }
        return {
            PROJECT_ID: projectId,
            PROJECT_NAME: projectName,
            ID: processTemplate.id,
            CATEGORY: processTemplate.category.toUpperCase(),
            CODE: processTemplate.code,
            DESC: processTemplate.description,
            LANGUAGE: processTemplate.language.toUpperCase(),
            NAME: processTemplate.name,
            ACTIVITIES_COUNT: processTemplate.activities_count,
            USED_IN_COMPONENTS: processTemplate.used_in_components,
            CREATION_DATE: processTemplate.created.at,
            CREATOR_DB_NAME: processTemplate.created.by.id,
            CREATOR_DISPLAY_NAME: processTemplate.created.by.name,
            CREATOR_INITIALS: processTemplate.created.by.initials,
            REVISION: processTemplate.revision,
        };
    }

    function _mapToOldRevisionInterface(processTemplate) {
        processTemplate.components = processTemplate.components.map(
            (component) => {
                const tmp = {
                    id: component.id,
                    parentId: component.parent_id || null,
                    name: component.name,
                    desc: component.desc || null,
                    code: component.code,
                    template: component.template_id || null,
                    category: component.category.toUpperCase(),
                    duration: component.duration,
                    unit: component.unit,
                    assignedTeamId: component.assigned_team_id || null,
                    color: component.color || null,
                    labour: component.labour || null,
                    topologicalIndex:
                        component.topological_index === undefined
                            ? null
                            : component.topological_index,
                    checklistId: component.checklist_id || null,
                    confirmationTeamId: component.confirmation_team_id || null,
                    reviewTeamIds: component.review_team_ids || null,
                };
                if (component.state) {
                    tmp.statename = component.state.name || null;
                    tmp.statedesc = component.state.desc || null;
                    tmp.statecode = component.state.code || null;
                }
                return tmp;
            }
        );
        return processTemplate;
    }

    function _mapToNewInterface(processTemplate) {
        return {
            components: processTemplate.components.map((component) => {
                const dto = {
                    id: component.id.toString(),
                    parent_id: component.parentId
                        ? component.parentId.toString()
                        : undefined,
                    name: notNull(component.name),
                    desc: notNull(component.desc),
                    code: notNull(component.code),
                    template_id: notNull(component.template),
                    category: notNull(
                        component.category,
                        "group"
                    ).toLowerCase(),
                    duration: Number.isNaN(
                        Number.parseFloat(component.duration)
                    )
                        ? undefined
                        : Number.parseFloat(component.duration),
                    unit: notNull(component.unit),
                    assigned_team_id: notNull(component.assignedTeamId),
                    color: notNull(component.color),
                    labour: notNull(component.labour),
                    topological_index: notNull(component.topologicalIndex),
                    checklist_id: notNull(component.checklistId),
                    confirmation_team_id: notNull(component.confirmationTeamId),
                    review_team_ids: notNull(component.reviewTeamIds),
                };

                if (component.isActivity) {
                    // as long as state is still a required property we just duplicate the core props
                    dto.state = {
                        name: component.statename || dto.name,
                        desc: component.statedesc || dto.desc,
                        code: component.statecode || dto.code,
                    };
                }

                return dto;
            }),
            edges: processTemplate.edges.map((edge) => {
                return {
                    target: edge.target.toString(),
                    source: edge.source.toString(),
                };
            }),
        };
    }

    function notNull(value, defaultValue = undefined) {
        return value === null ? defaultValue : value;
    }

    /**
     * POST template details to database. Then creates a new graph object from
     * server response.
     *
     * @param {string} templateId - a UUID to identify the object to download
     * @param {Object} data - a plain data object describing the whole template
     * @return {Promise}
     */
    function createTemplateRevision(templateId, data) {
        const projectId = $sbCurrentProject.pick("id");
        return $sbProcessTemplatesApi
            .createRevision(projectId, templateId, _mapToNewInterface(data))
            .then(_mapToOldRevisionInterface);
    }
}
