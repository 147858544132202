export default function MergeCtrl(
    resolvedSourceObject // the source we are merging.
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.src = resolvedSourceObject;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////
}
