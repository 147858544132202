/**
 * Error to handle function calls with wrong arguments
 *
 * @param {string} message - text for error message
 * @constructor
 * @extends Error
 */
function NoArgumentsError(message) {
    this.name = "NoArgumentsError";
    this.message = message || "No arguments given!";
}

NoArgumentsError.prototype = Object.create(Error.prototype);

export default NoArgumentsError;
