import Edge from "../edge/Edge";
import AnchorPoint from "../common/AnchorPoint";
import NodeContainer from "../node/NodeContainer";
/**
 * Create an edge container on the given container.
 *
 * The edge container will work as drawing stage for connections between display objects.
 *
 * If the display objects are supporting getAnchorPointOut | getAnchorPointIn functions these
 * points are used as edge start | end point. If not the point (0,0) is used.
 *
 * @constructs EdgeContainer
 * @extends createjs.Container
 * @param {createjs.Container} container - parent Container
 * @memberof WBS
 */
var EdgeContainer = function (container) {
    createjs.Container.call(this);

    this.LODActivated = false;

    container.addChild(this);

    this.stage = container.stage;
    this.stage.on("scaleChanged", this._onScaleChanged, this);
};

EdgeContainer.prototype = Object.create(createjs.Container.prototype);

/**
 * Just create an edge between the two display objects.
 *
 * If the display objects are supporting getAnchorPointOut | getAnchorPointIn functions these
 * points are used as edge start | end point. If not the point (0,0) is used.
 *
 * @param {WBS.NodeContainer} sourceObject - Source Object for Edge
 * @param {WBS.NodeContainer} targetObject - Target Object for Edge
 * @param {object} [opt] - options
 */
EdgeContainer.prototype.showEdgesBetween = function (
    sourceObject,
    targetObject,
    opt
) {
    var ps, // source point
        pt, // target point
        anchorPoint;

    // compute the source point
    if (typeof sourceObject.getAnchorPointOut === "function") {
        anchorPoint = sourceObject.getAnchorPointOut();
        ps = sourceObject.localToLocal(anchorPoint.x, anchorPoint.y, this);
    } else {
        ps = sourceObject.localToLocal(0, 0, this);
    }

    // compute the target point
    if (typeof targetObject.getAnchorPointIn === "function") {
        anchorPoint = targetObject.getAnchorPointIn();
        pt = targetObject.localToLocal(anchorPoint.x, anchorPoint.y, this);
    } else {
        pt = targetObject.localToLocal(0, 0, this);
    }

    // draw edge between points
    //
    this.drawEdge(ps, pt, sourceObject, targetObject, opt);
};

/**
 * Handles Scale Change
 * @private
 */
EdgeContainer.prototype._onScaleChanged = function () {
    // JH - deactivate LOD for edges 17.4.2015 (readd if LOD is required)
    //var LODTriggerScale = 0.8,
    //    scale = this.stage.viewport.scale;
    //if (scale < LODTriggerScale && !this.LODActivated) {
    //    this.LODActivated = true;
    //
    //    this.children.forEach(function (edge) {
    //        edge.moveToCommand.x += (AnchorPoint.prototype.bigRadius / 2 + 1);
    //    });
    //
    //} else if (scale >= LODTriggerScale && this.LODActivated) {
    //    this.LODActivated = false;
    //
    //    this.children.forEach(function (edge) {
    //        edge.moveToCommand.x -= (AnchorPoint.prototype.bigRadius / 2 + 1);
    //    });
    //}
};

/**
 * Draw an edge between two points given in the local coordinate system.
 *
 * The path tries to distinguish between
 *  # left to right edges
 *  # ascending or descending edges
 *  # straight edges
 *
 * @param {createjs.Point} sourcePoint - the source point
 * @param {createjs.Point} targetPoint - the target point
 * @param {WBS.NodeContainer} sourceObject - Source Object for Edge
 * @param {WBS.NodeContainer} targetObject - Target Object for Edge
 * @param {object} [opt] - options
 */
EdgeContainer.prototype.drawEdge = function (
    sourcePoint,
    targetPoint,
    sourceObject,
    targetObject,
    opt
) {
    var edge = new Edge(sourcePoint, targetPoint, opt, this.LODActivated);

    if (sourceObject instanceof NodeContainer) {
        edge.setSourceEntity(sourceObject.node.entity);
    }
    if (targetObject instanceof NodeContainer) {
        edge.setTargetEntity(targetObject.node.entity);
    }

    this.addChild(edge);
};

/**
 * Removes all Edges
 */
EdgeContainer.prototype.clear = function () {
    this.removeAllChildren();
};

export default EdgeContainer;
