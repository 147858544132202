export default function AuthStore(
    $window,
    $log,
    $rootScope,
    LOCAL_STORAGE_LAST_SESSION
) {
    "ngInject";

    $rootScope.$on("sb.global.logout", wipe);

    /**
     * NOTE: It should be possible to replace this service by amplify.
     */

    return {
        storeIdentity: storeIdentity,
        getLastSuccessfulSignInIdentity: getStoredIdentity,
        wipe: wipe,
    };

    function wipe() {
        $window.localStorage.removeItem(LOCAL_STORAGE_LAST_SESSION);
        $log.debug("Wiped last session from local storage");
    }

    function storeIdentity(identity) {
        wipe();

        $window.localStorage.setItem(
            LOCAL_STORAGE_LAST_SESSION,
            JSON.stringify(identity)
        );
        $log.debug("Stored session in local storage");
    }

    function getStoredIdentity() {
        const lastSession = $window.localStorage.getItem(
            LOCAL_STORAGE_LAST_SESSION
        );

        if (lastSession) {
            try {
                const identity = JSON.parse(lastSession);
                identity.userId = emailToHanaDbName(identity.mail);
                return identity;
            } catch (e) {
                $log.warn("get identity from store failed with:", e);
            }
        }
    }
    function emailToHanaDbName(email) {
        return email
            .toUpperCase()
            .replace(
                /[\u0021-\u0022\u0024-\u0025\u0027-\u0029\u002A-\u002F\u003A-\u0040\u005B-\u005E\u0060\u007B-\u007E]/g,
                "_"
            );
    }
}
