/* eslint-disable new-cap, camelcase */
/**
 * Created by Sebastian on 15.09.2015.
 *
 * This is a wrapper service for the basic Intercom Messenger API interactions,
 * supported by their client side javascript library.
 *
 * For documentation @see {@link http://docs.intercom.io/install-on-your-web-product/intercom-javascript-api}
 */
export default function intercomChatService(Intercom) {
    "ngInject";

    var service = {
        open: openChat,
        close: closeChat,
    };

    return service;

    /**
     * Open the messenger widget.
     *
     * @param {boolean} withNewConversation - Set true to start new conversation directly.
     */
    function openChat(withNewConversation) {
        if (withNewConversation) {
            Intercom.showNewMessage();
        } else {
            Intercom.show();
        }
    }

    function closeChat() {
        Intercom.hide();
    }
}
