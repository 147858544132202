import stateTemplateHtml from "./wbs.html";
import "angular-ui-router";

export default function wbsRouteConfiguration($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.wbs", {
        url: "/wbs",
        gaPageTrack: "/wbs", // google analytics extension
        templateUrl: stateTemplateHtml,
        controller: "WbsCtrl",
        controllerAs: "wbs",
        data: {
            title: "SECTION_WBS_TITLE",
            icon: "sb-icon-wbs_structure",
            category: "PLAN",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_WBS_EDITOR);
        },
    });
}
