/**
 * Namespace for WBS Editor
 * @namespace WBS
 */

import Editor from "../editor/Editor";
import ProjectContainer from "../node/ProjectContainer";
import MinorContainer from "../node/MinorContainer";
import StructureNode from "../node/StructureNode";
import ProjectNode from "../node/ProjectNode";
import RootContainer from "../node/RootContainer";
import NodeManager from "../model/NodeManager";
import WbsStage from "../wbseditor/WbsStage";
import WbsKeyboardHandler from "../wbseditor/WbsKeyboardHandler";
import AddSiblingCommand from "../commands/AddSiblingCommand";
import AddChildCommand from "../commands/AddChildCommand";
import RenameNodeCommand from "../commands/RenameNodeCommand";
import RemoveNodeCommand from "../commands/RemoveNodeCommand";
import CreateEdgeCommand from "../commands/CreateEdgeCommand";
import DeleteEdgeCommand from "../commands/DeleteEdgeCommand";
import ShowToastEvent from "../events/ShowToastEvent";

/**
 * BaseClass for an Editor for Work Breakdown Structure

 * @param {Object} canvas            Canvas on which WBSEditor is displayed
 * @param {Object} $sbErrorPresenter    Angular Service for Presenting Errors
 * @param {Object} wbsApiService     Angular Service for WBSApi
 * @param {boolean} debug   Enables Debug features (FPS...)
 *
 * @constructs WBSEditor
 * @extends WBS.Editor
 * @memberof WBS
 */
function WBSEditor(canvas, $sbErrorPresenter, wbsApiService, debug, $mdDialog) {
    Editor.call(this, canvas, debug);

    /**
     * WBS specific keyboard handler
     * @type {WbsKeyboardHandler}
     */
    this.keyboardhandler = new WbsKeyboardHandler(this);
    this.keyboardhandler.attachHandler();

    /**
     * @type {NodeManager}
     */
    this.nodeManager = new NodeManager();

    this.keyboardhandler.on(
        "addSibling",
        this._handleEventOfCurrentSelection.bind(this, "addSibling")
    );
    this.keyboardhandler.on(
        "addChild",
        this._handleEventOfCurrentSelection.bind(this, "addChild")
    );
    this.keyboardhandler.on(
        "delete",
        this._handleEventOfCurrentSelection.bind(this, "delete")
    );

    this.keyboardhandler.on("rename", this.enterNameEditMode, this);
    this.keyboardhandler.on("cancel", this.cancelCrtAction, this);

    this.stage.on("addChild", this.handleAddChild.bind(this));
    this.stage.on("addSibling", this.handleAddSibling.bind(this));
    this.stage.on("delete", this.handleDelete.bind(this));

    this.stage.on("createEdge", this.handleCreateEdge.bind(this));
    this.stage.on("edgeCreated", this.onEdgeCreated.bind(this));

    this.stage.on("edgeDeleted", this.onEdgeDeleted.bind(this));

    this.stage.on("nodeCreated", this.onNodeCreated.bind(this));
    this.stage.on("nodeDeleted", this.onNodeDeleted.bind(this));

    this.stage.on("nameChanged", this.handleRenameNode.bind(this, "NAME"));
    this.stage.on("codeChanged", this.handleRenameNode.bind(this, "CODE"));

    this.stage.$sbErrorPresenter = $sbErrorPresenter;
    this.stage.wbsApiService = wbsApiService;
    this.stage.$mdDialog = $mdDialog;
}

/**
 * Setup prototypal inheritance.
 * WBSEditor inherits from Editor.
 * @type {WBS.Editor}
 */
WBSEditor.prototype = Object.create(Editor.prototype);

/**
 * Creates a new WBSStage
 * @param {Object} canvas - Canvas on which WBS is drawn
 * @returns {WBS.WbsStage}
 */
WBSEditor.prototype.createStage = function (canvas) {
    return new WbsStage(canvas);
};

/**
 * Enters the Mode for editing the Name of a Node
 */
WBSEditor.prototype.enterNameEditMode = function () {
    if (!this.stage.currentSettings.modifyNodeDetails) {
        return;
    }
    var nameTextField = this.stage.crtSelectedNode.editableName;
    this.stage.focusElement(nameTextField);
};

/**
 * Delete the currently selected Node
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.handleDelete = function (event) {
    if (!this.stage.currentSettings.modifyNodes) {
        return;
    }
    // check if there is a editable element active -> no cmd
    //
    if (this.stage.crtFocusedElement) {
        return;
    }
    event.preventDefault();

    var deleteCmd;
    if (this.stage.crtSelectedEdge) {
        deleteCmd = new DeleteEdgeCommand(
            this.stage.crtSelectedEdge,
            this.stage
        );
    } else {
        deleteCmd = new RemoveNodeCommand(event.target, this.stage);
    }

    this.commandReceiver.execute(deleteCmd);
};

/**
 * Handles the given Event for the current Selection
 * @param {string} eventName - Name of Event
 * @param {createjs.Event} event - Event Object
 * @private
 */
WBSEditor.prototype._handleEventOfCurrentSelection = function (
    eventName,
    event
) {
    if (this.stage.crtSelectedNode) {
        var nodeEvent = new createjs.Event(eventName, true, true);
        this.stage.crtSelectedNode.dispatchEvent(nodeEvent);

        if (!this.stage.crtFocusedElement) {
            event.preventDefault();
        }
    }
};

/**
 * Add Child to currently Selected Node (if allowed)
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.handleAddChild = function (event) {
    if (!this.stage.currentSettings.modifyNodes) {
        return;
    }

    var addCmd = new AddChildCommand(event.target, this.nodeManager);
    this.commandReceiver.execute(addCmd);
};

/**
 * Add a child to the parent of the selected Node and place it below the selected Node
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.handleAddSibling = function (event) {
    if (!this.stage.currentSettings.modifyNodes) {
        return;
    }

    // create add sibling Command
    //
    if (this.stage.crtFocusedElement) {
        this.stage.blurCrtFocusedElement();
    } else {
        var addCmd = new AddSiblingCommand(event.target, this.nodeManager);
        this.commandReceiver.execute(addCmd);
    }
};

/**
 * Let the User rename the currently selected Node
 * @param {string} sType Attribute which is renamed
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.handleRenameNode = function (sType, event) {
    if (!this.stage.currentSettings.modifyNodeDetails) {
        return;
    }

    // create rename Command
    //
    var renameCmd = new RenameNodeCommand(
        event.target,
        sType,
        event.newText,
        event.oldText
    );
    this.commandReceiver.execute(renameCmd);
};

/**
 * Handles the Creation of Edges
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.handleCreateEdge = function (event) {
    if (!this.stage.currentSettings.modifyEdges) {
        return;
    }

    var stage = this.stage;

    var edgeExist = this.nodeManager.checkIfEdgeExists(
        event.edgeSource,
        event.edgeTarget
    );
    var oppositeEdgeExist = this.nodeManager.checkIfEdgeExists(
        event.edgeTarget,
        event.edgeSource
    );

    if (!edgeExist && !oppositeEdgeExist) {
        var edgeCmd = new CreateEdgeCommand(
            {
                SOURCE_ID: event.edgeSource,
                TARGET_ID: event.edgeTarget,
            },
            stage
        );

        this.commandReceiver.execute(edgeCmd);
    } else if (edgeExist) {
        this.stage.dispatchEvent(
            new ShowToastEvent("Edge already exists", "error", 4000)
        );
    } else {
        this.stage.dispatchEvent(
            new ShowToastEvent(
                "Edge in opposite direction already exists",
                "error",
                4000
            )
        );
    }
};

/**
 * handles the changes if a node is created
 */
WBSEditor.prototype.onNodeCreated = function () {
    this.stage.setNeedsDisplay();
};

/**
 * handles the changes if a node is deleted
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.onNodeDeleted = function (event) {
    this.stage.setNeedsDisplay();
    this.stage.onSingleTapOnNull();

    var entity = event.node.entity;
    entity.getGeometry().parent.removeChild(entity.getGeometry());
    console.log(entity.getData().ID);
    entity.deleteNode();
};

/**
 * handles the changes if an edge is created
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.onEdgeCreated = function (event) {
    this.stage.setNeedsDisplay();
    this.nodeManager.addEdge(event.edgeSource, event.edgeTarget);
    this.stage.showEdgesOfNode(this.stage.crtSelectedNode);
};

/**
 * handles the changes if an node is deleted
 *
 * @param {createjs.Event} event - Event Object
 */
WBSEditor.prototype.onEdgeDeleted = function (event) {
    this.stage.setNeedsDisplay();
    this.nodeManager.deleteEdge(event.edgeSource, event.edgeTarget);
    this.stage.showEdgesOfNode(this.stage.crtSelectedNode);
};

/**
 * Cancels the current Action (at the moment only edge creation)
 */
WBSEditor.prototype.cancelCrtAction = function () {
    this.stage.setNeedsDisplay();
    this.stage.disableEdgeCreateMode();
};

/**
 * Set the Data for the WBS
 * @param {Object} oTreeModel   Model which the WBS displays
 * @param {string} oTreeModel.PROJECT_ID    Id of the displayed project
 * @param {Array<Object>} oTreeModel.components     Components of the WBS
 * @param {Array<Object>} oTreeModel.edges     Edges of the WBS
 */
WBSEditor.prototype.setTreeModel = function (oTreeModel) {
    this.stage.setNeedsDisplay();
    var x = 0, // x coordinates of the last node
        y = 0, // y coordinates of the last node
        iTreeIndentationX = 100, // indentation of tree nodes below their parent
        maxIndentation = 0, // help counter to keep track of the max indentation of the tree
        iElementWidth = 230, // size of one tree node.
        oIDtoGeometryMap = {},
        projectNodeData = {
            NAME: "WBS",
            CODE: "",
            nComponentUse: "-",
            ID: undefined,
            PROJECT_ID: oTreeModel.PROJECT_ID,
        },
        projectContainer = new ProjectContainer(
            new ProjectNode(projectNodeData, this.nodeManager)
        ),
        _this = this;

    projectContainer.node.entity.setGeometry(projectContainer);

    /**
     * Go through the tree in a depth first manner.
     *
     * It is based on a recursion that is mapped on a queue.
     *
     * @param {Object} oElement - current Element
     * @param {function} fnOnElement - function that is called on element
     */
    function iterateDF(oElement, fnOnElement) {
        var stack = [oElement];
        var ele, i;

        oElement.intend = 0;

        // take the last added element form the stack
        ele = stack.pop();
        while (ele) {
            // call the given logic on the element
            fnOnElement(ele);

            // check if there a children (components)
            if (ele.components && ele.components.length > 0) {
                for (i = ele.components.length; i--; ) {
                    // eslint-disable-line
                    // track the depth (optional)
                    ele.components[i].intend = ele.intend + 1;
                    ele.components[i].parent = ele;
                    // add the child to the end of the stack. -> The last one will be the next for the iteration
                    stack.push(ele.components[i]);
                }
            }
            // take the last added element form the stack
            ele = stack.pop();
        }
    }

    /**
     * Add an element to the stage based on the coordinates calculated from x,y, indentation, etc..
     *
     * @param {Object} element - Element to be created
     */
    function createElementAtLocalPosition(element) {
        var container;

        if (element.parent) {
            container = new MinorContainer(
                new StructureNode(element, _this.nodeManager)
            );

            _this.nodeManager
                .getGeometry(element.parent.ID)
                .addChild(container);
        } else {
            container = new RootContainer(
                new StructureNode(element, _this.nodeManager)
            );
            projectContainer.addChild(container);
        }

        if (typeof element.ID === "string") {
            oIDtoGeometryMap[element.ID] = container;
        }

        if (element.intend > maxIndentation) {
            maxIndentation = element.intend;
        }

        y++;
        container.node.entity.setGeometry(container);
    }

    // sort the components, and draw a tree like representation for every component
    //
    oTreeModel.components.forEach(function (o) {
        // layout standard tree
        //
        iterateDF(o, createElementAtLocalPosition);
        x = x + iElementWidth + maxIndentation * iTreeIndentationX;

        // reset tree layout start values
        y = 0;
        maxIndentation = 0;
    }, this);

    this.viewport.removeAllChildren();
    this.viewport.addChild(projectContainer);
    projectContainer.dispatchAddedToStage();

    // add edges to the model
    //
    if (oTreeModel.edges !== undefined) {
        oTreeModel.edges.forEach(function (edge) {
            // add edges in a simple way

            _this.nodeManager.addEdge(edge.SOURCE_ID, edge.TARGET_ID);
        });
    }

    // create toast manager on the stage -> he will listen for ToastEvents
    //      and display them in a separate container on top of the scale container
    //new ToastManager(this.stage);
    this.onResize();
};

export default WBSEditor;
