import moment from "moment";

//
// CONTENT TO COPY TO BACKEND STARTS HERE !
//

/**
 * @class WorkFreeCalendar
 * @property {Object<String, Boolean>} nonWorkingDays
 *
 * @constructor
 */
function WorkFreeCalendar() {
    this.nonWorkingDays = Object.create(null); // create map-like object without default keys
}

WorkFreeCalendar.DATE_FORMAT = "MM-DD-YYYY";

/**
 * Transform a moment date into a string representation of the date
 * --
 * Non moment parameters are not allowed!
 *
 * @param {Moment} date
 * @return {String}
 */
WorkFreeCalendar.momentToStringIdentifier = function (date) {
    return date.format(WorkFreeCalendar.DATE_FORMAT);
};

/**
 * Add excluded date to the calendar.
 * Date refers to 00:00:00 of the day until 23:59:59.9999 of the day.
 *
 * @param {Moment} date - Any moment that should be handled as non working day
 */
WorkFreeCalendar.prototype.addNonWorkingDay = function (date) {
    this.nonWorkingDays[WorkFreeCalendar.momentToStringIdentifier(date)] = true;
};

/**
 * Check if given date is marked as non working day in the calendar.
 *
 * @param {Moment} date - Any moment that should be checked
 * @return {Boolean} - true if a non working day
 */
WorkFreeCalendar.prototype.isNonWorkingDay = function (date) {
    return (
        this.nonWorkingDays[WorkFreeCalendar.momentToStringIdentifier(date)] ===
        true
    );
};

//
// CONTENT TO COPY TO BACKEND ENDS HERE !
//

export default WorkFreeCalendar;
