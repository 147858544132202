import "angular";
import { ProjectSource } from "../../domain/sb_project_source.class";

export default function ToolsCtrl(
    SABLONO_STATES,
    $sbProject,
    $sbCurrentProject,
    $sbPermission,
    $rootScope,
    $mdDialog,
    $log,
    $state,
    $stateParams,
    $sbErrorPresenter,
    Analytics
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.loading = true;

    // Available tile sources, so we can check if our project contains one of these

    var availableUpdateSources = [
        //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
        // ProjectSource.TYPES.PRIMAVERA,
        // ProjectSource.TYPES.MSP,
        ProjectSource.TYPES.ASTA,
        ProjectSource.TYPES.EXCEL,
    ];
    var availableExportSources = [
        //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
        // ProjectSource.TYPES.PRIMAVERA,
        // ProjectSource.TYPES.ASTA,
        // ProjectSource.TYPES.MSP,
        ProjectSource.TYPES.EXCEL,
    ];

    vm.hasAvailableMergeSources = false;

    vm.projectSources = [];

    vm.openMergeDialog = openMergeDialog;
    vm.openExportDialog = openExportDialog;

    activate();
    //

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////
    function activate() {
        _checkSources()
            .then(_checkPlanPermissions)
            .then(function () {
                if (!vm.hasPlanPermissions) {
                    // We have to change the state title if we don't have permission to import
                    $state.current.data.title = "ACTION_NAV_TO_EXPORT";
                }
                _checkIncomingActions($stateParams);
                vm.loading = false;
            })
            .catch(function (error) {
                vm.loading = false;
                $log.error(error);
            });
    }
    /**
     * Checks all the sources of the project to see if it matches any of the tiles
     * @private
     */
    function _checkSources() {
        return $sbProject
            .getProjectSource($sbCurrentProject.pick("id"))
            .then(function (projectSources) {
                vm.projectSources = projectSources;
                vm.hasAvailableUpdateSources = availableUpdateSources.some(
                    (srcType) => _isSourceAvailable(projectSources, srcType)
                );
            })
            .catch($sbErrorPresenter.catch);
    }

    function _checkPlanPermissions() {
        return (vm.hasPlanPermissions = $sbPermission.hasPlanPermissions(
            $sbProject.getCurrent().privileges
        ));
    }

    /**
     * check if a given source is contained in an array of sources
     * @param {ProjectSource[]} sources - Array containing all possible sources
     * @param {String} value - The source we want to check
     * @returns {*|boolean}
     * @private
     */
    function _isSourceAvailable(sources, value) {
        return sources.some(function (src) {
            return value === src.type;
        });
    }

    /**
     * Check if the user has been redirected into this page from an "Update Data" or
     * "Export Data" call to action on another page. Open the appropiate dialog
     * if they are.
     *
     * @param {object} stateParams
     *
     * @returns {$mdDialog | undefined}
     */
    function _checkIncomingActions(stateParams) {
        switch (stateParams && stateParams.open) {
            case "update":
                return openMergeDialog();
            case "export":
                return openExportDialog();
        }
    }

    function createDialog(config) {
        return $mdDialog
            .chooseFile()
            .title(config.titleKey)
            .text(config.textKey)
            .projectSources(vm.projectSources)
            .availableSources(config.sources)
            .showCreationDate(config.showCreationDate)
            .showUsageNumbers(config.showUsageNumbers)
            .showLastUpdatedDate(config.showLastUpdatedDate)
            .showMergeHints(config.showMergeHints)
            .isProjectAvailable(config.isProjectAvailable)
            .class(config.dialogClass)
            .clickOutsideToClose(true)
            .escapeToClose(true);
    }

    function openDialog(dialog, $event) {
        return $mdDialog.show(dialog.targetEvent($event));
    }

    function openMergeDialog($event) {
        var mergeDialog = createDialog({
            titleKey: "SECTION_TOOLS_DIALOG_MERGE_TITLE",
            textKey: "SECTION_TOOLS_DIALOG_MERGE_TEXT",
            showLastUpdatedDate: true,
            showCreationDate: true,
            showMergeHints: true,
            showUsageNumbers: true,
            sources: availableUpdateSources,
        });

        openDialog(mergeDialog, $event).then(function (result) {
            $state.go("sablono.project.merge", {
                src: result,
            });
        });
    }

    function openExportDialog($event) {
        var exportDialog = createDialog({
            titleKey: "SECTION_TOOLS_DIALOG_EXPORT_TITLE",
            textKey: "SECTION_TOOLS_DIALOG_EXPORT_TEXT",
            showLastUpdatedDate: true,
            showCreationDate: true,
            isProjectAvailable: true,
            showUsageNumbers: true,
            sources: availableExportSources,
        });

        openDialog(exportDialog, $event).then(function (result) {
            if (result === "PROJECT") {
                Analytics.trackEvent(
                    "Progress export",
                    "click",
                    "CSV from Import/Export"
                );
            }

            $state.go(SABLONO_STATES.export, {
                src: result,
            });
        });
    }
}
