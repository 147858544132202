import _ from "lodash";
import {
    DocumentAttachment,
    ImageAttachment,
} from "../../ui-elements/components/sbAttachmentGallery/gallery_attachment.model";
import Note from "../../../domain/sb_note.class";

export default function (
    $q,
    $rootScope,
    $mdDialog,
    $sbDomain,
    $sbFileReader,
    ISSUE_TEMPLATE_CONFIG,
    SbNote,
    SbTeam,
    EVENTS,
    $sbIssue,
    $sbErrorPresenter,
    TRAFFIC_LIGHT_MODES,
    $sbTracking,
    $sbProjectCurrentSettings,
    Analytics
) {
    "ngInject";
    const vm = this;

    // the note that is created in the dialog
    vm.attachments = [];

    // type to chose from
    vm.availableTypes = _transformTypes(ISSUE_TEMPLATE_CONFIG.TYPES.OPTIONS);

    // selected confirmation team
    vm.selectedConfirmationTeam = {
        teamId: undefined,
        isSet: false,
    };

    // message to display on confirmation teams control
    vm.confirmationTeamErrorMessage = undefined;

    // maintain the saving state of the dialog
    vm.isSaving = false;
    // As declared in the database
    vm.MAX_ISSUE_DESCRIPTION_LENGTH = 1024;
    vm.TRAFFIC_LIGHT_MODES = TRAFFIC_LIGHT_MODES;
    vm.isUploadInProgress = false;
    vm.isGeolocationMessageDisplayed = false;

    vm.submit = submit;
    vm.closeDialog = closeDialog;
    // manipulation calls for the note
    vm.onAttach = onAttach;
    vm.onDetach = onDetach;
    vm.onSelectType = onSelectType;
    vm.updateSelectedTeam = updateSelectedTeam;
    vm.isUploading = (isUploading) => (vm.isUploadInProgress = isUploading);
    vm.isSubmitAvailable = isSubmitAvailable;

    vm.updateSelectedConfirmationTeam = updateSelectedConfirmationTeam;
    vm.isEditingMode = isEditingMode;
    vm.isConfirmationTeamOptional = isConfirmationTeamOptional;
    vm.getConfirmationTeamId = getConfirmationTeamId;
    vm.containsEncodedCharacterWeWantToPreserve =
        containsEncodedCharacterWeWantToPreserve;
    _init();

    /**
     * Create a new note instance
     * @returns {SbNote}
     * @private
     */
    function _init() {
        vm.selectedTeam = vm.note.responsibleTeam;

        if (isEditingMode()) {
            _setInitialSelectedConfirmationTeams(vm.note.confirmationTeam);
        }

        vm.attachments = [...vm.note.images, ...vm.note.attachments];

        $sbProjectCurrentSettings
            .getGeolocationSetting()
            .then((geolocationPermission) => {
                vm.isGeolocationMessageDisplayed = geolocationPermission;
            });
    }

    function submit(note) {
        if (!isSubmitAvailable()) {
            return;
        }

        note.attachments = _.chain(vm.attachments)
            .filter((attachment) => attachment instanceof DocumentAttachment)
            .map((document) => _.pick(document, ["id", "name"]))
            .value();

        note.images = _.chain(vm.attachments)
            .filter((attachment) => attachment instanceof ImageAttachment)
            .map((image) => _.pick(image, ["id"]))
            .value();

        // To fix broken links with empty spaces. It seems expressjs is running a
        // decodeUri by default for such texts. So, we are doing the opposite function first.
        //
        if (
            !isEditingMode() &&
            containsEncodedCharacterWeWantToPreserve(note.text)
        ) {
            note.text = encodeURI(note.text);
        }

        if (!isEditingMode()) {
            Analytics.trackConversion("note created");
        }
        return $mdDialog.hide({ note });
    }

    function containsEncodedCharacterWeWantToPreserve(x) {
        try {
            return x !== decodeURI(x);
        } catch (_) {
            return false;
        }
    }

    /**
     * Set the selected type as type of the note
     * @param {string} type
     */
    function onSelectType(type) {
        vm.note.setType(type.DOMAIN_NAME);
        _updateNoteConfirmationTeamFromIssueType(type.DOMAIN_NAME);
    }

    function closeDialog() {
        $mdDialog.cancel();
    }

    /**
     * Callback for the gallery that will give you a file object.
     *
     * @param $event
     * @param {GalleryAttachment} attachment - image or file
     * @returns {*}
     */
    function onAttach($event, attachment) {
        vm.attachments = [attachment].concat(vm.attachments.concat());
    }

    /**
     * Remove the image reference from the note.
     * @param {number} index - position in the image array
     */
    function onDetach(index) {
        vm.attachments.splice(index, 1);
    }

    function isSubmitAvailable() {
        return (
            vm.newIssueForm.$valid &&
            vm.note.type &&
            !vm.isSaving &&
            !vm.isUploadInProgress &&
            _validateConfirmationTeam()
        );
    }

    function updateSelectedTeam(note, team) {
        $sbTracking.note.teamSelect.responsibleTeam(team);

        team = team || SbTeam.createUnrestrictedTeam();
        note.setResponsibleTeam(team);
        vm.selectedTeam = team;
    }

    /**
     * A [note] with an ID means this dialog is being used to edit the note.
     *
     * Currently, we do not support editing the confirmation team.
     *
     */
    function isEditingMode() {
        return vm.note.id;
    }

    function isConfirmationTeamOptional() {
        const noteType = vm.note.type;
        switch (noteType) {
            case Note.OBSTRUCTION:
                return vm.confirmationTeamSettings.obstruction.isOptional;
            case Note.QUALITY_ISSUE:
                return vm.confirmationTeamSettings.qualityIssue.isOptional;
            default:
                return false; // Info | None type notes do not support a confirmation team
        }
    }

    /**
     * Return the [selectedConfirmationTeam] or as fallback [default_confirmation_team_id]
     * set on [confirmationTeamSettings] by respecting the given [noteType] or as default [Note.type]
     *
     * Returns `undefined` if note is of type Information
     */
    function getConfirmationTeamId(noteType) {
        if (vm.selectedConfirmationTeam.isSet) {
            return vm.selectedConfirmationTeam.teamId;
        }

        noteType = noteType || vm.note.type;

        switch (noteType) {
            case Note.QUALITY_ISSUE:
                return vm.confirmationTeamSettings.qualityIssue.defaultTeamId;
            case Note.OBSTRUCTION:
                return vm.confirmationTeamSettings.obstruction.defaultTeamId;
            default:
                return undefined;
        }
    }

    /**
     * Set all [selectedConfirmationTeam] with [team] if available, otherwise set the default
     */
    function _setInitialSelectedConfirmationTeams(team) {
        if (team) {
            vm.selectedConfirmationTeam = {
                teamId: team.id,
                isSet: true,
            };
        } else {
            vm.selectedConfirmationTeam = {
                teamId: undefined,
                isSet: false,
            };
        }
    }

    /**
     * Assign the confirmation [team] to [note]
     *
     * Update the [selectedConfirmationTeam] with [team] relative to [Note.type]
     */
    function updateSelectedConfirmationTeam(team) {
        $sbTracking.note.teamSelect.confirmationTeam(team);

        vm.note.setConfirmationTeam(team);

        _updateSelectedConfirmationTeam(team);
    }

    /**
     * Update the [selectedConfirmationTeam] with [team] relative to [Note.type]
     */
    function _updateSelectedConfirmationTeam(team) {
        vm.selectedConfirmationTeam = {
            teamId: team ? team.id : null,
            isSet: true,
        };
    }

    /**
     * Updated the [Note] confirmation team from [confirmationTeamSettings] whose matches the [noteType]
     */
    function _updateNoteConfirmationTeamFromIssueType(noteType) {
        if (noteType === Note.INFORMATION) {
            vm.note.setConfirmationTeam(null);
        } else {
            const teamId = getConfirmationTeamId(noteType);

            // null is equal to "confirmation not required", therefore we treat it as state
            if (teamId === null) {
                vm.note.setConfirmationTeam(null);
            } else {
                const team = vm.teams.find(function (team) {
                    return team.id === teamId;
                });
                vm.note.setConfirmationTeam(team);
            }
        }
    }

    /**
     * Returns `true` if [note] type is Information or None, otherwise whether the current [note] confirmation team
     * satisfies the [confirmationTeamSettings]
     *
     * Displays an error message on confirmation teams control if `false` is returned.
     */
    function _validateConfirmationTeam() {
        if (!vm.note.type || vm.note.type === Note.INFORMATION) {
            return true;
        }

        let isValid;
        if (isConfirmationTeamOptional()) {
            // we force user to select a team anyway
            // unassigned is equal to `null` which is a valid value, therefore below we check only for `undefined`
            //
            isValid = vm.note.getConfirmationTeam() !== undefined;
        } else {
            // user must select a valid team
            // unassigned (null) is not allowed
            //
            isValid = !!vm.note.getConfirmationTeam();
        }

        vm.confirmationTeamErrorMessage = isValid
            ? undefined
            : "DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_ERROR_REQUIRED";

        return isValid;
    }

    /**
     * We are using the SbNote.class type property to store and manipulate the type of the new note.
     * But our CONFIG options are using a different identifier then this type property.
     * So the input we need for the type selector in this case has to have the SbNote.class type names
     * as key for the option.
     *
     * This function is changing the key for each option to use the DOMAIN_NAME which references to the
     * SnNote.class internally.
     *
     * @param options
     * @returns {*}
     * @private
     */
    function _transformTypes(options) {
        return Object.keys(options).reduce(function (newOption, key) {
            const originOption = options[key];
            newOption[originOption.DOMAIN_NAME] = originOption;
            return newOption;
        }, {});
    }
}
