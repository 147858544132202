import LeanBoardModelMapper from "./lean_board_model.mapper";
import moment from "moment";

class LeanBoardDisplayDatesDecorator {
    static attachOrigins(activity, shouldIncludeActuals) {
        const schedule = activity.schedule;

        const DATE_ORIGIN = {
            PREDICTED: "DATE_ORIGIN_PREDICTED",
            ACTUAL: "DATE_ORIGIN_ACTUAL",
            FORECASTED: "DATE_ORIGIN_FORECASTED",
            LOOK_AHEAD: "DATE_ORIGIN_LOOK_AHEAD",
            BASELINE: "DATE_ORIGIN_BASELINE",
        };

        const scheduleStartDatesBasedOnImportance = [];
        if (shouldIncludeActuals) {
            scheduleStartDatesBasedOnImportance.push(
                {
                    date: schedule.actual.started,
                    dateOrigin: DATE_ORIGIN.ACTUAL,
                },
                {
                    date: schedule.actual.finished,
                    dateOrigin: DATE_ORIGIN.ACTUAL,
                }
            );
        }

        scheduleStartDatesBasedOnImportance.push(
            {
                date: schedule.forecast.start,
                dateOrigin: DATE_ORIGIN.FORECASTED,
            },
            {
                date: schedule.sessionLookAhead.start,
                dateOrigin: DATE_ORIGIN.LOOK_AHEAD,
            },
            {
                date: schedule.publishedLookAhead.start,
                dateOrigin: DATE_ORIGIN.LOOK_AHEAD,
            },
            { date: schedule.baseline.start, dateOrigin: DATE_ORIGIN.BASELINE }
        );

        const { index } = LeanBoardModelMapper.firstValidWithIndexIn(
            scheduleStartDatesBasedOnImportance.map((item) => item.date)
        );

        activity.startDateOrigin = scheduleStartDatesBasedOnImportance[index]
            ? scheduleStartDatesBasedOnImportance[index].dateOrigin
            : undefined;

        if (activity.hasPrediction) {
            activity.endDateOrigin = DATE_ORIGIN.PREDICTED;
        } else {
            const scheduleEndDatesBasedOnImportance = [];

            if (shouldIncludeActuals) {
                scheduleEndDatesBasedOnImportance.push(
                    {
                        date: schedule.actual.confirmed,
                        dateOrigin: DATE_ORIGIN.ACTUAL,
                    },
                    {
                        date: schedule.actual.finished,
                        dateOrigin: DATE_ORIGIN.ACTUAL,
                    }
                );
            }
            scheduleEndDatesBasedOnImportance.push(
                {
                    date: schedule.forecast.end,
                    dateOrigin: DATE_ORIGIN.FORECASTED,
                },
                {
                    date: schedule.sessionLookAhead.end,
                    dateOrigin: DATE_ORIGIN.LOOK_AHEAD,
                },
                {
                    date: schedule.publishedLookAhead.end,
                    dateOrigin: DATE_ORIGIN.LOOK_AHEAD,
                },
                {
                    date: schedule.baseline.end,
                    dateOrigin: DATE_ORIGIN.BASELINE,
                }
            );

            const { index } = LeanBoardModelMapper.firstValidWithIndexIn(
                scheduleEndDatesBasedOnImportance.map((item) => item.date)
            );

            activity.endDateOrigin = scheduleEndDatesBasedOnImportance[index]
                ? scheduleEndDatesBasedOnImportance[index].dateOrigin
                : undefined;
        }
    }

    static deriveDisplayedDuration(activity) {
        // This takes care of a weird edge case where an activity has ONLY actual dates
        // and the UI is configured to hide the actual dates
        if (!activity.endDate || !activity.startDate) {
            activity.displayedDuration = {};
            return;
        }

        const workHoursBetweenStartAndEnd =
            activity.calendar.getTotalWorkingTimeBetween(
                moment(activity.startDate).startOf("day"),
                moment(activity.endDate).endOf("day")
            );
        const workDays = activity.calendar.mapDurationToNextLargerUnit(
            workHoursBetweenStartAndEnd,
            "wh"
        );

        activity.displayedDuration = {
            value: workDays,
            unit: "wd",
        };
    }
}

export default LeanBoardDisplayDatesDecorator;
