import angular from "angular";
import _ from "lodash";
import ChecklistItem from "../../model/checklist_template_item.class";

export default function ChecklistTemplatesEditorCtrl(
    $sbChecklistService,
    $state,
    SABLONO_STATES,
    $stateParams,
    $timeout,
    $mdToast,
    $sbErrorPresenter,
    $sbTracking
) {
    "ngInject";

    var vm = this;
    var _debouncedSaveChanges = _.debounce(_saveChanges, 500);

    vm.deleteTemplateItem = deleteTemplateItem;
    vm.moveItemUp = moveItemUp;
    vm.moveItemDown = moveItemDown;
    vm.onChecklistTemplateNameChange = onChecklistTemplateNameChange;
    vm.onChecklistTemplateItemDescriptionChange =
        onChecklistTemplateItemDescriptionChange;
    vm.onChecklistTemplateItemSettingsChange =
        onChecklistTemplateItemSettingsChange;
    vm.addChecklistTemplateItem = addChecklistTemplateItem;
    vm.restoreBackup = restoreBackup;
    vm.onPasteIntoItem = onPasteIntoItem;

    vm.model = {
        checklistTemplate: undefined,
        newChecklistTemplateItemName: undefined,
        backup: undefined,
        templateItemSettingOptions: [
            {
                key: "no_mandatory",
                i18n: "CREATE_CHECKLIST_EDITOR_IS_NOT_MANDATORY",
            },
            {
                key: "work_mandatory",
                i18n: "CREATE_CHECKLIST_EDITOR_IS_WORK_MANDATORY",
            },
            {
                key: "confirm_mandatory",
                i18n: "CREATE_CHECKLIST_EDITOR_IS_CONFIRM_MANDATORY",
            },
            {
                key: "both_mandatory",
                i18n: "CREATE_CHECKLIST_EDITOR_IS_MANDATORY",
            },
        ],
    };

    $onInit();

    function $onInit() {
        $sbTracking.checklistTemplates.editing.editList();

        $sbChecklistService
            .getChecklistTemplateById(
                $stateParams.projectId,
                Number.parseFloat($stateParams.checklistTemplateId)
            )
            .then(_attachSettingsToItems)
            .then(_populateModelData)
            .then(_backupModel)
            .catch(_handleInitError);
    }

    function onPasteIntoItem(event, form) {
        if (!_.get(event, "clipboardData.getData")) {
            return;
        }
        const lineBreak = /\r?\n/;
        const lines = event.clipboardData.getData("text").split(lineBreak);

        lines
            .map((line) => line.trim())
            .filter((line) => line.length > 0)
            .forEach((text) => {
                const newChecklistItem = new ChecklistItem(null, text);
                newChecklistItem.setting =
                    mapItemConfigToSetting(newChecklistItem);
                vm.model.checklistTemplate.addItem(newChecklistItem);
            });

        // ng-paste is faster then ng-model that's why we have to put those
        //  triggers into a new digest cycle
        $timeout(() => {
            vm.model.newChecklistTemplateItemName = "";
            form.$setPristine();
            _debouncedSaveChanges();
        }, 0);
    }

    function _populateModelData(checklistTemplate) {
        _attachSettingsToItems(checklistTemplate);
        vm.model.checklistTemplate = checklistTemplate;
    }

    function _backupModel() {
        vm.model.backup = angular.copy(vm.model.checklistTemplate);
    }

    function _handleInitError(error) {
        $sbErrorPresenter.catch(error);
        $state.go(SABLONO_STATES.checklistTemplatesList);
    }

    function onChecklistTemplateNameChange() {
        if (vm.model.checklistTemplate.name !== vm.model.backup.name) {
            _debouncedSaveChanges();
        }
    }

    function onChecklistTemplateItemDescriptionChange(isValid, checklistItem) {
        if (!isValid) {
            restoreBackup();
        } else if (_hasChecklistItemDescriptionChanged(checklistItem)) {
            _debouncedSaveChanges();
        }
    }

    function addChecklistTemplateItem(form) {
        $sbTracking.checklistTemplates.editing.newItem();

        var newChecklistItem = new ChecklistItem(
            null,
            vm.model.newChecklistTemplateItemName
        );
        newChecklistItem.setting = mapItemConfigToSetting(newChecklistItem);
        vm.model.checklistTemplate.addItem(newChecklistItem);
        vm.model.newChecklistTemplateItemName = "";

        form.$setPristine();

        _debouncedSaveChanges();
    }

    function _saveChanges(withoutUpdatingViewModel) {
        var requestPayload = vm.model.checklistTemplate.toServerRequestModel();

        return $sbChecklistService
            .updateChecklistTemplate(
                $stateParams.projectId,
                vm.model.checklistTemplate.id,
                requestPayload
            )
            .then(function (updatedChecklist) {
                if (!withoutUpdatingViewModel) {
                    _populateModelData(updatedChecklist);
                }
            })
            .then(_showSuccessToast)
            .then(_backupModel)
            .catch(_handleSaveError);
    }

    function _handleSaveError(error) {
        $sbErrorPresenter.catch(error);
        restoreBackup();
    }

    function _showSuccessToast() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("CREATE_CHECKLIST_EDITOR_SAVED")
                .hideDelay(3000)
                .position("top right")
        );
    }

    function moveItemUp(item) {
        moveItem(vm.model.checklistTemplate.items, item, -1);
    }

    function moveItemDown(item) {
        moveItem(vm.model.checklistTemplate.items, item, +1);
    }

    function moveItem(items, item, delta) {
        $sbTracking.checklistTemplates.editing.moveItem();

        const currentPosition = _.indexOf(items, item);
        const isMoved = moveItemInArray(items, {
            fromIndex: currentPosition,
            toIndex: currentPosition + delta,
        });

        if (isMoved) _debouncedSaveChanges(true);
    }

    function moveItemInArray(array, { fromIndex, toIndex }) {
        if (fromIndex === toIndex || toIndex < 0 || toIndex >= array.length) {
            return false;
        }

        const item = array[fromIndex];
        _.remove(array, item);
        array.splice(toIndex, 0, item);
        return true;
    }

    function deleteTemplateItem(item) {
        $sbTracking.checklistTemplates.editing.deleteItem();
        vm.model.checklistTemplate.removeItem(item);
        _debouncedSaveChanges(true);
    }

    function restoreBackup() {
        return _populateModelData(angular.copy(vm.model.backup));
    }

    function _hasChecklistItemDescriptionChanged(changedItem) {
        var isMatch = false;

        angular.forEach(vm.model.backup.items, function (item) {
            if (
                item.id === changedItem.id &&
                item.description === changedItem.description
            ) {
                isMatch = true;
            }
        });

        return !isMatch;
    }

    function onChecklistTemplateItemSettingsChange(templateItem, setting = "") {
        if (setting === "require image" && templateItem.isWorkImageRequired) {
            $sbTracking.checklistTemplates.editing.toggleRequireImage();
        }

        mapSettingToItemConfig(templateItem);

        _debouncedSaveChanges();
    }

    function _attachSettingsToItems(checklistTemplate) {
        checklistTemplate.items.forEach((item) => {
            item.setting = mapItemConfigToSetting(item);
        });

        return checklistTemplate;
    }

    function mapSettingToItemConfig(templateItem) {
        const settings = vm.model.templateItemSettingOptions;

        switch (templateItem.setting) {
            case settings[0].key:
                templateItem.isWorkRequired = false;
                templateItem.isConfirmRequired = false;
                break;
            case settings[1].key:
                templateItem.isWorkRequired = true;
                templateItem.isConfirmRequired = false;
                break;
            case settings[2].key:
                templateItem.isWorkRequired = false;
                templateItem.isConfirmRequired = true;
                break;
            default:
                templateItem.isWorkRequired = true;
                templateItem.isConfirmRequired = true;
                break;
        }
    }

    function mapItemConfigToSetting(templateItem) {
        const isWorkRequired = templateItem.isWorkRequired;
        const isConfirmRequired = templateItem.isConfirmRequired;

        const settings = vm.model.templateItemSettingOptions;

        if (!isWorkRequired && !isConfirmRequired) {
            return settings[0].key;
        }

        if (isWorkRequired && !isConfirmRequired) {
            return settings[1].key;
        }

        if (!isWorkRequired && isConfirmRequired) {
            return settings[2].key;
        }

        return settings[3].key;
    }
}
