import _ from "lodash";

function SbTeam(id, name) {
    this.id = id;
    this.name = name;

    this.company = "";
    this.address = {
        city: "",
        postCode: "",
        country: "",
        street: "",
    };
    this.color = "";
    this.contact = {
        email: "",
        phone: "",
        responsible: "",
    };
    this.projectId = null;
    this.isProjectTeam = false;
    this.logoId = null;
    this.logoUrl = null;

    this.members = [];
    this.commercialMembers = [];

    this.assignedFor = "";
}

SbTeam.PROJECT_TEAM_I18N_KEY = "_PROJECT_TEAM";
SbTeam.UNRESTRICTED_I18N_KEY = "_UNRESTRICTED_TEAM";
SbTeam.UNRESTRICTED_ACCESS__TEAM_COLOR = "#4C4C4C";

SbTeam.NO_CONFIRMATION_AVAILABLE_I18N_KEY = "_NO_CONFIRMATION_TEAM_AVAILABLE";
SbTeam.NO_CONFIRMATION_AVAILABLE__TEAM_COLOR = "#4C4C4C";

/**
 * A constant ID whose represent the active Team on the Time of Note creation
 */
SbTeam.ID_REPORT_TEAM = "sb::team.reporting";

/**
 * A constant ID whose represent No team
 */
SbTeam.ID_NOT_SET = "sb::team.not-set";

SbTeam.createUnrestrictedTeam = function () {
    const team = new SbTeam(null, SbTeam.UNRESTRICTED_I18N_KEY);
    team.color = SbTeam.UNRESTRICTED_ACCESS__TEAM_COLOR;
    return team;
};

/**
 * Represents a not existing team. It is used whether a Note does not require confirmation team.
 */
SbTeam.createNoConfirmationTeamAvailable = function () {
    const team = new SbTeam(null, SbTeam.NO_CONFIRMATION_AVAILABLE_I18N_KEY);
    team.color = SbTeam.NO_CONFIRMATION_AVAILABLE__TEAM_COLOR;
    return team;
};

/**
 * Represents a not existing team. It is used as Option on Project Settings
 * to require no team should be preselected as default
 */
SbTeam.createNoDefaultPreselectedConfirmationTeam = function (color) {
    const team = new SbTeam(
        "sb::team.not-set",
        "_NO_DEFAULT_CONFIRMATION_TEAM"
    );
    team.color = color;
    return team;
};

SbTeam.createReportersTeam = function (color) {
    var team = new SbTeam(SbTeam.ID_REPORT_TEAM, "_REPORTERS_TEAM");
    team.color = color;
    return team;
};

SbTeam.prototype.getFormattedAddress = function () {
    var str = "";
    if (this.address.postCode) {
        str = this.address.postCode + " - ";
    }
    if (this.address.city) {
        if (this.address.country) {
            str = str + this.address.city + " (" + this.address.country + ")";
        } else {
            str = str + this.address.city;
        }
    } else {
        if (this.address.country) {
            str += this.address.country;
        }
    }
    return str;
};

SbTeam.prototype.getDisplayName = function () {
    var isValidName = _.isString(this.name);
    if (isValidName || this.isProjectTeam) {
        return this.isProjectTeam ? SbTeam.PROJECT_TEAM_I18N_KEY : this.name;
    } else {
        return SbTeam.UNRESTRICTED_I18N_KEY;
    }
};

SbTeam.prototype.hasAddressDetails = function hasAddressDetails() {
    var hasAddress = false;
    var address = this.address;

    if (_.isObjectLike(address)) {
        hasAddress = Object.keys(address).some(function (key) {
            return typeof address[key] === "string" && address[key].length > 0;
        });
    }

    return hasAddress;
};

SbTeam.prototype.setColor = function (color) {
    if (!_.isString(color)) {
        return;
    }
    if (color.indexOf("#") === 0) {
        this.color = color;
    } else {
        this.color = "#" + color;
    }
};

SbTeam.prototype.setIsProjectTeam = function (isProjectTeam) {
    this.isProjectTeam = isProjectTeam;
};

SbTeam.prototype.setMembers = function (members) {
    this.members = members;
};

SbTeam.prototype.hasAreaManagers = function () {
    return this.members.some(function (member) {
        return member.hasAreaManagerPrivilege;
    });
};

SbTeam.prototype.hasMembers = function () {
    return (
        this.members.length > 0 ||
        this.commercialMembers.filter(
            (member) => member.commercialAccess.privilege !== "define_manager"
        ).length > 0
    );
};

export default SbTeam;
