import _ from "lodash";

export default function ($sbExternalService, SbProject) {
    "ngInject";

    /**
     * The current used project
     * @type {SbProject}
     */
    let currentProject;

    return {
        get: getCurrent,
        set: setCurrent,
        unset: unsetCurrent,
        pick: pick,
    };

    function getCurrent() {
        return currentProject;
    }

    function unsetCurrent() {
        currentProject = undefined;
        $sbExternalService.unsetActiveProject();
    }

    function setCurrent(project) {
        if (project instanceof SbProject) {
            currentProject = project;
            $sbExternalService.setActiveProject(project);
            return getCurrent();
        }

        throw new TypeError(
            'IllegalArgumentException: The argument is not of type "SbProject".'
        );
    }

    function pick(property) {
        return _.get(currentProject, property);
    }
}
