/**
 * The project state and project controller has one major functionality:
 *  # provide a common way of using the subbar
 *
 *  If you define a child state of sablono.project provide the following data if necessary:
 *      # good to have:
 *          # subBarTitle (i18n string)
 *          # category (APP_CATEGORY)
 *      # optional
 *          # backButtonTarget (ui-sref location, default is browser back)
 *          # hasBackButton (boolean)
 *          # isSubBarHidden (boolean)
 */
import _ from "lodash";

export default function (
    $rootScope,
    $log,
    $state,
    $window,
    $sbCalendarRepository,
    $sbStructure,
    $sbTemplate,
    EVENTS
) {
    "ngInject";

    ////////////////////////////////////////
    //
    //      Direct variables
    //
    ////////////////////////////////////////

    var vm = this;

    ////////////////////////////////////////
    //
    //      SCOPE properties
    //
    ////////////////////////////////////////

    vm.title = "";
    vm.category = "";

    vm.backShown = false;
    vm.backTarget = undefined;
    vm.barHidden = false;

    vm.goBack = goBack;
    vm.setPageToolbarOptions = setPageToolbarOptions;
    vm.clearCaches = clearCaches;

    activate();

    ////////////////////////////////////////
    //
    //      WATCHER
    //
    ////////////////////////////////////////

    $rootScope.$on(
        "$stateChangeStart",
        function onStateChangeStart(event, toState, toParams, fromState) {
            if (fromState.name !== toState.name) {
                vm.setPageToolbarOptions(toState.data);
                vm.clearCaches();
            }
        }
    );

    $rootScope.$on(
        EVENTS.PLAN_WORK_REVIEW_SECTION_CHANGED,
        function onSectionChange(event, toSection) {
            var mergedOptions = _.assign({}, $state.current.data, {
                category: toSection,
            });

            vm.setPageToolbarOptions(mergedOptions);
        }
    );

    ////////////////////////////////////////
    //
    //      IMPL
    //
    ////////////////////////////////////////

    function activate() {
        vm.setPageToolbarOptions($state.current.data);
        vm.clearCaches();
    }

    /**
     * Set sub-state specific toolbar options
     * like back navigation button, page title and coloring.
     *
     * @param options
     * @param options.subBarTitle {string}
     * @param options.category {string} - One of PLAN, WORK, REVIEW
     * @param options.hasBackButton {boolean}
     * @param options.backButtonTarget {String} - A state identifier
     * @param options.isSubBarHidden {boolean}
     */
    function setPageToolbarOptions(options) {
        vm.title = options.subBarTitle;
        vm.category = options.category;

        vm.backShown = !!options.hasBackButton;
        vm.backTarget = options.backButtonTarget;
        vm.barHidden = !!options.isSubBarHidden;
    }

    /**
     * Clear the runtime API cache!
     */
    function clearCaches() {
        $log.debug("API cache cleared.");

        $sbStructure.clearCache();
        $sbTemplate.clearCache();
        $sbCalendarRepository.clear();
    }

    function goBack() {
        if (_.isString(vm.backTarget)) {
            $state.go(vm.backTarget);
        } else {
            $window.history.back();
        }
    }
}
