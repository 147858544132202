import templateHtml from "./cost_group.html";
import naturalSortFn from "common/filters/natural_sort.pojo";
import CombinedCostGroupStage from "../../model/combined_cost_group_stage.class";

export default {
    name: "sbCostGroup",
    templateUrl: templateHtml,
    bindings: {
        costGroup: "<",
        onFormInputChange: "&",
        onStageCellClick: "&",
        onUngroup: "&",
        onCombineActivities: "&",
        hasEditRights: "<",
        isLoading: "<",
    },
    controllerAs: "$ctrl",
    controller: SbCostGroupController,
};

function SbCostGroupController($sbPermission, $sbProject) {
    "ngInject";

    var vm = this;
    vm.hasReviewPermissions = $sbPermission.hasReviewPermissions(
        $sbProject.getCurrent().privileges
    );
    vm.isLinkToDeliverablesPageDisabledFor =
        isLinkToDeliverablesPageDisabledFor;
    vm.groupingStages = [];
    vm.isGroup = isGroup;
    vm.getGroupStageNames = getGroupStageNames;
    this.$onChanges = function () {
        vm.groupingStages = getGroupingStages(vm.costGroup.stages);
    };

    function isLinkToDeliverablesPageDisabledFor(stage, progressType) {
        return (
            !vm.hasReviewPermissions ||
            stage[progressType] < 1 ||
            !stage.isReportedAtToday() ||
            stage instanceof CombinedCostGroupStage
        );
    }

    function getGroupingStages(stages) {
        let previousStage = null;
        const sortedStages = stages.sort(sortStages);
        let groupIndex = 0;

        return sortedStages.reduce((groupingStage, stage) => {
            const item = { stage };
            item.groupByKey = false;
            if (previousStage !== null) {
                if (
                    previousStage.processTemplateId !== stage.processTemplateId
                ) {
                    // the next process template group
                    addProcessTemplate(groupingStage, { stage });
                    addStage(groupingStage, item, 0);
                    groupIndex = 1;
                } else {
                    addStage(groupingStage, item, groupIndex);
                    groupIndex++;
                }
            } else {
                // according the sorting stages by processTemplateId, so the first element of array must be the first process template group
                addProcessTemplate(groupingStage, { stage });
                addStage(groupingStage, item, 0);
                groupIndex = 1;
            }
            previousStage = stage;
            return groupingStage;
        }, []);
    }

    function sortStages(first, second) {
        if (
            first instanceof CombinedCostGroupStage &&
            second instanceof CombinedCostGroupStage
        ) {
            return naturalSortFn(first.name, second.name);
        }

        if (first instanceof CombinedCostGroupStage) {
            return -1;
        }
        if (second instanceof CombinedCostGroupStage) {
            return 1;
        }
        return naturalSortFn(
            first.processTemplateName,
            second.processTemplateName
        );
    }

    function addProcessTemplate(groupingStages, item) {
        item.groupByKey = true;
        item.index = item.stage.processTemplateId;
        groupingStages.push(item);
    }

    function addStage(groupingStages, item, groupIndex) {
        item.groupByKey = false;
        item.groupIndex = groupIndex;
        item.index = item.stage.id;
        groupingStages.push(item);
    }

    function isGroup(item) {
        return item.stage instanceof CombinedCostGroupStage;
    }

    function getGroupStageNames(item) {
        return item.stage.childCostGroupStages
            .map((activity_template) => activity_template.name)
            .join("\n");
    }
}
