import _ from "lodash";
import moment from "moment";
import LeanboardLane from "./leanboard_lane.class";
import LeanboardTimeframe from "./leanboard_timeframe.class";

/**
 * Create the initial data model for a leanboard column.
 *
 * @param {LeanboardTimeframe} timeframe
 * @constructor
 */
function Leanboard(timeframe) {
    this.lanes = [];
    this.timeframe = timeframe;
}

Leanboard.createLaneForBoard = function (board, deliverable) {
    var lane = new LeanboardLane(deliverable, board);
    board.addLane(lane);
    return lane;
};

Leanboard.getTimeFrameOf = function (from, to) {
    return new LeanboardTimeframe(from, to);
};

Leanboard.prototype.getEarliestDisplayedDay = function () {
    return this.timeframe.getStartAsMoment();
};

Leanboard.prototype.getHeader = function (columnTimespan) {
    var currentStart = this.timeframe.getStartAsMoment();
    var end = this.timeframe.getEndAsMoment();

    var dates = [];

    // setup array of dates
    //
    while (currentStart.isBefore(end)) {
        dates.push(currentStart.clone());
        currentStart = moment(currentStart).add(1, columnTimespan);
    }

    return dates;
};

/**
 * Add another column to the leanboard.
 *
 * @param {LeanboardLane} lane
 * @returns {Leanboard}
 */
Leanboard.prototype.addLane = function (lane) {
    this.lanes.push(lane);
    return this;
};

/**
 * Add another column to the leanboard.
 *
 * @param {Array.<LeanboardLane>} lanes
 * @returns {Leanboard}
 */
Leanboard.prototype.setLanes = function (lanes) {
    this.lanes = lanes;
    return this;
};

/**
 * Get columns of leanboard.
 *
 * @param {Function?} fnSort - Optionally a sort function can be given.
 * @returns {Array.<LeanboardLane>}
 */
Leanboard.prototype.getLanes = function (fnSort) {
    if (fnSort) {
        return this.lanes.sort(fnSort);
    }
    return this.lanes;
};

export default Leanboard;
