import angular from "angular";
import moment from "moment";
import sbDatePickerHtml from "./date_picker.tmpl.html";

export default angular
    .module("sbApp.common.components.datePicker", ["sbApp.services"])
    .component("sbDatePicker", {
        templateUrl: sbDatePickerHtml,
        bindings: {
            datetime: "<",
            timezone: "<",
            unsetUnavailable: "<",
            onDateChange: "&",
            unsetLabel: "@?",
            customLabel: "@?",
            isRequired: "<",
        },
        controller: DatePickerCtrl,
    });

function DatePickerCtrl($log, $sbDates) {
    "ngInject";
    var vm = this;

    // will be used when resetting date
    //
    var initial = {
        // datetime: ...
    };
    vm.dateIsInvalid = true;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.onCalendarDateChange = onCalendarDateChange;
    vm.onUnsetDateCheck = onUnsetDateCheck;

    function $onInit() {
        if ($sbDates.isValidDateValue(vm.datetime)) {
            vm.dateIsInvalid = !vm.datetime; // null is considered "valid"
            _saveInitialDate(vm.datetime);
        } else {
            vm.dateIsInvalid = true;
            $log.warn("Invalid Date/Moment", vm.datetime);
        }
    }

    function $onChanges(changes) {
        if (changes.timezone) {
            vm.timezone = changes.timezone.currentValue;
        }

        if (changes.datetime && $sbDates.isValidDateValue(changes.datetime)) {
            vm.datetime = changes.datetime.currentValue;
        }

        if ($sbDates.isValidDateValue(vm.datetime) && vm.timezone) {
            _setDateAndTimezone();

            _setSelection(vm.datetime, isSet(vm.datetime));
            vm.dateIsInvalid = !vm.datetime; // null is considered "valid"
        } else {
            vm.dateIsInvalid = true;
        }
    }

    function _setSelection(datetime, isSet) {
        vm.selected = {
            date: isSet ? $sbDates.toTimeZoneShiftedJSDate(datetime) : null,
            unset: !isSet,
        };
    }

    function isSet(date) {
        return date !== null;
    }

    function _setDateAndTimezone() {
        vm.datetime = $sbDates.momentOrNull(vm.datetime); // copy
        if (isSet(vm.datetime)) {
            vm.datetime.tz(vm.timezone.name);
        }
    }

    function onCalendarDateChange(selectedValues) {
        $log.debug("DatePickerCtrl::onDateChange", selectedValues);

        if (selectedValues.date !== null) {
            vm.onDateChange({
                datetime: $sbDates.toTimeZoneShiftedMoment(
                    selectedValues.date,
                    vm.timezone.name
                ),
            });
            vm.selected.unset = false;

            vm.dateIsInvalid = !vm.datetime; // null is considered "valid"
        } else {
            vm.onDateChange({
                datetime: selectedValues.date,
            });
            vm.dateIsInvalid = true;
        }
    }

    function onUnsetDateCheck(selectedValues) {
        $log.debug("Unset date un/checked", selectedValues);

        selectedValues.unset ? _clear() : _reset();
        onCalendarDateChange(vm.selected);
    }

    // clear the input
    function _clear() {
        $log.debug("Clear calendar date");
        vm.selected.date = null;
    }

    // restore initially set value
    function _reset() {
        $log.debug("Reset calendar date", initial.datetime);
        _setSelection(initial.datetime.tz(vm.timezone.name), true);
    }

    function _saveInitialDate(datetime) {
        initial.datetime = isSet(datetime) ? datetime : moment();
    }
}
