import angular from "angular";
import mergeRouteConfig from "./merge.route";
import mergeCtrl from "./merge.controller";
import mergeService from "./services/merge.service";
import sbMergeWizard from "./directives/sbMergeWizard/sb_merge_wizard.directive";
import sbCheckboxList from "./directives/sbCheckboxList/sb_checkbox_list.directive";
import "common/services/services";
import "../../common/ui-elements/directives/sbFileInput/sb_file_input.directive";

export default angular
    .module("sbApp.mergeModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.sbFileInput",
    ])
    .config(mergeRouteConfig)
    .controller("MergeCtrl", mergeCtrl)
    .factory("mergeService", mergeService)
    .directive("sbMergeWizard", sbMergeWizard)
    .directive("sbCheckboxList", sbCheckboxList);

/* declaration */
