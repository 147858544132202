import templateHtml from "./project_settings.html";
import "angular-ui-router";

/**
 * This is the configuration for project team state.
 * It uses ui-router to configure the route.
 *
 * @param {Object} $stateProvider - $stateProvider
 */
export default function projectSettingsStateConfig($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.settings", {
        url: "/settings",
        gaPageTrack: "/settings", // google analytics extension
        templateUrl: templateHtml,
        controller: "ProjectSettingsCtrl",
        controllerAs: "settings",
        data: {
            category: "SETTINGS",
            title: "SECTION_PROJECT_SETTINGS_PAGE_TITLE",
            icon: "mdi mdi-cogs",
            isSubBarHidden: true,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_PROJECT_SETTINGS_PAGE);
        },
    });
}
