import PresentableError from "./PresentableError";

/**
 * Error if a request is aborted
 *
 * @constructor
 * @extends PresentableError
 *
 * @param {XMLHttpRequest} request - Used XMLHttpRequest object
 */
function RequestAbortedError(request) {
    this.code = "REQUEST_ABORTED";
    this.title = "ERROR_REQUEST_ABORTED_TITLE";
    this.message = "ERROR_REQUEST_ABORTED_MESSAGE";
    this.request = request;
}

RequestAbortedError.prototype = Object.create(PresentableError.prototype); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ RequestAbortedError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.NONE;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ RequestAbortedError.prototype.shouldAutoClose = function () {
    return true;
};

export default RequestAbortedError;
