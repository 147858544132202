/**
 * Service that defines all view filter that are used in combination with deliverables.
 * Every functions returns an instance of ViewFilter
 *              ('common/services/oDataService/odata_view_filter.class')
 */
import moment from "moment";

import ODataFilterFactory from "../../../common/services/oDataService/odata_filter_factory.class";
import ViewFilter from "../../../common/services/oDataService/odata_view_filter.class";
import DateViewFilter from "../../../common/services/oDataService/odata_date_view_filter.class";
import ODataEqOption from "../../../common/services/oDataService/odata_eq_option";

export default function deliverablesFilterService(
    $sbTemplate,
    $sbFilterMenu,
    $sbODataViewFilter,
    $sbODataFilter
) {
    "ngInject";

    ////////
    //
    //  PUBLIC API
    //
    ////////

    return {
        projectODataFilter: projectODataFilter,
        codeOdataFilter: codeOdataFilter,

        nameFilter: nameFilter,
        codeFilter: codeFilter,
        descriptionFilter: descriptionFilter,
        typeFilter: typeFilter,
        nameCodeDescFilter: nameCodeDescFilter,
        structureFilter: structureFilter,
        templateFilter: templateFilter,
        workScheduledDateFilter: workScheduledDateFilter,
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter,
        inspectionDateFilter: inspectionDateFilter,
        progressFilter: progressFilter,
        scheduleFilter: scheduleFilter,
        noteFilter: noteFilter,
        projectFilter: projectFilter,
        areaManagerFilter: areaManagerFilter,
        assignedTemplateFilter: assignedTemplateFilter,
    };

    ////////
    //
    //  IMPLEMENTATION
    //
    ////////

    function projectODataFilter(projectId) {
        return new ODataFilterFactory().eq("PROJECT_ID", projectId);
    }

    function codeOdataFilter(code) {
        return new ODataFilterFactory().eq("CODE", code);
    }

    function progressFilter() {
        var vf = new ViewFilter("_STATUS", "progress")
            .setClass("sb-filter-progress")
            .setOptions([
                {
                    label: "_PROGRESS_0",
                    odata: function (factory) {
                        return factory.block(nothingStartedOdata());
                    },
                    name: "todo", // URL
                },
                {
                    label: "_PROGRESS_50",
                    odata: function (factory) {
                        return factory
                            .not()
                            .block(nothingStartedOdata())
                            .and()
                            .not()
                            .block(everythingDoneOdata());
                    },
                    name: "inProgress", // URL
                },
                {
                    label: "_PROGRESS_100",
                    odata: function (factory) {
                        factory.block(everythingDoneOdata());
                    },
                    name: "done", // URL
                },
            ]);
        vf.type = ViewFilter.TYPE.CHECKBOX;
        vf.isInReducedFilters = true;
        return vf;
    }

    function nothingStartedOdata() {
        return new ODataFilterFactory()
            .eq("ACTIVITIES_STARTED", 0)
            .and()
            .eq("ACTIVITIES_DONE", 0)
            .and()
            .eq("ACTIVITIES_WAITING_FOR_CONFIRMATION", 0)
            .and()
            .eq("ACTIVITIES_CONFIRMED", 0)
            .and()
            .eq("ACTIVITIES_REJECTED", 0);
    }

    function everythingDoneOdata() {
        return new ODataFilterFactory()
            .eq("ACTIVITIES_NOT_STARTED", 0)
            .and()
            .eq("ACTIVITIES_STARTED", 0)
            .and()
            .eq("ACTIVITIES_WAITING_FOR_CONFIRMATION", 0)
            .and()
            .eq("ACTIVITIES_REJECTED", 0);
    }

    function noteFilter() {
        var vf = new ViewFilter("_NOTES", "notes")
            .setClass("sb-filter-notes")
            .setOptions([
                {
                    label: "_OPEN_CLAIMS",
                    odata: $sbODataFilter.odataHas("CLAIM_QUANTITY"),
                    name: "claim", // URL
                },
                {
                    label: "_OPEN_OBSTRUCTIONS",
                    odata: $sbODataFilter.odataHas("OBSTRUCTION_QUANTITY"),
                    name: "obstruction", // URL
                },
                {
                    label: "_INFO_S",
                    odata: $sbODataFilter.odataHas("INFO_QUANTITY"),
                    name: "info", // URL
                },
                {
                    label: "_NO_NOTES",
                    odata: function (filterFactory) {
                        return filterFactory
                            .block(
                                new ODataFilterFactory()
                                    .eq("CLAIM_QUANTITY", null)
                                    .or()
                                    .eq("CLAIM_QUANTITY", 0)
                            )
                            .and()
                            .block(
                                new ODataFilterFactory()
                                    .eq("OBSTRUCTION_QUANTITY", null)
                                    .or()
                                    .eq("OBSTRUCTION_QUANTITY", 0)
                            )
                            .and()
                            .block(
                                new ODataFilterFactory()
                                    .eq("INFO_QUANTITY", null)
                                    .or()
                                    .eq("INFO_QUANTITY", 0)
                            );
                    },
                    name: "without", // URL
                },
            ]);
        vf.type = ViewFilter.TYPE.CHECKBOX;
        vf.isInReducedFilters = true;
        return vf;
    }

    function scheduleFilter() {
        var vf = new ViewFilter("_SCHEDULE", "schedule")
            .setClass("sb-filter-schedule")
            .setOptions([
                {
                    label: "_ON_SCHEDULE",
                    odata: _isOnTimeODataFilter(),
                    name: "onTime", // URL
                },
                {
                    label: "_BEHIND_SCHEDULE",
                    odata: _isBehindODataFilter(),
                    name: "behind", // URL
                },
                {
                    label: "_UNSCHEDULED",
                    odata: $sbODataFilter.odataUnscheduled,
                    name: "unscheduled", // URL
                },
            ]);
        vf.type = ViewFilter.TYPE.CHECKBOX;
        vf.isInReducedFilters = true;
        return vf;
    }

    function nameFilter() {
        var vf = ViewFilter.createTextFilter("_NAME", "name", "NAME");
        vf.placeholder = "_FILTER_NAME_TEXT_PLACEHOLDER";
        return vf;
    }

    function codeFilter() {
        var vf = ViewFilter.createTextFilter("_CODE", "code", "CODE");
        vf.placeholder = "_FILTER_CODE_TEXT_PLACEHOLDER";
        return vf;
    }

    function descriptionFilter() {
        var vf = ViewFilter.createTextFilter("_DESC", "desc", "DESC")
            .enableNullCondition("DESC")
            .setNullValueTranslatable("_NO_DESCRIPTION");
        vf.placeholder = "_FILTER_DESC_TEXT_PLACEHOLDER";
        return vf;
    }

    function typeFilter(isInReducedFilters = false) {
        return $sbFilterMenu.fetchDeliverableTypes().then(function (types) {
            const vf = new ViewFilter("_DELIVERABLE_TYPE", "type");
            vf.isInReducedFilters = isInReducedFilters;
            vf.setOptions(
                types.map(function (type) {
                    return new ODataEqOption(type.name, type.name, "TYPE_NAME");
                })
            );
            vf.type = ViewFilter.TYPE.SELECT;
            vf.placeholder = "_FILTER_TYPE_TEXT_PLACEHOLDER";
            return vf;
        });
    }

    function nameCodeDescFilter() {
        var vf = new ViewFilter("_DELIVERABLE_SEARCH", "search");
        vf.setODataHandler(function (factory, value) {
            return factory.block(
                new ODataFilterFactory()
                    .like("NAME", value)
                    .or()
                    .like("DESC", value)
                    .or()
                    .like("CODE", value)
            );
        });

        vf.isInReducedFilters = true;
        vf.placeholder = "_FILTER_SEARCH_DELIVERABLE_PLACEHOLDER";
        return vf;
    }

    function startDateFilter() {
        return DateViewFilter.createDateFilter(
            "START_DATE",
            "start",
            "SD",
            [
                $sbODataFilter.odataDateToday("SD"),
                $sbODataFilter.odataDateNextWeek("SD"),
                $sbODataFilter.odataDateNextMonth("SD"),
                $sbODataFilter.odataDateLastWeek("SD"),
                $sbODataFilter.odataDateLastMonth("SD"),
            ],
            new Date(),
            moment().add(14, "days")._d
        );
    }

    function endDateFilter() {
        return DateViewFilter.createDateFilter(
            "DUE_DATE",
            "end",
            "CD",
            [
                $sbODataFilter.odataDateToday("CD"),
                $sbODataFilter.odataDateNextWeek("CD"),
                $sbODataFilter.odataDateNextMonth("CD"),
                $sbODataFilter.odataDateLastWeek("CD"),
                $sbODataFilter.odataDateLastMonth("CD"),
            ],
            new Date(),
            moment().add(14, "days")._d
        );
    }

    function inspectionDateFilter() {
        const formatter = (date) => "'" + date.toISOString() + "'";
        var vf = DateViewFilter.createDateFilter(
            "_LAST_INSPECTION",
            "inspection",
            "STATE_CHANGE_TIME",
            [
                $sbODataFilter.odataDateToday("STATE_CHANGE_TIME", formatter),
                $sbODataFilter.odataDateLastWeek(
                    "STATE_CHANGE_TIME",
                    formatter
                ),
                $sbODataFilter.odataDateLastMonth(
                    "STATE_CHANGE_TIME",
                    formatter
                ),
            ],
            moment().subtract(14, "days")._d,
            new Date(),
            formatter
        ).setClass("sb-filter-inspection");

        vf.isInReducedFilters = true;
        return vf;
    }

    function workScheduledDateFilter() {
        var vf = rangeFilter(
            "WORK_SCHEDULED",
            "workScheduled",
            [
                $sbODataFilter.odataRangeToday(),
                $sbODataFilter.odataRangeNextWeek(),
                $sbODataFilter.odataRangeNextMonth(),
                $sbODataFilter.odataRangeLastWeek(),
                $sbODataFilter.odataRangeLastMonth(),
            ],
            moment().subtract(14, "days")._d,
            new Date()
        ).setClass("sb-filter-work");

        vf.isInReducedFilters = true;
        return vf;
    }

    function projectFilter() {
        return eqFilter("", "projectId", "PROJECT_ID").setDisplayable(false);
    }

    /**
     * Create view filter for process templates.
     *
     * @param {Object} [options]
     * @param {boolean} [options.fallbackToFirst=false]
     *
     * @returns {PromiseLike<ViewFilter>}
     */
    function templateFilter(options = {}, customOptionFilter = (x) => x) {
        const { fallbackToFirst = false } = options;

        return $sbTemplate
            .getProjectBasedList()
            .then(customOptionFilter)
            .then(function (templates) {
                var vf = new ViewFilter("_FILTER_LABEL_PROCESS", "workflow")
                    .setOptions(_optionMapper(templates))
                    .enableNullCondition("TEMPLATE_ID")
                    .setClass("sb-filter-template");

                const hasTemplates =
                    Array.isArray(templates) && templates.length > 0;
                if (hasTemplates && fallbackToFirst) {
                    vf.setValue(templates[0].ID);
                }

                vf.type = ViewFilter.TYPE.WORKFLOW;
                vf.isInReducedFilters = true;
                return vf;
            });
    }

    function _optionMapper(templates) {
        return templates.map(function (template) {
            return new ODataEqOption(template.ID, template.NAME, "TEMPLATE_ID");
        });
    }

    function _onlyAssignedTemplatesFilter(templates) {
        return templates.filter(function (template) {
            // We want to see only templates that are used
            // https://sablono.atlassian.net/browse/SAB-3681
            return template.USED_IN_COMPONENTS > 0;
        });
    }

    function assignedTemplateFilter(options) {
        return templateFilter(options, _onlyAssignedTemplatesFilter);
    }

    function structureFilter() {
        return $sbODataViewFilter.structureFilter(
            "_STRUCTURE",
            "structureId",
            "STRUCTURE_SPAN_INDEX"
        );
    }

    function areaManagerFilter() {
        var vf = new ViewFilter("_FILTER_AREA_MANAGER", "areaManagerFilter");

        vf.setODataHandler(function (factory, value) {
            return factory.block(
                new ODataFilterFactory()
                    .eq("AREA_MANAGER_USER_NAME", value)
                    .or()
                    .eq("AREA_MANAGER_USER_NAME", null)
            );
        });

        vf.isDisplayable = false;
        vf.isInReducedFilters = true;

        return vf;
    }

    ////////
    //
    //  LOGIC to make the structure filter work.
    //
    ////////

    function _mapToEqOptionObjects(suggestions) {
        return suggestions.map(function (sug) {
            // map it as an EqOption
            return new ODataEqOption(sug.id, sug.name, "CHILD_PI_TEMPLATE_ID");
        });
    }

    function eqFilter(keyLabel, key, oDataProperty) {
        return new ViewFilter(keyLabel, key)
            .setODataHandler(function (factory, value) {
                return factory.eq(oDataProperty, value);
            })
            .enableNullCondition(oDataProperty);
    }

    /**
     * Create a date range view filter.
     *
     * @param {String} keyLabel - the human readable name of the filter key
     * @param {String} key      - the internal key that is identifying the view filter
     * @param {Array<Object>} options   - we might create a options class soon to specify the options in detail.
     * @param {Date} initFrom   - initial date from in the date picker if custom
     * @param {Date} initTo     - initial date to in the date picker if custom
     * @returns {DateViewFilter}
     */
    function rangeFilter(keyLabel, key, options, initFrom, initTo) {
        return new DateViewFilter(keyLabel, key)
            .setODataHandler($sbODataFilter.createODataExprForInTimeRange)
            .setOptions(options)
            .setValueFrom(initFrom)
            .setValueTo(initTo)
            .clear();
    }

    function _isBehindODataFilter(odataKey) {
        return function (filterFactory) {
            return filterFactory.gt(odataKey || "ACTIVITIES_BEHIND", 0);
        };
    }

    function _isOnTimeODataFilter(odataKey) {
        return function (filterFactory) {
            return filterFactory.eq(odataKey || "ACTIVITIES_BEHIND", 0);
        };
    }
}
