import _ from "lodash";
import jsSVG from "jsSVG";
import naturalSortFn from "common/filters/natural_sort.pojo";

export default function svgService($document) {
    "ngInject";
    // initial pointer is set to this position
    //
    var pointer = {
        left: 0,
        top: 0,
    };

    // defines fixed pointer movements to achieve current layout
    //
    var POINTER_MOVEMENTS = {
        down: {
            cells: 75,
            columnHeaders: 90,
        },
        right: {
            cells: 70,
            content: 150,
            columnHeaders: 150,
        },
    };

    // config fonts and colors
    //
    var SVG_STYLE = {
        normal: {
            colors: {
                fill: "#FFF",
                stroke: "#999",
                "stroke-width": 1,
            },
            font: {
                size: 14,
            },
        },
        bold: {
            colors: {
                fill: "#FFF",
                stroke: "#000",
                "stroke-width": 1,
            },
            font: {
                size: 18,
                anchor: "middle",
                weight: "bold",
            },
        },
    };

    var DIMENSIONS = {
        offsetRight: 10,
        row: {
            heigth: 55,
        },
        structureCells: {
            width: 100,
        },
        deliverableCells: {
            width: 60,
        },
    };

    // stores any patterns created for hatched styling
    //
    var svgPatterns = {
        hatched: [],
        crossHatched: [],
    };

    var service = {
        getSvg: getSvg,
        createSvg: createSvg,
        createPatternOnSvg: createPatternsOnSvg,
        findPatternBy: findPatternBy,
        resetSvgPatterns: resetSvgPatterns,
    };

    return service;

    function createDrawing(width, height) {
        var element = $document[0].createElement("div");

        // creates the SVG document
        //
        var draw = jsSVG(element);
        draw.viewbox({
            x: 0,
            y: 0,
            width: width || 5000,
            height: height || 5000,
        });
        return draw;
    }

    function drawStructureColumnHeader(draw, text, config) {
        return drawRectangleWithText(draw, text, pointer, config);
    }

    function drawDeliverablesColumnHeader(draw, text, config) {
        return drawRectangleWithText(draw, text, pointer, config);
    }

    function drawStructureCell(draw, text, config) {
        return drawRectangleWithText(draw, text, pointer, config);
    }

    function drawDeliverableCell(draw, text, config) {
        return drawRectangleWithText(draw, text, pointer, config, true);
    }

    function getSvg(svgContainerId) {
        var svg = $document[0].getElementById(svgContainerId).firstElementChild;

        return jsSVG.adopt(svg);
    }

    // public
    //
    function createSvg(rows, translations) {
        resetPointer();

        var totalWidth = caluclateTotalWidth(
            pointer.left,
            POINTER_MOVEMENTS,
            getMaxCells(rows)
        );
        var totalHeight = caluclateTotalHeight(
            pointer.top,
            POINTER_MOVEMENTS,
            rows.length
        );

        var header = {
            structureColumn: {
                width: DIMENSIONS.structureCells.width,
                height: DIMENSIONS.row.heigth,
                font: SVG_STYLE.bold.font,
                colors: SVG_STYLE.bold.colors,
            },
            deliverablesColumn: {
                width:
                    totalWidth -
                    pointer.left -
                    POINTER_MOVEMENTS.right.columnHeaders -
                    DIMENSIONS.offsetRight,
                height: DIMENSIONS.row.heigth,
                font: SVG_STYLE.bold.font,
                colors: SVG_STYLE.bold.colors,
            },
        };

        var cells = {
            structure: {
                width: DIMENSIONS.structureCells.width,
                height: DIMENSIONS.row.heigth,
                font: SVG_STYLE.bold.font,
                colors: SVG_STYLE.bold.colors,
            },
            deliverable: {
                width: DIMENSIONS.deliverableCells.width,
                height: DIMENSIONS.row.heigth,
                font: SVG_STYLE.normal.font,
                colors: SVG_STYLE.normal.colors,
            },
        };

        var draw = createDrawing(totalWidth, totalHeight);

        drawStructureColumnHeader(
            draw,
            translations.structureColumnTitle,
            header.structureColumn
        );

        movePointerRight(POINTER_MOVEMENTS.right.columnHeaders);
        drawDeliverablesColumnHeader(
            draw,
            translations.deliverablesColumnTitle,
            header.deliverablesColumn
        );

        _.forEach(rows, function (row) {
            resetPointerToLeftStart();
            movePointerDown(POINTER_MOVEMENTS.down.cells);

            drawStructureCell(draw, row.code, cells.structure);

            movePointerRight(POINTER_MOVEMENTS.right.content);

            // for each cell node on the entry
            //
            _.forEach(
                row.cells.sort(function (c1, c2) {
                    return naturalSortFn(c1.infoToDisplay, c2.infoToDisplay);
                }),
                function (cell) {
                    var svgNode = drawDeliverableCell(
                        draw,
                        cell.infoToDisplay,
                        cells.deliverable
                    );

                    setCodeAsId(svgNode, cell.code);

                    // will place the next box beside the last
                    //
                    movePointerRight(POINTER_MOVEMENTS.right.cells);
                }
            );
        });

        // once complete - return the SVG element to the caller
        return draw.svg();
    }

    function resetSvgPatterns() {
        svgPatterns = {
            hatched: [],
            crossHatched: [],
        };
    }

    // private
    //

    function resetPointer() {
        pointer = {
            left: 5,
            top: 5,
        };
    }

    function resetPointerToLeftStart() {
        pointer.left = 5;
    }

    function movePointerRight(amount) {
        pointer.left += amount;
    }

    function movePointerDown(amount) {
        pointer.top += amount;
    }

    function drawRectangleWithText(draw, text, pointer, config, alignTextLeft) {
        var r1 = drawRectangle(
            draw,
            config.width,
            config.height,
            pointer.left, // x
            pointer.top, // y
            config.colors
        );

        // get the positioning needed to draw the text
        //
        var p1 = r1.bbox();
        var p1TextX = alignTextLeft ? p1.x + 3 : p1.cx;
        var p1TextY = p1.y + 17;

        drawText(draw, text, p1TextX, p1TextY, config.font);

        return r1;
    }

    function drawRectangle(draw, width, height, positionX, positionY, colors) {
        var rect = draw
            .rect(width, height)
            .move(positionX, positionY)
            .attr(colors);

        return rect;
    }

    function drawText(draw, text, moveX, moveY, font) {
        var t1 = draw
            .text(text || "")
            .move(moveX, moveY)
            .font(font);
    }

    function setCodeAsId(rect, code) {
        rect.node.id = code;
        return rect;
    }

    function getMaxCells(data) {
        return data.reduce(function (max, row) {
            return Math.max(max, row.cells.length || 0);
        }, 0);
    }

    function caluclateTotalWidth(offset, movements, maxNumOfCells) {
        if (maxNumOfCells < 3) {
            maxNumOfCells = 3;
        }
        return (
            offset +
            movements.right.content +
            maxNumOfCells * movements.right.cells
        );
    }

    function caluclateTotalHeight(offset, movements, numOfRows) {
        var headerHeight =
            movements.down.cells + movements.down.columnHeaders + offset;
        var contentHeight = numOfRows * movements.down.cells;

        return contentHeight + headerHeight;
    }

    function createPatternsOnSvg(svg, color) {
        var pair = _createPatternsOnSvg(svg, color);

        _storePatterns(color, _createPatternsOnSvg(svg, color));

        return pair;
    }

    function _createPatternsOnSvg(svg, color) {
        return {
            hatched: _createHatchPattern(svg, color),
            crossHatched: _createCrossHatchedPattern(svg, color),
        };
    }

    function _storePatterns(color, patterns) {
        svgPatterns.hatched.push({
            color: color,
            pattern: patterns.hatched,
        });

        svgPatterns.crossHatched.push({
            color: color,
            pattern: patterns.crossHatched,
        });
    }

    function _createHatchPattern(svg, color) {
        var GAP_SIZE = 10;
        var LINE_WIDTH = 5;

        return svg.pattern(GAP_SIZE, GAP_SIZE, function (add) {
            add.line(-5, GAP_SIZE, GAP_SIZE, -5).stroke({
                color: color,
                width: LINE_WIDTH,
                linecap: "square",
            });

            add.line(5, GAP_SIZE, GAP_SIZE, 5).stroke({
                color: color,
                width: LINE_WIDTH,
                linecap: "square",
            });
        });
    }

    function _createCrossHatchedPattern(svg, color) {
        var GAP_SIZE = 20;
        var LINE_WIDTH = 4;
        var LINE_COLOR = color;

        return svg.pattern(GAP_SIZE, GAP_SIZE, function (add) {
            add.line(0, 0, GAP_SIZE, GAP_SIZE).stroke({
                color: LINE_COLOR,
                width: LINE_WIDTH,
                linecap: "round",
            });

            add.line(0, GAP_SIZE, GAP_SIZE, 0).stroke({
                color: LINE_COLOR,
                width: LINE_WIDTH,
                linecap: "round",
            });
        });
    }

    function findPatternBy(type, color) {
        var exists = _.find(svgPatterns[type], function (pattern) {
            return pattern.color === color;
        });

        return exists && exists.pattern;
    }
}
