import _ from "lodash";
import moment from "moment";
import BaseComponent from "./sb_base_component.class";
import Activity from "./sb_activity.class";
import ActivityState from "./sb_activity_state.class";

/**
 * Create a deliverable
 *
 * @constructor
 * @extends BaseComponent
 */
function Group(id, name, code) {
    BaseComponent.call(this, id, name, code);

    //
    // navigation to other collections
    //

    /**
     * All the child components of the group
     * @type {Array<BaseComponent>}
     */
    this.children = null;
}

Group.prototype = Object.create(BaseComponent.prototype);

Group.ODATA_PROPERTY_MAP = {
    ID: "id",
    PI_TEMPLATE_ID: "piTemplateId",
    PROJECT_ID: "projectId",
    PARENT_ID: "parentId",

    NAME: "name",
    DESC: "desc",
    CODE: "code",
    CATEGORY: "category",

    SD: "startDate",
    CD: "endDate",
    IS_BEHIND: "scheduleState",

    PROGRESS: "progress",

    ISSUES_CHANGE_TIME: "issueChangeTime",
    PROGRESS_CHANGE_TIME: "progressChangeTime",
    PROGRESS_CHANGE_AUTHOR_DB_NAME: "progressChangeAuthor.dbName",
    PROGRESS_CHANGE_AUTHOR_DISPLAY_NAME: "progressChangeAuthor.displayName",
    PROGRESS_CHANGE_AUTHOR_INITIALS: "progressChangeAuthor.initials",
};

Group.createFromOdataObject = function (odataObject) {
    const clonedData = _.clone(odataObject);
    const instance = new Group(clonedData.ID, clonedData.NAME, clonedData.CODE);
    instance.__odataSource = clonedData;

    // use the property map to translate odata keys to class keys
    //
    Object.keys(Group.ODATA_PROPERTY_MAP).reduce(function (
        deliverable,
        odataProperty
    ) {
        const keyPath = Group.ODATA_PROPERTY_MAP[odataProperty];
        const value = clonedData[odataProperty];

        // if the value is null -> keep the default value from the constructor
        if (_.isNull(value)) {
            return deliverable;
        }

        _.set(deliverable, keyPath, value);

        return deliverable;
    }, instance);

    // make the dates proper moment dates
    //
    instance.startDate = instance.toMomentOrNull(instance.startDate);
    instance.endDate = instance.toMomentOrNull(instance.endDate);

    instance.progressChangeTime = instance.toMomentOrNull(
        instance.progressChangeTime
    );
    instance.issueChangeTime = instance.toMomentOrNull(
        instance.issueChangeTime
    );
    instance.latestInspectionTime = instance.toMomentOrNull(
        instance.latestInspectionTime
    );

    // convert IS_BEHIND - default is 'unknown'
    //
    if (instance.scheduleState > 0) {
        instance.scheduleState = BaseComponent.SCHEDULE_BEHIND;
    }
    if (instance.scheduleState === 0) {
        instance.scheduleState = BaseComponent.SCHEDULE_ON_TIME;
    }

    return instance;
};

Group.prototype.getQuantityOfActivities = function () {
    if (this.hasChildren()) {
        return this.getActivities().length;
    }
};

Group.prototype.getQuantityOfActivitiesDone = function () {
    if (this.hasChildren()) {
        return this.getActivitiesDone().length;
    }
};

/**
 * Validate if the group is behind the schedule.
 *
 * @override
 * @return {boolean}
 */
Group.prototype.isBehind = function () {
    const activities = this.getActivities();
    // if the activities are available -> use the most accurate approach to decide if the deliverable is behind
    //  -> if an activity is behind --> the deliverable is behind
    //
    if (activities.length > 0) {
        return _.some(activities, (activity) => activity.isBehind());

        // if the activities are not available go for the estimated approach
        //
    } else {
        return BaseComponent.prototype.isBehind.call(this);
    }
};

/**
 * Validate if the group is behind the schedule.
 *
 * @override
 * @return {boolean}
 */
Group.prototype.isOnTime = function () {
    const activities = this.getActivities();
    // if the activities are available -> use the most accurate approach to decide if the deliverable is behind
    //  -> if an activity is behind --> the deliverable is behind
    //
    if (activities.length > 0) {
        return _.every(activities, (activity) => activity.isOnTime());
        // if the activities are not available go for the estimated approach
        //
    } else {
        return BaseComponent.prototype.isOnTime.call(this);
    }
};

Group.prototype.getProgress = function () {
    if (this.hasChildren()) {
        const activities = this.getActivities();
        if (activities.length === 0) {
            return this.progress;
        }

        const summedProgress = activities.reduce(function (progress, activity) {
            return progress + activity.state.getCompletionPercentage();
        }, 0);

        return ActivityState.interpretMeanCompletionPercentage(
            summedProgress,
            activities.length
        );
    } else {
        return this.progress;
    }
};

Group.prototype.getLatestStateChangeAt = function (fallback) {
    if (this.hasChildren()) {
        const activities = this.getActivities();
        if (activities.length === 0) {
            return fallback;
        }

        const validStateChangeDates = activities
            .map((activity) => activity.state.changedAt)
            .filter((date) => moment.isMoment(date) && date.isValid());

        if (moment.isMoment(fallback) && fallback.isValid()) {
            validStateChangeDates.push(fallback);
        }

        if (validStateChangeDates.length < 1) {
            return fallback;
        }

        return moment.max(validStateChangeDates);
    } else {
        return fallback;
    }
};

Group.prototype.getLatestStateChange = function () {
    if (!this.hasChildren()) {
        return;
    }
    const activities = this.getActivities();
    if (activities.length === 0) {
        return;
    }

    const validStates = activities
        .map((activity) => activity.state)
        .filter(
            (state) =>
                moment.isMoment(state.changedAt) && state.changedAt.isValid()
        );

    if (validStates.length < 1) {
        return;
    }
    return _.maxBy(validStates, (state) => state.changedAt.valueOf());
};

/**
 *
 * Translate the progress number into enumerated translatable text
 *
 * @override
 * @return {*}
 */
Group.prototype.getProgressEnum = function () {
    const progress = this.getProgress();

    if (progress === BaseComponent.NOT_STARTED) {
        return BaseComponent.NOT_STARTED_ENUM;
    }

    if (progress === BaseComponent.DONE) {
        return BaseComponent.DONE_ENUM;
    }

    if (progress > BaseComponent.NOT_STARTED && progress < BaseComponent.DONE) {
        return BaseComponent.IN_PROGRESS_ENUM;
    }
};

/**
 * Set the child components/activities for this group
 * @param {Array.<BaseComponent>} children
 * @return {Group} this
 */
Group.prototype.setChildren = function (children) {
    this.children = children;

    // set the back reference
    children.forEach(function (child) {
        child.setParent(this);
    }, this);

    return this;
};

Group.prototype.find = function (id) {
    const component = _.find(this.children, ["id", id]);
    if (component) {
        return component;
    }

    return this.children.reduce((hit, child) => {
        if (hit) {
            return hit;
        }
        if (child instanceof Group) {
            return child.find(id);
        }
    }, undefined);
};

/**
 * Add the child component/activity for this group
 * @param {BaseComponent} child
 * @return {Group} this
 */
Group.prototype.addChild = function (child) {
    child.setParent(this);
    this.children = this.getChildren().concat([child]);
    return this;
};

Group.prototype.removeChildById = function (childId) {
    const children = this.getChildren();
    const removedChildren = _.remove(children, {
        id: childId,
    });
    this.setChildren(children);

    // clear the parent of children with the given id
    _.forEach(removedChildren, function (childToRemove) {
        childToRemove._parent = null;
    });
};

/**
 * Get the direct children of the group
 * @returns {Array.<BaseComponent>}
 */
Group.prototype.getChildren = function () {
    if (this.children) {
        return this.children;
    } else {
        return [];
    }
};

Group.prototype.hasChildren = function () {
    if (this.children) {
        return this.children.length > 0;
    }
    return false;
};

/**
 * All activities below this group on any depth which are running late
 * @returns {Array.<Activity>}
 */
Group.prototype.getLateActivities = function () {
    return this.getActivitiesBy(isActivityBehind, "endDate");
};

/**
 * All activities below this group on any depth which are running on time
 * @returns {Array.<Activity>}
 */
Group.prototype.getActivitiesOnTime = function () {
    return this.getActivitiesBy(isActivityOnTime, "startDate");
};

/**
 * All activities below this group on any depth which are running on time
 * @returns {Array.<Activity>}
 */
Group.prototype.getActivitiesDone = function () {
    return this.getActivitiesBy(isActivityDone, "startDate");
};

Group.prototype.getActivitiesBy = function (activityFilterFn, sortCondition) {
    return _.chain(this.getActivities())
        .filter(activityFilterFn)
        .sortBy(sortCondition || "startDate", "name")
        .value();
};

/**
 * All activities below this group on any depth
 * @returns {Array.<Activity>}
 */
Group.prototype.getActivities = function () {
    return this.getChildren().reduce(function (allActivities, component) {
        if (component instanceof Activity) {
            allActivities.push(component);
        } else if (component instanceof Group) {
            allActivities = allActivities.concat(component.getActivities());
        }
        return allActivities;
    }, []);
};

Group.prototype.getMostReleventStartDate = function () {
    const activitiesWithValidDate = this.getActivities()
        .map(function (activity) {
            return activity.getMostReleventStartDate();
        })
        .filter(function (date) {
            return moment.isMoment(date) && date.isValid();
        });
    if (activitiesWithValidDate.length > 0) {
        return moment.min(activitiesWithValidDate).clone();
    }
    return undefined;
};

Group.prototype.getMostReleventEndDate = function () {
    const activitiesWithValidDate = this.getActivities()
        .map(function (activity) {
            return activity.getMostReleventEndDate();
        })
        .filter(function (date) {
            return moment.isMoment(date) && date.isValid();
        });
    if (activitiesWithValidDate.length > 0) {
        return moment.max(activitiesWithValidDate).clone();
    }
    return undefined;
};

export default Group;

//
//  HELPER
//

function isActivityOnTime(activity) {
    return !activity.isBehind();
}

function isActivityDone(activity) {
    return activity.progress === 100;
}

function isActivityBehind(activity) {
    return activity.isBehind();
}
