import _ from "lodash";
import templateHtml from "./commercial_card.html";

export default {
    name: "sbCommercialCard",
    templateUrl: templateHtml,
    bindings: {
        costGroups: "<",
        selectedCostGroup: "<",
        onSelectCostGroup: "&",
        onDeleteCostGroup: "&",
        onSaveCostGroup: "&",
        onChangeReportDate: "&",
        onCombineActivities: "&",
        onEditMembership: "&",
        onUngroup: "&",
        hasEditRights: "<",
        isLoading: "<",
        isUpdatingMembership: "<",
    },
    controllerAs: "commercialCard",
    controller: CommercialCardCtrl,
};

function CommercialCardCtrl($state, $window, $sbTracking, SABLONO_STATES) {
    "ngInject";

    var vm = this;

    vm.$onChanges = $onChanges;
    vm.onFormInputChange = onFormInputChange;
    vm.onStageCellClick = onStageCellClick;

    /**
     *
     * @param {Object} changes
     * @param {Object<ngForm>} changes.form
     */
    function onFormInputChange(changes) {
        _updateStageCosts();
        _saveCostGroup(changes.form);
    }

    function onStageCellClick(data) {
        var property = data.property;
        var costGroupStage = data.stage;
        const { processTemplateId } = costGroupStage;

        const queryParams = {
            processTemplate: processTemplateId,
            activity: [costGroupStage.id],
            activityStates: "",
        };

        // map column to tracker states filter option
        // see web-apps/libs/bimtime/activity-tracker/feature-main/src/lib/helpers/tracker-query-params.mapper.ts:64
        // for supported values
        switch (property) {
            case "isDone":
                queryParams.activityStates = ["done", "confirmed"];
                $sbTracking.commercial.onGoToTracker("Done");
                break;
            case "inProgress":
                queryParams.activityStates = ["started", "rejected"];
                $sbTracking.commercial.onGoToTracker("Work in progress");
                break;
            case "isWaitingForConfirmation":
                queryParams.activityStates = ["waiting_for_confirmation"];
                $sbTracking.commercial.onGoToTracker(
                    "Waiting for confirmation"
                );
                break;
        }

        var url = $state.href(SABLONO_STATES.tracker, queryParams);
        $window.open(url, "_blank");
    }

    function $onChanges(changes) {
        _updateStageCosts();

        if (changes.costGroups && changes.costGroups.currentValue) {
            vm.costGroups = changes.costGroups.currentValue;
        }
    }

    function _updateStageCosts() {
        if (vm.selectedCostGroup) {
            vm.selectedCostGroup.setStageCosts();
        }
    }

    function _saveCostGroup($form) {
        if ($form.$valid) {
            vm.onSaveCostGroup({
                costGroup: _.cloneDeep(vm.selectedCostGroup),
            });
        }
    }
}
