import _ from "lodash";
import html from "./schedule_stats.html";
import angular from "angular";

export default angular
    .module("sbApp.common.components.scheduleStats", [])
    .component("sbScheduleStats", {
        templateUrl: html,
        bindings: {
            correspondingTutorial: "@",
            // UI flags
            isClickDisabled: "<",
            header: "<?",
            // chart data
            chartData: "<",
            chartOptions: "<?",
            isLoading: "<",
            onBarChartClick: "&?",
        },
        controller: function ScheduleStatsCtrl() {
            "ngInject";

            var vm = this;
            vm.subheaderValues;
            vm.$onInit = $onInit;
            vm.$onChanges = $onChanges;

            function $onInit() {
                const hasClickHandler = _.isFunction(vm.onBarChartClick);
                if (!hasClickHandler) {
                    vm.isClickDisabled = true;
                }

                vm.chartOptions = vm.chartOptions || {};
            }

            function $onChanges(changes) {
                vm.subheaderValues = undefined;
                if (_shouldDisplaySubheader(changes.chartData)) {
                    vm.subheaderValues = {
                        activities: changes.chartData.currentValue[0].total,
                    };
                }
            }

            function _shouldDisplaySubheader(chartDataChange) {
                return (
                    chartDataChange &&
                    chartDataChange.currentValue &&
                    chartDataChange.currentValue.length === 1 &&
                    chartDataChange.currentValue[0].total
                );
            }
        },
    });
