import angular from "angular";
import autoFocus from "./directives/autoFocus/autoFocus";
import equals from "./directives/equals/equals";
import sbError from "./directives/sbError/sbError";
import sbMultilineEdit from "./directives/sbMultilineEdit/sbMultilineEdit";
import sbBackgroundInfo from "./directives/sbBackgroundInfo/sbBackgroundInfo";
import sbBreadcrumbs from "./directives/sbBreadcrumbs/sbBreadcrumbs";
import sb_tableDirective from "./directives/sbTable/sb_table.directive";
import ngEnter from "./directives/ngEnter/ngEnter";
import sbIFrame from "./directives/sbIFrame/sbIFrame";
import sbActivityItem from "./directives/sbActivityItem/sbActivityItem";
import sb_treeDirective from "./directives/sbTree/sb_tree.directive";
import sbUserImage from "./directives/sbUserImage/sbUserImage";
import sbToolbarNavigator from "./directives/sbToolbarNavigator/sbToolbarNavigator";
import sbResizeableFlexBox from "./directives/sbResizeableFlexBox/sbResizeableFlexBox";
import sbNoData from "./directives/sbNoData/sbNoData";
import project_editDirective from "./directives/sbProjectEdit/project_edit.directive";
import sbTemplateGroupEdit from "./directives/sbTemplateGroupEdit/sbTemplateGroupEdit";
import sbTemplateActivityEdit from "./directives/sbTemplateActivityEdit/sbTemplateActivityEdit";
import sbConfigCardBox from "./directives/sbConfigCardBox/sbConfigCardBox";
import sbElementSelectionModule from "./directives/sbElementSelection/sbElementSelection.module";
import sb_tileDirective from "./directives/sbTile/sb_tile.directive";
import sb_auto_scrollDirective from "./directives/sbAutoScroll/sb_auto_scroll.directive";
import sb_file_inputDirective from "./directives/sbFileInput/sb_file_input.directive";
import sb_file_cardDirective from "./directives/sbFileInput/sb_file_card.directive";
import sb_component_upload_successDirective from "./directives/sbComponentUploadSuccess/sb_component_upload_success.directive";
import sb_loadingDirective from "./directives/sbLoading/sb_loading.directive";
import sbCalendarSelection from "./directives/sbCalendarSelection/sbCalendarSelection";
import sbTimePicker from "./directives/sbTimePicker/sbTimePicker";
import sb_timezone_selectorDirective from "./directives/sbTimezoneSelector/sb_timezone_selector.directive";
import sb_image_cardDirective from "./directives/sbImageCard/sb_image_card.directive";
import sb_inspection_badgeDirective from "./directives/sbInspectionBadge/sb_inspection_badge.directive";
import sb_user_badgeDirective from "./directives/sbUserBadge/sb_user_badge.directive";
import odata_listModule from "./directives/sbOdataList/odata_list.modules";
import search_barDirective from "./directives/sbSearchBar/search_bar.directive";
import elastic_inputDirective from "./directives/sbElasticInput/elastic_input.directive";
import has_persmissionDirective from "./directives/hasPermission/has_persmission.directive";
import content_zoomDirective from "./directives/sbContentZoom/content_zoom.directive";
import sbDecimalModelDirective from "./directives/sbDecimalModel/sbDecimalModel.directive";
import prevent_bubbleDirective from "./directives/sbPreventBubble/prevent_bubble.directive";
import sb_deliverable_overlayDirective from "./directives/deliverables/sbDeliverableOverlay/sb_deliverable_overlay.directive";
import sb_deliverable_detailsModule from "./directives/deliverables/sbDeliverableDetails/sb_deliverable_details.module";
import sb_deliverable_notesDirective from "./directives/deliverables/sbDeliverableNotes/sb_deliverable_notes.directive";
import sb_deliverable_note_commentsDirective from "./directives/deliverables/sbDeliverableNoteComments/sb_deliverable_note_comments.directive";
import sb_labeled_propertyComponent from "./directives/deliverables/sbDeliverableDetails/sbDeliverableDates/sbLabeledProperty/sb_labeled_property.component";
import sb_delayComponent from "./directives/deliverables/sbDeliverableDetails/sbDeliverableDates/sbDelay/sb_delay.component";
import sb_dateComponent from "./directives/deliverables/sbDeliverableDetails/sbDeliverableDates/sbDate/sb_date.component";
import sb_datesComponent from "./directives/deliverables/sbDeliverableDetails/sbDeliverableDates/sb_dates.component";
import sb_deliverable_notes_rowDirective from "./directives/deliverables/sbDeliverableNotesRow/sb_deliverable_notes_row.directive";
import sb_deliverable_dependenciesModule from "./directives/deliverables/sbDeliverableDependencies/sb_deliverable_dependencies.module";
import sb_deliverable_checklistsModule from "./directives/deliverables/sbDeliverableChecklists/sb_deliverable_checklists.module";
import sb_dependency_managerDirective from "./directives/deliverables/sbDependencyManager/sb_dependency_manager.directive";
import checklist_details_panelDirective from "./directives/deliverables/sbDeliverableChecklists/panels/sbChecklistDetailsPanel/checklist_details_panel.directive";
import fill_checklist_panelDirective from "./directives/deliverables/sbDeliverableChecklists/panels/sbFillChecklistPanel/fill_checklist_panel.directive";
import deliverablesOverlayDataService from "./directives/deliverables/deliveralbe_overlay_data.service";
import deliverablesNoteStateService from "./directives/deliverables/deliveralbe_overlay_note_state.service";
import deliverablesNoteRowMapperService from "./directives/deliverables/sbDeliverableNotesRow/sb_deliveralbe_note_row_mapper.service";
import sort_groupDirective from "./directives/sbHeaderBar/sbSortGroup/sort_group.directive";
import sort_itemDirective from "./directives/sbHeaderBar/sbSortItem/sort_item.directive";
import header_barDirective from "./directives/sbHeaderBar/header_bar.directive";
import sb_filter_textDirective from "./viewFilters/sbFilterText/sb_filter_text.directive";
import sb_filter_checkboxDirective from "./viewFilters/sbFilterCheckbox/sb_filter_checkbox.directive";
import sb_filter_dateDirective from "./viewFilters/sbFilterDate/sb_filter_date.directive";
import sb_filter_workflowDirective from "./viewFilters/sbFilterWorkflow/sb_filter_workflow.directive";
import sb_filter_structureDirective from "./viewFilters/sbFilterStructure/sb_filter_structure.directive";
import sb_filter_userDirective from "./viewFilters/sbFilterUser/sb_filter_user.directive";
import sb_filter_selectDirective from "./viewFilters/sbFilterSelect/sb_filter_select.directive";
import sb_help_linkComponent from "./directives/sbHelpLink/sb_help_link.component";
import sb_filtersModule from "./viewFilters/sbFilters/sb_filters.module";
import structure_autocompleteComponent from "./components/sbStructureAutocomplete/structure_autocomplete.component";
import choice_groupComponent from "./components/sbChoiceGroup/choice_group.component";
import info_messageComponent from "./components/sbInfoMessage/info_message.component";
import date_pickerComponent from "./components/sbDatePickers/date_picker.component";
import date_range_pickerComponent from "./components/sbDatePickers/date_range_picker.component";
import deliverable_workflowModule from "./directives/deliverables/sbDeliverableWorkflow/deliverable_workflow.module";
import filter_menuComponent from "./components/sbFilterMenu/filter_menu.component";
import checklist_ratioComponent from "./components/sbChecklistRatio/checklist_ratio.component";
import component_tag_cloudComponent from "./components/sbComponentTagCloud/component_tag_cloud.component";
import team_selectComponent from "./components/sbTeamSelect/team_select.component";
import timelineComponent from "./components/sbTimeline/sb_timeline.component";
import activity_creatorComponent from "./components/sbActivityCreator/activity_creator.component";
import recent_activitiesComponent from "./components/sbRecentActivities/recent_activities.component";
import traffic_light_selectorComponent from "./components/sbTrafficLightSelector/traffic_light_selector.component";
import galleryComponent from "./components/sbGallery/gallery.component";
import image_galleryComponent from "./components/sbAttachmentGallery/attachment_gallery.component";
import progress_optionsComponent from "./components/sbProgressOptions/progress_options.component";
import sb_properties_source_iconComponent from "./components/sbPropertiesSourceIcon/sb_properties_source_icon.component";
import sb_inspection_progress_listComponent from "./components/sbInspectionProgressList/sb_inspection_progress_list.component";
import sb_process_templateComponent from "./components/sbProcessTemplate/sb_process_template.component";
import activities_list_selectorModule from "./components/sbActivitiesListSelector/activities_list_selector.module";
import stepperComponent from "./components/sbStepper/stepper.component";
import filter_sidenavComponent from "./components/sbFilterSidenav/filter_sidenav.component";
import filter_menu_listComponent from "./components/sbFilterMenuList/filter_menu_list.component";
import scheduleStatsComponent from "./components/sbScheduleStats/schedule_stats.component";
import sb_label_badgeDirective from "./directives/sbLabelBadge/sb_label_badge.directive.js";

var modules = [
    autoFocus,
    equals,
    sbError,
    sbMultilineEdit,
    sbBackgroundInfo,
    sbBreadcrumbs,
    sb_tableDirective,
    ngEnter,
    sbIFrame,
    sbActivityItem,
    sb_treeDirective,
    sbUserImage,
    sbToolbarNavigator,
    sbResizeableFlexBox,
    sbNoData,
    project_editDirective,
    sbTemplateGroupEdit,
    sbTemplateActivityEdit,
    sbConfigCardBox,
    sbElementSelectionModule,
    sb_tileDirective,
    sb_auto_scrollDirective,
    sb_file_inputDirective,
    sb_file_cardDirective,
    sb_component_upload_successDirective,
    sb_loadingDirective,
    sbCalendarSelection,
    sbTimePicker,
    sb_timezone_selectorDirective,
    sb_image_cardDirective,
    sb_inspection_badgeDirective,
    sb_user_badgeDirective,
    odata_listModule,
    search_barDirective,
    elastic_inputDirective,
    has_persmissionDirective,
    content_zoomDirective,
    sbDecimalModelDirective,
    prevent_bubbleDirective,
    sb_deliverable_overlayDirective,
    sb_deliverable_detailsModule,
    sb_deliverable_notesDirective,
    sb_deliverable_note_commentsDirective,
    sb_labeled_propertyComponent,
    sb_delayComponent,
    sb_dateComponent,
    sb_datesComponent,
    sb_deliverable_notes_rowDirective,
    sb_deliverable_dependenciesModule,
    sb_deliverable_checklistsModule,
    sb_dependency_managerDirective,
    checklist_details_panelDirective,
    fill_checklist_panelDirective,
    sort_groupDirective,
    sort_itemDirective,
    header_barDirective,
    sb_filter_textDirective,
    sb_filter_checkboxDirective,
    sb_filter_dateDirective,
    sb_filter_workflowDirective,
    sb_filter_structureDirective,
    sb_filter_userDirective,
    sb_filter_selectDirective,
    sb_filtersModule,
    structure_autocompleteComponent,
    choice_groupComponent,
    info_messageComponent,
    date_pickerComponent,
    date_range_pickerComponent,
    deliverable_workflowModule,
    filter_menuComponent,
    checklist_ratioComponent,
    component_tag_cloudComponent,
    team_selectComponent,
    timelineComponent,
    activity_creatorComponent,
    recent_activitiesComponent,
    traffic_light_selectorComponent,
    galleryComponent,
    image_galleryComponent,
    progress_optionsComponent,
    sb_properties_source_iconComponent,
    sb_inspection_progress_listComponent,
    sb_process_templateComponent,
    activities_list_selectorModule,
    stepperComponent,
    filter_sidenavComponent,
    filter_menu_listComponent,
    scheduleStatsComponent,
    sb_help_linkComponent,
    sb_label_badgeDirective,
];
var moduleNames = modules.map(function (module) {
    return module.name;
});

export default angular
    .module("sbApp.directives", moduleNames)
    .service("$sbDeliverableNoteRowMapper", deliverablesNoteRowMapperService)
    .service("$sbDetailsOverlayData", deliverablesOverlayDataService)
    .service("$sbDeliverablesNoteStateService", deliverablesNoteStateService);
