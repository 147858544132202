import angular from "angular";
import htmlTemplate from "./sbEditorTree.html";
import _ from "lodash";
import SbEditorTree from "./sb_editor_tree.class";

export default angular
    .module("sbApp.sbEditorTree", ["sbApp.services"])
    .directive("sbEditorTree", function EditorTreeDirective($log) {
        return {
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            //replace: true,

            scope: {
                // the tree root node data model
                root: "=",
                // the id of the element that should be highlighted
                highlightId: "=",
                // callback on select
                onNodeSelect: "=",
                // a filter that can be applied at any time to hide certain model entries
                treeNodeFilter: "=",
                // no expand/collapse - always fully expanded
                viewOnly: "=",
                // a hover button on the right side
                onSecondActionPress: "=?",
            },

            link: function (scope, iElement, iAttrs) {
                scope.hasSecondAction =
                    iAttrs.onSecondActionPress !== undefined;
            },

            controller: function ($scope, EVENTS, $filter) {
                var dataSorter = $filter("compareTemplateComponents");

                $scope.collapseAll = true;
                $scope.model = new SbEditorTree();

                var listenerToken;

                var searchListener = $scope.$on(
                    EVENTS.GLOBAL_SEARCH_CHANGE,
                    function (event, eventData) {
                        $scope.listSearch = eventData.searchTerm;
                        $scope.model.collapseTree();

                        if (!eventData.searchTerm) {
                            $scope.model.expand($scope.model.list[0]);
                            return;
                        }

                        // find the nodes that are a match vs the search term and mark them visible
                        //
                        $scope.model.list
                            .filter(function (e) {
                                return _isTextIncludedIn(
                                    eventData.searchTerm,
                                    e.model.properties.name
                                );
                            })
                            .forEach(function (e) {
                                e.show();
                                $scope.model._showParents(e);
                            });
                    }
                );

                var highlightWatcher = $scope.$watch(
                    "highlightId",
                    function (newId) {
                        $log.debug("highlightId changed", newId);
                        if (newId) {
                            $scope.model.show($scope.model.getById(newId));
                        }
                    }
                );

                var filterWatcher = $scope.$watch(
                    "treeNodeFilter",
                    onTreeStructureChange
                );

                /**
                 * Watch changes of the root binding (necessary for dirty indication)
                 */
                var rootWatcher = $scope.$watch("root", function () {
                    onTreeStructureChange();
                });

                /**
                 * Watch the structure of the tree (add/remove/move nodes)
                 */
                var treeStructureWatcher = $scope.$watch(function () {
                    return $scope.model.generateTreeCheckSum($scope.root);
                }, onTreeStructureChange);

                function onTreeStructureChange() {
                    $scope.model.setData($scope.root, {
                        filter: $scope.treeNodeFilter,
                        sorter: dataSorter,
                    });

                    if ($scope.viewOnly) {
                        $scope.model.expandTree();
                    } else {
                        $scope.model.expand($scope.model.list[0]);
                    }
                    if (!$scope.model.getById($scope.highlightId)) {
                        $scope.model.show($scope.model.list[0]);
                    } else {
                        $scope.model.show(
                            $scope.model.getById($scope.highlightId)
                        );
                    }
                }

                // on destroy remove all listener
                //
                $scope.$on("$destroy", function () {
                    treeStructureWatcher();
                    rootWatcher();
                    highlightWatcher();
                    filterWatcher();
                    searchListener();
                });

                /**
                 * Toggle between expanded and collapsed
                 *
                 * @param  {SbEditorTreeNode} node   - The selected object
                 * @param  {boolean} expand   - should transition into expand mode
                 * @param  {Object} $event - Used to stop propagation
                 */
                $scope.toggleNode = function (node, expand, $event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    if (expand) {
                        $scope.model.expand(node);
                    } else {
                        $scope.model.collapse(node);
                    }
                };

                /**
                 * Collapse/Expand all the tree elements
                 *  # collapse: -> clean all collapsed states and return to the initial state.
                 *  # expand: -> mark all as visible and expanded..
                 *
                 *  @param {Object} $event - the ng-click event
                 *  @param {boolean} collapse - true if fully collapsed, false if fully expanded
                 */
                $scope.toggleAll = function ($event, collapse) {
                    $event.stopPropagation();
                    $event.preventDefault();

                    $log.debug("toggle all:", collapse);

                    if (collapse) {
                        $scope.model.collapseTree();
                    } else {
                        $scope.model.expandTree();
                    }

                    $scope.collapseAll = collapse;
                };

                $scope.secondActionPress = function (event, node) {
                    event.stopPropagation();
                    event.preventDefault();
                    $scope.onSecondActionPress(event, node);
                };

                $scope.treeNodeClick = function ($event, node) {
                    $scope.onNodeSelect(node.model);
                };

                function _isTextIncludedIn(searchTerm, searchIn) {
                    if (typeof searchTerm !== "string") {
                        return false;
                    }
                    if (typeof searchIn !== "string") {
                        return false;
                    }
                    return (
                        searchIn
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase()) > -1
                    );
                }
            },
        };
    });
