function ODataFilter(key, quantity) {
    this._key = key;
    this._displayText = key;
    this._shorty = key;
    this._quantity = quantity || 0;
}

ODataFilter.prototype.displayText = function (newValue) {
    if (arguments.length > 0) {
        this._displayText = newValue;
        return this;
    } else {
        return this._displayText;
    }
};

ODataFilter.prototype.quantity = function (newValue) {
    if (arguments.length > 0) {
        this._quantity = newValue;
        return this;
    } else {
        return this._quantity;
    }
};

ODataFilter.prototype.shorty = function (newValue) {
    if (arguments.length > 0) {
        this._shorty = newValue;
        return this;
    } else {
        return this._shorty;
    }
};

export default ODataFilter;
