import angular from "angular";
import _ from "lodash";
import html from "./team_card.html";

export default {
    templateUrl: html,
    transclude: true,
    controllerAs: "teamCard",
    bindings: {
        team: "<",
        onEditTeamClick: "&",
        isEditAllowed: "<",
    },
    controller: function TeamCard($sbCurrentProject, $sbTeam) {
        "ngInject";
        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        vm.image = {
            url: null,
        };

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = function () {
            fetchTeamLogo();
        };
        vm.$onChanges = function (changes) {
            if (changes.team) {
                const previousLogoId = _.get(
                    changes,
                    "team.previousValue.logoId"
                );
                const currentLogoId = _.get(
                    changes,
                    "team.currentValue.logoId"
                );
                const hasNewLogo = previousLogoId !== currentLogoId;
                if (hasNewLogo) {
                    fetchTeamLogo();
                }
            }
        };
        vm.$onDestroy = angular.noop;
        vm.$onInit = angular.noop;

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        function fetchTeamLogo() {
            const projectId = $sbCurrentProject.pick("id");
            const imageId = vm.team.logoId;

            return $sbTeam.fetchLogo(projectId, imageId).then((image) => {
                vm.image.url = image.dataUrl;
            });
        }
    },
};
