class LookAheadJobsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
        this.JOB_FORECAST_LOOK_AHEAD_ACTIVITIES =
            "FORECAST_LOOK_AHEAD_ACTIVITIES";
        this.JOB_SCHEDULE_LOOK_AHEAD_ACTIVITY = "SCHEDULE_LOOK_AHEAD_ACTIVITY";
        this.BULK_SCHEDULE_LOOK_AHEAD_ACTIVITIES =
            "BULK_SCHEDULE_LOOK_AHEAD_ACTIVITIES";
        this.ACCEPT_ALL_FORECASTED_DATES = "ACCEPT_ALL_FORECASTED_DATES";
    }

    /**
     * This endpoint creates a look ahead job for a given project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.type {string}    Name of the job
     * @param body.definition {object}    Detailed description about the job setup
     *
     */
    create(projectId, { type, definition }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/look-ahead-jobs`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "POST",
            header: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                type: type,
                definition: definition,
            }),
        }).then((resp) => {
            const jobId = resp.getResponseHeader("x-job-id");
            return { jobId };
        });
    }
}

export default LookAheadJobsWebService;
