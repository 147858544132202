import ActivityState from "../../../domain/sb_activity_state.class";
import SbTeam from "../../../domain/sb_team.class";
import AllDayCalendar from "../../../common/services/schedulingService/all_day_calendar.model";
import LeanboardSchedule from "./lean_board_schedule.class";

class LeanboardActivity {
    constructor(laneRecord, id, name, topologicalIndex) {
        this.id = id;
        this.templateId = null;
        this.laneRecord = laneRecord;

        this.topologicalIndex = topologicalIndex;
        this.name = name;

        this.noteStatistic = {
            openObstructions: 0,
            openClaims: 0,
            info: 0,
        };

        this.workTeam = SbTeam.createUnrestrictedTeam();
        this.state = new ActivityState(ActivityState.NOT_STARTED);
        this.calendar = new AllDayCalendar();

        // dates used to display the activity on the board.
        this.startDate = null;
        this.endDate = null;
        this.duration = {
            value: 0,
            unit: "wd",
        };

        // This is the override of the duration object above
        this.displayedDuration = {};

        this.isForecasted = false;
        this.daysBehindBaseline = 0;

        // detailed view on all dates available
        //  - used to run the activity panel and all date change interactions
        this.schedule = new LeanboardSchedule();

        this.numberOfDeliverables = 1;
    }
}

export default LeanboardActivity;
