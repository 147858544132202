import createjs from "createjs";

/**
 * Event dispatched, when an Edge was deleted
 * @param {WBS.StructureNode} source - Source Node of Edge
 * @param {WBS.StructureNode} target - Target Node of Edge
 * @constructs EdgeDeletedEvent
 * @extends createjs.Event
 */
function EdgeDeletedEvent(source, target) {
    createjs.Event.call(this, "edgeDeleted", false, false);

    this.edgeSource = source;
    this.edgeTarget = target;
}

EdgeDeletedEvent.prototype = Object.create(createjs.Event.prototype);

export default EdgeDeletedEvent;
