import angular from "angular";
import FiltersCtrl from "./sb_filters.controller";
import FiltersComponent from "./sb_filters.component";
import FilterPanelCtrl from "./sbFilterPanel/filter_panel.controller";
import FilterContentComponent from "./sbFilterPanel/filter_content.component";
import ViewFiltersService from "./services/view_filters.service";
export default angular
    .module("sbApp.directives.sbFilters", ["ngMaterial", "ui.router"])
    .controller("FilterPanelCtrl", FilterPanelCtrl)
    .controller("FiltersCtrl", FiltersCtrl)
    .component("sbFilterContent", FilterContentComponent)
    .component("sbFilters", FiltersComponent)
    .factory("$sbViewFiltersService", ViewFiltersService);
