/**
 * alias for a number
 * @typedef bitMask
 * @type {number}
 */

/**
 * describes a feature or action
 * @typedef RoleManager.Feature
 * @type {Object}
 * @property {string} id - id of the feature
 * @property {bitMask} bitMask - 32 bit mask
 *
 */

/**
 *  A class to handle roles and feature access
 *
 * @param {RoleManager.Feature[]} features - the provided features of the manager
 * @constructor
 */
function RoleManager(features) {
    /**
     * all avalible features
     * @type {RoleManager.Feature[]}
     */
    this.features = features;

    /**
     * feature map there the key is the feature id
     * @type {Object.<string, RoleManager.Feature>}
     */
    this.featureMap = this._reduceFeaturesToFeatureMap(this.features);

    /**
     * maximum bitMask with all features enabled
     * @type {bitMask}
     */
    this.max = this._reduceFeaturesToMaxBitMask(this.features);
    /**
     * minimum bitMask
     * @type {bitMask}
     */
    this.min = 0;
}

/**
 * reduce a array of features to feature map there the key is the feature id
 * @param {RoleManager.Feature[]} features - the provided features
 * @returns {Object.<string, RoleManager.Feature>}
 * @private
 */
RoleManager.prototype._reduceFeaturesToFeatureMap = function (features) {
    return features.reduce(function (featureMap, feature) {
        var id = feature.id;
        featureMap[id] = feature;
        return featureMap;
    }, {});
};

/**
 * combines all bitMask from all features to get the highest bitMask with all features enabled
 * @param {RoleManager.Feature[]} features - the features to work with
 * @returns {bitMask}
 * @private
 */
RoleManager.prototype._reduceFeaturesToMaxBitMask = function (features) {
    var bitMaskList = features.map(function (feature) {
        return feature.bitMask;
    });
    return this.reduceToMaxBitMask(bitMaskList);
};
/**
 * reduce a bitMask list to one bitMask with the max value
 * @param {bitMask[]} bitMaskList - bitMasks to combine
 * @returns {bitMask}
 */
RoleManager.prototype.reduceToMaxBitMask = function (bitMaskList) {
    return bitMaskList.reduce(function (maxBitMask, bitMask) {
        return maxBitMask | bitMask;
    }, this.min);
};

/**
 * reduce a bitMask list to the minimum bitMask
 * @param {bitMask[]} bitMaskList - bitMasks to combine
 * @returns {bitMask}
 */
RoleManager.prototype.reduceToMinBitMask = function (bitMaskList) {
    if (bitMaskList.length <= 0) {
        return this.min;
    }
    return bitMaskList.reduce(function (minBitMask, bitMask) {
        return minBitMask & bitMask;
    });
};

/**
 * gets a feature by id
 * @param {string} featureId - the identification of the feature
 * @returns {RoleManager.Feature|undefined}
 */
RoleManager.prototype.getFeatureById = function (featureId) {
    return this.featureMap[featureId];
};

/**
 * check if the bit mask match with the required feature bit mask
 * @param {string} featureId - the requested feature
 * @param {bitMask} bitMask - the provided bitMask to check against
 * @returns {boolean}
 */
RoleManager.prototype.featureGranted = function (featureId, bitMask) {
    var feature = this.getFeatureById(featureId);
    if (!feature) {
        return false;
    }
    var featureBitMask = feature.bitMask;
    return (featureBitMask & bitMask) === featureBitMask;
};

//app.factory("$sbPermission", function () {

export default function () {
    //  Given roles from the server
    //
    // ADMIN:       11111
    // MANAGER :    01111
    // CONTROLLER : 00111
    // INSPECTOR :  00010
    // REVIEWER :   00001

    var ROLES = {
        ADMIN: Number.parseInt("11111", 2),
        MANAGER: Number.parseInt("01111", 2),
        CONTROLLER: Number.parseInt("00111", 2),
        INSPECTOR: Number.parseInt("00010", 2),
        REVIEWER: Number.parseInt("00001", 2),
    };

    /**
     * all features for the application
     *
     * @type {RoleManager.Feature[]}
     */
    var features = [
        {
            id: "GROUP_ADMINISTRATION",
            bitMask: Number.parseInt("10000", 2),
        },
        {
            id: "PLAN",
            bitMask: Number.parseInt("1000", 2),
        },
        {
            id: "CONTROL",
            bitMask: Number.parseInt("00111", 2),
        },
        {
            id: "REVIEW",
            bitMask: Number.parseInt("1", 2),
        },
        {
            id: "COMMERCIAL",
            bitMask: Number.parseInt("100", 2),
        },
        {
            id: "WORK",
            bitMask: Number.parseInt("10", 2),
        },
        {
            id: "UNRESTRICTED",
            bitMask: Number.parseInt("0", 2),
        },
        {
            id: "SETTINGS",
            bitMask: Number.parseInt("1", 2),
        },
        {
            id: "CREATE_ACTIVITY",
            bitMask: Number.parseInt("100", 2),
        },
        {
            id: "CLOSE_ISSUE",
            bitMask: Number.parseInt("10", 2),
        },
        {
            id: "ASSIGN_PROCESS",
            bitMask: Number.parseInt("1000", 2),
        },
        {
            id: "MANAGE_TEAMS",
            bitMask: Number.parseInt("1000", 2),
        },
    ];

    // create the role manager with given features
    //
    var roleManager = new RoleManager(features);

    // return the public API stuff
    //
    return {
        hasPermission: function (privileges, feature) {
            return roleManager.featureGranted(feature, privileges);
        },
        hasReviewPermissions: function (privileges) {
            return roleManager.featureGranted("REVIEW", privileges);
        },
        hasControllerPermissions: function (privileges) {
            return roleManager.featureGranted("CONTROL", privileges);
        },
        hasPlanPermissions: function (privileges) {
            return roleManager.featureGranted("PLAN", privileges);
        },
        hasWorkPermissions: function (privileges) {
            return roleManager.featureGranted("WORK", privileges);
        },
        hasAdminPermissions: function (privileges) {
            return roleManager.featureGranted(
                "GROUP_ADMINISTRATION",
                privileges
            );
        },
        hasTeamEditPermissions: function (privileges) {
            return roleManager.featureGranted("MANAGE_TEAMS", privileges);
        },
        bitMaskByRoleName: function bitMaskByRoleName(roleName) {
            return ROLES[roleName];
        },
        isManagerRole: function (roleMask) {
            return (ROLES.MANAGER & roleMask) === ROLES.MANAGER;
        },
        Feature: {
            // enum like entity
            Plan: "PLAN",
            Work: "WORK",
            Review: "REVIEW",
            Commercial: "COMMERCIAL",
            Control: "CONTROL",
            Unrestricted: "UNRESTRICTED",
        },
    };
}
