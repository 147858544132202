import angular from "angular";

export default function CreateChecklistTemplateDialogCtrl($mdDialog) {
    "ngInject";
    var vm = this;

    vm.model = {
        checklistTemplateName: undefined,
    };

    vm.submit = submit;
    vm.closeDialog = closeDialog;

    function submit() {
        $mdDialog.hide({
            name: vm.model.checklistTemplateName,
        });
    }

    function closeDialog() {
        $mdDialog.cancel();
    }
}
