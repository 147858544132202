import angular from "angular";
import _ from "lodash";
import moment from "moment";
import htmlTemplate from "./sb_dates.html";
export default angular
    .module("sbApp.components.sbDates", [])
    .component("sbDates", {
        templateUrl: htmlTemplate,
        transclude: true,
        bindings: {
            hasEditRights: "<",
            changeDates: "&",
            timezone: "@",
            tooltip: "@",
            data: "=deliverable",
        },
        controller: function DatesCtrl($scope, $filter) {
            "ngInject";

            /////////////////////
            //
            //      Direct variables
            //
            /////////////////////

            var vm = this;
            var translate = $filter("translate");

            /////////////////////
            //
            //      SCOPE properties
            //
            /////////////////////

            vm.getDateRepresentation = getDateRepresentation;
            vm.editDates = editDates;

            /////////////////////
            //
            //      WATCHER
            //
            /////////////////////

            $scope.$watch("$ctrl.data", function onDataChanged(data) {
                vm.data = data;
            });

            /////////////////////
            //
            //      IMPL
            //
            /////////////////////

            function editDates() {
                if (vm.hasEditRights) {
                    vm.changeDates();
                }
            }

            /**
             *
             * @param {String} dateParam
             * @returns {*}
             */
            function getDateRepresentation(dateParam) {
                var deliverable = vm.data;

                if (deliverable) {
                    const dateValue = _.defaultTo(deliverable[dateParam], null);

                    let displayableMoment;
                    if (moment.isMoment(dateValue)) {
                        displayableMoment = moment.tz(dateValue, vm.timezone);
                    } else {
                        displayableMoment = moment.tz(
                            parseFloat(dateValue),
                            vm.timezone
                        );
                    }

                    if (displayableMoment.isValid()) {
                        return displayableMoment.format("L");
                    }
                }

                return _getDisplayTextWhenDateIsNotSet();
            }

            /**
             * @returns {String}
             */
            function _getDisplayTextWhenDateIsNotSet() {
                var isUserDefinedDate = vm.tooltip === "PLANNED_DATES_TOOLTIP";

                return isUserDefinedDate ? translate("_UNSET") : "-";
            }
        },
    });
