export default function (Analytics) {
    "ngInject";

    const leanBoardEvent = Analytics.trackEvent.bind(Analytics, "LeanBoard");
    const errorEvent = Analytics.trackEvent.bind(Analytics, "Known Errors");

    return {
        processTemplate: function () {
            return {
                assignTeams: function () {
                    const trackAssign = Analytics.trackEvent.bind(
                        this,
                        "Process templates",
                        "Assign teams"
                    );
                    return {
                        asResponsibleFor: function () {
                            trackAssign(`Responsible to activity`);
                        },
                        asReviewing: function () {
                            trackAssign("3rd Party to activity");
                        },
                        asConfirming: function () {
                            trackAssign("Confirmation to activity");
                        },
                    };
                },
                exportAsGraph: function () {
                    Analytics.trackEvent(
                        "Process templates",
                        "Export",
                        "graphml file"
                    );
                },
            };
        },
        progress: {
            change: {
                done: function (from, label) {
                    Analytics.trackEvent(from, "Progress change", label);
                },
            },
            import: {
                success() {
                    Analytics.trackEvent(
                        "Progress import",
                        "MS Excel",
                        "Success"
                    );
                },
                failure() {
                    Analytics.trackEvent(
                        "Progress import",
                        "MS Excel",
                        "Some errors"
                    );
                },
                tooLongInputFailure() {
                    errorEvent("Error", "Progress Import >500");
                },
            },
        },
        activityTracker: {
            filters: {
                changeFilterCondition: function (filterKey) {
                    Analytics.trackEvent(
                        "ActivityTracker",
                        "Filters",
                        filterKey
                    );
                },
            },
        },
        note: {
            filters: {
                changeFilterCondition: function (filterKey) {
                    Analytics.trackEvent("Issue Filters", "Change", filterKey);
                },
            },
            teamSelect: {
                responsibleTeam: function (team) {
                    if (team && team.id) {
                        Analytics.trackEvent(
                            "Details Overlay",
                            "Notes",
                            "Responsible Team"
                        );
                    }
                },
                confirmationTeam: function (team) {
                    if (team && team.id) {
                        Analytics.trackEvent(
                            "Details Overlay",
                            "Notes",
                            "Reviewing Team"
                        );
                    }
                },
            },
            creation: {
                start: function (from, label) {
                    if (!label) {
                        return Analytics.trackEvent(
                            from,
                            "Notes",
                            "creation on activity started"
                        );
                    }

                    Analytics.trackEvent(from, "Notes", label);
                },
                done: function (from, { type, photos, attachments }) {
                    Analytics.trackEvent(
                        from,
                        "Notes",
                        "created " + type + " issue"
                    );
                    Analytics.trackEvent(
                        from,
                        "Notes",
                        "added " + (photos.length || 0) + " photos"
                    );
                    Analytics.trackEvent(
                        from,
                        "Notes",
                        "added " + (attachments.length || 0) + " attachments"
                    );
                },
            },
            edit: function (action, property) {
                return Analytics.trackEvent("Update issue", action, property);
            },

            update: function (from, label) {
                Analytics.trackEvent(from, "Update issue", label);
            },
        },
        scheduling: {
            dirtyState: {
                resolved: function () {
                    Analytics.trackEvent(
                        "Scheduling",
                        "Dirty state",
                        "Resolved"
                    );
                },
            },
        },
        processTracker: {
            navigation: {
                next: function () {
                    Analytics.trackEvent(
                        "Process tracker",
                        "Navigation",
                        "Show next activities"
                    );
                },
                previous: function () {
                    Analytics.trackEvent(
                        "Process tracker",
                        "Navigation",
                        "Show previous activities"
                    );
                },
            },
        },
        detailedDependencies: {
            assignment: {
                started: function () {
                    Analytics.trackEvent(
                        "Process tracker",
                        "Dependencies",
                        "Creation started (detailed)"
                    );
                },
                canceled: function () {
                    Analytics.trackEvent(
                        "Process tracker",
                        "Dependencies",
                        "Creation canceled (detailed)"
                    );
                },
                completedWith: function (numOfDependencies) {
                    Analytics.trackEvent(
                        "Process tracker",
                        "Dependencies",
                        "Dependencies created " +
                            (numOfDependencies || "") +
                            " (detailed)"
                    );
                },
                as: function (type) {
                    Analytics.trackEvent(
                        "Process tracker",
                        "Dependencies",
                        type + " of ... (detailed)"
                    );
                },
            },
        },
        checklistTemplates: {
            creation: {
                started: function () {
                    Analytics.trackEvent(
                        "QA checklists",
                        "Create",
                        "Create new checklist"
                    );
                },
            },
            editing: {
                newItem: function () {
                    Analytics.trackEvent(
                        "QA checklists",
                        "Create",
                        "Create new item"
                    );
                },
                editList: function () {
                    Analytics.trackEvent(
                        "QA checklists",
                        "Edit",
                        "Open existing checklist in Editor"
                    );
                },
                deleteItem: function () {
                    Analytics.trackEvent(
                        "QA checklists",
                        "Edit",
                        "Delete item"
                    );
                },
                moveItem: function () {
                    Analytics.trackEvent("QA checklists", "Edit", "Move item");
                },
                toggleRequireImage: function () {
                    Analytics.trackEvent(
                        "sb_q_a_checklists",
                        "edit",
                        "Photo required"
                    );
                },
            },
        },
        detailsOverlay: {
            mobileAppLink: {
                clicked: function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "Link to Mobile app",
                        "Clicked"
                    );
                },
            },
            activity: {
                expand: function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "click",
                        "Expanded Activity Details"
                    );
                },
                requestUpdate: function () {
                    Analytics.trackEvent(
                        "sb_conversion",
                        "click",
                        "request for update"
                    );
                },
                reset: function (page) {
                    Analytics.trackEvent(
                        "sb_details_overlay",
                        "reset activity",
                        page
                    );
                },
            },
            tabs: {
                openActivities: function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "Tabs",
                        "Open Activities"
                    );
                },
                openNotes: function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "Tabs",
                        "Open Notes"
                    );
                },
                openDependencies: function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "Tabs",
                        "Open Dependencies"
                    );
                },
                openQAChecklists: function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "Tabs",
                        "Open QA Checklists"
                    );
                },
            },
            checklists: {
                openReviewer: function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "QA Checklists",
                        "Open Reviewer"
                    );
                },
                toPDF: function () {
                    Analytics.trackEvent(
                        "PDF Reports",
                        "click",
                        "QA checklist report"
                    );
                },
                fill: function (label) {
                    Analytics.trackEvent("QA Checklists", "fill", label);
                },
            },
        },
        deliverablesList: {
            filters: {
                changeFilterCondition: function (filterKey) {
                    Analytics.trackEvent("Filters", "Change", filterKey);
                },
            },
            fromToolbar: {
                onPrintQrCodes: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Toolbar",
                        "Button | Print QR Codes"
                    );
                },
                onAssignTemplate: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Toolbar",
                        "Button | Assign Template"
                    );
                },
                onAssignAreaManager: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Toolbar",
                        "Button | Assign Area Manager"
                    );
                },
                onChangeStructure: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Toolbar",
                        "Button | Change Structure"
                    );
                },
                onChangeDates: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Toolbar",
                        "Button | Change Dates"
                    );
                },
                onBulkStateChange: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Toolbar",
                        "Button | Bulk Progress Change"
                    );
                },
                onBulkNoteCreation: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Toolbar",
                        "Button | Bulk Note Creation"
                    );
                },
                onUpdateDeliverableType: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Assign Deliverable Type",
                        "Dialogue opened"
                    );
                },
                onUpdateDeliverableTypeSuccess: function () {
                    Analytics.trackEvent(
                        "Project Progress",
                        "Assign Deliverable Type",
                        "Type assigned"
                    );
                },
            },
            bulk: {
                configure: {
                    activities: function () {
                        var category = "Configure Activities";

                        return {
                            startDate: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Change start date",
                                    numOfActivities + " activities changed"
                                );
                            },
                            dueDate: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Change due date",
                                    numOfActivities + " activities changed"
                                );
                            },
                            unsetDates: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Unset start and due date",
                                    numOfActivities + " activities changed"
                                );
                            },
                            bulkResetActivities: function (numOfActivities) {
                                Analytics.trackEvent(
                                    "sb_configure_activities",
                                    "reset activity",
                                    numOfActivities + "_activities_reset"
                                );
                            },
                            notApplicable: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Activities deleted",
                                    numOfActivities + " activities deleted"
                                );
                            },
                            bringBackNotApplicable: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Activities brought back",
                                    numOfActivities + " activities brought back"
                                );
                            },
                            setDuration: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Set custom duration",
                                    numOfActivities + " activities changed"
                                );
                            },
                            setTeam: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Set custom team",
                                    numOfActivities + " activities changed"
                                );
                            },
                            unsetDuration: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Unset custom duration",
                                    numOfActivities + " activities changed"
                                );
                            },
                            unsetTeam: function (numOfActivities) {
                                Analytics.trackEvent(
                                    category,
                                    "Unset custom team",
                                    numOfActivities + " activities changed"
                                );
                            },
                        };
                    },
                },
                export: {
                    pdfReports: function (type) {
                        Analytics.trackEvent(
                            "PDF Reports",
                            "click",
                            type === "BY_STAGES"
                                ? "Requested QA PDFs"
                                : "Requested QA PDFs by date"
                        );
                    },
                    trackEmailAdded: function () {
                        Analytics.trackEvent(
                            "sb_p_d_f_reports",
                            "qa to external",
                            "email added"
                        );
                    },
                    trackTextAdded: function () {
                        Analytics.trackEvent(
                            "sb_p_d_f_reports",
                            "qa to external",
                            " text added"
                        );
                    },
                },
            },
        },
        leanBoard: {
            deliverable: {
                onLateActivitiesPanelClick: function () {
                    leanBoardEvent("Late activities", "Open panel");
                },
            },
            activity: {
                onSetLastPlannedStartDate: function () {
                    leanBoardEvent("Activity Details", "Set SRP Start date");
                },
                onSetLastPlannedDueDate: function () {
                    leanBoardEvent("Activity Details", "Set SRP Due date");
                },
                onDurationChanged: function () {
                    leanBoardEvent("Activity Details", "Change SRP duration");
                },
                onClick: function () {
                    leanBoardEvent("Activity Details", "Open panel");
                },
                onCommitForecastedDate: function () {
                    leanBoardEvent(
                        "Activity Details",
                        "Used forecasted date as new SRS"
                    );
                },
                onShowActivityDetails: function () {
                    leanBoardEvent("Activity Details", "Show Details");
                },
                onHideActivityDetails: function () {
                    leanBoardEvent("Activity Details", "Hide Details");
                },
                onMoveWBS: function () {
                    leanBoardEvent("Activity Details", "Move WBS activity");
                },
            },
            change: {
                actualDates: function (isShowing) {
                    leanBoardEvent("Actual Dates", isShowing ? "Show" : "Hide");
                },
                columnTimespan: function (timeFrame) {
                    leanBoardEvent("Changed time frame", timeFrame + " view");
                },
                viewAggregate: function (aggregateBy) {
                    leanBoardEvent("Changed to", aggregateBy + " view");
                },
                expandDeliverables: function () {
                    leanBoardEvent("Changed to", "Expand deliverables");
                },
                backToStructureView: function () {
                    leanBoardEvent("Changed to", "Back to WBS view");
                },
                timeRange: function (rangeInDays) {
                    leanBoardEvent(
                        "Select time range",
                        "spanning " + rangeInDays + " days"
                    );
                },
                predefinedRange: function (range, units) {
                    leanBoardEvent(
                        "Used predefined range",
                        "spanning " + range + " " + units
                    );
                },
                zoomSize: function (zoomSize) {
                    var sizeKeys = {
                        small: "Small",
                        medium: "Medium",
                        large: "Large",
                    };
                    leanBoardEvent(
                        "Changed activity design",
                        sizeKeys[zoomSize]
                    );
                },
            },
            open: {
                withTeamFilter: function () {
                    leanBoardEvent("Open", "Default specific team");
                },
                withShowAll: function () {
                    leanBoardEvent("Open", "Default show all activities");
                },
            },
            session: {
                start: function () {
                    leanBoardEvent("Staging Session", "Start session");
                },
                release: function () {
                    leanBoardEvent(
                        "Staging Session",
                        "Release new SRS to project"
                    );
                },
                onForecastAllDeliverables: function () {
                    leanBoardEvent("Forecasting", "Forecasted whole project");
                },
                onAcceptAllForecastedDeliverables: function () {
                    leanBoardEvent("Forecasting", "Accept All");
                },
                onCancelAcceptAllForecastedDeliverables: function () {
                    leanBoardEvent("Forecasting", "Accept All Canceled");
                },
                onForecastSingleDeliverable: function () {
                    leanBoardEvent(
                        "Forecasting",
                        "Forecasted individual deliverable"
                    );
                },
                onForecastSingleStructure: function () {
                    leanBoardEvent("Forecasting", "Forecasted WBS level");
                },
                onCancelForecast: function () {
                    leanBoardEvent(
                        "Forecasting",
                        "Canceled forecasting whole project through dialogue"
                    );
                },
                cancel: function () {
                    leanBoardEvent("Staging Session", "Cancel session");
                },
                concurrentSession: function (text) {
                    leanBoardEvent("Staging Session", text);
                },
                exitPageWithRelease: function () {
                    leanBoardEvent(
                        "Staging Session",
                        "Decided to leave page and release"
                    );
                },
                exitPageWithoutRelease: function () {
                    leanBoardEvent(
                        "Staging Session",
                        "Decided to leave page and NOT release"
                    );
                },
                exitPageWithDiscard: function () {
                    leanBoardEvent(
                        "Staging Session",
                        "Decided to leave page and discard session"
                    );
                },
                stayOnPage: function () {
                    leanBoardEvent(
                        "Staging Session",
                        "Decided to stay on page"
                    );
                },
            },
            selection: {
                started: () =>
                    leanBoardEvent("Bulk Changes", "Selection started"),
                onActivity: (isSelected) =>
                    leanBoardEvent(
                        "Bulk Changes",
                        `Activity ${isSelected ? "" : "de"}selected`
                    ),
                onActivities: () =>
                    leanBoardEvent("Bulk Changes", "Activities ALT selected"),
                canceled: () =>
                    leanBoardEvent("Bulk Changes", "Selection canceled"),
                error: () =>
                    errorEvent(
                        "Error",
                        "Tried to select a second activity from the same deliverable (Week Plan)"
                    ),
            },
            bulkChange: {
                started: () =>
                    leanBoardEvent("Bulk Changes", "Open change dialogue"),
                finished: (amount) =>
                    leanBoardEvent("Bulk Changes", `Finished (${amount})`),
            },
            print: function () {
                leanBoardEvent("Print", "Print");
            },
            search: function () {
                leanBoardEvent("Search", "Search");
            },
        },
        filterMenu: {
            byConstructionManager: function (pageName) {
                Analytics.trackEvent(
                    "Filter Component",
                    "Construction Manager",
                    pageName
                );
            },
            byProjectStructure: function (pageName) {
                Analytics.trackEvent(
                    "Filter Component",
                    "Project Structure",
                    pageName
                );
            },
            byTeam: function (pageName) {
                Analytics.trackEvent("Filter Component", "Teams", pageName);
            },
            byProcessTemplate: function (pageName) {
                Analytics.trackEvent(
                    "Filter Component",
                    "Process Template",
                    pageName
                );
            },
            byDeliverableType: function (pageName) {
                Analytics.trackEvent(
                    "Filter Component",
                    "Deliverable Type",
                    pageName
                );
            },
        },
        siteDiary: {
            calendar: {
                selectDate: function () {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Select dates",
                        "Select date"
                    );
                },
                selectDateFromPicker: function () {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Select dates",
                        "Select date from date picker"
                    );
                },
                showNextDates: function () {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Select dates",
                        "Show next dates"
                    );
                },
                showPreviousDates: function () {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Select dates",
                        "Show previous dates"
                    );
                },
            },
            onCreateNewEntry: function () {
                Analytics.trackEvent(
                    "Daily Logs",
                    "Create Logs",
                    "New log created"
                );
            },
            onFinaliseEntry: function () {
                Analytics.trackEvent(
                    "Daily Logs",
                    "Create Logs",
                    "Finalize Log Entry"
                );
            },
            onRefreshProgressData: function () {
                Analytics.trackEvent(
                    "Daily Logs",
                    "Create Logs",
                    "Refresh Progress Data"
                );
            },
            cards: {
                onCardSave: function (cardName) {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Create Logs",
                        cardName + " card saved"
                    );
                },
                onCardInputChange: function (label) {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Create Logs",
                        label + " changed"
                    );
                },
            },
            tryNavigateAwayWithUnsavedChanges: function () {
                Analytics.trackEvent(
                    "Daily Logs",
                    "Navigate away",
                    "Tried to navigate away with unsaved changes"
                );
            },
            onLeavingPageWithUnsavedChanges: {
                decidedToLeave: function () {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Navigate away",
                        "Decided to leave page"
                    );
                },
                decidedToStay: function () {
                    Analytics.trackEvent(
                        "Daily Logs",
                        "Navigate away",
                        "Decided to stay on page"
                    );
                },
            },
        },
        team: {
            onMemberRemovedItselfAsAdmin: function () {
                Analytics.trackEvent(
                    "Team",
                    "Member removed",
                    "Itself as admin"
                );
            },
            onMemberManagement: function (label) {
                Analytics.trackEvent("Team", label);
            },
            onTeamManagement: function (label) {
                Analytics.trackEvent("Team", label, "on Team Management");
            },
        },
        constructionManager: {
            onDeliverablesAssigned: function () {
                Analytics.trackEvent(
                    "Construction Manager",
                    "Deliverables assigned",
                    "Deliverables assigned"
                );
            },
            onLeaveDelegation: function (event) {
                Analytics.trackEvent(
                    "Construction Manager",
                    "Leave delegation",
                    event
                );
            },
            onKnownIssue: function (issue) {
                Analytics.trackEvent(
                    "Construction Manager",
                    "Known issues",
                    issue
                );
            },
        },
        commercial: {
            onCostGroupCreate: function (action) {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Create Cost Group",
                    action
                );
            },
            onCostGroupDelete: function () {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Delete Cost Group",
                    "Delete Cost Group"
                );
            },
            onCurrencyChange: function () {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Cost Group setup",
                    "Changed currency"
                );
            },
            onChangeReportedAt: function () {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Select date",
                    "Select date to evaluate Cost Group"
                );
            },
            onExportCsv: function () {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Export report",
                    "CSV exported"
                );
            },
            onGoToDeliverables: function (ofType) {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Click to see Deliverables",
                    ofType
                );
            },
            onGoToTracker: function (ofType) {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Click to see tracker",
                    ofType
                );
            },
            onMembershipEdit: function (action) {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Privacy of Cost Group",
                    action
                );
            },
            onEditingStageGroups: function (action) {
                Analytics.trackEvent(
                    "Commercial Dashboard",
                    "Cost Group setup",
                    action
                );
            },
        },
        projectSettings: {
            changeStatus: function (action) {
                Analytics.trackEvent(
                    "Project Settings",
                    "Project Status",
                    action
                );
            },
        },
        dates: {
            import: {
                success() {
                    Analytics.trackEvent("Dates import", "MS Excel", "Success");
                },
                failure() {
                    Analytics.trackEvent(
                        "Dates import",
                        "MS Excel",
                        "Some errors"
                    );
                },
                tooLongInputFailure() {
                    errorEvent("Error", "Dates Import >500");
                },
            },
        },
    };
}
