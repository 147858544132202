/**
 *   Basic error to be able to present it to a user.
 *
 * @param {string} [code=UNKNOWN] - Error code
 * @param {string} [title=ERRORS_UNKNOWN_TITLE] - Error title
 * @param {string} [message=ERRORS_UNKNOWN_MESSAGE] - Error message
 *
 * @constructor
 * @extends Error
 */
function PresentableError(code, title, message) {
    this.code = code || "UNKNOWN";
    this.title = title || "ERRORS_UNKNOWN_TITLE";
    this.message = message || "ERRORS_UNKNOWN_MESSAGE";
}

PresentableError.defaultIcon = "sb-icon-status_alert";

PresentableError.presentationStyle = {
    DIALOG: "dialog",
    TOAST: "toast",
    NONE: "none",
};

PresentableError.prototype = Object.create(Error.prototype);

/**
 * Return the best presentation style.
 * one of: TOAST (default), DIALOG
 *
 * @returns {string}
 */
PresentableError.prototype.preferredPresentationStyle = function () {
    return PresentableError.presentationStyle.TOAST;
};
/**
 * Whether it should show the plain not translated message or not
 * @returns {boolean}
 */
PresentableError.prototype.shouldShowPlainMessage = function () {
    return false;
};

/**
 * Icon to display.
 *
 * @returns {boolean|string} - false for no icon, true for default icon, or a css class string
 */
PresentableError.prototype.getIcon = function () {
    return PresentableError.defaultIcon;
};

/**
 * Actions are attached to Errors, so UI elements can use them to enable user interaction.
 *
 * @typedef {Object} Action
 *
 * @property content {String}
 * @property contentValues {Object}
 * @property highlighted {Boolean}
 * @property href {String}
 */

/**
 * Attach actions to the error if you want to use them later. UI elements are using them to enable user interaction
 *
 * @returns {Array.<Action>} - List with actions for the dialog or toast
 */
PresentableError.prototype.getActions = function () {
    return [];
};

/**
 * Whether the user can press escape to close the dialog and
 * can click outside the dialog to close it and
 * if the toast should stay active
 * @returns {boolean}
 */
PresentableError.prototype.shouldAutoClose = function () {
    return false;
};

export default PresentableError;
