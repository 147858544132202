export default function ChecklistAPIService($sbChecklistTemplatesApi) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        getByProject: getByProject,
        getById: getById,
        create: create,
        update: update,
        remove: remove,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Get all checklist templates for a project
     *
     * @param {string} projectId
     *
     * @returns {Array<Object>}
     */
    function getByProject(projectId) {
        return $sbChecklistTemplatesApi.getCollection(projectId);
    }

    /**
     * Get a checklist template by it's id
     *
     * @param {string} checklistTemplateId
     *
     * @returns {Object}
     */
    function getById(projectId, checklistTemplateId) {
        return $sbChecklistTemplatesApi.get(projectId, checklistTemplateId);
    }

    /**
     * Create a checklist template for a project
     *
     * @param {string} projectId
     * @param {string} checklistTemplateName
     *
     * @returns {Object}
     */
    function create(projectId, checklistTemplateName) {
        return $sbChecklistTemplatesApi.create(projectId, {
            name: checklistTemplateName,
            checklist_items: [],
        });
    }

    /**
     * Update a checklist template
     *
     * @param {Object} checklistTemplateData
     *
     * @returns {Object}
     */
    function update(projectId, checklistTemplateId, checklistTemplateData) {
        return $sbChecklistTemplatesApi.update(
            projectId,
            checklistTemplateId,
            checklistTemplateData
        );
    }

    /**
     * Delete a checklist template
     *
     * @returns {undefined}
     */
    function remove(projectId, checklistTemplateId) {
        return $sbChecklistTemplatesApi.delete(projectId, checklistTemplateId);
    }
}
