import angular from "angular";
import _ from "lodash";
import moment from "moment";
import VirtualODataLoader from "./virtual_odata_loader.class";

export default function (
    $log,
    $sbSiteInspections,
    $sbErrorPresenter,
    $sbInspectionsApi
) {
    "ngInject";
    var InspectionLazyLoader = function (filter, orderBy) {
        VirtualODataLoader.call(this, filter, orderBy);
    };

    InspectionLazyLoader.prototype = Object.create(
        VirtualODataLoader.prototype
    );

    /**
     * @inheritDoc
     *
     * @param {Number} from - load data block from number
     * @param {Number} to - to number
     * @returns {$q} - the resolved OData request with the data array
     */
    InspectionLazyLoader.prototype.loadODataResource = function (
        query,
        from,
        to
    ) {
        const projectIdFilter = _.find(query.filter, ["key", "projectId"]);
        const projectId = projectIdFilter.getValuesAsUrl();

        const queryParameters = {
            skip: from,
            top: to,
        };

        const authorFilter = _.find(query.filter, ["key", "author"]);
        if (authorFilter.hasValue()) {
            queryParameters.reporter = authorFilter.getValuesAsUrl();
        }

        const dateFilter = _.find(query.filter, ["key", "inspection"]);
        const filterDates = extractDatesFromDateViewFilter(dateFilter);
        queryParameters.lastUpdatedAfter = filterDates.from;
        queryParameters.lastUpdatedBefore = filterDates.to;

        return $sbInspectionsApi
            .getCollection(projectId, queryParameters)
            .then(function (response) {
                $log.log("lazy loader for INSPECTION_OVERVIEW", response);
                return {
                    count: response.meta.count_all,
                    items: response.inspections.map(
                        $sbSiteInspections.parseServerInspection
                    ),
                };
            })
            .catch(function (e) {
                $sbErrorPresenter.catch(e); // handle the error
                throw e; // throw again to inform the lazy loader
            });
    };

    return InspectionLazyLoader;
}

function extractDatesFromDateViewFilter(viewFilter) {
    const options = viewFilter.collectActiveOptions();
    if (options && options.length > 0) {
        const from = _.get(options, "[0].value.from");
        const to = _.get(options, "[0].value.to");
        return {
            from: from ? from.toISOString() : undefined,
            to: to ? to.toISOString() : undefined,
        };
    } else {
        if (viewFilter.hasValue()) {
            return {
                from: viewFilter.valueFrom
                    ? moment(viewFilter.valueFrom).startOf("day").toISOString()
                    : undefined,
                to: viewFilter.valueTo
                    ? moment(viewFilter.valueTo).endOf("day").toISOString()
                    : undefined,
            };
        }
    }
    return {};
}
