// !!!
// !!! this file needs to be in line with the sb-color-palette.scss and colorpalette.less file
// !!!

//
// colors with purpose
//

// activity state "like" colors
//
export const activity_state_not_started = "#FFFFFF";
export const activity_state_started = "#61A2F5";
export const activity_state_available = activity_state_started;
export const activity_state_completed = "#36B381";
export const activity_state_confirmed = activity_state_completed;
export const activity_state_done = activity_state_completed;
export const activity_state_wfc = activity_state_completed;
export const activity_state_rejected = "#FF4141";
export const activity_state_not_applicable = "#B3B3B3";

// activity state __icon__ colors
//
export const activity_state_icon_not_started = "#6C6C6C";
export const activity_state_icon_started = activity_state_started;
export const activity_state_icon_confirmed = activity_state_confirmed;
export const activity_state_icon_done = activity_state_done;
export const activity_state_icon_wfc = "#6C6C6C";
export const activity_state_icon_rejected = activity_state_rejected;

// scheduling colors
//
export const activity_scheduling_unscheduled = "#6C6C6C";
export const activity_scheduling_on_time = "#5EC29A";
export const activity_scheduling_behind = "#B33636";
export const user_date_color = "#36B381";
export const user_date_conflict_color = "#BA6500";

// note colors
//
export const note_color_info = "#365781";
export const note_color_quality_issue = "#E87E00";
export const note_color_obstruction = "#FF4141";
export const note_state_icon_open = "#FF5A5A";
export const note_state_icon_wfc = "#90F9C4";

// role colors
//
export const role_admin = "#8F2B2B";
export const role_manager = "#F8C244";
export const role_controller = "#365781";
export const role_inspector = "#36B381";
export const role_reviewer = "#6C6C6C";

// line indicator
//  on tracker
export const planed_completed_today = activity_scheduling_behind;
export const planed_completed_end_of_this_week = "#E87E00";
export const planed_completed_end_of_next_week = "#F8C224";

// in charts
export const planed_as_completed = "#F9C83A";
export const actual_completed = activity_state_completed;
export const actual_confirmed = activity_state_confirmed;
export const actual_work_finished = "#365781";
