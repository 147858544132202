import angular from "angular";
import htmlTemplate from "./sb_image_card.html";

/**
 * A basic directive to display an empty state.
 *
 * Give it a translation key for the title, message and a section.
 * You can transclude action buttons and they will be displayed at the bottom of the card.
 */
export default angular
    .module("sbApp.sbImageCard", [])
    .directive("sbImageCard", function () {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            bindToController: true,
            controllerAs: "card",
            scope: {
                name: "=",
                thumbnail: "<",
                uploadedTimestamp: "=",
                modifiedTimestamp: "=",
                creator: "=",
                size: "=",
                navigateTo: "@",
                sourceType: "@",
                onDelete: "&",
                ngModel: "=",
            },
            controller: angular.noop,
        };
    });
