import EditorStage from "../editor/EditorStage";
import EdgeContainer from "../edge/EdgeContainer";
import AnchorPoint from "../common/AnchorPoint";
import CreateEdgeEvent from "../events/CreateEdgeEvent";
import StructureNode from "../node/StructureNode";
import ToggleToastEvent from "../events/ToggleToastEvent";
/**
 * Editor Stage for WBSEditor
 *
 * @memberof WBS
 * @extends EditorStage
 * @constructs WbsStage
 *
 */
function WbsStage() {
    // call constructor of parent class to set context and arguments
    //
    EditorStage.apply(this, arguments);

    this.crtDownLightNodes = [];

    this.on("tapAnchorPoint", this.onTapAnchorPoint);
}

WbsStage.prototype = Object.create(EditorStage.prototype);

/**
 * Defines if Edge Creation Mode is enabled
 * @type {boolean}
 */
WbsStage.prototype.edgeCreateMode = false;

/**
 * all current down light nodes
 * @type {StructureNode[]}
 */
WbsStage.prototype.crtDownLightNodes = [];

/**
 * current edge start node
 * @type {StructureNode}
 */
WbsStage.prototype.crtEdgeStartNode = null;

/**
 * Enables edge creation mode and disable mouse hover
 * @param {WBS.StructureNode} edgeStartNode - Node where new Edge starts
 */
WbsStage.prototype.enableEdgeCreateMode = function (edgeStartNode) {
    this.setNeedsDisplay();
    this.disableEdgeCreateMode();
    this.edgeCreateMode = true;
    this.crtEdgeStartNode = edgeStartNode;
    this.selectNode(edgeStartNode);
    this.hoverEnabled = false;

    this.dispatchEvent(
        new ToggleToastEvent("edgeMode", true, "EDGE_MODE_ACTIVE")
    );
};

/**
 * Disabled edge creation mode and enables mouse hover
 */
WbsStage.prototype.disableEdgeCreateMode = function () {
    if (this.edgeCreateMode) {
        console.log("disable edge creation mode");
        this.edgeCreateMode = false;
        this.hoverEnabled = true;

        this.dispatchEvent(new ToggleToastEvent("edgeMode", false));
        this.setNeedsDisplay();
    }

    if (this.crtEdgeStartNode) {
        this.crtEdgeStartNode = null;
    }
};

/**
 * Handles SingleTap on an Element
 * * in edge creation mode: trigger creation on element
 * * otherwise: select node and show corresponding edges
 * @param {WBS.TapableElement} element - Element that was tapped
 * @returns {boolean|undefined}
 */
WbsStage.prototype.onSingleTapOnElement = function (element) {
    if (this.edgeCreateMode) {
        this.createEdgeBetween(this.crtEdgeStartNode, element);
        this.disableEdgeCreateMode();
    } else {
        if (element instanceof StructureNode) {
            this.showEdgesOfNode(element);
        }
        return EditorStage.prototype.onSingleTapOnElement.call(this, element);
    }
};

/**
 * Handles Single Tap when no element was tapped
 * * disables edge creation mode
 * * hide edges
 */
WbsStage.prototype.onSingleTapOnNull = function () {
    this.disableEdgeCreateMode();
    this.showEdgesOfNode(null);
    EditorStage.prototype.onSingleTapOnNull.call(this);
};

/**
 * Hides edges &  Uplight downloghted Nodes
 */
WbsStage.prototype.hideEdges = function () {
    this.setNeedsDisplay();
    this.upLightAllCrtDownLightNodes();
    if (this.edgeContainer instanceof EdgeContainer) {
        this.edgeContainer.clear();
    }
};

/**
 *
 * Display all incoming and outgoing edges of the given structuring node.
 *
 * Rendering is done on a separate {EdgeContainer}
 *
 * @param {WBS.StructureNode} element - Node the shown Edges belong to
 */
WbsStage.prototype.showEdgesOfNode = function (element) {
    if (!this.currentSettings.showEdges) {
        return;
    }

    this.upLightAllCrtDownLightNodes();

    var nodeContainer = null,
        edgeOption = {
            dsa: false,
        };

    if (this.edgeContainer === undefined) {
        this.edgeContainer = new EdgeContainer(this.viewport);
    } else {
        this.edgeContainer.clear();
    }

    if (element instanceof AnchorPoint) {
        element = element.parent;
    }

    if (element instanceof StructureNode) {
        var entity = element.entity;
        var aPredecessors = entity.getIncomingEdges(),
            aSuccessors = entity.getOutgoingEdges();

        aPredecessors.forEach(function (inId) {
            nodeContainer = element.entity.manager.getGeometry(inId);
            this.edgeContainer.showEdgesBetween(nodeContainer, element.parent);
        }, this);

        aSuccessors.forEach(function (toId) {
            nodeContainer = element.entity.manager.getGeometry(toId);
            this.edgeContainer.showEdgesBetween(
                element.parent,
                nodeContainer,
                edgeOption
            );
        }, this);

        var notConnectedNodes = entity.getNotConnectedStructureNodes();
        //remove itself from list
        var index = notConnectedNodes.indexOf(element);
        if (index !== -1) {
            notConnectedNodes.splice(index, 1);
        }
        this.downLightNodes(notConnectedNodes);
    }
};
/**
 * Downlights given Nodes
 * @param {StructureNode[]} nodes   Nodes to be downlighted
 */
WbsStage.prototype.downLightNodes = function (nodes) {
    this.setNeedsDisplay();
    this.upLightAllCrtDownLightNodes();
    this.crtDownLightNodes = nodes;

    nodes.forEach(function (node) {
        node.dispatchEvent("downLight");
    });
};
/**
 * uplight all current downlighted nodes
 */
WbsStage.prototype.upLightAllCrtDownLightNodes = function () {
    this.upLightNodes(this.crtDownLightNodes);
    this.crtDownLightNodes = [];
};

/**
 * Uplights given Nodes
 * @param {StructureNode[]} nodes - Nodes to be uplight
 */
WbsStage.prototype.upLightNodes = function (nodes) {
    this.setNeedsDisplay();
    nodes.forEach(function (node) {
        node.dispatchEvent("upLight");
    });
};

/**
 * executed if a anchor point was taped
 * @param {createjs.Event} event - Event Object
 */
WbsStage.prototype.onTapAnchorPoint = function (event) {
    var node = event.target;
    this.enableEdgeCreateMode(node);
};

/**
 * Creates a edge between {@startNode} and {@endNode}
 * @param {WBS.StructureNode} startNode - Start Node of Edge
 * @param {WBS.StructureNode} endNode - End Node of Edge
 */
WbsStage.prototype.createEdgeBetween = function (startNode, endNode) {
    if (
        typeof startNode.entity.getData().ID === "string" &&
        typeof endNode.entity.getData().ID === "string"
    ) {
        this.dispatchEvent(
            new CreateEdgeEvent(
                startNode.entity.getData().ID,
                endNode.entity.getData().ID
            )
        );
    }
};

/**
 * Handles changed settings (e.g. enabling of edit mode)
 * @param {WBS.SettingsChangedEvent} settingsChangedEvent - Event Object
 * @private
 */
WbsStage.prototype._onSettingsChanged = function (settingsChangedEvent) {
    this.setNeedsDisplay();
    EditorStage.prototype._onSettingsChanged.call(this, settingsChangedEvent);

    if (settingsChangedEvent.isDisabledAndHasChanged("showEdges")) {
        this.disableEdgeCreateMode();
        this.hideEdges();
    }
};

export default WbsStage;
