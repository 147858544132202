import _ from "lodash";

function User(user) {
    this.id = _.get(user, "attributes.sub");
    this.username = user.username;
    this.mail = _.get(user, "attributes.email");
    this.company = _.get(user, "attributes.custom:company");
    this.name = _.get(user, "attributes.name");
    this.displayName = this.name;
    this.initials = _.get(user, "attributes.name", "")
        .split(" ")
        .map((namePart) => namePart[0])
        .join("");

    this.signupOrigin = _.get(user, "custom:signup_origin");
    this.jobTitle = _.get(user, "custom:job_title");
    this.companyType = _.get(user, "custom:company_type");
    this.marketingConsent = _.get(user, "custom:hs_marketing_consent");

    this.userDetails = {
        username: user.username,
        mail: _.get(user, "attributes.email"),
    };

    this._amplify = user;
}

export default User;
