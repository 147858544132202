import _ from "lodash";

class StorageService {
    // TODO: Check if we need to scope the key per project
    // TODO: Clear on log out
    constructor() {
        this.ASYNC_JOB_KEY = "asyncJobs";
    }

    get(key) {
        // Get old entry
        let entry = localStorage.getItem(key);

        if (_.isString(entry)) {
            return JSON.parse(entry);
        }
    }

    store(key, data) {
        // Add to local storage
        return localStorage.setItem(key, data);
    }
}

export default StorageService;
