/* eslint-disable new-cap, camelcase */
/**
 * Created by Sebastian on 15.09.2015.
 *
 * This is a wrapper service for the basic Intercom API interactions,
 * supported by their client side javascript library.
 *
 * For documentation @see {@link http://docs.intercom.io/install-on-your-web-product/intercom-javascript-api}
 */
import angular from "angular";
import has from "has";
import "angular-intercom";
import "./intercomEventConstants";

export default function intercomService(
    Intercom,
    $rootScope,
    $log,
    INTERCOM_APP_ID,
    TRACKED_EVENTS
) {
    "ngInject";
    var validEventKeys = getValidEventKeys(TRACKED_EVENTS);

    $rootScope.$on("sb.global.logout", logout);

    return {
        events: TRACKED_EVENTS,
        boot: boot,
        bootUnknownUser: bootUnknownUser,
        update: update,
        logout: logout,
        track: track,
        show: show,
        hide: hide,
        showMessages: showMessages,
        showNewMessage: showNewMessage,
    };

    /**
     * Helper class to return all valid event names as array.
     * This is used to verify events before sending to API.
     *
     * @param {Object} trackedEvents - Key value pairs of event names.
     * @return {Array}
     */
    function getValidEventKeys(trackedEvents) {
        return Object.keys(trackedEvents).map(function (key) {
            return trackedEvents[key];
        });
    }

    /**
     * Boot the intercom user from configuration.
     *
     * @param bootConfiguration
     */
    function bootIntercomUser(bootConfiguration) {
        // documentation @see http://docs.intercom.io/install-on-your-web-product/intercom-javascript-api
        //
        Intercom.boot(bootConfiguration);
    }

    /**
     * Boot up Intercom with configuration.
     *
     * @param {User} user - instance of the user created by $sbAuth.getCurrentUser
     * @return {*} The session object from input.
     */
    function boot(user) {
        bootIntercomUser({
            app_id: INTERCOM_APP_ID,
            name: user.name,
            company: user.company,
            email: user.mail,
            custom_launcher_selector: "#intercom_launcher",
        });

        return user;
    }

    /**
     * Boot Intercom with unknown user (system_alert).
     * Used to track maintenance page event.
     */
    function bootUnknownUser() {
        var bootConfiguration = {
            app_id: INTERCOM_APP_ID,
            user_id: "SABLONO_SUPPORT",
            email: "support@sablono.com",
        };

        bootIntercomUser(bootConfiguration);
    }
    /**
     * Delegate call to Intercom object update method
     */
    function update() {
        Intercom.update();
    }

    /**
     * This method will effectively clear out any user data that you have been passing through the JS API.
     * You should call the shutdown method anytime a user logs out of the application.
     */
    function logout() {
        Intercom.shutdown();
    }

    /**
     * Trigger the desired event with the given metadata.
     *
     * @param {string} event - The event name @see IntercomEventConstants.js
     * @param {Object} [metadata] - The actual data to send to Intercom.
     */
    function track(event, metadata) {
        // if event is not valid fail silently
        //
        if (validEventKeys.indexOf(event) === -1) {
            $log.error(
                "No valid marketing event!",
                event,
                "is not in ",
                validEventKeys
            );
        } else {
            if (has("development")) {
                $log.info.apply($log, [
                    "%cIntercom.trackEvent( " + event + " )",
                    "color: #365781",
                ]);
            }

            // only send metadata if available
            //
            if (typeof metadata !== "undefined") {
                Intercom.trackEvent(event);
            } else {
                Intercom.trackEvent(event, metadata);
            }
        }
    }

    /**
     * This will show the default Intercom message window.
     * If there are no conversations it will open with the new message view,
     * if there are it will open with the messages list.
     */
    function show() {
        Intercom.show();
    }

    /**
     * This will hide the default Intercom message window.
     */
    function hide() {
        Intercom.hide();
    }

    /**
     * To open the message window with the message list you can call
     */
    function showMessages() {
        Intercom.showMessages();
    }

    /**
     * To open the message window with the new message view you can call
     * @param {string} [prePopulatedContent] - can be used to pre-populate the message composer.
     */
    function showNewMessage(prePopulatedContent) {
        Intercom.showNewMessage(prePopulatedContent);
    }
}
