export default class PerformanceWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint enables advanced analytics on project performance values.
     *
     * @param projectId {string}    UUID
     *
     * @param data {object}                         request body
     * @param data.date_range {object}              date range to evaluate
     * @param data.date_range.start_date {string}   start date (currently supports '-Infinity')
     * @param data.date_range.end_date {string}     a valid date value of format YYYY-MM-DD
     * @param data.requests {Array}                 One or multiple report requests
     *
     * @param [query] {object}      query parameters
     */
    getCollection(
        projectId,
        data,
        { byStructureId, byOnlyMyArea, byTeamId } = {}
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/performance`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: data,
            query: {
                by_structure_id: byStructureId,
                by_only_my_area: byOnlyMyArea,
                by_team_id: byTeamId,
            },
        });
    }
}
