/**
 * A ODataFilterSet instance to handle "selection" and "category" states.
 * It wraps a map of filter options identified by their KEY
 *
 * @param {String} category - category tag
 * @param {String} selection - key of the selected option
 * @param {Object<String,ODataFilter>} items - map with all filter options for this filter instance/category.
 * @constructor
 */
function ODataFilterSet(category, selection, items) {
    this._category = category;
    this._selection = selection;

    /**
     * list of available filters -> bound to the accordion panes
     * @type {Object<String,ODataFilter>}
     */
    this._items = items || [];
}

/**
 * Get or Set the selection key of this filter.
 *
 * @param {String} [newValue] - the new selection key
 * @returns {*} - the selection key string if no arguments or the ODataFilterSet Instance if you set the newValue
 */
ODataFilterSet.prototype.selection = function (newValue) {
    if (arguments.length > 0) {
        this._selection = newValue;
        return this;
    } else {
        return this._selection;
    }
};

/**
 * Get or Set the category tag of this filter.
 *
 * @param {String} [newValue] - the new category value tag
 * @returns {*} - the category string if no arguments or the ODataFilterSet Instance if you set the newValue
 */
ODataFilterSet.prototype.category = function (newValue) {
    if (arguments.length > 0) {
        this._category = newValue;
        return this;
    } else {
        return this._category;
    }
};

/**
 * Get or Set the items list of this filter.
 *
 * @param {Object<String,ODataFilter>} [newValue] - the new list of filter options
 * @returns {*} - the list if no arguments or the ODataFilterSet Instance if you set the newValue
 */
ODataFilterSet.prototype.items = function (newValue) {
    if (arguments.length > 0) {
        this._items = newValue;
        return this;
    } else {
        return this._items;
    }
};

/**
 * Get the Filter Option that is currently active.
 *
 * @returns {ODataFilter} - the filter option of the current selected key.
 */
ODataFilterSet.prototype.getSelectedItem = function () {
    return this._items[this.selection()];
};

export default ODataFilterSet;
