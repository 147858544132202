import angular from "angular";
export default angular
    .module("sbApp.sbIFrame", [])
    .directive("sbIframeOnload", function () {
        return {
            scope: {
                callBack: "=sbIframeOnload",
            },
            link: function (scope, element) {
                element.on("load", function () {
                    if (element.length > 0 && element[0].contentWindow) {
                        // if we can determine the iframe content location -> return it
                        return scope.callBack(
                            element[0].contentWindow.location
                        );
                    } else {
                        // return undefined location
                        return scope.callBack();
                    }
                });

                // unhook
                scope.$on("$destroy", function () {
                    element.off("load");
                });
            },
        };
    });
