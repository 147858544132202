import PresentableError from "./PresentableError";

/**
 * Error if a a user tries to modify a deliverable that has been
 * modified in a concurrent session
 *
 * @constructor
 * @extends PresentableError
 *
 */
function ModifiedInConcurrentSessionError() {
    this.code = "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION";
    this.title = "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_TITLE";
    this.message = "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_MESSAGE";
}

ModifiedInConcurrentSessionError.prototype = Object.create(
    PresentableError.prototype
); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ ModifiedInConcurrentSessionError.prototype.preferredPresentationStyle =
    function () {
        // set as NONE as this error is handled in the lean
        // board controller, process template page, and deliverables list
        return PresentableError.presentationStyle.NONE;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ ModifiedInConcurrentSessionError.prototype.shouldAutoClose =
    function () {
        return true;
    };

export default ModifiedInConcurrentSessionError;
