import _ from "lodash";
import LeanboardColumn from "./leanboard_column.class";
import moment from "moment";

/**
 * Create the initial data model for a leanboard lane.
 *
 * @constructor
 */
function LeanboardLane(deliverable, board) {
    this.data = deliverable.activities;
    this.deliverable = deliverable;
    this.layout = [];
    this.__board = board;
}

LeanboardLane.columnDayIdentifierFormat = "YYYY-MM-DD";

LeanboardLane.prototype.getColumnIdentifiersFor = function (range) {
    return range.map(function (day) {
        return day.format(LeanboardLane.columnDayIdentifierFormat);
    });
};

/**
 * Add another data item to the leanboard information.
 *
 * @param {object} value
 * @returns {LeanboardLane}
 */
LeanboardLane.prototype.addData = function (value) {
    this.data.push(value);
    return this;
};

/**
 * Add another column to the leanboard.
 *
 * @param {Array.<object>} data
 * @returns {LeanboardLane}
 */
LeanboardLane.prototype.setData = function (data) {
    this.data = data;
    return this;
};

/**
 * Find a specified data item by searching the key for the value.
 *
 * @param {string} key
 * @param {*} value
 *
 * @returns {object} The stored object
 */
LeanboardLane.prototype.getItemBy = function (key, value) {
    return _.find(this.data, [key, value]);
};

LeanboardLane.prototype.getEarliestDayInLane = function () {
    return this.__board.getEarliestDisplayedDay();
};

LeanboardLane.prototype.getBoardTimeFrame = function () {
    return this.__board.timeframe;
};
/**
 * Get columns of leanboard.
 *
 * @returns {Array.<LeanboardColumn>}
 */
LeanboardLane.prototype.getDeliverable = function () {
    return this.deliverable;
};

LeanboardLane.prototype.getTotalNumberOfItems = function () {
    return this.data.length;
};

LeanboardLane.prototype.getSlotOccupancy = function (
    columnTimespan,
    fnOccupySlot
) {
    var that = this;
    var numOfItems = this.getTotalNumberOfItems();
    var header = this.__board.getHeader(columnTimespan);

    var columns = header.map(function (headerItem) {
        return new LeanboardColumn(
            numOfItems,
            headerItem.format(LeanboardLane.columnDayIdentifierFormat)
        );
    });

    this.data
        .sort((firstActivity, secondActivity) => {
            const s1 = firstActivity.startDate;
            const s2 = secondActivity.startDate;
            // make sure the activities are placed in date order
            if (s1 && moment.isMoment(s1) && s1.isValid()) {
                if (s2 && moment.isMoment(s2) && s2.isValid()) {
                    return s1.isBefore(s2) ? -1 : 1;
                } else {
                    return 1;
                }
            } else {
                return -1;
            }
        })
        .forEach(function (item, index) {
            // might be a performance bottle neck.. .apply() might no be that cheap and is called maaaany times here.
            fnOccupySlot.apply(that, [item, columns, index]);
        });

    this.layout = columns;

    return this.layout;
};

export default LeanboardLane;
