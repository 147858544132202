import angular from "angular";
import htmlTemplate from "./sb_table.html";

export default angular
    .module("sbApp.sbTable", [])
    .directive("sbTable", function () {
        return {
            restrict: "E",
            scope: {
                headers: "=",
                content: "=",
                sortable: "=",
                rowsPerPage: "=",
                maxPaginationCount: "=",
            },
            bindToController: true,
            controllerAs: "table",
            templateUrl: htmlTemplate,
            controller: function ($scope, $filter) {
                // BA 22/08: I started refactoring this a little bit but it's still in progress
                // because we decided not to use it in this sprint. But still is improved
                /////////////////////
                //
                //      Direct variables
                //
                /////////////////////

                var vm = this;

                var orderBy = $filter("orderBy");

                /////////////////////
                //
                //      SCOPE properties
                //
                /////////////////////

                vm.defaultMaxPaginationCount = 10;
                vm.defaultCount = 10;
                vm.currentPage = 0;

                vm.pagination = [];
                vm.tablePage = 0;

                vm.handleSort = handleSort;
                vm.filterRowValue = filterRowValue;
                vm.order = order;

                vm.goToPage = goToPage;
                vm.setRowsPerPage = setRowsPerPage;

                activate();

                /////////////////////
                //
                //      WATCHER
                //
                /////////////////////

                $scope.$watch("content.length", function () {
                    calcPaginationCount();
                });

                /////////////////////
                //
                //      IMPL
                //
                /////////////////////

                function activate() {
                    calcPaginationStart();
                }

                function calcPaginationStart() {
                    var maxPaginationCount =
                        vm.maxPaginationCount || vm.defaultMaxPaginationCount;
                    vm.startFrom = Math.max(
                        Math.min(
                            Math.floor(vm.tablePage - maxPaginationCount / 2),
                            nbOfPages() - maxPaginationCount
                        ),
                        0
                    );
                }

                function calcPaginationCount() {
                    vm.pagination = _getNumber(nbOfPages());
                }

                vm.nbOfPages = nbOfPages;

                function nbOfPages() {
                    return Math.ceil(vm.content.length / vm.rowsPerPage) || 0;
                }

                function handleSort(field) {
                    if (vm.sortable && vm.sortable.indexOf(field) > -1) {
                        return true;
                    } else {
                        return false;
                    }
                }

                function filterRowValue(value, filter) {
                    //The filter can be passed in two ways:
                    if (typeof filter === "string") {
                        // filter: "amTimeAgo" //As a string
                        return $filter(filter)(value);
                    } else {
                        // filter:{ name: "amDateFormat", param: "L"} //As an object
                        return $filter(filter.name)(value, filter.param);
                    }
                }

                function order(predicate, reverse) {
                    vm.content = orderBy(vm.content, predicate, reverse);
                    vm.predicate = predicate;
                }

                if (vm.sortable && vm.sortable[0]) {
                    vm.order(vm.sortable[0], false);
                }

                function _getNumber(num) {
                    var range = new Array(num);
                    for (var i = 0; i < num; i++) {
                        range[i] = i;
                    }
                    return range;
                }

                function goToPage(page) {
                    vm.currentPage = page;
                    calcPaginationStart();
                }

                function setRowsPerPage(rowsPerPage) {
                    vm.rowsPerPage = rowsPerPage;

                    calcPaginationCount();
                    vm.goToPage(
                        Math.max(Math.min(vm.currentPage, nbOfPages() - 1), 0)
                    );
                }
            },
        };
    });
