/**
 * The module definition for the RegistrationCtrl.
 */
import angular from "angular";
import _ from "lodash";

export default function RegistrationCtrl(
    $log,
    $translate,
    $scope,
    $sbAuth,
    $sbPasswordPolicy,
    $sbLocale,
    $state,
    $stateParams,
    AVAILABLE_LANGUAGE_KEYS,
    AVAILABLE_LANGUAGE_CONFIG,
    Analytics,
    $sce
) {
    "ngInject";

    var vm = this;

    vm.AVAILABLE_LANGUAGE_KEYS = AVAILABLE_LANGUAGE_KEYS;
    vm.AVAILABLE_LANGUAGE_CONFIG = AVAILABLE_LANGUAGE_CONFIG;
    vm.AVAILABLE_ORIGINS = {
        general_register: "general_register",
        free_invite_register: "free_invite_register",
        track_register: "track_register",
        paid_invite_register: "paid_invite_register",
    };
    vm.QUESTION_TYPES = {
        analytics_source_data: {
            selection: "analyticsSourceData",
            other: "otherAnalyticsSourceData",
        },
        what_tracking: {
            selection: "whatTracking",
            other: "otherWhatTracking",
        },
        why_tracking: {
            selection: "whyTracking",
            other: "otherWhyTracking",
        },
    };

    vm.jobTitles = [
        { key: "Construction Operative", name: "CONSTRUCTION_OPERATIVE" },
        {
            key: "Sub-Contractor Manager (Black Hat)",
            name: "SUB_CONTRACTOR_MANAGER",
        },
        { key: "Construction Manager", name: "CONSTRUCTION_MANAGER" },
        { key: "Construction Director", name: "CONSTRUCTION_DIRECTOR" },
        {
            key: "Project / Package Manager",
            name: "PROJECT_OR_PACKAGE_MANAGER",
        },
        { key: "Commercial Director", name: "COMMERCIAL_DIRECTOR" },
        { key: "Commercial Manager", name: "COMMERCIAL_MANAGER" },
        { key: "Planner", name: "PLANNER" },
        { key: "Project Director", name: "PROJECT_DIRECTOR" },
        { key: "Board Director", name: "BOARD_DIRECTOR" },
        {
            key: "Central Business / Offsite Management",
            name: "CENTRAL_BUSINESS_OR_OFFSITE_MANAGEMENT",
        },
        { key: "Third party Inspector", name: "THIRD_PARTY_INSPECTOR" },
        { key: "Other", name: "OTHER" },
    ];

    vm.companyTypes = [
        { key: "UKI_General_Contractor", name: "MAIN_CONTRACTOR" },
        { key: "Subcontractor", name: "SUBCONTRACTOR" },
        { key: "POD Supplier", name: "POD_SUPPLIER" },
        { key: "UKI_House Builder", name: "HOUSE_BUILDER" },
        { key: "Architect", name: "ARCHITECT" },
        { key: "UKI_Developer", name: "DEVELOPER" },
        { key: "Housing Association", name: "HOUSING_ASSOCIATION" },
        { key: "Manufacturer", name: "MANUFACTURER" },
        { key: "UKI_Consultant", name: "CONSULTANT" },
    ];

    vm.signupQuestionnaires = [
        {
            signupOrigin: [vm.AVAILABLE_ORIGINS.general_register],
            questions: [
                {
                    type: vm.QUESTION_TYPES.analytics_source_data,
                    question: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1",
                    options: [
                        {
                            key: "Personal invite or referral",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_1",
                        },
                        {
                            key: "Social media",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_2",
                        },
                        {
                            key: "Blog article",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_3",
                        },
                        {
                            key: "Search engine",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_4",
                        },
                        {
                            key: "Event",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_5",
                        },
                        {
                            key: "Other",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_6",
                        },
                    ],
                },
                {
                    type: vm.QUESTION_TYPES.what_tracking,
                    question: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2",
                    options: [
                        {
                            key: "Tracking progress",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_1",
                        },
                        {
                            key: "Quality documentation",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_2",
                        },
                        {
                            key: "Reporting",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_3",
                        },
                        {
                            key: "Commercial Valuation Management",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_4",
                        },
                        {
                            key: "Planning",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_5",
                        },
                        {
                            key: "Daily/Weekly Progress Meetings",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_6",
                        },
                    ],
                },
                {
                    type: vm.QUESTION_TYPES.why_tracking,
                    question: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3",
                    options: [
                        {
                            key: "Replace existing Excel trackers",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_1",
                        },
                        {
                            key: "Simplified trade management",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_2",
                        },
                        {
                            key: "Quality documentation in a central location",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_3",
                        },
                        {
                            key: "Simplified reporting from site",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_4",
                        },
                        {
                            key: "Daily progress meetings",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_5",
                        },
                        {
                            key: "Easier understanding of project status",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_6",
                        },
                        {
                            key: "Look ahead planning sessions",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_7",
                        },
                        {
                            key: "Commercial valuations",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_8",
                        },
                    ],
                },
            ],
        },
        {
            signupOrigin: [
                vm.AVAILABLE_ORIGINS.track_register,
                vm.AVAILABLE_ORIGINS.free_invite_register,
            ],
            questions: [
                {
                    type: vm.QUESTION_TYPES.analytics_source_data,
                    question:
                        "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1",
                    options: [
                        {
                            key: "Personal invite or referral",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_1",
                        },
                        {
                            key: "Social media",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_2",
                        },
                        {
                            key: "Blog article",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_3",
                        },
                        {
                            key: "Search engine",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_4",
                        },
                        {
                            key: "Event",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_5",
                        },
                        {
                            key: "Other",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_6",
                        },
                    ],
                },
                {
                    type: vm.QUESTION_TYPES.what_tracking,
                    question:
                        "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2",
                    options: [
                        {
                            key: "Piling",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_1",
                        },
                        {
                            key: "Fitout",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_2",
                        },
                        {
                            key: "Facades",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_3",
                        },
                        {
                            key: "Prefab Package",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_4",
                        },
                        {
                            key: "Structural work",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_5",
                        },
                        {
                            key: "Complete project",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_6",
                        },
                        {
                            key: "Firestopping",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_7",
                        },
                        {
                            key: "Other",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_8",
                        },
                    ],
                },
                {
                    type: vm.QUESTION_TYPES.why_tracking,
                    question:
                        "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3",
                    options: [
                        {
                            key: "Replace existing Excel trackers",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_1",
                        },
                        {
                            key: "Simplified trade management",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_2",
                        },
                        {
                            key: "Simplified reporting from site",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_3",
                        },
                        {
                            key: "Daily progress meetings",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_4",
                        },
                        {
                            key: "Easier understanding of project status",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_5",
                        },
                        {
                            key: "Commercial Valuation Management",
                            name: "INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_6",
                        },
                    ],
                },
            ],
        },
    ];

    vm.errors = [
        {
            code: "SIGNUP_GENERIC_ERROR",
            title: "SIGNUP_GENERIC_ERROR_TITLE",
            message: "SIGNUP_GENERIC_ERROR_TEXT",
        },
        {
            code: "UsernameExistsException",
            title: "SIGNUP_ERROR_EMAIL_ALREADY_USED_TITLE",
            message: "SIGNUP_ERROR_EMAIL_ALREADY_USED_TEXT",
            button: "SIGNUP_ERROR_EMAIL_ALREADY_USED_BUTTON", // try again with different email,
            buttonCallback: showRegistration,
        },
    ];

    vm.user = {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        phone: "",
        password: "",
        language: $sbLocale.current(),
        hasAcceptedEula: false,
        hasAcceptedDataPrivacy: false,
        hasSignedUp: false,
        signupOrigin: "",
        signupAttempts: 0,
        inProgress: false,
        hasError: false,
        errorIndex: 0,
        jobTitle: "",
        otherJobTitle: "",
        companyType: "",
        marketingConsent: "",
        marketingMaterial: true,
        analyticsSourceData: "",
        otherAnalyticsSourceData: "",
        whatTracking: "",
        otherWhatTracking: "",
        whyTracking: "",
        otherWhyTracking: "",
    };

    vm.register = register;
    vm.tryAgain = tryAgain;
    vm.triggerErrorAction = triggerErrorAction;
    vm.isFreeUser = isFreeUser;
    vm.nextClicked = _nextClicked;
    vm.createHubspotWidgetSrc = createHubspotWidgetSrc;

    vm.urlExtension = $translate.use() === "de" ? "" : "en/";

    // Maximum attempts before suggesting the user to contact support.
    vm.MAX_SIGNUP_ATTEMPTS = 2;

    vm.passwordPolicy = "";
    vm.visibleStepNumber = 0;
    vm.totalStepNumber = 5;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    // Watch change password events and trigger the validation
    $scope.$watch(
        "$ctrl.userRegistrationForm.newPassword.$$rawModelValue",
        _passwordWatcher
    );
    $scope.$on("$destroy", function () {
        _passwordWatcher();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    _activate();

    function _activate() {
        _setSignupOrigin();
        _setMarketingConsent();
        _setRegistrationStepsNumber();
        _setEULAAndDataPrivacyForFreeUsers();
        return _getPasswordPolicy();
    }

    function _setRegistrationStepsNumber() {
        if (
            vm.user.signupOrigin === vm.AVAILABLE_ORIGINS.paid_invite_register
        ) {
            vm.totalStepNumber--;
        }
    }

    function _setMarketingConsent() {
        /**
         * AWS Cognito custom fields only accepts string or number and
         * therefore the marketing consent boolean is mapped to either
         * a "true" or "false" string.
         * (those are also the configured strings in hubspot)
         */
        vm.user.marketingConsent = isFreeUser() ? "true" : "false";
    }

    function _setEULAAndDataPrivacyForFreeUsers() {
        if (isFreeUser()) {
            vm.user.hasAcceptedEula = true;
            vm.user.hasAcceptedDataPrivacy = true;
        }
    }

    function _getPasswordPolicy() {
        return $sbPasswordPolicy.getPolicy().then(_setPolicy);
    }

    function _setPolicy(passwordPolicy) {
        vm.passwordPolicy = passwordPolicy;
    }

    function hasPolicy() {
        return angular.isObject(vm.passwordPolicy);
    }

    function _passwordWatcher(newPassword) {
        if (!angular.isString(newPassword) || newPassword.length === 0) {
            return;
        }

        vm.passwordPatternError = "";

        if (!hasPolicy()) {
            return;
        }

        $sbPasswordPolicy
            .getPasswordErrorTranslated(newPassword, vm.passwordPolicy)
            .then(function (translatedError) {
                vm.passwordPatternError = translatedError.join(", ");
            });
    }

    function _setSignupOrigin() {
        const signupOriginParam = $stateParams.signup_origin;
        if (vm.AVAILABLE_ORIGINS[signupOriginParam]) {
            vm.user.signupOrigin = vm.AVAILABLE_ORIGINS[signupOriginParam];
            Analytics.trackConversion(
                "registration page opened",
                vm.user.signupOrigin
            );
        } else {
            $state.go($state.$current.name, {
                signup_origin: vm.AVAILABLE_ORIGINS.general_register,
            });
        }
    }

    /**
     * Called to submit the form. The user contains the input values from the user.
     * The data is already pre-validated by the forms html.
     *
     * @param {user} user - User information from form input.
     */
    function register(user) {
        var formattedPhone = transformPhoneNumber(user.phone);
        const derivedCompany = deriveCompany(user.email);

        user.company = derivedCompany;
        user.phone = formattedPhone;
        user.signupAttempts += 1;
        user.inProgress = true;

        Analytics.trackConversion("registration clicked", vm.user.signupOrigin);
        $sbAuth
            .signUp(user)
            .then(_showSuccessAndWait)
            .catch(function (error) {
                try {
                    setErrorIndexBy(error.code);
                    _showError();
                } catch (e) {
                    $log.error(e);
                    _showFirstStepErrors();
                }
            })
            .finally(function () {
                user.inProgress = false;
            });
    }

    function setErrorIndexBy(errorCode) {
        vm.user.errorIndex = _.findIndex(vm.errors, function (error) {
            return error.code === errorCode;
        });
        if (!angular.isNumber(vm.user.errorIndex) || vm.user.errorIndex < 0) {
            vm.user.errorIndex = 0;
        }
    }

    function tryAgain(user) {
        user.signupAttempts += 1;
        if (user.signupAttempts > vm.MAX_SIGNUP_ATTEMPTS) {
            user.hasError = true;
        } else {
            register(user);
        }
    }

    function triggerErrorAction(index) {
        if (vm.errors[index].buttonCallback) {
            vm.errors[index].buttonCallback();
        }
    }

    function showRegistration() {
        vm.user.hasError = false;
    }

    function isFreeUser() {
        // according to https://sablono.atlassian.net/browse/SAB-7030 (Brian)
        // It is not wanted that users on the "paid" plan have to consent to marketing
        //  to avoid targeting them with marketing material
        //
        // so, we only show the consent if the user is on a free plan
        return (
            [
                vm.AVAILABLE_ORIGINS.free_invite_register,
                vm.AVAILABLE_ORIGINS.track_register,
            ].indexOf(vm.user.signupOrigin) > -1
        );
    }

    /**
     * Function for error and success handling
     */
    function _showSuccessAndWait() {
        vm.user.hasSignedUp = true;
    }

    function _showFirstStepErrors() {
        vm.user.hasError = true;
        vm.user.errorIndex = 1;
        if (vm.user.signupAttempts >= vm.MAX_SIGNUP_ATTEMPTS) {
            _showGenericError();
        }
    }

    /**
     * Returns weather the string starts with a + sign or not.
     *
     * @param {String} text - Input to test.
     * @return {boolean}
     */
    function isFirstCharPlus(text) {
        return text.charAt(0) === "+";
    }

    /**
     * Replace the first character with two 0s.
     *
     * @param {String} text - Input to change.
     * @return {string} - Returns a new string.
     */
    function replaceFirstCharWith00(text) {
        return "00" + text.slice(1, text.length);
    }

    /**
     * Strips out any non-digit character.
     *
     * @param {String} text - Input to clean from non-digits.
     * @return {*} - Returns a new string.
     */
    function stripOutNonDigits(text) {
        return text.replace(/\D/g, "");
    }

    /**
     * Transforms a phone number input string into a phone number.
     *
     * It does:
     *  - replace leading plus sign (+) with two 0s.
     *  - stripes out all non-digit characters
     *
     * @param {String} phoneNumber - Input phone number
     * @return {String} String representation of a number. Should be parsable with parseInt().
     */
    function transformPhoneNumber(phoneNumber) {
        if (angular.isUndefined(phoneNumber) || phoneNumber.length === 0) {
            return "";
        }

        if (angular.isString(phoneNumber) && isFirstCharPlus(phoneNumber)) {
            phoneNumber = replaceFirstCharWith00(phoneNumber);
        }

        return stripOutNonDigits(phoneNumber);
    }

    function _showError() {
        vm.user.hasError = true;
    }

    function _showGenericError() {
        vm.user.hasError = true;
        vm.user.errorIndex = 0;
    }

    function _nextClicked() {
        vm.visibleStepNumber += 1;
        switch (vm.visibleStepNumber) {
            case 1:
                Analytics.trackConversion(
                    "email provided",
                    vm.user.signupOrigin
                );
                break;
            case 2:
                Analytics.trackConversion("pw filled", vm.user.signupOrigin);
                break;
            case 3:
                Analytics.trackConversion(
                    "personal info provided",
                    vm.user.signupOrigin
                );
                break;
            case 4:
                Analytics.trackConversion(
                    "company info provided",
                    vm.user.signupOrigin
                );
                break;
        }
    }

    function deriveCompany(email) {
        const mailProvider = email.split("@")[1];
        if (!mailProvider) {
            return "";
        }

        const splitProvider = mailProvider.split(".");
        return splitProvider.splice(0, splitProvider.length - 1).join(".");
    }

    function createHubspotWidgetSrc() {
        return $sce.trustAsResourceUrl(
            `https://meetings.hubspot.com/florian-meichsner/track-free-user-signup-booking?embed=true&email=${vm.user.email}&firstname=${vm.user.firstName}&lastname=${vm.user.lastName}`
        );
    }
}
