import angular from "angular";
import htmlTemplate from "./sb_deliverable_note_comments.html";
import _ from "lodash";

export default angular
    .module("sbApp.sbDeliverableNoteComments", [])
    .directive("sbDeliverableNoteComments", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "noteComments",
            templateUrl: htmlTemplate,
            scope: {
                projectId: "<",
                noteId: "<",
            },
            controller: function ($timeout) {
                const vm = this;

                vm.$onChanges = $onChanges;

                function $onChanges() {
                    $timeout(() => {
                        const elId = `noteCommentsTimeline-${vm.noteId}`;
                        const noteCommentsTimelineEl =
                            document.getElementById(elId);

                        if (!noteCommentsTimelineEl) return;

                        noteCommentsTimelineEl.projectId = vm.projectId;
                        noteCommentsTimelineEl.noteId = vm.noteId;
                    }, 0);
                }
            },
        };
    });
