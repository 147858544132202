import ScaleChangedEvent from "../events/ScaleChangedEvent";
import ScaleChangedEndEvent from "../events/ScaleChangedEndEvent";
/**
 * Viewport for the used canvas, contains with itself two container
 * one for scaling and one for drag and drop navigation
 * listens on pan events, on mousewheel events and on pinch events
 * handles all the navigation through the canvas with drag and drop and zooming
 *
 * @param {EditorStage} stage reference to the used canvas stage
 * @constructs Viewport
 * @extends createjs.Container
 * @memberof WBS
 */
function Viewport(stage) {
    createjs.Container.call(this);

    this.scaleContainer = new createjs.Container();

    // save these methods just in case
    //
    this._addChild = this.addChild;
    this._removeChild = this.removeChild;

    // scale container has to be added to this container because it should be displayed on the stage
    //
    this._addChild(this.scaleContainer);

    // when these methods of this container are called, they will be called in the scale container
    //
    this.addChild = this.scaleContainer.addChild.bind(this.scaleContainer);
    this.removeChild = this.scaleContainer.removeChild.bind(
        this.scaleContainer
    );
    this.removeAllChildren = this.scaleContainer.removeAllChildren.bind(
        this.scaleContainer
    );

    /**
     * Current Zoom
     * @type {number}
     */
    this.zoom = 1;

    /**
     * Maximum Zoom Level
     * @constant
     * @default
     * @type {number}
     */
    this.MAXZOOM = 1;

    /**
     * Minimum Zoom Level
     * @constant
     * @default
     * @type {number}
     */
    this.MINZOOM = 0.3;

    /**
     * Current Scale
     * @type {number}
     */
    this.scale = 1;

    this.lastScaleX = 0; //; +  stage.canvas.offsetLeft;
    this.lastScaleY = 0; //; +  stage.canvas.offsetRight;

    // move the lastScale value to the left top corner of the canvas (screen relative)
    var canvasPos = stage.canvas.getBoundingClientRect();
    this.lastScaleX += canvasPos.left;
    this.lastScaleY += canvasPos.top;

    //inital position of viewport so root node is visible
    //
    this.x = 200;
    this.y = 50;

    // every viewport should have his own navigation methods and shouldn't share his with another viewport
    //
    this.onPanStart = this._onPanStart.bind(this);
    this.onPanMove = this._onPanMove.bind(this);
    this.onPanEnd = this._onPanEnd.bind(this);
    this.onPanCancel = this._onPanCancel.bind(this);
    this.onMouseWheel = this._onMouseWheel.bind(this);
    this.pinchStart = this._pinchStart.bind(this);
    this.pinchMove = this._pinchMove.bind(this);

    stage.hammertime.on("panstart", this.onPanStart);
    stage.hammertime.on("panmove", this.onPanMove);
    stage.hammertime.on("panend", this.onPanEnd);
    stage.hammertime.on("pancancel", this.onPanCancel);

    stage.canvas.addEventListener("mousewheel", this.onMouseWheel);
    stage.canvas.addEventListener("DOMMouseScroll", this.onMouseWheel);

    stage.hammertime.on("pinchstart", this.pinchStart);
    stage.hammertime.on("pinchmove", this.pinchMove);

    this.delayedEvent = {};
    this.instantEvent = {};
}

/**
 * Setup prototypal inheritance.
 * Viewport inherits from createjs.Container.
 *
 * @type {createjs.Container}
 */
Viewport.prototype = Object.create(createjs.Container.prototype);

/**
 * Added for completeness, not used yet
 *
 * @param {Event} event contains information of the triggered event
 * @private
 */
Viewport.prototype._onPanStart = function (event) {
    this.lastPosition = this.stage.getPointerPosition(event.changedPointers[0]);
};

/**
 * Drag and Drop handler, handles touch and mouse event.
 * Calculates the dragged way and sets the new position for the viewport
 *
 * @param {Event} event contains information of the triggered event
 * @private
 */
Viewport.prototype._onPanMove = function (event) {
    var pos = this.stage.getPointerPosition(event.changedPointers[0]),
        lastPos = this.lastPosition;

    var diff = {
        x: pos.x - lastPos.x,
        y: pos.y - lastPos.y,
    };

    this.lastPosition = pos;

    this.x += diff.x;
    this.y += diff.y;

    this.stage.setNeedsDisplay();
};

/**
 * Added for completeness, not used yet
 *
 * @private
 */
Viewport.prototype._onPanEnd = function () {};

/**
 * Added for completeness, not used yet
 *
 * @private
 */
Viewport.prototype._onPanCancel = function () {};

/**
 * Mouse wheel handler, calculates the mouse position and the zoom direction.
 * Calls the scaling method afterwards
 *
 * @param {Event} event contains information of the triggered event
 * @private
 */
Viewport.prototype._onMouseWheel = function (event) {
    event.preventDefault();
    var delta = event.wheelDelta || event.detail,
        mouseX = event.clientX - this.stage.canvas.offsetLeft - this.x,
        mouseY = event.clientY - this.stage.canvas.offsetTop - this.y,
        zoom = delta / Math.abs(delta);

    this._scaling(mouseX, mouseY, zoom);
};

/**
 * Added for completeness, not used yet
 *
 * @param {Event} event  contains information of the triggered event
 * @private
 */
Viewport.prototype._pinchStart = function (event) {
    this.lastScale = event.scale;
};

/**
 * Zoom handler for touch (multitouch)
 *
 * @param {Event} event contains information of the triggered event
 * @private
 */
Viewport.prototype._pinchMove = function (event) {
    event.preventDefault();

    var firstPointer = event.pointers[0],
        secondPointer = event.pointers[1];

    var mouseX =
            (firstPointer.clientX + secondPointer.clientX) / 2 -
            this.stage.canvas.offsetLeft -
            this.x,
        mouseY =
            (firstPointer.clientY + secondPointer.clientY) / 2 -
            this.stage.canvas.offsetTop -
            this.y,
        zoom = event.scale < 1 ? -1 : 1,
        scale = 0;

    if (zoom === 1) {
        scale = event.scale / 10 + 1;
    } else {
        scale = event.scale * event.scale;
    }

    this._scaling(mouseX, mouseY, zoom, scale);
};

/**
 * calculates the scaling from the given mouse position and zoom
 *
 * @param {number} mouseX mouse x position
 * @param {number} mouseY mouse y position
 * @param {number} zoom  positive for zoom in and negative for zoom out
 * @param {number} [scale] the scaling will be calculated or the given scaling will be used
 * @private
 */
Viewport.prototype._scaling = function (mouseX, mouseY, zoom, scale) {
    this.stage.setNeedsDisplay();

    var oldScale = this.scale,
        scaleFactor = 0.1 * zoom;

    // shouldn't zoom more in like max zoom or zoom more out like min zoom
    //
    if (zoom === 1 && this.MAXZOOM <= this.scaleContainer.scaleX) {
        this.scale =
            this.scaleContainer.scaleX =
            this.scaleContainer.scaleY =
                this.MAXZOOM;
        this.sendScaleEvent(oldScale);

        return;
    } else if (zoom === -1 && this.MINZOOM >= this.scaleContainer.scaleX) {
        this.scale =
            this.scaleContainer.scaleX =
            this.scaleContainer.scaleY =
                this.MINZOOM;
        this.sendScaleEvent(oldScale);

        return;
    }

    // adjust the scale factor
    //
    this.zoom += scaleFactor;

    if (!scale) {
        if (zoom === 1) {
            scale = 1.1;
        } else {
            scale = 1 / 1.1;
        }
    }

    // prepare the scale factor
    //
    var newScale = this.scale * scale;

    //check min max bounds
    //
    newScale = Math.max(Math.min(newScale, this.MAXZOOM), this.MINZOOM);
    this.scale =
        this.scaleContainer.scaleX =
        this.scaleContainer.scaleY =
            newScale;
    this.sendScaleEvent(oldScale);

    // move the reg point to the mouse position -> zoom center.
    //
    var scaleCursorMoveX = mouseX - this.lastScaleX;
    var scaleCursorMoveY = mouseY - this.lastScaleY;

    this.scaleContainer.x += scaleCursorMoveX;
    this.scaleContainer.regX += scaleCursorMoveX;
    this.scaleContainer.y += scaleCursorMoveY;
    this.scaleContainer.regY += scaleCursorMoveY;

    this.lastScaleX += scaleCursorMoveX;
    this.lastScaleY += scaleCursorMoveY;

    this.scaleContainer.children.forEach(function (e) {
        e.x += scaleCursorMoveX * -(1 / this.scale - 1);
        e.y += scaleCursorMoveY * -(1 / this.scale - 1);
    }, this);
};

/**
 * dispatches a scale event and a delayed event when the scale is finished
 *
 * @param {number} oldScale - Previous Scale
 */
Viewport.prototype.sendScaleEvent = function (oldScale) {
    //if scale didn't changed don't send an event
    //
    if (this.scale === oldScale) {
        return;
    }

    var event = new ScaleChangedEvent(this.scale);
    this.stage.dispatchEvent(event);

    clearTimeout(this.delayedEvent);
    this.delayedEvent = setTimeout(
        function () {
            var scaleEvent = new ScaleChangedEndEvent(this.scale);
            this.stage.dispatchEvent(scaleEvent);
        }.bind(this),
        200
    );
};

export default Viewport;
