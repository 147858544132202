export default class SnapshotsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the snapshot by date.
     *
     * @param projectId {string}    UUID
     * @param snapshotDate {string}    The unique identifier of the snapshotDa you are requesting a resource for.
     */
    get(projectId, snapshotDate) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/snapshots/${snapshotDate}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a new snapshot in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.created_for {string}    A date string in the format YYYY-MM-DD
     */
    create(projectId, { created_for }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/snapshots`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { created_for },
        });
    }
}
