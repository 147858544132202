export default class EulaWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the user-eula compliance.
     *
     */
    get() {
        const endpointUrl = `/api/${this.API_VERSION}/api/eula-standing`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint signs the eula.
     *
     * @param activityId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param [body.eula_accept_key] {string}    A unique string that represents the user name and an EULA version to be accepted.
     */
    accept({ eula_accept_key }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/eula-standing`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { eula_accept_key },
        });
    }
}
