import moment from "moment";
import _ from "lodash";

/**
 *
 * @param {number} start - in milliseconds
 * @param {number} end - in milliseconds
 *
 * @constructor
 */
function LeanboardTimeframe(start, end) {
    this.start = moment(start);
    this.end = moment(end);
    this.nonWorkingDays = [];
    this.exceptionDates = [];

    return this;
}

LeanboardTimeframe.COMPARE_DATE_FORMAT = "MM-DD-YYYY";

/**
 * Add non working days array to the leanboard.
 * @param {Array} nonWorkingDays
 * @param {Array<moment>} exceptionDates
 * @returns {LeanboardTimeframe}
 */
LeanboardTimeframe.prototype.setNonWorkingDays = function (
    nonWorkingDays,
    exceptionDates
) {
    this.nonWorkingDays = nonWorkingDays;
    this.exceptionDates = exceptionDates.map(function (date) {
        return date.format(LeanboardTimeframe.COMPARE_DATE_FORMAT);
    });
    return this;
};

LeanboardTimeframe.prototype.getWeek = function () {
    return moment(this.start).format("W");
};

/**
 * Start of timeframe as moment
 * @returns {moment}
 */
LeanboardTimeframe.prototype.getStartAsMoment = function () {
    return this.start;
};

/**
 * End of timeframe as moment
 * @returns {moment}
 */
LeanboardTimeframe.prototype.getEndAsMoment = function () {
    return this.end;
};

/**
 * Start of timeframe as number
 * @returns {number}
 */
LeanboardTimeframe.prototype.getStartInMilliseconds = function () {
    return this.start.valueOf();
};

/**
 * End of timeframe as number
 * @returns {number}
 */
LeanboardTimeframe.prototype.getEndInMilliseconds = function () {
    return this.end.valueOf();
};

/**
 * Start of timeframe as Date
 * @returns {number}
 */
LeanboardTimeframe.prototype.getStartAsDate = function () {
    return this.start.toDate();
};

/**
 * End of timeframe as Date
 * @returns {number}
 */
LeanboardTimeframe.prototype.getEndAsDate = function () {
    return this.end.toDate();
};

LeanboardTimeframe.prototype.isNonWorkingDay = function (dayIndex) {
    return this.isExceptionDay(dayIndex) || this.isWeekend(dayIndex);
};

LeanboardTimeframe.prototype.isNonWorkingWeek = function (dayIndex) {
    return this.isExceptionWeek(dayIndex);
};

LeanboardTimeframe.prototype.isExceptionDay = function (dayIndex) {
    // check if it is an exception date
    var verificationDay = this.start
        .clone()
        .add(dayIndex, "days")
        .format(LeanboardTimeframe.COMPARE_DATE_FORMAT);
    return this.exceptionDates.some(function (date) {
        return date === verificationDay;
    });
};

LeanboardTimeframe.prototype.isExceptionWeek = function (columnIndex) {
    // check if it is an exception date
    var verificationDay = this.start.clone().add(columnIndex, "week");

    const startOfWeek = verificationDay.clone().startOf("week");
    const endOfWeek = verificationDay.clone().endOf("week");

    const verificationWeek = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
        verificationWeek.push(day);
        day = day.clone().add(1, "d");
    }

    const formattedVerificationWeek = verificationWeek.map((day) =>
        day.format(LeanboardTimeframe.COMPARE_DATE_FORMAT)
    );
    const result = formattedVerificationWeek.reduce(
        (isExceptionWeek, formattedVerificationDay) => {
            if (!isExceptionWeek) {
                return false;
            }

            const isExceptionDay =
                _.indexOf(this.exceptionDates, formattedVerificationDay) !== -1;

            const isWeekend =
                _.indexOf(
                    this.nonWorkingDays,
                    moment(formattedVerificationDay, "DD-MM-YYYY").isoWeekday()
                ) !== -1;

            return isExceptionDay || isWeekend;
        },
        true
    );

    return result;
};

LeanboardTimeframe.prototype.isWeekend = function (dayIndex) {
    // offset of the timeframe, -1 to match 0-based non working days
    var offset = this.start.isoWeekday() - 1;

    return this.nonWorkingDays.some(function (nonWorkingIndex) {
        // day Index 0-based equals any non working day
        return nonWorkingIndex === ((dayIndex + offset) % 7) + 1;
    });
};

LeanboardTimeframe.prototype.getDays = function () {
    // calculate the days of the timeframe by comparing `to` and `from`
    return this.end.diff(this.start, "days", false) + 1;
};

LeanboardTimeframe.prototype.getWeeks = function () {
    // calculate the days of the timeframe by comparing `to` and `from`
    return this.end.diff(this.start, "weeks", false) + 1;
};

LeanboardTimeframe.prototype.endInDays = function (days) {
    // add number of `days` to `start`
    var endMoment = this.start
        .clone()
        .add(days - 1, "days")
        .endOf("day");

    // assign `end` the new end date
    this.end = endMoment;
};

LeanboardTimeframe.prototype.isToday = function (dayIndex) {
    var day = this.start.clone().add(dayIndex, "days");

    return day.isSame(moment(), "days");
};

LeanboardTimeframe.prototype.isThisWeek = function (dayIndex) {
    const columnWeekNumber = this.start.clone().add(dayIndex, "weeks").week();
    const currentWeekNumber = moment().week();
    return columnWeekNumber === currentWeekNumber;
};

export default LeanboardTimeframe;
