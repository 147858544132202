import inspectRequestTemplateHtml from "./inspect_request.html";

export default function ($stateProvider) {
    "ngInject";

    $stateProvider.state("requested_inspection", {
        templateUrl: inspectRequestTemplateHtml,
        controller: "inspectRequestCtrl",
        controllerAs: "inspectRequest",
        url: "/requested/inspection/{tokenId}",
        data: {
            title: "SECTION_REQUEST_FOR_INFORMATION_TITLE",
        },
    });
}
