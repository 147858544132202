import angular from "angular";
import htmlTemplate from "./sbToolbarNavigator.html";
/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbToolbarNavigator", [])
    .directive("sbToolbarNavigator", function () {
        return {
            /**
             * String of subset of EACM which restricts the directive to a specific directive declaration style.
             * If omitted, the defaults (elements and attributes) are used.
             *
             * @example <caption>Element Name</caption>
             * E - Element name (default): <my-directive></my-directive>
             * A - Attribute (default): <div my-directive="exp"></div>
             * C - Class: <div class="my-directive: exp;"></div>
             * M - Comment: <!-- directive: my-directive exp -->
             */
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            //replace: true,

            //transclude: true,

            //scope: {},

            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

            //compile : function(tElement, tAttrs) {},

            //link : function(scope, iElement, iAttrs, controller) {},

            controllerAs: "$ctrl",
            controller: function (
                $rootScope,
                $sbCurrentProject,
                SABLONO_STATES
            ) {
                "ngInject";

                const vm = this;

                vm.project = $sbCurrentProject.get();

                $rootScope.$on(
                    "$stateChangeSuccess",
                    function ($event, newState) {
                        if (SABLONO_STATES.isProjectState(newState.name)) {
                            vm.project = $sbCurrentProject.get();
                        } else {
                            vm.project = null;
                        }
                    }
                );
            },
        };
    });
