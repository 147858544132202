import _ from "lodash";
import moment from "moment";

export default function (
    ACTIVITY_TRANSITIONS,
    SbActivity,
    SbBaseComponent,
    SbActivityGroup,
    $state,
    $sbPermission,
    $sbTracking,
    $sbTeam,
    $sbCustomerSuccessRequests,
    $sbDeliverables,
    loadingToast,
    $sbOverwriteUserDefinedDates,
    $mdToast
) {
    "ngInject";

    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        isLeafActivity: isLeafActivity,
        isUserDateViolated: isUserDateViolated,
        generateStateChangeHandler,
        isStateChangeAllowed,
        hasAccessRestrictions: hasAccessRestrictions,

        assignTeamToWorkflow: assignTeamToWorkflow,
        removeActivitiesByCondition: removeActivitiesByCondition,
        generateIsInaccessibleByTeamMethod: generateIsInaccessibleByTeamMethod,
        onDeleteUserDefinedDateOf: onDeleteUserDefinedDateOf,
        getParentCount: getParentCount,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function assignTeamToWorkflow(components, teams) {
        function assignTeams(activity) {
            const responsibleTeamId = _.get(activity, "assignedTeam.id");
            if (Number.isInteger(responsibleTeamId)) {
                const responsibleTeam = _.find(teams, [
                    "id",
                    responsibleTeamId,
                ]);
                activity.setResponsibleTeam(responsibleTeam);
            }

            if (
                Array.isArray(activity.reviewTeams) &&
                activity.reviewTeams.length > 0
            ) {
                const reviewTeams = _.intersectionBy(
                    teams,
                    activity.reviewTeams,
                    "id"
                );
                activity.setReviewTeams(reviewTeams || []);
            }

            const confirmationTeamId = _.get(activity, "confirmationTeam.id");
            if (Number.isInteger(confirmationTeamId)) {
                const confirmationTeam = _.find(teams, [
                    "id",
                    confirmationTeamId,
                ]);
                activity.setConfirmingTeam(confirmationTeam);
            }
        }

        _.forEach(components, function (component) {
            if (component instanceof SbActivityGroup) {
                component.getActivities().forEach(assignTeams);
            }

            if (component instanceof SbActivity) {
                assignTeams(component);
            }
        });

        return components;
    }

    /**
     * Mutate the given array of components and remove activities that do not match the given
     * filter. Groups that will be empty after filtering will be removed as well.
     *
     * @param {Array<SbBaseComponent>} components
     * @param {function} activityFilter
     */
    function removeActivitiesByCondition(components, activityFilter) {
        if (!_.isArray(components)) {
            return;
        }

        _.remove(components, activityFilter);
        components.forEach(_applyFilterToChildComponents(activityFilter));
        _.remove(components, _isEmptyGroup);

        return components;
    }

    /**
     * Generate a function that is filtering the children of a given component. Will
     * lead to a recursive filtering of all children.
     *
     * @param {function} activityFilter
     * @return {Function}
     * @private
     */
    function _applyFilterToChildComponents(activityFilter) {
        return function (component) {
            if (component instanceof SbActivityGroup) {
                removeActivitiesByCondition(
                    component.getChildren(),
                    activityFilter
                );
            }
        };
    }

    /**
     * Create a function to check if a SbActivity is accessible by the given team.
     *
     * @param {SbTeam} filterByTeam
     * @return {function(*): boolean}
     */
    function generateIsInaccessibleByTeamMethod(filterByTeam) {
        return function (activity) {
            return (
                activity instanceof SbActivity &&
                !activity.isAccessibleByTeam(filterByTeam)
            );
        };
    }

    /**
     * Check if its a group without activities
     *
     * @param {SbBaseComponent} component
     * @return {*}
     * @private
     */
    function _isEmptyGroup(component) {
        if (component instanceof SbActivityGroup) {
            return _.isEmpty(component.getActivities());
        }
        return false;
    }

    function isUserDateViolated(node) {
        if (
            moment.isMoment(node.earliestStart) &&
            moment.isMoment(node.userDefinedStart)
        ) {
            return !node.earliestStart.isSame(node.userDefinedStart, "minute");
        }
        return false;
    }

    function isLeafActivity(node) {
        return !node.children || node.children.length === 0;
    }

    function generateStateChangeHandler(
        onCreateNote,
        onStateChange,
        onFillChecklist,
        onRequestActivityUpdate,
        onResetActivity
    ) {
        return function changeState(component, fromState, toState) {
            // handle report a note case
            //
            if (toState === ACTIVITY_TRANSITIONS.COULD_NOT_START) {
                if (angular.isFunction(onCreateNote)) {
                    $sbTracking.note.creation.start("Details Overlay");
                    return onCreateNote({
                        component,
                    });
                }
            }

            if (toState === "REQUEST_ACTIVITY_UPDATE") {
                return onRequestActivityUpdate({ component });
            }

            // handle CS case
            //
            if (toState === "RESET_ACTIVITY") {
                return onResetActivity({ component });
            }

            if (toState === "CS_REVERT_CONFIRMATION") {
                return $sbCustomerSuccessRequests.revertConfirmation(
                    component.id,
                    component.name
                );
            }

            if (component.isQAWorkflowRequired()) {
                // track location of state change
                //
                if (_.has($state, "current.gaPageTrack")) {
                    $sbTracking.detailsOverlay.checklists.fill(
                        $state.current.gaPageTrack
                    );
                }

                // handle state change
                //
                return onFillChecklist({
                    component,
                    fromState,
                    toState,
                });
            } else {
                if (fromState === toState) {
                    return;
                }

                // track location of state change
                //
                if (_.has($state, "current.gaPageTrack")) {
                    $sbTracking.progress.change.done(
                        "Details Overlay",
                        $state.current.gaPageTrack
                    );
                }

                // handle state change
                //
                return onStateChange({
                    component,
                    fromState,
                    toState,
                });
            }
        };
    }

    function hasAccessRestrictions(component) {
        // not an activity
        if (!(component instanceof SbActivity)) {
            return false;
        }

        // read only access on the activity
        if (!component.isReadWrite()) {
            return true;
        }

        // work permission (yes/no) ?
        return !$sbPermission.hasPermission(
            component.getAccessRoleMask(),
            "WORK"
        );
    }

    function isStateChangeAllowed(component) {
        // not an activity
        if (!(component instanceof SbActivity)) {
            return false;
        }

        // read only access on the activity
        if (!component.isReadWrite()) {
            return false;
        }

        // work permission (yes/no) ?
        return $sbPermission.hasPermission(
            component.getAccessRoleMask(),
            "WORK"
        );
    }

    function onDeleteUserDefinedDateOf(node, deliverable) {
        loadingToast.show("INFO_DELETING_ALL_USER_DEFINED_DATES_MESSAGE");
        return $sbOverwriteUserDefinedDates
            .forActivityBy({
                deliverableId: deliverable.id,
                activityTemplateId: node.piTemplateId,
            })
            .then(function () {
                $mdToast.show(
                    $mdToast
                        .simple()
                        .content("TOAST_DELETE_CUSTOM_DATES")
                        .hideDelay(3000)
                        .position("top right")
                );
            })
            .then(function () {
                $sbDeliverables.emitChangeEvent(
                    $sbDeliverables.DELIVERABLE_CHANGE_TYPES.BASELINE,
                    deliverable
                );
            })
            .finally(function () {
                loadingToast.hide();
            });
    }

    function getParentCount(node) {
        var count = -1;
        var parentNode = node.getParent();

        while (parentNode) {
            count++;
            parentNode = parentNode.getParent();
        }

        return count;
    }
}
