export default function CommercialValuationController(
    $timeout,
    $filter,
    $sbTemplate
) {
    "ngInject";

    $onInit();

    function $onInit() {
        var naturalSort = $filter("naturalSort");

        return $sbTemplate.getTemplates().then((templates) => {
            const sortedProcessTemplates = naturalSort(
                templates.map((template) => {
                    return {
                        name: template.NAME,
                        id: template.ID,
                    };
                }),
                "label"
            );

            $timeout(() => {
                const el = document.getElementById("commercialValuation");
                el.projectProcessTemplates = sortedProcessTemplates;
            }, 0);
        });
    }
}
