import createjs from "createjs";

/**
 * Event that is dispatched when scale is changing
 * @param {number} scale - New Scale
 * @constructs ScaleChangedEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function ScaleChangedEvent(scale) {
    createjs.Event.call(this, "scaleChanged", false, false);
    this.scale = scale;
}

ScaleChangedEvent.prototype = Object.create(createjs.Event.prototype);

export default ScaleChangedEvent;
