export default function ($translate, LANGUAGES) {
    "ngInject";
    var functions = {
        getZones: getZones,
        getZonesWithCity: getZonesWithCity,
        getZoneFromTimezone: getZoneFromTimezone,
        getDefaultTimezone: getDefaultTimezone,
    };

    var zones;

    /**
     * getZones - Returns an array containing all the zones
     *
     * @return {Array}  An array containing all the zones
     */
    function getZones() {
        return zones;
    }

    /**
     * getZonesWithCity - Return an array containing all the zones and the cities (used to display more human readable format)
     *
     * @return {Array}  An array containing all the zones
     */
    function getZonesWithCity() {
        return zones
            .map(function (zone) {
                var nameArray = zone.name.split("/");
                zone.city = nameArray[nameArray.length - 1].replace("_", " ");
                return zone;
            })
            .sort(function (a, b) {
                if (a.country > b.country) {
                    return 1;
                } else if (a.country < b.country) {
                    return -1;
                } else {
                    return 0;
                }
            });
    }

    /**
     * getDefaultTimezone - Returns the current timezone based on the language passed or the default one;
     * @param language - The language of the project
     *
     * @return {Object}  - The default timezone object
     */
    function getDefaultTimezone(language) {
        var currentLang = (language || $translate.use()).toUpperCase();

        var timezone = LANGUAGES.filter(function (country) {
            return country.abbreviation === currentLang;
        })[0].timezone;

        return getZoneFromTimezone(timezone);
    }

    /**
     * getZoneFromTimezone - Returns the object containing that timezone
     *
     * @param  {String} timezone - The timezone to search
     * @return {Object}
     */
    function getZoneFromTimezone(timezone) {
        return getZonesWithCity().filter(function (zone) {
            return zone.name === timezone;
        })[0];
    }

    zones = [
        {
            name: "Europe/Andorra",
            country: "Andorra",
        },
        {
            name: "Asia/Dubai",
            country: "United Arab Emirates",
        },
        {
            name: "Asia/Kabul",
            country: "Afghanistan",
        },
        {
            name: "America/Antigua",
            country: "Antigua And Barbuda",
        },
        {
            name: "America/Anguilla",
            country: "Anguilla",
        },
        {
            name: "Europe/Tirane",
            country: "Albania",
        },
        {
            name: "Asia/Yerevan",
            country: "Armenia",
        },
        {
            name: "Africa/Luanda",
            country: "Angola",
        },
        {
            name: "Antarctica/McMurdo",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Rothera",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Palmer",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Mawson",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Davis",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Casey",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Vostok",
            country: "Antarctica",
        },
        {
            name: "Antarctica/DumontDUrville",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Syowa",
            country: "Antarctica",
        },
        {
            name: "Antarctica/Troll",
            country: "Antarctica",
        },
        {
            name: "America/Argentina/Buenos_Aires",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Cordoba",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Salta",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Jujuy",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Tucuman",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Catamarca",
            country: "Argentina",
        },
        {
            name: "America/Argentina/La_Rioja",
            country: "Argentina",
        },
        {
            name: "America/Argentina/San_Juan",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Mendoza",
            country: "Argentina",
        },
        {
            name: "America/Argentina/San_Luis",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Rio_Gallegos",
            country: "Argentina",
        },
        {
            name: "America/Argentina/Ushuaia",
            country: "Argentina",
        },
        {
            name: "Pacific/Pago_Pago",
            country: "American Samoa",
        },
        {
            name: "Europe/Vienna",
            country: "Austria",
        },
        {
            name: "Australia/Lord_Howe",
            country: "Australia",
        },
        {
            name: "Antarctica/Macquarie",
            country: "Australia",
        },
        {
            name: "Australia/Hobart",
            country: "Australia",
        },
        {
            name: "Australia/Currie",
            country: "Australia",
        },
        {
            name: "Australia/Melbourne",
            country: "Australia",
        },
        {
            name: "Australia/Sydney",
            country: "Australia",
        },
        {
            name: "Australia/Broken_Hill",
            country: "Australia",
        },
        {
            name: "Australia/Brisbane",
            country: "Australia",
        },
        {
            name: "Australia/Lindeman",
            country: "Australia",
        },
        {
            name: "Australia/Adelaide",
            country: "Australia",
        },
        {
            name: "Australia/Darwin",
            country: "Australia",
        },
        {
            name: "Australia/Perth",
            country: "Australia",
        },
        {
            name: "Australia/Eucla",
            country: "Australia",
        },
        {
            name: "America/Aruba",
            country: "Aruba",
        },
        {
            name: "Europe/Mariehamn",
            country: "Aland Islands",
        },
        {
            name: "Asia/Baku",
            country: "Azerbaijan",
        },
        {
            name: "Europe/Sarajevo",
            country: "Bosnia And Herzegovina",
        },
        {
            name: "America/Barbados",
            country: "Barbados",
        },
        {
            name: "Asia/Dhaka",
            country: "Bangladesh",
        },
        {
            name: "Europe/Brussels",
            country: "Belgium",
        },
        {
            name: "Africa/Ouagadougou",
            country: "Burkina Faso",
        },
        {
            name: "Europe/Sofia",
            country: "Bulgaria",
        },
        {
            name: "Asia/Bahrain",
            country: "Bahrain",
        },
        {
            name: "Africa/Bujumbura",
            country: "Burundi",
        },
        {
            name: "Africa/Porto-Novo",
            country: "Benin",
        },
        {
            name: "America/St_Barthelemy",
            country: "Saint Barthelemy",
        },
        {
            name: "Atlantic/Bermuda",
            country: "Bermuda",
        },
        {
            name: "Asia/Brunei",
            country: "Brunei Darussalam",
        },
        {
            name: "America/La_Paz",
            country: "Bolivia",
        },
        {
            name: "America/Kralendijk",
            country: "Bonaire",
        },
        {
            name: "America/Noronha",
            country: "Brazil",
        },
        {
            name: "America/Belem",
            country: "Brazil",
        },
        {
            name: "America/Fortaleza",
            country: "Brazil",
        },
        {
            name: "America/Recife",
            country: "Brazil",
        },
        {
            name: "America/Araguaina",
            country: "Brazil",
        },
        {
            name: "America/Maceio",
            country: "Brazil",
        },
        {
            name: "America/Bahia",
            country: "Brazil",
        },
        {
            name: "America/Sao_Paulo",
            country: "Brazil",
        },
        {
            name: "America/Campo_Grande",
            country: "Brazil",
        },
        {
            name: "America/Cuiaba",
            country: "Brazil",
        },
        {
            name: "America/Santarem",
            country: "Brazil",
        },
        {
            name: "America/Porto_Velho",
            country: "Brazil",
        },
        {
            name: "America/Boa_Vista",
            country: "Brazil",
        },
        {
            name: "America/Manaus",
            country: "Brazil",
        },
        {
            name: "America/Eirunepe",
            country: "Brazil",
        },
        {
            name: "America/Rio_Branco",
            country: "Brazil",
        },
        {
            name: "America/Nassau",
            country: "Bahamas",
        },
        {
            name: "Asia/Thimphu",
            country: "Bhutan",
        },
        {
            name: "Africa/Gaborone",
            country: "Botswana",
        },
        {
            name: "Europe/Minsk",
            country: "Belarus",
        },
        {
            name: "America/Belize",
            country: "Belize",
        },
        {
            name: "America/St_Johns",
            country: "Canada",
        },
        {
            name: "America/Halifax",
            country: "Canada",
        },
        {
            name: "America/Glace_Bay",
            country: "Canada",
        },
        {
            name: "America/Moncton",
            country: "Canada",
        },
        {
            name: "America/Goose_Bay",
            country: "Canada",
        },
        {
            name: "America/Blanc-Sablon",
            country: "Canada",
        },
        {
            name: "America/Toronto",
            country: "Canada",
        },
        {
            name: "America/Nipigon",
            country: "Canada",
        },
        {
            name: "America/Thunder_Bay",
            country: "Canada",
        },
        {
            name: "America/Iqaluit",
            country: "Canada",
        },
        {
            name: "America/Pangnirtung",
            country: "Canada",
        },
        {
            name: "America/Resolute",
            country: "Canada",
        },
        {
            name: "America/Atikokan",
            country: "Canada",
        },
        {
            name: "America/Rankin_Inlet",
            country: "Canada",
        },
        {
            name: "America/Winnipeg",
            country: "Canada",
        },
        {
            name: "America/Rainy_River",
            country: "Canada",
        },
        {
            name: "America/Regina",
            country: "Canada",
        },
        {
            name: "America/Swift_Current",
            country: "Canada",
        },
        {
            name: "America/Edmonton",
            country: "Canada",
        },
        {
            name: "America/Cambridge_Bay",
            country: "Canada",
        },
        {
            name: "America/Yellowknife",
            country: "Canada",
        },
        {
            name: "America/Inuvik",
            country: "Canada",
        },
        {
            name: "America/Creston",
            country: "Canada",
        },
        {
            name: "America/Dawson_Creek",
            country: "Canada",
        },
        {
            name: "America/Vancouver",
            country: "Canada",
        },
        {
            name: "America/Whitehorse",
            country: "Canada",
        },
        {
            name: "America/Dawson",
            country: "Canada",
        },
        {
            name: "Indian/Cocos",
            country: "Cocos (Keeling) Islands",
        },
        {
            name: "Africa/Kinshasa",
            country: "Congo (Democratic Republic)",
        },
        {
            name: "Africa/Lubumbashi",
            country: "Congo (Democratic Republic)",
        },
        {
            name: "Africa/Bangui",
            country: "Central African Republic",
        },
        {
            name: "Africa/Brazzaville",
            country: "Congo",
        },
        {
            name: "Europe/Zurich",
            country: "Switzerland",
        },
        {
            name: "Africa/Abidjan",
            country: "Cote D'Ivoire",
        },
        {
            name: "Pacific/Rarotonga",
            country: "Cook Islands",
        },
        {
            name: "America/Santiago",
            country: "Chile",
        },
        {
            name: "Pacific/Easter",
            country: "Chile",
        },
        {
            name: "Africa/Douala",
            country: "Cameroon",
        },
        {
            name: "Asia/Shanghai",
            country: "China",
        },
        {
            name: "Asia/Harbin",
            country: "China",
        },
        {
            name: "Asia/Chongqing",
            country: "China",
        },
        {
            name: "Asia/Urumqi",
            country: "China",
        },
        {
            name: "Asia/Kashgar",
            country: "China",
        },
        {
            name: "America/Bogota",
            country: "Colombia",
        },
        {
            name: "America/Costa_Rica",
            country: "Costa Rica",
        },
        {
            name: "America/Havana",
            country: "Cuba",
        },
        {
            name: "Atlantic/Cape_Verde",
            country: "Cape Verde",
        },
        {
            name: "America/Curacao",
            country: "Curacao",
        },
        {
            name: "Indian/Christmas",
            country: "Christmas Island",
        },
        {
            name: "Asia/Nicosia",
            country: "Cyprus",
        },
        {
            name: "Europe/Prague",
            country: "Czech Republic",
        },
        {
            name: "Europe/Berlin",
            country: "Germany",
        },
        {
            name: "Europe/Busingen",
            country: "Germany",
        },
        {
            name: "Africa/Djibouti",
            country: "Djibouti",
        },
        {
            name: "Europe/Copenhagen",
            country: "Denmark",
        },
        {
            name: "America/Dominica",
            country: "Dominica",
        },
        {
            name: "America/Santo_Domingo",
            country: "Dominican Republic",
        },
        {
            name: "Africa/Algiers",
            country: "Algeria",
        },
        {
            name: "America/Guayaquil",
            country: "Ecuador",
        },
        {
            name: "Pacific/Galapagos",
            country: "Ecuador",
        },
        {
            name: "Europe/Tallinn",
            country: "Estonia",
        },
        {
            name: "Africa/Cairo",
            country: "Egypt",
        },
        {
            name: "Africa/El_Aaiun",
            country: "Western Sahara",
        },
        {
            name: "Africa/Asmara",
            country: "Eritrea",
        },
        {
            name: "Europe/Madrid",
            country: "Spain",
        },
        {
            name: "Africa/Ceuta",
            country: "Spain",
        },
        {
            name: "Atlantic/Canary",
            country: "Spain",
        },
        {
            name: "Africa/Addis_Ababa",
            country: "Ethiopia",
        },
        {
            name: "Europe/Helsinki",
            country: "Finland",
        },
        {
            name: "Pacific/Fiji",
            country: "Fiji",
        },
        {
            name: "Atlantic/Stanley",
            country: "Falkland Islands (Malvinas)",
        },
        {
            name: "Pacific/Chuuk",
            country: "Micronesia (Federated States Of)",
        },
        {
            name: "Pacific/Pohnpei",
            country: "Micronesia (Federated States Of)",
        },
        {
            name: "Pacific/Kosrae",
            country: "Micronesia (Federated States Of)",
        },
        {
            name: "Atlantic/Faroe",
            country: "Faroe Islands",
        },
        {
            name: "Europe/Paris",
            country: "France",
        },
        {
            name: "Africa/Libreville",
            country: "Gabon",
        },
        {
            name: "Europe/London",
            country: "United Kingdom",
        },
        {
            name: "America/Grenada",
            country: "Grenada",
        },
        {
            name: "Asia/Tbilisi",
            country: "Georgia",
        },
        {
            name: "America/Cayenne",
            country: "French Guiana",
        },
        {
            name: "Europe/Guernsey",
            country: "Guernsey",
        },
        {
            name: "Africa/Accra",
            country: "Ghana",
        },
        {
            name: "Europe/Gibraltar",
            country: "Gibraltar",
        },
        {
            name: "America/Godthab",
            country: "Greenland",
        },
        {
            name: "America/Danmarkshavn",
            country: "Greenland",
        },
        {
            name: "America/Scoresbysund",
            country: "Greenland",
        },
        {
            name: "America/Thule",
            country: "Greenland",
        },
        {
            name: "Africa/Banjul",
            country: "Gambia",
        },
        {
            name: "Africa/Conakry",
            country: "Guinea",
        },
        {
            name: "America/Guadeloupe",
            country: "Guadeloupe",
        },
        {
            name: "Africa/Malabo",
            country: "Equatorial Guinea",
        },
        {
            name: "Europe/Athens",
            country: "Greece",
        },
        {
            name: "Atlantic/South_Georgia",
            country: "South Georgia And Sandwich Isl.",
        },
        {
            name: "America/Guatemala",
            country: "Guatemala",
        },
        {
            name: "Pacific/Guam",
            country: "Guam",
        },
        {
            name: "Africa/Bissau",
            country: "Guinea-Bissau",
        },
        {
            name: "America/Guyana",
            country: "Guyana",
        },
        {
            name: "Asia/Hong_Kong",
            country: "Hong Kong",
        },
        {
            name: "America/Tegucigalpa",
            country: "Honduras",
        },
        {
            name: "Europe/Zagreb",
            country: "Croatia",
        },
        {
            name: "America/Port-au-Prince",
            country: "Haiti",
        },
        {
            name: "Europe/Budapest",
            country: "Hungary",
        },
        {
            name: "Asia/Jakarta",
            country: "Indonesia",
        },
        {
            name: "Asia/Pontianak",
            country: "Indonesia",
        },
        {
            name: "Asia/Makassar",
            country: "Indonesia",
        },
        {
            name: "Asia/Jayapura",
            country: "Indonesia",
        },
        {
            name: "Europe/Dublin",
            country: "Ireland",
        },
        {
            name: "Asia/Jerusalem",
            country: "Israel",
        },
        {
            name: "Europe/Isle_of_Man",
            country: "Isle Of Man",
        },
        {
            name: "Asia/Kolkata",
            country: "India",
        },
        {
            name: "Indian/Chagos",
            country: "British Indian Ocean Territory",
        },
        {
            name: "Asia/Baghdad",
            country: "Iraq",
        },
        {
            name: "Asia/Tehran",
            country: "Iran (Islamic Republic Of)",
        },
        {
            name: "Atlantic/Reykjavik",
            country: "Iceland",
        },
        {
            name: "Europe/Rome",
            country: "Italy",
        },
        {
            name: "Europe/Jersey",
            country: "Jersey",
        },
        {
            name: "America/Jamaica",
            country: "Jamaica",
        },
        {
            name: "Asia/Amman",
            country: "Jordan",
        },
        {
            name: "Asia/Tokyo",
            country: "Japan",
        },
        {
            name: "Africa/Nairobi",
            country: "Kenya",
        },
        {
            name: "Asia/Bishkek",
            country: "Kyrgyzstan",
        },
        {
            name: "Asia/Phnom_Penh",
            country: "Cambodia",
        },
        {
            name: "Pacific/Tarawa",
            country: "Kiribati",
        },
        {
            name: "Pacific/Enderbury",
            country: "Kiribati",
        },
        {
            name: "Pacific/Kiritimati",
            country: "Kiribati",
        },
        {
            name: "Indian/Comoro",
            country: "Comoros",
        },
        {
            name: "America/St_Kitts",
            country: "Saint Kitts And Nevis",
        },
        {
            name: "Asia/Pyongyang",
            country: "North Korea",
        },
        {
            name: "Asia/Seoul",
            country: "Korea",
        },
        {
            name: "Asia/Kuwait",
            country: "Kuwait",
        },
        {
            name: "America/Cayman",
            country: "Cayman Islands",
        },
        {
            name: "Asia/Almaty",
            country: "Kazakhstan",
        },
        {
            name: "Asia/Qyzylorda",
            country: "Kazakhstan",
        },
        {
            name: "Asia/Aqtobe",
            country: "Kazakhstan",
        },
        {
            name: "Asia/Aqtau",
            country: "Kazakhstan",
        },
        {
            name: "Asia/Oral",
            country: "Kazakhstan",
        },
        {
            name: "Asia/Vientiane",
            country: "Lao People's Democratic Republic",
        },
        {
            name: "Asia/Beirut",
            country: "Lebanon",
        },
        {
            name: "America/St_Lucia",
            country: "Saint Lucia",
        },
        {
            name: "Europe/Vaduz",
            country: "Liechtenstein",
        },
        {
            name: "Asia/Colombo",
            country: "Sri Lanka",
        },
        {
            name: "Africa/Monrovia",
            country: "Liberia",
        },
        {
            name: "Africa/Maseru",
            country: "Lesotho",
        },
        {
            name: "Europe/Vilnius",
            country: "Lithuania",
        },
        {
            name: "Europe/Luxembourg",
            country: "Luxembourg",
        },
        {
            name: "Europe/Riga",
            country: "Latvia",
        },
        {
            name: "Africa/Tripoli",
            country: "Libyan Arab Jamahiriya",
        },
        {
            name: "Africa/Casablanca",
            country: "Morocco",
        },
        {
            name: "Europe/Monaco",
            country: "Monaco",
        },
        {
            name: "Europe/Chisinau",
            country: "Moldova",
        },
        {
            name: "Europe/Podgorica",
            country: "Montenegro",
        },
        {
            name: "America/Marigot",
            country: "Saint Martin",
        },
        {
            name: "Indian/Antananarivo",
            country: "Madagascar",
        },
        {
            name: "Pacific/Majuro",
            country: "Marshall Islands",
        },
        {
            name: "Pacific/Kwajalein",
            country: "Marshall Islands",
        },
        {
            name: "Europe/Skopje",
            country: "Macedonia",
        },
        {
            name: "Africa/Bamako",
            country: "Mali",
        },
        {
            name: "Asia/Rangoon",
            country: "Myanmar",
        },
        {
            name: "Asia/Ulaanbaatar",
            country: "Mongolia",
        },
        {
            name: "Asia/Hovd",
            country: "Mongolia",
        },
        {
            name: "Asia/Choibalsan",
            country: "Mongolia",
        },
        {
            name: "Asia/Macau",
            country: "Macao",
        },
        {
            name: "Pacific/Saipan",
            country: "Northern Mariana Islands",
        },
        {
            name: "America/Martinique",
            country: "Martinique",
        },
        {
            name: "Africa/Nouakchott",
            country: "Mauritania",
        },
        {
            name: "America/Montserrat",
            country: "Montserrat",
        },
        {
            name: "Europe/Malta",
            country: "Malta",
        },
        {
            name: "Indian/Mauritius",
            country: "Mauritius",
        },
        {
            name: "Indian/Maldives",
            country: "Maldives",
        },
        {
            name: "Africa/Blantyre",
            country: "Malawi",
        },
        {
            name: "America/Mexico_City",
            country: "Mexico",
        },
        {
            name: "America/Cancun",
            country: "Mexico",
        },
        {
            name: "America/Merida",
            country: "Mexico",
        },
        {
            name: "America/Monterrey",
            country: "Mexico",
        },
        {
            name: "America/Matamoros",
            country: "Mexico",
        },
        {
            name: "America/Mazatlan",
            country: "Mexico",
        },
        {
            name: "America/Chihuahua",
            country: "Mexico",
        },
        {
            name: "America/Ojinaga",
            country: "Mexico",
        },
        {
            name: "America/Hermosillo",
            country: "Mexico",
        },
        {
            name: "America/Tijuana",
            country: "Mexico",
        },
        {
            name: "America/Santa_Isabel",
            country: "Mexico",
        },
        {
            name: "America/Bahia_Banderas",
            country: "Mexico",
        },
        {
            name: "Asia/Kuala_Lumpur",
            country: "Malaysia",
        },
        {
            name: "Asia/Kuching",
            country: "Malaysia",
        },
        {
            name: "Africa/Maputo",
            country: "Mozambique",
        },
        {
            name: "Africa/Windhoek",
            country: "Namibia",
        },
        {
            name: "Pacific/Noumea",
            country: "New Caledonia",
        },
        {
            name: "Africa/Niamey",
            country: "Niger",
        },
        {
            name: "Pacific/Norfolk",
            country: "Norfolk Island",
        },
        {
            name: "Africa/Lagos",
            country: "Nigeria",
        },
        {
            name: "America/Managua",
            country: "Nicaragua",
        },
        {
            name: "Europe/Amsterdam",
            country: "Netherlands",
        },
        {
            name: "Europe/Oslo",
            country: "Norway",
        },
        {
            name: "Asia/Kathmandu",
            country: "Nepal",
        },
        {
            name: "Pacific/Nauru",
            country: "Nauru",
        },
        {
            name: "Pacific/Niue",
            country: "Niue",
        },
        {
            name: "Pacific/Auckland",
            country: "New Zealand",
        },
        {
            name: "Pacific/Chatham",
            country: "New Zealand",
        },
        {
            name: "Asia/Muscat",
            country: "Oman",
        },
        {
            name: "America/Panama",
            country: "Panama",
        },
        {
            name: "America/Lima",
            country: "Peru",
        },
        {
            name: "Pacific/Tahiti",
            country: "French Polynesia",
        },
        {
            name: "Pacific/Marquesas",
            country: "French Polynesia",
        },
        {
            name: "Pacific/Gambier",
            country: "French Polynesia",
        },
        {
            name: "Pacific/Port_Moresby",
            country: "Papua New Guinea",
        },
        {
            name: "Asia/Manila",
            country: "Philippines",
        },
        {
            name: "Asia/Karachi",
            country: "Pakistan",
        },
        {
            name: "Europe/Warsaw",
            country: "Poland",
        },
        {
            name: "America/Miquelon",
            country: "Saint Pierre And Miquelon",
        },
        {
            name: "Pacific/Pitcairn",
            country: "Pitcairn",
        },
        {
            name: "America/Puerto_Rico",
            country: "Puerto Rico",
        },
        {
            name: "Asia/Gaza",
            country: "Palestinian Territory (Occupied)",
        },
        {
            name: "Asia/Hebron",
            country: "Palestinian Territory (Occupied)",
        },
        {
            name: "Europe/Lisbon",
            country: "Portugal",
        },
        {
            name: "Atlantic/Madeira",
            country: "Portugal",
        },
        {
            name: "Atlantic/Azores",
            country: "Portugal",
        },
        {
            name: "Pacific/Palau",
            country: "Palau",
        },
        {
            name: "America/Asuncion",
            country: "Paraguay",
        },
        {
            name: "Asia/Qatar",
            country: "Qatar",
        },
        {
            name: "Indian/Reunion",
            country: "Reunion",
        },
        {
            name: "Europe/Bucharest",
            country: "Romania",
        },
        {
            name: "Europe/Belgrade",
            country: "Serbia",
        },
        {
            name: "Europe/Kaliningrad",
            country: "Russian Federation",
        },
        {
            name: "Europe/Moscow",
            country: "Russian Federation",
        },
        {
            name: "Europe/Volgograd",
            country: "Russian Federation",
        },
        {
            name: "Europe/Samara",
            country: "Russian Federation",
        },
        {
            name: "Europe/Simferopol",
            country: "Russian Federation",
        },
        {
            name: "Asia/Yekaterinburg",
            country: "Russian Federation",
        },
        {
            name: "Asia/Omsk",
            country: "Russian Federation",
        },
        {
            name: "Asia/Novosibirsk",
            country: "Russian Federation",
        },
        {
            name: "Asia/Novokuznetsk",
            country: "Russian Federation",
        },
        {
            name: "Asia/Krasnoyarsk",
            country: "Russian Federation",
        },
        {
            name: "Asia/Irkutsk",
            country: "Russian Federation",
        },
        {
            name: "Asia/Yakutsk",
            country: "Russian Federation",
        },
        {
            name: "Asia/Khandyga",
            country: "Russian Federation",
        },
        {
            name: "Asia/Vladivostok",
            country: "Russian Federation",
        },
        {
            name: "Asia/Sakhalin",
            country: "Russian Federation",
        },
        {
            name: "Asia/Ust-Nera",
            country: "Russian Federation",
        },
        {
            name: "Asia/Magadan",
            country: "Russian Federation",
        },
        {
            name: "Asia/Kamchatka",
            country: "Russian Federation",
        },
        {
            name: "Asia/Anadyr",
            country: "Russian Federation",
        },
        {
            name: "Africa/Kigali",
            country: "Rwanda",
        },
        {
            name: "Asia/Riyadh",
            country: "Saudi Arabia",
        },
        {
            name: "Pacific/Guadalcanal",
            country: "Solomon Islands",
        },
        {
            name: "Indian/Mahe",
            country: "Seychelles",
        },
        {
            name: "Africa/Khartoum",
            country: "Sudan",
        },
        {
            name: "Europe/Stockholm",
            country: "Sweden",
        },
        {
            name: "Asia/Singapore",
            country: "Singapore",
        },
        {
            name: "Atlantic/St_Helena",
            country: "Saint Helena",
        },
        {
            name: "Europe/Ljubljana",
            country: "Slovenia",
        },
        {
            name: "Arctic/Longyearbyen",
            country: "Svalbard And Jan Mayen",
        },
        {
            name: "Europe/Bratislava",
            country: "Slovakia",
        },
        {
            name: "Africa/Freetown",
            country: "Sierra Leone",
        },
        {
            name: "Europe/San_Marino",
            country: "San Marino",
        },
        {
            name: "Africa/Dakar",
            country: "Senegal",
        },
        {
            name: "Africa/Mogadishu",
            country: "Somalia",
        },
        {
            name: "America/Paramaribo",
            country: "Suriname",
        },
        {
            name: "Africa/Juba",
            country: "South Sudan",
        },
        {
            name: "Africa/Sao_Tome",
            country: "Sao Tome And Principe",
        },
        {
            name: "America/El_Salvador",
            country: "El Salvador",
        },
        {
            name: "America/Lower_Princes",
            country: "Sint Maarten",
        },
        {
            name: "Asia/Damascus",
            country: "Syrian Arab Republic",
        },
        {
            name: "Africa/Mbabane",
            country: "Swaziland",
        },
        {
            name: "America/Grand_Turk",
            country: "Turks And Caicos Islands",
        },
        {
            name: "Africa/Ndjamena",
            country: "Chad",
        },
        {
            name: "Indian/Kerguelen",
            country: "French Southern Territories",
        },
        {
            name: "Africa/Lome",
            country: "Togo",
        },
        {
            name: "Asia/Bangkok",
            country: "Thailand",
        },
        {
            name: "Asia/Dushanbe",
            country: "Tajikistan",
        },
        {
            name: "Pacific/Fakaofo",
            country: "Tokelau",
        },
        {
            name: "Asia/Dili",
            country: "Timor-Leste",
        },
        {
            name: "Asia/Ashgabat",
            country: "Turkmenistan",
        },
        {
            name: "Africa/Tunis",
            country: "Tunisia",
        },
        {
            name: "Pacific/Tongatapu",
            country: "Tonga",
        },
        {
            name: "Europe/Istanbul",
            country: "Turkey",
        },
        {
            name: "America/Port_of_Spain",
            country: "Trinidad And Tobago",
        },
        {
            name: "Pacific/Funafuti",
            country: "Tuvalu",
        },
        {
            name: "Asia/Taipei",
            country: "Taiwan",
        },
        {
            name: "Africa/Dar_es_Salaam",
            country: "Tanzania",
        },
        {
            name: "Europe/Kiev",
            country: "Ukraine",
        },
        {
            name: "Europe/Uzhgorod",
            country: "Ukraine",
        },
        {
            name: "Europe/Zaporozhye",
            country: "Ukraine",
        },
        {
            name: "Africa/Kampala",
            country: "Uganda",
        },
        {
            name: "Pacific/Johnston",
            country: "United States Outlying Islands",
        },
        {
            name: "Pacific/Midway",
            country: "United States Outlying Islands",
        },
        {
            name: "Pacific/Wake",
            country: "United States Outlying Islands",
        },
        {
            name: "America/New_York",
            country: "United States",
        },
        {
            name: "America/Detroit",
            country: "United States",
        },
        {
            name: "America/Kentucky/Louisville",
            country: "United States",
        },
        {
            name: "America/Kentucky/Monticello",
            country: "United States",
        },
        {
            name: "America/Indiana/Indianapolis",
            country: "United States",
        },
        {
            name: "America/Indiana/Vincennes",
            country: "United States",
        },
        {
            name: "America/Indiana/Winamac",
            country: "United States",
        },
        {
            name: "America/Indiana/Marengo",
            country: "United States",
        },
        {
            name: "America/Indiana/Petersburg",
            country: "United States",
        },
        {
            name: "America/Indiana/Vevay",
            country: "United States",
        },
        {
            name: "America/Chicago",
            country: "United States",
        },
        {
            name: "America/Indiana/Tell_City",
            country: "United States",
        },
        {
            name: "America/Indiana/Knox",
            country: "United States",
        },
        {
            name: "America/Menominee",
            country: "United States",
        },
        {
            name: "America/North_Dakota/Center",
            country: "United States",
        },
        {
            name: "America/North_Dakota/New_Salem",
            country: "United States",
        },
        {
            name: "America/North_Dakota/Beulah",
            country: "United States",
        },
        {
            name: "America/Denver",
            country: "United States",
        },
        {
            name: "America/Boise",
            country: "United States",
        },
        {
            name: "America/Phoenix",
            country: "United States",
        },
        {
            name: "America/Los_Angeles",
            country: "United States",
        },
        {
            name: "America/Anchorage",
            country: "United States",
        },
        {
            name: "America/Juneau",
            country: "United States",
        },
        {
            name: "America/Sitka",
            country: "United States",
        },
        {
            name: "America/Yakutat",
            country: "United States",
        },
        {
            name: "America/Nome",
            country: "United States",
        },
        {
            name: "America/Adak",
            country: "United States",
        },
        {
            name: "America/Metlakatla",
            country: "United States",
        },
        {
            name: "Pacific/Honolulu",
            country: "United States",
        },
        {
            name: "America/Montevideo",
            country: "Uruguay",
        },
        {
            name: "Asia/Samarkand",
            country: "Uzbekistan",
        },
        {
            name: "Asia/Tashkent",
            country: "Uzbekistan",
        },
        {
            name: "Europe/Vatican",
            country: "Holy See (Vatican City State)",
        },
        {
            name: "America/St_Vincent",
            country: "Saint Vincent And Grenadines",
        },
        {
            name: "America/Caracas",
            country: "Venezuela",
        },
        {
            name: "America/Tortola",
            country: "Virgin Islands (British)",
        },
        {
            name: "America/St_Thomas",
            country: "Virgin Islands (U.S.)",
        },
        {
            name: "Asia/Ho_Chi_Minh",
            country: "Viet Nam",
        },
        {
            name: "Pacific/Efate",
            country: "Vanuatu",
        },
        {
            name: "Pacific/Wallis",
            country: "Wallis And Futuna",
        },
        {
            name: "Pacific/Apia",
            country: "Samoa",
        },
        {
            name: "Asia/Aden",
            country: "Yemen",
        },
        {
            name: "Indian/Mayotte",
            country: "Mayotte",
        },
        {
            name: "Africa/Johannesburg",
            country: "South Africa",
        },
        {
            name: "Africa/Lusaka",
            country: "Zambia",
        },
        {
            name: "Africa/Harare",
            country: "Zimbabwe",
        },
        {
            name: "America/Montreal",
            country: "Canada",
        },
    ];

    return functions;
}
