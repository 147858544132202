import angular from "angular";
import translateConfig from "./translate.config";
import themeConfig from "./theme.config";
import generalConfig from "./general.config";
import statesConfig from "./states.config";

var module = angular.module("sbApp.constants", [
    "pascalprecht.translate",
    "ui.router",
    "sbApp.services",
    "sbApp.configs",
]);

[translateConfig, themeConfig, generalConfig, statesConfig].forEach(
    function (constants) {
        constants(module);
    }
);

export default module;
