import angular from "angular";
import htmlTemplate from "./activity_creator.html";
import ConstantsModule from "constants/index";
import registerActivityNameComponent from "./sbActivityName/activity_name.component";
import registerActivityStateNameComponent from "./sbActivityStateName/activity_state_name.component";
import registerActivityLabourComponent from "./sbActivityLabour/activity_labour.component";
import registerActivityDurationComponent from "./sbActivityDuration/activity_duration.component";
import registerActivityAnnotationComponent from "./sbActivityAnnotation/activity_annotation.component";
import registerActivityColorComponent from "./sbActivityColor/activity_color.component";
import ActivityCommonInputCtrl from "./activity_common_input.controller";
import ActivityCreatorCtrl from "./activity_creator.controller";

var ActivityCreatorModule = angular
    .module("sbApp.common.components.activityCreator", [ConstantsModule.name])
    .controller("ActivityCommonInputCtrl", ActivityCommonInputCtrl)
    .controller("ActivityCreatorCtrl", ActivityCreatorCtrl)
    .component("sbActivityCreator", {
        templateUrl: htmlTemplate,
        bindings: {
            activity: "<?",
            teams: "<",
            calendar: "<",
            onActivityChanged: "&",
        },
        controller: "ActivityCreatorCtrl",
    });

registerActivityNameComponent(ActivityCreatorModule);
registerActivityStateNameComponent(ActivityCreatorModule);
registerActivityLabourComponent(ActivityCreatorModule);
registerActivityDurationComponent(ActivityCreatorModule);
registerActivityAnnotationComponent(ActivityCreatorModule);
registerActivityColorComponent(ActivityCreatorModule);

export default ActivityCreatorModule;
