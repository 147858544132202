import _ from "lodash";
import moment from "moment";

export default function filterMenuService(
    $sbProject,
    $sbStructure,
    $sbTeam,
    $filter,
    $q,
    $sbTemplate,
    $sbPerformanceApi
) {
    "ngInject";
    var _naturalSort = $filter("naturalSort");

    var service = {
        fetchTeamsAndStructures: fetchTeamsAndStructures,
        fetchDeliverableTypes: _fetchNumOfDeliverablesByType,
    };

    return service;

    function fetchTeamsAndStructures() {
        return $q.all({
            structures: _fetchStructuresSortedNaturally(),
            teams: _fetchTeams(),
            processTemplates: _fetchProjectBasedProcessTemplates(),
            deliverableTypes: _fetchNumOfDeliverablesByType(),
        });
    }

    function _fetchStructuresSortedNaturally() {
        return $sbStructure
            .list($sbProject.getCurrentProjectId())
            .then((structures) => {
                return _naturalSort(structures, "name");
            });
    }

    function _fetchTeams() {
        return $sbTeam
            .getTeams($sbProject.getCurrentProjectId())
            .then($sbTeam.sortTeams);
    }

    function _fetchProjectBasedProcessTemplates() {
        return $sbTemplate
            .getTemplates()
            .then(function (templates) {
                return $sbTemplate.parseTemplates(templates);
            })
            .then(function onlyAssignedTemplates(templates) {
                return templates.filter(function (template) {
                    return template.usedInComponents > 0;
                });
            })
            .then((templates) => _.orderBy(templates, "name"));
    }

    function _fetchNumOfDeliverablesByType() {
        return $sbPerformanceApi
            .getCollection($sbProject.getCurrentProjectId(), {
                date_range: {
                    start_date: Number.NEGATIVE_INFINITY.toString(),
                    end_date: moment().endOf("day").format(),
                },
                requests: [
                    {
                        metric: {
                            expression: "sb::count-deliverable",
                        },
                        dimension: {
                            name: "sb::deliverable-type",
                        },
                        pivot: {
                            metric: {
                                expression: "sb::count-deliverable",
                            },
                        },
                    },
                ],
            })
            .then(_getDeliverableTypeSorted);
    }

    function _getDeliverableTypeSorted(deliverableTypes) {
        return _naturalSort(deliverableTypes, "name");
    }
}
