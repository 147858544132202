import ConstantsModule from "./constants.module";

ConstantsModule.constant("ODATA_CONFIG", {
    MAX_BLOCK_SIZE: "500",
});

ConstantsModule.constant("ODATA_ORDER", {
    DESC: -1,
    UNSORTED: 0,
    ASC: 1,
});
