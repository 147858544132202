import { differenceWith } from "lodash";

export function combineXlsContentWithServerResponse(
    xlsContent,
    response,
    activityToExcelRowMapper
) {
    // result: [
    //     'IMPORT_PROGRESS_SUCCESS', // => 'IMPORT_SUCCESS'
    //     'IMPORT_PROGRESS_CHECKLIST_REQUIRED',
    //     'IMPORT_PROGRESS_NOT_ALLOWED_TRANSITION',
    //     'IMPORT_PROGRESS_ALREADY_UPDATED',
    //     'IMPORT_PROGRESS_DUPLICATE_NAME', // => 'IMPORT_ACTIVITY_DUPLICATE_NAME'
    //     'IMPORT_PROGRESS_NOT_RESPONSIBLE_TEAM',
    //     'IMPORT_PROGRESS_TIMESTAMP_IN_THE_FUTURE',
    //     'IMPORT_PROGRESS_ACTIVITY_NOT_FOUND', // => 'IMPORT_ACTIVITY_NOT_FOUND'

    //      'IMPORT_USER_DEFINED_DATES_ONLY_ONE_DATE_IS_ALLOWED',
    //      'IMPORT_USER_DEFINED_DATES_NO_DATE_AND_DURATION_GIVEN',
    //      'IMPORT_USER_DEFINED_DATES_DELIVERABLE_IS_LOCKED',
    //      'IMPORT_USER_DEFINED_DATES_INVALID_UNIT_OF_WORK',

    //     'IMPORT_PROGRESS_OTHER_ERROR', // => 'IMPORT_OTHER_ERROR'
    // ],
    let formattedResponse = response.response.deliverables.reduce(
        (acc, deliverable) => {
            deliverable.activities.forEach((activity) => {
                acc.push(activityToExcelRowMapper(activity, deliverable));
            });
            return acc;
        },
        []
    );

    const wrongProjectActivities = differenceWith(
        xlsContent,
        formattedResponse,
        (xlsRow, responseRow) => {
            const xlsRowDeliverableCode = xlsRow[0];
            const xlsRowActivityName = xlsRow[1];

            const responseRowDeliverableCode = responseRow[1];
            const responseRowActivityName = responseRow[2];

            return (
                xlsRowDeliverableCode === responseRowDeliverableCode &&
                xlsRowActivityName === responseRowActivityName
            );
        }
    );

    const wrongProjectActivitiesWithCorrespondingErrorCode =
        wrongProjectActivities.map((row) => {
            row.splice(4, 1);
            row.unshift("IMPORT_PROGRESS_WRONG_PROJECT");
            return row;
        });

    return formattedResponse
        .filter((row) => row[0] !== "IMPORT_SUCCESS")
        .concat(wrongProjectActivitiesWithCorrespondingErrorCode);
}
