import moment from "moment";
import _ from "lodash";

export default function WorkflowRowCtrl(
    ACTIVITY_TRANSITIONS,
    PADDING_SIZES,
    sbDeliverableWorkflowService,
    $filter
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var translate = $filter("translate");

    /////////////////////
    //
    //      View model properties
    //
    /////////////////////

    vm.row = {};

    /////////////////////
    //
    //      Lifecycle Hooks
    //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
    //
    /////////////////////

    vm.$onChanges = function () {
        vm.row = mapTo(vm.node);
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function mapTo(node) {
        return {
            id: node.id,
            piTemplateId: node.piTemplateId, // required when deleting user defined dates!
            padding:
                sbDeliverableWorkflowService.getParentCount(node) *
                    PADDING_SIZES.increment +
                PADDING_SIZES.initialDetail +
                "px",
            isNoneSchedulingLaneVisible:
                _deriveIsNoneSchedulingLaneVisible(node),
            duration: {
                text: _derivePlannedDurationText(
                    node.plannedDuration,
                    node.plannedDurationUnit
                ),
                lookAhead: _deriveLastPlannerDurationText(
                    node.lastPlannerDuration
                ),
            },
            dates: {
                isUserDefined: node.dateSource === vm.treeCtrl.DATE_SOURCE.USER,
                userDefinedStart: _deriveDateStringFrom(
                    node.userDefinedStart,
                    node.timezone
                ),
                userDefinedEnd: _deriveDateStringFrom(
                    node.userDefinedEnd,
                    node.timezone
                ),
                actualStart: _deriveDateStringFrom(
                    node.actualStart,
                    node.timezone
                ),
                actualEnd: _deriveDateStringFrom(node.actualEnd, node.timezone),
                actualConfirmed: _deriveDateStringFrom(
                    node.actualConfirmed,
                    node.timezone
                ),
                earliestStart: _deriveDateStringFrom(
                    node.earliestStart,
                    node.timezone
                ),
                earliestEnd: _deriveDateStringFrom(
                    node.earliestEnd,
                    node.timezone
                ),
                latestStart: _deriveDateStringFrom(
                    node.latestStart,
                    node.timezone
                ),
                latestEnd: _deriveDateStringFrom(node.latestEnd, node.timezone),
            },
            totalFloat: {
                inDays: _deriveFloatNumber(node.getTotalFloatInDays()),
            },
            confirmationTeam: _deriveConfirmationTeam(node.confirmationTeam),
            reviewTeams: _deriveReviewTeams(node.reviewTeams),
            qaChecklist: node.qa.templateId
                ? translate("YES")
                : translate("NO"),
        };
    }

    function _deriveReviewTeams(reviewTeams = []) {
        return reviewTeams.map((team) => translate(team.getDisplayName()));
    }

    function _deriveConfirmationTeam(team) {
        if (team && team.id) {
            return translate(team.getDisplayName());
        } else {
            return translate("_UNRESTRICTED_TEAM");
        }
    }

    function _deriveIsNoneSchedulingLaneVisible(node) {
        return (
            node.lastPlannerDuration ||
            node.confirmationTeam ||
            node.qa.templateId
        );
    }

    function _deriveLastPlannerDurationText(duration) {
        if (duration) {
            return (
                duration +
                " " +
                translate("_wd", {
                    duration: duration,
                })
            );
        } else {
            return "-";
        }
    }

    function _derivePlannedDurationText(duration, unit) {
        if (_.isNumber(duration)) {
            return (
                duration +
                " " +
                translate("_" + unit, {
                    duration: duration,
                })
            );
        } else {
            return "-";
        }
    }

    function _deriveFloatNumber(floatInDays) {
        if (_.isNumber(floatInDays)) {
            return (
                floatInDays +
                " " +
                translate("_d", {
                    duration: floatInDays,
                })
            );
        } else {
            return "-";
        }
    }

    function _deriveDateStringFrom(date, tz) {
        if (moment.isMoment(date) && date.isValid()) {
            return moment.tz(date, tz).format("L");
        } else {
            return "-";
        }
    }
}
