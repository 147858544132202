/**
 * Module description
 *
 * @module stickers
 */
import angular from "angular";
import moment from "moment";

import serviceModule from "../../../../common/services/services";
import apiModule from "../../../../common/services/api/api.module";

export default /** @alias module:stickers */ angular
    .module("sbApp.qrCardService", [serviceModule.name, apiModule.name])
    .factory(
        "qrCardService",
        function qrCardService(
            downloadCenterService,
            $sbStructureNodeJobsApi,
            $sbLocale
        ) {
            "ngInject";

            ////////////////////////////////
            //                            //
            //         PUBLIC API         //
            //                            //
            ////////////////////////////////

            /**
             * Print a QR Code pdf for the given structure elements
             *
             * @param {String} sProjectId - The project ID
             * @param {String} sLayout - one of 1x2-A4 or 2x6-A4
             * @param {Array<String>} items - Selected items with all necessary info
             *
             * @returns {Promise}
             */
            function printStructureNodesToPdf(sProjectId, sLayout, items) {
                return $sbStructureNodeJobsApi
                    .printQrCodes(sProjectId, items, {
                        language: $sbLocale.current(),
                        layout: sLayout,
                    })
                    .then((xhr) => {
                        downloadCenterService.downloadFrom(
                            xhr,
                            (backendFilename) =>
                                backendFilename ||
                                getFallbackFilename(items.length)
                        );
                        return xhr;
                    });
            }

            /**
             * Generate fallback filename
             *
             * @param  {number} [numOfCodes] Optional number of printed qr codes.
             * @returns {string}
             */
            function getFallbackFilename(numOfCodes) {
                const prefix = moment().format("YYYY-MM-DD");
                const suffix = numOfCodes ? "" : "_" + numOfCodes.toString(10);
                return `${prefix}_basic_QRCodes${suffix}.pdf`;
            }

            return {
                printStructureNodesToPdf: printStructureNodesToPdf,
            };
        }
    );
