import DateViewFilter from "./odata_date_view_filter.class";

/**
 * Option class that uses the given property and this.name to provide unified
 * odata functionality. Is used and conform to ViewFilter options interface.
 *
 * @param {String} id       - Element identifier
 * @param {String} name     - Element visual human readable representation
 * @param {String} property - OData Property to use for EQ operation
 * @constructor
 */
function ODataEqOption(id, name, property) {
    this.label = name;
    this.name = id;
    this.property = property;
}

ODataEqOption.prototype.odata = function (factory) {
    return factory.eq(this.property, this.name);
};

export default ODataEqOption;
