import _ from "lodash";
import moment from "moment";
import ViewSelectionProvider from "common/plainModels/ViewSelectionProvider";
import SbActivityState from "domain/sb_activity_state.class";

export default function LastPlannedSelectionStore($window) {
    "ngInject";

    const STORAGE_ID = "sb.leanboard";

    let viewSelectionProvider = undefined;
    let projectId = undefined;

    var service = {
        toggleSelection: toggleSelection,
        clearCurrentSelection: clearCurrentSelection,
        isSelected: isSelected,
        getSelections: getSelections,
        getSelectedObjects: getStoredSelection,
        hasActiveSelections: hasActiveSelections,
        updateStoreEntry: updateStoreEntry,
        makeStoreActivityFromDomain: makeStoreActivityFromDomain,
    };

    return service;

    //////////

    function init(_projectId) {
        if (typeof _projectId !== "string") {
            throw new Error("IllegalArgumentException");
        }
        projectId = _projectId;
        viewSelectionProvider = new ViewSelectionProvider();
        viewSelectionProvider.selections(
            getStoredSelection(projectId).map(({ id }) => id)
        );
    }

    function toggleSelection(activity) {
        const isSelected = viewSelectionProvider.selectionChange(activity.id);
        if (isSelected) {
            _addToObjectStore(makeStoreActivityFromDomain(activity));
        } else {
            _removeFromObjectStore(activity);
        }
        return isSelected;
    }

    function clearCurrentSelection() {
        viewSelectionProvider.clearSelection();
        return $window.sessionStorage.removeItem(
            `${STORAGE_ID}.${projectId}.selections`
        );
    }

    function getSelections(projectId) {
        if (!viewSelectionProvider) {
            init(projectId);
        }
        return viewSelectionProvider.selections();
    }

    function isSelected(activity) {
        if (!activity) {
            return false;
        }
        return viewSelectionProvider.isSelected(activity.id);
    }

    function hasActiveSelections() {
        return viewSelectionProvider.hasActiveSelections();
    }

    function updateStoreEntry(activity) {
        _removeFromObjectStore(activity);
        _addToObjectStore(activity);
    }

    function _addToObjectStore(storeEntry) {
        const storeActivities = getStoredSelection();
        storeActivities.push(storeEntry);
        _writeIntoStore(projectId, storeActivities);
    }

    function makeStoreActivityFromDomain(activity) {
        return {
            id: activity.id,
            laneRecord: {
                id: activity.laneRecord.id,
            },
            startDate: activity.startDate,
            actualStart: activity.actualStart,
            endDate: activity.endDate,
            state: activity.state,
            duration:
                activity.schedule.sessionLookAhead.duration ||
                activity.schedule.publishedLookAhead.duration ||
                activity.schedule.baseline.duration,
        };
    }

    function _removeFromObjectStore(activity) {
        const storeActivities = getStoredSelection();
        _.remove(storeActivities, ["id", activity.id]);

        _writeIntoStore(projectId, storeActivities);
    }

    function getStoredSelection(id = projectId) {
        return _loadFromStore(id);
    }

    function _writeIntoStore(projectId, activities) {
        const storable = activities.map(_storeToStorable);

        return $window.sessionStorage.setItem(
            `${STORAGE_ID}.${projectId}.selections`,
            JSON.stringify(storable)
        );
    }

    function _loadFromStore(projectId) {
        const selectionsStored = $window.sessionStorage.getItem(
            `${STORAGE_ID}.${projectId}.selections`
        );

        try {
            const selectionStoredParsed = JSON.parse(selectionsStored);

            if (!Array.isArray(selectionStoredParsed)) {
                return [];
            }

            return selectionStoredParsed.map(_storableToStore);
        } catch (e) {
            return [];
        }
    }

    function _storableToStore(storableEntry) {
        return {
            id: storableEntry.id,
            templateId: storableEntry.templateId,
            laneRecord: {
                id: storableEntry.parentId,
            },
            startDate: moment(storableEntry.startDate),
            actualStart: storableEntry.actualStart
                ? moment(storableEntry.actualStart)
                : undefined,
            endDate: moment(storableEntry.endDate),
            state: new SbActivityState(storableEntry.state),
            duration: storableEntry.duration,
        };
    }

    function _storeToStorable(storeEntry) {
        return {
            id: storeEntry.id,
            templateId: storeEntry.templateId,
            parentId: storeEntry.laneRecord.id,
            startDate: storeEntry.startDate.toISOString(),
            actualStart: storeEntry.actualStart
                ? storeEntry.actualStart.toISOString()
                : undefined,
            endDate: storeEntry.endDate.toISOString(),
            state: storeEntry.state.current,
            duration: storeEntry.duration,
        };
    }
}
