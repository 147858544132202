import angular from "angular";
import _ from "lodash";
import dialogTemplate from "./template_selector.tmpl.html";

export default angular
    .module("sbApp.sbTemplateSelectorDialog", [])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("templateSelector", {
            methods: [
                "title",
                "text",
                "ok",
                "cancel",
                "class",
                "ariaLabel",
                "showCopyTeamsQaSelector",

                // custom attributes
                //
                "templates",
                "deliverable",
                "searchBarPlaceholderKey",
                "warningText",
                "confirmCheckboxText",
            ],
            options: function selectDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: TemplateSelectorDialogCtrl,
                    controllerAs: "$ctrl",
                    bindToController: true,
                };

                function TemplateSelectorDialogCtrl($mdDialog, $q) {
                    "ngInject";
                    var vm = this;

                    vm.closeDialog = $mdDialog.cancel;
                    vm.resolve = (selectedTemplate) => {
                        const result = {
                            selectedTemplate,
                            copyTeamsAndQa: vm.showCopyTeamsQaSelector
                                ? vm.copyTeamsAndQa
                                : undefined,
                            answer: !vm.warningText
                                ? false
                                : vm.confirmOverwrite,
                        };

                        $mdDialog.hide(result);
                    };
                    vm.clearSearch = clearSearch;
                    vm.onSearchTextChange = onSearchTextChange;
                    vm.onFakeSearchIndication = onFakeSearchIndication;
                    vm.filterTemplatesBy = filterTemplatesBy;
                    vm.shouldShowTeamAndQaCopySelector =
                        shouldShowTeamAndQaCopySelector;
                    vm.showCopyTeamsQaSelector =
                        vm.showCopyTeamsQaSelector || false;

                    vm.copyTeamsAndQa = "map_by_name";

                    $onInit();

                    function $onInit() {
                        vm.originalTemplate = _.cloneDeep(vm.templates);
                        if (vm.warningText) {
                            vm.confirmOverwrite = false;
                        }
                    }

                    function filterTemplatesBy(searchText) {
                        var searchLowerCase = searchText.toLowerCase();

                        return $q(function (resolve) {
                            vm.templates = _.filter(
                                vm.originalTemplate,
                                function (template) {
                                    return (
                                        (template.NAME &&
                                            template.NAME.toLowerCase().indexOf(
                                                searchLowerCase
                                            ) > -1) ||
                                        (template.CODE &&
                                            template.CODE.toLowerCase().indexOf(
                                                searchLowerCase
                                            ) > -1) ||
                                        (template.DESC &&
                                            template.DESC.toLowerCase().indexOf(
                                                searchLowerCase
                                            ) > -1) ||
                                        (template.PROJECT_NAME &&
                                            template.PROJECT_NAME.toLowerCase().indexOf(
                                                searchLowerCase
                                            ) > -1)
                                    );
                                }
                            );

                            return resolve(vm.templates);
                        });
                    }

                    function onSearchTextChange(searchText) {
                        var hasSearchTextCleared =
                            _.isUndefined(searchText) || searchText === "";
                        if (hasSearchTextCleared) {
                            vm.clearSearch();
                        }
                    }

                    function onFakeSearchIndication(searchText) {
                        if (!searchText) {
                            return $q.resolve([]);
                        }

                        return vm
                            .filterTemplatesBy(searchText)
                            .then(function () {
                                return [];
                            });
                    }

                    function clearSearch() {
                        vm.searchText = undefined;
                        vm.templates = [].concat(vm.originalTemplate);
                    }

                    function shouldShowTeamAndQaCopySelector() {
                        return (
                            vm.showCopyTeamsQaSelector && vm.selectedTemplate
                        );
                    }
                }
            },
        });
    });
