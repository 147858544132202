import moment from "moment";

export default {
    filterTemplates: filterTemplates,
    toDate: toDate,
};

////////////////////////////////////////////////////

function filterTemplates() {
    return function (list, search) {
        var whitelist = ["CODE", "TEXT"];
        var filtered = list.filter(function (elem) {
            return whitelist.some(function (key) {
                if (elem[key]) {
                    return (
                        elem[key]
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1 ||
                        search === ""
                    );
                } else {
                    return false;
                }
            });
        });
        return filtered;
    };
}

function toDate() {
    return function (input, format) {
        if (moment(input).isValid()) {
            return moment(input).format(format);
        } else {
            return input;
        }
    };
}
