export default class DeliverablesOverlayNoteStateService {
    constructor(
        EVENTS,
        $sbDomain,
        $mdDialog,
        $sbIssue,
        $rootScope,
        $sbExternalService,
        $sbTracking,
        $sbErrorPresenter,
        SbNote,
        $q,
        Analytics
    ) {
        "ngInject";

        // dependencies
        this.EVENTS = EVENTS;
        this.$sbDomain = $sbDomain;
        this.$mdDialog = $mdDialog;
        this.$sbIssue = $sbIssue;
        this.$rootScope = $rootScope;
        this.$sbExternalService = $sbExternalService;
        this.$sbTracking = $sbTracking;
        this.$sbErrorPresenter = $sbErrorPresenter;
        this.$q = $q;
        this.SbNote = SbNote;
        this.Analytics = Analytics;
    }

    onStateChange(note, oldValue, newValue) {
        if (oldValue !== newValue) {
            return this._updateState(note, oldValue, newValue);
        }

        return this.$q.resolve(note);
    }

    _updateState(note, oldValue, newValue) {
        this.Analytics.trackConversion("note status changed");

        // update the UI
        note.ISSUE_STATUS = newValue;

        const noteToUpdate = this.$sbDomain.noteFactory
            .create(note)
            .setState(newValue);

        // send to server
        return this.$sbIssue
            .updateState(noteToUpdate)
            .then((updatedNote) => {
                this.$rootScope.$emit(
                    this.EVENTS.NOTE_CHANGED,
                    new this.EVENTS.SbChangeEvent(noteToUpdate, updatedNote),
                    updatedNote.deliverable_id
                );

                this.$sbTracking.note.update(
                    "Details Overlay",
                    `to state: ${newValue}`
                );

                return updatedNote;
            })
            .catch((error) => {
                note.ISSUE_STATUS = oldValue;
                throw error;
            });
    }

    promptIssueCommentDialog(note, projectId, oldValue, newValue) {
        const issueCommentPrompt = this.$mdDialog
            .confirm()
            .title("ADD_NOTE_COMMENT_REDIRECT_DIALOG_TITLE")
            .content("ADD_NOTE_COMMENT_REDIRECT_DIALOG_TEXT")
            .cancel("NO")
            .ok("YES");

        return this.$mdDialog.show(issueCommentPrompt).then(
            () => {
                this.createIssueCommentDialog(
                    note,
                    projectId,
                    oldValue,
                    newValue
                );
            },
            () => {
                this.$sbTracking.note.update(
                    "sb_details_overlay",
                    "add comment and photos skipped"
                );
                this.onStateChange(note, oldValue, newValue);
            }
        );
    }

    createIssueCommentDialog(note, projectId, oldValue, newValue) {
        const issueCommentDialog = this.$mdDialog
            .createIssueComment()
            .note(note);

        return this.$mdDialog
            .show(issueCommentDialog)
            .then(({ noteComment }) => {
                return this.onStateChange(note, oldValue, newValue).then(() => {
                    return this.$sbExternalService
                        .noteViewFacadeService()
                        .createNoteCommentFromAngularJS(projectId, noteComment);
                });
            });
    }
}
