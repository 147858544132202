import _ from "lodash";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";

export default function VisualizationConfigStateCtrl(
    DrawingConfiguration,
    SbDeliverable,
    $sbTemplate,
    $sbStructure,
    $sbReportingDeliverablesApi,
    $sbCurrentProject,
    $sbProject,
    $sbErrorPresenter,
    $sbSketchGenerator,
    $filter,
    $rootScope,
    $q,
    $sbSvg,
    $state,
    $log,
    SABLONO_STATES,
    Analytics
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;
    var _translate = $filter("translate");
    vm.deliverableTypes = undefined;
    vm.structureNodes = undefined;
    vm.drawingConfig = undefined;
    vm.isSubmitting = undefined;

    vm.generatedSvg = "";
    vm.defaultSvgHeaders = {
        left: "",
        right: "",
    };

    vm.goToNextStep = goToNextStep;
    vm.querySearch = querySearch;
    vm.onDeliverableTypeChange = onDeliverableTypeChange;
    vm.onChipAdd = onChipAdd;
    vm.onChipRemove = onChipRemove;
    vm.getButtonTextKey = getButtonTextKey;
    vm.regenerateSvgPreview = regenerateSvgPreview;
    vm.isSvgVisible = isSvgVisible;
    vm.isNextButtonDisabled = isNextButtonDisabled;
    vm.cleanRangeValue = cleanRangeValue;

    /////////////////////
    //
    //      Watcher
    //
    /////////////////////

    $rootScope.$on("$translateChangeSuccess", function onLanguageChange() {
        _updateSvgHeaderTranslation();
        vm.regenerateSvgPreview();
    });

    $onInit();

    function $onInit() {
        vm.steps = [
            "SECTION_VISU_CONFIG_DELIVERABLE_TYPE_STEPPER",
            "SECTION_VISU_CONFIG_STRUCTURE_NODES_STEPPER",
            "SECTION_VISU_CONFIG_SVG_TITLE_STEPPER",
            "SECTION_VISU_CONFIG_LABEL_PROPERTY_STEPPER",
            "SECTION_VISU_CONFIG_SVG_NAME_STEPPER",
        ];
        vm.highlightProperties = {
            row: [
                {
                    LABEL: "_NAME",
                    VALUE: "name",
                },
                {
                    LABEL: "_CODE",
                    VALUE: "code",
                },
            ],
            cell: [
                {
                    LABEL: "_NAME",
                    VALUE: "name",
                },
                {
                    LABEL: "_CODE",
                    VALUE: "code",
                },
                {
                    LABEL: "_DESC",
                    VALUE: "desc",
                },
            ],
        };
        vm.highlightedProperties = {
            row: {
                label: "name",
            },
            cell: {
                label: "code",
            },
        };
        vm.activeStepIndex = 0;
        vm.isActiveStepFulfilled = false;
        vm.deliverableTypeSearchText = "";
        vm.structureSearchText = "";
        vm.exampleStructureNodes = [];
        vm.selectedStructureNodes = [];
        vm.deliverables = [];
        vm.exampleDeliverables = [];
        vm.isLoading = true;
        vm.isSubmitting = false;

        _updateSvgHeaderTranslation();

        return $q
            .all([_getDeliverablesTypes(), _getStructureNodes()])
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
            });
    }

    function querySearch(searchText, items, searchProperty) {
        if (searchText) {
            var lwrText = searchText.toLowerCase();
            return $q.resolve(
                items.filter(function (option) {
                    return (
                        option[searchProperty]
                            .toLowerCase()
                            .indexOf(lwrText) !== -1
                    );
                })
            );
        } else {
            return $q.resolve(items);
        }
    }

    function onChipRemove() {
        return _setChipsFormValidation();
    }

    function onChipAdd() {
        return _setChipsFormValidation();
    }

    function onDeliverableTypeChange() {
        vm.isActiveStepFulfilled = true;
    }

    function goToNextStep() {
        // after step two fetching components
        if (vm.activeStepIndex === 1) {
            return _fetchDeliverablesByTypeAndStructure(
                vm.selectedDeliverableType.ID,
                vm.selectedStructureNodes
            ).then(function () {
                vm.activeStepIndex = vm.activeStepIndex + 1;
            });
        }
        // after step 5 we send the configuration
        if (vm.activeStepIndex === 4) {
            vm.isSubmitting = true;
            return _submitConfiguration().then(function (response) {
                vm.configurationStored = true;
                Analytics.trackEvent(
                    "2D visualization",
                    "Create visualization",
                    "Wizard successfully finished"
                );
                $state.go(SABLONO_STATES.visualizationGenerated, {
                    projectId: $sbProject.getCurrentProjectId(),
                    id: response.id,
                });
            });
        }
        vm.activeStepIndex = vm.activeStepIndex + 1;
        if (vm.activeStepIndex === 1) {
            // initial custom validation value - min length for structure nodes
            _setChipsFormValidation();
        }
    }

    function isSvgVisible() {
        return (
            vm.activeStepIndex > 0 &&
            vm.generatedSvg &&
            vm.selectedStructureNodes.length
        );
    }

    function isNextButtonDisabled(form) {
        var isInvalidForm = !form.$valid;
        var isConfigurationSubmitted = vm.configurationStored;
        var hasNoTemplates = !vm.deliverableTypes.length;
        var isSubmitting = vm.isSubmitting;

        return (
            isInvalidForm ||
            isConfigurationSubmitted ||
            hasNoTemplates ||
            isSubmitting
        );
    }

    function getButtonTextKey() {
        return vm.configurationStored
            ? "_SAVED"
            : vm.activeStepIndex < 4
              ? "_NEXT"
              : "_FINISH";
    }

    function _getDeliverablesTypes() {
        return $sbTemplate
            .getTemplates()
            .then(function _filterEmptyTemplates(templates) {
                vm.deliverableTypes = templates.filter(function (template) {
                    return template.ACTIVITIES_COUNT > 0;
                });

                Analytics.trackEvent(
                    "2D visualization",
                    "Create visualization",
                    "Wizard started"
                );
            })
            .finally(function () {
                vm.isLoading = false;
            });
    }

    function _submitConfiguration() {
        return $sbSvg.uploadVisualizationSettingsFor(
            $sbProject.getCurrentProjectId(),
            {
                name: vm.visuFileName,
                svg: {
                    longHeader: vm.svgHeaderRight || vm.defaultSvgHeaders.right,
                    shortHeader: vm.svgHeaderLeft || vm.defaultSvgHeaders.left,
                    rowCodeProperty: vm.highlightedProperties.row.label,
                    rowCodeRange: [
                        vm.highlightedProperties.row.startIndex,
                        vm.highlightedProperties.row.endIndex,
                    ],
                    codeRange: [
                        vm.highlightedProperties.cell.startIndex,
                        vm.highlightedProperties.cell.endIndex,
                    ],
                    codeProperty: vm.highlightedProperties.cell.label,
                },
                data: {
                    template: {
                        id: vm.selectedDeliverableType.ID,
                    },
                    rows: vm.selectedStructureNodes,
                },
            }
        );
    }

    function _getStructureNodes() {
        return $sbStructure
            .getSortedMappedList($sbProject.getCurrentProjectId())
            .then(function (structureNodes) {
                vm.structureNodes = structureNodes;
                vm.exampleStructureNodes = _.slice(structureNodes, 0, 3);
            });
    }

    function _setChipsFormValidation() {
        vm.stepperForm.$setValidity(
            "structureNodes",
            vm.selectedStructureNodes.length > 0
        );
        if (vm.stepperForm.$valid) {
            _fetchDeliverablesByTypeAndStructure(
                vm.selectedDeliverableType.ID,
                vm.selectedStructureNodes
            ).then(function () {
                regenerateSvgPreview();
            });
        }
    }

    function createFilterForTemplateId(templateId) {
        return new ODataFilterFactory().eq("TEMPLATE_ID", templateId);
    }

    function createFilterForStructureIds(structureNodes) {
        var structureFilters = structureNodes.map(function (structureNode) {
            return new ODataFilterFactory().eq(
                "STRUCTURE_ID",
                structureNode.id
            );
        });

        return ODataFilterFactory.join(structureFilters, "or");
    }

    function _fetchDeliverablesByTypeAndStructure(templateId, structureNodes) {
        const filterByTemplateId = createFilterForTemplateId(templateId);
        const filterByStructureIds =
            createFilterForStructureIds(structureNodes);

        const byTemplateAndStructureNodes = filterByTemplateId
            .and()
            .block(filterByStructureIds);

        return $sbReportingDeliverablesApi
            .getCollection($sbCurrentProject.pick("id"), {
                filter: byTemplateAndStructureNodes.get(),
                select: ["ID", "NAME", "CODE", "STRUCTURE_ID"].join(","),
            })
            .then(({ deliverables }) =>
                deliverables.map(SbDeliverable.createFromOdataObject)
            )
            .then(function (result) {
                vm.deliverables = result;
                vm.exampleDeliverables = _.slice(result, 0, 3);
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
            });
    }

    function _updateSvgHeaderTranslation() {
        vm.defaultSvgHeaders.left = _translate(
            "SECTION_VISUALIZATION_GENERATED_SVG_HEADER_LEFT_TITLE"
        ).toString();
        vm.defaultSvgHeaders.right = _translate(
            "SECTION_VISUALIZATION_GENERATED_SVG_HEADER_RIGHT_TITLE"
        ).toString();
    }

    function createDrawingConfigFromCurrentWizardState() {
        var config = new DrawingConfiguration(
            -1,
            vm.visuFileName,
            vm.selectedDeliverableType.ID
        );

        config.setDisplayFor(
            "row",
            vm.highlightedProperties.row.label || "code"
        );
        config.setRangeFor(
            "row",
            vm.highlightedProperties.row.startIndex,
            vm.highlightedProperties.row.endIndex
        );

        config.setDisplayFor(
            "cell",
            vm.highlightedProperties.cell.label || "code"
        );
        config.setRangeFor(
            "cell",
            vm.highlightedProperties.cell.startIndex,
            vm.highlightedProperties.cell.endIndex
        );

        config.setHeader(
            vm.svgHeaderRight || vm.defaultSvgHeaders.right,
            vm.svgHeaderLeft || vm.defaultSvgHeaders.left
        );

        config.rows = vm.selectedStructureNodes;
        config.cells = _.groupBy(vm.deliverables, "structureId");

        return config;
    }

    function regenerateSvgPreview() {
        if (vm.selectedDeliverableType) {
            vm.drawingConfig = createDrawingConfigFromCurrentWizardState();
        }

        if (vm.selectedStructureNodes.length) {
            vm.generatedSvg =
                $sbSketchGenerator.generateVisualizationFromConfig(
                    vm.drawingConfig.toSvgGeneratorInput("id")
                );
        }
    }

    function cleanRangeValue(cell, rangePosition) {
        var indexValue = vm.highlightedProperties[cell][rangePosition];

        if (indexValue === undefined || indexValue === null) {
            delete vm.highlightedProperties[cell][rangePosition];
        }
    }
}
