/**
 * Idea: use this directive as html tag to embed its content to a parents md-toolbar.
 *
 *  so on an abstract level there could be the basic toolbar with back and page name
 *  -> with this directive you can then add some page dependent actions to it.
 *
 */
import angular from "angular";
import htmlTemplate from "./sbSubbarActions.html";

export default function ($document) {
    "ngInject";
    return {
        restrict: "E",

        templateUrl: htmlTemplate, //HTML Template as String

        transclude: true,

        scope: {},

        link: function ($scope, $element) {
            // On document ready, we know that everything is there
            // => prepare the toolbar
            $document.ready(function () {
                _setToolbar();
            });

            var $sbSubbarActions = undefined;

            function _setToolbar() {
                $sbSubbarActions = $element;

                // Find the main subbar in the document
                var $sbSubbar = angular.element(
                    $document[0].getElementById("sb-subbar")
                );

                if ($sbSubbar) {
                    // if there is a subbar -> append the subbar actions to the md-toolbar
                    $sbSubbar.append($sbSubbarActions);
                }
            }

            // on destroy -> we have to remove the page dependent toolbar content.
            $scope.$on("$destroy", function () {
                if ($sbSubbarActions) {
                    $sbSubbarActions.remove();
                }
            });
        },
    };
}
