import SbEditorTreeNode from "./sb_editor_tree_node.class";
import _ from "lodash";

function SbEditorTree() {
    this.list = [];
}

SbEditorTree.prototype.getById = function (id) {
    return _.find(this.list, ["model.id", id]);
};

/**
 * Mark the given node as selected in the tree and make him visible.
 *
 * @param {SbEditorTreeNode} node - the node to be selected
 */
SbEditorTree.prototype.show = function (node) {
    if (!node) {
        return;
    }

    node.show();
    this._expandParents(node);
    this._showParents(node);
    this._showChildrenWhenExpanded(this.list[0]);
};

/**
 * Make a node visible -> make all parents visible
 *
 * @param {SbEditorTreeNode} treeNode
 * @private
 */
SbEditorTree.prototype._expandParents = function (treeNode) {
    if (treeNode.parent) {
        treeNode.parent.expand();
        this._expandParents(treeNode.parent);
    }
};

/**
 * Make a node visible -> make all parents visible
 *
 * @param {SbEditorTreeNode} treeNode
 * @private
 */
SbEditorTree.prototype._showParents = function (treeNode) {
    if (treeNode.parent) {
        treeNode.parent.show();
        this._showParents(treeNode.parent);
    }
};

/**
 * Check if the given node is expanded and in case mark children as
 * visible and continue checking the expand state of children.
 *
 * @param treeNode
 * @private
 */
SbEditorTree.prototype._showChildrenWhenExpanded = function (treeNode) {
    if (treeNode.isExpanded()) {
        treeNode.children.forEach(function (c) {
            c.show();
            this._showChildrenWhenExpanded(c);
        }, this);
    }
};

/**
 * 1. make children visible
 *
 * @param {SbEditorTreeNode} toggleNode - expand him
 * @private
 */
SbEditorTree.prototype.expand = function (toggleNode) {
    toggleNode.show().expand();
    this._showParents(toggleNode);

    toggleNode.children.forEach(function (c) {
        c.show();
        this._showChildrenWhenExpanded(c);
    }, this);
};

SbEditorTree.prototype.expandTree = function () {
    this.list.forEach(function (c) {
        c.show().expand();
    });
};

SbEditorTree.prototype.collapseTree = function () {
    this.list.forEach(function (c) {
        if (c.parent) {
            c.hide().collapse();
        } else {
            c.collapse();
        }
    });
};

/**
 * # toggle node collapsed
 *   -> all children (deep) hide
 *   -> self -> collapsed = true
 *
 * @param {SbEditorTreeNode} node - the node that should be visible and in a collapsed state
 * @private
 */
SbEditorTree.prototype.collapse = function (node) {
    node.collapsed = true;
    node.visible = true;

    node.children.forEach(this.hide, this);
};

SbEditorTree.prototype.hide = function (node) {
    node.visible = false;
    node.children.forEach(this.hide, this);
};

/**
 * Init the tree representation from a given object. This object has to provide the "interface"
 * methods: _getChildren_ and the properties: _id_ and _name_
 *
 * @param {object} treeRootNode
 * @param {object} config
 * @returns {Array}
 */
SbEditorTree.prototype.setData = function (treeRootNode, config) {
    var filter =
        config.filter ||
        function () {
            return true;
        };
    var sorter =
        config.sorter ||
        function () {
            return true;
        };
    var treeListModel = [];
    var tree = this;

    function mapToEditorNode(model) {
        var treeNode = tree.getById(model.id) || new SbEditorTreeNode(model);
        treeNode.setModel(model);
        treeListModel.push(treeNode);

        var modelChildren = model.getChildren().filter(filter).sort(sorter);

        if (modelChildren && modelChildren.length > 0) {
            treeNode.setChildren(
                modelChildren.map(function (child) {
                    return mapToEditorNode(child);
                })
            );
        } else {
            treeNode.setChildren([]);
        }
        return treeNode;
    }

    mapToEditorNode(treeRootNode);

    treeListModel[0].refreshLayer(0);

    this.list = treeListModel;
};

/**
 * Generate a string from the given tree structure. The string will vary
 * when adding or deleting nodes from any tree node. Or when the id of any
 * node changes.
 *
 * @param treeRootNode
 * @return {string}
 */
SbEditorTree.prototype.generateTreeCheckSum = function (treeRootNode) {
    var treeListModel = [];

    function mapToEditorNode(model) {
        var treeNode = {
            id: model.id,
            in: model.properties.topologicalIndex,
        };
        treeListModel.push(treeNode);

        var modelChildren = model.getChildren();
        if (modelChildren && modelChildren.length > 0) {
            treeNode.children = modelChildren.map(function (child) {
                return mapToEditorNode(child);
            });
        }
        return treeNode;
    }

    mapToEditorNode(treeRootNode);

    return JSON.stringify(treeListModel);
};

export default SbEditorTree;
