export default function ($sbExternalService, $log) {
    "ngInject";

    return {
        trackEvent: function (eventName, action, label) {
            const eventParameters = {
                action,
                label,
            };

            if ($sbExternalService.analyticsService()) {
                $sbExternalService
                    .analyticsService()
                    .trackEvent(eventName, eventParameters);
            } else {
                $log.warn("Analytics service not available");
            }
        },

        trackPageView: function (page) {
            if ($sbExternalService.analyticsService()) {
                $sbExternalService.analyticsService().trackPageView(page);
            } else {
                $log.warn("Analytics service not available");
            }
        },

        trackConversion: function (action, label) {
            if ($sbExternalService.analyticsService()) {
                $sbExternalService
                    .analyticsService()
                    .trackConversion({ action, label });
            } else {
                $log.warn("Analytics service not available");
            }
        },

        trackNorthStarExploration: function (action) {
            if ($sbExternalService.analyticsService()) {
                $sbExternalService
                    .analyticsService()
                    .trackNorthStarExploration({ action });
            } else {
                $log.warn("Analytics service not available");
            }
        },

        trackIdentity: function (userId) {
            if ($sbExternalService.analyticsService()) {
                $sbExternalService.analyticsService().trackIdentity(userId);
            } else {
                $log.warn("Analytics service not available");
            }
        },
    };
}
