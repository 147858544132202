import angular from "angular";
import htmlTemplate from "./sort_group.html";
export default angular
    .module("sbApp.sbSortGroupDirective", [])
    .directive("sbSortGroup", function sbSortGroupDirective() {
        return {
            restrict: "E",
            require: ["^^sbHeaderBar", "^sbSortGroup"],
            templateUrl: htmlTemplate,
            scope: {},
            controllerAs: "group",
            bindToController: true,
            controller: function SortGroupCtrl($attrs) {
                "ngInject";
                var vm = this;
                vm.direction = 0;

                vm.init = init;
                vm.onSortClick = onSortClick;
                vm.updateHeader = updateHeader;
                vm.setHeaderSortIndication = getActiveSorting;

                function init(element, parentCtrl) {
                    // saved for later usage, from link function (pattern for future directives)
                    vm.$element = element;

                    // comma separated list of column keys used for this sort group
                    vm.columnKeys = $attrs.fields
                        ? $attrs.fields.split(",").map(function (key) {
                              // remove unneeded whitespace from keys
                              return key.trim();
                          })
                        : [];

                    if ($attrs.noSort) {
                        vm.nonSortable = $attrs.noSort;
                    }

                    // reach through callback of sort change
                    vm.onSortChange = parentCtrl.onSortChange;

                    vm.fields = initSortGroup(
                        vm.columnKeys,
                        parentCtrl.columns
                    );

                    vm.label = $attrs.label;
                }

                /**
                 * Creates an array with information about sortable fields in this group.
                 *
                 * @param groupColumnKeys - The keys to sort in this group from the overall columns.
                 * @param overallColumns - The columns from the overall header bar.
                 *
                 * @returns {Array.<Object>}  Field information
                 */
                function initSortGroup(groupColumnKeys, overallColumns) {
                    return groupColumnKeys.map(function (key) {
                        var field = overallColumns[key];
                        field.key = key;
                        return field;
                    });
                }

                /**
                 * Calculates the current sort direction based on all fields in group.
                 *
                 * @param {Array.<Object>} fields - All fields of group.
                 * @returns {Number} 0 - unsorted | 1 descending | -1 - ascending
                 */
                function getActiveSorting(fields) {
                    return fields.reduce(function (result, field) {
                        return result + field.order;
                    }, 0);
                }

                /**
                 * Update the sort direction for overall sorting in group.
                 * Used to display correct icon
                 */
                function updateHeader() {
                    vm.direction = getActiveSorting(vm.fields);
                }

                /**
                 * toggle the sorting of a field item in the sorting menu
                 * and trigger update of sort config to interested controller
                 *
                 * @param {Object} field - field with sort information
                 */
                function onSortClick(field) {
                    field.order = toggleSortDirection(field.order);

                    vm.onSortChange(field.key, field.order);
                    updateHeader();
                }

                /**
                 * Change state of sort direction of given order.
                 *
                 * @param {Number(-1|0|1)} order - Order of sorting, asc | unsorted | desc
                 * @returns {number} The new calculated order.
                 */
                function toggleSortDirection(order) {
                    if (order === 0) {
                        // from unsorted to ascending
                        return 1;
                    } else {
                        // from ascending to descending and back
                        return order * -1;
                    }
                }
            },
            link: function postLink(scope, element, attrs, ctrls) {
                var headerBarCtrl = ctrls[0];
                var sortGroupCtrl = ctrls[1];

                sortGroupCtrl.init(element, headerBarCtrl);

                // watch for changes on currently sorted criteria
                //
                scope.$watch(
                    function () {
                        return headerBarCtrl.groupedBy;
                    },
                    function (groupedBy) {
                        // update header styling
                        sortGroupCtrl.updateHeader();
                        headerBarCtrl.clear(groupedBy);
                    }
                );
            },
        };
    });
