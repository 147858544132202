import angular from "angular";
import templateHtml from "./sbExitEditDialog.html";

export default angular
    .module("sbApp.sbExitEditDialog", [])

    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("sbExitEditDialog", {
            methods: [
                "ariaLabel",
                "actions",
                "autoClose",
                "clickOutsideToClose",
                "escapeToClose",
            ],
            options: [
                "$mdDialog",
                function selectDialog($mdDialog) {
                    return {
                        templateUrl: templateHtml,
                        controller: [
                            "$scope",
                            function mdDialogCtrl($scope) {
                                $scope.hide = function () {
                                    $mdDialog.hide(true);
                                };
                                $scope.abort = function () {
                                    $mdDialog.cancel();
                                };
                                $scope.resolve = function (action) {
                                    $mdDialog.hide(action);
                                };
                                $scope.submit = function () {
                                    $mdDialog.hide($scope.selectedParent);
                                };
                            },
                        ],
                        controllerAs: "dialog",
                        bindToController: true,
                    };
                },
            ],
        });
    });
