"use strict";

import ActivityStateTransitionPermissionMatrix from "./sb_activity_state_transition_permission_matrix.class";

const { get, isNumber } = require("lodash");

// !IMPORTANT! do not use lodash "has" instead of the has function below!
// this custom has function is checking for a truthy value on the object, while lodash has is doing "hasOwnProperty" stuff.
const has = (object, path) => {
    return !!get(object, path);
};

export const WORK_ONLY_WORKFLOW = 1;
export const CONFIRMATION_WORKFLOW_WORK_TEAM = 2;
export const CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM = 3;
export const BLOCK_ALL_ACTIONS = 5;
export const QA_BASED_WORK_ONLY_WORKFLOW = 6;
export const QA_BASED_CONFIRMATION_WORKFLOW_WORK_TEAM = 7;
export const QA_BASED_CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM = 8;

export default class ActivityStateTransitionPermissionMatrixFactory {
    static chooseWorkflowBasedOn({ membership, activity }) {
        const isChecklistRequired = has(activity, "qa.templateId");

        const isConfirmationRequired =
            has(activity, "confirmationTeam.id") &&
            isNumber(get(activity, "confirmationTeam.id"));
        if (isConfirmationRequired) {
            const hasFullAccessMembership = get(
                membership,
                "team.isProjectTeam"
            );
            if (hasFullAccessMembership) {
                // allow all actions
                return isChecklistRequired
                    ? [
                          QA_BASED_CONFIRMATION_WORKFLOW_WORK_TEAM,
                          QA_BASED_CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM,
                      ]
                    : [
                          CONFIRMATION_WORKFLOW_WORK_TEAM,
                          CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM,
                      ];
            }

            const assignedTeamId = get(activity, "assignedTeam.id");
            const confirmationTeamId = get(activity, "confirmationTeam.id");
            const membershipTeamId = get(membership, "team.id");

            const hasWorkResponsibilities =
                assignedTeamId === membershipTeamId ||
                activity.assignedTeam.id === null;
            const hasConfirmationResponsibilities =
                confirmationTeamId === membershipTeamId;
            if (hasWorkResponsibilities && hasConfirmationResponsibilities) {
                // allow combined actions
                return isChecklistRequired
                    ? [
                          QA_BASED_CONFIRMATION_WORKFLOW_WORK_TEAM,
                          QA_BASED_CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM,
                      ]
                    : [
                          CONFIRMATION_WORKFLOW_WORK_TEAM,
                          CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM,
                      ];
            }

            if (hasWorkResponsibilities) {
                // allow work state changes
                return isChecklistRequired
                    ? QA_BASED_CONFIRMATION_WORKFLOW_WORK_TEAM
                    : CONFIRMATION_WORKFLOW_WORK_TEAM;
            }

            if (hasConfirmationResponsibilities) {
                // allow confirmation state changes
                return isChecklistRequired
                    ? QA_BASED_CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM
                    : CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM;
            }
        } else {
            const hasFullAccessMembership = get(
                membership,
                "team.isProjectTeam"
            );
            if (hasFullAccessMembership) {
                // allow all actions
                return isChecklistRequired
                    ? QA_BASED_WORK_ONLY_WORKFLOW
                    : WORK_ONLY_WORKFLOW;
            }

            const assignedTeamId = get(activity, "assignedTeam.id");
            const membershipTeamId = get(membership, "team.id");

            const hasWorkResponsibilities =
                assignedTeamId === membershipTeamId || assignedTeamId === null;
            if (hasWorkResponsibilities) {
                // allow work state changes
                return isChecklistRequired
                    ? QA_BASED_WORK_ONLY_WORKFLOW
                    : WORK_ONLY_WORKFLOW;
            }
        }

        return BLOCK_ALL_ACTIONS;
    }

    static createFor({ workflow = WORK_ONLY_WORKFLOW }) {
        if (!Array.isArray(workflow)) {
            return ActivityStateTransitionPermissionMatrixFactory.mapWorkflowToMatrix(
                workflow
            );
        }

        const noPermissionsMatrix =
            new ActivityStateTransitionPermissionMatrix();

        return workflow.reduce((combined, next) => {
            return combined.or(
                ActivityStateTransitionPermissionMatrixFactory.mapWorkflowToMatrix(
                    next
                )
            );
        }, noPermissionsMatrix);
    }

    static mapWorkflowToMatrix(workflow) {
        const noPermissionsMatrix =
            new ActivityStateTransitionPermissionMatrix();
        switch (workflow) {
            case WORK_ONLY_WORKFLOW: {
                return noPermissionsMatrix.or(
                    new ActivityStateTransitionPermissionMatrix([
                        // not started, started, done
                        //
                        [0, 1, 1, 0, 0, 0], // not started -> to ?
                        [1, 0, 1, 0, 0, 0], // started -> to ?
                        [1, 1, 0, 0, 0, 0], // done -> to ?
                        // in case of a previous confirmation workflow which got removed later
                        // we are allowing to transition _from_ waiting for confirmation and rejected
                        [1, 1, 1, 0, 0, 0], // waiting for confirmation -> to ?
                        [0, 1, 1, 0, 0, 0], // rejected -> to ?
                    ])
                );
            }
            case CONFIRMATION_WORKFLOW_WORK_TEAM: {
                return noPermissionsMatrix.or(
                    new ActivityStateTransitionPermissionMatrix([
                        // not started, started, done, waiting for confirmation, rejected, confirmed
                        //
                        [0, 1, 0, 1, 0, 0], // not started -> to ?
                        [1, 0, 0, 1, 0, 0], // started -> to ?
                        [1, 1, 0, 0, 0, 0], // done -> to ?
                        [1, 1, 0, 0, 0, 0], // waiting for confirmation -> to ?
                        [0, 0, 0, 1, 0, 0], // rejected -> to ?
                        [0, 1, 0, 1, 0, 0], // confirmed -> to ?
                    ])
                );
            }
            case CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM: {
                return noPermissionsMatrix.or(
                    new ActivityStateTransitionPermissionMatrix([
                        // not started, started, done, waiting for confirmation, rejected, confirmed
                        //

                        [0, 0, 0, 0, 0, 0], // not started -> to ?
                        [0, 0, 0, 0, 0, 0], // started -> to ?
                        [0, 0, 0, 0, 1, 1], // done -> to ?
                        [0, 0, 0, 0, 1, 1], // waiting for confirmation -> to ?
                        [0, 0, 0, 0, 0, 1], // rejected -> to ?
                        [0, 0, 0, 0, 1, 0], // confirmed -> to ?
                    ])
                );
            }
            case QA_BASED_WORK_ONLY_WORKFLOW: {
                return noPermissionsMatrix.or(
                    // in sync with sb-daily:: app/domain/quality-pipeline-transitions.ts:46
                    new ActivityStateTransitionPermissionMatrix([
                        // not started, started, done
                        //
                        [0, 1, 1], // not started -> to ?
                        [0, 1, 1], // started -> to ?
                        [0, 0, 0], // done -> to ?

                        // in case of a previous confirmation workflow which got removed later
                        // we are allowing to transition _from_ waiting for confirmation and rejected
                        [0, 0, 0], // waiting for confirmation -> to ?
                        [0, 0, 1], // rejected -> to ?
                    ])
                );
            }
            case QA_BASED_CONFIRMATION_WORKFLOW_WORK_TEAM: {
                return noPermissionsMatrix.or(
                    // in sync with sb-daily:: app/domain/quality-pipeline-transitions.ts:46
                    new ActivityStateTransitionPermissionMatrix([
                        // not started, started, done, waiting for confirmation, rejected, confirmed
                        //
                        [0, 1, 0, 1, 0, 0], // not started -> to ?
                        [0, 1, 0, 1, 0, 0], // started -> to ?
                        [0, 0, 0, 0, 0, 0], // done -> to ?
                        [0, 0, 0, 0, 0, 0], // waiting for confirmation -> to ?
                        [0, 0, 0, 1, 0, 0], // rejected -> to ?
                        [0, 0, 0, 0, 0, 0], // confirmed -> to ?
                    ])
                );
            }
            case QA_BASED_CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM: {
                return noPermissionsMatrix.or(
                    // in sync with sb-daily:: app/domain/quality-pipeline-transitions.ts:46
                    new ActivityStateTransitionPermissionMatrix([
                        // not started, started, done, waiting for confirmation, rejected, confirmed
                        //
                        [0, 0, 0, 0, 0, 0], // not started -> to ?
                        [0, 0, 0, 0, 0, 0], // started -> to ?
                        [0, 0, 0, 0, 1, 1], // done -> to ?
                        [0, 0, 0, 0, 1, 1], // waiting for confirmation -> to ?
                        [0, 0, 0, 0, 0, 0], // rejected -> to ?
                        [0, 0, 0, 0, 0, 0], // confirmed -> to ?
                    ])
                );
            }
            case BLOCK_ALL_ACTIONS: {
                return noPermissionsMatrix;
            }
            default:
                return noPermissionsMatrix;
        }
    }
}
