//Controller of the overlay
export default function ($log, $sbFileReader, $mdDialog, images, currentIndex) {
    "ngInject";
    var vm = this;

    vm.images = images;
    vm.imagesAdditionalInfo = [];
    vm.currentIndex = currentIndex;

    vm.close = close;
    vm.imageLoaded = imageLoaded;
    function close() {
        $mdDialog.cancel();
    }

    function imageLoaded(atIndex) {
        if (vm.imagesAdditionalInfo[atIndex]) {
            return;
        }

        if (vm.images[atIndex].image_url) {
            $sbFileReader
                .extractImageMetadata(vm.images[atIndex].image_url)
                .then((metadata) => {
                    if (metadata) {
                        vm.imagesAdditionalInfo[atIndex] =
                            convertGpsDecimalsToPresentableString(metadata);
                        convertGpsDecimalsToPresentableString(metadata);
                    }
                })
                .catch((e) => $log.error(e));
        }
    }
}

function convertGpsDecimalsToPresentableString(metadata) {
    return {
        ...metadata,
        gpsAltitude: altitudeToString(metadata.gpsAltitude, "m"),
        gpsLatitude: angleToDegreesString(metadata.gpsLatitude, "N", "S"),
        gpsLongitude: angleToDegreesString(metadata.gpsLongitude, "E", "W"),
    };
}

function angleToDegreesString(value, positiveSymbol, negativeSymbol) {
    if (typeof value !== "number") {
        return value;
    }
    const direction = value > 0 ? positiveSymbol : negativeSymbol;
    const absoluteValue = Math.abs(value);
    const degrees = Math.floor(absoluteValue);
    const minutes = Math.floor((absoluteValue - degrees) * 60);
    const seconds = (
        Math.floor((absoluteValue - degrees - minutes / 60) * 360000) / 100
    ).toFixed(2);
    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
}

function altitudeToString(value, unitSymbol) {
    if (typeof value !== "number") {
        return value;
    }
    return `${value.toFixed(2)} ${unitSymbol}`;
}
