export default class CostPackagesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of accessible cost package.
     *
     * @param  projectId {string}   UUID
     */
    getCollection({ projectId }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                project_id: projectId,
            },
        });
    }

    /**
     * This endpoint returns the cost package by id.
     *
     * @param costPackageId {number}    key
     */
    get(costPackageId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a new cost package.
     *
     *
     * @param body {Object}    Request body
     * @param body.name {string}    User defined name of the cost package.
     * @param [body.budget] {number}    User defined budget of the cost package. The money that is distributed through all the activities.
     * @param [body.currency] {string}    User defined currency code (ISO 4217) when displaying money values.
     * @param body.activity_templates {array}    Activity templates included in this cost package.
     */
    create({ name, budget, currency, activity_templates }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { name, budget, currency, activity_templates },
        });
    }

    /**
     * This endpoint allows to update some properties.
     *
     * @param costPackageId {number}    key
     *
     * @param body {Object}    Request body
     * @param body.name {string}    User defined name of the cost package.
     * @param [body.budget] {number}    User defined budget of the cost package. The money that is distributed through all the activities.
     * @param [body.currency] {string}    User defined currency code (ISO 4217) when displaying money values.
     * @param body.activity_templates {array}    Activity templates included in this cost package.
     * @param body.activity_template_groups {array}    Activity template groups included in this cost package.
     */
    update(
        costPackageId,
        { name, budget, currency, activity_templates, activity_template_groups }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: {
                name,
                budget,
                currency,
                activity_templates,
                activity_template_groups,
            },
        });
    }

    /**
     * This endpoint allows to remove a cost package.
     *
     * @param costPackageId {number}    key
     */
    delete(costPackageId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
