import { findIndex } from "lodash";

export function mapToRequestModel(xls, dataGenerationCallback) {
    return xls.content.reduce((acc, xlsRow) => {
        const { projectId, deliverable, activity } =
            dataGenerationCallback(xlsRow);

        const isDeliverableOfCurrentProject =
            projectId === deliverable.projectId;
        if (!isDeliverableOfCurrentProject) return acc;

        let deliverableIndexInAccumulator = findIndex(acc, [
            "code",
            deliverable.code,
        ]);

        const isDeliverableAlreadyInAcc = deliverableIndexInAccumulator > -1;
        if (!isDeliverableAlreadyInAcc) {
            acc.push({
                code: deliverable.code,
                activities: [],
            });

            deliverableIndexInAccumulator = acc.length - 1;
        }

        acc[deliverableIndexInAccumulator].activities.push(activity);

        return acc;
    }, []);
}
