export default function PickProjectMemberCtrl(
    $sbTeam,
    $sbProject,
    $mdDialog,
    warningText
) {
    "ngInject";
    var vm = this;
    var projectId;

    vm.users = [];
    vm.hasFetchedUsers = false;
    vm.dialog = $mdDialog;
    vm.warningText = warningText;

    vm.$onInit = $onInit;
    vm.suggestUsers = suggestUsers;
    vm.submit = submit;

    function $onInit() {
        projectId = $sbProject.getCurrentProjectId();
        return $sbTeam.requestAndCacheUsersForProjectIfNeeded(projectId);
    }

    function suggestUsers(searchText) {
        return $sbTeam.getUsersByProjectAndNameOrEmail(projectId, searchText);
    }

    function submit(user) {
        vm.dialog.hide({
            responsibleUser: user,
        });
    }
    //call $onInit manual because it is not automatically called in a dialog controller
    vm.$onInit();
}
