/**
 * Created by RIEMI on 21.01.2016.
 */
import angular from "angular";
import moment from "moment";
import ChangeTaskDatetimeDialogCtrl from "./change-datetime.controller";
import dialogTemplate from "./change-datetime.tmpl.html";

export default angular
    .module("sbApp.sbChangeDatetimeDialog", [
        "sbApp.services",
        "sbApp.constants",
    ])
    .controller("ChangeTaskDatetimeDialogCtrl", ChangeTaskDatetimeDialogCtrl)
    .config(function ($mdDialogProvider, $mdDateLocaleProvider) {
        "ngInject";
        $mdDateLocaleProvider.formatDate = function (date) {
            return moment(date).format("L");
        };
        $mdDateLocaleProvider.parseDate = function (dateString) {
            const momentDate = moment(dateString, "L", true);
            return momentDate.isValid() ? momentDate.toDate() : new Date(NaN);
        };

        $mdDialogProvider.addPreset("changeDatetime", {
            methods: [
                "title",
                "icon",
                "class",
                "startDate",
                "endDate",
                "isKeepRangeEnabled",
                "differentDatesFlag",
                "conflictWarningFlag",
                "timezone",
                "range",
                "warningMessage",
                "deliverablesToChange",
                "showTemplateId", // optional
                "dateSelectionType", // optional
                "unsetUnavailable", // optional
                "unsetLabel", // optional with fallback
                "ariaLabel",
                "actions",
                "autoClose",
                "escapeToClose",
            ],
            options: function selectDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: ChangeTaskDatetimeDialogCtrl,
                    controllerAs: "dialog",
                    bindToController: true,
                };
            },
        });
    });
