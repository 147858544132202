import _ from "lodash";
import moment from "moment";
import Note from "./../../../../../domain/sb_note.class";
import Checklist from "./model/sb_checklist.model";
import ChecklistItem from "./model/sb_checklist_item.model";

/**
 *
 * @returns {ActivityChecklistMapper}
 * @constructor
 */
function ActivityChecklistMapper() {
    return this;
}

/**
 * Map a checklist api response to a Checklist entity.
 *
 * @param {Object} apiObject
 *
 * @returns {Checklist | undefined}}
 */
ActivityChecklistMapper.toChecklistDomain = function (apiObject) {
    if (!apiObject) {
        return;
    }

    const checklist = new Checklist(apiObject.id, apiObject.name);
    checklist.setDate(apiObject.created.at);

    checklist.setAuthor({
        mail: apiObject.created.by.email,
        username: apiObject.created.by.id,
        initials: apiObject.created.by.initials,
        displayName: apiObject.created.by.name,
    });

    const items = apiObject.checklist_items.map((item) =>
        this.parseRawChecklistItem(item)
    );
    checklist.setItems(items);

    return checklist;
};

/**
 * Instantiate a ChecklistItem from a raw server model
 *
 * @param rawChecklistItem
 * @return {ChecklistItem}
 * @private
 */
ActivityChecklistMapper.parseRawChecklistItem = function (rawChecklistItem) {
    const checklistItem = new ChecklistItem(
        rawChecklistItem.id,
        makeAnswerKey(rawChecklistItem.answer),
        rawChecklistItem.description
    );

    if (_.isArray(rawChecklistItem.notes)) {
        const notes = rawChecklistItem.notes.map((note) =>
            this.parseRawNote(note)
        );

        checklistItem.setNotes(notes);
    }

    return checklistItem;
};

/**
 * Instantiate a Note from a raw server model
 *
 * @param rawNote
 * @return {Note}
 * @private
 */
ActivityChecklistMapper.parseRawNote = function (rawNote) {
    return new Note(rawNote.id, rawNote.text)
        .setType(rawNote.type)
        .setCreateTime(moment(rawNote.reported.at, moment.ISO_8601, true));
};

function makeAnswerKey(answer) {
    switch (answer) {
        case "yes":
            return "Y";
        case "no":
            return "N";
        case "not_applicable":
            return "NA";
        default:
            return "B";
    }
}

export default ActivityChecklistMapper;
