import _ from "lodash";

export default function visualizationListCtrl(
    GeneratedDrawing,
    UploadedDrawing,
    $scope,
    $sbSvg,
    $sbCurrentProject,
    $state,
    $sbErrorPresenter,
    $mdDialog,
    $mdToast,
    $sbImageApi,
    $q,
    SABLONO_STATES,
    EVENTS,
    Analytics
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.project = undefined;
    vm.visualizations = [];
    vm.allowedExtensions = [".svg"];

    vm.loadNewFile = loadNewFile;
    vm.createVisualization = createVisualization;
    vm.uploadFileToServer = uploadFileToServer;
    vm.onWrongExtension = onWrongExtension;
    vm.showDeleteConfirmationBox = showDeleteConfirmationBox;
    vm.deleteVisualization = deleteVisualization;
    vm.navigateTo = navigateTo;
    vm.getBadgeText = getBadgeText;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    //Implementing the search broadcast receiver
    $scope.$on(EVENTS.GLOBAL_SEARCH_CHANGE, function (event, eventData) {
        vm.searchTerm = {
            name: eventData.searchTerm,
        };
    });

    $scope.$on("$stateChangeSuccess", function (event, toState) {
        if (
            toState.name === SABLONO_STATES.visualizationSketch ||
            toState.name === SABLONO_STATES.visualizationGenerated ||
            toState.name === SABLONO_STATES.visualizationConfiguration
        ) {
            vm.substateVisible = true;
        } else {
            vm.substateVisible = false;
            activate(); // reload data when going back
        }
    });

    // I this really needed?  The activate two lines above fires when the page is navigated to :S
    // activate();

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function activate() {
        vm.project = $sbCurrentProject.get();
        vm.isPageLoading = true;
        vm.searchTerm = {};
        return fetchVisualizationInfoByProject(vm.project.id).finally(() => {
            vm.isPageLoading = false;
        });
    }

    function fetchVisualizationInfoByProject(projectId) {
        return $sbSvg
            .fetchAllVisualizationsByProject(projectId)
            .then(function (visualizations) {
                return $q
                    .all(
                        visualizations.map((visualization) => {
                            if (!_.isEmpty(visualization.preview)) {
                                return $sbImageApi.downloadThumbImage(
                                    projectId,
                                    visualization.preview.imageId
                                );
                            }
                        })
                    )
                    .then((images) => {
                        images.map(function (image, index) {
                            visualizations[index].preview.thumbnail = image;
                        });
                    })
                    .catch($sbErrorPresenter.catch)
                    .finally(() => (vm.visualizations = visualizations));
            });
    }

    function createVisualization() {
        $state.go(SABLONO_STATES.visualizationConfiguration);
    }

    function loadNewFile($event) {
        $scope.$broadcast("sbFileInput.open", $event);
    }

    function uploadFileToServer($event, file, name) {
        _showLoadingToast();
        $sbSvg
            .uploadVisualization(file, vm.project.id, name)
            .then(function (svg) {
                Analytics.trackEvent(
                    "2D visualization",
                    "Create visualization",
                    "File uploaded"
                );
                $state.go(SABLONO_STATES.visualizationSketch, {
                    id: svg.id,
                });
                // refresh the list of available svg
                activate();
            })
            .catch($sbErrorPresenter.catch)
            .finally(() => $mdToast.hide());
    }

    /**
     * Shows the invalid extension error dialog
     */
    function onWrongExtension() {
        $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_WRONG_EXTENSION_TITLE")
                .content("DIALOG_WRONG_EXTENSION_CONTENT")
        );
    }

    function showDeleteConfirmationBox($event, svgKey, deleteCallback) {
        $event.stopPropagation();
        return $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .title("CONFIRM_SVG_DELETION_TITLE")
                    .content("CONFIRM_SVG_DELETION_MESSAGE")
                    .ok("YES")
                    .targetEvent($event)
            )
            .then(function onConfirm() {
                deleteCallback(svgKey)
                    .then(showDeleteSuccessToast)
                    .catch($sbErrorPresenter.catch);
            });
    }

    function deleteVisualization(svgKey) {
        return $sbSvg.removeVisualization(svgKey).then(function () {
            removeFromFrontEnd("visualizations", svgKey);
        });
    }

    function removeFromFrontEnd(listName, svgKey) {
        vm[listName] = _.filter(vm[listName], function (svg) {
            return svg.key !== svgKey;
        });
    }

    function showDeleteSuccessToast() {
        $mdToast.show(
            $mdToast.simple().content("INFO_SVG_DELETION_SUCCESSFUL")
        );
    }

    function navigateTo(drawing) {
        let state = SABLONO_STATES.visualizationSketch;

        if (drawing instanceof GeneratedDrawing) {
            state = SABLONO_STATES.visualizationGenerated;
        }
        return `${state}({id: ${drawing.key}})`;
    }

    function getBadgeText(drawing) {
        let sourceType = "_UPLOADED";

        if (drawing instanceof GeneratedDrawing) {
            sourceType = "_GENERATED";
        }
        return sourceType;
    }

    function _showLoadingToast() {
        return $mdToast.show(
            $mdToast
                .loading()
                .content("UPLOAD_SVG_FILE")
                .hideDelay(false)
                .position("top right")
        );
    }
}
