export default function ($mdDialog, $sbExternalService) {
    "ngInject";

    var vm = this;

    vm.confirmResetInput = "";
    vm.isResetActivityConfirmed = isResetActivityConfirmed;
    vm.cancel = $mdDialog.hide;
    vm.confirm = confirm;
    vm.loading = false;

    function isResetActivityConfirmed() {
        return vm.confirmResetInput !== "RESET";
    }

    function confirm() {
        vm.loading = true;

        let resetActivitiesJobDefinition;

        if (vm.activityIds) {
            resetActivitiesJobDefinition = {
                activity_ids: vm.activityIds,
            };
        } else {
            resetActivitiesJobDefinition = {
                deliverable_set: vm.deliverableSet,
                activity_templates: vm.activityTemplates,
            };
        }

        $sbExternalService
            .resetActivitiesJob(vm.projectId, resetActivitiesJobDefinition)
            .then((res) => {
                vm.loading = false;
                $mdDialog.hide(res.response);
            });
    }
}
