import _ from "lodash";

export default function validatorService() {
    var factory = {
        /**
         * Checks if all elements in the array unique and calls the given function
         * back with the boolean result of the calculation.
         * @param {Array} aValues Values to check
         * @returns {boolean}
         */
        isAllUniqueStringOrNumbers: function (aValues) {
            if (!Array.isArray(aValues)) return false;
            const isAllStringsOrNumbers = _.every(aValues, (v) => {
                return _.isString(v) || _.isNumber(v);
            });
            if (!isAllStringsOrNumbers) return false;

            const allValuesAsString = aValues.map((v) => {
                return v.toString();
            });

            const isWithoutDuplicates =
                _.uniq(allValuesAsString).length === aValues.length;
            return isAllStringsOrNumbers && isWithoutDuplicates;
        },

        /**
         * Parse given array if contained dates
         * @param {Array} aValues Values to Check
         * @returns {boolean}
         */
        isArrayOfDates: function (aValues) {
            // check for array
            //
            if (!_.isArray(aValues) || aValues.length === 0) {
                return false;
            }

            // remove empty entries
            //
            const validValues = _.without(aValues, null, undefined);
            if (validValues.length === 0) {
                return false;
            }

            // check if every value is date
            //
            return validValues.every(function (value) {
                return _.isDate(value);
            });
        },

        /*
         * Checks if given value is a string.
         * @param {string} sValue Value to check
         * @returns {boolean}
         */
        isString: function (sValue) {
            return _.isString(sValue);
        },

        /*
         * Checks if given value is a string and is not empty.
         * @param {string} sValue Value to check
         * @returns {boolean}
         */
        isNotEmptyString: function (sValue) {
            return factory.isString(sValue) && sValue.length > 0;
        },

        /*
         * Check if given value is a number.
         * @param {number} numValue Value to check
         * @returns {boolean}
         */
        isNumber: function (numValue) {
            return _.isNumber(numValue);
        },

        /**
         * Check if given array only contains not empty strings
         * @param {Array} aValues Values to check
         * @returns {*}
         */
        isArrayOfNotEmptyStrings: function (aValues) {
            // check for array
            //
            if (!Array.isArray(aValues)) {
                return false;
            }

            var notPassed = aValues.filter(function (value) {
                return !factory.isNotEmptyString(value);
            });

            if (notPassed.length > 0) {
                return [false, notPassed];
            }

            return [true];
        },

        /**
         * Check if given array of strings
         * @param {Array} aValues Values to check
         * @returns {*}
         */
        isArrayWithAnything: function (aValues) {
            // check for array
            //
            if (!Array.isArray(aValues)) {
                return false;
            }

            return [true];
        },
    };

    return factory;
}
