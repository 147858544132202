const _ = require("lodash");

function ChecklistTemplateItem(id, description) {
    this.id = id || null;
    this.description = description;

    // should be not mandatory by default
    this.isConfirmRequired = false;

    // should be mandatory by default
    this.isWorkRequired = true;

    // should be not mandatory by default
    this.isWorkImageRequired = false;
}

/**
 * @param {boolean} isWorkRequired
 *
 * @returns {ChecklistTemplateItem}
 */
ChecklistTemplateItem.prototype.setIsWorkRequired = function (isWorkRequired) {
    if (_.isBoolean(isWorkRequired)) {
        this.isWorkRequired = isWorkRequired;
    } else {
        throwError("isWorkRequired");
    }
    return this;
};

/**
 * @param {boolean} isConfirmRequired
 *
 * @returns {ChecklistTemplateItem}
 */
ChecklistTemplateItem.prototype.setIsConfirmRequired = function (
    isConfirmRequired
) {
    if (_.isBoolean(isConfirmRequired)) {
        this.isConfirmRequired = isConfirmRequired;
    } else {
        throwError("isConfirmRequired");
    }
    return this;
};

/**
 * @param {boolean} isWorkImageRequired
 *
 * @returns {ChecklistTemplateItem}
 */
ChecklistTemplateItem.prototype.setIsWorkImageRequired = function (
    isWorkImageRequired
) {
    if (_.isBoolean(isWorkImageRequired)) {
        this.isWorkImageRequired = isWorkImageRequired;
    } else {
        throwError("isWorkImageRequired");
    }

    return this;
};

/**
 * Generate the correct payload for saving to the database
 * @param {boolean} isWorkRequired
 * @returns {*}
 */
ChecklistTemplateItem.prototype.toServerRequestModel = function () {
    return {
        id: this.id,
        description: this.description,
        config: {
            answer_required_for: {
                confirm: this.isConfirmRequired,
                review: this.isConfirmRequired,
                work: this.isWorkRequired,
            },
            image_required_for: {
                work: this.isWorkImageRequired,
            },
        },
    };
};

function throwError(argName) {
    throw new Error(`Argument to '${argName}' must be of type boolean`);
}

export default ChecklistTemplateItem;
