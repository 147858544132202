import _ from "lodash";
import htmlTemplate from "./sb_merge_wizard.html";
import { ProjectSource } from "../../../../domain/sb_project_source.class";

export default function sbMergeWizard() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        replace: true,
        scope: {
            src: "=",
        },
        //link: function postLink(scope) {},
        controllerAs: "mergeWizard",
        controller: function sbMergeWizardCtrl(
            $scope,
            $log,
            $state,
            mergeService,
            intercomService,
            $mdDialog,
            $stateParams
        ) {
            "ngInject";
            var vm = this;
            var file;

            /////////////////////
            //
            //      SCOPE properties
            //
            /////////////////////

            // File source
            vm.src = $scope.src;
            vm.allowedExtensions = [".xml"];
            vm.projectId = $stateParams.projectId;
            vm.isExcel = $scope.src.type === ProjectSource.TYPES.EXCEL;
            vm.activeStepIndex = vm.isExcel ? 1 : 0;
            vm.loadingStepIndex = 2;
            vm.steps = [
                "SECTION_MERGE_STEP_1_STEPPER",
                "SECTION_MERGE_STEP_2_STEPPER",
                "SECTION_MERGE_STEP_3_STEPPER",
                "SECTION_MERGE_STEP_5_STEPPER",
            ];

            vm.unexpectedError = false;
            vm.mergeOptions = {};
            vm.analyzing = true;
            vm.openIntercomChat = intercomService.show;

            /**
             * The upload request response should be stored here.
             * @type {ComponentCreationResultDAO}
             */
            vm.uploadResponseDAO = undefined;

            vm.setDeleteFlagAndContinue = setDeleteFlagAndContinue;
            vm.setBringBackFlagAndContinue = setBringBackFlagAndContinue;

            vm.onFileSelected = onFileSelected;
            vm.openFileChooser = openFileChooser;
            vm.onWrongExtension = onWrongExtension;

            /////////////////////
            //
            //      IMPL
            //
            /////////////////////

            /**
             * Sets the user choice on deleting/keeping sablono items and moves forward.
             * @param {Boolean} shouldDeleteSablonoEntities
             */
            function setDeleteFlagAndContinue(shouldDeleteSablonoEntities) {
                vm.mergeOptions = {
                    shouldDelete: shouldDeleteSablonoEntities,
                };
            }

            /**
             * Sets the user choice on deleting/keeping sablono items and moves forward.
             * @param {Boolean} shouldBringBackSablonoEntities
             */
            function setBringBackFlagAndContinue(
                shouldBringBackSablonoEntities
            ) {
                vm.mergeOptions.shouldBringBack =
                    shouldBringBackSablonoEntities;
                _goToNextStep();
            }

            // Workflow functions
            function _goToSuccessStep() {
                _goToStep(vm.steps.length - 1);
            }

            function _goToNextStep() {
                _goToStep(vm.activeStepIndex + 1);
            }

            function _goToStep(step) {
                vm.activeStepIndex = step;

                if (step === vm.loadingStepIndex) {
                    return _doBackendHeavyStep($scope.src, vm.mergeOptions);
                }
            }

            function onFileSelected(fileData) {
                file = fileData;
                _goToNextStep();
            }

            function openFileChooser($event) {
                $scope.$broadcast("sbFileInput.open", $event);
            }

            /**
             * Callback method to cater the wrong extension of file.
             * @param {Object} error - Error object to be logged.
             */
            function onWrongExtension(error) {
                $mdDialog.show(
                    $mdDialog
                        .alert()
                        .title("DIALOG_WRONG_EXTENSION_TITLE")
                        .content("DIALOG_WRONG_EXTENSION_CONTENT")
                );
            }

            /**
             * Based on the selected file:
             *  # read the file
             *  # parse the file to JS objects
             *  # send the component data to the server and "ask" for a merge
             *  # based on the result
             *      -> check if there are orphan structuring nodes or deliverables
             *         that should be presented to the user
             *
             * @private
             */
            function _doBackendHeavyStep(src, config) {
                if (src.type === ProjectSource.TYPES.EXCEL) {
                    const params = {
                        sourceKey: src.id,
                    };
                    params["block-bring-back"] = !config.shouldBringBack;
                    params["block-deletion"] = !config.shouldDelete;
                    return $state
                        .go("sablono.project.import.excel", params)
                        .catch(_handlePostProcessingError);
                } else {
                    return mergeService
                        .readAndParseFile(file, src, vm.mergeOptions)
                        .then(_handleComponentMergeResult)
                        .catch(_handlePostProcessingError);
                }
            }

            /**
             * Just store the result object and check for problems.
             *
             * @param uploadResult
             * @returns {Promise}
             * @private
             */
            function _handleComponentMergeResult(uploadResult) {
                // store the dao for later use on the success page.
                //
                vm.uploadResponseDAO = uploadResult;

                _goToNextStep();
            }

            /**
             * Handles any error occurred during data post processing.
             * Goes to a success state with a flag with the error.
             *
             * @param error
             * @private
             */
            function _handlePostProcessingError(error) {
                $log.warn(error);
                vm.analyzing = false;
                vm.unexpectedError = true;
                _goToSuccessStep();
            }
        },
    };
}
