import moment, { isMoment } from "moment";
import _ from "lodash";

export default function WorkflowRowCtrl(
    $filter,
    PADDING_SIZES,
    sbDeliverableWorkflowService,
    $scope,
    $sbDetailsOverlay,
    $sbActivityStateTransitionUI,
    $sbMembership,
    $sbTeam
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var translate = $filter("translate");

    /////////////////////
    //
    //      View model properties
    //
    /////////////////////

    vm.row = {};
    vm.hasFullAccessTeamPermissions = hasFullAccessTeamPermissions();
    vm.onTimelineClick = onTimelineClick;

    /////////////////////
    //
    //      Lifecycle Hooks
    //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
    //
    /////////////////////

    vm.$onChanges = function () {
        vm.row = mapTo(vm.node);
    };

    // state is the only property change that supports change detection
    //
    $scope.$watch("$ctrl.node.state.current", function () {
        // full update of row as new permissions needs to be evaluated
        //
        vm.row = mapTo(vm.node);
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function mapTo(node) {
        const { information, options } =
            $sbActivityStateTransitionUI.selectViewModelFor(node);

        return {
            id: node.id,
            padding:
                sbDeliverableWorkflowService.getParentCount(node) *
                    PADDING_SIZES.increment +
                PADDING_SIZES.initial +
                "px",
            state: {
                information,
                isDisabled: !vm.treeCtrl.isStateChangeAllowed(node),
                hasTooltip: vm.treeCtrl.hasTooltip(node),
                enum: node.state.current,
                options,
            },
            topology: {
                number: node.topologicalIndex,
                isShown: !node.isCustomActivity() && node.topologicalIndex,
            },
            text: node.name,
            dates: _deriveDateView(node),
            tags: {
                claims: node._noteStatistic.openClaims,
                obstructions: node._noteStatistic.openObstructions,
                isBehind: node.isBehind(),
                isUserDefined: node.dateSource === vm.treeCtrl.DATE_SOURCE.USER,
                isConflicting: vm.treeCtrl.isUserDateViolated(node),
            },
            update: {
                hasUpdate:
                    isMoment(node.state.changedAt) &&
                    node.state.changedAt.isValid(),
                authorInitials: _.get(
                    node,
                    "state.changedByUser.initials",
                    "??"
                ),
                authorName: _.get(
                    node,
                    "state.changedByUser.displayName",
                    "User left the project"
                ),
                changedAt: _.get(node, "state.changedAt"),
            },
            team: node.assignedTeam
                ? translate(node.assignedTeam.getDisplayName())
                : translate("_UNRESTRICTED_TEAM"),
        };
    }

    function hasFullAccessTeamPermissions() {
        const currentTeam = $sbMembership.currentTeam();
        const isFullAccessTeam = $sbTeam.findProjectTeam([currentTeam]);

        return isFullAccessTeam ? true : false;
    }

    function _deriveDateView(node) {
        // srp / look ahead / last planner dates are leading
        if (
            moment.isMoment(node.lastPlannerStart) &&
            node.lastPlannerStart.isValid()
        ) {
            return {
                isVisible: true,
                startLabel: "LAST_PLANNER_START",
                endLabel: "LAST_PLANNER_END",
                start: moment
                    .tz(node.lastPlannerStart, node.timezone)
                    .format("L"),
                end: moment.tz(node.lastPlannerEnd, node.timezone).format("L"),
            };
        }

        // baseline earliest dates are leading
        if (
            moment.isMoment(node.earliestStart) &&
            node.earliestStart.isValid()
        ) {
            return {
                isVisible: true,
                startLabel: "EARLIEST_START",
                endLabel: "EARLIEST_END",
                start: moment.tz(node.earliestStart, node.timezone).format("L"),
                end: moment.tz(node.earliestEnd, node.timezone).format("L"),
            };
        }

        // baseline latest dates are leading
        if (moment.isMoment(node.latestStart) && node.latestStart.isValid()) {
            return {
                isVisible: true,
                startLabel: "LATEST_START",
                endLabel: "LATEST_END",
                start: moment.tz(node.latestStart, node.timezone).format("L"),
                end: moment.tz(node.latestEnd, node.timezone).format("L"),
            };
        }

        return {
            isVisible: false,
        };
    }

    function onTimelineClick(row) {
        $sbDetailsOverlay.toggleView("activity", row.id);
    }
}
