export default function ChecklistTemplateRowCtrl($scope) {
    "ngInject";

    var vm = this;

    vm.onNameChange = onNameChange;

    function onNameChange(isValid) {
        if (isValid) {
            vm.onNameBlur();
        } else {
            vm.onNameBlurInvalid();
        }
    }
}
