import angular from "angular";
import has from "has";
import moment from "moment";
import "moment-timezone";
import "common/services/services";
import "common/intercom/intercomModule";

export default function ExcelCtrl(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $cookies,
    $log,
    $timeout,
    $mdDialog,
    $mdToast,
    $controller,
    $sbErrorPresenter,
    $sbProject,
    calendar,
    $sbFileReader,
    $sbParseExcel,
    $sbComponent,
    inputSuggestion,
    EXCEL_EXTENSIONS,
    MAX_ROWS_FOR_AUTO_PREVIEW_REFRESH,
    ACCEPTED_DATETIME_FORMATS,
    sbExcelService
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var codeSelect = {
        name: "CODE",
        type: "code",
        value: "",
        as: "code",
        formatter: sbExcelService.makeString,
        required: true,
        suggestions: [],
        defaultSuggestions: ["code", "item no.", "bauteil-id", "external_id"],
    };

    var crtExcelData;

    var structureSuggestions = [];
    var levelSuggestionsSorted = [];

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.allowedExtensions = EXCEL_EXTENSIONS;

    vm.selectedFile = false;
    vm.dataUploading = false;
    vm.uploadSuccess = false;

    vm.rawDataPreview = false;
    vm.previewDirty = true;
    vm.refreshPreview = false;

    vm.fileProcessing = false;
    vm.fileName = "";
    vm.validFile = false;

    vm.levels = [
        {
            value: "",
            active: true,
            suggestions: [],
        },
    ];

    vm.rowAssignList = [
        {
            name: "NAME",
            type: "name",
            value: "",
            as: "name",
            required: true,
            formatter: sbExcelService.makeString,
            suggestions: [],
            defaultSuggestions: ["name"],
        },
        codeSelect,
        {
            name: "_DESC",
            type: "description",
            value: "",
            as: "desc",
            formatter: sbExcelService.makeString,
            active: false,
            suggestions: [],
            defaultSuggestions: ["beschreibung", "description", "desc"],
        },
        {
            name: "START_DATE",
            type: "startDate",
            value: "",
            as: "sd",
            formatter: (v) => {
                if (!v) return undefined;
                return makeMomentInProjectTz(v).startOf("day").valueOf();
            },
            suggestions: [],
            defaultSuggestions: ["start date", "startdate", "sd"],
        },
        {
            name: "DUE_DATE",
            type: "completionDate",
            value: "",
            as: "cd",
            formatter: (v) => {
                if (!v) return undefined;
                return makeMomentInProjectTz(v).endOf("day").valueOf();
            },
            suggestions: [],
            defaultSuggestions: [
                "completion date",
                "completiondate",
                "due date",
                "cd",
            ],
        },
    ];

    // variable to display raw xls data
    vm.xlsData = {
        headers: [],
        content: [],
        sortable: [],
    };

    vm.openFileChooser = openFileChooser;
    vm.onFileChanged = onFileChanged;
    vm.broadcastPreview = broadcastPreview;
    vm.setPreviewDirty = setPreviewDirty;

    vm.goToRawDataPreview = goToRawDataPreview;
    vm.goToPreview = goToPreview;

    vm.pushLevel = pushLevel;
    vm.removeLevel = removeLevel;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $rootScope.$on("$stateChangeSuccess", initView);

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function initView() {
        vm.selectedFile = false;
        vm.dataUploading = false;
        vm.uploadSuccess = false;
        vm.rawDataPreview = false;
        vm.fileProcessing = false;
        vm.fileName = "";
    }

    function onFileChanged($event, file, name) {
        vm.fileProcessing = true;
        vm.fileName = name;

        $sbFileReader
            .readFile(file, "readAsBinaryString")
            .then(_doFileStuff)
            .catch(function (error) {
                $log.error(error);
                $mdDialog.show(
                    $mdDialog
                        .alert()
                        .title("DIALOG_READING_FILE_ERROR_TITLE")
                        .content("DIALOG_READING_FILE_ERROR_CONTENT")
                );
            })
            .finally(function () {
                vm.fileProcessing = false;
            });
    }

    /**
     * On successful file read -> process the file data further.
     *  # send it to preview
     *  # calculate suggestions
     *
     * @param fileData
     * @private
     */
    function _doFileStuff(fileData) {
        vm.selectedFile = true;
        vm.rawDataPreview = false;
        vm.uploadSuccess = false;

        try {
            var workbook = $sbParseExcel.loadFile(fileData.rawData);
            var rowData = $sbParseExcel.readSheet(workbook, $sbParseExcel.ROW);

            vm.previewDirty = true;
            crtExcelData = rowData;
            _setRawDataPreview();
            broadcastPreview();

            var columnData = $sbParseExcel.readSheet(
                workbook,
                $sbParseExcel.COLUMN
            );
            vm.validFile = true;

            inputSuggestion
                .generateSuggestion(columnData)
                .then(setSuggestions)
                .catch($sbErrorPresenter.catch);
        } catch (e) {
            $log.error(e);
            $mdDialog.show(
                $mdDialog
                    .alert()
                    .title("DIALOG_IMPORT_FILE_ERROR_TITLE")
                    .content("DIALOG_IMPORT_FILE_ERROR_CONTENT")
            );
        }
    }

    /**
     * Take the suggestion results and update the UI
     *
     * @param suggest
     */
    function setSuggestions(suggest) {
        var badLevelSuggestions = vm.rowAssignList.reduce(
            function (prevDefaultSuggestionList, row) {
                return prevDefaultSuggestionList.concat(row.defaultSuggestions);
            },
            ["project"]
        );

        vm.rowAssignList.map(function (row) {
            row.suggestions = suggest[row.type] || [];

            //default suggestion
            row.value = row.suggestions.filter(function (suggestion) {
                suggestion = suggestion.toLowerCase();
                return row.defaultSuggestions.indexOf(suggestion) !== -1;
            })[0];

            if (row.required && angular.isUndefined(row.value)) {
                row.value = row.suggestions[0];
            }

            if (row.suggestions.length === 0 && row.required) {
                vm.validFile = false;
            }

            return row;
        });

        structureSuggestions = suggest.structure;

        levelSuggestionsSorted = structureSuggestions.sort(
            function (aRowName, bRowName) {
                var a =
                    badLevelSuggestions.indexOf(aRowName.toLowerCase()) === -1
                        ? 0
                        : 1;
                var b =
                    badLevelSuggestions.indexOf(bRowName.toLowerCase()) === -1
                        ? 0
                        : 1;
                return a - b;
            }
        );

        var levelsCount = vm.levels.length;

        vm.levels = [];

        for (var i = 0; i < levelsCount; i++) {
            vm.pushLevel();
        }
    }

    /**
     * Mark the preview as dirty.
     */
    function setPreviewDirty() {
        vm.previewDirty = true;
        if (crtExcelData && crtExcelData.data) {
            if (crtExcelData.data.length <= MAX_ROWS_FOR_AUTO_PREVIEW_REFRESH) {
                broadcastPreview();
            }
        }
    }

    /**
     * Send the latest excel data set to the preview table
     */
    function _setRawDataPreview() {
        if (!crtExcelData) return;
        vm.xlsData.headers = crtExcelData.headers.map(
            function (fieldHead, index) {
                return {
                    name: fieldHead,
                    //convert to string for angular filters
                    field: index.toString(),
                };
            }
        );
        vm.xlsData.content = crtExcelData.data;

        // This is setting the property that should be sorted in the header.
        // We have to map this if we want headers to be sortable on
        // sbTable directive.
        vm.xlsData.sortable = vm.xlsData.headers.map(function (header) {
            return header.field;
        });
    }

    /**
     * Send the current data state to the data preview.
     */
    function broadcastPreview() {
        if (!crtExcelData || !vm.previewDirty || vm.refreshPreview) {
            return;
        }

        vm.refreshPreview = true;

        $timeout(function () {
            vm.refreshPreview = false;
            vm.previewDirty = false;

            _buildUploadModel()
                .then(function (uploadModel) {
                    const blockDelete = $stateParams["block-deletion"];
                    const blockBringBack = $stateParams["block-bring-back"];

                    if (blockDelete === "true") {
                        uploadModel.setBlockDeletion(true);
                    }
                    if (blockBringBack === "true") {
                        uploadModel.setBlockBringBack(true);
                    }

                    // store the latest upload model in the rootImport view model
                    //
                    $scope.rootImport.setUploadModel(uploadModel, vm.fileName);

                    // inform the preview
                    //
                    $scope.$broadcast("sb.upload.preview", {
                        headerTexts: {
                            structure: "STRUCTURE",
                            projectElements: "PROJECT_ELEMENTS",
                        },
                        uploadModel: uploadModel,
                    });
                })
                .catch(invalidStructureInRows);
        }, 100);
    }

    /**
     * Create an upload model based on the view properties.
     *
     * @returns {*}
     * @private
     */
    function _buildUploadModel() {
        var header = _getSelectedHeader();

        return sbExcelService
            .buildComponentsFromAssignment(header, crtExcelData, vm.levels)
            .then(function (componentsToSend) {
                var uploadObject = {
                    projectId: $sbProject.getCurrentProjectId(),
                    components: componentsToSend,
                    structure: sbExcelService.asStructureList(componentsToSend),
                };

                if ($state.params.sourceKey) {
                    uploadObject.sourceKey = $state.params.sourceKey;
                } else {
                    // store the mapping Key (CODE property) in the DATA object
                    crtExcelData.source.data.mappingKey = codeSelect.value;
                    uploadObject.source = crtExcelData.source;
                }

                return $sbComponent.createComponentUploadModel(uploadObject);
            });
    }

    /**
     * Create nice header objects based on the selected columns and the meta data object
     * of the file.
     *
     * @returns {Array}
     * @private
     */
    function _getSelectedHeader() {
        return vm.rowAssignList
            .filter(function (row) {
                return crtExcelData.headers.indexOf(row.value) !== -1;
            })
            .map(function (row) {
                return {
                    name: row.name,
                    as: row.as,
                    field: crtExcelData.headers.indexOf(row.value).toString(),
                    formatter: row.formatter,
                };
            });
    }

    function invalidStructureInRows(invalidRowNumbers) {
        var invalidRowNumberRanges = sbExcelService.reduceNumbersToRanges(
            invalidRowNumbers,
            true
        );

        $mdDialog.show(
            $mdDialog
                .alert()
                .title("ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_TITLE")
                .content("ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_MESSAGE")
                .contentValues({
                    invalidRowNumberRanges: invalidRowNumberRanges,
                })
        );

        goToRawDataPreview();
    }

    /**
     * Add a level to the structure selection
     */
    function pushLevel() {
        var level = vm.levels.length + 1;

        vm.levels.push({
            level: level,
            value: levelSuggestionsSorted[
                Math.min(structureSuggestions.length - 1, level - 1)
            ],
            active: true,
            suggestions: structureSuggestions,
        });
        setPreviewDirty();
    }

    /**
     * Remove a specific level from the structure selection
     * @param level
     */
    function removeLevel(level) {
        var index = vm.levels.indexOf(level);
        vm.levels.splice(index, 1);
        setPreviewDirty();
    }

    /**
     * Show the raw data preview
     *
     * @param $event
     */
    function goToRawDataPreview($event) {
        if (has("development")) {
            $log.debug($event);
        }

        vm.rawDataPreview = true;
    }

    /**
     * Show the upload preview
     *
     * @param $event
     */
    function goToPreview($event) {
        if (has("development")) {
            $log.debug($event);
        }
        if (!vm.previewDirty) {
            vm.rawDataPreview = false;
        } else {
            broadcastPreview();
        }
    }

    /**
     * Open a file chooser
     * @param $event
     */
    function openFileChooser($event) {
        $scope.$broadcast("sbFileInput.open", $event);
    }

    /**
     * Removes time info from the given date and converts to given timezone's midnight
     * @param {Date} v
     * @returns Milliseconds
     */
    function makeMomentInProjectTz(v) {
        return moment.tz(
            `${v.getFullYear()}-${v.getMonth() + 1}-${v.getDate()}`,
            "YYYY-MM-DD",
            calendar.TIMEZONE.name
        );
    }
}
