import angular from "angular";
import activityNodeRendererTemplate from "./nodes_renderer.html";

export default function ActivityTreeCtrl(
    ACTIVITY_TRANSITIONS,
    SbActivity,
    sbDeliverableWorkflowService
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    vm.nodesRenderer = activityNodeRendererTemplate;

    /////////////////////
    //
    //      View model properties
    //
    /////////////////////

    vm.DATE_SOURCE = SbActivity.DATE_SOURCE;
    vm.ALLOCATION_ALGORITHM = SbActivity.ALLOCATION_ALGORITHM;

    vm.isLeafActivity = sbDeliverableWorkflowService.isLeafActivity;
    vm.isUserDateViolated = sbDeliverableWorkflowService.isUserDateViolated;
    vm.isStateChangeAllowed = isStateChangeAllowed;
    vm.hasTooltip = hasTooltip;

    vm.changeState = sbDeliverableWorkflowService.generateStateChangeHandler(
        vm.onCreateNote,
        vm.onStateChange,
        vm.onFillChecklist,
        vm.onRequestActivityUpdate,
        vm.onResetActivity
    );

    /////////////////////
    //
    //      Lifecycle Hooks
    //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
    //
    /////////////////////

    vm.$onChanges = function () {
        if (vm.content && vm.content.components) {
            vm.content = vm.content.components;
        }
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function hasTooltip(component) {
        // should show up when component is an activity
        //  where you can't change progress because of access restrictions
        //
        const hasChangeSupport = angular.isFunction(vm.onStateChange);
        if (!hasChangeSupport) {
            return false;
        }

        return sbDeliverableWorkflowService.hasAccessRestrictions(component);
    }

    function isStateChangeAllowed(component) {
        const hasChangeSupport = angular.isFunction(vm.onStateChange);
        if (!hasChangeSupport) {
            return false;
        }

        return sbDeliverableWorkflowService.isStateChangeAllowed(component);
    }
}
