import featureFlagsModule from "./feature_flags.module";

featureFlagsModule.provider(
    "$sbFeatureDecisions",
    function FeatureDecisionProvider() {
        this.$get = function ($sbExternalService) {
            "ngInject";
            return {
                isFeatureEnabled(featureName) {
                    return $sbExternalService
                        .featureFlagsService()
                        .isFeatureEnabled(featureName);
                },
                updateContext(context) {
                    return $sbExternalService
                        .featureFlagsService()
                        .updateContext(context);
                },
                clearContext() {
                    return $sbExternalService
                        .featureFlagsService()
                        .clearContext();
                },
            };
        };
    }
);
