import angular from "angular";
import simpleHtml from "common/toast/translatedToast.html";
import errorHtml from "common/toast/error.html";
import loadingHtml from "common/toast/loadingToast.html";
import undoHtml from "common/toast/undoToast.html";

export default angular
    .module("sbApp.toast", ["ngMaterial"])
    .config(function ($mdToastProvider) {
        $mdToastProvider.addPreset("simple", {
            argOption: "content",
            methods: [
                "content",
                "action",
                "highlightAction",
                "theme",
                "parent",
                "contentValues",
                "resolveAction",
            ],
            options: function ($mdToast, $mdTheming) {
                "ngInject";
                var opts = {
                    templateUrl: simpleHtml,
                    controller: function mdToastCtrl() {
                        this.resolve = function () {
                            $mdToast.hide();
                        };
                    },
                    hideDelay: 3000,
                    position: "top right",
                    theme: $mdTheming.defaultTheme(),
                    controllerAs: "toast",
                    bindToController: true,
                };
                return opts;
            },
        });
        $mdToastProvider.addPreset("undo", {
            argOption: "content",
            methods: [
                "content",
                "action",
                "highlightAction",
                "theme",
                "parent",
                "contentValues",
                "resolveAction",
                "hideDelay",
            ],
            options: function ($mdToast, $mdTheming) {
                "ngInject";
                var opts = {
                    templateUrl: undoHtml,
                    controller: function mdToastCtrl() {
                        this.action = "ACTION_UNDO";

                        this.resolve = function () {
                            $mdToast.hide();
                            this.resolveAction();
                        };
                    },
                    hideDelay: 3000,
                    position: "top right",
                    theme: $mdTheming.defaultTheme(),
                    controllerAs: "toast",
                    bindToController: true,
                };
                return opts;
            },
        });
        $mdToastProvider.addPreset("error", {
            argOption: "content",
            methods: [
                "icon",
                "content",
                "theme",
                "parent",
                "contentValues",
                "actions",
                "autoClose",
            ],
            options: function ($mdToast, $mdTheming) {
                "ngInject";
                var opts = {
                    templateUrl: errorHtml,
                    controller: function mdErrorToastCtrl() {
                        this.hide = function () {
                            $mdToast.hide();
                        };
                        this.resolve = function (action) {
                            $mdToast.hide(action);
                        };
                    },
                    position: "top right",
                    hideDelay: 3000,
                    theme: $mdTheming.defaultTheme(),
                    controllerAs: "toast",
                    bindToController: true,
                };
                return opts;
            },
        });
        $mdToastProvider.addPreset("loading", {
            argOption: "content",
            methods: [
                "icon",
                "content",
                "theme",
                "parent",
                "contentValues",
                "actions",
                "autoClose",
                "hideDelay",
            ],
            options: function ($mdToast, $mdTheming) {
                "ngInject";
                var opts = {
                    templateUrl: loadingHtml,
                    controller: function mdErrorToastCtrl() {
                        this.hide = function () {
                            $mdToast.hide();
                        };
                        this.resolve = function (action) {
                            $mdToast.hide(action);
                        };
                    },
                    position: "top right",
                    hideDelay: 0,
                    theme: $mdTheming.defaultTheme(),
                    controllerAs: "toast",
                    bindToController: true,
                };
                return opts;
            },
        });
    });
