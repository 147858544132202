export default class CostPackageGroupsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint allows to group several template activities.
     *
     * @param costPackageId {number}    ID of the cost package
     * @param body {Object}             Request body
     * @param body.name {string}        User defined name of the activity set merged.
     * @param body.activity_templates {array}    Activity templates included in this activity set merged.
     */
    create(costPackageId, { name, activity_templates }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}/grouped-activities`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { name, activity_templates },
        });
    }

    /**
     * This endpoint allows to remove an activity template from a group
     *
     * @param costPackageId {number}            ID of the cost package
     * @param activityTemplateGroupId {number}  ID of activity template group
     * @param activityTemplateId {string}       UUID of the activity template
     */
    delete(costPackageId, activityTemplateGroupId, activityTemplateId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}/grouped-activities/${activityTemplateGroupId}/activity-template/${activityTemplateId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
