import moment from "moment";
import BaseComponent from "../../../../domain/sb_base_component.class";

export default function DeliverableRowCtrl($filter, $sbColor) {
    "ngInject";
    var vm = this;

    vm.$onChanges = function (changes) {
        if (changes.deliverable) {
            onDeliverableChange();
        }

        if (changes.timezone) {
            onTimezoneChange();
        }
    };

    function onTimezoneChange() {
        _calcDates(vm.deliverable, vm.timezone);
    }

    function onDeliverableChange() {
        _calcDates(vm.deliverable, vm.timezone);
        _calcTags(vm.deliverable);
        _calcMain(vm.deliverable);
        _calcStyle(vm.deliverable);
    }

    function _calcMain(deliverable) {
        if (!deliverable) {
            return;
        }

        vm.progress = deliverable.progress || 0;
        vm.issues =
            deliverable._noteStatistic.openClaims +
                deliverable._noteStatistic.openObstructions || 0;
        vm.name = deliverable.name;
        vm.code = deliverable.code;
        vm.desc = deliverable.desc || "-";
        vm.type = deliverable.template ? deliverable.template.NAME : "-";

        const structurePath = deliverable.structure
            ? deliverable.structure.createPathString()
            : " - ";
        vm.area = {
            short: $filter("ellipse")(structurePath, -42),
            full: structurePath,
        };
    }

    function _calcStyle(deliverable) {
        if (!deliverable) {
            return;
        }

        vm.classes = $sbColor.classes.fromStatus(
            vm.progress,
            deliverable.scheduleState,
            deliverable._noteStatistic.openClaims,
            deliverable._noteStatistic.openObstructions
        );
    }

    function _calcDates(deliverable, timezone) {
        if (!deliverable || !timezone) {
            return;
        }

        const latestChange = [
            deliverable.progressChangeTime,
            deliverable.issueChangeTime,
        ].filter((time) => moment.isMoment(time) && time.isValid());

        vm.dates = {
            start: _toDateView(deliverable.startDate, timezone),
            end: _toDateView(deliverable.endDate, timezone),
            inspection:
                latestChange.length > 0
                    ? _toDateView(moment.max(latestChange), timezone)
                    : _toDateView(),
            isUnscheduled:
                !moment.isMoment(deliverable.startDate) &&
                !moment.isMoment(deliverable.endDate),
        };
    }

    function _calcTags(deliverable) {
        if (!deliverable) {
            return;
        }

        vm.tags = {
            claims: deliverable._noteStatistic.openClaims || 0,
            obstructions: deliverable._noteStatistic.openObstructions || 0,
            late: deliverable.scheduleState === BaseComponent.SCHEDULE_BEHIND,
            noProcess: !deliverable.template,
            onTime:
                deliverable.scheduleState === BaseComponent.SCHEDULE_ON_TIME,
        };
    }

    function _toDateView(momentDateTime, timezone) {
        if (moment.isMoment(momentDateTime) && momentDateTime.isValid()) {
            const date = moment.tz(momentDateTime, timezone);
            return {
                isValid: true,
                short: date.format("L"),
                long: date.format("lll"),
            };
        } else {
            return {
                isValid: false,
                short: undefined,
                long: undefined,
            };
        }
    }
}
