import angular from "angular";

export default function () {
    /////////////////////
    //
    //      API
    //
    /////////////////////

    // get all watchers on the whole page
    // getWatchers();

    // get watchers of a specific element (and its children)
    // getWatchers(document.body);

    // select the element of interest in Chrome Dev tools
    // getWatchers($0);

    var service = {
        getWatchers: getWatchers,
        countWatchers: countWatchers,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function getWatchers(root) {
        root = angular.element(root || document.documentElement);

        var watcherCount = 0;
        var scopeArr = [];

        function getElemWatchers(element) {
            var isolateWatchers = getWatchersFromScope(
                element.data().$isolateScope
            );
            var scopeWatchers = getWatchersFromScope(element.data().$scope);
            var watchers = scopeWatchers.concat(isolateWatchers);

            angular.forEach(element.children(), function (childElement) {
                watchers = watchers.concat(
                    getElemWatchers(angular.element(childElement))
                );
            });

            return watchers;
        }

        function getWatchersFromScope(scope) {
            if (scope && scopeArr.indexOf(scope.$id) === -1) {
                scopeArr.push(scope.$id);
                return scope.$$watchers || [];
            } else {
                return [];
            }
        }

        return getElemWatchers(root);
    }

    function countWatchers(root) {
        var element = angular.element(root || document.documentElement);
        var scope = element.data().$scope;

        if (scope) {
            return scope.$$watchersCount;
        }

        return 0;
    }
}
