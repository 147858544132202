import _ from "lodash";
import moment from "moment";

export default function (
    $mdDialog,
    $sbTeam,
    SbNote,
    SbTeam,
    $sbProjectCurrentSettings
) {
    "ngInject";
    var exports = {
        showForDeliverable: showForNewNoteWithDeliverableDefaults,
        showForActivity: showForNewNoteWithActivityDefaults,
        showForExistingNote: showForExistingNote,
    };
    return exports;

    //
    //  IMPL
    //

    function showForExistingNote(note, teams) {
        return $sbProjectCurrentSettings
            .getConfirmationTeamSettings()
            .then((confirmationTeamSettings) => {
                return _show({
                    title: "DIALOG_EDIT_ISSUE_TITLE",
                    teams: teams,
                    note: note,
                    confirmationTeamSettings: confirmationTeamSettings,
                });
            });
    }

    function showForNewNoteWithDeliverableDefaults(teams) {
        return $sbProjectCurrentSettings
            .getConfirmationTeamSettings()
            .then((confirmationTeamSettings) => {
                const note = new SbNote();
                note.setResponsibleTeam($sbTeam.findProjectTeam(teams));
                note.setCreateTime(moment());

                return _show({
                    title: "DIALOG_ISSUE_EDITOR_TITLE",
                    teams: teams,
                    confirmationTeamSettings: confirmationTeamSettings,
                    note: note,
                });
            });
    }

    function showForNewNoteWithActivityDefaults(
        defaultTeam,
        teams,
        filterExtensions
    ) {
        return $sbProjectCurrentSettings
            .getConfirmationTeamSettings()
            .then((confirmationTeamSettings) => {
                let teamToAssign;
                if (defaultTeam) {
                    teamToAssign = _.find(
                        teams,
                        _.matchesProperty("id", defaultTeam.id)
                    );
                } else {
                    teamToAssign = SbTeam.createUnrestrictedTeam();
                }

                const note = new SbNote();
                note.setResponsibleTeam(teamToAssign);
                note.setCreateTime(moment());

                return _show({
                    title: "DIALOG_ISSUE_EDITOR_TITLE",
                    teams: teams,
                    confirmationTeamSettings: confirmationTeamSettings,
                    note: note,
                    filterExtensions: filterExtensions,
                });
            });
    }

    function _show(setup) {
        const dialog = $mdDialog
            .issueEditor()
            .title(setup.title)
            .teamSelectorTitle("DIALOG_ISSUE_EDITOR_TEAM_SELECTOR_LABEL")
            .teamConfirmationTitle(
                "DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_LABEL"
            )
            .note(setup.note)
            .teams(setup.teams)
            .confirmationTeamSettings(setup.confirmationTeamSettings)
            .filterExtensions(setup.filterExtensions);

        return $mdDialog.show(dialog);
    }
}
