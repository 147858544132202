export default function (
    $log,
    $sbComponent,
    $sbProject,
    $sbCurrentProject,
    $sbFileReader,
    $sbParseXml
) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        readAndParseFile: readAndParseFile,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function readAndParseFile(
        file,
        { type, id },
        { shouldDelete, shouldBringBack }
    ) {
        return $sbFileReader
            .readFile(file, "readAsText")
            .then(function (fileData) {
                return fileData.rawData;
            })
            .then($sbFileReader.parseXml)
            .then(function (data) {
                return $sbProject.getCalendar().then(({ TIMEZONE }) => {
                    $log.debug("timezone is", TIMEZONE);
                    return $sbParseXml.transformImport(
                        data,
                        type,
                        TIMEZONE.name
                    );
                });
            })
            .then(_transformPreviewModel(id, { shouldDelete, shouldBringBack }))
            .then($sbComponent.uploadComponents);
    }

    function _transformPreviewModel(
        id,
        { shouldDelete = false, shouldBringBack = true } = {}
    ) {
        return function (previewModel) {
            var uploadModel = $sbComponent.createComponentUploadModel({
                projectId: $sbCurrentProject.pick("id"),
                sourceKey: id,
                components: previewModel.components,
                structure: previewModel.structureList,
            });

            uploadModel.removeStructureSingles();
            uploadModel.setEdges(previewModel.edges);
            uploadModel.setBlockDeletion(!shouldDelete);
            uploadModel.setBlockBringBack(!shouldBringBack);

            return uploadModel;
        };
    }
}
