export default class ActivitiesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the activities in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}    UUID
     */
    getCollection(
        projectId,
        { byVisualizationId, filter, select, orderby, top, skip, i18n }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/activities`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                by_visualization_id: byVisualizationId,
                filter,
                select,
                orderby,
                top,
                skip,
                i18n,
            },
        });
    }

    /**
     * This endpoint returns a specific activity by its id.
     *
     * @param activityId {string}    UUID
     */
    get(activityId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/activities/${activityId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }
}
