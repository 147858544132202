import _ from "lodash";

export default function DeliverableDependenciesCtrl(
    $log,
    $sbProject,
    $sbPermission,
    $sbDeliverableDependencies,
    $mdDialog,
    $sbDetailsOverlay,
    $sbTracking
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.PREDECESSOR = "PREDECESSOR";
    vm.SUCCESSOR = "SUCCESSOR";

    vm.manager = {
        isOpen: false,
        type: undefined,
        is: function is(type, deliverable) {
            return _callFnForType(type, {
                predecessor: $sbDeliverableDependencies.isPredecessor,
                successor: $sbDeliverableDependencies.isSuccessor,
                args: [deliverable],
            });
        },
        addTo: function addTo(type, deliverable) {
            return _callFnForType(type, {
                predecessor: addPredecessor,
                successor: addSuccessor,
                args: [deliverable],
            });
        },
        removeFrom: function removeFrom(type, deliverable) {
            return _callFnForType(type, {
                predecessor: removePredecessor,
                successor: removeSuccessor,
                args: [deliverable],
            });
        },
        openManager: function openManager(type) {
            vm.manager.isOpen = true;
            vm.manager.type = type;
        },
    };

    vm.userIsAllowed = false;
    vm.isLoading = true;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.confirmAndRemoveFrom = confirmAndRemoveFrom;
    vm.changeOverlayContext = changeOverlayContext;
    vm.loadDependencies = loadDependencies;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onInit() {
        vm.isDateSchedulingMode = !$sbProject
            .getCurrent()
            .isInEdgeSchedulingMode();
    }

    function $onChanges(change) {
        if (change.deliverable) {
            const currentDeliverable = _.get(
                change,
                "deliverable.currentValue.id"
            );
            const previousDeliverable = _.get(
                change,
                "deliverable.previousValue.id"
            );
            if (currentDeliverable !== previousDeliverable) {
                loadDependencies(change.deliverable.currentValue);
            }
        }
    }

    /**
     * Update the data model to reflect the given deliverables state.
     *
     * @param {Deliverable} deliverable
     */
    function loadDependencies(deliverable) {
        _clearView();
        _setPermissionFlag($sbProject.getCurrent());

        if (deliverable && deliverable.id) {
            $sbTracking.detailsOverlay.tabs.openDependencies();
            $sbDeliverableDependencies
                .fetchDetailedDependenciesFor(deliverable)
                .then(function () {
                    vm.predecessors = $sbDeliverableDependencies.predecessors;
                    vm.successors = $sbDeliverableDependencies.successors;
                    vm.deliverable =
                        $sbDeliverableDependencies.detailedConnection.center;
                })
                .finally(function () {
                    vm.isLoading = false;
                });
        }
    }

    function changeOverlayContext(deliverableId) {
        $sbDetailsOverlay.toggleView("deliverable", deliverableId);
    }

    function _clearView() {
        vm.predecessors = undefined;
        vm.successors = undefined;
        vm.deliverable = undefined;
        vm.manager.isOpen = false;
        vm.userIsAllowed = false;
        vm.isLoading = true;
    }

    function _setPermissionFlag(project) {
        if (project) {
            vm.userIsAllowed = $sbPermission.hasPlanPermissions(
                project.privileges
            );
        } else {
            vm.userIsAllowed = false;
        }
    }

    function confirmAndRemoveFrom($event, type, dependency) {
        $event.stopPropagation();
        $event.preventDefault();

        var dialogMessage =
            "SECTION_DEPENDENCIES_CONFIRM_DELETE_SINGLE_DIALOG_TEXT";
        var numberOfSiblingDependencies =
            dependency.getNumberOfSiblingDependencies();

        if (dependency.isDerived() && numberOfSiblingDependencies > 0) {
            dialogMessage =
                "SECTION_DEPENDENCIES_CONFIRM_DELETE_MULTIPLE_DIALOG_TEXT";
        }

        var confirm = $mdDialog
            .confirm()
            .title("SECTION_DEPENDENCIES_CONFIRM_DELETE_DIALOG_TITLE")
            .content(dialogMessage)
            .contentValues({
                value: numberOfSiblingDependencies,
            });

        return $mdDialog.show(confirm).then(function () {
            _callFnForType(type, {
                predecessor: removePredecessor,
                successor: removeSuccessor,
                args: [dependency],
            });
        });
    }

    function _callFnForType(typeOf, config) {
        if (typeOf === vm.PREDECESSOR) {
            return config.predecessor.apply(null, config.args);
        }
        if (typeOf === vm.SUCCESSOR) {
            return config.successor.apply(null, config.args);
        }
    }

    // new Edge: deliverable -> this
    function addPredecessor(deliverable) {
        $sbDeliverableDependencies
            .addPredecessor(deliverable)
            .then(function () {
                vm.predecessors = $sbDeliverableDependencies.predecessors;
            });
    }

    // remove Edge: deliverable -> this
    function removePredecessor(deliverable) {
        $sbDeliverableDependencies
            .removePredecessor(deliverable)
            .then(function () {
                vm.predecessors = $sbDeliverableDependencies.predecessors;
            });
    }

    // new Edge: this -> deliverable
    function addSuccessor(deliverable) {
        $sbDeliverableDependencies.addSuccessor(deliverable).then(function () {
            vm.successors = $sbDeliverableDependencies.successors;
        });
    }

    // remove Edge: this -> deliverable
    function removeSuccessor(deliverable) {
        $sbDeliverableDependencies
            .removeSuccessor(deliverable)
            .then(function () {
                vm.successors = $sbDeliverableDependencies.successors;
            });
    }
}
