export default function systemStatusService(SYSTEM_STATUS, $http) {
    "ngInject";
    return {
        getSystemStatus: getSystemStatus,
        isOperational: isOperational,
    };

    ////////////////////////////////
    //                            //
    //         PUBLIC API         //
    //                            //
    ////////////////////////////////

    function getSystemStatus() {
        return $http
            .get(`/api/v1/health`, {
                timeout: 3000,
            })
            .then(function () {
                return SYSTEM_STATUS.OPERATIONAL;
            })
            .catch(function () {
                return SYSTEM_STATUS.MAINTENANCE;
            });
    }

    function isOperational(status) {
        return status === SYSTEM_STATUS.OPERATIONAL;
    }
}
