import angular from "angular";

import html from "./sb_inspection_progress_list.html";
import activityStateTransitionSelectHtml from "./sb_activity_state_transition_select.html";

export default angular
    .module("sbApp.common.components.inspectionProgressList", [])
    .constant("BADGES_CONFIG", {
        PREDECESSOR_DONE: {
            css: "sb-tag-on-time",
            i18n: "SECTION_DELIVERABLES_PROGRESS_LIST_PREDECESSOR_DONE_BADGE",
        },
        SCHEDULED: {
            css: "sb-tag-open-claims",
            i18n: "SECTION_DELIVERABLES_PROGRESS_LIST_SCHEDULED_BADGE",
        },
    })
    .component("sbActivityStateTransitionSelect", {
        templateUrl: activityStateTransitionSelectHtml,
        bindings: {
            activity: "<",
            onStateChange: "&",
        },
        controller: function ActivityStateTransitionSelectCtrl(
            $sbActivityStateTransitionUI,
            $sbMembership
        ) {
            "ngInject";

            const vm = this;

            vm.$onChanges = $onChanges;

            function $onChanges() {
                vm.state = $sbActivityStateTransitionUI.selectViewModelFor(
                    vm.activity
                );
                vm.canUserReportProgress = canUserReportProgress(vm.activity);
            }

            function canUserReportProgress(activity) {
                return $sbMembership.canReportProgressFor(
                    activity.getParent(),
                    activity,
                    $sbMembership.current()
                );
            }
        },
    })
    .component("sbInspectionProgressList", {
        templateUrl: html,
        controllerAs: "inspectionProgressList",
        bindings: {
            deliverables: "<",
            onStateChange: "&",
        },
        controller: function InspectionProgressList(
            SbBaseComponent,
            BADGES_CONFIG
        ) {
            "ngInject";
            /////////////////////
            //
            //      Direct variables
            //
            /////////////////////

            var vm = this;

            /////////////////////
            //
            //      View model properties
            //
            /////////////////////

            vm.$onChanges = $onChanges;
            vm.getBadgeTranslationKey = getBadgeTranslationKey;
            vm.getBadgeClass = getBadgeClass;

            function $onChanges() {
                vm.deliverables = vm.deliverables.filter(
                    function (deliverable) {
                        return deliverable.activities.length > 0;
                    }
                );
            }

            function getBadgeTranslationKey(reasonKey) {
                return getBadgeConfigParamIfDefined(reasonKey, "i18n");
            }

            function getBadgeClass(reasonKey) {
                return getBadgeConfigParamIfDefined(reasonKey, "css");
            }

            function getBadgeConfigParamIfDefined(reasonKey, param) {
                if (
                    !Object.prototype.hasOwnProperty.call(
                        BADGES_CONFIG,
                        reasonKey
                    )
                ) {
                    return "";
                }

                return BADGES_CONFIG[reasonKey][param];
            }
        },
    });
