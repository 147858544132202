import angular from "angular";
import htmlTemplate from "./sbNoteTemplateEditCard.html";

export default function NoteTemplateEditCard($timeout) {
    "ngInject";

    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        scope: {
            editableObject: "=",
            onClose: "=", // TODO: We don't need that anymore, the expandable item should handle that.
            onSave: "=",
        },
        controller: function ($scope, $q, TEMPLATE_CONFIG) {
            "ngInject";
            $scope.saving = false;
            $scope.maxLengthCode = TEMPLATE_CONFIG.CODE.MAX_LENGTH;
            $scope.maxLengthDesc = TEMPLATE_CONFIG.DESC.MAX_LENGTH;

            // watch for change of bound object
            //
            $scope.$watch("editableObject", function () {
                init($scope.editableObject);
            });

            /**
             * Make a copy of the bound template for diff comparison.
             *
             * @param {Object} template - plain object representing the template.
             */
            function init(template) {
                $scope.edited = angular.copy(template);
            }

            /**
             * Handler for save changes action.
             * Delegates to bounded onSave function of controller.
             *
             * @param {Object} template - plain object representing the edited template.
             */
            function onEditSave(template) {
                if (angular.isDefined($scope.onSave)) {
                    $scope.saving = true;
                    $scope.onSave(template, $scope.edited).finally(function () {
                        $timeout(function () {
                            $scope.saving = false;
                        }, 2000);
                    });
                } else {
                    throw new Error(
                        "$scope.onSave not defined. -> Saving not possible!"
                    );
                }
            }

            // watch for changes and trigger save
            // the delay for the "live" save is configured with the debounce feature
            // from ng-model-options directive on the different models
            //
            $scope.$watchGroup(
                ["edited.CODE", "edited.TYPE", "edited.TEXT"],
                function (newValue, oldValue) {
                    if (
                        $scope.editForm.$valid &&
                        !angular.equals(newValue, oldValue)
                    ) {
                        onEditSave($scope.editableObject);
                    }
                }
            );
        },
    };
}
