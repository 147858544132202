import has from "has";
import ConstantsModule from "./constants.module";

/**
 * all available languages
 * the first key is the fallback language
 * @type {string[]}
 */
var availableLanguageKeys = ["en", "de", "es", "fr"];

var availableLanguageConfig = [
    {
        key: availableLanguageKeys[0],
        abbreviation: "en-GB",
        name: "English (UK)",
    },
    {
        key: availableLanguageKeys[1],
        abbreviation: "de-DE",
        name: "Deutsch",
    },
    {
        key: availableLanguageKeys[2],
        abbreviation: "es-ES",
        name: "Español",
    },
    {
        key: availableLanguageKeys[3],
        abbreviation: "fr-FR",
        name: "Français",
    },
];

ConstantsModule.constant("AVAILABLE_LANGUAGE_KEYS", availableLanguageKeys);
ConstantsModule.constant("AVAILABLE_LANGUAGE_CONFIG", availableLanguageConfig);
ConstantsModule.constant("DEFAULT_LANGUAGE_KEY", availableLanguageKeys[0]);
