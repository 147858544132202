import Component from "./Component";

/**
 * Activities are leaf Components that may have a predecessor and a successor
 * @param {string} id - Id of the Activity
 * @param {Object} properties - Properties an activity has besides dependency and parents
 * @extends {Component}
 * @constructor
 */
function Activity(id, properties) {
    Component.call(this, id, properties);

    this.predecessors = [];
    this.successors = [];
}

Activity.prototype = Object.create(Component.prototype);

/**
 * a simple text representation of the activity for ARIA support
 * @returns {string} - name and statename concatenated with a dash as a delimiter
 */
Activity.prototype.getText = function () {
    return this.properties.name + " - " + this.properties.statename;
};

/**
 * Return the list of predecessors of this component
 *
 * @returns {Array.<Activity>} - the list of Activities that this component dependents on.
 */
Activity.prototype.getPredecessors = function () {
    return this.predecessors;
};

/**
 * Return the list of successors of this component
 *
 * @returns {Array.<Activity>} - the list of Activities that need this component to be first
 */
Activity.prototype.getSuccessors = function () {
    return this.successors;
};

/**
 * Check if a cycle will occur while adding an edge.
 *
 * @param {Activity} source - the source of the edge you want to insert
 * @param {Activity} target - the target of the edge you want to insert
 *
 * @static
 * @returns {boolean} - true if a cycle will occur on adding this edge
 */
Activity.willCreateCycle = function (source, target) {
    // an edge with source and target the same thing is a cycle.
    if (source.selfById(target)) {
        return true;
    }

    // keep track of nodes that were flagged -> has to be cleaned up
    //
    // the flags will increase performance massively because nodes
    //   can't be added to the stack multiple times
    // Without the flag each branch in the graph will lead to checking
    //   the same node more than once.

    var flaggedNodes = [];

    function isUnFlaggedNode(node) {
        return node.__cycleCheckRunning !== true;
    }

    function flagNode(node) {
        node.__cycleCheckRunning = true;
        flaggedNodes.push(node);
        return node;
    }

    function clearFlag() {
        flaggedNodes.forEach(function (node) {
            node.__cycleCheckRunning = false;
        });
    }

    // start on the target and check if you can find the source on the dependencies path -> DFS
    //
    var successors = [target];
    flagNode(target);

    while (successors.length > 0) {
        var successor = successors.pop();
        if (successor.selfById(source)) {
            clearFlag();
            return true;
        }

        var newNodes = successor
            .getSuccessors()
            .filter(isUnFlaggedNode)
            .map(flagNode);

        successors = successors.concat(newNodes);
    }

    clearFlag();
    return false;
};

/**
 * Add a new predecessor to this component
 *
 * @param {Activity} component - the new predecessor
 * @throws cycleException - if the new dependency will create a cycle -> exception occurs.
 */
Activity.prototype.addPredecessor = function (component) {
    if (Activity.willCreateCycle(component, this)) {
        throw "Will create a cycle! - Denied";
    }
    this._addToPredecessors(component);
    component._addToSuccessors(this);
};

/**
 * Add a new predecessor to the list of predecessors
 *
 * @param {Component} component - the new predecessor
 *
 * @private
 * @returns {Number} - position in the array or -1 if not inserted
 */
Activity.prototype._addToPredecessors = function (component) {
    var isAlreadyThere = this.predecessors.some(component.selfById, component);
    if (!isAlreadyThere) {
        return this.predecessors.push(component);
    } else {
        return -1;
    }
};

/**
 * Add a new successor to this component
 *
 * @param {Activity} component - the new successor
 * @throws cycleException - if the new dependency will create a cycle -> exception occurs.
 */
Activity.prototype.addSuccessor = function (component) {
    if (Activity.willCreateCycle(this, component)) {
        throw "Will create a cycle! - Denied";
    }
    this._addToSuccessors(component);
    component._addToPredecessors(this);
};

/**
 * Add a new successor to the list of successors
 *
 * @param {Activity} component - the new successor
 *
 * @private
 * @returns {Number} - position in the array or -1 if not inserted
 */
Activity.prototype._addToSuccessors = function (component) {
    var isAlreadyThere = this.successors.some(component.selfById, component);
    if (!isAlreadyThere) {
        return this.successors.push(component);
    } else {
        return -1;
    }
};

/**
 * Remove the component from the list of successors. Also removes this
 * from the predecessors of the other component
 *
 * @param {Activity} component - the component to remove
 *
 * @returns {simpleEdge} - the edge that was removed
 */
Activity.prototype.removeSuccessor = function (component) {
    // remove from your own predecessor list
    this.successors = this.successors.filter(component.notSelfById, component);
    // remove from the others successor list
    component.predecessors = component.predecessors.filter(
        this.notSelfById,
        this
    );
    return {
        source: this.id,
        target: component.id,
    };
};

/**
 * Remove the component from the list of predecessors. Also removes this
 * from the successors of the other component
 *
 * @param {Activity} component - the component to remove
 *
 * @returns {simpleEdge} - the edge that was removed
 */
Activity.prototype.removePredecessor = function (component) {
    // remove from your own predecessor list
    this.predecessors = this.predecessors.filter(
        component.notSelfById,
        component
    );
    // remove from the others successor list
    component.successors = component.successors.filter(this.notSelfById, this);
    return {
        target: this.id,
        source: component.id,
    };
};

/**
 * Remove all successor references and all predecessor references on both sides
 *
 * @returns {Array<simpleEdge>} - List of simple edge objects with source and target
 */
Activity.prototype.removeAllEdges = function () {
    var edges = this.predecessors.map(this.removePredecessor, this);
    edges = edges.concat(this.predecessors.map(this.removeSuccessor, this));
    return edges;
};

/**
 *
 * @param {Activity} activity - the activity to look for on the predecessors path
 * @return {boolean} - true if a predecessor on any distance
 */
Activity.prototype.isOnPredecessorPath = function (activity) {
    return this.iteratePredecessorsBFS(function (element) {
        return element.selfById(activity);
    });
};

/**
 *
 * @param {Activity} activity - the activity to look for on the successors path
 * @return {boolean} - true if a successor on any distance
 */
Activity.prototype.isOnSuccessorPath = function (activity) {
    return activity.isOnPredecessorPath(this);
};

Activity.prototype.iteratePredecessorsBFS = function (callback) {
    var stack = [];
    var element = this;
    var emergencyFail = 0;
    while (element && emergencyFail < 10000) {
        var predecessors = element.getPredecessors();
        var stop = callback(element, predecessors);
        if (stop) {
            return true;
        }
        stack = stack.concat(predecessors);
        element = stack.pop();
        emergencyFail++;
        if (emergencyFail > 999) {
            console.log("warn");
        }
    }
    return false;
};

export default Activity;
