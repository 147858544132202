import WbsCommand from "./WBSCommand";
import EdgeDeletedEvent from "../events/EdgeDeletedEvent";
import ShowToastEvent from "../events/ShowToastEvent";
/**
 * Command to Delete an Edge
 * @param {WBS.Edge} oEdge - Edge to be deleted
 * @param {WBS.EditorStage} oStage - Stage on which Edges are Drawn
 * @constructs WBS.DeleteEdgeCommand
 * @implements {WBS.WbsCommand}
 * @memberof WBS
 */
function DeleteEdgeCommand(oEdge, oStage) {
    WbsCommand.call(this, oStage.$sbErrorPresenter, oStage.wbsApiService);

    /**
     * Edge to be deleted
     * @type {Edge}
     */
    this.edge = oEdge;

    this.stage = oStage;
}

DeleteEdgeCommand.prototype = Object.create(WbsCommand.prototype);

/**
 * Calls api do delete the edge
 * Triggers UI Changes
 */
DeleteEdgeCommand.prototype.execute = function () {
    var data = {
        SOURCE_ID: this.edge.sourceEntity.getData().ID,
        TARGET_ID: this.edge.targetEntity.getData().ID,
    };

    console.log(this.edge.sourceEntity.getData());
    var cmd = this;

    function triggerUI() {
        var sourceId = data.SOURCE_ID;
        var targetId = data.TARGET_ID;
        var edgeEvent = new EdgeDeletedEvent(sourceId, targetId);

        cmd.stage.dispatchEvent(edgeEvent);
        cmd.stage.dispatchEvent(
            new ShowToastEvent("EDGE_DELETE_SUCCESSFUL", "info")
        );
    }

    this.wbsApiService
        .deleteEdge(data)
        .then(triggerUI, this.$sbErrorPresenter.catch);
};

/**
 * Calls the api to create an Edge
 * Triggers UI Changes
 */
DeleteEdgeCommand.prototype.undo = function () {
    var cmd = this;

    function triggerUI() {
        //todo: remove edge from ui
        cmd.stage.dispatchEvent(
            new ShowToastEvent("EDGE_CREATE_SUCCESSFUL", "success")
        );
    }

    this.wbsApiService
        .createEdge(this.edge)
        .then(triggerUI)
        .catch(this.$sbErrorPresenter.catch);
};

/**
 * Deletes Edge again
 */
DeleteEdgeCommand.prototype.redo = function () {
    this.execute();
};

export default DeleteEdgeCommand;
