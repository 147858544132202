import angular from "angular";
import $sbServices from "common/services/services";
import intercomModule from "common/intercom/intercomModule";
import domainModule from "domain/domain.module";
import watcherCounterModule from "common/internals/watcherCounter/watcher.counter.module";
import leanBoardCtrl from "./lean_board.controller";
import leanBoardRouteConfig from "./lean_board.route";
import leanBoardService from "./services/lean_board.service";
import lastPlannedService from "./services/last_planned.service";
import LeanBoardFacadeService from "./services/lean_board_facade.service";
import LeanBoardStoreService from "./services/lean_board_store.service";
import lastPlannedSelectionStore from "./services/last_planned_selection_store.service";
import leanBoardComponent from "./components/sbLeanBoard/lean_board.component";
import activityCardComponent from "./components/sbActivityCard/activity_card.component";
import deliverableCardComponent from "./components/sbDeliverableCard/deliverable_card.component";
import leanBoardLaneComponent from "./components/sbLeanBoardLane/lean_board_lane.component";
import lateActivitiesPanelConfig from "./components/sbLateActivitiesPanel/sb_late_activities_panel.config";
import activityDetailsPanelConfig from "./panels/sbActivityDetailsPanel/byDeliverable/sb_deliverable_activity_details_panel.config";
import structureActivityDetailsPanelConfig from "./panels/sbActivityDetailsPanel/byStructure/sb_structure_activity_details_panel.config";
import lookAheadDefinitionDialogConfig from "./dialogs/sbLookAheadDefinitionDialog/sb_look_ahead_definition_dialog.config";
import activityDetailsUserInteractionsService from "./panels/sbActivityDetailsPanel/common/sb_activity_details_user_interaction.service";
import activityDetailsPanelCommonService from "./panels/sbActivityDetailsPanel/common/sb_activity_details_panel_common.service";
import lookAheadModificationService from "./services/look_ahead_modification.service";
import LookAheadSessionService from "./services/look_ahead_session.service";
import exitRunningSessionDialogConfig from "./dialogs/exitRunningSessionDialog/exit_running_session_dialog.config";
import "common/dialog/timeRangeSelector/time-range-selector.dialog.js";
import "angular-ui-router";

export default angular
    .module("sbApp.leanBoardModule", [
        "sbApp.sbTimeRangeSelectorDialog",
        "ngMaterial",
        "ui.router",
        "sbApp.filters",
        domainModule.name,
        $sbServices.name,
        intercomModule.name,
        watcherCounterModule.name,
    ])
    .config(leanBoardRouteConfig)
    .config(lateActivitiesPanelConfig)
    .config(activityDetailsPanelConfig)
    .config(structureActivityDetailsPanelConfig)
    .config(lookAheadDefinitionDialogConfig)
    .config(exitRunningSessionDialogConfig)
    .controller("LeanBoardCtrl", leanBoardCtrl)
    .factory("$sbLeanBoard", leanBoardService)
    .factory("$sbLastPlanned", lastPlannedService)
    .service("$sbLeanBoardFacade", LeanBoardFacadeService)
    .service("$sbLeanBoardStore", LeanBoardStoreService)
    .factory("$sbUserInteraction", activityDetailsUserInteractionsService)
    .factory(
        "$sbActivityDetailsPanelCommonService",
        activityDetailsPanelCommonService
    )
    .factory("$sbLookAheadModification", lookAheadModificationService)
    .service("$sbLookAheadSessions", LookAheadSessionService)
    .factory("$sbLastPlannedSelectionStore", lastPlannedSelectionStore)
    .component(leanBoardComponent.name, leanBoardComponent)
    .component(deliverableCardComponent.name, deliverableCardComponent)
    .component(activityCardComponent.name, activityCardComponent)
    .component("sbLeanBoardLane", leanBoardLaneComponent)
    .constant("MIN_COLUMN_WIDTH_LARGE", 165)
    .constant("MIN_COLUMN_WIDTH_SMALL", 70)
    .constant("BOTTOM_PADDING_LARGE", 8)
    .constant("BOTTOM_PADDING_SMALL", 4)
    .constant("LANE_MIN_HEIGHT", 48)
    .constant("SMALL_LANE_MIN_HEIGHT", 34)
    .constant("MINI_LANE_MIN_HEIGHT", 24)
    .constant("LANES_PER_PAGE", 30)
    .constant("ITEM_COUNT_TO_PREFETCH", 60)
    .constant("WEEK_BOARD_SIZES", {
        LARGE: "large",
        SMALL: "small",
    })
    // The values should be strings supported by moment.js
    // https://momentjs.com/docs/#/manipulating/
    .constant("WEEK_BOARD_TIMESPAN_PER_COLUMN", {
        DAY: "day",
        WEEK: "week",
    })
    .constant("WEEK_BOARD_VIEW_MODE", {
        DELIVERABLE: "deliverable",
        STRUCTURE: "structure",
    })
    .constant("USER_INTERACTION", {
        DURATION: "duration",
        START_DATE: "startDate",
        END_DATE: "endDate",
    });

/* declaration */
