import angular from "angular";

function getValueInsideRange(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

function setFlexValue(element, flexValue) {
    element.css("flex", flexValue.toString());
    element.css("-webkit-flex", flexValue.toString());
    element.css("-ms-flex", flexValue.toString());
}

/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbResizeableFlexBox", [])
    .directive("sbResizeableFlexBox", function ($window, $document, $log) {
        return {
            /**
             * String of subset of EACM which restricts the directive to a specific directive declaration style.
             * If omitted, the defaults (elements and attributes) are used.
             *
             * @example <caption>Element Name</caption>
             * E - Element name (default): <my-directive></my-directive>
             * A - Attribute (default): <div my-directive="exp"></div>
             * C - Class: <div class="my-directive: exp;"></div>
             * M - Comment: <!-- directive: my-directive exp -->
             */
            restrict: "A",

            scope: {
                sbResizeableFlexBox: "@",
                sbResizeMinFactor: "@",
                sbResizeMaxFactor: "@",
            },

            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

            //compile : function(tElement, tAttrs) {},

            link: function (scope, element, attrs) {
                //start parsing attributes

                var secondDragContainerId = attrs.sbResizeableFlexBox;

                /**
                 * resizeMinFactor
                 * @type {Number}
                 */
                var resizeMinFactor = parseFloat(attrs.sbResizeMinFactor, 10);
                if (isNaN(resizeMinFactor)) {
                    resizeMinFactor = 0.1;
                    if (attrs.sbResizeMinFactor) {
                        $log.warn(
                            "sbResizeableFlexBox",
                            "attribute sbResizeMinFactor is not a number:",
                            attrs.sbResizeMinFactor
                        );
                    }
                }
                /**
                 * resizeMaxFactor
                 * @type {Number}
                 */
                var resizeMaxFactor = parseFloat(attrs.sbResizeMaxFactor, 10);
                if (isNaN(resizeMaxFactor)) {
                    resizeMaxFactor = 0.7;
                    if (attrs.sbResizeMaxFactor) {
                        $log.warn(
                            "sbResizeableFlexBox",
                            "attribute sbResizeMaxFactor is not a number:",
                            attrs.sbResizeMaxFactor
                        );
                    }
                }

                //end parsing attributes

                var $firstDragContainer = angular.element(element[0]);

                var $dragZone = angular.element(
                    '<div class="sb-resizeable-flex-box-drag-zone"></div>'
                );

                $firstDragContainer.append($dragZone);

                /**
                 * @type {angular.element}
                 */
                var $secondDragContainer;

                function mousedown(event) {
                    $secondDragContainer = angular.element(
                        $document[0].getElementById(secondDragContainerId)
                    );

                    $document.on("mousemove", mousemove);
                    $document.on("mouseup", mouseup);
                    mousemove(event);
                }

                function mousemove(event) {
                    var fullWidth = $window.innerWidth;
                    var mousePositionX = event.clientX;
                    var percentageValue = getValueInsideRange(
                        mousePositionX / fullWidth,
                        resizeMinFactor,
                        resizeMaxFactor
                    );

                    var flexValueForFirstContainer = percentageValue * 100;
                    var flexVlaueForSecondContainer =
                        (1 - percentageValue) * 100;

                    setFlexValue(
                        $firstDragContainer,
                        flexValueForFirstContainer
                    );
                    setFlexValue(
                        $secondDragContainer,
                        flexVlaueForSecondContainer
                    );
                    event.preventDefault();
                }

                function mouseup() {
                    releaseEvents();

                    $secondDragContainer = null;
                }

                $dragZone.on("mousedown", mousedown);

                function releaseEvents() {
                    $document.off("mousemove", mousemove);
                    $document.off("mouseup", mouseup);
                }

                function deinit() {
                    releaseEvents();
                }

                scope.$on("$destroy", deinit);
            },

            //controller : function($scope, $element) {},
        };
    });
