import VerticalBranch from "../edge/VerticalBranch";
import NodeContainer from "./NodeContainer";
import MinorContainer from "./MinorContainer";

/**
 * @param {WBS.StructureNode} node - Content of Container
 * @constructs RootContainer
 * @extends WBS.NodeContainer
 * @memberof WBS
 */
function RootContainer(node) {
    node.isHorizontalNode = true;
    NodeContainer.call(this, node, VerticalBranch);
}

/**
 * Setup prototypal inheritance.
 * RootContainer inherits from createjs.Container.
 *
 * @type {NodeContainer}
 */
RootContainer.prototype = Object.create(NodeContainer.prototype);

RootContainer.prototype.margin = {
    x: 90,
    y: 100,
};

RootContainer.prototype.createSibling = function (node) {
    var containerElement = new RootContainer(node);
    node.entity.setGeometry(containerElement);
    this.parent.addChild(containerElement);
    this.refreshLayout();
};

RootContainer.prototype.createChild = function (node) {
    var containerElement = new MinorContainer(node);
    node.entity.setGeometry(containerElement);
    this.addChild(containerElement);
    this.refreshLayout();
};

export default RootContainer;
