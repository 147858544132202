export default function ProgressOptionsCtrl($timeout, $filter) {
    "ngInject";
    var asStateIcon = $filter("asStateIcon");

    var vm = this;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.$postLink = $postLink;

    vm.addChild = addChild;

    /////////////
    //    IMPL
    ////////////

    function $onInit() {
        vm.children = [];
        vm.showTooltip = !!vm.showTooltip;
    }

    function $onChanges($event) {
        if (
            $event.changeDisabled &&
            $event.changeDisabled.currentValue !==
                $event.changeDisabled.previousValue
        ) {
            vm.changeDisabled = $event.changeDisabled.currentValue;
        }

        if (
            $event.showTooltip &&
            $event.showTooltip.currentValue !== $event.showTooltip.previousValue
        ) {
            vm.showTooltip = $event.showTooltip.currentValue;
        }

        if (
            $event.selected &&
            $event.selected.currentValue !== $event.selected.previousValue
        ) {
            selectOption($event.selected.currentValue);
        }
    }

    function $postLink() {
        vm.classes = asStateIcon(vm.selected);
    }

    function addChild(child) {
        vm.children.push(child);
    }

    function selectOption(key) {
        // The process is reading the icon class of the selected option and applying this icon class to the
        // open select button. Because we allow icon's that are compiled with scope properties of the parent
        // we have to wait until the compile process has settled in order to read the resulting class names
        // from the icon HTML element.
        // Without the $timeout the result of reading the class name is the pre compiled value;
        // With the $timeout the result is the compiled class expression.
        $timeout(function () {
            vm.selected = key;
            vm.classes = asStateIcon(vm.selected);
        });
    }
}
