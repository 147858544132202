export default class DeliverableJobsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";

        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;

        this.ASSIGN_STRATEGY = {
            OVERWRITE: "OVERWRITE",
            SAFE_OVERWRITE: "SAFE_OVERWRITE",
            UPDATE: "UPDATE",
            ASSOCIATE: "ASSOCIATE",
        };
    }

    /**
     * POST request to create a deliverable job with given type and definition
     *
     * @param projectId {string}            UUID
     *
     * @param body {Object}                 Request body
     * @param body.type {string}            Name of the job
     * @param body.definition {Object}      Detailed description about the job setup
     *
     * @returns {Promise<{}>}
     */
    create(projectId, { type, definition }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/deliverable-jobs`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "POST",
            body: JSON.stringify({
                type,
                definition,
            }),
            header: {
                "Content-Type": "application/json",
            },
        }).then((resp) => {
            const jobId = resp.getResponseHeader("x-job-id");
            const response = JSON.parse(resp.responseText);
            return { response, jobId };
        });
    }

    /**
     * Create an 'IMPORT' deliverable job
     *
     * @param projectId {string}              UUID
     *
     * @param body {Object}                   Request body
     * @param body.source {Object}            A source object is used to group those imported deliverables and can be used in following operations
     * @param body.edges {Object[]}           List all dependencies between deliverables that are supposed to be created
     * @param body.deliverables {Object[]}    List all deliverables that are supposed to be created
     * @param body.structure {Object[]}       List all structure nodes that are supposed to be created
     *
     * @returns {Promise<{}>}
     */
    createImportJob(projectId, { source, edges, deliverables, structure }) {
        return this.create(projectId, {
            type: "IMPORT",
            definition: {
                source,
                edges,
                deliverables,
                structure,
            },
        });
    }

    /**
     * Create a 'REIMPORT' deliverable job
     *
     * @param projectId {string}                     UUID
     *
     * @param body {Object}                          Request body
     * @param body.source_id {number}                Platform identifier of the source
     * @param body.is_deletion_blocked {boolean}     Option to configure if deliverables that were previously imported but are not present in the update data can be deleted to keep source and platform data in sync
     * @param body.is_bring_back_blocked {boolean}   Option to configure if deliverables that were previously removed but are in the updated data again can be revoked to keep source and platform data in sync
     * @param body.edges {Object[]}                  List all dependencies between deliverables that are supposed to be created
     * @param body.deliverables {Object[]}           List all deliverables that are supposed to be created
     * @param body.structure {Object[]}              List all structure nodes that are supposed to be created
     *
     * @returns {Promise<{}>}
     */
    createReimportJob(
        projectId,
        {
            source_id,
            is_deletion_blocked,
            is_bring_back_blocked,
            edges,
            deliverables,
            structure = null,
        }
    ) {
        return this.create(projectId, {
            type: "REIMPORT",
            definition: {
                source_id,
                is_deletion_blocked,
                is_bring_back_blocked,
                edges,
                deliverables,
                structure,
            },
        });
    }

    /**
     * Create a 'MODIFY_PROPERTIES' deliverable job
     *
     * @param projectId {string}                UUID
     *
     * @param body {Object}                     Request body
     * @param body.properties {Object}          Definition of the change
     * @param body.deliverable_set {string|string[]}    Filter expression list of deliverable ids to identify a set of deliverables. All those deliverables will be updated
     *
     * @returns {Promise<{}>}
     */
    createModifyPropertiesJob(projectId, { properties, deliverable_set }) {
        return this.create(projectId, {
            type: "MODIFY_PROPERTIES",
            definition: {
                properties,
                deliverable_set,
            },
        });
    }

    /**
     * Create an 'ASSIGN_PROCESS' deliverable job
     *
     * @param projectId {string}                    UUID
     *
     * @param body {Object}                             Request body
     * @param body.strategy {string}                    Strategy of assignment
     * @param body.deliverable_set {string|string[]}    Filter expression list of deliverable ids to identify a set of deliverables. All those deliverables will be updated
     * @param body.process_template_id {string}         The unique platform wide identifier
     *
     * @returns {Promise<{}>}
     */
    createAssignProcessJob(
        projectId,
        { strategy, deliverable_set, process_template_id }
    ) {
        return this.create(projectId, {
            type: "ASSIGN_PROCESS",
            definition: {
                strategy,
                deliverable_set,
                process_template_id,
            },
        });
    }

    /**
     * Create a 'REMOVE' deliverable job
     *
     * @param projectId {string}                  UUID
     *
     * @param body {Object}                       Request body
     * @param body.deliverable_set {string|string[]}    Filter expression list of deliverable ids to identify a set of deliverables. All those deliverables will be updated
     *
     * @returns {Promise<{}>}
     */
    createRemoveJob(projectId, { deliverable_set }) {
        return this.create(projectId, {
            type: "REMOVE",
            definition: {
                deliverable_set,
            },
        });
    }
}
