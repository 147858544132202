/**
 * Implementation of missing translation handler for angular-translate.
 * See https://angular-translate.github.io/docs/#/api/pascalprecht.translate.$translateProvider for documentation.
 *
 * @param $log AngularJS log service
 * @param $translateSanitization See https://angular-translate.github.io/docs/#/api/pascalprecht.translate.$translateSanitization
 * @constructor
 */
export default function MissingTranslationHandlerService(
    $log,
    $translateSanitization
) {
    "ngInject";

    /*
     * The balance we try to achieve is to show special characters in strings,
     * ( originated from '&' in team names )
     * while not expose ourselves to an XSS.
     *
     * The custom function passed to OVERWRITE_SANITIZE_STRATEGY is a copy of the 'escape' strategy provided by
     * angular-translate where the .innerText replaces .html() in order to not decode special characters,
     * but still avoid evaluating them as code.
     *
     * See:
     * https://github.com/angular-translate/angular-translate/blob/master/src/service/sanitization.js
     * https://stackoverflow.com/questions/52707031/does-innertext-prevent-xss#:~:text=innerText%20%3D%20%22user%20input%22%20in,the%20input%20to%20prevent%20XSS%3F&text=it's%20safe%2C%20but%20you%20should,user%20input%20for%20other%20reasons.
     * https://cheatsheetseries.owasp.org/cheatsheets/DOM_based_XSS_Prevention_Cheat_Sheet.html#rule-7-fixing-dom-cross-site-scripting-vulnerabilities
     */

    const OVERWRITE_SANITIZE_STRATEGY = (value) => {
        var element = angular.element("<div></div>");
        element.text(value);
        const html = element.html();
        return html.replace(/&amp;/g, "&");
    };

    return function missingTranslationHandler(
        translationId,
        $uses,
        interpolateParams,
        defaultTranslationText,
        sanitizeStrategy
    ) {
        // TODO change log level for production and bring this back.
        // $log.debug("Detected missing translation for key:", translationId);

        // we expect this to happen only for text translation
        //
        const SANITIZATION_MODE = "text";

        return $translateSanitization.sanitize(
            translationId,
            SANITIZATION_MODE,
            sanitizeStrategy || OVERWRITE_SANITIZE_STRATEGY
        );
    };
}
