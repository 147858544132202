import templateHtml from "./svg_config_card.html";

export default {
    name: "svgConfigCard",
    templateUrl: templateHtml,
    bindings: {
        drawing: "<",
        onDelete: "&",
    },
};
