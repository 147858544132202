import angular from "angular";

export default function DownloadCtrl(
    moment,
    $stateParams,
    $state,
    downloadCenterService,
    exportSrcObject,
    $scope
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var userMessages = [
        {
            icon: "mdi mdi-thumb-up",
            title: "SECTION_EXPORT_SUCCESS_TITLE",
        },
        {
            icon: "mdi mdi-alert-circle",
            title: "SECTION_EXPORT_WRONG_FILE_TITLE",
            subtitle: "SECTION_EXPORT_WRONG_FILE_SUBTITLE",
        },
        {
            icon: "mdi mdi-file-check",
            title: "SECTION_EXPORT_UP_TO_DATE_FILE_TITLE",
            subtitle: "SECTION_EXPORT_UP_TO_DATE_FILE_SUBTITLE",
        },
    ];
    var successMessages = {
        PRIMAVERA: {
            title: "SECTION_EXPORT_SUCCESS_TITLE_PRIMAVERA",
            detail: "SECTION_EXPORT_SUCCESS_SUBTITLE",
        },
        MSP: {
            detail: "SECTION_EXPORT_CSV_SUCCESS_SUBTITLE",
        },
        ASTA: {
            detail: "SECTION_EXPORT_CSV_SUCCESS_SUBTITLE",
        },
        EXCEL: {
            detail: "SECTION_EXPORT_CSV_SUCCESS_SUBTITLE",
        },
        PROJECT: {
            detail: "SECTION_EXPORT_CSV_SUCCESS_SUBTITLE",
        },
    };

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.updateSuccessful = false;

    // used for binding the ui messages.
    vm.activeUserMessage = userMessages[0];
    vm.activeSuccessMessage = successMessages[exportSrcObject.type];
    vm.source = exportSrcObject.type;

    vm.downloadUpdatedFile = downloadUpdatedFile;
    vm.hasDownloadableContent = false;
    vm.downloadKey = undefined;
    vm.fileName = "";
    vm.exportStats = {};

    vm.isNothingInFile = false;
    vm.isNothingOnPlatform = false;
    vm.isWithChanges = false;
    vm.isOnlyPlatformDownload = false;

    activate();

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $scope.$on("$destroy", function () {
        downloadCenterService.remove(vm.downloadKey);
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function activate() {
        vm.downloadKey = $stateParams.downloadKey;
        vm.fileName = $stateParams.fileName;

        // if there is no download key --> go back to tools.
        if (!angular.isNumber(vm.downloadKey)) {
            return $state.go("sablono.project.tools", $stateParams, {
                location: "replace",
            });
        }

        vm.exportStats = $stateParams.exportStats;
        _handleUserMessages();
    }

    /**
     * Analyzes the situation based on the stats and chooses the right message to display.
     */
    function _handleUserMessages() {
        if (vm.exportStats.changed > 0) {
            vm.updateSuccessful = true;
            vm.activeUserMessage = userMessages[0];
        } else if (vm.exportStats.platformTotal > 0) {
            vm.updateSuccessful = true;
            vm.activeUserMessage = userMessages[0];
        } else {
            vm.updateSuccessful = false;
            if (
                vm.exportStats.missmatched ===
                vm.exportStats.activitiesFileTotal
            ) {
                // Wrong file
                vm.activeUserMessage = userMessages[1];
            } else {
                // No need to update the file
                vm.activeUserMessage = userMessages[2];
            }
        }
    }

    /**
     * Start the file download
     */
    function downloadUpdatedFile() {
        downloadCenterService.download(vm.downloadKey, vm.fileName);
    }
}
