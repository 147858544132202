/**
 * Idea: If you want to change the subbar completely you use an subbar-mode
 *  # Such a mode can be activated and will replace the subbar content with the given content.
 *  # From our design: It is ok to have special modes that change the toolbar in general. But it can not be the default state.
 *
 *
 * TODO: add flipping animation!
 *
 */
import angular from "angular";
import htmlTemplate from "./sbSubbarMode.html";

export default function ($rootScope, $document) {
    "ngInject";
    return {
        restrict: "E",

        templateUrl: htmlTemplate, //HTML Template as String

        transclude: true,

        scope: {
            sbActive: "=",
        },

        link: function (scope, element) {
            var $sbSubbar;
            var $toolbar;

            var tmpToolbarClassList = [];
            var tmpToolbarColorList = [];

            // # look for the real subbar.
            //      # place subbar-actions and subbar-mode as siblings
            //
            // # add a ng-hide with the activate condition
            // # add the transcluded content as sibling along with the default subbar
            // # add a ng-show to the transcluded content
            // # apply the sb-subbar-mode style classes to the default toolbar while active

            // that is the element itself
            $sbSubbar = angular.element(element[0]);

            $document.ready(function () {
                activate();
                refreshVisibility();
            });

            var watcher = scope.$watch("sbActive", refreshVisibility);
            var stateWatcher = $rootScope.$on(
                "$stateChangeStart",
                _deactivateMode
            );

            // on destroy -> we have to remove the page dependent toolbar content.
            scope.$on("$destroy", function () {
                $sbSubbar.remove();

                stateWatcher();
                watcher();

                $sbSubbar = undefined;
                $toolbar = undefined;
            });

            /**
             *
             */
            function activate() {
                $toolbar = angular.element(
                    $document[0].getElementById("sb-subbar")
                );

                if ($toolbar) {
                    // if there is a subbar -> append the subbar actions to the md-toolbar
                    $toolbar.append($sbSubbar);
                }
            }

            /**
             *
             */
            function refreshVisibility() {
                if (scope.sbActive) {
                    _activateSubbarMode();
                } else {
                    _deactivateSubbarMode();
                }
            }

            function _deactivateMode(/*event, toState, toParams, fromState, fromParams*/) {
                _deactivateSubbarMode(true);
            }

            function _deactivateSubbarMode(forceMode) {
                if ((!scope.sbActive || forceMode) && $toolbar) {
                    var $siblings = $toolbar.children();

                    $siblings.removeClass("sb-subbar-mode-hide");
                    $sbSubbar.addClass("sb-subbar-mode-hide");

                    // remove the sb-mode style from the toolbar
                    removeClassesFromToolbar();
                    // add the removed coloring theme again
                    revokeColoringStyleForToolbar();
                }
            }

            function _activateSubbarMode() {
                if (scope.sbActive && $toolbar) {
                    var $siblings = $toolbar.children();

                    $siblings.addClass("sb-subbar-mode-hide");
                    $sbSubbar.removeClass("sb-subbar-mode-hide");

                    // apply the sb-mode style to the toolbar
                    var subbarClasses = $sbSubbar.attr("class").split(" ");
                    addClassesToToolbar(subbarClasses);
                }
            }

            function revokeColoringStyleForToolbar() {
                tmpToolbarColorList.forEach(function (css) {
                    $toolbar.addClass(css);
                });
                tmpToolbarColorList = [];
            }

            /**
             *
             * @param classList
             */
            function addClassesToToolbar(classList) {
                var blackList = [
                    "sb-subbar-mode-hide",
                    "ng-scope",
                    "ng-isolate-scope",
                ];

                classList
                    .filter(function (css) {
                        return blackList.indexOf(css) < 0;
                    })
                    .forEach(function (css) {
                        tmpToolbarClassList.push(css);
                        $toolbar.addClass(css);
                    });
            }

            /**
             *
             */
            function removeClassesFromToolbar() {
                tmpToolbarClassList.forEach(function (css) {
                    $toolbar.removeClass(css);
                });
                tmpToolbarClassList = [];
            }
        },
    };
}
