import angular from "angular";
import accountManagementRouteConfig from "./account_management.route";
import accountManagementCtrl from "./account_management.controller";
import accountVerificationService from "./verification/account_verification.service";
import accountVerificationCtrl from "./verification/account_verification.controller";
import accountLoginCtrl from "./login/account_login.controller";
import accountResetPasswordCtrl from "./resetPassword/reset_password.controller";
import accountRegisterCtrl from "./registration/account_register.controller";
import VerifyEmailCtrl from "./verifyEmail/verify-email.controller";
import MaintenanceCtrl from "./maintenance/maintenance.controller";
import accountConfirmResetPasswordCtrl from "./confirmResetPassword/confirm_reset_password.controller";
import authenticationModule from "../../core/authentication/authentication.module";
import FeatureFlagsModule from "../../common/feature_flags/index";

import "configs/all";
import "../../common/dialog/eula/eula.dialog";

export default angular
    .module("sbApp.accountManagementModule", [
        "ngMaterial",
        "ui.router",
        "angularMoment",
        "pascalprecht.translate",
        "sbApp.services",
        "sbApp.constants",
        "sbApp.intercom",
        "sbApp.sbEulaDialog",
        authenticationModule.name,
        FeatureFlagsModule.name,
    ])
    .config(accountManagementRouteConfig)
    .controller("accountManagementCtrl", accountManagementCtrl)
    .controller("accountVerificationCtrl", accountVerificationCtrl)
    .controller("accountLoginCtrl", accountLoginCtrl)
    .controller("accountResetPasswordCtrl", accountResetPasswordCtrl)
    .controller(
        "accountConfirmResetPasswordCtrl",
        accountConfirmResetPasswordCtrl
    )
    .controller("accountRegisterCtrl", accountRegisterCtrl)
    .controller("VerifyEmailCtrl", VerifyEmailCtrl)
    .controller("MaintenanceCtrl", MaintenanceCtrl)
    .service("accountVerification", accountVerificationService);
/* declaration */
