var translation = {
    LANGUAGE: "es",
    SABLONO: "Sablono",

    /**
     * DICTIONARY
     */

    _DECIMAL: "{{n}}.{{m}}",

    _SECONDS: "segundos",
    _MINUTES: "minutos",
    _HOUR: "hora",
    _HOURS: "horas",
    _WORK_HOUR: "hora de trabajo",
    _WORK_HOURS: "horas de trabajo",
    _DAY: "día",
    _DAYS: "días",
    _WORKDAY: "día laboral",
    _WORKDAYS: "días laborales",
    _YEARS: "años",

    //Aliases for units
    _wd: "Días laborales",
    _wh: "Horas laborales",
    _h: "Horas",
    _d: "Días",

    _wd_ABBREVIATION: "dl",
    _wh_ABBREVIATION: "hl",
    _h_ABBREVIATION: "h",
    _d_ABBREVIATION: "d",

    _LAST_MODIFIED: "Última modificación",
    _SCHEDULE: "Calendario",

    _ON_SCHEDULE: "A tiempo",
    _AT_RISK: "En riesgo",
    _BEHIND_SCHEDULE: "Retrasado",
    _BEHIND_BASELINE_SCHEDULE: "Atrasado con respecto al programa original",
    _AHEAD_SCHEDULE: "Antes de la fecha prevista",
    _UNSCHEDULED: "No programado",
    _NO_PROCESS: "Sin flujo de trabajo",

    _PLANNED: "Planificado",
    _PLANNED_TO_BE_DONE: "Planificado a realizar",
    _ACTUAL: "Real",

    _NEW_NOTES: "Notas nuevas",
    _NOTES: "Notas",
    _NOTE_TEXT: "Texto de nota",
    _ISSUES_CLOSED: "Emisiones cerradas",
    _ISSUE_TEMPLATE_CODE: "Plantilla de notas",

    _CLAIM: "Cuestión de calidad ",
    _CLAIMS: "Cuestiones de calidad ",
    _OBSTRUCTION: "Obstrucción",
    _OBSTRUCTIONS: "Obstrucciones",

    _OPEN_CLAIM: "cuestión de calidad abierta",
    _OPEN_ISSUE: "cuestión pendiente",
    _OPEN_OBSTRUCTION: "obstrucción abierta",

    _OPEN_CLAIMS: "cuestiones de calidad abiertas",
    _OPEN_ISSUES: "Cuestiones abiertas",
    _OPEN_OBSTRUCTIONS: "obstrucciones abiertas",

    _ISSUE_STATUS: "Estado",
    _ISSUE_STATUS_OPEN: "Abierto",
    _ISSUE_STATUS_CLOSED: "Cerrado",
    _ISSUE_STATUS_WFC: "En revisión",
    _ISSUE_STATUS_REJECTED: "Reabierto",
    _ISSUE_STATUS_CONFIRMED: "Cerrado",
    _ISSUE_STATUS_NOT_REMOVED: "No suprimido",
    INPUT_INCLUDE_REMOVED_ISSUES_LABEL: "Estado de supresión",
    _ISSUE_STATUS_REMOVED: "Suprimido",

    _INFO_S: "información",
    _NO_NOTES: "Ninguno",
    _ISSUE_TYPE_INFO: "Informacion",

    _CLOSED_CLAIMS: "cuestiones de calidad cerradas",
    _CLOSE_ISSUE: "tema cerrado",
    _CLOSED_ISSUES: "cuestiones cerradas",
    _CLOSED_OBSTRUCTIONS: "obstrucciones cerradas",

    _OPEN_CLAIMS_N: "{{items}} cuestiones de calidad abiertas",
    _OPEN_ISSUES_N: "{{issues}} cuestiones abiertas",
    _OPEN_OBSTRUCTIONS_N: "{{items}} cuestiones de obstrucción abiertas",
    _INFO_N: "{{items}} notas",

    _ACTIVITIES_FINISHED_N: "{{done}} de {{quantity}} están terminadas",
    _ACTIVITIES_FINISHED: "actividades realizadas",
    _ACTIVITIES: "actividades",
    _ACTIVITIES_N: "{{activities | number}} Actividades",
    _CUSTOM_ACTIVITIES: "Actividades personalizadas",

    _CONTAINS: "Contiene",
    _CREATED: "Creado:",
    _DRAFT: "Borrador",

    _DATE: "Fecha",
    _DATE_REPORTED: "Reportado",
    _DATE_UPDATED: "Actualizado",
    _TODAY: "Hoy",
    _END_OF_LAST_MONTH: "Final del mes pasado",
    _CUSTOM_DATE: "Personalizado",
    _UNTIL: "Hasta",
    _ANYTIME: "En cualquier momento",
    _DAYS_BETWEEN_OVER_30_DAYS: "Hace más de 30 días",
    _DAYS_BETWEEN_TO_30_DAYS: "Últimos 30 Días",
    _DAYS_BETWEEN_TO_7_DAYS: "Últimos 7 Días",
    _DAYS_BETWEEN_AFTER_30_DAYS: "Próximos 30 Días",
    _DAYS_BETWEEN_AFTER_7_DAYS: "Próximos 7 Días",
    _TEN_DAYS_AGO: "En los últimos 10 días",
    _LAST_MONTH: "Último mes",
    _LAST_WEEK: "Última semana",
    _NEXT_WEEK: "Próxima semana",
    _THIS_WEEK: "Esta semana",
    _WEEK_OF_YEAR: "Inicio de la semana",

    _TO_DATE_NOT_SET: "ninguno",
    _FROM_DATE_NOT_SET: "ninguno",
    _UNSET: "no establecido",
    _TOPOLOGICAL_INDEX: "Actividad-posición en secuencia",

    _CUSTOM: "Definido por el usuario...",
    _STATUS: "Estado de la entregable",
    _UPDATING_STATUS: "Actualización de estado",
    _STATUS_UPDATED: "Estado actualizado",
    _FROM: "Desde",
    _TO: "A",
    _ALL: "Todos",
    _NO_DESCRIPTION: "Sin descripción",

    _INFO: "Notas de información",
    _ALL_TYPES: "Todos",
    _IMAGES: "Imágenes",

    _EVERYBODY: "Todos los demás",

    _ROLE_DEFAULT: "",
    _ROLE_ADMIN: "Administrador de Proyecto",
    _ROLE_MANAGER: "Gerente de Proyecto",
    _ROLE_INSPECTOR: "Inspector de Obra",
    _ROLE_CONTROLLER: "Controlador de Proyecto",
    _ROLE_REVIEWER: "Observador (sólo lectura)",
    _ROLE_REPLACEMENT: "Reemplazo",

    _ROLE_COMMERCIAL_MANAGE: "Gerente Comercial",
    _ROLE_COMMERCIAL_READ: "Revisor Comercial",

    _ROLE_INITIALS_ADMIN: "AP",
    _ROLE_INITIALS_MANAGER: "GP",
    _ROLE_INITIALS_INSPECTOR: "IO",
    _ROLE_INITIALS_CONTROLLER: "CP",
    _ROLE_INITIALS_REVIEWER: "OP",

    _COMPONENT: "Componente",
    _DELIVERY: "Entregable",
    _DOCUMENT: "Documento",
    _ROOM: "Habitación",
    _TRADE: "Oficio",
    _GROUP: "Grupo",
    _OTHERS: "Otro",
    _ACTIVITY: "Actividad",
    _DELIVERABLE: "Entregable",

    _PROGRESS_0: "no iniciado",
    _PROGRESS_100: "terminado",
    _PROGRESS_50: "trabajo iniciado",
    _PROGRESS_CHANGES: "Actualizaciones de actividad",
    _PROGRESS_CANNOT_START: "Reportar una nota",

    _NOT_STARTED: "No iniciado",
    _STARTED: "Trabajo iniciado",
    _DONE: "Terminado",
    _WAITING_FOR_CONFIRMATION: "Esperando confirmación",
    _CONFIRMED: "Confirmado",
    _REJECTED: "Rechazado",
    _APPROVED: "Aprobado",
    _COMPLETED: "Terminado: Confirmado + Terminado por el equipo de ejecución",
    _REVIEW_CHECKLIST: "Verificar Lista QA",

    _SELECT_ALL: "Seleccionar todo",
    _DESELECT_ALL: "Desmarcar todo",
    _SHOW_MORE: "Más",

    _PREDECESSOR: "Predecesor",
    _SUCCESSOR: "Sucesor",

    _PREDECESSOR_N:
        "Depende de {{items}} {{(items ===0 || items > 1) ? 'antecesores' : 'predecesor'}}",
    _SUCCESSOR_N:
        "Influye {{items}} sucesor{{(items ===0 || items > 1) ? 'es' : ''}}",

    _COPYRIGHT: "© {{year}} Sablono GmbH",

    _NEXT: "Siguiente",
    _REFRESH: "Actualizar",
    _UPDATE: "Actualizar",
    _IMPORT: "Importar",
    _DOWNLOAD: "Descargar",
    _DOWNLOAD_FILE: "Descargar archivo",
    _DOWNLOAD_MACRO: "Descargar macro",
    _CANCEL: "Cancelar",
    _CONTINUE: "Continuar",
    _GO_TO: "Ir a",
    _CLOSE: "Cerrar",
    _FINISH: "Hecho",
    _YES: "Sí",
    _NO: "No",
    _SAVED: "Guardado",

    _TEAM_COLOR: "Color del equipo",
    _COLOR: "Color",
    _STREET: "Dirección postal",
    _POST_CODE: "Código Postal",
    _CITY: "Ciudad",
    _COUNTRY: "País",
    _CONTACT_INFO: "Asignado a",
    _COMPANY: "Información de la Compañía:",

    _EDIT: "Editar",

    LATE_BY_DAYS: "{{ days }} Atrasado",

    _NOTE_RESPONSIBLE_TEAM: "Equipo responsable",
    _NOTE_RESPONSIBLE_TEAM_SHORT: "Responsable",
    _NOTE_CONFIRMATION_TEAM_SHORT: "Revisor",

    _NOTE_CONFIRMATION_TEAM: "Equipo revisor",

    _AREA_MANAGER: "Gerente responsable",

    _PROJECT_TEAM: "Equipo de acceso completo",
    PROJECT_TEAM_INVITED_MEMBERS: "Invitaciones pendientes",
    _NO_DEFAULT_CONFIRMATION_TEAM:
        "No hay equipo revisor preseleccionado por defecto",
    _REPORTERS_TEAM:
        "Equipo al que pertenece el usuario que informa de las notas",
    _UNRESTRICTED_TEAM: "No asignado",
    _NO_CONFIRMATION_TEAM_AVAILABLE: "No solicitado",

    _CREATION_DATE: "Creando fecha",

    _PROJECT_START_DATE: "Inicio",
    _PROJECT_DUE_DATE: "Final",

    _FILTER_TOOLTIP: "Filtrar",
    _SEND_MAIL: "Contacto",

    _AUTOCOMPLETE_SEARCH_BY_EMAIL_OR_USERNAME:
        "Escriba la dirección de correo electrónico y pulse la tecla Intro",
    _AUTOCOMPLETE_SEARCH_USER_NOT_FOUND:
        "Por favor, utilice la dirección de correo electrónico exacta de la persona que desea agregar a su proyecto. Pista",

    SECTION_TEAMS_SUPPORT_CENTER_TITLE: "Dirección de equipo",
    SECTION_TEAMS_ADD_NEW_TITLE: "Añadir un nuevo equipo",
    SECTION_TEAMS_ADD_NEW_TEXT:
        "Un equipo puede representar, por ejemplo, una empresa o un oficio. Las actividades creadas en Sablono pueden ser asignadas a equipos específicos para gestionar responsabilidades y visibilidad.",
    SECTION_TEAMS_EDIT_TITLE: "Editar equipo",
    SECTION_TEAMS_EDIT_TEXT:
        "Un equipo puede representar, por ejemplo, una empresa o un oficio. Las actividades creadas en Sablono pueden ser asignadas a equipos específicos para gestionar responsabilidades y visibilidad.",
    SECTION_TEAMS_EDIT_CONTACT_TITLE: "Detalles de contacto",
    SECTION_TEAMS_EDIT_CONTACT_TEXT:
        "Por favor, introduzca las credenciales de la persona responsable de este equipo.",
    SECTION_TEAMS_EDIT_LOCATION_TEXT:
        "Por favor, introduzca el nombre y la dirección de la empresa responsable de este equipo.",
    SECTION_TEAMS_EDIT_DETAILS_TITLE: "Información del equipo",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TITLE:
        "¿Renunciar a los derechos administrativos?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TITLE:
        "¿Renunciar a los derechos director del proyecto?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TEXT:
        "¿Estás seguro de que quieres cambiar tu rol a uno diferente? Esto no se puede deshacer",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TEXT:
        "¿Estás seguro de que quieres cambiar tu rol a uno diferente? Esto no se puede deshacer, porque perderás todos los privilegios para administrar a los miembros del equipo del proyecto",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TITLE: "¿Estás seguro?",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TEXT:
        "¿Realmente quieres retirarte de este proyecto? Después de eso ya no podrá acceder a él.",
    SECTION_TEAMS_PROJECT_TEAM_INFO_TOOLTIP:
        "El equipo de acceso completo es el único equipo que puede editar todas las actividades del proyecto aunque la actividad haya sido asignada a otro equipo. Por lo tanto, este rol es adecuado para la gestión de proyectos.",
    ACTION_DELETE_TEAM: "Borrar equipo",
    ACTION_DELETE_USER_FROM_TEAM: "Eliminar usuario",
    ACTION_CONFIRM_DELETE_USER_FROM_TEAM: "Confirmar eliminación",
    ACTION_UPLOAD_TEAM_LOGO: "Subir logotipo",

    INFO_NO_MATCHES_FOUND: "No se encontraron coincidencias",
    INFO_CONTACT_CUSTOMER_SUPPORT: "¿Necesita ayuda o más información?",
    ACTION_WATCH_VIDEO: "Vea nuestro video",

    _STRUCTURE: "Estructura",
    _PROJECT_STRUCTURE: "Estructura del proyecto",
    _NAME: "Nombre",
    _CODE: "Código",
    _DESC: "Descripción",

    ENTER_FULLSCREEN: "Tablero del sitio de inicio",
    EXIT_FULLSCREEN: "Cerrar el panel de control del sitio",
    LAST_UPDATED: "Datos actualizados por última vez",
    FULLSCREEN_REFRESH: "Recargar los datos",

    PLANNED_DATES_TOOLTIP:
        "Estas fechas han sido planificadas y fijadas por usted o uno de sus colaboradores.",
    CALCULATED_DATES_TOOLTIP:
        "Estas fechas han sido calculadas automáticamente por Sablono en base a sus flujos de trabajo.",
    FORECASTED_DELIVERY_TOOLTIP:
        'Sablono pronosticó el final de esta entregable en base a tu progreso actual. "En riesgo" es presentado si una actividad de esta entregable está retrasada y no forma parte del camino crítico.',

    NAME: "Nombre",
    CODE: "Código",
    _PROGRESS: "Avance",
    _INSPECTION: "Inspección",
    START_DATE: "Inicio",
    USER_DEFINED_START: "Inicio definido por el usuario",
    USER_DEFINED_END: "Final definido por el usuario",
    EARLIEST_START: "Inicio más temprano (Línea de base)",
    EARLIEST_END: "Final más temprano (Línea de base)",
    DUE_BETWEEN: "Debido entre",
    END_DATE: "Final",
    LATEST_START: "Inicio más tardío (Línea de base)",
    LATEST_END: "Final más tardío (Línea de base)",
    DUE_DATE: "Final",
    DURATION: "Duración",
    DURATION_UNIT: "Unidad de duración",
    LAST_PLANNER_START: "Inicio (Corto plazo)",
    LAST_PLANNER_END: "Finalización (Corto plazo)",

    REVISION_NUMBER_TOOLTIP: "Versión de plantilla",

    TOTAL_FLOAT: "Holgura total (Línea de base)",
    TOTAL_FLOAT_OF_X:
        "{{float >= 0 ? float + ' día' + (float === 1 ? 's' : '') : '-'}}",
    TOTAL_FLOAT_TOOLTIP:
        "Este buffer estará disponible para finalizar la entregable entre el inicio más temprano y el final más tardío.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_DELIVERABLE:
        "Este buffer estará disponible para finalizar la entregable entre el inicio más temprano y el final más tardío.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_ACTIVITY:
        "Este buffer estará disponible para finalizar la actividad entre el inicio más temprano y el final más tardío.",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_DELIVERABLE:
        "Esta Entregable forma parte de un Trayecto Crítico por lo cual no hay búfer disponible.",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_ACTIVITY:
        "Esta Actividad forma parte de un Trayecto Crítico por lo que no hay búfer disponible.",
    TOTAL_FLOAT_TOOLTIP_IS_NEGATIVE:
        "En función de la duración de sus Actividades y de las dependencias entre ellas, no podrá coincidir con las fechas de finalización definidas por el usuario.",
    TOTAL_FLOAT_TOOLTIP_IS_NULL:
        "No se calculó la Holgura Total porque no se definió ni la fecha de inicio ni la fecha de finalización para este trayecto.",

    PRIORITY: "Prioridad",
    TIME_OF_DAY: "Tiempo",
    PERCENTAGE_FINISHED: "terminado",
    _FINISHED: "Terminado",
    _REMOVE: "eliminar",
    _PROCESS: "Flujo de trabajo",
    _PROCESS_TEMPLATE: "Plantilla de flujos de trabajo",
    _STAGE: "Actividad",
    _UNSTRUCTURED: "No estructurado",
    _NO_TEMPLATE_ASSIGNED: "Ningún flujo de trabajo asignado",
    _NOT_SET: "Ninguno",
    _SELECT_STATUS: "... y su estado.",
    _ANY: "Todos",
    _LAST_INSPECTION: "Última actualización",
    WORK_SCHEDULED: "Trabajo planificado",
    _LEGEND: "Leyenda",

    _STATE: "Estado",
    _ACTIVITY_NUMBER: "Número de actividades",
    _DELIVERABLE_NUMBER: "Número de Entregables",

    INSPECTED_BY: "Último informe de {{timestamp}} por {{inspector}}",
    REPORTED_BY: "Reportado en {{timestamp}} por {{inspector}}",
    UPDATED_BY: "Última actualización en {{timestamp}} pro {{inspector}}",
    LAST_MODIFIED_BY: "Última editado el {{timestamp}} por {{user}}",
    REMOVED_BY: "Suprimido el {{timestamp}} por {{user}}",
    EDITED: "Editado",
    REMOVED: "Suprimido",

    _FILTER_NAME_TEXT_PLACEHOLDER: "Filtro por nombre de entregable...",
    _FILTER_CODE_TEXT_PLACEHOLDER: "Filtro por código de entregable...",
    _FILTER_TYPE_TEXT_PLACEHOLDER: "Filtro por tipo de entregable...",
    _FILTER_DESC_TEXT_PLACEHOLDER: "Filtro por descripción de entregable...",
    _FILTER_STAGE_TEXT_PLACEHOLDER:
        "Buscar y seleccionar una actividad o grupo...",
    _FILTER_LABEL_STAGE: "Etapa de entregable",
    _FILTER_LABEL_PROCESS: "Plantilla del flujo de trabajo",
    _FILTER_WORKFLOW_TEXT_PLACEHOLDER:
        "Buscar y seleccionar una plantilla de flujo de trabajo...",
    _FILTER_STRUCTURE_TEXT_PLACEHOLDER:
        "Buscar y seleccionar una parte de la estructura del proyecto...",
    _FILTER_SEARCH_DELIVERABLE_PLACEHOLDER:
        "Búsqueda de Entregables por nombre, código o descripción...",
    _FILTER_SEARCH_TEAM_MEMBERS_PLACEHOLDER: "Buscar miembros del equipo...",
    _FILTER_SEARCH_NOTES_PLACEHOLDER: "Busca notas por texto...",
    _SEARCH_BY_STAGE_PLACEHOLDER: "Buscar y seleccionar una actividad...",

    _DELIVERABLE_SEARCH: "Buscar",

    _ACTION_SHOW_ADVANCED_FILTERS: "Opciones avanzadas",
    _ACTION_SHOW_REDUCED_FILTERS: "Opciones básicas",

    /**
     * LOCALIZED LINKS
     */
    LINK_DOWNLOAD_MACRO: "/downloads/modAstaSablonoImporter.bas:",
    LINK_VERSION_PAGE:
        "https://support.sablono.com/es/articles/4783534-actualizaciones-de-la-plataforma-web-sablono",
    LINK_VISUALIZATION_VIDEO: "https://www.youtube.com/watch?v=mkLL8Gt1aRc",
    LINK_SUPPORT_CENTER: "https://support.sablono.com",
    LINK_IDEAS_PORTAL: "https://sablono.ideas.aha.io/?sort=recent",
    LINK_IMPRESSUM: "https://www.sablono.com/legal/imprint",

    /**
     * ACTIONS
     */

    ACTION_LOGIN: "Iniciar sesión",
    ACTION_REGISTER_USER: "Registrarse",
    ACTION_FINALISE_REGISTER_USER: "Finalizar",
    ACTION_CHANGE_USER: "Modificar usuario",
    ACTION_CHANGE_PASSWORD: "Cambiar contraseña",
    ACTION_ARIA_LABEL_CHANGE_LANGUAGE: "Cambiar Idioma",

    ACTION_SETUP_PASSWORD: "Definir nueva contraseña",
    LOGIN__PASSWORD_RESET_REQUIRED_MESSAGE:
        "Necesitas definir una nueva contraseña para tu usuario. Por favor, use el botón de abajo para hacerlo.",

    ACTION_CREATE_EMPTY_TOOLTIP: "Cree su propio proyecto",
    ACTION_PROJECT_FROM_IMPORT_TOOLTIP: "Importar proyecto",

    ACTION_CALL_THE_SUPPORT: "Abrir chat de soporte",
    ACTION_SHOW_ERROR_DETAILS: "Mostrar detalles",

    ACTION_OPEN_IN_TEMPLATE_EDITOR: "Abrir en el editor de plantillas",
    ACTION_ASSIGN_ACTIVITIES: "Asignar actividades",
    ACTION_SHOW_WHOLE_PROCESS: "Mostrar todo el flujo de trabajo",
    ACTION_SHOW_ALL_ACTIVITIES: "Mostrar todas las actividades",

    // NAV
    ACTION_NAV_TO_IMPRINT: "Pie de imprenta",
    ACTION_NAV_TO_AGB: "T&Cs",
    ACTION_NAV_TO_FAQ: "Soporte en línea",
    ACTION_NAV_TO_VERSION: "Actualizaciones y lanzamientos",
    ACTION_NAV_TO_IDEAS: "Portal Ideas",
    ACTION_NAV_TO_CONTACT: "Persona de contacto",
    ACTION_NAV_TO_APP: "Inspect Aplicación Web",
    ACTION_NAV_TO_LEARNING_CENTER: "Ir al Centro de Aprendizaje",
    ACTION_NAV_TO_LOGIN: "Ir al inicio de sesión",
    ACTION_RESEND_VERIFICATION:
        "Enviar un nuevo código de verificación por correo electrónico",
    ACTION_NAV_TO_PROJECTS: "Ir a proyectos",
    ACTION_NAV_TO_CONFIRM_RESET_PASSWORD: "Ir a Resetear Contraseña",
    ACTION_NAV_TO_SITE_DIARY: "Registros diarios",

    ACTION_NAV_TO_DASHBOARD: "Tablero de Rendimiento",
    ACTION_NAV_TO_LEANBOARD: "Planificación Lean",
    ACTION_NAV_TO_PROJECT_SELECTION: "Mostrar mis proyectos",
    ACTION_NAV_TO_ALL_PROJECTS: "Mis proyectos",
    ACTION_NAV_TO_PROJECT_DETAILS: "Configuración del proyecto",
    ACTION_NAV_TO_WBS: "Estructura del proyecto",
    ACTION_NAV_TO_TEAMS: "Equipos",
    ACTION_NAV_TO_PROJECT_DELIVERABLES: "Entregables",
    ACTION_NAV_TO_TEMPLATE_ELEMENT: "Plantillas de flujo de trabajo",
    ACTION_NAV_TO_TEMPLATE_CHECKLIST: "Listas de control de calidad",
    ACTION_NAV_TO_TEMPLATE_NOTES: "Plantillas de notas",
    ACTION_NAV_TO_TEMPLATE_NOTES_IMPORT: "Importar: Plantillas de notas",
    ACTION_NAV_TO_IMPORT_SELECTION: "Importar Entregables",
    ACTION_NAV_TO_INSPECTIONS: "Cronograma de actualizaciones",
    ACTION_NAV_TO_ANALYSIS: "Entregables",
    ACTION_NAV_TO_PROCESS_VIEWER: "Actividades",
    ACTION_NAV_TO_TRACKER: "Visualización de Actividades",
    ACTION_NAV_TO_NOTES: "Notas",
    ACTION_NAV_TO_HELP_FEEDBACK: "Ayuda y comentarios",
    ACTION_NAV_TO_USER_PROFILE: "Mi Perfil",
    ACTION_NAV_TO_VISUALIZATION: "Visualización de Progreso",
    ACTION_NAV_TO_ASSIGN_WORKFLOW: "Asignar esquema de flujos de trabajo",

    ACTION_NAV_TO_TEAM_EDIT_TOOLTIP: "Añadir miembros del equipo",

    ACTION_NAV_TO_IMPORT_EXCEL: "Entregables - Microsoft© Excel",
    ACTION_NAV_TO_IMPORT_MSPROJECT: "Proyecto Microsoft",
    ACTION_NAV_TO_IMPORT_ASTA: "Proyecto Asta Power",
    ACTION_NAV_TO_IMPORT_PRIMAVERA: "Oracle Primavera",
    ACTION_NAV_TO_IMPORT_XML: "archivo XML",
    ACTION_NAV_TO_IMPORT_EMPTY: "Nuevo Proyecto",
    ACTION_NAV_TO_IMPORT_ANOTHER_SOURCE: "Importar más Entregables",
    ACTION_NAV_TO_TOOLS: "Herramientas para importar/exportar",
    ACTION_NAV_TO_EXPORT: "Exportar Progreso del Proyecto",

    ACTION_NAV_TO_EXPORT_PRIMAVERA: "Exportar progreso de P6",
    ACTION_NAV_TO_REQUEST_ACCESS: "Solicitar acceso",

    ACTION_IMPORT_INTEGRATE:
        "Integre Sablono con sus herramientas habituales | Centro de ayuda de Sablono",
    ACTION_IMPORT_INTEGRATE_URL:
        "https://support.sablono.com/es/articles/2967622-paso-4-integre-sablono-con-sus-herramientas-habituales",

    ACTION_ASK_IMPORT_SOURCE: "¿No encontraste tu herramienta?",
    ACTION_ASK_IMPORT_SOURCE_SUBTITLE: "Contáctenos ahora",

    ACTION_ISSUE_EDITOR: "Crear nota nueva",

    /**
     * SECTIONS
     */
    SECTION_PROJECT_SELECTION_TITLE: "Mis proyectos",

    SECTION_DASHBOARD_TITLE: "Tablero de Rendimiento",
    SECTION_PROJECT_DETAIL_TITLE: "Configuración del proyecto",
    SECTION_PROJECT_DELIVERABLES_TITLE: "Entregables",
    SECTION_TEMPLATE_ELEMENTS_TITLE: "Plantillas de flujo de trabajo",
    //"SECTION_TEMPLATE_NOTES_TITLE": "Plantillas de notas",
    SECTION_TEMPLATE_NOTES_IMPORT_TITLE: "Importar: Plantillas de Notas",
    SECTION_INSPECTIONS_TITLE: "Cronograma de actualizaciones",
    SECTION_ANALYSIS_TITLE: "Entregables",
    SECTION_NOTES_TITLE: "Notas",
    SECTION_IMPORT_UPLOAD_TITLE: "Se completará la importación",
    SECTION_EXCEL_IMPORT_TITLE: "Importación de Microsoft© Excel",
    SECTION_XML_IMPORT_TITLE: "Importación XML",
    SECTION_NOTE_TEMPLATES_TITLE: "Plantillas de Notas",
    SECTION_TEMPLATES_TITLE: "Plantillas de flujo de trabajo",
    SECTION_WBS_TITLE: "Estructura del proyecto",
    SECTION_IMPORT_SELECTION_TITLE: "Importar datos del proyecto",
    SECTION_EXPORT_TITLE: "Progreso de las exportaciones",

    SECTION_EXCEL_IMPORT_DATES_TITLE: "Importación de Microsoft© Excel",
    TUTORIAL_IMPORT_DATES:
        "https://support.sablono.com/es/articles/5879573-como-importar-fechas-de-actividades-desde-microsoft-excel",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TITLE:
        "Fechas y Duraciones importadas con éxito ",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TEXT:
        "{{numberOfUpdates}} actividades han recibido nuevas fechas y/o duraciones.",
    SECTION_EXCEL_IMPORT_DATES_TEXT:
        "Por favor, asegúrese de que su Excel tiene el siguiente formato antes de importarlo: <br> Código de la entrega - Nombre de la actividad - Inicio - Fin - Formato de fecha - Duración - Unidad - ID del proyecto <br> Tenga en cuenta que no podrá importar las fechas de inicio y finalización de la misma actividad al mismo tiempo.",

    SECTION_TOOLS_TITLE: "Herramientas para importar/exportar",
    SECTION_TOOLS_IMPORT:
        "Actualizar su proyecto Sablono a la nueva versión de su calendario",
    SECTION_TOOLS_EXPORT:
        "Exportar el progreso de su proyecto para usarlo en su herramienta de planificación",

    SECTION_TOOLS_TILE_IMPORT:
        "<h3>Importar</h3> de <b>nuevos</b> entregables, avances o fechas de actividades",
    SECTION_TOOLS_TILE_UPDATE:
        "<h3>Actualizar</h3> para editar entregables <b>existentes</b>",
    SECTION_TOOLS_TILE_EXPORT:
        "<h3>Exportar</h3> el progreso de su proyecto para usarlo en otros programas",
    SECTION_TOOLS_IMPORT_ACTIVITIES:
        "Datos del Tracker existente - Microsoft© Excel",
    SECTION_TOOLS_IMPORT_PACKAGE:
        "Nuevo rastreador de actividad - Microsoft© Excel",

    SECTION_TOOLS_GO_DASHBOARD: "Ir al Tablero",

    SECTION_REQUEST_FOR_INFORMATION_TITLE: "Informe de progreso",
    SECTION_REQUEST_FOR_INFORMATION_OPT_OUT_TITLE: "Informe de progreso",

    SECTION_TOOLS_DIALOG_CREATED: "Importado en {{at}} por {{by}}",
    SECTION_TOOLS_DIALOG_UPDATED: "Última actualización en {{at}} por {{by}}",
    SECTION_TOOLS_DIALOG_MERGE_TITLE: "Actualizar tu proyecto Sablono",
    SECTION_TOOLS_DIALOG_MERGE_TEXT:
        "Por favor, seleccione la fuente de archivo que desea utilizar para actualizar su proyecto.",
    SECTION_TOOLS_DIALOG_EXPORT_TITLE: "Exportar el progreso de su proyecto",
    SECTION_TOOLS_DIALOG_EXPORT_TEXT:
        "Por favor, seleccione la parte de su proyecto que desea exportar el progreso.",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TILE:
        "@:INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TEXT:
        "Exportar el progreso del proyecto completo a un archivo CSV.",

    /**
     *
     * EXPORT PAGE TRANSLATIONS
     */
    SECTION_EXPORT_SELECT_FILE_SUBTITLE:
        "El progreso de su proyecto se escribirá en el archivo que cargue. <br/> Después puedes descargarlo e importarlo de nuevo a la herramienta que utilices.",
    SECTION_EXPORT_PROCESSING_TITLE: "Estamos actualizando su archivo...",
    SECTION_EXPORT_PROJECT_PROGRESS_TITLE:
        "Estamos exportando el progreso de su proyecto...",
    SECTION_EXPORT_SUCCESS_TITLE: "Su archivo está listo para descargar ahora.",
    SECTION_EXPORT_SUCCESS_TITLE_PRIMAVERA:
        "Su archivo actualizado está listo para descargar ahora.",
    SECTION_EXPORT_SUCCESS_SUBTITLE:
        "Se ha actualizado el estado de {{changed}} tareas.",
    SECTION_EXPORT_CSV_SUCCESS_SUBTITLE:
        "{{platformTotal}} líneas se han exportado.",
    SECTION_EXPORT_ASTA_RESOURCES:
        "Para importar el progreso de su proyecto a Asta Power Project por favor use la macro que se provee a continuación.",
    SECTION_EXPORT_ASTA_RESOURCES_DETAIL:
        "*Si usaste la macro antes no necesitarás descargarla de nuevo.",
    SECTION_EXPORT_WRONG_FILE_TITLE:
        "El archivo que seleccionó no parece coincidir con su proyecto Sablono...",
    SECTION_EXPORT_WRONG_FILE_SUBTITLE:
        "Las tareas en su archivo no se relacionan con las de Sablono. Para continuar por favor seleccione otro archivo.",
    SECTION_EXPORT_UP_TO_DATE_FILE_TITLE: "Su archivo ya está actualizado!",
    SECTION_EXPORT_UP_TO_DATE_FILE_SUBTITLE:
        "El progreso de su proyecto no ha cambiado desde la última vez que actualizó su archivo.",

    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_TITLE: "Sube tu archivo Primavera",
    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_SUBTITLE:
        "Para exportar el progreso de su proyecto, necesitaremos su proyecto Primavera en el formato de archivo Primavera-XML. <br />Después de subir el archivo, el progreso que documentaste con Sablono se añadirá automáticamente. <br />Después puedes volver a descargar el archivo e importarlo de nuevo a Primavera.",
    DIALOG_EXPORT_PROGRESS_TITLE: "Ups...",
    DIALOG_EXPORT_PROGRESS_CONTENT:
        "Lamentablemente, no hemos podido detectar si usted está trabajando con un sistema Primavera usando 'Coma' o 'Punto' como separador. Para poder importar el archivo proporcionado por nosotros, por favor díganos cómo está configurado su sistema.",
    DIALOG_EXPORT_PROGRESS_ACTION_COMMA: "Coma",
    DIALOG_EXPORT_PROGRESS_ACTION_FULL_STOP: "Punto",
    /**
     *
     * MERGE/UPDATE TRANSLATIONS
     */

    SECTION_MERGE_TITLE: "Actualizar proyecto",
    SECTION_MERGE_TOOLBAR_TITLE: "Actualiza tu proyecto Sablono",

    SECTION_MERGE_STEP_1_STEPPER: "Seleccione un archivo",
    SECTION_MERGE_STEP_2_STEPPER: "Seleccione Opciones de actualización",
    SECTION_MERGE_STEP_3_STEPPER: "Actualización del proyecto",
    SECTION_MERGE_STEP_5_STEPPER: "Actualización finalizada",

    SECTION_MERGE_STEP_1_TITLE:
        "Para actualizar su proyecto Sablono, por favor suba el archivo que contiene la última versión de su calendario.",
    SECTION_MERGE_STEP_1_SUBTITLE:
        "Seleccione un archivo que coincida con <strong>{{title}}</strong> (<em>{{fileName}}</em>).",

    SECTION_MERGE_STEP_2_TITLE: "Eliminar los productos de Sablono",
    SECTION_MERGE_STEP_2_SUBTITLE:
        "¿Desea eliminar los Entregables de Sablono que fueron importados con este archivo anteriormente pero que ya no están incluidos? Por favor, tenga en cuenta que esto también borraría todas las actividades y notas relacionadas con ellas.",
    SECTION_MERGE_STEP_2_KEEP: "No, guárdalos",
    SECTION_MERGE_STEP_2_DELETE: "Sí, bórralas",

    SECTION_MERGE_STEP_2A_TITLE:
        "Reactivar los entregables previamente suprimidos",
    SECTION_MERGE_STEP_2A_SUBTITLE:
        "¿Le gustaría reactivar los Entregables que forman parte de este archivo pero que fueron borrados del proyecto usando la plataforma Sablono después de la importación anterior?",
    SECTION_MERGE_STEP_2A_KEEP: "No, manténgalos borrados",
    SECTION_MERGE_STEP_2A_DELETE: "Sí, reactívelos",

    SECTION_MERGE_STEP_3_TITLE: "Estamos actualizando su proyecto Sablono...",

    SECTION_MERGE_STEP_4_TITLE: "Estamos procesando sus comentarios...",

    SECTION_MERGE_ERROR_TITLE: "Ups, algo salió mal...",
    SECTION_MERGE_ERROR_SUBTITLE:
        "Para asegurarse de que su proyecto Sablono se actualize con éxito, por favor inténtelo de nuevo. <br/>Si tiene más problemas, no dude en contactar con nosotros a través de nuestro servicio de chat.",

    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_HEADER:
        "Proyecto exitosamente actualizado",
    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_TEXT:
        "Su proyecto Sablono incluyendo sus Entregables y la Estructura del Proyecto fue actualizado a la última versión de su calendario.",
    SECTION_MERGE_SUCCESSFULL_XLS_PROJECT_UPLOAD_TEXT:
        "Su proyecto Sablono incluyendo sus Entregables y la Estructura del Proyecto fue actualizado a la última versión de su hoja de Excel.",
    SECTION_MERGE_INFO_EXCEL_MAPPING:
        "Utilice la columna <b>{{mappingKey}}</b> de su archivo como Código para asegurar una actualización exitosa.",
    SECTION_MERGE_INFO_EXCEL_MAPPING_HINT:
        "El Código se utiliza para identificar Entregables idénticas y actualizarlas.",

    /**
     * INPUT
     */

    INPUT_USERNAME_TITLE: "Email",
    INPUT_PASSWORD_TITLE: "Contraseña",
    INPUT_VERIFICATION_CODE_TITLE: "Código de Verificación",
    INPUT_NEW_PASSWORD_TITLE: "Nueva contraseña",
    INPUT_PASSWORD_REPEAT_TITLE: "Repetir contraseña",

    INPUT_FULL_NAME_TITLE: "Nombre",
    INPUT_FIRST_NAME_TITLE: "Nombre",
    INPUT_LAST_NAME_TITLE: "Apellido",
    INPUT_EMAIL_TITLE: "Correo electrónico del trabajo",
    INPUT_COMPANY_NAME_TITLE: "Nombre de la empresa",
    INPUT_REGISTER_PHONE_TITLE: "Teléfono del trabajo",
    INPUT_REGISTER_QUESTION_TITLE: "¿Cómo se enteró de nosotros?",
    INPUT_REGISTER_QUESTION_OTHER_TITLE: "¿Cómo exactmente?",
    INPUT_REGISTER_QUESTION_OPTION_OTHER: "algo más",
    INPUT_REGISTER_QUESTION_OPTION_WEBSITE: "Página principal de Sablono",
    INPUT_REGISTER_QUESTION_OPTION_TELESALE: "Campaña de telefonía",
    INPUT_REGISTER_QUESTION_OPTION_RECOMMENDATION: "Recomendación",
    INPUT_REGISTER_QUESTION_OPTION_GOOGLE: "Búsqueda en Internet",
    INPUT_REGISTER_QUESTION_OPTION_ADVERTISMENT: "Publicidad",

    INPUT_REGISTER_QUESTIONNAIRE_LABEL: "Escriba aquí su respuesta",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1:
        "¿Cómo ha conocido Sablono?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2:
        "¿Para qué desea utilizar Sablono?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3:
        "¿Por qué utilizar Sablono?",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_1:
        "Invitación personal o recomendación",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_2: "Redes sociales",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_3:
        "Artículo de blog",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_4:
        "Motor de búsqueda",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_5: "Evento",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_6: "Otro",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_1:
        "Seguimiento del progreso",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_2:
        "Documentación de calidad",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_3: "Informes",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_4:
        "Gestión de la valoración comercial'",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_5: "Planificación",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_6:
        "Reuniones de progreso diarias/semanales",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_1:
        "Sustituir los rastreadores Excel existentes",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_2:
        "Gestión comercial simplificada",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_3:
        "Documentación de calidad en una ubicación central",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_4:
        "Informes simplificados desde el sitio",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_5:
        "Reuniones diarias de progreso",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_6:
        "Mejor comprensión del estado del proyecto",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_7:
        "Sesiones de planificación anticipada",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_8:
        "Valoraciones comerciales",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1:
        "¿Cómo conoció Sablono Track?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2:
        "¿Qué va a seguir en Sablono?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3:
        "¿Por qué desea utilizar rieles Sablono?",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_1:
        "Invitación personal o recomendación",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_2: "Redes sociales",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_3:
        "Artículo de blog",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_4:
        "Motor de búsqueda",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_5: "Evento",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_6: "Otro",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_1: "Pilotaje",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_2: "Equipamiento",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_3: "Fachadas",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_4:
        "Paquete prefabricado",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_5:
        "Trabajos estructurales",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_6:
        "Proyecto completo",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_7: "Cortafuegos",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_8: "Otros",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_1:
        "Sustituir los rastreadores Excel existentes",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_2:
        "Gestión comercial simplificada",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_3:
        "Informes simplificados desde el sitio",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_4:
        "Reuniones diarias de progreso'",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_5:
        "Comprensión más fácil del estado del proyecto",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_6:
        "Gestión de la valoración comercial",

    INPUT_PROJECT_NAME_TITLE: "Nombre del proyecto",
    INPUT_PROJECT_CODE_TITLE: "Código del proyecto",
    INPUT_PROJECT_DESCRIPTION_TITLE: "Descripción del proyecto",
    INPUT_PROJECT_START_DATE_TITLE: "Inicio del proyecto",
    INPUT_PROJECT_LANGUAGE_TITLE: "Idioma del proyecto",
    INPUT_PROJECT_TIMEZONE_TITLE: "Zona horaria del proyecto",
    INPUT_PROJECT_START_TITLE: "Inicio del proyecto",
    INPUT_PROJECT_COMPLETION_TITLE: "Fin del proyecto",
    INPUT_PROJECT_TYPE: "Tipo de proyecto",
    INPUT_PROJECT_TYPE_LABEL: "Escriba aquí su respuesta",
    INPUT_PROJECT_TYPE_OPTION_1: "Construcción residencial",
    INPUT_PROJECT_TYPE_OPTION_2: "Construcción comercial",
    INPUT_PROJECT_TYPE_OPTION_3: "Fabricación y producción",
    INPUT_PROJECT_TYPE_OPTION_4: "Energía renovable",
    INPUT_PROJECT_TYPE_OPTION_5: "Patrimonio, conservación, rehabilitación",
    INPUT_PROJECT_TYPE_OPTION_6: "Pública (Sanidad, Educación, Defensa)",
    INPUT_PROJECT_TYPE_OPTION_7:
        "Construcción industrial (distribución, logística, centros de datos)",
    INPUT_PROJECT_TYPE_OPTION_8: "Infraestructuras, ferrocarril, carreteras",
    INPUT_PROJECT_TYPE_OPTION_9: "Vivienda privada",
    INPUT_PROJECT_TYPE_OPTION_10: "Otros",
    INPUT_PROJECT_IMAGE_SIZE_SUGGESTION: "Recomendado: 800x600px",
    INPUT_PROJECT_CURRENCY_TITLE: "Moneda del proyecto",

    ACTION_CREATE_PROJECT: "Crear proyecto",
    ACTION_LOGOUT: "Cerrar sesión",

    SECTION_SIDENAV_SETUP_TITLE: "Configuración",
    SECTION_SIDENAV_DASHBOARDS_AND_TRACKERS_TITLE: "Tableros & Visualizaciones",
    SECTION_SIDENAV_LISTS_TITLE: "Listas",
    SECTION_SIDENAV_SCHEDULES_AND_TIMELINES_TITLE:
        "Horarios & Líneas de tiempo",
    SECTION_SIDENAV_RESOURCES_TITLE: "Información",
    SECTION_SIDENAV_NO_PROJECT: "Ningún proyecto seleccionado.",

    SECTION_CREATE_EMPTY: "Crear nuevo proyecto",
    SECTION_CREATE_FROM_IMPORT: "Importar un proyecto desde otras herramientas",

    SECTION_LOGIN_TITLE: "Iniciar sesión",
    SECTION_REGISTRATION_TITLE: "Regístrate",
    SECTION_REGISTRATION_LANGUAGE: "Idioma",
    SECTION_CREATE_PROJECT_TITLE: "Crear proyecto",

    INFO_NO_INSPECTIONS: "ninguna inspección todavía",
    INFO_RECENT_INSPECTION: "inspección más reciente",
    INFO_TEAM_MEMBER: "Miembro del equipo",
    INFO_TEAM_MEMBERS: "Usuarios de flujo de trabajo y control de calidad",
    INFO_MORE_TEAM_MEMBER: "y 1 otro",
    INFO_MORE_TEAM_MEMBERS: "y {{numTeam}} otros",
    INFO_PROJECT_PROGRESS: "terminado",
    INFO_PROJECT_EXPIRY:
        "Los proyectos de demostración caducan tras 14 días de inactividad.",
    INFO_COMMERCIAL_USERS: "Usuarios comerciales",

    // Simple Labels
    BACK: "Atrás",
    CLOSE: "Cerrar",
    YES: "Sí",
    NO: "No",
    NOT_APPLICABLE: "N/A",
    PROJECT_LANGUAGE: "Idioma del proyecto",
    PROJECT_PREVIEW: "Vista previa del proyecto",
    FILE_VIEW: "Vista de archivo",
    PREVIEW_PROJECT: "Vista previa del proyecto",
    VIEW_FILE: "Ver archivo",
    SELECT_FILE: "Seleccionar archivo",

    REFRESH: "Actualizar",
    UPLOAD: "Subir",
    SETTINGS: "Ajustes",
    VISUALIZATION_SHOW_SETTINGS_TOOLTIP: "Ajustes",
    VISUALIZATION_SWITCH_SHOW_BORDER_INDICATOR: "Emisiones de marcas",

    _UPLOADED: "Subido",
    _GENERATED: "Generado",

    // Basic Error Messages
    ERROR_UNKNOWN_TITLE: "Error desconocido",
    ERROR_UNKNOWN_MESSAGE:
        "Se produjo un error inesperado. Póngase en contacto con la línea directa de asistencia técnica o support@sablono.com",
    ERRORS_NO_DATA_TITLE: "No hay datos disponibles",
    ERRORS_NO_DATA_MESSAGE: "No hay datos disponibles para la función deseada.",
    ERRORS_AUTHENTICATION_TITLE: "Error de autenticación",
    ERRORS_AUTHENTICATION_MESSAGE: "Su sesión ha expirado.",

    // HANA Native Errors
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_TITLE: "Este valor ya existe",
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_MESSAGE:
        "Este valor no se puede utilizar porque ya está siendo utilizado por el sistema.",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_TITLE:
        "Acción cancelada: Privilegios insuficientes",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_MESSAGE:
        "La Acción no se puede realizar porque faltan privilegios del sistema.",
    ERROR_HANA_VALUE_TO_LARGE_TITLE: "El valor supera el límite",
    ERROR_HANA_VALUE_TO_LARGE_MESSAGE:
        "La Acción no se puede realizar porque un valor excede el límite de la base de datos.",

    ERROR_DATE_END_BEFORE_START:
        "La fecha final debe ser anterior a la fecha de inicio.",
    // Custom HANA errors
    MISSING_INPUT: "Acción anulada: Faltan los parámetros requeridos",
    ERROR_DATE_END_BEFORE_START_MESSAGE:
        "Cambio de fecha imposible! La fecha final debe ser anterior a la fecha de inicio.",
    ERROR_PROJECT_OPERATION_DENIED:
        "Acción cancelada: Privilegios insuficientes",
    ERROR_COMPONENT_OPERATION_DENIED:
        "Acción cancelada: Privilegios insuficientes",
    ERROR_ACTIVITY_OPERATION_DENIED:
        "Acción cancelada: Privilegios insuficientes",
    ERROR_INVALID_ALLOCATION_UNIT:
        "Acción cancelada: Tipo de calendario desconocido",
    ERROR_INVALID_COLOR: "Acción cancelada: Color inválido",
    ERROR_MISSING_REQUIRED_PARAM:
        "Acción anulada: Faltan los parámetros requeridos",
    ERROR_CHANGING_PROGRESS_SIGNED_CHECKLIST_REQUIRED:
        "Informe sobre el progreso de esta actividad a través de la aplicación móvil porque tiene adjunta una lista de control de calidad.",
    ERROR_CONFIRMING_SIGNED_CHECKLIST_REQUIRED:
        "Informe sobre el progreso de esta actividad a través de la aplicación móvil porque tiene adjunta una lista de control de calidad.",
    ERROR_CHANGING_PROGRESS_ALREADY_CONFIRMED:
        "Al menos una actividad ha sido confirmada",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED:
        "Todos los Gerente responsable deben pertenecer al mismo equipo.",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED_TITLE:
        "Lamentablemente, no es posible...",
    ERROR_CHAIN_LEAVE_AREA_MANAGER_UNSUPPORTED:
        "Este Gerente responsable está sustituyendo actualmente a otro, por lo que no puede ser sustituido.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ASSIGNED:
        "Este usuario que usted seleccionó ya está reemplazando a este Gerente responsable.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ACTIVE:
        "La sustitución ya está activada.",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_TITLE: "No responsable",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_MESSAGE:
        "Parece que usted seleccionó Entregables para las cuales otro usuario es el Jefe Responsable. Por lo tanto, no se pudo llevar a cabo la actualización masiva de los progresos. Por favor, seleccione sólo las Entregables de las que usted es responsable e inténtelo de nuevo.",
    ERROR_CHANGING_PROGRESS_REJECTED_TITLE: "Cambio de progreso inválido",
    ERROR_CHANGING_PROGRESS_REJECTED_MESSAGE:
        "Al menos una actividad seleccionada ha sido cambiada a rechadazada. Una actividad solo puede ser cambiada a terminada",
    ERROR_CHECKLIST_EXPORT_THRESHOLD:
        "Proceso abortado! Este servicio soporta actualmente un máximo de 2000 listas de control de calidad por cada solicitud. Por favor, repita el proceso con menos Entregables o actividades.",
    ERROR_CHECKLIST_EXPORT_SIZE_THRESHOLD:
        "Los informes solicitados superan nuestro límite de exportación de {{sizeLimitInMb}} MB. Su solicitud incluye {{numberOfDocumentsIncluded}} listas de control de calidad con {{numberOfImagesIncluded}} fotos ({{sizeEstimateInMbOfImages}} MB) y {{numberOfAttachmentsIncluded}} archivos PDF adjuntos ({{sizeEstimateInMbOfAttachments}} MB). Por favor, reduzca el tamaño de su solicitud de exportación utilizando los filtros. Si sigue recibiendo este mensaje, póngase en contacto con nuestro servicio de asistencia técnica para hablar de las opciones disponibles.",
    ERROR_UNIQUE_CODE_VIOLATION:
        "La lista de Excel que está tratando de importar a este proyecto contiene Códigos Entregables que ya existen. Si está tratando de cambiar los Entregables existentes, por favor utilice nuestra funcionalidad de Actualización. Si desea importar nuevos Entregables, por favor defina los Códigos en Excel que son únicos para este proyecto.",
    ERROR_UNIQUE_CODE_VIOLATION_TITLE: "Los códigos tienen que ser únicos",

    //Request Errors
    ERROR_REQUEST_TITLE:
        "Error de solicitud de servidor. Por favor, inténtalo de nuevo y si sigue ocurriendo, ponte en contacto con nuestro equipo de soporte y comparte los detalles.",
    ERROR_REQUEST_MESSAGE:
        "Se ha producido un error durante la comunicación con el servidor. Por favor, inténtalo de nuevo y si sigue ocurriendo, ponte en contacto con nuestro equipo de soporte y comparte los detalles.",
    ERROR_REQUEST_TIMEOUT_TITLE:
        "Pedir Timeout. Por favor, inténtalo de nuevo y si sigue ocurriendo, ponte en contacto con nuestro equipo de soporte y comparte los detalles.",
    ERROR_REQUEST_TIMEOUT_MESSAGE:
        "La solicitud tardó demasiado tiempo. Por favor, inténtalo de nuevo y si sigue ocurriendo, ponte en contacto con nuestro equipo de soporte y comparte los detalles.",

    ERROR_REQUIRED_EMAIL_USERNAME: "Email es requerido",
    ERROR_REQUIRED_PASSWORD: "Se requiere contraseña",
    ERROR_REQUIRED_VERIFICATION_CODE: "Se requiere código de verificación",
    ERROR_REQUIRED_FIRST_NAME: "Nombre es requerido",
    ERROR_REQUIRED_LAST_NAME: "Apellido es requerido",
    ERROR_REQUIRED_COMPANY: "Empresa es requerida",
    ERROR_REQUIRED_EMAIL: "Correo electrónico es requerido",

    //Authentication Errors
    ERROR_AUTHENTICATION_UNKNOWN_TITLE: "Error de acceso",
    ERROR_AUTHENTICATION_UNKNOWN_MESSAGE:
        "El inicio de sesión falló inesperadamente. Por favor, inténtalo de nuevo y si sigue ocurriendo ponte en contacto con nuestro equipo de soporte en support@sablono.com.",
    ERROR_AUTHENTICATION_NO_SESSION_TITLE: "sesión vencida",
    ERROR_AUTHENTICATION_NO_SESSION_MESSAGE: "Su sesión ha expirado.",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_TITLE:
        "Email o contraseña no válidos",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_MESSAGE:
        "Su email o contraseña no es válido.",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_TITLE: "usuarios discapacitados",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_MESSAGE:
        "Su usuario está deshabilitado. Póngase en contacto con el servicio de asistencia técnica.",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_TITLE:
        "usuarios expiraron",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_MESSAGE:
        "Su cuenta ha expirado. Póngase en contacto con el servicio de asistencia técnica.",
    ERROR_AUTHENTICATION_USER_LOCKED_TITLE: "usuarios bloqueados",
    ERROR_AUTHENTICATION_USER_LOCKED_MESSAGE:
        "Su cuenta ha sido bloqueada automáticamente debido a demasiados intentos de acceso. Por favor, espere 15 minutos e inténtelo de nuevo. Si el problema persiste, póngase en contacto con nuestro equipo de asistencia en support@sablono.com.",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_TITLE:
        "Cambio de contraseña requerido",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_MESSAGE:
        "Por favor, cambie su contraseña antes de continuar.",

    ERROR_PASSWORD_NOT_EQUAL_TITLE: "La contraseña no es la misma",

    ERROR_INPUT_REQUIRED_TITLE: "Campo obligatorio",
    ERROR_INPUT_EMAIL_REQUIRED_TITLE:
        "Por favor, introduzca un correo electrónico para ponerse en contacto con usted.",
    ERROR_MINLENGTH_TITLE: "min. {{minlength}} caracteres",
    ERROR_MAXLENGTH_TITLE: "máx. {{maxlength}} caracteres ",
    ERROR_MIN_ONE_LOWERCASE_LETTER_TITLE: "al menos una letra minúscula",
    ERROR_MIN_ONE_UPPERCASE_LETTER_TITLE: "al menos una letra mayúscula",
    ERROR_MIN_ONE_SPECIAL_CHAR_TITLE: "al menos un carácter especial",
    ERROR_MIN_ONE_NUMBER_TITLE: "al menos un dígito (0-9)",
    ERROR_NUMBER_ONLY: "Introduzca un número válido.",
    ERROR_NO_DECIMAL: "Por favor, utilice sólo números enteros",

    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_REMOVE_USER_FROM_GROUP_MESSAGE:
        "No está autorizado a eliminar un usuario",
    ERROR_GROUP_DOES_NOT_EXIST_MESSAGE: "El grupo no existe en la plataforma",
    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_ADD_USER_TO_GROUP_MESSAGE:
        "No está autorizado a añadir un usuario",
    ERROR_USER_ALREADY_IN_PROJECT:
        "El usuario que añades ya está en el proyecto",
    ERROR_USER_ALREADY_IN_PROJECT_MESSAGE: "{{users}} ya están en el proyecto",
    ERROR_LAST_ADMIN_IN_PROJECT:
        "Un proyecto debe tener al menos un administrador",
    ERROR_FORBIDDEN_USER_IN_MULTIPLE_SYSTEM_GROUPS_MESSAGE:
        "No se permite agregar un usuario en múltiples grupos de sistema.",

    ERROR_UNEXPECTED_TITLE:
        "Ups... eso no debería pasar. Mejor vuelve al Tablero",

    ERROR_UNSUPPORTED_FILE_EXTENSION:
        "Tipo de archivo no válido. Los tipos de archivo admitidos son {{fileTypes}}.",
    ERROR_FAILED_FILE_UPLOAD: "Error de carga. Por favor, vuelva a intentarlo.",
    ERROR_MULTIPLE_INVITATIONS_PER_PROJECT: "El usuario ya está invitado",

    DIALOG_ALERT_OK: "OK",

    DIALOG_CONFIRM_OK: "OK",
    DIALOG_CONFIRM_CANCEL: "Cancelar",
    DIALOG_CONFIRM_CREATE: "Guardar",
    DIALOG_CONFIRM_CREATE_AND_NEW: "Guardar y crear otro",

    DIALOG_SELECT_OK: "Seleccionar",
    DIALOG_SELECT_CANCEL: "Cancelar",
    DIALOG_SELECT_NEXT: "Siguiente",
    DIALOG_SELECT_BACK: "Atrás",

    //Unable to load chat script
    DIALOG_HELP_DESK_SCRIPT_BLOCKED_TITLE:
        "Advertencia de servicio de Servicio de asistencia!",
    DIALOG_HELP_DESK_SCRIPT_BLOCKED_CONTENT:
        "Alguna extensión está bloqueando el servicio de soporte, por favor deshabilite cualquier bloqueador de anuncios para asegurar una experiencia sin problemas.",

    //Unable to fetch progress for the project
    DIALOG_FETCH_PROGRESS_ERROR_TITLE: "Traer el fracaso del progreso",
    DIALOG_FETCH_PROGRESS_ERROR_CONTENT:
        "Se ha producido un error al buscar el progreso, por favor inténtelo de nuevo.",

    //Unable to apply filters due to resulting query being too large too process
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_TITLE: "Uuups!",
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_CONTENT:
        "Lamentablemente, no podemos aplicar el Filtro de Estructura de Proyecto actualmente seleccionado debido a limitaciones comunes de su navegador web. Seleccione una parte de la estructura más profunda (por ejemplo, Nivel en lugar de Edificio) u otro criterio de filtro.",

    // wrong extension
    DIALOG_WRONG_EXTENSION_TITLE: "El archivo tiene el formato incorrecto",
    DIALOG_WRONG_EXTENSION_CONTENT:
        "Por favor seleccione un archivo con el formato correcto.",

    //error reading file
    DIALOG_READING_FILE_ERROR_TITLE: "Error de lectura de archivos",
    DIALOG_READING_FILE_ERROR_CONTENT:
        "Se ha producido un error al leer el archivo, por favor inténtelo de nuevo.",

    // error file has false or not existing fields
    DIALOG_ANALYSING_FILE_ERROR_TITLE: "Error de lectura de archivos",
    DIALOG_ANALYSING_FILE_ERROR_CONTENT:
        "Se ha producido un error al leer el archivo, algunos campos faltan o están dañados. Por favor, compruebe su expediente.",

    // import error
    DIALOG_IMPORT_FILE_ERROR_TITLE: "Error de importación de archivos",
    DIALOG_IMPORT_FILE_ERROR_CONTENT:
        "Se ha producido un error al importar el archivo, por favor inténtelo de nuevo.",
    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF_TITLE:
        "@:SECTION_MERGE_ERROR_TITLE",
    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF:
        "El PDF no pudo ser exportado debido al gran número de páginas. Por favor, seleccione un intervalo de tiempo más pequeño o un número menor de productos.",

    DIALOG_USER_CANCELED: "Acción cancelada",

    DIALOG_IMPORT_MERGE_CONFLICT_TITLE: "Advertencia",
    DIALOG_IMPORT_MERGE_CONFLICT_CONTENT:
        "Parece que está a punto de importar un horario muy similar al que ya se importó a su proyecto. Si eliges continuar, cada tarea se añadirá de nuevo a tu proyecto Sablono. También puede actualizar su proyecto para evitar tener elementos duplicados.",
    DIALOG_IMPORT_MERGE_ACTION: "Actualizar proyecto",
    DIALOG_IMPORT_CONTINUE_ACTION: "Continuar importando",

    DIALOG_ASSIGN_TEMPLATE_TITLE:
        "Asignar plantilla de flujo de trabajo a entregable",
    DIALOG_ASSIGN_TEMPLATE_TEXT:
        "Seleccione una plantilla de flujo de trabajo que desee asignar a la entregable seleccionada actualmente.",
    DIALOG_ASSIGN_TEMPLATE_NO_DATA_TEXT:
        "Parece que todavía no ha creado una plantilla de flujo de trabajo. Utilice el botón de abajo para abrir el editor.",
    DIALOG_ASSIGN_TEMPLATE_NO_TEMPLATES_NAV_TO:
        "Ir a la creación de plantillas",
    ACTION_ASSIGN_TEMPLATE: "Asignar plantilla de flujos de trabajo",
    DIALOG_ASSIGN_TEMPLATE_SEARCH_PLACEHOLDER:
        "Buscar plantillas por nombre, código....",
    DIALOG_ASSIGN_TEMPLATE_EMPTY_SEARCH_TEXT:
        "No hay plantillas que coincidan con su término de búsqueda.",

    DIALOG_CLONE_TEMPLATE_TITLE: "@:ACTION_CLONE_TEMPLATE",
    DIALOG_CLONE_TEMPLATE_TEXT:
        "Por favor, seleccione el esquema de flujos de trabajo que desea copiar en este proyecto de la siguiente lista.",
    DIALOG_CLONE_TEMPLATE_BUTTON_TEXT: "Copiar plantilla",
    DIALOG_CLONE_TEMPLATE_SUCCESS_TOAST_TEXT:
        "Plantilla de flujos de trabajo copiada con éxito",
    DIALOG_CLONE_TEMPLATE_SEARCH_PLACEHOLDER:
        "Buscar plantillas por nombre, código y proyecto....",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_AND_QA_TITLE:
        "Ahora, especifique lo que debe ocurrir con las listas de control de calidad y los equipos vinculados a la plantilla de flujos de trabajo seleccionada.",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_SKIP:
        "No copie ni adjunte ninguna lista de control de calidad ni ningún equipo",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_REUSE:
        "Utilizar las listas de control de calidad y los equipos existentes y crear otros nuevos para adjuntarlos a la plantilla de flujos de trabajo copiada",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_CLONE:
        "Copiar todas las Listas de Control de Calidad y Equipos y adjuntarlas a la Plantilla de Flujo de trabajo copiada (puede dar lugar a duplicados)",

    // creating project
    DIALOG_CREATING_PROJECT: "Creación de proyectos",
    DIALOG_BUSY_TAKE_TIME: "Esto puede tardar un rato.",

    // creating new activities
    DIALOG_CREATE_ACTIVITY_TOOLBAR_TITLE: "Crear nueva actividad",
    DIALOG_ACTION_CREATE_ACTIVITY: "Crear operación",
    _RECENT_ACTIVITIES: "Actividades creadas recientemente",
    _ADD_ACTIVITY_TOOLTIP: "Añada actividad a este entregable.",

    // md-table
    ROWS_PER_PAGE: "Filas por página",

    UPLOAD_ERROR: "Error de carga",

    // Create new deliverables dialog
    ACTION_CREATE_DELIVERABLES: "Añadir Entregables",
    DIALOG_CREATE_NEW_DELIVERABLES_TITLE: "Crear nuevas Entregables",
    INPUT_NAME_NEW_DELIVERABLE_PLACEHOLDER:
        "Por ejemplo Apartamento 3.2.1 Habitación 202 Panel de fachada 3777-08",
    INPUT_CODE_NEW_DELIVERABLE_PLACEHOLDER:
        "E.g. Apto. 3.2.1, R-202, FP-3777-08 - máximo 64 caracteres",
    DIALOG_CREATE_NEW_DELIVERABLE_SAVED: "Entregable guardada",
    DIALOG_CREATE_NEW_DELIVERABLES_ERROR_EXISTING_CODE:
        "Error: Hay un entregable con ese código ya en el proyecto. Por favor, elija uno diferente.",

    UPLOAD_SUCCESSFUL: "Subida exitosa",
    UPLOAD_SUCCESSFUL_CREATED_INFO:
        "{{elements}} Entregables actualizadas con nuevos valores.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO:
        "{{elements}} Entregables actualizadas con nuevos valores del archivo.",
    UPLOAD_SUCCESSFUL_DELETED_INFO:
        "{{elements}} Entregables removidas del proyecto.",
    UPLOAD_SUCCESSFUL_CREATED_STRUCTURE_INFO:
        "{{elements}} elementos de la estructura creados y agregados a su proyecto.",
    UPLOAD_SUCCESSFUL_UPDATED_STRUCTURE_INFO:
        "{{elements}} elementos de estructura actualizados con nuevos valores del archivo.",
    UPLOAD_SUCCESSFUL_DELETED_STRUCTURE_INFO:
        "{{elements}} elementos de la estructura removidos del proyecto.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO_NO_FILE:
        "{{elements}} Entregables actualizados con nuevos valores.",
    UPLOAD_SUCCESS_WARN_LIMITS_TITLE:
        "El proyecto que acaba de importar contiene texto que excede los límites de nuestro sistema. Para poder importar, tuvimos que acortarlo.",
    UPLOAD_SUCCESS_WARN_LIMITS_MESSAGE:
        "Nuestros límites son 64 caracteres para códigos de Entregables, 120 caracteres para nombres y 1000 caracteres para descripciones.",

    TO_PROJECT_DATA: "Ver Entregables",
    GO_TO_DELIVERABLES: "Ir a Entregables",

    DIALOG_UPLOADING_FILE_ERROR_TITLE: "Error de carga de datos",
    DIALOG_UPLOADING_FILE_ERROR_CONTENT:
        "Ha ocurrido un error mientras se cargaban los datos, por favor trate de nuevo.",

    DIALOG_UPLOADING_REMAINING_TIME_LEFT:
        "Tiempo estimado restante: {{time}} segundos.",
    DIALOG_PREVENT_CLOSING:
        "Hay un proceso en marcha que no debe ser interrumpido!",

    // import
    INFO_IMPORT_PROJECT_DATA_INTRODUCTION_MESSAGE:
        "Cuando configure un nuevo proyecto, por favor, importe primero los Entregables desde Microsoft© Excel o Asta Powerproject.\n Más tarde, también puede importar las Fechas de Actividad o el Progreso a través de Microsoft© Excel.",
    IMPORT_UPLOAD_COMPONENTS_IN_PROGRESS:
        "Estamos enviando {{elements}} elementos a Sablono",
    SECTION_IMPORT_SUCCESSFULL_PROJECT_IMPORT_HEADER:
        "Proyecto importado con éxito",

    // excel import
    LEVEL: "Nivel ",
    ASSIGN_PROJECT_ELEMENTS: "Entregables",
    CREATE_STRUCTURE: "Estructura del proyecto",
    ADD_STURCUTRE_LEVEL: "Añadir nivel",
    NOT_ASSIGNED: "No asignado",
    INFO_IMPORT_NO_DEFINED_STRUCTURE: "No se ha definido ninguna estructura",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_TITLE:
        "Estructuras inconsistentes",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_MESSAGE:
        "Las estructuras de las filas {{ invalidRowNumberRanges. join () }} no son consistentes. Por favor, revise sus datos en el fichero de importación y vuelva a intentarlo.",
    INFO_HOW_TO_IMPORT_A_EXCEL_PROJECT:
        "Cómo importar un proyecto de Microsoft Excel",

    NO_MATCH_FOUND: "No se ha encontrado ninguna coincidencia...",

    // import preview
    STRUCTURE: "Estructura del proyecto",
    STRUCTURE_BRACES: "Estructura del proyecto (Resumen de tareas)",
    PROJECT_ELEMENTS: "Entregables",
    PROJECT_ELEMENTS_BRACES: "Entregables (tareas)",
    REFRESH_PREVIEW: "Actualizar vista previa del proyecto",

    // Select file
    ACTION_REFRESH_SELECTED_FILE_TOOLTIP: "Actualizar el archivo seleccionado",
    PLEASE_SELECT_FILE: "Seleccione un archivo",

    EDGE_MODE_ACTIVE:
        "Modo de borde activo - Haga clic en un nodo para crear un borde entre ellos.",
    EDGE_CREATE_SUCCESSFUL: "Borde creado con éxito",
    EDGE_DELETE_SUCCESSFUL: "Borde borrado con éxito",

    TOGGLE_EDIT_MODE_TOOLTIP: "Editar",

    ACTION_ACTIVATE_EDIT_MODE: "Editar entregable",
    ACTION_DEACTIVATE_EDIT_MODE: "Terminar de editar",

    // xml import
    XML_IMPORT_NO_STRUCTURE:
        "No pudimos detectar una estructura de proyecto en el archivo que está intentando cargar. Puede configurarlo fácilmente después de finalizar la importación utilizando el editor de plan de la estructura del proyecto.",
    // general PDF
    PDF_GEN_PROGRESS: "Archivo generado.",

    //qr codes
    ACTION_QR_CODE_GENERATE: "Imprimir códigos QR",
    ACTION_QR_CODE_GENERATE_TOOLTIP:
        "Cree códigos QR imprimibles para su estructura en formato PDF.",
    QR_DIALOG_TITLE: "Seleccione un formato",
    QR_2x6_TITLE: "2 x 6 códigos QR",
    QR_1x2_TITLE: "1 x 2 códigos QR",
    QR_STICKER_POWERED_BY: "Desarrollado por",
    QR_STICKER_WARN_TEXT:
        "El adhesivo sólo se puede retirar por orden del propietario.",
    QR_DIALOG_LAST_LEVEL_TEXT:
        "Imprimir elementos de estructura del último nivel",
    QR_DIALOG_SELECT_STRUCTURE_TITLE:
        "Imprimir sólo elementos de estructura seleccionados",
    QR_DIALOG_STRUCTURE_SELECTION_TEXT:
        "Crear códigos QR para una selección de elementos de estructura",
    QR_DIALOG_STRUCTURE_ALL_TEXT:
        "Impresión para todos los elementos de estructura",
    ERROR_REPORT_SIZE_LIMIT_TITLE: "Informe demasiado grande",
    QR_DIALOG_ERROR_REPORT_SIZE_MESSAGE:
        "Por favor, cree sus códigos QR para menos de {{max}} entregables.",
    QR_DIALOG_WBS_ERROR_REPORT_SIZE_MESSAGE:
        "Por favor, cree sus códigos QR para menos de {{max}} elementos de estructura.",
    DELIVERABLE_REPORT_ERROR_SIZE_MESSAGE:
        "Cree este informe para menos de {{max}} entregables.",
    ACTIVITIES_REPORT_ERROR_SIZE_MESSAGE:
        "Cree este informe para menos de {{max}} actividades.",

    // code generation
    ACTION_GENERATE_CODES: "Generar códigos",
    ACTION_GENERATE_CODES_TOOLTIP:
        "Generar códigos para todos los nodos de estructura.",
    CODE_GENERATION_CONFIRM_DIALOG_TITLE: "Generación de código de estructura",
    CODE_GENERATION_CONFIRM_DIALOG_CONTENT:
        "Se generarán nuevos códigos para todos los elementos del plan de la estructura del proyecto. Se sobrescribirán los códigos anteriores. ¿Quiere proceder?",
    CODE_GENERATION_SUCCESS_MESSAGE: "Códigos generados.",

    // merge structure in wbs
    ACTION_MERGE_STRUCTURE: "Combinar estructura",
    ACTION_MERGE_STRUCTURE_TOOLTIP:
        "Unir automáticamente estructura duplicada.",
    DIALOG_MERGE_STRUCTURE_TITLE: "Combine la estructura de su proyecto",
    DIALOG_MERGE_STRUCTURE_TEXT:
        "Por favor, seleccione la estructura del proyecto (por fuente) en la que desea integrar las partes duplicadas de la estructura. <br /><br /><br /><b> Sugerencia: </b> Para combinar las partes de la estructura, su nombre y el nivel de la estructura deben ser exactamente los mismos.",
    DIALOG_MERGE_STRUCTURE_LOADING_TITLE: "Sólo unos segundos...",
    DIALOG_MERGE_STRUCTURE_LOADING_TEXT:
        "En este momento estamos combinando la estructura de su proyecto...",
    DIALOG_MERGE_STRUCTURE_SUCCESS_TITLE: "Estructura del proyecto combinada",

    // WBS
    WBS_CREATE_CHILD_TOOLTIP: "Agregar Subcategoría",
    WBS_CREATE_SIBLING_TOOLTIP: "Agregar Categoría Relacionada",
    WBS_DELETE_NODE_TOOLTIP: "Borrar categoría",
    CONFIRM_DELETE_STRUCTURE_NODE_TITLE: "¿Estás seguro?",
    CONFIRM_DELETE_STRUCTURE_NODE_TEXT:
        "¿Realmente desea borrar esta parte de la estructura de su proyecto?",

    //SECTIONS

    // login and registration page
    SUCCESSFUL_USER_SIGNUP:
        "Recibimos su información y nos pondremos en contacto con usted a la brevedad.",
    SUCCESSFUL_USER_SIGNUP_GET_MORE_INFO:
        "Mientras tanto, siga conociendo Sablono aun más.",

    // project selection
    PROJECT_SELECTION_PENDING_INVITES_TITLE: "Invitaciones pendientes",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_ACCEPT: "Aceptar",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_DECLINE: "Rechazar",
    PROJECT_SELECTION_ACCEPT_INVITATION: "Invitación aceptada",
    PROJECT_SELECTION_DECLINE_INVITATION: "Invitación rechazada",
    PROJECT_SELECTION_WELCOME_CARD_TITLE: "Bienvenido a Sablono!",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_TEXT:
        "¿Aún no es miembro de un proyecto? Póngase en contacto con su Administrador de Proyectos para solicitar acceso.",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_BUTTON_TEXT:
        "Enviar correo electrónico ahora",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_TEXT:
        "Si tiene alguna pregunta, no dude en ponerse en contacto con nosotros los días laborales de 9 a 18 horas.",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_BUTTON_TEXT:
        "Contactar el equipo de soporte",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_SUBJECT:
        "Por favor, añádame al proyecto de Sablono",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_BODY:
        "Hola,%0D%0A%0D%0A he activado mi cuenta en Sablono. Por favor, añádame al proyecto ahora para que pueda empezar a usar la plataforma. Muchas gracias!%0D%0A%0D%0A Ir a Sablono: https://app.sablono.com",
    PROJECT_SELECTION_FILTERS_STATUS: "Status",

    // project edit
    PROJECT_LANGUAGE_HELP_TOOLTIP_MESSAGE:
        "El idioma del proyecto define el idioma en el que se enviarán las notificaciones por correo electrónico.",
    PROJECT_TIMEZONE_HELP_TOOLTIP_MESSAGE:
        "La zona horaria del proyecto se utilizará para programar las actividades que se configuran en la plataforma.",

    //Dashboard
    SECTION_INSPECTIONS_PROGRESS: "Evolución del progreso (últimas 10 semanas)",

    // deliverable cards
    DASHBOARD_DELIVERABLES_UNSCHEDULED:
        "Entregables sin fecha de inicio y de entregable",
    DASHBOARD_DELIVERABLES_BEHIND: "Entregables atrasadas",
    DASHBOARD_DELIVERABLES_WITH_CLAIMS:
        "Entregables con cuestiones de calidad abierta",
    DASHBOARD_DELIVERABLES_WITH_OBSTRU:
        "Entregables con obstrucciones abiertas",
    DASHBOARD_DELIVERABLES_WITH_INFO: "Entregables con notas informativas",

    DASHBOARD_ALL_DELIVERABLES: "Todas las Entregables",

    DASHBOARD_STATS_CHART_SELECTION_TITLE:
        "Gráfico de barras: Planificado vs. real",
    DASHBOARD_STATS_CHART_SELECT_TYPE:
        "Seleccione la plantilla del flujo de trabajo",
    DASHBOARD_S_CURVE_CHART_SELECTION_TITLE: "Curva S: Planificado vs. real",
    DASHBOARD_S_CURVE_CHART_CHART_SELECT_TYPE: "Seleccionar actividades",
    DASHBOARD_S_CURVE_CHART_CHART_DESELECT_TYPE:
        "Eliminar la selección de actividades",
    DASHBOARD_S_CURVE_CHART_SELECTION_LABEL: "Selección:",
    DASHBOARD_S_CURVE_CHART_SELECTION_EVERYTHING:
        "Todas las actividades que cumplen los filtros seleccionados",
    DASHBOARD_S_CURVE_CHART_SELECTION_NO_MATCH:
        "No hay resultados que coincidan con sus criterios de filtrado actuales",
    DASHBOARD_PRODUCTIVITY_CHART_SELECTION_TITLE:
        "Gráfico de productividad: Planificado vs. real por semana",
    DASHBOARD_CHARTS_LEGEND_PLANNED: "Planificado",
    DASHBOARD_CHARTS_LEGEND_FINISHED: "Trabajo terminado",
    DASHBOARD_CHARTS_LEGEND_CONFIRMED: "Confirmado",
    DASHBOARD_CHARTS_LEGEND_UNPLANNED: "No programado",
    DASHBOARD_CHARTS_LEGEND_COMPLETED:
        "Terminado: Confirmado + Terminado por el equipo de ejecución",

    FILTER_BY_STRUCTURE: "Filtrar por Estructura",
    FILTER_BY_ALL_STRUCTURES: "Todas",
    DASHBOARD_S_CURVE_CHART_EMPTY:
        "No hay resultados que coincidan con sus criterios de filtrado actuales. Si ha seleccionado Actividades específicas, puede eliminar esa selección a continuación.",
    DASHBOARD_BAR_CHART_EMPTY:
        "No hay resultados que coincidan con sus criterios de filtrado actuales.",

    DASHBOARD_FILTER_BY_TEAM: "Filtrar por Equipo",
    DASHBOARD_FILTER_BY_ALL_TEAMS: "Todos",

    DASHBOARD_FILTER_BY_PROCESS_TEMPLATE:
        "Filtrar por plantilla de flujo de trabajo",
    DASHBOARD_FILTER_BY_ALL_PROCESS_TEMPLATES: "Todos los flujos de trabajo",

    DASHBOARD_SELECT_ACTIVITIES_DIALOG_TITLE: "Filtrar por actividades",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SEARCHBAR_PLACEHOLDER:
        "Buscar actividades por plantilla de flujo de trabajo, nombre y equipo...",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_DESCRIPTION:
        "Seleccione la combinación de actividades que desea ver en la curva S:",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SELECT_BUTTON: "Mostrar combinación",

    // Dashboard no data
    SECTION_DASHBOARD_NO_DATA_TITLE: "Finalice la configuración de su proyecto",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TITLE: "Cree sus primeras entregas",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TEXT:
        "Puede importar Entregables desde Microsoft© Excel y Asta Powerproject. O utilice el botón de arriba para crearlos manualmente.",
    SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION: "Ir a importar",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TITLE:
        "Termine su configuración en Sablono",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TEXT:
        "Para poder empezar a utilizar este proyecto necesita crear Entregables y al menos una Plantilla de Flujo de trabajo. Después vaya a asignarla a sus Entregables para crear Actividades.",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION: "Ir a Entregables",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION_2:
        "Ir a esquema de flujos de trabajo",

    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TITLE:
        "Crear un esquema de flujos de trabajo",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TEXT:
        "Las plantillas de flujo de trabajo deben asignarse a las Entregables para marcar las actividades.",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_ACTION: "Ir al Editor de plantillas",

    INFO_STRUCTURE_PROGRESS:
        "{{allElements}} Entregables, {{finishedElements}}% terminadas.",
    ERROR_EMPTY_TIMELINE_TITLE: "Todavía no hay inspecciones",

    //Timeline
    INFO_SHOW_ALL_INSPECTIONS:
        "Mostrar todas las {{totalAmountOfInspections}} inspecciones",
    INSPECTION_DETAIL_TITLE: "Reportado por - {{author}}",
    INSPECTION_DETAIL_SUBTITLE: "Subido {{time}}}",
    INSPECTION_DETAIL_HEADER_PROGRESS: "{{number}} Entregables actualizadas",
    INSPECTION_DETAIL_HEADER_NEW_NOTES: "{{number}} notas creadas",
    INSPECTION_DETAIL_HEADER_RESOLVED_NOTES: "{{number}} incidencias resueltas",
    INSPECTION_DETAIL_PAGE_NO_FILTERED_DATA:
        "No hay actualizaciones que coincidan con sus criterios de filtro",
    INSPECTION_DETAIL_FILTER_AUTHOR: "Autor",
    INSPECTION_DETAIL_FILTER_TIMESTAMP: "Fecha",
    INSPECTION_DETAIL_NO_DATA_TITLE:
        "No hay actualizaciones disponibles todavía",
    INSPECTION_DETAIL_NO_DATA_TEXT:
        "Todavía no se ha cargado ninguna actualización de su proyecto. Una vez que se haya completado la configuración del proyecto, puede utilizar nuestras aplicaciones móviles o, por ejemplo, la página de entregas para cargar el progreso.",
    INSPECTION_DETAIL_NO_DATA_ACTION: "Ir a Entregables & Progreso",

    //PROFILE PAGE
    SECTION_USER_PROFILE_TITLE: "Perfil del usuario",
    ERROR_PASSWORD_CHANGE_LIFETIME_MESSAGE:
        "Cambio de contraseña actualmente no permitido: la duración mínima de la contraseña es de un día. Espera {{time}}",
    SECTION_YOUR_INFORMATION_TITLE: "Datos personales",
    INFO_GENDER_FEMALE_TITLE: "Sra.",
    INFO_GENDER_MALE_TITLE: "Sr.",
    ERROR_EMAIL_FORMAT_MESSAGE: "Debe estar en el formato correcto.",
    INPUT_SALUTATION_TITLE: "Saludo",
    INPUT_TITLE_TITLE: "Título",
    INPUT_FIRSTNAME_TITLE: "Nombre",
    INPUT_LASTNAME_TITLE: "Apellido",
    INPUT_COMPANY_TITLE: "Empresa",
    ACTION_SAVE: "Guardar",
    ACTION_PUBLISH: "Publicar",
    SECTION_CHANGE_PASSWORD_TITLE: "Cambiar contraseña",
    CHANGE_PASSWORD_ENTER_OLD_TITLE: "Confirmar",
    CHANGE_PASSWORD_ENTER_OLD_MESSAGE:
        "Por favor, introduzca la antigua contraseña para confirmar",
    ACTION_CHANGE: "Cambiar",
    SECTION_EMAIL_ADRESSES_TITLE: "Direcciones de correo electrónico",
    INFO_PRIMARY_TITLE: "Primario",
    INFO_PRIMARY_NOT_VERIFIED: "No verificado",
    INFO_PRIMARY_VERIFIED: "Verificado",
    INPUT_NEW_EMAIL_TITLE: "Añadir nuevo correo electrónico",
    INFO_SAVE_SUCCESS_TITLE: "Guardado exitosamente",
    INFO_PASSWORD_CHANGE_SUCCESS_TITLE: "Contraseña cambiada correctamente",
    ACTION_CHANGE_PICTURE: "Seleccione una imagen",

    ACTION_FILTER: "Filtrar",
    ACTION_CLEAR_FILTERS: "Eliminar filtros",

    //Add Role Dialog
    _ROLE_DESCRIPTION_ADMIN:
        "Un administrador de proyecto tiene acceso a todas las funciones de la plataforma, incluida la funcionalidad de configuración crítica.<br>Este rol debe ser utilizado únicamente por administradores del más alto nivel.",
    _ROLE_DESCRIPTION_MANAGER:
        "Un director de proyecto tiene acceso a casi todas las funciones de la plataforma, excepto a las funciones críticas de configuración<br>Este rol debe ser usado por usuarios que se supone deben configurar y actualizar el proyecto.",
    _ROLE_DESCRIPTION_INSPECTOR:
        "Un inspector de obra puede acceder a la aplicación móvil de Sablonos, a Visual Trackers y a la página de Lean Planning.<br>El rol debe ser usado para usuarios que se supone deben actualizar pero que realmente no trabajan con Sablono.",
    _ROLE_DESCRIPTION_CONTROLLER:
        "Un Controlador de Proyecto tiene acceso a todas las páginas que no sean de configuración en la aplicación web, así como a la aplicación móvil.<br>Este rol debería usarse para usuarios que se supone que trabajan con todas las funciones pero que no están involucrados en la configuración.",
    _ROLE_DESCRIPTION_REVIEWER:
        "Un revisor de proyectos tiene permisos de sólo lectura para este proyecto.<br>Este rol debería usarse para cualquiera que deba trabajar con los datos pero que no se actualizará a sí mismo.",
    ACTION_ADD: "Añadir",

    SECTION_TEAMS_ADD_USER_DIALOG_TITLE:
        "Invitar a un miembro nuevo  a <b>{{name}}</b>",
    SECTION_TEAMS_ADD_USER_DIALOG_TEXT:
        "Los miembros agregados a este equipo sólo podrán informar sobre las actividades asignadas a él o a 'todos en el proyecto'. Puede configurarlo durante la creación de esquemas de flujos de trabajo.",
    //  -We could have a link here to the editor - Thought ?
    SECTION_TEAMS_ADD_USER_DIALOG_OK: "Invitar a miembro",
    SECTION_TEAMS_ADD_USER_INPUT_INVALID:
        "Introduzca una dirección de correo electrónico válida",

    SECTION_TEAMS_EDIT_USER_DIALOG_TITLE: "Editar <b>{{name}}</b>",
    SECTION_TEAMS_EDIT_USER_DIALOG_OK: "Editar miembro",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_ROLE:
        "O cambie el rol del usuario en este proyecto.",
    SECTION_TEAMS_ADD_USERS_DIALOG_CHANGE_ROLE:
        "Por favor, seleccione un rol que defina los derechos de acceso dentro de este proyecto",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_TEAM:
        "Aquí puedes puedes mover este usuario a otro equipo.",
    SECTION_TEAMS_ADD_NEW_USER_BADGE: "Añadir un nuevo miembro",
    SECTION_TEAMS_ADD_NEW_COMMERCIAL_USER_BADGE: "Añadir un usuario comercial",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_TITLE:
        "Adaptar invitación para <b>{{name}}</b>",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_TEAM:
        "Aquí puedes puedes mover este usuario a otro equipo.",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_ROLE:
        "Por favor, seleccione un rol que defina los derechos de acceso dentro de este proyecto",
    ACTION_INVITATION_RESEND: "Reenviar la invitación",
    ACTION_INVITATION_UPDATE_AND_RESEND:
        "Personalizar y reenviar la invitación",
    INPUT_SEARCH_USER_MESSAGE: "Buscar un usuario....",
    INPUT_SELECT_ROLE_MESSAGE: "Seleccione una función...",
    //Project Team
    SECTION_INSPECT_APP_SETTINGS_TITLE: "Configuración de la aplicación",
    SECTION_INSPECT_APP_SETTINGS_GEOLOCATION:
        "Capturar la ubicación geográfica de las fotos",
    SECTION_INSPECT_APP_GEOLOCATION_PERMISSIONS:
        "Las fotos tomadas o subidas a Sablono incluirán la ubicación geográfica de la posición en la que fueron tomadas, si la información está disponible.",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_TITLE:
        "[Sólo la aplicación Inspect] Limitar las actividades visibles",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_ENABLED:
        "La aplicación Inspect sólo mostrará las actividades que pueden ser editadas por el Inspector de Sitio actual.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_TITLE:
        "[Sólo la aplicación Inspect] Diseño de la información",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_NAME:
        "Los Nombres de sus Entregables se presentan más prominentemente en la aplicación Inspect.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_CODE:
        "Los Códigos de sus Entregables se presentan más prominentemente en la aplicación Inspect.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_TITLE:
        "[Equipo de ejecución] Firmar lista de control de calidad con contraseña",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_DESCRIPTION:
        "Como medida de seguridad adicional, los usuarios del equipo de Ejecución deberán introducir su contraseña al cargar listas de control de calidad mediante la aplicación móvil. La aplicación siempre capturará el nombre de usuario y la hora de carga, incluso si esta opción está desactivada.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_TITLE:
        "[Equipo de inspección y confirmación] Firmar la lista de control de calidad con contraseña",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_DESCRIPTION:
        "Como medida de seguridad adicional, los usuarios del equipo de Confirmación deberán introducir su contraseña al cargar listas de control de calidad mediante la aplicación móvil. La aplicación siempre capturará el nombre de usuario y la hora de carga, incluso si esta opción está desactivada.",

    SECTION_QUALITY_PRIVACY_TITLE: "Visibilidad de la calidad",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_TITLE:
        "Visibilidad limitada de las notas",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_DESCRIPTION:
        "Los usuarios sólo verán las notas que su equipo haya comunicado, de las que su equipo sea responsable o que estén vinculadas a actividades de las que su equipo sea responsable.",

    SECTION_EMAIL_NOTIFICATIONS_TITLE: "Notificaciones por correo electrónico",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_TITLE:
        "Actualización de la actividad diaria",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_DESCRIPTION:
        "Los usuarios recibirán un correo electrónico diario cada tarde mostrando: Disponible, Confirmado, Rechazado y Actividades en las que los sucesores ya han comenzado para su equipo.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_TITLE:
        "Actualización de la actividad en tiempo real",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_DESCRIPTION:
        "Los usuarios recibirán un correo electrónico en tiempo real mostrando: Actividades disponibles, confirmadas y rechazadas para su equipo.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_TITLE:
        "Recordatorio de actualización semanal",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_DESCRIPTION:
        "Los usuarios recibirán un correo electrónico semanal los lunes por la mañana mostrando: Actividades en las que los sucesores ya han comenzado para su equipo.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_TITLE:
        "Calendario semanal de anticipación",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_DESCRIPTION:
        "Los usuarios recibirán un correo electrónico semanal los lunes por la mañana mostrando: Las actividades programadas dentro de los próximos 14 días y las actividades atrasadas para su equipo.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_TITLE:
        "Actualización de problemas de obstrucción en tiempo real",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_DESCRIPTION:
        "Los usuarios recibirán un correo electrónico en tiempo real que muestra los problemas de obstrucción creados recientemente de los que su equipo es responsable o debe confirmar.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_TITLE:
        "Actualización de problemas de calidad en tiempo real",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_DESCRIPTION:
        "Los usuarios recibirán un correo electrónico en tiempo real que muestra los problemas de calidad creados recientemente de los que su equipo es responsable o debe confirmar.",

    SECTION_NOTE_CONFIRMATION: "Ajustes de confirmación de emisión",
    OBSTRUCTION_CONFIRMATION_TITLE: "Problemas de obstrucción",
    OBSTRUCTION_CONFIRMATION_DESCRIPTION:
        "Si se selecciona, los usuarios que informan de una cuestión de obstrucción deben asignarles un equipo revisor. En caso contrario, es opcional",
    OBSTRUCTION_DEFAULT_TEAM:
        "Equipo revisor por defecto para las incidencias de obstrucción",
    QUALITY_ISSUE_CONFIRMATION_TITLE: "Problemas de calidad",
    QUALITY_ISSUE_CONFIRMATION_DESCRIPTION:
        "Si se selecciona, los usuarios que informan de un problema de calidad deben asignarle un equipo revisor. En caso contrario, es opcional",
    QUALITY_ISSUE_DEFAULT_TEAM:
        "Equipo revisor por defecto para los problemas de calidad",

    INFO_USER_ADD_SUCCESS_TITLE: "{{users}} agregados con éxito",
    INFO_USER_MOVED_SUCCESS_TITLE: "El usuario se ha movido con éxito",
    INFO_USER_REMOVE_SUCCESS_TITLE: "Usuario eliminado del proyecto",
    INFO_NO_MEMBER_1_TITLE: "No hay ninguna",
    INFO_NO_MEMBER_2_TITLE: "en tu proyecto.",
    CONFIRM_DELETE_YOURSELF_MESSAGE:
        "¿Estás seguro de que quieres borrarte? Esto no se puede deshacer.",
    ACTION_UNDO: "Deshacer",
    INFO_USER_NOT_FOUND: "Usuario {{user}} no encontrado",
    INFO_LOADING_ADD_MESSAGE: "Añadiendo usuario",
    INFO_LOADING_ADD_MESSAGE_PLURAL: "Añadiendo usuarios",
    INFO_LOADING_REMOVE_MESSAGE: "Borrando usuario",
    INFO_LOADING_REMOVE_MESSAGE_PLURAL: "Borrando usuarios",
    INFO_LOADING_MOVE_MESSAGE: "Moviendo usuario",
    INFO_LOADING_MOVE_MESSAGE_PLURAL: "Moviendo usuarios",
    INFO_LOADING_RESTORE_MESSAGE: "Restaurando usuario",
    INFO_LOADING_RESTORE_MESSAGE_PLURAL: "Restaurando usuarios",

    _SUN: "Dom",
    _MON: "Lun",
    _TUE: "Mar",
    _WED: "Miér",
    _THU: "Jue",
    _FRI: "Vie",
    _SAT: "Sáb",

    SECTION_PROJECT_SETTINGS_PAGE_TITLE: "Configuración del proyecto",
    SECTION_PROJECT_CALENDAR_TITLE: "Calendario del proyecto",
    SECTION_SCHEDULING_MODE_TITLE: "Modo de programación",
    SECTION_PROJECT_CURRENCY_TITLE: "Moneda del proyecto",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_HEADING: "Programación individual",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_OPTION:
        "Este modo calculará la programación de su proyecto mientras ignora las dependencias entre los diferentes entregables.",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_HEADING: "Programación conectada",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_OPTION:
        "Este modo calculará la programación de su proyecto incluyendo las dependencias entre los diferentes entregables. Los resultados pueden diferir mucho de las fechas de inicio y final definidas por el usuario para sus entregables.",
    SECTION_SCHEDULING_MODE_SELECTION_INFO_TOOLTIP:
        "No te preocupes! Cambiar entre programación individual y conectada nunca borrará ninguna fecha. Si no está satisfecho con los resultados del otro modo, puede retroceder en cualquier momento.",

    ERROR_NO_WORKDAYS_SELECTED_MESSAGE:
        "Debe seleccionar al menos un día laboral",
    ERROR_NO_WORKHOURS_SELECTED_MESSAGE:
        "Por favor, seleccione su horario de trabajo",
    ERROR_NO_TIMEZONE_SELECTED_MESSAGE: "Por favor seleccione una zona horaria",
    ERROR_INVALID_TIMEZONE_SELECTED_MESSAGE:
        "Seleccione una zona horaria válida",
    DELETE_PROJECT: "Borrar proyecto",
    SECTION_PROJECT_INFOS_TITLE: "Información del proyecto",
    SECTION_SELECT_WORKING_DAYS_TITLE: "Días laborales",
    SECTION_SELECT_WORKING_HOURS_TITLE: "Seleccione su horario de trabajo",
    INFO_CALENDAR_EDIT_SUCCESS_TITLE: "Calendario actualizado con éxito",
    INFO_CALENDAR_EDIT_LOADING_TITLE: "El calendario se está actualizando...",
    CONFIRM_DELETE_PROJECT_MESSAGE: "¿Realmente quieres borrar este proyecto?",
    INFO_PROJECT_EDIT_SUCCESS_TITLE: "Cambios exitosos",
    INFO_PROJECT_EDIT_LOADING_TITLE: "El proyecto se está actualizando...",
    INPUT_START_DESCRIPTION: "Inicio",
    INPUT_END_DESCRIPTION: "Final",
    SECTION_SELECT_FIRST_PERIOD_TITLE: "Primer turno",
    SECTION_SELECT_SECOND_PERIOD_TITLE: "Segundo turno",
    SECTION_EXCEPTION_DAYS_TITLE: "Días no laborales",
    SECTION_EXCEPTION_DAYS_INFO:
        "Por favor introduzcs todos los días que su equipo de proyecto no trabajará, e.g. días festivos.",
    INPUT_DAY_LABEL_DESCRIPTION: "Ocasión",
    ERROR_DUPLICATED_DATES:
        "Parece que ha seleccionado el mismo día dos veces.",
    ACTION_ADD_MORE_EXCEPTION_DAY: "Añadir más días",

    //Edit Project
    INFO_PROJECT_REMOVE_SUCCESS_TITLE: "Proyecto eliminado con éxito",
    ACTION_DELETE_PROJECT_TITLE: "Borrar proyecto",
    ACTION_DELETE_PROJECT_CONFIRM_TITLE: "¿Borrar finalmente?",
    SECTION_EDIT_TITLE: "Editar",

    // edit Component
    INFO_COMPONENT_DATE_CHANGE_SUCCESS_TITLE: "{{name}} ha cambiado con éxito",

    //////
    // Start of template list page
    //

    // subbar actions
    ACTION_CLONE_TEMPLATE: "Copiar una plantilla existente",
    ACTION_CREATE_TEMPLATE: "Crear nueva plantilla",

    // group by menu
    ACTION_UNGROUPED: "No agrupado",
    ACTION_CREATOR: "Creador",
    ACTION_CATEGORY: "Categoría",
    ACTION_CODE: "Código",
    ACTION_REVISION: "Versión de plantilla",
    ACTION_ACTIVITIES: "Número de actividades",
    //
    // End of template list page

    // TEMPLATE
    TEMPLATE_GROUP_INPUT_HOURS_OF_WORK_TITLE: "Horas de trabajo",
    TEMPLATE_GROUP_TOOLTIP_DURATION_TITLE: "Duración:",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_BEFORE:
        "La duración representa la trayectoria crítica de su flujo de trabajo definido. Aquí,",
    TEMPLATE_GROUP_DURATION_TOOLTIP_LINK_TEXT: "horas y días laborales",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_AFTER:
        "las horas y los días están incluidos.",

    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TITLE: "Horas de trabajo:",
    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TEXT:
        "Este valor se calcula como la suma de la duración definida para cada actividad contenida en este grupo.",

    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_TEXT:
        "Los días y las horas de trabajo se basan en el calendario del proyecto. Días y horas son independientes.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_DISABLED_TEXT:
        "Defina sus duraciones planificadas como parte de sus secuencias de trabajo estándar en Sablono y aproveche al máximo sus capacidades de programación de línea de base y anticipación. Póngase en contacto con Sablono hoy mismo para obtener acceso a esta función.",
    CONFIRM_TEAM_DISABLED_TOOLTIP_TEXT:
        "Asigne equipos de confirmación a sus actividades para poder aprobar o incluso rechazar trabajos y listas de comprobación de control de calidad directamente desde el sitio, ¡totalmente documentado como parte de la pista de auditoría de su proyecto! Póngase en contacto con Sablono hoy mismo para obtener acceso a esta función.",
    TEMPLATE_ACTIVITY_EMPTY_CHECKLIST_TOOLTIP_TEXT:
        "Las listas de control de calidad que aún no incluyen elementos aparecerán desactivadas en la lista. Por favor, añada elementos a su plantilla de lista de control de calidad para poder adjuntarlos aquí.",
    TEMPLATE_ACTIVITY_CHECKLIST_DISABLED_TOOLTIP_TEXT:
        "Asigne listas de comprobación de control de calidad a sus actividades y asegúrese de que la información de calidad se captura automáticamente mientras se informa del progreso desde el sitio. Póngase en contacto con Sablono hoy mismo para obtener acceso a esta función.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_LINK_TEXT:
        "Días laborales: {{days}}<br>Horas de trabajo: {{times}}",
    SECTION_CREATE_TEMPLATE_TITLE: "Crear una nueva plantilla",
    INPUT_TEMPLATE_NAME_TITLE: "Nombre",
    INPUT_TEMPLATE_CODE_TITLE: "Código",
    INPUT_UNIT_TITLE: "Unidad",
    INPUT_ASSIGNED_TEAM_TITLE: "Equipo de ejecución",
    INPUT_REVIEW_TEAMS_TITLE: "Equipo de inspección",
    INPUT_CONFIRM_TEAM_TITLE: "Equipo de confirmación",
    _NOTES_CONFIRMATION_TEAM: "Equipo revisor",
    INPUT_OPTION_NO_CONFIRMATION_REQUIRED:
        "No se necesita confirmación por separado",
    INPUT_ASSIGNED_CHECKLIST_TITLE: "Lista de control adjuntada",
    INPUT_ASSIGNED_CHECKLIST_NONE: "No se ha adjuntado una lista de QA",
    INPUT_SELECT_ASSIGNED_TEAM_PLACEHOLDER: "Elige un equipo",
    INPUT_SELECT_MIXED_TEAM_ASSIGNMENT_OPTION: "Varios equipos asignados",
    INPUT_SELECT_ADD_NEW_TEAM_OPTION: "Crear un nuevo equipo",
    INPUT_TEMPLATE_DESCRIPTION_TITLE: "Descripción",
    INPUT_TEMPLATE_CATEGORY_TITLE: "Categoría",
    INPUT_TEMPLATE_ACTIVITY_NAME_TITLE: "Nombre de la actividad",
    INPUT_TEMPLATE_STATE_NAME_TITLE: "Nombre del Estado",
    INFO_TEMPLATE_ADD_SUCCESS_TITLE: "El modelo se ha creado con éxito.",
    INPUT_SELECT_PREDECESSOR_TITLE: "Seleccionar predecesor adicional",
    INPUT_SELECT_SUCCESSOR_TITLE: "Seleccionar sucesor adicional",
    INFO_TEMPLATE_EDIT_SUCCESS_TITLE:
        "La plantilla se ha editado correctamente",
    INFO_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} plantillas borrada. {{errors}} errores",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATES_MESSAGE: "Buscar plantillas",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATE_CONTENT_MESSAGE:
        "Buscar en la plantilla",
    ACTION_GROUP_BY: "Agrupar por",
    ACTION_GROUP_BY_TYPE: "Agrupar por tipo",
    ACTION_GROUP_BY_CREATOR: "Agrupar por creador",
    ACTION_GROUP_BY_CREATION_DATE: "Agrupar por fecha",
    ACTION_KEY: "Código",
    ACTION_NAME: "Nombre",
    ACTION_DESCRIPTION: "Descripción",
    ACTION_TEXT: "Texto",
    ACTION_TYPE: "Tipo",
    ACTION_SAVE_TEMPLATE: "Guardar plantilla de flujo de trabajo",
    ACTION_DATE: "Fecha",
    INFO_NO_TEMPLATES_YET_TITLE:
        "Todavía no se han creado plantillas de flujos de trabajo para este proyecto.",
    INFO_NO_TEMPLATES_YET_TEXT:
        "Las plantillas de flujo de trabajo se pueden utilizar para capturar información muy detallada sobre las secuencias de construcción. Ellas contienen actividades que pueden estructurarse en grupos y enlazarse a través relaciones. <br /><img src=' images/attach-template_en_slow. gif' /><img src=' images/attach-template_en_slow. gif' /><img /> Después de crear una plantilla, simplemente las asignas a las Entregables de su proyecto y marcar las actividades.",
    INFO_NO_TEMPLATES_YET_BUTTON_LEFT: "Crear nuevo",
    INFO_NO_TEMPLATES_SEARCH_1_MESSAGE: "Tú buscas ",
    INFO_NO_TEMPLATES_SEARCH_2_MESSAGE:
        "no coincidió con ninguna de las plantillas existentes.",
    INFO_NO_ACTIVITIES_IN_GROUP:
        "Este grupo no contiene ninguna actividad para el seguimiento del progreso.",
    INFO_NO_WORKFLOW_ASSIGNED_YET_TITLE:
        "Asigne una plantilla de flujo de trabajo",
    INFO_NO_WORKFLOW_ASSIGNED_YET_MESSAGE:
        "Para poder hacer un seguimiento del estado de las actividades, es necesario asignar una plantilla de flujo de trabajo a este producto final.",
    INFO_EMPTY_WORKFLOW_ASSIGNED_TITLE: "No hay Actividades para mostrar",
    INFO_EMPTY_WORKFLOW_ASSIGNED_MESSAGE:
        "O bien su Plantilla de Flujo de Trabajo no contiene todavía ninguna Actividad, o bien estaban todas ocultas. Utilice el Editor de Plantillas para comprobarlo.",
    SECTION_NO_GROUP_ELEMENT_TITLE: "No",
    ACTION_ADD_TEMPLATE_DESCRIPTION: "Añadir plantilla",
    SECTION_ALL_THE_TEMPLATES_1_TITLE: "Todos los",
    SECTION_ALL_THE_TEMPLATES_2_TITLE: "plantillas",
    CONFIRM_DELETE_TEMPLATES_MESSAGE:
        "¿Está seguro de que desea eliminar {{value}} plantillas?",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_TITLE: "No está permitido",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_MESSAGE:
        "Los plantillas de flujos de trabajo no pueden borrarse si están asignados a Entregables. La(s) plantilla(s) de flujo de trabajo que está intentando eliminar siguen siendo utilizadas por {{value}} Entrega{{(value ==1) ? '' : 's'}}. \nPor favor, asigne una nueva plantilla de flujo de trabajo a esos Entregables para poder continuar.",
    SECTION_GROUPS: "Grupos",
    SECTION_ACTIVITIES: "Actividades",
    INFO_ACTIVITY_TITLE: "Actividad",
    INFO_TEMPLATE_NO_CHILDREN:
        "Esta plantilla aún no tiene ningún grupo o actividad",
    ACTION_TEMPLATE_ADD_CONTENT: "Abrir en Editor",
    ACTION_TEMPLATE_DELETE_NOTE: "Eliminar nota",
    CONFIRM_DELETE_NOTE_MESSAGE:
        "¿Está seguro de que quiere eliminar esta nota?",
    ACTION_ADD_GROUP: "Añadir un nuevo grupo a {{name}}.",
    ACTION_ADD_ACTIVITY: "Añadir una nueva actividad a {{name}}.",
    ACTION_DELETE_GROUP: "Borrar grupo",
    ACTION_DELETE_ACTIVITY: "Borrar actividad",
    ACTION_TRANSFORM_ACTIVITY: "Transformar actividad a grupo",
    ACTION_DISCARD_CHANGES: "Descartar cambios",
    SECTION_TEMPLATE_EDITOR: "Editor de plantillas",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TITLE: "¿Sobrescribir equipos asignados?",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TEXT:
        "Si asigna un equipo de ejecución a este grupo, sobrescribirá las asignaciones ya realizadas para actividades contenidas. ¿Estás seguro de que quieres proceder?",
    CONFIRM_DELETE_GROUP_MESSAGE:
        "¿Está seguro de que desea eliminar el grupo {{name}}? Todo el contenido del grupo también será borrado.",
    CONFIRM_DELETE_ACTIVITY_MESSAGE:
        "¿Está seguro de que desea borrar la actividad {{name}}? Por favor, tenga en cuenta que después de reasignar la Plantilla de Flujo de trabajo, el progreso, las notas y las imágenes relacionadas con esta actividad en todos sus entregables ya no estarán disponibles. Esta acción no puede ser revertida.",
    INFO_TEMPLATE_NODE_REMOVE_SUCCESS_TITLE: "borrado con éxito.",
    CONFIRM_DISCARD_CHANGES_MESSAGE:
        "¿Está seguro de que desea descartar todos sus cambios y descargar la plantilla del servidor de nuevo?",
    INFO_TRANSFORM_ACTIVITY:
        "Los grupos y las actividades no se pueden asignar a actividades. Para ello hay que transformar la actividad a un grupo.",
    DIALOG_TRANSFORM_ACTIVITY_OPTION:
        "Por favor, seleccione uno de los siguientes enfoques",
    SELECTION_TRANSFORM_ACTIVITY_KEEP:
        "Cree un nuevo grupo y añada esta actividad a ese grupo.",
    SELECTION_TRANSFORM_ACTIVITY_DISCARD:
        "Cree un nuevo grupo y borre esta operación.",
    INFO_CREATE_NEW_GROUP: "Crear un nuevo grupo...",
    INFO_TEMPLATE_SAVED_SUCCESSFULL: "Plantilla guardada con éxito",
    INFO_CANNOT_DRAG_INTO_ACTIVTY_MESSAGE:
        "No se puede desplazar una actividad o grupo a otra actividad",
    INFO_CANNOT_DRAG_INTO_CHILD_MESSAGE:
        "No puede mover este grupo a uno de sus subgrupos",
    SECTION_PREDECESSOR_TITLE: "Depende de",
    SECTION_SUCCESSOR_TITLE: "Seguido por",
    SECTION_CURRENT_TITLE: "Actividad actual",
    ACTION_EXPAND_ALL: "Expandir todo",
    ACTION_COLLAPSE_ALL: "Colapsar todo",
    INFO_CIRCLE_PREDECESSOR_MESSAGE:
        "Esta operación ya se ha definido como sucesor en el workflow. Añadirlo como un predecesor conduciría a un ciclo de flujo de trabajo.",
    INFO_CIRCLE_SUCCESSOR_MESSAGE:
        "Esta operación ya se ha definido como predecesor en el workflow. Añadirlo como sucesor conduciría a un ciclo de flujo de trabajo.",
    INFO_SAVING_MESSAGE: "Grabando modificaciones...",
    INFO_DISCARDING_MESSAGE: "Descartando...",
    LABEL_EDIT_ACTIVITY: "Editar actividad",
    INFO_LAST_CHANGES_TIME: "Sus cambios se guardaron en este dispositivo",
    INFO_LAST_CHANGES_TIME_PUBLISH: "Publicar ahora",
    SELECT_EXIT_EDITOR_TITLE: "Salir de editor de plantillas",
    SELECT_EXIT_EDITOR_MESSAGE:
        "Sus cambios no se han publicado todavía. ¿Cómo quiere proceder?",
    SELECT_EXIT_EDITOR_DISMISS: "Permanecer en esta página",
    SECTION_CHANGE_PARENT_DIALOG_TITLE: "Seleccionar otro grupo",
    ACTION_MOVE_TO_ANOTHER_PARENT: "Mover a otro grupo",
    ACTION_PUBLISH_AND_UPDATE_DELIVERABLES: "Guardar y asignar",
    INFO_UPDATING_DELIVERABLES_MESSAGE: "Actualizando Entregables...",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL: "Entregables actualizadas con éxito",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL_N:
        "Entregables actualizadas con éxito",
    INFO_PUBLISHING_MESSAGE: "Publicando cambios...",
    INFO_TEMPLATE_PUBLISHED_SUCCESSFUL: "Plantilla publicada con éxito",
    TEMPLATE_EDITOR_NO_DATA_TITLE:
        "No hay {{(type=='GROUP')?'grupo añadido':'actividad añadida'}} todavía",
    TEMPLATE_EDITOR_NO_DATA_GROUP_TEXT:
        "Un grupo consta de varias actividades y se utiliza para estructurar el modelo. Añada un grupo existente o cree uno nuevo utilizando el campo anterior.",
    TEMPLATE_EDITOR_NO_DATA_ACTIVITY_TEXT:
        "Las actividades consisten en una actividad y un estado deseado (p. ej., instalar - instalado). Añada una actividad existente o cree una nueva utilizando el campo anterior.",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TITLE:
        "Ningún predecesor seleccionado todavía",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TEXT:
        "Los predecesores deben estar terminados antes de que se pueda iniciar la actividad actual. Seleccione una actividad de este esquema de flujos de trabajo utilizando el campo anterior.",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TITLE: "Ningún sucesor seleccionado todavía",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TEXT:
        "Los sucesores sólo pueden iniciarse una vez finalizada la actividad actual. Seleccione una actividad de este esquema de flujos de trabajo utilizando el campo anterior.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_PREDECESSOR:
        "Cada actividad creada en esta plantilla daría lugar a un ciclo de flujo de trabajo si se vuelve a utilizar. Cree más actividades antes de definir otro predecesor.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_SUCCESSOR:
        "Cada actividad creada en esta plantilla daría lugar a un ciclo de flujo de trabajo si se vuelve a utilizar. Cree más actividades antes de definir otro sucesor.",
    EDIT_TEMPLATE_ACTIVITY_DESCRIPTION_PLACEHOLDER:
        "Introduzca notas, descripciones o traducciones adicionales que usted o sus colaboradores deseen ver.",
    INFO_PROGRESS_CHANGE_DISABLED_TOOLTIP:
        "No se le permite cambiar el estado de esta actividad porque no es responsable de ella.",

    ACTION_EXPORT_GRAPHML_FILE: "Exportar el flujo de trabajo como .graphml",
    // TEMPLATE EDITOR
    _LABOUR: "Mano de obra",
    _LABOUR_UNIT: "Trabajadores",
    LABOUR_IN_UNIT: "{{ amount }} Trabajadores",

    // NOTE TEMPLATE PAGE
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TITLE: "Cree su primera plantilla de nota",
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TEXT:
        "Las plantillas de notas ayudan a ahorrar tiempo en el sitio, ya que permiten crear textos estándar que luego pueden utilizarse para crear notas de manera más eficiente.",
    INFO_NO_DATA_DUE_TO_FILTER_CONDITIONS:
        "Su filtro actual y criterios de búsqueda no coinciden con ninguna plantilla de nota.",
    ACTION_NOTE_TEMPLATE_NO_DATA_BUTTON: "Crea tu primera plantilla",
    NOTE_TEMPLATE_TITLE: "Tipo de nota",
    CONFIG_CARD_TITLE: "Filtros",
    INFO_PLACEHOLDER_SEARCH_BAR_NOTE_TEMPLATES_MESSAGE:
        "Buscar una plantilla de notas",
    INFO_NOTE_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} Notas de plantillas eliminadas. {{errors}} errors.",
    CONFIRM_DELETE_NOTE_TEMPLATE_MESSAGE:
        "¿Está seguro de que desea borrar {{value}} plantillas?",
    INFO_NOTE_TEMPLATE_LOADING_REMOVE_MESSAGE: "Borrando plantillas de notas",
    SECTION_CREATE_NOTE_TEMPLATE_TITLE: "Crear una nueva plantilla de notas",
    INPUT_CODE_TITLE: "Código",
    INPUT_NOTE_TEMPLATE_DESCRIPTION_TITLE: "Texto de plantilla de nota",
    INPUT_SELECT_TYPE: "Seleccionar tipo de nota",

    //PROJECT SELECTION
    INFO_PLACEHOLDER_SEARCH_BAR_PROJECT_SELECTION_MESSAGE: "Buscar un proyecto",

    // sb-subbar
    INFO_ELEMENTS_SELECTED: "seleccionado",

    DIALOG_CHANGE_DATES_TITLE: "Editar fechas",
    DIALOG_DATE_CHANGE_VALUE_NOT_DATE: "El valor introducido no es una fecha!",
    DIALOG_CHANGE_DATE_WARNING_MESSAGE:
        "Las Entregables seleccionados incluyen diferentes fechas de inicio y entregable. Aquí se muestran el inicio más temprano y la última fecha de entregable. Tenga en cuenta que moverá todos las Entregables seleccionadas si decide guardar.",
    DIALOG_CHANGE_DATE_ALL_SELECTED_WARNING_MESSAGE:
        "¡Atención! Todas las Entregables están actualmente seleccionadas. Si opta por modificar las fechas ahora, esto afectará a todas las Entregables y sobrescribirá las fechas de inicio y entregable existentes.",
    // MAINTENANCE CARD
    INFO_MAINTENANCE_CARD_TITLE_PLANNED: "Mantenimiento programado",
    INFO_MAINTENANCE_CARD_SUB_TITLE_PLANNED:
        "El sistema no está disponible actualmente debido a las actualizaciones programadas y al trabajo de mantenimiento.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_PLANNED:
        "Las ventanas de mantenimiento previstas son del viernes a las 22 horas (CET) al sábado a las 2 horas (CET), del sábado a las 22 horas (CET) al domingo a las 2 horas (CET) y del domingo a las 22 horas (CET) al lunes a las 2 horas (CET).",
    INFO_MAINTENANCE_CARD_TEXT_PLANNED:
        "Póngase en contacto con nuestro servicio de asistencia a través de <a href='mailto:support@sablono.com'>support@sablono.com</a> en caso de que tenga preguntas o necesite ayuda.",

    INFO_MAINTENANCE_CARD_TEXT_UNKNOWN:
        "Si el problema persiste, por favor contáctenos en <a href='mailto:support@sablono.com'>support@sablono.com</a>.",
    INFO_MAINTENANCE_CARD_TITLE_UNKNOWN: "Tiempo de espera de la red",
    INFO_MAINTENANCE_CARD_SUB_TITLE_UNKNOWN:
        "Parece que no hay conexión, o es lenta, con el servidor de Sablono en este momento...",
    INFO_NOTE_TYPE_EDIT_TEMPORARILY_UNAVAILABLE:
        "El cambio del tipo de Notas ya no funciona. Estamos trabajando para recuperar esta funcionalidad lo antes posible.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_UNKNOWN:
        "Por favor, compruebe si su conexión a Internet es estable e intente actualizar la página.",
    INFO_MAINTENANCE_CARD_BUTTON_UNKNOWN: "Centro de Soporte",

    // AUTO SIGN UP
    SIGNUP_TITLE_FREEMIUM: "Cree su cuenta Sablono Track gratuita",
    SIGNUP_TITLE_FULL: "Cree su cuenta Sablono",
    SIGNUP_SUCCESS_TITLE:
        "Verifique su correo electrónico usando su bandeja de entrada dentro de las 24 horas...",
    SIGNUP_SUCCESS_HUBSPOT_TITLE:
        "¿Quieres aprovechar al máximo tu experiencia Sablono? ¡Conozca a uno de nuestros expertos en productos!",
    SIGNUP_SUCCESS_HUBSPOT_TEXT:
        "Estaremos más que felices de ayudarlo a lograr sus objetivos con nuestra plataforma. Nuestro equipo dedicado está aquí para ayudar.",
    SIGNUP_SUCCESS_TEXT_WAIT_BUTTON: "haga clic aquí.",
    SIGNUP_SUCCESS_TEXT_MAIL_SENT:
        "El correo electrónico se envió por segunda vez. Si no recibes un correo electrónico en los próximos minutos, ponte en contacto con nuestro soporte a través de <a href=' mailto:support@sablono.com' >support@sablono.com</a> o <a href=' tel: 004903030609807440' >+49 030 609807440</a>. Con mucho gusto le ayudaremos!",

    SIGNUP_GENERIC_ERROR_TITLE: "Parece que hay un problema",
    SIGNUP_GENERIC_ERROR_TEXT:
        "Actualmente tenemos un problema inesperado con el proceso de registro. Póngase en contacto con nuestro soporte a través de <a href=' mailto:support@sablono.com' >support@sablono.com</a> o <a href=' tel: 004903030609807440' >+49 030 609807440</a>. Con mucho gusto le ayudaremos!",

    SIGNUP_FIRST_ERROR_TITLE: "Ups…",
    SIGNUP_FIRST_ERROR_TEXT:
        "Lo sentimos, pero algo salió mal al transferir sus datos a nuestro sistema. Por favor, inténtalo de nuevo.",
    SIGNUP_FIRST_ERROR_BUTTON: "Volver a la inscripción",

    SIGNUP_ERROR_EMAIL_ALREADY_USED_TITLE: "Ya se ha tomado",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_TEXT:
        "Su dirección de correo electrónico ya se utilizó para crear un usuario.",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_BUTTON:
        "Utilizar un correo electrónico diferente",

    SIGNUP_INFO_VERIFY_TITLE: "Elija una contraseña",
    SIGNUP_INFO_VERIFY_TEXT:
        "Por favor, elija una contraseña segura antes de comenzar a trabajar con Sablono.",
    SIGNUP_SUCCESS_VERIFY_TITLE: "Inscripción concluida con éxito",
    SIGNUP_SUCCESS_VERIFY_TEXT:
        "¡Enhorabuena! Asegúrate de consultar nuestra guía paso a paso de Sablono que puedes encontrar en <a target='_blank' href='http://support.sablono.com/tutoriales-en-espanol'>support.sablono.com</a> siempre que tengas preguntas sobre la plataforma.",
    SIGNUP_SUCCESS_VERIFY_BUTTON: "Ir al centro de soporte",
    ACTION_SIGNUP_VERIFY_TEXT: "Activar",

    SIGNUP_ERROR_VERIFY_FAILED_TITLE: "Ups...",
    SIGNUP_ERROR_VERIFY_FAILED_TEXT:
        "Por alguna razón la activación falló. Por favor, intente utilizar el enlace proporcionado de nuevo.",

    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TITLE: "Ups...",
    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TEXT:
        "El enlace que está usando parece inválido. Esto podría deberse a varias razones.",

    SIGNUP_EULA:
        "He leído y acepto el <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>Sablono End-User-License-Agreement</a>.*",
    SIGNUP_DATA_PRIVACY:
        "Estoy de acuerdo en que Sablono también puede transferir los datos que he introducido a los llamados terceros países (Art. 49 para. 1 lit. a) RGPD). Soy consciente de que en algunos terceros países (por ejemplo, EE.UU.) no puede excluirse el acceso por parte de las autoridades de control y/o supervisión. Posiblemente, no pueda hacer valer los derechos del interesado ni recurrir a los tribunales. La transmisión se realiza con el fin de cumplir el contrato, así como para contactos promocionales por parte de Sablono. Mi consentimiento es libremente revocable.*<br><br>Por favor, consulte la <a href='https://www.sablono.com/privacy' target='_blank'>Data and Privacy Policy</a> y tenga en cuenta en particular nuestra información sobre la transmisión a terceros países.",
    SIGNUP_MARKETING_CONSENT:
        "Deseo recibir en el futuro información sobre los productos Sablono por correo electrónico y por teléfono. Mi consentimiento es libremente revocable.*",

    //Visualization page
    SECTION_VISUALIZATION_PAGE_TITLE: "Visualización de Progreso",
    DIALOG_NO_MATCH_SVG_TITLE: "No se ha detectado ningún progreso coincidente",
    DIALOG_NO_MATCH_SVG_CONTENT:
        "No hemos podido detectar ningún progreso coincidente entre su archivo y el proyecto Sablono. Por favor, compruebe si los códigos de Entregables de su expediente coinciden con los códigos de la plataforma y si se les han asignado Plantillas de Flujo de trabajo.",
    INFO_PLACEHOLDER_SEARCH_BAR_VISUALIZATION: "Buscar una visualización..",

    DIALOG_BAD_PRINT_WARNING_TITLE: "Navegador no soportado",
    DIALOG_BAD_PRINT_WARNING_CONTENT:
        "Su navegador no soporta la impresión de la visualización a PDF. Por favor, descargue y utilice Google Chrome para hacerlo.",

    INFO_UPLOAD_VISUALIZATION_TEXT:
        "Cargue una imagen de su proyecto que contenga información sobre sus Entregables (archivo (SVG)",
    INFO_UPLOAD_NEW_VISUALIZATION_MESSAGE:
        "Cargue un dibujo que contenga los códigos utilizados en Sablono para todos las Entregables mostradas (archivo SVG únicamente).",
    INFO_GENERATE_NEW_VISUALIZATION_MESSAGE:
        "Cree una nueva visualización esquemática de su proyecto.",
    ACTION_UPLOAD_NEW_VISUALIZATION: "Cargar nuevo dibujo SVG",
    ACTION_GENERATE_NEW_VISUALIZATION: "Crear visualización",

    SECTION_VISUALIZATION_SHOW_PROGRESS: "Comparación con el Cronograma",
    SECTION_VISUALIZATION_SHOW_STAGE: "Mostrar estado",
    SECTION_VISUALIZATION_SHOW_CURRENT_ACTIVITY: "Mostrar equipos",
    SECTION_VISUALIZATION_SHOW_DONE_ACTIVITY: "Navegar Estados",
    SECTION_VISUALIZATION_PRINT_TOOLTIP:
        "Imprimir visualización o guardarla en formato PDF utilizando una impresora PDF",
    SECTION_VISUALIZATION_PROGRESS: "Comparación de línea de base",
    SECTION_VISUALIZATION_STAGE: "Estado",
    SECTION_VISUALIZATION_CURRENT_ACTIVITY: "Equipos",
    "0_INFO_LEGEND_NO_STAGE": "No se ha terminado la actividad",
    "0_ACTIVITIES_PLANNED_LEGEND": "No se han planificado actividades",

    ERROR_SVG_DOES_NOT_EXIST:
        "La visualización solicitada no se encontró en nuestro servidor. Puede elegir una de la lista.",
    ERROR_FILE_IS_NOT_SVG:
        "El archivo que subió no parece estar en formato SVG. Por favor, elija otro archivo.",
    CONFIRM_SVG_DELETION_TITLE: "Confirmar elimanació de la visualización",
    CONFIRM_SVG_DELETION_MESSAGE:
        "¿Está seguro de que desea borrar esta visualización?",
    INFO_SVG_DELETION_SUCCESSFUL: "Visualización borrada correctamente",

    // Visualization configuration section
    INFO_VISUALIZATION_CONFIG_NO_TEMPLATE_EMPTY_STATE_MESSAGE:
        "Seems like you did not create a Process Template in this project, yet.<br> Visualizations can only be created once a Process Template was set up.",

    SELECT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_MESSAGE:
        "¿Qué tipo de Entregables deben mostrarse en la visualización?",
    SELECT_VISUALIZATION_CONFIG_STRUCTURE_NODES_MESSAGE:
        "¿Qué partes de la estructura del proyecto se deben mostrar en la visualización?",
    SELECT_VISUALIZATION_CONFIG_SVG_TITLE_MESSAGE:
        "Por favor, defina ahora los títulos mostrados en su visualización.",
    SELECT_VISUALIZATION_CONFIG_LABEL_ROW_PROPERTY_MESSAGE:
        "¿Qué información se debe utilizar para etiquetar los niveles en la visualización?",
    SELECT_VISUALIZATION_CONFIG_LABEL_CELL_PROPERTY_MESSAGE:
        "¿Qué información se debe utilizar para etiquetar las Entregables mostradas en la visualización?",
    SELECT_VISUALIZATION_CONFIG_LABEL_PROPERTY_SUB_MESSAGE:
        "¿Qué caracteres de esa información se deben mostrar?",
    SELECT_VISUALIZATION_CONFIG_SVG_NAME_MESSAGE:
        "Por favor nombra la visualización para poder diferenciarla de otras.",

    SECTION_VISU_CONFIG_DELIVERABLE_TYPE_STEPPER:
        "Seleccione la plantilla del flujo de trabajo",
    SECTION_VISU_CONFIG_STRUCTURE_NODES_STEPPER:
        "Seleccione la estructura del proyecto",
    SECTION_VISU_CONFIG_SVG_TITLE_STEPPER: "Definir títulos",
    SECTION_VISU_CONFIG_LABEL_PROPERTY_STEPPER: "Definir etiquetas",
    SECTION_VISU_CONFIG_SVG_NAME_STEPPER: "Visualización de nombres",

    INPUT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_DESCRIPTION:
        "Seleccione una plantilla de flujo de trabajo...",
    INPUT_VISUALIZATION_CONFIG_STRUCTURE_NODES_DESCRIPTION:
        "Seleccione todas las estructuras relevantes de del proyecto...",
    INPUT_VISUALIZATION_CONFIG_HEADER_RIGHT_DESCRIPTION: "Título:",
    INPUT_VISUALIZATION_CONFIG_HEADER_LEFT_DESCRIPTION:
        "Estructura del proyecto:",
    INPUT_VISUALIZATION_CONFIG_LABEL_PROPERTY_DESCRIPTION:
        "Información visualizada:",
    INPUT_VISUALIZATION_CONFIG_FIRST_CHAR_DESCRIPTION:
        "Posición del primer carácter",
    INPUT_VISUALIZATION_CONFIG_SECOND_CHAR_DESCRIPTION:
        "Posición del último carácter",
    INFO_VISUALIZATION_CONFIG_LABEL_PROPERTY_LIST: "Ejemplos:",
    INPUT_VISUALIZATION_CONFIG_SVG_NAME_DESCRIPTION: "Defina un nombre...",

    // Visualization generated SVG default headings
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_LEFT_TITLE: "Niveles",
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_RIGHT_TITLE: "Título",

    // Visualization bulk update
    START_BULK_UPDATE: "Iniciar actualización del progreso",
    UPDATE_PROGRESS: "Actualizar progreso de la selección",
    BULK_UPDATE_MODE_TITLE:
        "Seleccionar entregables en grupo para actualizar una actividad.",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_TITLE:
        "Selección de múltiples plantillas de flujos de trabajo...",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "La actualización por lotes sólo funciona para una selección de Entregables de la Plantilla de Flujos de trabajo. Por favor, sólo seleccione Entregables utilizando la misma Plantilla.",

    // Visualization legend keys
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_APPLICABLE: "Actividad no aplicable",
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_STARTED: "No iniciado",
    VISUALIZATION_LEGEND_ENTRY_STAGE_AVAILABLE:
        "Disponible - Todos los predecesores terminados",
    VISUALIZATION_LEGEND_ENTRY_STAGE_STARTED: "Iniciado",
    VISUALIZATION_LEGEND_ENTRY_STAGE_COMPLETED: "Terminado y Confirmado",

    // Process Viewer
    SECTION_PROCESS_VIEWER_TITLE: "Actividades",
    SECTION_PROCESS_VIEWER_HIDE_ACTIVITIES_SWITCH_TEXT:
        "Ocultar actividades terminadas",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_1:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_2:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION",
    SECTION_PROCESS_VIEWER_PAGE_NO_FILTERED_DATA:
        "@:SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_TITLE: "No existen Entregables",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_MESSAGE:
        "Este proyecto todavía no contiene ninguna entregable.",
    SECTION_PROCESS_VIEWER_SELECT_ACTIVITIES_MESSAGE:
        "Seleccione operaciones para crear relaciones entre ellas.",
    SECTION_PROCESS_VIEWER_SELECTED_ACTIVITIES_MESSAGE:
        "{{activitiesNum}} actividades seleccionadas.",
    SECTION_PROCESS_VIEWER_PREDECESSOR_MESSAGE:
        "{{activitiesNum}}  actividades seleccionadas. Por favor, defina ahora sus predecesores...",
    SECTION_PROCESS_VIEWER_SUCCESSOR_MESSAGE:
        "{{activitiesNum}}  actividades seleccionadas. Por favor, defina ahora sus sucesores...",

    // Deliverables list page
    SECTION_DELIVERABLES_PAGE_TITLE: "Entregables",
    SECTION_DELIVERABLES_PAGE_SUBTITLE: "Volver al panel de instrumentos",
    SECTION_DELIVERABLES_PAGE_FILTERS: "Filtros activos:",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_CODE_NAME: "Nombre y Código",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STRUCT_DESC:
        "Descripción y Estructura",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_DATES:
        "Fecha de inicio y finalización",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STATUS: "Flujo de trabajo y Estado",
    SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA:
        "No hay Entregables que concidan con sus filtros",
    INFO_DELIVERABLES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> Entregables en la lista",
    INFO_LIST_UPDATED_MESSAGE: "Lista actualizada",
    INFO_LIST_SELECTION_REMOVED_MESSAGE: "Selección eliminada",
    SEARCHABLE_SELECT_SELECTION_REMOVED_MESSAGE: "Selección eliminada",
    SELECTED_ACTIVITIES__SECTION_PRINT_HEADER: "Actividades seleccionadas",

    ACTION_ASSIGN_TEMPLATE_ERROR_MAX_REQUEST:
        "El número de Entregables seleccionadas superó la solicitud máxima permitida.",
    ACTION_ASSIGN_TEMPLATE_LOADING: "Asignando plantilla...",
    ACTION_ASSIGN_TEMPLATE_SUCCESS_N:
        "Plantilla de flujo de trabajo asignada a {{changed}} de {{total}} entregables.",

    ACTION_OPEN_BULK_PROGRESS_CHANGE_DIALOG:
        "Actualización de progreso en grupo",
    DIALOG_BULK_PROGRESS_UPDATE_TITLE: "Actualización de progreso en grupo",
    DIALOG_BULK_PROGRESS_UPDATE_HEADER:
        "Por favor, seleccione todas las actividades que quiere actualizar:",
    INFO_BULK_PROGRESS_CHANGE_MESSAGE:
        "La grabación sobrescribirá el estado actual de todas las operaciones seleccionadas de todas las Entregables previamente seleccionadas.",
    INFO_BULK_PROGRESS_CHANGE_SUCCESS:
        "{productos}}} actividades han sido configuradas como '{{state}}'.",
    DIALOG_BULK_PROGRESS_UPDATE_ACTION_HEADER:
        "Ahora seleccione el estado que quiere reportar para esas actividades:",
    DIALOG_DELIVERABLE_TYPE_SELECTION_TITLE:
        "Activar el filtro Plantilla del flujo de trabajo",
    DIALOG_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "Para poder configurar las actividades y las actualizaciones de los informes, utilice primero la herramienta de filtro para seleccionar la plantilla de flujos de trabajo que desea editar. Lo encontrará en la ventana de filtros que puede abrir a la derecha.",

    ACTION_OPEN_BULK_UPDATE_OPTIONS_DIALOG: "Actualizar informes",
    DIALOG_BULK_UPDATE_OPTIONS_TITLE: "Actualización de entregables en grupo",
    DIALOG_BULK_UPDATE_OPTION_UPDATE_PROGRESS_TITLE:
        "Actualizar progreso de actividades",
    DIALOG_BULK_UPDATE_OPTION_CREATE_NOTE_TITLE: "Adjuntar Nota a actividades",

    DIALOG_BULK_CREATE_NOTE_TITLE: "Crear Notas en grupo",
    DIALOG_BULK_CREATE_NOTE_HEADER:
        "Por favor, seleccione la actividad para la que desea crear una nota:",

    DIALOG_BULK_PDF_EXPORT_TITLE:
        "Exportar los PDF de las listas de control de calidad",
    DIALOG_BULK_PDF_EXPORT_TEXT:
        "Elija las actividades para las que desea descargar los archivos PDF. Nosotros generaremos PDFs para ellos y le enviaremos un correo electrónico.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_TEXT:
        "Para exportar todas las listas de control de calidad firmadas en un día específico, seleccione la fecha a continuación. Después generaremos los PDFs para usted y le enviaremos un correo electrónico.",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TITLE:
        "No hay listas de control de calidad para exportar",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TEXT:
        "No hay listas de control de calidad completadas guardadas en Sablono para su selección actual. Para poder exportar archivos PDF, seleccione Entregables y Actividades que incluyan listas de control de calidad completadas.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_EMPTY_TEXT:
        "No hay listas de control de calidad que se hayan cargado y firmado en la fecha seleccionada. Para poder exportar los PDF, seleccione otro día.",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TITLE: "Sus PDFs están siendo generados...",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TEXT:
        "Una vez que hayamos creado sus {{numberOfReports}} archivos PDF, los enviaremos por correo electrónico a {{email}}. <br>Por favor, tenga en cuenta que esto llevará tiempo dependiendo del número de PDFs que haya solicitado (por ejemplo, 1000 PDFs pueden tardar hasta 60min).<br>Si está exportando grandes cantidades de PDFs, le rogamos que espere hasta recibir el primer correo electrónico, antes de activar la siguiente exportación.",

    DIALOG_DELIVERABLE_CHECKLIST_PDF_EXPORT_TITLE:
        "Exportar documentos de calidad para entregar",
    DIALOG_DELIVERABLE_CHECKLIST_PDF_SUCCESS_MESSAGE:
        "Una vez generado su PDF, lo enviaremos por correo electrónico a {{email}}<br>Por favor, tenga en cuenta que esto puede tomar algún tiempo dependiendo de la cantidad de datos incluidos.",

    ACTION_CONFIGURE_DELIVERABLES: "Configurar entregables",
    DIALOG_DELIVERABLE_SET_CONFIG_TITLE: "@:ACTION_CONFIGURE_DELIVERABLES",
    DIALOG__DELIVERABLE_SET__ASSIGN_TEMPLATE__OPTION_TITLE:
        "Asignar plantilla de flujos de trabajo",
    DIALOG__DELIVERABLE_SET__ASSIGN_STRUCTURE__OPTION_TITLE:
        "Mover estructura del proyecto",
    DIALOG__DELIVERABLE_SET__CHANGE_DATES__OPTION_TITLE: "Editar fechas",
    DIALOG__DELIVERABLE_SET__ASSIGN_DELIVERABLE_TYPE__OPTION_TITLE:
        "Asignar tipo",
    DIALOG__DELIVERABLE_SET__ASSIGN_RESPONSIBLE_MANAGER__OPTION_TITLE:
        "Asignar Gerente responsable",
    DIALOG__DELIVERABLE_SET__DELETE__OPTION_TITLE: "Borrar Entregables",

    ACTION_CONFIGURE_ACTIVITIES: "Configurar actividades",
    DIALOG_ACTIVITY_SET_CONG_TITLE: "@:ACTION_CONFIGURE_ACTIVITIES",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_END_TITLE:
        "Especifique la fecha de finalización",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_START_TITLE:
        "Especifique la fecha de inicio",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_TITLE:
        "Eliminar las fechas de actividad definidas por el usuario",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_SUBMIT:
        "Eliminar las fechas definidas por el usuario",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_TITLE:
        "Ocultar actividades del flujo de trabajo",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_TITLE:
        "Mostrar actividades ocultas",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES:
        "Por favor, seleccione las actividades que deben ser visibles de nuevo para los entregables previamente seleccionados:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY:
        "Por favor, seleccione las actividades para las que desea definir una fecha de inicio personalizada:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_END_DATE:
        "Seleccione las actividades para las que desea definir una fecha de finalización personalizada:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_UNSET:
        "Seleccione las actividades para las que desea eliminar todas las fechas de inicio y finalización personalizadas:",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_SELECT_ACTIVITIES:
        "Por favor seleccione aquellas actividades que deben ser ocultadas del flujo de trabajo de sus Entregables previamente seleccionadas:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_DATE:
        "Seleccione una fecha para",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_HINT:
        "Tenga en cuenta que las fechas de inicio o vencimiento más tempranas sólo se utilizarán como restricción al calcular la planificación del proyecto si no contradicen los flujos de trabajo y las dependencias definidas.",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_SUBMIT: "Ocultar actividades",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUBMIT:
        "Mostrar actividades ocultas",
    DIALOG_ACTIVITY_UNSET_WHAT_TEAM:
        "¿Para qué equipo desea volver a utilizar el estándar?",

    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_TITLE:
        "Modificar la duración de las actividades",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SELECT:
        "Por favor, seleccione aquellas actividades de los entregables previamente seleccionados para las que desea cambiar la duración:",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SUBMIT: "Cambiar la duración",
    ACTION_SET_DURATION_ACTIVITY_SUCCESS:
        "Las duraciones {{activities}}/{{totalActivitiesForBulkOperation}} cambiaron con éxito.",

    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_TITLE:
        "Eliminar la duración de las actividades definidas por el usuario",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SELECT:
        "Por favor, seleccione aquellas actividades de los entregables previamente seleccionados para las que desea eliminar la duración definida por el usuario y reactivar la duración estándar definida en el esquema de flujos de trabajo:",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SUBMIT: "Usar duración estándar",
    ACTION_UNSET_DURATION_ACTIVITY_SUCCESS:
        "Las duraciones {{activities}}/{{totalActivitiesForBulkOperation}} cambiaron con éxito.",

    DIALOG_ASSIGN_AREA_MANAGER_TITLE: "Asignar al Gerente responsable",
    DIALOG_ASSIGN_AREA_MANAGER_TEXT:
        "Por favor, escriba el correo electrónico de su compañero/a que desea establecer como Gerente responsable. Por favor, ten en cuenta que primero tienes que añadirlo/a a este proyecto",
    DIALOG_ASSIGN_AREA_MANAGER_ACTION: "Asignar",
    ACTION_ASSIGN_AREA_MANAGER_SUCCESS_N:
        "{{displayName}} fue establecido como Gerente responsable para {{deliverables}} Entregables.",

    ACTION_SEND_REQUEST_FOR_PROGRESS: "Enviar solicitud",
    ACTION_OPEN_REQUEST_FOR_PROGRESS_DIALOG: "Solicitud de actualización",
    DIALOG_REQUEST_FOR_UPDATE_TITLE: "Solicitud de actualización",
    DIALOG_REQUEST_FOR_UPDATE_CONTENT_HEADER:
        "Por favor, escriba el correo electrónico de su colaborador del que desea recibir información. Por favor, ten en cuenta que primero tienes que añadirlo a este proyecto.",
    DIALOG_PICK_PROJECT_MEMBER_USERS_PLACEHOLDER:
        "Correo electrónico de los colaboradores...",
    DIALOG_PICK_PROJECT_MEMBER_USER_NOT_FOUND:
        "Parece que está intentando solicitar una actualización de un usuario no añadido a este proyecto. Por favor, añádalo primero.",
    DIALOG_REQUEST_FOR_UPDATE_WARNING:
        "Parece que usted ha seleccionado un número muy grande de Entregables. Recomendamos enviar solicitudes de información más pequeñas, ya que su colaborador puede sentirse abrumado por la cantidad de información requerida.",
    TOAST_REQUEST_FOR_PROGRESS_SUCCESS:
        "Solicitud de actualización para {{deliverables}} Entregables",

    ACTION_OPEN_ASSIGN_TYPE_DIALOG: "Asignar Tipo de Entregable",
    DIALOG_ASSIGN_TYPE_TITLE: "Asignar Tipo de Entregable",
    DIALOG_ASSIGN_TYPE_TEXT:
        "Por favor, cree o seleccione un Tipo que desee asignar a los Entregables previamente seleccionados:",
    DIALOG_ASSIGN_TYPE_ACTION: "Asignar",
    DIALOG_PICK_TYPE_TO_ASSIGN_PLACEHOLDER: "Cree o seleccione un Tipo...",
    DIALOG_PICK_TYPE_TO_ASSIGN_NOT_FOUND:
        "Este tipo no existe todavía en este proyecto...",

    //Details Overlay
    _TIMEZONE: "Zona horaria",
    _DELAY: "Entregable prevista",
    _DELAYED_BY_X_DAYS: "{{days}} días de retrasado",
    _SOURCE: "Origen",
    _CREATED_WITH_EXCEL: "Importado de Excel",
    _CREATED_WITH_PRIMAVERA: "Importado de Primavera",
    _CREATED_WITH_MSP: "Importado de Microsoft Project",
    _CREATED_WITH_ASTA: "Importado de Asta Powerproject",
    _CREATED_WITH_CLONE: "Clonada de otro proyecto",
    _CREATED_WITH_USER: "Creado con Sablono",
    _SHOW_ALL: "Mostrar todas las actividades",
    _SHOW_ALL_CHECKLISTS: "Mostrar todos las listas de control de calidad",

    INFO_BEHIND_SCHEDULE_EXPLANATION_MESSAGE:
        "Al menos una actividad retrasada",
    OPEN_ACTIVITY_UPDATE_TIMELINE:
        "Calendario de actualización abierto para esta actividad",

    SECTION_DEPENDENCIES_TITLE: "Relaciones de dependencia ",
    INFO_NO_CHECKLIST_ITEMS:
        "Parece que esta lista de control de calidad se presentó sin ningún elemento que se haya comprobado.",
    INFO_DELIVERABLES_DEPENDENCIES_SCHEDULE_MODE_TEXT:
        'En el modo de "programación individual", las dependencias no influirán enel cronograma del proyecto. Puede cambiar a "programación conectada" en la página Configuración del proyecto.',
    INFO_DELIVERABLE_PREDECESSOR_ADD_TEXT:
        "Los predecesores deben estar terminados antes de que pueda iniciarse esta entregable.",
    INFO_DELIVERABLE_SUCCESSOR_ADD_TEXT:
        "Los sucesores sólo pueden iniciarse una vez finalizada esta entregable.",
    INFO_DETAILS_OVERLAY_FILTER_TEXT: "Filtrar por Equipo",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_TITLE:
        "No hay actividades asignadas al equipo",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_MESSAGE:
        "Parece que el equipo no fue asignado responsable de ninguna actividad de esta secuencia.",
    TOAST_DELETE_CUSTOM_DATES: "Fecha de eliminación definida por el usuario",
    SELECT_PREDECESSOR_DEPENDENCY_TITLE: "Seleccionar predecesores de la lista",
    SELECT_SUCCESSOR_DEPENDENCY_TITLE: "Seleccionar sucesores de la lista",
    ACTION_ADD_SUCCESSOR: "Añadir sucesor",
    ACTION_ADD_PREDECESSOR: "Añadir predecesor",
    ACTION_CLOSE_DEPENDENCY_MANAGER: "Terminando de editar",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_PRED:
        "Busque las Entregables por nombre, código o descripción y defina los predecesores...",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_SUCC:
        "Busque las Entregables por nombre, código o descripción y defina los sucesores...",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_DIALOG_TITLE:
        "Borrar relación de dependencia",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_MULTIPLE_DIALOG_TEXT:
        "¿Realmente desea borrar esta relación? Puesto que se transformó automáticamente de una dependencia de dos entregables, todas las dependencias asociadas a ella ({{value}}) también se eliminarán.",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_SINGLE_DIALOG_TEXT:
        "¿Realmente desea borrar esta relación?",
    INFO_ACTIVITY_DEPENDENCY_NOT_FULFILLED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' of <b>' + deliverableSource + '</b>' : ''}} necesita ser terminado antes de que <b>{{activityTarget}}</b>{{ deliverableTarget ? ' of <b>' + deliverableTarget + '</b>' : ''}} puede iniciarse.",
    INFO_ACTIVITY_DEPENDENCY_COMPLETED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' of <b>' + deliverableSource + '</b>' : ''}} estaba terminado, por lo tanto <b>{{activityTarget}}</b>{{ deliverableTarget ? ' of <b>' + deliverableTarget + '</b>' : ''}} puede iniciarse.",
    ERROR_EDGE_WOULD_INTRODUCE_CYCLE:
        "La relación de dependencia solicitada daría lugar a un ciclo.",

    // Structure  and dates dialog

    DIALOG_ASSIGN_STRUCTURE_TITLE:
        "Mover Entregables a la parte de la estructura del proyecto",
    DIALOG_ASSIGN_STRUCTURE_SELECTED_STRUCTURE_TEXT:
        "Las Entregables seleccionadas se moverán a la siguiente parte de la estructura:",
    ACTION_ASSIGN_STRUCTURE:
        "Mover Entregables a la parte de la estructura del proyecto",
    ACTION_CHANGE_DATES_SUCCESS_N:
        "Las fechas de {{deliverables}} Entregables han cambiado.",
    DIALOG_CHANGE_DATES_RANGE: "Mantener duración de {{days}} días.",
    DIALOG_CHANGE_DATES_LOADING_TEXT: "Cambiar fechas...",
    DIALOG_CHANGE_DATES_DERIVE_FROM_SCHEDULE_HINT_TEXT: "Eliminar fecha",
    DIALOG_ASSIGN_STRUCTURE_LOADING_TEXT: "Moviendo Entregables…",
    ACTION_ASSIGN_STRUCTURE_SUCCESS_N:
        "{{deliverables}} Entregables han sido movidas.",
    DIALOG_CHANGE_DATES_OPTION_BOTH_TITLE:
        "Modificar fechas de inicio y de entregable",
    DIALOG_CHANGE_DATES_OPTION_START_TITLE: "Modificar fechas de inicio",
    DIALOG_CHANGE_DATES_OPTION_END_TITLE: "Modificar fechas de entregable",
    INFO_DATE_CHANGE_CONFLICT_STRATEGY_MESSAGE:
        "Pueden producirse conflictos de fechas (pendiente antes de la fecha de inicio). Estos conflictos se resolverán borrando la fecha previa de inicio si decide continuar.",
    ACTION_SET_ACTIVITY_DUE_DATES_SUCCESS_N:
        "Las fechas de entregable de {{activities}}/{{totalActivitiesForBulkOperation}} han sido cambiadas.",
    ACTION_SET_ACTIVITY_START_DATES_SUCCESS_N:
        "Las fechas de inicio de {{activities}}/{{totalActivitiesForBulkOperation}} han sido cambiadas.",
    ACTION_DELETE_BULK_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} actividades eliminadas con éxito",
    ACTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} actividades visibles de nuevo",
    ACTION_UNSET_ACTIVITY_DATES_SUCCESS_N:
        "Las fechas definidas por el usuario de {{activities}}/{{totalActivitiesForBulkOperation}} actividades han sido eliminadas.",

    // Delete deliverables
    DIALOG_DELETE_DELIVERABLES_TITLE: "Borrar Entregables",
    DIALOG_DELETE_DELIVERABLES_TEXT_N:
        "¿Está seguro que desea eliminar {{items}} Entregables?",
    DIALOG_DELETE_DELIVERABLES_OK: "Sí, bórralas",
    TOAST_DELETE_DELIVERABLES_SUCCESS:
        "{{items}} Entregables eliminadas con éxito ",
    DIALOG_DELETE_DELIVERABLES_LOADING_TEXT: "Borrando Entregables...",

    // notes
    INFO_ALL_ISSUES_RESOLVED: "Todos los problemas se han resuelto.",
    INFO_NO_NOTES_REPORTED_TITLE: "No hay notas reportadas.",

    INFO_ISSUES_RESOLVED: "{{closed || 0}}/{{total}} cuestiones resueltas",

    ACTION_PRINT_DELIVERABLES_REPORT: "Imprimir informe de Entregables",
    ACTION_PRINT_ACTIVITIES_REPORT: "Imprimir informe de actividades",
    ACTION_PRINT_STRUCTURE_REPORT:
        "Imprimir informe de estructura del proyecto",
    ACTION_PRINT_REPORT: "Imprimir informe",
    ACTION_PRINT_REPORTs: "Imprimir informes",
    ACTION_EXPORT_DATA: "Exportar datos",
    ACTION_SHOW_PROCESS_VIEWER: "Mostrar todas las actividades",
    ACTION_SHOW_DELIVERABLES_LIST: "Mostrar todas las Entregables",
    ACTION_ASSIGN_AS_PREDECESSOR: "Defina predecesores de la selección",
    ACTION_ASSIGN_AS_SUCCESSOR: "Defina sucesor de la selección",

    INFO_DELIVERABLES_REPORT_TITLE: "Informe de progreso de Entregables",
    INFO_DELIVERABLES_REPORT_TEXT:
        "Este informe contiene información de progreso para aquellas Entregables que se visualizan actualmente en la lista.",
    INFO_ACTIVITIES_REPORT_TITLE: "Informe de progreso de Actividades",
    INFO_ACTIVITIES_REPORT_TEXT:
        "Este informe detallado contiene información de progreso para las Entregables visualizados actualmente, así como las actividades asignadas a los mismos.",
    INFO_STRUCTURE_REPORT_TEXT:
        "Este informe contiene información de progreso para todo el proyecto estructurado por la estructura de proyecto definida.",
    INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE:
        "Exportar datos del proyecto (CSV)",
    INFO_QR_CODES_TITLE: "Códigos QR",

    OVERVIEW_TITLE: "Informe de progreso Estructura del proyecto",
    OVERVIEW_TEXT:
        "Este informe contiene información de progreso por estructura de desglose del trabajo de su proyecto.",

    INFO_QR_CODES_TEXT:
        "Los códigos QR se pueden utilizar para identificar los Entregables en la obra y encontrar las Actividades relacionadas más rápidamente en la aplicación móvil.",
    INFO_QA_CHECKLIST_PDFS_TITLE: "PDFs de las Listas de control de calidad",
    INFO_QA_CHECKLIST_PDFS_TEXT:
        "En el siguiente paso podrá seleccionar las Actividades para las que desea exportar sus Listas de Control de Calidad como PDFs.",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TITLE: "Listas de control de calidad en PDF",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TEXT:
        "Esta exportación contiene todos los PDF de las listas de control de calidad que se han firmado en un día seleccionado. En el siguiente paso podrá seleccionar para qué fecha desea ejecutar la exportación.",

    SECTION_CHOOSE_REPORT_TYPE: "Seleccione un informe",

    INFO_NO_FILTERED_DATA:
        "No hay datos que coincidan con sus criterios de filtrado.",

    // LeanBoard
    SECTION_LEANBOARD_TITLE: "Planificación Lean",
    SECTION_LEANBOARD_EMPTY_MESSAGE: "No hay actividades programadas.",
    ACTION_LEANBOARD_GO_TO_PREV_WEEK: "Abierto la semana anterior",
    ACTION_LEANBOARD_GO_TO_NEXT_WEEK: "Abierto la próxima semana",
    ACTION_LEANBOARD_OPEN_OVERLAY: "Ver detalles",
    ACTION_LEANBOARD_FORECAST_DELIVERABLE_DATES: "Pronosticar todos",
    ACTION_LEANBOARD_SHOW_AGGREGATED_DELIVERABLES:
        "Mostrar los entregables incluidos",
    ACTION_LEANBOARD_ACCEPT_ALL: "Aceptar todo",
    ACTION_LEANBOARD_OPEN_LATE_ACTIVITIES_PANEL:
        "{{lateActivities}} actividades atrasadas",
    DIALOG_LATE_ACTIVITIES_TITLE:
        "Actividades atrasadas para {{deliverableName}}",
    DIALOG_LATE_ACTIVITIES_MESSAGE:
        "Haga clic en las actividades para establecer nuevas fechas de Corto Plazo.",
    ACTION_LEANBOARD_MOVE_TO_THIS_WEEK: "Cambiar a la semana actual",
    INFO_LEANBOARD_UPDATING_SCHEDULE: "Cargando...",
    ONE_WEEK: "1 semana",
    TWO_WEEKS: "2 semana",
    THREE_WEEKS: "3 semana",
    FOUR_WEEKS: "4 semana",
    EIGHT_WEEKS: "8 semana",
    TWELVE_WEEKS: "12 semana",
    COLUMN_TIMEFRAME_DAYS: "Días",
    COLUMN_TIMEFRAME_WEEKS: "Semanas",

    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_HEADER: "Sesión en curso...",
    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_MESSAGE:
        "Actualmente está ejecutando una sesión de Duración a Corto Alcance. ¿Desea salir de la página sin guardar o descartar sus cambios? Tenga en cuenta que otros usuarios no podrán iniciar otra sesión de preparación mientras la suya esté en curso.",
    ACTION_STAY_IN_LEANBOARD_SESSION: "Permanecer en la página",
    ACTION_DISCARD_LEANBOARD_SESSION_AND_LEAVE:
        "Cancelar y borrar todos los cambios",
    ACTION_RELEASE_LEANBOARD_SESSION_AND_LEAVE:
        "Liberar los resultados en el proyecto",
    ACTION_KEEP_LEANBOARD_SESSION_OPEN_AND_LEAVE: "Mantener la sesión abierta",

    INFO_LEANBOARD_DELIVERABLE_SELECTED:
        "Se ha seleccionado una actividad de este entregable.",

    // Request for update
    SECTION_REQUEST_TEXT:
        "Actualice las Entregables o busque otras si no puede encontrar los que ha trabajado.",
    SECTION_REQUEST_SUCCESS_TITLE: "Actualización exitosa",
    SECTION_REQUEST_SUCCESS_TEXT:
        "Se ha guardado su actualización. Gracias por colaborar!",
    SECTION_REQUEST_UPLOAD_BUTTON: "Guardar progreso",
    SECTION_REQUEST_INFORMATION_OPT_OUT_BUTTON: "Búsqueda de otras Entregables",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TITLE: "Acceso denegado",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TEXT:
        "Parece que el enlace en el que hizo clic debía ser utilizado por otro colaborador del proyecto. Por favor, asegúrese de que el correo electrónico estaba destinado a usted y compruebe dos veces con su remitente.",
    SECTION_REQUEST_ERROR_CLOSED_TITLE: "Enlace expirado",
    SECTION_REQUEST_ERROR_CLOSED_TEXT:
        "El enlace en el que hizo clic ya se ha utilizado para enviar un informe de progreso a la plataforma. Por favor, asegúrese de haber utilizado el correo electrónico correcto.",
    SECTION_REQUEST_ERROR_NOT_EXIST_TITLE: "No hay solicitud abierta",
    SECTION_REQUEST_ERROR_NOT_EXIST_TEXT:
        "Ups... Parece que no hay ninguna solicitud abierta de actualización relacionada con el enlace que utilizó. Por favor, asegúrese de hacer clic en un enlace válido.",
    ACTION_SELECT_PROJECT: "Seleccione un proyecto",

    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_PLACEHOLDER: "Buscar....",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_TITLE: "Buscar entrgas",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_MESSAGE:
        "Búsqueda de Entregables por nombre, código o descripción.",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_START_SEARCH_ACTION:
        "Iniciar búsqueda",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_TITLE:
        "No se han encontrado Entregables",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_MESSAGE:
        "No se han encontrado coincidencias para <b>{searchTerm}}</b>",
    SECTION_DELIVERABLES_PROGRESS_LIST_PREDECESSOR_DONE_BADGE:
        "Predecesor terminado",
    SECTION_DELIVERABLES_PROGRESS_LIST_SCHEDULED_BADGE: "Trabajo planificado",
    SECTION_PROCESS_PREVIOUS_PAGE: "Mostrar las siguientes actividades",
    SECTION_PROCESS_NEXT_PAGE: "Mostrar actividades anteriores",
    DIALOG_CREATE_DEPENDENCY_LOADING_TITLE: "Un momento...",
    DIALOG_CREATE_DEPENDENCY_LOADING_TEXT:
        "En este momento estamos creando sus dependencias. Esto puede tardar un par de segundos.",

    INFO_PROCESS_VIEWER_NOTE_CREATED: "Nota creada con éxito",

    SECTION_RESET_PASSWORD_LINK: "¿Olvidó su contraseña?",
    SECTION_RESET_PASSWORD_TEXT:
        "Por favor, escriba su dirección de correo electrónico. Le enviaremos un correo electrónico con más información.",
    SECTION_RESET_PASSWORD_BUTTON: "Solicitar una nueva contraseña",
    SECTION_RESET_PASSWORD_SUCCESS_TITLE: "Sólo quedan unos pasos...",
    SECTION_RESET_PASSWORD_SUCCESS_TEXT:
        'Por favor, revise su bandeja de entrada para más instrucciones. Asegúrate de echar también un vistazo a tu carpeta de spam si no puedes encontrar nuestro correo electrónico. Si no lo encuentra, por favor contacte con nuestro servicio de atención al cliente: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_TITLE: "Ups…",
    SECTION_RESET_PASSWORD_ERROR_TEXT:
        'Parece que algo salió mal. Vuelva a intentarlo en breve. Si esto sucede de nuevo, no dude en contactar con nuestro servicio de atención al cliente: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TITLE:
        "Límite de intentos excedido",
    SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TEXT:
        "Límite de intentos excedido, Por favor intente luego.",
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TITLE: "Usuario no existe",
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TEXT:
        "Parece que este usuario no está registrado para Sablono. Por favor, asegúrese de que no ha escrito mal.",
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TEXT:
        'Parece que no pudimos entregar el correo electrónico que contiene la contraseña temporal a la dirección que utilizó. Póngase en contacto con nuestro servicio de atención al cliente: <a href="mailto:support@sablono.com">support@sablono.com</a> Nos pondremos en contacto con usted lo antes posible.',
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TITLE: "Ups…",

    // Issues page
    SECTION_ISSUES_PAGE_TITLE: "Notas",
    INFO_ISSUES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> Notas en la lista",
    INPUT_ISSUE_TEXT_SEARCH_TITLE: "Texto de nota",
    INPUT_ISSUE_TEXT_SEARCH_PLACEHOLDER: "Filtrar por texto de nota...",
    INPUT_ISSUE_KEY_SEARCH_LABEL: "Código de nota",
    INPUT_ISSUE_KEY_PLACEHOLDER: "Filtrar por código de nota...",
    INPUT_ISSUE_TYPE_LABEL: "Tipo de nota",
    INPUT_ISSUE_STATUS_LABEL: "Estado",
    INPUT_ISSUE_TEMPLATE_KEY_LABEL: "Código de plantilla de notas",
    INPUT_ISSUE_TEMPLATE_KEY_PLACEHOLDER:
        "Filtrar por código de plantilla de nota...",
    INPUT_ISSUE_AUTHOR_SEARCH_LABEL: "Inspector",
    INPUT_ISSUE_AUTHOR_PLACEHOLDER:
        "Filtrar por nombre del inspector que reporta la nota...",
    INPUT_ISSUE_DELIVERABLE_SEARCH_LABEL: "Búsqueda de Entregable",
    INPUT_ISSUE_DELIVERABLE_PROCESS_SEARCH_LABEL:
        "Plantilla del flujo de trabajo",
    INPUT_ISSUE_DELIVERABLE_SEARCH_PLACEHOLDER:
        "Buscar notas por nombre, código o descripción...",
    INPUT_DELIVERABLE_NAME_LABEL: "Nombre de entregable",
    INPUT_DELIVERABLE_NAME_PLACEHOLDER: "Filtrar por nombre de entregable...",
    INPUT_DELIVERABLE_CODE_LABEL: "Código de entregable",
    INPUT_DELIVERABLE_CODE_PLACEHOLDER: "Filtrar por código de entregable...",
    INPUT_DELIVERABLE_DESC_LABEL: "Descripción de la entregable",
    INPUT_DELIVERABLE_DESC_PLACEHOLDER:
        "Filtrar por descripción de entregable...",
    INPUT_ACTIVITY_SEARCH_LABEL: "Búsqueda de Actividades",
    INPUT_ACTIVITY_SEARCH_PLACEHOLDER:
        "Buscar notas por nombre o descripción de la actividad....",
    INPUT_ACTIVITY_NAME_LABEL: "Nombre de la actividad",
    INPUT_ACTIVITY_NAME_PLACEHOLDER: "Filtrar por nombre de actividad...",
    INPUT_ACTIVITY_DESC_LABEL: "Descripción de la actividad",
    INPUT_ACTIVITY_DESC_PLACEHOLDER: "Filtrar por descripción de actividad...",

    INPUT_ISSUE_ISSUE_RESPONSIBLE_TEAM_PLACEHOLDER:
        "Seleccionar un equipo responsable...",

    INPUT_ISSUE_ISSUE_CONFIRMATION_TEAM_PLACEHOLDER:
        "Seleccionar un equipo revisor...",

    INPUT_ISSUE_GEOLOCATION_INFO:
        "El administrador de tu proyecto te ha pedido que subas fotos con geolocalización. Asegúrese de hacerlo.",

    //create new issue
    DIALOG_ISSUE_EDITOR_TITLE: "Crear una nueva nota",
    DIALOG_EDIT_ISSUE_TITLE: "Editar nota",
    DIALOG_EDIT_ISSUE_TOOLTIP: "@:ACTION_TEMPLATE_ADD_CONTENT",
    DIALOG_EDIT_ISSUE_SUCCESS: "@:INFO_PROJECT_EDIT_SUCCESS_TITLE",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_ERROR_REQUIRED:
        "Por favor, seleccione un Equipo revisor antes de continuar",
    DIALOG_ISSUE_EDITOR_TEAM_SELECTOR_LABEL: "Equipo responsable",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_LABEL: "Equipo revisor",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_NONE:
        "No se ha solicitado una revisión por separado",
    INPUT_ISSUE_DESCRIPTION_TITLE:
        "Introduzca un texto que describa la nueva nota",
    ACTION_ADD_ATTACHMENT_TO_NOTE: "Añadir archivo adjunto",

    //close issue
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TITLE: "Informe de estado de cuestiones",
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TEXT:
        "¿Realmente quieres cerrar este tema? Esto no se puede deshacer.",
    ACTION_CLOSE_ISSUE: "Cerrado",
    ACTION_OPEN_ISSUE: "Abrir",
    ACTION_FINISH_ISSUE: "En revisión",
    ACTION_CONFIRM_ISSUE: "Cerrado",
    ACTION_REJECT_ISSUE: "Reabierto",

    //print issue report dialog
    DIALOG_PRINT_ISSUE_REPORT_TITLE: "Seleccione un informe",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TITLE:
        "Informe de notas en PDF con fotos",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TEXT:
        "Este informe contendrá las notas que actualmente figuran en la lista.",
    SECTION_PRINT_CSV_ISSUE_REPORT_TITLE: "Tabla CSV",
    SECTION_PRINT_CSV_ISSUE_REPORT_TEXT:
        "Esta tabla contendrá todas las notas existentes y puede abrirse en Microsoft Excel.",

    //user defined dates
    INFO_DELETING_ALL_USER_DEFINED_DATES_MESSAGE:
        "Borrando fechas definidas por el usuario",
    INFO_USER_DEFINED_DATE: "Fecha definida por el usuario",
    USER_DEFINED_DATE_CONFLICT:
        "Conflicto de fechas definidas por el usuario y calculadas",
    INFO_USER_DEFINED_DATE_EXPLAINED:
        "Esta fecha definida por el usuario contradice el flujo de trabajo y las dependencias definidas. Por lo tanto, lo verá aquí, pero no influye en la programación calculada.",
    //Automatically calculated durations
    INFO_EQUAL_DISTRIBUTED_DURATIONS:
        "Duración calculada - Esta duración de la actividad se ha calculado en función de la duración de la entreda. Puede definir duraciones de actividad utilizando el editor de plantillas.",
    INFO_SCHEDULING_ON_DEMAND_DIRTY_MODE_TEXT:
        "Ha hecho modificaciones en la programación de este proyecto (ej., Entregables borradas o creó nuevas dependencias) que todavía no se han incorporado al programa.",
    ACTION_RECALCULATE_SCHEDULE: "Recalcular el cronograma",

    //////
    // Start of Commercial page
    //
    SECTION_COMMERCIAL_PAGE_TITLE: "Tablero comercial",
    SECTION_COMMERCIAL_PRO_PAGE_TITLE: "Valoración comercial",
    ACTION_NAV_TO_COMMERCIAL_PRO: "Valoración comercial",

    ACTION_NAV_TO_DASHBOARD_PRO: "Centro de control de Proyectos",
    SECTION_DASHBOARD_PRO: "Centro de control de Proyectos",

    ACTION_NAV_TO_COMMERCIAL: "Tablero comercial",
    SECTION_COMMERCIALS_PAGE_LOADING_DATA_MESSAGE: "Cargar datos comerciales",
    SECTION_COMMERCIALS_PAGE_NO_DATA_TITLE: "Sin grupo de costo disponible",
    SECTION_COMMERCIALS_PAGE_NO_DATA_MESSAGE:
        "Los Grupos de Costo le ayudarán a evaluar el progreso de su proyecto. Actualmente, no hay ningún Grupo de Costo disponible para que usted lo revise, lo que podría significar que no se creó ninguno o que usted no tiene suficientes permisos para revisarlos, todavía.",

    // subbar actions
    ACTION_SETUP_COST_GROUPS: "Crear un nuevo grupo de costos",

    // create cost group dialog
    DIALOG_CREATE_COST_GROUP_TITLE: "Crear un nuevo grupo de costos",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_TYPE:
        "En primer lugar, seleccione la plantilla de flujos de trabajo que contiene las actividades que desea asignar al nuevo grupo de costos:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_ACTIVITIES:
        "Seleccione todas las actividades que forman parte del nuevo grupo de costos:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_NAME:
        "¿Cuál es el nombre del grupo de costos?",
    DIALOG_CREATE_COST_GROUP_NEW_NAME_PLACEHOLDER: "Nombre",

    // cost group
    ACTION_DELETE_COST_GROUP: "Borrar grupo de costos",
    COST_GROUP_SAVED: "Grupo de costos guardado",

    // cost group details - statistics
    INFO_COST_GROUP_STAGES_TITLE: "Detalles del grupo de costo",
    INFO_COST_GROUP_STAGE_WEIGHT: "% del presupuesto",
    INFO_COST_GROUP_STAGE_BUDGET_ALLOCATED: "Presupuesto asignado",
    INFO_COST_GROUP_STAGE_BUDGET_STARTED: "Iniciado",
    INFO_COST_GROUP_STAGE_BUDGET_WAITING_FOR_CONFIRMATION:
        "Esperando confirmación",
    INFO_COST_GROUP_STAGE_BUDGET_COMPLETED: "Terminado y Confirmado",

    INFO_COST_GROUP_STAGE_PERCENTAGE_OF_COST_GROUP_SPENT:
        "Presupuesto del Grupo de Costo gastado",

    // cost group details - configuration error
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_TITLE: "Peso",
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_MESSAGE: "Peso total:",
    ERROR_COST_GROUP_CONFIG_REMAINING_WEIGHT_MESSAGE: "Peso restante:",

    // cost group header
    INPUT_COST_GROUP_ENTER_BUDGET_TITLE: "Introducir presupuesto",
    COST_GROUP_BUDGET_ALLOCATED: "Presupuesto asignado",
    COST_GROUP_BUDGET_STARTED: "Iniciado",
    COST_GROUP_BUDGET_WAITING_FOR_CONFIRMATION: "Esperando confirmación",
    COST_GROUP_BUDGET_COMPLETED: "Terminado y Confirmado",
    COST_GROUP_PERCENT_COST_COMPLETED: "Costo total terminado",

    ERROR_COST_GROUP_WEIGHT_INPUT_PATTERN_MESSAGE: "no un número",
    ERROR_COST_GROUP_WEIGHT_BUDGET_MIN_MESSAGE:
        "0 es el presupuesto mínimo soportado",

    COST_GROUP_NUMBER_OF_COMMERCIAL_MANAGERS:
        "Gerentes Comerciales: {{numberOf}}",
    COST_GROUP_NUMBER_OF_COMMERCIAL_REVIEWERS:
        "Evaluadores Comerciales: {{numberOf}}",

    COST_GROUP_ADD_MEMBER_DIALOG_HEADER: "Agregar o remover usuarios",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_MANAGER:
        "<b>Los Gerentes Comerciales</b> pueden modificar y revisar los valores comerciales de este equipo, así como gestionar el acceso.",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_REVIEWER:
        "<b>Los Evaluadores Comerciales</b> pueden revisar los valores comerciales de este equipo, pero no editar nada.",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_HEADER: "¿Estás seguro?",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_TEXT:
        "Una vez que te hayas eliminado, ya no podrás ver ni editar los valores comerciales de este equipo. ¿Desea continuar?",

    COMMERCIAL_PAGE_SECTION_COMBINED_STAGES:
        "Actividades de múltiples plantillas de flujos de trabajo",
    ACTION_COMBINE_COST_GROUP: "Combinar actividades",
    DIALOG_COMBINE_COST_GROUP_TITLE: "Combinar actividades",
    DIALOG_COMBINE_COST_GROUP_STAGE_SELECT_ACTIVITIES_DESCRIPTION:
        "Por favor, seleccione todas las actividades que desee combinar en una sola fila del Grupo de Costos:",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_DESCRIPTION:
        "Por favor, especifique el nombre de la fila combinada incluyendo las Actividades previamente seleccionadas:",
    DIALOG_CONFIRM_COMBINE: "Combinar",

    COST_GROUP_STAGE_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Busca las actividades por plantilla de flujo de trabajo e nombre...",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_PLACEHOLDER: "Nombre",

    //
    // End of Commercial  page

    //////
    // Start of CSV progress export headers
    //
    DELIVERABLE_CODE: "Entrega-Código",
    DELIVERABLE_NAME: "Entrega-Nombre",
    DELIVERABLE_DESC: "Entrega-Descripción",
    PROJECT_STRUCTURE: "Estructura de Proyecto",
    PLANNED_DELIVERABLE_START_DATE: "Inicio definido por el usuario (Entrega)",
    PLANNED_DELIVERABLE_END_DATE: "Final definido por el usuario (Entrega)",
    EARLIEST_DELIVERABLE_START_DATE: "Inicio más temprano (Entrega)",
    EARLIEST_DELIVERABLE_END_DATE: "Final más temprano (Entrega)",
    LATEST_DELIVERABLE_START_DATE: "Inicio más tardío (Entrega)",
    LATEST_DELIVERABLE_END_DATE: "Final más tardío (Entrega)",
    DELIVERABLE_SCHEDULE: "Entrega-Cronograma",
    DELIVERABLE_PROGRESS: "Entrega-Flujo de trabajo",

    ACTIVITY_ID: "ID de la actividad",
    ACTIVITY_NAME: "Actividad-Nombre",
    ACTIVITY_DESCRIPTION: "Actividad-Descripción",
    ACTIVITY_PLANNED_DURATION: "Duración planeada",
    ACTIVITY_ASSIGNED_TEAM_NAME: "Equipo de ejecución",
    PLANNED_ACTIVITY_START_DATE: "Inicio definido por el usuario (Actividad)",
    PLANNED_ACTIVITY_END_DATE: "Final definido por el usuario (Actividad)",
    EARLIEST_ACTIVITY_START_DATE: "Inicio más temprano (Actividad)",
    EARLIEST_ACTIVITY_END_DATE: "Final más temprano (Actividad)",
    LATEST_ACTIVITY_START_DATE: "Inicio más tardío (Actividad)",
    LATEST_ACTIVITY_END_DATE: "Final más tardío (Actividad)",
    ACTIVITY_ACTUAL_START_DATE: "Actividad-Fecha de inicio (real)",
    ACTIVITY_ACTUAL_END_DATE: "Actividad-Fecha de plazo (real)",
    ACTUAL_START_REPORTED_BY: "Inicio reportado por",
    ACTUAL_END_REPORTED_BY: "Final reportado por",
    ACTIVITY_OPEN_CLAIM_QUANTITY: "Cuestiones abiertas",
    ACTIVITY_OPEN_OBSTRUCTION_QUANTITY: "Obstructiones abiertas",
    ACTIVITY_CURRENT_PROGRESS: "Estado actual",
    ACTIVITY_SCHEDULE: "Actividad-Cronograma",
    ACTIVITY_REQUIRED_CHECKLIST: "Lista QA adjuntada",
    ACTIVITY_LAST_PLANNED_START: "Inicio previsto (Activdad)",
    ACTIVITY_LAST_PLANNED_END: "Final Previsto (Actividad)",
    ACTIVITY_LAST_PLANNED_DURATION: "Duración prevista (Días de trabajo)",
    ACTIVITY_CONFIRMATION_TIME: "Actividad-Fecha de confirmación (real)",
    ACTIVITY_CONFIRMATION_REPORTER: "Confirmado por",
    ACTIVITY_STATE: "Estado",
    //
    // End of CSV progress export headers

    //////
    // Start of checklist template abstract page
    //
    CHECKLIST_TEMPLATE_PAGE_NAME: "Listas de control de calidad",
    CHECKLIST_TEMPLATE_ADD_NEW: "Crear una nueva lista de QA",
    //
    // End of checklist template abstract page

    //////
    // Start of checklist template 'list' page
    //
    CHECKLIST_TEMPLATE_LIST_LOADING_DATA_MESSAGE:
        "Cargar listas de control de calidad",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TITLE: "Cree su primera lista de QA",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TEXT:
        "Las listas de control de calidad ayudan a garantizar un trabajo de alta calidad. Después de crear una lista de comprobación se puede asignar a las Actividades en el Editor de plantillas de flujos de trabajo. Posteriormente, se solicitará una vez que se cambie el estado de las Actividades.",
    CHECKLIST_TEMPLATE_LIST_EMPTY_BUTTON: "Cree su primera lista de QA",

    CHECKLIST_TEMPLATE_LIST_NUMBER_OF_ITEMS: "Número de elementos",
    CHECKLIST_TEMPLATE_LIST_CREATION: "Creado",

    CREATED_BY:
        "Creado {{timestamp | amDateFormat:dateFormat}} por {{inspector}}",

    CREATE_NEW_CHECKLIST_TEMPLATE_TITLE: "Crear una nueva lista de QA",
    CREATE_NEW_CHECKLIST_TEMPLATE_DESCRIPTION:
        "Primero, por favor elija un nombre para su nueva lista de QA:",
    CREATE_NEW_CHECKLIST_TEMPLATE_NAME_PLACEHOLDER: "Nombre de la lista de QA",
    CREATE_NEW_CHECKLIST_TEMPLATE_CONFIRM: "Crear una lista de QA",
    //
    // End of checklist template 'list' page

    //////
    // Start of checklist template 'editor' page
    //
    CREATE_CHECKLIST_EDITOR_TITLE: "Editor de Listas de control de calidad",
    CREATE_CHECKLIST_EDITOR_ITEMS: "Elementos:",
    CREATE_CHECKLIST_EDITOR_ADD_NEW: "Añadir",
    CREATE_CHECKLIST_EDITOR_ADD_NEW_PLACEHOLDER:
        "Agregue un nuevo elemento a su lista de QA...",
    CREATE_CHECKLIST_EDITOR_SAVED: "Lista de control guardada con éxito.",

    CREATE_CHECKLIST_EDITOR_SETTINGS_LABEL: "Obligatorio para",
    CREATE_CHECKLIST_EDITOR_SETTINGS_TOOLTIP:
        "Los elementos obligatorios de la lista de verificación están resaltados en la aplicación Hoy y no pueden ser contestados 'no verificados' por el equipo de confirmación.",

    CREATE_CHECKLIST_EDITOR_IS_MANDATORY: "Todos los equipos",
    CREATE_CHECKLIST_EDITOR_IS_NOT_MANDATORY: "Ningún equipo",
    CREATE_CHECKLIST_EDITOR_IS_CONFIRM_MANDATORY: "Sólo equipo de confirmación",
    CREATE_CHECKLIST_EDITOR_IS_WORK_MANDATORY: "Sólo equipo de ejecución",

    CREATE_CHECKLIST_EDITOR_REQUIRE_IMAGE_LABEL: "Foto necesaria",

    //
    // End of checklist template 'editor' page

    SECTION_CHECKLISTS_TITLE: "Listas de control de calidad",
    CHECKLISTS_TAB_NUMBER_UPLOADED: "Listas de control de calidad cargadas",
    CHECKLISTS_TAB_NUMBER_NON_STARTED: "Listes de contrôle d’AQ pas commencé",
    CHECKLISTS_TAB_NUMBER_ATTACHED_TO_ACTIVITY: "Adjunta a",
    CHECKLISTS_TAB_DONE_BY: "Terminado {{timestamp}} por {{inspector}}",
    CHECKLISTS_TAB_NO_CHECKLISTS_FOR_FILTER:
        "No se han asignado actividades con protocolos de garantía de calidad vinculados.",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE_FOR_FILTER:
        "Parece que no se han asignado al equipo actividades con protocolos de garantía de calidad vinculados para esta secuencia.",
    CHECKLISTS_TAB_NO_CHECKLISTS:
        "No hay registro de control de calidad vinculado",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE:
        "Los registros de control de calidad permiten comprobar una lista de elementos de la actividad. Los registros de control de calidad pueden vincularse a las actividades de las plantillas de flujos de trabajo.",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED:
        "Sin plantilla de flujo de trabajo asignada",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED_MESSAGE:
        "La asignación de plantillas de flujo de trabajo le permite capturar un mayor nivel de detalle para sus entregables. y añadir listas de comprobación a las actividades.",

    CHECKLISTS_DETAILS_RESPONSIBLE_TEAM: "Equipo de ejecución",
    CHECKLISTS_DETAILS_CONFIRMING_TEAM: "Equipo de confirmación",
    CHECKLISTS_DETAILS_REPORTED_BY: "Reportado por:",
    CHECKLISTS_DETAILS_RESULT_Y: "Sí",
    CHECKLISTS_DETAILS_RESULT_N: "No",
    CHECKLISTS_DETAILS_RESULT_B: "No verificado",
    CHECKLISTS_DETAILS_RESULT_NA: "Non applicabile",
    CHECKLISTS_DETAILS_RESULT_EMPTY: "",

    BACKGROUND_MENU_GLOBAL_SETTINGS: "Ajustes",
    BACKGROUND_MENU_RELEVENT_ONLY: "Mostrar sólo mis áreas",

    TEAM_USERS:
        "Usuarios responsables de Entregables no asignadas a Gerente responsable",
    TEAM_CONSTRUCTION_MANAGERS:
        "Gerentes de Construcción responsables de Entregables específicas",
    TEAM_ADD_REPLACEMENT: "Definir usuario de reemplazo",
    TEAM_REPLACEMENT_ACTIVE: "Activado",
    TEAM_REPLACEMENT_INACTIVE: "Desactivado",
    TEAM_REMOVE_REPLACEMENT: "Eliminar usuario de reemplazo",
    TEAM_NO_EXISTING_REPLACEMENT: "Ningún usuario de reemplazo existente",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TITLE:
        "Confirmar Gerente de Construcción en movimiento",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TEXT:
        "¿Estás seguro de que quieres trasladar a este Director de Construción a otro equipo? Si continúa, todos los Entregables asignados a él no serán asignados. Esto sólo se puede deshacer usando la página de Entregables.",

    WEEK_BOARD_OPTIONS: "Opciones",
    WEEK_BOARD_CHOOSE_SIZE: "Mostrar el diseño de la actividad grande",
    WEEK_BOARD_SHOW_ACTUALS: "Mostrar fechas reales cuando estén disponibles",
    WEEK_BOARD_SIZES_SMALL: "Pequeño",
    WEEK_BOARD_SIZES_LARGE: "Grande",
    WEEK_BOARD_PREVIOUS: "Página anterior",
    WEEK_BOARD_NEXT: "Página siguiente",

    DATE_ORIGIN_ACTUAL: "(Real)",
    DATE_ORIGIN_PREDICTED: "@:DATE_ORIGIN_LOOK_AHEAD",
    DATE_ORIGIN_FORECASTED: "(Prevista)",
    DATE_ORIGIN_LOOK_AHEAD: "(Corto plazo)",
    DATE_ORIGIN_BASELINE: "(Línea de base)",

    ACTIVITY_DETAILS_BASELINE_DURATION: "Duración (Línea de base)",
    ACTIVITY_DETAILS_STRUCTURE_DISPLAYED_DURATION:
        "Estructura del proyecto Duración",
    ACTIVITY_DETAILS_MEAN_DURATION_LOOKAHEAD: "Duración media (Corto plazo)",
    ACTIVITY_DETAILS_BASELINE_START: "Fecha de inicio (Línea de base)",
    ACTIVITY_DETAILS_BASELINE_DUE: "Fecha de finalización (Línea de base)",
    ACTIVITY_DETAILS_ACTUAL_START: "Inicio real",
    ACTIVITY_DETAILS_ACTUAL_DONE: "Final real",
    ACTIVITY_DETAILS_ACTUAL_CONFIRMED: "Real confirmado",
    ACTIVITY_DETAILS_LATEST_DURATION: "Duración (Corto plazo)",
    ACTIVITY_DETAILS_LATEST_START: "Fecha de inicio (Corto plazo)",
    ACTIVITY_DETAILS_NEW_DURATION: "Nueva duración",
    ACTIVITY_DETAILS_DISPLAYED_DURATION: "Duración actual",
    ACTIVITY_DETAILS_NEW_START: "Nueva fecha de inicio",
    ACTIVITY_DETAILS_DISPLAYED_START: "Fecha de inicio actual",
    ACTIVITY_DETAILS_NEW_DUE: "Nueva fecha de finalización",
    ACTIVITY_DETAILS_DISPLAYED_DUE: "Fecha de finalización actual",
    ACTIVITY_DETAILS_LATEST_DUE: "Fecha de finalización (Corto plazo)",
    ACTIVITY_DETAILS_NUMBER_OF_CHANGES: "Número de re-planificaciones",
    ACTIVITY_DETAILS_FORECAST_START: "Fecha de inicio",
    ACTIVITY_DETAILS_FORECAST_DUE: "Fecha de final prevista",
    ACTIVITY_DETAILS_ACCEPT_FORECAST: "Usar como fecha de corto plazo",
    ACTIVITY_DETAILS_FORECAST_START_TOOLTIP:
        "Utilice la fecha de inicio como nueva fecha de final de corto plazo. Después, todavía tendrá que guardar y publicar sus cambios en el proyecto",
    ACTIVITY_DETAILS_FORECAST_DUE_TOOLTIP:
        "Utilice la fecha prevista como nueva fecha de final de corto plazo. Después, todavía tendrá que guardar y publicar sus cambios en el proyecto",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES:
        "Programar todas las actividades siguientes de acuerdo con sus cambios y dependencias definidas.",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES_TOOLTIP:
        "Cuando la casilla está marcada, Sablono programará automáticamente todas las actividades sucesoras de ésta de acuerdo con los cambios que usted haya definido.Por favor, tenga en cuenta que esto conducirá a las fechas de anticipación para todas las actividades siguientes también.",
    ACTIVITY_DETAILS_RESPONSIBLE_TEAM: "Equipo de ejecución",
    ACTIVITY_DETAILS_PLANNED_LABOUR: "Mano de obra planificada",
    ACTIVITY_DETAILS_CONFIRMING_TEAM: "Equipo de confirmación",
    ACTIVITY_DETAILS_DELIVERABLE_CODE: "Código de entregable",
    ACTIVITY_DETAILS_DELIVERABLE_NAME: "Deliverable Name",
    ACTIVITY_DETAILS_PROJECT_STRUCTURE: "Estructura del proyecto",
    ACTIVITY_DETAILS_NUMBER_OF_DELIVERABLES: "Entregas incluidas",
    ACTIVITY_DETAILS_NUMBER_OF_FORECASTED_ACTIVITIES: "pronosticado",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY: "Productividad prevista",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY_UNIT: "Entregas por día",
    ACTIVITY_DETAILS_LAST_CHECKED_BY: "Última actualización por",
    ACTIVITY_DETAILS_LAST_CHECKED_ON: "Última actualización",
    ACTIVITY_DETAILS_CHANGE_DATE:
        "Seleccionar fecha de finalización de corto plazo",
    ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED:
        "No se ha solicitado una revisión por separado",
    ACTIVITY_DETAILS_START_SESSION: "Iniciar sesion",
    ACTIVITY_DETAILS_CHANGE_DATES_OF_ACTIVITIES: "Modificar fechas",
    ACTIVITY_DETAILS_HAS_CHANGED_THIS_SESSION:
        "Esta fecha de final de corto plazo se ha cambiado durante la sesión en marcha.",
    ACTIVITY_DETAILS_SAVED_IN_SESSION: "Sesión grabada",
    ACTIVITY_DETAILS_BULK_LOOK_AHEAD_SAVED:
        "{{changed}} de {{all}} fechas para mirar hacia adelante fueron cambiadas.",

    FILTER_MENU: "Filtros: {{activeFilterCount}} actualmente activos",
    FILTER_MENU_SEARCH_PLACEHOLDER: "Buscar en {{items}} elementos...",

    ACTION_DOWNLOAD_WEEKPLAN: "Imprimir",
    SESSION_MENU_BULK_CHANGES_CANCEL: "Finalizar",
    SESSION_MENU_BULK_CHANGES_START: "Mover múltiples actividades",
    SESSION_MENU_BULK_CHANGES_APPLY: "Modificar fechas de selección",
    SESSION_MENU_BULK_CHANGES_ACTIVITIES:
        "{{activitiesNum}} actividades seleccionadas.",
    SESSION_MENU_START: "Comenzar sesión de planificación a corto plazo",
    SESSION_MENU_SAVE_AND_RELEASE: "Publicar",
    SESSION_MENU_CANCEL: "Descartar",
    SESSION_MENU_COMMIT_TO_ALL_FORECASTED_DATES:
        "Utilice todas las fechas previstas como nuevo cronograma de corto plazo",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_TITLE:
        "Sesión de planificación sobre el Planificación Lean en curso....",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_MESSAGE:
        "Los siguientes usuarios aún no han publicado los cambios a corto plazo: {{users}}. Por favor, asegúrese de que el usuario que las ha creado guarde o descarte las fechas previstas antes de seguir trabajando en la planificación del proyecto.",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_TITLE:
        "Utilice todas las fechas previstas como nuevo cronograma de corto plazo",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_CONTENT:
        "¿Está seguro de que desea utilizar todas las fechas previstas como nuevas fechas de planificación a corto plazo? Por favor, tenga en cuenta que esto también incluirá los Entregables que usted podría no ver en su pantalla en este momento (por ejemplo, porque no coinciden con sus criterios de filtrado).",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE:
        "¿Realmente quiere pronosticar todos los entregables?",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE_CONTENT:
        "Pronóstico Todo incluirá todos los Entregables que coincidan con sus filtros actuales. Tenga en cuenta que también pueden aparecer en las páginas anteriores/siguientes. ¿Desea continuar?",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_TITLE:
        "¿Desea realmente utilizar todas las fechas previstas como nueva programación de Look Ahead?",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_CONTENT:
        "Aceptar todo incluirá todos los entregables previstos aunque no coincidan con sus filtros actuales o se muestren en las páginas anteriores/siguientes. ¿Desea continuar?",

    WORKFLOW_ACTIVITY_DETAIL_DELETE_CUSTOM_DATE:
        "Borrar fechas definidas por el usuario",
    WORKFLOW_ACTIVITY_DETAIL_QA_CHECKLIST: "Lista de control adjunta",
    WORKFLOW_ACTIVITY_DETAIL_LOOK_AHEAD_DURATION: "Duración (Corto plazo)",
    WORKFLOW_ACTIVITY_BASELINE_DURATION: "Duración (Línea de base)",
    WORKFLOW_GROUP_ACTIVITIES_COMPLETED: "Actividades finalizadas",
    ACTIVITY_DETAILS_REVIEW_TEAMS: "Equipo de inspección",

    WORKFLOW_BULK_LOOK_AHEAD_MULTI:
        "ALT + Clic para seleccionar un nivel de estructura de proyecto",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_TITLE: "Calculando su horario...",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_PROGRESS:
        "Todavía tenemos que reprogramar {{items}}  actividades.",
    WORKFLOW_BULK_LOOK_AHEAD_DIALOG_TITLE:
        "Cambiar las fechas para la selección",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_TITLE:
        "Otra actividad ya seleccionada...",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_MESSAGE:
        "Parece que ya ha seleccionado otra actividad del mismo entregable. Si desea seleccionar esta opción, deseleccione la que haya seleccionado anteriormente.",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_TITLE:
        "La actividad ya ha terminado...",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_MESSAGE:
        "Para actividades que ya han sido terminadas, las fechas de Look Ahead ya no pueden ser modificadas. Verá su fecha de inicio y final real cuando vea el Planificación Lean.",

    // Daily logs page
    SITE_DIARY_TEAMS_CARD_TITLE_LEFT: "Equipos",
    SITE_DIARY_TEAMS_CARD_TITLE_MANAGERS: "Gerentes",
    SITE_DIARY_TEAMS_CARD_TITLE_FOREMEN: "Capataz",
    SITE_DIARY_TEAMS_CARD_TITLE_WORKERS: "Trabajador",
    SITE_DIARY_TEAMS_CARD_TITLE_OTHER: "Otro",
    SITE_DIARY_TEAMS_CARD_TITLE_TOTAL: "Total",
    SITE_DIARY_PROGRESS_CARD_TITLE: "Avance",
    SITE_DIARY_PROGRESS_CARD_INFORMATION:
        "Última actualización {{createdAt}}. La información presentada puede actualizarse hasta que se finalice el registro.",
    SITE_DIARY_PROGRESS_CARD_TOOLTIP:
        "Esta actividad se inició el día {{dateStarted}}",
    SITE_DIARY_MACHINES_CARD_TITLE: "Maquinas",
    SITE_DIARY_MACHINES_CARD_PLACEHOLDER:
        "Introduzca qué máquinas y cuántas se utilizaron en el día seleccionado.",
    SITE_DIARY_EVENTS_CARD_TITLE: "Eventos especiales",
    SITE_DIARY_EVENTS_CARD_PLACEHOLDER:
        "Introduzca cualquier evento especial u otras notas que desee documentar para el día seleccionado.",
    ACTION_CHOOSE_A_DATE: "Seleccione fecha",
    ACTION_FINALISE_ENTRY: "Finalizar el registro",
    SITE_DIARY_CALENDAR_TODAY: "Hoy",
    SITE_DIARY_CALENDAR_FINALISED: "Terminado",
    SITE_DIARY_CALENDAR_FINALISED_BY: "Terminado por {{finaliser}}",

    SITE_DIARY_INFO_CARD_LOW: "Mínima",
    SITE_DIARY_INFO_CARD_HIGH: "Máxima",
    SITE_DIARY_INFO_CARD_CONDITIONS: "Estado del tiempo",
    SITE_DIARY_INFO_CARD_DEPARTMENT: "Departmento",
    SITE_DIARY_INFO_CARD_TEMPERATURE_UNIT: "&deg;C",

    SITE_DIARY_CARD_INPUT_ERROR_MIN_MESSAGE:
        "Por favor, seleccione un número arriba de {{value}}",
    SITE_DIARY_CARD_INPUT_ERROR_MAX_MESSAGE:
        "Por favor, seleccione un número abajo de {{value}}",

    SITE_DIARY_PROGRESS_REPORTED_BY: "Actualizado por {{inspector}}",
    SITE_DIARY_PROGRESS_EMPTY:
        "Parece que no se ha informado de ningún progreso, así como tampoco se han abierto o cerrado Notas en el día seleccionado....",
    SITE_DIARY_NOTE_CREATED_BY: "Actualizado por {{inspector}}",

    SITE_DIARY_CONFIRM_LEAVE_TITLE: "Cambios no guardados",
    SITE_DIARY_CONFIRM_LEAVE_TEXT:
        "Parece que ha hecho algunos cambios en este Registro Diario que aún no se han guardado. ¿Estás seguro de que quieres dejar esta página sin guardarlos primero?",

    SITE_DIARY_SAVE_SUCCESS: "Registro diario actualizado con éxito",
    SITE_DIARY_PAGINATION_SELECTION: "Página {{currentPage}} de {{totalPages}}",

    SITE_DIARY_SHOW_DETAILS: "Mostrar detalles",
    SITE_DIARY_HIDE_DETAILS: "Ocultar detalles",

    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TITLE: "¿Estás seguro de eso?",
    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TEXT:
        "Después de finalizar este registro diario, usted o sus colegas no podrán cambiarlo por más tiempo. ¿Quiere continuar?",

    COST_GROUP_ENTER_SEARCH_TERM: "Buscar Grupos de Costos...",
    COST_GROUP_SEARCH_EMPTY:
        "No hay grupos de coste que coincidan con sus criterios.",

    COST_GROUP_CONFIRM_DELETE_TITLE: "¿Estás seguro?",
    COST_GROUP_CONFIRM_DELETE_BODY:
        "¿Estás seguro de eso? Esto no se puede deshacer.",
    COST_GROUP_FORECASTED_VALUES_HINT:
        "Usted seleccionó una fecha en el futuro. Los números en el panel de Tablero comercial se proyectan en función de dónde debe estar el proyecto en la fecha que usted ha seleccionado.",

    COST_GROUP_CSV_COLUMN_WORK_PACKAGE: "Paquete de trabajo",
    COST_GROUP_CSV_COLUMN_BUDGET_SHARE: "Parte del presupuesto en %",
    COST_GROUP_CSV_COLUMN_BUDGET_ALLOCATED: "Presupuesto asignado",
    COST_GROUP_CSV_COLUMN_STARTED: "{{currency}} iniciado",
    COST_GROUP_CSV_COLUMN_PERCENT_STARTED: "% de Activitidades iniciadas",
    COST_GROUP_CSV_COLUMN_TOTAL_STARTED: "Número de actividades iniciadas",
    COST_GROUP_CSV_COLUMN_WAITING_FOR_CONFIRMATION:
        "{{currency}} esperando confirmación",
    COST_GROUP_CSV_COLUMN_PERCENT_WAITING_FOR_CONFIRMATION:
        "% de Actividades esperando confirmación",
    COST_GROUP_CSV_COLUMN_TOTAL_WAITING_FOR_CONFIRMATION:
        "Número de Actividades esperando confirmación",
    COST_GROUP_CSV_COLUMN_FINISHED_AND_CONFIRMED:
        "{{currency}} terminado y confirmado",
    COST_GROUP_CSV_COLUMN_PERCENT_FINISHED_AND_CONFIRMED:
        "% de Actividades terminadas y confirmadas",
    COST_GROUP_CSV_COLUMN_TOTAL_FINISHED_AND_CONFIRMED:
        "Número de Actividades terminadas y confirmadas",
    COST_GROUP_CSV_COLUMN_PERCENT_COST_GROUP_BUDGET_SPENT:
        "% del presupuesto del Grupo de Costo gastado",
    COST_GROUP_CSV_COLUMN_DATE_OF_GENERATION: "Informe exportado en",
    COST_GROUP_CSV_COLUMN_FIGURES_UP_TO_DATE: "Incl. actualizaciones hasta",

    ACTIVITIES_LIST_SELECTOR_HEADER_ACTIVITY: "Actividad",
    ACTIVITIES_LIST_SELECTOR_HEADER_TEAM: "Equipo de ejecución",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_TEAM:
        "Equipo de ejecución estándar",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_CONFIRMING_TEAM:
        "Equipo de confirmación estándar",
    ACTIVITIES_LIST_SELECTOR_HEADER_CONFIRMATION_TEAM:
        "@:CHECKLISTS_DETAILS_CONFIRMING_TEAM",
    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Busca las actividades por plantilla de flujo de trabajo, nombre y equipo...",

    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TITLE:
        "Código de Verificación no coincide",
    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TEXT:
        "Código de Verificación proporcionado no es válido, por favor intente nuevamente.",

    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TITLE:
        "Código de verificación inválido",
    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TEXT:
        "Parece que el código de verificación que introdujo no es correcto. Por favor, compruébelo e inténtelo de nuevo.",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TITLE:
        "Código de verificación expirado",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TEXT:
        "Oops... Parece que su código de verificación ha caducado. Por favor, solicita uno nuevo e inténtalo de nuevo.",
    SECTION_VERIFICATION_CODE_RESENT:
        "Se ha enviado un nuevo código de verificación a su dirección de correo electrónico registrada. Utiliza el enlace del nuevo correo electrónico para volver a intentarlo.",

    ERROR_EMAIL_FORMAT:
        "Por favor introduzca una dirección de correo electrónico válida",

    LEGEND_STAGE_MODE_DEFAULTS: "Otros",
    /**
     * Context: Information to display when options are disabled.
     *
     * Cases:
     *  - A checklist is required and this feature is currently not supported.
     */
    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS___TITLE:
        "Se requieren equipos de inspección",
    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS__MESSAGE:
        "Esta actividad tiene que ser controlada por los equipos de inspección, que sólo es compatible con la aplicación móvil Sablono. Por favor, utilícela para informar sobre el progreso.",
    MAKING_DECISION_LOCAL_DATA_HEADER:
        "Plantilla de flujo de trabajo guardada localmente",
    MAKING_DECISION_LOCAL_DATA_MESSAGE:
        "Actualmente está abriendo una versión de su Plantilla de Flujos de trabajo que sólo se ha guardado localmente en este dispositivo {{timeAgo}}. ¿Desea continuar trabajando en ella o quiere descargar la nueva Plantilla del servidor de Sablono?",
    DISCARD_AND_DOWNLOAD_NEW_VERSION: "Descartar y descargar la nueva versión",

    DELETE_PROJECT_DIALOG_WARN_USER:
        "¿Realmente quieres borrar este proyecto? Esto borrará toda la información relacionada de todos los sistemas Sablono.",
    DELETE_PROJECT_DIALOG_WARN_USER_INFO:
        "Advertencia: Esta acción no puede deshacerse.",
    DELETE_PROJECT_DIALOG_WARN_USER_NEXT: "Proceder",
    DELETE_PROJECT_DIALOG_WARN_USER_ALT: "Solicitar la exportación de datos",
    DELETE_PROJECT_DIALOG_PASSWORD:
        "Por favor, verifica tu contraseña de Sablono para poder borrar este proyecto irremediablemente.",
    DELETE_PROJECT_DIALOG_WRONG_PASSWORD: "Su contraseña no es válido.",
    DELETE_PROJECT_DIALOG_COPY_NAME_TO_DELETE:
        "Finalmente, por favor copie el nombre de su proyecto <b>{{projectName}}</b> de tu proyecto para confirmar que realmente quieres borrarlo permanentemente del servidor de Sablono. Advertencia: Esta acción no puede deshacerse.",
    DELETE_PROJECT_DIALOG_REPEAT_PROJECT_NAME_PLACEHOLDER:
        "Introduzca el nombre del proyecto para su confirmación",
    _ERASE: "Borrar",

    ARCHIVE_PROJECT: "Archivar el proyecto",
    ARCHIVE_PROJECT_CONFIRMATION:
        "¿Realmente quieres archivar este proyecto? Después, ningún usuario tendrá acceso a él, pero se guardará en el servidor de Sablono y podría ser reactivado en el futuro.",

    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL_SUBJECT:
        "Solicitar la exportación de datos",
    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL:
        "Hola, apoyo de Sablono,%0D%0A%0D%0A" +
        "Me gustaría borrar el proyecto (ID: {{projectId}}) pero recibir una exportación de los datos de antemano. Por favor, póngase en contacto conmigo lo antes posible y hágame saber los próximos pasos.%0D%0A" +
        "Saludos,%0D%0A" +
        "{{username}}",

    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER_WITHOUT_PROCESS_TEMPLATE:
        "Busca las actividades por plantilla de nombre y equipo...",

    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_TITLE: "¿Desea continuar?",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_MESSAGE:
        "Si desea reemplazar la Plantilla de flujo de trabajo en Entregables que ya fueron iniciados, por favor seleccione la casilla de abajo. Esta acción sólo puede ser revertida por el soporte de Sablono.",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_CHECKBOX_MESSAGE:
        "Sí, quiero sobrescribir los datos de progreso existentes",
    ERR_FR_MAX_BODY_LENGTH_EXCEEDED:
        "Parece que la cantidad de listas de control de calidad que está intentando exportar supera el límite de nuestro servidor. Por favor, inténtelo de nuevo seleccionando un número menor. Si el problema persiste, póngase en contacto con nuestro servicio de asistencia.",

    FILTER_BY_PROCESS_TEMPLATES_TITLE:
        "Filtrar por plantilla de flujo de trabajo",
    FILTER_BY_PROCESS_TEMPLATES_ALL_TEMPLATES: "Todos los flujos de trabajo",

    FILTER_BY_DELIVERABLE_TYPE_TITLE: "Filtrar por tipo de Entregable",
    FILTER_BY_DELIVERABLE_TYPE_ALL_TEMPLATES: "Todos los tipos de Entregable",

    DASHBOARD_PROJECT_BAR_CHART_TITLE: "Todas las actividades",
    SECTION_PROJECT_OVERVIEW_TITLE: "Resumen del proyecto",
    ACTION_NAV_TO_PROJECT_OVERVIEW_TITLE: "Resumen del proyecto",

    PROJECT_OVERVIEW_PROCESS_TEMPLATES_TITLE: "Flujos de trabajo",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_TITLE: "Tipos de entregables",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_TITLE:
        "Crear Tipos de Entregables",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_MESSAGE:
        "Una vez que haya clasificado sus Entregables por Tipos, encontrará aquí un resumen por Tipo.",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_ACTION: "@:GO_TO_DELIVERABLES",

    DASHBOARD_BAR_CHART_TOTAL_LABEL: "Total:",
    DASHBOARD_BAR_CHART_TOTAL_TEXT: "@:_ACTIVITIES_N",
    _DELIVERABLE_TYPE: "Tipo de Entregable",

    REMOVE_DELIVERABLE_TYPE: "Eliminar el tipo de Entregable",
    DIALOG_UNASSIGNED_TYPE_ACTION: "Eliminar",

    //    TUTORIAL LINKS

    TUTORIAL_BAR_CHART:
        "http://support.sablono.com/es/articles/4828856-grafico-de-barras-trabajo-planificado-vs-real",
    TUTORIAL_S_CURVE:
        "http://support.sablono.com/es/articles/4828855-curva-s-planificado-vs-real",
    TUTORIAL_PRODUCTIVITY_CHART:
        "https://support.sablono.com/es/articles/8704401-grafico-de-productividad-planificado-vs-real-por-semana",
    TUTORIAL_DELIVERABLE_TYPES:
        "http://support.sablono.com/es/articles/4838516-como-categorizar-sus-entregables-por-tipos",
    TUTORIAL_IMPORT_PROGRESS:
        "http://support.sablono.com/es/articles/5393783-como-importar-datos-de-progreso-desde-microsoft-excel",

    DELIVERABLE_LINK_TO_MOBILE_LABEL: "Abrir en la aplicación móvil",
    DELIVERABLE_LINK_TO_MOBILE: "Haga clic aquí",
    ERROR_OPEN_DEEP_LINK_TO_NATIVE_APP_MESSAGE:
        "Asegúrese de que la aplicación Sablono está instalada y disponible para abrirla",
    DELIVERABLE_VIEW: "Entregables",
    STRUCTURE_VIEW: "Estructura",

    PAGINATION_FETCH_FROM_SOURCE_ERROR_MESSAGE:
        "No se han podido cargar más datos en el fondo. La información presentada en la vista actual puede no estar completa.",

    UPLOAD_SVG_FILE:
        "Su archivo se está cargando - Esto puede tardar unos momentos...",

    TIME_RANGE_SELECTOR_TITLE:
        "Elija el rango de tiempo que se mostrará en la página de Planificación Lean",
    TIME_RANGE_SELECTOR_FROM: "Fecha de inicio",
    TIME_RANGE_SELECTOR_TO: "Fecha de finalización",
    TIME_RANGE_SELECTOR_EXTRA: "Rangos de tiempo predefinidos",
    TIME_RANGE_SELECTOR_CURRENTLY_SELECTED: "Actualmente seleccionado",
    TIME_RANGE_SELECTOR_START: "Inicio",
    TIME_RANGE_SELECTOR_END: "Fin",

    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_TITLE: "Cambiar los equipos",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SELECT:
        "Por favor, seleccione aquellas actividades de los entregables previamente seleccionados para las que desea cambiar los equipos predefinidos de su Plantilla de Flujos de trabajo:",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SUBMIT: "Cambiar equipos",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_DROPDOWN_DESCRIPTION:
        "¿Para qué equipo le gustaría cambiar la norma?",

    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_TITLE:
        "Eliminar equipos definidos por el usuario",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SELECT:
        "Por favor, seleccione aquellas actividades de los entregables previamente seleccionados para las que desea eliminar los equipos definidos por el usuario y utilizar en su lugar el estándar definido en su Plantilla de Flujos de trabajo:",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SUBMIT: "Utilizar equipos estándar",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_DROPDOWN_DESCRIPTION:
        "¿Para qué equipo desea volver a utilizar el estándar?",

    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_RESPONSIBLE:
        "@:ACTIVITY_DETAILS_RESPONSIBLE_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_CONFIRMATION:
        "@:ACTIVITY_DETAILS_CONFIRMING_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_BOTH: "Ambos",
    DIALOG_ACTIVITY_SET_SUCCESS_TEXT:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Equipos cambiados con éxito.",

    SECTION_EXCEL_IMPORT_PROGRESS_TEXT:
        "Por favor, asegúrese de que su Excel tiene el siguiente formato antes de importarlo:<br>" +
        "Código de entregable - Nombre de la actividad - Estado - Marca de tiempo - Formato de la fecha - ID del proyecto<br>" +
        "Por favor, tenga en cuenta que sólo podrá actualizar las actividades de las que su equipo es responsable.",

    SECTION_EXCEL_IMPORT_PROGRESS_TITLE: "@:SECTION_EXCEL_IMPORT_TITLE",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TITLE: "Progreso importado con éxito",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TEXT:
        "{{numberOfUpdates}} Las actualizaciones de estado se han importado a su proyecto",
    SECTION_EXCEL_IMPORT_UPLOADED_WITH_FAILURES:
        "Desafortunadamente, algunas de sus actualizaciones de estado no pudieron ser importadas a su proyecto:",

    CS_REVERT_CONFIRMATION_ACTION: "Solicitud: Revertir la confirmación",
    CS_RESET_ACTIVITY_DIALOG_HEADER: "Solicitud: Restablecer la actividad",
    CS_REVERT_CONFIRMATION_DIALOG_HEADER: "Solicitud: Revertir la confirmación",
    CS_RESET_ACTIVITY_DIALOG_CONTENT:
        "Si desea restablecer esta actividad, que incluye la eliminación de todos los datos conectados, copie el siguiente texto y péguelo en un mensaje al servicio de asistencia de Sablono:",
    CS_REVERT_CONFIRMATION_DIALOG_CONTENT:
        "Si quieres revertir el paso de confirmación de esta actividad incluyendo todos los datos conectados, por favor copia el siguiente texto y pégalo en un mensaje a tu soporte de Sablono:",

    IMPORT_PROGRESS_SUCCESS: "Actividad actualizada con éxito",
    IMPORT_SUCCESS: "Actividad actualizada con éxito",
    IMPORT_PROGRESS_CHECKLIST_REQUIRED:
        "Es necesario rellenar una lista de control de calidad mientras se actualiza el estado de la actividad",
    IMPORT_PROGRESS_NOT_ALLOWED_TRANSITION:
        "Las actualizaciones de estado proporcionadas entran en conflicto con la lógica empresarial de Sablono",
    IMPORT_PROGRESS_ALREADY_UPDATED:
        "La actualización de estado proporcionada ya existe",
    IMPORT_ACTIVITY_DUPLICATE_NAME:
        "El nombre de la actividad proporcionada existe dos veces en el entregable correspondiente",
    IMPORT_PROGRESS_NOT_RESPONSIBLE_TEAM:
        "Su equipo no es responsable de la actividad",
    IMPORT_PROGRESS_TIMESTAMP_IN_THE_FUTURE:
        "La marca de tiempo de la actualización de estado proporcionada está en el futuro",
    IMPORT_ACTIVITY_NOT_FOUND:
        "El nombre de la actividad proporcionada no existe en el correspondiente entregable",
    IMPORT_USER_DEFINED_DATES_ONLY_ONE_DATE_IS_ALLOWED:
        "Sólo debe proporcionarse una de las fechas de inicio o de finalización",
    IMPORT_USER_DEFINED_DATES_NO_DATE_AND_DURATION_GIVEN:
        "Debe indicarse la fecha de inicio o de finalización o la duración",
    IMPORT_USER_DEFINED_DATES_DELIVERABLE_IS_LOCKED:
        "El entregable está actualmente bloqueado en la sesión de planificación",
    IMPORT_USER_DEFINED_DATES_INVALID_UNIT_OF_WORK:
        "Unidad de trabajo incorrecta",
    IMPORT_PROGRESS_WRONG_PROJECT:
        "El ID de proyecto proporcionado no coincide con el proyecto abierto actualmente",
    ERROR_PROGRESS_ENTRIES_LIMITATION:
        "Sólo puede importar hasta 500 actualizaciones de estado durante una importación. Si quiere importar más, divida el archivo en varios e impórtelos uno tras otro.",
    ERROR_DATES_ENTRIES_LIMITATION:
        "Sólo puede importar hasta 10000 actualizaciones durante una importación. Si quiere importar más, divida el archivo en varios e impórtelos uno tras otro.",
    ERROR_PDF_CREATION_MESSAGE:
        "El archivo PDF solicitado es demasiado grande para ser creado. Por favor, reduzca el conjunto de datos incluidos e inténtelo de nuevo.",
    ERROR_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "El tamaño de los datos solicitados ha superado los límites. Por favor, reduce el conjunto de datos incluidos e inténtalo de nuevo.",
    ERROR_NOTE_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "El informe solicitado supera nuestro límite de exportación de {{sizeLimitInMb}} MB. Su solicitud incluye {{numberOfNotes}} notas con {{numberOfImagesIncluded}} fotos ({{sizeEstimateInMbOfImages}} MB). Por favor, reduzca el tamaño de su solicitud de exportación utilizando los filtros. Si sigue recibiendo este mensaje, póngase en contacto con nuestro servicio de asistencia para estudiar las distintas opciones.",
    INTERNAL_SERVER_ERROR: "@:ERROR_UNKNOWN_MESSAGE",
    FORBIDDEN:
        "No tiene suficientes permisos para ejecutar esta acción. Póngase en contacto con el administrador del proyecto para, por ejemplo, cambiar su función.",

    EULA_PROMPT_TITLE:
        "Hemos actualizado nuestro End User Licence Agreement (EULA) y Data and Privacy Policy",
    EULA_PROMPT_CONTENT:
        "Nos gustaría informarle de que hemos adaptado nuestro EULA y Data and Privacy Policy.<br><br>Aquí encontrará la versión actual del <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a>.<br><br>El texto de la Data and Privacy Policy lo encontrará en <a href='https://www.sablono.com/privacy' target='_blank'>https://www.sablono.com/privacy</a>.<br><br>No necesita hacer nada más. Si desea presentar una objeción, hágalo en un plazo de 30 días por correo electrónico a <a href='mailto:support@sablono.com'>support@sablono.com</a>.<br><br>Su equipo Sablono",
    EULA_CONFIRM_READ_TEXT: "He leído y comprendido el EULA.",
    EULA_PROMPT_ACTION_ACCEPT: "Ok",
    EULA_PROMPT_ACTION_READ: "Abrir el EULA como PDF",
    EULA_PROMPT_ACTION_SKIP: "Recordármelo más tarde",
    EULA_LINK:
        "https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf",
    EULA_REVIEW_EULA: "Revisar el EULA",
    PRIVACY_POLICY: "Política de privacidad y datos",
    PRIVACY_POLICY_URL:
        "https://www.sablono.com/legal/data-and-privacy-policy?hsLang=en",

    SECTION_TOOLS_DIALOG_USAGE_NUMBER: "({{active}} Entregables activo)",

    IMPORT_DELIVERABLE_EXCEL_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/2967630-como-importar-un-proyecto-de-microsoft-excel",
    IMPORT_ASTA_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/2960319-como-importar-un-cronograma-de-asta-power-project",
    CREATE_VISUAL_TRACKER_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/2911811-como-crear-visualizaciones-de-progreso-en-sablono",
    VISUAL_TRACKER_SVG_UPLOAD_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/5461453-como-crear-visualizaciones-de-progreso-en-inkscape",
    GO_TO_TUTORIAL_ACTION: "Ir al tutorial",
    COST_GROUP_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/4828779-como-puedo-configurar-un-grupo-de-costos",
    PROCESS_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/2967824-como-crear-una-plantilla-de-procesos",
    QA_CHECKLIST_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/2904883-que-son-las-listas-de-control-de-calidad-y-como-se-usan",
    NOTE_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/2967699-como-crear-una-plantilla-de-notas",
    NOTES_TUTORIAL_URL:
        "https://support.sablono.com/es/articles/5433250-como-asignar-notas-desde-la-plataforma-web",
    NOTES_EMPTY_STATE_HEADER: "Reporta tus primeras Notas",
    NOTES_EMPTY_STATE_CONTENT:
        "En Sablono, puedes crear Notas en tus Entregables, Actividades y elementos de QA. Lea el siguiente tutorial para aprender a reportar sus primeras Notas.",

    LEGEND_LINE_DUE_TODAY: "Para hoy",
    LEGEND_LINE_DUE_THIS_WEEK: "Para finales de esta semana",
    LEGEND_LINE_DUE_NEXT_WEEK: "Para finales de la semana que viene",

    MAIN_CONTRACTOR: "Contratista principal",
    SUBCONTRACTOR: "Subcontratista",
    POD_SUPPLIER: "Proveedor de cuartos de baño prefabricados",
    HOUSE_BUILDER: "Constructor",
    ARCHITECT: "Arquitecto",
    DEVELOPER: "Promotor",
    HOUSING_ASSOCIATION: "Asociación de la vivienda",
    MANUFACTURER: "Fabricante",
    CONSULTANT: "Consultor",
    OTHER: "Otro",
    CONSTRUCTION_MANAGER: "Director de obra",
    CONSTRUCTION_DIRECTOR: "Director de construcción",
    PROJECT_OR_PACKAGE_MANAGER: "Director de Proyecto / Paquete",
    COMMERCIAL_DIRECTOR: "Gerente Comercial",
    COMMERCIAL_MANAGER: "Jefe de obra comercial",
    PLANNER: "Planificador",
    PROJECT_DIRECTOR: "Director de Proyecto",
    BOARD_DIRECTOR: "Director de Junta",
    CONSTRUCTION_OPERATIVE: "Operario de construcción",
    SUB_CONTRACTOR_MANAGER: "Director de Subcontratista",
    CENTRAL_BUSINESS_OR_OFFSITE_MANAGEMENT: "Dirección Central",
    THIRD_PARTY_INSPECTOR: "Inspector de terceros",
    SECTION_REGISTRATION_JOB_TITLE: "Puesto",
    SECTION_REGISTRATION_COMPANY_TYPE: "Tipo de empresa",

    IMAGE_OVERLAY_INFO_TITLE_LOCATION: "Localisation",
    IMAGE_OVERLAY_INFO_TITLE_ALTITUDE: "Altitud",
    IMAGE_OVERLAY_INFO_TITLE_LONGITUDE: "Longitud",
    IMAGE_OVERLAY_INFO_TITLE_LATITUDE: "Latitud",
    SIGNUP_NEW_TEXTS_WITH_MARKETING_CONSENT:
        "Al suscribirse a Sablono, acepta nuestro <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a> final y nuestra <a href='https://www.sablono.com/privacy' target='_blank'>Política de datos y privacidad,</a> incluida la transferencia a los denominados terceros países. Sablono le proporcionará información relevante sobre nuestros productos. Puede darse de baja de estas comunicaciones en cualquier momento.",
    SIGNUP_NEW_TEXTS_WITHOUT_MARKETING_CONSENT:
        "Al suscribirse a Sablono, acepta nuestro <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_ENG.pdf' target='_blank'>End User License Agreement (EULA)</a> final y nuestra <a href='https://www.sablono.com/privacy' target='_blank'>Política de datos y privacidad,</a> incluida la transferencia a los denominados terceros países.",
    ACTION_ADD_COMMENT: "Añadir comentarios y fotos",
    ADD_NOTE_COMMENT_DIALOG_TITLE: "Añadir comentarios y fotos a la nota",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TITLE: "Informe de estado de cuestiones",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TEXT:
        "¿Quieres añadir un comentario o una foto mientras cambias el estado de esta Nota?",
    ADD_NOTE_COMMENT_DESCRIPTION_TITLE:
        "Introduzca un texto que describa el comentario de la nota",

    RESET_ACTIVITY_TITLE: "Reiniciar actividad",
    RESET_ACTIVITY_CONFIRMATION: "Para continuar, introduzca la palabra RESET",
    RESET_ACTIVITY_ACTION: "Borrar todos los datos de progreso y calidad",
    REQUEST_ACTIVITY_UPDATE:
        "Solicitar a un compañero que actualice los progresos",
    REQUEST_ACTIVITY_UPDATE_EMAIL_SUBJECT:
        "{{projectName}} - Por favor, actualice el estado de la actividad adjunta en Sablono",
    REQUEST_ACTIVITY_UPDATE_EMAIL_BODY:
        "Hola! \n\n Por favor, actualice el estado de la siguiente actividad en Sablono por mí: \n * {{deliverableCode}} - {{activityName}} \n * https://app.sablono.com/{{deliverableUrl}} \n\n Puede hacer clic en el enlace para abrir la actividad en su aplicación móvil Sablono o iniciar sesión en la web para actualizarla allí. \n\n Gracias \n {{userName}}",
    RESET_ACTIVITY_DESCRIPTION:
        '¿Realmente quieres restablecer esta actividad <b>a "no iniciada"</b>?\n\nSi lo hace, se eliminarán los informes de progreso, las fechas reales, las notas conectadas y las listas de control de calidad. Esto sólo puede deshacerse con el soporte de Sablono.',
    BULK_RESET_ACTIVITIES_TITLE: "Restablecer las actividades a no iniciadas",
    BULK_RESET_ACTIVITIES_DESCRIPTION:
        '¿Realmente desea restablecer las actividades seleccionadas <b>a "no iniciadas"</b>?\n\nSi lo hace, se eliminarán los informes de progreso, las fechas reales, las notas conectadas y las listas de control de calidad. Esto sólo puede deshacerse con el soporte de Sablono.',
    BULK_RESET_ACTIVITIES_HEADER:
        "Seleccione las actividades de los entregables previamente seleccionados que desea restablecer. Esto incluirá los informes de progreso, las fechas reales, las notas conectadas y las listas de control de calidad.",
    BULK_RESET_ACTIVITIES_SUBMIT: "Restablecer actividades",
    BULK_RESET_ACTIVITIES_TOAST:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Reinicio de actividades",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TITLE:
        "Exportar sus Notas a un informe PDF",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TEXT:
        "Una vez que hayamos creado su informe de notas en PDF, se lo enviaremos por correo electrónico a su {{email}}. Por favor, tenga en cuenta que esto puede tomar algún tiempo dependiendo del número de fotos que se incluyen en el mismo.",
    SEND_REPORT_TO_ADDITIONAL_EMAILS:
        "Comparta los PDF de la lista de control de calidad con sus colegas, incluidos los comentarios",

    INITIAL_ASSIGNMENT_OF_MULTIPLE_COMMERCIAL_MANAGERS_ERROR:
        "Sólo se le permite añadir el primer Gestor Comercial a este equipo, después ellos gestionarán el acceso.",
    PROJECT_CURRENCY_HELP_TOOLTIP_MESSAGE:
        "La divisa del proyecto se mostrará junto a todos los valores comerciales, por ejemplo, en el Panel de control de Commercial Pro o en el Rastreador de actividades.",
    PDF_EXCEEDS_LIMIT:
        "El PDF que ha intentado cargar supera los 50 MB. Cargue una versión más pequeña.",
};

module.exports = translation;
