import angular from "angular";
import addTemplateHtml from "./addTemplate.html";
import "common/services/services";

export default angular
    .module("sbApp.sbAddTemplateDialog", [])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("addTemplate", {
            methods: [
                "ariaLabel",
                "actions",
                "autoClose",
                "clickOutsideToClose",
                "escapeToClose",
            ],
            options: [
                "$mdDialog",
                function selectDialog($mdDialog) {
                    return {
                        templateUrl: addTemplateHtml,
                        controller: [
                            "$scope",
                            function mdDialogCtrl($scope) {
                                $scope.template = {
                                    CATEGORY: "GROUP",
                                };

                                function _closeWithData() {
                                    $mdDialog.hide($scope.template);
                                }

                                $scope.hide = _closeWithData;

                                $scope.abort = function () {
                                    $mdDialog.cancel();
                                };

                                $scope.resolve = _closeWithData;

                                $scope.submit = _closeWithData;

                                $scope.edit = function (
                                    editedObject,
                                    formObject
                                ) {
                                    $scope.isEdited =
                                        formObject.$valid && formObject.$dirty;
                                };
                            },
                        ],

                        controllerAs: "dialog",
                        bindToController: true,
                    };
                },
            ],
        });
    });
