/**
 *
 * A service module used to transform excel data to a preview model needed
 * by tables to display the component and structure information.
 *
 * WARNING: We are expecting that the excel file has
 *   # the data in the first sheet
 *   # one header line
 *   # well formatted data
 *
 *   So, many things can go wrong, but we are not handling that! Keep in mind.
 *
 */
import _ from "lodash";
import { isMoment } from "moment";
import * as XLSX from "xlsx";

export default function parseExcelService() {
    var excelService = {
        readSheet: readSheet,
        loadFile: loadFile,
        sanitizeField: sanitizeField,
        sanitizeAndFormatDate: sanitizeAndFormatDate,
        COLUMN: "COLUMN",
        ROW: "ROW",
    };

    return excelService;

    /**
     * Transform a workbook
     *
     * @param {Object} oWorkbook - XLSX.read result
     * @param {string} direction  "ROW" or "COLUMN" arranged data arrays.
     */
    function readSheet(oWorkbook, direction) {
        // if file contains at least on sheet extract information from first one
        //
        if (oWorkbook.SheetNames.length >= 1) {
            var sheetAsJSON = XLSX.utils.sheet_to_json(
                oWorkbook.Sheets[oWorkbook.SheetNames[0]],
                {
                    header: "A",
                }
            );

            // then transform to desired format
            //
            return {
                source: readSource(oWorkbook.Props),
                headers: readHeaders(sheetAsJSON),
                data: readData(sheetAsJSON, direction),
            };
        } else {
            // throw error if workbook is empty
            //
            throw new Error("This workbook has no sheets.");
        }
    }

    /**
     * Read the file/data as workbook
     *
     * @param data
     * @param type
     * @returns {*}
     */
    function loadFile(data, type) {
        return XLSX.read(data, {
            type: type || "binary",
            cellDates: true,
        });
    }

    /**
     *  create a sablono domain source object from the workbook properties
     *
     * @param {object} properties Workbook properties
     * @param {string} properties.Author
     * @param {string} properties.CreatedDate
     * @param {string} properties.ModifiedDate
     * @returns {object}
     */
    function readSource(properties) {
        return {
            type: "EXCEL",
            data: {
                author: properties.Author,
                creationDate: new Date(properties.CreatedDate),
                modifiedDate: new Date(properties.ModifiedDate),
            },
        };
    }

    /**
     * The first row is expected to contain the headers
     *
     * @param {array} sheetAsJSON
     * @returns {Array}
     */
    function readHeaders(sheetAsJSON) {
        var oHeader = sheetAsJSON[0];
        var aKeys = Object.keys(oHeader);

        return aKeys.map(function (sKey) {
            return oHeader[sKey];
        });
    }

    /**
     * Transforms the given excel data into an object containing metadata array with header information
     * and data array with multiple array containing information row- or column-wise. This structure of
     * the data array depends on the sFormat parameter. Possible values are "ROW" or "COLUMN".
     *
     * @private
     *
     * @param {Array} sheetAsJSON Content of the Excel File
     * @param {string} format "ROW" or "COLUMN"
     */
    function readData(sheetAsJSON, format) {
        // remove the first row and use it as header keys
        var aKeys = Object.keys(sheetAsJSON.shift());

        if (format === excelService.COLUMN) {
            return _readDataByColumn(sheetAsJSON, aKeys);
        }
        if (format === excelService.ROW) {
            return _readDataByRow(sheetAsJSON, aKeys);
        }
        throw new Error(
            "MissingRequiredParameter: format - has to be one of: " +
                excelService.COLUMN +
                " or " +
                excelService.ROW
        );
    }

    /**
     * Trims empty spaces from the strings' both ends. Returns *undefined* if
     * it becomes empty. If the input is not a *string*, then returns it directly.
     * @param {*} input
     */
    function sanitizeField(input) {
        if (_.isString(input)) {
            const trimmed = input?.trim();
            return trimmed?.length > 0 ? trimmed : undefined;
        } else {
            return input;
        }
    }

    function sanitizeAndFormatDate(input) {
        return isMoment(input) ? input.toISOString() : undefined;
    }

    function _readDataByColumn(sheetAsJSON, aKeys) {
        return aKeys.map(function (sKey) {
            return sheetAsJSON.map(function (oRow) {
                return oRow[sKey];
            });
        });
    }

    function _readDataByRow(sheetAsJSON, aKeys) {
        return sheetAsJSON.map(function (oRow) {
            return aKeys.map(function (sKey) {
                return oRow[sKey];
            });
        });
    }
}
