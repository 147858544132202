/* eslint-disable no-unreachable */
import _ from "lodash";
import moment from "moment";
import Leanboard from "../model/leanboard.class";
import LeanboardColumn from "../model/leanboard_column.class";

export default function (
    SbTeam,
    $sbCalendar,
    $sbDates,
    $sbActivities,
    $log,
    WEEK_BOARD_TIMESPAN_PER_COLUMN
) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    var service = {
        getTimeFrameOf: Leanboard.getTimeFrameOf,
        getWeekOfTimeFrame: getWeekOfTimeFrame,
        collectNonWorkingDays: collectNonWorkingDays,
        getBoard: getBoard,
        calcMovedDatesToStartDate: calcMovedDatesToStartDate,
        calcMovedDatesToEndDate: calcMovedDatesToEndDate,
        calcWorkingDuration: calcWorkingDuration,
        getDefaultTimeFrame: getDefaultTimeFrame,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function getWeekOfTimeFrame(timeframe) {
        return timeframe.getWeek();
    }

    function getDefaultTimeFrame() {
        // three weeks
        //
        const fromMoment = moment()
            .isoWeekday(1)
            .startOf(WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY);
        const toMoment = moment(fromMoment)
            .add(20, WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY)
            .endOf(WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY);
        return Leanboard.getTimeFrameOf(fromMoment, toMoment);
    }

    /**
     * Create and return the leanboard instance
     *
     * @param {LeanboardTimeframe} timeframe
     * @param {Array} deliverables
     * @param {'day' | 'week'} columnTimespan
     *
     * @returns {Leanboard}
     */
    function getBoard(timeframe, deliverables, columnTimespan) {
        const leanboard = new Leanboard(timeframe);
        const timeRange = [
            timeframe.getStartInMilliseconds(),
            timeframe.getEndInMilliseconds(),
        ];

        deliverables.forEach(function (deliverable) {
            const lane = Leanboard.createLaneForBoard(leanboard, deliverable);
            lane.getSlotOccupancy(
                columnTimespan,
                function occupancyStrategy(activity, columns, index) {
                    //
                    //  warn: the activities have to be ordered by start date otherwise you can get slot overwrites.
                    //

                    if (
                        !$sbActivities.isActivityInTimeRange(
                            activity,
                            timeRange
                        )
                    ) {
                        return;
                    }

                    // Array of moment objects representing the activity columnTimespanUnits
                    // ex. For an activity that needs 5 Work days that are all included in the timeframe
                    // DAY-VIEW
                    // would return an array with length 5. (One entry for each day )
                    // WEEK_VIEW
                    // would return an array with length 1. (One entry corresponding to the week )
                    const columnTimespanUnitsOfActivityInTimeFrame =
                        $sbDates.getIntersectingDaysBetweenTimeRanges(
                            [activity.startDate, activity.endDate],
                            timeRange,
                            columnTimespan
                        );

                    // formats the activity dates to the same format as the dates on the columns
                    const formattedActivityColumnTimespanUnits =
                        this.getColumnIdentifiersFor(
                            columnTimespanUnitsOfActivityInTimeFrame
                        );

                    // find the index of the column where the activity starts
                    //
                    const activityStartColumnTimespanUnit =
                        formattedActivityColumnTimespanUnits[0];

                    let columnIndexWhereActivityStarts;

                    if (columnTimespan === WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY) {
                        columnIndexWhereActivityStarts =
                            dayViewGetColumnIndexWhereActivityStartsStrategy.apply(
                                this,
                                [columns, activityStartColumnTimespanUnit]
                            );
                    } else {
                        columnIndexWhereActivityStarts =
                            weekViewGetColumnIndexWhereActivityStartsStrategy.apply(
                                this,
                                [columns, activityStartColumnTimespanUnit]
                            );
                    }

                    if (columnIndexWhereActivityStarts < 0) {
                        return;
                    }

                    // Find the next empty slot inside the column cell
                    // slots refer to the "lanes" each activity can be displayed in a column cell
                    const unoccupiedSlotIndex = columns[
                        columnIndexWhereActivityStarts
                    ].slots.indexOf(LeanboardColumn.BLANK_SLOT);

                    // place item
                    //
                    for (
                        let j = 0;
                        j < columnTimespanUnitsOfActivityInTimeFrame.length;
                        j++
                    ) {
                        // activity overflows?
                        if (
                            columnIndexWhereActivityStarts + j >=
                            columns.length
                        ) {
                            break;
                        }

                        // slot not empty? This shouldn't happen
                        if (
                            columns[columnIndexWhereActivityStarts + j].slots[
                                unoccupiedSlotIndex
                            ] !== LeanboardColumn.BLANK_SLOT
                        ) {
                            $log.warn("The slot is already occupied!");
                        }

                        // all good? insert activity in slot
                        columns[columnIndexWhereActivityStarts + j].slots[
                            unoccupiedSlotIndex
                        ] = index;
                    }
                }
            );
        });

        return leanboard;
    }

    function dayViewGetColumnIndexWhereActivityStartsStrategy(
        columns,
        activityStartColumnTimespanUnit
    ) {
        return _.findIndex(columns, ["id", activityStartColumnTimespanUnit]);
    }

    function weekViewGetColumnIndexWhereActivityStartsStrategy(
        columns,
        activityStartColumnTimespanUnit
    ) {
        const daysImpliedByWeek = [];
        for (let i = 0; i < columns.length; i++) {
            // Turns column represented by one date in a week to a whole week
            const startOfWeek = moment(columns[i].id).startOf(
                WEEK_BOARD_TIMESPAN_PER_COLUMN.WEEK
            );
            const endOfWeek = moment(columns[i].id).endOf(
                WEEK_BOARD_TIMESPAN_PER_COLUMN.WEEK
            );
            let day = startOfWeek;

            while (day <= endOfWeek) {
                daysImpliedByWeek.push(day);
                day = day.clone().add(1, "d");
            }
        }

        const formattedDaysImpliedByWeek =
            this.getColumnIdentifiersFor(daysImpliedByWeek);
        const formattedDaysGroupedInWeeks = _.chunk(
            formattedDaysImpliedByWeek,
            7
        );

        return formattedDaysGroupedInWeeks.reduce((result, week, index) => {
            // If an activity date belongs in a week
            if (_.indexOf(week, activityStartColumnTimespanUnit) !== -1) {
                // return which week it belongs to
                return index;
            }
            return result;
        }, -1);
    }

    function collectNonWorkingDays(calendar) {
        const nonWorkingDays = [];
        calendar.DAYS.forEach(function (day, index) {
            // 0 is 7 in the calendar representation
            if (!day) nonWorkingDays.push(index || 7);
        });
        return nonWorkingDays;
    }

    /**
     * Calculate the start and end date for an activity given a duration and a startDate
     * based on the supplied calendar.
     *
     * @param {number} duration - in working days
     * @param {Moment} newStartDate
     * @param {SbCalendar} calendar
     * @returns {{start: Moment, end: Moment}}
     */
    function calcMovedDatesToStartDate(duration, newStartDate, calendar) {
        const consumableCalendar = _createConsumableCalendar(calendar);
        const beginningOfShift =
            consumableCalendar.workingCalendar.updateTimeToMatchStartOfDay(
                newStartDate
            );
        const usedDuration = _.isNumber(duration) ? duration : 1; // fallback to one

        return {
            start: beginningOfShift,
            end: consumableCalendar.findEarliestEndForTask(
                beginningOfShift,
                usedDuration,
                "wd"
            ),
        };
    }

    function calcWorkingDuration(start, end, calendar) {
        const consumableCalendar = _createConsumableCalendar(calendar);
        const startOfShiftDay =
            consumableCalendar.workingCalendar.updateTimeToMatchStartOfDay(
                start
            );
        const endOfShiftDay =
            consumableCalendar.workingCalendar.updateTimeToMatchEndOfDay(end);

        if (endOfShiftDay.isBefore(startOfShiftDay)) {
            return {
                start: startOfShiftDay,
                end: endOfShiftDay,
                duration: -1,
            };
        }

        const diffInWorkingHours =
            consumableCalendar.getTotalWorkingTimeBetween(
                startOfShiftDay,
                endOfShiftDay
            );

        const diffInWorkingDays =
            consumableCalendar.mapDurationToNextLargerUnit(
                diffInWorkingHours,
                "wh"
            );

        return {
            start: startOfShiftDay,
            end: endOfShiftDay,
            duration: diffInWorkingDays,
        };
    }

    /**
     * Calculate the start and end date for an activity given a duration and a endDate
     * based on the supplied calendar.
     *
     * @param {number} duration - in working days
     * @param {Moment} newEndDate
     * @param {SbCalendar} calendar
     * @returns {{start: Moment, end: Moment}}
     */
    function calcMovedDatesToEndDate(duration, newEndDate, calendar) {
        const consumableCalendar = _createConsumableCalendar(calendar);
        const endOfShiftDay =
            consumableCalendar.workingCalendar.updateTimeToMatchEndOfDay(
                newEndDate
            );
        const usedDuration = _.isInteger(duration) ? duration : 1; // fallback to one

        return {
            start: consumableCalendar.findLatestStartForTask(
                endOfShiftDay,
                usedDuration,
                "wd"
            ),
            end: endOfShiftDay,
        };
    }

    /**
     * Create a consumable calendar instance from a project calendar class
     *
     * @param {SbCalendar} calendar
     * @param {number[]} calendar.DAYS
     * @param {Array<Array<string>>} calendar.BLOCKS
     * @param {WorkFreeCalendar} calendar.exceptionDates
     *
     * @return {ConsumableCalendar}
     * @private
     */
    function _createConsumableCalendar(calendar) {
        return $sbCalendar.ConsumableCalendar.createFrom({
            days: calendar.DAYS,
            blocks: calendar.BLOCKS,
            exceptionDates: calendar.exceptionDates,
        });
    }
}
