import PresentableError from "./PresentableError";

var errors = [
    {
        test: ["301 - unique constraint violated"],
        code: "HANA_UNIQUE_CONSTRAINT_VIOLATED",
        title: "ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_TITLE",
        message: "ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_MESSAGE",
    },
    {
        test: [
            "258 - insufficient privilege",
            "NR: 258, ERROR: insufficient privilege",
            "[258] insufficient privilege",
        ],
        code: "HANA_INSUFFICIENT_PRIVILEGE",
        title: "ERROR_HANA_INSUFFICIENT_PRIVILEGE_TITLE",
        message: "ERROR_HANA_INSUFFICIENT_PRIVILEGE_MESSAGE",
    },
    {
        test: ["274 - inserted value too large for column"],
        code: "HANA_VALUE_TO_LARGE",
        title: "ERROR_HANA_VALUE_TO_LARGE_TITLE",
        message: "ERROR_HANA_VALUE_TO_LARGE_MESSAGE",
    },
];

/**
 * Handles DB Native Errors like unique constraint violated, insufficient privileges error, etc..
 *
 * @constructor
 * @extends PresentableError
 *
 * @param {XMLHttpRequest} request - Used XMLHttpRequest object
 */
function DataBaseNativeError(request) {
    var correctErrorKey = errors.filter(function (errorDesc) {
        return DataBaseNativeError.doTest(request.responseText, errorDesc);
    });

    if (correctErrorKey.length !== 1) {
        throw "Not a DataBaseNativeError!";
    }

    this.code = correctErrorKey[0].code;
    this.title = correctErrorKey[0].title;
    this.message = correctErrorKey[0].message;
    this.request = request;
}

DataBaseNativeError.prototype = Object.create(PresentableError.prototype);

/**
 * check if the request response is a native DB error
 *
 * @param {XHRRequest} xhrRequest - The request that should be handled
 *
 * @returns {boolean} - true if it is a DB Native Error, false otherwise
 */
DataBaseNativeError.check = function (xhrRequest) {
    return errors.some(function (errorDesc) {
        return DataBaseNativeError.doTest(xhrRequest.responseText, errorDesc);
    });
};

DataBaseNativeError.doTest = function (value, errorDesc) {
    return errorDesc.test.some(function (textValue) {
        return value.indexOf(textValue) > -1;
    });
}; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ DataBaseNativeError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.TOAST;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ DataBaseNativeError.prototype.shouldAutoClose = function () {
    return true;
};

export default DataBaseNativeError;
