import angular from "angular";
import dialogTemplate from "./multi_selection_list.tmpl.html";

export default angular
    .module("sbApp.sbMultiSelectionListDialog", [])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("multiSelectionList", {
            methods: [
                "title",
                "text",
                "ok",
                "cancel",
                "class",
                "ariaLabel",

                // custom attributes
                //
                "list",
                "listDisplayKeys",
            ],
            options: function multiSelectionListDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: multiSelectionListDialogCtrl,
                    controllerAs: "$ctrl",
                    bindToController: true,
                };

                function multiSelectionListDialogCtrl($mdDialog) {
                    "ngInject";
                    var vm = this;
                    vm.selectedItems = [];
                    vm.closeDialog = closeDialog;
                    vm.resolve = resolve;

                    function resolve(selection) {
                        $mdDialog.hide(selection);
                    }

                    function closeDialog() {
                        $mdDialog.cancel();
                    }
                } // end of ctrl
            },
        });
    });
