import angular from "angular";
import _ from "lodash";
import html from "./filter_menu.html";

export default angular
    .module("sbApp.common.components.filterMenu", [])
    .component("sbFilterMenu", {
        templateUrl: html,
        transclude: true,
        bindings: {
            displayVertically: "<",
            onToggleAreaManagerFilter: "&",
            onViewModelSetUpComplete: "&",
        },
        controller: function filterMenuCtrl(
            $sbUserSettings,
            $sbMembership,
            $rootScope,
            EVENTS
        ) {
            "ngInject";
            var vm = this;

            /////////////////////
            //
            //      WATCHER
            //
            /////////////////////

            var areaManagerChangedListener = $rootScope.$on(
                EVENTS.AREA_MANAGER_CHANGED,
                _reloadSettings
            );

            /////////////////////
            //
            //      Lifecycle Hooks
            //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
            //
            /////////////////////

            vm.$onInit = function () {
                $sbMembership.get().then(_initFilterMenuSettings);
            };

            vm.$onDestroy = function () {
                areaManagerChangedListener();
            };

            /////////////////////
            //
            //      IMPL
            //
            /////////////////////

            function _reloadSettings() {
                $sbMembership.reloadCurrent().then(_initFilterMenuSettings);
            }

            vm.setAreaManagerFilter = function () {
                $sbUserSettings.saveAreaManagerFilter(
                    vm.areaManagerFilterIsActive
                );
                vm.onToggleAreaManagerFilter({
                    isActive: vm.areaManagerFilterIsActive,
                    hasAreaManagerRestrictions: vm.hasAreaManagerRestrictions,
                    userName: vm.userName,
                });
            };

            function _initFilterMenuSettings(membership) {
                vm.hasAreaManagerRestrictions =
                    membership.hasAreaManagerRestrictions;
                vm.userName = membership.user.userName;

                if (vm.hasAreaManagerRestrictions) {
                    vm.areaManagerFilterIsActive =
                        $sbUserSettings.getAreaManagerFilter();
                } else {
                    vm.areaManagerFilterIsActive = false;
                    $sbUserSettings.clearAreaManagerFilter();
                }

                return _indicateSetUpIsComplete();
            }

            function _indicateSetUpIsComplete() {
                if (vm.onViewModelSetUpComplete) {
                    vm.onViewModelSetUpComplete({
                        isActive: vm.areaManagerFilterIsActive,
                        hasAreaManagerRestrictions:
                            vm.hasAreaManagerRestrictions,
                        userName: vm.userName,
                    });
                } else {
                    throw new Error(
                        "Missing 'onViewModelSetUpComplete' callback for sbFilterMenu component"
                    );
                }
            }
        },
    });
