import angular from "angular";
import templateHtml from "./commercial_membership_edit.html";

export default {
    name: "sbCommercialMembershipEdit",
    templateUrl: templateHtml,
    bindings: {
        userSelection: "<",
        suggestUsers: "&",
        onMemberAdded: "&",
        onMemberRemoved: "&",
        canRemoveMember: "<",
    },
    controllerAs: "$ctrl",
    controller: angular.noop,
};
