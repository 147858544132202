import _ from "lodash";

/**
 *
 * Idea: Store files and larger binary data in a runtime store. Then you can access these file from different
 *  states and times. Just transport the key. Also we can properly drop the file if needed.
 *
 * You store it and get a key for access back.
 *
 */
export default function ($log, $window, $document) {
    "ngInject";
    var listStore = [];
    var typeStore = [];

    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        store: store,
        get: getRuntimeObject,
        getType: getType,
        download: download,
        remove: remove,
        downloadBlob: downloadBlob,
        downloadFrom: downloadFrom,
        getFilenameFromContentDispositionHeader,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function downloadFrom(xhr, callback) {
        const blob = xhr.response;
        let filename = getFilenameFromContentDispositionHeader(xhr);

        if (_.isFunction(callback)) {
            filename = callback(filename);
        }

        downloadBlob(blob, filename);
    }

    function downloadBlob(blob, fileName) {
        if (_.isFunction(fileName)) {
            fileName = fileName();
        }

        // If we have option for save or open blob use the following method
        //
        if ($window.navigator.msSaveOrOpenBlob) {
            $window.navigator.msSaveBlob(blob, fileName);
        } else {
            //Create an href element
            var elem = $window.document.createElement("a");

            //link element href with object url of blob
            elem.href = $window.URL.createObjectURL(blob);

            //Set download attribute to setup the name
            elem.download = fileName;

            //Append element to body
            $document[0].body.appendChild(elem);

            //Click the element for download to start
            elem.click();

            //Remove after download is initiated
            $document[0].body.removeChild(elem);
        }
    }

    /**
     * Store something - most likely a huge string or a XML Document in a runtime store
     *
     * @param {Object} something
     * @param {String} [type]
     * @returns {Number} your key to access the thing later.
     */
    function store(something, type) {
        var key = listStore.push(something);
        typeStore.push(type);

        return key;
    }

    /**
     * Get the data object of the file.
     *
     * @param {number} key - the key you got from store() call
     */
    function getRuntimeObject(key) {
        return listStore[key - 1];
    }

    /**
     * Get the type of the file of the given key.
     *
     * @param {Number} key
     * @returns {String|undefined}
     */
    function getType(key) {
        return typeStore[key - 1];
    }

    /**
     * Start a download of the file stored on the key. Save it with the given fileName.
     *
     * @param {Number} key
     * @param {String} fileName
     */
    function download(key, fileName) {
        var data = getRuntimeObject(key);
        var type = getType(key);

        if (!data || !type) {
            $log.warn("download not possible.");
            return;
        }

        // Create a blob object with the data and the type
        //
        var blob = new Blob([data], {
            type: type,
        });

        downloadBlob(blob, fileName);
    }

    /**
     * Clear the store.
     *
     * @param {number} key - the key you got from store() call
     */
    function remove(key) {
        listStore[key - 1] = undefined;
    }

    function getFilenameFromContentDispositionHeader(xhr) {
        const disposition = xhr.getResponseHeader("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[1]) {
                return decodeURIComponent(matches[1].replace(/['"]/g, ""));
            }
        }
        return decodeURI(xhr.getResponseHeader("x-sbln-original-filename"));
    }
}
