import _ from "lodash";

/**
 * @typedef LeanboardColumn
 * @type {object}
 * @property {Array.<string|number>} slots - Slots.
 */

/**
 * Create the initial data model for a leanboard column.
 *
 * @param {number} number
 * @param {string} id
 *
 * @constructor
 */
function LeanboardColumn(number, id) {
    this.id = id;
    this.slots = _.times(number, _.constant(LeanboardColumn.BLANK_SLOT));
}

LeanboardColumn.BLANK_SLOT = "blank";

export default LeanboardColumn;
