import angular from "angular";
import _ from "lodash";
import moment from "moment";
import ComponentUploadModel from "./../../plainModels/ComponentUploadModel";
import ComponentCreationResultDAO from "./../../plainModels/ComponentCreationResultDAO";

export default function (
    $q,
    $state,
    $log,
    $sbAssociate,
    $sbTemplate,
    $sbStructure,
    $sbProject,
    $sbPullUpdate,
    $sbColor,
    $sbDates,
    $sbDeliverablesApi,
    $sbDeliverableJobsApi,
    $sbReportingDeliverablesApi,
    $sbReassociate,
    $sbJobApi
) {
    "ngInject";

    return {
        // CRUD for lists of deliverables (root components)
        countDeliverablesOfProject: countDeliverablesOfProject,
        uploadComponents: syncComponents,

        updateComponents: updateComponents,
        updateComponentsDate: updateComponentsDate,
        updateComponentsStructure: updateComponentsStructure,
        updateComponentsTemplate: updateComponentsTemplate,
        updateComponentsAreaManager: updateComponentsAreaManager,
        updateDeliverablesType: updateDeliverablesType,

        // util
        createComponentUploadModel: createComponentUploadModel,
    };

    function countDeliverablesOfProject(projectId) {
        return $sbReportingDeliverablesApi
            .getCollection(projectId, {
                select: "ID",
                top: 1,
            })
            .then(({ meta }) => meta.count_all);
    }

    function updateComponentsAreaManager(deliverables, projectId, userId) {
        return updateComponents(deliverables, projectId, {
            area_manager_user_id: userId,
        });
    }

    function updateComponentsDate(deliverables, projectId, startDate, endDate) {
        return updateComponents(deliverables, projectId, {
            baseline_schedule: {
                constraints: {
                    start_date: startDate,
                    end_date: endDate,
                },
            },
        });
    }

    function updateComponentsStructure(deliverables, projectId, structureId) {
        return updateComponents(deliverables, projectId, {
            structure_id: structureId,
        });
    }

    /**
     * Batch update the type on the set of deliverables inside the project.
     *
     * @param deliverables {string|object[]}
     * @param projectId {string}
     * @param type {string}
     * @returns {Promise<unknown>}
     */
    function updateDeliverablesType(deliverables, projectId, type) {
        if (!angular.isString(type)) {
            return $q.reject(
                new TypeError(
                    'IllegalArgumentException: The argument "type" is not a "string".'
                )
            );
        }

        return _updateComponentsPropertiesChange(deliverables, projectId, {
            type_name: type,
        });
    }

    /**
     * Update the template of the deliverables.
     *
     * @param {Object[]|string} deliverables
     * @param {string} projectId
     * @param {string} templateId
     * @param {object} [options]
     * @param {boolean} options.isForceOverwrite
     * @returns {Promise|*}
     */
    function updateComponentsTemplate(
        deliverables,
        projectId,
        templateId,
        options
    ) {
        return updateComponents(
            deliverables,
            projectId,
            {
                TEMPLATE_ID: templateId,
            },
            options
        );
    }

    /**
     *
     * @param {Object[]|string} deliverables
     * @param {string} projectId
     * @param {Object} properties
     * @param {string} properties.TEMPLATE_ID
     * @param {object} [options] optional options object
     * @param {boolean} options.isForceOverwrite
     *
     */
    function updateComponents(deliverables, projectId, properties, options) {
        if (properties.TEMPLATE_ID) {
            return _updateComponentsTemplateChange(
                deliverables,
                properties.TEMPLATE_ID,
                options
            );
        } else {
            return _updateComponentsPropertiesChange(
                deliverables,
                projectId,
                properties
            );
        }
    }

    function _updateComponentsPropertiesChange(
        deliverables,
        projectId,
        properties
    ) {
        if (angular.isArray(deliverables)) {
            const deliverable_set = deliverables.map(function (deliverable) {
                return deliverable.id;
            });
            return $sbDeliverableJobsApi
                .createModifyPropertiesJob(projectId, {
                    deliverable_set,
                    properties,
                })
                .then(({ response, jobId }) => {
                    if (jobId) {
                        // Wait until the job is done
                        return $sbJobApi.waitFor(jobId).then(() => response);
                    }
                    return response;
                });
        }

        if (angular.isString(deliverables)) {
            return $sbDeliverableJobsApi
                .createModifyPropertiesJob(projectId, {
                    deliverable_set: deliverables,
                    properties,
                })
                .then(({ response, jobId }) => {
                    if (jobId) {
                        // Wait until the job is done
                        return $sbJobApi.waitFor(jobId).then(() => response);
                    }
                    return response;
                });
        }

        return $q.reject(
            new Error(deliverables + " has to be an array or string.")
        );
    }

    /**
     *
     * @param {Object[]|string} deliverables
     * @param {string} processTemplateId
     * @param {object} options
     * @param {boolean} options.isForceOverwrite
     *
     * @returns {Promise}
     * @private
     */
    function _updateComponentsTemplateChange(
        deliverables,
        processTemplateId,
        options = { isForceOverwrite: false }
    ) {
        if (Array.isArray(deliverables)) {
            deliverables = deliverables.map((deliveralbe) => {
                return {
                    ID: deliveralbe.id,
                    PI_TEMPLATE_ID: deliveralbe.piTemplateId,
                    TEMPLATE_REVISION: deliveralbe.templateRevision,
                };
            });
        }

        const requests = {
            pullUpdate: $sbPullUpdate.forDeliverables(
                processTemplateId,
                deliverables
            ),
            associate: $sbAssociate.forDeliverables(
                processTemplateId,
                deliverables
            ),
        };

        requests.reassociate = $sbReassociate.forDeliverables(
            processTemplateId,
            deliverables,
            options.isForceOverwrite
        );

        return $q.all(requests);
    }

    /**
     * Send data to component bulk load
     *
     * @param {ComponentUploadModel} data - the model to be uploaded
     * @return {Promise} the promise with the upload result
     */
    function syncComponents(data) {
        if (data instanceof ComponentUploadModel) {
            const {
                projectId,
                source,
                edges,
                deliverables,
                structure,
                sourceKey,
                isDeletionBlocked,
                isBringBackBlocked,
            } = data.dataForUpload();

            if (sourceKey) {
                return $sbDeliverableJobsApi
                    .createReimportJob(projectId, {
                        source_id: sourceKey,
                        is_deletion_blocked: isDeletionBlocked,
                        is_bring_back_blocked: isBringBackBlocked,
                        edges,
                        deliverables,
                        structure,
                    })
                    .then(
                        ({ response }) =>
                            new ComponentCreationResultDAO(response)
                    );
            }

            return $sbDeliverableJobsApi
                .createImportJob(projectId, {
                    source,
                    edges,
                    deliverables,
                    structure,
                })
                .then(
                    ({ response }) => new ComponentCreationResultDAO(response)
                );
        }
        return $q.reject(
            new Error(
                "uploadComponents has to be called with a ComponentUploadModel"
            )
        );
    }

    function createComponentUploadModel(input) {
        var projectId = input.projectId;
        var oSource = input.source;
        var sourceKey = input.sourceKey;
        var components = input.components;
        var structureList = input.structure;

        var uploadModel = new ComponentUploadModel(projectId);

        uploadModel
            .setSourceKey(sourceKey)
            .setSourceObject(oSource)
            .setComponents(components)
            .setStructuringNodeList(structureList);

        return uploadModel;
    }
}
