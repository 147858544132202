import moment from "moment";

export default function PrintReportApi(
    $sbCurrentProject,
    downloadCenterService,
    $sbLocale,
    $sbReportingActivitiesApi,
    $sbReportingDeliverablesApi
) {
    "ngInject";

    return {
        requestDeliverableReport,
        requestActivityReport,
    };

    function requestActivityReport({ filter, filterDescription = [] } = {}) {
        return $sbReportingActivitiesApi
            .printCollection($sbCurrentProject.pick("id"), {
                filter,
                language: $sbLocale.current(),
                i18n: filterDescription
                    .filter(({ value }) => !!value)
                    .map(({ key, value }) => key + ":" + value),
            })
            .then((xhr) =>
                downloadCenterService.downloadFrom(
                    xhr,
                    (backendFilename) =>
                        backendFilename ||
                        makeFileNameByReportType("deliverables")
                )
            );
    }

    function requestDeliverableReport({ filter, filterDescription = [] } = {}) {
        return $sbReportingDeliverablesApi
            .printCollection($sbCurrentProject.pick("id"), {
                filter,
                language: $sbLocale.current(),
                as: "report",
                i18n: filterDescription
                    .filter(({ value }) => !!value)
                    .map(({ key, value }) => key + ":" + value),
            })
            .then((xhr) =>
                downloadCenterService.downloadFrom(
                    xhr,
                    (backendFilename) =>
                        backendFilename ||
                        makeFileNameByReportType("activities")
                )
            );
    }

    function makeFileNameByReportType(reportType) {
        const date = moment().format("YYYY-MM-DDTHH-mm");
        const projectCode = $sbCurrentProject.pick("code");
        return `${date}_${projectCode}_${reportType}_report`;
    }
}
