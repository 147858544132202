import SbNote from "domain/sb_note.class";
import Note from "../../../../../../domain/sb_note.class";

function ChecklistItem(id, answer, description) {
    this.id = id;
    this.answer = answer;
    this.description = description;
    this.notes = [];
}

/**
 * Get the [count] for not resolved obstructions notes
 */
ChecklistItem.prototype.getUnresolvedObstructionsCount = function () {
    return _getNotResolvedNotesCountByType(this.notes, Note.OBSTRUCTION);
};

/**
 * Get the [count] for not resolved issue quality notes
 */
ChecklistItem.prototype.getUnresolvedIssueQualityCount = function () {
    return _getNotResolvedNotesCountByType(this.notes, Note.QUALITY_ISSUE);
};

/**
 * Set the notes of the item
 *
 * @param {Array<SbNote>} notes
 */
ChecklistItem.prototype.setNotes = function (notes) {
    this.notes = notes;

    return this;
};

ChecklistItem.prototype.getQualityNotesCount = function () {
    return this._countNotesOfType(SbNote.QUALITY_ISSUE);
};

ChecklistItem.prototype.getObstructionNotesCount = function () {
    return this._countNotesOfType(SbNote.OBSTRUCTION);
};

ChecklistItem.prototype.getInfoNotesCount = function () {
    return this._countNotesOfType(SbNote.INFORMATION);
};

ChecklistItem.prototype._countNotesOfType = function (type) {
    return this.notes.reduce(function (sum, note) {
        return sum + (note.type === type ? 1 : 0);
    }, 0);
};

/**
 * Return the count of notes matches by [type] whose are yet not closed or confirmed.
 */
function _getNotResolvedNotesCountByType(notes, type) {
    return notes.filter(function (note) {
        return (
            note.type === type &&
            note.state !== Note.STATE_CLOSED &&
            note.state !== Note.STATE_CLOSED_ENUM &&
            note.state !== Note.STATE_CONFIRMED_ENUM
        );
    }).length;
}

export default ChecklistItem;
