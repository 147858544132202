import createjs from "createjs";
/**
 * Event dispatched when a Text in a Structure Node changed
 * @param {WBS.StructureNode} structureNode - Node where the Text was changed
 * @param {string} oldText - Old Text of Node
 * @param {string} newText  - New Text of Node
 * @constructs TextChangedEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function TextChangedEvent(structureNode, oldText, newText) {
    createjs.Event.call(this, "textChanged", true, true);
    this.structureNode = structureNode;
    this.oldText = oldText;
    this.newText = newText;
}

TextChangedEvent.prototype = Object.create(createjs.Event.prototype);

export default TextChangedEvent;
