/* eslint-disable angular/log, no-console, no-unused-vars */

import DynamicVirtualRepeatLoader from "./virtual_repeat_loader.class";

/**
 * deliverables class to get chuncks of data upon request
 * by virtual repeater
 *
 * @param {string} filters - Filters to be applied in $filter param for service
 * @param {Object} orderBy - Sorting to be applied in $orderby param for service
 */
function VirtualODataLoader(filter, orderBy) {
    /**
     * The OData filter query string.
     * @type {string}
     * @private
     */
    this._filter = filter;
    this._query = {
        filter: filter,
        orderBy: orderBy,
    };

    DynamicVirtualRepeatLoader.call(this);

    /**
     * Flag to bind the UI - > loading indication
     * @type {boolean}
     */
    this.isLoading = true;

    /**
     * Flag to bind the UI - > no data indication
     * @type {boolean}
     */
    this.noFilteredData = false;
}

VirtualODataLoader.prototype = Object.create(
    DynamicVirtualRepeatLoader.prototype
);

VirtualODataLoader.prototype.loadODataResource = function (from, to) {
    // Has to implemented by the instance
    console.warn("Need overwrite by instance."); // eslint-disable-line
    return null;
};

/**
 * @inheritDoc
 *
 * @param {Number} from - load data block from number
 * @param {Number} to - to number
 * @returns {$q} - the resolved OData request with the data array
 */
VirtualODataLoader.prototype.loadData = function (from, to) {
    return this.loadODataResource(this._query, from, to)
        .then((data) => {
            this.setLength(data.count);
            this.isLoading = false;
            return data.items;
        })
        .catch((e) => {
            this.error = e;
            this.isLoading = false;
            this.hasError = true;
        });
};

VirtualODataLoader.prototype.setLength = function (length) {
    this._length = length;

    if (this._length <= 0) {
        this.noFilteredData = true;
    }

    if (this.countReadyFn) {
        this.countReadyFn(this);
    }
};

/**
 * Set the isLoading flag to bind UI elements on it
 *
 * Call the VRL reload function to update the elements and the element count to
 * the new filter and sort conditions.
 */
VirtualODataLoader.prototype.reload = function () {
    // clean everything.
    this._items = [];
    this._length = 0;
    this._lastLoadedIndex = 0;
    this.noFilteredData = false;
    this.isLoading = true;

    // init the first set of elements
    //
    this._loadDataAndAddToList();
};

export default VirtualODataLoader;
