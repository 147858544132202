export default class SchedulingWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint creates a baseline scheduling job for a given project.
     *
     * @param projectId {string}    UUID
     *
     */
    create(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/schedule/baseline`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "POST",
            header: {
                "Content-Type": "application/json",
            },
        }).then((resp) => {
            const jobId = resp.getResponseHeader("x-job-id");
            return { jobId };
        });
    }
}
