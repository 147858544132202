export default class StateChangesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * Add a state change to the history of state changes for this activity
     *
     * @param activityId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.state {string}    The state of an activity
     */
    create(activityId, { state }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/activities/${activityId}/state-changes`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { state },
        });
    }
}
