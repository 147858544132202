import angular from "angular";
import TimeRangeSelectorDialogCtrl from "./time-range-selector.controler";
import dialogTemplate from "./time-range-selector.tmpl.html";

export default angular
    .module("sbApp.sbTimeRangeSelectorDialog", [])
    .controller("TimeRangeSelectorDialogCtrl", TimeRangeSelectorDialogCtrl)
    .config(function ($mdDialogProvider, $mdDateLocaleProvider) {
        "ngInject";

        $mdDateLocaleProvider.firstDayOfWeek = 1;

        $mdDialogProvider.addPreset("timeRangeSelector", {
            methods: [
                "title",
                "startDate",
                "endDate",
                "openSession",
                "isDayView",
            ],
            options: function selectDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: TimeRangeSelectorDialogCtrl,
                    controllerAs: "dialog",
                    bindToController: true,
                };
            },
        });
    });
