import html from "./fullscreen_card.tmpl.html";

export default {
    templateUrl: html,
    controllerAs: "fullscreen",
    bindings: {
        lastUpdate: "<",
        onFullscreenClick: "&",
        onRefresh: "&",
    },
    controller: angular.noop,
};
