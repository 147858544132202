import _ from "lodash";

export default function (SbProject, $sbProjectsApi, $sbImageApi, $q) {
    "ngInject";

    /**
     * Cache of Projects
     * @type {Object}
     */
    const projectCache = {};

    return {
        list,

        get,
        create,
        edit,
        erase,
        archive,
        refresh,
        fetchLogo,
    };

    function create(args) {
        return $sbProjectsApi.create(args).then((project) => {
            const sbProject = _initProject(project);
            _cacheProject(sbProject);
            return sbProject;
        });
    }

    function fetchLogo(projectId, imageId) {
        if (!Number.isInteger(imageId)) {
            return _resolveWithPlaceholderImage(projectId);
        }

        return $sbImageApi.download(projectId, imageId, {
            transformResponse: [$sbImageApi.response.transformToDataURL],
        });
    }

    function _resolveWithPlaceholderImage(projectId) {
        return $q.resolve({
            dataUrl:
                "images/background/project-card-bg_" +
                (Number.parseInt(projectId.replace(/\D/g, "")) % 5) +
                ".jpg",
        });
    }

    function list() {
        return $sbProjectsApi
            .getCollection()
            .then(({ projects }) => projects.map(_initProject))
            .then((projects) => {
                projects.forEach(_cacheProject);
                return projects;
            });
    }

    function get(id) {
        if (_isProjectCached(id)) {
            return $q.resolve(_getCachedProject(id));
        } else {
            return _getFromServer(id).then(_cacheProject);
        }
    }

    function _getFromServer(id) {
        return $sbProjectsApi.get(id).then(_initProject);
    }

    function refresh(id) {
        return _getFromServer(id).then(function (newProject) {
            projectCache[id] = _.assign(projectCache[id], newProject);
            return projectCache[id];
        });
    }

    /**
     * Edit the project
     *
     * @param  {SbProject} project - the project as it should be
     * @return {Promise<SbProject>}
     */
    function edit(project) {
        return $sbProjectsApi
            .update(project.id, project.asServerModel())
            .then(_initProject)
            .then((parsedProject) => {
                _cacheProject(parsedProject);
                return parsedProject;
            });
    }

    function erase(id) {
        return $sbProjectsApi.delete(id).then((response) => {
            _removeProjectFromCache(id);
            return response;
        });
    }

    function archive(project) {
        const updateRequestBody = project.asServerModel();
        updateRequestBody.status = "archived";
        return $sbProjectsApi
            .update(project.id, updateRequestBody)
            .then((response) => {
                _removeProjectFromCache(project.id);
                return response;
            });
    }

    function _initProject(projectServerObject) {
        return new SbProject(projectServerObject);
    }

    function _cacheProject(project) {
        projectCache[project.id] = project;
        return project;
    }

    function _removeProjectFromCache(id) {
        projectCache[id] = undefined;
    }

    function _isProjectCached(projectId) {
        return !!projectCache[projectId];
    }

    function _getCachedProject(id) {
        return projectCache[id];
    }
}
