import "moment";

//
// CONTENT TO COPY TO BACKEND STARTS HERE !
//

/**
 * @class AllDayCalendar
 *
 * A calendar in which all the 24 hours of the day can be used
 */
function AllDayCalendar() {}

/**
 * Gets the earliest start on the AllDayCalendar
 *
 * @param  {Moment} fromTime - The hour from which the calculation should start
 * @return {Moment}          - The start of the task. In this case is equal to fromTime because we don't have to check if the
 *                             shift is started because this calendar is shift independent
 */
AllDayCalendar.prototype.findEarliestStartForTask = function (fromTime) {
    return fromTime.clone();
};

/**
 * Gets the earliest end on the AllDayCalendar
 *
 * @param  {Moment}  taskStart - The actual start of the task
 * @param  {Integer}        duration  - The duration of the task
 * @return {Moment}            - The start of the task. In this case is equal to (fromTime + the duration of the task) because we don't have to check if the
 *                               shift is started because this calendar is shift independent
 */
AllDayCalendar.prototype.findEarliestEndForTask = function (
    taskStart,
    duration
) {
    return taskStart.clone().add(duration, "h");
};

/**
 * Gets the latest start on the AllDayCalendar
 *
 * @param  {Moment}  taskEnd   - The actual end of the task
 * @param  {Integer}        duration  - The duration of the task
 * @return {Moment}            - The start of the task. In this case is equal to (fromTime - the duration of the task) because we don't have to check if the
 *                               shift is started because this calendar is shift independent
 */
AllDayCalendar.prototype.findLatestStartForTask = function (taskEnd, duration) {
    return taskEnd.clone().subtract(duration, "h");
};

/**
 * Gets the latest end on the AllDayCalendar
 *
 * @param  {Moment} fromTime - The hour from which the calculation should start
 * @return {Moment}          - The end of the task. In this case is equal to fromTime because we don't have to check if the
 *                             shift is started because this calendar is shift independent
 */
AllDayCalendar.prototype.findLatestEndForTask = function (fromTime) {
    return fromTime.clone();
};

//
// CONTENT TO COPY TO BACKEND ENDS HERE !
//

export default AllDayCalendar;
