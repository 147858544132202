import moment from "moment";
import htmlTemplate from "./calendar_day.html";

export default function registerComponentTo(module) {
    module.component("sbCalendarDay", {
        templateUrl: htmlTemplate,
        require: {
            calendarDaysListCtrl: "^sbCalendarDaysList",
        },
        bindings: {
            day: "<",
        },
        controllerAs: "calendarDay",
        controller: function () {
            var vm = this;

            vm.$onChanges = $onChanges;
            vm.onDayChange = onDayChange;
            vm.onDayDelete = onDayDelete;

            function $onChanges(changes) {
                // expected input is a day with date in moment.utc()
                //  -> the date picker works on a "locale date" of type Date()
                //  -> so we do back and forth conversion between utc moment and Date
                //
                if (changes.day) {
                    var mDate = changes.day.currentValue.date;
                    vm.localeDate = new Date(
                        mDate.year(),
                        mDate.month(),
                        mDate.date()
                    );
                }
            }

            function onDayChange(dayAndLabel, dateOfPicker) {
                // transform the picked day into a UTC date with same year, month, day
                dayAndLabel.date = moment.utc({
                    year: dateOfPicker.getFullYear(),
                    month: dateOfPicker.getMonth(),
                    day: dateOfPicker.getDate(),
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    milliseconds: 0,
                });

                vm.calendarDaysListCtrl.onChange({
                    day: dayAndLabel,
                });
            }

            function onDayDelete(day) {
                vm.calendarDaysListCtrl.onDelete({
                    day: day,
                });
            }
        },
    });
}
