import _ from "lodash";
import moment from "moment";

export default class LeanBoardFacadeService {
    constructor(
        $sbLastPlanned,
        $sbLookAheadSessions,
        $sbLocale,
        $sbCurrentProject,
        $sbMembership,
        $window
    ) {
        "ngInject";

        this.$sbLastPlanned = $sbLastPlanned;
        this.$sbLookAheadSessions = $sbLookAheadSessions;
        this.$sbLocale = $sbLocale;
        this.$sbCurrentProject = $sbCurrentProject;
        this.$sbMembership = $sbMembership;
        this.$window = $window;
        this.EXPAND_STRUCTURE_KEY = "expandStructure";
    }

    getAggregatesInTimeFrame(options) {
        const openSession = this.$sbLookAheadSessions.findAndGetOpenSessionFor(
            this.$sbMembership.currentUser()
        );
        const query = this._getQueryFrom(options);

        if (openSession) {
            query.bySessionId = openSession.id;
        }
        return this.$sbLastPlanned.getDeliverablesInTimeFrame(query, options);
    }

    getPDFDeliverablesInTimeFrame(options) {
        const query = this._getQueryFrom(options);
        query.language =
            this.$sbLocale.current() || this.$sbCurrentProject.pick("language");

        return this.$sbLastPlanned.getPDFDeliverablesInTimeFrame(
            query,
            options.aggregateInto
        );
    }

    /**
     * Map input to supported query params.
     *
     * @param options {Object}
     * @param options.timeframe {LeanboardTimeframe}
     * @param options.filters {Object}
     * @param options.filters.isMyArea {boolean}
     * @param options.filters.selectedStructure {Object|undefined}
     * @param options.filters.selectedStructure.ID {string|undefined}
     * @param options.filters.selectedProcessTemplate {Object|undefined}
     * @param options.filters.searchTerm {string|undefined}
     * @param options.filters.selectedTeam {Object|undefined}
     * @param options.filters.selectedTeam.name {Object|undefined}
     * @param options.filters.selectedTeam.id {Object|undefined}
     *
     * @returns {{
     *    from: number,
     *    to: number,
     *    timezone: string,
     *    onlyMyArea: boolean,
     *    structureId: string|undefined,
     *    includeLate: boolean,
     *    processTemplateId: string|undefined,
     *    search: string|undefined,
     *    teamId: number|undefined
     * }}
     * @private
     */
    _getQueryFrom(options) {
        const query = {
            from: options.timeframe.getStartInMilliseconds(),
            to: options.timeframe.getEndInMilliseconds(),
            timezone:
                options.timeframe.getStartAsMoment().tz() || moment.tz.guess(),
            onlyMyArea: options.filters.isMyArea,
            structureId: _.get(
                options.filters.selectedStructure,
                "ID",
                undefined
            ),
            teamId: _.get(options.filters.selectedTeam, "id", undefined),
            includeLate: _.get(options, "includeLate", undefined),
            processTemplateId: _.get(
                options.filters.selectedProcessTemplate,
                "ID",
                undefined
            ),
            search: _.get(options, "filters.searchTerm", undefined),
            pageNumber: _.get(options, "paging.pageNumber"),
            pageSize: _.get(options, "paging.pageSize"),
            columnTimespan: _.get(options, "columnTimespan"),
            isShowingActuals: _.get(options, "isShowingActuals"),
        };

        const selectedTeam = _.get(options.filters, "selectedTeam");

        if (selectedTeam && selectedTeam.name !== "PROJECT_TEAM") {
            query.teamId = _.get(options.filters, "selectedTeam.id");
        }

        return query;
    }

    saveViewSettings(config) {
        const EXPAND_STRUCTURE_KEY = "expandStructure";

        this.$window.localStorage.setItem(
            EXPAND_STRUCTURE_KEY,
            angular.toJson(config)
        );
    }

    getViewSettings() {
        // Get old entry
        let entry = this.$window.localStorage.getItem(
            this.EXPAND_STRUCTURE_KEY
        );

        if (_.isString(entry)) {
            return angular.fromJson(entry);
        }
    }

    clearViewSettings() {
        this.$window.localStorage.removeItem(this.EXPAND_STRUCTURE_KEY);
    }
}
