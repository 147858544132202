import Element from "../common/Element";
/**
 * Class for Branches (Hierarchy Connection between Nodes)
 *
 * @param {Object} rootNode - Root Node of Branch
 * @param {Array} structureNodeList - Elements connected to Root Node via Branch
 * @constructs Branch
 * @extends WBS.Element
 * @memberof WBS
 */
function Branch(rootNode, structureNodeList) {
    Element.apply(this);

    this.rootNode = rootNode;
    this.structureNodeList = structureNodeList || [];
    this.createBranch();
    this.calcBranch();
}

/**
 * Setup prototypal inheritance.
 * Branch inherits from Element.
 *
 * @type {Element}
 */
Branch.prototype = Object.create(Element.prototype);

/**
 * @default
 * @type {boolean}
 */
Branch.prototype.shouldCache = false;

/**
 * Creates a new Branch
 */
Branch.prototype.createBranch = function () {
    var shape = new createjs.Shape();

    this.addChild(shape);
    this.branch = shape;
};

/**
 * Gets all y Coordinates of Structure Nodes of Branch
 * @returns {Array}
 */
Branch.prototype.getAllY = function () {
    return this.structureNodeList.map(function (node) {
        return node.y;
    });
};

/**
 * Gets all x Coordinates of Structure Nodes of Branch
 * @returns {Array}
 */
Branch.prototype.getAllX = function () {
    return this.structureNodeList.map(function (node) {
        return node.x;
    });
};

/**
 * Draws the Branch
 */
Branch.prototype.calcBranch = function () {
    var g = this.branch.graphics;
    g.clear().setStrokeStyle(4, "square").beginStroke("#E4E4E4");
};

export default Branch;
