import _ from "lodash";

export default function ProcessViewerPaginationService() {
    var _currentPage = 1;
    var _recordsPerPage = 3;
    var _maxNumPages = 1;
    var _maxNumberOfActivities = 0;

    /////////////////////
    //
    //      API
    //
    /////////////////////

    var service = {
        setRecordsPerPage: setRecordsPerPage,
        calculateMaxNumberOfPages: calculateMaxNumberOfPages,
        goPreviousPage: goPreviousPage,
        hasPreviousPage: hasPreviousPage,
        goNextPage: goNextPage,
        hasNextPage: hasNextPage,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function setRecordsPerPage(recordsPerPage) {
        _currentPage = Math.ceil(
            (_recordsPerPage * _currentPage) / recordsPerPage
        );
        _maxNumPages = Math.ceil(_maxNumberOfActivities / recordsPerPage);
        _recordsPerPage = recordsPerPage;
        return getActivitiesFrom();
    }

    function calculateMaxNumberOfPages(deliverables) {
        _maxNumberOfActivities = deliverables.reduce(function (
            maxNumber,
            deliverable
        ) {
            return Math.max(maxNumber, deliverable.getActivities().length);
        }, 0);
        _maxNumPages = Math.ceil(_maxNumberOfActivities / _recordsPerPage);
    }

    function goPreviousPage() {
        if (hasPreviousPage()) {
            _currentPage -= 1;
        }
        return getActivitiesFrom();
    }

    function hasPreviousPage() {
        return _currentPage > 1;
    }

    function goNextPage() {
        if (hasNextPage()) {
            _currentPage += 1;
        }
        return getActivitiesFrom();
    }

    function hasNextPage() {
        return _currentPage < _maxNumPages;
    }

    function getActivitiesFrom() {
        return _recordsPerPage * (_currentPage - 1);
    }
}
