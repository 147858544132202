import angular from "angular";
import EulaDialogCtrl from "./eula.controler";
import dialogTemplate from "./eula.tmpl.html";
import eulaService from "./eula.service";

export default angular
    .module("sbApp.sbEulaDialog", [])
    .controller("EulaDialogCtrl", EulaDialogCtrl)
    .factory("$sbEulaService", eulaService)
    .config(function ($mdDialogProvider) {
        "ngInject";

        $mdDialogProvider.addPreset("eula", {
            methods: [
                "eulaAcceptKey",
                "activeDeadlineToAccept",
                "shouldBlockUser",
            ],
            options: function selectDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: EulaDialogCtrl,
                    controllerAs: "dialog",
                    bindToController: true,
                    escapeToClose: false,
                    clickOutsideToClose: false,
                };
            },
        });
    });
