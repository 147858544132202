import moment from "moment";
import naturalSortFn from "common/filters/natural_sort.pojo";

/**
 * The base class for 2D drawings with common attributes.
 *
 * @param key
 * @param name
 * @constructor
 */
function Drawing(key, name) {
    this.key = key;
    this.name = name;
    this.preview = {};
    this.creator = "";
}

Drawing.prototype.setCreator = function (displayName) {
    this.creator = displayName;
};

Drawing.prototype.setPreviewImageId = function (preview) {
    const id = Number.parseInt(preview, 10);
    if (!Number.isNaN(id)) {
        this.preview.imageId = id;
    }
};

/**
 * An uploaded svg file representing a 2D drawing.
 *
 * @param key
 * @param fileName
 * @constructor
 */
function UploadedDrawing(key, fileName) {
    Drawing.call(this, key, fileName);

    this.uri = null;
    this.uploadedAt = null;
}

UploadedDrawing.prototype = Object.create(Drawing.prototype);

UploadedDrawing.prototype.setUploadDate = function (timestamp) {
    this.uploadedAt = moment(timestamp);
};

UploadedDrawing.prototype.setResourceURI = function (resourceURI) {
    this.uri = resourceURI;
};

/**
 * An virtual 2D drawing represented by a set of configurations.
 * The drawing is generated at runtime by the configuration.
 *
 * @param {Number} key
 * @param {String} name
 * @param {DrawingConfiguration} config
 *
 * @constructor
 */
function GeneratedDrawing(key, name, config) {
    Drawing.call(this, key, name);

    this.config = config;
    this.createdAt = null;
}

GeneratedDrawing.prototype = Object.create(Drawing.prototype);

GeneratedDrawing.prototype.setCreationDate = function (timestamp) {
    this.createdAt = moment(timestamp);
};

/**
 * The configuration for a generated drawing.
 *
 * @param {Number} key
 * @param {String} name
 * @param {String} deliverableProcessId
 * @constructor
 */
function DrawingConfiguration(key, name, deliverableProcessId) {
    this.key = key;
    this.name = name;
    this.deliverableProcessId = deliverableProcessId;

    this.rows = [];
    this.cells = {};

    this.title = "";
    this.header = {
        long: "",
        short: "",
    };

    this.code = {
        row: {
            propertyName: "code",
            range: {
                start: 0,
                end: 0,
            },
        },
        cell: {
            propertyName: "code",
            range: {
                start: 0,
                end: 0,
            },
        },
    };
}

DrawingConfiguration.prototype.setTitle = function (title) {
    this.title = title || "";
};

DrawingConfiguration.prototype.setHeader = function (longText, shortText) {
    this.header = {
        long: longText || "",
        short: shortText || "",
    };
};

DrawingConfiguration.prototype.setDisplayFor = function (property, value) {
    var isAllowedProp = Object.prototype.hasOwnProperty.call(
        this.code,
        property
    );
    var isAllowedType = typeof value === "string";

    if (isAllowedProp && isAllowedType) {
        this.code[property].propertyName = value.toLowerCase();
    }
};

DrawingConfiguration.prototype.setRangeFor = function (property, from, to) {
    var isAllowedProp = Object.prototype.hasOwnProperty.call(
        this.code,
        property
    );
    var isAllowedType = typeof from === "number" && typeof to === "number";
    var isAllowedRange = from > 0 && to > 0 && from - 1 < to;

    if (isAllowedProp && isAllowedType && isAllowedRange) {
        this.code[property].range = {
            start: from - 1,
            end: to,
        };
    }
};

DrawingConfiguration.prototype.createEmptyDrawing = function () {
    return new GeneratedDrawing(this.key, this.name, this);
};

/**
 * Set the text for each structure/row
 *
 * @return {Array<Rows>}
 */
DrawingConfiguration.prototype.setTextValueForEachRow = function () {
    var propertyName = this.code.row.propertyName;
    var startIndex = this.code.row.range.start;
    var endIndex = this.code.row.range.end;

    var rows = (this.rows || []).map(function (row) {
        row.infoToDisplay = row[propertyName] || "";

        if (startIndex >= 0 && endIndex) {
            row.infoToDisplay = row.infoToDisplay.substring(
                startIndex,
                endIndex
            );
        }

        return row;
    });

    return rows;
};

/**
 * Set the text for each deliverable/cell for a given row
 *
 * @param {string} rowIdentifier
 * @param {object} row
 *
 * @return {Array<Cells>}
 */
DrawingConfiguration.prototype.setTextValueForEachCell = function (
    rowIdentifier,
    row
) {
    var propertyName = this.code.cell.propertyName;
    var startIndex = this.code.cell.range.start;
    var endIndex = this.code.cell.range.end;

    var cells = (this.cells[row[rowIdentifier]] || []).map(function (cell) {
        cell.infoToDisplay = cell[propertyName] || "";

        if (startIndex >= 0 && endIndex) {
            cell.infoToDisplay = cell.infoToDisplay.substring(
                startIndex,
                endIndex
            );
        }

        return cell;
    });

    return cells;
};

/**
 * Transform the drawing configuration to the format needed by the svg generator.
 *
 * @param {string} rowIdentifier
 *
 * @return {{headers: {main: string|*, leftSub: string, rightSub: string}, data: any[]}}
 */
DrawingConfiguration.prototype.toSvgGeneratorInput = function (rowIdentifier) {
    var self = this;

    var rows = this.setTextValueForEachRow();

    var data = rows
        .map(function (row) {
            var deliverableCells = self.setTextValueForEachCell(
                rowIdentifier,
                row
            );

            return {
                code: row.infoToDisplay,
                cells: deliverableCells,
            };
        })
        .sort(function (a, b) {
            return naturalSortFn(b.code, a.code);
        });

    return {
        headers: {
            left: this.header.short || "",
            right: this.header.long || "",
        },
        data: data,
        name: this.name,
    };
};

export default {
    GeneratedDrawing: GeneratedDrawing,
    DrawingConfiguration: DrawingConfiguration,
    UploadedDrawing: UploadedDrawing,
};
