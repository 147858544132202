import moment from "moment";

export default function (
    $mdDialog,
    Analytics,
    downloadCenterService,
    $sbReportingDeliverablesApi,
    $sbProject,
    $sbLocale,
    $mdToast,
    $sbErrorPresenter,
    QR_CODE_TEMPLATE
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.cancel = $mdDialog.cancel;
    vm.layouts = QR_CODE_TEMPLATE;
    vm.selectedLayout = QR_CODE_TEMPLATE[0];

    vm.onPressConfirm = onPressConfirm;

    // watch layout selection to

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function onPressConfirm(layout) {
        Analytics.trackEvent(
            "PDF Reports",
            "click",
            "Deliverables QR Codes | " + layout.type
        );

        if (vm.numberOfDeliverables > layout.maxDeliverables) {
            Analytics.trackEvent(
                "sb_known_errors",
                "export_report",
                "Deliverables QR Codes | " + layout.type
            );
            $mdDialog.show(
                $mdDialog
                    .alert()
                    .contentValues({
                        max: layout.maxDeliverables,
                    })
                    .title("ERROR_REPORT_SIZE_LIMIT_TITLE")
                    .content("QR_DIALOG_ERROR_REPORT_SIZE_MESSAGE")
            );
            return;
        }

        _showLoadingToast();

        $sbReportingDeliverablesApi
            .printCollection($sbProject.getCurrentProjectId(), {
                ...vm.oDataFilterExpr,
                language: $sbLocale.current(),
                layout: layout.type,
                as: "qr_codes",
            })
            .then((xhr) =>
                downloadCenterService.downloadFrom(
                    xhr,
                    (backendFilename) =>
                        backendFilename || getFallbackFilename()
                )
            )
            .catch($sbErrorPresenter.catch)
            .finally(() => $mdToast.hide());

        $mdDialog.hide({
            layout: layout.type,
        });
    }

    /**
     * Generate fallback filename
     *
     * @returns {string}
     */
    function getFallbackFilename() {
        const prefix = moment().format("YYYY-MM-DD");
        return `${prefix}_basic_QRCodes.pdf`;
    }

    function _showLoadingToast() {
        return $mdToast.show(
            $mdToast
                .loading()
                .content("ACTION_PRINT_REPORT")
                .hideDelay(false)
                .position("top right")
        );
    }
}
