import angular from "angular";
import templateHtml from "./exit_running_session_dialog.html";

export default function exitRunningSessionDialogPreset($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("exitRunningSession", {
        methods: ["class", "onStay", "onDiscard", "onRelease", "onKeepOpen"],
        options: function () {
            return {
                templateUrl: templateHtml,
                controller: function ExitRunningSessionDialogCtrl($mdDialog) {
                    "ngInject";

                    var vm = this;

                    vm.stay = createCallTo({
                        closeFn: $mdDialog.cancel,
                        callbackFn: vm.onStay,
                    });

                    vm.discard = createCallTo({
                        closeFn: $mdDialog.cancel,
                        callbackFn: vm.onDiscard,
                    });

                    vm.release = createCallTo({
                        closeFn: $mdDialog.hide,
                        callbackFn: vm.onRelease,
                    });

                    vm.keepOpen = createCallTo({
                        closeFn: $mdDialog.hide,
                        callbackFn: vm.onKeepOpen,
                    });

                    function createCallTo(callbacks) {
                        var closeFn = callbacks.closeFn;
                        var callbackFn = callbacks.callbackFn || angular.noop;

                        return function callTo() {
                            closeFn().then(callbackFn);
                        };
                    }
                },
                controllerAs: "$ctrl",
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
