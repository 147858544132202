import _ from "lodash";
import moment from "moment";
import ChecklistItem from "./checklist_template_item.class";
import ChecklistTemplateItem from "./checklist_template_item.class";

function ChecklistTemplate(id, name) {
    this.id = id;
    this.name = name;

    this.items = [];

    this.creationDate = "";
    this.creator = {
        displayName: "",
        initials: "",
        userName: "",
    };
}

/**
 * Set creationDate of the checklist
 *
 * @param {string} creationDate
 *
 * @returns {ChecklistTemplate}
 */
ChecklistTemplate.prototype.setCreationDate = function (creationDate) {
    this.creationDate = moment(creationDate);
    return this;
};

/**
 * Set the creator of the checklist
 *
 * @param {object} creator
 *
 * @returns {ChecklistTemplate}
 */
ChecklistTemplate.prototype.setCreator = function (creator) {
    this.creator.displayName = creator.name;
    this.creator.initials = creator.initials;
    this.creator.userName = creator.id;
    return this;
};

/**
 * Set item list on this checklist
 *
 * @param {Array<ChecklistTemplateItem>} items
 *
 * @returns {ChecklistTemplate}
 */
ChecklistTemplate.prototype.setItems = function (items) {
    this.items = items || [];
    return this;
};

/**
 * Get item list for this checklist
 *
 * @returns {Array<ChecklistTemplateItem>}
 */
ChecklistTemplate.prototype.getItems = function () {
    return this.items || [];
};

/**
 * Get the number of items in this checklist
 *
 * @returns {number}
 */
ChecklistTemplate.prototype.getNumberOfItems = function () {
    return this.getItems().length;
};

/**
 * Add single item into the list
 *
 * @param {ChecklistTemplateItem} item
 *
 * @returns {ChecklistTemplate}
 */
ChecklistTemplate.prototype.addItem = function (item) {
    if (item instanceof ChecklistItem) {
        this.items.push(item);
    } else {
        throw new Error(
            "Argument to `addItem` function should be instance of ChecklistItem"
        );
    }
    return this;
};

/**
 * Remove single item from the list
 *
 * @param {ChecklistTemplateItem} item
 *
 * @returns {Array<ChecklistTemplateItem>}
 */
ChecklistTemplate.prototype.removeItem = function (item) {
    if (item instanceof ChecklistItem) {
        var itemIndex = _.findIndex(this.items, function (element) {
            return element.id === item.id;
        });
        if (itemIndex > -1) {
            return this.items.splice(itemIndex, 1);
        }
    } else {
        throw new Error(
            "Argument to `removeItem` function should be instance of ChecklistItem"
        );
    }
};

/**
 * Generate the correct payload for saving to the database
 *
 *
 * @returns {*}
 */
ChecklistTemplate.prototype.toServerRequestModel = function () {
    return {
        name: this.name,
        checklist_items: this.items.map((item) => {
            const checklistTemplateItem = new ChecklistTemplateItem(
                item.id,
                item.description
            );
            checklistTemplateItem.setIsConfirmRequired(item.isConfirmRequired);
            checklistTemplateItem.setIsWorkRequired(item.isWorkRequired);
            checklistTemplateItem.setIsWorkImageRequired(
                item.isWorkImageRequired
            );

            return checklistTemplateItem.toServerRequestModel();
        }),
    };
};

export default ChecklistTemplate;
