import moment from "moment";

export default class NotesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a collection of notes scoped to a specific project.
     *
     * @param projectId {string}    UUID
     * @param byActivity {string}    UUID
     */
    getCollection(projectId, { byActivity }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                by_activity: byActivity,
            },
        });
    }

    /**
     * This endpoint returns a specific note by their given key.
     *
     * @param projectId {string}    UUID
     * @param noteId {integer}    The unique identifier of the note you are requesting a resource for.
     */
    get(projectId, noteId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes/${noteId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a new note in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.type {string}                The type of the note.
     * @param body.text {string}                The text of the note.
     * @param body.note_template_id {number}    The note template that it refers to.
     * @param body.assigned_team_id {number}    The team which is responsible for the note.
     * @param body.activity_id {string}         The activity it is attached to.
     * @param body.deliverable_id {string}      The deliverable it is attached to.
     * @param body.image_ids {number[]}         IDs of image attachments of this note
     * @param body.attachment_ids {string[]}    IDs of file attachments of this note
     * @param body.confirmation_team_id {number} The team which is confirmation for the note.
     */
    create(
        projectId,
        {
            type,
            text,
            note_template_id,
            assigned_team_id,
            activity_id,
            deliverable_id,
            image_ids,
            attachment_ids,
            confirmation_team_id,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes`;
        const body = {
            type,
            text,
            note_template_id,
            assigned_team_id,
            activity_id,
            deliverable_id,
            image_ids,
            attachment_ids,
        };

        body.confirmation_team_id = confirmation_team_id;

        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body,
        });
    }

    /**
     * Update state of an existing note (e.g. closing a claim or obstruction).
     *
     * @param projectId {string}    UUID
     * @param noteId {integer}    The unique identifier of the note you are requesting a resource for.
     *
     * @param body {Object}    Request body
     * @param body.state {string}    The current status of the note.
     */
    updateState(projectId, noteId, { state }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes/${noteId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: { to: state, at: moment().toISOString() },
        });
    }

    /**
     * This endpoint deletes an attached image from a note.
     *
     * @param projectId {string}    UUID
     * @param noteId {integer}    The unique identifier of the note you are requesting a resource for.
     * @param imageId {integer}    The unique identifier of the image you are deleteing.
     */
    deleteImage(projectId, noteId, imageId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes/${noteId}/images/${imageId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }

    /**
     * This endpoint deletes an attachment from a note.
     *
     * @param projectId {string}    UUID
     * @param noteId {integer}    The unique identifier of the note you are requesting a resource for.
     * @param fileId {integer}    The unique identifier of the attachment you are deleteing.
     */
    deleteFile(projectId, noteId, fileId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes/${noteId}/files/${fileId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }

    /**
     * This endpoint updates a new note in the project.
     *
     * @param projectId {string}    UUID
     * @param noteId {integer}    Number
     *
     * @param body {Object}    Request body
     * @param body.type {string}                The type of the note.
     * @param body.text {string}                The text of the note.
     * @param body.assigned_team_id {number}    The team which is responsible for the note.
     * @param body.image_ids {number[]}         IDs of image attachments of this note
     * @param body.attachment_ids {string[]}    IDs of file attachments of this note
     * @param body.confirmation_team_id {number}    The team which is confirmation for the note.
     */
    update(
        projectId,
        noteId,
        {
            type,
            text,
            imageIds,
            attachmentIds,
            responsibleTeamId,
            confirmationTeamId,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes/${noteId}`;

        const body = {
            type,
            text,
            assigned_team_id: responsibleTeamId,
            image_ids: imageIds,
            attachment_ids: attachmentIds,
        };

        body.confirmation_team_id = confirmationTeamId;

        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body,
        });
    }

    /**
     * This endpoint deletes a note.
     *
     * @param projectId {string}    UUID
     * @param noteId {integer}    The unique identifier of the note you are requesting a resource for.
     */
    delete(projectId, noteId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/notes/${noteId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
