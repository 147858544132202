/**
 *
 * Helper class to create readable and maintainable OData Filter
 *
 * You can do something like:
 *
 *  var filterCondition = new ODataFilterFactory()
 *                              .eq("PROJECT_ID", "super nice id")
 *                              .and().eq("TEMPLATE_ID", null)
 *                              .and().ne("CATEGORY", "ACTIVITY")
 *                              .and().ne("ID", anID)
 *                              .and().block(
 *                                  new ODataFilterFactory()
 *                                    .like("NAME", name)
 *                                    .or()
 *                                    .like("CODE", name)
 *                               )
 *                               .get();
 */

function ODataFilterFactory() {
    this.filter = [];
}

/**
 * Combines a list of filters into one and adds a given `separator` in between
 * @example
 *  var filters = [
 *      new ODataFilterFactory().eq("ID", "some id"),
 *      new ODataFilterFactory().gt("COUNT", 2),
 *  ];
 *  var joinedFilter = ODataFilterFactory.join(filters, "and");
 *  joinedFilter.get(); // ID eq 'some id' and COUNT gt 2
 *
 * @param {ODataFilterFactory[]} filterFactories - list of filter factories
 * @param {String} separator - can be `or` or `and`
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.join = function join(filterFactories, separator) {
    var lastIndex = filterFactories.length - 1;
    var joinedFactory = new ODataFilterFactory();
    joinedFactory.filter = filterFactories.reduce(function (
        filters,
        factory,
        index
    ) {
        const factoryResult = factory.get();
        if (factoryResult) {
            filters.push(factory.get());
            if (index !== lastIndex) {
                filters.push(separator.trim());
            }
        }
        return filters;
    }, []);
    return joinedFactory;
};

ODataFilterFactory.prototype.or = function () {
    this.filter.push("or");

    return this;
};

ODataFilterFactory.prototype.and = function () {
    this.filter.push("and");

    return this;
};

/**
 * GREATER THAN
 *
 * Add a gt expression to your filter
 * @param {String} key - column name
 * @param {Number|String} value - the number to compare with (if it is a BIGINT you have to give the number as "12341345324L" with a "L" at the end!
 * @param {String} [fallbackKey] - column name that is used if the key is NULL (If you set a fallback key
 *                                  that will lead to an expression that is checking for null and executing
 *                                  the "real" filter only in not NULL scenarios)
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.gt = function (key, value, fallbackKey) {
    if (fallbackKey) {
        var filterPartOne =
            "(( " + key + " ne null) and (" + key + " gt " + value + "))";
        var filterPartTwo =
            "(( " +
            fallbackKey +
            " gt " +
            value +
            ") and (" +
            key +
            " eq null))";
        this.filter.push("(" + filterPartOne + " or " + filterPartTwo + ")");
    } else {
        this.filter.push("(" + key + " gt " + value + ")");
    }

    return this;
};

/**
 * LESS THAN
 *
 * Add a gt expression to your filter
 * @param {String} key - column name
 * @param {Number|String} value - the number to compare with (if it is a BIGINT you have to give the number as "12341345324L" with a "L" at the end!
 * @param {String} [fallbackKey] - column name that is used if the key is NULL (If you set a fallback key
 *                                  that will lead to an expression that is checking for null and executing
 *                                  the "real" filter only in not NULL scenarios)
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.lt = function (key, value, fallbackKey) {
    if (fallbackKey) {
        var filterPartOne =
            "(( " + key + " ne null) and (" + key + " lt " + value + "))";
        var filterPartTwo =
            "(( " +
            fallbackKey +
            " lt " +
            value +
            ") and (" +
            key +
            " eq null))";
        this.filter.push("(" + filterPartOne + " or " + filterPartTwo + ")");
    } else {
        this.filter.push("(" + key + " lt " + value + ")");
    }

    return this;
};

ODataFilterFactory.prototype.between = function (key, valueFrom, valueTo) {
    return this.filter.push(
        "(" + key + " ge " + valueFrom + " and " + key + " le " + valueTo + ")"
    );
};

/**
 * Add a eq expression to your filter
 * @param {String} key - column name
 * @param {String|Number} value - search term
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.eq = function (key, value) {
    this.filter.push("(" + key + " eq " + this.sanatizeValue(value) + ")");

    return this;
};

/**
 * Add a eq expression to your filter - columnwise
 * @param {String} key - first column name
 * @param {String|Number} value - second column name
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.columnEq = function (key, value) {
    this.filter.push("(" + key + " eq " + value + ")");

    return this;
};

/**
 * Add an in expression to your filter to check equality against any one of a list a values
 * @param {String} key - column name
 * @param {(String|Number)[]} values - search term
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.in = function (key, values) {
    let filterQuery = "(";
    values.forEach((value, index) => {
        if (index > 0) {
            filterQuery += " or ";
        }
        filterQuery += "(" + key + " eq " + this.sanatizeValue(value) + ")";
    });
    filterQuery += ")";
    this.filter.push(filterQuery);

    return this;
};

/**
 * Add a not eq expression to your filter
 * @param {String} key - column name
 * @param {String|Number} value - search term
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.ne = function (key, value) {
    this.filter.push("( " + key + " ne " + this.sanatizeValue(value) + ")");

    return this;
};

/**
 * Add a like expression to your filter
 * @param {String} key - column name
 * @param {String} value - search term
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.like = function (key, value) {
    // single quotes have to be escaped by doubling it
    //  e.g: "o 'clock" becomes "o ''clock"
    const searchTerm = value.toLowerCase().replace(/'/g, "''");
    this.filter.push(`(substringof('${searchTerm}', tolower(${key})))`);

    return this;
};
/**
 * Adds a like expression for each key and combines them with an `or`
 * @example
 *  var filters = ["NAME", "CODE"];
 *  var filter = new ODataFilterFactory().likeOnOf(filters, "RaNdOm String");
 *  filter.get(); // (substringof('random string'), tolower(NAME)) or (substringof('random string'), tolower(CODE))
 *
 *  // equivalent to:
 *  var filter = new ODataFilterFactory().like("NAME", "RaNdOm String").or().like("CODE", "RaNdOm String")
 *
 * @param {String[]} keys - list of column names
 * @param {String} value - search term
 * @returns {ODataFilterFactory}
 */
ODataFilterFactory.prototype.likeOneOf = function (keys, value) {
    var filters = keys.map(function (key) {
        return new ODataFilterFactory().like(key, value);
    });
    this.block(ODataFilterFactory.join(filters, "or"));
    return this;
};

ODataFilterFactory.prototype.sanatizeValue = function (value) {
    if (typeof value === "string") {
        return "'" + value + "'";
    } else {
        return value;
    }
};

/**
 * add another filter inside a block ( ... )
 * @param {ODataFilterFactory} filter
 */
ODataFilterFactory.prototype.block = function (filter) {
    this.filter.push("(" + filter.get() + ")");
    return this;
};

ODataFilterFactory.prototype.not = function () {
    this.filter.push("not");
    return this;
};

ODataFilterFactory.prototype.get = function () {
    return this.filter.join(" ");
};

/**
 * Merge a range of filter expression into a block of braces and puts it
 * at position with index of start. The rest of the non-merged array is appended
 * to the right again.
 *
 * @example
 * this.filter = ['1', 'and', '3', 'or', '3', 'or', '5', 'and', '3'];
 *
 * now call to oDataFactory.mergeIntoBlock(2, 6) will result into
 *
 * this.filter = ['1', 'and', '(3 or 3 or 5)', 'and', '3'];
 *
 * It's a way to make post-construction changes to the odata filter query.
 *
 * @param {Number} start - The start index to merge. Begins with 0.
 * @param {Number} end - The last index to merge. Ends with this.filter.length
 *
 * @returns {ODataFilterFactory} For chaining
 */
ODataFilterFactory.prototype.mergeIntoBlock = function (start, end) {
    // split into pieces
    var filtersToMerge = this.filter.splice(start, end);
    var leftOver = this.filter.splice(start, this.filter.length);
    var block = "(" + filtersToMerge.join(" ") + ")";

    // reconnect again
    this.filter.push(block);
    this.filter.push.apply(this.filter, leftOver);
    return this;
};

ODataFilterFactory.prototype.getLengthOfResultingFilterExpression =
    function () {
        var filterQueryString = this.get();

        if (typeof filterQueryString === "string") {
            return filterQueryString.length;
        }

        return -1;
    };

export default ODataFilterFactory;
