import angular from "angular";
import _ from "lodash";
import moment from "moment";

export default function SiteDiaryCtrl(
    $q,
    $rootScope,
    $scope,
    $state,
    $sbSiteDiary,
    $sbWeekCalendar,
    $sbMembership,
    $sbErrorPresenter,
    $sbTracking,
    $stateParams,
    $sbProject,
    $mdDialog,
    $mdToast,
    EVENTS,
    Analytics
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    vm.diaryEntries = [];
    vm.progressSnapshot = undefined;
    vm.viewModelSetUpComplete = false;
    vm.isFinalisingEntry = false;
    vm.isLoading = false;

    vm.calendar = {
        today: $sbSiteDiary.getBeginningOfToday(),
        selectedDate: undefined,
        onSelectionChange: onEntryDateChange,
        initialize: initializeSelectedDate,
    };

    vm.onDateSelected = onDateSelected;
    vm.openDateChooser = openDateChooser;
    vm.onInfoCardSave = onInfoCardSave;
    vm.onTeamsCardSave = onTeamsCardSave;
    vm.onMachinesCardSave = onMachinesCardSave;
    vm.onEventsCardSave = onEventsCardSave;
    vm.createProgressSnapshot = createProgressSnapshot;
    vm.trackChangeEvent = _.debounce(trackChangeEvent, 1000);
    vm.finaliseDiaryEntry = finaliseDiaryEntry;

    vm.CHANGE_EVENT_TRACKING_KEYS = {
        GENERAL_CARD: {
            NAME: "General card",
            HOURS: "Working hours",
            DEPARTMENT: "Department",
            WEATHER: "Weather conditions",
            TEMPATURE: {
                MIN: "Min temp",
                MAX: "Max temp",
            },
        },
        TEAMS_CARD: {
            NAME: "Teams card",
            NUMBER_OF_MANAGERS: "Number of managers",
            NUMBER_OF_FOREMEN: "Number of foremen",
            NUMBER_OF_WORKERS: "Number of workers",
            NUMBER_OF_OTHERS: "Number of others",
        },
        MACHINES_CARD: {
            NAME: "Machines card",
        },
        EVENTS_CARD: {
            NAME: "Events card",
        },
    };

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    var onStateChangeListener = $rootScope.$on(
        "$stateChangeStart",
        _onStateChangeStart
    );

    $scope.$on("$destroy", function () {
        onStateChangeListener();
    });

    // should always match what is in the DB and
    // not be modified by the front end
    //
    var _pristineDiaryEntry = undefined;

    // a copy of _pristineDiaryEntry which the
    // front end can modify
    //
    vm.viewModelDiaryEntry = undefined;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    activate();

    function activate() {
        vm.calendar.initialize();

        return $sbSiteDiary
            .initializeCalendar(vm.calendar.selectedDate)
            .then(_setDiaryEntriesFromService)
            .then(function () {
                vm.onDateSelected($sbWeekCalendar.date);
            })
            .finally(function () {
                vm.viewModelSetUpComplete = true;
            })
            .catch($sbErrorPresenter.catch);
    }

    function _setDiaryEntriesFromService() {
        vm.diaryEntries = $sbWeekCalendar.diaryEntries || [];
    }

    function initializeSelectedDate() {
        var selectedDate = _.defaultTo(
            $sbSiteDiary.parseDate($stateParams.entry),
            $sbSiteDiary.getBeginningOfToday()
        );

        vm.calendar.onSelectionChange(selectedDate);
    }

    function onDateSelected(date) {
        if (date) {
            date = moment(date);

            vm.calendar.onSelectionChange(date);

            if (_entryExistsForSelectedDate(date)) {
                _fetchSelectedEntryAndSnapshotFor(date);
            } else {
                Analytics.trackConversion("entry created");

                _createNewEntryAndSnapshotFor(date);
            }
        }
    }

    function _fetchSelectedEntryAndSnapshotFor(date) {
        _toggleIsLoading();

        return $sbSiteDiary
            .fetchEntry(date)
            .then(_setPristineDiaryEntry)
            .then(_setViewModelDiaryEntry)
            .then(_setDiaryEntriesFromService)
            .then(_fetchProgressSnapshot)
            .then(_setIsFinalizedButtonDisabled)
            .catch($sbErrorPresenter.catch)
            .finally(_toggleIsLoading);
    }

    function _createNewEntryAndSnapshotFor(date) {
        _toggleIsLoading();

        return $sbSiteDiary
            .createNewEntry(date)
            .then(_setPristineDiaryEntry)
            .then(_setViewModelDiaryEntry)
            .then(_setDiaryEntriesFromService)
            .then($sbTracking.siteDiary.onCreateNewEntry)
            .then(createProgressSnapshot)
            .then(_setIsFinalizedButtonDisabled)
            .catch($sbErrorPresenter.catch)
            .finally(_toggleIsLoading);
    }

    function _fetchProgressSnapshot() {
        return $sbSiteDiary
            .fetchProgressSnapshotFor(moment(vm.calendar.selectedDate))
            .then(function (snapshot) {
                vm.progressSnapshot = snapshot;
            });
    }

    function createProgressSnapshot() {
        return $sbSiteDiary
            .createProgressSnapshotFor(moment(vm.calendar.selectedDate))
            .then(function (snapshot) {
                vm.progressSnapshot = snapshot;
            });
    }

    function _setIsFinalizedButtonDisabled() {
        // finalise button should only be enabled on days in the past
        //
        var isDateToday = $sbWeekCalendar.selectedDateIsToday();
        var isDateInFuture = $sbWeekCalendar.selectedDateIsInTheFuture();
        var isFinalized = vm.viewModelDiaryEntry.isFinalized();

        vm.isFinaliseButtonDisabled =
            isDateToday || isDateInFuture || isFinalized;
    }

    function _entryExistsForSelectedDate(date) {
        return _.find(vm.diaryEntries, function (entry) {
            return date.isSame(entry.date, "day");
        });
    }

    function onInfoCardSave(data) {
        var _entry = _getCopyOfPristineEntry();

        _entry.department = data.department;
        _entry.workingTime = data.workingTime;
        _entry.weather = data.weather;

        return _onDiarySave({
            cardName: vm.CHANGE_EVENT_TRACKING_KEYS.GENERAL_CARD.NAME,
            diaryEntry: _entry,
        });
    }

    function onTeamsCardSave(data) {
        var _entry = _getCopyOfPristineEntry();

        _entry.teams = data.teams;

        return _onDiarySave({
            cardName: vm.CHANGE_EVENT_TRACKING_KEYS.TEAMS_CARD.NAME,
            diaryEntry: _entry,
        });
    }

    function onMachinesCardSave(data) {
        var _entry = _getCopyOfPristineEntry();

        _entry.machines = data.machines;

        return _onDiarySave({
            cardName: vm.CHANGE_EVENT_TRACKING_KEYS.MACHINES_CARD.NAME,
            diaryEntry: _entry,
        });
    }

    function onEventsCardSave(data) {
        var _entry = _getCopyOfPristineEntry();

        _entry.events = data.events;

        return _onDiarySave({
            cardName: vm.CHANGE_EVENT_TRACKING_KEYS.EVENTS_CARD.NAME,
            diaryEntry: _entry,
        });
    }

    function _getCopyOfPristineEntry() {
        return angular.copy(_pristineDiaryEntry);
    }

    function _onDiarySave(data) {
        return $sbSiteDiary
            .updateEntry(moment($sbWeekCalendar.date), data.diaryEntry)
            .then(_setPristineDiaryEntry)
            .then(function (diaryEntry) {
                _showSuccessToast();

                if (data.cardName) {
                    $sbTracking.siteDiary.cards.onCardSave(data.cardName);
                    Analytics.trackConversion("change saved", data.cardName);
                }

                return diaryEntry;
            });
    }

    function _showSuccessToast() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("SITE_DIARY_SAVE_SUCCESS")
                .hideDelay(3000)
                .position("top right")
        );
    }

    function _setPristineDiaryEntry(diaryEntry) {
        _pristineDiaryEntry = angular.copy(diaryEntry);
        return diaryEntry;
    }

    function _setViewModelDiaryEntry(diaryEntry) {
        vm.viewModelDiaryEntry = diaryEntry;
        return diaryEntry;
    }

    function _toggleIsLoading() {
        vm.isLoading = !vm.isLoading;
    }

    function _setSelectedDateTo(date) {
        vm.calendar.selectedDate = $sbSiteDiary
            .trimToDatePrecision(date)
            .toDate();
    }

    function onEntryDateChange(current) {
        if (current) {
            current = moment(current);
            _setSelectedDateTo(current);
            $sbSiteDiary.navigateTo(current);

            // update the calendar information inside the service
            //
            $sbWeekCalendar.selectDate(current);
            $sbWeekCalendar.goToDate(current);
        }
    }

    function openDateChooser($event) {
        return $sbSiteDiary.openDateChooser(
            $event,
            {
                previousDate: vm.calendar.selectedDate,
                onSelectionChange: vm.onDateSelected,
            },
            function onCloseSuccess(mdPanelRef, closeReason) {
                if (closeReason) {
                    $sbTracking.siteDiary.calendar.selectDateFromPicker();
                }
            }
        );
    }

    function trackChangeEvent(label) {
        $sbTracking.siteDiary.cards.onCardInputChange(label);
    }

    function _onStateChangeStart(event, toState, toParams, fromState) {
        // he might need to sign in again
        //
        var isForcedRedirect =
            angular.isDefined(toState.data) && toState.data.forLogin;

        if (!isForcedRedirect && !_areChildFormsPristine(vm.masterForm)) {
            // stops the toolbar changing color before leaving the page
            //
            _updateToolbarColor();
            event.preventDefault();
            $sbTracking.siteDiary.tryNavigateAwayWithUnsavedChanges();

            return $mdDialog
                .show(
                    $mdDialog
                        .confirm()
                        .title("SITE_DIARY_CONFIRM_LEAVE_TITLE")
                        .content("SITE_DIARY_CONFIRM_LEAVE_TEXT")
                )
                .then(function () {
                    $sbTracking.siteDiary.onLeavingPageWithUnsavedChanges.decidedToLeave();
                    onStateChangeListener();
                    $state.go(toState, toParams);
                })
                .catch(function () {
                    $sbTracking.siteDiary.onLeavingPageWithUnsavedChanges.decidedToStay();
                });
        }
    }

    function _updateToolbarColor() {
        $rootScope.$emit(EVENTS.PLAN_WORK_REVIEW_SECTION_CHANGED, "WORK");
    }

    function _areChildFormsPristine(masterForm) {
        return (
            masterForm["$ctrl.eventsForm"].$pristine &&
            masterForm["$ctrl.infoForm"].$pristine &&
            masterForm["$ctrl.machinesForm"].$pristine &&
            masterForm["$ctrl.teamsForm"].$pristine
        );
    }

    function finaliseDiaryEntry() {
        return _confirmFinaliseEntry().then(function (didConfirm) {
            if (!didConfirm) {
                return;
            }

            vm.isFinalisingEntry = true;

            return _sendFinaliseEntryRequest()
                .then(_setViewModelDiaryEntry)
                .then(_updateFinaliseButtonStateInToolbar)
                .then($sbTracking.siteDiary.onFinaliseEntry)
                .then(createProgressSnapshot)
                .finally(function () {
                    vm.isFinalisingEntry = false;
                })
                .catch($sbErrorPresenter.catch);
        });
    }

    function _confirmFinaliseEntry() {
        return $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .title("SITE_DIARY_CONFIRM_FINALISE_ENTRY_TITLE")
                    .content("SITE_DIARY_CONFIRM_FINALISE_ENTRY_TEXT")
            )
            .catch(angular.noop);
    }

    function _sendFinaliseEntryRequest() {
        var _entry = _getCopyOfPristineEntry();

        _entry.finalized.at = moment();
        _entry.finalized.by = $sbMembership.currentUser();

        return _onDiarySave({
            cardName: undefined,
            diaryEntry: _entry,
        });
    }

    function _updateFinaliseButtonStateInToolbar(diaryEntry) {
        var weekEntry = _entryExistsForSelectedDate(diaryEntry.date);
        weekEntry.finalized = diaryEntry.finalized;
        vm.isFinaliseButtonDisabled = diaryEntry.finalized;
        return diaryEntry;
    }
}
