import _ from "lodash";

export default function RequestSentryService() {
    var abortableRequests = [];

    var service = {
        addAbortable: addAbortable,
        abortAllAndClean: abortAllAndClean,
        // for testing
        getAbortables: _getAbortables,
        requestIsStillProcessing: _requestIsStillProcessing,
    };

    return service;

    //////////

    /**
     * @param {Object<XMLHttpRequest>} request
     */
    function addAbortable(request) {
        abortableRequests.push(request);
    }

    function abortAllAndClean() {
        _.forEach(abortableRequests, function (request) {
            if (_requestIsStillProcessing(request)) {
                request.abort();
            }
        });
        abortableRequests = [];
    }

    /**
     * @param {Object<XMLHttpRequest>} request
     */
    function _requestIsStillProcessing(request) {
        return request.readyState !== 4;
    }

    /**
     * @returns {Array<XMLHttpRequest>}
     */
    function _getAbortables() {
        return abortableRequests;
    }
}
