import angular from "angular";
import htmlTemplate from "./sbMultilineEdit.html";
/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbMultilineEdit", [])
    .directive("sbMultilineEdit", function () {
        return {
            /**
             * String of subset of EACM which restricts the directive to a specific directive declaration style.
             * If omitted, the defaults (elements and attributes) are used.
             *
             * @example <caption>Element Name</caption>
             * E - Element name (default): <my-directive></my-directive>
             * A - Attribute (default): <div my-directive="exp"></div>
             * C - Class: <div class="my-directive: exp;"></div>
             * M - Comment: <!-- directive: my-directive exp -->
             */
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            //transclude: true,

            scope: {
                inlineEditEnabled: "=",
                editValue: "=",
                lineBreak: "=",
                selectText: "=",
                sbOnValueChange: "&",
            },

            //require: 'ngModel',  or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

            //compile : function(tElement, tAttrs) {},

            //link: function (scope, iElement, iAttrs, controller) {},

            controller: function ($scope, $element) {
                var myTextArea = $element.find("textarea")[0];
                var myTextDiv = $element.find("div")[0];

                function initLines(textArea, textHeight, textLineHeight) {
                    myTextArea.rows = textHeight / textLineHeight;

                    if ($scope.selectText) {
                        myTextArea.select();
                    }
                }

                function calculateLines(textArea) {
                    if (textArea.scrollHeight - textArea.offsetHeight > 0) {
                        textArea.rows += 1;
                    }
                }

                $scope.editing = {
                    value: "",
                };
                $scope.$watch(
                    "inlineEditEnabled",
                    function (newValue /*, oldValue*/) {
                        if (newValue) {
                            $scope.editing.value = $scope.editValue;
                            initLines(myTextArea, myTextDiv.offsetHeight, 20);
                        }
                    }
                );
                $scope.disableEditMode = function (saveValue) {
                    if (
                        saveValue &&
                        $scope.editValue !== $scope.editing.value
                    ) {
                        $scope.editValue = $scope.editing.value.replace(
                            /\n/g,
                            " "
                        );

                        if ($scope.sbOnValueChange) {
                            $scope.sbOnValueChange();
                        }
                    }
                    $scope.inlineEditEnabled = false;
                };
                $scope.onKeyPress = function ($event) {
                    calculateLines(myTextArea);
                    switch ($event.keyCode) {
                        case 13: // new line
                            if (!$scope.lineBreak) {
                                $event.preventDefault();
                                $scope.disableEditMode(true);
                            }
                    }
                };
                $scope.onKeyUp = function ($event) {
                    switch ($event.keyCode) {
                        case 27: // escape
                            $scope.disableEditMode(false);
                    }
                };
            },
        };
    });
