import replace from "lodash/replace";
import toInteger from "lodash/toInteger";
import isString from "lodash/isString";

import moment from "moment";

import "configs/all";
// eslint-disable-next-line angular/log
// import "moment-timezone";

export default function ($sbXML) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        getDeliverables: _getDeliverables,
        getDeliverablesCount: _getDeliverablesCount,
        updateFileWithServerProgressValues: _updateFileWithServerProgressValues,
        assemblePreviewModel: _assemblePreviewModel,
        collectAllComponentsFromXML: _collectAllComponentsFromXML,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Get all the available deliverables
     * @param  {XMLDocument} oXML - Object containing XML file structure and content.
     */
    function _getDeliverables(oXML) {
        return oXML.getElementsByTagName("Activity");
    }

    /**
     * Get count of all the deliverables in given object.
     * @param  {XMLDocument} oXML - Object containing XML file structure and content.
     */
    function _getDeliverablesCount(oXML) {
        return oXML.getElementsByTagName("Activity").length;
    }

    function parseToInteger(something) {
        if (typeof something === "string") {
            // always "1394.34"
            something = replace(something, ",", ".");
        }

        return toInteger(something); // Integer
    }

    function getXmlTagIntegerContent(element, tag) {
        return parseToInteger($sbXML.getXmlTagTextContent(element, tag));
    }

    /**
     * Update the progress of given task in given object.
     *
     * INFO: All duration values are used and processed as INT values to avoid comma values
     *          because dependent on the system and program setup the decimal delimiter is dot or comma
     *
     * @param  {Element} task - Task object which gets updated based on progress from the server.
     * @param  {Number} percentageCompleted - progress value
     * @param  {Object} exportStats - exportStats object which will be updated with respect to changes.
     */
    function _updateTaskProgress(task, percentageCompleted, exportStats) {
        //Get remaining duration from the file
        var remainingDurationFromFile = getXmlTagIntegerContent(
            task,
            "RemainingDuration"
        );

        //Get the planned duration from file
        var plannedDuration = getXmlTagIntegerContent(task, "PlannedDuration");
        var calculatedRemainingDuration = Math.round(
            plannedDuration * (1 - percentageCompleted / 100)
        );

        //Compare if there's any change in our server progress vs the progress from file
        //
        if (remainingDurationFromFile !== calculatedRemainingDuration) {
            exportStats.changed = exportStats.changed + 1;

            //Check if ActualStartDate doesn't exist
            //
            if (
                !task.getElementsByTagName("ActualStartDate")[0].childNodes
                    .length > 0
            ) {
                //Remove the ActualStartDate tag
                var element = task.getElementsByTagName("ActualStartDate");
                for (var index = element.length - 1; index >= 0; index--) {
                    element[index].parentNode.removeChild(element[index]);
                }

                //Create a new tag with ActualStartDate and set it's value to planned start date
                var tempActualStartDate = document.createElementNS(
                    "",
                    "ActualStartDate"
                );
                var tempActualStartDateValue = document.createTextNode(
                    $sbXML.getXmlTagTextContent(task, "PlannedStartDate", "")
                );
                tempActualStartDate.appendChild(tempActualStartDateValue);
                task.appendChild(tempActualStartDate);
            }

            //Update the remaining duration -> reflect reference to oXML object
            //
            task.getElementsByTagName(
                "RemainingDuration"
            )[0].childNodes[0].textContent =
                calculatedRemainingDuration.toString();
        }
        return task;
    }
    /**
     * Update the given oXML object with latest values from the server coming through updatedProgress
     * parameter.
     *
     * @param  {XMLDocument} oXML - Object containing XML file structure and content.
     * @param  {Map<id, progress>} progressFromServer - Updated progress values from the server.
     */
    function _updateFileWithServerProgressValues(oXML, progressFromServer) {
        var exportStats = _initExportStats();
        exportStats.activitiesFileTotal = _getDeliverablesCount(oXML);
        //Loop through each activity and check for progress updates accordingly
        angular.forEach(_getDeliverables(oXML), function (task) {
            var UID = $sbXML.getXmlTagTextContent(task, "GUID");

            //If we have updated progress for this on our server
            if (progressFromServer.has(UID)) {
                _updateTaskProgress(
                    task,
                    progressFromServer.get(UID),
                    exportStats
                );
            }
        });
        return {
            oXML: oXML,
            exportStats: exportStats,
        };
    }

    /**
     *
     * @return {Object} exportStats - Stats object with numbers that will be shown after export
     *
     * @private
     */
    function _initExportStats() {
        return {
            activitiesFileTotal: 0,
            changed: 0,
            missmatched: 0,
        };
    }

    /**
     * Find all the predecessors of given activity identity.
     * @param  {Document} oXML - XML document object
     * @param  {String} currentActivityId - Id of activity whom predecessors are to be found
     * @return {Array} - List of ids of predecessors
     */
    function _fetchtAllPredecessorsFromPrimaveraXMLActivity(
        oXML,
        currentActivityId
    ) {
        var predecessors = [];
        Array.prototype.forEach.call(
            oXML.getElementsByTagName("Relationship"),
            function (oRelationship) {
                //Get successor id of relationship
                var successorIdentity = $sbXML.getXmlTagTextContent(
                    oRelationship,
                    "SuccessorActivityObjectId",
                    ""
                );
                //If it matches the current activity identity (it means this will have predecessor)
                /* eslint-disable eqeqeq */
                if (successorIdentity == currentActivityId) {
                    predecessors.push(
                        $sbXML.getXmlTagTextContent(
                            oRelationship,
                            "PredecessorActivityObjectId",
                            ""
                        )
                    );
                }
                /* eslint-enable eqeqeq */
            }
        );
        return predecessors;
    }

    /**
     * Collect all the available components out of primavera XML Document
     * @param  {Document} oXML  - XML document object
     * @param  {String} timezone - The name of the timezone that is used for imported dates.
     * @return {Object} - Object of all the components
     */
    function _collectAllComponentsFromXML(oXML, timezone) {
        var components = [];
        //Go through each activity, and map it to our component object.
        Array.prototype.forEach.call(
            oXML.getElementsByTagName("Activity"),
            function (oTask) {
                var sUID = $sbXML.getXmlTagTextContent(oTask, "GUID", ""),
                    sName = $sbXML.getXmlTagTextContent(oTask, "Name", ""),
                    sStart = $sbXML.getXmlTagTextContent(
                        oTask,
                        "StartDate",
                        ""
                    ),
                    sFinished = $sbXML.getXmlTagTextContent(
                        oTask,
                        "FinishDate",
                        ""
                    ),
                    sDuration = 1,
                    sCode = $sbXML.getXmlTagTextContent(oTask, "Id", ""),
                    aPredecessor =
                        _fetchtAllPredecessorsFromPrimaveraXMLActivity(
                            oXML,
                            sUID
                        ),
                    oComponent;
                // create new component*/
                oComponent = {
                    _objectId: $sbXML.getXmlTagTextContent(
                        oTask,
                        "ObjectId",
                        undefined
                    ),
                    ext: sUID,
                    name: sName,
                    // description: sDescription,
                    sd: moment.tz(sStart, timezone).valueOf(),
                    cd: moment.tz(sFinished, timezone).valueOf(),
                    duration: sDuration,
                    //priority: nPriority,
                    //* /
                    code: sCode,
                    structure: $sbXML.getXmlTagTextContent(
                        oTask,
                        "WBSObjectId",
                        undefined
                    ),
                    predecessors: aPredecessor,
                    origin: "PRIMAVERA",
                };
                //Push current item to the map with respec to code as index
                components.push(oComponent);
            }
        );

        return components;
    }

    /**
     * Assemble our primavera model into preview model
     * @param  {Array} components - List of components
     * @param  {Document} oXML - XML Document object
     * @return {Object} - Returns an object with structure, list and our components
     */
    function _assemblePreviewModel(components, oXML) {
        //Initialize a structure array
        //
        var structureArray = [];
        var edges = [];
        var localToGlobalMapForStructure = {};
        var localToGlobalMapForComponents = {};

        //Fill the structure array with WBS elements matching our own data format
        //
        Array.prototype.forEach.call(
            oXML.getElementsByTagName("WBS"),
            function (oStructure) {
                var str = {
                    code: $sbXML.getXmlTagTextContent(oStructure, "Code", ""),
                    objectId: $sbXML.getXmlTagTextContent(
                        oStructure,
                        "ObjectId",
                        ""
                    ),
                    parentObjectId: $sbXML.getXmlTagTextContent(
                        oStructure,
                        "ParentObjectId",
                        ""
                    ),
                    name: $sbXML.getXmlTagTextContent(oStructure, "Name", ""),
                    ext: $sbXML.getXmlTagTextContent(oStructure, "GUID", ""),
                };

                localToGlobalMapForStructure[str.objectId] = str.ext;

                structureArray.push(str);
            }
        );

        // add parentExt based on parentObjectId and localToGlobalMap
        //
        structureArray.forEach(function (s) {
            s.parentExt = localToGlobalMapForStructure[s.parentObjectId];
        });

        // add structure as ext structure reference to the components
        //
        components.forEach(function (c) {
            c.structure = localToGlobalMapForStructure[c.structure];
            localToGlobalMapForComponents[c._objectId] = c.ext;
        });

        Array.prototype.forEach.call(
            oXML.getElementsByTagName("Relationship"),
            function (dependencyDocument) {
                var edge = {
                    src: $sbXML.getXmlTagTextContent(
                        dependencyDocument,
                        "PredecessorActivityObjectId",
                        ""
                    ),
                    trg: $sbXML.getXmlTagTextContent(
                        dependencyDocument,
                        "SuccessorActivityObjectId",
                        ""
                    ),
                };
                edges.push(edge);
            }
        );
        edges = edges.filter(_isValidEdge).map(function (localEdge) {
            return {
                src: localToGlobalMapForComponents[localEdge.src],
                trg: localToGlobalMapForComponents[localEdge.trg],
            };
        });

        return {
            structureList: structureArray, // the list for upload
            components: components,
            edges: edges,
        };
    }

    function _isValidEdge(edge) {
        return isString(edge.src) && isString(edge.trg);
    }
}
