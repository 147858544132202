import _ from "lodash";
import moment from "moment";
import BaseComponent from "./sb_base_component.class";
import ActivityState from "./sb_activity_state.class";

export default function (
    SbBaseComponent,
    SbDeliverable,
    SbNote,
    SbActivity,
    SbActivityGroup
) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        workflowFactory: {
            create: createWorkflowModelFromOdataEntities,
            createFromTemplateData: createWorkflowModelFromTemplateData,
        },
        noteFactory: {
            create: createNoteFromData,
        },
        deliverableFactory: {
            createFromODataLike: createDeliverableFromODataLike,
            makeIsBehindFrom: makeIsBehindFrom,
            makeProgressFromStatStats: makeProgressFromStatStats,
        },
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function createNoteFromData(newNote) {
        if (newNote instanceof SbNote) {
            return newNote;
        }
        const reportedAt = moment(
            newNote.ISSUE_TIME_CREATED,
            moment.ISO_8601,
            true
        );
        const note = new SbNote(newNote.ISSUE_ID, newNote.ISSUE_TEXT)
            .setCreateTime(reportedAt)
            .setDeliverable(new SbDeliverable(newNote.ROOT_COMPONENT_ID))
            .setAssignedComponent(new SbBaseComponent(newNote.REF_COMPONENT_ID))
            .setType(newNote.ISSUE_TYPE.toLowerCase());

        // TODO (should we also set the state?)

        note.__odataSource = newNote;

        return note;
    }

    function createDeliverableFromODataLike(data) {
        const deliverable = new SbDeliverable(data.ID, data.NAME, data.CODE);
        deliverable.desc = data.DESC;
        deliverable.structureId = data.STRUCTURE_ID;
        deliverable.projectId = data.PROJECT_ID;
        deliverable.piTemplateId = data.TEMPLATE_ID;
        deliverable.templateRevision = data.TEMPLATE_REVISION;
        deliverable.areaManagerUserName = data.AREA_MANAGER_USER_NAME;
        deliverable._noteStatistic.openObstructions = data.OBSTRUCTION_QUANTITY;
        deliverable._noteStatistic.openClaims = data.CLAIM_QUANTITY;

        deliverable.earliestStart = moment(data.EARLIEST_START, "x");
        deliverable.latestStart = moment(data.LATEST_START, "x");
        deliverable.plannedStart = moment(data.PLANNED_START, "x");
        deliverable.earliestEnd = moment(data.EARLIEST_END, "x");
        deliverable.latestEnd = moment(data.LATEST_END, "x");
        deliverable.plannedEnd = moment(data.PLANNED_END, "x");

        SbDeliverable.setLeadingDatesFromScheduleResult(deliverable);

        deliverable.scheduleState = makeIsBehindFrom(data.ACTIVITIES_BEHIND);

        deliverable.issueChangeTime = makeIssueChangeTime(data);
        deliverable.progressChangeTime = moment(
            data.STATE_CHANGE_TIME,
            moment.ISO_8601,
            true
        );

        deliverable.progress = makeProgressFromStatStats(data);

        return deliverable;
    }

    function makeIsBehindFrom(numberOfActivitiesBehind, parser = _.identity) {
        if (_.isNumber(numberOfActivitiesBehind)) {
            if (numberOfActivitiesBehind === 0) {
                return parser(BaseComponent.SCHEDULE_ON_TIME);
            }
            return parser(BaseComponent.SCHEDULE_BEHIND);
        }
        return BaseComponent.SCHEDULE_UNKNOWN;
    }

    function makeIssueChangeTime(data) {
        const issueResolvedAt = moment(data.ISSUE_RESOLVE_TIME, "x");
        if (issueResolvedAt.isValid()) {
            return issueResolvedAt;
        }
        return moment(data.ISSUE_CREATE_TIME, "x");
    }

    function makeProgressFromStatStats({
        ACTIVITIES_NOT_STARTED,
        ACTIVITIES_STARTED,
        ACTIVITIES_DONE,
        ACTIVITIES_WAITING_FOR_CONFIRMATION,
        ACTIVITIES_CONFIRMED,
        ACTIVITIES_REJECTED,
    }) {
        const activityCount =
            ACTIVITIES_NOT_STARTED +
            ACTIVITIES_STARTED +
            ACTIVITIES_DONE +
            ACTIVITIES_WAITING_FOR_CONFIRMATION +
            ACTIVITIES_CONFIRMED +
            ACTIVITIES_REJECTED;

        const totalCompletion =
            ACTIVITIES_NOT_STARTED * ActivityState.PERCENTAGE_NOT_STARTED +
            ACTIVITIES_STARTED * ActivityState.PERCENTAGE_STARTED +
            ACTIVITIES_DONE * ActivityState.PERCENTAGE_DONE +
            ACTIVITIES_WAITING_FOR_CONFIRMATION *
                ActivityState.PERCENTAGE_WAITING_FOR_CONFIRMATION +
            ACTIVITIES_CONFIRMED * ActivityState.PERCENTAGE_CONFIRMED +
            ACTIVITIES_REJECTED * ActivityState.PERCENTAGE_REJECTED;

        return ActivityState.interpretMeanCompletionPercentage(
            totalCompletion,
            activityCount
        );
    }

    /**
     *
     * @param odataEntities
     * @param optionalProperties
     * @returns {Array.<BaseComponent>}
     */
    function createWorkflowModelFromOdataEntities(
        odataEntities,
        optionalProperties
    ) {
        // create a nested group / activity model from the content
        var entities = odataEntities.map(function (entry) {
            var entity;
            if (entry.CATEGORY === "ACTIVITY") {
                entity = SbActivity.createFromOdataObject(entry);
                entity.state = new ActivityState(
                    entry.STATE,
                    moment(entry.STATE_CHANGED_AT, moment.ISO_8601, true),
                    entry.STATE_CHANGED_BY
                );
            } else {
                entity = SbActivityGroup.createFromOdataObject(entry);
            }

            return _.assign(entity, optionalProperties);
        });

        // set the parent child relationships
        //
        entities
            .filter(function (e) {
                return !(e instanceof SbActivity);
            })
            .forEach(function (entity) {
                var children = entities.filter(function (potentialChild) {
                    return entity.id === potentialChild.parentId;
                });
                entity.setChildren(children);
            });

        entities = entities.filter(function (e) {
            return !e.hasParent();
        });

        return entities;
    }

    /**
     * Specialized to work with the pi/template.xsjs service
     *
     * @param {Array<Object>} components - Components have properties like category, id, name, stateName, parentId, code, templateId, ..
     * @param {Array<SbTeam>} componentsData.teams - List of project teams
     * @returns {*|Array}
     */
    function createWorkflowModelFromTemplateData(components, componentsData) {
        var teams = componentsData.teams;
        var entities = components.map(function (entry) {
            var entity;
            if (entry.category === "ACTIVITY") {
                entity = new SbActivity(entry.id, entry.name, entry.code);
                entity.topologicalIndex = entry.topologicalIndex || 0;
                entity.assignedTeam.id = entry.assignedTeamId;
                entity.confirmationTeam.id = entry.confirmationTeamId;
                entity.qa.templateId = entry.checklistId;
            } else {
                entity = new SbActivityGroup(entry.id, entry.name, entry.code);
            }
            entity.category = entry.category;
            entity.parentId = entry.parentId;
            return entity;
        });

        // set the parent child relationships
        //
        entities
            .filter(function (e) {
                return !(e instanceof SbActivity);
            })
            .forEach(function (entity) {
                var children = entities.filter(function (potentialChild) {
                    return entity.id === potentialChild.parentId;
                });
                entity.setChildren(children);
            });
        _.forEach(entities, function (activity) {
            if (activity instanceof SbActivity) {
                const currTeam = _.find(teams, function (team) {
                    return team.id === activity.assignedTeam.id;
                });
                if (currTeam) {
                    activity.setResponsibleTeam(currTeam);
                } else {
                    activity.unsetTeamRestriction();
                }

                const confTeam = _.find(teams, function (team) {
                    return team.id === activity.confirmationTeam.id;
                });
                if (confTeam) {
                    activity.setConfirmingTeam(confTeam);
                }
            }
        });

        entities = entities.filter(function (e) {
            return !e.hasParent();
        });

        return entities;
    }
}
