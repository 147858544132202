class StructureNode {
    constructor(id, name, code, path = []) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.path = path;
    }

    createPathString(visibleLevels) {
        if (visibleLevels) {
            return this.path.slice(-1 * visibleLevels).join(" / ");
        } else {
            return this.path.join(" / ");
        }
    }
}

export default StructureNode;
