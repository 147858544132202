import angular from "angular";
import AssignTypeCtrl from "./assign_type_dialog.controller";
import dialogTemplate from "./assign_type_dialog.html";

export default function AssignTypeConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("assignType", {
        methods: ["title", "description", "action"],
        options: function () {
            return {
                controller: AssignTypeCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                templateUrl: dialogTemplate,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
