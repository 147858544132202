import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sb_labeled_property.html";
export default angular
    .module("sbApp.components.sbLabeledProperty", ["sbApp.components.sbDates"])
    .component("sbLabeledProperty", {
        templateUrl: htmlTemplate,
        bindings: {
            icon: "@?",
            label: "@?",
            tooltip: "@?",
        },
        transclude: true,
        require: {
            datesCtrl: "^sbDates",
        },
    });
