export default class ProjectSettingsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a specific project settings by given project Id.
     *
     * @param projectId {string}    UUID
     */
    async get(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/settings`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }
}
