import moment from "moment";

export default function BulkProcessOperationsService(
    $q,
    $sbCurrentProject,
    $sbRequest,
    $sbActivityJobsApi,
    $sbJobApi,
    $sbLocale,
    $sbResetActivityDialog,
    ACTIVITY_CONF_PROPERTIES_VALUES
) {
    "ngInject";
    ////////
    //
    //  PUBLIC API
    //
    ////////

    return {
        bulkStateChange: bulkStateChange,
        bulkDurationUpdate: bulkDurationUpdate,
        bulkActivityDelete: bulkActivityDelete,
        bulkBringBackNotApplicable: bulkBringBackNotApplicable,
        bulkExportChecklistPDF: bulkExportChecklistPDF,
        bulkExportChecklistPDFsByDate,
        bulkUserDates: bulkUserDates,
        bulkUnsetUserDates: bulkUnsetUserDates,
        bulkUnsetDuration: bulkUnsetDuration,
        bulkSetTeam: bulkSetTeam,
        bulkUnsetTeam: bulkUnsetTeam,
        bulkResetActivities: bulkResetActivities,
    };

    /**
     * Set user-defined dates on activity-states
     * for specified set of deliverables with same template.
     *
     * @param {moment.Moment|null} startDate
     * @param {moment.Moment|null} endDate
     * @param {Object[]} activityTemplates - List of activity templates
     * @param {DeliverableSelection} deliverableSelection
     *
     * @returns {Object} in - Function
     */
    function bulkUserDates(
        activityTemplates,
        deliverableSelection,
        { startDate, endDate }
    ) {
        if (!startDate && moment.isMoment(endDate)) {
            return _doBulkOperation(activityTemplates, deliverableSelection, {
                baseline_schedule: {
                    constraints: {
                        end_date: endDate.toISOString(),
                    },
                },
            });
        }

        if (!endDate && moment.isMoment(startDate)) {
            return _doBulkOperation(activityTemplates, deliverableSelection, {
                baseline_schedule: {
                    constraints: {
                        start_date: startDate.toISOString(),
                    },
                },
            });
        }

        return $q.reject(
            new Error(
                "One and only one of the supplied dates must be non-null!"
            )
        );
    }

    /**
     * Set unset user defined dates on activity-states
     * for specified set of deliverables with same template.
     *
     * @param {Object[]} activityTemplates - List of activity templates
     * @param {DeliverableSelection} deliverableSelection
     *
     * @returns {Object} in - Function
     */
    function bulkUnsetUserDates(activityTemplates, deliverableSelection) {
        return _doBulkOperation(activityTemplates, deliverableSelection, {
            baseline_schedule: {
                constraints: {
                    start_date: "sb::date.not-set",
                    end_date: "sb::date.not-set",
                },
            },
        });
    }

    /**
     * Send a backend request to bulk update the state for a subset of activities for selected/filtered deliverables
     * sharing the same template
     *
     * @param {string}   state - Desired new state
     * @param {Object[]} activityTemplates - List of activity templates
     * @param {DeliverableSelection} deliverableSelection
     */
    function bulkStateChange(activityTemplates, deliverableSelection, state) {
        return _doBulkOperation(activityTemplates, deliverableSelection, {
            state: state.toLowerCase(),
        });
    }

    /**
     * Send a backend request to bulk update the duration for subset of activities for selected/filtered deliverables
     * sharing the same template
     *
     * @param {string}   unit - 'wh' or 'wd'
     * @param {number}   value - any positive float
     * @param {Object[]} activityTemplates - List of activity templates
     * @param {DeliverableSelection} deliverableSelection
     */
    function bulkDurationUpdate(
        activityTemplates,
        deliverableSelection,
        { value, unit }
    ) {
        return _doBulkOperation(activityTemplates, deliverableSelection, {
            duration: {
                value: parseFloat(value),
                unit: unit,
            },
        });
    }

    /**
     * Send a backend request to bulk update the duration for subset of activities for selected/filtered deliverables
     * sharing the same template
     *
     *  @param {Object[]} activityTemplates - List of activity templates
     * @param {DeliverableSelection} deliverableSelection
     */
    function bulkUnsetDuration(activityTemplates, deliverableSelection) {
        return _doBulkOperation(activityTemplates, deliverableSelection, {
            duration: "sb::duration.not-set",
        });
    }

    /**
     * Send a backend request to bulk delete for subset of activities for selected/filtered deliverables
     * sharing the same template
     * @param {Object[]} activityTemplates - List of activity templates
     * @param {DeliverableSelection} deliverableSelection
     */
    function bulkActivityDelete(activityTemplates, deliverableSelection) {
        return _doBulkOperation(activityTemplates, deliverableSelection, {
            status: ACTIVITY_CONF_PROPERTIES_VALUES.DELETION_STATUS,
        });
    }

    /**
     * Send a backend request to bulk bring back activities for subset selected/filtered deliverables
     *
     * @param {Object[]} activityTemplates - List of activity templates
     * @param {DeliverableSelection} deliverableSelection
     */
    function bulkBringBackNotApplicable(
        activityTemplates,
        deliverableSelection
    ) {
        return _doBulkOperation(activityTemplates, deliverableSelection, {
            status: "active",
        });
    }

    function _doBulkOperation(
        activities,
        deliverableSelection,
        operationDefinition
    ) {
        const activity_templates = activities.map(({ ID, id }) => ID || id);
        let deliverable_set = deliverableSelection.getPreferredCondition();

        if (!deliverableSelection.isSelectAllActive()) {
            deliverable_set = deliverable_set.map(({ ID, id }) => ID || id);
        }

        if (operationDefinition.status) {
            const visible =
                operationDefinition.status !==
                ACTIVITY_CONF_PROPERTIES_VALUES.DELETION_STATUS;
            return $sbActivityJobsApi
                .createSwitchVisibilityJob($sbCurrentProject.pick("id"), {
                    visible,
                    deliverable_set,
                    activity_templates,
                })
                .then(({ response, jobId }) => {
                    if (jobId) {
                        // Wait until the job is done
                        return $sbJobApi.waitFor(jobId).then(() => response);
                    }
                    return response;
                });
        }

        return $sbActivityJobsApi
            .createModifyPropertiesJob($sbCurrentProject.pick("id"), {
                properties: operationDefinition,
                deliverable_set,
                activity_templates,
            })
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            });
    }

    function bulkExportChecklistPDF(
        deliverableSelection,
        activities,
        { forwardTo, text } = {},
        { excludedStates, format } = {}
    ) {
        const language =
            $sbLocale.current() || $sbCurrentProject.pick("language");

        return $sbActivityJobsApi.createBulkQAProtocolsJob(
            $sbCurrentProject.pick("id"),
            {
                deliverable_set: _getDeliverableSet(deliverableSelection),
                activity_templates: activities.map(({ ID, id }) => ID || id),
            },
            {
                language,
            },
            { forwardTo, text },
            { excludedStates, format }
        );
    }

    /**
     * Triggers sending email to user email with all checklist handover protocols
     * where a checklist as part of the protocol was signed at the given date.
     *
     * The given date will be converted into UTC start and end datetime values based on
     * the local timezone the user is currently in.
     *
     * @param date {moment.Moment}
     * @returns {Promise<{qaReports: number, requester: {username: string, email: string}}>}
     */
    function bulkExportChecklistPDFsByDate(date) {
        const language =
            $sbLocale.current() || $sbCurrentProject.pick("language");

        return $sbActivityJobsApi.createBulkQAProtocolsJob(
            $sbCurrentProject.pick("id"),
            {
                signed_between: {
                    from: moment(date).startOf("day").utc(false).format(),
                    to: moment(date)
                        .startOf("day")
                        .add(24, "hours")
                        .utc(false)
                        .format(),
                },
            },
            {
                language,
            }
        );
    }

    function _getDeliverableSet(deliverableSelection) {
        const selectionCondition = deliverableSelection.getPreferredCondition();
        if (typeof selectionCondition === "string") {
            return selectionCondition;
        } else {
            return selectionCondition.map(({ ID, id }) => ID || id);
        }
    }

    function bulkSetTeam(
        activityTemplates,
        deliverableSelection,
        { workTeamId, confirmingTeamId }
    ) {
        const definition = {};
        if (workTeamId) {
            definition.work_team = workTeamId;
        }

        if (confirmingTeamId) {
            definition.confirmation_team = confirmingTeamId;
        }

        return _doBulkOperation(
            activityTemplates,
            deliverableSelection,
            definition
        );
    }

    function bulkUnsetTeam(activityTemplates, deliverableSelection, teamType) {
        const definition = {};
        if (teamType === "bothTeams") {
            definition.work_team = "sb::team.not-set";
            definition.confirmation_team = "sb::team.not-set";
        } else {
            definition[teamType] = "sb::team.not-set";
        }

        return _doBulkOperation(
            activityTemplates,
            deliverableSelection,
            definition
        );
    }

    function bulkResetActivities(activityTemplates, deliverableSelection) {
        const projectId = $sbCurrentProject.pick("id");
        const activity_templates = activityTemplates.map(
            ({ ID, id }) => ID || id
        );
        const deliverable_set = _getDeliverableSet(deliverableSelection);

        return $sbResetActivityDialog.show({
            title: "BULK_RESET_ACTIVITIES_SUBMIT",
            description: "BULK_RESET_ACTIVITIES_DESCRIPTION",
            projectId,
            activityTemplates: activity_templates,
            deliverableSet: deliverable_set,
        });
    }
}
