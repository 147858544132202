import moment from "moment";
import _ from "lodash";
import SbActivityState from "domain/sb_activity_state.class";
import { EndBeforeStartError } from "../../../common/errors/EndBeforeStartError";

export default function LookAheadModificationService(
    $sbLeanBoard,
    USER_INTERACTION
) {
    "ngInject";

    return {
        use: (calendar) => {
            return {
                applyModificationToActivities:
                    applyModificationToActivities.bind(this, calendar),
                makeInteractions: makeInteractions,
                applyNewDuration: applyNewDuration,
                applyNewEndDate: applyNewEndDate.bind(this, calendar),
                applyNewStartDate: applyNewStartDate.bind(this, calendar),
            };
        },
        applyModificationToActivities: applyModificationToActivities,
        makeInteractions: makeInteractions,
        applyNewDuration: applyNewDuration,
        applyNewEndDate: applyNewEndDate,
        applyNewStartDate: applyNewStartDate,
    };

    function makeInteractions(definition) {
        const interactions = [];

        const isValidDate =
            moment.isMoment(definition.date) && definition.date.isValid();
        if (isValidDate) {
            interactions.push(definition.usedDate);
        }

        if (definition.duration) {
            if (interactions.length === 0) {
                // see user interaction service -> when only duration is changed we also send the start as "changed"
                interactions.push(USER_INTERACTION.START_DATE);
            }
            interactions.push(USER_INTERACTION.DURATION);
        }

        return interactions;
    }

    function applyModificationToActivities(
        calendar,
        definition,
        allActivities
    ) {
        const interactions = makeInteractions(definition);
        const changedActivities = new Set();

        // if there is a duration interaction
        //
        if (_.includes(interactions, USER_INTERACTION.DURATION)) {
            applyNewDuration(
                _stringToFloat(definition.duration),
                allActivities
            );
            allActivities.forEach((activity) =>
                changedActivities.add(activity)
            );
        }

        if (_.includes(interactions, USER_INTERACTION.START_DATE)) {
            // only applicable for not started activities - started activities are using their actual start
            //
            const startDateChangeEnabledActivities = allActivities.filter(
                (activity) => activity.state.is(SbActivityState.NOT_STARTED)
            );
            const activities = applyNewStartDate(
                calendar,
                definition.date,
                startDateChangeEnabledActivities
            );

            activities.forEach((activity) => changedActivities.add(activity));
        }

        if (_.includes(interactions, USER_INTERACTION.END_DATE)) {
            // only applicable for not yet completed activities
            //
            const endDateChangeEnabledActivities = allActivities.filter(
                (activity) => !activity.state.isCompleted()
            );

            const activities = endDateChangeEnabledActivities.filter(
                (activity) => {
                    try {
                        return applyNewEndDate(
                            calendar,
                            definition.date,
                            activity
                        );
                    } catch {
                        return false;
                    }
                }
            );

            activities.forEach((activity) => changedActivities.add(activity));
        }

        return Array.from(changedActivities.values());
    }

    function applyNewDuration(newDuration, activities) {
        activities.forEach((activity) => {
            activity.duration = _stringToFloat(newDuration);
        });
    }

    function applyNewStartDate(calendar, newStartDate, activities) {
        activities.forEach((activity) => {
            const { start, end } = $sbLeanBoard.calcMovedDatesToStartDate(
                activity.duration,
                validDateOrDefault(newStartDate, activity.startDate),
                calendar
            );
            activity.startDate = start;
            activity.endDate = end;
        });

        return activities;
    }

    function applyNewEndDate(calendar, newEndDate, activity) {
        if (activity.state.isWorkInProgress()) {
            // if the new end is undefined it's most likely a duration only change based on the end date
            //
            if (!newEndDate || !newEndDate.isValid()) {
                // calc a new end based on current start and current duration
                //
                const { start, end } = $sbLeanBoard.calcMovedDatesToStartDate(
                    activity.duration,
                    activity.actualStart,
                    calendar
                );
                activity.startDate = start;
                activity.endDate = end;
            } else {
                // calc a new duration based on the new end date and store both duration and end date
                //
                const { start, end, duration } =
                    $sbLeanBoard.calcWorkingDuration(
                        activity.actualStart,
                        newEndDate,
                        calendar
                    );

                if (_stringToFloat(duration) <= 0) {
                    // in case the new end is earlier than the actual start -> throw
                    throw new EndBeforeStartError();
                }

                activity.duration = _stringToFloat(duration);
                activity.startDate = start;
                activity.endDate = end;
            }
        } else {
            const { start, end } = $sbLeanBoard.calcMovedDatesToEndDate(
                activity.duration,
                validDateOrDefault(newEndDate, activity.endDate),
                calendar
            );
            activity.startDate = start;
            activity.endDate = end;
        }
        return true;
    }

    function _stringToFloat(value) {
        const number = parseFloat(value);
        return isNaN(number) ? undefined : number;
    }

    function validDateOrDefault(date, fallback) {
        if (!date || !date.isValid()) {
            return moment(fallback);
        } else {
            return date;
        }
    }
}
