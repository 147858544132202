export default class ProcessTemplatesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the list of active process templates.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/process-templates`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a process template.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.category {string}    The type of template
     * @param body.code {string}    A user defined code for the process template.
     * @param [body.description] {string}    A text describing the process template.
     * @param body.language {string}    Language of the template
     * @param body.name {string}    Name of the template
     */
    create(projectId, { category, code, description, language, name }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/process-templates`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { category, code, description, language, name },
        });
    }

    /**
     * This endpoint allows to archive the process template.
     *
     * @param projectId {string}    UUID
     * @param processTemplateId {string}    The unique identifier of the processTemplate you are requesting a resource for.
     */
    delete(projectId, processTemplateId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/process-templates/${processTemplateId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }

    /**
     * Get all details of a process template revision.
     *
     * @param projectId {string}            UUID
     * @param processTemplateId {string}    The unique identifier of the processTemplate you are requesting a resource for.
     */
    getLatestRevision(projectId, processTemplateId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/process-templates/${processTemplateId}/revisions/latest`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                purpose: "asRootTemplate",
            },
        });
    }

    /**
     * This endpoint allows to create a revision of the process template.
     *
     * @param projectId {string}            UUID
     * @param processTemplateId {string}    The unique identifier of the processTemplate you are requesting a resource for.
     *
     * @param body {Object}    Request body
     * @param body.components   {array}    Components of the process template
     * @param body.edges        {array}    The connection (edge) between activities
     */
    createRevision(projectId, processTemplateId, { components, edges }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/process-templates/${processTemplateId}/revisions`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: {
                components,
                edges,
            },
        });
    }
}
