import WbsCommand from "./WBSCommand";

/**
 * Command to Rename a Node
 *
 * @param {WBS.StructureNode} selectedNode - Node to be renamed
 * @param {string} sType CODE or NAME - Type of Changed Text
 * @param {string} newText - New Text
 * @param {string} oldText - Old Text
 * @constructs RenameNodeCommand
 * @implements {WBS.WbsCommand}
 * @memberof WBS
 */
function RenameNodeCommand(selectedNode, sType, newText, oldText) {
    WbsCommand.call(
        this,
        selectedNode.stage.$sbErrorPresenter,
        selectedNode.stage.wbsApiService
    );

    /**
     * Node that is renamed
     * @type {StructureNode}
     */
    this.selectedNode = selectedNode;

    /**
     * Property that is renamed
     * @type {string}
     */
    this.sType = sType;

    /**
     * New Text of the property
     * @type {string}
     */
    this.newText = newText;

    /**
     * Old Text of the property
     * @type {string}
     */
    this.oldText = oldText;
}

/**
 * Setup prototypal inheritance.
 * RenameNodeCommand inherits from WbsCommand.
 *
 * @type {WBS.WbsCommand}
 */
RenameNodeCommand.prototype = Object.create(WbsCommand.prototype);

/**
 * @throws Error
 * @param {Object} error - Error that occurred
 */
RenameNodeCommand.prototype.errorUndo = function (error) {
    var oData = this.selectedNode.entity.getData();
    oData[this.sType] = this.oldText;

    this.selectedNode.setCode(oData.CODE);
    this.selectedNode.setName(oData.NAME);

    // MB - deactivated while setCount is undefined
    //
    //this.selectedNode.setCount(oData.nComponentUse);

    throw error;
};

/**
 * Renames the node to new name
 */
RenameNodeCommand.prototype.execute = function () {
    var oData = this.selectedNode.entity.getData();
    var projectId = oData.PROJECT_ID;

    // change the UI immediately
    //
    oData[this.sType] = this.newText;
    this.selectedNode.setCode(oData.CODE);
    this.selectedNode.setName(oData.NAME);
    //this.selectedNode.setCount(oData.nComponentUse);

    // if the update fails -> reset the text to the old state.
    this.wbsApiService
        .updateNode(projectId, oData)
        .catch(this.errorUndo.bind(this))
        .catch(this.$sbErrorPresenter.catch);
};

/**
 * Renames the node to old name
 */
RenameNodeCommand.prototype.undo = function () {
    console.log(this.selectedNode);
};

/**
 * Redo the renaming
 */
RenameNodeCommand.prototype.redo = function () {
    this.execute();
};

export default RenameNodeCommand;
