import _ from "lodash";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";
import validator from "./validation_helpers";

export default function (
    $q,
    $sbDeliverableJobsApi,
    $sbCurrentProject,
    $sbJobApi
) {
    "ngInject";

    /////////////////////
    //
    //      API
    //
    /////////////////////

    const service = {
        forAssociatedDeliverablesInProject:
            pullUpdateForAssociatedDeliverablesInProject,
        forDeliverables: pullUpdateForDeliverables,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function pullUpdateForAssociatedDeliverablesInProject(processTemplateId) {
        const allDeliverablesInProjectQuery =
            _allAssociatedDeliverablesInCurrentProject(processTemplateId);
        const allDeliverablesInProjectQueryString =
            allDeliverablesInProjectQuery.get();

        const alreadyMigratedDeliverables =
            extendQueryToMatchAlreadyMigratedDeliverables(
                allDeliverablesInProjectQueryString
            );

        return $sbDeliverableJobsApi
            .createAssignProcessJob($sbCurrentProject.pick("id"), {
                strategy: $sbDeliverableJobsApi.ASSIGN_STRATEGY.UPDATE,
                deliverable_set: alreadyMigratedDeliverables,
                process_template_id: processTemplateId,
            })
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            });
    }

    function pullUpdateForDeliverables(processTemplateId, deliverables) {
        if (!_.isArray(deliverables) && !_.isString(deliverables)) {
            throw new Error(
                "Invalid parameter. Wrong type for parameter 'deliverables'"
            );
        }

        let alreadyMigratedDeliverables = [];

        if (_.isArray(deliverables)) {
            validator.throwOnMissingKeys(deliverables, [
                "ID",
                "PI_TEMPLATE_ID",
                "TEMPLATE_REVISION",
            ]);

            alreadyMigratedDeliverables = _filterForApplicableDeliverables(
                processTemplateId,
                deliverables.filter(isMigrated)
            );

            if (_.isEmpty(alreadyMigratedDeliverables)) {
                return $q.resolve({});
            }
        }

        if (_.isString(deliverables)) {
            alreadyMigratedDeliverables =
                extendQueryToMatchAlreadyMigratedDeliverables(
                    _extendQueryToMatchOnlyApplicableDeliverables(
                        processTemplateId,
                        deliverables
                    )
                );
        }

        return $sbDeliverableJobsApi
            .createAssignProcessJob($sbCurrentProject.pick("id"), {
                strategy: $sbDeliverableJobsApi.ASSIGN_STRATEGY.UPDATE,
                deliverable_set: alreadyMigratedDeliverables,
                process_template_id: processTemplateId,
            })
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            });
    }

    /**
     *
     * Filter for all deliverables which have the process template already associated to them.
     * Return the list of deliverable Ids (UUID).
     *
     * @param {string} processTemplateId - UUID
     *
     * @param {Object[]} deliverables
     * @param {String} deliverables.ID - UUID
     * @param {String} deliverables.PI_TEMPLATE_ID - UUID
     * @param {number|null} deliverables.TEMPLATE_REVISION
     *
     * @returns {string[]}
     * @private
     */
    function _filterForApplicableDeliverables(processTemplateId, deliverables) {
        return (deliverables || [])
            .filter(function hasSameProcess(deliverable) {
                return deliverable.PI_TEMPLATE_ID === processTemplateId;
            })
            .map(function (deliverable) {
                return deliverable.ID;
            });
    }

    function _extendQueryToMatchOnlyApplicableDeliverables(
        processTemplateId,
        queryString
    ) {
        const alreadyAssociatedProcessTemplateFilter = new ODataFilterFactory()
            .and()
            .eq("TEMPLATE_ID", processTemplateId);

        return queryString + " " + alreadyAssociatedProcessTemplateFilter.get();
    }

    function _allAssociatedDeliverablesInCurrentProject(processTemplateId) {
        return new ODataFilterFactory()
            .eq("TEMPLATE_ID", processTemplateId)
            .and()
            .eq("PROJECT_ID", $sbCurrentProject.pick("id"));
    }

    function isMigrated(deliverable) {
        return deliverable.TEMPLATE_REVISION !== null;
    }

    function extendQueryToMatchAlreadyMigratedDeliverables(queryString) {
        var alreadyMigratedDeliverablesFilter = new ODataFilterFactory()
            .and()
            .ne("TEMPLATE_REVISION", null);

        return queryString + " " + alreadyMigratedDeliverablesFilter.get();
    }
}
