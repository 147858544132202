import VirtualODataLoader from "./virtual_odata_loader.class";

export default function (
    $log,
    $q,
    SbDeliverable,
    $sbTemplate,
    $sbDomain,
    $sbStructure,
    $sbCurrentProject,
    $sbReportingDeliverablesApi,
    $sbErrorPresenter
) {
    "ngInject";
    var DeliverablesLazyLoader = function (filter, orderBy) {
        VirtualODataLoader.call(this, filter, orderBy);

        this.structureMapper = $sbStructure.asMap($sbCurrentProject.pick("id"));
        this.templateMapper = $sbTemplate.asMap($sbCurrentProject.pick("id"));
    };

    DeliverablesLazyLoader.prototype = Object.create(
        VirtualODataLoader.prototype
    );

    /**
     * @inheritDoc
     *
     * @param {Object} query
     * @param {ODataFilterFactory} query.filter
     * @param {Object} query.orderBy
     * @param {Number} from - load data block from number
     * @param {Number} to - to number
     * @returns {$q} - the resolved OData request with the data array
     */
    DeliverablesLazyLoader.prototype.loadODataResource = function (
        { filter, orderBy = { direction: 1, criteria: "CODE" } },
        from,
        to
    ) {
        const selects = [
            "ID",
            "STRUCTURE_ID",
            "PROJECT_ID",
            "TEMPLATE_ID",
            "TEMPLATE_REVISION",
            "NAME",
            "CODE",
            "DESC",
            "EARLIEST_START",
            "LATEST_START",
            "EARLIEST_END",
            "LATEST_END",
            "PLANNED_START",
            "PLANNED_END",
            "ACTIVITIES_BEHIND",
            "ISSUE_CREATE_TIME",
            "ISSUE_RESOLVE_TIME",
            "STATE_CHANGE_TIME",
            "ACTIVITIES_NOT_STARTED",
            "ACTIVITIES_STARTED",
            "ACTIVITIES_DONE",
            "ACTIVITIES_WAITING_FOR_CONFIRMATION",
            "ACTIVITIES_CONFIRMED",
            "ACTIVITIES_REJECTED",
            "CLAIM_QUANTITY",
            "OBSTRUCTION_QUANTITY",
            "AREA_MANAGER_USER_NAME",
        ];

        return $sbReportingDeliverablesApi
            .getCollection($sbCurrentProject.pick("id"), {
                filter: filter ? filter.get() : undefined,
                select: selects.join(","),
                orderby: `${orderBy.criteria} ${
                    orderBy.direction < 0 ? "desc" : "asc"
                }`,
                top: to - from,
                skip: from,
            })
            .then(({ meta, deliverables = [] }) => {
                return {
                    count: meta.count_all,
                    items: deliverables.map((data) =>
                        $sbDomain.deliverableFactory.createFromODataLike(data)
                    ),
                };
            })
            .then(({ count, items }) => {
                return $q
                    .all([this.structureMapper, this.templateMapper])
                    .then(([structure, templates]) => {
                        items.forEach((item) => {
                            item.structure = structure.get(item.structureId);
                            item.template = templates.get(item.piTemplateId);
                        });

                        return {
                            count,
                            items,
                        };
                    });
            })
            .then(function (response) {
                $log.log("lazy loader for DELIVERABLE_PROGRESS", response);
                return response;
            })
            .catch(function (e) {
                $sbErrorPresenter.catch(e); // handle the error
                throw e; // throw again to inform the lazy loader
            });
    };

    return DeliverablesLazyLoader;
}
