import htmlTemplate from "./deliverable_row.html";
import DeliverableRowCtrl from "./deliverable_row.controller";

export default {
    name: "sbDeliverableRow",
    templateUrl: htmlTemplate,
    bindings: {
        deliverable: "<",
        timezone: "<",
        noProgress: "<",
    },
    controllerAs: "row",
    controller: DeliverableRowCtrl,
};
