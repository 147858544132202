import angular from "angular";
import htmlTemplate from "./sb_process_template.html";

export default angular
    .module("sbApp.common.components.processTemplate", [])
    .component("sbProcessTemplate", {
        templateUrl: htmlTemplate,
        bindings: {
            template: "<",
        },
    });
