import angular from "angular";
import _ from "lodash";
import EditOrAddTeamDialogCtrl from "./edit_or_add_team.controller";
import dialogTemplate from "./edit_or_add_team.tmpl.html";

export default angular
    .module("sbApp.sbEditOrAddTeamDialog", [])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("editOrAddTeam", {
            methods: [
                "title",
                "titleIcon",
                "text",
                "team",
                "ok",
                "cancel",
                "class",
                "ariaLabel",
            ],
            options: function selectDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: EditOrAddTeamDialogCtrl,
                    controllerAs: "editOrAddTeam",
                    bindToController: true,
                };
            },
        });
    });
