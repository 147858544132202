import _ from "lodash";
import ModifiedInConcurrentSessionError from "../../common/errors/ModifiedInConcurrentSessionError";

export default function DialogsService(
    $mdDialog,
    $filter,
    $sbTemplate,
    $sbStructure,
    $sbActivities,
    $sbProject,
    $sbTeam,
    $translate,
    $q,
    $window,
    $sbFeatureDecisions,
    EnvironmentFlags
) {
    "ngInject";

    var naturalSort = $filter("naturalSort");

    return {
        openAssignTemplateDialog: openAssignTemplateDialog,
        openAssignStructureDialog: openAssignStructureDialog,
        openBulkStateChangeDialog: openBulkStateChangeDialog,
        openBulkCreateNoteDialog: openBulkCreateNoteDialog,
        openBulkChecklistPdfExport: openBulkChecklistPdfExport,
        openBulkChecklistPdfExportByDate,
        openModifiedInConcurrentSessionDialog:
            openModifiedInConcurrentSessionDialog,
        pickFromBulkUpdateChoices: pickFromBulkUpdateChoices,
    };

    function _filterEmptyTemplates(templates) {
        return templates.filter(function (template) {
            return template.ACTIVITIES_COUNT > 0;
        });
    }

    function _sortByCode(templates) {
        return naturalSort(templates, "CODE");
    }

    function _fetchNonEmptyTemplatesSortedByCode() {
        return $sbTemplate
            .getTemplates()
            .then(_filterEmptyTemplates)
            .then(_sortByCode);
    }

    function _createAssignTemplateDialog(templates) {
        const warningText = $sbFeatureDecisions.isFeatureEnabled(
            EnvironmentFlags.AllowTemplateOverwrite
        )
            ? "DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_MESSAGE"
            : undefined;

        return $mdDialog
            .templateSelector()
            .title("DIALOG_ASSIGN_TEMPLATE_TITLE")
            .searchBarPlaceholderKey(
                "DIALOG_ASSIGN_TEMPLATE_SEARCH_PLACEHOLDER"
            )
            .text("DIALOG_ASSIGN_TEMPLATE_TEXT")
            .warningText(warningText)
            .confirmCheckboxText(
                "DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_CHECKBOX_MESSAGE"
            )
            .ok("ACTION_ASSIGN_TEMPLATE")
            .templates(templates)
            .clickOutsideToClose(true)
            .escapeToClose(true);
    }

    function _createAssignStructureDialog(structureLevels) {
        return $mdDialog
            .assignStructure()
            .title("DIALOG_ASSIGN_STRUCTURE_TITLE")
            .ok("ACTION_ASSIGN_STRUCTURE")
            .structureLevels(structureLevels)
            .placeholder("_FILTER_STRUCTURE_TEXT_PLACEHOLDER")
            .clickOutsideToClose(true)
            .escapeToClose(true);
    }

    function _fetchStructureLevels() {
        return $sbStructure.getSortedMappedList(
            $sbProject.getCurrentProjectId()
        );
    }

    function openAssignStructureDialog($event) {
        return _fetchStructureLevels()
            .then(_createAssignStructureDialog)
            .then(function (assignStructureDialog) {
                return $mdDialog.show(
                    assignStructureDialog.targetEvent($event)
                );
            });
    }

    function openAssignTemplateDialog($event) {
        return _fetchNonEmptyTemplatesSortedByCode()
            .then(_createAssignTemplateDialog)
            .then(function (assignTemplateDialog) {
                return $mdDialog.show(assignTemplateDialog.targetEvent($event));
            });
    }

    function openBulkStateChangeDialog(
        $event,
        deliverableSelection,
        templateId
    ) {
        return $mdDialog.show(
            $mdDialog
                .bulkStateChange({
                    targetEvent: $event,
                    openFrom: $event.target,
                    closeTo: $event.target,
                })
                .templateId(templateId)
                .deliverableSelection(deliverableSelection)
        );
    }

    function openBulkCreateNoteDialog($event, templateId) {
        return $mdDialog.show(
            $mdDialog
                .bulkCreateNote({
                    targetEvent: $event,
                    openFrom: $event.target,
                    closeTo: $event.target,
                })
                .templateId(templateId)
        );
    }

    function openBulkChecklistPdfExport(deliverableSelection, templateId) {
        return $mdDialog.show(
            $mdDialog
                .bulkChecklistPdfExport()
                .deliverableSelection(deliverableSelection)
                .templateId(templateId)
        );
    }

    function openBulkChecklistPdfExportByDate() {
        return $mdDialog.show($mdDialog.bulkChecklistPdfExport());
    }

    /**
     * Opens blocked session dialog. Extracts the user information from the details in the "ParseableError".
     *
     * @param {ParseableError} error - A parsable error with message "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION"
     * @param {string} classType - A CSS class to attach to the dialog to style for plan, work, review
     * @returns {Promise<undefined>}
     */
    function openModifiedInConcurrentSessionDialog(error) {
        const userIds = _extractUserIdsFromErrorMessage(error);

        return $sbTeam
            .getUsers($sbProject.getCurrentProjectId())
            .then(function (users) {
                const usersRunningSession = _listUsersRunningSession(
                    userIds,
                    users
                );
                const modifiedInConcurrentSessionError =
                    new ModifiedInConcurrentSessionError();

                return _getTranslationsForDialog(
                    usersRunningSession,
                    modifiedInConcurrentSessionError
                )
                    .then(function (translations) {
                        const confirm = $mdDialog
                            .confirm()
                            .title(modifiedInConcurrentSessionError.title)
                            .content(translations)
                            .ok("_SEND_MAIL")
                            .cancel("_CANCEL");

                        return $mdDialog.show(confirm);
                    })
                    .then(function () {
                        $window.location.href =
                            _formMailToString(usersRunningSession);
                    });
            });
    }

    /**
     * Extract user identifiers from parsed error details.
     * This can only handle "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION" error type.
     *
     * @param {ParseableError} error
     * @returns {string[]}
     */
    function _extractUserIdsFromErrorMessage(error) {
        const responseObject = error.parseResponseAsJSON();
        const userNamesString = responseObject.details.split(
            "Component blocked by "
        )[1];
        return userNamesString.split(", ");
    }

    function _listUsersRunningSession(userIds, users) {
        // transform to [{"username": "test"}]
        //
        var sessionUsers = _.map(userIds, function (userId) {
            return _.set({}, "username", userId);
        });

        // will return all user object which are also in the sessionUser array by username
        //
        return _.intersectionBy(users, sessionUsers, "username");
    }

    function _getTranslationsForDialog(users, error) {
        return $translate(error.message, {
            users: _formUserDisplayNamesString(users),
        });
    }

    function _formUserDisplayNamesString(users) {
        var displayNames = users.map(function (user) {
            return user.displayName;
        });

        return displayNames.join(", ");
    }

    function _formMailToString(_usersRunningSessions) {
        if (
            !_.isArray(_usersRunningSessions) ||
            (_.isArray(_usersRunningSessions) &&
                _.isEmpty(_usersRunningSessions))
        ) {
            return "";
        }

        var mails = _usersRunningSessions.map(function (user) {
            return user.mail;
        });

        return "mailto:" + mails.join(";") + ";";
    }

    function pickFromBulkUpdateChoices($event, triggers) {
        return $mdDialog.show(
            $mdDialog
                .pickFromChoices({
                    targetEvent: $event,
                    openFrom: $event.target,
                    closeTo: $event.target,
                })
                .choices({
                    BULK_STATE_CHANGE: {
                        icon1: "mdi mdi-sync mdi-rotate-90 smaller blue",
                        title: "DIALOG_BULK_UPDATE_OPTION_UPDATE_PROGRESS_TITLE",
                    },
                    BULK_CREATE_NOTE: {
                        icon1: "sb-progress-option--cannot-start-icon sb-icon-attach_mobile smaller blue",
                        title: "DIALOG_BULK_UPDATE_OPTION_CREATE_NOTE_TITLE",
                    },
                })
                .onPick(function onChoicePicked(key) {
                    if (key === "BULK_STATE_CHANGE") {
                        triggers.bulkStateChange();
                    }

                    if (key === "BULK_CREATE_NOTE") {
                        triggers.bulkNoteCreation();
                    }
                })
        );
    }
}
