import angular from "angular";
import html from "./sb_help_link.html";
import ngTranslate from "angular-translate";

export default angular
    .module("sbApp.sbHelpLink", [ngTranslate])
    .component("sbHelpLink", {
        templateUrl: html,
        bindings: {
            trackBy: "<?",
            url: "@",
        },
        controllerAs: "helpLink",
        controller: angular.noop,
    });
