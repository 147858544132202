import SbTeam from "../../../domain/sb_team.class";

export default function ProjectCurrentSettingsService(
    $sbProject,
    $sbMembership,
    $sbProjectSettingsApi
) {
    "ngInject";

    let settings;

    return {
        clear: () => (settings = undefined),
        getConfirmationTeamSettings: _createConfirmationTeamSettings,
        getGeolocationSetting: _getGeolocationSetting,
    };

    async function _getGeolocationSetting() {
        if (!settings) {
            const projectId = $sbProject.getCurrent().id;
            settings = await $sbProjectSettingsApi.get(projectId);
        }
        return settings.inspect_app_config.geolocation_permission;
    }

    /**
     * Initialize the [projectSettings] from current active project
     */
    async function _createConfirmationTeamSettings() {
        if (!settings) {
            const projectId = $sbProject.getCurrent().id;
            settings = await $sbProjectSettingsApi.get(projectId);
        }

        const qualityFeatureConfig = settings.quality_feature_config;
        return {
            obstruction: {
                isOptional:
                    qualityFeatureConfig.obstruction_workflow.is_optional,
                defaultTeamId: _normalizeConfirmationTeamId(
                    qualityFeatureConfig.obstruction_workflow
                        .default_confirmation_team_id
                ),
            },
            qualityIssue: {
                isOptional: qualityFeatureConfig.quality_workflow.is_optional,
                defaultTeamId: _normalizeConfirmationTeamId(
                    qualityFeatureConfig.quality_workflow
                        .default_confirmation_team_id
                ),
            },
        };
    }

    /**
     * Maps the [teamId] from a constant to its ID if needed
     */
    function _normalizeConfirmationTeamId(teamId) {
        switch (teamId) {
            case SbTeam.ID_REPORT_TEAM:
                return $sbMembership.current().team.id;
            case SbTeam.ID_NOT_SET:
                return teamId;
            default:
                return teamId;
        }
    }
}
