export default class CostPackageMembershipsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the project cost package membership.
     *
     * @param costPackageId {string}    UUID
     */
    getCollection(costPackageId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}/memberships`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns the cost package membership by id.
     *
     * @param costPackageId {string}    UUID
     * @param membershipId {string}    The unique identifier of the membership you are requesting a resource for.
     */
    get(costPackageId, membershipId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}/memberships/${membershipId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a new cost package membership in the project.
     *
     * @param costPackageId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.user_id {string}    The unique identifier of the user.
     * @param body.role {string}    The name of the role this membership will use
     */
    create(costPackageId, { user_id, role }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}/memberships`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { user_id, role },
        });
    }

    /**
     * This endpoint allows to remove a cost package membership.
     *
     * @param costPackageId {string}    UUID
     * @param membershipId {string}    The unique identifier of the membership you are requesting a resource for.
     */
    delete(costPackageId, membershipId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/cost-packages/${costPackageId}/memberships/${membershipId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
