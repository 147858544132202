export default class DependencyJobsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;

        this.JOB_TYPES = {
            CREATE_DELIVERABLE_DEPENDENCY: "CREATE_DELIVERABLE_DEPENDENCY",
            DELETE_DELIVERABLE_DEPENDENCY: "DELETE_DELIVERABLE_DEPENDENCY",
            CREATE_ACTIVITY_DEPENDENCY: "CREATE_ACTIVITY_DEPENDENCY",
            DELETE_ACTIVITY_DEPENDENCY: "DELETE_ACTIVITY_DEPENDENCY",
        };
    }

    /**
     * This endpoint creates a new dependency job in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     */
    create(projectId, body) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/dependency-jobs`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: body,
        });
    }

    /**
     * This endpoint creates a new dependency job in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     */
    createAsync(projectId, body) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/dependency-jobs`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "POST",
            header: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }).then((resp) => {
            const jobId = resp.getResponseHeader("x-job-id");
            return { jobId };
        });
    }

    /**
     * Create an 'CREATE_DELIVERABLE_DEPENDENCY' dependency job
     *
     * @param projectId {string}              UUID
     *
     * @param body {Object}                   Request body
     * @param body.source_deliverable_id {string}   UUID of the source deliverable
     * @param body.target_deliverable_id {string}   UUID of the target deliverable
     *
     * @returns {Promise<{}>}
     */
    createAddDeliverableDependencyJob(
        projectId,
        { source_deliverable_id, target_deliverable_id }
    ) {
        return this.createAsync(projectId, {
            type: this.JOB_TYPES.CREATE_DELIVERABLE_DEPENDENCY,
            definition: {
                origin: "MANUAL",
                source_deliverable_id,
                target_deliverable_id,
            },
        });
    }

    /**
     * Create an 'CREATE_ACTIVITY_DEPENDENCY' dependency job
     *
     * @param projectId {string}              UUID
     *
     * @param definition {Object[]} List of (source_activity_id, target_activity_id) dependency pairs
     *
     * @returns {Promise<{}>}
     */
    createAddActivityDependencyJob(projectId, definition) {
        return this.createAsync(projectId, {
            type: this.JOB_TYPES.CREATE_ACTIVITY_DEPENDENCY,
            definition,
        });
    }

    /**
     * Create an 'DELETE_ACTIVITY_DEPENDENCY' dependency job
     *
     * @param projectId {string}              UUID
     *
     * @param body {Object}                   Request body
     * @param body.source_activity_id {string}      UUID of the source activity
     * @param body.source_deliverable_id {string}   UUID of the source deliverable
     * @param body.target_activity_id {string}      UUID of the target activity
     * @param body.target_deliverable_id {string}   UUID of the target deliverable
     *
     * @returns {Promise<{}>}
     */
    createRemoveActivityDependencyJob(
        projectId,
        {
            source_activity_id,
            source_deliverable_id,
            target_activity_id,
            target_deliverable_id,
        }
    ) {
        return this.create(projectId, {
            type: this.JOB_TYPES.DELETE_ACTIVITY_DEPENDENCY,
            definition: {
                source_activity_id,
                source_deliverable_id,
                target_activity_id,
                target_deliverable_id,
            },
        });
    }

    /**
     * Create an 'DELETE_ACTIVITY_DEPENDENCY' dependency job
     *
     * @param projectId {string}              UUID
     *
     * @param body {Object}                   Request body
     * @param body.source_deliverable_id {string}   UUID of the source deliverable
     * @param body.target_deliverable_id {string}   UUID of the target deliverable
     *
     * @returns {Promise<{}>}
     */
    createRemoveDeliverableDependencyJob(
        projectId,
        { source_deliverable_id, target_deliverable_id }
    ) {
        return this.create(projectId, {
            type: this.JOB_TYPES.DELETE_DELIVERABLE_DEPENDENCY,
            definition: { source_deliverable_id, target_deliverable_id },
        });
    }
}
