export default class StructureNodeJobsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint creates a new structure node job in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     */
    create(projectId, body) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/structure-node-jobs`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: body,
        });
    }

    /**
     * This endpoint returns QR Codes as PDF representation of the structure nodes in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     */
    print(projectId, { definition }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/structure-node-jobs`;
        return this.$sbRequest.pdf({
            url: endpointUrl,
            method: "POST",
            body: {
                type: StructureNodeJobsWebService.JOB_TYPES.CREATE_QR_CODES,
                definition,
            },
        });
    }

    /**
     * This endpoint returns a PDF with QR Codes of the structure nodes in the project for printing.
     *
     * @param projectId {string}    UUID
     * @param structureNodes {string[]}    Array of UUIDs
     * @param options {Object}
     * @param options.language {string}
     * @param options.layout {string}
     *
     * @return {Promise}
     */
    printQrCodes(projectId, structureNodes, { language, layout }) {
        return this.print(projectId, {
            definition: {
                options: {
                    language,
                    layout,
                },
                structure_nodes: structureNodes,
            },
        });
    }

    /**
     * This endpoint returns a PDF with QR Codes of the structure nodes in the project for printing.
     *
     * @param projectId {string}    UUID
     *
     * @return {Promise}
     */
    generateCodes(projectId) {
        return this.create(projectId, {
            type: StructureNodeJobsWebService.JOB_TYPES
                .GENERATED_STRUCTURE_CODES,
        });
    }

    /**
     * This endpoint returns a PDF with QR Codes of the structure nodes in the project for printing.
     *
     * @param projectId {string}    UUID
     *
     * @return {Promise}
     */
    mergeCodes(projectId) {
        return this.create(projectId, {
            type: StructureNodeJobsWebService.JOB_TYPES.MERGE_STRUCTURE,
        });
    }
}

StructureNodeJobsWebService.JOB_TYPES = {
    CREATE_QR_CODES: "CREATE_QR_CODES",
    GENERATED_STRUCTURE_CODES: "GENERATED_STRUCTURE_CODES",
    MERGE_STRUCTURE: "MERGE_STRUCTURE",
};
