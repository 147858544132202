import angular from "angular";
import inspectDeliverablesRouteConfig from "./inspect_deliverables.route";
import InspectDeliverablesCtrl from "./inspect_deliverables.controller";
import inspectDeliverablesService from "./services/inspect_deliverables.service";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.inspectDeliverables", [
        "ngMaterial",
        "ui.router",
        "sbApp.autoFocus",
        "sbApp.constants",
    ])
    .config(inspectDeliverablesRouteConfig)
    .controller("InspectDeliverablesCtrl", InspectDeliverablesCtrl)
    .factory("inspectDeliverablesService", inspectDeliverablesService);

/* declaration */
