import angular from "angular";
import htmlTemplate from "./progress_options.tmpl.html";
import componentCtrl from "./progress_options.controller";
import progressOptionsItemModule from "./progress_options_item.component";

export default angular
    .module("sbApp.common.components.progressOptions", [
        progressOptionsItemModule.name,
    ])
    .component("sbProgressOptions", {
        templateUrl: htmlTemplate,
        transclude: true,
        bindings: {
            changeDisabled: "<",
            showTooltip: "<",
            selected: "<",
            onChange: "&",
        },
        controller: componentCtrl,
    });
