import moment from "moment";
import _ from "lodash";
import naturalSortFn from "../../../../common/filters/natural_sort.pojo";

export default function AssignTypeCtrl(
    $sbTeam,
    $sbProject,
    $sbPerformanceApi,
    $mdDialog,
    $log,
    warningText
) {
    "ngInject";
    var vm = this;
    var projectId;

    vm.dialog = $mdDialog;
    vm.warningText = warningText;
    vm.searchText = "";
    vm.selectedType = undefined;

    vm.types = [];

    vm.$onInit = $onInit;
    vm.suggestTypes = suggestTypes;
    vm.submit = submit;
    vm.onUnassignedCheck = onUnassignedCheck;
    vm.isValidDataForSubmit = isValidDataForSubmit;
    vm.isUnassignedChecked = false;

    function $onInit() {
        projectId = $sbProject.getCurrentProjectId();

        return fetchNumOfDeliverablesByType(projectId)
            .then((types) => {
                vm.types = _sortedTypes(types);
            })
            .catch((err) => {
                $log.error(err);
            });
    }

    function suggestTypes(searchText) {
        return _filterTypeByName(searchText, vm.types);
    }

    function submit(selectedType, searchText) {
        vm.dialog.hide({
            type: _.get(selectedType, "name", searchText),
        });
    }

    function onUnassignedCheck() {
        vm.searchText = "";
        vm.selectedType = undefined;
    }

    function isValidDataForSubmit() {
        return vm.searchText || vm.selectedType || vm.isUnassignedChecked;
    }
    function _sortedTypes(types) {
        return types.sort((first, second) => {
            return naturalSortFn(first.count, second.count);
        });
    }

    function _filterTypeByName(queryText, types) {
        queryText = _.upperCase(queryText);
        return _.filter(types, function searchProperties(type) {
            return _.includes(_.upperCase(type.name), queryText);
        });
    }

    /**
     * Fetch all deliverable types of the project and a count of
     * how many deliverables have that type assigned.
     *
     * @param projectId {string}
     * @returns {{name: string, count: number}[]}
     */
    function fetchNumOfDeliverablesByType(projectId) {
        return $sbPerformanceApi.getCollection(projectId, {
            date_range: {
                start_date: Number.NEGATIVE_INFINITY.toString(),
                end_date: moment().endOf("day").format(),
            },
            requests: [
                {
                    metric: {
                        expression: "sb::count-deliverable",
                    },
                    dimension: {
                        name: "sb::deliverable-type",
                    },
                    pivot: {
                        metric: {
                            expression: "sb::count-deliverable",
                        },
                    },
                },
            ],
        });
    }
}
