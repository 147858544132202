import moment from "moment";

/**
 * Create a Note
 *
 * @constructor
 */
function Note(id, text) {
    this.id = id;
    this.text = text;
    this.state = Note.STATE_OPEN_ENUM;
    this.type = null;
    this.createTime = null;

    // @type {id: string}[]
    this.images = [];

    // @type {id: string, name: string}[]
    this.attachments = [];

    // the team that is responsible to resolve the note
    this.responsibleTeam = null;

    // the team that is responsible to resolve the note
    this.confirmationTeam = null;

    // the assigned base component
    this.component = null;

    // the parent deliverable of the assigned base component
    this._deliverable = null;

    // the odata representation coming from server
    this.__odataSource = null;
}

Note.INFORMATION = "info";
Note.QUALITY_ISSUE = "quality_issue";
Note.OBSTRUCTION = "obstruction";

Note.TYPE_QUALITY_ISSUE_ENUM = "QUALITY_ISSUE";
Note.TYPE_OBSTRUCTION_ENUM = "OBSTRUCTION";
Note.TYPE_INFO_ENUM = "INFO";

/**
 * @deprecated in favor of Note.STATE_OPEN_ENUM
 */
Note.STATE_OPEN = "A";

/**
 * @deprecated in favor of Note.STATE_CLOSED_ENUM
 */
Note.STATE_CLOSED = "D";

Note.STATE_OPEN_ENUM = "OPEN";
Note.STATE_CLOSED_ENUM = "CLOSED";
Note.STATE_REMOVED_ENUM = "REMOVED";
Note.STATE_WAITING_FOR_CONFIRMATION_ENUM = "WAITING_FOR_CONFIRMATION";
Note.STATE_CONFIRMED_ENUM = "CONFIRMED";
Note.STATE_REJECTED_ENUM = "REJECTED";

const STATES_AVAILABLE = [
    Note.STATE_OPEN,
    Note.STATE_CLOSED,
    Note.STATE_OPEN_ENUM,
    Note.STATE_CLOSED_ENUM,
    Note.STATE_REMOVED_ENUM,
    Note.STATE_WAITING_FOR_CONFIRMATION_ENUM,
    Note.STATE_CONFIRMED_ENUM,
    Note.STATE_REJECTED_ENUM,
];

Note.ISSUE_TEST_REGEX = new RegExp(
    `(${Note.OBSTRUCTION}|${Note.QUALITY_ISSUE}|${Note.TYPE_QUALITY_ISSUE_ENUM}|${Note.TYPE_OBSTRUCTION_ENUM})`,
    "i"
);

Note.ODATA_PROPERTY_MAP = {
    ID: "id",
    STATE: "state",
    TYPE_NAME: "type",
    TEXT: "text",
    CREATION_TIMESTAMP: "createTime",
};

Note.isFinished = function ({ state, type }) {
    if (!state || !type) {
        return;
    }

    if (type === Note.TYPE_INFO_ENUM) {
        return;
    }

    return (
        state === Note.STATE_CLOSED ||
        state === Note.STATE_CLOSED_ENUM ||
        state === Note.STATE_CONFIRMED_ENUM
    );
};

Note.prototype.isIssue = function () {
    return Note.ISSUE_TEST_REGEX.test(this.type);
};

Note.prototype.setState = function (state) {
    if (STATES_AVAILABLE.indexOf(state) > -1) {
        this.state = state;
    } else {
        throw new Error(
            "ValueError - The state of a note has to be one of: " +
                STATES_AVAILABLE.join(", ")
        );
    }
    return this;
};

Note.prototype.getImages = function () {
    return this.images || [];
};

Note.prototype.getAttachments = function () {
    return this.attachments || [];
};

/**
 * Set the referenced component of the note. So the component (activity/group/deliverable) where
 * this note is located on. (It can be identical with the _deliverable property)
 *
 * @param {BaseComponent} sbBaseComponent
 * @returns {Note}
 */
Note.prototype.setAssignedComponent = function (sbBaseComponent) {
    this.component = sbBaseComponent;
    return this;
};

Note.prototype.getAssignedComponent = function () {
    return this.component;
};

/**
 * Set the type of issue. Should be one of:
 *  - Note.INFORMATION = "info";
 *  - Note.QUALITY_ISSUE = "quality_issue";
 *  - Note.OBSTRUCTION = "obstruction"
 *
 * @param {string} type
 * @returns {Note}
 */
Note.prototype.setType = function (type) {
    this.type = type;
    return this;
};

/**
 * Get the responsible team
 *
 * @return {SbTeam}
 */
Note.prototype.getResponsibleTeam = function () {
    return this.responsibleTeam;
};

/**
 * Set the responsible team
 *  -> Best use SbTeam.createUnrestrictedTeam() to indicate a "no team" assignment
 *  -> setting it to null or undefined means "unknown" assignment
 *
 * @param {SbTeam} team
 * @return {Note}
 */
Note.prototype.setResponsibleTeam = function (team) {
    this.responsibleTeam = team;
    return this;
};

/**
 * Get the confirmation team
 *
 * @return {SbTeam}
 */
Note.prototype.getConfirmationTeam = function () {
    return this.confirmationTeam;
};

/**
 * Set the responsible team
 *  -> Best use SbTeam.createUnrestrictedTeam() to indicate a "no team" assignment
 *  -> setting it to null or undefined means "unknown" assignment
 *
 * @param {SbTeam} team
 * @return {Note}
 */
Note.prototype.setConfirmationTeam = function (team) {
    this.confirmationTeam = team;
    return this;
};

/**
 * Find a suitable id for the responsible team
 * - supports "unknown" assignment -> null
 * and
 * - SbTeam assignment -> id
 *
 * @return {number|null}
 */
Note.prototype.findResponsibleTeamId = function () {
    return this.responsibleTeam ? this.responsibleTeam.id : null;
};

/**
 * Find a suitable id for the confirming team
 * - SbTeam assignment -> id
 *
 * @return {number|null}
 */
Note.prototype.findConfirmationTeamId = function () {
    return this.confirmationTeam ? this.confirmationTeam.id : null;
};

/**
 * Set the date and time when this issue was created.
 *
 * @param {moment} createMoment
 * @returns {Note}
 */
Note.prototype.setCreateTime = function (createMoment) {
    if (moment.isMoment(createMoment) && createMoment.isValid()) {
        this.createTime = createMoment;
    } else {
        console.warn(
            "Note.prototype.setCreateTime has to be called with a valid moment but got: ",
            createMoment
        );
    }
    return this;
};

/**
 * Get the corresponding deliverable of the note.It's not the directly assigned component
 * but the deliverable that is covering the note.
 *
 * @returns {null|Deliverable|*}
 */
Note.prototype.getDeliverable = function () {
    return this._deliverable;
};

/**
 * Set the corresponding deliverable of the note. It's not the directly assigned component
 * but the deliverable that is covering the note.
 *
 * @param {Deliverable} sbDeliverable
 * @returns {Note}
 */
Note.prototype.setDeliverable = function (sbDeliverable) {
    this._deliverable = sbDeliverable;
    return this;
};

export default Note;
