import angular from "angular";
import htmlTemplate from "./sb_deliverable_details.html";
import DeliverableDetailsCtrl from "./sb_deliverable_details.controller";
import "common/dialog/changeDateTime/change-datetime.dialog";
export default angular
    .module("sbApp.sbDeliverableDetails", [
        "sbApp.sbChangeDatetimeDialog",
        "sbApp.constants",
        "sbApp.services",
    ])
    .controller("DeliverableDetailsCtrl", DeliverableDetailsCtrl)
    .component("sbDeliverableDetails", {
        templateUrl: htmlTemplate,
        bindings: {
            deliverable: "<",
            isLoading: "<",
        },
        controller: "DeliverableDetailsCtrl",
    });
