import angular, { toJson } from "angular";

// needed to make Amplify work on IE11
//
import "core-js/modules/es.promise";
import "core-js/modules/es.object.assign";
import "core-js/modules/es.typed-array.uint32-array";
import "core-js/modules/es.typed-array.from";

import authenticationConfig from "./authentication.config";
import authenticationProvider from "./authentication.provider";
import authStoreService from "./auth-store.service";
import passwordPolicyService from "./password-policy.service";
import User from "./user/User";

// TODO: RIEMI(2019-08-20) - Move custom errors to core module
//
import NewPasswordRequiredError from "../../common/errors/NewPasswordRequiredError";
import PasswordResetRequiredError from "../../common/errors/PasswordResetRequiredError";
import AuthError from "../../common/errors/AuthError";
import ngTranslate from "angular-translate";

export default angular
    .module("sbApp.core.authentication", [ngTranslate])
    .config(authenticationConfig)
    .constant("LOCAL_STORAGE_LAST_SESSION", "lastSession")
    .value("AuthError", AuthError)
    .value("PasswordResetRequiredError", PasswordResetRequiredError)
    .value("NewPasswordRequiredError", NewPasswordRequiredError)
    .value("User", User)
    .value("toJson", toJson)
    .factory("$sbAuthStore", authStoreService)
    .factory("$sbPasswordPolicy", passwordPolicyService)
    .provider("$sbAuth", authenticationProvider);
