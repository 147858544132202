import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sbTimePicker.html";

export default angular
    .module("sbApp.sbTimePicker", [])
    .directive("sbTimePicker", function () {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            scope: {
                label: "@",
                startTime: "@",
                endTime: "@",
                model: "=",
                tick: "@",
                ngDisabled: "=",
                name: "@",
                parentForm: "=form",
                trackChange: "&",
            },
            controllerAs: "timePicker",
            controller: function ($scope, $timeout, $rootScope) {
                var vm = this;

                /////////////////////
                //
                //      SCOPE properties
                //
                /////////////////////

                vm.onSelectionChange = onSelectionChange;

                /////////////////////
                //
                //      WATCHERS
                //
                /////////////////////

                //Adds the validity value passedHour when the DOM is created
                $timeout(function () {
                    $scope.parentForm[$scope.name].$setValidity(
                        "passedHour",
                        true
                    );
                }, 0);

                // Check if the start time changed so it can readjust the hours shown and print an error if the hour displayed was before the starting date
                $scope.$watch("startTime", function (newVal) {
                    if (newVal) {
                        if (
                            moment($scope.model, "HH:mm").isBefore(
                                moment($scope.startTime, "HH:mm")
                            )
                        ) {
                            $scope.parentForm[$scope.name].$setValidity(
                                "passedHour",
                                false
                            );
                            $scope.parentForm[$scope.name].$setDirty();
                        }
                        createTicking();
                    }
                });

                // Check if the date is changed so i can restore the validity of the field
                $scope.$watch("model", function (model) {
                    if (
                        !moment($scope.model, "HH:mm").isBefore(
                            moment($scope.startTime, "HH:mm")
                        )
                    ) {
                        $scope.parentForm[$scope.name].$setValidity(
                            "passedHour",
                            true
                        );
                    }
                });

                $rootScope.$on("onLanguageChange", function () {
                    createTicking();
                });

                /////////////////////
                //
                //      IMPL
                //
                /////////////////////

                createTicking();

                /**
                 * Creates the ticking for the select from startTime to endTime
                 * @param {Object} event - keyboard event for pressed key
                 */
                function createTicking() {
                    var startTime = $scope.startTime || "00:00";
                    var endTime = $scope.endTime || "23:59";

                    var startMoment = moment(startTime, "HH:mm");
                    var endMoment = moment(endTime, "HH:mm");

                    vm.hours = [];

                    if (endMoment.isBefore(startMoment)) {
                        endMoment.add(1, "day");
                    }

                    var stepDate = angular.copy(startMoment);
                    while (stepDate.isBefore(endMoment)) {
                        vm.hours.push({
                            value: stepDate.format("HH:mm"),
                            text: stepDate.format("LT"),
                        });
                        stepDate.add($scope.tick, "minutes");
                    }
                }

                function onSelectionChange() {
                    if (
                        $scope.trackChange &&
                        angular.isFunction($scope.trackChange)
                    ) {
                        $scope.trackChange();
                    }
                }
            },
        };
    });
