import angular from "angular";
import htmlTemplate from "./inspection_row.html";

export default {
    templateUrl: htmlTemplate,
    controllerAs: "inspection",
    bindings: {
        row: "<",
    },
};
