import createjs from "createjs";

/**
 * Event dispatched, when an Edge should be created
 * @param {WBS.StructureNode} source - Source Node of Edge
 * @param {WBS.StructureNode} target - Target Node of Edge
 * @constructs CreateEdgeEvent
 * @extends createjs.Event
 */
function CreateEdgeEvent(source, target) {
    createjs.Event.call(this, "createEdge", false, false);

    this.edgeSource = source;
    this.edgeTarget = target;
}

CreateEdgeEvent.prototype = Object.create(createjs.Event.prototype);

export default CreateEdgeEvent;
