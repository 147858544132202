import ConstantsModule from "./constants.module";

ConstantsModule.constant("QR_CODE_TEMPLATE", [
    {
        type: "2x6-A4",
        text: "QR_2x6_TITLE",
        icon: "sb-icon-qr_pdf_12",
        maxOffset: 11,
        maxDeliverables: 500,
    },
    {
        type: "1x2-A4",
        text: "QR_1x2_TITLE",
        icon: "sb-icon-qr_pdf_2",
        maxOffset: 1,
        maxDeliverables: 100,
    },
]);
