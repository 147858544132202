import inspectDeliverablesTemplateHtml from "./inspect_deliverables.html";

export default function ($stateProvider) {
    "ngInject";

    $stateProvider.state("requested_inspection_deliverables", {
        templateUrl: inspectDeliverablesTemplateHtml,
        controller: "InspectDeliverablesCtrl",
        controllerAs: "inspectDeliverables",
        url: "/requested/progress/search/{projectId}",
        data: {
            title: "SECTION_REQUEST_FOR_INFORMATION_OPT_OUT_TITLE",
        },
        resolve: {
            membership: function ($sbMembership, $stateParams, $sbAuthStore) {
                const identity = $sbAuthStore.getLastSuccessfulSignInIdentity();
                return $sbMembership.get(
                    $stateParams.projectId,
                    identity.userId
                ); // will be cached internally
            },
        },
    });
}
