import angular from "angular";
import CreateIssueCommentController from "./create_issue_comment.controller";
import dialogTemplate from "./create_issue_comment.tmpl.html";

import ServiceModule from "./../../services/services";
import DomainModule from "./../../../domain/domain.module";

export default angular
    .module("sbApp.sbCreateIssueCommentDialog", [
        DomainModule.name,
        ServiceModule.name,
        "ngMaterial",
    ])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("createIssueComment", {
            methods: ["note"],
            options: function IssueEditorDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: CreateIssueCommentController,
                    controllerAs: "$ctrl",
                    bindToController: true,
                };
            },
        });
    });
