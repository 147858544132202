import angular from "angular";
import toolsRouteConfig from "./tools.route";
import ToolsCtrl from "./tools.controller";
import "common/dialog/chooseProjectSource/choose-project-source.dialog";
import { ProjectSourceEvent } from "../../domain/sb_project_source.class";

export default angular
    .module("sbApp.toolsModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.sbChooseProjectSourceDialog",
    ])
    .config(toolsRouteConfig)
    .controller("ToolsCtrl", ToolsCtrl)
    .filter("showDateAndUser", showDateAndUser)
    .filter("showUsageNumber", showUsageNumber);

/* declaration */

/* implementation */

/**
 * This filter displays properly formatted string with creation and/or update information, including
 * date and author name.
 */
function showDateAndUser($filter) {
    return function (projectSource, showCreation, showUpdate) {
        const parts = [];

        if (showCreation) {
            const creationEvent = projectSource.firstEvent(
                ProjectSourceEvent.TYPES.IMPORT
            );
            if (creationEvent) {
                const createTextPart = $filter("translate")(
                    "SECTION_TOOLS_DIALOG_CREATED",
                    {
                        at: creationEvent.at.format("LLL"),
                        by: creationEvent.by.name,
                    }
                );
                parts.push(createTextPart);
            }
        }
        if (showUpdate) {
            const updateEvent = projectSource.lastEvent(
                ProjectSourceEvent.TYPES.UPDATE
            );
            if (updateEvent) {
                const updateTextPart = $filter("translate")(
                    "SECTION_TOOLS_DIALOG_UPDATED",
                    {
                        at: updateEvent.at.format("LLL"),
                        by: updateEvent.by.name,
                    }
                );
                parts.push(updateTextPart);
            }
        }

        return parts.join(" | ");
    };
}

function showUsageNumber($filter) {
    return function (projectSource, showUsageNumbers) {
        return showUsageNumbers
            ? $filter("translate")("SECTION_TOOLS_DIALOG_USAGE_NUMBER", {
                  active: projectSource.usage.active,
              })
            : "";
    };
}
