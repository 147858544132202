/**
 * Create a calendar
 *
 * @constructor
 */
function Calendar(key, days, blocks, timezone) {
    // the naming style is required to support backwards compatibility
    this.KEY = key;
    this.DAYS = days.map(function (day) {
        return !!day;
    });

    this.BLOCKS = blocks;
    this.TIMEZONE = timezone;

    this.exceptionDates = [];
}

Calendar.prototype.tz = function () {
    return this.TIMEZONE.name;
};

Calendar.prototype.calcStartHourOfWorkday = function (
    isoWeekday,
    /* 1..7 */ isDependingOnWorkday
) {
    /* return 0..23 */
    return this.hourOfDay(
        isoWeekday,
        isDependingOnWorkday,
        function () {
            return this.BLOCKS[0][0];
        },
        this
    );
};

Calendar.prototype.calcEndHourOfWorkday = function (
    isoWeekday,
    /* 1..7 */ isDependingOnWorkday
) {
    /* return [0..23, 0..59] */
    return this.hourOfDay(isoWeekday, isDependingOnWorkday, function () {
        return this.BLOCKS[1][1];
    });
};

Calendar.prototype.hourOfDay = function (
    /* 1..7 */ isoWeekday,
    isDependingOnWorkday,
    fnGetBlock
) {
    var isWorkingDay = true;
    var time = null;

    if (isDependingOnWorkday) {
        isWorkingDay = this.DAYS[isoWeekday];
    }

    if (isWorkingDay) {
        var timeString = fnGetBlock.call(this);

        if (angular.isString(timeString) && timeString.indexOf(":") === 2) {
            time = timeString.split(":").map(function (hourOrMinute) {
                return Number.parseInt(hourOrMinute);
            });
        }

        // 24:00 -> 00:00
        if (time[0] === 24) {
            time[0] = 0;
        }
    }

    return time;
};

export default Calendar;
