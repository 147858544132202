/**
 *
 * Not used at the moment.
 * Was planned as part of https://sablono.atlassian.net/browse/SAB-3080
 * But moved out into own story -> https://sablono.atlassian.net/browse/SAB-3087
 *
 *  TODO -> either implement story above or remove this module
 */

import angular from "angular";
import routeConfig from "./freemium_landing.route";
import FreemiumLandingCtrl from "./freemium_landing.controller";

export default angular
    .module("sbApp.freemiumLanding", ["ngMaterial", "ui.router"])
    .config(routeConfig)
    .controller("FreemiumLandingCtrl", FreemiumLandingCtrl);

/* declaration */
