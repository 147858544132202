import _ from "lodash";

/**
 * Create a process template / workflow
 *
 * @constructor
 */
function ProcessTemplate(id, name, code) {
    this.id = id;
    this.projectId = null;

    this.name = name;
    this.code = code;
    this.category = "GROUP";

    this.__odataSource = null;
}

ProcessTemplate.ODATA_PROPERTY_MAP = {
    ID: "id",
    PROJECT_ID: "projectId",

    NAME: "name",
    DESC: "desc",
    CODE: "code",
    CATEGORY: "category",
};

ProcessTemplate.createFromOdataObject = function (odataObject) {
    return ProcessTemplate.createFromOdataObjectIntoInstance(
        odataObject,
        new ProcessTemplate()
    );
};

ProcessTemplate.createFromOdataObjectIntoInstance = function (
    odataObject,
    instance
) {
    var clonedData = _.clone(odataObject);
    instance.__odataSource = clonedData;

    // use the property map to translate odata keys to class keys
    //
    Object.keys(ProcessTemplate.ODATA_PROPERTY_MAP).reduce(function (
        processTemplate,
        odataProperty
    ) {
        var keyPath = ProcessTemplate.ODATA_PROPERTY_MAP[odataProperty];
        var value = clonedData[odataProperty];

        // if the value is null -> keep the default value from the constructor
        if (_.isNull(value)) {
            return processTemplate;
        }

        _.set(processTemplate, keyPath, value);

        return processTemplate;
    }, instance);

    return instance;
};

ProcessTemplate.prototype.setProjectId = function (id) {
    this.projectId = id;
    return this;
};

export default ProcessTemplate;
