export default class ReportingActivitiesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the activities in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/activities`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns a detailed PDF report for the activities in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}                Query parameter
     * @param query.filter {string}         An odata filter string.
     * @param query.i18n {string[]}         A key value pair separated by ':' where the value is replacing the key when processing the request.
     * @param query.language {string}       A language abbreviation string.
     */
    printCollection(projectId, { filter, i18n, language }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/activities`;
        return this.$sbRequest.pdf({
            url: endpointUrl,
            method: "GET",
            query: {
                filter,
                i18n,
            },
            header: {
                "Accept-Language": language,
            },
        });
    }

    /**
     * This endpoint returns a CSV representation of the activities in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}            Query parameter
     * @param query.i18n {string[]}         A key value pair separated by ':' where the value is replacing the key when processing the request.
     * @param query.language {string}       A language to be used when generating the report.
     * @param query.by_source_id {number}   A source id to be used when generating the report.
     * @param query.by_source_type {string} A source type to be used when generating the report.
     */
    exportCollection(
        projectId,
        { i18n, language, by_source_id, by_source_type }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/activities`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "GET",
            query: {
                i18n,
                language,
                by_source_id,
                by_source_type,
            },
            header: {
                Accept: "text/csv",
            },
        });
    }
}
