import CustomButton from "./CustomButton";
/**
 * Contains the shape of the anchor point
 * @param {boolean} isBig Determines wether it is the LOD version or not
 * @constructs AnchorPoint
 * @extends WBS.CustomButton
 * @memberof WBS
 **/
function AnchorPoint(isBig) {
    CustomButton.call(this);

    if (isBig) {
        this.padding = this.radius = this.bigRadius;
    }

    var g = new createjs.Graphics();
    g.beginFill("#fff");
    g.setStrokeStyle(this.radius / 4);
    g.beginStroke("#0f8cfe");
    g.drawCircle(0, 0, this.radius);

    //draw inner circle
    g.beginFill("#0f8cfe");
    g.drawCircle(0, 0, this.radius / 2);

    var shape = new createjs.Shape(g);
    var halfSize = this.radius / 2;

    //draw hit area
    shape.hitArea = new createjs.Shape();
    shape.hitArea.graphics
        .beginFill("#000")
        .drawRect(
            -halfSize - this.radius,
            -halfSize - this.radius,
            this.radius + this.padding * 2,
            this.radius + this.padding * 2
        );
    this.addChild(shape);
}

AnchorPoint.prototype = Object.create(CustomButton.prototype);

/**
 * Radius for Zoomed out Version
 * @type {number}
 */
AnchorPoint.prototype.bigRadius = 20;

/**
 * Radius of Anchor Point
 * @type {number}
 */
AnchorPoint.prototype.radius = 10;

/**
 * Padding for Anchor Point
 * @type {number}
 */
AnchorPoint.prototype.padding = 10;

export default AnchorPoint;
