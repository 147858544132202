import _ from "lodash";

export default function ActivityListSelectorService() {
    var activities = [];
    var filteredActivities = [];

    function setActivities(_activityModels) {
        if (!Array.isArray(_activityModels)) {
            throw new Error("_activityModels must be an array");
        }

        activities = _activityModels.map(function (activityModel) {
            return {
                selected: false,
                model: activityModel,
                displayPath: activityModel.getDisplayPath(true),
                topologicalIndex: activityModel.topologicalIndex,
            };
        });

        filteredActivities = activities;

        return activities;
    }

    function getActivities() {
        return activities;
    }

    function getSelectedActivityModels() {
        return activities
            .filter((activity) => activity.selected)
            .map((activity) => activity.model);
    }

    function unselectAllActivities() {
        _.forEach(activities, function (activity) {
            activity.selected = false;
        });
    }

    function setFilteredActivities(_filteredActivities) {
        if (!Array.isArray(_filteredActivities)) {
            throw new Error("_filteredActivities must be an array");
        }

        filteredActivities = _filteredActivities;
        return filteredActivities;
    }

    function getFilteredActivities() {
        return filteredActivities;
    }

    function selectAllFilteredActivities() {
        _.forEach(filteredActivities, function (activity) {
            activity.selected = true;
        });
    }

    function areActivitiesSelected() {
        return activities.some((activity) => activity.selected);
    }

    function areAllFilteredActivitiesSelected() {
        if (!Array.isArray(filteredActivities)) {
            return false;
        }

        if (filteredActivities.length === 0) {
            return false;
        }

        return filteredActivities.every((activity) => activity.selected);
    }

    function selectActivity(activity) {
        activity.selected = !activity.selected;
    }

    function selectActivities(selections) {
        const activitiesToSelect = _.intersectionWith(
            activities,
            selections,
            (activity, selection) => {
                return activity.model.id === selection.id;
            }
        );

        activitiesToSelect.forEach((selectedActivity) => {
            selectActivity(selectedActivity);
        });

        return activities;
    }

    return {
        setActivities: setActivities,
        getActivities: getActivities,
        getSelectedActivityModels: getSelectedActivityModels,
        unselectAllActivities: unselectAllActivities,
        getFilteredActivities: getFilteredActivities,
        setFilteredActivities: setFilteredActivities,
        selectAllFilteredActivities: selectAllFilteredActivities,
        areActivitiesSelected: areActivitiesSelected,
        areAllFilteredActivitiesSelected: areAllFilteredActivitiesSelected,
        selectActivity: selectActivity,
        selectActivities: selectActivities,
    };
}
