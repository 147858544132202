import angular from "angular";
/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbOnChange", [])
    .directive("sbOnChange", function OnChangeDirective($timeout) {
        return {
            restrict: "A",
            templateUrl: "",
            link: function (scope, element, attrs) {
                // compile on change event handler function from element
                //
                var onChangeFunc = scope.$eval(attrs.sbOnChange);

                /**
                 * Handler for change event of file input element.
                 * Wraps user specified handler into angular timeout service.
                 *
                 * @param {Object} $event - the file handling event
                 */
                function onChangeHandler($event) {
                    $timeout(function () {
                        onChangeFunc.call(null, $event);
                    }, 0);
                }

                element.on("change", onChangeHandler);

                // remove listeners if scope got destroyed
                //
                scope.$on("$destroy", function () {
                    element.off("change", onChangeHandler);
                });
            },
        };
    });
