/**
 * Created by Sebastian on 23.11.2015.
 */
import angular from "angular";
import ImportCtrl from "./import.controller";
import importStateConfiguration from "./import.route";
import ImportSelectionCtrl from "./states/importSelection/import_selection.controller";
import ImportUploadCtrl from "./states/importUpload/import_upload.controller";
import ImportActivitiesCtrl from "./states/importActivities/import_activities.controller";
import ImportPackagesCtrl from "./states/importPackages/import_packages.controller";
//TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
import ImportProjectCtrl from "./states/xml/xml.controller";
import ExcelCtrl from "./states/excel/excel.controller";
import ImportProgressCtrl from "./states/importProgress/import_progress.controller";
import ImportDatesCtrl from "./states/importDates/import_dates.controller";
import uploadPreviewDirective from "./directives/sbUploadPreview/sb_upload_preview.directive";
import validatorFactory from "./services/validator/validator";
import suggestionFactory from "./services/inputSuggestion/inputSuggestion";
import excelService from "./states/excel/excel.service";
import importProgressFacadeService from "./states/importProgress/import_progress_facade.service";
import importDatesFacadeService from "./states/importDates/import_dates_facade.service";
import "angular-ui-router";
import "../../common/ui-elements/directives/sbFileInput/sb_file_input.directive";
import "common/services/services";
import sbExcelViewer from "./directives/sbExcelViewer/sb_excel_viewer.directive";
import sbSelectFile from "./directives/sbSelectFile/sb_select_file.directive";
import excelReaderService from "./services/import/excel_reader.service";

// for some reason eslint is having trouble with our constant definition..
// but I don't see the issue with function-type..   -> MB added eslint-disable
/* eslint-disable angular/function-type */
export default angular
    .module("sbApp.import", [
        "ngMaterial",
        "ngCookies",
        "ui.router",
        "sbApp.sbFileInput",
        "sbApp.intercom",
        "sbApp.services",
        "sbApp.configs",
    ])
    .config(importStateConfiguration)
    .factory("validator", validatorFactory)
    .factory("inputSuggestion", suggestionFactory)
    .factory("sbExcelService", excelService)
    .factory("$sbImportProgressFacadeService", importProgressFacadeService)
    .factory("$sbImportDatesFacadeService", importDatesFacadeService)
    .factory("$sbExcelReaderService", excelReaderService)
    .controller("ImportCtrl", ImportCtrl)
    .controller("ImportSelectionCtrl", ImportSelectionCtrl)
    .controller("ImportUploadCtrl", ImportUploadCtrl)
    //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
    .controller("ImportProjectCtrl", ImportProjectCtrl)
    .controller("ExcelCtrl", ExcelCtrl)
    .controller("ImportProgressCtrl", ImportProgressCtrl)
    .controller("ImportActivitiesCtrl", ImportActivitiesCtrl)
    .controller("ImportPackagesCtrl", ImportPackagesCtrl)
    .controller("ImportDatesCtrl", ImportDatesCtrl)
    .directive("sbUploadPreview", uploadPreviewDirective)
    .directive("sbExcelViewer", sbExcelViewer)
    .directive("sbSelectFile", sbSelectFile)
    .constant("XML_FILE_TYPE", [".xml", ".XML"])
    .constant("EXCEL_EXTENSIONS", [".xls", ".xlsx", ".XLS", ".XLSX"])
    .constant("MAX_ROWS_FOR_AUTO_PREVIEW_REFRESH", 500);
