import angular from "angular";

export default angular
    .module("sbApp.preventBubble", [])
    .directive("sbPreventBubble", function preventBubbleDirective() {
        return {
            restrict: "A", // only activate on element attribute
            link: function (scope, element, attrs) {
                element.on("keydown", function (event) {
                    event.stopPropagation();
                });
            },
        };
    });
