import _ from "lodash";
import ChecklistItem from "./sb_checklist_item.model";

function ChecklistItemHandover(description, position, totalColumns) {
    this.description = description;
    this.position = position;

    this.execution = undefined;
    this.review = [];
    this.confirmation = undefined;

    this.totalColumns = totalColumns;
    this.columns = [];

    //

    //    {
    //         description: 'QA item 2 name should be here',
    //         columns: [checklistItem, checklistItem]
    //     }
}

/**
 * Set the execution result
 * @param {ChecklistItem} checklistItem
 */
ChecklistItemHandover.prototype.setExecutionItem = function (checklistItem) {
    this.execution = checklistItem;
    this.columns = _configureColumns.call(this);
};

ChecklistItemHandover.prototype.getExecutionItem = function () {
    return this.execution;
};

/**
 * Set the confirmation result
 * @param {ChecklistItem} checklistItem
 */
ChecklistItemHandover.prototype.setConfirmationItem = function (checklistItem) {
    this.confirmation = checklistItem;
    this.columns = _configureColumns.call(this);
};

ChecklistItemHandover.prototype.getConfirmationItem = function () {
    return this.confirmation;
};

/**
 * Set the review result
 * @param {ChecklistItem[]} checklistItem
 */
ChecklistItemHandover.prototype.addReviewItem = function (checklistItem) {
    this.review.push(checklistItem);
    this.columns = _configureColumns.call(this);
};

ChecklistItemHandover.prototype.getReviewItems = function () {
    return this.review;
};

function _configureColumns() {
    const newColumns = [];

    if (this.execution) {
        newColumns.push(this.execution);
    }

    if (!_.isEmpty(this.review)) {
        this.review.forEach((item) => {
            newColumns.push(item);
        });
    }

    if (this.confirmation) {
        newColumns.push(this.confirmation);
    }

    return addEmptyColumns(newColumns, this.totalColumns);
}

function addEmptyColumns(newColumns, totalColumns) {
    if (newColumns.length === totalColumns) {
        return newColumns;
    }

    while (newColumns.length < totalColumns) {
        newColumns.push(new ChecklistItem(-1, "EMPTY", ""));
    }

    return newColumns;
}

export default ChecklistItemHandover;
