import moment from "moment";
import CalendarTimeShift from "./calendar_time_shift.model";
import WorkFreeCalendar from "./work_free_calendar.model";
//
// CONTENT TO COPY TO BACKEND STARTS HERE !
//

/**
 * WorkingCalendar - A calendar in which only the hours in the shift can be used.
 *
 * @class WorkingCalendar
 * @property {Array<CalendarTimeShift>} _workingShiftsOnDay
 * @property {Array<Number>}_workingHoursPerDay
 * @property {WorkFreeCalendar} exceptionDates
 *
 * @param {Array<Boolean>}        days   - An array containing all the days of the week. e.g: [0,1,1,1,1,1,0] 0 -> not working | 1 -> working (first index is sunday)
 * @param {Array<Array<String>>}  blocks - An array containing all the shifts blocks. e.g: [["08:00", "12:00"], ["13:00", "18:00"]]
 * @param {WorkFreeCalendar}      exceptionDates - An array containing all the shifts blocks. e.g: [["08:00", "12:00"], ["13:00", "18:00"]]
 */
function WorkingCalendar(days, blocks, exceptionDates) {
    this._totalWorkingTimeBetweenDatesCache = new Map();

    this.initWorkingShifts(days, blocks);
    this.initWorkingHoursPerWorkingDay(blocks);

    if (exceptionDates instanceof WorkFreeCalendar) {
        this.exceptionDates = exceptionDates;
    }
}

WorkingCalendar.MAX_TIME_FLOAT_OF_DAY = 23.95;
WorkingCalendar.MIN_TIME_FLOAT_OF_DAY = 0;

WorkingCalendar.WORKING_HOURS_UNIT_ENUM = "wh";
WorkingCalendar.WORKING_DAYS_UNIT_ENUM = "wd";
WorkingCalendar.HOURS_UNIT_ENUM = "h";
WorkingCalendar.DAYS_UNIT_ENUM = "d";

WorkingCalendar.createFrom = function (calendar) {
    var days = calendar && Array.isArray(calendar.days) ? calendar.days : [];
    var blocks =
        calendar && Array.isArray(calendar.blocks) ? calendar.blocks : [];
    var workFreeCalendar = new WorkFreeCalendar();

    if (calendar && calendar.exceptionDates) {
        calendar.exceptionDates.forEach(function (exceptionDate) {
            workFreeCalendar.addNonWorkingDay(exceptionDate);
        });
    }

    return new WorkingCalendar(days, blocks, workFreeCalendar);
};

/**
 * Array of all the days of the week. If is it a working day it contains a
 * CalendarTimeShift Object otherwise it's an empty array
 *
 * @param {Array<Boolean>}       days
 * @param {Array<Array<String>>} blocks
 */
WorkingCalendar.prototype.initWorkingShifts = function (days, blocks) {
    this._workingShiftsOnDay = days.map(function (isWorkingDay) {
        if (!isWorkingDay) {
            return [];
        }
        return blocks.map(function (block) {
            return new CalendarTimeShift(block[0], block[1]);
        });
    });
};

/**
 * Store duration of available working hours for a working day with the given shifts.
 *
 * @param {Array<Array<String>>} blocks -  An array containing all the shifts blocks. e.g: [["08:00", "12:00"], ["13:00", "18:00"]]
 */
WorkingCalendar.prototype.initWorkingHoursPerWorkingDay = function (blocks) {
    this._workingHoursPerDay = blocks.reduce(function (hours, block) {
        var shift = new CalendarTimeShift(block[0], block[1]);
        return hours + shift.getWorkingHours();
    }, 0);
};

WorkingCalendar.prototype.hasNoExceptionDays = function () {
    return typeof this.exceptionDates === "undefined";
};

/**
 * Calculates the working time between the given start and end date in hours as float.
 *
 * @param {Moment} startDate
 * @param {Moment} endDate
 *
 * @returns {float} duration as working hours
 */
WorkingCalendar.prototype.getTotalWorkingTimeBetween = function (
    startDate,
    endDate
) {
    if (startDate === null || endDate === null) {
        return 0;
    }

    if (startDate.isSame(endDate)) {
        return 0;
    }

    const startDateInMs = startDate.valueOf();
    const endDateInMs = endDate.valueOf();
    const key = startDateInMs + "_" + endDateInMs;

    if (this._totalWorkingTimeBetweenDatesCache.has(key)) {
        return this._totalWorkingTimeBetweenDatesCache.get(key);
    }

    var nextValidStartingTime = this.findEarliestStartForTask(startDate);

    if (nextValidStartingTime.isSameOrAfter(endDate)) {
        this._totalWorkingTimeBetweenDatesCache.set(key, 0);
        return 0;
    }

    // step one - get the duration between startDate and end of first day
    var duration = 0;

    if (!nextValidStartingTime.isSame(endDate, "day")) {
        duration += this.getRemainingDuration(nextValidStartingTime);
    }

    var currentDay = moment(nextValidStartingTime).add(1, "day");

    // step two - add a full working day for all the days in between
    while (currentDay.isBefore(endDate, "day")) {
        duration += this.getTotalDurationOfAllShifts(currentDay);
        currentDay.add(1, "day");
    }

    // step three - get the duration between start of final day and endDate
    duration += this.getPassedDuration(endDate);

    this._totalWorkingTimeBetweenDatesCache.set(key, duration);
    return duration;
};

/**
 * Get the remaining working hours of that already started day based on
 * the shifts set in the working calendar.
 *
 * @param {Moment} date
 *
 * @returns {float} remaining working hours of day
 */
WorkingCalendar.prototype.getRemainingDuration = function (date) {
    return this.aggregateShiftDuration(date, function (duration, shift) {
        return (
            duration + shift.hoursRemainingOnShift(_transformHourToFloat(date))
        );
    });
};

/**
 * Get the passed working hours of that already started day based on
 * the shifts set in the working calendar.
 *
 * @param {Moment} date
 *
 * @returns {float} passed working hours of day
 */
WorkingCalendar.prototype.getPassedDuration = function (date) {
    return this.aggregateShiftDuration(date, function (duration, shift) {
        return duration + shift.hoursPassedOnShift(_transformHourToFloat(date));
    });
};

/**
 * Get the summed up working hours of all shifts on that day.
 *
 * @param {Moment} date
 *
 * @returns {float} working hours of all shifts on that day
 */
WorkingCalendar.prototype.getTotalDurationOfAllShifts = function (date) {
    return this.aggregateShiftDuration(date, function (duration, shift) {
        return duration + shift.duration;
    });
};

/**
 * Helper to calculate durations based on a working days shifts.
 *
 * @param {Moment} date
 * @param {Function} callback - parameters are the same as of Array.prototype.reduce
 *
 * @returns {float}
 */
WorkingCalendar.prototype.aggregateShiftDuration = function (date, callback) {
    if (this.exceptionDates && this.exceptionDates.isNonWorkingDay(date)) {
        return 0;
    }

    var shifts = this.getShiftsByDay(date);
    return shifts.reduce(callback, 0);
};

/**
 * Get a working days shift setup.
 *
 * @param {Moment} date
 *
 * @returns {Array<CalendarTimeShift>} All shifts for that particular working day.
 */
WorkingCalendar.prototype.getShiftsByDay = function (date) {
    return this._workingShiftsOnDay[date.day()];
};

WorkingCalendar.prototype.getDailyWorkingHours = function () {
    return this._workingHoursPerDay;
};

/**
 * findEarliestStartForTask - Finds the earliest start for the task. Since the calendar is shift dependent it has to check if the hour is inside the shift,
 *                            otherwise the start hour of the next available shift is returned
 *
 * @param  {Moment} fromTime - The hour from which the calculation should start
 * @return {Moment}            The day and hour in which the task start
 */
WorkingCalendar.prototype.findEarliestStartForTask =
    function findEarliestStartForTask(fromTime) {
        return this.findNextValidLaterMoment(fromTime, false);
    };

/**
 * Finds the a valid point in time of the working calendar that is later then the given
 * point in time. Since the calendar is shift dependent it has to check if the hour is inside
 * the shift, otherwise the start hour of the following available shift is returned.
 *
 * @param  {Moment}  momentToStartFrom   - The point in time from which the calculation should start
 * @param  {boolean}        endOfShiftAllowed   - If the given dates matches the "edge" of a shift you can either
 *                                                  - return the edge or
 *                                                  - return the other side of the following shift
 * @return {Moment} The day and hour of a valid working time
 */
WorkingCalendar.prototype.findNextValidLaterMoment = function (
    momentToStartFrom,
    endOfShiftAllowed
) {
    //The hour in which I have to start to work
    var startTime;
    //The day passed by moving through the shifts
    var elapsedDays = 0;
    //The current day of the week
    var dayOfTheWeek = momentToStartFrom.day();
    //The hour to start the calculation from
    var timeAsFloat = _transformHourToFloat(momentToStartFrom);

    //Loops until the startTime is found
    while (!startTime) {
        var current = this.getDateTimeAfter(
            momentToStartFrom,
            elapsedDays,
            timeAsFloat
        );
        if (
            this.hasNoExceptionDays() ||
            !this.exceptionDates.isNonWorkingDay(current)
        ) {
            this.traverseWorkingDayShifts(dayOfTheWeek, function (shift) {
                // if the given start is before a shift end -> we found the correct shift.
                //
                if (
                    timeAsFloat < shift.endAsFloat ||
                    (endOfShiftAllowed &&
                        isFloatEquals(timeAsFloat, shift.endAsFloat))
                ) {
                    // if start is before actual shift start -> use shift start
                    if (
                        timeAsFloat < shift.startAsFloat ||
                        (endOfShiftAllowed &&
                            isFloatEquals(timeAsFloat, shift.startAsFloat))
                    ) {
                        startTime = shift.startAsFloat;
                    } else {
                        // otherwise use the given start
                        startTime = timeAsFloat;
                    }
                    return true;
                }
            });
        }

        if (!startTime) {
            //Adds one day to the elapsed days
            elapsedDays++;
            //Set the starting hour to midnight of the next day
            timeAsFloat = WorkingCalendar.MIN_TIME_FLOAT_OF_DAY;
            //Move to the next day of the week
            dayOfTheWeek = (dayOfTheWeek + 1) % 7;
        }
    }

    return this.getDateTimeAfter(momentToStartFrom, elapsedDays, startTime);
};

/**
 * findEarliestEndForTask     - Finds the earliest end for the task. Since the calendar is shift dependent it has to check if the task can be done inside a shift,
 *                            otherwise the task is splitted in multiple shifts
 *
 * @param  {Moment}  taskStart - When the task starts
 * @param  {number}         durationInWorkingHours  - How many hours (wh) does this task takes to do
 * @return {Moment}             The day and hour in which the task end
 */
WorkingCalendar.prototype.findEarliestEndForTask = function (
    taskStart,
    durationInWorkingHours
) {
    //The day passed by moving through the shifts
    var elapsedDays = 0;
    //The hours passed by moving through the shifts
    var elapsedHours = 0;
    //The current day of the week
    var dayOfTheWeek = taskStart.day();
    //The hour to start the calculation from
    var timeAsFloat = _transformHourToFloat(taskStart);

    while (elapsedHours < durationInWorkingHours) {
        var foundEndTime = false;

        var current = this.getDateTimeAfter(
            taskStart,
            elapsedDays,
            timeAsFloat
        );
        if (
            this.hasNoExceptionDays() ||
            !this.exceptionDates.isNonWorkingDay(current)
        ) {
            this.traverseWorkingDayShifts(
                dayOfTheWeek,
                function forEachShift(shift) {
                    var availableHoursOnShift =
                        shift.hoursRemainingOnShift(timeAsFloat);
                    var hoursLeft = durationInWorkingHours - elapsedHours;

                    if (availableHoursOnShift >= hoursLeft) {
                        // we found the shift to stop

                        timeAsFloat =
                            Math.max(shift.startAsFloat, timeAsFloat) +
                            hoursLeft;
                        elapsedHours += hoursLeft;

                        return (foundEndTime = true);
                    } else if (availableHoursOnShift > 0) {
                        // shift isn't sufficient

                        timeAsFloat = shift.endAsFloat;
                        elapsedHours += availableHoursOnShift;
                    }
                }
            );
        }

        if (!foundEndTime) {
            //Adds one day to the elapsed days
            elapsedDays++;
            //Set the starting hour to midnight of the next day
            timeAsFloat = 0;
            //Move to the next day of the week
            dayOfTheWeek = (dayOfTheWeek + 1) % 7;
        }
    }

    return this.getDateTimeAfter(taskStart, elapsedDays, timeAsFloat);
};

/**
 * _module - Returns the a mod b but contemplating also negative numbers
 * http://javascript.about.com/od/hintsandtips/a/Floating-Point-And-Javascript.htm
 *
 * @param  {number} a - the dividend
 * @param  {number} b - the divisor
 * @return {number}   - a mod b
 */
function _module(a, b) {
    return ((a % b) + b) % b;
}

/**
 * findEarliestStartForTask - Finds the latest end for the task. Since the calendar is shift dependent it has to check if the hour is inside the shift,
 *                            otherwise the end hour of the previous available shift is returned
 *
 * @param  {Moment} fromTime - The hour from which the calculation should start
 * @return {Moment}            The day and hour in which the task ends
 */
WorkingCalendar.prototype.findLatestEndForTask = function (fromTime) {
    return this.findNextValidEarlierMoment(fromTime, false);
};

/**
 * Finds the a valid point in time of the working calendar that is earlier then the given
 * point in time. Since the calendar is shift dependent it has to check if the hour is inside
 * the shift, otherwise the end hour of the previous available shift is returned.
 *
 * @param  {Moment}  momentToStartFrom   - The point in time from which the calculation should start
 * @param  {boolean}        startOfShiftAllowed - If the given dates matches the "edge" of a shift you can either
 *                                                  - return the edge or
 *                                                  - return the other side of the following shift
 * @return {Moment} The day and hour in which the task ends
 */
WorkingCalendar.prototype.findNextValidEarlierMoment = function (
    momentToStartFrom,
    startOfShiftAllowed
) {
    var elapsedDays = 0;
    var dayOfTheWeek = momentToStartFrom.day();
    var time = _transformHourToFloat(momentToStartFrom);
    var startTime;

    while (!startTime) {
        var current = this.getDateTimeBefore(
            momentToStartFrom,
            elapsedDays,
            time
        );
        if (
            this.hasNoExceptionDays() ||
            !this.exceptionDates.isNonWorkingDay(current)
        ) {
            // traverses the shifts of this working day
            //
            this.traverseWorkingDayShiftsReversed(
                dayOfTheWeek,
                function forEachShift(shift) {
                    // if the given start is before a shift end -> we found the correct shift.
                    //
                    if (
                        time > shift.startAsFloat ||
                        (startOfShiftAllowed &&
                            isFloatEquals(time, shift.startAsFloat))
                    ) {
                        // if start is before actual shift start -> use shift start
                        //
                        if (
                            time > shift.endAsFloat ||
                            (startOfShiftAllowed &&
                                isFloatEquals(time, shift.endAsFloat))
                        ) {
                            startTime = shift.endAsFloat;
                        } else {
                            // otherwise use the given start
                            startTime = time;
                        }

                        return true;
                    }
                }
            );
        }

        if (!startTime) {
            //Adds one day to the elapsed days
            elapsedDays++;

            // TODO PROBLEM!!! We need an epsilon for times, to distinguish between 2 days on midnight
            // 23:59:999 is day before midnight
            // 24:00:000 -> 00:00:000 is new day already

            // Set the starting hour to 3 minutes before midnight of the previous day
            //
            time = WorkingCalendar.MAX_TIME_FLOAT_OF_DAY;
            //Move to the previous day of the week
            dayOfTheWeek = _module(dayOfTheWeek - 1, 7);
        }
    }

    return this.getDateTimeBefore(momentToStartFrom, elapsedDays, startTime);
};

/**
 * findLatestStartForTask     - Finds the latest start for the task. Since the calendar is shift dependent it has to check if the task can be done inside one shift,
 *                              otherwise the task is splitted in multiple shifts
 *
 * @param  {Moment}  taskEnd   - When the task end
 * @param  {number}  duration  - How many hours (wh) does this task takes to do
 * @return {Moment}             The day and hour in which the task start
 */
WorkingCalendar.prototype.findLatestStartForTask = function (
    taskEnd,
    duration
) {
    var day = taskEnd.day();
    var timeAsFloat = _transformHourToFloat(taskEnd);

    var elapsedHours = 0;
    var elapsedDays = 0;

    var foundStartTime = false;
    while (elapsedHours < duration) {
        var current = this.getDateTimeBefore(taskEnd, elapsedDays, timeAsFloat);
        if (
            this.hasNoExceptionDays() ||
            !this.exceptionDates.isNonWorkingDay(current)
        ) {
            this.traverseWorkingDayShiftsReversed(
                day,
                function forEachShift(shift) {
                    var availableHoursOnShift =
                        shift.hoursPassedOnShift(timeAsFloat); //2h
                    var hoursLeft = duration - elapsedHours; //4h, 2h

                    if (hoursLeft <= availableHoursOnShift) {
                        // we found the shift to stop
                        //
                        timeAsFloat =
                            Math.min(shift.endAsFloat, timeAsFloat) - hoursLeft;
                        elapsedHours += hoursLeft;

                        return (foundStartTime = true);
                    } else if (availableHoursOnShift > 0) {
                        // shift isn't sufficient
                        //
                        timeAsFloat = shift.startAsFloat;
                        elapsedHours += availableHoursOnShift;
                    }
                }
            );
        }

        if (!foundStartTime) {
            elapsedDays++;
            timeAsFloat = WorkingCalendar.MAX_TIME_FLOAT_OF_DAY;
            day = _module(day - 1, 7);
        }
    }

    return this.getDateTimeBefore(taskEnd, elapsedDays, timeAsFloat);
};

/**
 * Traverse the shifts array.
 *
 * @param {number} isoWeekday
 * @param {function} fnEach
 */
WorkingCalendar.prototype.traverseWorkingDayShifts = function (
    isoWeekday,
    fnEach
) {
    var shifts = this._workingShiftsOnDay[isoWeekday];

    shifts.some(fnEach.bind(this));
};

WorkingCalendar.prototype.traverseWorkingDayShiftsReversed = function (
    isoWeekday,
    fnEach
) {
    var shifts = this._workingShiftsOnDay[isoWeekday];

    var i = shifts.length;
    while (i--) {
        if (fnEach.call(this, shifts[i])) {
            break;
        }
    }
};

/**
 * Find the the next start of a working day that is earlier (or equal) then given point in time
 *
 * @param {Moment} date
 * @returns {Moment}
 */
WorkingCalendar.prototype.findEarlierValidMorning = function (date) {
    var earlierMorning = this.findNextValidEarlierMoment(date, true);
    var shiftsOfDay = this._workingShiftsOnDay[earlierMorning.day()];
    var shift = shiftsOfDay[0];

    earlierMorning
        .hours(shift.startingHours())
        .minutes(shift.startingMinutes());

    return earlierMorning;
};

/**
 * Find the next end of a working day that is later (or equal) then given point in time
 * @param date
 * @returns {Moment}
 */
WorkingCalendar.prototype.findLaterValidEvening = function (date) {
    var laterEvening = this.findNextValidLaterMoment(date, true);
    var shiftsOfDay = this._workingShiftsOnDay[laterEvening.day()];
    var shift = shiftsOfDay[shiftsOfDay.length - 1]; // the last shift of the day

    laterEvening.hours(shift.endingHours()).minutes(shift.endingMinutes());

    return laterEvening;
};

/**
 * Subtract the elapsed days from the given moment and set the floating number as time of this moment.
 *
 * @param {Moment} from
 * @param {Number} elapsedDays
 * @param {float} timeAsFloat
 * @returns {Moment}
 */
WorkingCalendar.prototype.getDateTimeBefore = function (
    from,
    elapsedDays,
    timeAsFloat
) {
    return _setFloatHourToDay(
        from.clone().subtract(elapsedDays, "d"),
        timeAsFloat
    );
};

/**
 * Add the elapsed days to the given moment and set the floating number as time of this moment.
 *
 * @param {Moment} from
 * @param {Number} elapsedDays
 * @param {float} timeAsFloat
 * @returns {Moment}
 */
WorkingCalendar.prototype.getDateTimeAfter = function (
    from,
    elapsedDays,
    timeAsFloat
) {
    return _setFloatHourToDay(from.clone().add(elapsedDays, "d"), timeAsFloat);
};

/**
 * Sets the hour as a float in to a day. e.g: _setFloatHourToDay(18/07/2016, 4.5) = 18/07/2016 04:30
 *
 * @param  {Moment} day  - The day in which the hour has to be set
 * @param  {float}  floatHour   - The hour to be set as a float
 * @return {Moment}      - The day with the specified hour
 */
function _setFloatHourToDay(day, floatHour) {
    if (typeof floatHour === "undefined" || floatHour <= 0) {
        return day.hour(0).minute(0);
    }

    var hour = Math.floor(floatHour);
    var minute =
        hour !== 0
            ? Math.round((floatHour % hour) * 60)
            : Math.round(floatHour * 60);

    return day.hour(floatHour).minute(minute);
}

/**
 * Transforms the hour passed in to a float value. e.g: 14:15 -> 14,25 or 04:30 -> 4,5
 *
 * @param  {Moment} hourAsMoment - The hour to transform
 * @return {float}               - The hour as a float number. e.g: 14:15 -> 14,25 or 04:30 -> 4,5
 */
function _transformHourToFloat(hourAsMoment) {
    return hourAsMoment.hours() + hourAsMoment.minutes() / 60;
}

/**
 * Check if two 64bit Float aka Double numbers are equal to a certain tolerance
 * (to take rounding errors into account)
 *  --> see: https://github.com/sablono/sb-bimtime/pull/115
 *
 * @param {number} lhs
 * @param {number} rhs
 * @returns {boolean}
 */
function isFloatEquals(lhs, rhs) {
    return Math.abs(lhs - rhs) <= 1e-6;
}

WorkingCalendar.prototype.findStartShiftOfDay = function (date) {
    const shiftsOfDay = this.getShiftsByDay(date);

    const hasShifts = shiftsOfDay.length > 0;
    if (hasShifts) {
        return shiftsOfDay[0];
    }
};

WorkingCalendar.prototype.findEndShiftOfDay = function (date) {
    const shiftsOfDay = this.getShiftsByDay(date);

    const hasShifts = shiftsOfDay.length > 0;
    if (hasShifts) {
        return shiftsOfDay[1];
    }
};

WorkingCalendar.prototype.updateTimeToMatchStartOfDay = function (date) {
    const shift = this.findStartShiftOfDay(date);

    const isWorkingDay = shift instanceof CalendarTimeShift;
    if (isWorkingDay) {
        return date
            .hours(shift.startingHours())
            .startOf("hour")
            .minutes(shift.startingMinutes());
    } else {
        return date;
    }
};

WorkingCalendar.prototype.updateTimeToMatchEndOfDay = function (date) {
    const shift = this.findEndShiftOfDay(date);

    const isWorkingDay = shift instanceof CalendarTimeShift;
    if (isWorkingDay) {
        return date
            .hours(shift.endingHours())
            .startOf("hour")
            .minutes(shift.endingMinutes());
    } else {
        return date;
    }
};

//
// CONTENT TO COPY TO BACKEND ENDS HERE !
//

export default WorkingCalendar;
