/**
 * @typedef {(
 *      'NOT_STARTED' |
 *      'STARTED' |
 *      'DONE' |
 *      'WAITING_FOR_CONFIRMATION' |
 *      'REJECTED' |
 *      'CONFIRMED'
 * )} ActivityStateEnum - Allowed activity states
 */

/**
 * Create an instance of ActivityState.
 *
 * @param {ActivityStateEnum} current
 * @param {moment.Moment} [changedAt] - string representation of date time
 * @param {string} [changedBy] - username
 * @returns {ActivityState}
 * @constructor
 */
function ActivityState(current, changedAt, changedBy) {
    this.current = current;
    this.changedAt = changedAt;
    this.changedBy = changedBy;

    this.__odataSource = null;

    return this;
}

ActivityState.isWorkDone = function (stateString) {
    return ActivityState.WORK_DONE_STATES.indexOf(stateString) > -1;
};

/**
 * @param {number} totalCompletion Sum of all completion percentages of activites
 * @param {number} totalCount Number of activites in the sum
 * @returns {number} Mean completion percentage rounded to the nearest integer, but at least 1 if the progress is not exactly zero.
 */
ActivityState.interpretMeanCompletionPercentage = function (
    totalCompletion,
    totalCount
) {
    if (totalCount === 0 || totalCompletion === 0) {
        return 0;
    }
    return Math.max(1, Math.round(totalCompletion / totalCount));
};

ActivityState.prototype.isCompleted = function () {
    return ActivityState.WORK_COMPLETED_STATES.indexOf(this.current) > -1;
};

ActivityState.prototype.isWorkInProgress = function () {
    return ActivityState.WORK_IN_PROGRESS_STATES.indexOf(this.current) > -1;
};

ActivityState.prototype.getCompletionPercentage = function () {
    switch (this.current) {
        case ActivityState.NOT_STARTED:
            return ActivityState.PERCENTAGE_NOT_STARTED;
        case ActivityState.STARTED:
            return ActivityState.PERCENTAGE_STARTED;
        case ActivityState.DONE:
            return ActivityState.PERCENTAGE_DONE;
        case ActivityState.WAITING_FOR_CONFIRMATION:
            return ActivityState.PERCENTAGE_WAITING_FOR_CONFIRMATION;
        case ActivityState.REJECTED:
            return ActivityState.PERCENTAGE_REJECTED;
        case ActivityState.CONFIRMED:
            return ActivityState.PERCENTAGE_CONFIRMED;
        default:
            return 0;
    }
};

ActivityState.NOT_STARTED = "NOT_STARTED";
ActivityState.STARTED = "STARTED";
ActivityState.DONE = "DONE";
ActivityState.WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION";
ActivityState.REJECTED = "REJECTED";
ActivityState.CONFIRMED = "CONFIRMED";

// aligned with backend logic
ActivityState.PERCENTAGE_NOT_STARTED = 0;
ActivityState.PERCENTAGE_STARTED = 50;
ActivityState.PERCENTAGE_DONE = 100;
ActivityState.PERCENTAGE_WAITING_FOR_CONFIRMATION = 75;
ActivityState.PERCENTAGE_REJECTED = 50;
ActivityState.PERCENTAGE_CONFIRMED = 100;

ActivityState.WORK_DONE_STATES = [
    ActivityState.DONE,
    ActivityState.WAITING_FOR_CONFIRMATION,
    ActivityState.CONFIRMED,
];

ActivityState.WORK_IN_PROGRESS_STATES = [
    ActivityState.STARTED,
    ActivityState.REJECTED,
    ActivityState.WAITING_FOR_CONFIRMATION,
];

ActivityState.WORK_COMPLETED_STATES = [
    ActivityState.DONE,
    ActivityState.CONFIRMED,
];

ActivityState.prototype.is = function (stateEnum) {
    return this.current === stateEnum;
};

export default ActivityState;
