import htmlTemplate from "./sbToolbarStateInfo.html";
import timeline from "../../../../ui-elements/components/sbTimeline/sb_timeline.component.html";
import SbTimelineCtrl from "../../../../ui-elements/components/sbTimeline/sb_timeline.controller";
import moment from "moment";

export default {
    name: "sbToolbarStateInfo",
    templateUrl: htmlTemplate,
    controller: function (
        $rootScope,
        $window,
        $sbAuthStore,
        $mdPanel,
        $mdDialog,
        $sbErrorPresenter,
        $sbCurrentProject,
        $sbMembership,
        $sbInspectionsApi,
        $sbSiteInspections,
        EVENTS,
        SABLONO_STATES
    ) {
        "ngInject";

        var vm = this;
        vm.project = null;
        const MAX_INSPECTIONS = 12;

        vm.$onInit = $onInit;

        vm.isSchedulingRequired = isSchedulingRequired;

        vm.showSchedulingPanel = showSchedulingPanel;
        vm.showProfilePanel = showProfilePanel;
        vm.openTimelinePanel = openTimelinePanel;
        vm.isMaxInspectionsLimitExceeded = isMaxInspectionsLimitExceeded;

        vm.isLoadingTimeline;
        vm.inspections;
        vm.totalAmountOfInspections;

        vm.panelRef;

        $rootScope.$on("$stateChangeSuccess", function ($event, newState) {
            if (SABLONO_STATES.isProjectState(newState.name)) {
                vm.project = $sbCurrentProject.get();
                vm.role = $sbMembership.currentRole().name;
                if (!vm.inspections) {
                    _fetchInspectionDataForTimeline();
                }
            } else {
                vm.project = null;
                vm.role = "default";
                vm.inspections = undefined;
            }
        });

        function $onInit() {
            vm.project = $sbCurrentProject.get();
            vm.role = $sbMembership.currentRole().name;
            vm.session = $sbAuthStore.getLastSuccessfulSignInIdentity();
            _fetchInspectionDataForTimeline();
        }

        function openTimelinePanel($event) {
            if (vm.panelRef && vm.panelRef.isAttached) {
                // If the panel is open, close it
                vm.panelRef.close();
                return;
            }

            const config = {
                locals: {
                    isLoadingTimeline: vm.isLoadingTimeline,
                    inspections: vm.inspections,
                    totalAmountOfInspections: vm.totalAmountOfInspections,
                },
                controllerAs: "$ctrl",
                controller: SbTimelineCtrl,
                templateUrl: timeline,
                focusOnOpen: false,
                clickOutsideToClose: true,
                propagateContainerEvents: true,
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo($event.target)
                    .addPanelPosition(
                        $mdPanel.xPosition.ALIGN_END,
                        $mdPanel.yPosition.BELOW
                    )
                    .withOffsetY("8px"),
            };

            vm.panelRef = $mdPanel.create(config);
            markInspectionAsRead(vm.project.id);
            return vm.panelRef.open();
        }

        function isMaxInspectionsLimitExceeded(num) {
            return num > MAX_INSPECTIONS;
        }

        function _fetchInspectionDataForTimeline() {
            if (!vm.project) {
                return;
            }

            vm.isLoadingTimeline = true;
            vm.inspections = null;
            vm.totalAmountOfInspections = null;
            return $sbInspectionsApi
                .getCollection(vm.project.id, { top: MAX_INSPECTIONS })
                .then((response) => {
                    return {
                        count: response.meta.count_all,
                        items: response.inspections.map(
                            $sbSiteInspections.parseServerInspection
                        ),
                    };
                })
                .then(function (results) {
                    vm.totalAmountOfInspections = results.count;

                    const lastTimeRead = getLastReadInspectionTime(
                        vm.project.id
                    );
                    if (lastTimeRead) {
                        vm.numberOfUnreadInspections = results.items.filter(
                            (inspection) =>
                                moment(inspection.UPLOADED_AT).isAfter(
                                    lastTimeRead
                                )
                        ).length;
                    } else {
                        vm.numberOfUnreadInspections = results.items.length;
                    }

                    return results.items;
                })
                .then((data) => (vm.inspections = data))
                .catch($sbErrorPresenter.catch)
                .finally(function () {
                    vm.isLoadingTimeline = false;
                });
        }

        function markInspectionAsRead(projectId) {
            vm.numberOfUnreadInspections = 0;
            $window.localStorage.setItem(
                getLastReadInspectionStoreKey(projectId),
                moment().toISOString()
            );
        }

        function getLastReadInspectionTime(projectId) {
            const timestamp = $window.localStorage.getItem(
                getLastReadInspectionStoreKey(projectId)
            );

            if (timestamp) {
                const timeAsMoment = moment(timestamp);
                if (timeAsMoment.isValid()) {
                    return timeAsMoment.toISOString();
                }
                return undefined;
            } else {
                return undefined;
            }
        }

        function getLastReadInspectionStoreKey(projectId) {
            return `sb.${projectId}.last_read_inspection_time`;
        }

        function isSchedulingRequired() {
            return vm.project && vm.project.isSchedulingNeeded();
        }

        function showSchedulingPanel($event) {
            $mdPanel.open("$sbSchedulingPanel", {
                id: "$sbSchedulingPanel",
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo($event.target)
                    .addPanelPosition(
                        $mdPanel.xPosition.CENTER,
                        $mdPanel.yPosition.BELOW
                    )
                    .withOffsetY("12px"),
                openFrom: $event,
            });
        }

        function showProfilePanel($event) {
            $mdPanel.open("$sbProfilePanel", {
                id: "$sbProfilePanel",
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo($event.target)
                    .addPanelPosition(
                        $mdPanel.xPosition.CENTER,
                        $mdPanel.yPosition.BELOW
                    )
                    .withOffsetY("8px"),
                openFrom: $event,
            });
        }

        ///////////////////////////////////////////////
        //          HELPDESK BLOCK SUPPORT           //
        //////////////////////////////////////////////
        //Check if some script is blocking help desk
        vm.initHelpDesk = function () {
            if ($window.helpDeskError) {
                $mdDialog.show(
                    $mdDialog
                        .alert()
                        .title("DIALOG_HELP_DESK_SCRIPT_BLOCKED_TITLE")
                        .content("DIALOG_HELP_DESK_SCRIPT_BLOCKED_CONTENT")
                );
                $window.helpDeskError = false;
            }
        };
        vm.initHelpDesk();
    },
};
