import createjs from "createjs";
/**
 * Generic Handler for Keyboard Events
 *
 * @param {WBS.Editor} editor - editor the handler belongs to
 * @constructs KeyboardHandler
 * @extends createjs.EventDispatcher
 * @memberof WBS
 */
function KeyboardHandler(editor) {
    /**
     * Editor the Handle belongs to
     * @type {WBS.Editor}
     */
    this.editor = editor;

    /**
     * Map of KeyCode and Actions
     * @type {Object}
     */
    this.oKeyCodes = {};
    this.defaultEvents = {};
    this.registeredEvents = {};

    this._handleKeyDown = this.handleKeyDown.bind(this);
}

// extend EventDispatcher from CreateJS framework
//
createjs.EventDispatcher.initialize(KeyboardHandler.prototype);

/**
 * attaches KeyboardHandler.handleKeyDown to window
 */
KeyboardHandler.prototype.attachHandler = function () {
    window.addEventListener("keydown", this._handleKeyDown, false);
};

/**
 * detaches KeyboardHandler.handleKeyDown from window
 */
KeyboardHandler.prototype.detachHandler = function () {
    window.removeEventListener("keydown", this._handleKeyDown, false);
};

/**
 * Throw a createjs event on key press
 * @param {Event} oEvent - Keypress Event
 */
KeyboardHandler.prototype.handleKeyDown = function (oEvent) {
    var sKeyCode = oEvent.keyCode,
        eventName = this.oKeyCodes[sKeyCode],
        keyboardEvent,
        preventDefault;

    if (!eventName) {
        return;
    }

    keyboardEvent = new createjs.Event(eventName, false, true);
    preventDefault = this.dispatchEvent(keyboardEvent);
    if (preventDefault === false) {
        oEvent.preventDefault();
    }
};

export default KeyboardHandler;
