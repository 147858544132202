/**
 *
 * Basic toolbar for sablono list views. Supports selection and delete
 *
 */
import angular from "angular";
import htmlTemplate from "./sbEleSelectionSubbar.html";

export default function () {
    return {
        restrict: "E",

        templateUrl: htmlTemplate, //HTML Template as String

        transclude: true,

        scope: {
            selectionModel: "=",
            hasSelectAll: "=",
            totalElements: "=",
            onDeletePress: "=",
            elementClass: "=",
            elementSubclass: "=",
        },
    };
}
