/**
 * Escape XML in a javascript string. Will escape:
 *    - &
 *    - "
 *    - <
 *    - >
 *    - '
 *
 * @param string - The string to escape a predefined list of characters.
 *                 See escape.map for characters and their escaped counterpart.
 * @param ignore - A list of characters which should be ignored when escaping.
 * @returns {string} The escaped string sequence.
 */
function escape(string, ignore = "") {
    let pattern;

    if (string === null || string === undefined) return "";

    // only allow ignoring escapable characters, the rest is replaced by whitespace
    //
    ignore = ignore.replace(/[^&"<>']/g, "");

    // remove ignored characters from character list which will be escaped
    //
    pattern = "([&\"<>'])".replace(new RegExp("[" + ignore + "]", "g"), "");

    // escape characters through defined mapping in escape.map
    //
    return string.replace(new RegExp(pattern, "g"), function (str, item) {
        return escape.map[item];
    });
}

escape.map = {
    ">": "&gt;",
    "<": "&lt;",
    "'": "&apos;",
    '"': "&quot;",
    "&": "&amp;",
};

export default escape;
