import PresentableError from "./PresentableError";

/**
 * Basic error for unknown problems
 * -> has an additional plainError attribute to inspect the true reason.
 *
 * @constructor
 * @extends PresentableError
 */
function UnknownError(error) {
    this.code = "ERROR_UNKNOWN";
    this.title = "ERROR_UNKNOWN_TITLE";
    this.message = "ERROR_UNKNOWN_MESSAGE";

    this.plainError = error ? error.toString() : null;
}

UnknownError.prototype = Object.create(PresentableError.prototype); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ UnknownError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.TOAST;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ UnknownError.prototype.shouldAutoClose = function () {
    return true;
};

export default UnknownError;
