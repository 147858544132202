import ConstantsModule from "./constants.module";

ConstantsModule.constant("TEMPLATE_CATEGORY_MAPPING", {
    ACTIVITY: {
        label: "_ACTIVITY",
        icon: "sb-icon-activity",
    },
    COMPONENT: {
        label: "_COMPONENT",
        icon: "mdi mdi-cube-outline",
    },
    DELIVERABLE: {
        label: "_DELIVERABLE",
        icon: "mdi mdi-cube-outline",
    },
    DELIVERY: {
        label: "_DELIVERY",
        icon: "sb-icon-delivery",
    },
    DOCUMENT: {
        label: "_DOCUMENT",
        icon: "mdi mdi-file-outline",
    },
    GROUP: {
        label: "_OTHERS",
        icon: "sb-icon-group",
    },
    ROOM: {
        label: "_ROOM",
        icon: "sb-icon-room",
    },
    TRAIT: {
        label: "_TRADE",
        icon: "sb-icon-trait",
    },
});
