import angular from "angular";
import _ from "lodash";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";

export default function TemplateEditorService(
    Analytics,
    $sbProjectApi,
    $sbCurrentProject,
    $sbPermission,
    $sbDeliverablesFilter,
    $sbMembership,
    $sbTemplate,
    $mdDialog,
    $sbRequest,
    $sbErrorPresenter,
    $mdToast,
    $sbProcessTemplateJobsApi
) {
    "ngInject";

    const MAX_PROJECTS_TO_AVOID_TOO_LONG_URLS = 80;

    var service = {
        triggerCloneTemplateWorkflow: triggerCloneTemplateWorkflow,
        fetchUserTemplates: fetchUserTemplates, // public for testing
        cloneUserSelection: cloneUserSelection, // public for testing
        successToastAfterCloning: successToastAfterCloning, // public for testing
    };

    return service;

    function triggerCloneTemplateWorkflow($event, templates) {
        return $mdDialog
            .show(
                $mdDialog
                    .templateSelector()
                    .targetEvent($event)
                    .title("DIALOG_CLONE_TEMPLATE_TITLE")
                    .text("DIALOG_CLONE_TEMPLATE_TEXT")
                    .ok("DIALOG_CLONE_TEMPLATE_BUTTON_TEXT")
                    .searchBarPlaceholderKey(
                        "DIALOG_CLONE_TEMPLATE_SEARCH_PLACEHOLDER"
                    )
                    .templates(templates)
                    .showCopyTeamsQaSelector(true)
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
            )
            .then(service.cloneUserSelection)
            .then(service.successToastAfterCloning)
            .catch(function (error) {
                if (!_.isUndefined(error)) {
                    $sbErrorPresenter.catch(error);
                }
            });
    }

    function cloneUserSelection({ selectedTemplate, copyTeamsAndQa }) {
        const projectId = $sbCurrentProject.pick("id");
        return $sbProcessTemplateJobsApi
            .createCloneJob(projectId, {
                from_process_template_id: selectedTemplate.ID,
                copyTeamsAndQa,
            })
            .then(
                _trackWorkflowInGoogleAnalytics(
                    selectedTemplate,
                    copyTeamsAndQa
                )
            );
    }

    function _trackWorkflowInGoogleAnalytics(selectedTemplate, copyTeamsAndQa) {
        const templateIsFromSameProject =
            $sbCurrentProject.pick("id") === selectedTemplate.PROJECT_ID;

        let label = "";
        switch (copyTeamsAndQa) {
            case "skip":
                label = "Without Teams & QA";
                break;
            case "map_by_name":
                label = "With all Teams & QA";
                break;
            case "clone":
                label = "With new Teams & QA";
                break;
        }

        if (templateIsFromSameProject) {
            _sendAnalyticEvent(label + " from same project");
        } else {
            _sendAnalyticEvent(label + " from another project");
        }

        return selectedTemplate;
    }

    function _sendAnalyticEvent(label) {
        Analytics.trackEvent("Process templates", "Create", label);
    }

    function successToastAfterCloning() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("DIALOG_CLONE_TEMPLATE_SUCCESS_TOAST_TEXT")
        );
    }

    function fetchUserTemplates() {
        return $sbProjectApi.list().then(function (projects) {
            const projectData = _.chain(projects)
                .filter((project) =>
                    $sbPermission.hasPlanPermissions(project.privileges)
                )
                .sortBy("createdTime")
                .reverse()
                .map((project) => ({
                    id: project.id,
                    name: project.name,
                }))
                .take(MAX_PROJECTS_TO_AVOID_TOO_LONG_URLS)
                .value();

            return $sbTemplate.getTemplatesForProjects(projectData);
        });
    }
}
