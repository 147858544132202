import angular from "angular";
import _ from "lodash";

export default function ChecklistTemplatesListCtrl(
    $filter,
    $state,
    $sbProject,
    $sbTracking,
    $sbChecklistService,
    $sbErrorPresenter,
    $mdDialog,
    SABLONO_STATES
) {
    "ngInject";

    var vm = this;

    vm.isFetching = true;
    vm.checklistTemplates = [];
    vm.isEmpty = isEmpty;

    vm.createNewChecklist = createNewChecklist;

    // columns for data table
    vm.columns = {
        name: {
            criteria: "_NAME",
        },
        numberOfItems: {
            criteria: "CHECKLIST_TEMPLATE_LIST_NUMBER_OF_ITEMS",
        },
        creation: {
            criteria: "CHECKLIST_TEMPLATE_LIST_CREATION",
        },
    };

    $onInit();

    function $onInit() {
        _fetchChecklistTemplates();
    }

    function createNewChecklist($event) {
        var dialog = $mdDialog.createChecklistTemplate().targetEvent($event);

        return $mdDialog.show(dialog).then(_onCreateChecklistDialogDone);
    }

    function _onCreateChecklistDialogDone(results) {
        $sbTracking.checklistTemplates.creation.started();

        return $sbChecklistService
            .createChecklistTemplate($sbProject.getCurrentProjectId(), {
                name: results.name,
            })
            .then(_checkCreateServiceResponse)
            .then(_goToEditorPage)
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
            });
    }

    function _checkCreateServiceResponse(serviceResponse) {
        if (!serviceResponse || !serviceResponse.id) {
            throw new Error(
                "Unable to go to editor page as the creation response is missing property id"
            );
        }
        return serviceResponse;
    }

    function _goToEditorPage(createdChecklist) {
        $state.go(SABLONO_STATES.checklistTemplatesEditor, {
            checklistTemplateId: createdChecklist.id,
        });
    }

    function _fetchChecklistTemplates() {
        $sbChecklistService
            .fetchAllChecklistTemplatesForProject(
                $sbProject.getCurrentProjectId()
            )
            .then(_checkServiceResponse)
            .then(_setChecklists)
            .finally(function () {
                vm.isFetching = false;
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
            });
    }

    function _checkServiceResponse(serviceResponse) {
        if (!serviceResponse || !_.isArray(serviceResponse)) {
            throw new Error(
                "Unable to set checklists as server response is invalid data type"
            );
        }
        return serviceResponse;
    }

    function _setChecklists(checklistTemplates) {
        vm.checklistTemplates = _sortChecklistTemplates(checklistTemplates);
    }

    function _sortChecklistTemplates(checklists) {
        var naturalSort = $filter("naturalSort");

        return naturalSort(checklists, "name");
    }

    function isEmpty() {
        return !vm.isFetching && vm.checklistTemplates.length === 0;
    }
}
