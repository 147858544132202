export default function RequestAccessCtrl($state) {
    "ngInject";

    init();

    function init() {
        const el = document.getElementById("requestAccess");
        el.toState = $state.current.name;
    }
}
