import deliverablesTemplateHtml from "./deliverables.html";

export default function ($stateProvider) {
    "ngInject";

    var allowedFilter = [
        "name",
        "desc",
        "code",
        "search",
        "start",
        "end",
        "progress",
        "notes",
        "schedule",
        "workflow",
        "structureId",
        "stage",
        "type",
        "inspection",
        "workScheduled",
    ];

    $stateProvider.state("sablono.project.ngdeliverables", {
        templateUrl: deliverablesTemplateHtml,
        controller: "DeliverablesCtrl",
        controllerAs: "deliverables",
        url:
            "/ngdeliverables?" +
            allowedFilter.join("&") +
            "&open&selectedTab&sort",
        reloadOnSearch: false,
        gaPageTrack: "/ngdeliverables", // google analytics extension
        data: {
            title: "SECTION_DELIVERABLES_PAGE_TITLE",
            category: "REVIEW", // PLAN or WORK
            searchEnabled: true,
            searchPlaceholder: "_FILTER_SEARCH_DELIVERABLE_PLACEHOLDER",
        },
        onEnter: function (intercomService, Analytics, TRACKED_EVENTS) {
            "ngInject";

            intercomService.track(TRACKED_EVENTS.ENTERED_DELIVERABLES_LIST);
            Analytics.trackNorthStarExploration("open deliverables");
        },
    });
}
