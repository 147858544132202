import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sb_inspection_badge.html";
import "common/services/services";
export default angular
    .module("sbApp.sbInspectionBadge", ["sbApp.services"])
    .component("sbInspectionBadge", {
        templateUrl: htmlTemplate,
        bindings: {
            authorInitials: "<",
            authorName: "<",
            date: "<",
            customTranslation: "<",
        },
        controller: function ($sbDates, $translate) {
            "ngInject";
            var vm = this;

            vm.$onChanges = function (change) {
                if (change.date) {
                    if (
                        change.date.currentValue !== change.date.previousValue
                    ) {
                        _onDateChange();
                    }
                }

                if (change.authorName || change.authorInitials) {
                    _onAuthorChange();
                }
            };

            function _onDateChange() {
                if (vm.date) {
                    vm.formattedDate = $sbDates
                        .anyToMoment(vm.date)
                        .format("L");
                    vm.formattedDateLLL = $sbDates
                        .anyToMoment(vm.date)
                        .format("LLL");
                }
                _generateTooltip();
            }

            function _onAuthorChange() {
                _generateTooltip();
            }

            function _generateTooltip() {
                vm.tooltip = $translate.instant(
                    vm.customTranslation || "INSPECTED_BY",
                    {
                        inspector: vm.authorName,
                        timestamp: vm.formattedDateLLL,
                    }
                );
            }
        },
    });
