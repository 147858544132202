import html from "./sb_deliverable_checklist_item.html";
import { v4 as uuidv4 } from "uuid";

export default {
    templateUrl: html,
    transclude: true,
    controllerAs: "checklist",
    bindings: {
        item: "<",
        openChecklist: "&",
        printChecklist: "&",
    },
    controller: function SbDeliverableChecklistItem(
        $sbPermission,
        $sbCurrentProject,
        $timeout
    ) {
        "ngInject";

        var vm = this;
        vm.$onChanges = $onChanges;

        function $onChanges(changes) {
            // This timeout is needed because without it the element is looked in the DOM before
            // the randomElementId has been evaluated.
            $timeout(function () {
                const el = document.getElementById(vm.randomElementId);
                if (changes.item && el) {
                    el.item = changes.item.currentValue;
                    el.canOpenTemplate = $sbPermission.hasAdminPermissions(
                        $sbCurrentProject.pick("privileges")
                    );
                }
            }, 1);
        }
        vm.onOpenChecklist = function () {
            vm.openChecklist();
        };

        vm.randomElementId = uuidv4();
    },
};
