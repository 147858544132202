import angular from "angular";
import moment from "moment";

export default function UserProfileCtrl(
    profileService,
    $scope,
    $log,
    $sbErrorPresenter,
    $mdDialog,
    $mdToast
) {
    "ngInject";

    var vm = this;

    vm.badgePreview = {
        mail: "",
        displayName: "",
        initials: "",
    };
    vm.user = {}; // form model
    vm.original = {}; // just for comparison - dirty check

    vm.newEmail = "";
    vm.passwordPolicy = null;

    vm.$onInit = fetchAndSetUser;

    vm.submitUserAttributeChanges = submitUserAttributeChanges;
    vm.updatePreview = updatePreview;
    vm.changePassword = changePassword;

    vm.isEqual = isEqualUserInfo;

    ///////////////////////////////
    //
    //      IMPL
    //
    ///////////////////////////////

    function isEqualUserInfo(one, two) {
        if (one.name !== two.name) {
            return false;
        }
        return one.company === two.company;
    }

    function fetchAndSetUser() {
        return profileService
            .getUser()
            .then(function (user) {
                vm.user = user;
                vm.original = angular.copy(user);
                updatePreview(user);
            })
            .catch($sbErrorPresenter.catch);
    }

    function updatePreview(user) {
        vm.badgePreview.displayName = `${user.name} (${user.company})`;
        vm.badgePreview.initials = (user.name || "")
            .split(" ")
            .slice(0, 2)
            .map((word) => word[0])
            .join("");
        vm.badgePreview.mail = user.email;
    }

    function submitUserAttributeChanges(user) {
        return profileService
            .modifyCurrentUser(user)
            .then(() => (vm.original = angular.copy(user)))
            .then(_showSuccess)
            .catch($sbErrorPresenter.catch);
    }

    function _showSuccess() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("INFO_SAVE_SUCCESS_TITLE")
                .position("top right")
        );
    }

    function _showPasswordSuccess() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("INFO_PASSWORD_CHANGE_SUCCESS_TITLE")
                .position("top right")
        );
    }

    function changePassword() {
        $mdDialog
            .show($mdDialog.promptPassword())
            .then((oldPassword) =>
                profileService.changePassword({
                    oldPassword,
                    newPassword: vm.newPassword,
                })
            )
            .then(() => (vm.newPassword = vm.confirmNewPassword = ""))
            .then(_showPasswordSuccess)
            .catch((error) => {
                if (error) {
                    $log.error(error);
                    const alert = $mdDialog
                        .alert()
                        .title(error.name)
                        .content(error.message)
                        .ok("DIALOG_ALERT_OK");

                    return $mdDialog.show(alert);
                }
            });
    }
}
