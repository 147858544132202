import ActivityChecklistMapper from "./sb_activity_checklist_mapper";

export default function DeliverableChecklistsService(
    $sbChecklistsApi,
    Checklist,
    ChecklistHandover,
    $sbExternalService
) {
    "ngInject";
    var service = {
        fetchChecklistsByDeliverableId: fetchChecklistsByDeliverableId,
        fetchSignedChecklist: fetchSignedChecklist,
        createChecklistHandover: createChecklistHandover,
    };

    function fetchChecklistsByDeliverableId(deliverableId) {
        return $sbExternalService.getChecklistSequences(deliverableId);
    }

    function fetchSignedChecklist(activityId, checklistId) {
        return $sbChecklistsApi
            .get(activityId, checklistId)
            .then((checklist) =>
                ActivityChecklistMapper.toChecklistDomain(checklist)
            );
    }

    /**
     * Combine two checklists to form a handover checklist
     *
     * @param {Object} activityChecklistWithDetails
     * @return {ChecklistHandover}
     */
    function createChecklistHandover(activityChecklistWithDetails) {
        var checklistHandover = new ChecklistHandover(
            activityChecklistWithDetails.executionChecklist,
            activityChecklistWithDetails.qualityPipeline
        );
        checklistHandover
            .setReviewChecklists(activityChecklistWithDetails.reviewChecklists)
            .setConfirmationChecklist(
                activityChecklistWithDetails.confirmationChecklist
            );

        return checklistHandover;
    }

    return service;
}
