import angular from "angular";
import ViewSelectionProvider from "common/plainModels/ViewSelectionProvider";

export default function TemplatesCtrl(
    EVENTS,
    Analytics,
    $scope,
    $state,
    $sbTemplate,
    $sbErrorPresenter,
    addTemplateDialog,
    $mdToast,
    $mdDialog,
    $mdMedia,
    TEMPLATE_CATEGORY_MAPPING,
    whitelist,
    templatesService,
    templateEditorService,
    $sbTeam,
    $stateParams
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $scope.$on(
        "$stateChangeSuccess",
        function onStateChangeSuccess(event, toState, toParams) {
            var initMode = toParams.init;
            if (initMode === "create") {
                vm.openAddTemplate();
            }
        }
    );

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Checks whether a certain item matches our searched criteria
     * @param {String} item - object to check.
     * @returns {*|boolean}
     */
    vm.searchItems = function (item) {
        return whitelist.some(function (key) {
            var property = item[key];
            if (property) {
                return (
                    property
                        .toLowerCase()
                        .indexOf(vm.searchTerm.toLowerCase()) !== -1 ||
                    vm.searchTerm === ""
                );
            } else {
                return false;
            }
        });
    };

    /**
     * A string that is used in the filters to search for code or name.
     *
     * @type {string} - text to search for
     */
    vm.searchTerm = "";

    /**
     * Show a loading indication if not loaded.
     *
     * @type {boolean} - flag
     */
    vm.loaded = false;

    /**
     * Private List of all the templates.
     *
     * @type {Array} - list of templates
     */
    var templates = [];

    /**
     * Array of object of all groups that can be sorted
     * @type {Array}
     */
    vm.groups = [
        {
            name: "ACTION_UNGROUPED",
            key: "UNGROUP",
            label: false,
        },
        {
            name: "ACTION_CREATOR",
            key: "CREATOR_DISPLAY_NAME",
            label: true,
        },
        {
            name: "ACTION_CATEGORY",
            key: "CATEGORY",
            label: true,
        },
        {
            name: "ACTION_CODE",
            key: "CODE",
            label: true,
        },
        {
            name: "ACTION_ACTIVITIES",
            key: "ACTIVITIES_COUNT",
            label: true,
        },
        {
            name: "ACTION_REVISION",
            key: "REVISION",
            label: true,
        },
    ];

    /**
     * Array of object of all header columns
     * @type {Array}
     */
    vm.header = [
        {
            class: "sb-template-column category-column",
            key: "CATEGORY",
            text: "ACTION_CATEGORY",
            hasIcon: false,
        },
        {
            class: "sb-template-column code-column",
            key: "CODE",
            text: "ACTION_CODE",
            hasIcon: true,
        },
        {
            class: "sb-template-column name-column",
            key: "NAME",
            text: "ACTION_NAME",
            hasIcon: true,
        },
        {
            class: "sb-template-column revision-column",
            key: "REVISION",
            text: "ACTION_REVISION",
            hasIcon: true,
        },
        {
            class: "sb-template-column desc-column",
            key: "DESC",
            text: "ACTION_DESCRIPTION",
            hasIcon: true,
        },
        {
            class: "sb-template-column activities-column",
            key: "ACTIVITIES_COUNT",
            text: "ACTION_ACTIVITIES",
            hasIcon: true,
        },
        {
            class: "sb-template-column initials-column",
            key: "CREATOR_INITIALS",
            text: "ACTION_CREATOR",
            hasIcon: true,
        },
        {
            class: "sb-template-column date-column",
            key: "CREATION_DATE",
            text: "ACTION_DATE",
            hasIcon: true,
        },
    ];

    vm.currentName = vm.groups[0].name;
    vm.currentKey = vm.groups[0].key;

    vm.orderFilter = "NAME";
    vm.reverse = false;

    /**
     * Holds the selection state
     * @type {ViewSelectionProvider}
     */
    vm.selectionProvider = new ViewSelectionProvider("ID");

    /**
     * Called by the headers to sort the table. First click sort ascending, second click sort descending
     * @param  {String} filter - A string rappresenting the key of the templates Object used for sorting
     */
    vm.changeOrder = function (filter) {
        if (vm.orderFilter === filter) {
            vm.reverse = !vm.reverse;
        } else {
            vm.reverse = false;
            vm.orderFilter = filter;
        }
    };

    /**
     * Find out if there are no group displayed. Used to show the messages
     * @return {Boolean} - true if is empty
     */
    vm.isEmpty = function () {
        return !vm.groupedTemplates.some(function (group) {
            return (
                group.filteredTemplates && group.filteredTemplates.length > 0
            );
        });
    };
    /**
     * Group the templates by a key
     * @param  {String} key  - The key by which they will be sorted
     * @param  {String} name - The name that will be used in the group title
     */
    vm.groupTemplates = function (key, name) {
        vm.currentKey = key;
        vm.currentName = name;
        if (key === "UNGROUP") {
            vm.groupedTemplates = [
                {
                    name: "SECTION_ALL_THE_TEMPLATES_1_TITLE",
                    key: key,
                    groupingElement: "",
                    templates: templates,
                },
            ];
            return;
        }
        var alreadyMeet = [];
        var grouped = [];

        // map the templates to groups
        //
        templates.map(function (elem) {
            var mappingValue = elem[key];

            if (key === "CATEGORY") {
                mappingValue = TEMPLATE_CATEGORY_MAPPING[mappingValue]
                    ? mappingValue
                    : "GROUP";
            }
            // check if there is already a group for the template then -> add else -> create new
            //
            var index = alreadyMeet.indexOf(mappingValue);
            if (index === -1) {
                var newGroup = {
                    name: name,
                    key: key,
                    groupingElement: mappingValue,
                    templates: [],
                };
                alreadyMeet.push(mappingValue);
                newGroup.templates.push(elem);
                grouped.push(newGroup);
            } else {
                grouped[index].templates.push(elem);
            }
        });

        vm.groupedTemplates = grouped;
    };

    /*
		 I know that this looks really ugly but unfortunatelly I didn't found a proper solution
		 If you sort some values with the < = > operators and you have some undefined or null values
		 on it the will always be on top when you sort in an ascendent order.
		 This is because
		 (undefined > 1) -> true
		 (undefined > "A") -> true
		 So I transform every undefined to a lot of ZZZ so that the undefined is always on bottom if you sort it.

		 Me and Mathias talked about this out and we didn't found a better solution. If you can find it please
		 implement it.

		 14:45 05.08.2015 - Matteo Cardellini
		 */
    vm.sortGroups = function (group) {
        if (angular.isUndefined(group) || group === null) {
            return "ZZZZZZZZZZZZ";
        } else {
            return group.groupingElement;
        }
    };

    vm.sortTemplates = function (template) {
        var value = template[vm.orderFilter];
        if (
            angular.isUndefined(value) ||
            value === null ||
            (angular.isString(value) && value === "")
        ) {
            return "ZZZZZZZZZZZZ";
        }
        return value;
    };

    let globalTemplatesPromise;
    vm.loadCloneableTemplates = () => {
        if (globalTemplatesPromise === undefined) {
            globalTemplatesPromise = templatesService.fetchUserTemplates();
        }
        return globalTemplatesPromise;
    };

    vm.openCloneTemplateDialog = ($event) => {
        return vm
            .loadCloneableTemplates()
            .then((templates) => (vm.existingTemplate = templates))
            .then(() =>
                templatesService.triggerCloneTemplateWorkflow(
                    $event,
                    vm.existingTemplate
                )
            )
            .then(_fetchTemplatesFromServer)
            .then(_clearTeamsCache);
    };

    function _fetchTemplatesFromServer() {
        $sbTemplate.clearCache();

        return $sbTemplate
            .getTemplates()
            .then(function (data) {
                groupedTemplatesBinding(data);
                vm.groupTemplates(vm.currentKey, vm.currentName);
            })
            .then((data) => joinWithLocalDataState())
            .catch($sbErrorPresenter.catch);
    }

    /**
     * Open the dialog for adding a template
     */
    vm.openAddTemplate = function () {
        addTemplateDialog
            .openDialogAdd()
            .then(function (template) {
                Analytics.trackEvent(
                    "Process templates",
                    "Create",
                    "Newly created"
                );
                $mdToast.show(
                    $mdToast
                        .simple()
                        .content("INFO_TEMPLATE_ADD_SUCCESS_TITLE")
                        .position("top right")
                );
                templates.push(template);
                vm.groupTemplates(vm.currentKey, vm.currentName);

                // redirect to template editor
                return $state.go(
                    "sablono.project.templateEditor.nodeDetailView",
                    {
                        templateId: template.ID,
                    }
                );
            })
            .catch(function () {
                // remove the init parameter if set.
                return $state.go(
                    "sablono.project.templates",
                    {
                        init: undefined,
                    },
                    {
                        notify: false,
                    }
                );
            });
    };

    /**
     * Get a list of all the selected templates
     * @return {Array} - An array containing all the selected templates
     */
    vm.getSelectedTemplates = function () {
        return vm.selectionProvider.selections();
    };

    /**
     * Find out if there are templates to be deleted. Ask for confirmation and the delete the templates
     */
    vm.deleteTemplates = function () {
        const toBeDeleted = vm.getSelectedTemplates();

        // nothing to delete
        //
        if (toBeDeleted.length < 1) {
            return;
        }

        // template still used on deliverables
        //
        var usedInComponents = toBeDeleted.reduce(function (used, template) {
            return template.USED_IN_COMPONENTS + used;
        }, 0);

        if (usedInComponents > 0) {
            return $mdDialog.show(
                $mdDialog
                    .alert()
                    .clickOutsideToClose(true)
                    .title("ALERT_DELETE_TEMPLATES_NOT_ALLOWED_TITLE")
                    .content("ALERT_DELETE_TEMPLATES_NOT_ALLOWED_MESSAGE")
                    .contentValues({
                        value: usedInComponents,
                    })
                    .ok("DIALOG_ALERT_OK")
            );
        }

        // deletion of unused process templates
        //
        return $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .content("CONFIRM_DELETE_TEMPLATES_MESSAGE")
                    .contentValues({
                        value: toBeDeleted.length,
                    })
            )
            .then(function () {
                //Call delete Templates service
                return $sbTemplate
                    .deleteTemplates(toBeDeleted)
                    .then(function (deletedTemplates) {
                        var succesfully = 0;
                        var withError = 0;
                        //map over the result. If it's fulfilled increase the number of success otherwise increase the number of errors
                        var reallyDeleted = deletedTemplates.map(
                            function (elem, index) {
                                if (elem.status === "fulfilled") {
                                    succesfully++;
                                    return toBeDeleted[index];
                                } else {
                                    withError++;
                                }
                            }
                        );

                        $mdToast.show(
                            $mdToast
                                .simple()
                                .content("INFO_TEMPLATE_REMOVE_MESSAGE")
                                .contentValues({
                                    success: succesfully,
                                    errors: withError,
                                })
                                .position("top right")
                        );

                        //Delete from the view
                        reallyDeleted.map(function (deleted) {
                            templates = templates.filter(function (template) {
                                return template !== deleted;
                            });
                        });

                        vm.groupTemplates(vm.currentKey, vm.currentName);
                        vm.selectionProvider.clearSelection();
                    })
                    .catch($sbErrorPresenter.catch);
            });
    };

    /* Global search service receiver */
    var searchListener = $scope.$on(
        EVENTS.GLOBAL_SEARCH_CHANGE,
        function (event, eventData) {
            vm.searchTerm = eventData.searchTerm;
        }
    );

    $scope.$on("$destroy", function () {
        searchListener();
    });

    //init state manually
    $onInit();

    function $onInit() {
        vm.groupedTemplates = [];
        return $sbTemplate
            .getTemplates()
            .then((data) => {
                vm.loaded = true;
                groupedTemplatesBinding(data);
            })
            .then((data) => joinWithLocalDataState())
            .catch($sbErrorPresenter.catch);
    }

    function groupedTemplatesBinding(data) {
        templates = data;
        vm.groupedTemplates = [
            {
                name: "SECTION_ALL_THE_TEMPLATES_1_TITLE",
                groupingElement: "",
                templates: data,
            },
        ];
    }
    function joinWithLocalDataState() {
        templates.forEach((template) => {
            templateEditorService
                .getTemplateDetailsFromLocalStore(template.ID)
                .then(() => {
                    template.isLocalData = true;
                })
                .catch(() => {
                    template.isLocalData = false;
                });
        });
    }

    function _clearTeamsCache() {
        $sbTeam.clearTeamsCache($stateParams.projectId);
    }
}
