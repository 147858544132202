import angular from "angular";
import moment from "moment";
import htmlTemplate from "./scheduling_mode.html";

export default angular
    .module("sbApp.ProjectSettingsModule.schedulingMode", [])
    .component("sbSchedulingMode", {
        templateUrl: htmlTemplate,
        bindings: {
            mode: "<",
            isEditable: "<",
            onModeChange: "&?",
        },
    });
