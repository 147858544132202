import exportTemplateHtml from "./export.html";
import downloadTemplateHtml from "./states/download/download.html";
import fileTemplateHtml from "./states/fileBased/file_based.html";

export default function ($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.export", {
        templateUrl: exportTemplateHtml,
        controller: "ExportCtrl",
        controllerAs: "export",
        // an export is always source key related or the keyword "PROJECT" that means the whole project
        url: "/export/{src:[0-9]*|PROJECT}",
        gaPageTrack: "/export", // google analytics extension
        data: {
            title: "SECTION_EXPORT_TITLE",
            category: "REVIEW",
        },
        resolve: {
            exportSrcObject: function ($sbProject, $stateParams, project) {
                "ngInject";
                if ($stateParams.src !== "PROJECT") {
                    return $sbProject
                        .getProjectSource(
                            project.id,
                            Number.parseInt($stateParams.src, 10)
                        )
                        .catch(function (error) {
                            return error;
                        });
                } else {
                    return {
                        NAME: "PROJECT",
                        PROJECT_ID: project.id,
                    };
                }
            },
        },
    });

    $stateProvider.state("sablono.project.export.download", {
        templateUrl: downloadTemplateHtml,
        controller: "DownloadCtrl",
        controllerAs: "download",
        url: "/download",
        data: {
            hasBackButton: true,
        },
        params: {
            downloadKey: undefined,
            exportStats: {},
            fileName: "",
        },
    });

    $stateProvider.state("sablono.project.export.file", {
        templateUrl: fileTemplateHtml,
        controller: "FileBasedCtrl",
        controllerAs: "fileBased",
        url: "/file",
        data: {},
    });
}
