import "moment-timezone";
import "common/services/services";
import "common/intercom/intercomModule";

export default function ImportProgressCtrl(
    $mdDialog,
    $sbErrorPresenter,
    $sbImportProgressFacadeService,
    $sbProject,
    EXCEL_EXTENSIONS,
    $sbTracking,
    $sbExcelReaderService,
    $log,
    Analytics
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    vm.fileName = undefined;
    vm.xlsData = undefined;

    vm.uploadSuccess = false;
    vm.dataUploading = false;
    vm.disableUpload = true;
    vm.uploadHasAtLeastAFailure = false;

    vm.onFileChanged = _onFileChanged;
    vm.upload = _uploadAndHandleErrors;

    vm.allowedExtensions = EXCEL_EXTENSIONS;

    vm.viewConstants = {
        successTitle: "SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TITLE",
        successText: "SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TEXT",
        successButtonLink: "sablono.project.ngdeliverables",
        successButtonText: "TO_PROJECT_DATA",
    };

    function _onFileChanged($event, file, name) {
        return $sbProject
            .getCalendar()
            .then((calendar) => calendar.TIMEZONE.name)
            .then((timezone) =>
                $sbExcelReaderService.excelBinaryToRows(file, timezone)
            )
            .then((xlsData) => {
                vm.fileName = name;
                vm.xlsData = xlsData;
            })
            .catch((err) => {
                $log.error(err);
                $mdDialog.show(
                    $mdDialog.alert().title(err.name).content(err.message)
                );
            })
            .finally(() => {
                vm.disableUpload = false;
            });
    }

    function _uploadAndHandleErrors() {
        try {
            _upload();
        } catch (e) {
            $sbErrorPresenter.catch(e);
            vm.dataUploading = false;
        }
    }

    function _upload() {
        vm.dataUploading = true;
        return $sbImportProgressFacadeService
            .sendExcelDataToServer(vm.xlsData)
            .then((result) => {
                Analytics.trackConversion("data imported/updated");

                if (result.allUpdatesSuccessful) {
                    vm.uploadSuccess = true;
                    $sbTracking.progress.import.success();
                } else {
                    vm.xlsData = result.updatedXls;
                    vm.uploadHasAtLeastAFailure = true;
                    $sbTracking.progress.import.failure();
                }
            })
            .catch((err) => {
                if (err.message === "ERROR_ENTRIES_LIMITATION") {
                    $sbTracking.progress.import.tooLongInputFailure();
                    $mdDialog.show(
                        $mdDialog
                            .alert()
                            .title(err.title ? err.title : "UNKNOWN")
                            .content("ERROR_PROGRESS_ENTRIES_LIMITATION")
                    );
                    return;
                }
                $sbTracking.progress.import.failure();
                $sbErrorPresenter.catch(err);
            })
            .finally(() => {
                vm.dataUploading = false;
                vm.disableUpload = true;
            });
    }
}
