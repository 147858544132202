import _ from "lodash";
import angular from "angular";
import html from "./svg.tmpl.html";

export default {
    templateUrl: html,
    controllerAs: "svg",
    bindings: {
        svgCode: "<",
        components: "<",
        selection: "<?",
        onComponentClick: "<?",
        onMouseOver: "<?",
        onMouseOut: "<?",
        colorProperty: "@",
        parentId: "@",
    },
    controller: function SvgComponentCtrl(
        $document,
        $timeout,
        $rootScope,
        $sbSvgGenerate,
        $sbSketchVisualizationSetting,
        $log,
        MODES,
        EVENTS
    ) {
        "ngInject";

        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        const MAX_BORDER_PX_WIDTH = 4;
        const UPLOADED_SVG_BORDER_SCALAR = 4;
        const CSS_UNIT_VALUE_REGEX = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/;

        const CONTAINER_ID = "sb-svg";

        var currentSvgCode = "";
        var currentComponents = [];
        var currentSelection = [];

        const originalStrokes = new Map();
        const originalStrokeStyles = new Map();

        var vm = this;

        /////////////////////
        //
        //      WATCHER
        //
        /////////////////////

        var updateListener = $rootScope.$on(
            EVENTS.SVG__UPDATE_ELEMENT,
            function (event, data) {
                _findAndSetSvgElement(data);
            }
        );

        const borderListener = $rootScope.$watch(function () {
            return $sbSketchVisualizationSetting.model.showBorder;
        }, _updateSvgBorderColors);

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onDestroy = function () {
            updateListener();
            borderListener();
        };

        vm.$onChanges = function (changes) {
            if (
                changes.svgCode &&
                changes.svgCode.currentValue !== changes.svgCode.previousValue
            ) {
                if (changes.svgCode.currentValue.length > 0) {
                    currentSvgCode = _removeSvgExistingClasses(
                        changes.svgCode.currentValue
                    );
                }
            }
            if (
                changes.components &&
                changes.components.currentValue !==
                    changes.components.previousValue
            ) {
                if (changes.components.currentValue.length > 0) {
                    currentComponents = changes.components.currentValue;
                }
            }

            if (_hasSelectionChanged(changes)) {
                currentSelection = changes.selection.currentValue;
            }

            if (currentSvgCode || currentComponents || currentSelection) {
                // wrap the svg calculation in a timeout to allow the digest cycle to
                // change the ready state. Otherwise the $onChange digest collection
                // is updating the DOM only once
                return $timeout(function () {
                    $sbSvgGenerate.resetSvgPatterns();
                    _attachSvg(currentSvgCode, CONTAINER_ID);
                    _setPrintStyleClass(CONTAINER_ID);
                    _matchComponents(currentComponents, currentSvgCode);
                }, 0);
            }
        };

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        function _hasSelectionChanged(changes) {
            return (
                _.get(changes, "selection.currentValue") !==
                _.get(changes, "selection.previousValue")
            );
        }

        function _removeSvgExistingClasses(svgCode) {
            // eslint-disable-next-line no-useless-escape
            const removeClassTagRegExpWithDoubleQuotes = /class=\"[^\"]+\"/gim;
            // eslint-disable-next-line no-useless-escape
            const removeClassTagRegExpWithSingleQuotes = /class=\'[^\']+\'/gim;
            return svgCode
                .replace(removeClassTagRegExpWithDoubleQuotes, "")
                .replace(removeClassTagRegExpWithSingleQuotes, "");
        }

        function _attachSvg(svgCode, containerId) {
            var svgContainer = angular.element(
                $document[0].getElementById(containerId)
            );
            svgContainer.empty();
            svgContainer.off("click");
            svgContainer.on("click", _clickElement);
            svgContainer.off("mouseover");
            svgContainer.off("mouseout");

            if (
                !_is_touch_device4() &&
                _.isFunction(vm.onMouseOver) &&
                _.isFunction(vm.onMouseOut)
            ) {
                svgContainer.on("mouseover", _.debounce(vm.onMouseOver, 400));
                svgContainer.on("mouseover", placeHoverColor);
                svgContainer.on("mouseout", vm.onMouseOut);
                svgContainer.on("mouseout", revertHoverColor);
            }

            svgContainer.append(svgCode);

            _setViewBox(svgContainer);
        }

        function _setViewBox(svgContainer) {
            const viewBox = svgContainer.children().attr("viewBox");
            if (viewBox) {
                const [, , width, height] = viewBox.split(" ");
                vm.viewBox = {
                    width: Number.parseFloat(width),
                    height: Number.parseFloat(height),
                };
            }
        }

        //  IMPORTANT This is not 100% accurate
        // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
        function _is_touch_device4() {
            var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

            var mq = function (query) {
                return window.matchMedia(query).matches;
            };

            if (
                "ontouchstart" in window ||
                (window.DocumentTouch && document instanceof DocumentTouch) // eslint-disable-line
            ) {
                return true;
            }

            // include the 'heartz' as a way to have a non matching MQ to help terminate the join
            // https://git.io/vznFH
            var query = [
                "(",
                prefixes.join("touch-enabled),("),
                "heartz",
                ")",
            ].join("");
            return mq(query);
        }

        function _setPrintStyleClass(containerId) {
            const svgContainer = angular.element(
                $document[0].getElementById(containerId)
            );
            if (vm.viewBox) {
                if (vm.viewBox.width > vm.viewBox.height) {
                    $log.debug(
                        `viewBox: ${vm.viewBox.width}w x ${vm.viewBox.height}h -> prefer landscape print`
                    );
                    svgContainer[0].sbAddClass("sb-print-prefer-landscape");
                } else {
                    $log.debug(
                        `viewBox: ${vm.viewBox.width}w x ${vm.viewBox.height}h -> prefer portrait print`
                    );
                    svgContainer[0].sbAddClass("sb-print-prefer-portrait");
                }
            }
        }

        function _matchComponents(components) {
            originalStrokes.clear();
            originalStrokeStyles.clear();
            components.forEach(_findAndSetSvgElement);
        }

        function _findAndSetSvgElement(component) {
            var svgDOMelement = angular.element(
                $document[0].getElementById(component.CODE)
            )[0];
            if (svgDOMelement) {
                _setSvgElement(svgDOMelement, component);
            }
        }

        function _setSvgElement(svgElement, component) {
            _storeOriginalStyles(svgElement, component.CODE);

            _applyDefaultStylesAndAttributes(svgElement, component);

            _applyOutlineForIssues(svgElement, {
                id: component.CODE,
                color: component.STROKE_COLOR,
            });

            _applyHighlightIfSelectedForProgressUpdate(svgElement, component);

            _applyHatchPatternIfWaitingForConfirmationOrAvailable(
                svgElement,
                component
            );

            _applyCrossHatchPatternIfRejected(svgElement, component);
        }

        function _storeOriginalStyles(svgElement, id) {
            originalStrokeStyles.set(id, svgElement.style.stroke);
            originalStrokes.set(id, svgElement.style.strokeWidth);
        }

        function _applyDefaultStylesAndAttributes(svgElement, component) {
            svgElement.style.fill = component[vm.colorProperty];
            svgElement.style.fillOpacity = 1;
            svgElement.style.opacity = 1;

            svgElement.setAttribute("deliverable", component.ID);

            // to apply styling for focused stats
            //
            svgElement.sbAddClass("sb-svg-element");
        }

        function resetStrokeStyles(svgElement, { id }) {
            svgElement.style.strokeWidth = originalStrokes.get(id);
            svgElement.style.stroke = originalStrokeStyles.get(id);
        }

        function setStrokeStyles(svgElement, { color }) {
            svgElement.style.stroke = color;
            const cssWidth = svgElement.style.strokeWidth || "1";
            // css width can be anything like "1", "0", "1rem", "+12.34%", "2.3px"
            const [, width, unit] = cssWidth.match(CSS_UNIT_VALUE_REGEX);

            svgElement.style.strokeWidth =
                Math.min(
                    MAX_BORDER_PX_WIDTH,
                    width * UPLOADED_SVG_BORDER_SCALAR
                ).toString() + unit;
        }

        function _applyOutlineForIssues(svgElement, { id, color }) {
            resetStrokeStyles(svgElement, { id });
            if ($sbSketchVisualizationSetting.model.showBorder && color) {
                setStrokeStyles(svgElement, { color });
            }
        }

        function _applyHoverOutline(svgElement, { id, color }) {
            resetStrokeStyles(svgElement, { id });
            setStrokeStyles(svgElement, { color });
        }

        function executeOnEvent($event, callback) {
            const id = $event.target.getAttribute("deliverable");
            const component = _.find(vm.components, ["ID", id]);
            if (component) {
                callback($event.target, component);
            }
        }

        function revertHoverColor($event) {
            executeOnEvent($event, (svgElement, component) =>
                _applyOutlineForIssues(svgElement, {
                    color: component.STROKE_COLOR,
                    id: component.CODE,
                })
            );
        }

        function placeHoverColor($event) {
            executeOnEvent($event, (svgElement, component) =>
                _applyHoverOutline(svgElement, {
                    color: "#365781",
                    id: component.CODE,
                })
            );
        }

        function _updateSvgBorderColors() {
            return $timeout(function () {
                vm.components.forEach((component) => {
                    const svgDOMelement = angular.element(
                        $document[0].getElementById(component.CODE)
                    )[0];
                    if (svgDOMelement) {
                        _applyOutlineForIssues(svgDOMelement, {
                            id: component.CODE,
                            color: component.STROKE_COLOR,
                        });
                    }
                });
            }, 0);
        }

        function _applyHighlightIfSelectedForProgressUpdate(
            svgElement,
            component
        ) {
            if (_hasBeenSelectedForProgressUpdate(component)) {
                svgElement.sbAddClass("sb-svg-element--selected");
            } else {
                svgElement.sbRemoveClass("sb-svg-element--selected");
            }
        }

        function _applyHatchPatternIfWaitingForConfirmationOrAvailable(
            svgElement,
            component
        ) {
            var isWaitingForConfirmationOrAvailable = !!component.HATCH_COLOR;

            if (
                isWaitingForConfirmationOrAvailable &&
                _isBrowseOrShowStatusMode()
            ) {
                _applyColoredPatternToSvg(
                    svgElement,
                    "hatched",
                    component[vm.colorProperty]
                );
            }
        }

        function _applyCrossHatchPatternIfRejected(svgElement, component) {
            var isRejected = !!component.CROSS_HATCH_COLOR;

            if (isRejected && _isBrowseOrShowStatusMode()) {
                _applyColoredPatternToSvg(
                    svgElement,
                    "crossHatched",
                    component[vm.colorProperty]
                );
            }
        }

        function _applyColoredPatternToSvg(svgElement, patternType, color) {
            var svg = $sbSvgGenerate.getSvg(CONTAINER_ID);
            var pattern = _getOrCreatePatternOn(svg, patternType, color);
            svgElement.style.fill = pattern.fill();
        }

        function _isBrowseOrShowStatusMode() {
            return (
                vm.colorProperty === MODES.STAGE.colorProperty ||
                vm.colorProperty === MODES.BROWSE_ACTIVITY.colorProperty
            );
        }

        function _hasBeenSelectedForProgressUpdate(component) {
            return (
                _.isArray(currentSelection) &&
                currentSelection.indexOf(component.ID) !== -1
            );
        }

        function _clickElement(event) {
            if (_.isFunction(vm.onComponentClick)) {
                vm.onComponentClick(event.target);
            }
        }

        function _getOrCreatePatternOn(svg, type, color) {
            var pattern = $sbSvgGenerate.findPatternBy(type, color);

            // when not existing create and find it
            //
            if (_.isUndefined(pattern)) {
                $sbSvgGenerate.createPatternOnSvg(svg, color);
                pattern = $sbSvgGenerate.findPatternBy(type, color);
            }

            return pattern;
        }
    },
};
