export default function SketchGeneratorService(
    $log,
    $q,
    $sbSvg,
    $sbSvgGenerate
) {
    "ngInject";
    return {
        loadVisualization: loadVisualization,
        fetchContentByMode: $sbSvg.fetchVisualizationContentByMode,
        generateVisualizationFromConfig: generateVisualizationFromConfig,
    };

    /**
     * Load the visualization by fetching the configuration then pass it on to generate
     * a SVG from that configuration.
     *
     * @param {Number} visualizationKey
     */
    function loadVisualization(visualizationKey) {
        $log.debug(
            "SketchGeneratorService::loadVisualization",
            visualizationKey
        );

        // get configuration to generate SVG
        return (
            $sbSvg
                .fetchConfigurationByKey(visualizationKey)

                // transform configuration to be used by generator
                .then(transformServerConfigToGeneratorInput)

                // pass to generator
                .then(function (generatorInput) {
                    return {
                        name: generatorInput.name,
                        svg: generateVisualizationFromConfig(generatorInput),
                    };
                })
        );
    }

    /**
     * Transform the drawing configuration to the format needed by the svg generator.
     *
     * @param {DrawingConfiguration} config
     * @return {{headers: {left: string, right: string}, data: *}}
     */
    function transformServerConfigToGeneratorInput(config) {
        $log.debug(
            "SketchGeneratorService::transformServerConfigToGeneratorInput",
            config
        );

        return config.toSvgGeneratorInput("id");
    }

    /**
     * The configuration needed for generating an SVG.
     *
     * @param config
     *
     * @param config.headers - Map with translations key to text.
     * @param config.headers.left
     * @param config.headers.right
     *
     * @param config.data
     */
    function generateVisualizationFromConfig(config) {
        $log.debug(
            "SketchGeneratorService::generateVisualizationFromConfig",
            config
        );

        return $sbSvgGenerate.createSvg(config.data, {
            structureColumnTitle: config.headers.left,
            deliverablesColumnTitle: config.headers.right,
        });
    }
}
