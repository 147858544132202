import ConstantsModule from "./constants.module";
import pck from "../../../package.json";

ConstantsModule.constant("VERSION", pck.version);

// set app version to window
//
window.sablono = window.sablono || {};
window.sablono.version = pck.version;

export default pck.version;
