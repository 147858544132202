/**
 *
 * Routing concept here:
 *
 */
import sablonoRootHtml from "./sablono.html";
import projectHtml from "./states/project.html";
import ParseableError from "../../errors/ParseableError";

export default function ($stateProvider) {
    "ngInject";
    // THE ROOT SABLONO STATE
    //
    $stateProvider.state("sablono", {
        abstract: true,
        templateUrl: sablonoRootHtml,
        controller: "SablonoCtrl as sablono",
        data: {
            isSubBarHidden: true,
        },
        resolve: {
            session: function (
                $sbAuth,
                intercomService,
                $sbFeatureDecisions,
                $sbExternalService,
                Analytics
            ) {
                "ngInject";
                return $sbAuth.getCurrentUser().then((user) => {
                    // call intercom boot in async manner -> should not affect our service
                    intercomService.boot(user);

                    $sbExternalService.setIntercomService(intercomService);
                    $sbFeatureDecisions.updateContext({ user });

                    Analytics.trackIdentity(user.id);
                    return user;
                });
            },
        },
    });

    // the project state -> so everything that is related to a project is child of this state
    //
    $stateProvider.state("sablono.project", {
        //[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}
        abstract: true,
        url: "/{projectId:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}}",
        templateUrl: projectHtml,
        controller: "ProjectCtrl as project",
        data: {
            isSubBarHidden: false,
        },
        resolve: {
            project: function resolveProjectAndPrivileges(
                $stateParams,
                $sbProjectApi,
                $sbCurrentProject,
                $sbProjectCurrentSettings,
                $sbDetailsOverlayData,
                $sbAuth,
                $sbAuthStore,
                $sbMembership,
                $state,
                $sbErrorPresenter
            ) {
                "ngInject";

                return Promise.resolve(
                    $sbAuthStore.getLastSuccessfulSignInIdentity()
                )
                    .then((user) => {
                        if (!user) {
                            // In case no sign in is stored locally we sign out and go to login page
                            return $sbAuth.signOut();
                        }
                        return $sbMembership.get(
                            $stateParams.projectId,
                            user.userId
                        );
                    })
                    .then(() => {
                        return $sbProjectApi
                            .get($stateParams.projectId)
                            .then($sbCurrentProject.set)
                            .then((project) => {
                                $sbDetailsOverlayData.setCurrentProject(
                                    project
                                );
                                $sbProjectCurrentSettings.clear();
                                return project;
                            })
                            .catch(function (err) {
                                throw err;
                            });
                    })
                    .catch(function (err) {
                        $sbErrorPresenter.catch(err);
                        if (err instanceof ParseableError) {
                            return $state.go(
                                "sablono.projectSelection",
                                {},
                                {
                                    location: "replace",
                                }
                            );
                        } else {
                            throw err;
                        }
                    });
            },
        },
    });
}
