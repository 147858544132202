import { general } from "../../globals";

export default function has(testable) {
    var tests = {
        development: function development() {
            return !general.isProductionBuild;
        },
        app_sablono_com: function appSablonoCom() {
            const hostname = window.location.hostname; // eslint-disable-line
            console.debug("Hostname: %c" + hostname, "color: #FF4500"); // eslint-disable-line

            return ["app.sablono.com", "beta.sablono.com"].some(
                function (item) {
                    return hostname === item;
                }
            );
        },
        uk_sablono_com: () => {
            const hostname = window.location.hostname; // eslint-disable-line
            console.debug("Hostname: %c" + hostname, "color: #FF4500"); // eslint-disable-line

            return ["uk.sablono.com"].some(function (item) {
                return hostname === item;
            });
        },
    };

    // return false if test case not found
    //
    if (!tests[testable]) {
        return false;
    }

    return !!tests[testable].call(null);
}
