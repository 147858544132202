//TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
// Whole file can be removed
export default function ImportProjectCtrl(
    $scope,
    $log,
    $rootScope,
    $mdDialog,
    $controller,
    $state,
    $sbParseXml,
    $sbFileReader,
    XML_FILE_TYPE,
    $sbProject,
    $sbCurrentProject,
    $sbComponent,
    $sbErrorPresenter
) {
    "ngInject";
    var vm = this;

    var projectSources = []; // all available sources in the project

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.selectedFile = false;
    vm.fileProcessing = false;

    vm.fileName = "";

    vm.allowedExtensions = XML_FILE_TYPE;
    vm.openFileChooser = openFileChooser;
    vm.onFileChanged = onFileChanged;

    vm.projectSource = $state.params.src;

    activate();

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function activate() {
        //Since we have multiple sources, we need to store them for later check on duplicates
        $sbProject
            .getProjectSource($sbCurrentProject.pick("id"))
            .then((sources) => (projectSources = sources))
            .catch($sbErrorPresenter.catch);
    }

    function onFileChanged(file, name) {
        vm.fileProcessing = true;
        vm.selectedFile = true;
        vm.fileName = name;

        $sbFileReader
            .readFile(file)
            .then(_loadFileIntoController)
            .finally(function () {
                vm.fileProcessing = false;
            });
    }

    function _setData(parsedXmlData) {
        // store the source type to witch between ui views
        //
        vm.projectSource = parsedXmlData.sourceType;

        // Check only in case we have a source available previously
        //
        if (projectSources && projectSources.length > 0) {
            var sourceToImport = {
                NAME: parsedXmlData.source.type,
                DATA: {
                    title: parsedXmlData.source.data.title,
                },
            };

            for (var i = 0; i < projectSources.length; i++) {
                // if sources are equal
                //
                if (_areSourcesSimilar(projectSources[i], sourceToImport)) {
                    //Generate dialog
                    var confirm = $mdDialog
                        .confirm()
                        .title("DIALOG_IMPORT_MERGE_CONFLICT_TITLE")
                        .content("DIALOG_IMPORT_MERGE_CONFLICT_CONTENT")
                        .targetEvent(event)
                        .ok("DIALOG_IMPORT_MERGE_ACTION")
                        .cancel("DIALOG_IMPORT_CONTINUE_ACTION");

                    return $mdDialog.show(confirm).then(
                        function gotoMerge() {
                            // on OK
                            return $state.go("sablono.project.merge", {
                                src: projectSources[i].id,
                            });
                        },
                        function continueWithImport() {
                            // on Cancel
                            sendToPreview(parsedXmlData);
                        }
                    );
                }
            }
        }
        sendToPreview(parsedXmlData);
    }

    function sendToPreview(parsedXmlData) {
        var model = $sbComponent.createComponentUploadModel({
            projectId: $sbProject.getCurrentProjectId(),
            source: parsedXmlData.source,
            components: parsedXmlData.components,
            structure: parsedXmlData.structureList,
        });

        model.removeStructureSingles();
        model.setEdges(parsedXmlData.edges);

        // store the latest upload model in the rootImport view model
        //
        $scope.rootImport.setUploadModel(model, vm.fileName);

        // inform the preview
        //
        $scope.$broadcast("sb.upload.preview", {
            headerTexts: {
                structure: "STRUCTURE_BRACES",
                projectElements: "PROJECT_ELEMENTS_BRACES",
            },
            uploadModel: model,
        });
    }

    /**
     * Basic error handling if we have no other error handling
     *
     */
    function _handleError(oErrorEvent) {
        $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_ANALYSING_FILE_ERROR_TITLE")
                .content("DIALOG_ANALYSING_FILE_ERROR_CONTENT")
        );
        $log.error("Catched Error: " + oErrorEvent.message, oErrorEvent.stack);
    }

    function _loadFileIntoController(fileReaderResult) {
        return $sbFileReader
            .parseXml(fileReaderResult.rawData)
            .then(function (data) {
                $log.debug("parse success", data);
                return $sbProject.getCalendar().then(function (calendar) {
                    $log.debug("timezone is", calendar.TIMEZONE);
                    return $sbParseXml.transformImport(
                        data,
                        vm.projectSource,
                        calendar.TIMEZONE.name
                    );
                });
            })
            .then(_setData)
            .catch(_handleError);
    }

    function openFileChooser($event) {
        $scope.$broadcast("sbFileInput.open", $event);
    }

    /**
     *
     * @param {ProjectSource} source
     * @param {Object} newSourceToImport
     * @return {boolean|boolean}
     * @private
     */
    function _areSourcesSimilar(source, newSourceToImport) {
        const sameTitle = source.data.title === newSourceToImport.DATA.title;
        const sameType = source.type === newSourceToImport.NAME;
        return sameTitle && sameType;
    }
}
