import moment from "moment";
import WorkingCalendar from "./working_calendar.model";
import AllDayCalendar from "./all_day_calendar.model";
import WorkFreeCalendar from "./work_free_calendar.model";

//
// CONTENT TO COPY TO BACKEND STARTS HERE !
//

/**
 * The consumable calendar is a wrapper for
 * the AllDayCalendar and the WorkingCalendar. If the unit is wh -> use WorkingCalendar,
 * otherwise use AllDayCalendar.
 *
 * @class ConsumableCalendar
 * @property {WorkingCalendar} workingCalendar
 * @property {AllDayCalendar} allDayCalendar
 *
 * @param {Array<Boolean>}        days   - An array containing all the days of the week. e.g: [0,1,1,1,1,1,0] 0 -> not working | 1 -> working
 * @param {Array<Array<String>>}  blocks - An array containing all the shifts blocks. e.g: [["08:00", "12:00"], ["13:00", "18:00"]]
 * @param {WorkFreeCalendar}      exceptionDates - An array containing all the shifts blocks. e.g: [["08:00", "12:00"], ["13:00", "18:00"]]
 *
 */
function ConsumableCalendar(days, blocks, exceptionDates) {
    this.workingCalendar = new WorkingCalendar(days, blocks, exceptionDates);
    this.allDayCalendar = new AllDayCalendar();
}

ConsumableCalendar.createFrom = function (calendar) {
    var days = calendar && Array.isArray(calendar.days) ? calendar.days : [];
    var blocks =
        calendar && Array.isArray(calendar.blocks) ? calendar.blocks : [];
    var workFreeCalendar = new WorkFreeCalendar();

    if (calendar && calendar.exceptionDates) {
        calendar.exceptionDates.forEach(function (exceptionDate) {
            workFreeCalendar.addNonWorkingDay(exceptionDate);
        });
    }

    return new ConsumableCalendar(days, blocks, workFreeCalendar);
};

/**
 * fromServerCalendarObject - Transform the serverCalendar in to a ConsumableCalendar
 *
 * @param  { {DAYS: Array<Number>, BLOCKS: Array<Array<String>>, exceptionDates: WorkFreeCalendar} } serverCalendar - The calendar coming from the server
 * @return {ConsumableCalendar}    - A new ConsumableCalendar created from the calendar of the server
 */
ConsumableCalendar.fromServerCalendarObject = function (serverCalendar) {
    //fallback if serverCalendar is not defined
    //
    var days = [1, 1, 1, 1, 1, 1, 1];
    var blocks = [["08:00", "16:00"]];
    var exceptionDates;

    if (serverCalendar) {
        days = serverCalendar.DAYS;
        blocks = serverCalendar.BLOCKS;
        exceptionDates = serverCalendar.exceptionDates;
    }

    return new ConsumableCalendar(days, blocks, exceptionDates);
};

/**
 * Find the earliest possible start for a task based on the given starting time and calendar.
 *
 * @param {Moment} fromTime
 * @param {String} unit
 *
 * @returns {Moment}
 */
ConsumableCalendar.prototype.findEarliestStartForTask = function (
    fromTime,
    unit
) {
    if (this.isWorkingTimeUnit(unit)) {
        return this.workingCalendar.findEarliestStartForTask(fromTime);
    } else {
        return this.allDayCalendar.findEarliestStartForTask(fromTime);
    }
};

/**
 * Get the total working time between two moments based on the working calendar
 * with shifts and exception dates.
 *
 * @param {Moment} start
 * @param {Moment} end
 *
 * @returns {float}
 */
ConsumableCalendar.prototype.getTotalWorkingTimeBetween = function (
    start,
    end
) {
    return this.workingCalendar.getTotalWorkingTimeBetween(start, end);
};

/**
 * Find the earliest possible end for a task based on the given starting time,
 * needed duration and calendar.
 *
 * @param {Moment} fromTime
 * @param {number} duration
 * @param {String} unit
 *
 * @returns {Moment}
 */
ConsumableCalendar.prototype.findEarliestEndForTask = function (
    fromTime,
    duration,
    unit
) {
    var hourlyDuration = this.mapDurationToSmallerUnit(duration, unit);

    if (this.isWorkingTimeUnit(unit)) {
        return this.workingCalendar.findEarliestEndForTask(
            fromTime,
            hourlyDuration
        );
    } else {
        return this.allDayCalendar.findEarliestEndForTask(
            fromTime,
            hourlyDuration
        );
    }
};

/**
 * Find the latest possible start for a task based on the given ending time,
 * needed duration and calendar.
 *
 * @param {Moment} fromTime
 * @param {number} duration
 * @param {String} unit
 *
 * @returns Moment}
 */
ConsumableCalendar.prototype.findLatestStartForTask = function (
    fromTime,
    duration,
    unit
) {
    var hourlyDuration = this.mapDurationToSmallerUnit(duration, unit);

    if (this.isWorkingTimeUnit(unit)) {
        return this.workingCalendar.findLatestStartForTask(
            fromTime,
            hourlyDuration
        );
    } else {
        return this.allDayCalendar.findLatestStartForTask(
            fromTime,
            hourlyDuration
        );
    }
};

/**
 * Find the latest possible end for a task based on the given ending time and calendar.
 *
 * @param {Moment} fromTime
 * @param {String} unit
 *
 * @returns {Moment}
 */
ConsumableCalendar.prototype.findLatestEndForTask = function (fromTime, unit) {
    if (this.isWorkingTimeUnit(unit)) {
        return this.workingCalendar.findLatestEndForTask(fromTime);
    } else {
        return this.allDayCalendar.findLatestEndForTask(fromTime);
    }
};

ConsumableCalendar.prototype.findEarlierValidMorning = function (date, unit) {
    if (this.isWorkingTimeUnit(unit)) {
        return this.workingCalendar.findEarlierValidMorning(date);
    } else {
        return moment(date);
    }
};

ConsumableCalendar.prototype.findLaterValidEvening = function (date, unit) {
    if (this.isWorkingTimeUnit(unit)) {
        return this.workingCalendar.findLaterValidEvening(date);
    } else {
        return moment(date);
    }
};

/**
 * Maps the given duration from (working) days to (working) hours.
 * If hour units is given the duration is passed through.
 *
 * @param {number} duration
 * @param {String} unit - Possible values are: wd, d
 *
 * @returns {number}
 */
ConsumableCalendar.prototype.mapDurationToSmallerUnit = function (
    duration,
    unit
) {
    var hourlyDuration = duration;

    if (unit === WorkingCalendar.WORKING_DAYS_UNIT_ENUM) {
        hourlyDuration = duration * this.workingCalendar.getDailyWorkingHours();
    }

    if (unit === WorkingCalendar.DAYS_UNIT_ENUM) {
        hourlyDuration = duration * 24;
    }

    return hourlyDuration;
};

/**
 * Maps the given duration from (working) hours to (working) days.
 * If day units are given the duration is passed through.
 *
 * @param {float} duration
 * @param {string} unit - Possible values are: wh, h
 *
 * @returns {integer}
 */
ConsumableCalendar.prototype.mapDurationToNextLargerUnit = function (
    duration,
    unit
) {
    var dailyDuration = duration;
    var dailyWorkingHours = this.workingCalendar.getDailyWorkingHours();

    if (
        unit === WorkingCalendar.WORKING_HOURS_UNIT_ENUM &&
        dailyWorkingHours !== 0
    ) {
        dailyDuration = duration / dailyWorkingHours;
    }

    if (unit === WorkingCalendar.HOURS_UNIT_ENUM) {
        dailyDuration = duration / 24;
    }

    return Math.ceil(dailyDuration);
};

/**
 * Check if given units comply with working units from the WorkingCalendar class.
 *
 * @param {String} unit
 * @returns {boolean}
 */
ConsumableCalendar.prototype.isWorkingTimeUnit = function (unit) {
    return (
        unit === WorkingCalendar.WORKING_HOURS_UNIT_ENUM ||
        unit === WorkingCalendar.WORKING_DAYS_UNIT_ENUM
    );
};

//
// CONTENT TO COPY TO BACKEND ENDS HERE !
//

export default ConsumableCalendar;
