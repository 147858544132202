import filterContentTemplate from "./filter_content.html";
import ViewFilter from "common/services/oDataService/odata_view_filter.class";
export default {
    templateUrl: filterContentTemplate,
    transclude: true,
    bindings: {
        viewFilters: "=",
        onFilterChange: "&",
        showAdvanced: "=",
    },
    controller: function FilterPanelContentCtrl() {
        var vm = this;

        vm.TYPE = ViewFilter.TYPE;

        vm.onFilterChange = function () {};
    },
};
