import moment from "moment";
// import moment from "moment-timezone";

export default function ($sbXML) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        collectAllComponentsFromXml: _collectAllComponentsFromXml,
        assemblePreviewModel: _assemblePreviewModel,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Assembles a preview model from Compontent Map
     * @param {Object} oCompMap Components for the Preview Model
     * @returns {{structure: Array, components: Array}}
     * @private
     */
    function _assemblePreviewModel(oCompMap) {
        // the map is msp xml code to entity object that we created from the task
        //  1.3.4.2.1 -> { name , code, ext, ... }
        //
        // eventually -> components should have a structure reference
        //            -> structuring nodes should have a parentExt

        var componentKeys;
        var structureKeys = {};
        var uidToExternalId = {};

        // we check all elements and look for their parents.
        //      -> these parents are added to structure
        //      -> afterwards we add all elements that are not structure to the component list
        Object.keys(oCompMap).forEach(function (componentCode) {
            var oComponent = oCompMap[componentCode],
                code = oComponent.code.split("."),
                parentCode = code.slice(0, -1);

            // we need to know which file dependent identifier is used to reference a specific element
            //      (e.g.: to transform the edge src and trg ID's properly)
            uidToExternalId[oComponent._taskUid] = oComponent.ext;

            parentCode = parentCode.join(".");
            var parentC = oCompMap[parentCode];

            if (parentC) {
                oComponent.parentExt = parentC.ext;

                // add it to structureKeys
                //
                structureKeys[parentCode] = parentC;
            }
        });

        // everything that is not structure is component
        //
        componentKeys = Object.keys(oCompMap).filter(function (componentCode) {
            return !structureKeys[componentCode];
        });

        return {
            structureList: Object.keys(structureKeys).map(
                function (structureCode) {
                    return structureKeys[structureCode];
                }
            ),
            components: componentKeys.map(function (componentCode) {
                var oComponent = oCompMap[componentCode];
                oComponent.structure = oComponent.parentExt;
                oComponent.parentExt = undefined;
                return oComponent;
            }),
            edges: componentKeys.reduce(function (_edges, componentCode) {
                var oComponent = oCompMap[componentCode];
                for (var i = 0; i < oComponent.predecessors.length; i++) {
                    _edges.push({
                        src: uidToExternalId[oComponent.predecessors[i]],
                        trg: oComponent.ext,
                    });
                }
                return _edges;
            }, []),
        };
    }

    function _getMspUID(oTask) {
        return $sbXML.getXmlTagTextContent(oTask, "UID", "");
    }

    /**
     * Get Unique_Task_ID representing identifier for extended attributes
     */
    function _getUniqueTaskFieldId(oXML) {
        //Note: whenever performing XML operations use childNodes instead of children
        //they're supported by IE.
        var extendedAttributes =
            oXML.getElementsByTagName("ExtendedAttributes")[0].childNodes;
        for (var i = 0, len = extendedAttributes.length; i < len; i++) {
            var alias = $sbXML.getXmlTagTextContent(
                extendedAttributes[i],
                "Alias"
            );
            if (alias === "Unique_Task_ID") {
                return $sbXML.getXmlTagTextContent(
                    extendedAttributes[i],
                    "FieldID",
                    ""
                );
            }
        }
    }

    /**
     * Get ASTA unique identifier for our current task
     * @param {Document} oXML - XML object representing the whole file
     * @param {Task} oTask - Task object representing the current task for which we need the UID
     */
    function _getAstaUIDFn(oXML) {
        //Since in ASTA Unique_Task_ID is not a tag but part of extended attributes in all the tasks
        //So we have to determine first what is Id of Unique_Task_ID extended attribute and then find
        //it in each individual task.
        /*  Example:
         * -> In file
         * <ExtendedAttributes>
         *     <ExtendedAttribute>
         *        <FieldID>123</FieldID>
         *        <FieldName>Flag1</FieldName>
         *        <Alias>Buffer_Task</Alias>
         *     </ExtendedAttribute>
         *     <ExtendedAttribute>
         *        <FieldID>124</FieldID>
         *        <FieldName>Text1</FieldName>
         *        <Alias>Unique_Task_ID</Alias>
         *     </ExtendedAttribute>
         * </ExtendedAttributes>
         *
         * -> In same file under each Task Tag:
         * <ExtendedAttribute>
         *    <FieldID>123</FieldID>
         *    <Value>True</Value>
         * </ExtendedAttribute>
         * <ExtendedAttribute>
         *    <FieldID>124</FieldID>
         *    <Value>1210</Value>
         * </ExtendedAttribute>
         *
         */
        var uniqueTaskFieldId = _getUniqueTaskFieldId(oXML);

        return function _getAstaUID(oTask) {
            //Get extended attributes of the task
            var extendedTaskAttributes =
                oTask.getElementsByTagName("ExtendedAttribute");
            //Loop through everyone until it matches the uniqueTaskFieldId
            for (var i = 0, len = extendedTaskAttributes.length; i < len; i++) {
                /* eslint-disable eqeqeq */
                if (
                    uniqueTaskFieldId ==
                    $sbXML.getXmlTagTextContent(
                        extendedTaskAttributes[i],
                        "FieldID",
                        ""
                    )
                ) {
                    //Return the value but only if it is not falsy
                    var uid = $sbXML.getXmlTagTextContent(
                        extendedTaskAttributes[i],
                        "Value"
                    );
                    if (uid) {
                        return uid;
                    } else {
                        // fallback for UID is the msp approach (<UID>)
                        return _getMspUID(oTask);
                    }
                }
                /* eslint-enable eqeqeq */
            }
        };
    }

    /**
     * Search Components from Document
     * @private
     *
     * @param {Object} oXML         - Document to be searched
     * @param {String} sourceType   - Just a flag marking the type of import
     * @param {String} timezone     - The name of the timezone that is used for imported dates.
     * @returns {Object} - map from component code to component object
     */
    function _collectAllComponentsFromXml(oXML, sourceType, timezone) {
        var oComponentsMap = {},
            expression;

        var computeUID =
            sourceType === "ASTA" ? _getAstaUIDFn(oXML) : _getMspUID;

        // collect tasks -> basis for components and structure
        //  and collect the needed attributes
        //
        Array.prototype.forEach.call(
            oXML.getElementsByTagName("Task"),
            function (oTask) {
                var sUID = computeUID(oTask),
                    taskUid = $sbXML.getXmlTagTextContent(oTask, "UID", ""),
                    sName = $sbXML.getXmlTagTextContent(oTask, "Name", ""),
                    sDescription = $sbXML.getXmlTagTextContent(
                        oTask,
                        "Notes",
                        ""
                    ),
                    sStart = $sbXML.getXmlTagTextContent(oTask, "Start", ""),
                    sFinished = $sbXML.getXmlTagTextContent(
                        oTask,
                        "Finish",
                        ""
                    ),
                    sDuration = $sbXML.getXmlTagTextContent(
                        oTask,
                        "Duration",
                        ""
                    ),
                    nPriority = Number.parseInt(
                        $sbXML.getXmlTagTextContent(oTask, "Priority", 0),
                        10
                    ),
                    sWBS = $sbXML.getXmlTagTextContent(
                        oTask,
                        "OutlineNumber",
                        "unstructured"
                    ), //ID
                    aPredecessor = $sbXML.getXmlTagTextContentFromSelector(
                        oTask,
                        "PredecessorLink > PredecessorUID"
                    ),
                    oComponent;

                // MSP priority is in the interval [0 - 1000].
                // This interval has to be mapped to the values [1,2,3]
                //
                nPriority = Math.round(nPriority / 500) + 2;

                // create new component
                oComponent = {
                    _taskUid: taskUid,
                    ext: sUID,
                    name: sName,
                    desc: sDescription,
                    sd: moment.tz(sStart, timezone).valueOf(),
                    cd: moment.tz(sFinished, timezone).valueOf(),
                    duration: sDuration,
                    prio: nPriority,
                    code: sWBS,
                    predecessors: aPredecessor, // store the plain UID's for predecessors.
                    origin: sourceType,
                };
                //remove "empty" cells
                expression = oComponent.name && oComponent.sd && oComponent.cd;
                if (!expression) {
                    return;
                }

                oComponentsMap[oComponent.code] = oComponent;
            }
        );
        return oComponentsMap;
    }
}
