import angular from "angular";
import htmlTemplate from "./sb_filter_user.html";
export default angular
    .module("sbApp.sbFilterUser", [])
    .directive("sbFilterUser", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "filterText",
            templateUrl: htmlTemplate,
            replace: true,
            scope: {
                /**
                 * @typeof ViewFilter
                 */
                viewFilter: "=",

                // callback to notify the outside world
                onChange: "=",
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "filterText.viewFilter.value",
                    function (newValue, oldValue) {
                        // support clear viewFilter from outside -> newValue is undefined
                        if (newValue === undefined && newValue !== oldValue) {
                            controller.activate();
                        }
                    }
                );
            },
            controller: function FilterTextCtrl($sbTeam, $sbProject) {
                "ngInject";
                var vm = this;
                var projectId;

                vm.searchTextChanged = searchTextChanged;
                vm.onInputChange = onInputChange;
                vm.onSubmit = onSubmit;
                vm.activate = activate;
                vm.$onInit = $onInit;
                vm.suggestUsers = suggestUsers;
                vm.searchText = "";
                vm.searchUser;

                activate();

                ///////////
                //
                //  IMPL
                //
                ///////////

                function $onInit() {
                    projectId = $sbProject.getCurrentProjectId();
                    $sbTeam.requestAndCacheUsersForProjectIfNeeded(projectId);
                }

                function suggestUsers(searchText) {
                    return $sbTeam.getUsersByProjectAndNameOrEmail(
                        projectId,
                        searchText
                    );
                }

                function activate() {
                    vm.searchText = vm.viewFilter.value
                        ? vm.viewFilter.value[0]
                        : "";
                }

                function onSubmit(value) {
                    onInputChange(value);
                    _blur();
                }

                function onInputChange(value) {
                    if (angular.isString(value) && value.length > 0) {
                        vm.viewFilter.setValue(value);
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    } else {
                        clearInput();
                    }
                }

                function _blur() {
                    // hack to blur on enter because we do not have direct access to the
                    // input element of the md-autocomplete directive
                    document.activeElement.blur();
                }

                function clearInput() {
                    if (vm.viewFilter.hasValue()) {
                        vm.searchText = "";
                        vm.viewFilter.clear();
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    }
                }

                function searchTextChanged(value) {
                    if (!value) {
                        clearInput();
                    }
                }
            },
        };
    });
