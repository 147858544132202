export default class UsersWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";

        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the list of known users.
     *
     * A user is known to you when you are at least once in the same project
     * OR
     * if an entered search term is matching the user id or the user email exactly.
     *
     * @param  {string} search
     */
    getCollection(search) {
        const endpointUrl = `/api/${this.API_VERSION}/api/users`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                search,
            },
        });
    }
}
