import moment from "moment";
import templateHtml from "./date_select.html";

export default {
    name: "sbDateSelect",
    templateUrl: templateHtml,
    bindings: {
        selectDate: "&",
    },
    controller: DateSelectCtrl,
};

function DateSelectCtrl($rootScope, $sbDateSelect, EVENTS) {
    "ngInject";

    var vm = this;

    vm.dateRangeList = {
        untilToday: {
            displayValue: "_TODAY",
            isDefault: true,
            value: "untilToday",
            date: moment().endOf("day"),
        },
        until1MonthAgo: {
            displayValue: "_END_OF_LAST_MONTH",
            isDefault: false,
            value: "until1MonthAgo",
            date: moment().subtract(1, "months").endOf("month"),
        },
    };

    vm.customDateRange = {
        displayValue: "_CUSTOM_DATE",
        isDefault: false,
        value: "custom",
    };

    vm.isForecastMode = false;
    vm.$onInit = $onInit;
    vm.showCustomCalendar = showCalendar;
    vm.onPresetDateSelection = onPresetDateSelection;
    vm.getDisplayedText = getDisplayedText;

    var FORECAST_MODE_CALENDAR_ICON = "mdi-calendar-alert";
    var CALCULATE_MODE_CALENDAR_ICON = "mdi-calendar";

    $rootScope.$on(
        EVENTS.PLAN_WORK_REVIEW_SECTION_CHANGED,
        function (event, toSection) {
            vm.isForecastMode = toSection === "PLAN";
        }
    );

    function $onInit() {
        // initial selection
        //
        onPresetDateSelection(vm.dateRangeList.untilToday.value);
    }

    function showCalendar($event) {
        var previousSelection = vm.dateRangeKey;
        var previousSelectedDate = vm.selectedDate;

        return $sbDateSelect
            .openCalendar($event, {
                selectedDate: moment(vm.selectedDate).toDate(),
            })
            .then(function onDateSelected(selectedDate) {
                if (selectedDate) {
                    _onCustomDateSelection(selectedDate);
                } else {
                    _restorePreviousSelection(
                        previousSelection,
                        previousSelectedDate
                    );
                }
            });
    }

    function getDisplayedText() {
        var icon = vm.isForecastMode
            ? FORECAST_MODE_CALENDAR_ICON
            : CALCULATE_MODE_CALENDAR_ICON;

        return (
            '<span class="sb-date-select__placeholder"><i class="mdi ' +
            icon +
            '"></i> ' +
            vm.displayedSelection +
            " </span>"
        );
    }

    function onPresetDateSelection(presetOptionValue) {
        var previousDate = moment(vm.selectedDate);
        var currentDate = vm.dateRangeList[presetOptionValue].date;

        _updateSelection(presetOptionValue, currentDate);
        _ifChangedNotify(previousDate, currentDate);
    }

    function _onCustomDateSelection(selectedDate) {
        var previousDate = moment(vm.selectedDate);
        var currentDate = moment(selectedDate);

        _updateSelection(vm.customDateRange.value, currentDate);
        _triggerSelectTextUpdate();
        _ifChangedNotify(previousDate, currentDate);
    }

    function _updateSelection(optionValue, date) {
        vm.selectedDate = date.toDate();
        vm.dateRangeKey = optionValue;
        vm.displayedSelection = date.format("ll");
    }

    function _restorePreviousSelection(previousSelection, previousDate) {
        var previousRange = vm.dateRangeList[previousSelection];
        if (previousRange) {
            onPresetDateSelection(previousSelection);
        } else {
            _onCustomDateSelection(previousDate);
        }
    }

    function _triggerSelectTextUpdate() {
        vm.dateRange = {};
        vm.dateRange.key = vm.customDateRange.value;
    }

    function _ifChangedNotify(previousDate, currentDate) {
        if (!previousDate.isSame(currentDate, "day")) {
            vm.selectDate({
                reportedAt: currentDate,
            });
        }
    }
}
