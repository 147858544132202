import Branch from "./Branch";
/**
 * Class for Vertical Branches
 *
 * @param {Object} rootNode - Root Node of Branch
 * @param {Array} structureNodeList - Elements connected to Root Node via Branch
 * @constructs VerticalBranch
 * @extends WBS.Branch
 * @memberof WBS

 */
function VerticalBranch(rootNode, structureNodeList) {
    Branch.call(this, rootNode, structureNodeList);
}

/**
 * Setup prototypal inheritance.
 * VerticalBranch inherits from Branch.
 *
 * @type {Branch}
 */
VerticalBranch.prototype = Object.create(Branch.prototype);

/**
 * call this function every time you update the rootNode or any structure node in the list
 */
VerticalBranch.prototype.calcBranch = function () {
    Branch.prototype.calcBranch.call(this);

    if (!this.rootNode || this.structureNodeList.length === 0) {
        return;
    }

    this.x = this.rootNode.x;
    this.y = this.rootNode.y;

    var rootX = -this.rootNode.width / 2 + 15;

    var allY = this.getAllY();
    allY.push(0);
    var minY = Math.min.apply(Math, allY);
    var maxY = Math.max.apply(Math, allY);

    var g = this.branch.graphics;

    //draw vertical line
    //
    g.moveTo(rootX, minY + this.rootNode.height / 2 + 1).lineTo(rootX, maxY);

    //draw lines to structure nodes
    //
    this.structureNodeList.forEach(function (node) {
        g.moveTo(node.x - node.node.width / 2 - 1, node.y).lineTo(
            rootX,
            node.y
        );
    }, this);
};

export default VerticalBranch;
