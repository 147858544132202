import structureActivityDetailsPanelTemplate from "./sb_structure_activity_details_panel.html";
import StructureActivityDetailsPanelController from "./sb_structure_activity_details_panel.controller";

export default function PanelProviderConfig($mdPanelProvider) {
    "ngInject";
    $mdPanelProvider.definePreset("$sbStructureActivityDetailsPanel", {
        controller: StructureActivityDetailsPanelController,
        controllerAs: "$ctrl",
        templateUrl: structureActivityDetailsPanelTemplate,
        focusOnOpen: false,
        zIndex: 100,
        clickOutsideToClose: true,
        escapeToClose: true,
        hasBackdrop: true,
    });
}
