import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sb_delay.html";
export default angular
    .module("sbApp.components.sbDelay", [
        "sbApp.components.sbLabeledProperty",
        "sbApp.domain",
    ])
    .component("sbDelay", {
        templateUrl: htmlTemplate,
        bindings: {
            remainingWork: "<",
            latestEnd: "<",
            earliestEnd: "<",
            calendar: "<",
            timezone: "<",
            tooltip: "@?",
        },
        controller: function DelayCtrl(SbDeliverable) {
            "ngInject";
            var vm = this;

            vm.delayByDays = delayByDays;
            vm.isCompletable = isCompletable;

            // Support different API for now
            //
            function delayByDays(remainingDays, endDate) {
                return SbDeliverable.delayByDays(
                    mapToInput(remainingDays, endDate),
                    vm.calendar,
                    moment.tz(vm.timezone)
                );
            }

            function isCompletable(remainingDays, endDate) {
                return SbDeliverable.isCompletable(
                    mapToInput(remainingDays, endDate),
                    vm.calendar,
                    moment.tz(vm.timezone)
                );
            }

            function mapToInput(remainingDays, endDate) {
                return {
                    remainingWorkingDays: remainingDays,
                    plannedEnd: endDate,
                };
            }
        },
    });
