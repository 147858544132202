import commercialValuationHtml from "./commercial-valuation.html";

export default function ($stateProvider) {
    "ngInject";

    $stateProvider.state("sablono.project.commercialValuation", {
        templateUrl: commercialValuationHtml,
        controller: "CommercialValuationCtrl",
        controllerAs: "commercialPro",
        url: "/commercial-valuation",
        gaPageTrack: "/commercial-valuation", // google analytics extension
        data: {
            title: "SECTION_COMMERCIAL_PRO_PAGE_TITLE",
            category: "REVIEW", // PLAN or WORK
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";

            intercomService.track(
                TRACKED_EVENTS.ENTERED_COMMERCIAL_VALUATION_PAGE
            );
        },
    });
}
