import angular from "angular";
import IssueEditorController from "./issue_editor.controller";
import issueEditorService from "./issue_editor.service";
import dialogTemplate from "./issue_editor.tmpl.html";

import ServiceModule from "./../../services/services";
import DomainModule from "./../../../domain/domain.module";

export default angular
    .module("sbApp.sbIssueEditorDialog", [
        DomainModule.name,
        ServiceModule.name,
        "ngMaterial",
    ])
    .factory("$sbIssueEditorService", issueEditorService)
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("issueEditor", {
            methods: [
                "title",
                "teamSelectorTitle",
                "teamConfirmationTitle",
                "class",
                "note",
                "teams",
                "confirmationTeamSettings",
                "filterExtensions",
            ],
            options: function IssueEditorDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: IssueEditorController,
                    controllerAs: "$ctrl",
                    bindToController: true,
                };
            },
        });
    });
