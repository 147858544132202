import angular from "angular";

export default function InspectDeliverablesCtrl(
    inspectDeliverablesService,
    $stateParams,
    $sbStructure,
    $sbStateChangesApi,
    loadingToast,
    $mdToast,
    $window,
    $sbErrorPresenter,
    Analytics
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    // this is used to only show the list for the last submitted search query
    let currentRequestNumber = 0;

    let structureMapper = new Map();

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.onSearchTextChange = onSearchTextChange;
    vm.onFakeSearchIndication = onFakeSearchIndication;
    vm.searchDeliverablesBy = searchDeliverablesBy;
    vm.onStateChange = onStateChange;
    vm.clearSearch = clearSearch;
    vm.onBackPressed = onBackPressed;
    vm.$onInit = $onInit;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onInit() {
        vm.projectId = $stateParams.projectId;

        vm.searchText = "";
        vm.deliverables = [];

        vm.isShowSearchHintEnabled = true;
        vm.hasNoSearchResults = false;
        vm.isSearching = false;

        $sbStructure.asMap(vm.projectId).then((map) => (structureMapper = map));
    }

    function onSearchTextChange(searchText) {
        var hasSearchTextCleared =
            angular.isUndefined(searchText) || searchText === "";
        if (hasSearchTextCleared) {
            vm.clearSearch();
        }
    }

    function clearSearch() {
        vm.$onInit();
    }

    function onFakeSearchIndication(searchText) {
        return vm.searchDeliverablesBy(searchText).then(function () {
            return [];
        });
    }

    function searchDeliverablesBy(searchText) {
        var requestNumber = ++currentRequestNumber;

        vm.isShowSearchHintEnabled = false;
        vm.isSearching = true;

        return inspectDeliverablesService
            .searchDeliverablesWithActivitiesBy(
                vm.projectId,
                searchText,
                structureMapper
            )
            .then(function (deliverables) {
                // the user already submitted a new search, just ignore the result
                if (requestNumber !== currentRequestNumber) {
                    return;
                }

                vm.hasNoSearchResults = deliverables.length === 0;
                vm.deliverables = deliverables;
            })
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                vm.isSearching = false;
            });
    }

    function onStateChange(activity, fromState, toState) {
        const hasStateChanged = fromState !== toState;

        if (hasStateChanged) {
            activity.setCurrentState(toState);

            loadingToast.show("_UPDATING_STATUS");

            return $sbStateChangesApi
                .create(activity.id, {
                    state: toState.toLowerCase(),
                })
                .then(function () {
                    Analytics.trackEvent(
                        "Request for update",
                        "Progress change",
                        "Opt-out list"
                    );
                })
                .then(_showSuccessToast)
                .catch(function (error) {
                    $mdToast.hide();
                    activity.setCurrentState(fromState);
                    $sbErrorPresenter.catch(error);
                });
        }
    }

    function onBackPressed() {
        $window.history.back();
    }

    function _showSuccessToast() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("_STATUS_UPDATED")
                .hideDelay(2000)
                .position("top right")
        );
    }
}
