import angular from "angular";
import featureFlagsModule from "./feature_flags.module";

featureFlagsModule.directive(
    "sbIsFeatureEnabled",
    function ($animate, $compile, $sbFeatureDecisions) {
        return {
            multiElement: true,
            transclude: "element",
            priority: 600,
            terminal: true,
            restrict: "A",
            $$tlb: true,
            link: function ($scope, $element, $attr, ctrl, $transclude) {
                var block, childScope, previousElements;

                $scope.$watch(
                    $attr.sbIsFeatureEnabled,
                    function ngIfWatchAction(featureName) {
                        if ($sbFeatureDecisions.isFeatureEnabled(featureName)) {
                            if (!childScope) {
                                $transclude(function (clone, newScope) {
                                    childScope = newScope;
                                    clone[clone.length++] =
                                        $compile.$$createComment(
                                            "end sbIsFeatureEnabled",
                                            $attr.sbIsFeatureEnabled
                                        );
                                    // Note: We only need the first/last node of the cloned nodes.
                                    // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                                    // by a directive with templateUrl when its template arrives.
                                    block = {
                                        clone: clone,
                                    };
                                    $animate.enter(
                                        clone,
                                        $element.parent(),
                                        $element
                                    );
                                });
                            }
                        } else {
                            if (previousElements) {
                                previousElements.remove();
                                previousElements = null;
                            }
                            if (childScope) {
                                childScope.$destroy();
                                childScope = null;
                            }
                            if (block) {
                                previousElements = getBlockNodes(block.clone);
                                $animate
                                    .leave(previousElements)
                                    .done(function (response) {
                                        if (response !== false) {
                                            previousElements = null;
                                        }
                                    });
                                block = null;
                            }
                        }
                    }
                );
            },
        };
    }
);

function getBlockNodes(nodes) {
    // TODO(perf): update `nodes` instead of creating a new object?
    var node = nodes[0];
    var endNode = nodes[nodes.length - 1];
    var blockNodes;

    for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
        if (blockNodes || nodes[i] !== node) {
            if (!blockNodes) {
                blockNodes = angular.element(
                    Array.prototype.slice.call(nodes, 0, i)
                );
            }
            blockNodes.push(node);
        }
    }

    return blockNodes || nodes;
}
