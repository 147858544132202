import angular from "angular";
import _ from "lodash";

export default function (
    $sbRequest,
    REQUEST_UPDATE_URL,
    SbDeliverable,
    $sbDomain,
    $q,
    $filter
) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    var service = {
        getRequestContent: fetchRequestContent,
        sendRequestResult: uploadData,
        createRequestForProgress: createRequestForProgress,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function createRequestForProgress(userName, selection) {
        if (!angular.isString(userName)) {
            return $q.reject(
                new Error(
                    "Error: The responsible username needs to be a string."
                )
            );
        }

        if (!(angular.isString(selection) || angular.isArray(selection))) {
            return $q.reject(
                new Error(
                    "Error: The selection needs to be a OData query string or an array of objects with ID property."
                )
            );
        }

        // request data by deliverable ids
        //
        if (angular.isArray(selection)) {
            var ids = selection.map(function (deliverable) {
                return deliverable.ID;
            });

            return _createRequestForProgressFromDeliverables(userName, ids);
        }

        //request data using an OData query string
        //
        if (angular.isString(selection)) {
            var queryString = selection;
            return _createRequestForProgressFromQueryString(
                userName,
                queryString
            );
        }
    }

    function _createRequestForProgressFromQueryString(username, queryString) {
        return $sbRequest.json({
            url: REQUEST_UPDATE_URL,
            method: "POST",
            body: {
                responsiblePerson: username,
                $filter: queryString,
            },
        });
    }

    function _createRequestForProgressFromDeliverables(
        username,
        deliverableIds
    ) {
        return $sbRequest.json({
            url: REQUEST_UPDATE_URL,
            method: "POST",
            body: {
                responsiblePerson: username,
                aComponents: deliverableIds,
            },
        });
    }

    function fetchRequestContent(tokenId) {
        return $sbRequest.json({
            url: REQUEST_UPDATE_URL + "/" + tokenId,
            method: "GET",
            transformResponse: [_transformData],
        });
    }

    function _transformData(data) {
        if (data && data.deliverables) {
            // map and convert to deliverable class, and assign the workflow
            // content to each one
            data.deliverables = data.deliverables.map(function (item) {
                var deliverable = SbDeliverable.createFromOdataObject(item);
                var workflow = $sbDomain.workflowFactory.create(
                    item.WORKFLOW_CONTENT
                );
                deliverable.SELECTION_REASON = item.SELECTION_REASON;
                deliverable.setChildren(workflow);
                return deliverable;
            });

            data.deliverables = sortByReason(data.deliverables);
        }

        return data;
    }

    function sortByReason(deliverables) {
        var sorNaturalBy = $filter("naturalSort");

        return _.chain(deliverables)
            .groupBy("SELECTION_REASON")
            .transform(function (result, value, key) {
                // put the scheduled onces first
                //
                if (key === "SCHEDULED") {
                    return result.unshift(sorNaturalBy(value, "code"));
                }

                // append all the others
                //
                result.push(sorNaturalBy(value, "code"));
            }, [])
            .flatten()
            .value();
    }

    function uploadData(deliverablesList, tokenId) {
        return $sbRequest.json({
            url: REQUEST_UPDATE_URL + "/" + tokenId,
            method: "PUT",
            body: _getAllModifiedElements(deliverablesList),
        });
    }

    function _getAllModifiedElements(deliverablesList) {
        var uploadCollection = [];
        _.forEach(deliverablesList, function (deliverable) {
            var changedActivities = _.filter(
                deliverable.activities,
                function (activity) {
                    return activity.dirty;
                }
            );
            if (changedActivities.length > 0) {
                uploadCollection = uploadCollection.concat(changedActivities);
            }
        });
        return _toUploadModel(uploadCollection);
    }

    function _toUploadModel(collection) {
        return _.map(collection, function (element) {
            return {
                ID: element.id,
                STATE: element.state.current,
            };
        });
    }
}
