import angular from "angular";
import sablonoRouteConfig from "./sablono.route";
import profilePanelConfig from "./directives/sbProfilePanel/sb_profile_panel";
import schedulingPanelConfig from "./directives/sbSchedulingPanel/sb_scheduling_panel";
import sablonoRun from "./sablono.run";
import sablonoCtrl from "./sablono.controller";
import projectCtrl from "./states/project.controller";
import menuContentService from "./services/menu_content.service";
import sbSidenav from "./components/sbSidenav/sb_sidenav.component";
import subbarActionsDirective from "./directives/sbSubbarActions/sbSubbarActions";
import subbarModeDirective from "./directives/sbSubbarMode/sbSubbarMode";
import toolbarSearchBar from "./directives/sbToolbarSearchBar/sbToolbarSearchBar";
import toolbarStateInfo from "./directives/sbToolbarStateInfo/sbToolbarStateInfo";
import FeatureFlagsModule from "../../feature_flags/index";
import "common/services/services";
import "angular-sanitize";
import "angular-moment";

export default angular
    .module("sbApp.sablonoState", [
        "ui.router",
        "ngSanitize",
        "sbApp.services",
        "sbApp.intercom",
        "angularMoment",
        FeatureFlagsModule.name,
    ])
    .config(sablonoRouteConfig)
    .config(profilePanelConfig)
    .config(schedulingPanelConfig)
    .constant("LEFT_SIDE_NAV_ID", "global-left-side-navigation")
    .run(sablonoRun)
    .controller("SablonoCtrl", sablonoCtrl)
    .controller("ProjectCtrl", projectCtrl)
    .factory("MenuContentService", menuContentService)
    .directive("sbSubbarActions", subbarActionsDirective)
    .directive("sbSubbarMode", subbarModeDirective)
    .component(sbSidenav.name, sbSidenav)
    .component(toolbarStateInfo.name, toolbarStateInfo)
    .component(toolbarSearchBar.name, toolbarSearchBar);
