import has from "../../../has/has";
import "./watcher_counter.less";

export default function sbWatcherCounterDirective($sbAngularWatcher) {
    "ngInject";
    var directive = {
        restrict: "A",
        link: link,
    };

    return directive;

    /////////////

    function link(scope, element, attrs) {
        if (element && has("development")) {
            var watcherCounterElement = _createWatcherCounter(element);

            scope.$watch(
                function () {
                    return _getWatchers(element);
                },
                function (newVal, oldVal) {
                    watcherCounterElement.innerHTML = newVal + " watchers";
                }
            );
        }
    }

    function _getWatchers(element) {
        return $sbAngularWatcher.countWatchers(element);
    }

    function _createWatcherCounter(element) {
        var watcherCounterElement = document.createElement("div");
        watcherCounterElement.className = "sb-watcher-counter";

        element[0].appendChild(watcherCounterElement);

        return watcherCounterElement;
    }
}
