import moment from "moment";

export default function ($sbCalendar, $sbGraph, $sbSchedulingApi, $sbJobApi) {
    "ngInject";
    return {
        calculateEarliestStartAndEnd: calculateEarliestStartAndEnd,
        calculateLatestStartAndEnd: calculateLatestStartAndEnd,
        distributionAlgorithm: distributeActivitiesDurationEvenly,
        scheduleProject: scheduleProject,
    };

    function scheduleProject(projectId) {
        return $sbSchedulingApi
            .create(projectId)
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            });
    }

    //
    // CONTENT TO COPY TO BACKEND STARTS HERE !
    //

    /**
     * GOAL: calculate the start and end for activities of multiple deliverables
     *
     * @param {{
     *   nodes: Array<{
     *      minStart: Moment,
     *   }>,
     *   edges: Array<Object>
     *   calendar: {
     *      blocks: Array<Array<String>>,
     *      days: Array<Number>,
     *      exceptionDates: Array<Moment>,
     *   }
     * }} config
     *
     */
    function calculateEarliestStartAndEnd(config) {
        if (config.nodes.length > 0) {
            config.nodes.forEach(function (node) {
                node.minStart = node.minStart || moment.invalid();
            });

            var calendar = $sbCalendar.ConsumableCalendar.createFrom(
                config.calendar
            );
            var graph = new $sbGraph.Graph(config.nodes, config.edges);
            graph.traverse(createDateFunctionForward(calendar));

            return [].concat(graph.nodes);
        }

        return [];
    }

    /**
     * GOAL: calculate the start and end for activities of multiple deliverables
     *
     * @param {{
     *   nodes: Array<{
     *      maxEnd: Moment,
     *   }>,
     *   edges: Array<Object>
     *   calendar: {
     *      blocks: Array<Array<String>>,
     *      days: Array<Number>,
     *      exceptionDates: Array<Moment>,
     *   }
     * }} config
     *
     */
    function calculateLatestStartAndEnd(config) {
        if (config.nodes.length > 0) {
            config.nodes.forEach(function (node) {
                node.maxEnd = node.maxEnd || moment.invalid();
            });

            var calendar = $sbCalendar.ConsumableCalendar.createFrom(
                config.calendar
            );
            var graph = new $sbGraph.Graph(config.nodes, config.edges);
            graph.traverseBackwards(createDateFunctionBackwards(calendar));

            return [].concat(graph.nodes);
        }

        return [];
    }

    /**
     * A stats entry contains scheduling information about a deliverable.
     *
     * @typedef {Object} StatsEntry
     *
     * @property {String} algorithmForDurationScheduling - One of EQUAL_DISTRIBUTION, DEFAULT.
     * @property {Moment} earliestStartDate
     * @property {Moment} latestEndDate
     * @property {Number} maxTopologicalIndex
     */

    /**
     * Distribute activity duration evenly by working time between start and end date.
     *
     * @param {Object} config
     * @param {Moment} config.start
     * @param {Moment} config.end
     * @param {Array<Object>} config.nodes
     * @param {Array<Object>} config.edges
     * @param {Object<String, StatsEntry>} config.stats
     * @param {Object} config.calendar

     * @returns {Object<string,GraphNode>} Map of all the activities in which the keys of the objects
     *                                     represents the id of the activity.
     */
    function distributeActivitiesDurationEvenly(config) {
        var calendar = $sbCalendar.ConsumableCalendar.createFrom(
            config.calendar
        );
        var stats = config.stats;

        config.nodes.forEach(function (node) {
            node.allocationAlgorithm = "DEFAULT";

            var localScheduleInfo = stats[node.parentId]; // global scheduling config
            if (localScheduleInfo) {
                var activitySchedulingAlgo =
                    localScheduleInfo.algorithmForDurationScheduling;
                if (activitySchedulingAlgo === "EQUAL_DISTRIBUTION") {
                    var givenDuration = calendar.getTotalWorkingTimeBetween(
                        localScheduleInfo.earliestStartDate,
                        localScheduleInfo.latestEndDate
                    );
                    var maxTopologicalIndex =
                        localScheduleInfo.maxTopologicalIndex;
                    var durationPerActivity = givenDuration; // full duration activity as fallback

                    if (maxTopologicalIndex > 0) {
                        durationPerActivity =
                            givenDuration / maxTopologicalIndex;
                    }

                    node.duration = durationPerActivity;
                    node.unit = "wh";
                    node.allocationAlgorithm = activitySchedulingAlgo;
                }
            }
        });

        return config;
    }
    /**
     * Create a function that takes a node and calculates its earliest start and earliest end.
     *
     * @param {ConsumableCalendar} calendar
     * @returns {Function}
     */
    function createDateFunctionForward(calendar) {
        return function (node) {
            var maxEEOfPredecessors = node.predecessors.reduce(
                maxEE,
                node.data.minStart
            );

            if (maxEEOfPredecessors.isValid()) {
                node.data.es = calendar.findEarliestStartForTask(
                    maxEEOfPredecessors,
                    node.data.unit
                );
                node.data.ee = calendar.findEarliestEndForTask(
                    node.data.es,
                    node.data.duration,
                    node.data.unit
                );
            } else {
                // in case the maxEE is invalid (unscheduled) both es and ee are invalid as well.
                node.data.es = moment.invalid();
                node.data.ee = moment.invalid();
            }
        };
    }

    /**
     * Create a function that takes a node and calculates its latest end and latest start.
     *
     * @param {ConsumableCalendar} calendar
     * @returns {Function}
     */
    function createDateFunctionBackwards(calendar) {
        return function (node) {
            var minLsOfSuccessors = node.successors.reduce(
                minLS,
                node.data.maxEnd
            );

            if (minLsOfSuccessors.isValid()) {
                node.data.le = calendar.findLatestEndForTask(
                    minLsOfSuccessors,
                    node.data.unit
                );
                node.data.ls = calendar.findLatestStartForTask(
                    node.data.le,
                    node.data.duration,
                    node.data.unit
                );
            } else {
                // in case the minLs is invalid (unscheduled) both le and ls are invalid as well
                node.data.le = moment.invalid();
                node.data.ls = moment.invalid();
            }
        };
    }

    /**
     * Maximum earliest end of a certain value and a nodes value.
     *
     * @param {Moment} currentMax
     * @param {GraphNode} node
     * @returns
     */
    function maxEE(currentMax, node) {
        if (!currentMax.isValid()) {
            return node.data.ee;
        }
        if (currentMax.isBefore(node.data.ee)) {
            return node.data.ee;
        } else {
            return currentMax;
        }
    }

    /**
     * Minimum latest start of a certain value and a nodes value.
     *
     * @param {Moment} currentMin
     * @param {GraphNode} node
     * @returns
     */
    function minLS(currentMin, node) {
        if (!currentMin.isValid()) {
            return node.data.ls;
        }
        if (currentMin.isAfter(node.data.ls)) {
            return node.data.ls;
        } else {
            return currentMin;
        }
    }

    //
    // CONTENT TO COPY TO BACKEND ENDS HERE !
    //
}
