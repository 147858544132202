import angular from "angular";
import htmlTemplate from "./project_card.html";
import componentCtrl from "./project_card.controller";
import "common/services/services";

export default angular
    .module("sbApp.common.components.projectCard", ["sbApp.services"])
    .component("sbProjectCard", {
        templateUrl: htmlTemplate,
        bindings: {
            project: "<",
        },
        controller: componentCtrl,
    });
