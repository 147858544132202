import _ from "lodash";
import moment from "moment";
import templateHtml from "./diary_info.html";

export default {
    name: "sbDiaryInfo",
    templateUrl: templateHtml,
    replace: true,
    bindings: {
        diaryEntry: "<",
        onSave: "&",
        isFinalisingEntry: "<",
        trackChangeEvent: "&",
        changeEventKeys: "<",
    },
    controller: InfoCardController,
};

function InfoCardController($sbProject, $sbErrorPresenter) {
    "ngInject";
    var vm = this;

    vm.isSaving = false;
    vm.isFormDisabled = false;
    vm.projectName = $sbProject.getCurrent().name;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;

    vm.submit = submit;
    vm.trackTimeChange = trackTimeChange;

    function $onInit() {
        vm.newDiaryEntry = angular.copy(vm.diaryEntry);
        vm.isFormDisabled = vm.diaryEntry.isFinalized();

        vm.started = vm.newDiaryEntry.workingTime.start;
        vm.ended = vm.newDiaryEntry.workingTime.end;
    }

    function $onChanges(changes) {
        if (_diaryEntryBindingHasChanged(changes)) {
            vm.isFormDisabled = vm.diaryEntry.isFinalized();
        }
    }

    function submit(form) {
        if (form.$valid) {
            vm.isSaving = true;

            vm.newDiaryEntry.workingTime.end = vm.ended;
            vm.newDiaryEntry.workingTime.start = vm.started;

            vm.onSave({
                diaryEntry: vm.newDiaryEntry,
            })
                .then(function () {
                    form.$setPristine();
                })
                .finally(function () {
                    vm.isSaving = false;
                })
                .catch($sbErrorPresenter.catch);
        }
    }

    function trackTimeChange() {
        vm.trackChangeEvent({
            label: vm.changeEventKeys.GENERAL_CARD.HOURS,
        });
    }

    function _diaryEntryBindingHasChanged(changes) {
        return !_.isUndefined(_.get(changes, "diaryEntry.currentValue"));
    }
}
