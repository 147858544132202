import angular from "angular";

export default /*ngInject*/ function () {
    /**
     * This service generates codes based on other codes.
     */
    return {
        getNextSuggestionFrom: getNextSuggestionFrom,
    };

    /**
     * This function generates a new consecutive code/name based on the given one.
     * It extracts the last number in it and replaces it with the
     * number increased by 1 while preserving the leading zeros, or returns
     * the input with an appended "1" if no number is found.
     *
     * @param {String} input - the input string.
     * @returns {string} - the suggestion.
     */
    function getNextSuggestionFrom(input) {
        var lastDigitsRex = /(\d+)(?!.*\d)/,
            newSuggestion,
            increasedDigitsAsString,
            digitsToReplace;

        // return empty string if code doesn't contain a number
        //
        if (!lastDigitsRex.test(input)) {
            if (angular.isUndefined(input)) {
                return "1";
            }
            return input + "1";
        }

        // extract last number in code
        //
        digitsToReplace = input.match(lastDigitsRex)[0];
        increasedDigitsAsString =
            _increaseNumberWithLeadingZeroes(digitsToReplace);

        // replace the old number in the code with the new one
        //
        newSuggestion = input.replace(digitsToReplace, increasedDigitsAsString);

        return newSuggestion;
    }

    /**
     * Increment a number by 1 given as string, while preserving leading zeros.
     *
     * @param {String} numberAsString - the number that should be increased
     * @returns {*}
     */
    function _increaseNumberWithLeadingZeroes(numberAsString) {
        var digits = numberAsString.length,
            currentNumber;

        currentNumber = Number.parseInt(numberAsString, 10);

        // return input on false input
        //
        if (isNaN(currentNumber)) {
            return numberAsString;
        }

        // increase by 1 and transform to string
        //
        currentNumber++;
        currentNumber = currentNumber.toString();

        // append zeros to get old length of string
        //
        if (digits > currentNumber.length) {
            var diff = digits - currentNumber.length;
            currentNumber = new Array(diff + 1).join("0") + currentNumber;
        }

        return currentNumber;
    }
}
