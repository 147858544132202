import _ from "lodash";
import "./cost_group_membership.class";

function CostGroupMembershipManager(initialMemberships) {
    /**
     * All the managed memberships (read or write)
     *
     * @type {Array<CostGroupMembership>}
     */
    this.memberships = initialMemberships || [];
}

CostGroupMembershipManager.prototype.add = function (memberships) {
    this.memberships = _.unionBy(
        _.castArray(memberships),
        this.memberships,
        "user"
    );
};

CostGroupMembershipManager.prototype.remove = function (memberships) {
    var _memberships = _.castArray(memberships);

    this.memberships = _.filter(this.memberships, function (membership) {
        return !_.find(_memberships, ["user", membership.user]);
    });
};

CostGroupMembershipManager.prototype.collectReadOnlyMemberships = function () {
    return _.filter(this.memberships, ["isAllowedToModify", false]);
};

CostGroupMembershipManager.prototype.collectAdminMemberships = function () {
    return _.filter(this.memberships, ["isAllowedToModify", true]);
};

CostGroupMembershipManager.prototype.getMemberships = function () {
    return this.memberships;
};

CostGroupMembershipManager.prototype.clearMemberships = function () {
    this.memberships = [];
};

CostGroupMembershipManager.prototype.findMembershipOf = function (user) {
    return _.find(this.memberships, ["user", user]);
};

export default CostGroupMembershipManager;
