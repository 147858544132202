import angular from "angular";
import htmlTemplate from "./sb_deliverable_dependencies.html";
import deliverableDependenciesService from "./sb_deliverable_dependencies.service";
import DeliverableDependenciesCtrl from "./sb_deliverable_dependencies.controller";
import DeliverableDependenciesModel from "./sb_deliverable_dependencies.model";

export default angular
    .module("sbApp.sbDeliverableDependencies", [
        "sbApp.services",
        "sbApp.sbOdataList",
        "sbApp.sbDeliverableOverlay",
    ])
    .service("$sbDeliverableDependencies", deliverableDependenciesService)
    .controller("DeliverableDependenciesCtrl", DeliverableDependenciesCtrl)
    .value(
        "DetailedConnection",
        DeliverableDependenciesModel.DetailedConnection
    )
    .value(
        "ActivityDependency",
        DeliverableDependenciesModel.ActivityDependency
    )
    .value(
        "DeliverableDependency",
        DeliverableDependenciesModel.DeliverableDependency
    )
    .component("sbDeliverableDependencies", {
        name: "sbDeliverableDependencies",
        templateUrl: htmlTemplate,
        controller: DeliverableDependenciesCtrl,
        bindings: {
            deliverable: "<",
        },
    });
