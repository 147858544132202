function CostGroupMembership(user, isAllowedToModify) {
    this.user = user;
    this.isAllowedToModify = isAllowedToModify;
}

CostGroupMembership.prototype.isAllowedToUpdateBudget = function () {
    return !!this.isAllowedToModify;
};

CostGroupMembership.prototype.isAllowedToUpdateWeight = function () {
    return !!this.isAllowedToModify;
};

CostGroupMembership.prototype.isAllowedToChangeMemberships = function () {
    return !!this.isAllowedToModify;
};

export default CostGroupMembership;
