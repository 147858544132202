import angular from "angular";
import "angular-ui-router";

export default angular
    .module("sbApp.loadingToast", ["ui.router"])
    .factory("loadingToast", function ($mdToast, $q) {
        var openToast;

        function show(text) {
            openToast = $mdToast.show($mdToast.loading().content(text));
            return openToast;
        }

        function hide() {
            if (!openToast) return $q.resolve();

            var promise = $mdToast.hide();
            openToast = undefined;
            return promise;
        }

        function toggleLoadingToast(text) {
            if (angular.isUndefined(openToast)) {
                return show(text);
            } else {
                return hide();
            }
        }

        return {
            hide: hide,
            show: show,
            toggleLoadingToast: toggleLoadingToast,
        };
    });
