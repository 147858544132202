import _ from "lodash";
import htmlTemplate from "./lean_board_lane.html";
import LeanboardColumn from "../../model/leanboard_column.class";

export default {
    name: "sbLeanBoardLane",
    templateUrl: htmlTemplate,
    bindings: {
        lane: "<",
        onActivityClicked: "&",
        onShowDeliverableDetails: "&",
        onShowAggregatedDeliverables: "&",
        onForecastDatesForDeliverable: "&",
        zoomSize: "<",
        onShowLateActivities: "&",
        loadingDeliverable: "<",
        openSession: "<",
        selectedColumnTimespan: "<",
        showDeliverableDetailsButton: "<",
        isDeliverableView: "<",
        columns: "<",
    },
    controller: LeanBoardLaneController,
};

function LeanBoardLaneController(
    $mdPanel,
    $sbLastPlannedSelectionStore,
    $sbTracking,
    LANE_MIN_HEIGHT,
    SMALL_LANE_MIN_HEIGHT,
    MINI_LANE_MIN_HEIGHT,
    BOTTOM_PADDING_LARGE,
    BOTTOM_PADDING_SMALL,
    WEEK_BOARD_SIZES,
    WEEK_BOARD_TIMESPAN_PER_COLUMN
) {
    "ngInject";
    var vm = this;

    vm.$onChanges = $onChanges;
    vm.WEEK_BOARD_SIZES = WEEK_BOARD_SIZES;
    vm.WEEK_BOARD_TIMESPAN_PER_COLUMN = WEEK_BOARD_TIMESPAN_PER_COLUMN;

    vm.isDeliverableSelected = function (deliverable) {
        return deliverable
            .getActivities()
            .some((a) => vm.isActivitySelected(a));
    };

    vm.isActivitySelected = $sbLastPlannedSelectionStore.isSelected;

    function $onChanges() {
        if (vm.lane) {
            const timeFrame = vm.lane.getBoardTimeFrame();

            vm.rows = _prepareRows(vm.lane.layout, vm.lane.data, timeFrame);
            vm.deliverable = vm.lane.getDeliverable();
            vm.numOfLateActivities = vm.deliverable.countLateActivities();
            vm.minLaneHeight = _determineMinLaneHeightBy(vm.zoomSize, vm.rows);
        }
    }

    function _determineMinLaneHeightBy(zoomSize, rows) {
        switch (zoomSize) {
            case WEEK_BOARD_SIZES.SMALL:
                return (
                    MINI_LANE_MIN_HEIGHT * rows.length + BOTTOM_PADDING_SMALL
                );
            case WEEK_BOARD_SIZES.LARGE:
            default:
                return LANE_MIN_HEIGHT * rows.length + BOTTOM_PADDING_LARGE;
        }
    }

    /**
     * Prepare the rows for the layout
     * @param {Array} layout - Incoming layout with all the data indices in place
     * @param {Array} data - contains all the data from the activities
     * @param {LeanboardTimeframe} timeFrame - defines start and end date
     * @returns {Array} - Layout-ready array of rows
     * @private
     */
    function _prepareRows(layout, data, timeFrame) {
        var maxRows = layout[0].slots.length,
            bufferName = "buffer",
            rows = [];

        for (var i = 0; i < maxRows; i++) {
            // we loop through all the possible rows, exploring
            // what's in the columns for that row
            var tempRow = [];

            layout.forEach(function (column, index) {
                // we prepare a temp row with all the activities/buffers
                var activityIndex = column.slots[i];
                if (activityIndex === LeanboardColumn.BLANK_SLOT) {
                    tempRow.push(bufferName + index);
                } else {
                    tempRow.push(activityIndex);
                }
            });

            var allBuffers = tempRow.every(function (item) {
                return _.isString(item) && item.indexOf(bufferName) > -1;
            });
            if (!allBuffers) {
                // now we will use the temp row to get the final layout
                rows.push(
                    _placeActivitiesInRow(tempRow, bufferName, data, timeFrame)
                );
            }
        }
        return rows;
    }

    /**
     * Given a layout with all the occurrences of an activity, map it to a
     * grid with no repeated activities and proper values for display purposes
     * @param {Array} layoutRow - array with indices of the used data record, including all occurences
     * @param {String} bufferName - identifies activity buffers
     * @param {Array} data - contains all the data from the activities
     * @param {LeanboardTimeframe} timeFrame - defines start and end date
     * @returns {Array} - Layout - ready row
     * @private
     */
    function _placeActivitiesInRow(layoutRow, bufferName, data, timeFrame) {
        // let's remove dups and set the flex values
        var uniqueIndices = _.uniq(layoutRow),
            activityCount = _.countBy(layoutRow, _.identity),
            row = [];

        // put the activities in the row with
        // overflow, visibility and flex values
        uniqueIndices.map(function (dataIndex) {
            if (_.isString(dataIndex) && dataIndex.indexOf(bufferName) > -1) {
                // buffer
                row.push({
                    flex: 1,
                    visibility: "hidden",
                });
            } else {
                // Look up the activity in the data
                var activity = data[dataIndex],
                    periodStart = timeFrame.getStartAsMoment(),
                    periodEnd = timeFrame.getEndAsMoment();

                // Assign overflow classes
                activity.className = _toOverflowClasses(
                    activity.startDate,
                    activity.endDate,
                    periodStart,
                    periodEnd
                );

                row.push({
                    data: activity,
                    flex: activityCount[dataIndex],
                    visibility: "visible",
                });
            }
        });
        return row;
    }

    /**
     * Check if an activity overflows in each direction and return proper classes
     * @param activityStartDate
     * @param activityEndDate
     * @param periodStart
     * @param periodEnd
     * @returns {string}
     * @private
     */
    function _toOverflowClasses(
        activityStartDate,
        activityEndDate,
        periodStart,
        periodEnd
    ) {
        var classes = "";

        if (activityStartDate.isBefore(periodStart, "hour")) {
            classes += "sb-activity-card--overflows--left";
        }
        if (activityEndDate.isAfter(periodEnd, "hour")) {
            classes += " sb-activity-card--overflows--right";
        }
        return classes;
    }
}
