import angular from "angular";
import TemplatesStateConfig from "./templates.route";
import stateTemplateHtml from "./templates.html";
import TemplatesCtrl from "./templates.controller";
import TemplateService from "./services/templates.service";
import "angular-ui-router";
import "angular-messages";
import "common/services/services";
import "common/dialog/addTemplate/addTemplateDialog";
import "common/intercom/intercomModule";
import "common/ui-elements/directives/sbElementSelection/sbElementSelection.module";

export default angular
    .module("sbApp.TemplateCtrl", [
        "ui.router",
        "ngMaterial",
        "ngMessages",
        "sbApp.services",
        "sbApp.addTemplateDialog",
        "sbApp.sbTemplateSelectorDialog",
        "sbApp.intercom",
        "sbApp.sbElementSelection",
    ])
    .constant("whitelist", ["NAME", "CODE", "DESC"])
    .config(TemplatesStateConfig)
    .controller("TemplatesCtrl", TemplatesCtrl)
    .factory("templatesService", TemplateService);
