import templateHtml from "./diary_events.html";
import _ from "lodash";

export default {
    name: "sbDiaryEvents",
    templateUrl: templateHtml,
    replace: true,
    bindings: {
        diaryEntry: "<",
        onSave: "&",
        isFinalisingEntry: "<",
        changeEventKeys: "<",
    },
    controller: EventsCardController,
};

function EventsCardController($sbErrorPresenter) {
    "ngInject";
    var vm = this;

    vm.isFormDisabled = false;
    vm.isSaving = false;
    vm.MAX_CHARACTER_COUNT = 2000;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;

    vm.submit = submit;

    function $onInit() {
        vm.newDiaryEntry = angular.copy(vm.diaryEntry);
        vm.isFormDisabled = vm.diaryEntry.isFinalized();
    }

    function $onChanges(changes) {
        if (_diaryEntryBindingHasChanged(changes)) {
            vm.isFormDisabled = vm.diaryEntry.isFinalized();
        }
    }

    function submit(form) {
        if (form.$valid) {
            vm.isSaving = true;

            vm.onSave({
                diaryEntry: vm.newDiaryEntry,
            })
                .then(function () {
                    form.$setPristine();
                })
                .finally(function () {
                    vm.isSaving = false;
                })
                .catch($sbErrorPresenter.catch);
        }
    }

    function _diaryEntryBindingHasChanged(changes) {
        return !_.isUndefined(_.get(changes, "diaryEntry.currentValue"));
    }
}
