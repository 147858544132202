import _ from "lodash";
import CyclicGraphError from "../../../../errors/CyclicGraphError";

export default function DeliverableDependenciesService(
    ActivityDependency,
    Analytics,
    DetailedConnection,
    $sbDependencyJobsApi,
    $sbDependency,
    $sbErrorPresenter,
    $sbCurrentProject,
    $sbProject,
    $sbJobApi,
    $q
) {
    "ngInject";
    var service = {
        CHANGE_DELIVERABLE_DELAY: 300,
        detailedConnection: {},
        predecessors: [],
        successors: [],
        fetchDetailedDependenciesFor: fetchDetailedDependenciesFor,
        addPredecessor: addPredecessor,
        removePredecessor: removePredecessor,
        isPredecessor: isPredecessor,
        addSuccessor: addSuccessor,
        removeSuccessor: removeSuccessor,
        isSuccessor: isSuccessor,
    };

    return service;

    function mapToViewModel(detailedConnection, nodes, edges) {
        edges.forEach(function (edge) {
            var source = _.find(nodes, ["activityId", edge.source]);
            var target = _.find(nodes, ["activityId", edge.target]);
            detailedConnection.addDependency(source, target, edge.derived);
        });

        return detailedConnection;
    }

    function setViewModel(detailedConnection) {
        service.detailedConnection = detailedConnection;

        service.predecessors = detailedConnection.listPredecessors();
        service.successors = detailedConnection.listSuccessors();
    }

    function fetchDetailedDependenciesFor(deliverable) {
        return _fetchDetailedDependenciesForInto(
            deliverable,
            new DetailedConnection(deliverable)
        );
    }

    function _mergeDetailedDependenciesWithCurrent() {
        return _fetchDetailedDependenciesForInto(
            service.detailedConnection.center,
            service.detailedConnection
        );
    }

    function _fetchDetailedDependenciesForInto(deliverable, connection) {
        return $sbDependency
            .getDetailedDependencies(deliverable)
            .then(function (detailedDependencies) {
                return mapToViewModel(
                    connection,
                    detailedDependencies.nodes,
                    detailedDependencies.edges
                );
            })
            .then(setViewModel)
            .catch($sbErrorPresenter.catch);
    }

    /**
     * Create a new edge between the chosen deliverable and
     * the current deliverable in the service.
     *
     * The chosen deliverable will be a predecessor to the current.
     *
     * @param {Deliverable} newPredecessor - Deliverable
     */
    function addPredecessor(newPredecessor) {
        return $sbDependencyJobsApi
            .createAddDeliverableDependencyJob(
                service.detailedConnection.center.projectId,
                {
                    source_deliverable_id: newPredecessor.id,
                    target_deliverable_id: service.detailedConnection.center.id,
                }
            )
            .then(function ({ jobId }) {
                // if the job failed it is most likely caused by a cycle
                return $sbJobApi.waitFor(jobId).then((response) => {
                    if (response.state === $sbJobApi.STATE_FAILED) {
                        return $q.reject(new CyclicGraphError());
                    }
                });
            })
            .then(function () {
                Analytics.trackEvent(
                    "Scheduling",
                    "Dependencies",
                    "Predecessor created"
                );
            })
            .then($sbProject.refreshCurrent)
            .catch($sbErrorPresenter.catch)
            .then(_mergeDetailedDependenciesWithCurrent);
    }

    /**
     * Create a new edge between the chosen deliverable and
     * the current deliverable in the service.
     *
     * The chosen deliverable will be a successor of the current.
     *
     * @param {Deliverable} newSuccessor - Deliverable
     */
    function addSuccessor(newSuccessor) {
        // Wait until the job is done
        return $sbDependencyJobsApi
            .createAddDeliverableDependencyJob(
                service.detailedConnection.center.projectId,
                {
                    source_deliverable_id: service.detailedConnection.center.id,
                    target_deliverable_id: newSuccessor.id,
                }
            )
            .then(function ({ jobId }) {
                // if the job failed it is most likely caused by a cycle
                return $sbJobApi.waitFor(jobId).then((response) => {
                    if (response.state === $sbJobApi.STATE_FAILED) {
                        return $q.reject(new CyclicGraphError());
                    }
                });
            })
            .then(function () {
                Analytics.trackEvent(
                    "Scheduling",
                    "Dependencies",
                    "Successor created"
                );
            })
            .then($sbProject.refreshCurrent)
            .catch($sbErrorPresenter.catch)
            .then(_mergeDetailedDependenciesWithCurrent);
    }

    /**
     * Remove the dependency from local model and server
     * the current deliverable in the service.
     *
     * @param {ActivityDependency} activityDependency
     */
    function removeSuccessor(activityDependency) {
        return removeDependency(activityDependency, "Successor");
    }

    function removePredecessor(activityDependency) {
        return removeDependency(activityDependency, "Predecessor");
    }

    function removeDependency(activityDependency, direction) {
        const projectId = $sbCurrentProject.pick("id");
        var removePromise;

        if (activityDependency.isDerived()) {
            var deliverableEdge = activityDependency.parent;
            removePromise = $sbDependencyJobsApi
                .createRemoveDeliverableDependencyJob(projectId, {
                    source_deliverable_id: deliverableEdge.source.id,
                    target_deliverable_id: deliverableEdge.target.id,
                })
                .then(function () {
                    Analytics.trackEvent(
                        "Scheduling",
                        "Dependencies",
                        direction + " removed"
                    );
                });
        } else {
            removePromise = $sbDependencyJobsApi
                .createRemoveActivityDependencyJob(projectId, {
                    source_activity_id: _.get(activityDependency, "source.id"),
                    source_deliverable_id: _.get(
                        activityDependency,
                        "parent.source.id"
                    ),
                    target_activity_id: _.get(activityDependency, "target.id"),
                    target_deliverable_id: _.get(
                        activityDependency,
                        "parent.target.id"
                    ),
                })
                .then(function () {
                    Analytics.trackEvent(
                        "Scheduling",
                        "Dependencies",
                        "Activity" + direction + " removed"
                    );
                });
        }

        service.detailedConnection.removeDependency(activityDependency);
        setViewModel(service.detailedConnection);

        return removePromise
            .then($sbProject.refreshCurrent)
            .catch($sbErrorPresenter.catch); // TODO on fail -> revert the change
    }

    function isPredecessor(deliverable) {
        return _isPartOfAnyDependency(
            deliverable,
            service.predecessors,
            "source"
        );
    }

    function isSuccessor(deliverable) {
        return _isPartOfAnyDependency(
            deliverable,
            service.successors,
            "target"
        );
    }

    /**
     * Check if the given deliverable is in the list.
     *
     * @param {Object} deliverable  - an entry in the list (requires an ID property)
     * @param {Array}  list         - the list to operate on
     * @returns {boolean}
     * @private
     */
    function _isPartOfAnyDependency(deliverable, list, type) {
        if (_.isArray(list) && _.isObject(deliverable)) {
            return list.some(function (activityDependency) {
                return activityDependency.parent[type].id === deliverable.id;
            });
        } else {
            return false;
        }
    }
}
