"use strict";

import StateTransitionPermissionMatrix from "./sb_state_transition_permission_matrix.class";
import Note from "./sb_note.class";

const NO_PERMISSIONS = [
    // open, closed, waiting for confirmation, rejected, confirmed
    //
    [0, 0, 0, 0, 0], // open -> to ?
    [0, 0, 0, 0, 0], // closed -> to ?
    [0, 0, 0, 0, 0], // waiting for confirmation -> to ?
    [0, 0, 0, 0, 0], // rejected -> to ?
    [0, 0, 0, 0, 0], // confirmed -> to ?
];

export default class NoteStateTransitionPermissionMatrix extends StateTransitionPermissionMatrix {
    constructor(permissions) {
        const STATE_TO_INDEX = new Map([
            [Note.STATE_OPEN_ENUM, 0],
            [Note.STATE_CLOSED_ENUM, 1],
            [Note.STATE_WAITING_FOR_CONFIRMATION_ENUM, 2],
            [Note.STATE_REJECTED_ENUM, 3],
            [Note.STATE_CONFIRMED_ENUM, 4],
        ]);

        const INDEX_TO_STATE = new Map([
            [0, Note.STATE_OPEN_ENUM],
            [1, Note.STATE_CLOSED_ENUM],
            [2, Note.STATE_WAITING_FOR_CONFIRMATION_ENUM],
            [3, Note.STATE_REJECTED_ENUM],
            [4, Note.STATE_CONFIRMED_ENUM],
        ]);

        super(permissions || NO_PERMISSIONS, INDEX_TO_STATE, STATE_TO_INDEX);
    }
}
