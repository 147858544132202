import angular from "angular";

export default angular
    .module("sbApp.sbConfigCardBox", [])
    .directive("sbConfigCardBox", function () {
        /**
         *
         * Layout for a Config card and some other content. Put the attribute to the parent
         *   Best as md-content:  <md-content sb-config-card-box>
         *
         * The first child should be a md-card -> the config card
         *
         * All following children will be handeld as content and bundled in a new container with proper layout
         *
         */

        return {
            restrict: "A",

            scope: {},

            link: function (scope, element) {
                var $boxContainer = angular.element(element[0]);
                var $boxChildren = angular.element($boxContainer.children());

                $boxContainer.addClass("sb-config-card-box");

                // the first is suposed to be the card -> the left box thing
                //
                angular
                    .element($boxChildren[0])
                    .addClass("sb-config-card-box-left hide-xs");

                // create a proper container for the other content (right part)
                //
                var $boxRight = angular.element(
                    '<div class="sb-config-card-box-right"></div>'
                );

                // remove all children then the first and append them to the rightbox
                //
                for (var i = 1; i < $boxChildren.length; i++) {
                    var $child = $boxChildren[i];
                    var e = angular.element($child).detach();
                    $boxRight.append(e);
                }

                $boxContainer.append($boxRight);
            },
        };
    });
