function ChangeEvent(before, after) {
    // hold the state of the entity before the change happened
    this.before = before;

    // hold the state of the entity after the change happened
    this.after = after;
}

ChangeEvent.prototype.isCreateEvent = function () {
    return this.after && !this.before;
};

ChangeEvent.prototype.isDeleteEvent = function () {
    return !this.after && this.before;
};

ChangeEvent.prototype.isUpdateEvent = function () {
    return this.after && this.before;
};

/**
 * Check if at least one of the two states (before, after) exists.
 * @returns {boolean} if it is a valid change event
 */
ChangeEvent.prototype.isValid = function () {
    return this.after || this.before;
};

export default ChangeEvent;
