import angular from "angular";
import htmlTemplate from "./sb_label_badge.html";
import "common/services/services";
export default angular
    .module("sbApp.sbLabelBadge", ["sbApp.services"])
    .component("sbLabelBadge", {
        templateUrl: htmlTemplate,
        controllerAs: "vm",
        bindings: {
            label: "<",
            tooltipKey: "<",
            modifiedByName: "<",
            date: "<",
            customClass: "@",
        },
        controller: function ($sbDates, $translate) {
            "ngInject";
            var vm = this;

            vm.$onChanges = function (change) {
                if (
                    change.date &&
                    change.date.currentValue !== change.date.previousValue
                ) {
                    _onDateChange();
                }

                if (change.modifiedByName) {
                    _onModifiedByChange();
                }
            };

            function _onDateChange() {
                if (vm.date) {
                    vm.formattedDate = $sbDates
                        .anyToMoment(vm.date)
                        .format("LLL");
                }
                _generateTooltip();
            }

            function _onModifiedByChange() {
                _generateTooltip();
            }

            function _generateTooltip() {
                vm.tooltip = $translate.instant(vm.tooltipKey, {
                    user: vm.modifiedByName,
                    timestamp: vm.formattedDate,
                });
            }
        },
    });
