import angular from "angular";
import htmlTemplate from "./search_bar.html";
export default angular
    .module("sbApp.sbSearchBar", [])
    .directive("sbSearchBar", function SearchBarDirective() {
        return {
            scope: {},
            restrict: "E",
            bindToController: {
                searchText: "=?",
                onSearchTextChange: "&",
                searchPlaceholder: "@",
            },
            controllerAs: "searchBar",
            templateUrl: htmlTemplate,
            controller: function SearchBarController() {
                "ngInject";
                var vm = this;

                vm.searchText = vm.searchText || "";

                vm.clear = clear;
                vm.onTextChange = onTextChange;

                function clear(searchBar) {
                    // do not trigger on empty input
                    //
                    if (
                        searchBar.searchText &&
                        searchBar.searchText.length !== 0
                    ) {
                        searchBar.searchText = "";
                    }
                }

                function onTextChange(searchText) {
                    vm.onSearchTextChange({
                        text: searchText,
                    });
                }
            },
            link: function ($scope, $element, $attr, $ctrl) {
                $scope.$watch(
                    "searchBar.searchText",
                    function (newSearchText, oldSearchText) {
                        if (newSearchText !== oldSearchText) {
                            $ctrl.onTextChange(newSearchText);
                        }
                    }
                );
            },
        };
    });
