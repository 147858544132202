import angular from "angular";
import _ from "lodash";
import moment from "moment";
import templateHtml from "./week_calendar_header.html";

export default {
    name: "sbWeekCalendarHeader",
    templateUrl: templateHtml,
    bindings: {
        onGoToToday: "&",
    },
    controller: WeekCalendarHeaderCtrl,
};

function WeekCalendarHeaderCtrl($scope, $sbWeekCalendar) {
    "ngInject";
    var vm = this;

    var _vm = {
        currentMonthText: undefined,
        goToPreviousWeek: goToPreviousWeek,
        goToNextWeek: goToNextWeek,
        goToToday: goToToday,
    };

    _.merge(vm, _vm);

    $scope.$watch(
        function () {
            return $sbWeekCalendar.currentMonthText;
        },
        function (currentMonthText) {
            vm.currentMonthText = currentMonthText;
        }
    );

    function goToPreviousWeek() {
        $sbWeekCalendar.goToPreviousWeek();
    }

    function goToNextWeek() {
        $sbWeekCalendar.goToNextWeek();
    }

    function goToToday() {
        $sbWeekCalendar.goToToday();
        vm.onGoToToday({
            date: $sbWeekCalendar.date,
        });
    }
}
