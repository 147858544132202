import html from "./activity_tree.tmpl.html";
import ActivityTreeCtrl from "./activity_tree.controller";

export default {
    templateUrl: html,
    bindings: {
        content: "<",
        onStateChange: "&?",
        onCreateNote: "&?",
        onFillChecklist: "&?",
        parentCtrl: "=?",
        onRequestActivityUpdate: "&?",
        onResetActivity: "&?",
    },
    controller: ActivityTreeCtrl,
};
