export default function ($log, ODATA_ORDER, $stateParams, $state) {
    "ngInject";
    /**
     * creates a new view filter and sort store
     * @param routeStateName - name of the router state (used to save the state in the url)
     * @param keyPrefix - local storage key prefix, may be unique per store
     * @param projectId - id of the current project
     * @constructor
     */
    function ViewFilterStore(routeStateName, keyPrefix, projectId) {
        this.routeStateName = routeStateName;
        this.keyPrefix = keyPrefix;
        this.projectId = projectId;
        this.excludeStateParams = ["projectId", "sort", "open"];
    }

    /**
     * saves filters in URL state params and in local store
     * @param filters {Object}
     * @param [pushOnNavigationHistoryStack] {boolean} - if true the state change will create a new history entry,
     *                                                   otherwise not - defaults to false
     */
    ViewFilterStore.prototype.setFilterParams = function setFilterParams(
        filters,
        pushOnNavigationHistoryStack
    ) {
        pushOnNavigationHistoryStack = !!pushOnNavigationHistoryStack;
        var params = this._viewFiltersToStateParams(filters);
        this._saveFilterParamsToLocalStore(params);
        this._setUrlParamsFromViewFilters(
            filters,
            pushOnNavigationHistoryStack
        );
    };

    /**
     * load state params either from URL or from local store
     * @returns {Object}
     */
    ViewFilterStore.prototype.getFilterParams = function getFilterParams() {
        if (this._hasActiveFiltersInURL()) {
            return $stateParams;
        } else {
            var params = this._loadFilterParamsFromLocalStore();
            return params || $stateParams;
        }
    };

    ViewFilterStore.prototype.setOrderBy = function setOrderBy(
        criteria,
        direction,
        pushOnNavigationHistoryStack
    ) {
        var orderParamValue = this._getOrderUrlParamValue(criteria, direction);
        this._saveSortQueryToLocalStore(orderParamValue);
        this.replaceUrlParamsWithoutReload(
            {
                sort: orderParamValue,
            },
            pushOnNavigationHistoryStack
        );
    };

    /**
     * load sort criteria and direction from URL or local store if available
     * @returns {{criteria: string, direction: string}|null}
     */
    ViewFilterStore.prototype.getOrderBy = function getOrderBy() {
        var previousSortCriteria;
        if (this._hasActiveFiltersInURL()) {
            previousSortCriteria = this._loadSortQueryFromURL();
        } else {
            previousSortCriteria = this._loadSortQueryFromLocalStore();
        }

        if (
            previousSortCriteria === null ||
            previousSortCriteria === undefined
        ) {
            return null;
        }

        var previousSortDirection = ODATA_ORDER.ASC;

        // setup descending sort criteria
        if (previousSortCriteria.charAt(0) === "-") {
            previousSortCriteria = previousSortCriteria.substring(1);
            previousSortDirection = ODATA_ORDER.DESC;
        }

        return {
            criteria: previousSortCriteria,
            direction: previousSortDirection,
        };
    };

    ViewFilterStore.prototype._hasActiveFiltersInURL =
        function hasActiveFiltersInURL() {
            return (
                this._getNumberOfAppliedFilters(
                    $stateParams,
                    this.excludeStateParams
                ) > 0
            );
        };

    /**
     * Create the URL according to current view filter setup.
     *
     * @param {Array.<ViewFilter>} filters  - An array with view filter objects. Representing current filter state.
     * @param {boolean} [addToHistory]    - if true one state change is tracked for all URL filter updates
     * @private
     */
    ViewFilterStore.prototype._setUrlParamsFromViewFilters =
        function _setUrlParamsFromViewFilters(filters, addToHistory) {
            filters.forEach(
                function (filter, index) {
                    if (index === 0) {
                        this._setFilterQueryToURL(
                            filter.key,
                            filter,
                            addToHistory
                        );
                    } else {
                        this._setFilterQueryToURL(filter.key, filter, false);
                    }
                }.bind(this)
            );
        };

    ViewFilterStore.prototype.replaceUrlParamsWithoutReload =
        function replaceUrlParamsWithoutReload(options, noStateReplace) {
            var navigationOptions = {
                notify: false,
            };

            if (!noStateReplace) {
                // the default case is replace.

                // but especially for the first step to the page we don't want to replace
                // the previous page.
                navigationOptions.location = "replace";
            } else {
                $log.debug("current URL location added to History");
                navigationOptions.location = true;
            }
            $state.go(this.routeStateName, options, navigationOptions);
        };

    /**
     * Set the given URL key to the given view filter value.
     * The view filter will use a value text as string
     * representation of the current filter.
     *
     * @param {String} filterKey
     * @param {ViewFilter} viewFilter
     * @param {boolean} [noStateReplace]
     * @private
     */
    ViewFilterStore.prototype._setFilterQueryToURL =
        function _setFilterQueryToURL(filterKey, viewFilter, noStateReplace) {
            var paramValue = viewFilter
                ? viewFilter.getValuesAsUrl()
                : undefined;

            var options = {};
            options[filterKey] = paramValue;
            this.replaceUrlParamsWithoutReload(options, noStateReplace);
        };

    /**
     * Create a $stateParams like map object from the given view filter array.
     *
     * @param {Array.<Object>} filters - An array with view filter objects. Representing current filter state.
     *
     * @returns {Object} $stateParams like map without undefined values.
     * @private
     */
    ViewFilterStore.prototype._viewFiltersToStateParams =
        function _viewFiltersToStateParams(filters) {
            return filters.reduce(function (map, filter) {
                if (filter.hasValue()) {
                    map[filter.key] = filter.getValuesAsUrl();
                }
                return map;
            }, {});
        };

    /**
     * Configures all view filters to reflect the active URL params.
     *
     * @param {Object} params - @see $stateParams like map.
     * @param {Array.<Object>} filters - An array with view filter objects. Representing current filter state.
     * @private
     */
    ViewFilterStore.prototype._setViewFilterValuesFrom =
        function _setViewFilterValuesFrom(params, filters) {
            // url to view filter initial values

            var nameToFilterMap = filters.reduce(function (map, filter) {
                map[filter.key] = filter;
                return map;
            }, {});

            // go over URL params and configure view filters
            Object.keys(params).forEach(function (key) {
                var value = params[key];
                var filter = nameToFilterMap[key];

                if (filter) {
                    filter.setValue(value);
                } else {
                    $log.debug(key + " not found in params.");
                }
            });
        };

    /**
     * Calculates the number of active filter queries in the URL,
     * which are view filter relevant. Will exclude specified query
     * params if passed in additionally.
     *
     * @param {Object} params - @see $stateParams like map.
     * @param {Array.<String>} exluded - Query param names to exclude from counting.
     *
     * @returns {Number} Number of active filter queries in params.
     * @private
     */
    ViewFilterStore.prototype._getNumberOfAppliedFilters =
        function _getNumberOfAppliedFilters(params, exluded) {
            exluded = exluded || [];

            return Object.keys(params)
                .filter(function (key) {
                    return exluded.indexOf(key) === -1;
                })
                .map(function (key) {
                    return params[key];
                })
                .reduce(function (sumOfAppliedFilters, filterValue) {
                    if (filterValue) {
                        sumOfAppliedFilters++;
                    }
                    return sumOfAppliedFilters;
                }, 0);
        };

    /**
     * Calculate query value for sort query param.
     *
     * @example _calcSortQueryValue('name', -1); // sort name descending
     *
     * @param {String} criteria - the sorted criteria
     * @param {Number} direction - ascending (positive number or 0) or descending (negative number)
     *
     * @returns {String} query value for sort param in ULR
     */
    ViewFilterStore.prototype._getOrderUrlParamValue =
        function _calcSortQueryValue(criteria, direction) {
            var queryValue = criteria;
            if (direction < 0) {
                queryValue = "-" + queryValue;
            }
            return queryValue;
        };

    /////////
    //  Local Storage Store
    ////////

    /**
     * Get the key that is used to save and load data from and to the local storage.
     * @returns {String} concatenation of `this.keyPrefix`, `.filter.` and `this.projectId`
     */
    ViewFilterStore.prototype.getFilterLocalStorageKey =
        function getLocalStorageKey() {
            return this.keyPrefix + ".filter." + this.projectId;
        };

    /**
     * Get the key that is used to save and load data from and to the local storage.
     * @returns {String} concatenation of `this.keyPrefix`, `.filter.` and `this.projectId`
     */
    ViewFilterStore.prototype.getSortLocalStorageKey =
        function getLocalStorageKey() {
            return this.keyPrefix + ".sort" + this.projectId;
        };

    /**
     * Store the current filter setup in the local store
     *
     * @param {Object} params - @see $stateParams like map.
     * @private
     */
    ViewFilterStore.prototype._saveFilterParamsToLocalStore =
        function _saveFilterQueryToLocalStore(params) {
            return localStorage.setItem(
                this.getFilterLocalStorageKey(),
                JSON.stringify(params)
            );
        };

    /**
     * Load the current filter setup from the local store.
     *
     * @private
     */
    ViewFilterStore.prototype._loadFilterParamsFromLocalStore =
        function _loadFilterParamsFromLocalStore() {
            return JSON.parse(
                localStorage.getItem(this.getFilterLocalStorageKey())
            );
        };

    /**
     * Put sort configuration into local storage. It's saved per project.
     *
     * @param {String} queryValue - The current URL param value for sorting.
     * @private
     */
    ViewFilterStore.prototype._saveSortQueryToLocalStore =
        function _saveSortQueryToLocalStore(queryValue) {
            return localStorage.setItem(
                this.getSortLocalStorageKey(),
                queryValue
            );
        };

    /**
     * Retrieve the current sort configuration from local storage as URL param value.
     *
     * @returns {String|null} The param value for sort or null.
     * @private
     */
    ViewFilterStore.prototype._loadSortQueryFromLocalStore =
        function _loadSortQueryFromLocalStore() {
            return localStorage.getItem(this.getSortLocalStorageKey());
        };

    /**
     * Retrieve the current sort configuration from the URL;
     *
     * @returns {String|null} The param value for sort or null.
     * @private
     */
    ViewFilterStore.prototype._loadSortQueryFromURL =
        function _loadSortQueryFromURL() {
            return $stateParams.sort;
        };

    /////////
    //  API
    ////////

    return {
        getStore: getStore,
    };

    /////////
    //  IMPL
    ////////
    /**
     * creates a new view filter and sort store
     * @param routeStateName - name of the router state (used to save the state in the url)
     * @param keyPrefix - local storage key prefix, may be unique per store
     * @param projectId - id of the current project
     * @returns {ViewFilterStore}
     */
    function getStore(routeStateName, keyPrefix, projectId) {
        return new ViewFilterStore(routeStateName, keyPrefix, projectId);
    }
}
