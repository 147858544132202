export default function DeliverableChecklistsPdfExportCtrl($mdDialog) {
    "ngInject";

    var vm = this;

    vm.closeDialog = $mdDialog.cancel;
    vm.dialogMode = {
        name: "LOADING",
    };

    init();

    function init() {
        vm.error = null;

        return vm
            .sendRequest()
            .then((response) => {
                vm.userEmail = response.requester.email;
                vm.dialogMode = {
                    name: "SUCCESS",
                };
            })
            .catch((error) => {
                vm.error = error;
                vm.dialogMode = {
                    name: "ERROR",
                };
            });
    }
}
