import angular from "angular";
import _ from "lodash";

export default function ActivityCreatorCtrl() {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;

    vm.onTeamSelected = onTeamSelected;
    vm.onStartDateChange = onStartDateChange;
    vm.findSelectedTeam = findSelectedTeam;

    function $onInit() {
        vm.teams = angular.copy(vm.teams) || [];
        vm.activity = angular.copy(vm.activity);

        vm.selectedTeam = vm.findSelectedTeam(vm.teams, vm.activity);
    }

    function $onChanges($event) {
        if ($event.activity) {
            vm.activity = angular.copy(vm.activity);
            vm.selectedTeam = vm.findSelectedTeam(vm.teams, vm.activity);
        }
    }

    function findSelectedTeam(teams, activity) {
        var selectedTeam = activity.assignedTeam;

        // find or assign default
        if (activity.hasAssignedTeam()) {
            selectedTeam = _.find(teams, ["id", activity.assignedTeam.id]);
        }

        return selectedTeam;
    }

    function onTeamSelected(team) {
        vm.selectedTeam = team;

        var hasNoTeamRestriction = vm.selectedTeam === null;
        if (hasNoTeamRestriction) {
            vm.activity.unsetTeamRestriction();
        } else {
            vm.activity.setResponsibleTeam(vm.selectedTeam);
        }

        vm.onActivityChanged({
            form: vm.activityForm,
            activity: vm.activity,
        });
    }

    function onStartDateChange(datetime) {
        vm.activity.startDate = datetime;

        vm.onActivityChanged({
            form: vm.activityForm,
            activity: vm.activity,
        });
    }
}
