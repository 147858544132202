import _ from "lodash";
import PresentableError from "./PresentableError";
import RequestError from "./RequestError";

/**
 * Basic error to handle authentication errors.
 *
 * @constructor
 * @extends PresentableError
 * @param {RequestError|String} messageOrError - Used to extract an error message to display
 */
function AuthError(messageOrError) {
    var message = "";

    if (messageOrError instanceof RequestError) {
        var response = messageOrError.parseResponseAsJSON();
        if (response === false) {
            // JSON parse failed
            message = messageOrError.request.responseText;
        } else if (response && response.exception) {
            message = response.exception;
        } else if (response && response.exceptionString) {
            message = response.exceptionString;
        } else if (response && response.exceptionMessage) {
            message = response.exceptionMessage;
        }
    } else {
        message = messageOrError;
    }

    this.plainMessage = message;
    this.code = this.messageToCode(message);
    this.title = this.code + "_TITLE";
    this.message = this.code + "_MESSAGE";
}

/**
 * Hana errors
 *
 * ## INVALID USERNAME OR PASSWORD ##
 * invalid username or password at ptime/query/catalog/userinfo.cc:952
 *
 * ## USER DEACTIVATED ##
 * user is deactivated: user was explicitly deactivated by administrator [1] at ptime/query/catalog/userinfo.cc:705
 *
 * ## OUTSIDE VALIDITY PERIOD ##
 * connect attempt outside user's validity period: ERR_CON_OUTSIDE_VALIDITY_PERIOD
 *
 * ## USER IS LOCKED ###
 * user is locked; try again later: lock time is 1440 minutes; user is locked until 2015-05-20 12:21:57.1620000 (given in UTC) [1440,2015-05-20 12:21:57.1620000] at ptime/query/catalog/userinfo.cc:946
 */

AuthError.errorTypeList = [
    {
        pattern: /User does not exist./i,
        code: "ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD",
    },
    {
        pattern: /Incorrect username or password/i,
        code: "ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD",
    },
    {
        pattern: /Password attempts exceeded/i,
        code: "ERROR_AUTHENTICATION_USER_LOCKED",
    },
];

AuthError.prototype = Object.create(PresentableError.prototype);

AuthError.prototype.messageToCode = function (message) {
    var error = _.find(AuthError.errorTypeList, function (errorType) {
        return errorType.pattern.test(message);
    });
    return (error && error.code) || "ERROR_AUTHENTICATION_UNKNOWN";
};

AuthError.prototype.shouldShowPlainMessage = function () {
    return this.code === "ERROR_AUTHENTICATION_UNKNOWN";
}; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ AuthError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.TOAST;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ AuthError.prototype.getIcon = function () {
    return "mdi mdi-account";
}; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ AuthError.prototype.shouldAutoClose = function () {
    return true;
};

AuthError.prototype.isValidError = function () {
    return AuthError.errorTypeList.some(function checkIfCodeIsEqual(
        errorObject
    ) {
        return errorObject.code === this.code;
    }, this);
};

export default AuthError;
