import moment from "moment";
import _ from "lodash";

export default function csvService(
    $sbCurrentProject,
    $translate,
    $sbLocale,
    $window,
    $sbReportingActivitiesApi,
    $sbReportingNotesApi,
    downloadCenterService
) {
    "ngInject";
    var ISSUES_PROPERTY_TRANSLATION = {
        ISSUE_NAME: "INPUT_ISSUE_KEY_SEARCH_LABEL",
        ISSUE_TYPE: "INPUT_ISSUE_TYPE_LABEL",
        ISSUE_TEXT: "_NOTE_TEXT",
        ISSUE_CODE: "INPUT_ISSUE_TEMPLATE_KEY_LABEL",
        ISSUE_TIME_CREATED: "_CREATION_DATE",
        ISSUE_STATUS: "_ISSUE_STATUS",
        ROOT_COMPONENT_STRUCTURE_ID: "_STRUCTURE",
        ROOT_COMPONENT_NAME: "INPUT_DELIVERABLE_NAME_LABEL",
        ROOT_COMPONENT_CODE: "INPUT_DELIVERABLE_CODE_LABEL",
        REF_COMPONENT_NAME: "_STAGE",
        AUTHOR_DISPLAY_NAME: "INPUT_ISSUE_AUTHOR_SEARCH_LABEL",
        ISSUE_IMAGE_COUNT: "_IMAGES",
        ISSUE_STATUS_OPEN: "_ISSUE_STATUS_OPEN",
        ISSUE_STATUS_RESOLVED: "_ISSUE_STATUS_CLOSED",
        ISSUE_STATUS_CLOSED: "_ISSUE_STATUS_CLOSED",
        ISSUE_STATUS_WFC: "_ISSUE_STATUS_WFC",
        ISSUE_STATUS_REJECTED: "_ISSUE_STATUS_REJECTED",
        ISSUE_STATUS_CONFIRMED: "_ISSUE_STATUS_CONFIRMED",
        ISSUE_STATUS_REMOVED: "_ISSUE_STATUS_REMOVED",

        CLAIM: "_CLAIM",
        OBSTRUCTION: "_OBSTRUCTION",
        INFO: "_INFO",

        // @deprecated
        RESPONSIBLE_TEAM_NAME: "_NOTE_RESPONSIBLE_TEAM",
        // @deprecated
        CONFIRMATION_TEAM_NAME: "_NOTES_CONFIRMATION_TEAM",
        RESPONSIBLE_TEAM_ID: "_NOTE_RESPONSIBLE_TEAM",
        CONFIRMATION_TEAM_ID: "_NOTES_CONFIRMATION_TEAM",
        PROJECT_TEAM: "_PROJECT_TEAM",
        EMPTY_CONFIRMATION_TEAM: "_NO_CONFIRMATION_TEAM_AVAILABLE",
        _UNRESTRICTED_TEAM: "_UNRESTRICTED_TEAM",
    };

    var PROGRESS_PROPERTY_TRANSLATION = {
        NAME: "DELIVERABLE_NAME",
        CODE: "DELIVERABLE_CODE",
        DESC: "DELIVERABLE_DESC",
        PROGRESS: "DELIVERABLE_PROGRESS",
        PI_TEMPLATE_ID: "_PROCESS",
        STRUCTURE_ID: "PROJECT_STRUCTURE",
        SD: "PLANNED_DELIVERABLE_START_DATE",
        CD: "PLANNED_DELIVERABLE_END_DATE",
        EARLIEST_START: "EARLIEST_DELIVERABLE_START_DATE",
        EARLIEST_END: "EARLIEST_DELIVERABLE_END_DATE",
        LATEST_START: "LATEST_DELIVERABLE_START_DATE",
        LATEST_END: "LATEST_DELIVERABLE_END_DATE",
        LAST_INSPECTION: "_LAST_INSPECTION",
        IS_BEHIND: "DELIVERABLE_SCHEDULE",
        CLAIM_QUANTITY: "_CLAIMS",
        OBSTRUCTION_QUANTITY: "_OBSTRUCTIONS",
        UNSCHEDULED: "_NO_PROCESS",
        ON_TIME: "_ON_SCHEDULE",
        BEHIND: "_BEHIND_SCHEDULE",
        // extended progress export translations
        ACTIVITY_ID: "ACTIVITY_ID",
        ACTIVITY_NAME: "ACTIVITY_NAME",
        ACTIVITY_DESC: "ACTIVITY_DESCRIPTION",
        ACTIVITY_PROGRESS: "ACTIVITY_CURRENT_PROGRESS",
        ACTIVITY_CLAIM_QUANTITY: "ACTIVITY_OPEN_CLAIM_QUANTITY",
        ACTIVITY_OBSTRUCTION_QUANTITY: "ACTIVITY_OPEN_OBSTRUCTION_QUANTITY",
        ACTIVITY_USER_START_DATE: "PLANNED_ACTIVITY_START_DATE",
        ACTIVITY_USER_DUE_DATE: "PLANNED_ACTIVITY_END_DATE",
        ACTIVITY_EARLIEST_START: "EARLIEST_ACTIVITY_START_DATE",
        ACTIVITY_EARLIEST_END: "EARLIEST_ACTIVITY_END_DATE",
        ACTIVITY_LATEST_START: "LATEST_ACTIVITY_START_DATE",
        ACTIVITY_LATEST_END: "LATEST_ACTIVITY_END_DATE",
        ACTIVITY_ALLOCATION: "ACTIVITY_PLANNED_DURATION",
        ACTIVITY_ALLOCATION_UNIT: "DURATION_UNIT",
        ACTIVITY_ACTUAL_START: "ACTIVITY_ACTUAL_START_DATE",
        ACTIVITY_ACTUAL_START_REPORTER_DB_NAME: "ACTUAL_START_REPORTED_BY",
        ACTIVITY_ACTUAL_END: "ACTIVITY_ACTUAL_END_DATE",
        ACTIVITY_ACTUAL_END_REPORTER_DB_NAME: "ACTUAL_END_REPORTED_BY",
        ACTIVITY_ASSIGNED_TEAM_ID: "ACTIVITY_ASSIGNED_TEAM_NAME",
        ACTIVITY_TOPOLOGICAL_INDEX: "_TOPOLOGICAL_INDEX",
        ACTIVITY_PLANNED_LABOUR_ALLOCATION: "ACTIVITY_DETAILS_PLANNED_LABOUR",
        ACTIVITY_CONFIRMATION_TEAM_ID: "ACTIVITY_DETAILS_CONFIRMING_TEAM",
        ACTIVITY_CHECKLIST_TEMPLATE_ID: "ACTIVITY_REQUIRED_CHECKLIST",
        ACTIVITY_COMMITTED_LAST_PLANNED_START: "ACTIVITY_LAST_PLANNED_START",
        ACTIVITY_COMMITTED_LAST_PLANNED_END: "ACTIVITY_LAST_PLANNED_END",
        ACTIVITY_COMMITTED_LAST_PLANNED_DURATION:
            "ACTIVITY_LAST_PLANNED_DURATION",
        ACTIVITY_IS_BEHIND: "ACTIVITY_SCHEDULE",
        ACTIVITY_CONFIRMATION_TIME: "ACTIVITY_CONFIRMATION_TIME",
        ACTIVITY_CONFIRMATION_AUTHOR_DB_NAME: "ACTIVITY_CONFIRMATION_REPORTER",
        ACTIVITY_STATE: "ACTIVITY_STATE",
        // dictionary
        PROJECT_TEAM: "_PROJECT_TEAM",
        _UNRESTRICTED_TEAM: "_UNRESTRICTED_TEAM",
        YES: "_YES",
        NO: "_NO",
    };

    // API
    //
    return {
        downloadAllNotesInCSV: downloadAllNotesInCSV,
        fetchSourceProgressCSV: fetchSourceProgressCSV,
        fetchProjectProgressCSV: fetchProjectProgressCSV,
        generateCsvFrom: generateCsvFrom,
        downloadCsv: downloadCsv,
    };

    /**
     * Send a http request to the server to download all notes in a CSV report
     * @param {String} projectId
     * @param {Object} translation - includes all the translated values for the report generation
     * @returns {Promise}
     */
    function downloadAllNotesInCSV() {
        return _getCSVReportTranslations(ISSUES_PROPERTY_TRANSLATION).then(
            function (trans) {
                return $sbReportingNotesApi
                    .exportCollection($sbCurrentProject.pick("id"), {
                        i18n: trans.split(";"),
                    })
                    .then((xhr) => {
                        const blob = new Blob([xhr.response], {
                            type: xhr.getResponseHeader("Content-Type"),
                        });
                        return _getNotesCSVReportFileName().then((filename) => {
                            return downloadCenterService.downloadBlob(
                                blob,
                                filename
                            );
                        });
                    });
            }
        );
    }

    function fetchSourceProgressCSV(sourceKey, sourceType, projectId) {
        return _getCSVReportTranslations(PROGRESS_PROPERTY_TRANSLATION).then(
            function (translateConfig) {
                var urlParameter = {
                    language: $sbLocale.current(),
                    i18n: translateConfig.split(";"),
                    by_source_id: sourceKey,
                    by_source_type: sourceType,
                };
                return _fetchProgressCSV(projectId, urlParameter);
            }
        );
    }

    function fetchProjectProgressCSV(projectId) {
        return _getCSVReportTranslations(PROGRESS_PROPERTY_TRANSLATION).then(
            function (translateConfig) {
                var urlParameter = {
                    language: $sbLocale.current(),
                    i18n: translateConfig.split(";"),
                };

                return _fetchProgressCSV(projectId, urlParameter);
            }
        );
    }

    function _getCSVReportTranslations(transPropertyMap) {
        return $translate(_.values(transPropertyMap)).then(function (trans) {
            var translations = [];

            _.keys(transPropertyMap).forEach(function (translationKey) {
                translations.push(
                    translationKey +
                        ":" +
                        trans[transPropertyMap[translationKey]]
                );
            });
            return translations.join(";");
        });
    }

    function _getNotesCSVReportFileName() {
        var exportDate = moment().format("l");

        return $translate("SECTION_ISSUES_PAGE_TITLE").then(
            function (translation) {
                return (
                    exportDate +
                    "_" +
                    translation +
                    "_" +
                    $sbCurrentProject.pick("name").trim() +
                    ".csv"
                );
            }
        );
    }

    function _fetchProgressCSV(projectId, urlParameter) {
        //Get the data
        return $sbReportingActivitiesApi.exportCollection(
            projectId,
            urlParameter
        );
    }

    /////
    // Frontend csv generation
    // downloadCsv code found here:
    // https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2
    /////

    /**
     * @data {Object}
     * @options {Object}
     * @options.fileName {string}
     **/
    function downloadCsv(data, options) {
        if (!data) {
            return;
        }

        var CSV_EXTENSION = ".csv";
        var DEFAULT_FILENAME = "export" + CSV_EXTENSION;
        var CSV_FILE_HEADER = "text/csv;charset=utf-8;";

        var fileName;

        if (!options) {
            options = {};
        }

        fileName = options.fileName + CSV_EXTENSION || DEFAULT_FILENAME;

        var blob = new Blob([data], {
            type: CSV_FILE_HEADER,
        });

        if ($window.navigator.msSaveBlob) {
            // IE 10+, FF
            $window.navigator.msSaveBlob(blob, fileName);
        } else {
            var link = $window.document.createElement("a");

            // feature detection
            //
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                //
                var url = URL.createObjectURL(blob);

                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = "hidden";

                $window.document.body.appendChild(link);

                link.click();

                $window.document.body.removeChild(link);
            }
        }
    }

    /////
    // generateCsvFrom code based on the following link:
    // https://halistechnology.com/2015/05/28/use-javascript-to-export-your-data-as-csv/
    /////

    /**
     * @data {Object}
     * @options {Object}
     * @options.columnDelimiter {string}
     * @options.lineDelimiter {string}
     *
     * @returns string
     */
    function generateCsvFrom(data, options) {
        if (_.isEmpty(data.rows)) {
            return null;
        }

        var FALLBACK_DELIMITERS = {
            column: ",",
            line: "\n",
        };

        options = options || {};
        options.columnDelimiter = _.get(
            options,
            "columnDelimiter",
            FALLBACK_DELIMITERS.column
        );
        options.lineDelimiter = _.get(
            options,
            "lineDelimiter",
            FALLBACK_DELIMITERS.line
        );

        var result = "";

        result += _createHeaderLine(data.columnNames, options);

        _.forEach(data.rows, function (row) {
            var keys = Object.keys(row);

            var line = _.chain(keys)
                .map(function sanitizeRowContent(key) {
                    var rowContent = row[key];
                    return _sanitize(rowContent, options);
                })
                .join(options.columnDelimiter)
                .value();

            line += options.lineDelimiter;

            result += line;
        });

        return result.trim();
    }

    function _createHeaderLine(columnNames, options) {
        var line = "";
        line += columnNames.join(options.columnDelimiter);
        line += options.lineDelimiter;
        return line;
    }

    function _sanitize(rowContent, options) {
        rowContent = _replaceAllCarriageReturnsWithNewLinesOn(rowContent);
        rowContent = _removeNewLinesFrom(rowContent);

        rowContent = _resolveConflictingLineDelimitersOn(
            rowContent,
            options.lineDelimiter
        );
        rowContent = _resolveConflictingColumnDelimitersOn(
            rowContent,
            options.columnDelimiter
        );

        return rowContent;
    }

    function _replaceAllCarriageReturnsWithNewLinesOn(rowContent) {
        return rowContent.toString().replace(/\r/g, "\n").trim();
    }

    function _removeNewLinesFrom(rowContent) {
        var parts = rowContent.split("\n");

        // if new line characters are one after the other
        // this will result in empty elements in the array
        //
        parts = _.filter(parts, function isNotEmptyString(part) {
            return part !== "" && part !== "";
        });

        return parts.join(" ").trim();
    }

    function _resolveConflictingLineDelimitersOn(rowContent, character) {
        if (_.includes(rowContent, character)) {
            return rowContent.split(character).join(" ");
        }
        return rowContent;
    }

    function _resolveConflictingColumnDelimitersOn(rowContent, character) {
        if (_.includes(rowContent, character)) {
            return '"' + rowContent + '"';
        }
        return rowContent;
    }
}
