import moment from "moment";
import _ from "lodash";

function DiaryEntry(id) {
    this.id = id;

    this.date = null;
    this.created = {
        by: null,
        at: null,
    };
    this.finalized = {
        by: null,
        at: null,
    };
    this.department = null;
    this.machines = null;
    this.events = null;
    this.workingTime = {
        start: null,
        end: null,
    };
    this.weather = {
        condition: null,
        tempature: {
            low: null,
            high: null,
        },
    };
    this.teams = [];
}

DiaryEntry.createFromServerObject = function (
    serverObject,
    allTeamMembersInProject,
    allTeamsInProject
) {
    var diaryEntry = new DiaryEntry(serverObject.id);

    diaryEntry.setDate(serverObject.date);
    diaryEntry.setCreatedAt(serverObject.createdAt);
    diaryEntry.setFinalizedAt(serverObject.finalizedAt);
    diaryEntry.setWorkingTime(serverObject.workingTime);

    var userWhoCreatedEntry = _.find(allTeamMembersInProject, [
        "username",
        serverObject.createdBy,
    ]);
    var userWhoFinalizedEntry = _.find(allTeamMembersInProject, [
        "username",
        serverObject.finalizedBy,
    ]);

    if (userWhoCreatedEntry) {
        diaryEntry.created.by = userWhoCreatedEntry;
    }
    if (userWhoFinalizedEntry) {
        diaryEntry.finalized.by = userWhoFinalizedEntry;
    }

    diaryEntry.department = serverObject.department;
    diaryEntry.machines = serverObject.machines;
    diaryEntry.events = serverObject.events;
    diaryEntry.weather = serverObject.weather;
    diaryEntry.teams = allTeamsInProject;

    _setTeamMembers(diaryEntry, serverObject);

    return diaryEntry;
};

function _setTeamMembers(diaryEntry, serverObject) {
    diaryEntry.teams.forEach(function (team) {
        team.numberOfManager = 0;
        team.numberOfForeman = 0;
        team.numberOfWorker = 0;
        team.numberOfOther = 0;

        var matchingTeam = _.find(serverObject.teams, ["id", team.id]);

        if (matchingTeam) {
            _.set(
                team,
                "numberOfManager",
                _.defaultTo(matchingTeam.numberOfManager, 0)
            );
            _.set(
                team,
                "numberOfForeman",
                _.defaultTo(matchingTeam.numberOfForeman, 0)
            );
            _.set(
                team,
                "numberOfWorker",
                _.defaultTo(matchingTeam.numberOfWorker, 0)
            );
            _.set(
                team,
                "numberOfOther",
                _.defaultTo(matchingTeam.numberOfOther, 0)
            );
        }
    });
}

DiaryEntry.prototype.setDate = function (dateAsString) {
    this.date = moment(dateAsString);
    return this;
};

DiaryEntry.prototype.setCreatedAt = function (dateAsString) {
    this.created.at = moment(dateAsString);
    return this;
};

DiaryEntry.prototype.setFinalizedAt = function (dateAsString) {
    this.finalized.at = moment(dateAsString);
    return this;
};

DiaryEntry.prototype.setWorkingTime = function (workingTime) {
    if (!workingTime.start && !workingTime.end) {
        this.workingTime = {
            start: "07:00",
            end: "16:00",
        };
        return;
    }

    this.workingTime = workingTime;
};

DiaryEntry.toServerUploadModel = function (data) {
    return {
        is_finalized: data.isFinalized(),
        department: data.department,
        machines: data.machines,
        events: data.events,
        working_time: {
            start: _.get(data, "workingTime.start"),
            end: _.get(data, "workingTime.end"),
        },
        weather: {
            condition: _.get(data, "weather.condition"),
            temperature: {
                lowest: _.get(data, "weather.temperature.low"),
                highest: _.get(data, "weather.temperature.high"),
            },
        },
        teams: _teamsToUploadModel(data.teams),
    };
};

function _teamsToUploadModel(teams) {
    return teams.map(function (team) {
        return {
            id: team.id,
            number_of_managers: _.defaultTo(team.numberOfManager, 0),
            number_of_foremen: _.defaultTo(team.numberOfForeman, 0),
            number_of_workers: _.defaultTo(team.numberOfWorker, 0),
            number_of_others: _.defaultTo(team.numberOfOther, 0),
        };
    });
}

/*
 * Get total amount of a type of worker (eg. manager) for all teams
 * @param {string} memberType - either numberOfManager, numberOfForeman, numberOfWorker, or numberOfOther
 * @returns {number} total amount of memberTypes across all teams.
 */
DiaryEntry.prototype.getTotalCountFor = function (memberType) {
    return this.teams.reduce(function (sum, team) {
        return (sum += _.defaultTo(team[memberType], 0));
    }, 0);
};

DiaryEntry.prototype.getTotalTeamMembersCount = function () {
    var memberTypes = [
        "numberOfManager",
        "numberOfForeman",
        "numberOfWorker",
        "numberOfOther",
    ];
    var _this = this;

    return memberTypes.reduce(function (sum, memberType) {
        return (sum += _this.getTotalCountFor(memberType));
    }, 0);
};

DiaryEntry.prototype.getMembersCountFor = function (team) {
    return (
        _.defaultTo(team.numberOfManager, 0) +
        _.defaultTo(team.numberOfForeman, 0) +
        _.defaultTo(team.numberOfWorker, 0) +
        _.defaultTo(team.numberOfOther, 0)
    );
};

DiaryEntry.prototype.isFinalized = function () {
    return !!(this.finalized.at && this.finalized.by);
};

export default DiaryEntry;
