/**
 * Base Class for Commands
 * @constructs Command
 * @memberof WBS
 * @interface
 */
function Command() {}

/**
 * Called when Command is executed in Command Chain
 */
Command.prototype.execute = function () {};

/**
 * Called to revert the execution
 */
Command.prototype.undo = function () {};

/**
 * Called to redo the execution
 */
Command.prototype.redo = function () {};

export default Command;
