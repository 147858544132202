import angular from "angular";
import commercialRouteConfig from "./commercial.route";
import CommercialCtrl from "./commercial.controller";
import sbCommercialCardComponent from "./components/sbCommercialCard/commercial_card.component";
import sbCostGroupComponent from "./components/sbCostGroup/cost_group.component";
import sbCommercialCardHeader from "./components/sbCommercialCardHeader/commercial_header.component";
import sbDateSelectModule from "./components/sbDateSelect/date_select.module";
import sbCommercialMembershipEdit from "./components/sbCommercialMembershipEdit/commercial_membership_edit.component";
import CommercialApiService from "./services/commercial_api.service";
import CommercialService from "./services/commercial.service";
import CreateNewCostGroupPreset from "./dialogs/createNewCostGroup/create_new_cost_group.config";
import CombineCostGroupActivitiesConfig from "./dialogs/combineCostGroupActivities/combine_cost_group_activities.config";
import EditCostGroupMembershipPreset from "./dialogs/editCostGroupMembership/edit_cost_group_membership.config";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.commercial", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.directives",
        "sbApp.common.components.processTemplate",
        sbDateSelectModule.name,
    ])
    .config(commercialRouteConfig)
    .config(CreateNewCostGroupPreset)
    .config(CombineCostGroupActivitiesConfig)
    .config(EditCostGroupMembershipPreset)
    .controller("CommercialCtrl", CommercialCtrl)
    .factory("$sbCommercialApi", CommercialApiService)
    .factory("$sbCommercial", CommercialService)
    .component(sbCommercialCardComponent.name, sbCommercialCardComponent)
    .component(sbCostGroupComponent.name, sbCostGroupComponent)
    .component(sbCommercialCardHeader.name, sbCommercialCardHeader)
    .component(sbCommercialMembershipEdit.name, sbCommercialMembershipEdit);
