import angular from "angular";
import WbsCtrl from "./wbs.controller";
import wbsRouteConfig from "./wbs.route";
import "./directives/wbs_editor.directive";
import servicesModule from "../../common/services/services";
import apiModule from "../../common/services/api/api.module";
import "common/services/services";
import "common/intercom/intercomModule";
import "common/dialog/multiSelectionList/multi_selection_list.dialog";

export default angular
    .module("sbApp.wbsEditor", [
        "ui.router",
        servicesModule.name,
        apiModule.name,
        "sbApp.qrCardService",
        "sbApp.wbs",
        "sbApp.intercom",
        "sbApp.sbMultiSelectionListDialog",
    ])
    .config(wbsRouteConfig)
    .controller("WbsCtrl", WbsCtrl);
