import angular from "angular";
import _ from "lodash";

export default function EditOrAddTeamDialogCtrl(
    $mdDialog,
    $sbColor,
    $sbFileReader,
    $sbImageUpload,
    $sbErrorPresenter,
    $sbCurrentProject,
    $sbTeam,
    $log
) {
    "ngInject";
    var vm = this;

    var MAX_LOGO_IMAGE_WIDTH = 300;
    var MAX_LOGO_IMAGE_HEIGHT = 300;

    vm.allowedExtensions = [".png", ".jpg", ".jpeg"];
    vm.deleteAllowed = false;
    vm.editTeam = {};
    vm.editLogo = {};
    vm.isProcessingImage = false;
    vm.projectId = null;

    vm.error = {
        i18n: "",
        options: {
            fileTypes: vm.allowedExtensions.join(", "),
        },
    };

    vm.image = {
        url: null,
    };

    vm.isModified = isTeamModified;
    vm.delete = deleteTeam;
    vm.closeDialog = closeDialog;
    vm.onTeamLogoChange = onTeamLogoChange;
    vm.processImage = processImage;
    vm.resolve = resolve;
    vm.onWrongExtension = onWrongExtension;

    activate();

    function onTeamLogoChange($event, file) {
        vm.isProcessingImage = true;

        return vm
            .processImage(file)
            .finally(function () {
                vm.isProcessingImage = false;
            })
            .catch($sbErrorPresenter.catch);
    }

    function processImage(file) {
        return $sbFileReader
            .readAndResizeImage(
                file,
                MAX_LOGO_IMAGE_WIDTH,
                MAX_LOGO_IMAGE_HEIGHT
            )
            .then(onImageResized);
    }

    /**
     * Upload and display image
     *
     * @param imageData {Object}
     * @param imageData.fileName {string}
     * @param imageData.data.rawData {Blob}
     * @returns {Promise<undefined>}
     */
    function onImageResized(imageData) {
        return $sbImageUpload
            .uploadImages(
                imageData.fileName,
                [imageData.data.rawData],
                vm.projectId
            )
            .then(function (logo) {
                vm.editLogo = _.merge({}, imageData);
                vm.editTeam.logoId = logo.id;
                vm.editTeam.logoUrl = logo.url.remote;

                return logo.id;
            })
            .then((imageId) => {
                return $sbTeam.fetchLogo(vm.projectId, imageId);
            })
            .then(({ dataUrl }) => {
                vm.image.url = dataUrl;
            })
            .catch(function (err) {
                $log.error(err);
                vm.error.i18n = "ERROR_FAILED_FILE_UPLOAD";
            });
    }

    function onWrongExtension() {
        vm.error.i18n = "ERROR_UNSUPPORTED_FILE_EXTENSION";
    }

    function activate() {
        if (vm.team) {
            // edit mode. Create a copy and display delete button
            vm.editTeam = angular.copy(vm.team);
            vm.deleteAllowed =
                !vm.editTeam.isProjectTeam && !vm.editTeam.hasMembers();
            vm.projectId = vm.team.projectId;

            // only fetch logo on update of team
            //
            fetchTeamLogo(vm.projectId, vm.team.logoId);
        } else {
            // default color
            // @Todo: avoid repeating colors
            vm.editTeam.color = $sbColor.color.random();
            vm.address = {};
            vm.contact = {};
            vm.projectId = $sbCurrentProject.pick("id");
        }
    }

    function isTeamModified() {
        return !angular.equals(vm.editTeam, vm.team);
    }

    function closeDialog() {
        $mdDialog.cancel();
    }

    function resolve() {
        vm.editTeam.address = _emptyStringsToNull(vm.editTeam.address);
        vm.editTeam.contact = _emptyStringsToNull(vm.editTeam.contact);
        vm.team = _emptyStringsToNull(vm.editTeam);

        $mdDialog.hide(vm.team);
    }

    function _emptyStringsToNull(object) {
        return _.mapValues(object, function (value) {
            if (angular.isString(value) && value.trim() === "") {
                return null;
            }
            return value;
        });
    }

    function deleteTeam() {
        return $mdDialog.cancel(vm.team);
    }

    function fetchTeamLogo(projectId, imageId) {
        return $sbTeam.fetchLogo(projectId, imageId).then((image) => {
            vm.image.url = image.dataUrl;
        });
    }
}
