import angular from "angular";
export default angular
    .module("sbApp.sbAutoScroll", [])
    .directive("sbAutoScroll", function sbAutoScrollDirective() {
        return {
            restrict: "A",
            scope: {
                sbAutoScroll: "=",
            },
            link: linkAutoScroll,
        };
    });

function linkAutoScroll(scope, iElement, iAttrs, controller, transcludeFn) {
    scope.$watch("sbAutoScroll", function (newVal, oldVal) {
        if (oldVal !== undefined && newVal > oldVal) {
            iElement[0].scrollTop = iElement[0].scrollHeight;
        }
    });
}
