import settingHtml from "./sb_visualization_setting_menu.html";

export default {
    controllerAs: "$ctrl",
    templateUrl: settingHtml,
    controller: function VisualizationSettingCtrl(
        $sbSketchVisualizationSetting
    ) {
        "ngInject";
        var vm = this;

        // just init
        vm.showBorder = $sbSketchVisualizationSetting.model.showBorder;

        vm.onBorderChange = function (isShown) {
            if (isShown) {
                $sbSketchVisualizationSetting.showBorder();
            } else {
                $sbSketchVisualizationSetting.hideBorder();
            }
        };
    },
};
