import angular from "angular";
import htmlTemplate from "./sbActivityItem.html";
/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbActivityItem", [])
    .directive("sbActivityItem", function () {
        return {
            /**
             * String of subset of EACM which restricts the directive to a specific directive declaration style.
             * If omitted, the defaults (elements and attributes) are used.
             *
             * @example <caption>Element Name</caption>
             * E - Element name (default): <my-directive></my-directive>
             * A - Attribute (default): <div my-directive="exp"></div>
             * C - Class: <div class="my-directive: exp;"></div>
             * M - Comment: <!-- directive: my-directive exp -->
             */
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            //transclude: true,

            scope: {
                activityName: "=",
                activityStateName: "=",
                editable: "=",
                sbOnValueChange: "&",
            },

            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

            //compile : function(tElement, tAttrs) {},

            //link: function (scope, element, attrs, controller) { },

            controller: function ($scope) {
                $scope.enableNameEdit = function (isButton) {
                    if ($scope.editable) {
                        $scope.isEditNameEnabled = true;
                        if (isButton) {
                            $scope.selectNameTextOnFocus = false;
                        } else {
                            $scope.selectNameTextOnFocus = true;
                        }
                    }
                };
                $scope.enableStateEdit = function (isButton) {
                    if ($scope.editable) {
                        $scope.isEditStateEnabled = true;

                        if (isButton) {
                            $scope.selectStateTextOnFocus = false;
                        } else {
                            $scope.selectStateTextOnFocus = true;
                        }
                    }
                };
            },
        };
    });
