export default class ReportingDeliverablesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the deliverables in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}            Query parameter
     * @param query.filter {string}         An odata filter string.
     * @param query.select {string}         An odata select string.
     * @param query.orderby {string}        An odata order by string.
     * @param query.top {number}            An odata top specification limiting the amount of records returned.
     * @param query.skip {number}           An odata skip specification used to paginate the returned records.
     * @param query.expand {string}         A nested property that will be expand per entity
     */
    getCollection(projectId, { filter, select, orderby, top, skip, expand }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/deliverables`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                filter,
                select,
                orderby,
                top,
                skip,
                expand,
            },
        });
    }

    /**
     * This endpoint returns a PDF representation of the deliverables in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}            Query parameter
     * @param query.filter {string}         An odata filter string.
     * @param query.i18n {string[]}         A key value pair separated by ':' where the value is replacing the key when processing the request.
     *
     * PDF report related query parameters
     * @param query.language {string}               A language abbreviation string.
     * @param query.as { "qr_codes"|"report" }      The type of PDF to print, e.g. QR codes or performance report.
     * @param query.layout { "qr_codes"|"report" }      A supported zweckform layout.
     */
    printCollection(projectId, { filter, i18n, language, as, layout }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/deliverables`;
        return this.$sbRequest.pdf({
            url: endpointUrl,
            method: "GET",
            query: {
                filter,
                i18n,
                as,
                layout,
            },
            header: {
                "Accept-Language": language,
            },
        });
    }

    /**
     * This endpoint returns a CSV representation of the deliverables in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}            Query parameter
     * @param query.expand {string}         A nested property that will be expand per entity
     * @param query.i18n {string[]}         A key value pair separated by ':' where the value is replacing the key when processing the request.
     * @param query.language {string}       A language to be used when generating the report.
     * @param query.by_source_id {number}   A source id to be used when generating the report.
     * @param query.by_source_type {string} A source type to be used when generating the report.
     */
    exportCollection(
        projectId,
        { expand, i18n, language, by_source_id, by_source_type }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/deliverables`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "GET",
            query: {
                expand,
                i18n,
                language,
                by_source_id,
                by_source_type,
            },
            header: {
                Accept: "text/csv",
            },
        });
    }

    /**
     * This endpoint returns a list representation of the deliverables in the project which is enhanced by tracking measures like progress and quality.
     *
     * @param projectId {string}        UUID
     * @param visualizationId {number|string}  Filter the result by visualization entity id.
     * @param top {number}  How many deliverables to retrieve.
     * @param skip {number}  How many deliverables to skip.
     */
    getCollectionByVisualization(projectId, visualizationId, top, skip) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/deliverables`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                by_visualization_id: visualizationId,
                top: top,
                skip: skip,
            },
        });
    }
}
