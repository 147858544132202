import angular from "angular";
import htmlTemplate from "./gallery.html";
import _ from "lodash";

export default angular
    .module("sbApp.common.components.gallery", [])
    .component("sbGallery", {
        templateUrl: htmlTemplate,
        bindings: {
            images: "<",
            currentIndex: "=",
            onImageUrlLoaded: "&",
        },
        controllerAs: "gallery",
        controller: function ($scope, $document, $q, $sbImageApi, $sbProject) {
            var vm = this;
            var keyDownListener;

            vm.$onInit = $onInit;
            vm.$onChanges = $onChanges;
            vm.$onDestroy = $onDestroy;

            vm.getScrollStyle = getScrollStyle;
            vm.scrollPhotoRight = scrollPhotoRight;
            vm.scrollPhotoLeft = scrollPhotoLeft;
            vm.isArrowRightActive = isArrowRightActive;
            vm.isArrowLeftActive = isArrowLeftActive;
            vm.onKeyDown = onKeyDown;

            function $onInit() {
                vm.currentIndex = vm.currentIndex || 0;

                keyDownListener = angular
                    .element($document)
                    .on("keydown", onKeyDown);
            }

            function $onChanges(changes) {
                if (
                    _.get(changes, "images.currentValue") !==
                    _.get(changes, "images.previousValue")
                ) {
                    return changes.images.currentValue.forEach(
                        (photo, index) => {
                            const data = {
                                atIndex: index,
                            };

                            if (!photo.image_url) {
                                return downloadFullImage(photo).then(() => {
                                    vm.onImageUrlLoaded(data);
                                });
                            }
                            vm.onImageUrlLoaded(data);
                        }
                    );
                }
            }

            function $onDestroy() {
                angular.element($document).off("keydown", keyDownListener);
            }

            function onKeyDown($event) {
                if ($event.keyCode === 37) {
                    vm.scrollPhotoLeft();
                }
                if ($event.keyCode === 39) {
                    vm.scrollPhotoRight();
                }
                $scope.$apply();
            }

            function getScrollStyle() {
                return {
                    "min-width": vm.images.length * 100 + "%",
                    transform:
                        "translateX(-" +
                        (100 / vm.images.length) * vm.currentIndex +
                        "%)",
                };
            }

            function scrollPhotoRight() {
                if (vm.currentIndex < vm.images.length - 1) {
                    vm.currentIndex++;
                }
            }

            function scrollPhotoLeft() {
                if (vm.currentIndex > 0) {
                    vm.currentIndex--;
                }
            }

            function isArrowRightActive() {
                return vm.images.length - 1 > vm.currentIndex;
            }

            function isArrowLeftActive() {
                return vm.currentIndex > 0;
            }

            function downloadFullImage(photo) {
                const projectId = $sbProject.getCurrentProjectId();
                return $sbImageApi
                    .download(projectId, photo.id, {
                        transformResponse: [
                            $sbImageApi.response.transformToDataURL,
                        ],
                    })
                    .then((image) => {
                        photo.image_url = image.dataUrl;
                        return photo;
                    });
            }
        },
    });
