import angular from "angular";
import htmlTemplate from "./progress_options_item.tmpl.html";
import componentCtrl from "./progress_options_items.controller";

export default angular
    .module("sbApp.common.components.progressOptionsItem", [])
    .component("sbProgressOptionsItem", {
        templateUrl: htmlTemplate,
        transclude: true,
        require: {
            parent: "^sbProgressOptions",
        },
        bindings: {
            key: "@",
            disabled: "=?ngDisabled",
        },
        controller: componentCtrl,
    });
