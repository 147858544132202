import angular from "angular";

export default angular
    .module("sbApp.templateFilters", [])
    .filter("assignGroupIcon", function (TEMPLATE_CATEGORY_MAPPING) {
        return function (category) {
            return angular.isDefined(TEMPLATE_CATEGORY_MAPPING[category])
                ? TEMPLATE_CATEGORY_MAPPING[category].icon
                : TEMPLATE_CATEGORY_MAPPING.GROUP.icon;
        };
    })
    .filter("assignGroupTranslation", function (TEMPLATE_CATEGORY_MAPPING) {
        return function (category) {
            return angular.isDefined(TEMPLATE_CATEGORY_MAPPING[category])
                ? TEMPLATE_CATEGORY_MAPPING[category].label
                : TEMPLATE_CATEGORY_MAPPING.GROUP.label;
        };
    })
    .filter("filterCategory", function () {
        return function (list, type) {
            if (!angular.isArray(list)) {
                return [];
            }
            if (type === "GROUP") {
                return list.filter(function (child) {
                    return !(
                        child.category === "ACTIVITY" ||
                        child.CATEGORY === "ACTIVITY"
                    );
                });
            } else {
                return list.filter(function (child) {
                    return (
                        child.category === "ACTIVITY" ||
                        child.CATEGORY === "ACTIVITY"
                    );
                });
            }
        };
    })
    .filter("sortTemplateComponents", function () {
        return function (list) {
            if (!angular.isArray(list)) {
                return [];
            } else {
                return list.sort(_compareComponents);
            }
        };
    })
    .filter("compareTemplateComponents", function () {
        return _compareComponents;
    });

/**
 * A basic sorter that prefers categories over activities. Otherwise string compare.
 *
 * @param {Component} cOne - first element
 * @param {Component} cTwo - second element
 *
 * @return {number} -1 if smaller, 1 if bigger, 0 equal
 * @private
 */
function _compareComponents(cOne, cTwo) {
    var a = cOne.getData ? cOne.getData() : cOne;
    var b = cTwo.getData ? cTwo.getData() : cTwo;

    var aWithID = a.topologicalIndex || 1;
    var bWithID = b.topologicalIndex || 1;

    if (aWithID === bWithID) {
        const symbolAtTheEndOfSortingOrder = "#";

        //If sort order matches
        aWithID =
            a.name.toLowerCase() + " " + symbolAtTheEndOfSortingOrder + a.id;
        bWithID =
            b.name.toLowerCase() + " " + symbolAtTheEndOfSortingOrder + b.id;
    }

    if (aWithID < bWithID) {
        return -1;
    } else if (aWithID > bWithID) {
        return 1;
    } else {
        return 0;
    }
}
