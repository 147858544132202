import angular from "angular";
import htmlTemplate from "./sb_filter_text.html";
import ViewFilter from "common/services/oDataService/odata_view_filter.class";

export default angular
    .module("sbApp.sbFilterText", [])
    .directive("sbFilterText", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "filterText",
            templateUrl: htmlTemplate,
            replace: true,
            //transclude: true,
            scope: {
                /**
                 * @typeof ViewFilter
                 */
                viewFilter: "=",

                // callback to notify the outside world
                onChange: "=",
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "filterText.viewFilter.value",
                    function (newValue, oldValue) {
                        // support clear viewFilter from outside -> newValue is undefined
                        if (newValue === undefined && newValue !== oldValue) {
                            controller.activate();
                        }
                    }
                );
            },
            controller: function FilterTextCtrl($element) {
                var vm = this;

                vm.tmp = {};

                vm.onCheckboxChange = onCheckboxChange;
                vm.clearInput = clearInput;
                vm.onInputChange = onInputChange;
                vm.activate = activate;
                vm.focusTextInput = focusTextInput;

                activate();

                ///////////
                //
                //  IMPL
                //
                ///////////

                function activate() {
                    if (vm.viewFilter.isNullCondition()) {
                        vm.tmp.value = "";
                        vm.tmp.isEmpty = true;
                    } else {
                        if (Array.isArray(vm.viewFilter.value)) {
                            vm.tmp.name = vm.viewFilter.value
                                .map((v) => v.trim())
                                .join(", ");
                        } else {
                            vm.tmp.value = "";
                        }
                        vm.tmp.isEmpty = false;
                    }
                }

                function onInputChange(value) {
                    if (angular.isString(value) && value.length > 0) {
                        vm.viewFilter.setValue(value);
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    } else {
                        clearInput();
                    }
                }

                function focusTextInput() {
                    $element.find("input")[0].focus();
                }

                function clearInput() {
                    if (vm.viewFilter.hasValue()) {
                        vm.tmp.value = "";
                        vm.viewFilter.clear();
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                        vm.focusTextInput();
                    }
                }

                function onCheckboxChange(isEmptyFlag, value) {
                    if (isEmptyFlag) {
                        vm.viewFilter.setValue(ViewFilter.NULL_CONDITION);
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    } else {
                        onInputChange(value);
                    }
                }
            },
        };
    });
