import htmlTemplate from "./activity_duration.html";

export default function registerComponentTo(module) {
    module.component("sbActivityDuration", {
        templateUrl: htmlTemplate,
        bindings: {
            parent: "<",
            activity: "<?",
        },
        controller: "ActivityCommonInputCtrl",
    });
}
