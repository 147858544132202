import "angular";

export default function FileBasedCtrl(
    moment,
    $sbErrorPresenter,
    $scope,
    exportService,
    downloadCenterService,
    $sbFileReader,
    $log,
    $mdDialog,
    $q,
    $state,
    $stateParams,
    exportSrcObject
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;
    // store the download to start it immediately. Later add the parsing to it.
    var fetchProgressPromise = undefined;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.allowedExtensions = [".xml"];
    vm.step = 1; // activate and deactivate view parts based on the step you are in.
    vm.updatedXml = ""; //Init empty string to be updated with updated XML
    vm.importedFileName = "";

    // store the update map in here.
    vm.updatedProgress = {};

    vm.onFileChanged = onFileChanged;
    vm.openFileChooser = openFileChooser;
    vm.onWrongExtension = onWrongExtension;

    activate();

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Activation method to initialize everything starting with
     * fetching the current project progress from the server
     */
    function activate() {
        fetchProgressPromise = fetchProgressMap(exportSrcObject);
    }
    /**
     * Callback method for file upload
     * @param {Event} event -   Event on which this callback method occurred
     * @param {File} file - Object of uploaded file
     * @param {String} name - Name of uploaded file
     */
    function onFileChanged(event, file, name) {
        vm.importedFileName = name;
        vm.step = 2;

        fetchProgressPromise
            .then(function () {
                return $q(function (resolve, reject) {
                    if (Object.keys(vm.updatedProgress).length === 0) {
                        reject();
                    } else {
                        parseAndProcessFile(file, name);
                        resolve();
                    }
                });
            })
            .catch(function () {
                vm.step = 1;
                $mdDialog.show(
                    $mdDialog
                        .alert()
                        .title("DIALOG_FETCH_PROGRESS_ERROR_TITLE")
                        .content("DIALOG_FETCH_PROGRESS_ERROR_CONTENT")
                );
            });
    }

    /**
     * Method to open file chooser broadcast the intent
     * @param {Event} $event - Event object (MouseEvent, KeyPress etc.)
     */
    function openFileChooser($event) {
        $scope.$broadcast("sbFileInput.open", $event);
    }

    /**
     * Callback method to cater the wrong extension of file.
     * @param {Object} error - Error object to be logged.
     */
    function onWrongExtension(error) {
        $log.error(error);
        $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_WRONG_EXTENSION_TITLE")
                .content("DIALOG_WRONG_EXTENSION_CONTENT")
        );
    }

    /**
     * Get the progress map from service
     * -> Returns key, value pairs of id and current progress for the currently selected project.
     */
    function fetchProgressMap({ id, type }) {
        return exportService
            .fetchProgressMap({ id, type })
            .then(function ({ response }) {
                vm.updatedProgress = response;
            })
            .catch($sbErrorPresenter.catch);
    }

    /**
     * Parse the given file and call the service to update values in the file
     * @param {File} file - Object of uploaded file
     * @param {String} name - Name of the uploaded file
     */
    function parseAndProcessFile(file) {
        //Read the file data using our fileReader service by passing it file object
        $sbFileReader
            .readFile(file, "readAsText")
            .then(function (fileData) {
                return $sbFileReader
                    .parseXml(fileData.rawData)
                    .then(function (data) {
                        //Update progress values
                        return exportService
                            .updateProgressValues(data, vm.updatedProgress)
                            .then(function (serviceResult) {
                                var downloadKey = downloadCenterService.store(
                                    serviceResult.updatedXmlFileString,
                                    "text/xml"
                                );
                                serviceResult.downloadKey = downloadKey;

                                return $state.go(
                                    "sablono.project.export.download",
                                    {
                                        downloadKey: downloadKey,
                                        exportStats: serviceResult.exportStats,
                                        fileName:
                                            "sablono_updated_" +
                                            moment().format("YYYY_M_D_H_m") +
                                            "_" +
                                            vm.importedFileName +
                                            ".xml",
                                    }
                                );
                            });
                    });
            })
            .catch(function (error) {
                vm.step = 1;
                $log.error(error);
                $mdDialog.show(
                    $mdDialog
                        .alert()
                        .title("DIALOG_READING_FILE_ERROR_TITLE")
                        .content("DIALOG_READING_FILE_ERROR_CONTENT")
                );
            });
    }
}
