import angular from "angular";
import moment from "moment";
import dialogTemplate from "./assign_structure.tmpl.html";

export default angular
    .module("sbApp.sbAssignStructureDialog", [])
    .config(function ($mdDialogProvider) {
        "ngInject";
        $mdDialogProvider.addPreset("assignStructure", {
            methods: [
                "title",
                "text",
                "ok",
                "cancel",
                "class",
                "ariaLabel",

                // custom attributes
                //
                "structureLevels",
                "placeholder",
            ],
            options: function selectDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: AssignStructureDialogCtrl,
                    controllerAs: "$ctrl",
                    bindToController: true,
                };

                function AssignStructureDialogCtrl($mdDialog) {
                    "ngInject";

                    var vm = this;

                    vm.closeDialog = closeDialog;
                    vm.resolve = resolve;
                    vm.onSelectionChange = onSelectionChange;

                    function onSelectionChange(item) {
                        vm.selectedItem = item;
                    }

                    function resolve(structure) {
                        $mdDialog.hide(structure);
                    }

                    function closeDialog() {
                        $mdDialog.cancel();
                    }
                } // end of ctrl
            },
        });
    });
