import CustomButton from "./CustomButton";

/**
 * Class for a Button with an Icon on it
 * @param {string} icon Icon that is displayed in the Button
 * @param {string} tooltip Tooltip of the Button
 * @constructs IconButton
 * @extends WBS.CustomButton
 * @memberof WBS
 */
function IconButton(icon, tooltip) {
    CustomButton.call(this);

    this.setBounds(0, 0, this.width, this.height);

    /**
     * Text for Tooltip
     * @type {string}
     */
    this.tooltipText = tooltip;

    this.createBackground();
    this.createIcon(icon);

    this.onMouseIn = this._onMouseIn.bind(this);
    this.onMouseOut = this._onMouseOut.bind(this);

    this.on("mouseIn", this.onMouseIn);
    this.on("mouseOut", this.onMouseOut);
}

IconButton.prototype = Object.create(CustomButton.prototype);

/**
 * Font of Icon Button
 * @default 'MaterialDesignIcons'
 * @type {string}
 */
IconButton.prototype.textFont = "Material Design Icons";

/**
 * Size of the Icon
 * @default '14px'
 * @type {string}
 */
IconButton.prototype.iconSize = "16px";

/**
 * Color of the Icon
 * @default '#E4E4E4'
 * @type {string}
 */
IconButton.prototype.iconColor = "#E4E4E4";

/**
 * Hover Color of Icon
 * @default "#007AFF"
 * @type {string}
 */
IconButton.prototype.iconColorHover = "#007AFF";

/**
 * Background Color of Button
 * @default "#fff"
 * @type {string}
 */
IconButton.prototype.backgroundColor = "#fff";

/**
 * Hover Background Color of Button
 * @default "#fff"
 * @type {string}
 */
IconButton.prototype.backgroundColorHover = "#fff";

/**
 * Border Color of the Button
 * @default '#E4E4E4'
 * @type {string}
 */
IconButton.prototype.borderColor = "#E4E4E4";

/**
 * Hover Border Color of the Button
 * @default '#E4E4E4'
 * @type {string}
 */
IconButton.prototype.borderColorHover = "#E4E4E4";

/**
 * Shadow size
 * @default 4
 * @type {number}
 */
IconButton.prototype.shadowSize = 4;

/**
 * Height of Button
 * @default 20
 * @type {number}
 */
IconButton.prototype.height = 20;

/**
 * Width of Button
 * @default 20
 * @type {number}
 */
IconButton.prototype.width = 20;

/**
 * Padding of Hit Area
 * @default 8
 * @type {number}
 */
IconButton.prototype.hitAreaPadding = 8;

/**
 * Creates the Icon
 * @param {string} icon - Icon to be displayed in Button
 * @returns {createjs.Text}
 */
IconButton.prototype.createIcon = function (icon) {
    var text = new createjs.Text(
        String.fromCodePoint(icon),
        this.iconSize + " " + this.textFont,
        this.iconColor
    );

    text.x = 0;
    text.y = 0;

    text.textAlign = "center";
    text.textBaseline = "middle";

    this.addChild(text);
    this.icon = text;
    return text;
};

/**
 * Updates the Icon to current Settings(Color, Size, Font)
 */
IconButton.prototype.refreshIcon = function () {
    this.icon.color = this.iconColor;
    this.icon.font = this.iconSize + " " + this.textFont;
};

/**
 * Creates the Background for the Icon
 */
IconButton.prototype.createBackground = function () {
    if (!this.background) {
        this.background = new createjs.Shape();
        this.addChild(this.background);
    }
    var shape = this.background;

    var height = this.height;
    var width = this.width;

    var g = shape.graphics.clear();

    shape.color = g.beginFill(this.backgroundColor).command;
    shape.strokeColor = g.beginStroke(this.borderColor).command;
    g.setStrokeStyle(4, "square").drawRoundRect(
        -this.width / 2,
        -this.height / 2,
        width,
        height,
        width / 2
    );

    this.hitArea = this.hitArea || new createjs.Shape();

    this.hitArea.graphics
        .clear()
        .beginFill("#000")
        .drawRect(
            -this.width / 2 - this.hitAreaPadding,
            -this.height / 2 - this.hitAreaPadding,
            this.width + this.hitAreaPadding * 2,
            this.height + this.hitAreaPadding * 2
        );

    //shape.shadow = new createjs.Shadow("rgba(0,0,0,0.3)", 0, 0, this.shadowSize);

    this.background = shape;
};

/**
 * Updates the Background to Current Settings
 */
IconButton.prototype.refreshBackground = function () {
    this.createBackground();
};

/**
 * Refreshs all pars of Button (Icon + Background)
 */
IconButton.prototype.refresh = function () {
    this.refreshIcon();
    this.refreshBackground();
};

/**
 * Handler for MouseIn
 * @private
 */
IconButton.prototype._onMouseIn = function () {
    this.icon.color = this.iconColorHover;
    this.background.color.style = this.backgroundColorHover;
    this.background.strokeColor.style = this.borderColorHover;
    if (this.stage) {
        this.stage.canvas.title = this.tooltipText;
    }
};

/**
 * Handler for MouseOut
 * @private
 */
IconButton.prototype._onMouseOut = function () {
    this.icon.color = this.iconColor;
    this.background.color.style = this.backgroundColor;
    this.background.strokeColor.style = this.borderColor;
    if (this.stage) {
        this.stage.canvas.title = "";
    }
};

/**
 * Setter for IconText
 * @param {string} icon - new Icon
 */
IconButton.prototype.setIcon = function (icon) {
    this.icon.text = icon;
};

/**
 * Getter for Icon Text
 * @returns {string}
 */
IconButton.prototype.getIcon = function () {
    return this.icon.text;
};

/**
 * Sets Corner Radius
 * @todo currently does nothings
 */
IconButton.prototype.setCornerRadius = function () {
    this.createBackground();
};

export default IconButton;
