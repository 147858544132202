import _ from "lodash";
import moment from "moment";

export default function DeliverableDetailsCtrl(
    $filter,
    $sbErrorPresenter,
    $sbDetailsOverlayData,
    $sbDeliverableJobsApi,
    $sbDeliverablesApi,
    $sbCurrentProject,
    $sbTeam,
    $sbColor,
    $log,
    $timeout,
    $sbPermission,
    DELIVERABLE_CONFIG,
    EVENTS,
    $mdDialog,
    $rootScope,
    $sbDeliverables,
    $sbStructure,
    $sbJobApi,
    Analytics,
    DATE_TYPES,
    $window,
    $mdToast,
    $sbTracking
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    const activityUpdateWatcher = $rootScope.$on(
        EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
        () => buildOverlayDataViewModel(vm.deliverable)
    );

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.data = undefined;
    vm.timezone = "";
    vm.editModeDisabled = true;

    // We save the progress so when changing the bar doesn't go from the previous % to zero and then to the next % but
    // directly from the previous % to the next %
    vm.savedProgress = undefined;

    vm.dataConfig = DELIVERABLE_CONFIG;

    vm.toggleEditMode = toggleEditMode;
    vm.inputChanged = onInputChange;
    vm.onStructureChange = onStructureChange;
    vm.openDateTimeDialog = openDateTimeDialog;
    vm.showDeliverableOpenIssues = showDeliverableOpenIssues;
    vm.showDeliverableScheduleIndicator = showDeliverableScheduleIndicator;
    vm.getTotalFloatDisplayText = getTotalFloatDisplayText;
    vm.getDeliverableTotalFloatTooltipKey = getDeliverableTotalFloatTooltipKey;
    vm.onOpenExternalAppLink = onOpenExternalAppLink;

    vm.$onChanges = $onChanges;
    vm.$onDestroy = () => activityUpdateWatcher();

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onChanges(change) {
        if (change.deliverable) {
            const currentDeliverable = _.get(
                change,
                "deliverable.currentValue"
            );
            const previousDeliverable = _.get(
                change,
                "deliverable.previousValue"
            );
            if (
                currentDeliverable &&
                currentDeliverable !== previousDeliverable
            ) {
                buildOverlayDataViewModel(change.deliverable.currentValue);
            }
        }
    }

    function buildOverlayDataViewModel(deliverable) {
        if (!_.get(deliverable, "id")) {
            vm.data = {};
            return;
        }

        vm.data = {
            ID: deliverable.id,
            NAME: deliverable.name,
            CODE: deliverable.code,
            DESC: deliverable.description || "-",
            STRUCTURE_ID: deliverable.structureId,
            PLANNED_START_DATE: deliverable.startDate,
            PLANNED_END_DATE: deliverable.endDate,
            TYPE_NAME: deliverable.typeName,
            SCHEDULE_STATE: {
                IS_BEHIND: deliverable.isBehind(),
                IS_ON_TIME: deliverable.isOnTime(),
            },
            PROGRESS: deliverable.getProgress(),
            PI_TEMPLATE_ID: deliverable.piTemplateId,
            OBSTRUCTION_QUANTITY: _.get(
                deliverable,
                "_noteStatistic.openObstructions",
                0
            ),
            CLAIM_QUANTITY: _.get(deliverable, "_noteStatistic.openClaims", 0),
            TOTAL_FLOAT: _.get(deliverable, "baselineSchedule.totalFloat"),
            EARLIEST_START: _.get(
                deliverable,
                "baselineSchedule.earliestStart",
                null
            ),
            EARLIEST_END: _.get(
                deliverable,
                "baselineSchedule.earliestEnd",
                null
            ),
            LATEST_START: _.get(
                deliverable,
                "baselineSchedule.latestStart",
                null
            ),
            LATEST_END: _.get(deliverable, "baselineSchedule.latestEnd", null),
            LATEST_INSPECTION_TIME: deliverable.getLatestStateChangeAt(
                deliverable.issueChangeTime
            ),
            MOBILE_LINK: `sbdaily://open?project_id=${$sbDetailsOverlayData.current.project.id}&deliverable_code=${vm.deliverable.code}`,
        };
        vm.data.SCHEDULE_STATE.IS_KNOWN =
            vm.data.SCHEDULE_STATE.IS_BEHIND ||
            vm.data.SCHEDULE_STATE.IS_ON_TIME;

        vm.data.PROGRESS_CLASSES = $sbColor.classes.fromStatus(
            vm.data.PROGRESS,
            vm.data.SCHEDULE_STATE.IS_KNOWN
                ? vm.data.SCHEDULE_STATE.IS_BEHIND
                : null,
            vm.data.CLAIM_QUANTITY,
            vm.data.OBSTRUCTION_QUANTITY
        );

        vm.editModeDisabled = true;
        vm.savedProgress = vm.data.PROGRESS;
        vm.timezone = $sbDetailsOverlayData.current.calendar.tz();

        return $sbStructure
            .getSortedMappedList($sbDetailsOverlayData.current.project.id)
            .then((structure) => (vm.projectStructure = structure))
            .then(_setStructure)
            .then(_setAreaManager)
            .then(_setPermissionFlag($sbDetailsOverlayData.current.project))
            .catch($sbErrorPresenter.catch);
    }

    function showDeliverableScheduleIndicator() {
        const isKnown = _.get(vm, "data.SCHEDULE_STATE.IS_KNOWN", false);
        const isAssigned = _.get(vm, "data.PI_TEMPLATE_ID", false);
        return isKnown || !isAssigned;
    }

    function showDeliverableOpenIssues() {
        return (
            vm.data && (vm.data.CLAIM_QUANTITY || vm.data.OBSTRUCTION_QUANTITY)
        );
    }

    function getDeliverableTotalFloatTooltipKey() {
        const totalFloatInDays = _getTotalFloatInDays();

        if (totalFloatInDays === 0) {
            return "TOTAL_FLOAT_TOOLTIP_IS_ZERO_DELIVERABLE";
        }
        if (totalFloatInDays > 0) {
            return "TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_DELIVERABLE";
        }
        if (totalFloatInDays < 0) {
            return "TOTAL_FLOAT_TOOLTIP_IS_NEGATIVE";
        }

        return "TOTAL_FLOAT_TOOLTIP_IS_NULL";
    }

    function getTotalFloatDisplayText() {
        if (_totalFloatIsNumber()) {
            return (
                _getTotalFloatInDays() +
                " " +
                $filter("translate")(_getTranslationForTotalFloat())
            );
        } else {
            return _getNoFloatDisplayText();
        }
    }

    function _getDeliverableTotalFloat() {
        if (vm.data) {
            return Number.parseInt(vm.data.TOTAL_FLOAT);
        }
    }

    function _totalFloatIsNumber() {
        return !isNaN(_getDeliverableTotalFloat());
    }

    function _getTranslationForTotalFloat() {
        return _getTotalFloatInDays() === 1 ? "_DAY" : "_DAYS";
    }

    function _getTotalFloatInDays() {
        if (_totalFloatIsNumber()) {
            const duration = moment.duration(_getDeliverableTotalFloat());
            return Math.round(duration.asDays());
        }
    }

    function _setAreaManager() {
        if (vm.deliverable.areaManagerUserName) {
            return $sbTeam
                .findUserByEmail(vm.deliverable.areaManagerUserName)
                .then(function (users) {
                    if (users.length) {
                        vm.areaManager = {
                            displayName: _.get(users[0], "name"),
                            userName: _.get(users[0], "id"),
                        };
                    } else {
                        vm.areaManager = undefined;
                    }
                })
                .catch(function (error) {
                    $log.warn(error);
                    vm.areaManager = undefined;
                });
        } else {
            vm.areaManager = undefined;
        }
    }

    function _setStructure() {
        return $sbStructure
            .node($sbCurrentProject.pick("id"), vm.data.STRUCTURE_ID)
            .then((structureNode) => {
                if (structureNode) {
                    vm.data.structure = structureNode;
                    vm.data.STRUCTURE_NAME =
                        structureNode.PARENT_PATH.join(" / ");
                }
            });
    }

    function _getNoFloatDisplayText() {
        return "-";
    }

    function _setPermissionFlag(project) {
        if (project) {
            vm.userIsAllowed = $sbPermission.hasPlanPermissions(
                project.privileges
            );
        } else {
            vm.userIsAllowed = false;
        }
    }

    function toggleEditMode() {
        if (vm.userIsAllowed) {
            if (vm.editModeDisabled) {
                Analytics.trackEvent(
                    "Details Overlay",
                    "edit",
                    "Activate edit mode"
                );
            }
            vm.editModeDisabled = !vm.editModeDisabled;
        }
    }

    function _showSavedIndication() {
        vm.isSaving = true;
        return $timeout(function () {
            vm.isSaving = false;
        }, 1500);
    }

    function onStructureChange(item) {
        if (item && !vm.editModeDisabled) {
            const structureId = item.id;
            const deliverableId = vm.data.ID;

            return $sbDeliverableJobsApi
                .createModifyPropertiesJob($sbCurrentProject.pick("id"), {
                    properties: {
                        structure_id: structureId,
                    },
                    deliverable_set: [deliverableId],
                })
                .then(({ response, jobId }) => {
                    if (jobId) {
                        // Wait until the job is done
                        return $sbJobApi.waitFor(jobId).then(() => response);
                    }
                    return response;
                })
                .then(function () {
                    Analytics.trackEvent(
                        "Details Overlay",
                        "edit",
                        "Change structure"
                    );
                    vm.deliverable.structureId = structureId;
                })
                .then(_showSavedIndication)
                .then(() => buildOverlayDataViewModel(vm.deliverable))
                .catch($sbErrorPresenter.catch);
        }
    }

    function onInputChange(fieldName) {
        Analytics.trackEvent("Details Overlay", "edit", "Change " + fieldName);
        return $sbDeliverablesApi
            .update($sbCurrentProject.pick("id"), vm.data.ID, {
                name: vm.data.NAME,
                description: vm.data.DESC,
                start_date: valueOfMoment(vm.data.PLANNED_START_DATE),
                end_date: valueOfMoment(vm.data.PLANNED_END_DATE),
            })
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            })
            .then((response) => {
                vm.deliverable.code = response.code;
                vm.deliverable.name = response.name;
                vm.deliverable.description = response.description || "-";
                if (response.start_date) {
                    vm.deliverable.start_date = moment(response.start_date);
                }
                if (response.end_date) {
                    vm.deliverable.end_date = moment(response.end_date);
                }
            });
    }

    function valueOfMoment(date) {
        if (moment.isMoment(date) && date.isValid()) {
            return date.toISOString();
        }
        return undefined;
    }

    function getPlannedDatesFromLocalData(data) {
        const startDate = data.PLANNED_START_DATE.isValid()
            ? data.PLANNED_START_DATE
            : null;
        const endDate = data.PLANNED_END_DATE.isValid()
            ? data.PLANNED_END_DATE
            : null;

        if (startDate !== null) {
            startDate.tz(vm.timezone);
        }

        if (endDate !== null) {
            endDate.tz(vm.timezone);
        }

        return {
            startDate: startDate,
            endDate: endDate,
            isKeepRangeEnabled: startDate !== null && endDate !== null,
        };
    }

    function showChangeDatetimeDialog({
        startDate,
        endDate,
        isKeepRangeEnabled,
    }) {
        return $mdDialog.show(
            $mdDialog
                .changeDatetime()
                .startDate(startDate)
                .endDate(endDate)
                .isKeepRangeEnabled(isKeepRangeEnabled)
                .dateSelectionType(DATE_TYPES.START_AND_DUE_DATE)
                .unsetLabel(
                    "DIALOG_CHANGE_DATES_DERIVE_FROM_SCHEDULE_HINT_TEXT"
                )
                .conflictWarningFlag(false)
        );
    }

    function openDateTimeDialog() {
        Analytics.trackEvent("Details Overlay", "edit", "Change Dates");

        const { startDate, endDate, isKeepRangeEnabled } =
            getPlannedDatesFromLocalData(vm.data);

        return showChangeDatetimeDialog({
            startDate,
            endDate,
            isKeepRangeEnabled,
        })
            .then(({ SD, CD }) => {
                vm.data.PLANNED_START_DATE = SD;
                vm.data.PLANNED_END_DATE = CD;
            })
            .then(onInputChange)
            .then(function () {
                Analytics.trackEvent(
                    "Scheduling",
                    "Deliverable dates",
                    "Automatically calculate"
                );
            })
            .then(function () {
                $sbDeliverables.emitChangeEvent(
                    $sbDeliverables.DELIVERABLE_CHANGE_TYPES.BASELINE,
                    vm.data
                );
            })
            .catch((err) => {
                if (err) {
                    $sbErrorPresenter.catch(err);
                }
            });
    }

    function onOpenExternalAppLink($event) {
        $event.preventDefault();

        $window.location.href = vm.data.MOBILE_LINK;

        $mdToast.show(
            $mdToast
                .simple()
                .content("ERROR_OPEN_DEEP_LINK_TO_NATIVE_APP_MESSAGE")
                .hideDelay(2000)
                .position("top right")
        );

        $sbTracking.detailsOverlay.mobileAppLink.clicked();
    }
}
