import createjs from "createjs";

/**
 * Event that is dispatched when mouse enters something
 * @constructs MouseInEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function MouseInEvent() {
    createjs.Event.call(this, "mouseIn", true, true);
}

MouseInEvent.prototype = Object.create(createjs.Event.prototype);

export default MouseInEvent;
