export default class ReportingNotesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the notes in the project which is enhanced by project entities.
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}            Query parameter
     * @param query.filter {string}         An odata filter string.
     * @param query.select {string}         An odata select string.
     * @param query.orderby {string}        An odata order by string.
     * @param query.top {number}            An odata top specification limiting the amount of records returned.
     * @param query.skip {number}           An odata skip specification used to paginate the returned records.
     */
    getCollection(projectId, { filter, select, orderby, top, skip }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/notes`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                filter,
                select,
                orderby,
                top,
                skip,
            },
        });
    }

    /**
     * This endpoint returns a PDF representation of the notes in the project (optionally containing images).
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}            Query parameter
     * @param query.filter {string}         An odata filter string.
     * @param query.orderby {string}        An odata order by string.
     * @param query.language {string}         A nested property that will be expand per entity
     * @param query.i18n {string[]}         A key value pair separated by ':' where the value is replacing the key when processing the request.
     */
    printCollection(projectId, { filter, orderby, language, i18n }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/notes`;

        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                filter,
                orderby,
                i18n,
            },
            header: {
                "Accept-Language": language,
                Accept: "application/pdf",
            },
        });
    }

    /**
     * This endpoint returns CSV representation of the notes in the project which is enhanced by project entities.
     *
     * @param projectId {string}    UUID
     *
     * @param query {Object}            Query parameter
     * @param query.i18n {string}       A key value pair separated by ':' where the value is replacing the key when processing the request.
     */
    exportCollection(projectId, { i18n }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects/${projectId}/notes`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "GET",
            query: {
                i18n,
            },
            header: {
                Accept: "text/csv",
            },
        });
    }
}
