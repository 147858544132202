import KeyboardHandler from "../editor/KeyboardHandler";
/**
 * Defines the Keyboard Settings for the WBSEditor
 *
 * @memberof WBS
 * @constructs WbsKeyboardHandler
 * @extends KeyboardHandler
 */
function WbsKeyboardHandler() {
    KeyboardHandler.apply(this, arguments);

    this.oKeyCodes = {
        110: "delete",
        46: "delete", // entf
        45: "addChild", // einfg
        13: "addSibling", //enter
        113: "rename",
        27: "cancel", //esc
    };

    if (navigator.appVersion.indexOf("Mac") !== -1) {
        //add some osx keyboard events
        //
        this.oKeyCodes["8"] = "delete"; //backspace
        this.oKeyCodes["9"] = "addChild"; //tab
    }
}

WbsKeyboardHandler.prototype = Object.create(KeyboardHandler.prototype);

export default WbsKeyboardHandler;
