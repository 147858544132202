import angular from "angular";
import _ from "lodash";
import moment from "moment";

export default function (
    $rootScope,
    $q,
    $log,
    $filter,
    SbTeam,
    $sbImageApi,
    $sbMembershipsApi,
    $sbTeamsApi,
    $sbUsersApi
) {
    "ngInject";
    /**
     *
     * @type {{String: {users: Promise<[Object]>, requestDate: moment}}}
     * Object looks like this>
     * {
     *    "projectID": {
     *        users: new Promise(),
     *        requestDate: moment(),
     *    }
     * }
     */

    var usersInProjectCache = {};
    var teamsInProjectCache = new Map();

    var userInProjectCacheExpirationDuration = moment.duration(10, "minutes");

    // clear the cache after a logout
    $rootScope.$on("sb.global.logout", function () {
        usersInProjectCache = {};
        clearTeamsCache();
    });

    var _naturalSort = $filter("naturalSort");

    var service = {
        // const
        UNRESTRICTED_I18N_KEY: SbTeam.UNRESTRICTED_I18N_KEY,
        PROJECT_TEAM_I18N_KEY: SbTeam.PROJECT_TEAM_I18N_KEY,

        // teams
        getTeams: getTeams,
        asMap: asMap,
        collectCurrentUserAccessibleTeams: collectCurrentUserAccessibleTeams,
        findProjectTeam: findProjectTeam,
        findTeamById: findTeamById,
        createTeam: createTeam,
        updateTeam: updateTeam,
        deleteTeam: deleteTeam,
        createUnrestrictedTeam: SbTeam.createUnrestrictedTeam,
        clearTeamsCache: clearTeamsCache,

        // user
        getUsers: getUsers,
        findUserByEmail: findUserByEmail,
        getUsersByProject: getUsersByProject,

        getUsersByProjectAndNameOrEmail: getUsersByProjectAndNameOrEmail,
        requestAndCacheUsersForProjectIfNeeded:
            requestAndCacheUsersForProjectIfNeeded,
        clearUserCacheForAllProjects: clearUserCacheForAllProjects,

        // team member
        getMemberships: getMemberships,
        insertUser: insertUser,
        deleteUser: deleteUser,
        changeMembership: changeMembership,

        // utils
        getTeamDisplayName: getTeamDisplayName,
        sortTeams: sortTeams,
        fetchLogo,
    };

    return service;

    function fetchLogo(projectId, imageId) {
        if (!Number.isInteger(imageId)) {
            return $q.reject(new Error("MissingRequiredParameter"));
        }

        return $sbImageApi.download(projectId, imageId, {
            transformResponse: [$sbImageApi.response.transformToDataURL],
        });
    }

    function getTeamDisplayName(name, isProjectTeam) {
        var isValidName = angular.isString(name);
        if (isValidName || isProjectTeam) {
            return isProjectTeam ? SbTeam.PROJECT_TEAM_I18N_KEY : name;
        } else {
            return SbTeam.UNRESTRICTED_I18N_KEY;
        }
    }

    function getMemberships(projectId) {
        return $sbMembershipsApi
            .getCollection(projectId)
            .then(({ memberships }) => {
                return memberships
                    .filter(({ removed_at }) => !removed_at)
                    .map((membership) => {
                        const member = {
                            initials: membership.user.initials,
                            username: membership.user.id,
                            mail: membership.user.email,
                            displayName: membership.user.name,
                            role: membership.role.name.toUpperCase(),
                            roleId: membership.role.id,
                            teamId: membership.team.id,
                            commercialAccess: membership.commercial_access.map(
                                (access) => ({
                                    onTeam: access.on_team,
                                    privilege: access.privilege,
                                })
                            ),

                            // replacement arrangement properties
                            hasAreaManagerPrivilege:
                                membership.team.has_area_restrictions,
                        };

                        if (membership.substitute) {
                            member.selectedReplacement = {
                                initials: membership.substitute.user.initials,
                                username: membership.substitute.user.id,
                                mail: membership.substitute.user.email,
                                displayName: membership.substitute.user.name,
                            };
                            member.isReplacementActive =
                                membership.substitute.is_active;
                        }

                        return member;
                    });
            });
    }

    function getTeams(projectId) {
        if (teamsInProjectCache.has(projectId)) {
            return Promise.resolve(teamsInProjectCache.get(projectId));
        }
        return $sbTeamsApi
            .getCollection(projectId)
            .then(({ teams }) =>
                teams.map((team) =>
                    _createDomainTeamFromResponse(team, projectId)
                )
            )
            .then((teams) => sortTeams(teams))
            .then((teams) => {
                teamsInProjectCache.set(projectId, teams);
                return teams;
            });
    }

    function asMap(projectId) {
        return getTeams(projectId).then(
            (list) => new Map(list.map((item) => [item.id, item]))
        );
    }

    function clearTeamsCache(projectId) {
        if (projectId) {
            teamsInProjectCache.delete(projectId);
        } else {
            teamsInProjectCache = new Map();
        }
    }

    function collectCurrentUserAccessibleTeams(teams, currentUserTeamId) {
        var projectTeam = findProjectTeam(teams);
        var isFullAccessUser = projectTeam.id === currentUserTeamId;
        teams = _.filter(teams, function teamsFilter(team) {
            return (
                team.id === currentUserTeamId ||
                isFullAccessUser ||
                team.isProjectTeam
            );
        });

        return teams;
    }

    function findProjectTeam(teams) {
        return _.find(teams, function (team) {
            return team.isProjectTeam;
        });
    }

    function findTeamById(teams, teamId) {
        return _.find(teams, {
            id: teamId,
        });
    }

    /**
     * Store a new team based on an instance of SbTeam
     *
     * @param {SbTeam} team
     * @return {PromiseLike<SbTeam> | Promise<SbTeam>}
     */
    function createTeam(team) {
        clearTeamsCache(team.projectId);
        return $sbTeamsApi
            .create(team.projectId, _createRequestPayloadFromSbTeam(team))
            .then(function (newTeam) {
                return _createDomainTeamFromResponse(newTeam, team.projectId);
            });
    }

    /**
     * Update the db store entry of a given team (instance of SbTeam)
     *
     * @param {SbTeam} team
     * @return {PromiseLike<SbTeam> | Promise<SbTeam>}
     */
    function updateTeam(team) {
        clearTeamsCache(team.projectId);
        return $sbTeamsApi
            .update(
                team.projectId,
                team.id,
                _createRequestPayloadFromSbTeam(team)
            )
            .then(function () {
                return team;
            });
    }

    function deleteTeam(team) {
        if (
            angular.isObject(team) &&
            !Object.prototype.hasOwnProperty.call(team, "id")
        ) {
            $log.error("Missing property 'id' on", team);
            return $q.reject("Missing property 'id' on", team);
        }

        // not allowed
        //
        if (team.isProjectTeam) {
            $log.error("Project team can not be deleted.");
            return $q.reject("Project team can not be deleted.");
        }

        clearTeamsCache(team.projectId);
        return $sbTeamsApi.delete(team.projectId, team.id);
    }

    function _createRequestPayloadFromSbTeam(sbTeam) {
        return {
            name: sbTeam.name,
            color: sbTeam.color,
            logo: {
                id: sbTeam.logoId,
            },
            address: {
                name: sbTeam.company,
                city: _.get(sbTeam, "address.city") || undefined,
                post_code: _.get(sbTeam, "address.postCode") || undefined,
                country: _.get(sbTeam, "address.country") || undefined,
                street: _.get(sbTeam, "address.street") || undefined,
            },
            contact: {
                email: _.get(sbTeam, "contact.email") || undefined,
                phone: _.get(sbTeam, "contact.phone") || undefined,
                name: _.get(sbTeam, "contact.responsible") || undefined,
            },
        };
    }

    function _createDomainTeamFromResponse(teamResponse, projectId) {
        const team = new SbTeam(teamResponse.id, teamResponse.name);
        team.setColor(teamResponse.color);
        team.setIsProjectTeam(teamResponse.is_project_team);
        team.projectId = projectId;
        team.logoId = _.get(teamResponse, "logo.id");
        team.logoUrl = _.get(teamResponse, "logo.url");
        team.company = _.get(teamResponse, "address.name");
        team.address = {
            city: _.get(teamResponse, "address.city"),
            postCode: _.get(teamResponse, "address.post_code"),
            country: _.get(teamResponse, "address.country"),
            street: _.get(teamResponse, "address.street"),
        };
        team.contact = {
            email: _.get(teamResponse, "contact.email"),
            phone: _.get(teamResponse, "contact.phone"),
            responsible: _.get(teamResponse, "contact.name"),
        };
        return team;
    }

    /**
     * Delete a user
     * @param  {Membership} membership - The that should be removed
     * @return {Promise}
     */
    function deleteUser(membership) {
        return $sbMembershipsApi.delete(
            membership.team.projectId,
            membership.user.id
        );
    }

    /**
     * Update user role
     * @param  {Membership} membership  - The that should be removed
     * @param  {String} role            - The new role (enum) of the user in the project
     * @param  {number} [teamId]        - The new team id (optional)
     * @param  {Object} [substitute]    - The new team id (optional)
     * @return {Promise}
     */
    function changeMembership(membership, { role, teamId, substitute }) {
        return $sbMembershipsApi.update(
            membership.team.projectId,
            membership.user.id,
            {
                role_name: role,
                team_id: teamId,
                substitute: substitute,
            }
        );
    }

    /**
     * Get all the users that have at least on project shared with me
     *
     * @param  {String} projectId
     *
     * @return {Promise}
     */
    function getUsers(projectId) {
        if (!projectId) {
            return $q.reject(
                new Error("Missing required parameter: 'projectId'")
            );
        }
        return service.getUsersByProject(projectId);
    }

    /**
     * find all uses in the project
     *
     * @param  {String} projectId
     * @return {Promise}
     */
    function getUsersByProject(projectId) {
        return $sbMembershipsApi
            .getCollection(projectId)
            .then(({ memberships }) =>
                memberships.map(_createMemberFromServer)
            );
    }

    /**
     * Try the get a user that I don't know yet
     *
     * @param  {String} email - Email of the user I'm trying to search
     * @return {Promise}
     */
    function findUserByEmail(email) {
        if (!email) {
            return $q.resolve([]);
        }
        return $sbUsersApi.getCollection(email).then(({ users }) => users);
    }

    /**
     * Get all the users that have at least on project shared with me and have the queryText in the name or email
     *
     * @param  {String} projectId
     * @param {String} queryText
     * @param {Boolean} [invalidateCache] - forces a new request to fetch all users from the server
     *
     * @return {promise}
     */
    function getUsersByProjectAndNameOrEmail(
        projectId,
        queryText,
        invalidateCache
    ) {
        var users = requestAndCacheUsersForProjectIfNeeded(
            projectId,
            invalidateCache
        );
        return users.then(_filterUsersByNameOrEmail.bind(null, queryText));
    }

    /**
     * Get all the users that have at least on project shared with me and caches it
     *
     * @param  {String} projectId
     * @param {Boolean} [invalidateCache] - forces a new request to fetch all users from the server
     *
     * @return {Promise}
     */
    function requestAndCacheUsersForProjectIfNeeded(
        projectId,
        invalidateCache
    ) {
        var users;
        if (!invalidateCache) {
            if (angular.isDefined(usersInProjectCache[projectId])) {
                var cache = usersInProjectCache[projectId];
                // check if the cache has not already expired
                if (
                    !cache.requestDate
                        .clone()
                        .add(userInProjectCacheExpirationDuration)
                        .isBefore(moment())
                ) {
                    users = cache.users;
                }
            }
        }
        //check if we already have a request in the cache
        if (
            !angular.isDefined(users) ||
            // if the status is 2, the promise was rejected, we should fetch the users again
            users.$$state.status === 2
        ) {
            users = getUsersByProject(projectId);
            usersInProjectCache[projectId] = {
                users: users,
                requestDate: moment(),
            };
        }

        return users;
    }

    function clearUserCacheForAllProjects() {
        usersInProjectCache = {};
    }

    function _filterUsersByNameOrEmail(queryText, users) {
        queryText = _.upperCase(queryText);
        return _.filter(users, function searchProperties(user) {
            return Object.keys(user).some(function (key) {
                return _.includes(_.upperCase(user[key]), queryText);
            });
        });
    }

    /**
     * Insert user
     * @param  {String} username    - The username of the user (e.g. MC)
     * @param  {int} teamId         - The id of the team in which the user should be inserted
     * @param  {String} roleName    - The name of the role that user should get
     * @param  {String} projectId   - The id of the project
     * @return {Promise}
     */
    function insertUser(username, teamId, roleName, projectId) {
        return $sbMembershipsApi.create(projectId, {
            user_id: username,
            team_id: teamId,
            role_name: roleName,
        });
    }

    function _createMemberFromServer({ role, team, user, removed_at }) {
        return {
            initials: user.initials,
            username: user.id,
            mail: user.email,
            displayName: user.name,
            role: role.name,
            roleId: role.id,
            teamId: team.id,
            removedAt: removed_at,
        };
    }

    function sortTeams(teams) {
        var _fullAccessTeam = _.remove(teams, function (team) {
            return team.name === "PROJECT_TEAM";
        }).pop();
        teams = _naturalSort(teams, "name");
        teams.unshift(_fullAccessTeam);

        return teams;
    }
}
