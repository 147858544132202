import angular from "angular";
import htmlTemplate from "./sb_file_card.html";
import FileCardController from "./../sbFileInput/sb_file_input.controller";
import "./../sbFileInput/sbOnChange/sbOnChange";

export default angular
    .module("sbApp.sbFileCard", ["sbApp.sbOnChange"])
    .directive("sbFileCard", function FileCardDirective() {
        return {
            restrict: "E",
            transclude: true,
            templateUrl: htmlTemplate,
            scope: {
                allowedExtensions: "=",
                sbFileChanged: "&",
                sbWrongExtension: "&",
                options: "=?",
            },
            controller: FileCardController,
            bindToController: true,
            controllerAs: "fileCtrl",
        };
    });
