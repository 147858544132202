import angular from "angular";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import pck from "../../../../../../package.json";

import AuthenticationError from "./../../../../common/errors/AuthenticationError";

export default function requestProvider() {
    //TODO: add config options

    this.$get = function $get(
        Analytics,
        $q,
        $log,
        $window,
        $sbErrorHandler,
        $sbRequestSentry,
        $sbAuth,
        $sbStorage
    ) {
        "ngInject";
        /**
         * Used to transform XMLHttpRequest
         *
         * @callback requestTransform
         * @param {XMLHttpRequest} request
         * @param {Object} request.option
         * @returns {$q} promise
         */

        /**
         *
         * @param {Object} options Options for Request
         * @param {String} options.url - HTTP URL Template
         * @param {String} [options.method] - HTTP Method (GET, POST, etc.) case insensitive
         * @param {String} [options.body] - HTTP Body
         * @param {Object} [options.header] - HTTP Header
         * @param {Object} [options.query] - HTTP URL Query Params
         * @param {String} [options.responseType] - XMLHttpRequest response type (text, arrayBuffer, blob, document, etc.)
         * @param {Boolean} [options.debugLog] - debug log
         * @param {Boolean} options.isAbortable - determines if it is ok to call .abort() on the request (e.g. on route change from dashboard)
         * @param {requestTransform[]} [options.transformRequest] - called before request send
         * @param {requestTransform[]} [options.transformResponse] - called after request loaded
         * @returns {$q} Promise
         */
        function request(options) {
            options.url = options.url || "";
            options.method = (options.method || "GET").toUpperCase();
            options.body = options.body || null;
            options.header = options.header || {};
            options.query = options.query || {};
            options.timeout = options.timeout || 0;
            options.isAbortable = options.isAbortable || false;

            var responseType = options.responseType;
            var timeout = options.timeout;
            var debugLog = !!options.debugLog;

            var transformRequest = (options.transformRequest || []).concat([
                request.transform.queryToUrl,
                request.transform.setAuthentication,
                request.transform.setSblnHeaders,
            ]);

            var transformResponse = options.transformResponse || [];

            var xhrRequest = new XMLHttpRequest();

            return $q(function (resolve, reject, progress) {
                var currentRequest = xhrRequest;
                currentRequest.options = options;

                currentRequest.addEventListener(
                    "readystatechange",
                    function () {
                        let jobId = undefined;

                        if (
                            currentRequest
                                .getAllResponseHeaders()
                                .indexOf("x-job-id") >= 0
                        ) {
                            jobId =
                                currentRequest.getResponseHeader("x-job-id");
                        }

                        if (jobId) {
                            //TODO migrated to angular as addJobToStorage. Refactor this
                            // Add to local storage
                            let jobs =
                                $sbStorage.get($sbStorage.ASYNC_JOB_KEY) || [];
                            if (!_.find(jobs, (job) => job === jobId)) {
                                // Push new job
                                jobs.push(jobId);

                                // Add to local storage
                                return $sbStorage.store(
                                    $sbStorage.ASYNC_JOB_KEY,
                                    JSON.stringify(jobs)
                                );
                            }
                        }
                    },
                    false
                );

                if (
                    debugLog ||
                    ($window.sessionStorage && $window.sessionStorage.debug)
                ) {
                    currentRequest.addEventListener(
                        "readystatechange",
                        function () {
                            $log.debug(
                                "XMLHttpRequest readystatechange",
                                currentRequest.getAllResponseHeaders()
                            );
                        },
                        false
                    );
                    currentRequest.addEventListener(
                        "loadstart",
                        $log.debug.bind(
                            $log,
                            "XMLHttpRequest loadstart",
                            currentRequest
                        ),
                        false
                    );
                    currentRequest.addEventListener(
                        "progress",
                        $log.debug.bind(
                            $log,
                            "XMLHttpRequest progress",
                            currentRequest
                        ),
                        false
                    );
                    currentRequest.addEventListener(
                        "load",
                        $log.debug.bind(
                            $log,
                            "XMLHttpRequest load",
                            currentRequest
                        ),
                        false
                    );
                    currentRequest.addEventListener(
                        "loadend",
                        $log.debug.bind(
                            $log,
                            "XMLHttpRequest loadend",
                            currentRequest
                        ),
                        false
                    );
                    currentRequest.addEventListener(
                        "error",
                        $log.debug.bind(
                            $log,
                            "XMLHttpRequest error",
                            currentRequest
                        ),
                        false
                    );
                    currentRequest.addEventListener(
                        "abort",
                        $log.debug.bind(
                            $log,
                            "XMLHttpRequest abort",
                            currentRequest
                        ),
                        false
                    );
                    currentRequest.addEventListener(
                        "timeout",
                        $log.debug.bind(
                            $log,
                            "XMLHttpRequest timeout",
                            currentRequest
                        ),
                        false
                    );
                }

                currentRequest.addEventListener("progress", progress, false);

                currentRequest.addEventListener(
                    "load",
                    function () {
                        var status = Number.parseInt(currentRequest.status);
                        if (status < 200 || status >= 300) {
                            reject(currentRequest);
                            return;
                        }
                        _trackTiming(currentRequest);
                        transformResponse
                            .reduce(function (promise, transform) {
                                return promise.then(transform);
                            }, $q.resolve(currentRequest))
                            .then(resolve, reject);
                    },
                    false
                );

                currentRequest.addEventListener("error", reject, false);
                currentRequest.addEventListener("abort", reject, false);
                currentRequest.addEventListener("timeout", reject, false);

                transformRequest
                    .reduce(function (promise, transform) {
                        return promise.then(transform);
                    }, $q.resolve(currentRequest))
                    .then(function () {
                        currentRequest.open(
                            currentRequest.options.method,
                            currentRequest.options.url,
                            true
                        );

                        currentRequest.responseType = responseType || "";
                        currentRequest.timeout = timeout;

                        var header = currentRequest.options.header;
                        Object.keys(header).forEach(function (headerKey) {
                            var headerValue = header[headerKey];
                            currentRequest.setRequestHeader(
                                headerKey,
                                headerValue
                            );
                        });

                        currentRequest._requestStartTime = new Date().getTime();

                        if (currentRequest.options.isAbortable) {
                            $sbRequestSentry.addAbortable(currentRequest);
                        }

                        currentRequest.send(currentRequest.options.body);
                    })
                    .catch(reject);
            }).catch(function (error) {
                return $sbErrorHandler.rethrowAsInterpretableDomainError(
                    error,
                    xhrRequest
                );
            });
        }

        function _trackTiming(req) {
            try {
                var timeSpent = new Date().getTime() - req._requestStartTime;
                var urlPathname = req.options.url.split("?")[0];

                if (urlPathname) {
                    $log.debug(
                        timeSpent + "ms \t",
                        req.options.method + "\t",
                        urlPathname
                    );
                }
            } catch (e) {
                $log.error("$sbRequest time tracking failed due to:", e);
            }
        }

        /**
         *
         * @param {Object} options Options for Request
         * @param {string} options.url - HTTP URL Template
         * @param {string} [options.method] - HTTP Method (GET, POST, etc.) case insensitive
         * @param {Object} [options.body] - HTTP Body - Content of the Request
         * @param {Object} [options.header] - HTTP Header
         * @param {Object} [options.query] - HTTP URL Query Params
         * @param {string} [options.responseType] - XMLHttpRequest response type (text, arrayBuffer, blob, document, etc.)
         * @param {boolean} [options.debugLog] - debug log
         * @param {boolean} [options.rawDataBody] - sends body a as raw data
         * @param {boolean} [options.formUrlencoded] -
         * @param {requestTransform[]} [options.transformRequest] - called before request send
         * @param {requestTransform[]} [options.transformResponse] - called after request loaded
         * @returns {$q} Promise
         */
        request.json = function (options) {
            options.header = options.header || {};
            if (!options.header.Accept) {
                options.header.Accept = "application/json";
            }

            if (options.rawDataBody) {
                options.transformRequest = options.transformRequest || [];
            } else if (options.formUrlencoded) {
                options.transformRequest = (
                    options.transformRequest || []
                ).concat([request.transform.formUrlencoded]);
            } else {
                options.transformRequest = (
                    options.transformRequest || []
                ).concat([request.transform.stringifyJSON]);
            }

            options.transformResponse = [request.transform.parseJSON].concat(
                options.transformResponse || []
            );

            return request(options);
        };

        request.pdf = function (options = {}) {
            options.header = Object.assign({}, options.header, {
                Accept: "application/pdf",
                "Content-Type": "application/json",
            });

            // in case a job needs some data in the body
            //
            options.transformRequest = (options.transformRequest || []).concat([
                request.transform.stringifyJSON,
            ]);

            return request.binary(options);
        };

        request.binary = function (options = {}) {
            options.responseType = "blob";
            return request(options);
        };

        request.image = function (url, options = {}) {
            options.url = url;
            options.method = "GET";
            options.header = Object.assign({}, options.header);
            options.responseType = "blob";
            options.transformRequest = options.transformRequest || [];
            options.transformResponse = options.transformResponse || [];
            return request(options);
        };

        request.multiPart = function (url, formData, options = {}) {
            options.url = url;
            options.method = options.method || "POST";
            options.header = Object.assign({}, options.header);
            options.transformRequest = options.transformRequest || [];
            options.transformResponse = options.transformResponse || [];
            options.body = formData;
            return request(options);
        };

        request.transform = {};

        request.transform.queryToUrl = function (currentRequest) {
            return $q(function (resolve) {
                var query = currentRequest.options.query;
                var keys = Object.keys(query);

                if (keys.length > 0) {
                    const queryUrl = keys
                        .filter((key) => query[key] !== undefined)
                        .map((key) => {
                            const value = query[key];
                            if (Array.isArray(value)) {
                                return value
                                    .map((v) => {
                                        return (
                                            key +
                                            "=" +
                                            fixedEncodeURIComponent(v)
                                        );
                                    })
                                    .join("&");
                            }
                            return key + "=" + fixedEncodeURIComponent(value);
                        })
                        .join("&");
                    currentRequest.options.url += "?" + queryUrl;
                }
                resolve(currentRequest);
            });
        };

        /**
         * encodeURIComponent does not escape the following characters: A-Z a-z 0-9 - _ . ! ~ * ' ( )
         *
         * To be strictly adhering to http://tools.ietf.org/html/rfc3986 which is used in OpenAPI 3
         * we need to escape the remaining characters as well.

         * @param str {string}
         * @returns {string}
         */
        function fixedEncodeURIComponent(str) {
            return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
                return "%" + c.charCodeAt(0).toString(16);
            });
        }

        request.transform.parseJSON = function (currentRequest) {
            try {
                const responseData = angular.fromJson(
                    currentRequest.responseText || '""'
                );
                return $q.resolve(responseData);
            } catch (e) {
                return $q.reject(new Error(e));
            }
        };

        request.transform.stringifyJSON = function (currentRequest) {
            return $q(function (resolve) {
                if (currentRequest.method !== "GET") {
                    if (currentRequest.options.body instanceof FormData) {
                        if (!currentRequest.options.header["Content-Type"]) {
                            currentRequest.options.header["Content-Type"] =
                                "application/x-www-form-urlencoded; charset=UTF-8";
                        }
                    } else {
                        currentRequest.options.body = currentRequest.options
                            .body
                            ? angular.toJson(currentRequest.options.body)
                            : undefined;
                        if (!currentRequest.options.header["Content-Type"]) {
                            currentRequest.options.header["Content-Type"] =
                                "application/json;charset=UTF-8";
                        }
                    }
                }

                resolve(currentRequest);
            });
        };

        request.transform.setAuthentication = function (currentRequest) {
            return $sbAuth
                .currentSession()
                .then(function (session) {
                    if (session) {
                        currentRequest.options.header.Authentication =
                            session.idToken.jwtToken;
                        currentRequest.options.header.Authorization =
                            session.accessToken.jwtToken;
                    }
                    return currentRequest;
                })
                .catch(function (err) {
                    throw new AuthenticationError();
                });
        };

        request.transform.setSblnHeaders = function (currentRequest) {
            currentRequest.options.header["X-Sbln-Request-Id"] = uuidv4();
            currentRequest.options.header["X-Sbln-User-Agent"] =
                `${pck.name}/${pck.version} (HOST:${window.location.hostname};)`;
            return $q.resolve(currentRequest);
        };

        request.transform.formUrlencoded = function (currentRequest) {
            return $q(function (resolve) {
                if (currentRequest.method !== "GET") {
                    if (!currentRequest.options.header["Content-Type"]) {
                        currentRequest.options.header["Content-Type"] =
                            "application/x-www-form-urlencoded; charset=UTF-8";
                    }
                    var body = currentRequest.options.body;
                    var str = [];
                    for (var key in body) {
                        str.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(
                                body[key]
                            )}`
                        );
                    }
                    currentRequest.options.body = str.join("&");
                }
                resolve(currentRequest);
            });
        };

        return request;
    };
}
