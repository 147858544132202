import angular from "angular";
import CreateChecklistTemplateCtrl from "./create_checklist_template_dialog.controller";
import dialogTemplate from "./create_checklist_template_dialog.html";

export default function CreateChecklistTemplateConfig($mdDialogProvider) {
    "ngInject";

    $mdDialogProvider.addPreset("createChecklistTemplate", {
        options: function () {
            return {
                controller: CreateChecklistTemplateCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
