/**
 * If you use the deliverable-jobs service
 * a POST request will give you a resulting object like:
 *
 * {
 *      "projectId"     : "ioparfja-dx32eda-3d3d-3d3d3",
 *      "sourceKey"     : 5983405,
 *      "deliverables" : [
 *          {
 *              "id" : "adsflf-4rf-rt5tfaw4t",
 *              "code" : "ASD 03.1",
 *              "ext" : "01.01.02.03",
 *              "name" : "hey there",
 *              "change" : {
 *                  "operation" : "CREATED"
 *              }
 *          }
 *      ]
 *      "structure" : [
 *          {
 *              "id" : "kc4üa-3dac9efp-3addc",
 *              "code" : "ASD",
 *              "ext" : "01.01",
 *              "name" : "Im a structure",
 *              "change" : {
 *                  "operation" : "UPDATED"
 *              }
 *          }
 *      ]
 * }
 *
 *
 * This DAO is more about documentation than functionality. But feel free to add functionality :D
 *
 */
import _ from "lodash";

/**
 * @name AtomicChange
 * @type Object
 * @property {string}        key     The affected property
 * @property {string|number} from    The value before change
 * @property {string|number} to      The value after change
 */

/**
 * @name ChangeEntry
 * @type Object
 * @property {string} operation     The type of change (CREATED;UPDATED;DELETED)
 * @property {array<AtomicChange>} list     A list of atomic changes that are part of the change
 */

/**
 * @name ChangeObject
 * @type Object
 * @property {string} id        The unique sablono ID
 * @property {string} code      The user given code
 * @property {string} ext       The unique external ID
 * @property {string} name      The user given name
 * @property {ChangeEntry} change    The change describing object
 */

/**
 * Class to be used as interface for the response body.
 *
 * @param {Object} responseBody - result body of deliverable-jobs service
 *
 * @constructor
 */
function ComponentCreationResultDAO(responseBody) {
    // new pieces
    //
    this.projectId = responseBody.projectId;
    this.sourceKey = responseBody.sourceKey;
    this._deliverableChanges = responseBody.deliverables;
    this._structureChanges = responseBody.structure;

    this._hasCreatedElements = null;
    this._hasUpdatedElements = null;
    this._hasDeletedElements = null;
    this._hasCreatedStructure = null;
    this._hasUpdatedStructure = null;
    this._hasDeletedStructure = null;
}

ComponentCreationResultDAO.CREATE_KEY = "CREATED";
ComponentCreationResultDAO.UPDATE_KEY = "UPDATED";
ComponentCreationResultDAO.DELETE_KEY = "DELETED";

/**
 * Create a tests function that can be called on a change object
 * Will return true if one of the atomic changes matches the criteria.
 */
ComponentCreationResultDAO.hasAtomicChange = function (fnTest) {
    return function (changeObject) {
        if (_.isArray(changeObject.change.list)) {
            return changeObject.change.list.some(fnTest);
        }
        return false;
    };
};

ComponentCreationResultDAO.prototype.reset = function () {
    this._hasCreatedElements = null;
    this._hasUpdatedElements = null;
    this._hasDeletedElements = null;
    this._hasCreatedStructure = null;
    this._hasUpdatedStructure = null;
    this._hasDeletedStructure = null;
};

ComponentCreationResultDAO.prototype.filterDeliverableChanges = function (
    fnFilter
) {
    this._deliverableChanges = this._deliverableChanges.filter(fnFilter);
    this.reset();
};

ComponentCreationResultDAO.prototype.filterStructureChanges = function (
    fnFilter
) {
    this._structureChanges = this._structureChanges.filter(fnFilter);
    this.reset();
};

/**
 * Check if there are created deliverables in the response
 * @returns {boolean}
 */
ComponentCreationResultDAO.prototype.hasCreatedDeliverables = function () {
    if (this._hasCreatedElements === null) {
        this._hasCreatedElements =
            this._deliverableChanges.some(_isCreateOperation);
    }
    return this._hasCreatedElements;
};

/**
 * Check if there are updated deliverables in the response
 * @returns {boolean}
 */
ComponentCreationResultDAO.prototype.hasUpdatedDeliverables = function () {
    if (this._hasUpdatedElements === null) {
        this._hasUpdatedElements =
            this._deliverableChanges.some(_isUpdateOperation);
    }
    return this._hasUpdatedElements;
};

/**
 * Check if there are deleted deliverables in the response
 * @returns {boolean}
 */
ComponentCreationResultDAO.prototype.hasDeletedDeliverables = function () {
    if (this._hasDeletedElements === null) {
        this._hasDeletedElements =
            this._deliverableChanges.some(_isDeleteOperation);
    }
    return this._hasDeletedElements;
};

/**
 * Check if there are created structuring nodes in the response
 * @returns {boolean}
 */
ComponentCreationResultDAO.prototype.hasCreatedStructure = function () {
    if (this._hasCreatedStructure === null) {
        this._hasCreatedStructure =
            this._structureChanges.some(_isCreateOperation);
    }
    return this._hasCreatedStructure;
};

/**
 * Check if there are updated structuring nodes in the response
 * @returns {boolean}
 */
ComponentCreationResultDAO.prototype.hasUpdatedStructure = function () {
    if (this._hasUpdatedStructure === null) {
        this._hasUpdatedStructure =
            this._structureChanges.some(_isUpdateOperation);
    }
    return this._hasUpdatedStructure;
};

/**
 * Check if there are deleted structuring nodes in the response
 * @returns {boolean}
 */
ComponentCreationResultDAO.prototype.hasDeletedStructure = function () {
    if (this._hasDeletedStructure === null) {
        this._hasDeletedStructure =
            this._structureChanges.some(_isDeleteOperation);
    }
    return this._hasDeletedStructure;
};

/**
 * Return the list of created deliverables based on the response object
 * @returns {Array<ChangeObject>}
 */
ComponentCreationResultDAO.prototype.getCreatedDeliverables = function () {
    return this._deliverableChanges.filter(_isCreateOperation);
};

/**
 * Return the list of updated deliverables based on the response object
 * @returns {Array<ChangeObject>}
 */
ComponentCreationResultDAO.prototype.getUpdatedDeliverables = function () {
    return this._deliverableChanges.filter(_isUpdateOperation);
};

/**
 * Return the list of deleted deliverables based on the response object
 * @returns {Array<ChangeObject>}
 */
ComponentCreationResultDAO.prototype.getDeletedDeliverables = function () {
    return this._deliverableChanges.filter(_isDeleteOperation);
};

/**
 * Return the list of created structuring nodes based on the response object
 * @returns {Array<ChangeObject>}
 */
ComponentCreationResultDAO.prototype.getCreatedStructure = function () {
    return this._structureChanges.filter(_isCreateOperation);
};

/**
 * Return the list of updated structuring nodes based on the response object
 * @returns {Array<ChangeObject>}
 */
ComponentCreationResultDAO.prototype.getUpdatedStructure = function () {
    return this._structureChanges.filter(_isUpdateOperation);
};

/**
 * Return the list of deleted structuring nodes based on the response object
 * @returns {Array<ChangeObject>}
 */
ComponentCreationResultDAO.prototype.getDeletedStructure = function () {
    return this._structureChanges.filter(_isDeleteOperation);
};

export default ComponentCreationResultDAO;

///////
//  IMPL
//////

/**
 * Check if the given change object is of type delete
 * @param {ChangeObject} thing
 * @returns {boolean}
 * @private
 */
function _isDeleteOperation(thing) {
    return thing.change.operation === ComponentCreationResultDAO.DELETE_KEY;
}

/**
 * Check if the given change object is of type update
 * @param {ChangeObject} thing
 * @returns {boolean}
 * @private
 */
function _isUpdateOperation(thing) {
    return thing.change.operation === ComponentCreationResultDAO.UPDATE_KEY;
}

/**
 * Check if the given change object is of type create
 * @param {ChangeObject} thing
 * @returns {boolean}
 * @private
 */
function _isCreateOperation(thing) {
    return thing.change.operation === ComponentCreationResultDAO.CREATE_KEY;
}
