import deliverablePanelTooltip from "../components/sbDeliverablePanelTooltipPanel/sb_deliverable_panel_tooltip.html";

export default function DeliverablePanelTooltipService(
    $mdPanel,
    $sbColor,
    $q,
    MODES
) {
    "ngInject";
    var panelRef;
    return {
        open: open,
        close: close,
    };

    function close() {
        if (panelRef && panelRef.close) {
            return panelRef.close();
        }
        return $q.resolve();
    }

    function open($event, context) {
        // on first hover create a new panel
        if (!panelRef) {
            _openNewPanel($event, context).then((value) => {
                // keep a reference to the panel to reuse
                panelRef = value;
            });
            return;
        }
        return _openExistingPanel($event, context);
    }

    function _openExistingPanel($event, context) {
        panelRef.updatePosition(_getPanelPosition($event));
        panelRef.config.locals = _getTemplateLocals(context);
        return panelRef.open();
    }

    function _openNewPanel($event, context) {
        return $mdPanel.open({
            locals: _getTemplateLocals(context),
            position: _getPanelPosition($event),
            controllerAs: "$ctrl",
            controller: angular.noop,
            templateUrl: deliverablePanelTooltip,
            focusOnOpen: false,
            clickOutsideToClose: true,
            panelClass: "sb-visualization-tooltip",
            propagateContainerEvents: true,
        });
    }

    function _getTemplateLocals(context) {
        let tooltipSecondaryText;
        switch (context.activeMode.name) {
            case MODES.STAGE.name:
                context.component.STAGE
                    ? (tooltipSecondaryText = context.component.STAGE.NAME)
                    : (tooltipSecondaryText = MODES.STAGE.noValueKey);
                break;
            case MODES.BROWSE_ACTIVITY.name:
                tooltipSecondaryText = _getBrowseStatusModeLegendText(context);
                break;

            case MODES.CURRENT.name:
                if (
                    context.component.HAS_PROGRESS_REPORTED &&
                    context.component.CURRENT
                ) {
                    tooltipSecondaryText = context.component.CURRENT.TEAM_NAME;
                } else {
                    tooltipSecondaryText = MODES.CURRENT.noValueKey;
                }
                break;

            case MODES.PROGRESS.name:
                if (context.component.IS_BEHIND) {
                    tooltipSecondaryText = "_BEHIND_SCHEDULE";
                    break;
                }
                tooltipSecondaryText = "_ON_SCHEDULE";
                break;
            default:
                tooltipSecondaryText = "Something went wrong";
        }

        return {
            tooltipHeaderText:
                context.component.CODE + " | " + context.component.NAME,
            tooltipSecondaryText: tooltipSecondaryText,
            close: close,
        };
    }

    function _getPanelPosition($event) {
        return $mdPanel
            .newPanelPosition()
            .relativeTo($event.target)
            .addPanelPosition(
                $mdPanel.xPosition.ALIGN_START,
                $mdPanel.yPosition.ABOVE
            );
    }

    function _getBrowseStatusModeLegendText(context) {
        const stageReachedColor = context.component.STAGE_REACHED_COLOR;
        const isHatched = _isTruthy(context.component.HATCH_COLOR);
        const isCrossHatched = _isTruthy(context.component.CROSS_HATCH_COLOR);

        // This legend entry is the only case, where a stroked entry text is used
        if (
            stageReachedColor ===
            $sbColor.color.visualization.stages.NOT_APPLICABLE
        ) {
            return "VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_APPLICABLE";
        }

        // find the corresponding legend entry
        for (let legendEntry of context.legend) {
            if (
                !legendEntry.stroke &&
                stageReachedColor === legendEntry.color &&
                _isEqualTruthyFalsy(isHatched, legendEntry.hatch) &&
                _isEqualTruthyFalsy(isCrossHatched, legendEntry.crossHatch)
            ) {
                return legendEntry.key;
            }
        }
        return "No value found in legend";
    }

    function _isTruthy(toCheck) {
        if (!toCheck) {
            return false;
        }
        return true;
    }

    function _isEqualTruthyFalsy(a, b) {
        // Cast truthy and falsy values as booleans
        // and check if both evaluate the same
        return !a === !b;
    }
}
