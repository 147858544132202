import accountManagementTemplateHtml from "./account_management.html";
import accountLoginTemplateHtml from "./login/account_login.html";
import accountRegisterTemplateHtml from "./registration/account_register.html";
import maintenanceTemplateHtml from "./maintenance/maintenance.html";
import resetPasswordTemplateHtml from "./resetPassword/reset_password.html";
import confirmResetPasswordTemplateHtml from "./confirmResetPassword/confirm_reset_password.html";
import verifyEmailTemplateHtml from "./verifyEmail/verify-email.html";

export default function ($stateProvider) {
    "ngInject";

    $stateProvider.state("account", {
        abstract: true,
        templateUrl: accountManagementTemplateHtml,
        controller: "accountManagementCtrl",
        controllerAs: "accountCtrl",
        url: "/account?signup_origin", // putting it here so that both .signIn and .register can inherit it.
        data: {
            title: "",
        },
        resolve: {
            // We have to check the system health to show the maintenance message in case the system is down.
            //
            systemStatus: function checkIfSystemIsAlive($sbSystemStatus) {
                "ngInject";

                return $sbSystemStatus.getSystemStatus();
            },
        },
    });
    // @TODO BA 1/3/16: there are two interesting things here:
    // 2. the resolve thing is important and we should test it properly
    // 3. managesOverlay. Needed? We should also refactor that
    const loginStateDefaultData = {
        title: "SECTION_LOGIN_TITLE",
        hasTabs: true,
        selectedTab: 0,
        managesOverlay: true,
        // This forLogin flag is useful in case of unpublished changes in the templates editor, so if we
        // are bounced back to login because of session expiration, we don't loose changes.
        forLogin: true,
        isRegisterVisible: true,
    };

    $stateProvider.state("account.login", {
        url: "/login?name&params",
        templateUrl: accountLoginTemplateHtml,
        controller: "accountLoginCtrl",
        controllerAs: "$ctrl",
        data: loginStateDefaultData,
        resolve: {
            $resolvedIdentityFromStore: function ($sbAuthStore) {
                "ngInject";
                return $sbAuthStore.getLastSuccessfulSignInIdentity();
            },
        },
    });
    $stateProvider.state("account.signIn", {
        url: "/signIn?name&params",
        templateUrl: accountLoginTemplateHtml,
        controller: "accountLoginCtrl",
        controllerAs: "$ctrl",
        data: loginStateDefaultData,
        resolve: {
            $resolvedIdentityFromStore: function ($sbAuthStore) {
                "ngInject";
                return $sbAuthStore.getLastSuccessfulSignInIdentity();
            },
        },
    });
    $stateProvider.state("account.register", {
        url: "/register",
        templateUrl: accountRegisterTemplateHtml,
        controller: "accountRegisterCtrl",
        controllerAs: "$ctrl",
        gaPageTrack: "/account/register",
        data: {
            title: "SECTION_REGISTRATION_TITLE",
            hasTabs: true,
            selectedTab: 1,
            isRegisterVisible: true,
        },
    });
    $stateProvider.state("account.confirmResetPassword", {
        url: "/confirmResetPassword?email&verificationCode",
        templateUrl: confirmResetPasswordTemplateHtml,
        controller: "accountConfirmResetPasswordCtrl",
        controllerAs: "$ctrl",
        data: {
            title: "SECTION_LOGIN_TITLE",
        },
    });
    $stateProvider.state("account.resetPassword", {
        url: "/password",
        templateUrl: resetPasswordTemplateHtml,
        controller: "accountResetPasswordCtrl",
        controllerAs: "password",
        data: {
            title: "SECTION_LOGIN_TITLE",
        },
    });
    $stateProvider.state("account.verifyEmail", {
        url: "/verifyEmail?email&verificationCode",
        templateUrl: verifyEmailTemplateHtml,
        controller: "VerifyEmailCtrl",
        controllerAs: "$ctrl",
        data: {
            title: "SECTION_LOGIN_TITLE",
        },
    });
    $stateProvider.state("account.maintenance", {
        url: "/maintenance",
        templateUrl: maintenanceTemplateHtml,
        controller: "MaintenanceCtrl",
        controllerAs: "$ctrl",
        data: {
            title: "SECTION_LOGIN_TITLE",
        },
    });
}
