import _ from "lodash";
import moment from "moment";

import LeanboardModelMapper from "../services/lean_board_model.mapper";

import Pager, {
    createPager,
} from "../../../common/services/api/pagination/pager";
import LookAheadProgramPagingSource from "../../../common/services/api/pagination/look_ahead_program.paging_source";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";

export default function LastPlannedService(
    downloadCenterService,
    SbTeam,
    $sbCurrentProject,
    $sbTeam,
    $q,
    $sbDates,
    $sbActivitiesApi,
    $sbLookAheadProgramApi,
    $sbLookAheadJobsApi,
    $sbJobApi,
    USER_INTERACTION,
    WEEK_BOARD_VIEW_MODE
) {
    "ngInject";
    var _consumableCalendar;

    var service = {
        fetchAndExtendActivity: fetchAndExtendActivity,
        updateLastPlannedDatesFor: updateLastPlannedDatesFor,
        rescheduleLastPlannedDatesFrom: rescheduleLastPlannedDatesFrom,
        forecastDatesForAllDeliverables: forecastDatesForAllDeliverables,
        forecastDatesForDeliverable: forecastDatesForDeliverable,
        forecastDatesForStructure: forecastDatesForStructure,
        getDeliverablesInTimeFrame: getDeliverablesInTimeFrame,
        getPDFDeliverablesInTimeFrame: getPDFDeliverablesInTimeFrame,
        calculateIsForecastEnabled: calculateIsForecastEnabled,
        setConsumableCalendar: setConsumableCalendar,
        isAnActivityForecasted: isAnActivityForecasted,
        acceptAllForecastedDates: acceptAllForecastedDates,
        updateLastPlannedDatesForStructureActivity:
            updateLastPlannedDatesForStructureActivity,
        rescheduleLastPlannedDatesForStructureActivity:
            rescheduleLastPlannedDatesForStructureActivity,
    };

    return service;

    //////////

    function fetchAndExtendActivity(activity) {
        return Promise.all([
            $sbActivitiesApi.get(activity.id),
            $sbTeam.asMap($sbCurrentProject.pick("id")),
        ]).then(([activityData, teamsMap]) => {
            activity.confirmationTeam = teamsMap.get(
                activityData.confirmation_team_id
            );
            activity.reviewTeams = (activityData.review_team_ids || []).map(
                (id) => teamsMap.get(id)
            );

            activity.isBehind = activityData.is_behind_baseline;
            activity.progressChangeTime = toMomentOrNull(
                _.get(activityData, "state.reported.at")
            );
            activity.plannedLabour = activityData.planned_labour;

            activity.progressChangeAuthor = {
                displayName: _.get(activityData, "state.reported.by.name"),
            };

            activity.lastPlannedChangeCount = _.get(
                activityData,
                "look_ahead_schedule.number_of_replannings"
            );

            return activity;
        });
    }

    async function updateLastPlannedDatesForStructureActivity(
        structureActivity,
        constraints,
        activeFilters,
        areaManagerUserName
    ) {
        const { jobId } = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.BULK_SCHEDULE_LOOK_AHEAD_ACTIVITIES,
                definition: _prepareStructureScheduleDefinition(
                    structureActivity,
                    constraints,
                    false,
                    activeFilters,
                    areaManagerUserName
                ),
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(jobId);
    }

    async function updateLastPlannedDatesFor(activity, constraints) {
        const { jobId } = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.JOB_SCHEDULE_LOOK_AHEAD_ACTIVITY,
                definition: _prepareScheduleDefinition(
                    activity,
                    constraints,
                    false
                ),
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(jobId);
    }

    //TODO Replace, migrated to angular. Warning for changed signature!!! ( 1st param is project Id )
    async function rescheduleLastPlannedDatesFrom(activity, constraints) {
        const response = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.JOB_SCHEDULE_LOOK_AHEAD_ACTIVITY,
                definition: _prepareScheduleDefinition(
                    activity,
                    constraints,
                    true
                ),
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(_.get(response, "jobId"));
    }

    async function rescheduleLastPlannedDatesForStructureActivity(
        activity,
        constraints,
        activeFilters,
        areaManagerUserName
    ) {
        const response = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.BULK_SCHEDULE_LOOK_AHEAD_ACTIVITIES,
                definition: _prepareStructureScheduleDefinition(
                    activity,
                    constraints,
                    true,
                    activeFilters,
                    areaManagerUserName
                ),
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(_.get(response, "jobId"));
    }

    async function forecastDatesForAllDeliverables(
        calendar,
        activeFilters,
        areaManagerUserName
    ) {
        const oDataQuery = _getOdataQueryFromActiveFilters(
            activeFilters,
            areaManagerUserName
        );
        const today = _getStartOfToday(calendar);

        const response = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.JOB_FORECAST_LOOK_AHEAD_ACTIVITIES,
                definition: {
                    start_of_forecast: today,
                    deliverable_set: oDataQuery,
                },
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(_.get(response, "jobId"));
    }

    async function acceptAllForecastedDates() {
        const oDataQuery = new ODataFilterFactory()
            .eq("PROJECT_ID", $sbCurrentProject.pick("id"))
            .get();

        const response = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.ACCEPT_ALL_FORECASTED_DATES,
                definition: {
                    deliverable_set: oDataQuery,
                },
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(_.get(response, "jobId"));
    }

    async function forecastDatesForDeliverable(deliverable, calendar) {
        var today = _getStartOfToday(calendar);

        const response = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.JOB_FORECAST_LOOK_AHEAD_ACTIVITIES,
                definition: {
                    start_of_forecast: today,
                    deliverables: [deliverable.id],
                },
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(_.get(response, "jobId"));
    }

    async function forecastDatesForStructure(structureSubTree, calendar) {
        var today = _getStartOfToday(calendar);

        const spanIndexLeft = structureSubTree.LEFT_TREE_SPAN;
        const spanIndexRight = structureSubTree.RIGHT_TREE_SPAN;

        const oDataFactory = new ODataFilterFactory();

        oDataFactory
            .eq("PROJECT_ID", $sbCurrentProject.pick("id"))
            .and()
            .between("STRUCTURE_SPAN_INDEX", spanIndexLeft, spanIndexRight);

        const response = await $sbLookAheadJobsApi.create(
            $sbCurrentProject.pick("id"),
            {
                type: $sbLookAheadJobsApi.JOB_FORECAST_LOOK_AHEAD_ACTIVITIES,
                definition: {
                    start_of_forecast: today,
                    deliverable_set: oDataFactory.get(),
                },
            }
        );
        // Wait until the job is done
        return $sbJobApi.waitFor(_.get(response, "jobId"));
    }

    function _getStartOfToday(calendar) {
        return $sbDates
            .getStartOfDayFromMoment(moment(), calendar)
            .toISOString();
    }

    function _getOdataQueryFromActiveFilters(
        activeFilters,
        areaManagerUserName
    ) {
        let oDataFactory = new ODataFilterFactory().eq(
            "PROJECT_ID",
            $sbCurrentProject.pick("id")
        );

        if (activeFilters.searchTerm) {
            oDataFactory
                .and()
                .block(
                    new ODataFilterFactory()
                        .like("NAME", activeFilters.searchTerm)
                        .or()
                        .like("DESC", activeFilters.searchTerm)
                        .or()
                        .like("CODE", activeFilters.searchTerm)
                );
        }

        if (activeFilters.selectedProcessTemplate) {
            oDataFactory
                .and()
                .eq("TEMPLATE_ID", activeFilters.selectedProcessTemplate.ID);
        }

        if (activeFilters.selectedStructure) {
            const spanIndexLeft =
                activeFilters.selectedStructure.LEFT_TREE_SPAN;
            const spanIndexRight =
                activeFilters.selectedStructure.RIGHT_TREE_SPAN;

            oDataFactory
                .and()
                .between("STRUCTURE_SPAN_INDEX", spanIndexLeft, spanIndexRight);
        }

        if (activeFilters.isMyArea) {
            oDataFactory
                .and()
                .block(
                    new ODataFilterFactory()
                        .eq("AREA_MANAGER_USER_NAME", areaManagerUserName)
                        .or()
                        .eq("AREA_MANAGER_USER_NAME", null)
                );
        }

        return oDataFactory.get();
    }

    function formatDateString(dateValue) {
        return moment(dateValue).format("YYYY-MM-DD");
    }

    /**
     * Fetch the data for a lean board
     *
     * @param {Object}  query
     * @param {number}  query.from (as milliseconds)
     * @param {number}  query.to (as milliseconds)
     * @param {string}  query.timezone
     * @param {boolean} query.onlyMyArea
     * @param {boolean} query.includeLate
     * @param {string|undefined}  query.structureId
     * @param {string|undefined}  query.processTemplateId
     * @param {number|undefined}  query.bySessionId
     * @param {number|undefined}  query.teamId
     * @param {string|undefined}  query.search
     * @param {number|undefined}  [query.pageNumber]
     * @param {number|undefined}  [query.pageSize]
     * @param {string}  aggregateInto - enum of WEEK_BOARD_VIEW_MODE
     *
     * @returns {Promise<Pager>}
     */
    async function getDeliverablesInTimeFrame(
        query,
        { aggregateInto, ignoreActuals }
    ) {
        const queryParams = {
            timezone: query.timezone,
            byOnlyMyArea: query.onlyMyArea,
            byStructureId: query.structureId,
            byProcessTemplateId: query.processTemplateId,
            bySessionId: query.bySessionId,
            byTeamId: query.teamId,
            search: query.search,
            includeLate: query.includeLate,
        };
        queryParams.from = formatDateString(query.from);
        queryParams.to = formatDateString(query.to);
        queryParams.levelOfDetail =
            aggregateInto === WEEK_BOARD_VIEW_MODE.STRUCTURE
                ? $sbLookAheadProgramApi.LEVEL_OF_DETAIL.STRUCTURE
                : $sbLookAheadProgramApi.LEVEL_OF_DETAIL.DELIVERABLE;

        // used to load an initial chunk of data up until the given page
        let initialLoadCount;
        if (query.pageNumber && query.pageSize) {
            initialLoadCount = (query.pageNumber + 1) * query.pageSize;
        }

        const pager = await createPager(
            {
                limit: Pager.DEFAULT_LIMIT,
            },
            (config) => {
                return new LookAheadProgramPagingSource(
                    $sbLookAheadProgramApi,
                    {
                        ...queryParams,
                        limit: config.limit,
                        projectId: $sbCurrentProject.pick("id"),
                    }
                );
            },
            initialLoadCount
        );

        const teams = await $sbTeam.asMap($sbCurrentProject.pick("id"));
        const isStructureAggregate =
            aggregateInto === WEEK_BOARD_VIEW_MODE.STRUCTURE;

        pager.mapper = (record) => {
            const lane = LeanboardModelMapper.createFromApiResponse(
                record,
                _consumableCalendar,
                {
                    isIgnoringCode: isStructureAggregate,
                    isIgnoringActualDates:
                        isStructureAggregate || ignoreActuals,
                }
            );
            lane.setMaxDateForLateSection(query.from);

            lane.getActivities().forEach(function (activity) {
                _attachResponsibleTeam(activity, teams);
            });
            return lane;
        };

        return pager;
    }

    function getPDFDeliverablesInTimeFrame(query, aggregateInto) {
        const queryParams = {
            from: formatDateString(query.from),
            to: formatDateString(query.to),
            timezone: query.timezone,
            byOnlyMyArea: query.onlyMyArea,
            byStructureId: query.structureId,
            byProcessTemplateId: query.processTemplateId,
            bySessionId: query.bySessionId,
            byTeamId: query.teamId,
            language: query.language,
            columnTimespan: query.columnTimespan,
            isShowingActuals: query.isShowingActuals,
            levelOfDetail:
                aggregateInto === WEEK_BOARD_VIEW_MODE.STRUCTURE
                    ? $sbLookAheadProgramApi.LEVEL_OF_DETAIL.STRUCTURE
                    : $sbLookAheadProgramApi.LEVEL_OF_DETAIL.DELIVERABLE,
        };

        return $sbLookAheadProgramApi
            .printCollection($sbCurrentProject.pick("id"), queryParams)
            .then((xhr) => downloadCenterService.downloadFrom(xhr));
    }

    function _attachResponsibleTeam(activity, projectTeams) {
        const workTeamId = _.get(activity, "workTeam.id");
        if (projectTeams.has(workTeamId)) {
            activity.workTeam = projectTeams.get(workTeamId);
        } else {
            activity.workTeam = SbTeam.createUnrestrictedTeam();
        }
    }

    /**
     * Calculates if it is possible to forecast activities in the current project
     *
     * @param {array} deliverables - array of LeanboardLaneRecords
     *
     * @returns {boolean}
     */
    function calculateIsForecastEnabled(deliverables) {
        // Forecast is enabled if there are activities that are behind schedule
        return _.some(
            deliverables,
            function _checkForLateActivities(deliverable) {
                return deliverable.countLateActivities() > 0;
            }
        );
    }

    /**
     * Calculates if an activity has already been forcasted
     *
     * @param {array} deliverables - array of LeanboardLaneRecords
     *
     * @returns {boolean}
     */
    function isAnActivityForecasted(deliverables) {
        return _.some(
            deliverables,
            function _checkForForecastedActivities(deliverable) {
                return _.some(
                    deliverable.activities,
                    (activity) => activity.isForecasted
                );
            }
        );
    }

    function setConsumableCalendar(consumableCalendar) {
        _consumableCalendar = consumableCalendar;
    }

    //TODO Migrated replace from angular
    function _prepareScheduleDefinition(
        activity,
        constraints,
        updateDependents
    ) {
        return {
            activity: {
                id: activity.id,
                look_ahead_schedule: {
                    start_date: {
                        date: activity.startDate.toISOString(),
                        is_user_defined: constraints.some(
                            function (constraint) {
                                return (
                                    constraint === USER_INTERACTION.START_DATE
                                );
                            }
                        ),
                    },
                    end_date: {
                        date: activity.endDate.toISOString(),
                        is_user_defined: constraints.some(
                            function (constraint) {
                                return constraint === USER_INTERACTION.END_DATE;
                            }
                        ),
                    },
                    duration: {
                        value: activity.lastPlannedDuration || undefined,
                        is_user_defined: constraints.some(
                            function (constraint) {
                                return constraint === USER_INTERACTION.DURATION;
                            }
                        ),
                    },
                },
            },
            schedule: {
                update_dependents: updateDependents,
            },
        };
    }

    function toMomentOrNull(date) {
        if (_.isNil(date)) {
            return null;
        }
        return moment(date, moment.ISO_8601, true);
    }

    function _prepareStructureScheduleDefinition(
        activity,
        constraints,
        updateDependents,
        activeFilters,
        areaManagerUserName
    ) {
        const oDataQuery = _getOdataQueryFromActiveFilters(
            activeFilters,
            areaManagerUserName
        );

        return {
            activities: {
                activity_template_id: activity.templateId,
                deliverable_set: oDataQuery,
                look_ahead_schedule: {
                    start_date: {
                        date: activity.startDate.toISOString(),
                        is_user_defined: constraints.some(
                            function (constraint) {
                                return (
                                    constraint === USER_INTERACTION.START_DATE
                                );
                            }
                        ),
                    },
                    end_date: {
                        date: activity.endDate.toISOString(),
                        is_user_defined: constraints.some(
                            function (constraint) {
                                return constraint === USER_INTERACTION.END_DATE;
                            }
                        ),
                    },
                    duration: {
                        value: activity.lastPlannedDuration || undefined,
                        is_user_defined: constraints.some(
                            function (constraint) {
                                return constraint === USER_INTERACTION.DURATION;
                            }
                        ),
                    },
                },
            },
            schedule: {
                update_dependents: updateDependents,
            },
        };
    }
}
