import angular from "angular";
import moment from "moment";
import templateHtml from "./lean_board.html";

export default {
    name: "sbLeanBoard",
    replace: true,
    templateUrl: templateHtml,
    bindings: {
        onActivityClicked: "&",
        onShowDeliverableDetails: "&",
        onShowAggregatedDeliverables: "&",
        onForecastDatesForDeliverable: "&",
        onNext: "&",
        onPrevious: "&",
        onWeekNavigate: "&",
        onShowLateActivities: "&",
        timeFrame: "<",
        deliverables: "<",
        hasNext: "<",
        hasPrevious: "<",
        isFetchingData: "<",
        openSession: "<",
        fetchingFromDeliverable: "<",
        selectedColumnTimespan: "<",
        zoomSize: "<",
        selectedViewMode: "<",
    },
    controller: LeanBoardCtrl,
};

function LeanBoardCtrl(
    $sbLeanBoard,
    $log,
    $translate,
    $sbTeam,
    $state,
    $sbTracking,
    $rootScope,
    $mdDialog,
    MIN_COLUMN_WIDTH_LARGE,
    MIN_COLUMN_WIDTH_SMALL,
    WEEK_BOARD_SIZES,
    WEEK_BOARD_TIMESPAN_PER_COLUMN,
    WEEK_BOARD_VIEW_MODE
) {
    "ngInject";
    var vm = this;

    vm.$onChanges = $onChanges;

    // The board
    vm.board;
    vm.laneColumns = [];

    // Layout variables
    vm.headerColumns;
    vm.numColumns;
    vm.minColumnWidth;
    vm.WEEK_BOARD_SIZES = WEEK_BOARD_SIZES;
    vm.WEEK_BOARD_TIMESPAN_PER_COLUMN = WEEK_BOARD_TIMESPAN_PER_COLUMN;
    vm.WEEK_BOARD_VIEW_MODE = WEEK_BOARD_VIEW_MODE;

    vm.isDeliverableLoading = isDeliverableLoading;
    vm.openTimeRangeDialog = openTimeRangeDialog;

    // Navigation
    vm.getWeekOfTimeFrame = $sbLeanBoard.getWeekOfTimeFrame;

    vm.navigateToNextColumnTimeframe = _navigateTimeframeByDays.bind(
        this,
        "add"
    );
    vm.navigateToPreviousColumnTimeframe = _navigateTimeframeByDays.bind(
        this,
        "subtract"
    );

    function $onChanges(change) {
        $log.debug("LeanBoardCtrl::$onChanges - ", change);

        if (change.timeFrame && change.timeFrame.currentValue) {
            vm.timeFrame = change.timeFrame.currentValue;
        }
        // avoid initially calculating twice with onInit
        //
        if (
            change.deliverables &&
            !(
                change.deliverables.isFirstChange() &&
                change.timeFrame.isFirstChange()
            )
        ) {
            if (
                angular.isArray(change.deliverables.currentValue) &&
                angular.isObject(vm.timeFrame)
            ) {
                vm.deliverables = change.deliverables.currentValue;
                refreshBoard(
                    vm.timeFrame,
                    vm.deliverables,
                    vm.selectedColumnTimespan
                );
            }
        }
    }

    /**
     * Change the from and to state parameters of the URL and force a refresh of the board.
     *
     * @param {moment} from
     * @param {moment} to
     * @private
     */
    function _changeLeanboardTimeFrame(from, to) {
        var usedFormat = "DD-MM-YY";
        vm.onWeekNavigate({
            from: from.format(usedFormat),
            to: to.format(usedFormat),
            format: usedFormat,
        });
    }

    /**
     *
     * @param direction
     * @param days
     * @param {LeanboardTimeframe} currentTimeFrame
     * @private
     */
    function _navigateTimeframeByDays(direction, currentTimeFrame) {
        _changeLeanboardTimeFrame(
            moment(currentTimeFrame.getStartAsMoment())[direction](7, "day"),
            moment(currentTimeFrame.getEndAsMoment())[direction](7, "day")
        );
    }

    function refreshBoard(timeFrame, deliverables, selectedColumnTimespan) {
        vm.board = createLeanBoardFrom(
            timeFrame,
            deliverables,
            selectedColumnTimespan
        );
        const isDayView =
            vm.selectedColumnTimespan === vm.WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY;

        vm.headerColumns = _generateHeaderColumns(
            vm.board.getHeader(selectedColumnTimespan),
            vm.timeFrame,
            isDayView
        );
        vm.numColumns = vm.headerColumns.length;
        vm.laneColumns = getViewColumns(vm.numColumns, vm.timeFrame, isDayView);
        vm.minColumnWidth = _determineMinColumnWidthBy(vm.zoomSize);
    }

    /**
     *
     * @param {moment[]} headerColumns
     * @private
     */
    function _generateHeaderColumns(headerColumns, timeFrame, isDayView) {
        if (
            vm.selectedColumnTimespan === vm.WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY
        ) {
            return headerColumns.map((columnHeader, index) => ({
                text: columnHeader.format("LTS"),
                isToday: _isToday(index, timeFrame, isDayView),
                isNonWorkingDay: _isNonWorkingDay(index, timeFrame, isDayView),
            }));
        }

        if (
            vm.selectedColumnTimespan === vm.WEEK_BOARD_TIMESPAN_PER_COLUMN.WEEK
        ) {
            if ($translate.use() === "de") {
                return headerColumns.map((columnHeader, index) => {
                    return {
                        text: "KW " + columnHeader.week(),
                        isToday: _isToday(index, timeFrame, isDayView),
                        isNonWorkingDay: _isNonWorkingDay(
                            index,
                            timeFrame,
                            isDayView
                        ),
                    };
                });
            } else {
                return headerColumns.map((columnHeader, index) => {
                    const startOfWeek = columnHeader.clone().startOf("week");
                    const endOfWeek = columnHeader.clone().endOf("week");
                    return {
                        text: `${startOfWeek.format(
                            "LTS"
                        )} - ${endOfWeek.format("LTS")}`,
                        isToday: _isToday(index, timeFrame, isDayView),
                        isNonWorkingDay: _isNonWorkingDay(
                            index,
                            timeFrame,
                            isDayView
                        ),
                    };
                });
            }
        }

        return headerColumns;
    }

    function _determineMinColumnWidthBy(zoomSize) {
        switch (zoomSize) {
            case WEEK_BOARD_SIZES.SMALL:
                return MIN_COLUMN_WIDTH_SMALL;
            case WEEK_BOARD_SIZES.LARGE:
            default:
                return MIN_COLUMN_WIDTH_LARGE;
        }
    }

    function createLeanBoardFrom(timeframe, deliverables, columnTimespan) {
        $log.debug(
            "LeanBoardCtrl::prepareLeanBoardData - for timeframe " +
                timeframe.getStartAsMoment().format("YYYY-MM-DD") +
                " -> " +
                timeframe.getEndAsMoment().format("YYYY-MM-DD") +
                " with " +
                deliverables.length +
                " deliverables"
        );

        return $sbLeanBoard.getBoard(timeframe, deliverables, columnTimespan);
    }

    function isDeliverableLoading(deliverable) {
        return vm.fetchingFromDeliverable === deliverable.id;
    }

    function getViewColumns(numberOfColumns, timeframe, isDayView) {
        const result = [];
        for (let i = 0; i < numberOfColumns; i++) {
            result.push({
                column: i,
                isToday: _isToday(i, timeframe, isDayView),
                isNonWorkingDay: _isNonWorkingDay(i, timeframe, isDayView),
            });
        }

        return result;
    }

    function _isNonWorkingDay(columnIndex, timeframe, isDayView) {
        if (isDayView) {
            return timeframe.isNonWorkingDay(columnIndex);
        }

        return timeframe.isNonWorkingWeek(columnIndex);
    }

    function _isToday(columnIndex, timeframe, isDayView) {
        if (isDayView) {
            return timeframe.isToday(columnIndex);
        }

        return timeframe.isThisWeek(columnIndex);
    }

    function showChangeDatetimeDialog() {
        return $mdDialog.show(
            $mdDialog
                .timeRangeSelector()
                .startDate(vm.timeFrame.getStartAsDate())
                .endDate(vm.timeFrame.getEndAsDate())
                .openSession(vm.openSession)
                .isDayView(
                    vm.selectedColumnTimespan ===
                        vm.WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY
                )
        );
    }

    function openTimeRangeDialog() {
        showChangeDatetimeDialog().then(({ startDate, endDate }) => {
            _changeLeanboardTimeFrame(moment(startDate), moment(endDate));
        });
    }
}
