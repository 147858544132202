import angular from "angular";
import _ from "lodash";
import moment from "moment";
import templateHtml from "./week_calendar_body.html";

export default {
    name: "sbWeekCalendarBody",
    templateUrl: templateHtml,
    bindings: {
        onDateSelected: "&",
    },
    controller: WeekCalendarBodyCtrl,
};

function WeekCalendarBodyCtrl($scope, $sbWeekCalendar) {
    "ngInject";
    var vm = this;

    var _vm = {
        weekdays: [],
        getEventForDay: getEventForDay,
        isNonWorkingDay: isNonWorkingDay,
        selectDate: selectDate,
    };

    _.merge(vm, _vm);

    $scope.$watch(
        function () {
            return $sbWeekCalendar.weekdays;
        },
        function (weekdays) {
            vm.weekdays = weekdays;
        }
    );

    $scope.$watch(
        function () {
            return $sbWeekCalendar.date;
        },
        function (date) {
            vm.date = date;
        }
    );

    function getEventForDay(date) {
        return $sbWeekCalendar.getEventForDay(date);
    }

    function isNonWorkingDay(date) {
        return $sbWeekCalendar.isNonWorkingDay(date);
    }

    function selectDate(date) {
        vm.onDateSelected({
            date: date,
        });
    }
}
