//Polyfill for adding class to element since IE doesn't support classList or className. (MC)
Node.prototype.sbAddClass = function (className) {
    var previousClassList = this.getAttribute("class") || "";
    var newClassList = "";
    if (previousClassList.indexOf(className) > -1) {
        newClassList = previousClassList;
    } else {
        newClassList = previousClassList + " " + className;
    }
    this.setAttribute("class", newClassList.replace("  ", " "));
    return newClassList;
};
//Polyfill for adding class to element since IE doesn't support classList or className. (MC)
Node.prototype.sbRemoveClass = function (className) {
    var previousClassList = this.getAttribute("class") || "";
    var newClassList = previousClassList
        .replace(className, "")
        .replace("  ", " ");
    this.setAttribute("class", newClassList);
    return newClassList;
};
