/**
 * Service that includes all the scheduling process validations and checks
 *
 */
import moment from "moment";

export default function deliverableDateChangeService(
    $log,
    $sbDates,
    $mdToast,
    $sbBusyIndication,
    $mdDialog,
    $sbComponent,
    $q,
    Analytics
) {
    "ngInject";
    ////////
    //
    //  PUBLIC API
    //
    ////////

    return {
        startChangeWorkflow: startChangeWorkflow,
        getDatesFromSelection: getDatesFromSelection,
        assignNewDates: assignNewDates,
    };

    ////////
    //
    //  IMPL
    //
    ////////

    /**
     *
     * @param {DeliverableSelection} deliverableSelection - the selection model
     * @param {object} selectedTemplate
     * @param {SbCalendar} calendar
     * @returns {*}
     */
    function startChangeWorkflow(
        deliverableSelection,
        selectedTemplate,
        calendar
    ) {
        var timezone = calendar.TIMEZONE;
        var workTimes = $sbDates.parseCalendarToWorkTimes(calendar);

        var dates = getDatesFromSelection(deliverableSelection, workTimes);
        var isKeepRangeEnabled = !deliverableSelection.isSelectAllActive();
        var showDifferentDatesFlag = !dates.allSame;
        var warningMessage = deliverableSelection.isSelectAllActive()
            ? "DIALOG_CHANGE_DATE_ALL_SELECTED_WARNING_MESSAGE"
            : "DIALOG_CHANGE_DATE_WARNING_MESSAGE";

        var earliestStartDate = $sbDates.toMomentOrNull(dates.earliest);
        var latestEndDate = $sbDates.toMomentOrNull(dates.latest);

        if (earliestStartDate !== null) {
            earliestStartDate.tz(timezone.name);
        }

        if (latestEndDate !== null) {
            latestEndDate.tz(timezone.name);
        }

        var changeDateTimeDialog = $mdDialog
            .changeDatetime()
            .startDate(earliestStartDate)
            .endDate(latestEndDate)
            .isKeepRangeEnabled(isKeepRangeEnabled)
            .differentDatesFlag(showDifferentDatesFlag)
            .conflictWarningFlag(true)
            .showTemplateId(selectedTemplate)
            .warningMessage(warningMessage)
            .unsetLabel("DIALOG_CHANGE_DATES_DERIVE_FROM_SCHEDULE_HINT_TEXT")
            .deliverablesToChange({
                activeSelection: deliverableSelection.getPreferredCondition(),
            })
            .timezone(timezone);

        return $mdDialog.show(changeDateTimeDialog).then(function (result) {
            return assignNewDates(result, deliverableSelection);
        });
    }

    /**
     *
     * @param {DeliverableSelection} deliverableSelection
     * @param workTimes
     * @return {*}
     */
    function getDatesFromSelection(deliverableSelection, workTimes) {
        var dates;

        if (!deliverableSelection.hasAllSelectionsLocally()) {
            dates = {
                // today and tomorrow noon for select all
                earliest: moment()
                    .hours(workTimes.morning.hour)
                    .minutes(workTimes.morning.minute)
                    .valueOf(),
                latest: moment()
                    .hours(workTimes.evening.hour)
                    .minutes(workTimes.evening.minute)
                    .valueOf(),
                allSame: false,
            };
        } else {
            dates = $sbDates.calcDateInfoForDeliverables(
                deliverableSelection.getLocalSelection()
            );
        }

        return dates;
    }

    function toServerTime(datetime) {
        if (datetime === null) {
            return "sb::date.not-set";
        }

        if (moment.isMoment(datetime)) {
            return datetime.toISOString();
        }
    }

    /**
     *
     * @param changes
     * @param {DeliverableSelection} deliverableSelection
     */
    function assignNewDates({ SD, CD }, deliverableSelection) {
        const startDate = toServerTime(SD);
        const endDate = toServerTime(CD);

        return assignDatesToDeliverables(
            deliverableSelection,
            startDate,
            endDate
        );
    }

    /**
     *
     * @param {DeliverableSelection} deliverableSelection
     * @param {string} startDate
     * @param {string} endDate
     * @return {*}
     */
    function assignDatesToDeliverables(
        deliverableSelection,
        startDate,
        endDate
    ) {
        var setDatePromise = $sbComponent.updateComponentsDate(
            deliverableSelection.getPreferredCondition(),
            deliverableSelection.projectId,
            startDate,
            endDate
        );

        return $sbBusyIndication
            .show(
                setDatePromise,
                "DIALOG_CHANGE_DATES_LOADING_TEXT",
                "DIALOG_MERGE_STRUCTURE_LOADING_TITLE"
            )
            .then(function (results) {
                if (
                    startDate === "sb::date.not-set" ||
                    endDate === "sb::date.not-set"
                ) {
                    Analytics.trackEvent(
                        "Scheduling",
                        "Deliverable dates",
                        "Automatically calculate"
                    );
                }

                // return a nice output for the caller
                return {
                    deliverables: results ? results.deliverables : [],
                };
            })
            .then(function (result) {
                _showToast("ACTION_CHANGE_DATES_SUCCESS_N", {
                    deliverables:
                        result && result.deliverables
                            ? result.deliverables.length
                            : "-",
                });
                return result;
            });
    }

    function _showToast(text, content) {
        $mdToast.show(
            $mdToast
                .simple()
                .content(text)
                .contentValues(content)
                .hideDelay(5000)
                .position("top right")
        );
    }
}
