export default function SbTimelineCtrl(Analytics) {
    "ngInject";

    var vm = this;
    vm.trackClick = trackClick;

    vm.$onInit = function $onInit() {
        Analytics.trackNorthStarExploration("open update timeline");
    };

    function trackClick() {
        Analytics.trackConversion("update timeline clicked");
    }
}
