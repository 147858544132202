import PixelRatioChangedEvent from "../events/PixelRatioChangedEvent";

//performance.now() polyfill
window.performance = window.performance || {};
window.performance.now = window.performance.now =
    window.performance.webkitNow ||
    window.performance.mozNow ||
    window.performance.msNow;

if (!window.performance.now) {
    window.performance.startOffset = Date.now ? Date.now() : +new Date();
    window.performance.now = function () {
        return (
            (Date.now ? Date.now() : +new Date()) -
            window.performance.startOffset
        );
    };
}

/**
 * expand normal stage for high-dpi displays
 *
 * @constructs RetinaStage
 * @extends createjs.Stage
 * @memberof WBS
 */
function RetinaStage() {
    // call constructor of parent class
    //
    createjs.Stage.apply(this, arguments);

    this.canvas.style.position = "absolute";
    this.context = this.canvas.getContext("2d");
    this.domStage = this.createDOMStage();

    this.snapToPixelEnabled = true;
    this.snapToPixel = true;

    this.pixelRatio = 0;
    this.tickOnUpdate = false;
    this.needsDisplay = true;
    this.realBounds = new createjs.Rectangle(0, 0, 0, 0);
}

/**
 * Setup prototypal inheritance.
 * RetinaStage inherits from createjs.Stage.
 *
 * @type {createjs.Stage}
 */
RetinaStage.prototype = Object.create(createjs.Stage.prototype);

/**
 * @default 0
 * @type {number}
 */
RetinaStage.prototype.frameStartTime = 0;

/**
 * @default 0
 * @type {number}
 */
RetinaStage.prototype.operations = 0;

/**
 * @default 20
 * @type {number}
 */
RetinaStage.prototype.maxFrameTime = 1000 / 50;

/**
 * Updates the Stage
 */
RetinaStage.prototype.update = function () {
    this.operations = 0;

    this.frameStartTime = window.performance.now();

    //disable anti aliasing, much better vor clear lines like this
    //
    if (
        this.context.imageSmoothingEnabled ||
        this.context.mozImageSmoothingEnabled ||
        this.context.webkitImageSmoothingEnabled
    ) {
        this.context.imageSmoothingEnabled = false;
        this.context.mozImageSmoothingEnabled = false;
        this.context.webkitImageSmoothingEnabled = false;
    }

    //Detect if the device Ratio changed (simulate the event) maybe find a better solution
    //
    if (this.getDevicePixelRatio() !== this.pixelRatio) {
        this.onPixelRatioChanged();
    }

    //Resize if size changed
    //
    if (this.sizeChanged) {
        this.resize();
        this.sizeChanged = false;
    }

    var tickStartTime = window.performance.now();

    //call tick method
    //
    var needsDisplay = this.needsDisplay;
    this.needsDisplay = false;
    if (needsDisplay) {
        this.tick();
    }

    this.lastTickDuration = window.performance.now() - tickStartTime;

    var drawStartTime = window.performance.now();

    // call super method
    //
    if (needsDisplay) {
        createjs.Stage.prototype.update.call(this);
    }

    this.lastDrawDuration = window.performance.now() - drawStartTime;
};

/**
 * Set size of the Stage
 * @param {number} width - Width of Stage
 * @param {number} height - Height of Stage
 */
RetinaStage.prototype.setSize = function (width, height) {
    this.width = width;
    this.height = height;
    this.sizeChanged = true;
};

/**
 * @returns {number} the current device pixel ratio
 */
RetinaStage.prototype.getDevicePixelRatio = function () {
    return window.devicePixelRatio;
};

/**
 * executed every time the device pixel changed since last frame
 */
RetinaStage.prototype.onPixelRatioChanged = function () {
    this.pixelRatio = this.getDevicePixelRatio();
    this.scale = 1 / this.pixelRatio;
    this.resize();

    var event = new PixelRatioChangedEvent(this, this.pixelRatio);
    this.dispatchEvent(event);
};

/**
 * executed every time the width or height changed since last frame
 */
RetinaStage.prototype.resize = function () {
    this.realBounds.width = this.width * this.pixelRatio;
    this.realBounds.height = this.height * this.pixelRatio;

    this.canvas.width = this.realBounds.width;
    this.canvas.height = this.realBounds.height;
    this.scaleX = this.scaleY = this.pixelRatio;
    this.canvas.style.width = this.width + "px";
    this.canvas.style.height = this.height + "px";

    var domScale = 1 / this.pixelRatio;
    var transform = "scale(" + domScale + "," + domScale + ")";
    this.domStage.style.transform = transform;
    this.domStage.style.webkitTransform = transform;
    this.domStage.style.msTransform = transform;
    this.domStage.style.mozTransform = transform;

    this.setNeedsDisplay();
};

/**
 * Getter for Frame Duration
 * @returns {number}
 */
RetinaStage.prototype.getFrameDuration = function () {
    return window.performance.now() - this.frameStartTime;
};

/**
 * Getter for Free Time
 * @returns {number}
 */
RetinaStage.prototype.getFreeTime = function () {
    var duration = this.getFrameDuration(),
        lastDrawDuration = Math.min(
            this.lastDrawDuration,
            this.maxFrameTime - 1
        );

    return this.maxFrameTime - (duration + lastDrawDuration);
};

/**
 * Determines if there is free time
 * @returns {boolean}
 */
RetinaStage.prototype.hasFreeTime = function () {
    this.operations++;

    return this.getFreeTime() > 0 || this.operations < 10;
};

/**
 * Create a DOM Stage
 * @returns {Element}
 */
RetinaStage.prototype.createDOMStage = function () {
    var DOMStage = document.createElement("div");
    DOMStage.style.position = "absolute";
    this.canvas.parentNode.appendChild(DOMStage);

    return DOMStage;
};

/**
 * Adds a child to DOMStage
 * @param {Object} element - Added Child
 */
RetinaStage.prototype.addDOMChild = function (element) {
    this.domStage.appendChild(element);
};

/**
 * Remove a Child from DOM Stage
 * @param {Object} element - Removed Child
 */
RetinaStage.prototype.removeDOMChild = function (element) {
    this.domStage.removeChild(element);
};

/**
 * redraws the stage in the next frame
 */
RetinaStage.prototype.setNeedsDisplay = function () {
    this.needsDisplay = true;
};

export default RetinaStage;
