import _ from "lodash";
import moment from "moment";
import Note from "./sb_note.class";

/**
 * Create a deliverable
 *
 * @constructor
 */
function BaseComponent(id, name, code) {
    this.id = id;

    this.piTemplateId = null;
    this.projectId = null;
    this.parentId = null;
    this.rootId = null;

    this.name = name;
    this.code = code;
    this.desc = "";
    this.category = "";

    this.startDate = null;
    this.endDate = null;
    this.timezone = null;

    this.scheduleState = BaseComponent.SCHEDULE_UNKNOWN;
    this.progress = BaseComponent.NOT_STARTED;

    this.progressChangeTime = null;
    this.issueChangeTime = null;
    this.progressChangeAuthor = {
        dbName: null,
        displayName: null,
        initials: null,
    };

    this._noteStatistic = {
        closedObstructions: 0,
        openObstructions: 0,
        closedClaims: 0,
        openClaims: 0,
        info: 0,
    };

    //
    // navigation to other collections
    //

    /**
     * All the notes of the deliverable
     * @type {Array<Object>}
     */
    this.notes = null;

    //
    //  private stuff
    //

    this._parent = null;

    this.__odataSource = null;
}

BaseComponent.COULD_NOT_START = -1;
BaseComponent.NOT_STARTED = 0;
BaseComponent.IN_PROGRESS = 50;
BaseComponent.DONE = 100;

BaseComponent.COULD_NOT_START_ENUM = "COULD_NOT_START";
BaseComponent.NOT_STARTED_ENUM = "NOT_STARTED";
BaseComponent.STARTED_ENUM = "STARTED";
BaseComponent.IN_PROGRESS_ENUM = "IN_PROGRESS";
BaseComponent.DONE_ENUM = "DONE";
BaseComponent.WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION";

BaseComponent.SCHEDULE_UNKNOWN = null;
BaseComponent.SCHEDULE_BEHIND = true;
BaseComponent.SCHEDULE_ON_TIME = false;

BaseComponent.prototype.setProjectId = function (id) {
    this.projectId = id;
    return this;
};

BaseComponent.prototype.setProgress = function (progress) {
    if (_.isNumber(progress)) {
        this.progress = progress;
    } else {
        switch (progress) {
            case BaseComponent.NOT_STARTED_ENUM:
                this.progress = BaseComponent.NOT_STARTED;
                return this;
            case BaseComponent.DONE_ENUM:
            case BaseComponent.WAITING_FOR_CONFIRMATION:
                this.progress = BaseComponent.DONE;
                return this;
            case BaseComponent.IN_PROGRESS_ENUM:
            case BaseComponent.STARTED_ENUM:
                this.progress = BaseComponent.IN_PROGRESS;
                return this;
            default:
                throw new TypeError(
                    'IllegalArgumentException: The argument is not of type "integer" or part of the enum'
                );
        }
    }
    return this;
};

/**
 * Validate if the component is behind the schedule based on the stored DB state.
 *
 * @return {boolean}
 */
BaseComponent.prototype.isBehind = function () {
    return this.scheduleState === BaseComponent.SCHEDULE_BEHIND;
};

/**
 * Validate if the component is on time the schedule based on the stored DB state.
 *
 * @return {boolean}
 */
BaseComponent.prototype.isOnTime = function () {
    return this.scheduleState === BaseComponent.SCHEDULE_ON_TIME;
};

BaseComponent.prototype.getProgress = function () {
    return this.progress;
};

BaseComponent.prototype.getProgressEnum = function () {
    if (this.progress === BaseComponent.NOT_STARTED) {
        return BaseComponent.NOT_STARTED_ENUM;
    }

    if (this.progress === BaseComponent.DONE) {
        return BaseComponent.DONE_ENUM;
    }

    if (
        this.progress > BaseComponent.NOT_STARTED &&
        this.progress < BaseComponent.DONE
    ) {
        return BaseComponent.IN_PROGRESS_ENUM;
    }
};

/**
 * Set the parent reference for this component
 * @param {Group|null} parent
 * @returns {BaseComponent} this
 */
BaseComponent.prototype.setParent = function (parent) {
    this._parent = parent;
    return this;
};

/**
 * Clear the parent reference for this component
 * @returns {BaseComponent} this
 */
BaseComponent.prototype.clearParent = function () {
    if (this.hasParent()) {
        this.getParent().removeChildById(this.id);
        this.parentId = null;
    }
    return this;
};

/**
 * Get the parent domain model if available
 * @returns {Group|null}
 */
BaseComponent.prototype.getParent = function () {
    return this._parent;
};

/**
 * The id of the parent (either entity or the direct property)
 * @returns {string}
 */
BaseComponent.prototype.getParentId = function () {
    return this.getParent() ? this.getParent().id : this.parentId;
};

/**
 * Check if there is a parent entity (parent id is ignored)
 * @returns {boolean}
 */
BaseComponent.prototype.hasParent = function () {
    return !!this._parent;
};

/**
 * Timezone in the format: 'Europe/London'
 * @param {String} timezone
 * @returns {BaseComponent}
 */
BaseComponent.prototype.setTimezone = function (timezone) {
    this.timezone = timezone;
    return this;
};

BaseComponent.prototype.hasNotes = function () {
    return (
        this._noteStatistic.openObstructions > 0 ||
        this._noteStatistic.openClaims > 0 ||
        this._noteStatistic.info > 0
    );
};

BaseComponent.prototype.getNumberOf = function (type) {
    switch (type) {
        case Note.INFORMATION:
            return this._noteStatistic.info;
        case Note.QUALITY_ISSUE:
            return this._noteStatistic.openClaims;
        case Note.OBSTRUCTION:
            return this._noteStatistic.openObstructions;
    }
};

/**
 * Add an instance of Note to the statistics
 * @param {Note} sbNote
 * @returns {BaseComponent}
 */
BaseComponent.prototype.addNoteToStats = function (sbNote) {
    switch (sbNote.type) {
        case Note.INFORMATION:
            this._noteStatistic.info++;
            return this;
        case Note.QUALITY_ISSUE:
            this._noteStatistic.openClaims++;
            return this;
        case Note.OBSTRUCTION:
            this._noteStatistic.openObstructions++;
            return this;
    }
    return this;
};

/**
 * Remove an instance of Note from the statistics
 * @param {Note} sbNote
 * @returns {BaseComponent}
 */
BaseComponent.prototype.removeNoteFromStats = function (sbNote) {
    switch (sbNote.type) {
        case Note.INFORMATION:
            this._noteStatistic.info--;
            return this;
        case Note.QUALITY_ISSUE:
            this._noteStatistic.openClaims--;
            return this;
        case Note.OBSTRUCTION:
            this._noteStatistic.openObstructions--;
            return this;
    }
    return this;
};

BaseComponent.prototype.setStartDate = function (startDate) {
    this.startDate = startDate;
    return this;
};

BaseComponent.prototype.setEndDate = function (endDate) {
    this.endDate = endDate;
    return this;
};

BaseComponent.prototype.getDisplayPath = function () {
    var currentParent = this.getParent(),
        pathArray = [];

    while (currentParent && currentParent instanceof BaseComponent) {
        pathArray.push(currentParent.name);
        currentParent = currentParent.getParent();
    }
    // remove the root, as it will be the deliverable itself
    pathArray.pop();

    // reverse the array and return the joint string
    var result = pathArray.reverse().join(" > ");

    return result.length > 0 ? result + " > " + this.name : this.name;
};

BaseComponent.prototype.toMomentOrNull = function (dateISOString) {
    const date = moment(dateISOString, moment.ISO_8601, true);
    return date.isValid() ? date : null;
};

export default BaseComponent;
