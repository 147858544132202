// Should correspond to ExcelImportLegacyCodeRequiredPieces interface
export default function legacyExcelImportService(
    $sbDeliverableJobsApi,
    $sbProcessTemplatesApi,
    $sbTemplate,
    $sbTeam,
    $sbColor,
    $sbFileReader,
    $sbCurrentProject,
    intercomService
) {
    "ngInject";

    var service = {
        createDeliverablesAndStructures:
            $sbDeliverableJobsApi.createImportJob.bind($sbDeliverableJobsApi),
        createProcessTemplate:
            $sbTemplate.addTemplateUsingOnlyTheNewInterfaces.bind($sbTemplate),
        createActivityTemplatesAndTheirConnections:
            $sbProcessTemplatesApi.createRevision.bind($sbProcessTemplatesApi),
        assignProcessTemplateToDeliverables:
            $sbDeliverableJobsApi.createAssignProcessJob.bind(
                $sbDeliverableJobsApi
            ),

        associateStrategy: $sbDeliverableJobsApi.ASSIGN_STRATEGY.ASSOCIATE,
        clearTeamsCache: function () {
            return $sbTeam.clearTeamsCache($sbCurrentProject.pick("id"));
        }.bind($sbTeam),
        getRandomColor: $sbColor.color.random,
        readFile: $sbFileReader.readFile,
    };

    return service;
}
