import _ from "lodash";

export default function sbLookAheadDefinitionDialogController(
    $mdDialog,
    USER_INTERACTION
) {
    "ngInject";
    const vm = this;

    vm.USER_INTERACTION = USER_INTERACTION;

    // the created look ahead change setup in the dialog
    //
    vm.definition = {
        isUpdateDependentsChecked: true,
        duration: null,
        date: null,
        usedDate: USER_INTERACTION.START_DATE,
    };

    // interactions
    //
    vm.onSave = onSave;
    vm.onDateChanged = (date) => (vm.definition.date = date);
    vm.isSaveDisabled = isSaveDisabled;
    vm.onCancel = $mdDialog.cancel;

    function onSave() {
        const output = {
            updateDependants: vm.definition.isUpdateDependentsChecked,
            duration: vm.definition.duration,
        };

        if (vm.definition.usedDate === USER_INTERACTION.END_DATE) {
            output.endDate = vm.definition.date;
        }

        if (vm.definition.usedDate === USER_INTERACTION.START_DATE) {
            output.startDate = vm.definition.date;
        }

        $mdDialog.hide(output);
    }

    function isSaveDisabled() {
        const isInvalid = _.get(vm, "activitiesForm.$invalid", true);
        const isDirty = _.get(vm, "activitiesForm.$dirty", false);
        return isInvalid || !isDirty;
    }
}
