import angular from "angular";
import _ from "lodash";
import html from "./searchable_select.tmpl.html";

export default {
    templateUrl: html,
    bindings: {
        rootTemplateIds: "<?",
        onSelect: "&?",
    },
    controller: function SearchableSelectCtrl(
        $q,
        $sbTemplate,
        $sbCurrentProject,
        $sbErrorPresenter
    ) {
        "ngInject";

        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;
        var originalStages = [];

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        // vm.originalStages = [];
        vm.availableStages = [];
        vm.groupedStages = [];
        vm.selectedStages = {};

        vm.clearSearch = clearSearch;
        vm.onSearchTextChange = onSearchTextChange;
        vm.onFakeSearchIndication = onFakeSearchIndication;
        vm.onStageSelectionChange = onStageSelectionChange;
        vm.onStageDeselectionChange = onStageDeselectionChange;
        vm.hasUniqueChoices = hasUniqueChoices;
        vm.selectAll = selectAll;

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = function () {
            _fetchTemplateStageSuggestions($sbCurrentProject.pick("id"));
        };

        vm.$onChanges = function (changes) {
            var currentRootTemplateIds = _.get(
                changes,
                "rootTemplateIds.currentValue"
            );
            var previousRootTemplateIds = _.get(
                changes,
                "rootTemplateIds.previousValue"
            );

            var hasRootTemplateIdsChanged = !_.isEqual(
                currentRootTemplateIds,
                previousRootTemplateIds
            );
            if (hasRootTemplateIdsChanged) {
                vm.rootTemplateIds = angular.copy(currentRootTemplateIds);

                // reduce selectable search items by used templates
                var suggestions = _filterSuggestionsByUsedTemplates(
                    originalStages,
                    vm.rootTemplateIds
                );
                _setStages(suggestions);

                if (vm.searchText) {
                    // reduce selectable search items by user search term
                    updateAvailableAndGroupedStages(
                        _filterSuggestionsBySearchTerm(
                            vm.searchText,
                            originalStages
                        )
                    );
                }
            }
        };

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        function _filterSuggestionsByUsedTemplates(
            suggestions,
            rootTemplateIds
        ) {
            if (_.isArray(rootTemplateIds) && rootTemplateIds.length !== 0) {
                return suggestions.filter(function (suggestion) {
                    return rootTemplateIds.indexOf(suggestion.rootId) !== -1;
                });
            }

            return suggestions;
        }

        function _setStages(suggestions) {
            updateAvailableAndGroupedStages(suggestions);
            originalStages = [].concat(vm.availableStages);
        }

        function _fetchTemplateStageSuggestions(projectId) {
            return $sbTemplate
                .getListOfAllActivitiesOfAllTemplates(projectId)
                .then(function (suggestions) {
                    return _filterSuggestionsByUsedTemplates(
                        suggestions,
                        vm.rootTemplateIds
                    );
                })
                .then(_setStages)
                .catch(function (err) {
                    _setStages([]);

                    if (err) {
                        $sbErrorPresenter.catch(err);
                    }
                });
        }

        function onSearchTextChange(searchText) {
            var hasSearchTextCleared =
                _.isUndefined(searchText) || searchText === "";
            if (hasSearchTextCleared) {
                vm.clearSearch();
            }
        }

        function filterTemplatesBy(searchText) {
            return $q(function (resolve) {
                return resolve(
                    _filterSuggestionsBySearchTerm(searchText, originalStages)
                );
            });
        }

        function _filterSuggestionsBySearchTerm(searchText, suggestions) {
            var searchLowerCase = searchText.toLowerCase();
            var searchableProps = ["name", "rootName"];

            return _.filter(suggestions, function filterBySearchTerm(template) {
                return searchableProps.some(function (searchableProp) {
                    return (
                        template[searchableProp] &&
                        template[searchableProp]
                            .toLowerCase()
                            .indexOf(searchLowerCase) > -1
                    );
                });
            });
        }

        /**
         * To indicate a loading on the md-autocomplete we need to return a promise here.
         * To not show results in a drop down, but instead in a separate UI we need to always
         * resolve with an empty array at the end.
         *
         * @param searchText
         * @returns {Promise}
         */
        function onFakeSearchIndication(searchText) {
            if (!searchText) {
                return $q.resolve([]);
            }

            return filterTemplatesBy(searchText).then(
                function (availableStages) {
                    updateAvailableAndGroupedStages(availableStages);
                    return [];
                }
            );
        }

        function clearSearch() {
            vm.searchText = undefined;
            updateAvailableAndGroupedStages([].concat(originalStages));
        }

        function onStageSelectionChange() {
            emitSelectionChanged();
        }

        function onStageDeselectionChange(groupedStageTemplateId) {
            _.unset(vm.selectedStages, groupedStageTemplateId);
            emitSelectionChanged();
        }

        function updateAvailableAndGroupedStages(newValue) {
            vm.availableStages = newValue;
            vm.groupedStages = groupAvailableStages(vm.availableStages);
            vm.hasUniqueChoices = hasUniqueChoices(vm.groupedStages);
        }

        function groupAvailableStages(availableStages) {
            return _.values(_.groupBy(availableStages, "rootId")).map(
                (templateAvailableStages) => {
                    return {
                        templateName: templateAvailableStages[0].rootName,
                        templateId: templateAvailableStages[0].rootId,
                        availableStages: templateAvailableStages,
                    };
                }
            );
        }

        function hasUniqueChoices(stages = []) {
            if (_.isEmpty(stages)) {
                return false;
            }

            return stages.every((stage) => stage.availableStages.length === 1);
        }

        function selectAll() {
            vm.selectedStages = vm.groupedStages.reduce(
                (selectedStages, groupedStage) => {
                    selectedStages[groupedStage.templateId] =
                        groupedStage.availableStages[0];
                    return selectedStages;
                },
                {}
            );

            emitSelectionChanged();
        }

        function emitSelectionChanged() {
            vm.onSelect({
                selection: _.compact(_.values(vm.selectedStages)),
            });
        }
    },
};
