import createjs from "createjs";

/**
 * Event that is dispatched when something is focused
 * @constructs FocusEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function FocusEvent() {
    createjs.Event.call(this, "focus", true, true);
}

FocusEvent.prototype = Object.create(createjs.Event.prototype);

export default FocusEvent;
