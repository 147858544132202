/**
 * @typedef simpleEdge
 * @type {Object}
 * @property {string|number} source - The source of the edge
 * @property {string|number} target - The target of the edge
 */

/**
 * Basic component node object.
 *
 * @param {string|number} id - something to identify the component
 * @param {Object} properties - plain object to attach data to the component
 *
 * @class
 * @constructor
 */
function Component(id, properties) {
    this.id = id;
    /**
     * generic data
     * @type {Object}
     */
    this.properties = properties || {};

    /**
     * parent component if it has a parent
     * @type {Group|null}
     */
    this.parent = null;
}

/**
 * Get the attached data of the component
 *
 * @returns {Object|*}
 */
Component.prototype.getData = function () {
    return this.properties;
};

/**
 * Gets the Successors of a Component
 * Since Component have no successors, it returns an empty array
 * more space efficient than to assign each component an empty array for successors
 * @returns {Array}
 */
Component.prototype.getSuccessors = function () {
    return [];
};

/**
 * Gets the Predecessors of a Component
 * see getSuccessors
 * @returns {Array}
 */
Component.prototype.getPredecessors = function () {
    return [];
};

/**
 * Gets the Children of a Component
 * Since Components don' have children, it returns an empty array
 * @returns {Array.<Component>}
 */
Component.prototype.getChildren = function () {
    return [];
};

/**
 * Return the list of all child components by collecting everything the hierarchy down.
 *
 * @returns {Array.<Component>} - the list of Components that need this component to be first
 */
Component.prototype.getChildrenDeep = function () {
    return [];
};

/**
 * Returns if this component has at least one successor.
 *
 * @return {boolean}
 */
Component.prototype.hasSuccessors = function () {
    return this.getSuccessors().length > 0;
};

/**
 * Returns if this component has at least one predecessor.
 *
 * @return {boolean}
 */
Component.prototype.hasPredecessors = function () {
    return this.getPredecessors().length > 0;
};

/**
 * Returns if this component has at least one child.
 *
 * @return {boolean}
 */
Component.prototype.hasChildren = function () {
    return this.getChildren().length > 0;
};

/**
 * Compare the given component with this for equality by id
 *
 * @param {Component} component - the component to compare this with.
 * @returns {boolean} - true if not equal! - false if equal
 */
Component.prototype.notSelfById = function (component) {
    return component.id !== this.id;
};

/**
 * Compare the given component with this for equality by id
 *
 * @param {Component} component - the component to compare this with.
 * @returns {boolean} - true if equal! - false if not equal
 */
Component.prototype.selfById = function (component) {
    return component.id === this.id;
};

/**
 * Returns if the parent component is a valid parent.
 *
 * @param {Component} parent - The component to test against.
 * @return {boolean} Returns true if valid parent.
 */
Component.prototype.isValidParent = function (parent) {
    // check type
    if (!(parent instanceof Component)) {
        console.error("The parent has to be instance of Component");
        return false;
    }

    // check if the parent and this are the same
    //
    if (this.selfById(parent)) {
        console.error(
            "Not possible, because parent and child a the same -> would be a loop"
        );
        return false;
    }

    // check if the new parent is a children of this - not necessary if the new parent is null
    //
    if (parent.hasAncestor(this)) {
        console.error(
            "Not possible, because the new parent is a descendant of himself -> would be a loop"
        );
        return false;
    }
    return true;
};

/**
 * Set the parent of the component. The old parent is overridden. Just one parent is possible.
 *
 * @param {Group|null} newParentComponent - the parent or null if it should be a root
 * @return {boolean} true if successful , false if not.
 */
Component.prototype.setParent = function (newParentComponent) {
    // check if old and new parent are the same
    //
    if (
        newParentComponent &&
        this.getParent() &&
        newParentComponent.selfById(this.getParent())
    ) {
        return true;
    }

    // check the overall validity of the new parent
    //
    if (newParentComponent && !this.isValidParent(newParentComponent)) {
        return false;
    }

    // remove this component from all children of the old parent
    // and make it child of new parent component
    //
    if (newParentComponent) {
        newParentComponent.addChild(this);
    } else if (this.parent) {
        this.parent.removeChild(this);
    }

    return true;
};

/**
 * Return the current parent
 *
 * @returns {Group|null} - the parent or null if root
 */
Component.prototype.getParent = function () {
    return this.parent;
};

Component.prototype.hasParent = function () {
    return !!this.parent;
};

/**
 * Check if the given component is an ancestor of this by the hierarchy relation.
 *
 * @param {Component} component - the Component that could be an ancestor or not
 * @returns {boolean} - true if the component is ancestor, false if not
 */
Component.prototype.hasAncestor = function (component) {
    var parent = this.getParent();
    while (parent) {
        if (component.selfById(parent)) {
            return true;
        }
        parent = parent.getParent();
    }
    return false;
};

// /////////////////////////
//      RETURN the class
// ////////////////////////
export default Component;
