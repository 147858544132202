import angular from "angular";
import html from "./sb_properties_source_icon.html";

export default angular
    .module("sbApp.sbPropertiesSourceIcon", [])
    .component("sbPropertiesSourceIcon", {
        templateUrl: html,
        bindings: {
            tooltipTextKey: "@",
            secondaryIcon: "@",
        },
    });
