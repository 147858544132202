import createjs from "createjs";
import TapableElement from "./TapableElement";
import HoverableElement from "./HoverableElement";

/**
 * button which contains and listens click or tap events
 * @constructs CustomButton
 * @extends createjs.Container
 * @extends WBS.TabableElement
 * @extends WBS.HoverableElement
 * @memberof WBS
 **/
function CustomButton() {
    createjs.Container.call(this);
    TapableElement.call(this);
    HoverableElement.call(this);
}

CustomButton.prototype = Object.create(createjs.Container.prototype);

export default CustomButton;
