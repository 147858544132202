import angular from "angular";
import moment from "moment";
import templateHtml from "./deliverable_card.html";

export default {
    name: "sbProcessDeliverableCard",
    templateUrl: templateHtml,
    bindings: {
        deliverable: "<",
        onShowDeliverableDetails: "&",
        zoomSize: "<",
    },
};
