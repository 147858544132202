import Graph from "./Graph";
import Group from "./Group";
import Activity from "./Activity";

/**
 * The template graph is a special compound graph that can handle two node types
 *  # ACTIVITIES and
 *  # GROUPS
 *
 * @param {Array} components - list of plain component objects
 * @param {Array} edges - list of plain edge objects
 * @extends {Graph}
 * @constructor
 */
function TemplateGraph(components, edges) {
    /**
     * A object literal map which holds the topological order index
     * It's mapped based on the component id
     *
     * @member {Object<String, Number>}
     */
    this.topologicalOrder = {};

    Graph.call(this, components, edges);
    this.refreshTopologicalOrder();
}

TemplateGraph.prototype = Object.create(Graph.prototype);

/**
 * Creates either an Activity or a Group as a new node of the Grpah
 * @override
 * @param {string} id - id of the new node
 * @param {Object} data - data of the new node
 * @returns {Activity|Group}
 * @private
 */
TemplateGraph.prototype._createNewElement = function (id, data) {
    if (data.category === "ACTIVITY") {
        return new Activity(id, data);
    } else {
        return new Group(id, data);
    }
};

/**
 * Gets all Activities of the graph
 * @returns {Array.<Activity>}
 */
TemplateGraph.prototype.getActivityList = function (rootComponent) {
    var activities = [];

    function getActivities(component) {
        if (component) {
            activities = activities.concat(
                component.getChildren().filter(function (child) {
                    return child instanceof Activity;
                })
            );

            component.getChildren().forEach(function (child) {
                getActivities(child);
            });
        }
    }

    if (rootComponent) {
        getActivities(rootComponent);
    } else {
        getActivities(this.getRoot());
    }

    return activities;
};

/**
 * Get the topological order value for a requested component.
 * @param {Component} component
 */
TemplateGraph.prototype.getTopologicalOrderFor = function (component) {
    return this.topologicalOrder[component.id];
};

/**
 * Extend the dependency creation of the graph by updating the topological order as well.
 * @param {Activity} source
 * @param {Activity} target
 */
TemplateGraph.prototype.addDependency = function (source, target) {
    var dependency = Graph.prototype.addDependency.call(this, source, target);
    this.refreshTopologicalOrder();
    return dependency;
};

/**
 * Extend the node remove of the graph by updating the topological order as well.
 *
 * @param {(string|number)} id - The id for the node to be removed.
 * @returns {{removedComponents: Array.<Component>, removedInterDependencies: Array.<Edge>}}
 */
TemplateGraph.prototype.removeNode = function (id) {
    var output = Graph.prototype.removeNode.call(this, id);
    this.refreshTopologicalOrder();
    return output;
};

TemplateGraph.prototype.deleteDependency = function (source, target) {
    var dependency = Graph.prototype.deleteDependency.call(
        this,
        source,
        target
    );
    this.refreshTopologicalOrder();
    return dependency;
};

/**
 * Calculate the topological order of the graph and return the result as 2D array with a list of layer
 * and a list of activities per layer.
 *
 * WARN: As the base graph for a template graph consists of activities only, the topological
 *       layer will contain activities only, too.
 *
 * @returns {Array}
 */
TemplateGraph.prototype.collectTopologicalLayer = function () {
    // target array -> 2D
    //
    var topologicalLayer = [];

    // input
    //
    var allNodes = [].concat(this.getActivityList());
    var allEdges = [].concat(this.collectEdges());

    // while there are nodes left add them to the last layer and remove edges and
    // nodes from the input
    //
    while (allNodes.length > 0) {
        // collect everything that is a target
        //
        var currentTargetActivities = allEdges.map(function (edge) {
            return edge.target;
        });

        // collect all nodes that are not target of an edge
        //
        var activitiesOfTheLayer = [];
        var activitiesNotPlacedYet = [];

        allNodes.forEach(function (activity) {
            if (currentTargetActivities.indexOf(activity) === -1) {
                // the activity is not target of an edge
                activitiesOfTheLayer.push(activity);
            } else {
                // the activity is still target of an edge
                activitiesNotPlacedYet.push(activity);
            }
        });

        // push the new layer to the list of layers!
        //
        topologicalLayer.push(activitiesOfTheLayer);

        // cleanup the graph! -> remove nodes of the "new" layer and the dependent edges, too.
        //
        allNodes = activitiesNotPlacedYet;
        allEdges = allEdges.filter(function (edge) {
            return activitiesOfTheLayer.indexOf(edge.source) === -1;
        });
    }

    return topologicalLayer;
};

/**
 * Compute the complete topology of the graph.
 *
 * Container/Groups will get the lowest number of all children.
 *  -> best would be to calculate on leaf level and propagate upwards.
 */
TemplateGraph.prototype.refreshTopologicalOrder = function () {
    // remove the old order
    this.topologicalOrder = {};

    // pick up the topological layer and set the activity index accordingly to
    // the layer
    this.collectTopologicalLayer().forEach(function (topologicalLayer, index) {
        topologicalLayer.forEach(function (activity) {
            // write the index for the activity.
            this.topologicalOrder[activity.id] = index + 1;
        }, this);
    }, this);

    // calculate for the parents.
    //
    var parentQueue = [].concat(this.getActivityList());

    while (parentQueue.length > 0) {
        var component = parentQueue.shift();
        var parent = component.getParent();

        if (parent) {
            var parentIndex = this.getTopologicalOrderFor(parent);
            var componentIndex = this.getTopologicalOrderFor(component);

            // if the component topological index is smaller than the parent topological index
            //  -> update the parent
            if (
                typeof parentIndex !== "number" ||
                parentIndex > componentIndex
            ) {
                // parents layer number is child layer number
                this.topologicalOrder[parent.id] = componentIndex;
            }

            // add the parent to the queue as well.
            parentQueue.push(parent);
        }
    }

    // update the topologicalIndex of the properties
    //
    this.getComponents().forEach(function (c) {
        c.getData().topologicalIndex = this.getTopologicalOrderFor(c) || 0; // 0 as fallback if they are not set.
    }, this);
};

/**
 * This callback is part of the TemplateGraph class.
 *
 * @callback TemplateGraph~transformActivityToGroupCallback
 * @param {Object} activityData
 */

/**
 * Transforms an activity in a group and either deletes it, or is becoming a child of new group
 * @param {string} activityId - activity id of activity to be transformed
 * @param {boolean} keepActivity - if to keep the activity or remove it
 * @param {TemplateGraph~transformActivityToGroupCallback} fnTransformData - Function that handles what is done with the existing data.
 * @returns {Component}
 */
TemplateGraph.prototype.transformActivityToGroup = function (
    activityId,
    keepActivity,
    fnTransformData
) {
    var activity = this.getComponent(activityId);
    var activityData = activity.getData();
    var groupData = fnTransformData(activityData);

    var group = this.newComponentToParent(
        groupData.id,
        activity.parent.id,
        groupData
    );
    if (keepActivity) {
        activity.setParent(group);
    } else {
        this.removeNode(activity.id);
    }

    this.refreshCodes();
    return group;
};

/**
 * Look in all graph directions (parents, children, predecessors, successors)
 * for the >to node< starting with the >from node<
 *
 * @param {Component|Activity|string} from - the starting node
 * @param {Component|Activity|string} to - the end node
 * @return {string} the direction (UNKNOWN|TOP|BOTTOM|LEFT|RIGHT)
 */
TemplateGraph.prototype.findDirection = function (from, to) {
    // get the real objects if the given ones are just string IDs
    if (typeof from !== "object") {
        from = this.getComponent(from);
    }
    if (typeof to !== "object") {
        to = this.getComponent(to);
    }
    // if on of them is undefined, null, 0 or "" - we can say that it is unknown.
    if (!from || !to) {
        return "UNKNOWN";
    }

    // check the hierarchies first -> pretty cheap
    if (from.hasAncestor(to)) {
        return "TOP";
    }
    if (to.hasAncestor(from)) {
        return "BOTTOM";
    }

    // check the graph -> pretty expensive
    if (from instanceof Activity && to instanceof Activity) {
        if (from.isOnPredecessorPath(to)) {
            return "LEFT";
        }
        if (from.isOnSuccessorPath(to)) {
            return "RIGHT";
        }
    }

    // if no match .. its unknown.
    return "UNKNOWN";
};

export default TemplateGraph;
