import angular from "angular";

/**
 * A basic directive to keep the box fixed
 */
export default angular
    .module("sbApp.sbDynamicBoxContainer", [])
    .directive("sbDynamicBoxContainer", function ($window) {
        function link(scope, element, attributes) {
            var offset = 145; //default
            if (attributes.offset && attributes.offset > -1) {
                offset = attributes.offset;
            }
            angular
                .element(element)
                .css("height", $window.innerHeight - offset + "px");
            $window.addEventListener("resize", function () {
                angular
                    .element(element)
                    .css("height", $window.innerHeight - offset + "px");
            });
        }
        return {
            restrict: "A",
            link: link,
        };
    });
