import angular from "angular";
import html from "./area_manager_card.html";

export default {
    templateUrl: html,
    controllerAs: "areaManagerCard",
    bindings: {
        member: "<",
        onEditMemberClick: "&",
        isEditAllowed: "<",
        replacementMembers: "<",
        selectedReplacement: "<",
        onReplacementChange: "&",
        onReplacementActiveChange: "&",
        isChangeReplacementAllowed: "<",
    },
    controller: function AreaManagerCard() {
        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = angular.noop;
        vm.$onChanges = angular.noop;
        vm.$onDestroy = angular.noop;
        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        vm.replacementChange = replacementChange;
        vm.onChangeReplacementClick = onChangeReplacementClick;
        vm.isAllowedReplacement = isAllowedReplacement;

        function replacementChange(user) {
            vm.onReplacementChange({
                areaManager: vm.member,
                replacement: user,
            });
        }

        function isAllowedReplacement(user) {
            if (
                vm.selectedReplacement &&
                user.username === vm.selectedReplacement.username
            ) {
                return false;
            }

            if (vm.member && user.username === vm.member.username) {
                return false;
            }

            return true;
        }

        function onChangeReplacementClick($mdOpenMenuCallback) {
            if (!vm.isChangeReplacementAllowed) {
                return;
            }

            if (vm.member.isReplacementActive) {
                vm.member.isReplacementActive = !vm.member.isReplacementActive;
                vm.onReplacementActiveChange({
                    areaManager: vm.member,
                    replacement: vm.selectedReplacement,
                });
            } else {
                $mdOpenMenuCallback();
            }
        }
    },
};
