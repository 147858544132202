export default function dependencyService($sbDependenciesApi) {
    "ngInject";
    return {
        getDetailedDependencies: getDetailedDependencies,
    };

    ////////////////////////////////
    //                            //
    //         PUBLIC API         //
    //                            //
    ////////////////////////////////

    /**
     * Fetch a detailed description about dependencies of a deliverable
     *
     * @param {Deliverable} deliverable
     * @param {string} deliverable.id
     * @return {*}
     */
    function getDetailedDependencies(deliverable) {
        return $sbDependenciesApi
            .getCollection(deliverable.id)
            .then(({ activities, dependencies }) => {
                return {
                    nodes: activities.map((activity) => {
                        return {
                            deliverableId: activity.root_id,
                            activityId: activity.id,
                            structure: activity.structure || " - ",
                            deliverableName: activity.deliverable_name,
                            activityName: activity.name,
                            activityIndex: activity.topological_index,
                            state: activity.state.toUpperCase(),
                        };
                    }),
                    edges: dependencies.map((dependency) => {
                        return {
                            source: dependency.source_activity_id,
                            target: dependency.target_activity_id,
                            derived: dependency.is_derived,
                        };
                    }),
                };
            });
    }
}
