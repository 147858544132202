import angular from "angular";
import html from "./checklist_ratio.html";

export default angular
    .module("sbApp.common.components.checklistRatio", [])
    .component("sbChecklistRatio", {
        templateUrl: html,
        bindings: {
            yesRatio: "<",
            noRatio: "<",
            notApplicableRatio: "<",
        },
        controllerAs: "vm",
        controller: angular.noop,
    });
