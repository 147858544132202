import angular from "angular";
import _ from "lodash";
import htmlTemplate from "./sbTemplateActivityEdit.html";

export default angular
    .module("sbApp.sbTemplateActivityEdit", [])
    .constant("DEFAULT_ACTIVITY_COLOR", "#FFFFFF")
    .directive(
        "sbTemplateActivityEdit",
        function TemplateActivityEditCtrl(
            ACTIVITY_CONFIG,
            Analytics,
            TEMPLATE_WORK_DONE_UNITS,
            FeatureFlags,
            EnvironmentFlags
        ) {
            return {
                restrict: "E",
                templateUrl: htmlTemplate, //HTML Template as String
                scope: false,
                controllerAs: "activityForm",
                controller: function (
                    $scope,
                    DEFAULT_ACTIVITY_COLOR,
                    $sbTracking,
                    $sbCurrentProject,
                    $sbFeatureDecisions
                ) {
                    /////////////////////
                    //
                    //      Direct variables
                    //
                    /////////////////////

                    var vm = this;

                    /////////////////////
                    //
                    //      SCOPE properties
                    //
                    /////////////////////

                    vm.UNITS = TEMPLATE_WORK_DONE_UNITS;
                    vm.CONFIG = ACTIVITY_CONFIG;
                    vm.EnvironmentFlags = EnvironmentFlags;

                    vm.isDurationEnabled = $sbFeatureDecisions.isFeatureEnabled(
                        FeatureFlags.TemplateEditorDurations
                    );
                    vm.isLabourEnabled = $sbFeatureDecisions.isFeatureEnabled(
                        FeatureFlags.TemplateEditorLabour
                    );
                    vm.isConfirmationEnabled =
                        $sbFeatureDecisions.isFeatureEnabled(
                            FeatureFlags.TemplateEditorConfirmationTeams
                        );
                    vm.isChecklistEnabled =
                        $sbFeatureDecisions.isFeatureEnabled(
                            FeatureFlags.TemplateEditorQaChecklist
                        );

                    //Only push element into DOM if data is available completely
                    vm.dataIsAvailable = false;

                    //Init times and days
                    vm.times = "";
                    vm.days = "";
                    vm.link = "";

                    vm.color = $scope.template.COLOR || DEFAULT_ACTIVITY_COLOR;
                    vm.labour = $scope.template.PLANNED_LABOUR_ALLOCATION || "";
                    vm.checklist = _.find($scope.checklists, [
                        "id",
                        $scope.template.ASSIGNED_CHECKLIST_ID,
                    ]);
                    vm.hasEmptyChecklist = false;

                    vm.isReviewTeamAvailable = isReviewTeamAvailable;

                    vm.onTeamSelected = onTeamSelected;
                    vm.onTeamCreated = onTeamCreated;
                    vm.onChecklistSelected = onChecklistSelected;

                    vm.ASSIGNED_TEAM_ID = "ASSIGNED_TEAM_ID";
                    vm.CONFIRM_TEAM_ID = "CONFIRM_TEAM_ID";
                    vm.REVIEW_TEAM_IDS = "REVIEW_TEAM_IDS";
                    vm.ASSIGNED_CHECKLIST_ID = "ASSIGNED_CHECKLIST_ID";

                    /////////////////////
                    //
                    //      WATCHER
                    //
                    /////////////////////

                    var colorWatcher = $scope.$watch(
                        "activityForm.color",
                        function (newValue, oldValue) {
                            onAttributeChanged(newValue, oldValue, "COLOR");
                        }
                    );
                    var labourWatcher = $scope.$watch(
                        "activityForm.labour",
                        function (newValue, oldValue) {
                            onAttributeChanged(
                                newValue,
                                oldValue,
                                "PLANNED_LABOUR_ALLOCATION"
                            );
                        }
                    );
                    var checklistWatcher = $scope.$watch(
                        "checklists",
                        function (newValue) {
                            vm.checklist = _.find(newValue, [
                                "id",
                                $scope.template[vm.ASSIGNED_CHECKLIST_ID],
                            ]);

                            vm.hasEmptyChecklist = _.some(newValue, [
                                "numberOfItems",
                                0,
                            ]);
                        }
                    );

                    $scope.$on("$destroy", function () {
                        // unbind watchers
                        colorWatcher();
                        labourWatcher();
                        checklistWatcher();
                    });

                    /////////////////////
                    //
                    //      IMPL
                    //
                    /////////////////////

                    /**
                     * Only if an attribute changes it will be set to the template node.
                     *
                     * @param newValue
                     * @param oldValue
                     * @param attribute
                     */
                    function onAttributeChanged(newValue, oldValue, attribute) {
                        if (newValue !== oldValue && !_.isUndefined(newValue)) {
                            $scope.template[attribute] = newValue;
                            $scope.edit(
                                $scope.template,
                                $scope.sbTemplateActivityEdit
                            );
                        }
                    }
                    /**
                     * Reach through to template editor and add form information.
                     *
                     * @param {"ASSIGNED_TEAM_ID" | "CONFIRM_TEAM_ID" | "REVIEW_TEAM_IDS"} teamAssignment
                     * @param {SbTeam|SbTeam[]} team
                     */
                    function onTeamSelected(teamAssignment, team) {
                        if (teamAssignment === vm.REVIEW_TEAM_IDS) {
                            assignTeamsToTemplate(
                                teamAssignment,
                                team,
                                $scope.template
                            );
                        } else {
                            assignTeamToTemplate(
                                teamAssignment,
                                team,
                                $scope.template
                            );
                        }
                        trackTeamSetup(teamAssignment);

                        $scope.edit(
                            $scope.template,
                            $scope.sbTemplateActivityEdit
                        );
                    }

                    /**
                     * Send analytic events about team selection and setup.
                     *
                     * @param {"ASSIGNED_TEAM_ID" | "CONFIRM_TEAM_ID" | "REVIEW_TEAM_IDS"} teamAssignment
                     */
                    function trackTeamSetup(teamAssignment) {
                        const { asResponsibleFor, asReviewing, asConfirming } =
                            $sbTracking.processTemplate().assignTeams();
                        switch (teamAssignment) {
                            case vm.ASSIGNED_TEAM_ID:
                                asResponsibleFor();
                                break;
                            case vm.CONFIRM_TEAM_ID:
                                asConfirming();
                                break;
                            case vm.REVIEW_TEAM_IDS:
                                asReviewing();
                                break;
                        }
                    }

                    /**
                     * Assign single team  as repsonisble or confirmation team to template.
                     *
                     * @param {"ASSIGNED_TEAM_ID" | "CONFIRM_TEAM_ID"} teamAssignment
                     * @param {SbTeam} team
                     * @param {Object} template
                     * @returns {Object} template
                     */
                    function assignTeamToTemplate(
                        teamAssignment,
                        team,
                        template
                    ) {
                        var hasUnrestrictedAccess = team === null;
                        if (hasUnrestrictedAccess) {
                            if (teamAssignment === vm.CONFIRM_TEAM_ID) {
                                _unsetReviewTeam();
                            }
                            template[teamAssignment] = null;
                        } else {
                            // assign specific team
                            //
                            template[teamAssignment] = team.id;
                        }

                        return template;
                    }

                    /**
                     * Assign multiple teams as review teams to template.
                     *
                     * @param {"REVIEW_TEAM_IDS"} teamAssignment
                     * @param {SbTeam[]} teams
                     * @param {Object} template
                     * @returns {Object} template
                     */
                    function assignTeamsToTemplate(
                        teamAssignment,
                        teams,
                        template
                    ) {
                        template[teamAssignment] = teams.map((team) => team.id);
                        return template;
                    }

                    function onTeamCreated(teamAssignment, teams) {
                        return $scope.onCreateNewTeam().then(function (team) {
                            if (teamAssignment === vm.REVIEW_TEAM_IDS) {
                                const teamsAssigned = (
                                    $scope.template[vm.REVIEW_TEAM_IDS] || []
                                ).map((teamId) => {
                                    return _.find(teams, ["id", teamId]);
                                });
                                teamsAssigned.push(team);
                                onTeamSelected(teamAssignment, teamsAssigned);
                            } else {
                                onTeamSelected(teamAssignment, team);
                            }
                        });
                    }

                    function onChecklistSelected(checklist) {
                        if (checklist) {
                            $scope.template[vm.ASSIGNED_CHECKLIST_ID] =
                                checklist.id;
                        } else {
                            $scope.template[vm.ASSIGNED_CHECKLIST_ID] = null;
                            _unsetReviewTeam();
                        }

                        Analytics.trackEvent(
                            "QA checklists",
                            "Assign",
                            "Assign to activity in Template Editor"
                        );

                        $scope.edit(
                            $scope.template,
                            $scope.sbTemplateActivityEdit
                        );
                    }

                    function _unsetReviewTeam() {
                        $scope.template.REVIEW_TEAM_IDS = [];
                    }

                    function isReviewTeamAvailable() {
                        return (
                            !_.isNil(
                                $scope.template[vm.ASSIGNED_CHECKLIST_ID]
                            ) && !_.isNil($scope.template[vm.CONFIRM_TEAM_ID])
                        );
                    }
                },
            };
        }
    );
