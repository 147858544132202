import CacheContainer from "../common/CacheContainer";
/**
 * @param {StructureNode} node - Node in Container
 * @param {VerticalBranch | HorizontalBranch} BranchClass - branch class you want to use
 * @constructs NodeContainer
 * @extends WBS.CacheContainer
 * @abstract
 * @memberof WBS
 */
function NodeContainer(node, BranchClass) {
    CacheContainer.call(this);

    this.node = node;
    // calc the anchor point position
    var nodeBounds = this.node.getBounds();
    this.anchorPointIn = new createjs.Point(nodeBounds.x, 0);
    this.anchorPointOut = new createjs.Point(
        nodeBounds.x + nodeBounds.width,
        0
    );

    this.branch = new BranchClass(this.node, []);
    createjs.Container.prototype.addChildAt.call(this, this.branch, 0);

    this.on("changed-height", this.heightChangedHandler);
    this.on("changed-width", this.widthChangedHandler);

    this.addChild(this.node);
}

NodeContainer.prototype = Object.create(CacheContainer.prototype);

NodeContainer.prototype.margin = {
    x: 30,
    y: 30,
};

NodeContainer.prototype.heightChangedHandler = function () {};

NodeContainer.prototype.widthChangedHandler = function () {};

NodeContainer.prototype.checkIsOnStage = true;
NodeContainer.prototype.anchorPointIn = new createjs.Point(0, 0);
NodeContainer.prototype.anchorPointOut = new createjs.Point(0, 0);

/**
 * Return the edge anchor point for incoming edges (this is target)
 *
 * @returns {createjs.Point|*}
 */
NodeContainer.prototype.getAnchorPointIn = function () {
    return this.anchorPointIn;
};

/**
 * Return the edge anchor point for outgoing edges (this is source)
 *
 * @returns {createjs.Point|*}
 */
NodeContainer.prototype.getAnchorPointOut = function () {
    return this.anchorPointOut;
};

/**
 * positions the node you want to add first and then add it to this node
 * @param {NodeContainer} element the node you want to add
 */
NodeContainer.prototype.addChild = function (element) {
    if (element instanceof NodeContainer) {
        createjs.Container.prototype.addChild.call(this, element);
        this.branch.structureNodeList.push(element);
        this.branch.calcBranch();
        this.refreshLayout();
    } else {
        createjs.Container.prototype.addChild.call(this, element);
    }
};

/**
 * Dispatches Added to Stage for its Children so it can be displayed
 */
NodeContainer.prototype.dispatchAddedToStage = function () {
    this.node.dispatchAddedToStage();

    for (var i = 0; i < this.children.length; i++) {
        var child = this.children[i];
        if (child instanceof NodeContainer) {
            child.dispatchAddedToStage();
        }
    }
};

/**
 * recalculates the layout
 */
NodeContainer.prototype.refreshLayout = function () {
    var lastNodeContainer;
    var nodeBounds = this.node.getBounds();

    for (var i = 0; i < this.children.length; i++) {
        var child = this.children[i];
        if (child instanceof NodeContainer) {
            if (lastNodeContainer) {
                var lastChildBoundingBox = lastNodeContainer.getBounds();

                child.x = child.margin.x;
                child.y =
                    lastNodeContainer.y +
                    lastChildBoundingBox.height +
                    child.margin.y;
            } else {
                child.x = child.margin.x;
                child.y = nodeBounds.height + child.margin.y;
            }

            lastNodeContainer = child;
        }
    }

    this.branch.calcBranch();

    //var bounds = this.getBounds();
    //this.

    if (this.parent instanceof NodeContainer) {
        this.parent.refreshLayout();
    }
};

/**
 * Removed Child from Container
 * @param {Object} child - Child to be removed
 */
NodeContainer.prototype.removeChild = function (child) {
    var index = this.branch.structureNodeList.indexOf(child);

    createjs.Container.prototype.removeChild.call(this, child);
    this.branch.structureNodeList.splice(index, 1);
    this.refreshLayout();
};

export default NodeContainer;
