import SelectableElement from "../common/SelectableElement";
import HoverableElement from "../common/HoverableElement";
import NodeEntity from "../model/NodeEntity";
import IconButton from "../common/IconButton";
import EditableText from "../common/EditableText";
import AnchorPoint from "../common/AnchorPoint";
import TextChangedEvent from "../events/TextChangedEvent";

/**
 * Class for Structure Nodes
 * * defines what to display
 * * how to display
 * @param {Object} data - Data displayed in Node
 * @param {WBS.NodeManager} nodeManager - NodeManager Instance that handles Nodes in WBS
 * @constructs StructureNode
 * @extends WBS.HoverableElement
 * @extends WBS.SelectableElement
 * @memberof WBS
 */
function StructureNode(data, nodeManager) {
    SelectableElement.call(this);

    //!!!IMPORTANT!!! multiple inheritance
    HoverableElement.call(this);

    this.onPixelRatioChanged = this._onPixelRatioChanged.bind(this);
    this.onScaleChanged = this._onScaleChanged.bind(this);

    this.createAddChildButton();
    this.createAddSiblingButton();

    this.createBackground();
    this.createProgressBackground();
    this.createNameText();
    this.createBigNameText();
    this.createCodeText();

    this.createDeleteNodeButton();

    // MB - deactivated while no date change is possible
    //
    // this.createStartDate();

    this.createAddComponentButton();

    this.createAnchorPoint();
    this.createBigAnchorPoint();

    this.entity = new NodeEntity(data.ID, nodeManager);
    this.entity.setData(data);

    // MB - deactivated while no date change is possible
    //
    //this.setStartDate("31.1.2015");

    var nodeData = this.entity.getData();
    this.setName(nodeData.NAME);
    this.setCode(nodeData.CODE);

    this.setBounds(-this.width / 2, -this.height / 2, this.width, this.height);

    this.enableHitArea();

    //this.mouseEnabled = true;
    //this.mouseChildren = false;

    this.calcCacheBounds();

    this.on("added", this._onAddedToStage);
    this.on("removed", this._onRemovedFromStage);
    this.on("tick", this._onTick);
    this.snapToPixel = true;

    this.on("selected", this.onSelected);
    this.on("deselected", this.onDeselected);

    this.on("downLight", this.onDownLight);
    this.on("upLight", this.onUpLight);

    this.LODActivated = false;

    //bind all state change events
    this.stateChangeEvents.forEach(function (type) {
        this.on(type, this.onStateChanged);
    }, this);

    this.on("focus", this.onFocus.bind(this));
    this.on("blur", this.onBlur.bind(this));

    this.on("mouseIn", this.onMouseIn);
    this.on("mouseOut", this.onMouseOut);

    this.addComponentButton.on("tap", this._onAddComponent, this);
    this.addChildButton.on("tap", this._onAddChild, this);
    this.addSiblingButton.on("tap", this._onAddSibling, this);
    this.deleteNodeButton.on("tap", this._onDeleteNode, this);
    this.anchorPoint.on("tap", this._onTapAnchorPoint, this);
    this.bigAnchorPoint.on("tap", this._onTapAnchorPoint, this);
}

StructureNode.parseServerResponse = function (newElement) {
    return {
        ID: newElement.id,
        CODE: newElement.code,
        NAME: newElement.name,
        PROJECT_ID: newElement.project_id,
        PARENT_ID: newElement.parent_id,
    };
};

StructureNode.prototype = Object.create(SelectableElement.prototype);

/**
 * Determines if it is a Root Node or not
 * @default
 * @type {boolean}
 */
StructureNode.prototype.isProjectNode = false;

/**
 * Determines if it is a Horizontal Node (Depth = 1) or Vertical Node (other)
 * @type {boolean}
 */
StructureNode.prototype.isHorizontalNode = false;

/**
 * enable caching
 * @inheritDoc
 * @type {boolean}
 */
StructureNode.prototype.shouldCache = true;

/**
 * enable visibility check if the element is really on the stage
 * @type {boolean}
 */
StructureNode.prototype.checkIsOnStage = true;

/**
 * Background Color - any valid CSS Color
 * @default
 * @type {string}
 */
StructureNode.prototype.backgroundColor = "#365781";

/**
 * Backgound hover color - any valid CSS Color
 * @default
 * @type {string}
 */
StructureNode.prototype.backgroundColorHover = "#2B4667";

/**
 * Text Color - any valid CSS Color
 * @default
 * @type {string}
 */
StructureNode.prototype.textColor = "#ffffff";

/**
 * Normal Text Size - any valid CSS Size
 * @default
 * @type {string}
 */
StructureNode.prototype.textSize = "18px";

/**
 * Small Text Size - any valid CSS Size
 * @default
 * @type {string}
 */
StructureNode.prototype.smallTextSize = "14px";

/**
 * width of the element
 * @default
 * @type {number}
 */
StructureNode.prototype.width = 256;

/**
 * height of the element
 * @default
 * @type {number}
 */
StructureNode.prototype.height = 64;

/**
 * inner padding
 * does not change the width and height of the element!!
 * @type {{top: number, right: number, bottom: number, left: number}}
 */
StructureNode.prototype.padding = {
    top: 10,
    right: 30,
    bottom: 14,
    left: 20,
};

/**
 * Corner radius for round background corners
 * @type {number}
 */
StructureNode.prototype.cornerRadius = 4;

/**
 * Corner radius for buttons
 * @type {number}
 */
StructureNode.prototype.buttonCornerRadius = 4;

/**
 * The size of the blurring effect
 * @type {number}
 */
StructureNode.prototype.shadowSize = 4;

/**
 * the height of the progress bar
 * @type {number}
 */
StructureNode.prototype.progressBarHeight = 4;

/**
 * the last stage, used for delete event listener
 * @type {EditorStage}
 * @private
 */

/**
 * indicates if the node is currently down light
 * @type {boolean}
 */
StructureNode.prototype.downLight = false;

StructureNode.prototype._lastStage = null;

/**
 * @default
 * @type {string[]}
 */
StructureNode.prototype.stateChangeEvents = [
    "selected",
    "deselected",
    "mouseIn",
    "mouseOut",
];

/**
 *
 * @type {boolean}
 */
StructureNode.prototype.hoverable = true;

/**
 * Handles Adding to Stage
 * @private
 */
StructureNode.prototype._onAddedToStage = function () {
    if (!this.stage || this._lastStage) {
        return;
    }

    this._lastStage = this.stage;

    // set i18n name of the tooltips
    this.addChildButton.tooltipText = this._concatControlButtonText(
        "addChild",
        this.stage.getI18nFor("CREATE_CHILD_TOOLTIP")
    );
    this.addSiblingButton.tooltipText = this._concatControlButtonText(
        "addSibling",
        this.stage.getI18nFor("CREATE_SIBLING_TOOLTIP")
    );
    this.deleteNodeButton.tooltipText = this._concatControlButtonText(
        "delete",
        this.stage.getI18nFor("DELETE_NODE_TOOLTIP")
    );

    this.refreshDeleteNodeButton();
    this.refreshAddChildButton();
    this.refreshAddSiblingButton();

    this.stage.on("pixelRatioChanged", this._onPixelRatioChanged, this);
    this.stage.on("scaleChanged", this._onScaleChanged, this);

    this.onPixelRatioChanged();
};

/**
 * Handles Removing from Stage
 * @private
 */
StructureNode.prototype._onRemovedFromStage = function () {
    if (!this._lastStage) {
        return;
    }
    this._lastStage.off("pixelRatioChanged", this._onPixelRatioChanged);
    this._lastStage.off("scaleChanged", this._onScaleChanged);
    this._lastStage = null;
};

/**
 * Handles Change of Pixel Ratio
 * @private
 */
StructureNode.prototype._onPixelRatioChanged = function () {
    this.uncache();
    this.calcCacheScale();
};

/**
 * Handles Change of Scale
 * @private
 */
StructureNode.prototype._onScaleChanged = function () {
    this.calcCacheScale();
    this.invalidateCache();
};

/**
 * Handles Click on Add Component Button
 * @private
 */
StructureNode.prototype._onAddComponent = function () {
    var addComponentsEvent = new createjs.Event("addComponents", true, false);
    this.dispatchEvent(addComponentsEvent);
};

/**
 * Handles Click on Add Child Button
 * @private
 */
StructureNode.prototype._onAddChild = function () {
    var addChildEvent = new createjs.Event("addChild", true, false);
    this.dispatchEvent(addChildEvent);
};

/**
 * Handles Click on Add Sibling Button
 * @private
 */
StructureNode.prototype._onAddSibling = function () {
    var addSiblingEvent = new createjs.Event("addSibling", true, false);
    this.dispatchEvent(addSiblingEvent);
};

/**
 * Handles Click on Delete Button
 * @private
 */
StructureNode.prototype._onDeleteNode = function () {
    var deleteNodeEvent = new createjs.Event("delete", true, false);
    this.dispatchEvent(deleteNodeEvent);
};

/**
 * Handles Click on Anchor Point
 * @private
 */
StructureNode.prototype._onTapAnchorPoint = function () {
    var tapAnchorPointEvent = new createjs.Event("tapAnchorPoint", true, false);
    this.dispatchEvent(tapAnchorPointEvent);
};

/**
 * Handles Tick
 * @private
 */
StructureNode.prototype._onTick = function () {};

/**
 * Handles on Focus Event
 * @param {Object} event - onFocus Event
 */
StructureNode.prototype.onFocus = function (event) {
    event.stopPropagation();
};

/**
 * Handles Defocus Event
 * @param {Object} event - onBlur Event
 */
StructureNode.prototype.onBlur = function (event) {
    event.stopPropagation();
};

/**
 * Handles MouseIn Event
 * @param {Object} event - MouseIn Event
 */
StructureNode.prototype.onMouseIn = function (event) {
    event.stopPropagation();
};

/**
 * Handles MouseOutEvent
 * @param {Object} event - MouseOut Event
 */
StructureNode.prototype.onMouseOut = function (event) {
    event.stopPropagation();
};

/**
 * Handles Select
 */
StructureNode.prototype.onSelected = function () {
    this.disableHitArea();
};

/**
 * Handles Deselect
 */
StructureNode.prototype.onDeselected = function () {
    this.enableHitArea();
};

/**
 * Calculates Cache Scale
 */
StructureNode.prototype.calcCacheScale = function () {
    if (!this.stage) {
        return;
    }
    this.cacheScale = this.stage.pixelRatio * this.stage.viewport.scale;
    //
    //   console.log('cache scale', this.cacheScale);

    var LODTriggerScale = 0.8;
    if (this.cacheScale < LODTriggerScale && !this.LODActivated) {
        this.showNoDetailNode();
    } else if (this.cacheScale >= LODTriggerScale && this.LODActivated) {
        this.showDetailNode();
    }
};

/**
 * Create Shape for Background
 */
StructureNode.prototype.createBackground = function () {
    var shape = new createjs.Shape();

    var height = this.height;
    var width = this.width;

    var g = shape.graphics;

    shape.color = g.beginFill(this.backgroundColor).command;

    g.drawRoundRect(
        -width / 2,
        -height / 2,
        width,
        height,
        this.cornerRadius
    ).closePath();

    shape.shadow = new createjs.Shadow(
        "rgba(0,0,0,0.3)",
        0,
        0,
        this.shadowSize
    );

    this.background = shape;

    this.addChild(shape);
};

/**
 * Creates Progress Bar
 * @param {number} [progress] - Progress of Structure Node
 */
StructureNode.prototype.createProgressBackground = function (progress) {
    progress = progress || 1.0;

    var shape = new createjs.Shape();

    var g = shape.graphics;

    shape.color = g.beginFill("rgba(255,255,255,0.8)").command;

    var height = this.progressBarHeight;
    var width = Math.round(this.width * progress); // for real progress, replace 1

    g.drawRoundRectComplex(
        -this.width / 2,
        this.height / 2 - height,
        width,
        height,
        0,
        0,
        0,
        height
    ).closePath();

    this.progress = shape;

    this.addChild(shape);
};

/**
 * Method to create a new Button with Tooltips and add it as a child to the StructureNode.
 * The initial visibility of the button is set to NOT visible.
 *
 * @param {string} tooltipSymbol - UTF-8 encoded symbol for keyboard shortcut.
 * @param {string[]} tooltipTexts - First string entry is for Mac, second for Windows.
 * @param {number} cornerRadius - Corner Radius of IconButton
 *
 * @returns {WBS.IconButton} Instance of IconButton
 * @private
 */
StructureNode.prototype._createControlButton = function (
    tooltipSymbol,
    tooltipTexts,
    cornerRadius
) {
    var button = new IconButton(tooltipSymbol, tooltipTexts, cornerRadius);

    button.visible = false;
    this.addChild(button);

    return button;
};

StructureNode.prototype._concatControlButtonText = function (key, text) {
    var isMac = navigator.appVersion.indexOf("Mac") > -1;

    switch (key) {
        case "addChild":
            return text + " " + (isMac ? "[Tab]" : "[Insert/Einfg.]");
        case "addSibling":
            return text + " " + (isMac ? "[Enter]" : "[Enter]");
        case "delete":
            return text + " " + (isMac ? "[Backspace]" : "[Del./Entf.]");
        default:
            return text;
    }
};

/**
 * Creates Button to Add Components
 */
StructureNode.prototype.createAddComponentButton = function () {
    var button = this._createControlButton(
        // mdi cube outline
        0xf01a6,
        this._concatControlButtonText("", "")
    );

    button.width = button.height = 22;
    button.iconSize = "16px";
    button.iconColor = "#fff";
    button.iconColorHover = "#FEDF3F"; //'#fff';
    button.backgroundColor = "transparent";
    button.backgroundColorHover = "transparent"; //'#36B381';
    button.borderColor = "transparent";
    button.borderColorHover = "transparent"; //'#36B381';
    button.hitAreaPadding = 5;
    button.refresh();

    button.x = this.width / 2 - button.width / 2 - button.hitAreaPadding;
    button.y =
        this.height / 2 -
        this.progressBarHeight -
        button.height / 2 -
        button.hitAreaPadding;

    this.addChild(button);
    this.addComponentButton = button;
};

/**
 * Creates Button to add a Child
 *
 * @returns {WBS.IconButton|undefined} returns the created button or undefined
 */
StructureNode.prototype.createAddChildButton = function () {
    if (this.addChildButton) {
        return;
    }

    // mdi arrow down
    var button = this._createControlButton(
        0xf0045,
        this._concatControlButtonText("addChild", "Add Sub-Category"),
        {
            bottomRight: this.buttonCornerRadius,
            bottomLeft: this.buttonCornerRadius,
        }
    );

    button.helperLine = new createjs.Shape();

    button.addChildAt(button.helperLine, 0);

    this.addChildButton = button;
    //this.refreshAddChildButton();
    return button;
};

/**
 * Repositions Add Child Button
 */
StructureNode.prototype.refreshAddChildButton = function () {
    var button = this.addChildButton;
    // mdi plus
    button.setIcon(String.fromCodePoint(0xf0415));
    button.y = this.height / 2 + button.height / 2 + 6;

    if (this.isProjectNode) {
        button.x = 0;
    } else {
        button.x = -this.width / 2 + button.width / 2 + this.cornerRadius;
    }

    button.helperLine.graphics
        .clear()
        .setStrokeStyle(4, "square")
        .beginStroke("#E4E4E4")
        .moveTo(0, 0)
        .lineTo(0, -button.height / 2 - 6);
};

/**
 * Creates a Button to add a Sibling
 * @returns {WBS.IconButton|undefines} returns the created button or undefined
 */
StructureNode.prototype.createAddSiblingButton = function () {
    if (this.addSiblingButton) {
        return;
    }

    var button = this._createControlButton(
        //mdi plus
        0xf0415,
        this._concatControlButtonText("addSibling", "Add Related Category"),
        {
            bottomRight: this.buttonCornerRadius,
            bottomLeft: this.buttonCornerRadius,
        }
    );

    this.addSiblingButton = button;
    //this.refreshAddSiblingButton();
    return button;
};

/**
 * Repositions Add Sibling Button
 */
StructureNode.prototype.refreshAddSiblingButton = function () {
    var button = this.addSiblingButton;

    if (this.isHorizontalNode) {
        // mdi plus
        button.setIcon(String.fromCodePoint(0xf0415));
        button.y = -this.height / 2 - button.height / 2 - button.hitAreaPadding;
        button.x = 0;
        button.setCornerRadius({
            topLeft: this.buttonCornerRadius,
            topRight: this.buttonCornerRadius,
        });
    } else {
        button.y = 0;
        button.x = -this.width / 2 - button.width / 2 - 5;
        button.setCornerRadius({
            topLeft: this.buttonCornerRadius,
            bottomLeft: this.buttonCornerRadius,
        });
    }
};

/**
 * Creates a button to delete the Node
 * @returns {WBS.IconButton|undefines} returns the created button or undefined
 */
StructureNode.prototype.createDeleteNodeButton = function () {
    if (this.deleteNodeButton) {
        return;
    }

    var button = this._createControlButton(
        // mdi delete
        0xf01b4,
        this._concatControlButtonText("delete", "Delete Category"),
        {
            topRight: this.buttonCornerRadius,
            topLeft: this.buttonCornerRadius,
        }
    );
    button.width = button.height = 22;
    button.iconSize = "16px";
    button.iconColor = "#fff";
    button.iconColorHover = "#FF5E61"; //'#fff';
    button.backgroundColor = "transparent";
    button.backgroundColorHover = "transparent"; //'#FF5E61';
    button.borderColor = "transparent";
    button.borderColorHover = "transparent"; //'#FF5E61';
    button.hitAreaPadding = 5;
    button.refresh();

    this.deleteNodeButton = button;
    //this.refreshDeleteNodeButton();
    return button;
};

/**
 * Repositions Delete Node Button
 */
StructureNode.prototype.refreshDeleteNodeButton = function () {
    var button = this.deleteNodeButton;

    button.x = this.width / 2 - button.width / 2 - button.hitAreaPadding;
    button.y = -this.height / 2 + button.height / 2 + button.hitAreaPadding;
};

/**
 * Create an AnchorPoint
 */
StructureNode.prototype.createAnchorPoint = function () {
    var anchorPoint = new AnchorPoint();

    anchorPoint.x = this.width / 2;
    anchorPoint.y = 0;

    anchorPoint.visible = false;

    this.addChild(anchorPoint);
    this.anchorPoint = anchorPoint;
};

/**
 * Create Anchor Point for small Level of Detail
 */
StructureNode.prototype.createBigAnchorPoint = function () {
    var anchorPoint = new AnchorPoint(true);

    anchorPoint.x = this.width / 2;
    anchorPoint.y = 0;

    anchorPoint.visible = false;

    this.addChild(anchorPoint);
    this.bigAnchorPoint = anchorPoint;
};

/**
 * Create TextField where Name is displayed/can be edited on click
 * @returns {createjs.Text}
 */
StructureNode.prototype.createNameText = function () {
    var text = this._createText();
    text.textAlign = "left";
    text.textBaseline = "bottom";

    var editableText = new EditableText(text);
    editableText.x = -(this.width / 2) + this.padding.left;
    editableText.y = this.height / 2 - this.padding.bottom;
    editableText.width = this.width - this.padding.left - this.padding.right;
    editableText.height = 22;
    editableText.calcBoundingBox();

    editableText.enableHitArea();

    editableText.on("textChanged", this.onNameChanged.bind(this));

    this.addChild(editableText);
    this.editableName = editableText;
    this.name = text;
    return text;
};

/**
 * Create TextField where Code is displayed/can be edited on click
 * @returns {createjs.Text}
 */
StructureNode.prototype.createCodeText = function () {
    var text = this._createText(this.smallTextSize);

    text.textAlign = "left";
    text.textBaseline = "top";

    var editableText = new EditableText(text);
    editableText.x = -(this.width / 2 - this.padding.left);
    editableText.y = -this.height / 2 + this.padding.top;
    editableText.width = this.width - this.padding.left - this.padding.right;
    editableText.height = 16;
    editableText.calcBoundingBox();
    editableText.enableHitArea();

    editableText.on("textChanged", this.onCodeChanged.bind(this), this);

    this.addChild(editableText);

    this.editableCode = editableText;
    this.code = text;
    return text;
};

/**
 * Create TextField where Name is displayed on small Level of Detail
 * @returns {createjs.Text}
 */
StructureNode.prototype.createBigNameText = function () {
    var text = this._createText();
    text.textAlign = "center";

    text.visible = false;
    text.font =
        '24px "Work Sans, Helvetica Neue, Helvetica, Arial, sans-serif"';
    text.height = 50;

    this.addChild(text);
    this.bigName = text;
    return text;
};

/**
 * Create TextField that displays startDate
 * @returns {createjs.Text}
 */
StructureNode.prototype.createStartDate = function () {
    var text = this._createText(this.smallTextSize);

    text.textAlign = "right";
    text.textBaseline = "top";

    text.x = this.width / 2 - this.padding.right;
    text.y = -this.height / 2 + this.padding.top;
    this.addChild(text);

    this.startDate = text;
    return text;
};

/**
 * Sets Name to Text Displayed on Name Text Field
 * @param {string} name - Name of Node
 */
StructureNode.prototype.setName = function (name) {
    this.editableName.setText(name || "");
    this.bigName.text = name || "";
};

/**
 * Sets Code to Code displayed on Code TextField
 * @param {number} code - Code of Node
 */
StructureNode.prototype.setCode = function (code) {
    this.editableCode.setText(code || "");
};

/**
 * Set Start Date that is displayed on Node
 * @param {string} startDate - StartDate of Node
 */
StructureNode.prototype.setStartDate = function (startDate) {
    this.startDate.text = startDate;
};

/**
 * Calculates the Bounds that should be cached
 */
StructureNode.prototype.calcCacheBounds = function () {
    var b = this.cacheBounds;

    //normal height
    b.x = -this.width / 2;
    b.y = -this.height / 2;
    b.width = this.width;
    b.height = this.height;

    //Add margin for shadow
    if (this.background.shadow) {
        b.x -= this.shadowSize;
        b.y -= this.shadowSize;
        b.width += this.shadowSize * 2;
        b.height += this.shadowSize * 2;
    }

    if (this.addSiblingButton.visible) {
        if (this.isHorizontalNode) {
            var addSiblingButtonHeight =
                this.addSiblingButton.height +
                this.addSiblingButton.hitAreaPadding +
                2;
            if (this.background.shadow) {
                addSiblingButtonHeight -= this.shadowSize;
            }
            b.height += addSiblingButtonHeight;
            b.y -= addSiblingButtonHeight;
        } else if (!this.isProjectNode) {
            var addSiblingButtonWidth =
                this.addSiblingButton.width +
                this.addSiblingButton.hitAreaPadding;
            if (this.background.shadow) {
                addSiblingButtonWidth -= this.shadowSize;
            }
            b.width += addSiblingButtonWidth;
            b.x -= addSiblingButtonWidth;
        }
    }

    if (this.addChildButton.visible) {
        var addChildButtonSize =
            this.addChildButton.height + this.addChildButton.hitAreaPadding;
        if (this.background.shadow) {
            addChildButtonSize -= this.shadowSize;
        }
        b.height += addChildButtonSize;
    }

    if (this.anchorPoint.visible) {
        b.width += this.anchorPoint.radius;
    } else if (this.bigAnchorPoint.visible) {
        b.width += this.bigAnchorPoint.radius;
    }
};

/**
 * Dispatches the Added to Stage Event
 */
StructureNode.prototype.dispatchAddedToStage = function () {
    this._onAddedToStage({});
};

/**
 * Dispatches an Event when Name is changed
 * @param {WBS.TextChangedEvent} textChangedEvent - Event fired when Text is Changed
 * @private
 */
StructureNode.prototype.onNameChanged = function (textChangedEvent) {
    if (!this.stage) {
        return;
    }
    textChangedEvent.stopPropagation();

    var nameChangedEvent = new TextChangedEvent(
        this,
        textChangedEvent.oldText,
        textChangedEvent.newText
    );
    nameChangedEvent.type = "nameChanged";

    this.dispatchEvent(nameChangedEvent);
    this.uncache();
};

/**
 * Dispatches an Event when Code is changed
 * @param {TextChangedEvent} textChangedEvent - Event fired when Text is Changed
 * @private
 */
StructureNode.prototype.onCodeChanged = function (textChangedEvent) {
    if (!this.stage) {
        return;
    }
    textChangedEvent.stopPropagation();

    var codeChangedEvent = new TextChangedEvent(
        this,
        textChangedEvent.oldText,
        textChangedEvent.newText
    );
    codeChangedEvent.type = "codeChanged";

    this.dispatchEvent(codeChangedEvent);
    this.uncache();
};

/**
 * Handles Change of State
 */
StructureNode.prototype.onStateChanged = function () {
    this.uncache();

    if (!this.stage) {
        return;
    }
    var settings = this.stage.currentSettings;

    if (this.selected || this.mouseIn) {
        this.background.color.style = this.backgroundColorHover;
    } else {
        this.background.color.style = this.backgroundColor;
    }

    //check hit area
    var isHitAreaEnabled = !!this.hitArea;
    var shouldHitAreaEnable = !(this.mouseIn || this.selected);
    if (isHitAreaEnabled && !shouldHitAreaEnable) {
        this.disableHitArea();
    } else if (!isHitAreaEnabled && shouldHitAreaEnable) {
        this.enableHitArea();
    }

    this.addChildButton.visible =
        (this.mouseIn || this.selected) && settings.modifyNodes;
    this.addSiblingButton.visible =
        (this.mouseIn || this.selected) &&
        settings.modifyNodes &&
        !this.isProjectNode;
    this.deleteNodeButton.visible =
        !this.isProjectNode &&
        (this.mouseIn || this.selected) &&
        settings.modifyNodes;

    if (this.LODActivated) {
        this.bigAnchorPoint.visible =
            (this.mouseIn || this.selected) &&
            !this.isProjectNode &&
            !settings.modifyEdges;
        this.anchorPoint.visible = this.addComponentButton.visible = false;
    } else {
        //add button and normal anchor button visible if mouseIn or selected
        this.anchorPoint.visible =
            (this.mouseIn || this.selected) &&
            !this.isProjectNode &&
            settings.modifyEdges;
        this.addComponentButton.visible =
            (this.mouseIn || this.selected) &&
            !this.isProjectNode &&
            settings.assignComponents;

        this.bigAnchorPoint.visible = false;
    }

    this.calcCacheBounds();
};

/**
 * Displays a low Level of Detail version of Node
 */
StructureNode.prototype.showNoDetailNode = function () {
    // MB - deactivated while LOD is not working perfect
    //
    //this.LODActivated = true;
    //
    //this.name.visible = false;
    //this.code.visible = false;
    //
    //this.bigName.visible = true;
    //
    ////check if hover elements are affected
    //this.onStateChanged();
};

/**
 * Displays all the Details on Node
 */
StructureNode.prototype.showDetailNode = function () {
    // MB - deactivated while LOD is not working perfect
    //
    //this.LODActivated = false;
    //
    //this.name.visible = true;
    //this.code.visible = true;
    //
    //this.bigName.visible = false;
    //
    ////check if hover elements are affected
    //this.onStateChanged();
};

/**
 * Handles downlighting of node
 */
StructureNode.prototype.onDownLight = function () {
    this.downLight = true;
    this.alpha = 0.5;
    this.invalidateCache();
};

/**
 *Handles Uplighting of Node
 */
StructureNode.prototype.onUpLight = function () {
    this.downLight = false;
    this.alpha = 1;
    this.invalidateCache();
};

export default StructureNode;
