import angular from "angular";
import has from "has";
import "common/services/services";

/**
 * Return the DOM siblings between the first and last node in the given array.
 * @param {Array} array like object
 * @returns {Array} the inputted object or a jqLite collection containing the nodes
 */
function getBlockNodes(nodes) {
    // TODO(perf): update `nodes` instead of creating a new object?
    var node = nodes[0];
    var endNode = nodes[nodes.length - 1];
    var blockNodes;

    for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
        if (blockNodes || nodes[i] !== node) {
            if (!blockNodes) {
                blockNodes = angular.element(
                    Array.prototype.slice.call(nodes, 0, i)
                );
            }
            blockNodes.push(node);
        }
    }

    return blockNodes || nodes;
}

export default angular
    .module("sbApp.permissions", ["sbApp.services"])
    .directive(
        "sbHasPermission",
        function HasPermissionsDirective(
            ROLE_NAMES,
            $sbPermission,
            $animate,
            $compile,
            $sbProject
        ) {
            return {
                multiElement: true,
                transclude: "element",
                priority: 600,
                terminal: true,
                restrict: "A",
                $$tlb: true,
                link: link,
            };

            function link($scope, $element, $attr, ctrl, $transclude) {
                // copy of https://github.com/angular/angular.js/blob/master/src/ng/directive/ngIf.js#L3
                //
                var block, childScope, previousElements;
                $scope.$watch(
                    $attr.sbHasPermission,
                    function sbHasPermissionWatchAction(value) {
                        var project = $sbProject.getCurrent();

                        var privileges = project.privileges;
                        var permission = false;

                        switch (value) {
                            case ROLE_NAMES.ADMIN: {
                                permission =
                                    $sbPermission.hasAdminPermissions(
                                        privileges
                                    );
                                break;
                            }
                            case ROLE_NAMES.MANAGER: {
                                permission =
                                    $sbPermission.hasPlanPermissions(
                                        privileges
                                    );
                                break;
                            }
                            case ROLE_NAMES.CONTROLLER: {
                                permission =
                                    $sbPermission.hasControllerPermissions(
                                        privileges
                                    );
                                break;
                            }
                            case ROLE_NAMES.REVIEWER: {
                                permission =
                                    $sbPermission.hasReviewPermissions(
                                        privileges
                                    );
                                break;
                            }
                            case ROLE_NAMES.INSPECTOR: {
                                permission =
                                    $sbPermission.hasWorkPermissions(
                                        privileges
                                    );
                                break;
                            }
                            case "DEV": {
                                permission = has("development");
                                break;
                            }
                            case "BETA": {
                                permission = has("development");
                                break;
                            }
                            case "ACTIVITY_CREATOR": {
                                permission = $sbPermission.hasPermission(
                                    privileges,
                                    "CREATE_ACTIVITY"
                                );
                                break;
                            }
                            default:
                                permission = false;
                        }

                        // ngIf logic
                        //
                        if (permission) {
                            if (!childScope) {
                                $transclude(function (clone, newScope) {
                                    childScope = newScope;
                                    clone[clone.length++] =
                                        $compile.$$createComment(
                                            "end sbHasPermission",
                                            $attr.ngIf
                                        );
                                    // Note: We only need the first/last node of the cloned nodes.
                                    // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                                    // by a directive with templateUrl when its template arrives.
                                    block = {
                                        clone: clone,
                                    };
                                    $animate.enter(
                                        clone,
                                        $element.parent(),
                                        $element
                                    );
                                });
                            }
                        } else {
                            if (previousElements) {
                                previousElements.remove();
                                previousElements = null;
                            }
                            if (childScope) {
                                childScope.$destroy();
                                childScope = null;
                            }
                            if (block) {
                                previousElements = getBlockNodes(block.clone);
                                $animate
                                    .leave(previousElements)
                                    .done(function (response) {
                                        if (response !== false) {
                                            previousElements = null;
                                        }
                                    });
                                block = null;
                            }
                        }
                    }
                );
            }
        }
    );
