import _ from "lodash";
import naturalSortFn from "common/filters/natural_sort.pojo";

export default function sbDeliverableNotesCtrl(
    SbNote,
    $sbIssue,
    $sbDomain,
    EVENTS,
    $rootScope,
    $scope,
    $log,
    $sbErrorPresenter,
    $sbDeliverableNoteRowMapper,
    $sbDeliverablesNoteStateService
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.data = undefined;
    vm.groups = [];
    vm.onStateChange = onStateChange;
    vm.addNoteToListAndSummary = addNoteToListAndSummary;

    vm.$onChanges = $onChanges;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    const noteChangeListener = $rootScope.$on(
        EVENTS.NOTE_CHANGED,
        ($event, changeEvent) => {
            if (changeEvent.isCreateEvent()) {
                handleNewNoteCreated(changeEvent);
            } else {
                _changeNoteStatusInListAndSummary(changeEvent.after);
            }
        }
    );

    $scope.$on("$destroy", function () {
        noteChangeListener();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function onStateChange(note, oldValue, newValue) {
        return $sbDeliverablesNoteStateService
            .onStateChange(note, oldValue, newValue)
            .catch($sbErrorPresenter.catch);
    }

    function $onChanges(changes) {
        if (changes.notes) {
            if (Array.isArray(vm.notes)) {
                const mappedNotes = vm.notes.map((note) =>
                    $sbDeliverableNoteRowMapper.toViewModel(note)
                );

                vm.data = _.sortBy(mappedNotes, [
                    "ISSUE_STATUS",
                    "LAST_UPDATE_TIME",
                ]);

                vm.data.reverse();

                vm.summary = $sbIssue.createIssuesSummary(vm.data);
                vm.summaryLegend = _createSummaryLegend(vm.summary);

                const notesGroupedByRef = _.groupBy(
                    vm.data,
                    "REF_COMPONENT_ID"
                );
                const groups = [];
                for (const key in notesGroupedByRef) {
                    groups.push({
                        id: key,
                        name: notesGroupedByRef[key][0].REF_COMPONENT_NAME,
                        topologicalIndex:
                            notesGroupedByRef[key][0]
                                .REF_COMPONENT_TOPOLOGICAL_INDEX,
                        notes: notesGroupedByRef[key],
                    });
                }
                vm.groups = groups.sort((a, b) => {
                    if (a.topologicalIndex === b.topologicalIndex) {
                        return naturalSortFn(a.name, b.name);
                    }
                    return a.topologicalIndex - b.topologicalIndex;
                });
            }
        }
    }

    function _createSummaryLegend(summary) {
        return [
            {
                text: "_OPEN_CLAIMS",
                value: summary.claims.open,
                iconClasses: "sb-icon sb-icon-status_alert sb-claim",
            },
            {
                text: "_OPEN_OBSTRUCTIONS",
                value: summary.obstructions.open,
                iconClasses: "sb-icon sb-icon-status-problem sb-obstruction",
            },
            {
                text: "_CLOSED_CLAIMS",
                value: summary.claims.closed,
                iconClasses: "sb-icon sb-icon-status_alert sb-issue-closed",
            },
            {
                text: "_CLOSED_OBSTRUCTIONS",
                value: summary.obstructions.closed,
                iconClasses: "sb-icon sb-icon-status-problem sb-issue-closed",
            },
            {
                text: "_INFO_S",
                value: summary.infos.total,
                iconClasses: "sb-icon sb-icon-status_info sb-info",
            },
        ];
    }

    function addNoteToListAndSummary(note) {
        vm.data = vm.data || [];
        vm.data.unshift(note);
        vm.summary = $sbIssue.createIssuesSummary(vm.data);
        vm.summaryLegend = _createSummaryLegend(vm.summary);
        return note;
    }

    function _changeNoteStatusInListAndSummary(note) {
        const noteThatChangedStatus = _.find(vm.data, function (element) {
            return element.ISSUE_ID === note.id;
        });

        if (!noteThatChangedStatus) {
            return;
        }

        noteThatChangedStatus.ISSUE_STATUS = $sbIssue.mapApiToNoteState(
            note.state
        );

        vm.summary = $sbIssue.createIssuesSummary(vm.data);
        vm.summaryLegend = _createSummaryLegend(vm.summary);
    }

    /**
     * Will be called on note change events.
     * Currently, only handles creation events.
     *
     * Will update the list with the created note or fetch new list from server as fallback.
     *
     * @param changeEvent
     *
     * @private
     */
    function handleNewNoteCreated(changeEvent) {
        const newNote = changeEvent.after;

        // if deliverable in the overlay is different from the deliverable of the new note
        //  -> skip
        if (newNote._deliverable.id !== vm.deliverable.id) {
            return;
        }

        if (newNote instanceof SbNote && newNote.__odataSource) {
            const odataRepresentation = newNote.__odataSource;
            // workaround to support $sbIssue.getIssuesForDeliverable() where we do the same
            // can be removed if this component works fully based on SbNote
            odataRepresentation.RESPONSIBLE_TEAM = newNote.getResponsibleTeam();

            addNoteToListAndSummary(odataRepresentation);
        } else {
            // fallback to fetch if no odata representation is provided (needed for rendering issue row)
            //
            // TODO loadIssues(vm.deliverableId);
        }
    }
}
