import angular from "angular";
import visualizationListRouteConfig from "./visualizations.route";
import visualizationListCtrl from "./visualizations.controller";
import visualizationsService from "./services/visualizations.service";
import sketchVisualizationService from "./services/sketch_visualization.service";
import sketchGeneratorService from "./services/sketch_generator.service";
import svgService from "./services/svg.service";
import svgGenerationService from "./services/svg_generation.service";
import SketchVisualizationController from "./states/sketchVisualization/sketch_visualization.controller";
import VisualizationConfigController from "./states/visualizationConfig/visualization_config.controller";
import svgComponent from "./components/sbSvg/svg.component";
import legendComponent from "./components/sbLegend/legend.component";
import searchableSelectComponent from "./components/sbSearchableSelect/searchable_select.component";
import sbSvgConfigCardComponent from "./components/sbSvgConfigCard/svg_config_card.component";
import singleActionCardComponent from "./components/sbSingleActionCard/single_action_card.component";
import registerModelClasses from "./models/models.index";
import fullscreenCardComponent from "./components/sbFullscreenCard/fullscreen_card.component";
import fullscreenService from "./services/fullscreen.service";
import deliverablePanelTooltipService from "./services/deliverable_panel_tooltip.service";
import visualizationSettingService from "./components/sbSettingsPanel/sb_visualization_setting.service";
import visualizationSettingMenu from "./components/sbSettingsPanel/sb_visualization_setting_menu.component";

import apiModule from "../../common/services/api/api.module";

// define module
//
var visualizationModule = angular
    .module("sbApp.VisualizationModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.filters",
        "sbApp.sbImageCard",
        apiModule.name,
    ])
    .config(visualizationListRouteConfig)
    .controller("visualizationListCtrl", visualizationListCtrl)
    .factory("visualizationService", visualizationsService)
    .factory("$sbSketchGenerator", sketchGeneratorService)
    .factory("$sbSketchVisualization", sketchVisualizationService)
    .factory("$sbSvg", svgService)
    .factory("$sbSvgGenerate", svgGenerationService)
    .factory("$sbFullscreenService", fullscreenService)
    .factory("$sbDeliverablePanelTooltip", deliverablePanelTooltipService)
    .factory("$sbSketchVisualizationSetting", visualizationSettingService)
    .controller("SketchVisualizationCtrl", SketchVisualizationController)
    .controller("VisualizationConfigCtrl", VisualizationConfigController)
    .component("sbSvg", svgComponent)
    .component("sbLegend", legendComponent)
    .component("sbFullscreenCard", fullscreenCardComponent)
    .component("sbSearchableSelect", searchableSelectComponent)
    .component("sbSvgConfigCard", sbSvgConfigCardComponent)
    .component("sbSingleActionCard", singleActionCardComponent)
    .component("sbVisualizationSettingMenu", visualizationSettingMenu)
    .constant("MODES", {
        STAGE: {
            name: "STAGE",
            colorProperty: "STAGE_COLOR",
            noValueKey: "0_INFO_LEGEND_NO_STAGE",
            printTitleKey: "SECTION_VISUALIZATION_STAGE",
            requiresConfirmation: "WAITING_FOR_CONFIRMATION",
            rejected: "REJECTED",
            printGAKey: "Status visualization",
            modeGAKey: "Status",
        },
        BROWSE_ACTIVITY: {
            name: "BROWSE_ACTIVITY",
            colorProperty: "STAGE_REACHED_COLOR",
            noValueKey: "0_INFO_LEGEND_NO_STAGE",
            printTitleKey: "SECTION_VISUALIZATION_STAGE",
            printGAKey: "Browse activities visualization",
            modeGAKey: "Browse Activities",
        },
    });

//TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
// PROGRESS: {
//     name: "PROGRESS",
//         colorProperty: "PROGRESS_COLOR",
//         printTitleKey: "SECTION_VISUALIZATION_PROGRESS",
//         printGAKey: "Baseline visualization",
//         modeGAKey: "Baseline",
// },
// CURRENT: {
//     name: "CURRENT",
//         colorProperty: "CURRENT_ACTIVITY_COLOR",
//         noValueKey: "0_INFO_LEGEND_NO_STAGE",
//         printTitleKey: "SECTION_VISUALIZATION_CURRENT_ACTIVITY",
//         printGAKey: "Team visualization",
//         modeGAKey: "Teams",
// },

registerModelClasses(visualizationModule);

export default visualizationModule;
