/**
 * capsules and handles the access to node information
 * @constructs NodeManager
 * @memberof WBS
 **/
function NodeManager() {
    /**
     * contains all data of each node
     * @type {Object.<String, Array>}
     * @private
     */
    this._nodeData = {};
    /**
     * contains all incoming edges of each node
     * @type {Object.<String, Array>}
     * @private
     */
    this._incomingEdges = {};
    /**
     * contains all outgoing edges of each node
     * @type {Object.<String, Array>}
     * @private
     */
    this._outgoingEdges = {};
    /**
     * contains ids mapped to geometry
     * @type {Object.<String, NodeContainer>}
     * @private
     */
    this._idToGeometry = {};
}

/**
 * getter for incoming edges of specific node
 * @param {string} id - node id
 * @returns {Array} all incoming edges if id existing
 */
NodeManager.prototype.getIncomingEdges = function (id) {
    return this._incomingEdges[id] || [];
};

/**
 * getter for outgoing edges of specific node
 * @param {string} id - node id
 * @returns {Array} all outgoing edges if id existing
 */
NodeManager.prototype.getOutgoingEdges = function (id) {
    return this._outgoingEdges[id] || [];
};

/**
 * lets you set the geometry object of a node
 * @param {string} id - node id
 * @param {WBS.NodeContainer} geometry - new geometry
 */
NodeManager.prototype.setGeometry = function (id, geometry) {
    this._idToGeometry[id] = geometry;
};

/**
 * getter for geometry object of a node
 * @param {string} id - node id
 * @returns {(NodeContainer|undefined)} geometry if id existing, otherwise undefined
 */
NodeManager.prototype.getGeometry = function (id) {
    return this._idToGeometry[id];
};

/**
 * lets you set the data of a node
 * @param {string} id - node id
 * @param {Object} data - custom data
 */
NodeManager.prototype.setData = function (id, data) {
    this._nodeData[id] = data;
};

/**
 * getter for data of a node
 * @param {string} id - node id
 * @returns {(Object|undefined)} node data if id existing, otherwise undefined
 */
NodeManager.prototype.getData = function (id) {
    return this._nodeData[id];
};

/**
 * just pass the start and end id, the adding to outgoing edges and incoming edges
 * will be handled. If the start or end id is unknown to the manager, the edge won't be added.
 * if edge already exists, nothing will happen
 * @param {string} firstId - edge start
 * @param {string} secondId - edge end
 */
NodeManager.prototype.addEdge = function (firstId, secondId) {
    if (!firstId || !secondId) {
        return;
    }
    var ids = this.getAllIDs();
    if (ids.indexOf(firstId) === -1 || ids.indexOf(secondId) === -1) {
        return;
    }
    if (this.checkIfEdgeExists(firstId, secondId)) {
        return;
    }

    var outgoingEdge = this._outgoingEdges[firstId];
    if (outgoingEdge) {
        outgoingEdge.push(secondId);
    } else {
        this._outgoingEdges[firstId] = [secondId];
    }

    var incomingEdge = this._incomingEdges[secondId];
    if (incomingEdge) {
        incomingEdge.push(firstId);
    } else {
        this._incomingEdges[secondId] = [firstId];
    }
};

/**
 * just pass the edge start and end node id and the deleting to outgoing edges and incoming edges
 * will be handled
 * @param {string} firstId - edge start
 * @param {string} secondId - edge end
 */
NodeManager.prototype.deleteEdge = function (firstId, secondId) {
    if (!this.checkIfEdgeExists(firstId, secondId)) {
        return;
    }

    var indexOutgoing = this._outgoingEdges[firstId].indexOf(secondId);
    var indexIncoming = this._incomingEdges[secondId].indexOf(firstId);

    if (~indexOutgoing) {
        this._outgoingEdges[firstId].splice(indexOutgoing, 1);
    }
    if (~indexIncoming) {
        this._incomingEdges[secondId].splice(indexIncoming, 1);
    }
};

/**
 * checks if the edges exist
 * @param {string} firstId - edge start
 * @param {string} secondId - edge end
 * @returns {boolean} whether the edge exists or not
 */
NodeManager.prototype.checkIfEdgeExists = function (firstId, secondId) {
    if (
        !this._outgoingEdges[firstId] ||
        !this._incomingEdges[secondId] ||
        this._outgoingEdges[firstId].length === 0 ||
        this._incomingEdges[secondId].length === 0
    ) {
        return false;
    }
    var indexOutgoing = this._outgoingEdges[firstId].indexOf(secondId);
    var indexIncoming = this._incomingEdges[secondId].indexOf(firstId);

    return !!(~indexIncoming || ~indexOutgoing);
};

/**
 * getter for all ids
 * @returns {Array}
 */
NodeManager.prototype.getAllIDs = function () {
    var ids = [];
    for (var id in this._nodeData) {
        ids.push(id);
    }
    return ids;
};

/**
 * getter for all not connected node ids
 * @param {string} id - id of Node for which should be checked
 * @returns {Array}
 */
NodeManager.prototype.getNotConnectedNodeIDs = function (id) {
    var ids = this.getAllIDs();
    var incoming = this.getIncomingEdges(id) || [];
    var outgoing = this.getOutgoingEdges(id) || [];
    var connected = incoming.concat(outgoing);
    var selfIndex = ids.indexOf(id);
    ids.splice(selfIndex, 1);
    return ids.filter(function (localId) {
        return connected.indexOf(localId) === -1;
    });
};

/**
 * getter for all not connect node geometries
 * @param {string} id - id of Node for which should be checked
 * @returns {WBS.NodeContainer[]}
 */
NodeManager.prototype.getNotConnectedNodeGeometry = function (id) {
    return this.getNotConnectedNodeIDs(id).map(this.getGeometry.bind(this));
};

/**
 * getter for all not connect structure nodes
 * @param {String} id - Node Id
 * @returns {WBS.StructureNode[]}
 */
NodeManager.prototype.getNotConnectedStructureNodes = function (id) {
    return this.getNotConnectedNodeGeometry(id).map(function (geometry) {
        return geometry.node;
    });
};

/**
 * delete all references to the given id
 * @param {String} id - Id for which references should be deleted
 */
NodeManager.prototype.deleteNode = function (id) {
    var incomingEdges = this.getIncomingEdges(id);
    incomingEdges.forEach(function (nodeId) {
        var localOutgoingEdges = this.getOutgoingEdges(nodeId);
        var index = localOutgoingEdges.indexOf(id);
        this._outgoingEdges[nodeId].splice(index, 1);
    }, this);

    var outgoingEdges = this.getOutgoingEdges(id);
    outgoingEdges.forEach(function (nodeId) {
        var localIncomingEdges = this.getOutgoingEdges(nodeId);
        var index = localIncomingEdges.indexOf(id);
        this._incomingEdges[nodeId].splice(index, 1);
    }, this);

    delete this._outgoingEdges[id];
    delete this._incomingEdges[id];
    delete this._nodeData[id];
    delete this._idToGeometry[id];
};

/**
 * Change Property of Node
 * @param {string} id - Id of Node
 * @param {string} key - which propertu should be changed - Currently only "CODE"
 * @param {string} value - new Value of Property
 */
NodeManager.prototype.changeProperty = function (id, key, value) {
    switch (key) {
        case "CODE":
            this.getData(id).CODE = value;
            this.getGeometry(id).node.setCode(value);
            this.getGeometry(id).node.invalidateCache();
            break;
    }
};

export default NodeManager;
