import ActivityStateTransitionPermissionMatrixFactory from "../../../domain/sb_activity_state_transition_permission_matrix_factory.class";
import { get } from "lodash";
import ActivityState from "../../../domain/sb_activity_state.class";

export default class ActivityStateTransitionViewModelService {
    constructor(ACTIVITY_STATES, ACTIVITY_TRANSITIONS, $sbMembership) {
        "ngInject";

        this.ACTIVITY_STATES = ACTIVITY_STATES;
        this.ACTIVITY_TRANSITIONS = ACTIVITY_TRANSITIONS;
        this.membership = $sbMembership;
    }

    selectViewModelFor(activity) {
        const permissionMatrix = this.createPermissionFor(activity);
        const options = this.setupStateOptionsFrom(activity, permissionMatrix);
        const information = this.setupStateInformationFrom(
            activity,
            permissionMatrix
        );

        return {
            information,
            options,
        };
    }

    setupStateOptionsFrom(activity, permissionsMatrix) {
        const ACTIVITY_STATES = this.ACTIVITY_STATES;
        const ACTIVITY_TRANSITIONS = this.ACTIVITY_TRANSITIONS;

        const currentState = get(activity, "state.current", "").toUpperCase();

        const createHiddenOption = (key) => {
            return {
                key,
                isVisible: false,
                isDisabled: true,
            };
        };

        const createOptionFor = (availableStates, stateName) => {
            if (availableStates.includes(stateName)) {
                return {
                    key: stateName,
                    isVisible: true,
                    isDisabled: !permissionsMatrix.isAllowed([
                        currentState,
                        stateName,
                    ]),
                };
            } else {
                return createHiddenOption(stateName);
            }
        };

        const availableStates = permissionsMatrix.getEnabledStates();

        const options = {};
        options.notStarted = createOptionFor(
            availableStates,
            ACTIVITY_STATES.NOT_STARTED
        );
        options.started = createOptionFor(
            availableStates,
            ACTIVITY_STATES.STARTED
        );
        options.done = createOptionFor(availableStates, ACTIVITY_STATES.DONE);
        options.waitingForConfirmation = createOptionFor(
            availableStates,
            ACTIVITY_STATES.WAITING_FOR_CONFIRMATION
        );
        if (activity.isQAWorkflowRequired()) {
            const isVisible = this.isReviewChecklistOptionVisible(
                activity,
                availableStates
            );

            const isEnabled =
                !this.isBlockedByInspectionTeamFeature(activity) &&
                (permissionsMatrix.isAllowed([
                    currentState,
                    ACTIVITY_STATES.CONFIRMED,
                ]) ||
                    permissionsMatrix.isAllowed([
                        currentState,
                        ACTIVITY_STATES.REJECTED,
                    ]));

            options.reviewChecklist = {
                key: ACTIVITY_TRANSITIONS.REVIEW_CHECKLIST,
                isVisible,
                isDisabled: !isEnabled,
            };
            options.confirmed = createHiddenOption(
                ACTIVITY_TRANSITIONS.CONFIRMED
            );
            options.rejected = createHiddenOption(
                ACTIVITY_TRANSITIONS.REJECTED
            );
        } else {
            options.rejected = createOptionFor(
                availableStates,
                ACTIVITY_STATES.REJECTED
            );
            options.confirmed = createOptionFor(
                availableStates,
                ACTIVITY_STATES.CONFIRMED
            );
            options.reviewChecklist = createHiddenOption(
                ACTIVITY_TRANSITIONS.REVIEW_CHECKLIST
            );
        }
        options.couldNotStart = {
            key: ACTIVITY_TRANSITIONS.COULD_NOT_START,
        };

        return options;
    }

    setupStateInformationFrom(activity, permissionMatrix) {
        const information = {
            isVisible: false,
            title: "NONE",
            message: "NONE",
        };

        if (this.isBlockedByInspectionTeamFeature(activity)) {
            const availableStates = permissionMatrix.getEnabledStates();
            const isBlockingReviewChecklistOption =
                this.isReviewChecklistOptionVisible(activity, availableStates);

            if (isBlockingReviewChecklistOption) {
                return {
                    isVisible: true,
                    title: "INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS___TITLE",
                    message:
                        "INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS__MESSAGE",
                };
            }
        }

        return information;
    }

    isReviewChecklistOptionVisible(activity, availableStates) {
        return (
            availableStates.includes(this.ACTIVITY_STATES.CONFIRMED) ||
            availableStates.includes(this.ACTIVITY_STATES.REJECTED) ||
            // this is a workaround because the permission matrix doesn't know about review teams yet
            this.isCurrentUserAssignedAsReviewTeam(activity)
        );
    }

    isBlockedByInspectionTeamFeature(activity) {
        const isInspectionTeamInvolved =
            get(activity, "reviewTeams", []).length > 0;
        const isChecklistRequired = activity.isQAWorkflowRequired();
        const isInStateWaitingForConfirmation = activity.state.is(
            ActivityState.WAITING_FOR_CONFIRMATION
        );

        return (
            isInspectionTeamInvolved &&
            isChecklistRequired &&
            isInStateWaitingForConfirmation
        );
    }

    createPermissionFor(activity) {
        // choose correct workflow and create permission matrix for it
        //
        return ActivityStateTransitionPermissionMatrixFactory.createFor({
            workflow:
                ActivityStateTransitionPermissionMatrixFactory.chooseWorkflowBasedOn(
                    {
                        membership: this.membership.current(),
                        activity,
                    }
                ),
        });
    }

    isCurrentUserAssignedAsReviewTeam(activity) {
        const currentUserTeam = this.membership.current().team.id;
        const reviewTeams = get(activity, "reviewTeams", []).map((t) => t.id);
        return reviewTeams.includes(currentUserTeam);
    }
}
