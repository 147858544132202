"use strict";

// polyfills needed to execute tests on PhantomJS
import "core-js/features/map";

import _ from "lodash";
import moment from "moment";
import BaseComponent from "./sb_base_component.class";
import SbTeam from "./sb_team.class";
import SbActivityState from "./sb_activity_state.class";
import SbActivityStateMapper from "./sb_activity_state_mapper.class";

const stateToProgress = new Map([
    [SbActivityState.NOT_STARTED, 0],
    [SbActivityState.STARTED, 50],
    [SbActivityState.DONE, 100],
    [SbActivityState.WAITING_FOR_CONFIRMATION, 100],
    [SbActivityState.REJECTED, 50],
    [SbActivityState.CONFIRMED, 100],
]);

/**
 * Create an Activity
 *
 * @constructor
 * @extends BaseComponent
 */
class Activity extends BaseComponent {
    constructor(id, name, code) {
        super(id, name, code);
        this.color = null;

        // by class definition the category has to be "ACTIVITY"
        this.category = "ACTIVITY";

        this.dateSource = Activity.DATE_SOURCE.AUTO;

        this.topologicalIndex = 0;

        this.plannedDuration = null;
        this.plannedDurationUnit = null;
        this.plannedLabour = null;

        this.assignedTeam = {
            id: null,
            name: null,
            color: null,
            isProjectTeam: null,
        };

        this.confirmationTeam = {
            id: null,
            name: null,
            color: null,
            isProjectTeam: null,
        };

        this.reviewTeams = [];

        this.qa = {
            templateId: null,
            signedId: null,
            confirmedId: null,
        };

        this.state = {
            current: null,
            changedAt: null,
            changedBy: null,
        };

        // minimum role to work on this activity
        //
        this.accessRole = Activity.ACCESS_ROLE;

        // permissions for read-only or read-write access
        //
        this.permission = Activity.READ_ONLY;
    }

    /**
     *
     * @param {Object} odataObject
     * @returns {Activity}
     */
    static createFromOdataObject(odataObject) {
        return Activity.createFromOdataObjectIntoInstance(
            odataObject,
            new Activity(odataObject.ID, odataObject.NAME, odataObject.CODE)
        );
    }

    /**
     *
     * @param {Object} odataObject
     * @param {Object} instance
     * @returns {Activity}
     */
    static createFromOdataObjectIntoInstance(odataObject, instance) {
        Activity.createFromServerObjectAndMappingIntoInstance(
            odataObject,
            instance,
            Activity.ODATA_PROPERTY_MAP
        );
        instance.state = SbActivityStateMapper.toDomainEntity(
            odataObject,
            SbActivityStateMapper.ODATA_PROPERTY_MAP
        );
        return instance;
    }

    /**
     *
     * @param {Object} newModelObject
     * @param {Object} instance
     * @returns {Activity}
     */
    static createFromNewModelObjectIntoInstance(newModelObject, instance) {
        Activity.createFromServerObjectAndMappingIntoInstance(
            newModelObject,
            instance,
            Activity.NEW_MODEL_PROPERTY_MAP
        );
        instance.state = SbActivityStateMapper.createFromApiState(
            newModelObject.state
        );
        return instance;
    }

    /**
     *
     * @param {Object} serverObject
     * @param {Object} instance
     * @returns {Activity}
     */
    static createFromServerObjectAndMappingIntoInstance(
        serverObject,
        instance,
        PROPERTY_MAPPER
    ) {
        const clonedData = _.clone(serverObject);
        instance.__odataSource = clonedData;

        // use the property map to translate odata keys to class keys
        //
        instance = Object.keys(PROPERTY_MAPPER).reduce(function (
            activity,
            odataPropertyPath
        ) {
            const keyPath = PROPERTY_MAPPER[odataPropertyPath];
            const value = _.get(clonedData, odataPropertyPath);

            // if the value is null -> keep the default value from the constructor
            if (_.isNull(value)) {
                return activity;
            }
            _.set(activity, keyPath, value);

            return activity;
        }, instance);

        // convert to color string
        //
        instance.color = "#" + instance.color;

        // convert "integer"
        instance.plannedDuration = isNaN(instance.plannedDuration)
            ? undefined
            : instance.plannedDuration;

        instance.lastPlannerDuration = isNaN(instance.lastPlannerDuration)
            ? undefined
            : instance.lastPlannerDuration;

        // make the dates proper moment dates
        //
        instance.startDate = instance.toMomentOrNull(instance.startDate);
        instance.endDate = instance.toMomentOrNull(instance.endDate);
        instance.userDefinedStart = instance.toMomentOrNull(
            instance.userDefinedStart
        );
        instance.userDefinedEnd = instance.toMomentOrNull(
            instance.userDefinedEnd
        );
        instance.actualStart = instance.toMomentOrNull(instance.actualStart);
        instance.actualEnd = instance.toMomentOrNull(instance.actualEnd);
        instance.actualConfirmed = instance.toMomentOrNull(
            instance.actualConfirmed
        );
        instance.earliestStart = instance.toMomentOrNull(
            instance.earliestStart
        );
        instance.earliestEnd = instance.toMomentOrNull(instance.earliestEnd);
        instance.latestStart = instance.toMomentOrNull(instance.latestStart);
        instance.latestEnd = instance.toMomentOrNull(instance.latestEnd);
        instance.lastPlannerStart = instance.toMomentOrNull(
            instance.lastPlannerStart
        );
        instance.lastPlannerEnd = instance.toMomentOrNull(
            instance.lastPlannerEnd
        );

        instance.progressChangeTime = instance.toMomentOrNull(
            instance.progressChangeTime
        );

        // convert IS_BEHIND - default is 'unknown'
        //
        if (instance.scheduleState > 0) {
            instance.scheduleState = BaseComponent.SCHEDULE_BEHIND;
        }
        if (instance.scheduleState === 0) {
            instance.scheduleState = BaseComponent.SCHEDULE_ON_TIME;
        }

        // seems like there is only an ID coming from the server.
        const responsibleTeam = new SbTeam(
            instance.assignedTeam.id,
            instance.assignedTeam.name
        );
        responsibleTeam.setColor(instance.assignedTeam.color);
        responsibleTeam.projectId = instance.projectId;
        responsibleTeam.isProjectTeam = instance.assignedTeam.isProjectTeam;
        instance.assignedTeam = responsibleTeam;

        if (Array.isArray(instance.reviewTeamIds)) {
            instance.reviewTeams = instance.reviewTeamIds.map(
                (id) => new SbTeam(id, undefined)
            );
        }
        _.unset(instance, "reviewTeamIds");

        instance.confirmationTeam = new SbTeam(
            instance.confirmationTeam.id,
            undefined
        );
        instance.confirmationTeam.projectId = instance.projectId;

        return instance;
    }

    /**
     * Map an Activity's progress to an SbActivityState entity. Note it is impossible to infer REJECTED state from the progress
     *
     * @param {Object} Activity
     *
     * @returns {SbActivityState}
     */
    static deriveStateFromProgress(activity) {
        switch (activity.progress) {
            case 0:
                return SbActivityState.NOT_STARTED;

            case 50:
                return SbActivityState.STARTED;

            case 100:
                return _getFullProgressState(activity);
        }
    }

    /**
     * Maps an activities state to progress.
     *
     * @param {Activity} activity
     *
     * @returns {number} progress values as number, one of 0, 50 or 100
     */
    static deriveProgressFromState(activity) {
        const currentState = activity.state.current;
        if (stateToProgress.has(currentState)) {
            return stateToProgress.get(currentState);
        }
    }

    isConfirmed() {
        // return false if there is not state set
        //
        if (!(this.state instanceof SbActivityState)) {
            return false;
        }

        return this.state.is(SbActivityState.CONFIRMED);
    }

    isConfirmationRequired() {
        return !!(this.confirmationTeam && this.confirmationTeam.id);
    }

    setColor(color) {
        this.color = color;
        return this;
    }

    setCurrentState(newState) {
        if (!SbActivityState[newState]) {
            throw "Not a valid state";
        }

        this.state = new SbActivityState(newState, moment());
        this.progress = Activity.deriveProgressFromState(this);

        return this;
    }

    setResponsibleTeam(responsibleTeam) {
        this.assignedTeam = responsibleTeam;
    }

    setConfirmingTeam(confirmingTeam) {
        this.confirmationTeam = confirmingTeam;
    }

    setReviewTeams(reviewTeams) {
        this.reviewTeams = reviewTeams;
    }

    addReviewTeam(reviewTeam) {
        this.reviewTeams.push(reviewTeam);
    }

    unsetTeamRestriction() {
        this.assignedTeam = SbTeam.createUnrestrictedTeam();
        return this;
    }

    /**
     * Whenever there is checklist template, a signed checklist or a confirmed checklist attached
     * to an activity we are saying that this activity is supporting a QA workflow.
     *
     * @return {boolean}
     */
    isQAWorkflowRequired() {
        return !!(
            this.qa.templateId ||
            this.qa.signedId ||
            this.qa.confirmedId
        );
    }

    /**
     * Project Team has always access
     * The assigned team has access
     * The confirmation team has access
     * If the activity is free for all every team has access
     * --
     * will throw if no team assignment available
     *
     * @param {SbTeam} team - the team to check the activity access against
     * @return {boolean}
     */
    isAccessibleByTeam(team) {
        const hasFullAccess = team.isProjectTeam;
        const isUnassigned = this.assignedTeam.id === null;
        const isResponsibleTeam = this.assignedTeam.id === team.id;
        const isReviewTeam = (this.reviewTeams || []).some(
            (reviewTeam) => reviewTeam.id === team.id
        );
        const isConfirmationTeam = this.confirmationTeam.id === team.id;

        return (
            team &&
            (hasFullAccess ||
                isResponsibleTeam ||
                isReviewTeam ||
                isConfirmationTeam ||
                isUnassigned)
        );
    }

    hasAssignedTeam() {
        return this.assignedTeam && this.assignedTeam.id !== null;
    }

    isCustomActivity() {
        return !this.piTemplateId;
    }

    /**
     * Check if activity is read-write.
     *
     * @returns {boolean}
     */
    isReadWrite() {
        return Number.parseInt(this.permission, 10) === Activity.READ_WRITE;
    }

    /**
     * Gets the access role mask or 0
     *
     * @returns {number}
     */
    getAccessRoleMask() {
        return Number.parseInt(this.accessRole, 10) || 0;
    }

    /**
     * Gets the activity's state or name depending on it's progress
     *
     * @returns {string}
     */
    getDisplayText() {
        return this.name;
    }

    /**
     * Returns true if the activity is finished, false if it is not finished
     *
     * @returns {boolean}
     */
    isDone() {
        return SbActivityState.isWorkDone(this.state.current);
    }

    /**
     * Returns the total float (buffer) for the activity in milliseconds
     *
     * @returns {number}
     */
    getTotalFloat() {
        return Number.parseInt(this.totalFloat);
    }

    /**
     * Checks if the total float returned is a number
     *
     * @returns {boolean}
     */
    totalFloatIsNumber() {
        return !isNaN(this.getTotalFloat());
    }

    /**
     * Returns the total float for the activity in days
     *
     * @returns {number|undefined}
     */
    getTotalFloatInDays() {
        if (this.totalFloatIsNumber()) {
            const duration = moment.duration(this.getTotalFloat());
            return Math.round(duration.asDays());
        }
    }

    getMostReleventStartDate() {
        if (this.lastPlannerStart && this.lastPlannerStart.isValid()) {
            return this.lastPlannerStart;
        }

        if (this.earliestStart && this.earliestStart.isValid()) {
            return this.earliestStart;
        }

        if (this.latestStart && this.latestStart.isValid()) {
            return this.latestStart;
        }
    }

    getMostReleventEndDate() {
        if (this.lastPlannerEnd && this.lastPlannerEnd.isValid()) {
            return this.lastPlannerEnd;
        }

        if (this.earliestEnd && this.earliestEnd.isValid()) {
            return this.earliestEnd;
        }

        if (this.latestEnd && this.latestEnd.isValid()) {
            return this.latestEnd;
        }
    }

    setProgress(progress) {
        BaseComponent.prototype.setProgress.call(this, progress);

        this.state = new SbActivityState(
            Activity.deriveStateFromProgress(this)
        );
    }
}

Activity.READ_ONLY = 1;
Activity.READ_WRITE = 2;

Activity.ACCESS_ROLE = 1;

Activity.DATE_SOURCE = {
    AUTO: "AUTO",
    USER: "USER",
};

Activity.ALLOCATION_ALGORITHM = {
    DEFAULT: "DEFAULT",
    EQUAL_DISTRIBUTION: "EQUAL_DISTRIBUTION",
};

Activity.ODATA_PROPERTY_MAP = {
    ID: "id",
    TEMPLATE_ID: "piTemplateId",
    PROJECT_ID: "projectId",
    PARENT_ID: "parentId",
    ROOT_ID: "rootId",

    NAME: "name",
    GROUP_NAME: "displayPath",
    STATE_NAME: "stateName",
    DESC: "desc",
    CODE: "code",
    CATEGORY: "category",
    TOPOLOGICAL_INDEX: "topologicalIndex",
    COLOR: "color",

    SD: "startDate",
    CD: "endDate",
    USER_DEFINED_START: "userDefinedStart",
    USER_DEFINED_END: "userDefinedEnd",

    ACTUAL_STARTED_AT: "actualStart",
    ACTUAL_FINISHED_AT: "actualEnd",
    ACTUAL_CONFIRMED_AT: "actualConfirmed",
    EARLIEST_START: "earliestStart",
    EARLIEST_END: "earliestEnd",
    LATEST_START: "latestStart",
    LATEST_END: "latestEnd",

    COMMITTED_LAST_PLANNED_DURATION: "lastPlannerDuration",
    COMMITTED_LAST_PLANNED_START: "lastPlannerStart",
    COMMITTED_LAST_PLANNED_END: "lastPlannerEnd",

    DATE_SOURCE: "dateSource",
    ALLOCATION_ALGORITHM: "allocationAlgorithm",

    ALLOCATION: "plannedDuration",
    ALLOCATION_UNIT: "plannedDurationUnit",
    PLANNED_LABOUR_ALLOCATION: "plannedLabour",

    PROGRESS: "progress",
    IS_BEHIND: "scheduleState",

    PROGRESS_CHANGE_TIME: "progressChangeTime",
    PROGRESS_CHANGE_AUTHOR_DB_NAME: "progressChangeAuthor.dbName",
    PROGRESS_CHANGE_AUTHOR_DISPLAY_NAME: "progressChangeAuthor.displayName",
    PROGRESS_CHANGE_AUTHOR_INITIALS: "progressChangeAuthor.initials",

    ASSIGNED_TEAM_ID: "assignedTeam.id",
    ASSIGNED_TEAM_NAME: "assignedTeam.name",
    ASSIGNED_TEAM_IS_PROJECT_TEAM: "assignedTeam.isProjectTeam",
    ASSIGNED_TEAM_COLOR: "assignedTeam.color",

    CONFIRMATION_TEAM_ID: "confirmationTeam.id",
    REVIEW_TEAM_IDS: "reviewTeamIds",

    "ACCESS.SET_MASK": "permission",
    "ACCESS.ROLE_MASK": "accessRole",

    TOTAL_FLOAT: "totalFloat",

    CHECKLIST_TEMPLATE_ID: "qa.templateId",
    SIGNED_CHECKLIST_ID: "qa.signedId",
    CONFIRMED_CHECKLIST_ID: "qa.confirmedId",
    IS_AVAILABLE: "isAvailable",
};

Activity.NEW_MODEL_PROPERTY_MAP = {
    id: "id",
    activity_template_id: "piTemplateId",
    deliverable_id: "deliverableId",

    name: "name",
    group: "displayPath",
    state_name: "stateName",
    description: "desc",
    code: "code",
    category: "category",
    topological_index: "topologicalIndex",
    color: "color",

    sd: "startDate",
    cd: "endDate",
    "baseline_schedule.constraints.start_date": "userDefinedStart",
    "baseline_schedule.constraints.end_date": "userDefinedEnd",

    "baseline_schedule.earliest_start": "earliestStart",
    "baseline_schedule.earliest_end": "earliestEnd",
    "baseline_schedule.latest_start": "latestStart",
    "baseline_schedule.latest_end": "latestEnd",

    "look_ahead_schedule.duration.value": "lastPlannerDuration",
    "look_ahead_schedule.start_date": "lastPlannerStart",
    "look_ahead_schedule.end_date": "lastPlannerEnd",

    "baseline_schedule.duration.value": "plannedDuration",
    "baseline_schedule.duration.unit": "plannedDurationUnit",
    planned_labour: "plannedLabour",

    progress: "progress",
    is_behind_baseline: "scheduleState",

    "state.reported.at": "progressChangeTime",
    "state.reported.by.email": "progressChangeAuthor.dbName",
    "state.reported.by.name": "progressChangeAuthor.displayName",
    "state.reported.by.initials": "progressChangeAuthor.initials",

    responsible_team_id: "assignedTeam.id",
    review_team_ids: "reviewTeamIds",
    confirmation_team_id: "confirmationTeam.id",

    number_of_obstruction_notes: "_noteStatistic.openObstructions",
    number_of_closed_obstruction_notes: "_noteStatistic.closedObstructions",
    number_of_quality_notes: "_noteStatistic.openClaims",
    number_of_closed_quality_notes: "_noteStatistic.closedClaims",
    number_of_info_notes: "_noteStatistic.info",

    checklist_template_id: "qa.templateId",
};

export default Activity;

function _getFullProgressState(activity) {
    if (activity.isConfirmed()) {
        return SbActivityState.CONFIRMED;
    }

    if (activity.isConfirmationRequired()) {
        return SbActivityState.WAITING_FOR_CONFIRMATION;
    }

    return SbActivityState.DONE;
}
