import _ from "lodash";
import moment from "moment";
import "./model/cost_group_membership.class";

export default function CommercialCtrl(
    $q,
    $scope,
    $state,
    $rootScope,
    $sbCommercial,
    $sbMembership,
    $mdDialog,
    $sbProject,
    $sbPermission,
    $sbTracking,
    $mdToast,
    $sbCsv,
    $sbErrorPresenter,
    $sbFeatureDecisions,
    EVENTS
) {
    "ngInject";

    var vm = this;
    vm.isUpdatingMembership = false;
    vm.costGroups = [];
    vm.selectedCostGroup = undefined;
    vm.reportedAsAt = moment();
    vm.hasAdminPermissions = false;

    vm.loadCostGroup = loadCostGroup;
    vm.deleteCostGroup = deleteCostGroup;
    vm.saveCostGroup = _.debounce(saveCostGroup, 1000);
    vm.onCreateCostGroup = onCreateCostGroup;
    vm.isEmptyStateVisible = isEmptyStateVisible;
    vm.changeReportDate = changeReportDate;
    vm.onEditCostGroupMembership = onEditCostGroupMembership;
    vm.combineActivities = combineActivities;
    vm.exportCsv = exportCsv;
    vm.ungroupActivities = ungroupActivities;

    $onInit();

    function $onInit() {
        vm.hasActiveEmptyState = false;
        vm.isLoading = true;

        vm.costGroups = $sbCommercial.costGroups;
        vm.selectedCostGroup = vm.costGroups[0];

        return $sbCommercial
            .fetchAllCostGroupsByProject($sbProject.getCurrentProjectId())
            .then(function () {
                vm.costGroups = $sbCommercial.costGroups;
                vm.selectedCostGroup = vm.costGroups[0];
            })
            .then(_loadSelectedCostGroup)
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                vm.isLoading = false;
            });
    }

    function _loadSelectedCostGroup() {
        if (vm.selectedCostGroup) {
            return loadCostGroup(vm.selectedCostGroup);
        }
    }

    function refreshCostGroup(costGroup) {
        return $sbCommercial
            .fetchCostGroup(costGroup.id)
            .then((freshCostGroup) => (vm.selectedCostGroup = freshCostGroup))
            .then(_loadSelectedCostGroup);
    }

    function loadCostGroup(costGroup) {
        vm.isCostGroupLoading = true;

        return $q
            .all([
                $sbCommercial.loadCostGroupStages(costGroup, vm.reportedAsAt),
                $sbCommercial.loadCostGroupMembership(costGroup.id),
            ])
            .then(function () {
                var costGroupFromService = _.find($sbCommercial.costGroups, [
                    "id",
                    costGroup.id,
                ]);

                // Endpoint will return undefined if the cost group has been deleted by another user
                if (!costGroupFromService) {
                    return $onInit();
                }

                _deriveAndSetPermissionFromCostGroup(costGroupFromService);

                vm.selectedCostGroup = costGroupFromService;
            })
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                vm.isCostGroupLoading = false;
            });
    }

    function _deriveAndSetPermissionFromCostGroup(costGroup) {
        var currentUserMembership =
            costGroup.membershipManager.findMembershipOf(
                $sbMembership.currentUser().userName
            );
        if (currentUserMembership) {
            vm.hasAdminPermissions =
                currentUserMembership.isAllowedToChangeMemberships();
        } else {
            vm.hasAdminPermissions = false;
        }
    }

    function deleteCostGroup(costGroup) {
        if (vm.hasAdminPermissions) {
            return _confirmDeleteCostGroup().then(function (userConfirmed) {
                if (!userConfirmed) {
                    return;
                }
                return $sbCommercial
                    .deleteCostGroup(costGroup)
                    .then(function () {
                        $sbTracking.commercial.onCostGroupDelete();
                        vm.costGroups = $sbCommercial.costGroups;
                        vm.selectedCostGroup = vm.costGroups[0];
                        return _loadSelectedCostGroup();
                    })
                    .catch($sbErrorPresenter.catch);
            });
        }
    }

    function _confirmDeleteCostGroup() {
        return $mdDialog.show(
            $mdDialog
                .confirm()
                .title("COST_GROUP_CONFIRM_DELETE_TITLE")
                .titleIcon("mdi mdi-alert")
                .content("COST_GROUP_CONFIRM_DELETE_BODY")
                .cancel("NO")
                .ok("YES")
        );
    }

    function saveCostGroup(costGroup) {
        if (vm.hasAdminPermissions) {
            return $sbCommercial
                .updateCostGroup(costGroup)
                .then(_onSaveSuccess)
                .catch($sbErrorPresenter.catch);
        }
    }

    function _onSaveSuccess() {
        $sbTracking.commercial.onCostGroupCreate("Save Cost Group");

        vm.costGroups = $sbCommercial.costGroups;

        _showSuccessToast();
    }

    function _showSuccessToast() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("COST_GROUP_SAVED")
                .hideDelay(2000)
                .position("top right")
        );
    }

    function onCreateCostGroup($event) {
        $sbTracking.commercial.onCostGroupCreate("Process started");

        var dialog = $mdDialog
            .createCostGroup({
                scope: $scope,
            })
            .targetEvent($event);

        return $mdDialog
            .show(dialog)
            .then(onCreateCostGroupDialogDone)
            .catch(function () {
                // calls the trackDialogCloseEvent function in the dialog
                $scope.trackDialogCloseEvent();
            });
    }

    function onCreateCostGroupDialogDone(results) {
        return $sbCommercial
            .createCostGroup({
                name: results.name,
                stages: _.map(results.activities, "id"),
            })
            .then(function () {
                $sbTracking.commercial.onCostGroupCreate("Created");
                vm.costGroups = $sbCommercial.costGroups;
                vm.selectedCostGroup = _.last(vm.costGroups);
                _deriveAndSetPermissionFromCostGroup(vm.selectedCostGroup);
            })
            .catch($sbErrorPresenter.catch);
    }

    function isEmptyStateVisible(costGroups) {
        return !vm.isLoading && costGroups.length === 0;
    }

    function changeReportDate(reportedAt) {
        $sbTracking.commercial.onChangeReportedAt();

        vm.reportedAsAt = moment(reportedAt);

        if (_isReportDateInFuture(vm.reportedAsAt)) {
            _setIsForecastModeTo(true);
        } else {
            _setIsForecastModeTo(false);
        }

        _loadSelectedCostGroup();
    }

    function _isReportDateInFuture(reportedAsAt) {
        return moment().isBefore(reportedAsAt, "day");
    }

    function _setIsForecastModeTo(isForecastMode) {
        $rootScope.$emit(
            EVENTS.PLAN_WORK_REVIEW_SECTION_CHANGED,
            isForecastMode ? "PLAN" : "REVIEW"
        );
    }

    function exportCsv() {
        $sbTracking.commercial.onExportCsv();

        var dataForExport = $sbCommercial.generateDataForCsvExport(
            vm.selectedCostGroup,
            vm.reportedAsAt
        );

        var fileName = dataForExport.fileName;

        var csv = $sbCsv.generateCsvFrom(dataForExport);

        $sbCsv.downloadCsv(csv, {
            fileName: fileName,
        });
    }

    function onEditCostGroupMembership($event) {
        var dialog = $mdDialog
            .editCostGroupMembership({
                locals: {
                    allowSingleManagerOnly: false,
                },
            })
            .currentManagers(_getCurrentManagerMemberships())
            .currentReviewers(_getCurrentReviewerMemberships())
            .targetEvent($event);

        return $mdDialog.show(dialog).then(function (result) {
            _onEditMembershipDialogDone(result, $event);
        });
    }

    function _getCurrentManagerMemberships() {
        return vm.selectedCostGroup.membershipManager
            .collectAdminMemberships()
            .map(function (membership) {
                return membership.user;
            });
    }

    function _getCurrentReviewerMemberships() {
        return vm.selectedCostGroup.membershipManager
            .collectReadOnlyMemberships()
            .map(function (membership) {
                return membership.user;
            });
    }

    /**
     * @param {Object}      result
     * @param {boolean}     result.hasRemovedSelf
     * @param {Array<User>} result.selectedManagers
     * @param {Array<User>} result.selectedReviewers
     * @param {Object}      $event
     */
    function _onEditMembershipDialogDone(result, $event) {
        if (result.hasRemovedSelf) {
            return _confirmRemovalOfSelf()
                .then(function () {
                    return _updateCostGroupMembership(result).then(
                        $state.reload
                    );
                })
                .catch(function () {
                    // reopen the dialog
                    //
                    onEditCostGroupMembership($event);
                });
        } else {
            return _updateCostGroupMembership(result);
        }
    }

    function _updateCostGroupMembership(result) {
        vm.isUpdatingMembership = true;

        return $sbCommercial
            .updateCostGroupMembership(
                vm.selectedCostGroup,
                result.selectedManagers,
                result.selectedReviewers
            )
            .then(_updateMembershipViewModel)
            .then(_showSuccessToast)
            .finally(function () {
                vm.isUpdatingMembership = false;
            })
            .catch(function (error) {
                // reload membership on error
                //
                $sbCommercial.loadCostGroupMembership(vm.selectedCostGroup.id);

                $sbErrorPresenter.catch(error);
            });
    }

    function _updateMembershipViewModel(newMemberships) {
        vm.selectedCostGroup.membershipManager.clearMemberships();

        vm.selectedCostGroup.membershipManager.add(newMemberships);
    }

    function _confirmRemovalOfSelf() {
        var dialog = $mdDialog
            .confirm()
            .title("COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_HEADER")
            .titleIcon("mdi mdi-alert")
            .content("COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_TEXT")
            .cancel("NO")
            .ok("YES");

        return $mdDialog.show(dialog);
    }

    function ungroupActivities({ item, costGroup }) {
        $sbTracking.commercial.onEditingStageGroups("Group deleted");
        vm.isCostGroupLoading = true;
        return $sbCommercial
            .ungroupActivities(item, costGroup)
            .then(() => refreshCostGroup(costGroup))
            .then(() => _onSaveSuccess())
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
            });
    }

    function combineActivities({ $event, costGroup }) {
        $sbTracking.commercial.onEditingStageGroups("Group created");

        return $mdDialog
            .show(
                $mdDialog
                    .combineCostGroupActivities({
                        targetEvent: $event,
                        openFrom: $event.target,
                        closeTo: $event.target,
                    })
                    .costGroup(costGroup)
            )
            .then((combinedStages) => {
                vm.isCostGroupLoading = true;
                return $sbCommercial.combineActivities({
                    combinedStages: combinedStages,
                    costGroup: costGroup,
                });
            })
            .then(() => refreshCostGroup(costGroup))
            .then(() => _onSaveSuccess());
    }
}
