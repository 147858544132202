import BulkStateChangeCtrl from "./bulk_state_change.controller";
import dialogTemplate from "./bulk_state_change.html";

export default function BulkStateChangeConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("bulkStateChange", {
        methods: ["class", "deliverableSelection", "templateId"],
        options: function () {
            return {
                controller: BulkStateChangeCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
