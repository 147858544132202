import SelectableElement from "../common/SelectableElement";
import AnchorPoint from "../common/AnchorPoint";
import HoverableElement from "../common/HoverableElement";
/**
 * Class for Edge (Non-Hierarchical Connections Between Nodes)
 * @param {createjs.Point} sourcePoint - Source Point of Edge
 * @param {createjs.Point} targetPoint - Target Point of Edge
 * @param {Object} opt - Options of Edge
 * @param {boolean} isLOD - Determines of LOD is activated
 * @constructs Edge
 * @extends WBS.SelectableElement
 * @extends WBS.HoverableElement
 * @memberof WBS
 **/
function Edge(sourcePoint, targetPoint, opt, isLOD) {
    SelectableElement.call(this);

    HoverableElement.call(this);

    this.shape = new createjs.Shape();

    this.on("selected", this.onSelected);
    this.on("deselected", this.onDeselected);

    this.addChild(this.shape);

    this.on("mouseIn", this.onMouseIn);

    this.on("mouseOut", this.onMouseOut);

    this.drawEdge(sourcePoint, targetPoint, opt, isLOD);
}

Edge.prototype = Object.create(SelectableElement.prototype);

/**
 * Handles Select of Edges
 */
Edge.prototype.onSelected = function () {
    this.shape.color.style = this.selectColor;
};

/**
 * Handles Deselect of Edge
 */
Edge.prototype.onDeselected = function () {
    this.shape.color.style = this.defaultColor;
};

/**
 * Handles MouseIn of Edges
 */
Edge.prototype.onMouseIn = function () {
    this.shape.shadow = this.hoverShadow;
};

/**
 * Handles MouseOut of Edges
 */
Edge.prototype.onMouseOut = function () {
    this.shape.shadow = undefined;
};
/**
 * Every coordinate delta smaller then this offset will be interpreted as straight
 * @default
 * @type {number}
 */
Edge.prototype.straightOffset = 50;

/**
 * Shadow that is displayed on Hover
 * @type {*|Shadow}
 */
Edge.prototype.hoverShadow = new createjs.Shadow("#007AFF", 0, 0, 10);

/**
 * Default Color of Edge
 * @default
 * @type {string}
 */
Edge.prototype.defaultColor = "#545346";

/**
 * Color of Edge when selected
 * @default
 * @type {string}
 */
Edge.prototype.selectColor = "#FF0000";

/**
 * Draw an edge between two points given in the local coordinate system.
 *
 * The path tries to distinguish between
 *  # left to right edges
 *  # ascending or descending edges
 *  # straight edges
 *
 * @param {createjs.Point} sourcePoint - the source point
 * @param {createjs.Point} targetPoint - the target point
 * @param {object} [opt] - options
 * @param {boolean} isLOD - determines if LOD is activated
 */
Edge.prototype.drawEdge = function (sourcePoint, targetPoint, opt, isLOD) {
    var connection = this.shape,
        anchorRadius = (AnchorPoint.prototype.radius / 4) * 3,
        offset = this.straightOffset,
        p1 = sourcePoint.clone(),
        p2 = targetPoint.clone(),
        dX = p2.x - p1.x; // distance between source and target in x direction
    //dY = p2.y - p1.y; // distance between source and target in y direction

    p1.x += anchorRadius + 1;
    p2.x -= anchorRadius;

    if (isLOD) {
        p1.x += AnchorPoint.prototype.bigRadius / 2;
    }

    // we need some cases
    //
    // #1     o --> o   (straight line)
    //
    // #2     o <-- o   (straight line)
    //
    // #3   o -
    //          -> o
    //
    // #4      -> o
    //      o -

    // #5   o <-
    //           - o
    //
    // #6        - o
    //      o <-

    function leftToRight() {
        return p1.x < p2.x;
    }

    function toTop() {
        return p1.y > p2.y + offset;
    }

    function toBottom() {
        return p1.y < p2.y - offset;
    }

    // start
    //
    connection.graphics.setStrokeStyle(4);
    this.shape.color = connection.graphics.s(this.defaultColor).command;
    connection.graphics.mt(p1.x, p1.y);

    this.moveToCommand = connection.graphics.command;
    // mid
    if (leftToRight()) {
        // #1, #3, #4
        if (toTop()) {
            // #4
            connection.graphics.bt(
                p1.x + dX,
                p1.y,
                p2.x - dX,
                p2.y,
                p2.x,
                p2.y
            );
        } else if (toBottom()) {
            // #3
            connection.graphics.bt(
                p1.x + dX,
                p1.y,
                p2.x - dX,
                p2.y,
                p2.x,
                p2.y
            );
        } else {
            // #1
            var mX = p1.x + dX / 2,
                mY = p1.y + offset;

            connection.graphics
                .bt(p1.x + dX / 4, p1.y, mX - dX / 4, mY, mX, mY)
                .bt(mX + dX / 4, mY, p2.x - dX / 4, p2.y, p2.x, p2.y);
        }
    } else {
        var d = offset * 1.13,
            fX,
            fY,
            tX,
            tY;

        // #2, #5, #6
        if (toTop()) {
            // #5
            fX = p2.x;
            fY = p2.y + d;
            tX = p1.x;
            tY = fY;

            connection.graphics
                .bt(p1.x + d, p1.y, tX + d, tY, tX, tY)
                .lt(fX, fY)
                .bt(fX - d, fY, p2.x - d, p2.y, p2.x, p2.y);
        } else if (toBottom()) {
            // #6
            fY = p2.y - d;
            fX = p2.x;
            tY = fY;
            tX = p1.x;

            connection.graphics
                .bt(p1.x + d, p1.y, tX + d, tY, tX, tY)
                .lt(fX, fY)
                .bt(fX - d, fY, p2.x - d, p2.y, p2.x, p2.y);
        } else {
            // #2
            fY = p2.y + d;
            tX = p1.x;
            fX = p2.x;
            tY = fY;

            connection.graphics
                .bt(p1.x + d, p1.y, tX + d, tY, tX, tY)
                .lt(fX, fY)
                .bt(fX - d, fY, p2.x - d, p2.y, p2.x, p2.y);
        }
    }

    // draw the arrow
    connection.graphics
        .mt(p2.x - 5, p2.y - 5)
        .lt(p2.x, p2.y)
        .lt(p2.x - 5, p2.y + 5)
        .es();

    // draw the anchor nodes
    if (!opt || opt.dsa !== false) {
        connection.graphics
            .f("#016482")
            .dc(p1.x - anchorRadius, p1.y, anchorRadius)
            .mt(p2.x + 2 * anchorRadius, p2.y, anchorRadius);
    }
    if (!opt || opt.dta !== false) {
        connection.graphics
            .f("#080182")
            .dc(p2.x + anchorRadius, p2.y, anchorRadius)
            .ef();
    }
};

/**
 * Set Entity for Source Node
 * @param {WBS.StructureNode} entity - source node entity
 */
Edge.prototype.setSourceEntity = function (entity) {
    this.sourceEntity = entity;
};

/**
 * Set Entity for Tagrget Node
 * @param {WBS.StructureNode} entity - target node entity
 */
Edge.prototype.setTargetEntity = function (entity) {
    this.targetEntity = entity;
};

export default Edge;
