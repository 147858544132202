import angular from "angular";
import _ from "lodash";
import moment from "moment";
import escapeStringRegexp from "escape-string-regexp";
import Activity from "./model/Activity";
import PresentableError from "common/errors/PresentableError";

export default function TemplateEditorCtrl(
    $scope,
    $state,
    $stateParams,
    $sbTeam,
    $sbTeamDialogs,
    $mdDialog,
    $mdToast,
    $sbDialog,
    $sbTracking,
    sortCaseInsensitiveFilter,
    templateEditorService,
    $sbErrorPresenter,
    templateId,
    preloadedGraph,
    $q,
    loadingToast,
    $sbTemplate,
    $filter,
    $rootScope,
    $sbPullUpdate,
    $sbProject,
    $sbChecklistTemplateWebService,
    TEMPLATE_DIRECTIONS,
    projectCalendar,
    teams,
    Analytics,
    $sbGraphMlGenerator,
    downloadCenterService
) {
    "ngInject";
    /**
     * current graph
     * @type {TemplateGraph}
     */
    var graph;

    /**
     * Flattened list of all components
     * @type {Array}
     */
    $scope.componentsList = [];

    /**
     * Data of the root node -> used in the toolbar
     * @type {Object}
     */
    $scope.rootNode = {};

    /**
     * Root element -> is propagated to the tree directive
     * @type {Component}
     */
    $scope.treeRootGroup = {};

    /**
     * Node displayed in Details view
     * @type {Object}
     */
    $scope.selectedNode = {};

    /**
     * Details of the right part of the editor - like the breadcrumb and the components
     *
     * @type {{components: Array, hierarchy: Array}}
     */
    $scope.detail = {
        name: " -- ",
        components: [],
        hierarchy: [],
    };

    /**
     * List of predecessors of the current selection
     * @type {Array}
     */
    $scope.predecessors = [];

    /**
     * List of successors of the current selection
     * @type {Array}
     */
    $scope.successors = [];

    /**
     * Count of predecessors and successors
     * @type {Int}
     */
    $scope.predecessorsCount = 0;
    $scope.successorsCount = 0;

    /**
     * Actions for last breadcrumb's action if it's Group
     */
    $scope.lastBreadcrumbActionsGroup = [
        {
            text: "ACTION_MOVE_TO_ANOTHER_PARENT",
            icon: "sb-icon-move-parent",
            action: function ($event) {
                $scope.openChangeParentDialog(
                    $event,
                    graph.getComponent($scope.selectedNode.id)
                );
            },
        },
        {
            text: "ACTION_DELETE_GROUP",
            icon: "mdi mdi-delete",
            action: function ($event) {
                $scope.deleteTreeAndGoToParent($event, $scope.selectedNode);
            },
        },
    ];

    /**
     * Actions for last breadcrumb's action if it's Activity
     */
    $scope.lastBreadcrumbActionsActivity = [
        {
            text: "ACTION_MOVE_TO_ANOTHER_PARENT",
            icon: "sb-icon-move-parent",
            action: function ($event) {
                $scope.openChangeParentDialog(
                    $event,
                    graph.getComponent($scope.selectedNode.id)
                );
            },
        },
        {
            text: "ACTION_DELETE_ACTIVITY",
            icon: "mdi mdi-delete",
            action: function ($event) {
                $scope.deleteTreeAndGoToParent($event, $scope.selectedNode);
            },
        },
        {
            text: "ACTION_TRANSFORM_ACTIVITY",
            icon: "",
            action: function ($event) {
                $scope.transformToGroup($event, $scope.selectedNode);
            },
        },
    ];

    /**
     * Options for transforming an activity
     */
    var transformActivityOptions = [
        {
            keepActivity: true,
            text: "SELECTION_TRANSFORM_ACTIVITY_KEEP",
        },
        {
            keepActivity: false,
            text: "SELECTION_TRANSFORM_ACTIVITY_DISCARD",
        },
    ];

    $scope.teams = teams;
    $scope.checklists = [];
    $scope.onCreateNewTeam = onCreateNewTeam;

    _fetchAndSetAvailableChecklists();

    function onCreateNewTeam() {
        return $sbTeamDialogs
            .openCreateTeamDialog()
            .then(function onNewTeamSave(newTeam) {
                newTeam.projectId = $stateParams.projectId;

                return $sbTeam
                    .createTeam(newTeam)
                    .then(function updateTeamList(createdTeam) {
                        Analytics.trackEvent(
                            "Team",
                            "Created",
                            "on Template Editor"
                        );

                        // add created team to list
                        //
                        $scope.teams = [createdTeam].concat($scope.teams);

                        return createdTeam;
                    })
                    .catch($sbErrorPresenter.catch);
            });
    }

    /**
     * Given node data - return a state change href
     *
     * @param {Component|Object} node - the node to get a href for
     * @returns {*} - the $state.href instance
     */
    function getHrefToNodeDetailView(node) {
        var id = node.id;
        return $state.href("sablono.project.templateEditor.nodeDetailView", {
            templateId: templateId,
            nodeId: id,
        });
    }

    /**
     * Set the current selection for the right part of the editor
     *
     * @param {String} nodeId - the id of the node that should be visible in the detail
     * @private
     */
    function _navigateToNode(nodeId) {
        $scope.selectedNode = graph.getComponent(nodeId).getData();
        if ($scope.selectedNode.parentId) {
            $scope.selectedNode.parent =
                graph.getComponent(nodeId).parent.properties.name;
        }

        if (_.isUndefined($scope.selectedNode.statename)) {
            $scope.selectedNode.statename = $scope.selectedNode.name;
        }

        refreshDetailLists(nodeId);
        $scope.detail.name = $scope.selectedNode.name;
        $scope.detail.hierarchy = templateEditorService.getComponentHierarchy(
            graph.getComponent(nodeId)
        ); // array of node with id and name

        $scope.detail.hierarchy.map(function (node) {
            node.href = getHrefToNodeDetailView(node);
            return node;
        });

        $scope.predecessors = graph.getPredecessors(nodeId);
        $scope.successors = graph.getSuccessors(nodeId);
        $scope.predecessorsCount = $scope.predecessors.length;
        $scope.successorsCount = $scope.successors.length;

        // the cards handle their data via $scope.template
        if ($scope.selectedNode.category !== "ACTIVITY") {
            $scope.template = templateEditorService.toGroupCardContent(
                $scope.selectedNode,
                graph
            );
            // code is only visible for the root element
            if (graph.getRoot().getData().id !== $scope.selectedNode.id) {
                $scope.template.codeHidden = true;
            }
        } else {
            // Edit activity card
            $scope.template = templateEditorService.toActivityCardContent(
                $scope.selectedNode
            );
        }
        $scope.template.DURATION = $filter("localizedDecimalNumber")(
            $scope.template.DURATION
        );
        $rootScope.$on("$translateChangeSuccess", function () {
            // Language has changed let's change the value as well.
            $scope.template.DURATION = $filter("localizedDecimalNumber")(
                $scope.template.DURATION
            );
        });
        $scope.template.calendar = projectCalendar;
    }

    /**
     * Set the component list of the detail section (group and activity)
     *
     * @param {String} nodeId - the id of the node that should be visible in the detail
     */
    function refreshDetailLists(nodeId) {
        $scope.detail.components = graph.getChildComponentsData(nodeId);
    }

    /**
     * Change the state to the current selection
     *
     * @param {String} nodeId - identifier of the node that should be selected
     */
    function setSelectedNode(nodeId) {
        $state.go("sablono.project.templateEditor.nodeDetailView", {
            templateId: templateId,
            nodeId: nodeId,
        });
    }

    /**
     * Determine the direction between two nodes of the graph (up, down, left, right, unknown)
     * @param {Component} fromNodeId - start point
     * @param {Component} toNodeId - end point
     * @returns {string} - Enum of the direction
     */
    function getNextDirection(fromNodeId, toNodeId) {
        return TEMPLATE_DIRECTIONS[graph.findDirection(fromNodeId, toNodeId)];
    }

    //  Handle the state change success
    //
    $scope.$on(
        "$stateChangeStart",
        function onStateChangeSuccess(
            event,
            toState,
            toParams,
            fromState,
            fromParams
        ) {
            var stateName = "sablono.project.templateEditor.nodeDetailView";

            // if you stay on the page but the query has changed
            //
            if (toState.name === stateName && fromState.name === stateName) {
                var fromNode = fromParams.nodeId;
                var toNode = toParams.nodeId;
                $scope.nextDirection = getNextDirection(fromNode, toNode);
            }

            // if you try to leave the page
            //
            if (toState.name !== stateName && fromState.name === stateName) {
                // if the to state is not flagged  with a force attribute (e.g. login page)
                if (angular.isDefined(toState.data) && !toState.data.forLogin) {
                    // if there are unsaved changes
                    if ($scope.rootNode.unsavedChanges) {
                        event.preventDefault();
                        $scope.showExitDialog(event, toState, toParams);
                    }
                }
            }
        }
    );

    $scope.$on(
        "$stateChangeSuccess",
        function onStateChangeSuccess(event, toState, toParams) {
            var nodeId = toParams.nodeId;
            if (!graph) {
                initGraph(preloadedGraph, nodeId);
            } else {
                _navigateToNode(nodeId);
            }
        }
    );

    /**
     * Initialize the editor with a graph
     *
     * @param {TemplateGraph} newGraph - the graph context of the whole page
     * @param {String} initWithNodeId - identifier of the node that should be selected and highlighted
     */
    function initGraph(newGraph, initWithNodeId) {
        graph = newGraph;
        graph.calendar = projectCalendar;

        if (!initWithNodeId || !graph.getComponent(initWithNodeId)) {
            initWithNodeId = graph.getRoot().id;
        }

        $scope.rootNode = graph.getRoot().getData();
        $scope.treeRootGroup = graph.getRoot();

        setSelectedNode(initWithNodeId);
        _navigateToNode(initWithNodeId);

        if ($scope.rootNode.lastChangesTimestamp) {
            showUnpublishedChangedDataDialog();
        }
    }

    /**
     * Saves current view model to local storage
     * @param {Array|Object} changedElement - plain data that has been changed
     */
    function handleLocalChanges(changedElement) {
        if (changedElement) {
            if (angular.isArray(changedElement)) {
                changedElement.forEach(function (elem) {
                    elem.isDirty = true;
                });
            } else {
                changedElement.isDirty = true;
            }
        }

        $scope.rootNode.unsavedChanges = true;
        $scope.rootNode.lastChangesTimestamp = new Date();
        templateEditorService
            .saveTemplateDetailsToLocalStore(templateId, graph.toJSON())
            .catch($sbErrorPresenter.catch);
        try {
            $scope.predecessorsCount =
                graph.components[$state.params.nodeId].predecessors.length;
            $scope.successorsCount =
                graph.components[$state.params.nodeId].successors.length;
        } catch (e) {
            //
        }
    }

    /**
     * Trigger the selection of a node.
     * @param {Component|Object} node - node to set as detail
     */
    $scope.enterNode = function (node) {
        setSelectedNode(node.id);
    };

    /**
     * Handle the overall removing of a dependency in the context of the complete editor.
     *
     * @param {Component} connectedActivity - the dependency to remove
     * @param {boolean} isPredecessor - if it is a predecessor (otherwise successor)
     */
    $scope.deleteDependency = function (connectedActivity, isPredecessor) {
        if (isPredecessor) {
            graph.deleteDependency(connectedActivity, $scope.selectedNode.id);
        } else {
            graph.deleteDependency($scope.selectedNode.id, connectedActivity);
        }
        var changed = [$scope.selectedNode, connectedActivity.getData()];
        handleLocalChanges(changed);
    };

    /**
     * For a search term - compute the list of available activities to assign as predecessor/successor
     *
     * @param {string} searchTerm - text to look for.
     * @param {boolean} suggestPredecessors - true for predecessors cycle detection
     * @returns {Array.<Activity>} - the activities that match the searchTerm
     */
    $scope.suggestDependency = function (searchTerm, suggestPredecessors) {
        var normalizedTerm = searchTerm ? searchTerm.toLowerCase() : "";
        var escapedTerm = escapeStringRegexp(normalizedTerm);

        //filter direct predecessors + successors + self
        var currentPredecessors = graph.getPredecessors($scope.selectedNode.id);
        var currentSuccessors = graph.getSuccessors($scope.selectedNode.id);
        var currentComponent = graph.getComponent($scope.selectedNode.id);

        /*
         * check if property exist and is matching the lower case search term.
         */
        function findTermIn(searchTerm, object, key) {
            if (object && object[key]) {
                return object[key].toLowerCase().search(searchTerm) > -1;
            }
            return false;
        }

        // search in code + name + statename + parentname
        //
        return graph
            .getActivityList()
            .filter(function onlyActivitiesMatchingTheSearchTerm(activity) {
                var properties = activity.getData();

                return (
                    findTermIn(escapedTerm, properties, "name") ||
                    findTermIn(escapedTerm, properties, "statename") ||
                    findTermIn(escapedTerm, properties, "code") ||
                    (activity.hasParent() &&
                        findTermIn(
                            escapedTerm,
                            activity.getParent().getData(),
                            "name"
                        ))
                );
            })
            .filter(function edgeAlreadyExist(activity) {
                var isPredecessor = currentPredecessors.indexOf(activity) > -1;
                var isSuccessor = currentSuccessors.indexOf(activity) > -1;

                return (
                    !isPredecessor &&
                    !isSuccessor &&
                    activity.notSelfById(currentComponent)
                );
            })
            .filter(function willNotCreateCycle(activity) {
                if (suggestPredecessors) {
                    return !Activity.willCreateCycle(
                        graph.getComponent(activity.id),
                        currentComponent
                    );
                } else {
                    return !Activity.willCreateCycle(
                        currentComponent,
                        graph.getComponent(activity.id)
                    );
                }
            });
    };

    /**
     * Handles the selection of a dependency in the autocomplete
     * -> adds the new dependency to graph if possible
     * @param {Component} selectedActivity - activity that is added as a dependency
     * @param {boolean} isPredecessor - determines if selectedActivity is added as a predecessor or a successor
     * @return {Promise} - promise covering the conversion.
     */
    $scope.createDependency = function (selectedActivity, isPredecessor) {
        var selectedActivityReference = graph.getComponent(selectedActivity.id);
        var selectedNodeReference = graph.getComponent($scope.selectedNode.id);

        return $q(function (resolve, reject) {
            if (isPredecessor) {
                if (
                    Activity.willCreateCycle(
                        selectedActivityReference,
                        selectedNodeReference
                    )
                ) {
                    return reject("PREDECESSOR");
                }
                graph.addDependency(
                    selectedActivity.id,
                    $scope.selectedNode.id
                );
            } else {
                if (
                    Activity.willCreateCycle(
                        selectedNodeReference,
                        selectedActivityReference
                    )
                ) {
                    return reject("SUCCESSOR");
                }
                graph.addDependency(
                    $scope.selectedNode.id,
                    selectedActivity.id
                );
            }
            var changed = [$scope.selectedNode, selectedActivity.getData()];
            handleLocalChanges(changed);
            return resolve();
        });
    };

    /**
     * Creates a new Group with same Name as Activity,
     * existing activity becomes component of new Group (to keep progress)
     *
     * @param {$event} $event - the event that triggered the action
     * @param {Component|Object} component - the component with id to work with.
     */
    $scope.transformToGroup = function ($event, component) {
        $event.stopPropagation();

        $mdDialog
            .show(
                $mdDialog
                    .select()
                    .title("DIALOG_TRANSFORM_ACTIVITY_OPTION")
                    .selectOptions(transformActivityOptions)
                    .selectedOption(
                        transformActivityOptions[
                            transformActivityOptions.length - 1
                        ]
                    )
                    .targetEvent($event)
            )
            .then(function (userSelection) {
                var selectedOption = userSelection.select;
                var group = graph.transformActivityToGroup(
                    component.id,
                    selectedOption.keepActivity,
                    function (activityData) {
                        return {
                            id: templateEditorService.getUniqueKey(),
                            layer: activityData.layer,
                            name:
                                activityData.name +
                                "-" +
                                activityData.statename,
                            category: "GROUP",
                            closedChildren: false,
                        };
                    }
                );
                var changed = selectedOption.keepActivity
                    ? [component, group.getData()]
                    : group.getData();
                setSelectedNode(group.id);
                handleLocalChanges(changed);
            });
    };

    /**
     * Handles update of node attributes via the edit directive
     *
     * @param {Object} node - the edited node data from the form
     * @param {Object} form - the form
     */
    $scope.edit = function (node, form) {
        if (!form.$valid || !form.$dirty) {
            return;
        }

        editTemplateNode(node);
    };

    function editTemplateNode(node) {
        if (node.CATEGORY !== "ACTIVITY") {
            var group = node;

            // update all child activities with assigned teams from group
            //
            updateTeamAssignmentOf(group);

            templateEditorService.updateGroupFromCardContent(
                group,
                $scope.selectedNode
            );
        } else {
            templateEditorService.updateActivityFromCardContent(
                node,
                $scope.selectedNode
            );
        }
        graph.refreshCodes();
        handleLocalChanges($scope.selectedNode);
    }

    function updateTeamAssignmentOf(group) {
        /**
         * RM 2018-06-1
         * Removed team assignment GA events as they were firing on all group field changes (not just team change).
         * TODO: Only fire this function on team assignment change
         */
        var canReassignTeamToChildrenFromGroup =
            angular.isArray(group.ASSIGNED_TEAM_IDS) &&
            group.ASSIGNED_TEAM_IDS.length === 1;
        if (canReassignTeamToChildrenFromGroup) {
            templateEditorService.updateAssignedTeamInActivitiesBy(
                group.ID,
                group.ASSIGNED_TEAM_IDS[0],
                graph
            );
        }
    }

    /**
     * Remove the given node from the graph.
     *
     * @param {Component|Object} node - to delete
     * @returns {{removedComponents, removedInterDependencies}|{removedComponents: Array.<Component>, removedInterDependencies: Array.<Edge>}} - edges and components that are deleted by the action
     */
    function deleteNode(node) {
        var deleteNodeId = node.id;
        var nodeToDelete = graph.getComponent(deleteNodeId);

        var deleteParentId = nodeToDelete.getParent().id;
        var parent = graph.getComponent(deleteParentId).getData();
        var removedData = graph.removeNode(deleteNodeId);
        handleLocalChanges(parent);

        return removedData;
    }

    /**
     * Deletes a node or an activity, show confirm dialog + undo toast
     *
     * @param {Object} $event - the click event
     * @param {Object} node - the node that should be deleted
     */
    $scope.deleteTree = function ($event, node) {
        $event.stopPropagation();

        var deleteNodeId = node.id;
        var deleteNodeData = graph.getComponent(deleteNodeId);
        var deleteParentId = deleteNodeData.getParent().id;

        var removedData;
        var dialogText =
            node.category === "ACTIVITY"
                ? "CONFIRM_DELETE_ACTIVITY_MESSAGE"
                : "CONFIRM_DELETE_GROUP_MESSAGE";
        $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .content(dialogText)
                    .contentValues({
                        name: node.name,
                    })
                    .targetEvent($event)
            )
            .then(function () {
                removedData = deleteNode(node);

                refreshDetailLists($scope.selectedNode.id);
                // show success toast + offer possibility to undo
                $mdToast.show(
                    $mdToast
                        .undo()
                        .content("INFO_TEMPLATE_NODE_REMOVE_SUCCESS_TITLE")
                        .contentValues({
                            name: node.name,
                        })
                        .position("top right")
                        .resolveAction(function () {
                            // on undo: insert the deleted note again.
                            graph.addComponents(removedData.removedComponents);
                            graph.changeParent(deleteNodeId, deleteParentId);
                            // insert the deleted edges again
                            removedData.removedInterDependencies.forEach(
                                function (edge) {
                                    graph.addDependency(
                                        edge.source,
                                        edge.target
                                    );
                                }
                            );

                            refreshDetailLists($scope.selectedNode.id);
                            handleLocalChanges(parent);
                        })
                );
            });
    };

    /**
     * Deletes a node or an activity, show confirm dialog + undo toast
     * Navigates to the parent
     *
     * @param {Object} $event - the click event
     * @param {Object} node - the node that should be deleted
     */
    $scope.deleteTreeAndGoToParent = function ($event, node) {
        $event.stopPropagation();
        var deleteNodeId = node.id;
        var deleteNodeData = graph.getComponent(deleteNodeId);

        var deleteParentId = deleteNodeData.getParent().id;
        var removedData;
        var dialogText =
            node.category === "ACTIVITY"
                ? "CONFIRM_DELETE_ACTIVITY_MESSAGE"
                : "CONFIRM_DELETE_GROUP_MESSAGE";
        $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .content(dialogText)
                    .contentValues({
                        name: node.name,
                    })
                    .targetEvent($event)
            )
            .then(function () {
                //   var removedData = graph.removeNode(deleteNodeId);
                //   refreshDetailLists($scope.selectedNode.id);
                //   handleLocalChanges(parent);
                removedData = deleteNode(node);
                setSelectedNode(deleteParentId);

                // show success toast + offer possibility to undo
                $mdToast.show(
                    $mdToast
                        .undo()
                        .content("INFO_TEMPLATE_NODE_REMOVE_SUCCESS_TITLE")
                        .contentValues({
                            name: node.name,
                        })
                        .position("top right")
                        .resolveAction(function () {
                            // on undo: insert the deleted note again.
                            graph.addComponents(removedData.removedComponents);
                            graph.changeParent(deleteNodeId, deleteParentId);
                            // insert the deleted edges again
                            removedData.removedInterDependencies.forEach(
                                function (edge) {
                                    graph.addDependency(
                                        edge.source,
                                        edge.target
                                    );
                                }
                            );

                            refreshDetailLists($scope.selectedNode.id);
                            handleLocalChanges(parent);
                            setSelectedNode(deleteParentId);
                        })
                );
            });
    };

    /**
     * Suggest templates based on autoComplete input
     *
     * @param {string} searchTerm - text to look for
     * @returns {Promise} - Promise covering the result of group suggestion.
     */
    $scope.suggestGroup = function (searchTerm) {
        return templateEditorService.suggestGroup(
            searchTerm,
            graph.getRoot().id
        );
    };

    /**
     * Suggest templates based on autoComplete input
     *
     * @param {string} searchTerm - text to look for
     * @returns {Promise} - Promise covering the result of activity suggestion.
     */
    $scope.suggestActivities = function (searchTerm) {
        return templateEditorService.suggestActivities(searchTerm);
    };

    /**
     * Handle the auto-complete select
     *
     * @param {Object} selectedOrCreatedComponent - component properties
     */
    $scope.selectActivity = function (selectedOrCreatedComponent) {
        // no selected item -> ESC pressed while having suggestion.
        if (!selectedOrCreatedComponent) {
            return;
        }

        var simpleObject = templateEditorService.createSimpleActivity(
            selectedOrCreatedComponent,
            false,
            $scope.selectedNode.layer + 1
        );
        if (!simpleObject.statename) {
            simpleObject.stateedit = true;
        }
        graph.newComponentToParent(
            simpleObject.id,
            $scope.selectedNode.id,
            simpleObject
        );
        $scope.addComponentToModel(simpleObject);
    };

    /**
     * Callback for tree - on node select
     *
     * @param {Component|Object} node - select the node
     */
    $scope.onTreeNodeSelect = function (node) {
        setSelectedNode(node.id);
    };

    /**
     * Callback for tree - on node moved -> dnd
     *
     * @param {Component} fromNode - the node that is dragged.
     * @param {Component} toNode - the element where the dragged element is dropped.
     *
     * @return {boolean} - true if successful
     */
    $scope.onTreeNodeMove = function (fromNode, toNode) {
        var parentNode = toNode.getData();
        var previousParentNode = fromNode.getParent();

        if (parentNode.category === "ACTIVITY") {
            $mdToast.show(
                $mdToast
                    .simple()
                    .content("INFO_CANNOT_DRAG_INTO_ACTIVTY_MESSAGE")
                    .position("top right")
            );
            return false;
        }

        if (toNode.hasAncestor(fromNode)) {
            $mdToast.show(
                $mdToast
                    .simple()
                    .content("INFO_CANNOT_DRAG_INTO_CHILD_MESSAGE")
                    .position("top right")
            );
            return false;
        }

        graph.changeParent(fromNode, toNode);
        handleLocalChanges([
            fromNode.getData(),
            toNode.getData(),
            previousParentNode.getData(),
        ]);
        return true;
    };

    /**
     * Handles Selection of autoComplete
     * either: add a new group or an existing tree
     *
     * @param {Object} selectedOrCreatedComponent - result of the suggestion
     */
    $scope.selectTree = function (selectedOrCreatedComponent) {
        // no selected item -> ESC pressed while having suggestion.
        if (!selectedOrCreatedComponent) {
            return;
        }

        // if the new component has a valid server id
        //  -> request the template part from the server.
        //  -> else: make a plain add of the created element.
        //
        if (!angular.isString(selectedOrCreatedComponent.ID)) {
            var simpleObject = templateEditorService.createSimpleGroup(
                selectedOrCreatedComponent,
                false,
                $scope.selectedNode.layer + 1
            );
            graph.newComponentToParent(
                simpleObject.id,
                $scope.selectedNode.id,
                simpleObject
            );
            $scope.addComponentToModel(simpleObject);
        } else {
            templateEditorService
                .appendTree(
                    selectedOrCreatedComponent,
                    $scope.selectedNode,
                    graph
                )
                .then(function (newComponent) {
                    $scope.addComponentToModel(newComponent);
                })
                .catch($sbErrorPresenter.catch);
        }
    };

    /**
     * Add a new component to the graph
     *
     * @param {Object} component - the properties of a new component
     */
    $scope.addComponentToModel = function (component) {
        $scope.detail.components.unshift(component);
        handleLocalChanges(component);
    };

    /**
     * Handle the the state of an activity was changed.
     *
     * @param {Object} component - the element that was changed
     */
    $scope.onStateChange = function (component) {
        handleLocalChanges(component);
    };

    /**
     * save all local changes on server, clear local store on success
     * @return {Promise} - Promise covering an empty result, but is resloved if everything is done..
     */
    $scope.saveChanges = function () {
        $mdToast.show($mdToast.simple().content("INFO_PUBLISHING_MESSAGE"));
        return templateEditorService
            .saveTemplatesDetails(templateId, graph.toJSON())
            .then(function (savedTemplate) {
                initGraph(savedTemplate, $scope.selectedNode.id);
                return templateEditorService.removeTemplateDetailsFromLocalStore(
                    templateId
                );
            })
            .catch($sbErrorPresenter.catch);
    };

    $scope.saveChangesAndUpdate = function () {
        $scope.isLoading = true;
        Analytics.trackConversion("template saved");

        return $sbTemplate
            .getTemplate(templateId)
            .then(function ({ USED_IN_COMPONENTS }) {
                if (USED_IN_COMPONENTS > 0) {
                    return _saveTemplateAndAssignToDeliverables().catch(
                        _parseCheckForCustomChanges
                    );
                } else {
                    return $scope
                        .saveChanges()
                        .then(_showTemplateSavedSuccessToast);
                }
            })
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                $scope.isLoading = false;
            });
    };

    function _parseCheckForCustomChanges(error) {
        $sbErrorPresenter.catch(error);
    }

    function _fetchAndSetAvailableChecklists() {
        return $sbChecklistTemplateWebService
            .getByProject($stateParams.projectId)
            .then(function (checklists) {
                var mappedChecklists = checklists.checklist_templates.map(
                    function (checklist) {
                        return {
                            title: checklist.name,
                            id: checklist.id,
                            numberOfItems: checklist.checklist_items.length,
                        };
                    }
                );

                $filter("naturalSort")(mappedChecklists, "title");

                $scope.checklists = mappedChecklists;
            })
            .catch($sbErrorPresenter.catch);
    }

    function _showTemplateSavedSuccessToast() {
        return $mdToast.show(
            $mdToast.simple().content("INFO_TEMPLATE_PUBLISHED_SUCCESSFUL")
        );
    }

    function _saveTemplateAndAssignToDeliverables() {
        return $scope
            .saveChanges()
            .then(function (templateId) {
                loadingToast.show("INFO_UPDATING_DELIVERABLES_MESSAGE");
                return $sbPullUpdate
                    .forAssociatedDeliverablesInProject(templateId)
                    .then(function () {
                        $mdToast.show(
                            $mdToast
                                .simple()
                                .content(
                                    "INFO_UPDATING_DELIVERABLES_SUCCESSFUL"
                                )
                        );
                    })
                    .catch(function (error) {
                        try {
                            if (
                                error.message ===
                                "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION"
                            ) {
                                $sbTracking.leanBoard.session.concurrentSession(
                                    "Tried to change a deliverable that has been changed in another session"
                                );
                                return $sbDialog.openModifiedInConcurrentSessionDialog(
                                    error
                                );
                            } else {
                                $sbErrorPresenter.catch(
                                    error,
                                    PresentableError.presentationStyle.DIALOG
                                );
                            }
                        } catch (e) {
                            $sbErrorPresenter.catch(
                                e,
                                PresentableError.presentationStyle.DIALOG
                            );
                        }
                    })
                    .finally(function () {
                        loadingToast.hide();
                    });
            })
            .catch(function (error) {
                if (error) {
                    $sbErrorPresenter.catch(
                        error,
                        PresentableError.presentationStyle.DIALOG
                    );
                }
            });
    }

    /**
     * Discard all changes and load new model from server
     *
     * @param {$event} $event - the click event
     */
    $scope.discardChanges = function ($event) {
        $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .content("CONFIRM_DISCARD_CHANGES_MESSAGE")
                    .targetEvent($event)
            )
            .then(function () {
                discardData();
            });
    };

    /**
     * Discard all changes and load new model from server
     *
     * @return {Promise} - Promise covering an empty result, but is resolved if everything is done..
     */
    $scope.discardChangesAndExit = function () {
        return $q(function (resolve, reject) {
            loadingToast.show("INFO_DISCARDING_MESSAGE");
            templateEditorService
                .removeTemplateDetailsFromLocalStore(templateId)
                .finally(function () {
                    templateEditorService
                        .getGraphFromServer(templateId)
                        .then(function (newGraph) {
                            //nessesary
                            loadingToast.hide();
                            //
                            initGraph(newGraph);
                            resolve();
                        })
                        .catch($sbErrorPresenter.catch)
                        .catch(reject);
                });
        });
    };

    /**
     * Inform the user that there are unsaved changes and ask him for a solution.
     *
     * @param {$event} $event - the click event
     * @param {string} toState - name of the state you want to navigate to.
     * @param {Object} toStateParams - parameter object of the state you want to navigate to.
     */
    $scope.showExitDialog = function ($event, toState, toStateParams) {
        $mdDialog
            .show($mdDialog.sbExitEditDialog().clickOutsideToClose(true))
            .then((response) => {
                switch (response) {
                    case "save": {
                        return $scope.saveChanges();
                    }
                    case "discard": {
                        return $scope.discardChangesAndExit();
                    }
                    case "publish": {
                        return $scope.saveChangesAndUpdate();
                    }
                    default: {
                        return Promise.resolve();
                    }
                }
            })
            .then(() => {
                $state.go(toState, toStateParams);
            })
            .catch(angular.noop);
    };

    /**
     * Handle local changes with the selected node.
     */
    $scope.saveChangesFromCurrentNode = function () {
        handleLocalChanges($scope.selectedNode);
    };

    /**
     * Open the dialog for changing the parent
     * @param  {$event} $event - click event
     * @param  {Object} [node] - click event
     */
    $scope.openChangeParentDialog = function ($event, node) {
        $mdDialog
            .show(
                $mdDialog
                    .sbChangeParentDialog()
                    .treeRootGroup($scope.treeRootGroup)
                    .changeParentForNode(node)
                    .targetEvent($event)
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
            )
            .then(function (parent) {
                graph.changeParent(node.id, parent.id);
                handleLocalChanges(graph.getComponent(node.id).getData());
                if ($scope.selectedNode.id === node.id) {
                    // update breadcrumps
                    $scope.detail.hierarchy =
                        templateEditorService.getComponentHierarchy(
                            graph.getComponent(node.id)
                        ); // array of node with id and name
                    $scope.detail.hierarchy.map(function (node) {
                        node.href = getHrefToNodeDetailView(node);
                        return node;
                    });
                }
            });
    };

    /*
     *  Shows a confirm dialog for getting the user decision regarding discard or keep with local data.
     * */
    function showUnpublishedChangedDataDialog() {
        const confirm = $mdDialog
            .confirm()
            .title("MAKING_DECISION_LOCAL_DATA_HEADER")
            .content("MAKING_DECISION_LOCAL_DATA_MESSAGE")
            .contentValues({
                timeAgo: moment($scope.rootNode.lastChangesTimestamp).fromNow(),
            })
            .ok("DISCARD_AND_DOWNLOAD_NEW_VERSION")
            .cancel("_CONTINUE");

        $mdDialog.show(confirm).then(function () {
            discardData();
        });
    }

    function discardData() {
        loadingToast.show("INFO_DISCARDING_MESSAGE");
        templateEditorService
            .removeTemplateDetailsFromLocalStore(templateId)
            .finally(function () {
                templateEditorService
                    .getGraphFromServer(templateId)
                    .then(function (newGraph) {
                        loadingToast.hide();
                        initGraph(newGraph);
                    })
                    .catch($sbErrorPresenter.catch);
            });
    }

    /**
     * Exports a GraphML representation of the current template state.
     * This file can be imported to yEd for further refinement.
     */
    $scope.onExportGraphMlFile = function () {
        $sbTracking.processTemplate().exportAsGraph();
        var fileName =
            moment().format("YYYY_M_D_H_m") +
            "_" +
            $scope.rootNode.name +
            ".graphml";
        $scope.storeInDownloadCenter(
            $scope.generateGraphMlDocument(),
            fileName
        );
    };

    /**
     * Generate a string representation of the GraphML document
     */
    $scope.generateGraphMlDocument = function () {
        var graphData = graph.toJSON();

        return $sbGraphMlGenerator.toGraphMlDocument(templateId, {
            getAllActivities: function () {
                return graphData.components
                    .filter(function (component) {
                        return component.category === "ACTIVITY";
                    })
                    .map(function (activity) {
                        var assignedTeam = _.find(teams, [
                            "id",
                            activity.assignedTeamId,
                        ]);

                        return {
                            id: activity.id,
                            name: activity.name,
                            color: _.get(assignedTeam, "color", "#FFFFFF"),
                        };
                    });
            },
            getAllDependencies: function () {
                return graphData.edges;
            },
        });
    };

    /**
     * Will download the given string as file in the browser.
     *
     * @param graphMlDocument {string}
     * @param fileName {string}
     */
    $scope.storeInDownloadCenter = function (graphMlDocument, fileName) {
        var downloadKey = downloadCenterService.store(
            graphMlDocument,
            "application/xml"
        );
        downloadCenterService.download(downloadKey, fileName);
        downloadCenterService.remove(downloadKey);
    };
}
