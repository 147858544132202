import getPreferredLanguageKey from "../common/preferredLanguage/preferredLanguage";
import * as langDe from "../lang/de";
import * as langEn from "../lang/en";
import * as langEsEs from "../lang/es-ES";
import * as langFr from "../lang/fr";

export default function (module) {
    module.config(
        function (
            $translateProvider,
            AVAILABLE_LANGUAGE_KEYS,
            DEFAULT_LANGUAGE_KEY
        ) {
            var userPreference = localStorage.getItem("languageKey");

            //check if the user preference exists in the available languages
            if (AVAILABLE_LANGUAGE_KEYS.indexOf(userPreference) === -1) {
                userPreference = undefined;
            }

            var systemPreference = getPreferredLanguageKey();

            $translateProvider
                .translations("de", langDe)
                .translations("en", langEn)
                .translations("es", langEsEs)
                .translations("fr", langFr)
                .registerAvailableLanguageKeys(AVAILABLE_LANGUAGE_KEYS, {
                    "de*": "de",
                    "es*": "es",
                    "fr*": "fr",
                    "*": DEFAULT_LANGUAGE_KEY,
                })
                .preferredLanguage(userPreference || systemPreference)
                .fallbackLanguage(DEFAULT_LANGUAGE_KEY);

            /**
             * We define our own handler for what happens if translations for a key are missing.
             * The current implementation of angular-translate does not sanitize text it does not know.
             * Meaning the sanitization strategy configured below is not applied to those texts. That
             * allows for stored XSS when user input is passed through the "translate" directive.
             */
            $translateProvider.useMissingTranslationHandler(
                "$sbMissingTranslationHandlerService"
            );

            /**
             * Using the ["sce", "sceParameters"] strategy has the implication that after translations happened e.g. by
             * using filters the result is a special object instead of a string which may break follow-up
             * filter when chained and expecting a string.
             *
             * The positive thing is that markup is detected correctly and formatted.
             *
             * When using the "escape" strategy markup end up escaped in the DOM and is not processes
             * meaning we can't use <br> or <h1> tags inside translations. We would have to spit into multiple
             * translations and use the markup in the DOM directly. Which might be a good separation
             * of concerns anyway.
             *
             * The positive thing is that the resulting translation stays a string and can be further processed.
             */
            $translateProvider.useSanitizeValueStrategy([
                "sce",
                "sceParameters",
            ]);
        }
    );
}
