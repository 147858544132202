import angular from "angular";
import htmlTemplate from "./info_message.tmpl.html";

export default angular
    .module("sbApp.common.components.infoMessage", [])
    .component("sbInfoMessage", {
        replace: true,
        templateUrl: htmlTemplate,
        bindings: {
            text: "@",
            dismissable: "<",
            // allowed types: INFO, WARNING, ALERT
            type: "@",
        },
        controller: function ChoiceGroupCtrl($attrs) {
            var vm = this;

            vm.isClickable = !!$attrs.ngClick;

            vm.$onInit = $onInit;

            function $onInit() {
                vm.hidden = false;

                // default: info
                vm.class = "sb-info-message-info";
                vm.icon = "mdi mdi-information";

                if (vm.type === "WARNING") {
                    vm.class = "sb-info-message-warning";
                    vm.icon = "mdi mdi-alert";
                }
                if (vm.type === "ALERT") {
                    vm.class = "sb-info-message-alert";
                    vm.icon = "mdi mdi-alert-circle";
                }
            }
        },
    });
