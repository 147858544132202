import _ from "lodash";
import Activity from "../../../../domain/sb_activity.class";

export default function CombineCostGroupActivitiesCtrl(
    $scope,
    $sbErrorPresenter,
    $mdDialog,
    $sbTracking,
    $sbTemplate,
    $sbActivities,
    $sbCostPackagesApi
) {
    "ngInject";

    var vm = this;

    let _selectedActivities = [];
    vm.availableActivities = [];

    // the data model that is created in the dialog
    vm.result = {
        name: undefined,
    };

    // UI callbacks
    vm.submit = submit;
    vm.closeDialog = closeDialog;
    vm.hasSelectedStages = hasSelectedStages;
    vm.canSubmit = canSubmit;
    vm.onStageSelectionChange = onStageSelectionChange;

    activate();

    function activate() {
        $sbTracking.commercial.onEditingStageGroups("Process started");
        return _loadUngroupedActivityTemplates();
    }

    function submit(group) {
        group.activities = _selectedActivities.map((selectedActivity) => ({
            id: selectedActivity.id,
        }));
        $mdDialog.hide(group);
    }

    function canSubmit() {
        return (
            _isValidCombinedCostGroupStagesName(vm.result.name) &&
            hasSelectedStages(vm.availableStages)
        );
    }

    function onStageSelectionChange(selectedStages) {
        _selectedActivities = selectedStages;
    }

    //////
    //  internals
    ///

    function _isValidCombinedCostGroupStagesName(name) {
        return (
            _.isString(name) &&
            name.trim().length <= 128 &&
            name.trim().length > 0
        );
    }

    function hasSelectedStages(activities) {
        return _selectedActivities.length > 0;
    }

    function _loadUngroupedActivityTemplates() {
        return $sbCostPackagesApi
            .get(vm.costGroup.id)
            .then((result) => {
                _addActivities(result.activity_templates);
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                $mdDialog.cancel();
            });
    }

    function _addActivities(activityTemplates) {
        vm.availableActivities = activityTemplates.map((activityTemplate) => {
            const activity = new Activity(
                activityTemplate.id,
                activityTemplate.name,
                ""
            );
            activity.processTemplateName = _.find(vm.costGroup.stages, [
                "id",
                activityTemplate.id,
            ]).processTemplateName;

            return activity;
        });
    }

    function closeDialog() {
        if (vm.hasSelectedStages()) {
            $sbTracking.commercial.onEditingStageGroups(
                "Cancelled before Name defined"
            );
        } else {
            $sbTracking.commercial.onEditingStageGroups(
                "Cancelled before Activities selected"
            );
        }

        $mdDialog.cancel();
    }
}
