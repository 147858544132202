import angular from "angular";
import _ from "lodash";
import moment from "moment";

export default function viewFiltersService($translate) {
    "ngInject";
    var service = {
        getChipsFromViewFilter: getChipsFromViewFilter,
        trackFilterChanges: trackFilterChanges,
    };

    return service;

    function getChipsFromViewFilter(viewFilters) {
        return viewFilters
            .filter((viewFilter) => viewFilter.isValueDisplayable())
            .map(function (viewFilter) {
                var filterValues = viewFilter.getValuesTranslatableForChips();
                var chipValue = "";
                var lastFilter = _.last(filterValues);
                _.forEach(filterValues, function (filterValue, i) {
                    if (i > 0) {
                        chipValue += " ";
                    }
                    if (angular.isDate(filterValue)) {
                        chipValue += moment(filterValue).format("D MMMM YYYY");
                        if (filterValue !== lastFilter) {
                            chipValue += " - ";
                        }
                    } else {
                        chipValue += $translate.instant(filterValue);
                    }
                });
                return {
                    label:
                        $translate.instant(viewFilter.getTranslatableKey()) +
                        ": ",
                    value: chipValue,
                    viewFilter: viewFilter,
                };
            });
    }

    function trackFilterChanges(
        filters,
        trackedFilters,
        blackListFilters,
        trackingFunction
    ) {
        filters.forEach(function (filter) {
            var isTrackedAlready = _.find(trackedFilters, ["key", filter.key]);
            if (blackListFilters.indexOf(filter.key) === -1) {
                if (filter.hasValue() && !isTrackedAlready) {
                    trackedFilters.push(filter);
                    trackingFunction(filter.key);
                } else {
                    _.remove(trackedFilters, ["key", filter.key]);
                }
            }
        });
        return trackedFilters;
    }
}
