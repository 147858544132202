import createjs from "createjs";

/**
 * Event that is dispatched when PixelRatio changed
 * @constructs PixelRatioChangedEvent
 * @param {WBS.EditorStage} stage - Stage where pixel ratio was changed
 * @param {Object} pixelRatio - new PixelRatio
 * @extends createjs.Event
 * @memberof WBS
 */
function PixelRatioChangedEvent(stage, pixelRatio) {
    createjs.Event.call(this, "pixelRatioChanged", false, false);
    this.stage = stage;
    this.pixelRatio = pixelRatio;
}

PixelRatioChangedEvent.prototype = Object.create(createjs.Event.prototype);

export default PixelRatioChangedEvent;
