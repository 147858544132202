import "moment-timezone";
import "common/services/services";
import "common/intercom/intercomModule";

export default function ImportPackagesCtrl(
    $sbExternalService,
    $sbTeam,
    $sbCurrentProject,
    $sbTemplate,
    $filter,
    $sbLegacyExcelImport,
    $state,
    SABLONO_STATES
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    init();

    async function init() {
        const projectId = $sbCurrentProject.pick("id");

        const importPackageModalData = {
            projectId,
            excelImportLegacyCodeRequiredPieces: $sbLegacyExcelImport,
            navigateToImportSelection,
            navigateToTracker,
        };

        $sbExternalService.openImportPackageModal(importPackageModalData);
    }

    function navigateToImportSelection() {
        $state.go("sablono.project.import.selection");
    }

    function navigateToTracker(params) {
        $state.go(SABLONO_STATES.tracker, params);
    }
}
