import moment from "moment";

export default function MaintenanceCtrl() {
    "ngInject";

    const now = moment.tz("Europe/Berlin");
    const isPlannedMaintenance = isInPlannedMaintenanceWindow(now);

    const vm = this;

    vm.i18n = {
        title: isPlannedMaintenance
            ? "INFO_MAINTENANCE_CARD_TITLE_PLANNED"
            : "INFO_MAINTENANCE_CARD_TITLE_UNKNOWN",
        subTitle: isPlannedMaintenance
            ? "INFO_MAINTENANCE_CARD_SUB_TITLE_PLANNED"
            : "INFO_MAINTENANCE_CARD_SUB_TITLE_UNKNOWN",
        primaryContent: isPlannedMaintenance
            ? "INFO_MAINTENANCE_CARD_TEXT_STARTER_PLANNED"
            : "INFO_MAINTENANCE_CARD_TEXT_STARTER_UNKNOWN",
        secondaryContent: isPlannedMaintenance
            ? "INFO_MAINTENANCE_CARD_TEXT_PLANNED"
            : "INFO_MAINTENANCE_CARD_TEXT_UNKNOWN",
    };

    /**
     * Returns if a given timestamp is inside our planned maintenance windows.
     *
     * @param timestamp {moment.Moment}
     * @returns {boolean}
     */
    function isInPlannedMaintenanceWindow(timestamp) {
        if (
            (isFriday(timestamp) && isAfter10Pm(timestamp)) ||
            (isSaturday(timestamp) && isBefore2Am(timestamp))
        ) {
            return true;
        }

        if (
            (isSaturday(timestamp) && isAfter10Pm(timestamp)) ||
            (isSunday(timestamp) && isBefore2Am(timestamp))
        ) {
            return true;
        }

        return (
            (isSunday(timestamp) && isAfter10Pm(timestamp)) ||
            (isMonday(timestamp) && isBefore2Am(timestamp))
        );
    }

    /**
     * Returns for a given timestamp if it is a Monday.
     *
     * @param timestamp {moment.Moment}
     * @returns {boolean}
     */
    function isMonday(timestamp) {
        const MONDAY_INDEX = 1;
        return timestamp.isoWeekday() === MONDAY_INDEX;
    }

    /**
     * Returns for a given timestamp if it is a Friday.
     *
     * @param timestamp {moment.Moment}
     * @returns {boolean}
     */
    function isFriday(timestamp) {
        const FRIDAY_INDEX = 5;
        return timestamp.isoWeekday() === FRIDAY_INDEX;
    }

    /**
     * Returns for a given timestamp if it is a Saturday.
     *
     * @param timestamp {moment.Moment}
     * @returns {boolean}
     */
    function isSaturday(timestamp) {
        const SATURDAY_INDEX = 6;
        return timestamp.isoWeekday() === SATURDAY_INDEX;
    }

    /**
     * Returns for a given timestamp if it is a Sunday.
     *
     * @param timestamp {moment.Moment}
     * @returns {boolean}
     */
    function isSunday(timestamp) {
        const SUNDAY_INDEX = 7;
        return timestamp.isoWeekday() === SUNDAY_INDEX;
    }

    /**
     * Returns if the given timestamp is after 10pm including 10pm
     * Hour index go from 0 to 23.
     *
     * @param timestamp {moment.Moment}
     * @returns {boolean}
     */
    function isAfter10Pm(timestamp) {
        return timestamp.hour() >= 21;
    }

    /**
     * Returns if the given timestamp is before 2am excluding 2am.
     * Hour index go from 0 to 23.
     *
     * @param timestamp {moment.Moment}
     * @returns {boolean}
     */
    function isBefore2Am(timestamp) {
        return timestamp.hour() < 2;
    }
}
