/**
 * Directive to be used in combination with the ComponentUploadModel class.
 *
 * Usage:
 *  # Add the html tag <sb-upload-preview> to your document (no attributes needed)
 *  # From your controller broadcast a "sb.upload.preview" Event with the ComponentUploadModel
 */
import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sb_upload_preview.html";
import htmlRowTemplate from "./sb_upload_preview_tree_row.html";

export default function sbUploadPreview() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        replace: true,
        scope: {},
        //link: function postLink(scope) {},
        controllerAs: "preview",
        controller: function sbUploadPreviewCtrl(
            $templateCache,
            $scope,
            $filter
        ) {
            "ngInject";
            var vm = this;

            /////////////////////
            //
            //      SCOPE properties
            //
            /////////////////////

            vm.rowTemplate = $templateCache.get(htmlRowTemplate);

            vm.deliverablesTable = {
                headers: [
                    {
                        name: "CODE",
                        field: "code",
                    },
                    {
                        name: "NAME",
                        field: "name",
                    },
                    {
                        name: "_DESC",
                        field: "desc",
                    },
                    {
                        name: "START_DATE",
                        field: "startDateDisplay",
                        dataField: "sd",
                    },
                    {
                        name: "DUE_DATE",
                        field: "completionDateDisplay",
                        dataField: "cd",
                    },
                    {
                        name: "DURATION",
                        field: "duration",
                    },
                ],
                components: [],
                // vars for sb-table directive pagination
                itemsPerPage: 25,
                maxPages: 5,
            };

            vm.structure = [];
            vm.uploadModel = [];

            /////////////////////
            //
            //      WATCHER
            //
            /////////////////////

            $scope.$on("sb.upload.preview", setPreviewModel);

            /////////////////////
            //
            //      IMPL
            //
            /////////////////////

            /**
             * Set the preview model (components, structure) based on a event.
             *
             * @param $event
             * @param {Object} eventData                            The data send along with the event.
             * @param {ComponentUploadModel} eventData.uploadModel  The upload model the preview will represent.
             * @param {Object} eventData.headerTexts                The header for the two preview sections.
             */
            function setPreviewModel($event, eventData) {
                // these are display properties..
                //
                vm.headerTexts = eventData.headerTexts;

                // that is the data we will send.
                //
                vm.uploadModel = eventData.uploadModel;

                _setComponents(eventData.uploadModel._components);
                _setStructure(eventData.uploadModel._structuringNodes);
            }

            /**
             * Extend the given data and set it to the view model.
             *
             * @param {Array} comps The list of components to send/preview
             */
            function _setComponents(comps) {
                comps.forEach(function (c) {
                    if (c.sd) {
                        c.startDateDisplay = moment(c.sd).format("L");
                    }
                    if (c.cd) {
                        c.completionDateDisplay = moment(c.cd).format("L");
                    }
                });
                vm.deliverablesTable.components = comps;
            }

            /**
             * The the structure list to the view model
             *
             * @param {Array} structure The list of structuring nodes to send/preview.
             */
            function _setStructure(structure) {
                var naturalSort = $filter("naturalSort");
                vm.structure = naturalSort(structure, "code");
            }
        },
    };
}
