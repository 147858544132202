export default function ImportUploadCtrl(
    $stateParams,
    $sbErrorPresenter,
    $sbComponent,
    intercomService,
    $sbAuth,
    $sbProject,
    Analytics
) {
    "ngInject";

    var vm = this;

    var uploadComponentsPromise = undefined;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.uploadModel = $stateParams.uploadModel;
    vm.previousState = $stateParams.previousState;
    vm.projectId = $stateParams.projectId;

    vm.resultDAO = undefined;
    vm.hasError = false;
    vm.hasSuccess = false;
    vm.responseError = undefined;
    vm.showErrorDetail = false;

    vm.openIntercomChat = intercomService.show;
    vm.isUnknownError = isUnknownError;
    vm.showErrorDetails = showErrorDetails;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    activate();

    function activate() {
        uploadComponentsPromise = $sbComponent.uploadComponents(vm.uploadModel);
        return waitForUploadCompletion();
    }

    function isUnknownError() {
        return $sbErrorPresenter.isUnknownError(vm.responseError);
    }

    function showErrorDetails() {
        vm.showErrorDetail = true;
    }

    function waitForUploadCompletion() {
        return uploadComponentsPromise
            .then(function (resultDAO) {
                vm.hasSuccess = true;
                vm.resultDAO = resultDAO;

                // @track that user successfully imported a project since we use this workflow
                // for update excel we have to allow the no source() case. Because there is just
                // a sourceKey instead.
                //
                if (vm.uploadModel && vm.uploadModel.source()) {
                    intercomService.track(
                        intercomService.events.IMPORTED_PROJECT,
                        {
                            method: vm.uploadModel.source().type,
                        }
                    );
                    Analytics.trackEvent(
                        "Deliverable creation",
                        "import",
                        vm.uploadModel.source().type
                    );
                } else {
                    Analytics.trackEvent("Deliverable creation", "update");
                }

                Analytics.trackConversion("data imported/updated");
            })
            .catch(handleError);
    }

    function handleError(error) {
        vm.hasError = true;
        vm.responseError = $sbErrorPresenter.transformToPresentableError(error);

        // auth errors should be handled in the common way.
        //
        if ($sbErrorPresenter.isAuthError(error)) {
            return $sbAuth.goToLoginState(vm.previousState, $stateParams);
        }

        if (vm.responseError.message === "ERROR_UNIQUE_CODE_VIOLATION") {
            vm.responseError.title = "ERROR_UNIQUE_CODE_VIOLATION_TITLE";
        }
    }
}
