import _ from "lodash";

export default function (
    $mdDialog,
    $mdToast,
    Analytics,
    $sbTracking,
    $sbPrintQRCodesDialog,
    $sbPrintReportApi,
    $sbDialog,
    $sbErrorPresenter,
    $sbStructureNodesApi,
    $sbLocale,
    downloadCenterService,
    $sbProject
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var TYPES = {
        OVERVIEW: {
            icon1: "sb-icon-wbs_structure",
            title: "OVERVIEW_TITLE",
            text: "OVERVIEW_TEXT",
        },
        DELIVERABLES: {
            icon1: "mdi mdi-file",
            icon2: "sb-icon sb-icon-project_elements",
            title: "INFO_DELIVERABLES_REPORT_TITLE",
            text: "INFO_DELIVERABLES_REPORT_TEXT",
        },
        ACTIVITIES: {
            icon1: "mdi mdi-file",
            icon2: "sb-icon sb-icon-group",
            title: "INFO_ACTIVITIES_REPORT_TITLE",
            text: "INFO_ACTIVITIES_REPORT_TEXT",
        },
        QA_CHECKLIST_PDFS: {
            icon1: "mdi mdi-file-document",
            title: "INFO_QA_CHECKLIST_PDFS_TITLE",
            text: "INFO_QA_CHECKLIST_PDFS_TEXT",
        },
        QA_CHECKLIST_PDFS_BY_DATE: {
            icon1: "mdi mdi-file-document",
            title: "INFO_QA_CHECKLIST_PDFS_BY_DATE_TITLE",
            text: "INFO_QA_CHECKLIST_PDFS_BY_DATE_TEXT",
        },
        QR_CODES: {
            icon1: "mdi mdi-qrcode",
            title: "INFO_QR_CODES_TITLE",
            text: "INFO_QR_CODES_TEXT",
        },
    };

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.types = Object.keys(TYPES)
        .filter(function (key) {
            return vm.typesDisplayed.indexOf(key) > -1;
        })
        .reduce(function (typesObject, key) {
            typesObject[key] = TYPES[key];
            return typesObject;
        }, {});

    vm.cancel = $mdDialog.cancel;
    vm.printReport = printReport;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function printReport(type) {
        switch (type) {
            case "DELIVERABLES":
                Analytics.trackEvent("PDF Reports", "click", "Deliverables");
                if (vm.deliverableSelection.getSize() > 1000) {
                    Analytics.trackEvent(
                        "sb_known_errors",
                        "export_report",
                        type.toLowerCase()
                    );
                    $mdDialog.show(
                        $mdDialog
                            .alert()
                            .contentValues({
                                max: 1000,
                            })
                            .title("ERROR_REPORT_SIZE_LIMIT_TITLE")
                            .content("DELIVERABLE_REPORT_ERROR_SIZE_MESSAGE")
                    );
                    return;
                }
                _showLoadingToast();
                $sbPrintReportApi
                    .requestDeliverableReport(makeProgressReportSetup())
                    .then(_hideLoadingToast)
                    .catch(_errorHandling);
                break;

            case "ACTIVITIES":
                Analytics.trackEvent("PDF Reports", "click", "Activities");
                if (vm.deliverableSelection.getSize() > 100) {
                    Analytics.trackEvent(
                        "sb_known_errors",
                        "export_report",
                        type.toLowerCase()
                    );
                    $mdDialog.show(
                        $mdDialog
                            .alert()
                            .contentValues({
                                max: 100,
                            })
                            .title("ERROR_REPORT_SIZE_LIMIT_TITLE")
                            .content("DELIVERABLE_REPORT_ERROR_SIZE_MESSAGE")
                    );
                    return;
                }
                _showLoadingToast();
                $sbPrintReportApi
                    .requestActivityReport(makeProgressReportSetup())
                    .then(_hideLoadingToast)
                    .catch(_errorHandling);
                break;
            case "QA_CHECKLIST_PDFS":
                if (!vm.templateId) {
                    return _showMultipleTemplateIdErrorMessage();
                }

                $sbDialog.openBulkChecklistPdfExport(
                    vm.deliverableSelection,
                    vm.templateId
                );
                break;
            case "QA_CHECKLIST_PDFS_BY_DATE":
                $sbDialog.openBulkChecklistPdfExportByDate();
                break;
            case "QR_CODES":
                $sbTracking.deliverablesList.fromToolbar.onPrintQrCodes();

                // get OData query string or local list of selected deliverables
                var selection = vm.deliverableSelection.getPreferredCondition();

                if (_.isString(selection) && selection.length > 0) {
                    vm.oDataFilter.filter = selection;
                }

                if (Array.isArray(selection) && selection.length > 0) {
                    vm.oDataFilter.filter = selection
                        .map((deliverable) => `(ID eq '${deliverable.id}')`)
                        .join(" or ");
                }

                $sbPrintQRCodesDialog.show(
                    vm.oDataFilter,
                    vm.deliverableSelection.getSize()
                );

                break;

            case "OVERVIEW":
                if (vm.isLoadingDashboardReport) {
                    return;
                }

                Analytics.trackEvent("PDF Reports", "click", "Structure");

                vm.isLoadingDashboardReport = true;
                $sbStructureNodesApi
                    .printCollection($sbProject.getCurrentProjectId(), {
                        language: $sbLocale.current(),
                        as: "report",
                    })
                    .then((xhr) => {
                        downloadCenterService.downloadFrom(xhr);
                        return xhr;
                    })
                    .finally(() => {
                        vm.isLoadingDashboardReport = false;
                    })
                    .catch($sbErrorPresenter.catch);
        }

        $mdDialog.hide(type);
    }

    function _showLoadingToast() {
        $mdToast.show(
            $mdToast
                .loading()
                .content("ACTION_PRINT_REPORT")
                .hideDelay(false)
                .position("top right")
        );
    }

    function _hideLoadingToast() {
        $mdToast.hide();
    }

    function _showMultipleTemplateIdErrorMessage() {
        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_DELIVERABLE_TYPE_SELECTION_TITLE")
                .content("DIALOG_DELIVERABLE_TYPE_SELECTION_MESSAGE")
                .ok("DIALOG_ALERT_OK")
        );
    }

    function _errorHandling(err) {
        if (
            err.code === "REQUEST_TIMEOUT" ||
            err.code === "PAYLOAD_TOO_LARGE"
        ) {
            return $mdToast.show(
                $mdToast
                    .simple()
                    .content("ERROR_PDF_CREATION_MESSAGE")
                    .hideDelay(3000)
                    .position("top right")
            );
        }
        return $sbErrorPresenter.catch(err);
    }

    function makeProgressReportSetup() {
        return {
            ...vm.oDataFilter,
            filterDescription: vm.filterDescription,
        };
    }
}
