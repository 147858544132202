import angular from "angular";
import htmlTemplate from "./traffic_light_selector.html";

export default angular
    .module("sbApp.common.components.trafficLightSelector", [])
    .component("sbTrafficLightSelector", {
        replace: true,
        templateUrl: htmlTemplate,
        bindings: {
            types: "<",
            onSelect: "&",
            selectedType: "<?",
            selectorMode: "<",
        },
        controller: function (TRAFFIC_LIGHT_MODES) {
            "ngInject";
            var vm = this;

            vm.$onInit = $onInit;
            vm.onClick = onClick;

            function $onInit() {
                // we allow selectedType as string or object
                if (angular.isString(vm.selectedType)) {
                    vm.selectedType = vm.types[vm.selectedType];
                }
                // use a copied version of the given selection to not update outside references
                // -> vm.selection is private
                vm.selection = angular.copy(vm.selectedType);
                vm.isIssueTypeSelector =
                    vm.selectorMode === TRAFFIC_LIGHT_MODES.ISSUE_TYPE;
            }

            function onClick(type) {
                vm.selection = type;
                vm.onSelect({
                    type: type,
                });
            }
        },
    });
