import moment from "moment";

class LeanboardLaneRecord {
    constructor(id, name) {
        this.id = id;
        this.name = name;

        this.code = "";
        this.location = "";
        this.activities = [];
        this.numberOfLateActivities = 0;
        this.maxDateForLateSection = moment();

        this.isReloading = false;
    }

    getActivities() {
        return this.activities;
    }

    getActivitiesInLateSection() {
        const firstDayOfVisibleWeek = this.maxDateForLateSection;
        const today = moment().startOf("day");
        const latestEnd = moment.min(firstDayOfVisibleWeek, today);

        return this.activities.filter((activity) => {
            return (
                activity.endDate &&
                activity.endDate.isBefore(latestEnd) &&
                !activity.state.isCompleted()
            );
        });
    }

    countLateActivities() {
        return (
            this.getActivitiesInLateSection().length +
            this.numberOfLateActivities
        );
    }

    setMaxDateForLateSection(momentDate) {
        this.maxDateForLateSection = moment(momentDate);
    }

    toggleIsReloading() {
        this.isReloading = !this.isReloading;
    }
}

export default LeanboardLaneRecord;
