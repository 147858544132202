import angular from "angular";
import EditCostGroupMembershipCtrl from "./edit_cost_group_membership.controller";
import dialogTemplate from "./edit_cost_group_membership.html";

export default function EditCostGroupMembershipConfig($mdDialogProvider) {
    "ngInject";

    $mdDialogProvider.addPreset("editCostGroupMembership", {
        methods: ["currentManagers", "currentReviewers"],
        options: function () {
            return {
                controller: EditCostGroupMembershipCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
                preserveScope: true,
            };
        },
    });
}
