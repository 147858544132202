import angular from "angular";
import moment from "moment";
import htmlTemplate from "./calendar_days_list.html";
import registerCalendarDayComponent from "./sbCalendarDay/calendar_day.component";

var calendarDaysListModule = angular
    .module("sbApp.ProjectSettingsModule.calendarDaysList", [])
    .component("sbCalendarDaysList", {
        templateUrl: htmlTemplate,
        bindings: {
            calendarDays: "<",
            onAdd: "&",
            onDelete: "&",
            onChange: "&",
            hasDuplicateDates: "<",
        },
        controllerAs: "calendarDaysList",
    });

registerCalendarDayComponent(calendarDaysListModule);

export default calendarDaysListModule;
