/*jshint unused: vars */
import angular from "angular";
import ConstantsModule from "constants/index";
import FeatureFlagsModule from "./common/feature_flags/index";
import AngularBridgeModule from "./angular.bridge.config";
import "./sentry.config";
import "angular-cookies";
import "angular-sanitize";
import "angular-animate";
import "angular-aria";
import "angular-messages";
import "angular-material";
import "angular-moment";
import "angular-ui-router";
import "angular-translate";
import "angular-ui-tree";
import "states/states";
import "common/ui-elements/ui-elements";
import "common/services/services";
import "common/states/sablono/sablono.module";
import "common/filters/all";
import "common/dialog/dialog";
import "common/toast/toast";
import "configs/all";

/**
 * @ngdoc overview
 * @name app
 * @description
 *
 * Main module of the application.
 */
var dependencies = [
    "ngCookies",
    "ngAria",
    "ngMessages",
    "ngSanitize",
    "ngAnimate",
    "ngMaterial",
    "angularMoment",
    "ui.router",
    "pascalprecht.translate",
    //?
    "ui.tree",
    "sbApp.constants",
    ConstantsModule.name,
    FeatureFlagsModule.name,
    AngularBridgeModule.name,
    "ngSentry",
    "sbApp.states",
    "sbApp.directives",
    "sbApp.services",
    "sbApp.sablonoState",
    "sbApp.allFilters",
    "sbApp.dialog",
    "sbApp.toast",
];

export default angular.module("sbApp", dependencies);
