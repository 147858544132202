import _ from "lodash";

export default function TimeLineCtrl(
    timelineService,
    $rootScope,
    $scope,
    $state,
    EVENTS,
    $sbProject
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;
    var _inspections = [];

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.$onInit = $onInit;
    vm.selectedInspection = {};
    vm.odataFilterCondition = undefined;
    vm.hasNoData = false;
    vm.isLoading = true;

    vm.filters = [
        timelineService.createProjectFilter($sbProject.getCurrentProjectId()),
        timelineService.createAuthorFilter(),
        timelineService.createUploadDateFilter(),
    ];

    vm.isOpenInspection = isOpenInspection;
    vm.filterByViewFilters = filterByViewFilters;
    vm.onInspectionsReady = onInspectionsReady;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    var unWatchStateChange = $rootScope.$on(
        "$stateChangeSuccess",
        function onStateChangeSuccess(event, toState, toParams) {
            setSelection(toParams.inspection, _inspections);
        }
    );
    var unWatchProgressChange = $rootScope.$on(
        EVENTS.DELIVERABLE__DATA_CHANGED,
        updateInspectionData
    );
    var unWatchNoteChange = $rootScope.$on(
        EVENTS.NOTE_CHANGED,
        updateInspectionData
    );

    $scope.$on("$destroy", function $onDestroy() {
        unWatchStateChange();
        unWatchProgressChange();
        unWatchNoteChange();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onInit() {
        vm.odataFilterCondition = vm.filters;
    }

    function onInspectionsReady(inspections) {
        vm.isLoading = false;
        _inspections = _inspections.concat(inspections);
        if (_inspections.length > 0) {
            initSelectionModel(_inspections);
        } else {
            vm.hasNoData = true;
        }
    }

    function initSelectionModel(inspections) {
        var selectedInspectionId = $state.params.inspection;
        if (!selectedInspectionId && inspections.length > 0) {
            selectedInspectionId = inspections[0].ID;
            $state.go("sablono.project.timeline.inspection", {
                inspection: selectedInspectionId,
            });
        }
        setSelection(selectedInspectionId, _inspections);
        return inspections;
    }

    function setSelection(inspectionId, inspections) {
        vm.selectedInspection = _.find(inspections, ["ID", inspectionId]) || {};
    }

    function isOpenInspection(id) {
        return vm.selectedInspection && id === vm.selectedInspection.ID;
    }

    function filterByViewFilters() {
        vm.odataFilterCondition = [].concat(vm.filters);
    }

    function updateInspectionData() {
        filterByViewFilters();
    }
}
