export default function accountManagementCtrl(
    $scope,
    $state,
    systemStatus,
    intercomService,
    $sbSystemStatus,
    TRACKED_EVENTS
) {
    "ngInject";

    /////////////////////
    //
    // Scope properties
    //
    /////////////////////

    var vm = this;

    vm.currentStateData = $state.current.data;
    vm.selectedTab = 0;
    vm.isSystemOperational = $sbSystemStatus.isOperational(systemStatus);

    vm.copyrightYear = getCopyrightYear();

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $scope.$on("$stateChangeSuccess", function (event, toState) {
        // We need this to refresh the tab situation
        vm.currentStateData = toState.data;
        vm.selectedTab = toState.data.selectedTab;

        //Expire the session of help desk chat
        if (toState.name === "account.login") {
            if (vm.isSystemOperational === false) {
                intercomService.bootUnknownUser();
                intercomService.track(TRACKED_EVENTS.MAINTENANCE_SHOWN);
                intercomService.hide();
                // Navigate to maintenance state
                $state.go("account.maintenance");
            } else {
                // logout any old intercom user reference.
                intercomService.logout();
            }
        }

        // if target is maintenance but the system is healthy -> go to login
        if (toState.name === "account.maintenance") {
            if (vm.isSystemOperational === true) {
                // Navigate to login state
                $state.go("account.login");
            }
        }
    });

    function getCopyrightYear() {
        return new Date().getFullYear();
    }
}
