import angular from "angular";
import CreateNewCostGroupCtrl from "./create_new_cost_group.controller";
import dialogTemplate from "./create_new_cost_group.html";

export default function CreateNewCostGroupConfig($mdDialogProvider) {
    "ngInject";

    $mdDialogProvider.addPreset("createCostGroup", {
        options: function () {
            return {
                controller: CreateNewCostGroupCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
                preserveScope: true,
            };
        },
    });
}
