import angular from "angular";
import _ from "lodash";
import htmlTemplate from "./wbs_editor.html";
import WBSEditor from "./wbseditor/WBSEditor";
import "common/errors/PresentableError";
import "common/errors/RequestError";
import "../services/wbs/wbs_api.service";
import "common/services/services";

export default angular
    .module("sbApp.wbs", ["sbApp.wbsApiService", "sbApp.services"])
    .directive("sbWbsEditor", function WbsEditorDirective() {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            controllerAs: "wbsEditor",
            bindToController: {
                projectId: "=",
            },
            controller: function WbsEditorCtrl(
                $rootScope,
                $q,
                $element,
                $sbErrorPresenter,
                $translate,
                wbsApiService,
                $mdDialog,
                $filter
            ) {
                var vm = this;

                vm.$onInit = onInit;
                vm.$onDestroy = onDestroy;

                vm.createStructureEditor = createStructureEditor;
                vm.changeStructure = changeStructure;
                vm.reloadWbsEditor = reloadWbsEditor;

                var unWatch = $rootScope.$on(
                    "$translateChangeSuccess",
                    updateTranslation
                );
                var naturalSort = $filter("naturalSort");

                function onInit() {
                    vm.editor = vm.createStructureEditor(
                        $element[0].querySelector("canvas")
                    );
                    updateTranslation(vm.editor); // set once -> i18n can not be changed without reload

                    return reloadWbsEditor(vm.editor);
                }

                function onDestroy() {
                    vm.editor.keyboardhandler.detachHandler();
                    unWatch();
                }

                function updateTranslation(editor) {
                    var i18nKeys = [
                        "WBS_CREATE_CHILD_TOOLTIP",
                        "WBS_CREATE_SIBLING_TOOLTIP",
                        "WBS_DELETE_NODE_TOOLTIP",
                    ];
                    var translations = $translate.instant(i18nKeys);

                    editor.setI18n({
                        CREATE_CHILD_TOOLTIP:
                            translations.WBS_CREATE_CHILD_TOOLTIP,
                        CREATE_SIBLING_TOOLTIP:
                            translations.WBS_CREATE_SIBLING_TOOLTIP,
                        DELETE_NODE_TOOLTIP:
                            translations.WBS_DELETE_NODE_TOOLTIP,
                    });
                    return editor;
                }

                function reloadWbsEditor(editor) {
                    return loadStructure()
                        .then(function (treeModel) {
                            _sortTreeNaturally(treeModel.components);
                            editor.enableEditorMode();
                            editor.setTreeModel(treeModel);
                            return treeModel;
                        })
                        .catch($sbErrorPresenter.catch);
                }

                function _sortTreeNaturally(components) {
                    _sortNaturallyByCode(components);

                    _.forEach(components, function (component) {
                        if (component.components) {
                            _sortTreeNaturally(component.components);
                        }
                    });
                }

                function _sortNaturallyByCode(components) {
                    return naturalSort(components, "CODE");
                }

                function loadStructure() {
                    return wbsApiService.loadTree(vm.projectId);
                }

                function createStructureEditor(canvas) {
                    return new WBSEditor(
                        canvas,
                        $sbErrorPresenter,
                        wbsApiService,
                        false,
                        $mdDialog
                    );
                }

                function changeStructure(editor, idToCodeMap) {
                    var stage = editor.stage,
                        nodeManager = editor.nodeManager;

                    Object.keys(idToCodeMap).forEach(function (id) {
                        nodeManager.changeProperty(id, "CODE", idToCodeMap[id]);
                    });

                    stage.setNeedsDisplay();
                }
            },
            link: function link($scope, $element, $attr, ctrl) {
                // trigger reload of editor after merge
                //
                $scope.$on("reloadEditor", function () {
                    ctrl.reloadWbsEditor(ctrl.editor);
                });

                // listen to changed codes from auto-generation process
                //
                $scope.$on("codeChanged", function ($event, idToCodeMap) {
                    ctrl.changeStructure(ctrl.editor, idToCodeMap);
                });
            },
        };
    });
