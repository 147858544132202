import angular from "angular";
import _ from "lodash";
import TemplateGraph from "./../model/TemplateGraph";
import "common/services/services";
/**
 * give me your POJO object, and I am happy.
 */
export default function TemplateEditorService(
    $q,
    $log,
    $translate,
    $sbColor,
    moment,
    LOCAL_STORAGE_TEMPLATE_DETAILS_PREFIX,
    $window,
    $sbTemplate,
    $sbSuggestionsApi,
    $sbCurrentProject
) {
    "ngInject";
    //********************************************************************
    //
    //              THE PRIVATE PART
    //
    //********************************************************************

    /**
     * A global counter for local IDs - starting a the current timestamp
     *
     * @type {number}
     */
    var sessionStartTimestamp = new Date().getTime();

    /**
     * create a unique locale storage key for a template id
     * @param {string} templateId - a UUID to support storing of multiple templates
     * @returns {string} - the local store key to use
     * @private
     */
    function _getTemplateDetailsLocalStorageKey(templateId) {
        return LOCAL_STORAGE_TEMPLATE_DETAILS_PREFIX + templateId;
    }

    //********************************************************************
    //
    //              THE PUBLIC PART
    //
    //********************************************************************

    /**
     * Request template details from database.
     *
     * @param {string} templateId - a UUID to identify the object to download
     * @return {Promise}
     */
    function getTemplateDetails(templateId) {
        return $sbTemplate
            .getTemplateDetails(templateId)
            .then(_generateActivityColorsForUnsetValues);
    }

    /**
     * POST template details to database. Then creates a new graph object from
     * server response.
     *
     * @param {string} templateId - a UUID to identify the object to download
     * @param {Object} data - a plain data object describing the whole template
     * @return {Promise}
     */
    function saveTemplatesDetails(templateId, data) {
        return $sbTemplate
            .createTemplateRevision(templateId, data)
            .then(createGraphFromRawData);
    }

    /**
     * Get a list of groups that are available for usage in a template
     *
     * @param {string} groupName - name query for a group name or group code
     * @param {string} selfId - The ID will be excluded from the result set
     * @returns {Promise}
     */
    function suggestGroup(groupName, selfId) {
        return $sbSuggestionsApi
            .getCollection(
                "group",
                groupName,
                $sbCurrentProject.pick("id"),
                selfId
            )
            .then((response) =>
                response.suggestions.map((suggestion) => {
                    return {
                        ID: suggestion.id,
                        NAME: suggestion.name,
                        DESC: suggestion.description,
                        CODE: suggestion.code,
                        CREATED_AT: suggestion.created.at,
                        CREATED_BY_USER: suggestion.created.by.id,
                        CREATED_BY_DISPLAY_NAME: suggestion.created.by.name,
                        CREATED_BY_INITIALS: suggestion.created.by.initials,
                        NUMBER_OF_ACTIVITIES: suggestion.number_of_activities,
                    };
                })
            );
    }

    /**
     * Get a list of groups that are available for usage in a template.
     *
     * @param {string} searchTerm - name query for a name or state name
     * @returns {Promise}
     */
    function suggestActivities(searchTerm) {
        return $sbSuggestionsApi
            .getCollection("activity", searchTerm)
            .then((response) =>
                response.suggestions.map((suggestion) => {
                    return {
                        ID: suggestion.id,
                        NAME: suggestion.name,
                        STATE_NAME: suggestion.name,
                        LANGUAGE: suggestion.language,
                    };
                })
            );
    }

    /**
     * Request template details from local store.
     *
     * @param {string} templateId - a UUID to identify the object in the local store
     * @returns {Promise}
     */
    function getTemplateDetailsFromLocalStore(templateId) {
        return $q(function (resolve, reject) {
            var localStorageKey =
                _getTemplateDetailsLocalStorageKey(templateId);
            var data = angular.fromJson(
                $window.localStorage.getItem(localStorageKey)
            );
            if (data) {
                resolve(data);
            } else {
                reject();
            }
        });
    }

    /**
     * Create a new graph object from @see Graph.
     *
     * @param {Object} data            - Holds all information about graph.
     * @param {Array} data.components  - Holds all information about the components.
     * @param {Array} data.edges       - Holds all information about the edges.
     *
     * @return {TemplateGraph}
     */
    function createGraphFromRawData(data) {
        return new TemplateGraph(data.components, data.edges);
    }

    /**
     * Request template details as TemplateGraph from database.
     *
     * @param {string} templateId - a UUID to identify the object to download
     *
     * @returns {Promise<TemplateGraph>}
     */
    function getGraphFromServer(templateId) {
        return getTemplateDetails(templateId).then(createGraphFromRawData);
    }

    function _generateActivityColorsForUnsetValues(data) {
        data.components.forEach(function (component) {
            if (
                component.category === "ACTIVITY" &&
                !angular.isString(component.color)
            ) {
                component.color = $sbColor.color.random();
            }
        });
        return data;
    }

    /**
     * Request template details as TemplateGraph from local store.
     *
     * @param {string} templateId - a UUID to identify the object to load
     *
     * @returns {Promise<TemplateGraph>}
     */
    function getGraphFromLocalStoreOrServer(templateId) {
        return getTemplateDetailsFromLocalStore(templateId)
            .catch(() => getTemplateDetails(templateId))
            .then(createGraphFromRawData);
    }

    /**
     * Save the given data object in the local store.
     *
     * @param {string} templateId   - used to identify the data in the store.
     * @param {Object} data         - the plain object to store
     *
     * @returns {Promise}
     */
    function saveTemplateDetailsToLocalStore(templateId, data) {
        return $q(function (resolve) {
            var localStorageKey =
                _getTemplateDetailsLocalStorageKey(templateId);
            var stringifiedData = angular.toJson(data);
            $window.localStorage.setItem(localStorageKey, stringifiedData);
            resolve();
        });
    }

    /**
     * Drop the stored template from the local storage.
     *
     * @param {string} templateId   - used to identify the data in the store.
     * @returns {Promise}
     */
    function removeTemplateDetailsFromLocalStore(templateId) {
        return $q(function (resolve) {
            var localStorageKey =
                _getTemplateDetailsLocalStorageKey(templateId);
            $window.localStorage.removeItem(localStorageKey);
            resolve(templateId);
        });
    }

    /**
     * Build a hierarchy from a given component.
     * This is used to build the path from the given component up
     * to its root parent.
     *
     * @param {Component} component - Starting point to get the hierarchy path for.
     *
     * @returns {Array.<{id: (string|number), name: string}>}
     */
    function getComponentHierarchy(component) {
        var hierarchy = [];
        var emergencyCounter = 0;

        while (component && emergencyCounter < 100) {
            hierarchy.push({
                id: component.getData().id,
                name: component.getData().name,
            });
            component = component.getParent();
            emergencyCounter++;
        }

        if (emergencyCounter >= 100) {
            $log.error(
                "Emergency Exit on templateEditorService.getComponentHierarchy"
            );
        }

        return hierarchy.reverse();
    }

    /**
     * Replace a dummy element of a graph by a proper template fetched from the server.
     *
     * @param {Object} appendedTreeRoot - an odata resource describing the append tree
     * @param {Component} parent        - the component where you want to attach the append tree
     * @param {Graph} graph             - the main graph to append the tree to
     * @returns {Promise}
     */
    function appendTree(appendedTreeRoot, parent, graph) {
        return getTemplateDetails(appendedTreeRoot.ID)
            .then(function swapIds(appendedTreeData) {
                // iterate through the tree and set the id as template id and the actual id as the current timestamp
                //
                var swapHash = {};
                appendedTreeData.components.forEach(function (child) {
                    child.template = child.id;
                    child.id = getUniqueKey();
                    swapHash[child.template] = child.id;
                });

                // use newly assigned ids for edges and parent relations
                appendedTreeData.edges.forEach(function (edge) {
                    edge.source = swapHash[edge.source];
                    edge.target = swapHash[edge.target];
                });

                appendedTreeData.components.forEach(function (child) {
                    child.parentId = swapHash[child.parentId];
                });

                return appendedTreeData;
            })
            .then(function addTree(appendedTreeData) {
                if (appendedTreeData.components) {
                    var actualTree = appendedTreeData.components[0];
                    var subGraph = new TemplateGraph(
                        appendedTreeData.components,
                        appendedTreeData.edges
                    );
                    graph.merge(subGraph, parent.id);

                    return actualTree;
                }
            });
    }

    /**
     * Method to traverse through child components and filter out activities only
     *
     * @param {string} parentNodeId - Id of parent node for the current object
     * @param {object} graph - Graph object used to get child components against given parent node
     * @param {array} childActivites - An array to retain list of child activities
     */
    function _getAllChildActivities(parentNodeId, graph, childActivities) {
        if (!childActivities) {
            childActivities = [];
        }
        graph.getChildComponentsData(parentNodeId).forEach(function (v) {
            if (v.category === "GROUP") {
                return _getAllChildActivities(v.id, graph, childActivities);
            } else {
                childActivities.push(v);
            }
        });
        return childActivities;
    }

    /**
     *  Calculate working hours from the calendar array
     *  @param [array] blocks - Collection of start and end times
     */
    function getWorkingHours(blocks) {
        var totalWorkingHours = 0;
        blocks.forEach(function (v) {
            //Compare the times
            var start = moment("2016-10-10 " + v[0]);
            var end = moment("2016-10-10 " + v[1]);
            totalWorkingHours =
                totalWorkingHours + moment.duration(end.diff(start)).asHours();
        });
        if (totalWorkingHours > 0) {
            return totalWorkingHours;
        } else {
            //Return default working hours in case our calculation fails in any way
            return 8;
        }
    }

    /**
     * Method to convert units to proper values
     *
     * @param {Object} activity - Activity object containing all the available attributes for acitivity.
     *
     */
    function _changeDurationValueByUnit(activity, calendar) {
        if (activity.unit === "d") {
            return 0;
        } else if (activity.unit === "h") {
            return 0;
        } else if (activity.unit === "wd") {
            //Get working hours
            var workingHours = 8;
            //Check if our calendar is present
            if (calendar && calendar.BLOCKS && calendar.BLOCKS.length > 0) {
                workingHours = getWorkingHours(calendar.BLOCKS);
            }
            //convert the result to hours
            return parseFloat(activity.duration) * parseFloat(workingHours);
        } else if (activity.unit === "wh") {
            if (activity.duration) {
                return parseFloat(activity.duration);
            } else {
                return 0;
            }
        } else {
            return parseFloat(activity.duration);
        }
    }

    /**
     * Sum of durations the activities within the currently selected group
     *
     * @param {array} allChildActivities - Array of activities only
     */
    function _childActivitiesSum(allChildActivities, calendar) {
        var totalDuration = 0;
        allChildActivities.forEach(function (activity) {
            var duration = _changeDurationValueByUnit(activity, calendar);
            if (duration) {
                totalDuration = totalDuration + duration;
            } else {
                totalDuration = totalDuration + 0;
            }
        });
        return Math.round(totalDuration * 10) / 10;
    }

    function _collectAssignedTeamIdsOf(allChildActivities) {
        return _.chain(allChildActivities).map("assignedTeamId").uniq().value();
    }

    function updateAssignedTeamInActivitiesBy(groupId, assignedTeamId, graph) {
        var childActivities = _getAllChildActivities(groupId, graph);
        return childActivities.map(function (activity) {
            activity.assignedTeamId = assignedTeamId;
            return activity;
        });
    }

    /**
     * Transform a plain object to an odata conform object (used in the edit cards)
     *
     * @param {Object} basicElement - plain object with (id, name, ...)
     * @param {object} graph - Graph object used to get child components against given parent node
     * @returns {Object}
     */
    function toGroupCardContent(basicElement, graph) {
        //Fetch the child activities
        var childActivities = _getAllChildActivities(basicElement.id, graph);

        //Return the card
        graph.calendar.TIMEZONE = null;
        return {
            ID: basicElement.id,
            NAME: basicElement.name,
            CODE: basicElement.code,
            DESC: basicElement.desc,
            CATEGORY: basicElement.category,
            WORK_HOURS: _childActivitiesSum(childActivities, graph.calendar),
            ASSIGNED_TEAM_IDS: _collectAssignedTeamIdsOf(childActivities),
            calendar: graph.calendar,
        };
    }

    /**
     * Transform a plain object to an odata conform object for activity edit card
     *
     * @param {Object} basicElement - plain object with (id, name, ...)
     * @returns {Object}
     */
    function toActivityCardContent(basicElement) {
        return {
            ID: basicElement.id,
            ACTIVITY_NAME: basicElement.name,
            STATE_NAME: basicElement.statename,
            DESC: basicElement.desc,
            CATEGORY: basicElement.category,
            DURATION: basicElement.duration,
            UNIT: basicElement.unit ? basicElement.unit : "wd",
            ASSIGNED_TEAM_ID: basicElement.assignedTeamId,
            REVIEW_TEAM_IDS: basicElement.reviewTeamIds || [],
            CONFIRM_TEAM_ID: basicElement.confirmationTeamId,
            COLOR: basicElement.color,
            PLANNED_LABOUR_ALLOCATION: basicElement.labour,
            ASSIGNED_CHECKLIST_ID: basicElement.checklistId,
        };
    }

    /**
     * Write the values of an odata conform object to the basic element back (used in the edit cards)
     *
     * @param {Object} cardElement - odata conform object
     * @param {Object} basicElement - plain object with (id, name, ...)
     */
    function updateGroupFromCardContent(cardElement, basicElement) {
        basicElement.id = cardElement.ID;
        basicElement.name = cardElement.NAME;
        basicElement.desc = cardElement.DESC;
        basicElement.code = cardElement.CODE;
        basicElement.category = cardElement.CATEGORY;
    }

    /**
     * Write activity update values back to odata from edit card
     *
     * @param {Object} cardElement - odata conform object
     * @param {Object} basicElement - plain object with (id, name, ...)
     */
    function updateActivityFromCardContent(cardElement, basicElement) {
        basicElement.id = cardElement.ID;
        basicElement.name = cardElement.ACTIVITY_NAME;
        basicElement.statename = cardElement.STATE_NAME;
        basicElement.desc = cardElement.DESC;
        basicElement.category = cardElement.CATEGORY;
        basicElement.duration = cardElement.DURATION;
        basicElement.unit = cardElement.UNIT;
        basicElement.assignedTeamId = cardElement.ASSIGNED_TEAM_ID;
        basicElement.confirmationTeamId = cardElement.CONFIRM_TEAM_ID;
        basicElement.reviewTeamIds = cardElement.REVIEW_TEAM_IDS;
        basicElement.checklistId = cardElement.ASSIGNED_CHECKLIST_ID;
        basicElement.color = cardElement.COLOR;
        basicElement.labour = cardElement.PLANNED_LABOUR_ALLOCATION;
    }

    /**
     * Get a new unique number from the global counter
     *
     * @returns {number}
     */
    function getUniqueKey() {
        return sessionStartTimestamp++;
    }

    /**
     * Create an empty group object to use it in the view
     *
     * @param {boolean} justEdited - set the justEdited flag
     * @param {number} inLayer - the layer to place the new element in
     *
     * @returns {{id: number, layer: *, name: Number, category: string, edit: boolean, closedChildren: boolean, justEdited: *, components: Array}}
     */
    function createEmptyGroup(justEdited, inLayer) {
        return {
            id: getUniqueKey(),
            layer: inLayer,
            name: Infinity,
            category: "GROUP",
            edit: true,
            closedChildren: false,
            justEdited: justEdited,
            components: [],
        };
    }

    /**
     * Returns an empty activity object with the edit mode enabled to show the autocompletes
     *
     * @param {boolean} justEdited - set the justEdited flag
     * @param {number} inLayer - the layer to place the new element in
     *
     * @returns {{id: number, layer: *, name: Number, statename: string, category: string, stateedit: boolean, edit: boolean, closedChildren: boolean, justEdited: *}}
     */
    function createEmptyActivity(justEdited, inLayer) {
        return {
            id: getUniqueKey(),
            layer: inLayer,
            name: Infinity,
            statename: "",
            category: "ACTIVITY",
            unit: "wd",
            stateedit: false,
            edit: true,
            closedChildren: true,
            justEdited: justEdited,
            assignedTeamId: null,
            color: null,
            labour: null,
        };
    }

    function createSimpleGroup(oDataObject, justEdited, inLayer) {
        var group = createEmptyGroup(justEdited, inLayer);
        group.name = oDataObject.NAME;
        return group;
    }

    function createSimpleActivity(oDataObject, justEdited, inLayer) {
        var activity = createEmptyActivity(justEdited, inLayer);
        activity.name = oDataObject.NAME;
        activity.statename = oDataObject.STATE_NAME;
        activity.template = oDataObject.ID;
        activity.color = $sbColor.color.random();
        return activity;
    }

    return {
        appendTree: appendTree,
        getUniqueKey: getUniqueKey,
        getWorkingHours: getWorkingHours,
        createEmptyGroup: createEmptyGroup,
        createEmptyActivity: createEmptyActivity,
        createSimpleActivity: createSimpleActivity,
        createSimpleGroup: createSimpleGroup,
        suggestGroup: suggestGroup,
        suggestActivities: suggestActivities,
        getComponentHierarchy: getComponentHierarchy,
        saveTemplatesDetails: saveTemplatesDetails,
        saveTemplateDetailsToLocalStore: saveTemplateDetailsToLocalStore,
        getTemplateDetails: getTemplateDetails,
        getGraphFromLocalStoreOrServer: getGraphFromLocalStoreOrServer,
        getTemplateDetailsFromLocalStore: getTemplateDetailsFromLocalStore,
        getGraphFromServer: getGraphFromServer,
        removeTemplateDetailsFromLocalStore:
            removeTemplateDetailsFromLocalStore,
        toGroupCardContent: toGroupCardContent,
        toActivityCardContent: toActivityCardContent,
        updateGroupFromCardContent: updateGroupFromCardContent,
        updateActivityFromCardContent: updateActivityFromCardContent,
        updateAssignedTeamInActivitiesBy: updateAssignedTeamInActivitiesBy,
    };
}
