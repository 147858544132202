import angular from "angular";
import html from "./print_qrcodes.html";
import controller from "./print_qrcodes.controller";

export default angular
    .module("sbApp.sbPrintQRCodesDialog", [])
    .config(registerDialog)
    .factory("$sbPrintQRCodesDialog", registerService());

/////////////////////
//
//      IMPL
//
/////////////////////

function registerService() {
    return function sbPrintReportDialogService($mdDialog) {
        "ngInject";

        return {
            show: _show,
        };

        function _show(oDataFilterExpr, numberOfDeliverables) {
            return $mdDialog.show(
                $mdDialog
                    .printQRCodes()
                    .oDataFilterExpr(oDataFilterExpr)
                    .numberOfDeliverables(numberOfDeliverables)
            );
        }
    };
}

function registerDialog($mdDialogProvider) {
    $mdDialogProvider.addPreset("printQRCodes", {
        methods: ["oDataFilterExpr", "numberOfDeliverables"],
        options: function printQRCodes() {
            return {
                templateUrl: html,
                controllerAs: "dialog",
                bindToController: true,
                controller: controller,
                clickOutsideToClose: true,
                escapeToClose: true,
            };
        },
    });
}
