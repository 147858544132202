import PresentableError from "./PresentableError";

/**
 * Error if a request entity is larger than limits defined by server.
 *
 * @constructor
 * @extends PresentableError
 *
 */
function PayloadTooLargeError() {
    this.code = "PAYLOAD_TOO_LARGE";
    this.message = "ERROR_REQUEST_SIZE_EXCEEDED_MESSAGE";
}

PayloadTooLargeError.prototype = Object.create(PresentableError.prototype); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ PayloadTooLargeError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.TOAST;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ PayloadTooLargeError.prototype.shouldAutoClose =
    function () {
        return true;
    };

export default PayloadTooLargeError;
