import html from "./sb_timeline.component.html";
import SbTimelineCtrl from "./sb_timeline.controller";
import angular from "angular";
import "angular-ui-router";
import "common/intercom/intercomModule";
import "common/services/services";

export default angular
    .module("sbApp.common.components.timeline", ["ngMaterial"])
    .component("sbTimeline", {
        templateUrl: html,
        bindings: {
            isLoading: "<",
            inspections: "<",
            totalAmountOfInspections: "<",
            showMoreIsVisible: "<",
        },
        controller: SbTimelineCtrl,
        controllerAs: "$ctrl",
    });
