import angular from "angular";
import { find, isEqual } from "lodash";
import pendingInvitationHtml from "./modify_pending_invitaiton.tmpl.html";

export default angular
    .module("sbApp.sbModifyInvitationDialog", ["sbApp.services"])
    .config(function ($mdDialogProvider) {
        "ngInject";

        $mdDialogProvider.addPreset("modifyPendingInvitation", {
            methods: [
                "class",
                "title",
                "titleValue",
                "titleIcon",
                "cancel",
                "clickOutsideToClose",
                "escapeToClose",
                // data input
                "invitation",
                "teams", // the available teams
                "roles", // the available roles
            ],
            options: function selectDialog($mdDialog) {
                "ngInject";
                return {
                    templateUrl: pendingInvitationHtml,
                    controller: ModifyPendingInvitationDialogCtrl,
                    controllerAs: "$ctrl",
                    bindToController: true,
                };

                function ModifyPendingInvitationDialogCtrl() {
                    "ngInject";
                    /////////////////////
                    //
                    //      Direct variables
                    //
                    /////////////////////

                    /////////////////////
                    //
                    //      SCOPE properties
                    //
                    /////////////////////

                    var vm = this;

                    vm.dismiss = cancelDialog;
                    vm.resolve = resolveDialog;
                    vm.isModified = isModified;
                    vm.onSelectTeam = onSelectTeam;
                    vm.onSelectRole = onSelectRole;

                    /////////////////////
                    //
                    //      WATCHER
                    //
                    /////////////////////

                    /////////////////////
                    //
                    //      IMPL
                    //
                    /////////////////////

                    _activate();

                    function _activate() {
                        if (!vm.invitation) {
                            cancelDialog();
                        }

                        vm.email = vm.invitation.contact.email;
                        vm.selectedRole = _findRoleByName(
                            vm.invitation.membership.role.name
                        );
                        vm.selectedTeam = find(vm.teams, {
                            id: vm.invitation.membership.team.id,
                        });
                    }

                    function cancelDialog() {
                        $mdDialog.cancel();
                    }

                    function resolveDialog() {
                        $mdDialog.hide({
                            before: _asBeforeModel(vm.invitation),
                            after: _asAfterModel(
                                vm.email,
                                vm.selectedTeam,
                                vm.selectedRole
                            ),
                        });
                    }

                    function onSelectTeam(team) {
                        vm.selectedTeam = team;
                    }

                    function onSelectRole(role) {
                        vm.selectedRole = role;
                    }

                    function isModified() {
                        return !isEqual(
                            _asBeforeModel(vm.invitation),
                            _asAfterModel(
                                vm.email,
                                vm.selectedTeam,
                                vm.selectedRole
                            )
                        );
                    }

                    /**
                     * Determine from the input how the "before" model will look like
                     * The before model is representing the state of the model before
                     * editing/creating/deleting. (e.g. if you create: the before state
                     * is an empty object)
                     *
                     * @param {Object|undefined} invitation
                     * @returns {*}
                     * @private
                     */
                    function _asBeforeModel(invitation) {
                        return {
                            contact: {
                                email: invitation.contact.email,
                            },
                            membership: {
                                role: _findRoleByName(
                                    invitation.membership.role.name
                                ),
                                team: find(vm.teams, {
                                    id: invitation.membership.team.id,
                                }),
                            },
                        };
                    }

                    /**
                     * Determine from the input how the "after" model will look like
                     * The after model is representing the state of the model after
                     * editing/creating/deleting. (e.g. if you delete: the after state
                     * is an empty object)
                     *
                     * @param {string} email
                     * @param {Object} team
                     * @param {string} role
                     * @returns {*}
                     * @private
                     */
                    function _asAfterModel(email, team, role) {
                        return {
                            contact: {
                                email,
                            },
                            membership: {
                                role: role,
                                team: team,
                            },
                        };
                    }

                    function _findRoleByName(name) {
                        return find(vm.roles, {
                            ROLE_NAME: name.toUpperCase(),
                        });
                    }
                }
            },
        });
    });
