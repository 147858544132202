import angular from "angular";
import htmlTemplate from "./sb_deliverable_notes.html";
import directiveCtrl from "./sb_deliverable_notes.controller";
import "common/dialog/issueEditor/issue_editor.dialog";

export default angular
    .module("sbApp.sbDeliverableNotes", [
        "sbApp.directives",
        "sbApp.sbIssueEditorDialog",
        "sbApp.dialog",
    ])
    .directive("sbDeliverableNotes", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "notes",
            templateUrl: htmlTemplate,
            scope: {
                deliverable: "<",
                notes: "<",
                isLoading: "<",
                onCreateNote: "&",
                onPrint: "&",
            },
            controller: directiveCtrl,
        };
    });
