import angular from "angular";
import htmlTemplate from "./sb_dependency_manager.html";

export default angular
    .module("sbApp.sbDependencyManager", [])
    .directive("sbDependencyManager", function () {
        return {
            restrict: "E",
            replace: true,
            bindToController: true,
            controllerAs: "dependencyManager",
            templateUrl: htmlTemplate,
            //transclude: true,
            scope: {
                type: "=",
                isOpen: "=",
                // add type
                add: "=",
                // is of type
                is: "=",
            },
            controller: function DependencyManagerCtrl(
                $stateParams,
                $q,
                $sbODataViewFilter,
                $sbDeliverablesFilter
            ) {
                "ngInject";

                /////////////////////
                //
                //      Direct variables
                //
                /////////////////////

                var configTexts = {
                    PREDECESSOR: {
                        title: "SELECT_PREDECESSOR_DEPENDENCY_TITLE",
                        addButtonText: "ACTION_ADD_PREDECESSOR",
                        searchPlaceholder:
                            "SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_PRED",
                        label: "_PREDECESSOR",
                        icon: "mdi mdi-arrange-bring-forward",
                    },
                    SUCCESSOR: {
                        title: "SELECT_SUCCESSOR_DEPENDENCY_TITLE",
                        addButtonText: "ACTION_ADD_SUCCESSOR",
                        searchPlaceholder:
                            "SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_SUCC",
                        label: "_SUCCESSOR",
                        icon: "mdi mdi-arrange-send-backward",
                    },
                };
                /////////////////////
                //
                //      SCOPE properties
                //
                /////////////////////

                var vm = this;

                vm.PREDECESSOR = "PREDECESSOR";
                vm.SUCCESSOR = "SUCCESSOR";
                vm.search = {
                    text: "",
                };

                vm.onSearch = onSearch;
                vm.onClose = onClose;
                vm.hasDependencies = hasDependencies;
                vm.activate = activate;

                /////////////////////
                //
                //      WATCHER
                //
                /////////////////////

                /////////////////////
                //
                //      IMPL
                //
                /////////////////////

                function activate() {
                    _updateTexts(configTexts, vm.type);

                    return _resolveFilters()
                        .then(function updateFilters(filters) {
                            return (vm.searchFilters = filters);
                        })
                        .then(function updateFactory(filters) {
                            return (vm.searchFactory =
                                _updateSearchFactory(filters));
                        });
                }

                function onClose() {
                    vm.search.text = "";
                    vm.isOpen = false;
                }

                function hasDependencies(deliverable) {
                    return (
                        vm.is(vm.PREDECESSOR, deliverable) ||
                        vm.is(vm.SUCCESSOR, deliverable)
                    );
                }

                function onSearch(text) {
                    vm.searchFilters = _updateSearchFiltersWith(
                        text,
                        vm.searchFilters
                    );
                    vm.searchFactory = _updateSearchFactory(vm.searchFilters);
                }

                function _updateTexts(textsByType, type) {
                    return (vm.texts = textsByType[type]);
                }

                function _updateSearchFactory(searchFilters) {
                    const logicalOperators = ["AND", "OR", "OR"];
                    const oDataFilterFactory =
                        $sbODataViewFilter.combinedODataWithOperatorsFrom(
                            searchFilters,
                            logicalOperators
                        );

                    const isSearchActive = vm.search && vm.search.text !== "";
                    if (isSearchActive) {
                        oDataFilterFactory.mergeIntoBlock(2, 6);
                    }

                    return oDataFilterFactory;
                }

                function _updateSearchFiltersWith(searchText, searchFilters) {
                    searchFilters.forEach(function (filter) {
                        const isEmptyString = searchText === "";
                        if (isEmptyString) {
                            filter.clear();
                        } else {
                            filter.setValue(searchText);
                        }
                    });
                    return searchFilters;
                }

                function _resolveFilters() {
                    return $q.all([
                        $sbDeliverablesFilter.nameFilter(),
                        $sbDeliverablesFilter.codeFilter(),
                        $sbDeliverablesFilter.descriptionFilter(),
                    ]);
                }
            },
            link: function ($scope, $element, $attr, $ctrl) {
                $scope.$watch(
                    "dependencyManager.type",
                    function (newType, oldType) {
                        if (newType !== oldType) {
                            if (angular.isDefined(newType)) {
                                $ctrl.activate();
                            }
                        }
                    }
                );
            },
        };
    });
