import LeanboardActivity from "../model/lean_board_activity.class";
import LeanboardLaneRecord from "../model/lean_board_lane_record.class";
import _ from "lodash";
import SbTeam from "../../../domain/sb_team.class";
import ActivityState from "../../../domain/sb_activity_state.class";
import LeanboardSchedule from "../model/lean_board_schedule.class";
import moment from "moment";

class LeanBoardModelMapper {
    static createFromApiResponse(
        apiRecord,
        calendar,
        { isIgnoringCode = false, isIgnoringActualDates = false } = {}
    ) {
        const record = new LeanboardLaneRecord(apiRecord.id, apiRecord.name);
        record.location = apiRecord.location;

        if (!isIgnoringCode) {
            record.code = apiRecord.code;
        }

        record.numberOfLateActivities = apiRecord.number_of_late_activities;
        record.activities = apiRecord.activities.map((apiActivity) =>
            LeanBoardModelMapper.createActivityFromApiResponse(
                apiActivity,
                record,
                calendar,
                isIgnoringActualDates
            )
        );

        return record;
    }

    static createActivityFromApiResponse(
        apiActivity,
        deliverable,
        calendar,
        isIgnoringActualDates
    ) {
        const activity = new LeanboardActivity(
            deliverable,
            apiActivity.id,
            apiActivity.name,
            apiActivity.topological_index
        );
        activity.templateId = apiActivity.activity_template_id;
        activity.calendar = calendar;

        activity.noteStatistic = {
            openObstructions: apiActivity.number_of_obstruction_notes,
            openClaims: apiActivity.number_of_quality_notes,
            info: apiActivity.number_of_info_notes,
        };

        // create work team reference
        const workTeam = _.get(apiActivity, "team_assignments.work[0]");
        if (workTeam) {
            activity.workTeam = new SbTeam(workTeam);
        }

        activity.state = LeanBoardModelMapper.apiStateToDomain(
            apiActivity.state
        );

        const forecastSchedule = apiActivity.look_ahead_schedule.forecast;
        const baselineSchedule = apiActivity.baseline_schedule;

        activity.isForecasted = _.has(forecastSchedule, "start_date");
        activity.numberOfDeliverables = apiActivity.number_of_deliverables;
        activity.numberOfForecastedActivities =
            apiActivity.number_of_forecasted_activities;
        activity.plannedLabour = apiActivity.planned_labour;

        // add the fancy "I know everything schedule" which is used by the activity panel only!
        activity.schedule =
            LeanBoardModelMapper.createScheduleFromApiResponse(apiActivity);

        const { startDate, endDate, duration, hasPrediction, actualStart } =
            LeanBoardModelMapper.apiResponseToDisplayedDates(
                apiActivity,
                calendar,
                activity.state,
                isIgnoringActualDates || activity.isForecasted
            );
        activity.startDate = startDate;
        activity.actualStart = actualStart;
        activity.endDate = endDate;
        activity.duration = duration;
        activity.hasPrediction = hasPrediction;

        activity.daysBehindBaseline =
            LeanBoardModelMapper.calculateDaysBehindBaseline(
                calendar,
                LeanBoardModelMapper.firstValidIn([
                    baselineSchedule.earliest_end,
                    baselineSchedule.latest_end,
                ]),
                activity.endDate
            );

        return activity;
    }

    static createScheduleFromApiResponse(apiActivity) {
        const schedule = new LeanboardSchedule();

        const leadingSchedule = apiActivity.look_ahead_schedule.session;
        const lookAheadSchedule = apiActivity.look_ahead_schedule;
        const forecastSchedule = apiActivity.look_ahead_schedule.forecast || {};
        const actualSchedule = apiActivity.actual_schedule || {};
        const baselineSchedule = apiActivity.baseline_schedule || {};

        // baseline
        //
        schedule.baseline = {
            start: LeanBoardModelMapper.firstValidIn([
                baselineSchedule.earliest_start,
                baselineSchedule.latest_start,
            ]),
            end: LeanBoardModelMapper.firstValidIn([
                baselineSchedule.earliest_end,
                baselineSchedule.latest_end,
            ]),
            duration: _.get(baselineSchedule, "duration.value", null),
            durationUnit: _.get(baselineSchedule, "duration.unit", "wd"),
        };

        // forecasted dates
        //
        schedule.forecast = {
            start: LeanBoardModelMapper.firstValidIn([
                forecastSchedule.start_date,
            ]),
            end: LeanBoardModelMapper.firstValidIn([forecastSchedule.end_date]),
        };

        // actual
        //
        schedule.actual = {
            started: LeanBoardModelMapper.firstValidIn([
                actualSchedule.started,
            ]),
            finished: LeanBoardModelMapper.firstValidIn([
                actualSchedule.finished,
            ]),
            confirmed: LeanBoardModelMapper.firstValidIn([
                actualSchedule.confirmed,
            ]),
        };

        // published look ahead dates (given as they are through API)
        //
        schedule.publishedLookAhead = {
            start: LeanBoardModelMapper.firstValidIn([
                lookAheadSchedule.start_date,
            ]),
            end: LeanBoardModelMapper.firstValidIn([
                lookAheadSchedule.end_date,
            ]),
            duration: _.get(lookAheadSchedule, "duration.value", null),
        };

        // -- sessionLookAhead --
        // those are the tricky ones.. because the backend api is mixing
        //  - baseline, published lookahead, session look ahead and forecast
        //  - into this number which makes this date the "displayed board date"
        //  - we have to untangle those here to make the panel work
        //
        const isBaselineDifferentToSession =
            LeanBoardModelMapper.isDifferent(
                schedule.baseline.start,
                leadingSchedule.start_date
            ) ||
            LeanBoardModelMapper.isDifferent(
                schedule.baseline.end,
                leadingSchedule.end_date
            );

        const isForecastAdded = !!forecastSchedule.start_date; // they always come as pair

        if (isForecastAdded) {
            // there can be no session change -> the published look ahead is what we are looking for..
            schedule.sessionLookAhead = {
                start: schedule.publishedLookAhead.start,
                end: schedule.publishedLookAhead.end,
                duration: schedule.publishedLookAhead.duration,
            };
        } else if (isBaselineDifferentToSession) {
            // -> session look ahead is as defined by leading schedule
            schedule.sessionLookAhead = {
                start: LeanBoardModelMapper.firstValidIn([
                    leadingSchedule.start_date,
                ]),
                end: LeanBoardModelMapper.firstValidIn([
                    leadingSchedule.end_date,
                ]),
                duration: _.get(leadingSchedule, "duration.value", null),
            };
        } else {
            // look ahead and baseline are equal -> session look ahead is undefined
            schedule.sessionLookAhead = {
                start: null,
                end: null,
                duration: null,
            };
        }

        return schedule;
    }

    static apiStateToDomain(apiState) {
        switch (apiState) {
            case "started": {
                return new ActivityState(ActivityState.STARTED);
            }
            case "waiting_for_confirmation": {
                return new ActivityState(
                    ActivityState.WAITING_FOR_CONFIRMATION
                );
            }
            case "confirmed": {
                return new ActivityState(ActivityState.CONFIRMED);
            }
            case "done": {
                return new ActivityState(ActivityState.DONE);
            }
            case "rejected": {
                return new ActivityState(ActivityState.REJECTED);
            }
            default:
                return new ActivityState(ActivityState.NOT_STARTED);
        }
    }

    static apiResponseToDisplayedDates(
        apiActivity,
        calendar,
        state,
        isIgnoringActualDates
    ) {
        const displayedDates = {
            hasPrediction: false,
            duration: {
                unit: "wd",
            },
        };

        // decision making is purely based on the "leading" schedule and the actual dates (+state)
        const leadingSchedule = apiActivity.look_ahead_schedule.session;
        const actualSchedule = apiActivity.actual_schedule || {};

        displayedDates.duration.value = _.get(
            leadingSchedule,
            "duration.value",
            0
        );

        if (state.is(ActivityState.NOT_STARTED) || isIgnoringActualDates) {
            displayedDates.startDate = LeanBoardModelMapper.firstValidIn([
                leadingSchedule.start_date,
            ]);
        } else {
            const startDates = [
                actualSchedule.started,
                // This one is a bit counter intuitive.
                // It is in case the user went from
                // not_started -> finished
                actualSchedule.finished,
                leadingSchedule.start_date,
            ];
            displayedDates.startDate =
                LeanBoardModelMapper.firstValidIn(startDates);
        }

        const endDates = [leadingSchedule.end_date];

        if (state.isCompleted() && !isIgnoringActualDates) {
            endDates.unshift(actualSchedule.finished);
            endDates.unshift(actualSchedule.confirmed);
        }

        if (
            state.isWorkInProgress() &&
            !_.isNil(displayedDates.startDate) &&
            !isIgnoringActualDates
        ) {
            // end dates are based on actual start + planned duration
            // leanboardActivity predicted end is basically a single activity forecast - the backend
            // forecasting logic is using the exact same approach to forecast started activities.
            //

            const startForPrediction =
                calendar.workingCalendar.updateTimeToMatchStartOfDay(
                    displayedDates.startDate.clone()
                );

            const predictEnd = calendar.findEarliestEndForTask(
                startForPrediction,
                displayedDates.duration.value || 1,
                displayedDates.duration.unit
            );

            endDates.unshift(predictEnd.toISOString());
            displayedDates.hasPrediction = true;
        }

        displayedDates.endDate = LeanBoardModelMapper.firstValidIn(endDates);
        displayedDates.actualStart = moment(actualSchedule.started);

        return displayedDates;
    }

    static firstValidIn(apiDates) {
        return LeanBoardModelMapper.firstValidWithIndexIn(apiDates).value;
    }

    static firstValidWithIndexIn(apiDates) {
        const result = apiDates.reduce(
            function (currentDate, nextDate, index) {
                if (currentDate.value) {
                    return currentDate;
                }

                // try string in UTC
                let date = moment(nextDate, moment.ISO_8601, true);
                if (date.isValid()) {
                    currentDate.value = date;
                    currentDate.index = index;
                }

                return currentDate;
            },
            { value: null, index: -1 }
        );

        return result;
    }

    /**
     * This function will calculate how many (calendar) days behind is SRP due date behind baseline due date.
     * Returns 0 if there is no last planned date
     *
     * return {Integer}
     */
    static calculateDaysBehindBaseline(calendar, baselineEnd, displayedEnd) {
        // If somehow the user doesn't have a baseline or SRP, we cannot calculate a diff
        if (!baselineEnd || !baselineEnd.isValid()) {
            return "-";
        }

        if (!displayedEnd) {
            return 0;
        } else {
            let totalWorkingHours = calendar.getTotalWorkingTimeBetween(
                baselineEnd,
                displayedEnd
            );

            // getTotalWorkingTimeBetween only returns a valid number when startDate is earlier than endDate
            if (!totalWorkingHours) {
                totalWorkingHours =
                    -1 *
                    calendar.getTotalWorkingTimeBetween(
                        displayedEnd,
                        baselineEnd
                    );
            }

            return calendar.mapDurationToNextLargerUnit(
                totalWorkingHours,
                "wh"
            );
        }
    }

    static isDifferent(v1, v2) {
        if (_.isNil(v1) && _.isNil(v2)) {
            return false;
        }

        if (!_.isNil(v1) && _.isNil(v2)) {
            return true;
        }

        if (_.isNil(v1) && !_.isNil(v2)) {
            return true;
        }

        if (moment.isMoment(v1)) {
            return !v1.isSame(v2);
        } else {
            return v1 !== v2;
        }
    }
}

export default LeanBoardModelMapper;
