import has from "has";

export default function (
    $scope,
    $mdSidenav,
    $state,
    $sbAuth,
    session,
    $interval,
    LEFT_SIDE_NAV_ID,
    SABLONO_STATES,
    $rootScope,
    $timeout,
    $sbDetailsOverlay,
    $mdDialog,
    $sbProject,
    EVENTS,
    $sbFeatureDecisions,
    $sbEulaService
) {
    "ngInject";

    var vm = this;

    $scope.LEFT_SIDE_NAV_ID = LEFT_SIDE_NAV_ID;
    $scope.user = session;
    vm.logout = logout;
    vm.toggleSideMenuAndPreventDefault = toggleSideMenuAndPreventDefault;
    vm.isSideMenuOpen = isSideMenuOpen;
    vm.hasDevFlag = has("development");
    vm.isFullscreenMode = false;
    vm.eula;

    function toggleSideMenuAndPreventDefault($event) {
        $event.preventDefault();
        // BA 1/3: Added this timeout to prevent weird behaviour on double click
        $timeout(function () {
            $mdSidenav(LEFT_SIDE_NAV_ID).toggle();
        }, 0);

        return false;
    }

    function isSideMenuOpen() {
        return $mdSidenav(LEFT_SIDE_NAV_ID).isOpen();
    }

    function logout() {
        $mdSidenav(LEFT_SIDE_NAV_ID).close();
        $sbDetailsOverlay.close();
        $sbAuth.signOut();
        $sbFeatureDecisions.clearContext();
    }

    $scope.$on(
        "$stateChangeSuccess",
        function (event, newState, newParam, oldState) {
            // only close the sidenav from "internal" state change events
            if (
                oldState &&
                oldState.name &&
                SABLONO_STATES.isSablonoState(oldState.name)
            ) {
                $mdSidenav(LEFT_SIDE_NAV_ID).close();
            }

            // if you are on a project state -> close the deliverable overlay on state change.
            if (oldState.name && SABLONO_STATES.isProjectState(oldState.name)) {
                $sbDetailsOverlay.close();
            }

            $mdDialog.cancel();

            return (
                vm.eula ? Promise.resolve(vm.eula) : $sbEulaService.getEula()
            ).then((eula) => {
                if (!eula.userCanUseSablono) {
                    return $mdDialog.show(
                        $mdDialog
                            .eula()
                            .eulaAcceptKey(eula.eulaAcceptKey)
                            .activeDeadlineToAccept(
                                eula.activeDeadlineToAccept.format("L")
                            )
                            .shouldBlockUser(true)
                    );
                } else {
                    vm.eula = eula;
                }
            });
        }
    );

    //Method to check where to enable search
    $scope.checkSubBar = function (toState) {
        if (!toState) {
            toState = $state.current;
        }
        if (toState.data) {
            var d = toState.data;

            if (d.isSubBarHidden && d.barShadowHidden) {
                $scope.subBarHidden = false;
                return false;
            } else {
                $scope.subBarHidden = true;
                return true;
            }
        }
        $scope.subBarHidden = false;
        return false;
    };

    //Watch for state changes
    $scope.$on("$stateChangeStart", function (event, toState) {
        $scope.checkSubBar(toState);
    });

    //For the first state check enabled
    $scope.checkSubBar();

    $rootScope.$on(
        EVENTS.FULLSCREEN_MODE_CHANGE,
        function (evt, isFullscreenMode) {
            vm.isFullscreenMode = isFullscreenMode;
        }
    );
}
