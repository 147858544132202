import DeliverableChecklistsPdfExportCtrl from "./deliverable_checklists_pdf_export.controller";
import dialogTemplate from "./deliverable_checklists_pdf_export.html";

export default function DeliverableChecklistsPdfExportConfig(
    $mdDialogProvider
) {
    "ngInject";

    $mdDialogProvider.addPreset("deliverableChecklistsPdfExport", {
        methods: ["sendRequest"],
        options: function () {
            return {
                controller: DeliverableChecklistsPdfExportCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
