export default function ConfirmDestructiveActionDialogCtrl($mdDialog, $sbAuth) {
    "ngInject";
    var vm = this;

    vm.close = $mdDialog.cancel;

    vm.archiveOrDeleteState = {
        actionButtonText: "DELETE_PROJECT_DIALOG_WARN_USER_NEXT",
        actionButtonAction: () => {
            if (vm.skipPasswordStep === true) {
                return goToTypeProjectNameState();
            }
            goToConfirmWithPasswordState();
        },
        actionDisabled: () => false,
    };

    vm.confirmWithPasswordState = {
        userPassword: "",
        inputFieldType: "password",
        actionButtonText: "_NEXT",
        actionButtonAction: validatePassword,
        actionDisabled: () => {
            return vm.confirmWithPasswordState.userPassword.length === 0;
        },
    };

    vm.typeProjectNameState = {
        actionButtonText: "_ERASE",
        actionButtonAction: goAheadAndDelete,
        projectName: vm.config.key,
        userTypedProjectName: "",
        actionDisabled: () => {
            return (
                vm.typeProjectNameState.userTypedProjectName !==
                    vm.typeProjectNameState.projectName ||
                vm.typeProjectNameState.error
            );
        },
    };

    vm.finalState = {};

    vm.currentState = vm.archiveOrDeleteState;

    function goToConfirmWithPasswordState() {
        vm.currentState = vm.confirmWithPasswordState;
        vm.currentState.showLoading = false;
    }
    function goToTypeProjectNameState() {
        vm.currentState = vm.typeProjectNameState;
        vm.currentState.showLoading = false;
    }

    function validatePassword() {
        vm.currentState.showLoading = true;
        $sbAuth
            .getCurrentUser()
            .then((session) => {
                $sbAuth
                    .signIn(
                        session.username,
                        vm.confirmWithPasswordState.userPassword
                    )
                    .then(
                        () => {
                            goToTypeProjectNameState();
                        },
                        (err) => {
                            vm.currentState.inputError = err;
                            vm.currentState.showLoading = false;
                        }
                    );
            })
            .catch((err) => {
                vm.currentState.error = err;
            });
    }

    function goAheadAndDelete() {
        vm.currentState.showLoading = true;

        return vm.actions
            .confirmedAction(vm)
            .then(() => {
                vm.currentState.showLoading = false;
            })
            .catch((err) => {
                if (err) {
                    vm.currentState.showLoading = false;
                    vm.currentState.error = err;
                }
            });
    }
}
