export default function EulaDialogCtrl(
    $log,
    $mdDialog,
    $translate,
    $sbEulaService
) {
    "ngInject";
    var vm = this;

    vm.closeDialog = closeDialog;
    vm.acceptEula = acceptEula;
    vm.openEULADocument = openEULADocument;

    function closeDialog() {
        return $mdDialog.hide();
    }

    function acceptEula() {
        $sbEulaService.acceptEula(vm.eulaAcceptKey).finally($mdDialog.hide);
    }

    function openEULADocument() {
        $translate("EULA_LINK").then((url) => {
            window.open(url, "_blank");
        });
    }
}
