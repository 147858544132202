import htmlTemplate from "./sb_excel_viewer.html";

export default function sbExcelViewer() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        scope: {
            xlsData: "<",
            message: "<?",
        },
        controllerAs: "excelViewer",
        bindToController: true,
        controller: angular.noop,
    };
}
