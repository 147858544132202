import _ from "lodash";

var helpers = {
    required: required,
    throwOnMissingKeys: throwOnMissingKeys,
};

export default helpers;

function throwOnMissingKeys(objects, properties) {
    var isConformingToInterface = helpers.required(properties);

    if (!isConformingToInterface(objects)) {
        var message =
            "Invalid Interface Error. Required keys " +
            properties.join(", ") +
            " missing.";

        var err = new Error(message);
        err.code = "INVALID_INTERFACE_ERROR__MISSING_REQUIRED_KEYS";
        throw err;
    }
}

function required(properties) {
    return function validateAll(objects) {
        return _.every(objects, function validate(obj) {
            return _.every(properties, _.partial(_.has, obj));
        });
    };
}
