import _ from "lodash";
import moment from "moment";
import ActivityState from "./sb_activity_state.class";

/**
 * Create an instance of ActivityState.
 *
 * @returns {ActivityStateMapper}
 * @constructor
 */
function ActivityStateMapper() {
    return this;
}

ActivityStateMapper.ODATA_PROPERTY_MAP = {
    STATE: "current",
    STATE_CHANGED_AT: "changedAt",
    STATE_CHANGED_BY: "changedBy",
};

ActivityStateMapper.NEW_MODEL_PROPERTY_MAP = {
    "state.name": "current",
    "state.reported.at": "changedAt",
    "state.reported.by.name": "changedBy",
};

ActivityStateMapper.API_STATE_TO_DOMAIN = new Map([
    ["not_started", ActivityState.NOT_STARTED],
    ["started", ActivityState.STARTED],
    ["done", ActivityState.DONE],
    ["waiting_for_confirmation", ActivityState.WAITING_FOR_CONFIRMATION],
    ["rejected", ActivityState.REJECTED],
    ["confirmed", ActivityState.CONFIRMED],
]);

/**
 * Map an OData Data Transfer Object (DTO) to an ActivityState entity.
 *
 * @param {Object} odataObject
 * @param {ActivityState?} instance
 * @param {Object<string, string>?} PROPERTY_MAP
 *
 * @returns {ActivityState}
 */
ActivityStateMapper.toDomainEntity = function (odataObject, PROPERTY_MAP) {
    if (typeof PROPERTY_MAP === "undefined") {
        PROPERTY_MAP = ActivityStateMapper.ODATA_PROPERTY_MAP;
    }

    // use the property map to translate properties into target properties
    //
    const target = Object.keys(PROPERTY_MAP).reduce(function (
        _target,
        odataPropertyPath
    ) {
        const keyPath = PROPERTY_MAP[odataPropertyPath];
        const value = _.get(odataObject, odataPropertyPath);

        // if the value is null -> keep the default value from the constructor
        if (_.isNull(value)) {
            return _target;
        }
        _.set(_target, keyPath, value);

        return _target;
    }, {});

    return new ActivityState(
        target.current,
        moment(target.changedAt, moment.ISO_8601, true),
        target.changedBy
    );
};

ActivityStateMapper.createFromApiState = function (apiState) {
    const state = ActivityStateMapper.API_STATE_TO_DOMAIN.get(apiState);
    if (state) {
        return new ActivityState(state);
    }
    throw new Error(
        "Unsupported api state provided! Can not parse: " + apiState
    );
};

export default ActivityStateMapper;
