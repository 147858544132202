import angular from "angular";
import htmlTemplate from "./sb_component_upload_success.html";

/**
 * A basic directive to display a ComponentCreationResultDAO
 */
export default angular
    .module("sbApp.sbComponentUploadSuccess", [])
    .directive("sbComponentUploadSuccess", function () {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            transclude: true,
            scope: {
                dao: "=",
                showWarning: "&",
                header: "@",
                text: "@?",
            },
            link: function (scope, element, attrs) {
                // set default translation
                //
                if (!attrs.header) {
                    attrs.header = "UPLOAD_SUCCESSFUL";
                }

                // display text and buttons if given
                //
                scope.hasText = angular.isDefined(attrs.text);

                // display info/warn if necessary
                //
                scope.hasExceededLimits = scope.showWarning();
            },
        };
    });
