import _ from "lodash";

export default function userSuggestionService($q, $sbTeam) {
    "ngInject";
    var USER_SEARCH_PROPERTIES = [
        "USER_NAME",
        "FIRST_NAME",
        "LAST_NAME",
        "COMPANY_NAME",
        "DISPLAY_NAME",
        "EMAIL",
        "username",
        "displayName",
        "mail",
    ];

    var service = {
        suggestUsersFrom: suggestUsersFrom,
    };

    return service;

    //////////

    function suggestUsersFrom(searchText, listOfUsers, chosenUsers) {
        // don't suggest users already chosen
        //
        var suggestions = listOfUsers.filter(function (user) {
            return chosenUsers.indexOf(user) === -1;
        });

        // resolve all if search is null, undefined or something that you can not search for
        //
        if (!_.isString(searchText)) {
            return $q.resolve(suggestions);
        }

        searchText = searchText.toLowerCase();

        suggestions = suggestions.filter(
            function filterBySearchTextOn(suggestion) {
                return _hasSearchTextLikeProps(suggestion, searchText);
            }
        );

        return $q.resolve(suggestions);
    }

    function _hasSearchTextLikeProps(suggestion, searchText) {
        var simplifiedUser = _.pick(suggestion, USER_SEARCH_PROPERTIES);

        return _.find(
            simplifiedUser,
            function containsSearchText(userProperty) {
                return userProperty
                    ? userProperty.toLowerCase().indexOf(searchText) > -1
                    : false;
            }
        );
    }
}
