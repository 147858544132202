import angular from "angular";
import _ from "lodash";
import moment from "moment";
import DiaryEntry from "../model/diary_entry.class";
import ProgressSnapshot from "../model/progress_snapshot.class";
import DateChooserPanelCtrl from "../components/sbDateChooser/date_chooser.controller";
import panelHtml from "../components/sbDateChooser/date_chooser.panel.html";

export default function SiteDiaryService(
    $q,
    $mdPanel,
    $sbProject,
    $sbTeam,
    $sbDiaryEntriesApi,
    $sbSnapshotsApi,
    $sbWeekCalendar,
    $state,
    SABLONO_STATES
) {
    "ngInject";

    var allTeamsInProject = [];
    var allTeamMembersInProject = [];

    var service = {
        fetchEntries: fetchEntries,
        fetchEntry: fetchEntry,
        fetchProgressSnapshotFor: fetchProgressSnapshotFor,
        createProgressSnapshotFor: createProgressSnapshotFor,
        createNewEntry: createNewEntry,
        updateEntry: updateEntry,
        getBeginningOfToday: getBeginningOfToday,
        initializeCalendar: initializeCalendar,
        navigateTo: navigateTo,
        openDateChooser: openDateChooser,
        parseDate: parseDate,
        toISODateString: toISODateString,
        trimToDatePrecision: trimToDatePrecision,
    };

    function initializeCalendar(selectedDate) {
        return $q
            .all({
                diaryEntries: service.fetchEntries(),
                calendar: $sbProject.getCalendar(
                    $sbProject.getCurrentProjectId()
                ),
            })
            .then(function (values) {
                $sbWeekCalendar.init(
                    values.calendar,
                    selectedDate,
                    values.diaryEntries
                );
            });
    }

    function fetchEntries() {
        var projectId = $sbProject.getCurrentProjectId();

        return $q
            .all({
                teams: $sbTeam.getTeams(projectId),
                teamMembers: $sbTeam.getUsersByProject(projectId),
                diaryEntries: $sbDiaryEntriesApi
                    .getCollection(projectId)
                    .then((response) =>
                        response.diary_entries.map(mapDiaryToOldModel)
                    ),
            })
            .then(_setTeamsInService)
            .then(_setTeamMembersInService)
            .then(function (data) {
                return data.diaryEntries.map(_parseToSiteDiaryModel);
            });
    }

    function mapSnapshotToOldModel(entry) {
        const oldModel = {
            id: entry.id,
            date: entry.created_for,
            generatedBy: entry.generated.by.id,
            generatedAt: entry.generated.at,
            changes: entry.events_by_deliverable.map((deliverable) => {
                return {
                    id: deliverable.id,
                    name: deliverable.name,
                    code: deliverable.code,
                    structure: deliverable.structure,
                    activities: deliverable.state_events.map((state_event) => {
                        const stateFrom = _.get(
                            state_event,
                            "event.transitioned_from.name",
                            ""
                        ).toUpperCase();

                        const stateTo = _.get(
                            state_event,
                            "event.transitioned_to.name",
                            ""
                        ).toUpperCase();
                        return {
                            id: _.get(state_event, "activity.id"),
                            name: _.get(state_event, "activity.name"),
                            topologicalIndex: _.get(
                                state_event,
                                "activity.topological_index"
                            ),
                            stateTransitionedFrom: stateFrom ? stateFrom : null,
                            stateTransitionedFromAt: _.get(
                                state_event,
                                "event.transitioned_from.reported.at"
                            ),
                            stateReached: stateTo ? stateTo : null,
                            stateReachedBy: _.get(
                                state_event,
                                "event.transitioned_to.reported.by.id"
                            ),
                        };
                    }),
                    noteChanges: deliverable.note_events.map((note_event) => {
                        return {
                            id: _.get(note_event, "note.id"),
                            componentId: _.get(
                                note_event,
                                "event.status_to.name"
                            ),
                            componentName: _.get(
                                note_event,
                                "note.attached_to.name"
                            ),
                            type: noteTypeNewToOld(
                                _.get(note_event, "note.type")
                            ),
                            text: _.get(note_event, "note.text"),
                            status: noteStatusNewToOld(
                                _.get(note_event, "event.status_to.name")
                            ),
                            statusChangedBy: _.get(
                                note_event,
                                "event.status_to.reported.by.id"
                            ),
                        };
                    }),
                };
            }),
        };
        return oldModel;
    }

    function noteTypeNewToOld(type) {
        switch (type) {
            case "quality_issue":
                return "CLAIM";
            case "obstruction":
                return "OBSTRUCTION";
            case "info":
                return "INFO";
        }
    }

    function noteStatusNewToOld(status) {
        switch (status) {
            case "open":
                return "A";
            case "resolved":
                return "D";
            case "info":
                return "A";
        }
    }

    function mapDiaryToOldModel(entry) {
        const oldModel = {
            id: entry.id,
            date: entry.date,
            createdBy: entry.created.by.id,
            createdAt: entry.created.at,
            finalizedAt: _.get(entry, "finalized.at"),
            finalizedBy: _.get(entry, "finalized.by.id"),
            department: entry.department,
            machines: entry.machines,
            events: entry.events,
            weather: {
                condition: _.get(entry, "weather.condition"),
                temperature: {
                    low: _.get(entry, "weather.temperature.lowest"),
                    high: _.get(entry, "weather.temperature.highest"),
                },
            },
            teams: (entry.teams || []).map((team) => {
                return {
                    id: team.id,
                    name: team.name,
                    numberOfManager: team.number_of_managers,
                    numberOfForeman: team.number_of_foremen,
                    numberOfWorker: team.number_of_workers,
                    numberOfOther: team.number_of_others,
                };
            }),
        };
        oldModel.workingTime = {
            start: _.get(entry, "working_time.start"),
            end: _.get(entry, "working_time.end"),
        };
        return oldModel;
    }

    function fetchEntry(dateAsMoment) {
        return $sbDiaryEntriesApi
            .get(
                $sbProject.getCurrentProjectId(),
                dateAsMoment.format("YYYY-MM-DD")
            )
            .then(mapDiaryToOldModel)
            .then(_parseToSiteDiaryModel);
    }

    function fetchProgressSnapshotFor(dateAsMoment) {
        return $sbSnapshotsApi
            .get(
                $sbProject.getCurrentProjectId(),
                dateAsMoment.format("YYYY-MM-DD")
            )
            .then(mapSnapshotToOldModel)
            .then(_parseToProgressSnapshotModel);
    }

    function createProgressSnapshotFor(dateAsMoment) {
        return $sbSnapshotsApi
            .create($sbProject.getCurrentProjectId(), {
                created_for: dateAsMoment.format("YYYY-MM-DD"),
            })
            .then(mapSnapshotToOldModel)
            .then(_parseToProgressSnapshotModel);
    }

    function _parseToProgressSnapshotModel(snapshot) {
        var progressSnapshot = new ProgressSnapshot(
            snapshot.id,
            snapshot.projectId
        );
        progressSnapshot.setGeneratedAt(snapshot.generatedAt);
        progressSnapshot.setChanges(snapshot.changes, allTeamMembersInProject);
        return progressSnapshot;
    }

    function createNewEntry(dateAsMoment) {
        return $sbDiaryEntriesApi
            .create($sbProject.getCurrentProjectId(), {
                date: dateAsMoment.format("YYYY-MM-DD"),
            })
            .then(mapDiaryToOldModel)
            .then(_parseToSiteDiaryModel)
            .then(_updateDiaryEntriesInCalendar);
    }

    function updateEntry(dateAsMoment, entryData) {
        // PUT response does not include the changes prop
        // so needs to be reattached
        //
        var _changes = entryData.changes;
        return $sbDiaryEntriesApi
            .update(
                $sbProject.getCurrentProjectId(),
                dateAsMoment.format("YYYY-MM-DD"),
                DiaryEntry.toServerUploadModel(entryData)
            )
            .then(mapDiaryToOldModel)
            .then(_parseToSiteDiaryModel)
            .then(_updateDiaryEntriesInCalendar)
            .then(function (updatedEntry) {
                updatedEntry.changes = _changes;
                return updatedEntry;
            });
    }

    function _updateDiaryEntriesInCalendar(newEntry) {
        $sbWeekCalendar.updateDiaryEntriesWith(newEntry);

        return newEntry;
    }

    function _setTeamsInService(data) {
        allTeamsInProject = data.teams;
        return data;
    }

    function _setTeamMembersInService(data) {
        allTeamMembersInProject = data.teamMembers;
        return data;
    }

    function _parseToSiteDiaryModel(entry) {
        return DiaryEntry.createFromServerObject(
            entry,
            allTeamMembersInProject,
            allTeamsInProject
        );
    }

    function getBeginningOfToday() {
        return service.trimToDatePrecision(moment());
    }

    function navigateTo(diaryEntry) {
        $state.go(
            SABLONO_STATES.siteDiary,
            {
                entry: service.toISODateString(diaryEntry),
            },
            {
                location: "replace",
                notify: false,
            }
        );
    }

    function openDateChooser($event, locals, onCloseSuccess) {
        return $mdPanel.open({
            attachTo: angular.element(document.body),
            templateUrl: panelHtml,
            focusOnOpen: false,
            bindToController: true,
            controllerAs: "$ctrl",
            controller: DateChooserPanelCtrl,
            clickOutsideToClose: true,
            escapeToClose: true,
            position: $mdPanel
                .newPanelPosition()
                .relativeTo($event.target)
                .addPanelPosition(
                    $mdPanel.xPosition.ALIGN_START,
                    $mdPanel.yPosition.ALIGN_TOPS
                ),
            animation: $mdPanel
                .newPanelAnimation()
                .openFrom($event)
                .duration(200)
                .closeTo($event.target)
                .withAnimation($mdPanel.animation.SCALE),
            locals: locals,
            onCloseSuccess: onCloseSuccess,
        });
    }

    function parseDate(entryDateString) {
        // first second of the given day
        //
        var entryDate = service.trimToDatePrecision(
            moment(entryDateString, moment.ISO_8601)
        );

        if (entryDate.isValid()) {
            return entryDate;
        }
    }

    function toISODateString(date) {
        var ISO_DATE_FORMAT = "YYYY-MM-DD";
        return moment(date).format(ISO_DATE_FORMAT);
    }

    function trimToDatePrecision(date) {
        return moment(date).startOf("day");
    }

    return service;
}
