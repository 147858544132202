export default class DependenciesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list of dependencies attached to a deliverable
     *
     * @param deliverableId {string}    UUID
     */
    getCollection(deliverableId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/deliverables/${deliverableId}/dependencies`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }
}
