import angular from "angular";
import ODataLoaderProvider from "./odata_loader.provider";
import ODataListDirective from "./odata_list.directive";
import "common/services/services";
import "angular-material";

export default angular
    .module("sbApp.sbOdataList", ["ngMaterial", "sbApp.services"])
    .provider("sbOdataLoader", ODataLoaderProvider)
    .directive("sbOdataList", ODataListDirective);
