import angular from "angular";
import checklistTemplatesRouteConfig from "./checklist_templates.route";
import ChecklistTemplatesListCtrl from "./states/list/list.controller";
import ChecklistTemplatesEditorCtrl from "./states/editor/editor.controller";
import sbChecklistTemplateOverview from "./states/editor/components/checklistTemplateOverview/checklist_template_overview.component";
import sbChecklistTemplateRow from "./states/list/components/checklistTemplateRow/checklist_template_row.component";
import CreateChecklistTemplateDialog from "./dialogs/createChecklistTemplate/create_checklist_template_dialog.config";
import ChecklistService from "./services/checklist.service";
import DirectivesModule from "common/ui-elements/ui-elements";
import "angular-material";
import "angular-ui-router";

export default angular
    .module("sbApp.checklistTemplates", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        DirectivesModule.name,
    ])
    .config(checklistTemplatesRouteConfig)
    .config(CreateChecklistTemplateDialog)
    .factory("$sbChecklistService", ChecklistService)
    .controller("ChecklistTemplatesListCtrl", ChecklistTemplatesListCtrl)
    .controller("ChecklistTemplatesEditorCtrl", ChecklistTemplatesEditorCtrl)
    .component("sbChecklistTemplateOverview", sbChecklistTemplateOverview)
    .component("sbChecklistTemplateRow", sbChecklistTemplateRow);
