import angular from "angular";
import htmlTemplate from "./sbUserImage.html";
export default angular
    .module("sbApp.sbUserImage", [])
    .component("sbUserImage", {
        templateUrl: htmlTemplate, //HTML Template as String
        bindings: {
            initials: "<",
            displayName: "<",
        },
    });
