/**
 * Service that includes all the scheduling process validations and checks
 *
 */
import _ from "lodash";

export default function deliverableTemplateAssignService(
    $sbBusyIndication,
    $mdDialog,
    $sbDialog,
    $sbComponent,
    $sbTracking,
    $sbErrorPresenter
) {
    "ngInject";
    ////////
    //
    //  PUBLIC API
    //
    ////////

    var service = {
        startAssignWorkflow: startAssignWorkflow,
        assignTemplateToSelection: assignTemplateToSelection,
        updateTemplateBySelectionState: updateTemplateBySelectionState,
        startBulkActivityConfigurationWorkflow:
            startBulkActivityConfigurationWorkflow,
    };

    return service;

    ////////
    //
    //  IMPL
    //
    ////////

    /**
     *
     * @param $event
     * @param {DeliverableSelection} deliverableSelection
     * @param templateId - shared templateId between the selected deliverables
     * @return {*}
     */
    function startBulkActivityConfigurationWorkflow(
        $event,
        deliverableSelection,
        templateId
    ) {
        return $mdDialog.show(
            $mdDialog
                .activitySetConfiguration({
                    targetEvent: $event,
                    openFrom: $event.target,
                    closeTo: $event.target,
                })
                .templateId(templateId)
                .deliverableSelection(deliverableSelection)
        );
    }

    /**
     *
     * @param $event
     * @param {DeliverableSelection} deliverableSelection
     * @return {*}
     */
    function startAssignWorkflow($event, deliverableSelection) {
        return $sbDialog.openAssignTemplateDialog($event).then(function ({
            selectedTemplate,
            answer = false,
        }) {
            return assignTemplateToSelection(
                deliverableSelection,
                selectedTemplate,
                {
                    isForceOverwrite: answer,
                }
            );
        });
    }

    /**
     *
     * @param {DeliverableSelection} deliverableSelection
     * @param {object} template
     * @param {object} options
     * @param {boolean} options.isForceOverwrite
     * @return {*}
     */
    function assignTemplateToSelection(
        deliverableSelection,
        template,
        options
    ) {
        var assignPromise = service
            .updateTemplateBySelectionState(
                deliverableSelection,
                template,
                options
            )
            .catch(function (error) {
                try {
                    if (
                        error.message ===
                        "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION"
                    ) {
                        $sbTracking.leanBoard.session.concurrentSession(
                            "Tried to change a deliverable that has been changed in another session"
                        );
                        return $sbDialog.openModifiedInConcurrentSessionDialog(
                            error
                        );
                    } else {
                        $sbErrorPresenter.catch(error);
                    }
                } catch (e) {
                    $sbErrorPresenter.catch(error);
                }
            });

        return $sbBusyIndication
            .show(
                assignPromise,
                "ACTION_ASSIGN_TEMPLATE_LOADING",
                "DIALOG_MERGE_STRUCTURE_LOADING_TITLE"
            )
            .then(function (assignRes) {
                const associatedDeliverables = _.get(
                    assignRes,
                    "associate",
                    []
                );
                const reassociatedDeliverables = _.get(
                    assignRes,
                    "reassociate",
                    []
                );

                const updatedDeliverables = _.get(assignRes, "pullUpdate", []);

                return {
                    deliverables: _.union(
                        associatedDeliverables,
                        reassociatedDeliverables,
                        updatedDeliverables
                    ),
                    template: template,
                };
            });
    }

    /**
     * Update process template of deliverables by selection.
     *
     * @param {DeliverableSelection} deliverableSelection
     * @param {object} template
     * @param {string} template.ID
     * @param {object} options
     * @param {boolean} options.isForceOverwrite
     * @return {*}
     */
    function updateTemplateBySelectionState(
        deliverableSelection,
        template,
        options
    ) {
        return $sbComponent.updateComponentsTemplate(
            deliverableSelection.getPreferredCondition(),
            deliverableSelection.projectId,
            template.ID,
            options
        );
    }
}
