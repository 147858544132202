import _ from "lodash";

export class GalleryAttachment {
    constructor(id) {
        this.id = id;
    }
}

export class ImageAttachment extends GalleryAttachment {
    constructor(id) {
        super(id);

        this.fullDataUri = undefined;
        this.thumbDataUri = undefined;
    }

    isThumbDownloaded() {
        return !_.isNil(this.thumbDataUri);
    }

    isFullDownloaded() {
        return !_.isNil(this.fullDataUri);
    }
}

export class DocumentAttachment extends GalleryAttachment {
    constructor(id, name) {
        super(id);

        this.name = name;
    }
}
