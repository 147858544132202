export default function BulkCreateNoteCtrl(
    $mdDialog,
    $sbErrorPresenter,
    $sbActivities
) {
    "ngInject";
    var vm = this;

    var _selectedActivities = [];

    vm.loading = true;

    vm.submit = submit;
    vm.closeDialog = $mdDialog.cancel;
    vm.isActivitiesSelected = isActivitiesSelected;
    vm.onActivitySelectionChange = onActivitySelectionChange;

    //call $onInit manually because it is not automatically called in a dialog controller
    $onInit();

    function $onInit() {
        return $sbActivities
            .getAllActivitiesByDeliverableTemplateID({
                templateId: vm.templateId,
            })
            .then(function (activities) {
                vm.activities = activities;
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                $mdDialog.cancel();
            })
            .finally(function () {
                vm.loading = false;
            });
    }

    function submit() {
        $mdDialog.hide(_selectedActivities[0]);
    }

    function isActivitiesSelected() {
        return _selectedActivities.length > 0;
    }

    function onActivitySelectionChange(selectedActivities) {
        _selectedActivities = selectedActivities;
    }
}
