/**
 * A service module for common parsing methods used by MSP, Primavera and ASTA
 * by tables to display the component and structure information.
 *
 * @module parseXmlService
 */
export default function parseXmlService($q, $sbXML, $sbPrimavera, $sbMsp) {
    "ngInject";
    return {
        transformImport: fnPubTransformImport,
        transformMsp: transformMSP,
        transformAsta: transformAsta,
        transformPrimavera: transformPrimavera,
    };

    /**
     * Read the source info from the document
     *
     * @private
     *
     * @param {Object} oXML Document to be extracted
     * @returns {{type: string, data: {creationDate: string, title: string, fileName: string}}}
     */
    function _extractSourceFromXml(oXML, sourceType) {
        var cDate = oXML.querySelector("Project > CreationDate"),
            sFileName = oXML.querySelector("Project > Name"),
            sTitle = oXML.querySelector("Project > Title") || sFileName;

        return {
            type: sourceType,
            data: {
                creationDate: cDate ? cDate.innerHTML : "",
                title: sTitle ? sTitle.innerHTML : "",
            },
        };
    }

    ////////////////////////////////
    //                            //
    //         PUBLIC API         //
    //                            //
    ////////////////////////////////

    /**
     * Read String and convert it to XML. Extract Task tags and create a component model like we are used to it.
     * Process the component tree model and create:
     *
     * aComponents: aRootComponents,
     * aChildComponents: aChildComponents,
     * aStructuring: aStructuring,
     * aStructureFilter: oObj.aSub
     *
     * @listens "FileIO" - "onload"
     *
     * @param  {Document} oXML - XML File as String from e.g. a FileReader
     * @param  {String} timezone    - The name of the timezone that is used for imported dates.
     * @return {Promise}
     */
    function transformMSP(oXML, timezone) {
        return $q(function (resolve) {
            var oCompModel,
                oComponentMap = $sbMsp.collectAllComponentsFromXml(
                    oXML,
                    "MSP",
                    timezone
                );

            oCompModel = $sbMsp.assemblePreviewModel(oComponentMap);
            oCompModel.source = _extractSourceFromXml(oXML, "MSP");
            oCompModel.sourceType = "MSP";
            resolve(oCompModel);
        });
    }

    function transformAsta(oXML, timezone) {
        return $q(function (resolve) {
            var oCompModel,
                oComponentMap = $sbMsp.collectAllComponentsFromXml(
                    oXML,
                    "ASTA",
                    timezone
                );

            oCompModel = $sbMsp.assemblePreviewModel(oComponentMap);
            oCompModel.source = _extractSourceFromXml(oXML, "ASTA");
            oCompModel.sourceType = "ASTA";
            resolve(oCompModel);
        });
    }

    /**
     * Read the xml and create a model out of it.
     * @param  {Document} oXML      - XML file as string object
     * @param  {String} timezone    - The name of the timezone that is used for imported dates.
     * @return {Promise}
     */
    function transformPrimavera(oXML, timezone) {
        return $q(function (resolve) {
            //Collect all the relevant components from XML of primavera
            var oCompModel,
                oComponentMap = $sbPrimavera.collectAllComponentsFromXML(
                    oXML,
                    timezone
                );
            //Assemble everything into preview model
            oCompModel = $sbPrimavera.assemblePreviewModel(oComponentMap, oXML);
            //Set the source and it's type
            oCompModel.source = _extractSourceFromXml(oXML);
            oCompModel.source.type = "PRIMAVERA";
            oCompModel.sourceType = "PRIMAVERA";
            //Resolve the promise
            resolve(oCompModel, "PRIMAVERA");
        });
    }
    /**
     * Transform imported file into our preview model based on
     * type of import, i.e. MSProject, Primavera etc.
     * @param  {Document} oXML      - XML File as String from e.g. a FileReader
     * @param  {String} sourceType  - Just a flag marking the type of import
     * @param  {String} timezone    - The name of the timezone that is used for imported dates.
     * @return {Promise} Resolves with the transformed data
     */
    function fnPubTransformImport(oXML, sourceType, timezone) {
        if (oXML.getElementsByTagName("APIBusinessObjects").length > 0) {
            //It's primavera object
            return transformPrimavera(oXML, timezone);
        } else {
            //It's MSP XML object -> Asta or MSP
            if (sourceType === "ASTA") {
                return transformAsta(oXML, timezone);
            } else {
                return transformMSP(oXML, timezone);
            }
        }
    }
}
