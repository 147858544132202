import createjs from "createjs";

/**
 * Event dispatched, when an Edge was created
 * @param {WBS.StructureNode} source - Source Node of Edge
 * @param {WBS.StructureNode} target - Target Node of Edge
 * @constructs EdgeCreatedEvent
 * @extends createjs.Event
 */
function EdgeCreatedEvent(source, target) {
    createjs.Event.call(this, "edgeCreated", false, false);

    this.edgeSource = source;
    this.edgeTarget = target;
}

EdgeCreatedEvent.prototype = Object.create(createjs.Event.prototype);

export default EdgeCreatedEvent;
