import PickFromChoicesCtrl from "./pick_from_choices_dialog.controller";
import dialogTemplate from "./pick_from_choices_dialog.html";

export default function pickFromChoicesConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("pickFromChoices", {
        methods: ["choices", "onPick", "class"],
        options: function () {
            return {
                controller: PickFromChoicesCtrl,
                controllerAs: "$ctrl",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
