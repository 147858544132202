import angular from "angular";
import moment from "moment";
import htmlTemplate from "./project_edit.html";

export default angular
    .module("sbApp.sbProjectEdit", [])
    .directive(
        "sbProjectEdit",
        function (
            PROJECT_CONFIG,
            $sbTimezone,
            $sbFileReader,
            $sbErrorPresenter,
            $sbImageUpload,
            $sbFeatureDecisions,
            PageFlags
        ) {
            return {
                restrict: "E",
                templateUrl: htmlTemplate, //HTML Template as String
                controllerAs: "editProject",
                bindToController: true,
                scope: {
                    project: "=",
                    projectForm: "=",
                    creationMode: "=",
                },

                controller: function (
                    $sbProjectApi,
                    $translate,
                    $sbPermission,
                    LANGUAGES,
                    $timeout
                ) {
                    "ngInject";
                    /////////////////////
                    //
                    //      Direct variables
                    //
                    /////////////////////

                    var MAX_COVER_IMAGE_WIDTH = 700;
                    var MAX_COVER_IMAGE_HEIGHT = 700;

                    var vm = this;

                    /////////////////////
                    //
                    //      View model properties
                    //
                    /////////////////////

                    vm.availableLanguages = LANGUAGES;
                    vm.CONFIG = PROJECT_CONFIG;
                    vm.editAllowed = false;
                    vm.isSaved = false;
                    vm.showTimezoneEdit = false;
                    vm.creationDialog = false;
                    vm.currencyEditAllowed = false;

                    vm.allowedPictureExtension = [
                        ".png",
                        ".jpg",
                        ".jpeg",
                        ".gif",
                    ];

                    vm.image = {
                        url: null,
                    };

                    vm.projectTypes = [
                        {
                            key: "Residential Construction",
                            name: "INPUT_PROJECT_TYPE_OPTION_1",
                        },
                        {
                            key: "Commercial Construction",
                            name: "INPUT_PROJECT_TYPE_OPTION_2",
                        },
                        {
                            key: "Manufacturing & Production",
                            name: "INPUT_PROJECT_TYPE_OPTION_3",
                        },
                        {
                            key: "Renewable Energy",
                            name: "INPUT_PROJECT_TYPE_OPTION_4",
                        },
                        {
                            key: "Heritage, Conservation, Refurbishment",
                            name: "INPUT_PROJECT_TYPE_OPTION_5",
                        },
                        {
                            key: "Public (Health, Education, Defence)",
                            name: "INPUT_PROJECT_TYPE_OPTION_6",
                        },
                        {
                            key: "Industrial Construction (Distribution, Logistics, Data Centre)",
                            name: "INPUT_PROJECT_TYPE_OPTION_7",
                        },
                        {
                            key: "Infrastructure, Rail, Roads",
                            name: "INPUT_PROJECT_TYPE_OPTION_8",
                        },
                        {
                            key: "Private Housing",
                            name: "INPUT_PROJECT_TYPE_OPTION_9",
                        },
                        {
                            key: "Other",
                            name: "INPUT_PROJECT_TYPE_OPTION_10",
                        },
                    ];
                    vm.availableCurrencies = ["USD", "EUR", "GBP", "AUD"];
                    vm.changeTimezone = changeTimezone;
                    vm.onPictureChange = onPictureChange;

                    vm.onStartDateChange = (date) =>
                        (vm.project.startDate = date);
                    vm.onEndDateChange = (date) => (vm.project.endDate = date);

                    _activate();

                    /////////////////////
                    //
                    //      IMPL
                    //
                    /////////////////////

                    function _activate() {
                        if (!vm.project) {
                            // we are in creation mode, so we initialize
                            vm.creationDialog = true;
                            var timezone = $sbTimezone.getDefaultTimezone();

                            vm.project = {
                                language: $translate.use().toUpperCase(),
                                TIMEZONE: timezone,
                                startDate: undefined,
                                endDate: undefined,
                            };
                            vm.editAllowed = true;
                            vm.showTimezoneEdit = true;
                        } else {
                            refreshProjectImage(
                                vm.project.id,
                                vm.project.imageId
                            );

                            vm.editAllowed =
                                $sbPermission.hasAdminPermissions(
                                    vm.project.privileges
                                ) || vm.creationMode;
                            vm.currencyEditAllowed =
                                $sbFeatureDecisions.isFeatureEnabled(
                                    PageFlags.CommercialValuation
                                );
                        }
                    }

                    function onPictureChange($event, file) {
                        return $sbFileReader
                            .readAndResizeImage(
                                file,
                                MAX_COVER_IMAGE_WIDTH,
                                MAX_COVER_IMAGE_HEIGHT
                            )
                            .then(function (result) {
                                return _uploadImage(
                                    result.data.rawData,
                                    result.fileName,
                                    vm.project.id
                                );
                            })
                            .catch($sbErrorPresenter.catch);
                    }

                    function _showSavedIndication() {
                        vm.isSaved = true;
                        return $timeout(function () {
                            vm.isSaved = false;
                        }, 1500);
                    }

                    function refreshProjectImage(projectId, imageId) {
                        return $sbProjectApi
                            .fetchLogo(projectId, imageId)
                            .then((image) => {
                                vm.image.url = image.dataUrl;
                            });
                    }

                    function _uploadImage(blob, fileName, projectId) {
                        return $sbImageUpload
                            .uploadImages(fileName, [blob], projectId)
                            .then(function (result) {
                                vm.project.imageId = result.id;
                                return $sbProjectApi.edit(vm.project);
                            })
                            .then(() =>
                                refreshProjectImage(
                                    vm.project.id,
                                    vm.project.imageId
                                )
                            )
                            .then(_showSavedIndication)
                            .catch($sbErrorPresenter.catch);
                    }

                    /**
                     * changeTimezone - When the language changes, update the current timezone
                     *
                     * @param  {String} language - Two char identifing the language (EN, DE, IT...)
                     */
                    function changeTimezone(language) {
                        vm.project.TIMEZONE =
                            $sbTimezone.getDefaultTimezone(language);
                    }

                    /**
                     * Checks if given end date is before the start date
                     * @param {Date} endDate - end date of  range
                     * @param {Date} startDate - start date of range
                     * @returns {boolean} - (True if end date before start date, false otherwise)
                     */
                    function _endDateBeforeStartDate(endDate, startDate) {
                        if (!startDate || !endDate) {
                            return false;
                        }

                        return moment(endDate).isBefore(moment(startDate));
                    }
                },
            };
        }
    );
