export default function importActivitiesService(
    $sbTemplate,
    $sbCurrentProject,
    $sbExternalService,
    $filter
) {
    "ngInject";

    const service = {
        openImportActivitiesDialog,
    };

    return service;

    function openImportActivitiesDialog(
        navigateOnConfirmCallback,
        navigateOnCancelCallback
    ) {
        _fetchProcessTemplatesOfCurrentProject().then((processTemplates) => {
            const projectId = $sbCurrentProject.pick("id");

            const importActivitiesModalData = {
                processTemplates,
                projectId,
                navigateOnCancelCallback,
                navigateOnConfirmCallback,
            };

            $sbExternalService.openImportActivitiesModal(
                importActivitiesModalData
            );
        });
    }

    function _fetchProcessTemplatesOfCurrentProject() {
        var naturalSort = $filter("naturalSort");

        return $sbTemplate.getTemplates().then((templates) => {
            return naturalSort(
                templates.map((template) => {
                    return {
                        name: template.NAME,
                        id: template.ID,
                    };
                }),
                "label"
            );
        });
    }
}
