import WbsCommand from "./WBSCommand";
import StructureNode from "../node/StructureNode";

/**
 * Command to Add a Sibling
 * @param {WBS.NodeContainer} selectedNode - Currently Selected node to add Child to
 * @param {WBS.NodeManager} nodeManager - Manages all the Nodes
 * @constructs AddSiblingClass
 * @implements {WBS.WbsCommand}
 * @memberof WBS
 */
function AddSiblingCommand(selectedNode, nodeManager) {
    WbsCommand.call(
        this,
        selectedNode.stage.$sbErrorPresenter,
        selectedNode.stage.wbsApiService
    );

    /**
     *
     * @type {WBS.NodeManager}
     */
    this.nodeManager = nodeManager;

    /**
     * Node to which a sibling is added
     * @type {WBS.StructureNode}
     */
    this.selectedNode = selectedNode;
}

/**
 * Setup prototypal inheritance.
 * AddSiblingCommand inherits from ssCommand.
 *
 * @type {WBS.WbsCommand}
 */
AddSiblingCommand.prototype = Object.create(WbsCommand.prototype);

/**
 * add sibling to selected node
 */
AddSiblingCommand.prototype.execute = function () {
    var selectedNodeData = this.selectedNode.entity.getData();
    var sProjectId = "";
    var sParentId = "";

    if (selectedNodeData) {
        sProjectId = selectedNodeData.PROJECT_ID;
        sParentId = selectedNodeData.PARENT_ID;
    } else {
        sProjectId = undefined;
        sParentId = undefined;
    }

    var cmd = this;

    function addNodeToUI(newElement) {
        const parsedElement = StructureNode.parseServerResponse(newElement);

        var childNode = new StructureNode(parsedElement, cmd.nodeManager);

        cmd.selectedNode.parent.createSibling(childNode);
        cmd.selectedNode.parent.parent.dispatchAddedToStage();

        var nodeCreatedEvent = new createjs.Event("nodeCreated", true, false);
        cmd.selectedNode.stage.dispatchEvent(nodeCreatedEvent);
    }

    // if the data object is valid for the server , call the server API
    // -> this is a developer case (maybe remove on production)
    if (sProjectId !== undefined) {
        this.wbsApiService
            .createNode(sProjectId, sParentId)
            .then(addNodeToUI)
            .catch(this.$sbErrorPresenter.catch);
    } else {
        addNodeToUI({
            d: {
                NAME: "not on server!",
            },
        });
    }
};

/**
 * @todo remove sibling from selected node
 */
AddSiblingCommand.prototype.undo = function () {
    console.log(this.selectedNode);
};

/**
 * Readds sibling to node
 */
AddSiblingCommand.prototype.redo = function () {
    this.execute();
};

export default AddSiblingCommand;
