export default function ActivityDetailsPanelCommonService(
    $sbPermission,
    $sbProject,
    $sbTracking,
    $timeout,
    $document,
    $mdPanel
) {
    "ngInject";

    const service = {
        onClose: onClose,
        hasPermission: hasPermission,
        repositionPanel: repositionPanel,
    };

    return service;

    function onClose(mdPanelRef) {
        mdPanelRef.close();
    }

    function hasPermission() {
        return $sbPermission.hasPlanPermissions(
            $sbProject.getCurrent().privileges
        );
    }

    function repositionPanel(panelModelId, mdPanelRef) {
        // Timeout ensures the correct height of the panel is set after it is moved
        return $timeout(function () {
            const clickedActivityCard =
                $document[0].getElementById(panelModelId);

            mdPanelRef.updatePosition(
                $mdPanel
                    .newPanelPosition()
                    .relativeTo(clickedActivityCard)
                    .addPanelPosition("align-start", "below")
            );
        }, 25);
    }
}
