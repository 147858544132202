import schedulingPanelTemplate from "./sb_scheduling_panel.html";

export default function PanelProviderConfig($mdPanelProvider) {
    "ngInject";
    $mdPanelProvider.definePreset("$sbSchedulingPanel", {
        controller: PanelMenuCtrl,
        controllerAs: "$ctrl",
        templateUrl: schedulingPanelTemplate,
        panelClass: "sb-scheduling-state-panel",
        focusOnOpen: false,
        zIndex: 100,
        clickOutsideToClose: true,
        escapeToClose: true,
    });
}

function PanelMenuCtrl(
    mdPanelRef,
    loadingToast,
    EVENTS,
    $sbErrorPresenter,
    $rootScope,
    $sbCurrentProject,
    $sbProjectApi,
    $sbScheduling,
    $sbTracking,
    Analytics,
    $mdToast
) {
    "ngInject";

    var vm = this;

    vm.scheduleProject = scheduleProject;
    vm.showSuccessToast = showSuccessToast;
    vm.trackSuccessfulRescheduling = trackSuccessfulRescheduling;
    vm.updateProjectCache = updateProjectCache;
    vm.triggerDeliverablesListReload = triggerDeliverablesListReload;
    vm.closeSchedulingPanel = closeSchedulingPanel;

    function scheduleProject() {
        loadingToast.show("INFO_PROJECT_EDIT_LOADING_TITLE");

        var projectId = $sbCurrentProject.pick("id");

        return $sbScheduling
            .scheduleProject(projectId)
            .then(function () {
                vm.trackSuccessfulRescheduling();
                vm.showSuccessToast();

                return vm
                    .updateProjectCache(projectId)
                    .then(vm.triggerDeliverablesListReload);
            })
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                vm.closeSchedulingPanel();
                loadingToast.hide();
            });
    }

    function closeSchedulingPanel() {
        return mdPanelRef.close();
    }

    function triggerDeliverablesListReload() {
        $rootScope.$emit(EVENTS.DELIVERABLES_LIST__TRIGGER_RELOAD);
    }

    function updateProjectCache(projectId) {
        return $sbProjectApi.refresh(projectId);
    }

    function trackSuccessfulRescheduling() {
        $sbTracking.scheduling.dirtyState.resolved();
    }

    function showSuccessToast() {
        return $mdToast.show(
            $mdToast
                .simple()
                .content("INFO_PROJECT_EDIT_SUCCESS_TITLE")
                .position("top right")
        );
    }
}
