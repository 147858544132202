import angular from "angular";
import inspectRequestRouteConfig from "./inspect_request.route";
import inspectRequestCtrl from "./inspect_request.controller";
import "angular-ui-router";
import "angular-material";
import "common/dialog/dialog";

export default angular
    .module("sbApp.inspectRequest", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.dialog",
    ])
    .config(inspectRequestRouteConfig)
    .controller("inspectRequestCtrl", inspectRequestCtrl);

/* declaration */
