import _ from "lodash";

function getPreferredLanguageKey() {
    var languageKey =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

    if (_.isString(languageKey)) {
        //normalize string
        languageKey = languageKey.substr(0, 2).toLowerCase();
    } else {
        //default to american english..
        languageKey = "en";
    }
    return languageKey;
}

export default getPreferredLanguageKey;
