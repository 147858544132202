import angular from "angular";
import Command from "./Command";

/**
 * Base Class for WbsCommands - knows Toast Events and NodeManager
 *
 * @param {Object} $sbErrorPresenter - Class to Present Errors in Toasts or Dialogs
 * @param {Object} wbsApiService - Service that includes the API for WBS
 * @constructs WbsCommand
 * @extends WBS.Command
 * @memberof WBS
 * @interface
 */

function WbsCommand($sbErrorPresenter, wbsApiService) {
    Command.call(this, arguments);

    this.wbsApiService = wbsApiService;
    this.$sbErrorPresenter = $sbErrorPresenter;
}

export default WbsCommand;
