/* eslint-disable angular/log, no-console, no-unused-vars */

/**
 *
 * Virtual Repeat Lazy loader. See -> https://material.angularjs.org/latest/demo/virtualRepeat
 *      - Section: Deferred Loading JS code example
 *
 *  "All that matters is that we implement getItemAtIndex and getLength."
 *  These two methods are handling the md-virtual-repeat content.
 *      # So if you ask for the element at position -> check if we maybe should fetch the next block in advance.
 *
 */

function DynamicVirtualRepeatLoader() {
    this.BLOCK_SIZE = 300;
    this.PRE_LOAD_THRESHOLD = 100;

    this._lastLoadedIndex = 0;

    /**
     * All the elements -> is used to display the elements in the list.
     * @type {Array}
     */
    this._items = [];

    /**
     * The quantity of all elements -> is used to determine the size of the scroll-bar.
     * @type {number}
     */
    this._length = 0;

    this.reload();
}

/**
 * Required method for lazy loading.
 *
 * @return {Number} the length of the virtual list.
 */
DynamicVirtualRepeatLoader.prototype.getLength = function () {
    if (this._items.length > this._length) {
        return this._items.length;
    } else {
        return this._length;
    }
};

/**
 * Required method for lazy loading.
 *
 * @param {Number} index - the item at the specific location.
 *
 * @return {Object} the item at the index..
 */
DynamicVirtualRepeatLoader.prototype.getItemAtIndex = function (index) {
    // check if already fetched, if not -> fetch
    if (index + this.PRE_LOAD_THRESHOLD > this._lastLoadedIndex) {
        this._loadDataAndAddToList();
    }

    // in any case: return the element or null if it is currently loading.
    //
    return this._items[index];
};

/**
 * Reload or init the thing.
 */
DynamicVirtualRepeatLoader.prototype.reload = function () {
    // clean everything.
    this._items = [];
    this._length = 0;
    this._lastLoadedIndex = 0;

    // init the length
    //
    this.loadCount().then(
        function (quantity) {
            this._length = quantity;

            if (this.countReadyFn) {
                this.countReadyFn(this);
            }
        }.bind(this)
    );

    // init the first set of elements
    //
    this._loadDataAndAddToList();
};

DynamicVirtualRepeatLoader.prototype._loadDataAndAddToList = function () {
    var from = this._lastLoadedIndex;
    var to = from + this.BLOCK_SIZE;
    this._lastLoadedIndex = to;

    this.loadData(from, to)
        .then(
            function (listOfNewElements) {
                if (this.onLoadDataFn) {
                    this.onLoadDataFn(listOfNewElements);
                }

                this._items = this._items.concat(listOfNewElements);

                // if all items are locally available
                //  check if there is a handler implemented and call it with all items
                if (this._items.length >= this._length) {
                    if (this.allFetchedFn) {
                        this.allFetchedFn(this._items);
                    }
                }
            }.bind(this)
        )
        .catch(
            function (error) {
                this._lastLoadedIndex = this._lastLoadedIndex - this.BLOCK_SIZE;
                console.error(error); // eslint-disable-line
            }.bind(this)
        );
};

/**
 * Load a subset of the data.
 *
 * @protected
 * @abstract
 * @param {Number} from - load data in range from
 * @param {Number} to - load data in range to
 *
 * @return {$q} - the resolved promise with a data array.
 */
DynamicVirtualRepeatLoader.prototype.loadData = function (from, to) {
    // Has to implemented by the instance
    console.warn("Need overwrite by instance.", from, to); // eslint-disable-line
    return null;
};

/**
 * Load the count of all elements.
 *
 * @protected
 * @abstract
 * @return {$q} - the resolved promise with the number.
 */
DynamicVirtualRepeatLoader.prototype.loadCount = function () {
    // Has to implemented by the instance
    console.warn("Need overwrite by instance."); // eslint-disable-line
    return null;
};

DynamicVirtualRepeatLoader.prototype.countReady = function (fn) {
    this.countReadyFn = fn;
    return this;
};

DynamicVirtualRepeatLoader.prototype.allFetched = function (fn) {
    this.allFetchedFn = fn;
    return this;
};

DynamicVirtualRepeatLoader.prototype.onLoadData = function (fn) {
    this.onLoadDataFn = fn;
    return this;
};

export default DynamicVirtualRepeatLoader;
