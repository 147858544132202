/**
 * #######################
 * ### !!!IMPORTANT!!! ###
 * #######################
 * don't inherit with prototype, this class is designed
 * for multiple inheritance and require a EventDispatcher as super class
 * only inherit by calling the constructor like this:
 * TapableElement.call(this);
 * @constructs TabableElement
 * @abstract
 * @memberof WBS
 */
function TapableElement() {
    this.tapable = true;
}

TapableElement.prototype.tapable = true;

export default TapableElement;
