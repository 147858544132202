"use strict";

const _ = require("lodash");

function StateTransitionPermissionMatrix(
    permissions,
    indexToState,
    stateToIndex
) {
    this.permissions = permissions;
    this.indexToState = indexToState;
    this.stateToIndex = stateToIndex;
}

StateTransitionPermissionMatrix.prototype.isAllowed = function ([
    fromState,
    toState,
]) {
    const fromIndex = this.getIndexFor(fromState);
    const toIndex = this.getIndexFor(toState);

    if (!_.isUndefined(fromIndex) && !_.isUndefined(toIndex)) {
        return !!this.permissions[fromIndex][toIndex];
    }
};

StateTransitionPermissionMatrix.prototype.getEnabledStates = function () {
    const states = new Set();

    this.forEachCell((value, [, columnIndex]) => {
        if (value === 1) {
            states.add(this.getStateFor(columnIndex));
        }
    });

    return Array.from(states);
};

StateTransitionPermissionMatrix.prototype.getIndexFor = function (stateName) {
    return this.stateToIndex.get(stateName);
};

StateTransitionPermissionMatrix.prototype.getStateFor = function (stateIndex) {
    return this.indexToState.get(stateIndex);
};

StateTransitionPermissionMatrix.prototype.and = function (matrix) {
    const combinedPermission = this.mapEachCell(
        (currentCell, [rowIndex, columnIndex]) => {
            const additionalCell = _.get(
                matrix.permissions,
                [rowIndex, columnIndex],
                0
            );

            return currentCell && additionalCell;
        }
    );

    return new StateTransitionPermissionMatrix(
        combinedPermission,
        this.indexToState,
        this.stateToIndex
    );
};

StateTransitionPermissionMatrix.prototype.or = function (matrix) {
    const combinedPermission = this.mapEachCell(
        (currentCell, [rowIndex, columnIndex]) => {
            const additionalCell = _.get(
                matrix.permissions,
                [rowIndex, columnIndex],
                0
            );

            return currentCell || additionalCell;
        }
    );
    return new StateTransitionPermissionMatrix(
        combinedPermission,
        this.indexToState,
        this.stateToIndex
    );
};

StateTransitionPermissionMatrix.prototype.forEachCell = function (callback) {
    this.permissions.forEach((row, rowIndex) => {
        row.forEach((cellValue, columnIndex) => {
            callback(cellValue, [rowIndex, columnIndex]);
        });
    });
};

StateTransitionPermissionMatrix.prototype.mapEachCell = function (callback) {
    return this.permissions.map((row, rowIndex) => {
        return row.map((cellValue, columnIndex) => {
            return callback(cellValue, [rowIndex, columnIndex]);
        });
    });
};

export default StateTransitionPermissionMatrix;
