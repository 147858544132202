import _ from "lodash";

class Project {
    constructor({
        id,
        name,
        code,
        desc,
        created_at,
        language = "EN",
        progress = 0,
        image,
        privileges,
        purpose,
        inspect_app_config,
        scheduling,
        quality_feature_config,
        notification_config,
        subscription,
        currency_code,
        account,
    }) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.desc = desc;
        this.createdTime = created_at;
        this.progress = progress;
        this.language = language.toUpperCase();
        this.inspectCombinedStatusChanges =
            !inspect_app_config.block_agg_progress;
        this.activateSignatureForWorkTeam =
            inspect_app_config.sign_qa_checklist_work_team;
        this.activateSignatureForInspectionTeam =
            inspect_app_config.sign_qa_checklist_inspection_team;
        this.imageId = image.id;
        this.schedulingMode = scheduling.mode;
        this.schedulingState = scheduling.state;
        this.privileges = privileges;
        this.purpose = purpose;
        this.subscription = this.mapSubscription(subscription);
        this.geolocationPermission = inspect_app_config.geolocation_permission;
        this.activateLimitedNoteVisibility = _.get(
            quality_feature_config,
            "limited_note_visibility",
            false
        );
        this.activateDailyUpdate = _.get(
            notification_config,
            "activate_email_daily_update",
            false
        );
        this.activateRealTimeUpdate = _.get(
            notification_config,
            "activate_email_realtime_update",
            false
        );
        this.activateWeeklyReminder = _.get(
            notification_config,
            "activate_email_weekly_reminder",
            false
        );
        this.activateWeeklyLookAhead = _.get(
            notification_config,
            "activate_email_weekly_lookahead",
            false
        );
        this.activateObstructionNotifications = _.get(
            notification_config,
            "activate_email_obstruction_note_notification",
            false
        );
        this.activateQualityNotifications = _.get(
            notification_config,
            "activate_email_quality_issue_notification",
            false
        );
        this.isObstructionConfirmationMandatory = !_.get(
            quality_feature_config,
            "obstruction_workflow.is_optional",
            true
        );
        this.isQualityConfirmationMandatory = !_.get(
            quality_feature_config,
            "quality_workflow.is_optional",
            true
        );

        this.obstructionTeamId = _.get(
            quality_feature_config,
            "obstruction_workflow.default_confirmation_team_id",
            "sb::team.not-set"
        );
        this.qualityTeamId = _.get(
            quality_feature_config,
            "quality_workflow.default_confirmation_team_id",
            "sb::team.not-set"
        );
        this.currencyCode = currency_code;
        this.account = account;
    }

    isInEdgeSchedulingMode() {
        return this.schedulingMode === "EDGES";
    }

    isSchedulingNeeded() {
        return this.schedulingState === "NEEDS_SCHEDULING";
    }

    asServerModel() {
        const serverModel = {
            name: this.name,
            code: this.code,
            desc: this.desc,
            language: this.language.toLowerCase(),
            inspect_app_config: {
                block_agg_progress: !this.inspectCombinedStatusChanges,
                geolocation_permission: this.geolocationPermission,
                sign_qa_checklist_work_team: this.activateSignatureForWorkTeam,
                sign_qa_checklist_inspection_team:
                    this.activateSignatureForInspectionTeam,
            },
            scheduling: {
                mode: this.schedulingMode,
            },
            image: {
                id: this.imageId,
            },
            quality_feature_config: {
                limited_note_visibility: this.activateLimitedNoteVisibility,
            },
            notification_config: {
                activate_email_daily_update: this.activateDailyUpdate,
                activate_email_realtime_update: this.activateRealTimeUpdate,
                activate_email_weekly_reminder: this.activateWeeklyReminder,
                activate_email_weekly_lookahead: this.activateWeeklyLookAhead,
                activate_email_obstruction_note_notification:
                    this.activateObstructionNotifications,
                activate_email_quality_issue_notification:
                    this.activateQualityNotifications,
            },
        };

        serverModel.quality_feature_config.obstruction_workflow = {
            is_optional: !this.isObstructionConfirmationMandatory,
            default_confirmation_team_id: this.obstructionTeamId,
        };
        serverModel.quality_feature_config.quality_workflow = {
            is_optional: !this.isQualityConfirmationMandatory,
            default_confirmation_team_id: this.qualityTeamId,
        };
        serverModel.currency_code = this.currencyCode;

        return serverModel;
    }

    mapSubscription(subscription) {
        return [...subscription.addons, subscription.plan].join(",");
    }
}

export default Project;
