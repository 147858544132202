/**
 *
 * behavior:
 *  - on init:
 *      - URL or local storage will be used to determine the current filter state
 *      - set Overlay to initial value
 *      - set Chips to initial value
 *      - set URL to initial value (if from local store)
 *      - set local store to initial value (if from URL)
 *
 *  - overlay change:
 *      - update URL
 *      - update Chip
 *      - store in local store
 *
 *  - URL change:
 *      - no listening at all
 *      - the initial URL state is parsed into the active filter model
 *
 *  - Chip delete:
 *      - not enabled yet
 *      - Update URL
 *      - store in local store
 *      - Update Overlay
 *
 *  - supported filter
 *    - name
 *    - code
 *    - desc
 *    - progress
 *    - schedule
 *    - notes
 *    - start date
 *    - end data
 *    - workflow name
 *    - structure
 *    - stage + stage progress
 */
import FiltersCtrl from "./sb_filters.controller";
import htmlTemplate from "./sb_filters.html";
export default {
    templateUrl: htmlTemplate,
    bindings: {
        filteredItemCount: "=",
        totalItemCount: "=",
        onChange: "=",
        viewFilters: "=",
        filterPanelRef: "=",
        itemCountTranslation: "@",
        filterButtonTooltipTranslation: "@",
        allowFilterClear: "=",
        allowAllFilterClear: "=",
        validateFilters: "=",
        disableProcessTemplates: "<",
    },
    controllerAs: "filters",
    controller: FiltersCtrl,
};
