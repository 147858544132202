import angular from "angular";
import _ from "lodash";
import addUserHtml from "./add_user_to_team.tmpl.html";

export default angular
    .module("sbApp.sbAddUserDialog", ["sbApp.services"])
    .config(function ($mdDialogProvider) {
        "ngInject";

        $mdDialogProvider.addPreset("addUserToTeam", {
            methods: [
                "title",
                "text",
                "titleValue",
                "selectRoleText",
                "class",
                "ok",
                "cancel",
                "titleIcon",
                "clickOutsideToClose",
                "escapeToClose",
                // data input
                "user", // the pre-selected user
                "teams", // the available teams
                "roles", // the available roles
            ],
            options: function selectDialog($mdDialog) {
                "ngInject";
                return {
                    templateUrl: addUserHtml,
                    controller: AddUserToTeamDialogCtrl,
                    controllerAs: "addUserToTeam",
                    bindToController: true,
                };

                function AddUserToTeamDialogCtrl($sbUsersApi, $q) {
                    "ngInject";
                    /////////////////////
                    //
                    //      Direct variables
                    //
                    /////////////////////

                    /////////////////////
                    //
                    //      SCOPE properties
                    //
                    /////////////////////

                    var vm = this;

                    vm.selectedUsers = [];

                    vm.dismiss = cancelDialog;
                    vm.resolveDelete = resolveDelete;
                    vm.resolve = resolveDialog;
                    vm.suggestUsers = suggestUsers;
                    vm.canSubmit = canSubmit;
                    vm.checkUser = checkUser;
                    vm.isInputInvalid = false;
                    vm.onSelectTeam = onSelectTeam;
                    vm.onSelectRole = onSelectRole;

                    /////////////////////
                    //
                    //      WATCHER
                    //
                    /////////////////////

                    /////////////////////
                    //
                    //      IMPL
                    //
                    /////////////////////

                    _activate();

                    function _activate() {
                        // expect 5 roles here (ADMIN, MANAGER, CONTROLLER, INSPECTOR, REVIEWER)
                        //  -> default should be controller.
                        vm.selectedRole = _findRoleByName("CONTROLLER");

                        // if there is already a user -> select his team and role -> we will modify him
                        if (vm.user) {
                            vm.selectedRole = _findRoleByName(vm.user.role);
                            vm.selectedTeam = _.find(vm.teams, {
                                id: vm.user.teamId,
                            });
                        }
                    }

                    function cancelDialog() {
                        $mdDialog.cancel();
                    }

                    function resolveDialog() {
                        $mdDialog.hide({
                            before: _asBeforeModel(vm.user),
                            after: _asAfterModel(
                                vm.user,
                                vm.selectedTeam,
                                vm.selectedRole,
                                vm.selectedUsers
                            ),
                        });
                    }

                    function resolveDelete(user) {
                        $mdDialog.hide({
                            before: _asBeforeModel(user),
                        });
                    }

                    function canSubmit(user, users) {
                        if (angular.isObject(user)) {
                            return !_.isEqual(
                                _asBeforeModel(vm.user),
                                _asAfterModel(
                                    vm.user,
                                    vm.selectedTeam,
                                    vm.selectedRole
                                )
                            );
                        }
                        if (angular.isArray(users)) {
                            return users.length > 0;
                        }
                    }

                    /**
                     * Determine from the input how the "before" model will look like
                     * The before model is representing the state of the model before
                     * editing/creating/deleting. (e.g. if you create: the before state
                     * is an empty object)
                     *
                     * @param {Object|undefined} user
                     * @returns {*}
                     * @private
                     */
                    function _asBeforeModel(user) {
                        if (angular.isObject(user)) {
                            return {
                                userEmail: user.email,
                                userId: user.username,
                                teamId: user.teamId,
                                roleId: user.roleId,
                                roleName: user.role,
                            };
                        } else {
                            return undefined;
                        }
                    }

                    /**
                     * Determine from the input how the "after" model will look like
                     * The after model is representing the state of the model after
                     * editing/creating/deleting. (e.g. if you delete: the after state
                     * is an empty object)
                     *
                     * @param {Object|undefined} user
                     * @param {Object} team
                     * @param {Object} role
                     * @param {Array} [users]
                     * @returns {*}
                     * @private
                     */
                    function _asAfterModel(user, team, role, users) {
                        if (angular.isObject(user)) {
                            return {
                                userEmail: user.email,
                                teamId: team.id,
                                roleName: role.ROLE_NAME,
                                userId: user.username,
                            };
                        } else if (angular.isArray(users)) {
                            return users.map(function (newUser) {
                                return {
                                    userEmail: newUser.EMAIL,
                                    roleName: role.ROLE_NAME,
                                    userId: newUser.USER_NAME,
                                };
                            });
                        } else {
                            return undefined;
                        }
                    }

                    /**
                     * Search the available users based on the provided search text
                     *
                     * @param  {string} searchText  - The key used to search on the available users
                     * @return {Promise}            - An array containing the users that match the key
                     */
                    function suggestUsers(searchText) {
                        if (!searchText) {
                            return $q.resolve([]);
                        }
                        return $sbUsersApi
                            .getCollection(searchText)
                            .then(({ users }) => {
                                return users.map((user) => ({
                                    EMAIL: user.email,
                                }));
                            })
                            .then((users) =>
                                users.length
                                    ? _.differenceBy(
                                          users,
                                          vm.selectedUsers,
                                          "EMAIL"
                                      )
                                    : isEmailValid(searchText)
                                      ? [{ EMAIL: searchText }]
                                      : []
                            );
                    }

                    function isEmailValid(email) {
                        return /\S+@\S+\.\S+/.test(email);
                    }

                    /**
                     * Checks if the last chip added corresponds with a user.
                     * If the chip is a string, then we remove it from the list.
                     * @param chip
                     */
                    function checkUser(chip) {
                        const isChipAString = _.isString(chip);
                        const isChipInUsersList = vm.selectedUsers.some(
                            (user) => user.EMAIL === chip
                        );

                        if (!isChipAString) {
                            vm.isInputInvalid = false;
                            return;
                        }

                        if (isChipInUsersList) {
                            vm.isInputInvalid = false;
                            vm.selectedUsers.pop();
                            return;
                        }

                        if (isEmailValid(chip)) {
                            vm.isInputInvalid = false;
                            vm.selectedUsers[vm.selectedUsers.length - 1] = {
                                EMAIL: chip,
                            };
                        } else {
                            vm.isInputInvalid = true;
                            vm.selectedUsers.pop();
                        }
                    }

                    function _findRoleByName(name) {
                        return _.find(vm.roles, {
                            ROLE_NAME: name,
                        });
                    }

                    function onSelectTeam(team) {
                        vm.selectedTeam = team;
                    }

                    function onSelectRole(role) {
                        vm.selectedRole = role;
                    }
                }
            },
        });
    });
