import angular from "angular";
import htmlTemplate from "./pending_invite_card.html";
import componentCtrl from "./pending_invite_card.controller";
import "common/services/services";

export default angular
    .module("sbApp.common.components.pendingInviteCard", ["sbApp.services"])
    .component("sbPendingInviteCard", {
        templateUrl: htmlTemplate,
        bindings: {
            project: "<",
            isLoading: "<",
            onAccept: "&",
            onDecline: "&",
        },
        controller: componentCtrl,
    });
