import _ from "lodash";

export default function VisualizationsService(
    $sbColor,
    $filter,
    $rootScope,
    $sbSketchVisualization,
    $sbSketchGenerator,
    $sbTemplate,
    $q,
    MODES,
    SABLONO_STATES
) {
    "ngInject";

    var stageColors = $sbColor.color.visualization.stages;

    var STAGE_COLORS = {
        notApplicable: stageColors.NOT_APPLICABLE,
        notStartedStroke: stageColors.NOT_APPLICABLE, // just to pick up a grey color, has nothing to do with the actual state
        notStarted: stageColors.NOT_STARTED,
        available: stageColors.STARTED_OR_AVAILABLE,
        started: stageColors.STARTED_OR_AVAILABLE,
        waitingForConfirmation:
            stageColors.COMPLETED_OR_WAITING_FOR_CONFIRMATION,
        completed: stageColors.COMPLETED_OR_WAITING_FOR_CONFIRMATION,
        rejected: stageColors.REJECTED,
    };

    // set default strategy
    //
    var strategyService = $sbSketchVisualization;

    var service = {
        determineStrategyByState: determineStrategyByState,
        loadVisualization: loadVisualization,
        fetchContentByMode: fetchContentByMode,
        setContentByMode: setContentByMode,
        updateColorPropertyForComponentsBy: updateColorPropertyForComponentsBy,
        STAGE_COLORS: STAGE_COLORS,
    };

    $rootScope.$on("$stateChangeSuccess", function (event, toState) {
        if (
            toState.name === SABLONO_STATES.visualizationSketch ||
            toState.name === SABLONO_STATES.visualizationGenerated
        ) {
            service.determineStrategyByState(toState.name);
        }
    });

    function determineStrategyByState(stateName) {
        switch (stateName) {
            case SABLONO_STATES.visualizationGenerated: {
                strategyService = $sbSketchGenerator;
                break;
            }
            case SABLONO_STATES.visualizationSketch: {
                strategyService = $sbSketchVisualization;
                break;
            }
            default: {
                var err = new Error("IllegalArgumentException");
                err.message =
                    "The " +
                    stateName +
                    " is not a valid argument. Only " +
                    SABLONO_STATES.visualizationGenerated +
                    " and " +
                    SABLONO_STATES.visualizationGenerated +
                    " are allowed.";
                throw err;
            }
        }
    }

    function loadVisualization(svgKey) {
        // delegate retrieval of SVG to chosen service
        //
        return strategyService.loadVisualization(svgKey);
    }

    /**
     * Gets all the content to be displayed in a given svg sketch, transforming and enriching
     * the data to get all necessary properties. It returns the components with color information
     * and the legend with all the colors and entries for a given mode.
     *
     * @param {string} svgId
     * @param {object} mode
     * @returns {Promise}
     */
    function fetchContentByMode(svgId, mode) {
        return strategyService
            .fetchContentByMode(svgId, mode.name)
            .then(function (deliverables) {
                return setContentByMode(deliverables, mode);
            });
    }

    /**
     * Transforming and enriching the data to get all necessary properties.
     * It returns the components with color information and the legend with all the colors and entries for a given mode.
     *
     * @param {Array<object>} deliverables
     * @param {object} mode
     * @return {*}
     */
    function setContentByMode(deliverables, mode) {
        if (deliverables && deliverables.length) {
            const coloredDeliverables = deliverables.map(
                _addColorsToDeliverable
            );

            return $sbTemplate.getTemplates().then((templates) => {
                const legend = _getLegendFromComponents(
                    coloredDeliverables,
                    mode,
                    templates
                );
                return {
                    components: coloredDeliverables,
                    legend: legend,
                };
            });
        } else {
            return $q.resolve({
                components: [],
                legend: [],
            });
        }
    }

    /**
     * Loop through all components to create a legend with all the colors that have at least
     * one occurrence in the components array.
     *
     * @param {array} components
     * @param {object} mode
     * @param {array} templates
     * @returns {*}
     * @private
     */
    function _getLegendFromComponents(components, mode, templates) {
        let legend;

        // special case for this mode
        //
        if (mode === MODES.BROWSE_ACTIVITY) {
            legend = _createLegendItemsForBrowseStatusMode(components);

            return removeDuplicateEntriesFrom(legend);
        } else {
            // all the other modes based on the components
            //
            legend = _createLegendItemsFrom(components, mode);
        }

        // On stage mode
        if (mode === MODES.STAGE) {
            return _sortStageModeEntriesAndRemoveDuplicates(legend, templates);
        }

        // Return the legend with unique entries and naturally sorted
        //
        return sortEntriesAndRemoveDuplicates(legend);
    }

    /**
     * Determine legend entries for components by currently active mode.
     *
     * @param components
     * @param mode
     * @returns {Array}
     * @private
     */
    function _createLegendItemsFrom(components, mode) {
        var legend = [];

        components.forEach(function (component) {
            var newEntry = {};

            // If it's the default color, overwrite with the no value entry
            //
            if (_isDefaultColor(component[mode.colorProperty])) {
                newEntry = _createLegendEntryForNoValue(component, mode);
            } else {
                switch (mode) {
                    case MODES.STAGE:
                        newEntry = _createLegendEntryForStageMode(
                            component,
                            mode.colorProperty
                        );

                        // Hatch entries
                        //
                        if (component.HATCH_COLOR) {
                            legend.push(
                                _createLegendEntryForHatchColor(component)
                            );
                        }

                        // Cross hatch entries
                        //
                        if (component.CROSS_HATCH_COLOR) {
                            legend.push(
                                _createLegendEntryForCrossHatchColor(component)
                            );
                        }
                        break;
                    //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
                    // case MODES.CURRENT:
                    //     newEntry = _createLegendEntryForCurrentMode(
                    //         component,
                    //         mode.colorProperty
                    //     );
                    //     break;
                    // case MODES.PROGRESS:
                    //     newEntry = _createLegendEntryForProgressMode(
                    //         component,
                    //         mode.colorProperty
                    //     );
                    //     break;
                }
            }

            // Push the entry into the legend
            //
            legend.push(newEntry);

            // Stroke entries
            //
            if (component.STROKE_COLOR) {
                legend.push(_createLegendEntryForStrokeColor(component));
            }
        });

        return legend;
    }

    /**
     * Create legend items for stage reached mode.
     *
     * @param components
     * @returns {*[]}
     * @private
     */
    function _createLegendItemsForBrowseStatusMode(components) {
        var legend = [
            {
                color: STAGE_COLORS.notApplicable,
                key: "VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_APPLICABLE",
            },
            {
                color: STAGE_COLORS.notStarted,
                overrideWhiteColor: STAGE_COLORS.notApplicable,
                key: "VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_STARTED",
            },
            {
                color: STAGE_COLORS.available,
                hatch: true,
                key: "VISUALIZATION_LEGEND_ENTRY_STAGE_AVAILABLE",
            },
            {
                color: STAGE_COLORS.started,
                key: "VISUALIZATION_LEGEND_ENTRY_STAGE_STARTED",
            },
            {
                color: STAGE_COLORS.waitingForConfirmation,
                hatch: true,
                key: "_WAITING_FOR_CONFIRMATION",
            },
            {
                color: STAGE_COLORS.rejected,
                crossHatch: true,
                key: "_REJECTED",
            },
            {
                color: STAGE_COLORS.completed,
                key: "VISUALIZATION_LEGEND_ENTRY_STAGE_COMPLETED",
            },
        ];

        components.forEach(function (component) {
            if (component.STROKE_COLOR) {
                legend.push(_createLegendEntryForStrokeColor(component));
            }
        });

        return legend;
    }

    function _createLegendEntryForStageMode(component, colorProperty) {
        const newEntry = {
            color: component[colorProperty],
            templateId: component.TEMPLATE_ID,
        };

        if (component.STAGE) {
            newEntry.topologicalIndex = component.STAGE.TOPOLOGICAL_INDEX + "";
            newEntry.key = component.STAGE.STATE_NAME;
        }

        return newEntry;
    }

    //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
    // function _createLegendEntryForCurrentMode(component, colorProperty) {
    //     var newEntry = {
    //         color: component[colorProperty],
    //     };
    //     if (component.CURRENT) {
    //         newEntry.key = component.CURRENT.TEAM_NAME;
    //     }
    //     return newEntry;
    // }
    // function _createLegendEntryForProgressMode(component, colorProperty) {
    //     return {
    //         color: component[colorProperty],
    //         key: $sbColor.getLegendKeyFromColor(component[colorProperty]),
    //     };
    // }

    function _createLegendEntryForNoValue(component, mode) {
        return {
            color: STAGE_COLORS.notStartedStroke,
            stroke: true,
            key: mode.noValueKey,
        };
    }

    function _createLegendEntryForStrokeColor(component) {
        return {
            color: component.STROKE_COLOR,
            stroke: true,
            key: $sbColor.getLegendKeyFromColor(component.STROKE_COLOR),
        };
    }

    function _createLegendEntryForHatchColor() {
        return {
            color: $sbColor.color.STAGE_PATTERN_COLOR,
            hatch: true,
            key: "_WAITING_FOR_CONFIRMATION",
        };
    }

    function _createLegendEntryForCrossHatchColor() {
        return {
            color: $sbColor.color.STAGE_PATTERN_COLOR,
            crossHatch: true,
            key: "_REJECTED",
        };
    }

    /**
     * Determines whether a color is the default color
     * @param color
     * @returns {boolean}
     * @private
     */
    function _isDefaultColor(color) {
        return color === STAGE_COLORS.notStarted;
    }

    /**
     * Adds color properties to a given deliverable
     *
     * @param {Object} deliverable
     * @returns {Object} component - with updated properties
     * @private
     */
    function _addColorsToDeliverable(deliverable) {
        // Remove hatch and stroke styles applied from the last selected mode
        //
        deliverable = _cleanStrokeAndHatchColorsFrom(deliverable);

        // All available modes
        //
        //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
        // deliverable[MODES.PROGRESS.colorProperty] = $sbColor.color.fromStatus(
        //     deliverable.IS_BEHIND
        // );

        if (deliverable.STAGE) {
            deliverable[MODES.STAGE.colorProperty] =
                $sbColor.toHexColor(deliverable.STAGE.COLOR) ||
                STAGE_COLORS.notStarted;
        } else {
            deliverable[MODES.STAGE.colorProperty] = STAGE_COLORS.notStarted;
        }

        //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
        // if (deliverable.HAS_PROGRESS_REPORTED && deliverable.CURRENT) {
        //     deliverable[MODES.CURRENT.colorProperty] =
        //         $sbColor.toHexColor(deliverable.CURRENT.TEAM_COLOR) ||
        //         STAGE_COLORS.notStarted;
        // } else {
        //     deliverable[MODES.CURRENT.colorProperty] = STAGE_COLORS.notStarted;
        // }

        // Stroke colors
        //
        if (deliverable.CLAIM_QUANTITY) {
            deliverable.STROKE_COLOR = $sbColor.color.outline.withClaims;
        }
        if (deliverable.OBSTRUCTION_QUANTITY) {
            deliverable.STROKE_COLOR = $sbColor.color.outline.withObstructions;
        }

        // Requires HATCH pattern for confirmation
        //
        if (
            deliverable.STAGE &&
            deliverable.STAGE.STATE === MODES.STAGE.requiresConfirmation
        ) {
            deliverable.HATCH_COLOR = $sbColor.color.WAITING_FOR_CONFIRMATION;
        }

        // Requires HATCH pattern for confirmation
        //
        if (
            deliverable.STAGE &&
            deliverable.STAGE.STATE === MODES.STAGE.rejected
        ) {
            deliverable.CROSS_HATCH_COLOR = $sbColor.color.behindSchedule;
        }

        return deliverable;
    }

    function _cleanStrokeAndHatchColorsFrom(component) {
        return _clearParamsFrom(component, [
            "STROKE_COLOR",
            "HATCH_COLOR",
            "CROSS_HATCH_COLOR",
        ]);
    }

    /**
     * Delete all provided parameters from the given object.
     *
     * @param {Object} object
     * @param  {string[]} params
     * @returns {Object}
     * @private
     */
    function _clearParamsFrom(object, params) {
        return _.pick(object, _.difference(_.keys(object), params));
    }

    function updateColorPropertyForComponentsBy(stageId, components) {
        return components.map(function setColorsOn(component) {
            var activity = _.find(component.ACTIVITIES, [
                "TEMPLATE_ID",
                stageId,
            ]);

            _setComponentColorsBasedOnActivityState(component, activity);

            return component;
        });
    }

    function _setComponentColorsBasedOnActivityState(component, activity) {
        var colorProperty = MODES.BROWSE_ACTIVITY.colorProperty;

        if (activity) {
            var STATE_TO_COLOR = {
                NOT_STARTED: STAGE_COLORS.notStarted,
                AVAILABLE: STAGE_COLORS.available,
                STARTED: STAGE_COLORS.started,
                DONE: STAGE_COLORS.completed,
                CONFIRMED: STAGE_COLORS.completed,
                WAITING_FOR_CONFIRMATION: STAGE_COLORS.waitingForConfirmation,
                REJECTED: STAGE_COLORS.rejected,
            };

            component[colorProperty] = STATE_TO_COLOR[activity.STATE];
            _setHatchColorsBasedOnActivityState(
                component,
                colorProperty,
                activity.STATE
            );
        } else {
            component[colorProperty] = STAGE_COLORS.notApplicable;
            _unsetHatchColors(component);
        }

        return component;
    }

    function _setHatchColorsBasedOnActivityState(
        component,
        colorProperty,
        activityState
    ) {
        switch (activityState) {
            case "WAITING_FOR_CONFIRMATION": // fall through
            case "AVAILABLE":
                component.HATCH_COLOR = component[colorProperty];
                component.CROSS_HATCH_COLOR = null;
                break;
            case "REJECTED":
                component.HATCH_COLOR = null;
                component.CROSS_HATCH_COLOR = component[colorProperty];
                break;
            default:
                _unsetHatchColors(component);
        }
    }

    function _unsetHatchColors(component) {
        component.HATCH_COLOR = null;
        component.CROSS_HATCH_COLOR = null;
    }

    function sortEntriesAndRemoveDuplicates(legend) {
        legend = removeDuplicateEntriesFrom(legend);
        return $filter("naturalSort")(legend, "key");
    }

    function _sortStageModeEntriesAndRemoveDuplicates(legend, templates) {
        legend = removeDuplicateEntriesFrom(legend);

        return $filter("naturalSort")(
            _addStageModeHeadersToLegend(legend, templates),
            "templateId",
            "topologicalIndex"
        );
    }

    function _addStageModeHeadersToLegend(legend, templates) {
        const MINIMUM_TOPOLOGICAL_INDEX = "0.5";
        const uniqueTemplateIDs = _findUniqueTemplateIDs(legend);

        // When one template is present do not display template name in legend
        if (uniqueTemplateIDs.length <= 1) {
            return legend;
        }

        for (let templateId of uniqueTemplateIDs) {
            let template = getTemplate(templateId, templates);
            legend.push({
                templateId: templateId,
                key: template.NAME,
                topologicalIndex: MINIMUM_TOPOLOGICAL_INDEX,
                header: true,
            });
        }

        legend.push({
            key: "LEGEND_STAGE_MODE_DEFAULTS",
            header: true,
            topologicalIndex: MINIMUM_TOPOLOGICAL_INDEX,
        });

        return legend;
    }

    function getTemplate(templateId, templates) {
        return _.find(templates, { ID: templateId });
    }

    function _findUniqueTemplateIDs(legend) {
        const templateIds = legend
            .map(({ templateId }) => templateId)
            .filter(_.isString);
        return _.without(_.uniq(templateIds), undefined);
    }

    function removeDuplicateEntriesFrom(legend) {
        return _.uniqWith(legend, _.isEqual);
    }

    return service;
}
