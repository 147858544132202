import angular from "angular";
import _ from "lodash";

export default function PaginatePageSizeDirective() {
    return {
        restrict: "A",
        scope: {
            onPageSizeChange: "&sbPaginatePageSize",
        },
        link: function postLink(scope, element, attr, ctrl) {
            var THROTTLE_WAIT_IN_MS = 50;

            ctrl.setPageSizeChangeCallback(scope.onPageSizeChange);

            var _unwatch = scope.$watch(
                _elementWidth,
                _.throttle(_onElementWidthChange, THROTTLE_WAIT_IN_MS)
            );

            scope.$on("$destroy", function _onScopeDestroyed() {
                _unwatch();
            });

            function _elementWidth() {
                return element[0].clientWidth;
            }

            function _onElementWidthChange(newWidth) {
                var newPageSize = ctrl.calculatePageSizeFrom(newWidth);

                if (ctrl.hasPageSizeChanged(newPageSize)) {
                    ctrl.notifyAboutPageSizeChanges(newPageSize);
                    ctrl.setPageSize(newPageSize);
                }
            }
        },
        controller: function PaginatePageSizeCtrl($log) {
            "ngInject";

            var vm = this;

            var SINGLE_ELEMENT_WIDTH_IN_PIXEL = 180 + 16 + 16; // content + margin left + margin right
            var LEFT_PANE_OFFSET_IN_PIXEL = 300;

            var currentPageSize = 0;
            var onPageSizeChange = angular.noop;

            vm.setPageSizeChangeCallback = setPageSizeChangeCallback;
            vm.setPageSize = setPageSize;
            vm.calculatePageSizeFrom = calculatePageSizeFrom;
            vm.notifyAboutPageSizeChanges = notifyAboutPageSizeChanges;
            vm.hasPageSizeChanged = hasPageSizeChanged;

            function setPageSizeChangeCallback(fnCallback) {
                if (angular.isFunction(fnCallback)) {
                    onPageSizeChange = fnCallback;
                } else {
                    $log.error(
                        "PaginatePageSizeDirective - Attached callback is not a function"
                    );
                }
            }

            function setPageSize(newPageSize) {
                currentPageSize = newPageSize;
            }

            function hasPageSizeChanged(newPageSize) {
                return currentPageSize !== newPageSize;
            }

            function calculatePageSizeFrom(width) {
                return Math.floor(
                    (width - LEFT_PANE_OFFSET_IN_PIXEL) /
                        SINGLE_ELEMENT_WIDTH_IN_PIXEL
                );
            }

            function notifyAboutPageSizeChanges(newPageSize) {
                onPageSizeChange({
                    changes: {
                        pageSize: {
                            previousValue: currentPageSize,
                            currentValue: newPageSize,
                        },
                    },
                });
            }
        },
    };
}
