import DeliverableSetConfigurationCtrl from "./deliverable_set_configuration.controller";
import dialogTemplate from "./deliverable_set_configuration.html";

export default function DeliverableSetConfigurationConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("deliverableSetConfiguration", {
        methods: ["class"],
        options: function () {
            return {
                controller: DeliverableSetConfigurationCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
