import angular from "angular";
import _ from "lodash";

export default function (
    $sbDeliverableProcessApi,
    $sbProject,
    $sbCurrentProject,
    $sbDomain,
    $sbTeam,
    $sbMembership,
    SbActivity,
    $q
) {
    "ngInject";

    return {
        loadWorkflow: loadWorkflow,
        createSummaryFromWorkflow: createSummaryFromContentList,
        __getWorkflowActivities: _getWorkflowActivities,
        __splitRootFromRest: _splitRootFromRest,
    };

    function loadWorkflow(deliverable) {
        if (!deliverable) {
            return $q.reject("No deliverable specified!");
        }

        let mapOfUsers = new Map();

        return $q
            .all([
                $sbDeliverableProcessApi.getCollection(deliverable.id),
                $sbTeam.getUsersByProject($sbCurrentProject.pick("id")),
            ])
            .then(([{ components }, users]) => {
                users.forEach((user) => {
                    mapOfUsers.set(user.username, user);
                });
                return components;
            })
            .then(_aggregateDeliverableContentForParents("PARENT_ID", "ID"))
            .then(_splitRootFromRest(deliverable.id))
            .then(_getWorkflowActivities)
            .then(function ({ content, deliverable }) {
                const activities = _collectActivities(content);
                activities.forEach((activity) => {
                    if (activity.state.changedBy) {
                        activity.state.changedByUser = mapOfUsers.get(
                            activity.state.changedBy
                        );
                    }
                });
                _calculateAndSetPrivileges(
                    activities,
                    deliverable,
                    $sbMembership.current()
                );
                return {
                    content,
                    deliverable,
                };
            });
    }

    function _getWorkflowActivities({ content, deliverable }) {
        if (content && angular.isArray(content)) {
            return $sbProject.getCalendar().then(function (calendar) {
                const newContent = $sbDomain.workflowFactory.create(content, {
                    timezone: calendar.TIMEZONE.name,
                });
                return {
                    content: newContent,
                    deliverable,
                };
            });
        } else {
            return $q.resolve({
                content: [],
                deliverable,
            });
        }
    }

    function _collectActivities(components) {
        return components.reduce(function (activities, component) {
            if (component instanceof SbActivity) {
                activities.push(component);
                return activities;
            } else {
                return activities.concat(component.getActivities());
            }
        }, []);
    }

    function _calculateAndSetPrivileges(
        activities,
        odataDeliverable,
        userMembership
    ) {
        activities.forEach(function (activity) {
            if (
                $sbMembership.canReportProgressFor(
                    odataDeliverable,
                    activity,
                    userMembership
                )
            ) {
                activity.accessRole = userMembership.role.mask;
                activity.permission = SbActivity.READ_WRITE;
            } else {
                activity.accessRole = "1";
                activity.permission = SbActivity.READ_ONLY;
            }
        });
    }

    /**
     * Create a function to remove a specfic node id from a list of nodes.
     *
     * e.g. _splitRootFromRest('A')([{A},{B},{C}]) -> would remove {A}
     *  while {A}, {B} and {C} are objects with the ID A,B or C
     *
     * @param {string} rootId
     * @returns {Function} the function takes a list and
     *                       returns an object having the list with one element removed and the removed element
     * @private
     */
    function _splitRootFromRest(rootId) {
        // return the target function
        return function (contentList) {
            let deliverable;
            const content = contentList.filter(function (component) {
                if (component.PARENT_ID === rootId) {
                    component.PARENT_ID = undefined;
                }
                if (component.ID === rootId) {
                    deliverable = component;
                }
                return component.ID !== rootId;
            });
            return {
                content,
                deliverable,
            };
        };
    }

    function createSummaryFromContentList(list) {
        return list.reduce(
            function (summary, entry) {
                if (entry.CATEGORY === "ACTIVITY") {
                    summary.all++;
                    switch (entry.PROGRESS) {
                        case 0:
                            summary.todo++;
                            break;
                        case 100:
                            summary.done++;
                            break;
                        default:
                            summary.inProgress++;
                            break;
                    }
                }
                return summary;
            },
            {
                todo: 0,
                done: 0,
                inProgress: 0,
                all: 0,
            }
        );
    }

    /**
     * Create a function to aggregate the activity information to groups and deliverables in
     * a deliverable workflow.
     *
     * @param {String}  parentKey   - The name of the property that is referencing the parent
     * @param {String}  key         - The name of the property that is used to identify an element
     * @returns {Function}
     * @private
     */
    function _aggregateDeliverableContentForParents(parentKey, key) {
        return function (list) {
            // create a mapping ID -> all dependant activities
            //
            var parentIdToActivities = _createMapParentToAllChildren(
                list,
                parentKey,
                key
            );

            // aggregate with conditions and apply to thing!
            //
            _.forIn(parentIdToActivities, function (_activities, contentId) {
                // filter all leaf components that are not "Activities"
                //
                var activities = _activities.filter(_isActivity);
                // if there are no valid activities left.. leave here.
                if (activities.length === 0) {
                    return;
                }

                var contentGroup = _.find(
                    list,
                    _.matchesProperty(key, contentId)
                );
                if (contentGroup) {
                    contentGroup.SD = _.min(
                        _.map(activities, _.property("SD"))
                    );
                    contentGroup.CD = _.max(
                        _.map(activities, _.property("CD"))
                    );
                    contentGroup.PROGRESS =
                        _.sum(_.map(activities, _.property("PROGRESS"))) /
                        activities.length;
                }
            });

            return list;
        };
    }

    function _isActivity(component) {
        return component.CATEGORY === "ACTIVITY";
    }

    /**
     * Create a mapping that is linking every element of a list to a list of all leafs that are connected
     * to this element via any path in the tree
     *
     * Example:
     *    A
     *  B   C
     * D   E  F
     *
     * A -> [D,E,F]
     * B -> [D]
     * C -> [E,F]
     *
     * Use case: You want to aggregate information from leafs to their parents.
     *
     * @param {Array}   list        - The list of elements that are part of the tree.
     * @param {String}  parentKey   - The name of the property that is referencing the parent
     * @param {String}  key         - The name of the property that is used to identify an element
     * @returns {*}
     * @private
     */
    function _createMapParentToAllChildren(list, parentKey, key) {
        // inti the mapping Parent Key -> Array of list elements
        var parentMap = list.reduce(function (map, child) {
            if (map[child[parentKey]]) {
                map[child[parentKey]].push(child);
            } else {
                map[child[parentKey]] = [child];
            }
            return map;
        }, {});

        // also long as we modify the mapping keep going.
        var changed = true;
        while (changed) {
            changed = false;
            _.forIn(parentMap, function (tmpMap, parentId) {
                parentMap[parentId] = tmpMap.reduce(function (all, child) {
                    // if the child is also a parent for others. Replace this child by all its other children
                    if (parentMap[child[key]]) {
                        changed = true;
                        return all.concat(parentMap[child[key]]);
                    } else {
                        all.push(child);
                        return all;
                    }
                }, []);
            });
        }

        return parentMap;
    }
}
