import profileHtml from "./profile.html";

export default function ($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.profile", {
        templateUrl: profileHtml,
        controller: "UserProfileCtrl",
        controllerAs: "userProfile",
        url: "/profile",
        data: {
            title: "SECTION_USER_PROFILE_TITLE",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_PROFILE_PAGE);
        },
    });
}
