export default function authCtrl($sbExternalService, $sbAuthStore, $state) {
    "ngInject";

    var vm = this;

    $sbExternalService
        .authenticationService()
        ?.observeSession()
        .subscribe((sessionDetails) => {
            if (sessionDetails && sessionDetails.user) {
                $sbAuthStore.storeIdentity({
                    username: sessionDetails.user.username,
                    mail: sessionDetails.user.email,
                    name: sessionDetails.user.name,
                    company: sessionDetails.user.company,
                    displayName: sessionDetails.user.displayName,
                    initials: sessionDetails.user.initials,
                });

                return $state.go(
                    "sablono.projectSelection",
                    {},
                    {
                        location: "replace",
                    }
                );
            }
        });
}
