import htmlTemplate from "./checklist_template_overview.html";
import ChecklistTemplateOverviewCtrl from "./checklist_template_overview.controller";
export default {
    templateUrl: htmlTemplate,
    bindings: {
        checklistTemplate: "=",
        onNameBlur: "&",
        onNameBlurInvalid: "&",
    },
    controllerAs: "overview",
    controller: ChecklistTemplateOverviewCtrl,
};
