export default class DeliverableNotesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns notes with photos attached a deliverable.
     *
     * @param deliverableId {string}    UUID
     */
    getCollection(deliverableId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/deliverables/${deliverableId}/notes`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }
}
