export default class ProcessTemplateJobsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * POST request to create a process template job with given type and definition
     *
     * @param projectId {string}            UUID
     *
     * @param body {Object}                 Request body
     * @param body.type {string}            Type of job
     * @param body.configuration {Object}   Detailed description about the job setup
     *
     * @returns {Promise<{}>}
     */
    create(projectId, { type, configuration }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/process-templates/jobs`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: {
                type,
                configuration,
            },
        });
    }

    /**
     * Create a 'CLONE' process template job
     *
     * @param projectId {string}              UUID
     *
     * @param body {Object}                   Request body
     * @param body.from_process_template_id {string}    UUID of the process template that will be cloned
     * @param body.prefix {string}                      Prefix attached to the name coming from source process template
     *
     * @returns {Promise<{}>}
     */
    createCloneJob(
        projectId,
        { from_process_template_id, prefix, copyTeamsAndQa }
    ) {
        return this.create(projectId, {
            type: "clone",
            configuration: {
                from_process_template_id,
                prefix,
                team_assignments: copyTeamsAndQa,
                checklist_templates: copyTeamsAndQa,
            },
        });
    }
}
