export default class ChecklistHandoverWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the checklist handover pdf for a pair of checklists.
     *
     * @param activityId {string}    UUID
     * @param checklistIds {number[]}  The unique identifier of the checklists you are requesting a resource for.
     * @param options {Object}
     * @param options.language {string}
     */
    printAsPdf(activityId, checklistIds, { language }) {
        const endpointUrl = `/api/${
            this.API_VERSION
        }/api/activities/${activityId}/checklist-handover/${checklistIds.join(
            ".."
        )}`;
        return this.$sbRequest.pdf({
            url: endpointUrl,
            method: "GET",
            query: {
                language,
            },
            header: {
                "Accept-Language": language,
            },
        });
    }
}
