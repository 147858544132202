import angular from "angular";

export default angular
    .module("sbApp.sbTreeRow", [])
    .directive("sbTreeRow", function sbTreeRowDirective($compile) {
        return {
            restrict: "E",
            scope: {
                node: "=",
            },
            link: function (scope, element, attrs) {
                element.html(attrs.template);
                $compile(element.contents())(scope);
            },
        };
    });
