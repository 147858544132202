import angular from "angular";
import html from "./reset_activity.html";
import controller from "./reset_activity.controller";

export default angular
    .module("sbApp.sbResetActivityDialog", [])
    .config(registerDialog)
    .factory("$sbResetActivityDialog", registerService());

function registerDialog($mdDialogProvider) {
    $mdDialogProvider.addPreset("resetActivity", {
        methods: [
            "title",
            "textContent",
            "projectId",
            "activityIds", // optional
            "activityTemplates", // optional
            "deliverableSet", // optional
        ],
        options: function resetActivityDialog() {
            return {
                templateUrl: html,
                controller: controller,
                controllerAs: "dialog",
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
            };
        },
    });
}

function registerService() {
    return function sbResetActivityDialogService($mdDialog) {
        "ngInject";

        return {
            show: _show,
        };

        function _show(options) {
            return $mdDialog.show(
                $mdDialog
                    .resetActivity()
                    .title(options.title)
                    .textContent(options.description)
                    .projectId(options.projectId)
                    .activityIds(options.activityIds)
                    .activityTemplates(options.activityTemplates)
                    .deliverableSet(options.deliverableSet)
            );
        }
    };
}
