import CostGroupStage from "./cost_group_stage.class";
import moment from "moment";

function CombinedCostGroupStage(id, name, weight) {
    CostGroupStage.call(this, id, name, weight);

    // of type CostGroupStage
    this.childCostGroupStages = [];

    this.processTemplateId = null;
    this.processTemplateName = "COMMERCIAL_PAGE_SECTION_COMBINED_STAGES";
}

CombinedCostGroupStage.prototype = Object.create(CostGroupStage.prototype);

CombinedCostGroupStage.prototype.setChildCostGroupStages = function (
    childCostGroupStages
) {
    const combinedStats = childCostGroupStages.reduce(
        (obj, stage) => {
            Object.keys(obj).forEach((key) => {
                obj[key] += stage[key];
            });
            return obj;
        },
        {
            inProgress: 0,
            isWaitingForConfirmation: 0,
            isDone: 0,
            total: 0,
        }
    );

    this.inProgress = combinedStats.inProgress;
    this.isWaitingForConfirmation = combinedStats.isWaitingForConfirmation;
    this.isDone = combinedStats.isDone;
    this.total = combinedStats.total;

    this.isConfirmable = childCostGroupStages.some(
        (stage) => stage.isConfirmable
    );

    this.childCostGroupStages = childCostGroupStages;
    this.asReportedAt = moment.max(
        childCostGroupStages
            .map((stage) => stage.asReportedAt)
            .filter((date) => moment.isMoment(date))
    );
};

export default CombinedCostGroupStage;
