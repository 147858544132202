import moment from "moment";

export default function ($sbEulaApi) {
    "ngInject";
    var exports = {
        getEula,
        acceptEula,
    };
    return exports;

    function getEula() {
        return $sbEulaApi.get().then((eulaApi) => {
            return {
                activeDeadlineToAccept: moment(
                    eulaApi.active_deadline_to_accept
                ),
                activeEulaVersion: eulaApi.active_eula_version,
                userCanUseSablono: eulaApi.user_can_use_sablono,
                userMustBePromptedForEula:
                    eulaApi.user_must_be_prompted_for_eula,
                eulaAcceptKey: eulaApi.eula_accept_key,
            };
        });
    }

    function acceptEula(eulaAcceptKey) {
        return $sbEulaApi.accept({
            eula_accept_key: eulaAcceptKey,
        });
    }
}
