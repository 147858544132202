export default class LookAheadProgramWebService {
    constructor($sbRequest, API_VERSION, $sbFeatureDecisions) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.$sbFeatureDecisions = $sbFeatureDecisions;
        this.API_VERSION = API_VERSION;

        this.LEVEL_OF_DETAIL = {
            DELIVERABLE: "deliverable",
            STRUCTURE: "structure",
        };
    }

    /**
     * Schema for pagination metadata of response.
     *
     * @typedef {Object} ResponsePagingMetadata
     *
     * @property has_next_page {boolean}
     * @property has_previous_page {boolean}
     * @property next_page {string}
     * @property previous_page {string}
     */

    /**
     * Schema for metadata of response.
     *
     * @typedef {Object} ResponseMetadata
     *
     * @property count_all {number}
     * @property count_page {number}
     * @property page {ResponsePagingMetadata}
     */

    /**
     * Response schema of GET request for look-ahead-program endpoint.
     *
     * @typedef {Object} GetLookAheadProgramCollection
     *
     * @property meta {ResponseMetadata}
     * @property records {Object[]}
     */

    /**
     * This endpoint returns the list of deliverables for a given project.
     *
     * @param projectId {string}            UUID
     * @param from {string}                 From which day is the data requested.
     * @param to {string}                   Until which day is the data requested.
     * @param timezone {string}             Timezone used for look ahead time related calculations.
     * @param byOnlyMyArea {boolean}        Filter data to show only my area.
     * @param byStructureId {string}        Filter data to show deliverables with given structure. UUID
     * @param byProcessTemplateId {string}  Filter data to show deliverables with given process template. UUID
     * @param bySessionId {number}          The unique identifier of the session.
     * @param byTeamId {number}             The unique identifier of the team.
     * @param includeLate {boolean}         Filter data to show late activities in the result.
     * @param search {string}               Filter data to show deliverables having a text match with the search term
     * @param levelOfDetail {string}        The level of detail you are interested in. Used as aggregation layer. (structure or deliverable)
     * @param [limit] {number}              Number of aggregates to include in the requested look ahead program view.
     * @param [cursor] {string}             Base64 encoded cursor used for pagination.
     *
     * @returns {Promise<GetLookAheadProgramCollection>}
     */
    getCollection(
        projectId,
        {
            from,
            to,
            timezone,
            byOnlyMyArea,
            byStructureId,
            byProcessTemplateId,
            bySessionId,
            byTeamId,
            includeLate,
            search,
            levelOfDetail,
            limit,
            cursor,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/look-ahead-program`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                from: from,
                to: to,
                timezone: timezone,
                by_only_my_area: byOnlyMyArea,
                by_structure_id: byStructureId,
                by_process_template_id: byProcessTemplateId,
                by_session_id: bySessionId,
                by_include_late: includeLate,
                by_team_id: byTeamId,
                search: search,
                level_of_detail: levelOfDetail,
                limit,
                cursor,
            },
        });
    }

    /**
     * Request a PDF document for the current look ahead program with the given filters.
     *
     * @param projectId {string}            UUID
     * @param from {string}                 From which day is the data requested.
     * @param to {string}                   Until which day is the data requested.
     * @param timezone {string}             Timezone used for look ahead time related calculations.
     * @param byOnlyMyArea {boolean}        Filter data to show only my area.
     * @param byStructureId {string}        Filter data to show deliverables with given structure. UUID
     * @param byProcessTemplateId {string}  Filter data to show deliverables with given process template. UUID
     * @param bySessionId {number}          The unique identifier of the session.
     * @param byTeamId {number}             The unique identifier of the team.
     * @param language {string}             The language to localize the report.
     * @param levelOfDetail {string}        The level of detail you are interested in. Used as aggregation layer. (structure or deliverable)
     * @param columnTimespan {string}       The timespan represented by each column. Currently day or week.
     * @param isShowingActuals {boolean}    Whether to use the actual dates of activities when available or not.
     */
    printCollection(
        projectId,
        {
            from,
            to,
            timezone,
            byOnlyMyArea,
            byStructureId,
            byProcessTemplateId,
            bySessionId,
            byTeamId,
            language,
            levelOfDetail,
            columnTimespan,
            isShowingActuals,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/look-ahead-program`;

        return this.$sbRequest.pdf({
            url: endpointUrl,
            method: "GET",
            query: {
                from,
                to,
                timezone,
                language,
                by_only_my_area: byOnlyMyArea,
                by_structure_id: byStructureId,
                by_process_template_id: byProcessTemplateId,
                by_team_id: byTeamId,
                by_session_id: bySessionId,
                level_of_detail: levelOfDetail,
                column_timespan: columnTimespan,
                include_actuals: isShowingActuals,
            },
        });
    }
}
