import angular from "angular";
import timelineRouteConfig from "./timeline.route";
import timelineCtrl from "./timeline.controller";
import timelineDetailCtrl from "./states/timeline_detail.controller";
import timelineService from "./services/timeline.service";
import inspectionRowComponent from "./components/inspection_row.component";
import issuesModule from "./../issues/issues.module";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.timeline", ["ngMaterial", "ui.router", issuesModule.name])
    .config(timelineRouteConfig)
    .controller("TimeLineCtrl", timelineCtrl)
    .controller("TimeLineDetailCtrl", timelineDetailCtrl)
    .component("inspectionRow", inspectionRowComponent)
    .factory("timelineService", timelineService);

/* declaration */
