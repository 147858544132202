export default function resetPasswordCtrl($sbAuth) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////
    var vm = this;

    var ERRORS = {
        UserNotFoundException: {
            title: "SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TITLE",
            text: "SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TEXT",
        },
        LimitExceededException: {
            title: "SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TITLE",
            text: "SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TEXT",
        },
        GENERIC_ERROR: {
            title: "SECTION_RESET_PASSWORD_ERROR_TITLE",
            text: "SECTION_RESET_PASSWORD_ERROR_TEXT",
        },
    };

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.success = false;
    vm.loading = false;
    vm.hasError = false;
    vm.currentError = ERRORS.GENERIC_ERROR;

    vm.ERRORS = ERRORS;

    vm.onSubmitPress = triggerPasswordReset;
    vm.formVisible = formVisible;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function triggerPasswordReset() {
        vm.loading = true;
        $sbAuth
            .requestResetPassword(vm.username)
            .then(_setFlags)
            .catch(_handleError)
            .finally(function () {
                vm.loading = false;
            });
    }

    function _setFlags() {
        vm.success = true;
    }

    function _handleError(error) {
        vm.hasError = true;
        if (ERRORS[error.code]) {
            vm.currentError = ERRORS[error.code];
        }
    }

    function formVisible() {
        return !vm.loading && !vm.success && !vm.hasError;
    }
}
