import ConsumableCalendar from "./consumable_calendar.model";
import WorkingCalendar from "./working_calendar.model";
import AllDayCalendar from "./all_day_calendar.model";

export default function () {
    return {
        ConsumableCalendar: ConsumableCalendar,
        WorkingCalendar: WorkingCalendar,
        AllDayCalendar: AllDayCalendar,

        // for testing
        //
        _setFloatHourToDay: _setFloatHourToDay,
    };

    /**
     * Sets the hour as a float in to a day. e.g: _setFloatHourToDay(18/07/2016, 4.5) = 18/07/2016 04:30
     *
     * @param  {Moment} day  - The day in which the hour has to be set
     * @param  {float}  floatHour   - The hour to be set as a float
     * @return {Moment}      - The day with the specified hour
     */
    function _setFloatHourToDay(day, floatHour) {
        var isNegativeOrZeroFloat =
            parseFloat(floatHour) === floatHour && floatHour <= 0;
        if (isNegativeOrZeroFloat) {
            return day.hour(0).minute(0);
        }

        var hour = Math.floor(floatHour);
        var minute =
            hour !== 0
                ? Math.round((floatHour % hour) * 60)
                : Math.round(floatHour * 60);

        return day.hour(floatHour).minute(minute);
    }
}
