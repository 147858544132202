export default function ProcessViewerProgress(
    $sbTracking,
    $sbActivities,
    $sbIssueEditorService,
    $mdToast,
    $sbErrorPresenter,
    $rootScope,
    $q,
    EVENTS,
    $sbIssue
) {
    "ngInject";

    var service = {
        changeState,
        createNoteOnActivity: createNoteOnActivity,
        updateStateForActivity,
    };

    return service;

    function changeState(activity, fromState, toState) {
        if (fromState === toState) {
            return $q.resolve();
        }

        // update state and dependent progress
        //
        activity.setCurrentState(toState);

        return $sbActivities.odata
            .updateState(activity.id, {
                state: toState,
            })
            .then(function () {
                $sbTracking.progress.change.done(
                    "Process tracker",
                    "Process tracker"
                );
                return publishStateChange(activity);
            })
            .catch(function revertOnError(error) {
                activity.setCurrentState(fromState);
                $sbErrorPresenter.catch(error);
            });
    }

    function publishStateChange(activity) {
        $rootScope.$emit(
            EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
            activity.id,
            activity.state.current
        );
    }

    function createNoteOnActivity(activity, teams) {
        $sbTracking.note.creation.start("Process tracker");

        return $sbIssueEditorService
            .showForActivity(activity.assignedTeam, teams)
            .then(({ note }) => {
                note.setAssignedComponent(activity);
                return $sbIssue.createIssue(note);
            })
            .then(function (noteResponse) {
                $sbTracking.note.creation.done("Process tracker", {
                    type: noteResponse.type,
                    photos: noteResponse.image_ids,
                    attachments: noteResponse.attachments,
                });
            })
            .then(function () {
                $mdToast.show(
                    $mdToast
                        .simple()
                        .content("INFO_PROCESS_VIEWER_NOTE_CREATED")
                );
            });
    }

    /**
     * Update the data with state changes from the outside (e.g. details overlay).
     *
     * @param deliverables
     * @param activityId
     * @param toState
     */
    function updateStateForActivity(deliverables, activityId, toState) {
        deliverables.some(function (deliverable) {
            return deliverable.getActivities().some(function (activity) {
                if (activity.id === activityId) {
                    activity.setCurrentState(toState);
                    return true;
                }
            });
        });
    }
}
