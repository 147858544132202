import VirtualODataLoader from "./virtual_odata_loader.class";

export default function (
    $log,
    $q,
    SbDeliverable,
    $sbStructure,
    $sbTemplate,
    $sbCurrentProject,
    $sbReportingDeliverablesApi
) {
    "ngInject";
    var DeliverablesSimpleLazyLoader = function (filter, orderBy) {
        VirtualODataLoader.call(this, filter, orderBy);

        this.structureMapper = $sbStructure.asMap($sbCurrentProject.pick("id"));
        this.templateMapper = $sbTemplate.asMap($sbCurrentProject.pick("id"));
    };

    DeliverablesSimpleLazyLoader.prototype = Object.create(
        VirtualODataLoader.prototype
    );

    /**
     * @inheritDoc
     *
     * @param {Object} query
     * @param {ODataFilterFactory} query.filter
     * @param {Object} query.orderBy
     * @param {Number} from - load data block from number
     * @param {Number} to - to number
     * @returns {$q} - the resolved OData request with the data array
     */
    DeliverablesSimpleLazyLoader.prototype.loadODataResource = function (
        { filter, orderBy = { direction: 1, criteria: "CODE" } },
        from,
        to
    ) {
        const selects = [
            "ID",
            "STRUCTURE_ID",
            "PROJECT_ID",
            "TEMPLATE_ID",
            "NAME",
            "CODE",
            "DESC",
        ];

        return $sbReportingDeliverablesApi
            .getCollection($sbCurrentProject.pick("id"), {
                filter: filter ? filter.get() : undefined,
                select: selects.join(","),
                orderby: `${orderBy.criteria} ${
                    orderBy.direction < 0 ? "desc" : "asc"
                }`,
                top: to - from,
                skip: from,
            })
            .then(({ meta, deliverables = [] }) => {
                return {
                    count: meta.count_all,
                    items: deliverables.map((deliverableData) =>
                        SbDeliverable.createFromOdataObject(deliverableData)
                    ),
                };
            })
            .then(({ count, items }) => {
                return $q
                    .all([this.structureMapper, this.templateMapper])
                    .then(([structure, templates]) => {
                        items.forEach((item) => {
                            item.structure = structure.get(item.structureId);
                            item.template = templates.get(item.piTemplateId);
                        });

                        return {
                            count,
                            items,
                        };
                    });
            })
            .then(function (response) {
                $log.log("lazy loader for DELIVERABLES", response);
                return response;
            });
    };

    return DeliverablesSimpleLazyLoader;
}
