import "createjs";
/**
 * Container that Caches Elements in Viewport
 *
 * @constructs CacheContainer
 * @extends createjs.Container
 * @memberof WBS
 */
function CacheContainer() {
    createjs.Container.call(this);

    if (this.debugDraw && this.checkIsOnStage) {
        this.debugDrawShape = new createjs.Shape();
        createjs.Container.prototype.addChild.call(this, this.debugDrawShape);
        this.on("tick", this.drawDebugGraphics);
    }
}

CacheContainer.prototype = Object.create(createjs.Container.prototype);

/**
 * Status of Debug Mode
 * @type {boolean}
 */
CacheContainer.prototype.debugDraw = false;

/**
 * Determines if it should be checked it it is on viewport
 * @type {boolean}
 */
CacheContainer.prototype.checkIsOnStage = false;

/**
 * Draw bounds in Debug Mode
 */
CacheContainer.prototype.drawDebugGraphics = function () {
    var g = this.debugDrawShape.graphics;

    g.clear();

    var bounds = this.getBounds();

    if (!bounds) {
        return;
    }
    g.beginStroke("#F00");
    g.drawRect(bounds.x, bounds.y, bounds.width, bounds.height);
};

/**
 * Determines Visibility of Container
 * @returns {*}
 */
CacheContainer.prototype.isVisible = function () {
    var visible = createjs.Container.prototype.isVisible.call(this);
    if (this.checkIsOnStage && visible) {
        var bounds = this.getBounds();

        if (bounds) {
            visible = this.isOnStage(bounds);
        }
    }
    return visible;
};

/**
 * Checks if Element is in Viewport
 */
CacheContainer.prototype.isOnStage = (function () {
    var globalBounds = new createjs.Rectangle(0, 0, 0, 0);
    return function (bounds) {
        var topLeftCorner = this.localToGlobal(bounds.x, bounds.y);
        var bottomRightCorner = this.localToGlobal(
            bounds.x + bounds.width,
            bounds.y + bounds.height
        );
        globalBounds.setValues(
            topLeftCorner.x,
            topLeftCorner.y,
            bottomRightCorner.x - topLeftCorner.x,
            bottomRightCorner.y - topLeftCorner.y
        );

        var visible = this.stage.realBounds.intersects(globalBounds);
        return visible;
    };
})();

export default CacheContainer;
