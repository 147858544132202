import processViewerHtml from "./process_viewer.html";

export default function ($stateProvider) {
    "ngInject";
    // TODO
    // 9/6/22 Remove again once a LL project that uses it is done.
    // Update: 14/3/2023 Now we want this page for LoR Proof of concept
    $stateProvider.state("sablono.project.processViewer", {
        templateUrl: processViewerHtml,
        controller: "ProcessViewerCtrl",
        controllerAs: "processViewer",
        url: "/process_viewer",
        reloadOnSearch: false,
        gaPageTrack: "/processViewer", // google analytics extension
        data: {
            title: "SECTION_PROCESS_VIEWER_TITLE",
            category: "REVIEW",
            searchEnabled: true,
            searchPlaceholder: "_FILTER_SEARCH_DELIVERABLE_PLACEHOLDER",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_PROCESS_VIEWER_PAGE);
        },
    });
}
