import lookAheadDefinitionDialogTemplate from "./sb_look_ahead_definition_dialog.html";
import LookAheadDefinitionDialogController from "./sb_look_ahead_definition_dialog.controller";

export default function DialogProviderConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("sbLookAheadDefinitionDialog", {
        methods: ["class", "calendar"],
        options: function () {
            return {
                zIndex: 100,
                templateUrl: lookAheadDefinitionDialogTemplate,
                controller: LookAheadDefinitionDialogController,
                controllerAs: "$ctrl",
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
