export default class VisualizationsWebService {
    constructor($q, $sbRequest, $sbImageApi, API_VERSION) {
        "ngInject";
        this.$q = $q;
        this.$sbRequest = $sbRequest;
        this.$sbImageApi = $sbImageApi;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the project visualizations.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/visualizations`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns the visualization by id. (As content in the correct data type or as meta data in JSON)
     *
     * @param projectId {string}    UUID
     * @param visualizationId {integer}    The unique identifier of the visualization you are requesting a resource for.
     */
    get(projectId, visualizationId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/visualizations/${visualizationId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    getPlainSvg(projectId, visualizationId) {
        let endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/visualizations/${visualizationId}`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "GET",
            header: {
                Accept: "image/svg+xml",
            },
            responseType: "text",
            transformResponse: [
                ({ responseText }) => {
                    return responseText;
                },
            ],
        });
    }

    /**
     * Upload an image (File/Blob)
     *
     * API POST call to /projects/<project-id>/images
     *
     * @param  {string} projectId          - the project id where this image belongs to
     * @param  {Blob}   blob               - the Image as data blob
     * @param  {Object} options
     * @param  {string} options.filename   - the name of the image
     *
     * @return {Promise}
     */
    upload(projectId, blob, { filename }) {
        if (!projectId || typeof projectId !== "string") {
            return this.$q.reject(new Error("MissingRequiredParameter"));
        }

        if (!(blob instanceof Blob)) {
            return this.$q.reject(new Error("MissingRequiredParameter"));
        }

        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/files`;
        const fd = new FormData();
        fd.append("file", blob, filename);

        return this.$sbRequest.multiPart(endpointUrl, fd).then(function (xhr) {
            const location = xhr.getResponseHeader("Location");
            const id = location.substring(location.lastIndexOf("/") + 1);
            const url = endpointUrl + "/" + id;

            return {
                id,
                url,
            };
        });
    }

    /**
     * This endpoint creates a new visualization in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.name {string}    Simple name of the visualization entity to quickly identify it
     * @param body.definition {Object}    OneOf the definitions
     */
    create(projectId, { name, definition }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/visualizations`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { name, definition },
        });
    }

    /**
     * This endpoint allows to remove a visualization.
     *
     * @param projectId {string}    UUID
     * @param visualizationId {integer}    The unique identifier of the visualization you are requesting a resource for.
     */
    delete(projectId, visualizationId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/visualizations/${visualizationId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
