import ConstantsModule from "./constants.module";

ConstantsModule.constant(
    "LOCAL_STORAGE_TEMPLATE_DETAILS_PREFIX",
    "TEMPLATE_DETAILS_"
);
ConstantsModule.constant("ISSUES_VIEW_FILTER_STORE_KEY", "sb.issues");
ConstantsModule.constant(
    "DELIVERABLES_VIEW_FILTER_STORE_KEY",
    "sb.deliverables"
);
ConstantsModule.constant(
    "PROCESS_VIEWER_VIEW_FILTER_STORE_KEY",
    "sb.processViewer"
);
ConstantsModule.constant(
    "PROCESS_VIEWER_HIDE_FINISHED_ACTIVITES_KEY",
    "hideFinishedActivites"
);
