import VirtualODataLoader from "./virtual_odata_loader.class";

export default function (
    $log,
    $sbCurrentProject,
    SbTeam,
    $sbTeam,
    $sbReportingNotesApi,
    $sbErrorPresenter
) {
    "ngInject";
    var IssuesLazyLoader = function (filter, orderBy) {
        VirtualODataLoader.call(this, filter, orderBy);

        this.teamMapper = $sbTeam.asMap($sbCurrentProject.pick("id"));
    };

    IssuesLazyLoader.prototype = Object.create(VirtualODataLoader.prototype);

    /**
     * @inheritDoc
     *
     * @param {Object} query
     * @param {ODataFilterFactory} query.filter
     * @param {Object} query.orderBy
     * @param {Number} from - load data block from number
     * @param {Number} to - to number
     * @returns {$q} - the resolved OData request with the data array
     */
    IssuesLazyLoader.prototype.loadODataResource = function (
        { filter, orderBy = { direction: 1, criteria: "ISSUE_TIME_CREATED" } },
        from,
        to
    ) {
        const selects = [
            "AUTHOR_DISPLAY_NAME",
            "AUTHOR_INITIALS",
            "ISSUE_ID",
            "ISSUE_STATUS",
            "ISSUE_TYPE",
            "ISSUE_TEXT",
            "ISSUE_NAME",
            "ISSUE_TIME_CREATED",
            "ROOT_COMPONENT_ID",
            "ROOT_COMPONENT_NAME",
            "ROOT_COMPONENT_CODE",
            "ROOT_COMPONENT_DESC",
            "REF_COMPONENT_ID",
            "REF_COMPONENT_NAME",
            "REF_COMPONENT_DESC",
            "RESPONSIBLE_TEAM_ID",
            "LAST_UPDATER_INITIALS",
            "LAST_UPDATER_DISPLAY_NAME",
            "LAST_UPDATE_TIME",
            "CONFIRMATION_TEAM_ID",
            "LAST_MODIFICATION_TIME",
            "LAST_MODIFICATION_DISPLAY_NAME",
        ];

        return $sbReportingNotesApi
            .getCollection($sbCurrentProject.pick("id"), {
                filter: filter ? filter.get() : undefined,
                select: selects.join(","),
                orderby: `${orderBy.criteria} ${
                    orderBy.direction < 0 ? "desc" : "asc"
                }`,
                top: to - from,
                skip: from,
            })
            .then(({ meta, notes = [] }) => {
                return this.teamMapper.then((teams) => {
                    notes.forEach((item) => {
                        item.RESPONSIBLE_TEAM =
                            teams.get(item.RESPONSIBLE_TEAM_ID) ||
                            SbTeam.createUnrestrictedTeam();

                        item.CONFIRMATION_TEAM =
                            teams.get(item.CONFIRMATION_TEAM_ID) ||
                            SbTeam.createNoConfirmationTeamAvailable();
                    });
                    return {
                        count: meta.count_all,
                        items: notes,
                    };
                });
            })
            .then(function (response) {
                $log.log("lazy loader for DELIVERABLE_NOTES", response);
                return response;
            })
            .catch(function (e) {
                $sbErrorPresenter.catch(e); // handle the error
                throw e; // throw again to inform the lazy loader
            });
    };

    return IssuesLazyLoader;
}
