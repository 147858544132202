import _ from "lodash";

export default function DeliverableSetConfigurationCtrl(
    $mdDialog,
    $sbCurrentProject,
    $sbPermission,
    $sbFeatureDecisions,
    EnvironmentFlags
) {
    "ngInject";
    const vm = this;

    vm.hasAdminPermissions = $sbPermission.hasAdminPermissions(
        $sbCurrentProject.pick("privileges")
    );

    vm.closeDialog = $mdDialog.cancel;
    vm.onOptionClick = onOptionClick;

    vm.types = {
        BULK_ASSIGN_TEMPLATE: {
            icon1: "sb-icon-template smaller blue",
            title: "DIALOG__DELIVERABLE_SET__ASSIGN_TEMPLATE__OPTION_TITLE",
        },
        BULK_ASSIGN_STRUCTURE: {
            icon1: "sb-icon-wbs_structure smaller blue",
            title: "DIALOG__DELIVERABLE_SET__ASSIGN_STRUCTURE__OPTION_TITLE",
        },
        BULK_CHANGE_DATES: {
            icon1: "mdi mdi-calendar smaller blue",
            title: "DIALOG__DELIVERABLE_SET__CHANGE_DATES__OPTION_TITLE",
        },
        BULK_ASSIGN_DELIVERABLE_TYPE: {
            icon1: "mdi mdi-group smaller blue",
            title: "DIALOG__DELIVERABLE_SET__ASSIGN_DELIVERABLE_TYPE__OPTION_TITLE",
        },
        BULK_ASSIGN_RESPONSIBLE_MANAGER: {
            icon1: "mdi mdi-account-star smaller blue",
            title: "DIALOG__DELIVERABLE_SET__ASSIGN_RESPONSIBLE_MANAGER__OPTION_TITLE",
        },
        BULK_DELETE: {
            icon1: "mdi mdi-delete smaller blue",
            title: "DIALOG__DELIVERABLE_SET__DELETE__OPTION_TITLE",
        },
    };

    if (
        !$sbFeatureDecisions.isFeatureEnabled(
            EnvironmentFlags.DeprecatedFeatureToRemoveSoon
        )
    ) {
        vm.types = _.omit(vm.types, ["BULK_ASSIGN_RESPONSIBLE_MANAGER"]);
    }

    function onOptionClick(key) {
        $mdDialog.hide({ key });
    }
}
