/**
 * Created by RIEMI on 21.01.2016.
 */
import moment from "moment";

export default function ChangeTaskDatetimeDialogCtrl(
    $log,
    $mdDialog,
    $mdDateLocale,
    $sbDates,
    $sbDomain,
    SbActivity,
    Analytics,
    DATE_TYPES
) {
    "ngInject";
    var vm = this;

    vm.closeDialog = closeDialog;
    vm.onCalendarDatesChanged = onCalendarDatesChanged;
    vm.acceptCurrentChanges = acceptCurrentChanges;
    vm.chooseDatePickerOption = chooseDatePickerOption;
    vm.isKeepRangeVisible = isKeepRangeVisible;

    activate();

    /**
     * Activation method, to set proper date and times before the dialog
     */
    function activate() {
        _setDefaults();
        _setupCalendarLocale();
        if (
            $sbDates.isValidDateValue(vm.startDate) &&
            $sbDates.isValidDateValue(vm.endDate)
        ) {
            // preset dates or take now
            //
            vm.startDate = $sbDates.momentOrNull(vm.startDate);
            vm.endDate = $sbDates.momentOrNull(vm.endDate);
        } else {
            $log.warn("Invalid Date Value", vm.startDate, vm.endDate);
        }
        // If there's a type preselected, just jump to it
        if (vm.dateSelectionType) {
            chooseDatePickerOption(vm.dateSelectionType);
        }
    }

    function chooseDatePickerOption(key) {
        if (vm.step === 1) {
            Analytics.trackEvent(
                "Dialog | Change Dates",
                "Select",
                "Option | " + key
            );
        }

        vm.step = 2;
        vm.type = key;

        switch (key) {
            case DATE_TYPES.START_AND_DUE_DATE:
                vm.showConflictWarning = vm.conflictWarningFlag;
                break;
            case DATE_TYPES.START_DATE:
                vm.showConflictWarning =
                    vm.conflictWarningFlag !== false && vm.endDate !== null;
                break;
            case DATE_TYPES.END_DATE:
                vm.showConflictWarning =
                    vm.conflictWarningFlag !== false && vm.startDate !== null;
                break;
        }
    }

    function closeDialog() {
        $mdDialog.cancel();
    }

    /**
     * Validate, throw errors (if any) and forward changes accordingly
     */
    function acceptCurrentChanges() {
        $mdDialog.hide({
            deliverablesSelection: vm.deliverablesToChange
                ? vm.deliverablesToChange.activeSelection
                : [],
            SD: vm.type === DATE_TYPES.DUE_DATE ? undefined : vm.startDate,
            CD: vm.type === DATE_TYPES.START_DATE ? undefined : vm.endDate,
        });
    }

    /**
     * Executed on every date or time change in the dialog.
     *
     * Validates if the given combination is valid and sets the validity
     * of endbeforestart to given boolean.
     */
    function onCalendarDatesChanged(start, end) {
        vm.form.$setValidity(
            "endbeforestart",
            !endDateBeforeStartDate(end, start)
        );

        if (vm.form.$valid) {
            vm.startDate = start;
            vm.endDate = end;
        }
    }

    /**
     * Checks if given end date is before the start date
     * @param {moment} endDate - end date of  range
     * @param {moment} startDate - start date of range
     * @returns {boolean} - (True if it's before start date, false otherwise)
     */
    function endDateBeforeStartDate(endDate, startDate) {
        if (startDate === null || endDate === null) {
            return false;
        }

        if (!(moment.isMoment(startDate) && moment.isMoment(endDate))) {
            return false;
        }

        return moment(endDate).isBefore(moment(startDate));
    }

    function isKeepRangeVisible() {
        return vm.isKeepRangeEnabled && !vm.differentDatesFlag;
    }

    function _setDefaults() {
        vm.types = {
            START_AND_DUE_DATE: {
                image: "/images/dates/dates-both.svg",
                title: "DIALOG_CHANGE_DATES_OPTION_BOTH_TITLE",
            },
            START_DATE: {
                image: "/images/dates/dates-start.svg",
                title: "DIALOG_CHANGE_DATES_OPTION_START_TITLE",
            },
            DUE_DATE: {
                image: "/images/dates/dates-end.svg",
                title: "DIALOG_CHANGE_DATES_OPTION_END_TITLE",
            },
        };

        vm.selection = {};
        vm.keepRange = {
            active: false,
            duration: "",
        };

        vm.step = 1;
    }

    function _setupCalendarLocale() {
        $mdDateLocale.firstDayOfWeek = moment.localeData().firstDayOfWeek();
    }
}
