export default function profileService($log, $sbAuth) {
    "ngInject";

    var service = {
        getUser: getUser,
        modifyCurrentUser: modifyCurrentUser,
        changePassword: changePassword,
    };

    return service;

    //////////////////////////////////////////////////////

    function getUser() {
        return $sbAuth.currentAuthenticatedUser().then(function (userInfo) {
            return {
                id: userInfo.attributes.sub,
                name: userInfo.attributes.name,
                company: userInfo.attributes["custom:company"],
                email: userInfo.attributes.email,
            };
        });
    }

    function modifyCurrentUser({ name, company }) {
        const payload = {};
        payload["custom:company"] = company;
        payload.name = name;
        return $sbAuth
            .currentAuthenticatedUser()
            .then((authenticatedUser) =>
                $sbAuth.updateUserAttributes(authenticatedUser, payload)
            );
    }

    function changePassword({ oldPassword, newPassword }) {
        return $sbAuth
            .currentAuthenticatedUser()
            .then((authenticatedUser) =>
                $sbAuth.changePassword(
                    authenticatedUser,
                    oldPassword,
                    newPassword
                )
            );
    }
}
