export default class FilesWebService {
    constructor($q, $sbRequest, API_VERSION) {
        "ngInject";
        this.$q = $q;
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * Upload a file (File/Blob)
     *
     * API POST call to /projects/<project-id>/files
     *
     * @param  {string} projectId          - the project id where this file belongs to
     * @param  {Blob}   blob               - the File as data blob
     * @param  {Object} options
     * @param  {string} options.filename   - the name of the file
     *
     * @return {Promise}
     */
    upload(projectId, blob, { filename }) {
        if (!projectId || typeof projectId !== "string") {
            return this.$q.reject(new Error("MissingRequiredParameter"));
        }

        if (!(blob instanceof Blob)) {
            return this.$q.reject(new Error("MissingRequiredParameter"));
        }

        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/files`;
        const fd = new FormData();

        fd.append("file", blob, encodeURI(filename));

        return this.$sbRequest.multiPart(endpointUrl, fd).then(function (xhr) {
            const location = xhr.getResponseHeader("Location");
            const originalFilename = xhr.getResponseHeader(
                "X-sbln-original-filename"
            );
            const id = location.substring(location.lastIndexOf("/") + 1);
            const url = endpointUrl + "/" + id;

            return {
                id,
                url,
                originalFilename,
            };
        });
    }

    download(projectId, assetId, options = {}) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/files/${assetId}`;
        return this.$sbRequest.binary(
            Object.assign(
                {
                    url: endpointUrl,
                    method: "GET",
                },
                options
            )
        );
    }
}
