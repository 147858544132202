import angular from "angular";
import htmlTemplate from "./sbDependencyContainer.html";
/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbDependencyContainer", [])
    .directive(
        "sbDependencyContainer",
        function DependencyContainerDirective() {
            return {
                /**
                 * String of subset of EACM which restricts the directive to a specific directive declaration style.
                 * If omitted, the defaults (elements and attributes) are used.
                 *
                 * @example <caption>Element Name</caption>
                 * E - Element name (default): <my-directive></my-directive>
                 * A - Attribute (default): <div my-directive="exp"></div>
                 * C - Class: <div class="my-directive: exp;"></div>
                 * M - Comment: <!-- directive: my-directive exp -->
                 */
                restrict: "E",

                templateUrl: htmlTemplate, //HTML Template as String

                //transclude: true,

                scope: {
                    isPredecessor: "=",
                    dependencies: "=",
                    deleteDependency: "=",
                    createDependency: "=",
                    suggestDependency: "=",
                    nodeClicked: "=",
                    graphParentId: "=",
                },

                //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

                //compile : function(tElement, tAttrs) {},

                link: function (/*scope, iElement ,iAttrs, controller*/) {},

                controller: function (
                    $scope,
                    $element,
                    sortCaseInsensitiveFilter,
                    $timeout,
                    $mdToast,
                    $document
                ) {
                    var currentHighlightedActivity = null;

                    /**
                     * real text input from the user
                     * @type {string}
                     */
                    $scope.autocompleteText = "";

                    $scope.navigate = function (event) {
                        $scope.nodeClicked(
                            {
                                id: $scope.graphParentId,
                            },
                            event
                        );
                    };

                    function getAllActivityNodesById(id) {
                        return Array.prototype.slice
                            .call(
                                $document[0].querySelectorAll(
                                    '[sb-node-id="' + id + '"]'
                                )
                            )
                            .map(function (node) {
                                return node.parentNode;
                            });
                    }

                    // watch on the dependencies
                    //
                    var watcher = $scope.$watch("dependencies", function () {
                        if (angular.isDefined($scope.dependencies)) {
                            $scope.dependencyList = []
                                .concat($scope.dependencies)
                                .sort(function (first, second) {
                                    return sortCaseInsensitiveFilter(
                                        first.properties.name,
                                        second.properties.name
                                    );
                                });
                        }
                    });

                    // remove the watcher if Ctrl is destroyed
                    //
                    $scope.$on("$destroy", function () {
                        watcher();
                        if (currentHighlightedActivity) {
                            $scope.downlightActivity(
                                currentHighlightedActivity
                            );
                        }
                    });

                    /**
                     * handle the deletion of an element in the list
                     *
                     * @param {Component} dependency - the Component to be removed from the list
                     */
                    $scope.onDeleteDependency = function (dependency) {
                        // TODO: (RIEMI 21.02.2016) Remove usage of angular internal functions.
                        $scope.$$childHead.$mdAutocompleteCtrl.blur(); // eslint-disable-line angular/no-private-call

                        // remove the element from the list
                        $scope.dependencyList.splice(
                            $scope.dependencyList.indexOf(dependency),
                            1
                        );

                        // call to the parent
                        $scope.deleteDependency(
                            dependency,
                            $scope.isPredecessor
                        );
                    };

                    /**
                     * Handles the selection of a dependency in the autocomplete
                     * -> adds the new dependency to graph if possible
                     *
                     * @param {Component} selectedActivity - activity that is added as a dependency
                     * @param {Object} autoCompleteCtrl - controller for the autocomplete to clear it afterwards
                     */
                    $scope.onCreateDependency = function (
                        selectedActivity,
                        autoCompleteCtrl
                    ) {
                        if (
                            angular.isUndefined(selectedActivity) ||
                            selectedActivity === null
                        ) {
                            return;
                        }

                        //used autocomplete as a select box without typing something in
                        if (!$scope.autocompleteText) {
                            //blur input
                            var activeElement = $document[0].activeElement;
                            if (activeElement) {
                                activeElement.blur();
                            }
                        }

                        // TODO: (RIEMI 21.02.2016) Remove usage of angular internal functions.
                        $scope.$$childHead.$mdAutocompleteCtrl.blur(); // eslint-disable-line angular/no-private-call
                        // call to the parent
                        $scope
                            .createDependency(
                                selectedActivity,
                                $scope.isPredecessor
                            )
                            .then(function () {
                                // add the element to the beginning of the list
                                $scope.dependencyList.unshift(selectedActivity);

                                // empty the autoComplete
                                autoCompleteCtrl.activitySearchName = "";
                            })
                            .catch(function (type) {
                                $mdToast.show(
                                    $mdToast
                                        .simple()
                                        .content(
                                            "INFO_CIRCLE_" + type + "_MESSAGE"
                                        )
                                        .position("top right")
                                );
                            });
                    };

                    $scope.highlightActivity = function (activity) {
                        if (currentHighlightedActivity) {
                            $scope.downlightActivity(
                                currentHighlightedActivity
                            );
                        }
                        currentHighlightedActivity = activity;
                        getAllActivityNodesById(activity.id).forEach(
                            function (node) {
                                node.classList.add("highlighted-node");
                                return node;
                            }
                        );
                    };
                    $scope.downlightActivity = function (activity) {
                        currentHighlightedActivity = null;
                        getAllActivityNodesById(activity.id).forEach(
                            function (node) {
                                node.classList.remove("highlighted-node");
                            }
                        );
                    };
                },
            };
        }
    );
