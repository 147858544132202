import Element from "./Element";

/**
 * Class for Editable Elements
 *
 * @constructs EditableElement
 * @extends WBS.Element
 * @memberof WBS
 */
function EditableElement() {
    // call constructor of parent class to set context
    //
    Element.call(this);

    // set event handler
    //
    /**
     * Public onFocus Method
     * @function
     */

    this.onFocus = this._onFocus.bind(this);

    /**
     * Public onBlur Method
     * @function
     */
    this.onBlur = this._onBlur.bind(this);

    this.on("focus", this.onFocus);
    this.on("blur", this.onBlur);
}

/**
 * Setup prototypal inheritance.
 * EditableElement inherits from Element.
 *
 * @type {Element}
 */
EditableElement.prototype = Object.create(Element.prototype);

/**
 * Editablity of the Editable Element
 * @type {boolean}
 */
EditableElement.prototype.editable = true;

/**
 * Status of Focus on EditableElement
 * @type {boolean}
 */
EditableElement.prototype.hasFocus = false;

/**
 * Handles the Focus of the Element
 * @private
 */
EditableElement.prototype._onFocus = function () {
    this.hasFocus = true;
};

/**
 * Handles the unfocus of the Element
 * @private
 */
EditableElement.prototype._onBlur = function () {
    this.hasFocus = false;
};

export default EditableElement;
