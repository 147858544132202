import ViewFilter from "../../common/services/oDataService/odata_view_filter.class";

export default function ProcessViewerLoaderService(
    $sbDeliverablesFilter,
    $sbODataViewFilter,
    $sbProject,
    $sbComponent,
    DeliverableProcessLazyLoader,
    $q,
    PROCESS_VIEWER_VIEW_FILTER_STORE_KEY,
    $sbODataViewFilterStore,
    $state
) {
    "ngInject";

    var service = {
        createLoader: createLoader,
        setupFilters: setupFilters,
        fetchTotalCountOfDeliverablesInProject:
            fetchTotalCountOfDeliverablesInProject,
        setFilterParams: setFilterParams,
    };

    var viewFilterStore;

    return service;

    /**
     * Create the loader for the process viewer data.
     */
    function createLoader(
        filters,
        { structureMapper, teamMapper },
        { fnOnCountChange, fnOnDeliverablesLoaded }
    ) {
        var loader = new DeliverableProcessLazyLoader(
            $sbODataViewFilter.combinedOData(filters),
            "CODE",
            { structureMapper, teamMapper }
        );

        loader.countReady(fnOnCountChange);
        loader.onLoadData(fnOnDeliverablesLoaded);

        return loader;
    }

    /**
     * Load all view filters and initialize OData loader.
     *
     * @param {string} projectId
     * @param {Object} [options]
     * @param {Object} [options.templateFilterOptions]
     * @param {boolean} [options.templateFilterOptions.fallbackToFirst]
     *
     * @private
     */
    function setupFilters(projectId, options = {}) {
        var dataFilters = [
            createProjectFilter($sbProject.getCurrentProjectId()),
            $sbDeliverablesFilter.nameCodeDescFilter(),
            $sbDeliverablesFilter.assignedTemplateFilter(
                options.templateFilterOptions
            ),
            $sbDeliverablesFilter.structureFilter(),
            $sbDeliverablesFilter.areaManagerFilter(),
        ];

        viewFilterStore = $sbODataViewFilterStore.getStore(
            $state.$current.name,
            PROCESS_VIEWER_VIEW_FILTER_STORE_KEY,
            projectId
        );

        return $q.all(dataFilters).then(function (filters) {
            // load filter params from url or local store
            var filterParams = viewFilterStore.getFilterParams();

            // apply filter params
            $sbODataViewFilter.setViewFilterValuesFrom(filterParams, filters);

            // store filter params in url and local store
            viewFilterStore.setFilterParams(filters, false);

            return filters;
        });
    }

    /**
     * Create the project view filter.
     * Enables default filtering by current project.
     *
     * @param projectId
     * @returns {ViewFilter}
     */
    function createProjectFilter(projectId) {
        return new ViewFilter("", "projectId")
            .setODataHandler(function (factory, value) {
                return factory.eq("PROJECT_ID", value);
            })
            .enableNullCondition("PROJECT_ID")
            .setDisplayable(false)
            .setValue(projectId);
    }

    function fetchTotalCountOfDeliverablesInProject() {
        return $sbComponent.countDeliverablesOfProject(
            $sbProject.getCurrentProjectId()
        );
    }

    function setFilterParams(filters) {
        //save filters to URL and local storage
        viewFilterStore.setFilterParams(filters);
    }
}
