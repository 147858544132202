/**
 * http://stackoverflow.com/questions/15478954/sort-array-elements-string-with-numbers-natural-sort
 *
 * Comparator function for natural sorting of strings containing numbers.
 *
 * @param {String} a
 * @param {String} b
 *
 * @returns {number}
 */
export default function naturalSort(a, b) {
    if (typeof a !== "string" && typeof b !== "string") {
        return 0;
    }
    if (typeof a !== "string") {
        return 1;
    }
    if (typeof b !== "string") {
        return -1;
    }

    var ax = [],
        bx = [];

    // replaces groups of digits
    //
    // group 1 ($1) => all digits
    // group 2 ($2) => all non-digits
    //
    var regexp = /(\d+)|(\D+)/g;

    a.replace(regexp, function (_, $1, $2) {
        ax.push([$1 || Infinity, $2 || ""]); // save tupel of found groups
    });
    b.replace(regexp, function (_, $1, $2) {
        bx.push([$1 || Infinity, $2 || ""]);
    });

    while (ax.length && bx.length) {
        // start comparing both values each found value from the beginning
        var an = ax.shift();
        var bn = bx.shift();

        // compare digits first and fallback to string comparision
        var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);

        if (nn) return nn; // return on non-zero
    }

    // if strings are equal until here, decide on length
    return ax.length - bx.length;
}
