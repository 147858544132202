import WbsCommand from "./WBSCommand";
import EdgeCreatedEvent from "../events/EdgeCreatedEvent";
import ShowToastEvent from "../events/ShowToastEvent";
/**
 * Command to Create an Edge
 * @param {WBS.Edge} oEdge - Edge to be creates
 * @param {WBS.EditorStage} oStage - Stage on which Edges are Drawn
 * @constructs CreateEdgeCommand
 * @implements {WBS.WbsCommand}
 * @memberof WBS
 */
function CreateEdgeCommand(oEdge, oStage) {
    WbsCommand.call(this, oStage.$sbErrorPresenter, oStage.wbsApiService);

    /**
     *
     * @type {WBS.Edge}
     */
    this.edge = oEdge;

    /**
     *
     * @type {WBS.EditorStage}
     */
    this.stage = oStage;
}

CreateEdgeCommand.prototype = Object.create(WbsCommand.prototype);

/**
 * Calls the api to create an Edge
 * Triggers UI Changes
 */
CreateEdgeCommand.prototype.execute = function () {
    var cmd = this;

    function triggerUI(edge) {
        //todo: create edge in ui
        var sourceId = edge.SOURCE_ID;
        var targetId = edge.TARGET_ID;
        var edgeEvent = new EdgeCreatedEvent(sourceId, targetId);

        cmd.stage.dispatchEvent(edgeEvent);
        cmd.stage.dispatchEvent(
            new ShowToastEvent("EDGE_CREATE_SUCCESSFUL", "success")
        );
    }

    this.wbsApiService
        .createEdge(this.edge)
        .then(triggerUI, this.$sbErrorPresenter.catch);
};

/**
 * Calls api do delete the edge
 * Triggers UI Changes
 */
CreateEdgeCommand.prototype.undo = function () {
    function triggerUI() {
        //todo: create edge in ui
        console.log("triggerUI undo");
    }

    this.wbsApiService
        .deleteEdge(this.edge)
        .then(triggerUI)
        .catch(this.$sbErrorPresenter.catch);
};

/**
 * Redo Edge Creation
 */
CreateEdgeCommand.prototype.redo = function () {
    this.execute();
};

export default CreateEdgeCommand;
