import PresentableError from "./PresentableError";

/**
 * Error if a request timed out.
 *
 * @constructor
 * @extends PresentableError
 *
 * @param {XMLHttpRequest} request - Used XMLHttpRequest object
 */
function RequestTimeoutError(request) {
    this.code = "REQUEST_TIMEOUT";
    this.title = "ERROR_REQUEST_TIMEOUT_TITLE";
    this.message = "ERROR_REQUEST_TIMEOUT_MESSAGE";
    this.request = request;
}

RequestTimeoutError.prototype = Object.create(PresentableError.prototype); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ RequestTimeoutError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.TOAST;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ RequestTimeoutError.prototype.shouldAutoClose = function () {
    return true;
};

export default RequestTimeoutError;
