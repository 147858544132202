import angular from "angular";
import _ from "lodash";
import ChecklistTemplate from "../model/checklist_template.class";
import ChecklistTemplateItem from "../model/checklist_template_item.class";

export default function ChecklistService($q, $sbChecklistTemplateWebService) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    var service = {
        checklists: [],
        fetchAllChecklistTemplatesForProject:
            fetchAllChecklistTemplatesForProject,
        getChecklistTemplateById: getChecklistTemplateById,
        createChecklistTemplate: createChecklistTemplate,
        updateChecklistTemplate: updateChecklistTemplate,
        deleteChecklistTemplate: deleteChecklistTemplate,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function fetchAllChecklistTemplatesForProject(projectId) {
        return $sbChecklistTemplateWebService
            .getByProject(projectId)
            .then(_parseResponseToModel)
            .then(_setChecklistsInService);
    }

    function getChecklistTemplateById(projectId, checklistTemplateId) {
        var checklistIndex = _getChecklistServiceIndex(checklistTemplateId);

        if (checklistIndex > -1) {
            return $q.resolve(service.checklists[checklistIndex]);
        } else {
            return $sbChecklistTemplateWebService
                .getById(projectId, checklistTemplateId)
                .then(_parseResponseToModel);
        }
    }

    function createChecklistTemplate(projectId, checklistTemplateData) {
        return $sbChecklistTemplateWebService
            .create(projectId, checklistTemplateData.name)
            .then(_parseResponseToModel)
            .then(_addChecklistInService);
    }

    function updateChecklistTemplate(
        projectId,
        checklistTemplateId,
        checklistTemplateData
    ) {
        return $sbChecklistTemplateWebService
            .update(projectId, checklistTemplateId, checklistTemplateData)
            .then(_parseResponseToModel)
            .then(_updateChecklistInService);
    }

    function deleteChecklistTemplate(projectId, checklistTemplate) {
        var backUp = angular.copy(checklistTemplate);
        var oldIndex = _.findIndex(service.checklists, ["id", backUp.id]);

        service.checklists = _.filter(service.checklists, function (checklist) {
            return checklist.id !== backUp.id;
        });

        return $sbChecklistTemplateWebService
            .remove(projectId, checklistTemplate.id)
            .catch(function (error) {
                service.checklists.splice(oldIndex, 0, backUp);
                throw error;
            });
    }

    function _setChecklistsInService(checklists) {
        return (service.checklists = checklists);
    }

    function _addChecklistInService(newChecklistTemplate) {
        service.checklists = service.checklists.concat([newChecklistTemplate]);
        return newChecklistTemplate;
    }

    function _updateChecklistInService(updatedChecklist) {
        var checklistIndex = _getChecklistServiceIndex(updatedChecklist.id);

        if (checklistIndex > -1) {
            service.checklists[checklistIndex] = updatedChecklist;
        }

        return updatedChecklist;
    }

    function _parseResponseToModel(response) {
        if (_.isArray(response.checklist_templates)) {
            return response.checklist_templates.map(function (element) {
                return _createAChecklistTemplateModelFor(element);
            });
        } else {
            return _createAChecklistTemplateModelFor(response);
        }
    }

    function _createAChecklistTemplateModelFor(data) {
        var checklistTemplate = new ChecklistTemplate(data.id, data.name);

        checklistTemplate.setCreationDate(data.created.at);
        checklistTemplate.setCreator(data.created.by);

        var checklistTemplateItems = data.checklist_items.map(function (item) {
            var checklistItem = new ChecklistTemplateItem(
                item.id,
                item.description
            );
            checklistItem.setIsConfirmRequired(
                item.config.answer_required_for.confirm
            );
            checklistItem.setIsWorkRequired(
                item.config.answer_required_for.work
            );
            checklistItem.setIsWorkImageRequired(
                item.config.image_required_for.work
            );

            return checklistItem;
        });

        checklistTemplate.setItems(checklistTemplateItems);

        return checklistTemplate;
    }

    function _getChecklistServiceIndex(checklistTemplateId) {
        return _.findIndex(service.checklists, function (checklistTemplate) {
            return checklistTemplate.id === checklistTemplateId;
        });
    }
}
