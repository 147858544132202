import sbRequestActionDialog from "../../../states/deliverables/dialogs/requestAction/request_action_dialog.component";

export default function CustomerSuccessService(
    $sbMembership,
    $window,
    $mdDialog,
    $sbFeatureDecisions,
    $translate,
    EnvironmentFlags
) {
    "ngInject";

    /////////////////////
    //
    //      API
    //
    /////////////////////

    const JIRA_CREATE_CS_TICKET_LINK =
        "https://sablono.atlassian.net/secure/CreateIssue.jspa?issuetype=10036&pid=10005";

    return {
        revertConfirmation,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function revertConfirmation(activityId, activityName) {
        const userId = $sbMembership.current().user.userName;

        const dialog = $mdDialog.confirm({
            ...sbRequestActionDialog,
            locals: {
                text: $translate.instant(
                    "CS_REVERT_CONFIRMATION_DIALOG_CONTENT"
                ),
                info: `Please revert confirmation on activity with id = '${activityId}' (name: ${activityName}).
                Requesting user id is '${userId}'.`,
                title: $translate.instant(
                    "CS_REVERT_CONFIRMATION_DIALOG_HEADER"
                ),
            },
        });

        return $mdDialog.show(dialog).then(navigateToJIRA);
    }

    function navigateToJIRA() {
        if (
            $sbFeatureDecisions.isFeatureEnabled(EnvironmentFlags.ShowCsRequest)
        ) {
            return $window.open(JIRA_CREATE_CS_TICKET_LINK);
        } else {
            $mdDialog.hide();
        }
    }
}
