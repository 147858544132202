import createjs from "createjs";

/**
 * Event dispatched when a Toast should be shown
 * @param {string} content - the message
 * @param {string} type - the type (error, info, success)
 * @param {number} [hideDelay] - duration until the toast fades out
 * @constructs ShowToastEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function ShowToastEvent(content, type, hideDelay) {
    createjs.Event.call(this, "showToast", false, false);
    this.content = content;
    this.color = type;
    this.hideDelay = hideDelay || 3000;
}

ShowToastEvent.prototype = Object.create(createjs.Event.prototype);

export default ShowToastEvent;
