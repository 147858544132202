export default function ActivityCommonInputCtrl(
    ACTIVITY_CONFIG,
    TEMPLATE_WORK_DONE_UNITS
) {
    "ngInject";
    var vm = this;

    vm.CONFIG = ACTIVITY_CONFIG;
    vm.UNITS = TEMPLATE_WORK_DONE_UNITS;
}
