import angular from "angular";
import html from "./role_selection.html";

export default {
    templateUrl: html,
    controllerAs: "$ctrl",
    bindings: {
        selectedRole: "<",
        roles: "<",
        onSelect: "&",
    },
    controller: function RoleSelect() {
        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = angular.noop;
        vm.$onChanges = angular.noop;
        vm.$onDestroy = angular.noop;

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////
    },
};
