import angular from "angular";
import _ from "lodash";
import moment from "moment";
import panelHtml from "./date_select_calendar.panel.html";

export default function DateSelectService($mdPanel, $q) {
    "ngInject";
    var service = {
        openCalendar: openCalendar,
    };

    return service;

    function openCalendar($event, options) {
        var today = moment().toDate();

        var defaultLocals = {
            selectedDate: today,
        };

        return $q(function (resolve) {
            $mdPanel.open({
                attachTo: angular.element(document.body),
                templateUrl: panelHtml,
                focusOnOpen: false,
                bindToController: true,
                controllerAs: "$ctrl",
                controller: function DatePickerCtrl(mdPanelRef) {
                    "ngInject";

                    var vm = this;
                    vm.hideCalendar = hideCalendar;

                    function hideCalendar(selectedDate) {
                        if (selectedDate) {
                            mdPanelRef.close(selectedDate);
                        } else {
                            mdPanelRef.close();
                        }
                    }
                },
                clickOutsideToClose: true,
                escapeToClose: true,
                position: $mdPanel.newPanelPosition().absolute().right().top(),
                locals: _.assign(defaultLocals, options),
                onCloseSuccess: function (mdPanelRef, closeReason) {
                    if (
                        closeReason === $mdPanel.closeReasons.CLICK_OUTSIDE ||
                        closeReason === $mdPanel.closeReasons.ESCAPE
                    ) {
                        return;
                    }

                    resolve(closeReason);
                },
            });
        });
    }
}
