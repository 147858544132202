/**
 * wrapper for the node manager
 * @constructs NodeEntity
 * @memberof WBS
 * @param {string} id - id current node
 * @param {WBS.NodeManager} manager - Instance of Node Manager that should be wrapped
 **/
function NodeEntity(id, manager) {
    this.id = id;
    this.manager = manager;
}

/**
 * getter for all incoming edges
 * @returns {Array|null}
 */
NodeEntity.prototype.getIncomingEdges = function () {
    return this.manager.getIncomingEdges(this.id);
};

/**
 * getter for all outgoing edges
 * @returns {Array|null}
 */
NodeEntity.prototype.getOutgoingEdges = function () {
    return this.manager.getOutgoingEdges(this.id);
};

/**
 * setter for custom node data
 * @param {Object} data - New Node Data
 */
NodeEntity.prototype.setData = function (data) {
    this.manager.setData(this.id, data);

    var geometry = this.getGeometry();
    if (geometry) {
        geometry.node.dispatchEvent("dataUpdated");
    }
};

/**
 * getter for custom node data
 * @returns {Object|null}
 */
NodeEntity.prototype.getData = function () {
    return this.manager.getData(this.id);
};

/**
 * adds an edge from this node to another node
 * @param {string} id - id of other node
 */
NodeEntity.prototype.addEdgeTo = function (id) {
    this.manager.addEdge(this.id, id);
};

/**
 * adds an edge from other node to this node
 * @param {string} id - id of other node
 */
NodeEntity.prototype.addEdgeFrom = function (id) {
    this.manager.addEdge(id, this.id);
};

/**
 * deletes an edge
 * @param {string} id - id of other node
 */
NodeEntity.prototype.deleteEdge = function (id) {
    this.manager.deleteEdge(this.id, id);
};

/**
 * checks if an edge from this node to a node already exist
 * @param {string} id - id of other node
 * @returns {boolean}
 */
NodeEntity.prototype.checkIfEdgeExistsTo = function (id) {
    return this.manager.checkIfEdgeExists(this.id, id);
};

/**
 * checks if an edge from another node to this exist
 * @param {string} id - id of other node
 * @returns {boolean}
 */
NodeEntity.prototype.checkIfEdgeExistsFrom = function (id) {
    return this.manager.checkIfEdgeExists(id, this.id);
};

/**
 * checks if an edge from this node to another and vice versa exist
 * @param {string} id - id of other node
 * @returns {boolean}
 */
NodeEntity.prototype.checkIfEdgeExists = function (id) {
    var existsTo = this.checkIfEdgeExistsTo(id);
    var existsFrom = this.checkIfEdgeExistsFrom(id);

    return !!(existsTo || existsFrom);
};

/**
 * setter for geometry
 * @param {WBS.NodeContainer} geometry - New Geometry
 */
NodeEntity.prototype.setGeometry = function (geometry) {
    this.manager.setGeometry(this.id, geometry);
};

/**
 * getter for geometry of this node
 * @returns {NodeContainer|null}
 */
NodeEntity.prototype.getGeometry = function () {
    return this.manager.getGeometry(this.id);
};

/**
 * getter for all not connected node ids
 * @returns {String[]}
 */
NodeEntity.prototype.getNotConnectedNodeIDs = function () {
    return this.manager.getNotConnectedNodeIDs(this.id);
};

/**
 * getter for all not connected structure nodes
 * @returns {StructureNode[]}
 */
NodeEntity.prototype.getNotConnectedStructureNodes = function () {
    return this.manager.getNotConnectedStructureNodes(this.id);
};

/**
 * deletes the current node
 */
NodeEntity.prototype.deleteNode = function () {
    this.manager.deleteNode(this.id);
};

export default NodeEntity;
