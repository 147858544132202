import CacheContainer from "./CacheContainer";

/**
 * BaseClass for all Elements Displayed in WBS
 * @constructs Element
 * @extends WBS.CacheContainer
 * @memberof WBS
 */
function Element() {
    CacheContainer.call(this);

    if (this.shouldCache || this.debugDrawCacheBounds) {
        this.checkCache = this._checkCache.bind(this);
        this.on("tick", this.checkCache);
        if (this.debugDrawCacheBounds) {
            this.debugDrawCacheBoundsShape = new createjs.Shape();
            this.addChild(this.debugDrawCacheBoundsShape);
        }
    }

    this.cacheBounds = new createjs.Rectangle(0, 0, 0, 0);
    this.cacheScale = 1;

    this.createHitArea();
}

Element.prototype = Object.create(CacheContainer.prototype);

/**
 * Determines whether Cache Bounds should be drawn
 */
Element.prototype.debugDrawCacheBounds = false;

/**
 * Font type displayed on Element
 * @type{string}
 */
Element.prototype.textFont = '"Work Sans", "Helvetica Neue", sans-serif';

/**
 * Font Size
 * @default '16px'
 * @type {string}
 */
Element.prototype.textSize = "16px";

/**
 * FontColor
 * @type {string}
 */
Element.prototype.textColor = "#222";
Element.prototype.width = 0;
Element.prototype.height = 0;

/**
 * changes only allowed before instantiate
 * if true it checks every tick if the Element is cached
 * if isn't cached it caches the element
 * @type {boolean}
 */

Element.prototype.shouldCache = false;

Element.prototype.invalidCache = true;

/**
 * Creates Text
 * @param {String} [size] - any valid css font-size
 * @param {String} [font] - any valid css font-family
 * @param {String} [color] -any valid css color
 * @returns {createjs.Text}
 * @private
 */
Element.prototype._createText = function (size, font, color) {
    size = size || this.textSize;
    font = font || this.textFont;
    color = color || this.textColor;
    var text = new createjs.Text("", size + " " + font, color);
    text.hitArea = this.hitArea;
    text.textBaseline = "middle";
    return text;
};

/**
 * Checks Cache
 * @private
 */
Element.prototype._checkCache = function () {
    if (this.debugDrawCacheBounds) {
        this.drawCacheBounds();
    }
    if (this.shouldCache) {
        if ((!this.cacheCanvas || this.invalidCache) && this.isVisible()) {
            if (
                !this.cacheCanvas ||
                (this.invalidCache && this.stage.hasFreeTime())
            ) {
                this.invalidCache = false;

                this.uncache();

                this.cache(
                    this.cacheBounds.x,
                    this.cacheBounds.y,
                    this.cacheBounds.width,
                    this.cacheBounds.height,
                    this.cacheScale
                );
            } else {
                this.stage.setNeedsDisplay();
            }
        }
    }
};

Element.prototype.drawCacheBounds = function () {
    this.debugDrawCacheBoundsShape.graphics
        .clear()
        .beginFill("rgba(255,0,0,0.3)")
        .drawRect(
            this.cacheBounds.x,
            this.cacheBounds.y,
            this.cacheBounds.width,
            this.cacheBounds.height
        );
};

Element.prototype.invalidateCache = function () {
    this.invalidCache = true;
};

Element.prototype.createHitArea = function () {
    this._hitArea = new createjs.Shape();
    this._hitArea.graphics
        .beginFill("#000")
        .drawRect(-this.width / 2, -this.height / 2, this.width, this.height);
};

Element.prototype.enableHitArea = function () {
    this.hitArea = this._hitArea;
};

Element.prototype.disableHitArea = function () {
    this.hitArea = null;
};

Element.prototype.nextPowOf2 = function (x) {
    return Math.pow(2, Math.ceil(Math.log(x) / Math.log(2)));
};

export default Element;
