import stateTemplateHtml from "./projectSelection.html";
import "angular-ui-router";

export default function projectSelectionStateConfig($stateProvider) {
    "ngInject";

    $stateProvider.state("sablono.projectSelection", {
        url: "/projectSelection",
        templateUrl: stateTemplateHtml,
        controller: "ProjectSelectionCtrl as projects",
        data: {
            appCategory: "PLAN",
            title: "SECTION_PROJECT_SELECTION_TITLE",
            icon: "sb-icon-project_overview",
            searchEnabled: true,
            searchPlaceholder:
                "INFO_PLACEHOLDER_SEARCH_BAR_PROJECT_SELECTION_MESSAGE",
        },
        onEnter: function ($sbCurrentProject, $sbProjectCurrentSettings) {
            "ngInject";
            $sbCurrentProject.unset();
            $sbProjectCurrentSettings.clear();
        },
    });
}
