import moment from "moment";
import _ from "lodash";

function ProgressSnapshot(id, projectId) {
    this.id = id;
    this.projectId = projectId;

    this.changes = [];
    this.generatedAt = null;
}

ProgressSnapshot.prototype.setGeneratedAt = function (dateAsString) {
    this.generatedAt = moment(dateAsString);
    return this;
};

ProgressSnapshot.prototype.setChanges = function (
    changes,
    allTeamMembersInProject
) {
    this.changes = _.forEach(changes, function (change) {
        _setChangeReportersOn(
            change.activities,
            "stateReachedBy",
            allTeamMembersInProject
        );
        _setChangeReportersOn(
            change.noteChanges,
            "statusChangedBy",
            allTeamMembersInProject
        );

        _.forEach(change.activities, function (activity) {
            if (activity.stateTransitionedFromAt) {
                activity.stateTransitionedFromAt = moment(
                    activity.stateTransitionedFromAt
                );
            }
        });
    });

    return this;
};

function _setChangeReportersOn(
    listOfChanges,
    reportedBy,
    allTeamMembersInProject
) {
    _.forEach(listOfChanges, function dbNameToUser(change) {
        change[reportedBy] = _.find(allTeamMembersInProject, [
            "username",
            change[reportedBy],
        ]);
    });
}

export default ProgressSnapshot;
