import angular from "angular";
import _ from "lodash";
import htmlTemplate from "./sb_filter_structure.html";
import ViewFilter from "common/services/oDataService/odata_view_filter.class";

export default angular
    .module("sbApp.sbFilterStructure", [])
    .directive("sbFilterStructure", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "filterStructure",
            templateUrl: htmlTemplate,
            replace: true,
            //transclude: true,
            scope: {
                /**
                 * @typeof ViewFilter
                 */
                viewFilter: "=",

                // inform parent
                onChange: "=",

                //
                placeholder: "@",
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "filterStructure.viewFilter.value",
                    function (newValue, oldValue) {
                        // support clear viewFilter from outside -> newValue is undefined
                        if (newValue === undefined && newValue !== oldValue) {
                            controller.activate();

                            // Remove scrollMask: this was a bug that was blocking the UI when removing
                            // a multiline element on the filter.
                            // This bug was documented and I think it is a special case of it. We should keep
                            // an eye on future updates
                            var scrollMask =
                                document.getElementsByClassName(
                                    "md-scroll-mask"
                                );
                            if (scrollMask[0]) {
                                // scrollMask[0].parentNode.removeChild(scrollMask[0]);
                                scrollMask[0].style.display = "none";
                            }
                        }
                    }
                );
            },
            controller: function FilterStructureCtrl() {
                var vm = this;
                vm.onSelectionChange = onSelectionChange;
                vm.selectUnstructured = selectUnstructured;
                vm.activate = activate;

                activate();

                ///////////
                //
                //  IMPL
                //
                ///////////

                function activate() {
                    var activeOptions = vm.viewFilter.collectActiveOptions();
                    if (activeOptions && activeOptions.length > 0) {
                        vm.selectedItem = activeOptions[0];
                    } else if (vm.viewFilter.isNullCondition()) {
                        vm.isUnstructuredOnly = true;
                    } else {
                        vm.selectedItem = {}; // simulate "no selection"
                    }
                }

                function selectUnstructured(active) {
                    if (active) {
                        vm.viewFilter.setValue(ViewFilter.NULL_CONDITION);
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    } else {
                        onSelectionChange(vm.selectedItem);
                    }
                }

                function onSelectionChange(selectedItem) {
                    // empty objects are our simulation of "no selection"
                    if (selectedItem && !_.isEmpty(selectedItem)) {
                        vm.viewFilter.setSelectedOption(selectedItem.name);
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    } else if (vm.viewFilter.hasValue()) {
                        vm.viewFilter.clear();
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    }
                }
            },
        };
    });
