import ConstantsModule from "./constants.module";

var env = {
    DEVELOPMENT: "development",
    STAGING: "staging",
    PRODUCTION: "production",
};

var ENVIRONMENT_STRING = getEnvironment(window.location.hostname);

// save environment as
ConstantsModule.constant("ENVIRONMENT", ENVIRONMENT_STRING);

// set environment to window
//
window.sablono = window.sablono || {};
window.sablono.environment = ENVIRONMENT_STRING;

export default ENVIRONMENT_STRING;

function getEnvironment(hostname) {
    var environment = "";

    switch (hostname) {
        case "app.sablono.com":
            environment = env.PRODUCTION;
            break;
        case "localhost":
            environment = env.DEVELOPMENT;
            break;
        default:
            environment = env.STAGING;
    }

    return environment;
}
