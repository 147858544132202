/**
 * Created by Sebastian on 23.11.2015.
 */
import importHtmlTemplate from "./import.html";
import selectionHtmlTemplate from "./states/importSelection/import_selection.html";
import uploadHtmlTemplate from "./states/importUpload/import_upload.html";
import excelHtml from "./states/excel/excel.html";
import importProgressHtml from "./states/importProgress/import_progress.html";
import importDatesHtml from "./states/importDates/import_dates.html";
import xmlHtml from "./states/xml/xml.html";
import activitiesHtml from "./states/importActivities/import_activities.html";
import packagesHtml from "./states/importPackages/import_packages.html";

/**
 * The abstract import state. Everything related to
 * import is under this state, e.g. excel & msProject state.
 * @param {object} $stateProvider - state provider
 */
export default function ImportStateConfiguration($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.import", {
        abstract: true,
        templateUrl: importHtmlTemplate,
        controller: "ImportCtrl",
        controllerAs: "rootImport",
        url: "/import",
    });

    $stateProvider.state("sablono.project.import.selection", {
        url: "",
        gaPageTrack: "/import", // google analytics extension
        templateUrl: selectionHtmlTemplate,
        controller: "ImportSelectionCtrl",
        controllerAs: "importSelection",
        data: {
            title: "SECTION_IMPORT_SELECTION_TITLE",
            icon: "sb-icon-import",
            category: "PLAN",
        },
    });

    $stateProvider.state("sablono.project.import.upload", {
        url: "/upload",
        templateUrl: uploadHtmlTemplate,
        controller: "ImportUploadCtrl",
        controllerAs: "uploadState",
        gaPageTrack: "/import/upload", // google analytics extension
        data: {
            title: "SECTION_IMPORT_SELECTION_TITLE",
            subBarTitle: "SECTION_IMPORT_UPLOAD_TITLE",
            category: "PLAN",
        },
        params: {
            uploadModel: undefined,
            previousState: "sablono.project.import.selection",
        },
    });

    //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
    // Activated route access by MB in behalf of the Sven Richter to support client: Dressler Bau
    //
    $stateProvider.state("sablono.project.import.xml", {
        templateUrl: xmlHtml,
        url: "/project?src",
        gaPageTrack: "/import/project", // google analytics extension
        controller: "ImportProjectCtrl",
        controllerAs: "importProject",
        data: {
            title: "SECTION_XML_IMPORT_TITLE",
            icon: "sb-icon-msproject_outline",
            category: "PLAN",
            hasBackButton: true,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(
                TRACKED_EVENTS.ENTERED_MS_PROJECT_IMPORT_PAGE
            );
        },
    });

    $stateProvider.state("sablono.project.import.excel", {
        url: "/excel?sourceKey&block-deletion&block-bring-back",
        gaPageTrack: "/import/excel", // google analytics extension
        templateUrl: excelHtml,
        controller: "ExcelCtrl",
        controllerAs: "importExcel",
        data: {
            title: "SECTION_EXCEL_IMPORT_TITLE",
            icon: "sb-icon-excel_outline",
            category: "PLAN",
            hasBackButton: true,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_EXCEL_IMPORT_PAGE);
        },
        resolve: {
            calendar: function ($stateParams, $sbProject) {
                "ngInject";
                return $sbProject.getCalendar($stateParams.projectId);
            },
        },
    });
    $stateProvider.state("sablono.project.import.progress", {
        url: "/progress",
        gaPageTrack: "/import/progress", // google analytics extension
        templateUrl: importProgressHtml,
        controller: "ImportProgressCtrl",
        controllerAs: "importProgress",
        data: {
            title: "SECTION_EXCEL_IMPORT_PROGRESS_TITLE",
            icon: "sb-icon-excel_outline",
            category: "PLAN",
            hasBackButton: true,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_EXCEL_IMPORT_PAGE);
        },
    });
    $stateProvider.state("sablono.project.import.dates", {
        url: "/dates",
        gaPageTrack: "/import/dates", // google analytics extension
        templateUrl: importDatesHtml,
        controller: "ImportDatesCtrl",
        controllerAs: "importDates",
        data: {
            title: "SECTION_EXCEL_IMPORT_DATES_TITLE",
            icon: "sb-icon-excel_outline",
            category: "PLAN",
            hasBackButton: true,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_EXCEL_IMPORT_PAGE);
        },
    });
    $stateProvider.state("sablono.project.import.activityData", {
        url: "/activity-attributes",
        gaPageTrack: "/import/activityData", // google analytics extension
        templateUrl: activitiesHtml,
        controller: "ImportActivitiesCtrl",
        controllerAs: "importActivities",
        data: {
            title: "SECTION_EXCEL_IMPORT_DATES_TITLE",
            icon: "sb-icon-excel_outline",
            category: "PLAN",
            hasBackButton: true,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_EXCEL_IMPORT_PAGE);
        },
    });
    $stateProvider.state("sablono.project.import.packages", {
        url: "/packages",
        gaPageTrack: "/import/packages", // google analytics extension
        templateUrl: packagesHtml,
        controller: "ImportPackagesCtrl",
        controllerAs: "importPackages",
        data: {
            title: "SECTION_EXCEL_IMPORT_DATES_TITLE",
            icon: "sb-icon-excel_outline",
            category: "PLAN",
            hasBackButton: true,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_EXCEL_IMPORT_PAGE);
        },
    });
}
