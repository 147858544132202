/**
 * Receiver of Commands
 * * knows how to carry out operations associated with the command
 * * maintains a history of executed commands
 *
 * @returns {{execute: Function, undo: Function, redo: Function}}
 * @constructs CommandReceiver
 * @memberof WBS
 */
function CommandReceiver() {
    /**
     * Saved commands.
     *
     * @type {WBS.Command[]}
     * @private
     */
    var commands = [],
        currentCommandIndex = -1;

    return {
        /**
         * Executes the given Command, saves position of execution
         * @param {WBS.Command} command - Command to be executed
         * @memberof WBS.CommandReceiver
         */
        execute: function (command) {
            command.execute();
            commands[++currentCommandIndex] = command;

            //delete possible redos since a new command branch is executed
            //
            if (commands.length - 1 > currentCommandIndex) {
                commands.splice(currentCommandIndex + 1, Number.MAX_VALUE);
            }
        },
        /**
         * Undo the most recent Command
         * @memberof WBS.CommandReceiver
         */
        undo: function () {
            var command = commands[currentCommandIndex];
            if (command) {
                command.undo();
                currentCommandIndex--;
            }
        },
        /**
         * Redo the most Recent Undone Command
         * @memberof WBS.CommandReceiver
         */
        redo: function () {
            if (commands[currentCommandIndex + 1]) {
                var command = commands[++currentCommandIndex];
                command.redo();
            }
        },
        /**
         * Checks if it is possible to undo (>=1 Command has to be executed)
         * @returns {boolean}
         * @memberof WBS.CommandReceiver
         */
        canUndo: function () {
            return currentCommandIndex >= 0;
        },
        /**
         * Checks it it is possible to redo (>=1 Command has to be undone)
         * @returns {boolean}
         * @memberof WBS.CommandReceiver
         */
        canRedo: function () {
            return currentCommandIndex < commands.length - 1;
        },
    };
}

export default CommandReceiver;
