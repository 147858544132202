import angular from "angular";
import _ from "lodash";

/**
 * If placed next to an ng-model
 *
 * 1. It will parse user input like
 *  "1", "1.2", "1,1" into a decimal number 1, 1.2, 1.1
 *
 * 2. It will show the number model value as $filter('number') formatted
 *
 */

export default angular
    .module("sbApp.sbDecimalModel", [])
    .directive("sbDecimalModel", function sbDecimalModel($filter) {
        "ngInject";
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ngModelController) {
                var numberFormatter = $filter("number");

                //convert data from view format to model format
                //
                ngModelController.$parsers.push(function (userInput) {
                    if (_.isString(userInput)) {
                        return parseFloat(userInput.replace(",", ".")); //converted
                    }

                    return userInput;
                });

                //convert data from model format to view format
                //
                ngModelController.$formatters.push(function (modelData) {
                    return numberFormatter(modelData); //converted
                });
            },
        };
    });
