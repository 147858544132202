import trackerHtml from "./tracker.html";

export default function ($stateProvider) {
    "ngInject";

    var allowedQueryParams = [
        "processTemplate",
        "activityStates",
        "activity",
        "endDate",
        "structures",
        "workTeams",
        "content",
    ];

    $stateProvider.state("sablono.project.tracker", {
        templateUrl: trackerHtml,
        controller: "TrackerCtrl",
        controllerAs: "tracker",
        url: "/tracker?" + allowedQueryParams.join("&"),
        reloadOnSearch: false,
        gaPageTrack: "/tracker", // google analytics extension
        data: {
            title: "ACTION_NAV_TO_TRACKER",
            category: "REVIEW",
            searchEnabled: false,
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";

            intercomService.track(TRACKED_EVENTS.ENTERED_TRACKER_PAGE);
        },
    });
}
