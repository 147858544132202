/**
 *
 * Usage Example:
 *
 *
 *
 *
 *
 *
 *
 * working with options:
 *  required properties: name, label, odata, (isActive)
 *
 *  Either there are options defined as values OR direct values!
 *  A combination of option and manual value is not supported.
 *
 *
 *
 *
 */
import _ from "lodash";
import ODataFilterFactory from "./odata_filter_factory.class";
import angular from "angular";

/**
 * Interface to unify the usage of filter
 *
 * @param {String} keyLabel - human readable name of the filter
 * @param {String} key      - name of the filter
 * @constructor
 */
function ViewFilter(keyLabel, key) {
    this.translatable = {
        key: keyLabel,
        value: "",
        none: "_NOT_SET",
    };

    /**
     * defines which directive the filter will use
     * @type {string}
     */
    this.type = ViewFilter.TYPE.TEXT;

    /**
     * Information if view filter can be displayed in the UI
     * @type {boolean}
     */
    this.isDisplayable = true;

    /**
     * The class to apply.
     * @type {String}
     */
    this.class = "";

    /**
     * The string used to identify the view filter.
     * @type {String}
     */
    this.key = key;

    /**
     * the values as array that are actually representing the state of the view filter
     * @typedef {Array}
     */
    this.value;

    /**
     * a view filter can support options that will map this.value to the corresponding option.
     * @typedef {Array}
     */
    this.options;

    // a view filter should have an oData handler directly or via options.
    //
    this.odata;

    // support NULL condition if you enable: it requires an odata property
    //
    this.isNullConditionEnabled = false;
    this.oDataProperty;

    return this;
}

ViewFilter.TYPE = {
    TEXT: "text",
    DATE: "date",
    CHECKBOX: "checkbox",
    STRUCTURE: "structure",
    WORKFLOW: "workflow",
    STAGE: "stage",
    USER: "user",
    SELECT: "select",
};

/**
 * Create a text view filter.
 *
 * @param {String} keyLabel - the human readable name of the filter key
 * @param {String} key      - the internal key that is identifying the view filter
 * @param {String} oDataProperty    - the ODATA property that is represented by the filter.
 * @param {Date} [initialValue]   - optional initial value of the filter
 * @returns {ViewFilter}
 */
ViewFilter.createTextFilter = function createTextFilter(
    keyLabel,
    key,
    oDataProperty,
    initialValue
) {
    return new ViewFilter(keyLabel, key)
        .setODataHandler(function (factory, value) {
            return factory.like(oDataProperty, value);
        })
        .setValue(initialValue);
};

ViewFilter.NULL_CONDITION = "_none_";

/**
 * Simple print the state of the view filter.
 */
ViewFilter.prototype.what = function () {
    /* eslint-disable no-console, angular/log */
    console.group();

    console.log("URL: " + this.getValuesAsUrl());
    console.log("CHIP: " + this.getValuesTranslatableForChips());
    console.log("REPORT: " + this.getValuesTranslatableForReports());
    console.log("ODATA: " + this.odata(new ODataFilterFactory()).get());

    console.groupEnd();
    /* eslint-enable no-console, angular/log */
};

///////////////////
//
//  GETTER
//
///////////////////

ViewFilter.prototype.isNullCondition = function () {
    return this.enableNullCondition && this.is(ViewFilter.NULL_CONDITION);
};

ViewFilter.prototype.getTranslatableKey = function () {
    return this.translatable.key;
};

ViewFilter.prototype.getTranslatableValue = function () {
    return this.translatable.value;
};

ViewFilter.prototype.getValue = function () {
    return this.value;
};

ViewFilter.prototype.getValuesTranslatableForChips = function () {
    if (this.isNullCondition()) {
        return [this.translatable.none];
    }
    if (this.translatable.value) {
        return _wrapIntoArray(this.translatable.value);
    } else {
        return _wrapIntoArray(this.value);
    }
};

ViewFilter.prototype.isValueDisplayable = function () {
    var value = this.getValue();
    var hasAtLeastOneDefinedValue =
        value &&
        value.length > 0 &&
        value.some(function (value) {
            return angular.isDefined(value);
        });
    return this.isDisplayable && hasAtLeastOneDefinedValue;
};

ViewFilter.prototype.getValuesAsUrl = function () {
    if (this.isNullCondition()) {
        return [ViewFilter.NULL_CONDITION];
    }
    return _wrapIntoArray(this.value)
        .filter(function (value) {
            return typeof value !== "undefined";
        })
        .join(",");
};

ViewFilter.prototype.getValuesTranslatableForReports = function () {
    return {
        key: this.translatable.key,
        value: this.getValuesTranslatableForChips(),
    };
};

ViewFilter.prototype.getOptions = function () {
    return this.options;
};

///////////////////
//
//  SETTER
//
///////////////////

/**
 * The value is the representation of the view filter state. It has to be an array.
 * You can also path a single string. That one will get wrapped in an array.
 *
 * @param {Array|String} newValue - Either a single value or an array of values
 * @returns {ViewFilter}
 */
ViewFilter.prototype.setValue = function (newValue) {
    if (_.isUndefined(newValue)) {
        this.clear();
        return this;
    }

    if (this.isMultiValue(newValue)) {
        newValue = this.getMultiValues(newValue);
    }

    let newValueIsArray = _wrapIntoArray(newValue);

    if (this.getOptions()) {
        // if there are options configured, the value must be a subset of the options
        newValueIsArray = newValueIsArray.filter((value) => {
            if (value === ViewFilter.NULL_CONDITION) {
                return true;
            }
            return this.getOptions().find((option) => {
                return _getOptionName(option) === value;
            });
        });

        if (newValueIsArray.length === 0 && this.isCustomOptionSupported()) {
            newValueIsArray = _wrapIntoArray(newValue);
        }
    }

    this.value = newValueIsArray;
    this.refreshState();

    return this;
};

ViewFilter.prototype.isCustomOptionSupported = function () {
    return false;
};

ViewFilter.prototype.isMultiValue = function (newValue) {
    return _.isString(newValue) && newValue.indexOf(",") > -1;
};

ViewFilter.prototype.getMultiValues = function (newValue) {
    return newValue.split(",");
};

/**
 *
 */
ViewFilter.prototype.addSelectedOption = function (optionName) {
    this.value = this.value.concat([optionName]);
    this.refreshState();

    return this;
};

/**
 *
 */
ViewFilter.prototype.setSelectedOption = function (optionName) {
    this.value = [optionName];
    this.refreshState();

    return this;
};

/**
 * You can set options that will be used to map specific values of a view filter to
 * the properties of a option. The internal property "isActive" is used to represent
 * the options active or inactive state.
 *
 * The ViewFilter implementation HAS TO make sure that the options active state is
 * reflected in the value and vice versa.
 *
 * @param {Array} options
 * @param {String}      options.label       - the translatable human readable representation of the option
 * @param {String}      options.name        - the id of the option (used in URL AND to map the value!!)
 * @param {Function}    options.odata       - the odata function that is representing the option
 * @param {Boolean}     [options.isActive]  - the active state of the option.
 * @returns {ViewFilter}
 */
ViewFilter.prototype.setOptions = function (options) {
    this.options = options;

    // based on the active options we set the value.
    //
    this.value = this.getOptions().filter(_isOptionActive).map(_getOptionName);
    this.refreshState();

    return this;
};

ViewFilter.prototype.setValueTranslatable = function (text) {
    this.translatable.value = text;
    return this;
};

ViewFilter.prototype.setNullValueTranslatable = function (text) {
    this.translatable.none = text;
    return this;
};

/**
 * Displayable 'none' means it will be ignored in chips, report and url
 * but it is part of the ODATA
 *
 * @param isHidden
 * @returns {ViewFilter}
 */
ViewFilter.prototype.setDisplayable = function (isDisplayable) {
    this.isDisplayable = isDisplayable;
    return this;
};

/**
 *
 * @param {Function} odata - function that takes a ODataFilterFactory and the current value
 * @returns {ViewFilter}
 */
ViewFilter.prototype.setODataHandler = function (odata) {
    this.odata = odata;
    return this;
};

/**
 *
 * @param property
 */
ViewFilter.prototype.enableNullCondition = function (property) {
    this.isNullConditionEnabled = true;
    this.oDataProperty = property;
    return this;
};

ViewFilter.prototype.setClass = function (value) {
    this.class = value;
    return this;
};

///////////////////
//
//  OTHERS
//
///////////////////

/**
 * Check if the the given value matches the view filter value
 *
 * @param value
 * @returns {boolean}
 */
ViewFilter.prototype.is = function (value) {
    if (this.isMultiValue(value)) {
        const values = this.getMultiValues(value);
        const diff = _.xor(values, this.value);
        return diff.length === 0;
    }

    return this.value && this.value.indexOf(value) > -1;
};

/**
 *
 * @param option
 * @returns {boolean}
 */
ViewFilter.prototype.isOptionActiveByValue = function (option) {
    if (this.value === undefined) {
        // case filter disabled
        return false;
    } else {
        return this.value.indexOf(_getOptionName(option)) > -1;
    }
};

/**
 *
 * @param odataFactory
 * @returns {*}
 */
ViewFilter.prototype.applyOdata = function (odataFactory) {
    if (this.isNullCondition()) {
        return odataFactory.eq(this.oDataProperty, null);
    } else {
        var options = this.collectActiveOptions();

        if (options) {
            const innerFactory = new ODataFilterFactory();
            options.forEach(function (option, index) {
                if (index > 0) {
                    innerFactory.or();
                }
                option.odata(innerFactory);
            });

            return odataFactory.block(innerFactory);
        } else {
            if (this.value.length > 1) {
                const innerFactory = new ODataFilterFactory();
                this.value.forEach((option, index) => {
                    if (index > 0) {
                        innerFactory.or();
                    }
                    this.odata(innerFactory, this.value[index].trim());
                });
                return odataFactory.block(innerFactory);
            }
            return this.odata(odataFactory, this.value[0]);
        }
    }
};

ViewFilter.prototype.collectActiveOptions = function () {
    if (this.getOptions()) {
        return this.getOptions().filter(this.isOptionActiveByValue, this);
    } else {
        return undefined;
    }
};

ViewFilter.prototype.hasValue = function () {
    if (_.isUndefined(this.value)) {
        return false;
    } else if (_.isArray(this.value)) {
        return this.value.length > 0;
    } else {
        return true;
    }
};

ViewFilter.prototype.refreshState = function () {
    // means: set translatable values from this.value
    //
    var options = this.collectActiveOptions();
    if (options && options.length > 0) {
        this.translatable.value = options.map(_getOptionLabel);
    } else {
        this.translatable.value = this.value;
    }

    // and set the options value to true or false if options are active or not
    //
    if (this.getOptions()) {
        this.getOptions().forEach(function (option) {
            option.isActive = this.isOptionActiveByValue(option);
        }, this);
    }
};

/**
 * Set all options to false,
 * Set the value to be undefined.
 *
 */
ViewFilter.prototype.clear = function () {
    // and set the options value to true or false if options are active or not
    //
    if (this.getOptions()) {
        this.getOptions().forEach(function (option) {
            option.isActive = false;
        }, this);
    }

    this.value = undefined;

    this.refreshState();
    return this;
};

export default ViewFilter;

function _getOptionLabel(option) {
    return option.label;
}

function _getOptionName(option) {
    return option.name;
}

function _isOptionActive(option) {
    return !!option.isActive;
}

/**
 * @param input
 * @returns {Array}
 * @private
 */
function _wrapIntoArray(input) {
    if (_.isArray(input)) {
        return input;
    } else {
        return [input];
    }
}
