import dashboardProTemplateHtml from "./dashboard-pro.html";

export default function ($stateProvider) {
    "ngInject";

    const allowedQueryParams = [
        "structures",
        "processTemplate",
        "deliverableType",
        "workTeams",
        "activity",
        "dateRange",
        "scheduleType",
        "dashboard",
    ];

    $stateProvider.state("sablono.project.dashboardPro", {
        templateUrl: dashboardProTemplateHtml,
        controller: "DashboardProCtrl",
        controllerAs: "dashboardPro",
        url: "/dashboards-pro?" + allowedQueryParams.join("&"),
        gaPageTrack: "/dashboards-pro", // google analytics extension
        data: {
            title: "SECTION_DASHBOARD_PRO",
            category: "CONTROL",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_DASHBOARD_PRO_PAGE);
        },
    });
}
