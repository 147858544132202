import _ from "lodash";
import moment from "moment";
import SbActivityState from "domain/sb_activity_state.class";

export default function svgService(
    UploadedDrawing,
    GeneratedDrawing,
    DrawingConfiguration,
    $sbRequest,
    $http,
    $sbColor,
    $sbTeam,
    $q,
    $log,
    $sbCurrentProject,
    $sbVisualizationsApi,
    $sbReportingDeliverablesApi
) {
    "ngInject";

    return {
        removeVisualization,
        uploadVisualization,
        uploadVisualizationSettingsFor,

        fetchAllVisualizationsByProject,
        fetchVisualizationByKey,

        fetchVisualizationContentByMode: fetchDeliverablesByGeometryKey,

        fetchConfigurationByKey,
    };

    ////////////////////////////////
    //                            //
    //         PUBLIC API         //
    //                            //
    ////////////////////////////////

    function toValidMomentOrUndefined(activity, path) {
        const datetime = moment(_.get(activity, path), moment.ISO_8601, true);

        return datetime.isValid() ? datetime : undefined;
    }
    /**
     * Fetches the data to determine the decorations of the drawing by mode.
     *
     * Each mode needs different data to color and highlight different parts
     * of the current drawing.
     *
     * @param {Number} svgKey
     * @returns {Object[]}
     */
    function fetchDeliverablesByGeometryKey(svgKey) {
        const projectId = $sbCurrentProject.pick("id");
        return _requestSvgContent(projectId, svgKey).then(
            function (deliverables) {
                if (deliverables.length < 1) {
                    return [];
                }

                return $sbTeam.getTeams(projectId).then(function (teams) {
                    _.forEach(deliverables, function (deliverable) {
                        deliverable.HAS_PROGRESS_REPORTED = _.some(
                            deliverable.ACTIVITIES,
                            _isStarted
                        );
                        deliverable.IS_BEHIND = _.some(
                            deliverable.ACTIVITIES,
                            _isBehind
                        )
                            ? 1
                            : 0;
                        deliverable.PROGRESS_COLOR = $sbColor.color.fromStatus(
                            deliverable.IS_BEHIND
                        );
                        deliverable.STAGE = findLatestStageReached(
                            deliverable.ACTIVITIES
                        );
                        deliverable.CURRENT = findCurrentActivity(
                            deliverable.ACTIVITIES
                        );
                        setTeamValuesOnActivity(deliverable.CURRENT, teams);
                    });

                    return deliverables;
                });
            }
        );
    }

    function _requestSvgContent(projectId, svgKey) {
        const top = 200;
        let skip = 0;
        return $sbReportingDeliverablesApi
            .getCollectionByVisualization(projectId, svgKey, top, skip)
            .then(function parseResult({ meta, deliverables }) {
                const response = _parseResults(deliverables);
                return { meta, response };
            })
            .then(function ({ meta, response }) {
                if (meta.count_all === meta.count_page) {
                    return response;
                }

                const promises = [];
                while (skip + top < meta.count_all) {
                    skip += top;

                    const loadPage = $sbReportingDeliverablesApi
                        .getCollectionByVisualization(
                            projectId,
                            svgKey,
                            top,
                            skip
                        )
                        .then(({ deliverables }) =>
                            _parseResults(deliverables)
                        );

                    promises.push(loadPage);
                }

                return $q
                    .allSettled(promises)
                    .then((promiseResults) =>
                        promiseResults.map(({ value }) => value)
                    )
                    .then((responseDeliverables) =>
                        response.concat(_.flatten(responseDeliverables))
                    );
            });
    }

    function _parseResults(deliverables) {
        return deliverables.map((deliverable) =>
            _deliverableMapper(deliverable)
        );
    }

    function _deliverableMapper(deliverable) {
        return {
            ID: deliverable.id,
            TEMPLATE_ID: deliverable.process_template_id,
            PROJECT_ID: deliverable.project_id,
            NAME: deliverable.name,
            CODE: deliverable.code,
            CLAIM_QUANTITY: deliverable.claim_quantity,
            OBSTRUCTION_QUANTITY: deliverable.obstruction_quantity,
            ACTIVITIES: deliverable.activities.map((activity) =>
                _activityMapper(activity)
            ),
        };
    }

    function _activityMapper(activity) {
        return {
            ID: activity.id,
            IS_BEHIND: activity.is_behind_baseline,
            TOPOLOGICAL_INDEX: activity.topological_index,
            STATE: activity.state.name,
            STATE_CHANGED_AT: toValidMomentOrUndefined(
                activity,
                "state.reported.at"
            ),
            NAME: activity.name,
            STATE_NAME: activity.name,
            CONFIRMATION_TIME: toValidMomentOrUndefined(
                activity,
                "actual_schedule.confirmed.at"
            ),
            ASSIGNED_TEAM_ID: activity.responsible_team_id,
            CONFIRMATION_TEAM_ID: activity.confirmation_team_id,
            COLOR: activity.color,
            SD: toValidMomentOrUndefined(
                activity,
                "baseline_schedule.constraints.start_date"
            ),
            CD: toValidMomentOrUndefined(
                activity,
                "baseline_schedule.constraints.end_date"
            ),
            TEMPLATE_ID: activity.activity_template_id,
        };
    }

    /**
     * Determine the activity that we consider "latest stage reached"
     * -> means: from all the activities which are done, confirmed, rejected, waiting for confirmation
     *      -> take the "latest" by sequence, fall back to state change time
     *
     * @param activities
     * @returns {*}
     */
    function findLatestStageReached(activities) {
        return _.chain(activities)
            .filter(_isConsideredStageReached)
            .orderBy(
                ["TOPOLOGICAL_INDEX", "STATE_CHANGED_AT"],
                ["desc", "desc"]
            )
            .head()
            .value();
    }

    function _isConsideredStageReached(activity) {
        return _isWorkDone(activity) || _isRejected(activity);
    }

    function _isWorkDone(activity) {
        return SbActivityState.isWorkDone(activity.STATE);
    }

    function _isRejected(activity) {
        return SbActivityState.REJECTED === activity.STATE;
    }

    function _isWorkNotDone(activity) {
        return !SbActivityState.isWorkDone(activity.STATE);
    }

    function _isStarted(activity) {
        return SbActivityState.NOT_STARTED !== activity.STATE;
    }

    function _isBehind(activity) {
        return activity.IS_BEHIND;
    }

    /**
     * Determine the activity that we consider "currently active"
     * -> means: from all the activities which are NOT done -> take the one the has
     *      * highest progress
     *      * next in sequence
     *      * next by start date
     *
     * @param activities
     * @return {*}
     */
    function findCurrentActivity(activities) {
        let result = _.chain(activities)
            .filter(_isWorkNotDone)
            .orderBy(
                ["STATE", "TOPOLOGICAL_INDEX", "SD"],
                ["desc", "asc", "asc"]
            )
            .head()
            .value();

        // Activities can get an AVAILABLE state.
        // The order we get from the old implementation is
        // STARTED -> REJECTED -> NOT_STARTED -> AVAILABLE
        // The order we want to have though is
        // STARTED -> REJECTED -> AVAILABLE -> NOT_STARTED
        // So the idea is if you reach the NOT_STARTED activities change the
        // alphabetical order checked.

        if (result && result.STATE === "NOT_STARTED") {
            result = _.chain(activities)
                .filter(_isWorkNotDone)
                .orderBy(
                    ["STATE", "TOPOLOGICAL_INDEX", "SD"],
                    ["asc", "asc", "asc"]
                )
                .head()
                .value();
        }
        return result;
    }

    function setTeamValuesOnActivity(activity, teams) {
        if (!activity) {
            return;
        }

        activity.TEAM_ID = activity.ASSIGNED_TEAM_ID;
        const team = _.find(teams, ["id", activity.ASSIGNED_TEAM_ID]);

        if (team) {
            activity.TEAM_COLOR = team.color;
            activity.TEAM_NAME = team.getDisplayName();
        } else {
            activity.TEAM_COLOR = $sbTeam.UNRESTRICTED_ACCESS__TEAM_COLOR;
            activity.TEAM_NAME = $sbTeam.UNRESTRICTED_I18N_KEY;
        }

        return activity;
    }

    /**
     * Fetches the full configuration to generate a visualization.
     *
     * @param {number} svgConfigurationKey
     * @returns {Promise<DrawingConfiguration>}
     */
    function fetchConfigurationByKey(svgConfigurationKey) {
        return fetchVisualizationByKey(svgConfigurationKey).then(
            function (configuration) {
                const config = new DrawingConfiguration(
                    configuration.id,
                    configuration.name,
                    _.get(configuration, "definition.data.process_template.id")
                );

                config.setDisplayFor(
                    "row",
                    _.get(configuration, "definition.row_text.property")
                );
                config.setRangeFor(
                    "row",
                    _.get(configuration, "definition.row_text.from_index"),
                    _.get(configuration, "definition.row_text.to_index")
                );

                config.setDisplayFor(
                    "cell",
                    _.get(configuration, "definition.cell_text.property")
                );
                config.setRangeFor(
                    "cell",
                    _.get(configuration, "definition.cell_text.from_index"),
                    _.get(configuration, "definition.cell_text.to_index")
                );

                config.setTitle(configuration.name);
                config.setHeader(
                    _.get(configuration, "definition.header.for_cells"),
                    _.get(configuration, "definition.header.for_rows")
                );

                config.rows = _.get(
                    configuration,
                    "definition.data.structure_nodes"
                );
                config.cells = _.groupBy(
                    _.get(configuration, "definition.data.deliverables", []),
                    "structure_id"
                );
                return config;
            }
        );
    }

    /**
     * Upload a configuration for generating a 2D drawing.
     *
     * @param {String} projectId
     * @param {Object} drawingConfig
     */
    function uploadVisualizationSettingsFor(projectId, { name, svg, data }) {
        return $sbVisualizationsApi.create(projectId, {
            name,
            definition: {
                cell_text: {
                    property: _.get(svg, "codeProperty", "code"),
                    from_index: _.get(svg, "codeRange[0]", 0),
                    to_index: _.get(svg, "codeRange[1]", 0),
                },
                row_text: {
                    property: _.get(svg, "rowCodeProperty", "code"),
                    from_index: _.get(svg, "rowCodeRange[0]", 0),
                    to_index: _.get(svg, "rowCodeRange[1]", 0),
                },
                header: {
                    for_rows: svg.shortHeader,
                    for_cells: svg.longHeader,
                },
                data: {
                    process_template_id: data.template.id,
                    structure_node_ids: data.rows.map(({ id }) => id),
                },
            },
        });
    }

    /**
     * Fetches an uploaded visualization.
     *
     * @param {Number} svgKey
     */
    function fetchVisualizationByKey(svgKey) {
        return $sbVisualizationsApi.get($sbCurrentProject.pick("id"), svgKey);
    }

    /**
     * Fetches all types of visualizations of the project.
     *
     * Currently it contains uploaded and generated visualizations.
     *
     * @param projectId
     *
     * @return {Promise<{ uploaded: UploadedDrawing[], generated: GeneratedDrawing[] }>}
     */
    function fetchAllVisualizationsByProject(projectId) {
        return $sbVisualizationsApi
            .getCollection(projectId)
            .then(({ visualizations }) => {
                return visualizations.map((visualization) => {
                    if (_.has(visualization, "definition.file_id")) {
                        return toUploadedDrawing(visualization);
                    } else {
                        return toConfiguredDrawing(visualization);
                    }
                });
            });
    }

    function toConfiguredDrawing(configuration) {
        const config = new DrawingConfiguration(
            configuration.id,
            configuration.name
        );
        config.setTitle(configuration.title || configuration.name);
        config.setHeader(
            _.get(configuration, "definition.header.for_cells"),
            _.get(configuration, "definition.header.for_rows")
        );

        const drawing = config.createEmptyDrawing();
        drawing.setCreationDate(_.get(configuration, "created.at"));
        drawing.setCreator(_.get(configuration, "created.by.name"));
        drawing.setPreviewImageId(_.get(configuration, "preview.id"));
        return drawing;
    }

    function toUploadedDrawing(info) {
        const drawing = new UploadedDrawing(info.id, info.name);
        drawing.setResourceURI(_.get(info, "definition.file_id"));
        drawing.setCreator(_.get(info, "created.by.name"));
        drawing.setUploadDate(_.get(info, "created.at"));
        drawing.setPreviewImageId(_.get(info, "preview.id"));
        return drawing;
    }

    function uploadVisualization(file, projectId, name) {
        return $sbVisualizationsApi
            .upload(projectId, file, {
                filename: name,
            })
            .then(({ id, url }) =>
                $sbVisualizationsApi.create(projectId, {
                    name,
                    definition: {
                        file_id: id,
                    },
                })
            );
    }

    function removeVisualization(svgKey) {
        return $sbVisualizationsApi.delete(
            $sbCurrentProject.pick("id"),
            svgKey
        );
    }
}
