import angular from "angular";
import _ from "lodash";
import moment from "moment";

export default class LeanBoardStoreService {
    constructor($sbCurrentProject, $window, $sbFilterStore) {
        "ngInject";

        this.$window = $window;
        this.$sbCurrentProject = $sbCurrentProject;
        this.$sbFilterStore = $sbFilterStore;
    }

    getLastSelection() {
        const selection = this._retrieveSelectionIfNewerThanTwoDays(
            this._getKeyForLastSelectionOfProject(),
            {}
        );

        let storeFilters = this.$sbFilterStore.getLastFilterSelection({});

        return {
            ...selection,
            ...this._mapStoreToLeanboardFilters(storeFilters),
        };
    }

    setSelection(params) {
        const filteredParams = _.omitBy(params, _.isUndefined);

        const filtersToStore = this._mapLeanboardToStoreFilters(filteredParams);
        const displaySettingsToStore = _.omit(filteredParams, [
            "selectedTeam",
            "structureId",
            "selectedProcessTemplate",
        ]);

        this._storeValue(
            this._getKeyForLastSelectionOfProject(),
            displaySettingsToStore
        );

        this.$sbFilterStore.setLastFilterSelection(filtersToStore);
    }

    _getKeyForLastSelectionOfProject() {
        return "sb.leanboard.usage.params" + this.$sbCurrentProject.pick("id");
    }

    _retrieveSelectionIfNewerThanTwoDays(key, defaultValue) {
        let storedValue = _.defaultTo(
            angular.fromJson(this.$window.localStorage.getItem(key)),
            defaultValue
        );

        const isStoredValueOlderThanTwoDays = this._timestampOlderThan(
            storedValue.timestamp,
            2
        );

        if (storedValue.timestamp && isStoredValueOlderThanTwoDays) {
            storedValue = _.omit(storedValue, ["from", "to"]);
        }

        return _.omit(storedValue, ["timestamp"]);
    }

    _storeValue(key, value) {
        const clonedValue = _.clone(value);
        clonedValue.timestamp = moment().toISOString();
        return this.$window.localStorage.setItem(
            key,
            angular.toJson(clonedValue)
        );
    }

    clearAll() {
        this._removeValue(this._getKeyForLastSelectionOfProject());
    }

    _removeValue(key) {
        return this.$window.localStorage.removeItem(key);
    }

    _timestampOlderThan(timestamp, duration, granularity = "day") {
        return moment().diff(moment(timestamp), granularity) > 2;
    }

    _mapLeanboardToStoreFilters(filters) {
        // Since deliverable type is not yet used in the leanboard
        // we need to not overwrite while saving the new selection
        const deliverableType = this.$sbFilterStore.getLastFilterSelection(
            {}
        ).deliverableType;
        return {
            deliverableType: deliverableType,
            team: filters.selectedTeam,
            structure: filters.structureId,
            processTemplate: filters.selectedProcessTemplate,
        };
    }
    _mapStoreToLeanboardFilters(filters) {
        return {
            selectedTeam: filters.team,
            structureId: filters.structure,
            selectedProcessTemplate: filters.processTemplate,
        };
    }
}
