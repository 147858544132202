import { concat, find, flatMap, identity, map } from "lodash";

export default class Pager {
    /**
     * Private constructor. Use createPager instead.
     *
     * @private
     * @param config {Object}
     * @param config.limit {number}
     * @param [config.prefetchDistance] {number}
     * @param pagingSourceFactory {Function}
     */
    constructor(config, pagingSourceFactory) {
        this.config = config;
        this.pagingSourceFactory = pagingSourceFactory;

        // actual pagination data structure
        this.pagingSource = null;
        this.pagingData = [];
        this.currentPage = null;
        this.mapper = identity;
    }

    async init({ initialLoadCount } = {}) {
        // temporary overwrite paging source limit to load larger chunk on initial fetch
        const originalLimit = this.config.limit;
        let initialLimit = initialLoadCount || 0;
        if (initialLimit <= originalLimit) {
            initialLimit = originalLimit;
        }

        this.pagingSource = this.pagingSourceFactory({
            ...this.config,
            limit: initialLimit,
        });

        // load initial page with either increased limit or normal limit
        this.currentPage = await this.pagingSource.load();

        // restore original limit so subsequent fetches are again smaller
        this.pagingSource.config.limit = originalLimit;
        this.pagingData.push(this.currentPage);
    }

    totalCount() {
        return this.currentPage.total;
    }

    localCount() {
        return flatMap(this.pagingData, "data").length;
    }

    allLocalItems() {
        const items = map(
            concat([], flatMap(this.pagingData, "data")),
            this.mapper
        );
        return items;
    }

    mergePagingData(otherPager, identity) {
        const otherData = flatMap(otherPager.pagingData, "data");
        this.pagingData.forEach((page) => {
            page.data = page.data.map((data) => {
                const inOther = find(otherData, [identity, data[identity]]);
                if (inOther) {
                    return inOther;
                }
                return data;
            });
        });
    }

    async nextPage() {
        const page = await this.pagingSource.load(this.currentPage.nextKey);
        this.currentPage = page;
        this.pagingData.push(page);
        return this;
    }

    async prevPage() {
        const page = await this.pagingSource.load(this.currentPage.prevKey);
        this.currentPage = page;
        this.pagingData.unshift(page);
        return this;
    }
}

Pager.DEFAULT_LIMIT = 60;

/**
 * Factory function to create an initialized Pager instance.
 *
 * @param config {{ limit: number, initialLoadCount: number }}
 * @param pagingSourceFactory {Function}
 * @param [initialLimit] {number}
 *
 * @returns {Promise<Pager>}
 */
export async function createPager(config, pagingSourceFactory, initialLimit) {
    const newPager = new Pager(config, pagingSourceFactory);
    await newPager.init({
        initialLoadCount: initialLimit,
    });

    return newPager;
}
