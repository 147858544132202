/**
 *
 * Interface-like class to define the data object that is expected by
 * deliverable-jobs requests to create components.
 *
 * Also provides usefull and common methods to change the upload model
 * or do some checks on the upload model. Some Highlights:
 *  # removeStructureSingles    - remove root structuring nodes until there is more than one root
 *  # setBlockDeletion          - define if delete operations are allowed.
 *  # forAllComponentsChange    - set a propertry for all components to a specific value
 *
 */
import _ from "lodash";
import hash from "../services/hash.pojo";

const _castString = (v) => (_.isNil(v) ? undefined : _.toString(v));

function ComponentUploadModel(projectId) {
    this._projectId = projectId;
    this._source = undefined;
    this._sourceKey = undefined;
    this._components = [];
    this._edges = [];
    this._structuringNodes = undefined;
    this._isDeletionBlocked = false;
    this._isBringBackBlocked = false;
}

/**
 * static values to describe the key used for the category upload
 * @type {string}
 */
ComponentUploadModel.COMPONENT_CATEGORY_KEY = "cat";
/**
 * static values to describe database constraints for component and structure name
 * @type {number}
 */
ComponentUploadModel.MAX_NAME_LENGTH = 120;
/**
 * static values to describe database constraints for component and structure describtion
 * @type {number}
 */
ComponentUploadModel.MAX_DESC_LENGTH = 1000;
/**
 * static values to describe database constraints for component and structure code
 * @type {number}
 */
ComponentUploadModel.MAX_CODE_LENGTH = 64;
/**
 * static values to describe database constraints for component and structure external id
 * @type {number}
 */
ComponentUploadModel.MAX_EXTERNAL_ID_LENGTH = 64;

/**
 * Create the data structure used by the backend to handle a bulk data upload.
 */
ComponentUploadModel.prototype.dataForUpload = function () {
    return {
        projectId: this._projectId,
        deliverables: this.getServerReadyComponents(),
        edges: this.getServerReadyEdges(),
        source: this._source,
        sourceKey: this.getSourceKey(),
        structure: this.getServerReadyStructuringNodes(),
        isDeletionBlocked: this._isDeletionBlocked,
        isBringBackBlocked: this._isBringBackBlocked,
    };
};

/**
 * Pick up the structuring nodes and map them to a server ready object.
 * @returns {Array<Object>|undefined} The list of structuring nodes for upload.
 */
ComponentUploadModel.prototype.getServerReadyStructuringNodes = function () {
    if (!this._structuringNodes) {
        return undefined;
    }

    return this._structuringNodes.map(function (node) {
        return {
            ext: _castString(node.ext), // max 64
            parent_ext: _castString(node.parentExt), // max 64

            name: node.name.slice(0, ComponentUploadModel.MAX_NAME_LENGTH), // max 120
            code: node.code.slice(0, ComponentUploadModel.MAX_CODE_LENGTH), // max 64

            desc: node.desc
                ? node.desc.slice(0, ComponentUploadModel.MAX_DESC_LENGTH)
                : undefined, // max 1000
        };
    });
};

/**
 * Pick up the components and map them to a server ready object.
 * @returns {Array<Object>|undefined} The list of components for upload.
 */
ComponentUploadModel.prototype.getServerReadyComponents = function () {
    return this._components.map(function (comp) {
        return {
            ext: _castString(comp.ext), // max 64

            name: comp.name.slice(0, ComponentUploadModel.MAX_NAME_LENGTH), // max 120
            code: comp.code.slice(0, ComponentUploadModel.MAX_CODE_LENGTH), // max 64

            desc: comp.desc
                ? comp.desc.slice(0, ComponentUploadModel.MAX_DESC_LENGTH)
                : undefined, // max 1000

            cat: comp.cat,

            sd: typeof comp.sd === "number" ? comp.sd : undefined,
            cd: typeof comp.cd === "number" ? comp.cd : undefined,

            structure_ext: _castString(comp.structure), // actually strucure ext (max 64)
        };
    });
};

ComponentUploadModel.prototype.getServerReadyEdges = function () {
    return this._edges;
};

/**
 * Check if either the current list of components or the current list of structuing nodes
 * has strings that are exceeding the DB limits
 *
 * @returns {boolean} True if there is at least one property that is exceeding.
 */
ComponentUploadModel.prototype.hasStringsExceedingDBCapacity = function () {
    function _exceeds(name, constraint) {
        return name ? name.length > constraint : false;
    }

    function isToLong(element) {
        return (
            _exceeds(element.name, ComponentUploadModel.MAX_NAME_LENGTH) ||
            _exceeds(element.code, ComponentUploadModel.MAX_CODE_LENGTH) ||
            _exceeds(element.desc, ComponentUploadModel.MAX_DESC_LENGTH)
        );
    }

    return (
        this._components.some(isToLong) || this._structuringNodes.some(isToLong)
    );
};

/**
 * Get the project ID of the model upload
 * @returns {string} The project ID
 */
ComponentUploadModel.prototype.project = function () {
    return this._projectId;
};

/**
 * Set the upload model's project reference.
 * @param {string} id
 */
ComponentUploadModel.prototype.setProject = function (id) {
    this._projectId = id;
    return this;
};

/**
 * Get the amount of deliverables that are in the model
 * @returns {number} The size of the components array
 */
ComponentUploadModel.prototype.quantity = function () {
    return this._components.length;
};

/**
 * Get the source object of the model
 * @returns {Object|undefined}
 */
ComponentUploadModel.prototype.source = function () {
    return this._source;
};

/**
 * Set the source key -> is used on merge upload as merge partner
 * @param {number} key The source pseudo key.
 */
ComponentUploadModel.prototype.setSourceKey = function (key) {
    this._sourceKey = key;
    return this;
};
/**
 * get the source key -> is used on merge upload as merge partner
 * @returns {number|undefined} The source pseudo key.
 */
ComponentUploadModel.prototype.getSourceKey = function () {
    if (_.isUndefined(this._sourceKey)) return undefined;
    return Number.parseInt(this._sourceKey, 10);
};

/**
 * Check if the upload model is requesting an update/merge of an existing
 * project source.
 * @returns {boolean}
 */
ComponentUploadModel.prototype.isMergeRequest = function () {
    if (this._sourceKey !== undefined) {
        return true;
    } else {
        return false;
    }
};

/**
 * Set the source object -> that means most likely that you are creating new data (no merge)
 * @param {Object} obj The object defining source
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.setSourceObject = function (obj) {
    this._source = obj;
    return this;
};

/**
 * Set the file name of the source object if there is one.
 * @param {string} fileName
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.setSourceFileName = function (fileName) {
    if (this._source) {
        this._source.data.fileName = fileName;
    }
    return this;
};

/**
 * Set the components to upload
 * @param {Array<Object>} list The components for upload
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.setComponents = function (list) {
    this._components = list.map(function (component) {
        component.structure = externalIdToHash(component.structure);
        return component;
    });
    return this;
};

ComponentUploadModel.prototype.components = function () {
    return this._components;
};

/**
 * Set the list of structuring nodes to the model.
 * @param {Array<Object>} structuringNodes The plain list of node objects.
 */
ComponentUploadModel.prototype.setStructuringNodeList = function (
    structuringNodes
) {
    // make the external ID an integer based on a hash function
    structuringNodes = structuringNodes.map(function (node) {
        // hash the external ID
        node.ext = externalIdToHash(node.ext);
        // hash the external ID of the parent
        node.parentExt = externalIdToHash(node.parentExt);
        return node;
    });

    // only unique nodes are allowed
    this._structuringNodes = _.uniqBy(structuringNodes, function (node) {
        return node.ext;
    });

    return this;
};

function externalIdToHash(externalIdString) {
    var integerHash = hash(externalIdString);
    return integerHash ? integerHash.toString() : null;
}

ComponentUploadModel.prototype.structuringNodes = function () {
    return this._structuringNodes;
};

/**
 * Set the edges for upload
 * @param {{src: String, trg: String}[]} list The edges for upload ("src" and a "trg" property has to match an external ID of the components.)
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.setEdges = function (list) {
    this._edges = list;
    return this;
};

/**
 * Set the is deletion blocked flag.
 * @param {boolean} shouldBlock true if deleting is forbidden.
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.setBlockDeletion = function (shouldBlock) {
    this._isDeletionBlocked = shouldBlock;
    return this;
};

/**
 * Set the is bring back blocked flag.
 * @param {boolean} shouldBlock true if bring back is forbidden.
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.setBlockBringBack = function (shouldBlock) {
    this._isBringBackBlocked = shouldBlock;
    return this;
};

/**
 * Change a property of all components to the given value.
 * @param {string} property The property to change
 * @param {*} value The value to change the property to.
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.forAllComponentsChange = function (
    property,
    value
) {
    this._components.forEach(function (component) {
        component[property] = value;
    });
    return this;
};

/**
 * Sometimes it is valuable to remove structuring node roots that have no siblings.
 * E.g.: For MSP, ASTA and Primavera.
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.removeStructureSingles = function () {
    // find roots -> if just one -> remove him with references.
    //
    var roots = this._structuringNodes.filter(function (node) {
        return !node.parentExt;
    });
    if (roots.length === 1) {
        this.removeStructuringNode(roots[0]);
        this.removeStructureSingles();
    }
    return this;
};

/**
 * Remove a structuring node completely form the upload model with all references.
 * @param {Object} nodeToRemove The node object that should be removed
 * @returns {ComponentUploadModel} Return this for method chaining
 */
ComponentUploadModel.prototype.removeStructuringNode = function (nodeToRemove) {
    // remove the node
    this._structuringNodes = this._structuringNodes.filter(function (node) {
        return node.ext !== nodeToRemove.ext;
    });
    // clean parentExt references
    this._structuringNodes.forEach(function (node) {
        if (node.parentExt === nodeToRemove.ext) {
            node.parentExt = undefined;
        }
    });
    // clean component references
    this._components.forEach(function (component) {
        if (component.structure === nodeToRemove.ext) {
            component.structure = undefined;
        }
    });
    return this;
};

export default ComponentUploadModel;
