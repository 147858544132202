import Pagination from "./model/pagination.class";
import VirtualPagination from "./model/virtual_pagination.class";

export default function PaginationService() {
    "ngInject";
    return {
        getNewPaginator(items, itemsPerPage) {
            return new Pagination(items, itemsPerPage);
        },
        getNewVirtualPaginator(pager, config) {
            return new VirtualPagination(pager, config);
        },
    };
}
