export default function (module) {
    module.config(function ($mdThemingProvider) {
        $mdThemingProvider.definePalette("RiemiRed", {
            50: "D99B9B",
            100: "D18686",
            200: "CA7272",
            300: "C25E5E",
            400: "BB4A4A",
            500: "B33636",
            600: "A13131",
            700: "8F2B2B",
            800: "7D2626",
            900: "6B2020",
            A100: "FFC0C0",
            A200: "F99090",
            A400: "FF5A5A",
            A700: "FF4141",
            contrastDefaultColor: "light", // whether, by default, text (contrast) on this palette should be dark or light
            contrastDarkColors: [
                "50",
                "100", //hues which contrast should be 'dark' by default
                "200",
                "300",
                "A100",
            ],
            contrastLightColors: undefined, // could also specify this if default was 'dark'
        });

        $mdThemingProvider.definePalette("Florange", {
            50: "F4BF80",
            100: "F1B266",
            200: "EFA54D",
            300: "ED9833",
            400: "EA8B1A",
            500: "E87E00",
            600: "D17100",
            700: "BA6500",
            800: "A25800",
            900: "8B4C00",
            A100: "FFDEC0",
            A200: "F9C490",
            A400: "FF9B41",
            A700: "FF9B41",
            contrastDefaultColor: "light", // whether, by default, text (contrast) on this palette should be dark or light
            contrastDarkColors: [
                "50",
                "100", //hues which contrast should be 'dark' by default
                "200",
                "300",
                "A100",
            ],
            contrastLightColors: undefined, // could also specify this if default was 'dark'
        });

        $mdThemingProvider.definePalette("SablonoGreen", {
            50: "9BD9C0",
            100: "86D1B3",
            200: "72CAA7",
            300: "5EC29A",
            400: "4ABB8E",
            500: "36B381",
            600: "31A174",
            700: "2B8F67",
            800: "267D5A",
            900: "206B4D",
            A100: "C0FFDE",
            A200: "90F9C4",
            A400: "5AFFA8",
            A700: "41FF9B",
            contrastDefaultColor: "light", // whether, by default, text (contrast) on this palette should be dark or light
            contrastDarkColors: [
                // should be in line with sb-bimtime/web-apps/apps/bimtime/src/styles.scss:29
                "50",
                "100", //hues which contrast should be 'dark' by default
                "200",
                "300",
                "400",
                "A100",
                "A200",
                "A400",
            ],
            contrastLightColors: undefined, // could also specify this if default was 'dark'
        });

        $mdThemingProvider
            .theme("default")
            .primaryPalette("SablonoGreen")
            .accentPalette("SablonoGreen", {
                default: "500",
            })
            .warnPalette("RiemiRed");
    });
}
