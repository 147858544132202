import angular from "angular";
import _ from "lodash";
import escapeStringRegexp from "escape-string-regexp";
import htmlTemplate from "./structure_autocomplete.html";

export default angular
    .module("sbApp.sbStructureAutocomplete", [])
    .component("sbStructureAutocomplete", {
        templateUrl: htmlTemplate,
        controllerAs: "structure",
        bindings: {
            model: "<",
            options: "<",
            placeholder: "<",
            isDisabled: "=",
            onSelectionChange: "=",
        },
        controller: function StructureAutocompleteCtrl($q, $filter) {
            /////////////////////
            //
            //      Direct variables
            //
            /////////////////////

            var vm = this;

            /////////////////////
            //
            //      View model properties
            //
            /////////////////////

            vm.itemModel;
            vm.querySearch = querySearch;

            /////////////////////
            //
            //      Lifecycle Hooks
            //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
            //
            /////////////////////

            vm.$onChanges = function (change) {
                if (change.model && change.model.currentValue) {
                    var curModel = change.model.currentValue;
                    if (_.isEmpty(curModel)) {
                        vm.itemModel = null;
                    } else {
                        vm.itemModel = _.assign(vm.itemModel, {
                            id:
                                change.model.currentValue.ID ||
                                change.model.currentValue.id,
                            label: _computeLabel(change.model.currentValue),
                        });
                    }
                }
            };

            /////////////////////
            //
            //      IMPL
            //
            /////////////////////

            function _computeLabel(data) {
                if (angular.isString(data.label)) {
                    return data.label;
                }
                if (angular.isArray(data.PARENT_PATH)) {
                    return $filter("asPathToParent")(data, "PARENT_PATH");
                }
                return data.NAME || data.name;
            }

            /**
             * querySearch - triggered when searching for structure
             *
             * @param  {String} searchText - The string to be searched
             * @return {Promise}           - An always resolving promise that returns the filtered structure nodes
             */
            function querySearch(searchText) {
                var structureOptions = vm.options;
                if (searchText) {
                    searchText = escapeStringRegexp(searchText);

                    // replace "/" with .* for any character in "regex"
                    searchText = searchText.replace(/\s*[//]\s*/g, ".*");

                    // create a regex like: Hallo World 12*.asd/i
                    var searchRegEx = new RegExp(searchText, "i");

                    return $q.resolve(
                        structureOptions.filter(function (option) {
                            // test label and code
                            return (
                                searchRegEx.test(option.label) ||
                                searchRegEx.test(option.code)
                            );
                        })
                    );
                } else {
                    return $q.resolve(structureOptions);
                }
            }
        },
    });
