import _ from "lodash";
import html from "./activities_list_selector_searchbar.html";

export default {
    templateUrl: html,
    bindings: {
        placeholder: "@",
    },
    controller: function ActivitiesListSelectorSearchbarCtrl(
        $scope,
        $sbActivitiesListSelector
    ) {
        "ngInject";

        var vm = this;

        var _allActivities = [];

        vm.getMatches = getMatches;
        vm.onSearchTextChange = onSearchTextChange;

        $scope.$watch(
            function () {
                return $sbActivitiesListSelector.getActivities();
            },
            function (activities) {
                _allActivities = activities;
            }
        );

        function getMatches() {
            return [];
        }

        function onSearchTextChange(searchText) {
            $sbActivitiesListSelector.unselectAllActivities();

            var filteredActivities = _allActivities.filter(function (activity) {
                var searchValues = _getSearchValues(activity);

                return _.some(searchValues, function (searchValue) {
                    return (
                        searchValue
                            .toLowerCase()
                            .indexOf(searchText.toLowerCase()) > -1
                    );
                });
            });

            $sbActivitiesListSelector.setFilteredActivities(filteredActivities);

            return [];
        }

        function _getSearchValues(activity) {
            const searchValues = [];
            searchValues.push(activity.model.getDisplayPath());
            searchValues.push(
                _.defaultTo(_.get(activity.model, "assignedTeam.name"), "")
            );
            searchValues.push(
                _.defaultTo(_.get(activity.model, "processTemplateName"), "")
            );
            return searchValues;
        }
    },
};
