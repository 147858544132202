import moment from "moment";

function Checklist(id, name) {
    this.id = id;
    this.name = name;

    this.date = undefined;
    this.author = undefined;

    this.items = undefined;
}

Checklist.prototype.setDate = function (timestamp) {
    this.date = moment(timestamp);
    return this;
};

Checklist.prototype.getDate = function () {
    return this.date;
};

Checklist.prototype.setAuthor = function (author) {
    this.author = author;
    return this;
};

Checklist.prototype.getAuthor = function () {
    return this.author;
};

/**
 * @param {Array<ChecklistItem>} items
 */
Checklist.prototype.setItems = function (items) {
    this.items = items;
    return this;
};

Checklist.prototype.getItems = function () {
    return this.items;
};

export default Checklist;
