import angular from "angular";
import html from "./invited_member_card.html";

export default {
    templateUrl: html,
    controllerAs: "$ctrl",
    bindings: {
        invitation: "<",
        onEditInvitationClick: "&",
    },
    controller: function InvitedMember($translate) {
        "ngInject";
        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        vm.mappedMember = mapInvitationToMember();

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = angular.noop;
        vm.$onChanges = angular.noop;
        vm.$onDestroy = angular.noop;

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        function mapInvitationToMember() {
            /**
             * See expected Invitation object schema
             * http://localhost:8080/#tag/membership_invitations/operation/get_membership_invitations_of_project
             */
            return {
                initials: vm.invitation.contact.email
                    .substring(0, 2)
                    .toUpperCase(),
                username: "",
                mail:
                    vm.invitation.membership.team.name === "PROJECT_TEAM"
                        ? $translate.instant("_PROJECT_TEAM")
                        : vm.invitation.membership.team.name,
                displayName: vm.invitation.contact.email,
                role: vm.invitation.membership.role.name,
                roleId: vm.invitation.membership.role.id,
                teamId: vm.invitation.membership.team.id,
                hasAreaManagerPrivilege: false,
            };
        }
    },
};
