import _ from "lodash";

function Pagination(items, itemsPerPage) {
    this.items = items || [];
    this.itemsPerPage = Number.parseInt(itemsPerPage);

    this.currentPageSelection = 1;
    this.setNumberOfPages();
    this.range = {
        from: 0,
        to: this.itemsPerPage,
    };
}

Pagination.prototype.setNumberOfPages = function () {
    this.numberOfPages = Math.ceil(
        this.items.length / Number.parseInt(this.itemsPerPage)
    );
};

Pagination.prototype.getPage = function (pageNumber) {
    if (this.numberOfPages === 0) {
        return this.items;
    }

    if (pageNumber < 1) {
        throw new Error("Page number must be greater than or equal to 1");
    }

    if (pageNumber > this.numberOfPages) {
        throw new Error(
            "Page number must be less than or equal to number of pages"
        );
    }

    this.currentPageSelection = Number.parseInt(pageNumber);

    this.resetRange();

    // the first page will already be within the range
    //
    for (var i = 1; i < this.currentPageSelection; i++) {
        this.moveRangeBy(this.itemsPerPage);
    }

    return this.getItemsInRange();
};

Pagination.prototype.resetRange = function () {
    this.range = {
        from: 0,
        to: this.itemsPerPage,
    };

    return this;
};

Pagination.prototype.getNextPage = function () {
    this.currentPageSelection += 1;

    this.moveRangeBy(this.itemsPerPage);
    return this.getItemsInRange();
};

Pagination.prototype.getPrevPage = function () {
    this.currentPageSelection -= 1;

    this.moveRangeBy(-1 * this.itemsPerPage);
    return this.getItemsInRange();
};

Pagination.prototype.getFirstPage = function () {
    return this.getPage(1);
};

Pagination.prototype.getLastPage = function () {
    return this.getPage(this.numberOfPages);
};

Pagination.prototype.hasNextPage = function () {
    return this.range.to < this.getNumberOfItems();
};

Pagination.prototype.hasPrevPage = function () {
    return this.range.from > 0;
};

Pagination.prototype.getItemsInRange = function () {
    return this.getItemsBetween(this.range.from, this.range.to);
};

Pagination.prototype.getItemsBetween = function (from, to) {
    return _.slice(this.items, from, to);
};

Pagination.prototype.moveRangeBy = function (value) {
    this.range.from += value;
    this.range.to += value;

    return this;
};

Pagination.prototype.getNumberOfItems = function () {
    return this.items.length;
};

Pagination.prototype.getPageNumbersAsObjectArray = function () {
    var pages = [];

    for (var i = 0; i < this.numberOfPages; i++) {
        var pageNumber = i + 1;

        pages.push({
            number: pageNumber,
            isCurrentlySelected: this.isPageSelected(pageNumber),
        });
    }

    return pages;
};

Pagination.prototype.isPageSelected = function (pageNumber) {
    return this.currentPageSelection === pageNumber;
};

export default Pagination;

//////////
