import _ from "lodash";
import AuthenticationError from "common/errors/AuthenticationError";

export default function InspectRequestCtrl(
    $scope,
    $q,
    $sbInspectionRequest,
    SbBaseComponent,
    $mdDialog,
    $sbErrorPresenter,
    $timeout,
    $rootScope,
    EVENTS,
    $stateParams,
    Analytics,
    $sbProjectApi,
    $sbCurrentProject,
    $sbMembership
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var errors = {
        ERROR_INSPECT_REQUEST_USER_NOT_ASSIGNED: {
            TITLE: "SECTION_REQUEST_ERROR_PERMISSION_DENIED_TITLE",
            TEXT: "SECTION_REQUEST_ERROR_PERMISSION_DENIED_TEXT",
        },
        ERROR_INSPECT_REQUEST_CLOSED: {
            TITLE: "SECTION_REQUEST_ERROR_CLOSED_TITLE",
            TEXT: "SECTION_REQUEST_ERROR_CLOSED_TEXT",
        },
        ERROR_INSPECT_REQUEST_DOES_NOT_EXIST: {
            TITLE: "SECTION_REQUEST_ERROR_NOT_EXIST_TITLE",
            TEXT: "SECTION_REQUEST_ERROR_NOT_EXIST_TEXT",
        },
    };

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    var vm = this;

    vm.onStateChange = onStateChange;
    vm.onOptOutClick = onOptOutClick;
    vm.sendUpdate = sendUpdate;
    vm.$onInit = $onInit;

    vm.error = false;
    vm.success = false;
    vm.deliverables = [];
    vm.currentError = {
        TITLE: "ERROR_UNKNOWN_TITLE",
        TEXT: "ERROR_UNKNOWN_MESSAGE",
    };

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onInit() {
        vm.loading = true;
        vm.deliverables = [];
        vm.error = false;
        vm.token = $stateParams.tokenId;

        Analytics.trackEvent("Request for update", "open link", "");
        $sbInspectionRequest
            .getRequestContent(vm.token)
            .then((data) => {
                if (data.deliverables && data.deliverables.length > 0) {
                    const projectId = data.deliverables[0].projectId;

                    // load project and membership so they are cached for permission evaluation
                    //
                    return $q
                        .all([
                            $sbProjectApi
                                .get(projectId)
                                .then($sbCurrentProject.set),
                            $sbMembership.get(projectId), // will be cached internally
                        ])
                        .then(() => data);
                }
                return data;
            })
            .then(function (data) {
                // Set flatten activities for every deliverable
                if (data && data.deliverables) {
                    if (data.deliverables.length > 0) {
                        vm.projectId = data.deliverables[0].projectId;
                    }
                    vm.deliverables = _.filter(
                        data.deliverables,
                        function (deliverable, index) {
                            deliverable.activities =
                                deliverable.getActivities();
                            deliverable.dirty = false;
                            deliverable.open = index === 0;

                            return deliverable.activities.length > 0;
                        }
                    );
                } else {
                    if (data.dateClosed) {
                        return _handleError(
                            new Error("ERROR_INSPECT_REQUEST_CLOSED")
                        );
                    }
                }
                vm.loading = false;
            })
            .catch(_handleError);
    }

    function _handleError(error) {
        if (error instanceof AuthenticationError) {
            $sbErrorPresenter.catch(error);
        }
        vm.error = true;
        if (errors[error.message]) {
            vm.currentError = errors[error.message];
        }
    }

    function onStateChange(activity, fromState, toState) {
        activity.setCurrentState(toState);
        activity.dirty = true;
        activity.getParent().dirty = true;

        Analytics.trackEvent(
            "Request for update",
            "Progress change",
            "RfU list before saving"
        );
    }

    function sendUpdate() {
        vm.loading = true;
        return $sbInspectionRequest
            .sendRequestResult(vm.deliverables, $stateParams.tokenId)
            .then(function () {
                vm.loading = false;
                vm.success = true;

                Analytics.trackEvent(
                    "Request for update",
                    "request fulfilled",
                    ""
                );
            })
            .catch(_handleError);
    }

    function onOptOutClick() {
        Analytics.trackEvent("Request for update", "opt-out", "");
    }
}
