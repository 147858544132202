import html from "./request_action_dialog.template.html";

export default {
    templateUrl: html,
    controllerAs: "dialog",
    controller: function RequestActionDialogCtrl($mdDialog, text, info) {
        "ngInject";
        var vm = this;

        vm.text = text;
        vm.info = info;

        vm.close = handleClose;
        vm.ok = handleOk;

        function handleOk() {
            $mdDialog.hide();
        }

        function handleClose() {
            $mdDialog.cancel();
        }
    },
};
