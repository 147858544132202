export default function PendingInviteCardCtrl($sbProjectApi) {
    "ngInject";

    var vm = this;

    vm.image = {
        url: undefined,
    };

    vm.handleAcceptClick = handleAcceptClick;
    vm.handleRejectClick = handleRejectClick;

    vm.$onInit = function () {
        fetchProjectImage();
    };

    function fetchProjectImage() {
        const projectId = vm.project.id;
        const imageId = vm.project.imageId;

        return $sbProjectApi.fetchLogo(projectId, imageId).then((image) => {
            vm.image.url = image.dataUrl;
        });
    }

    function handleAcceptClick() {
        vm.onAccept(vm.project);
    }

    function handleRejectClick() {
        vm.onDecline(vm.project);
    }
}
