import _ from "lodash";
import Membership from "./membership.class";

export default function MembershipService(
    $q,
    $sbRequest,
    SbActivity,
    SbDeliverable,
    $sbMembershipsApi,
    $sbMembershipInvitationsApi
) {
    "ngInject";

    let _currentMembership;

    return {
        current: current,
        currentTeam: currentTeam,
        currentUser: currentUser,
        currentRole: currentRole,
        get: get,
        clearCache: clearCache,
        reloadCurrent: reloadCurrent,
        canReportProgressFor: canReportProgressFor,
        getMembershipInvitationsForProject: getMembershipInvitationsForProject,
        getMembershipInvitationsForUser: getMembershipInvitationsForUser,
        acceptMembershipInvitation: acceptMembershipInvitation,
        rejectMembershipInvitation: rejectMembershipInvitation,
        inviteUser: inviteUser,
        addCommercialUser: addCommercialUser,
        deleteCommercialUser: deleteCommercialUser,
    };

    /**
     * Invites user to the project
     *
     * @param  {String} email    - User's email
     * @param  {int} teamId         - The id of the team in which the user should be inserted
     * @param  {String} roleName    - The name of the role that user should get
     * @param  {String} projectId   - The id of the project
     * @return {Promise}
     */
    function inviteUser(email, teamId, roleName, projectId) {
        return $sbMembershipInvitationsApi.create(projectId, {
            email,
            team_id: teamId,
            role_name: roleName,
        });
    }

    /**
     * Add commercial access privileges for a user.
     *
     * @param {string} projectId
     * @param {number} userId
     * @param {number} teamId
     * @param {string} privilege
     *
     * @return {Promise}
     */
    function addCommercialUser(projectId, userId, teamId, privilege) {
        return $sbMembershipsApi.createCommercialPrivileges(projectId, userId, {
            teamId,
            privilege,
        });
    }

    /**
     * Remove commercial access privileges for a user.
     *
     * @param {string} projectId
     * @param {string} userId
     * @param {number} teamId
     *
     * @return {Promise}
     */
    function deleteCommercialUser(projectId, userId, teamId) {
        return $sbMembershipsApi.deleteCommercialPrivileges(
            projectId,
            userId,
            teamId
        );
    }

    /**
     * Returns all invitations for project by project id
     *
     * @param {string} [projectId]
     * @returns {Promise}
     */
    function getMembershipInvitationsForProject(projectId) {
        return $sbMembershipInvitationsApi
            .getByProject(projectId)
            .then((data) => data.invitations);
    }

    /**
     * Returns all invitations for current user
     *
     * @returns {Promise}
     */
    function getMembershipInvitationsForUser(projectId) {
        return $sbMembershipInvitationsApi
            .getForCurrentUser(projectId)
            .then((data) => data.invitations);
    }

    /**
     * Accepts membership invitation for current user
     *
     * @returns {Promise}
     */
    function acceptMembershipInvitation(invitationId) {
        return $sbMembershipInvitationsApi.accept(invitationId);
    }

    /**
     * Accepts membership invitation for current user
     *
     * @returns {Promise}
     */
    function rejectMembershipInvitation(invitationId) {
        return $sbMembershipInvitationsApi.reject(invitationId);
    }

    //////////

    /**
     * Returns the membership of the currently signed in user
     *
     * @param {string} [projectId]
     * @param {string} [userId]
     *
     * @returns {Object<Membership>} _currentMembership
     */
    function get(projectId, userId) {
        if (_isCached()) {
            return $q.resolve(current());
        } else {
            if (!projectId || !userId) {
                return $q.reject(
                    new Error(
                        `Missing required parameters: 'projectId' or 'userId'. projectId: ${projectId}, userId: ${userId}`
                    )
                );
            }

            return _fetchMembership(projectId, userId)
                .then(_parseToModel)
                .then(_setCurrent)
                .then(current);
        }
    }

    function clearCache() {
        _setCurrent(undefined);
    }

    function reloadCurrent() {
        const userName = _.get(_currentMembership, "user.userName");
        const projectId = _.get(_currentMembership, "team.projectId");
        clearCache();
        return get(projectId, userName);
    }

    /**
     * Returns whether the currently signed in user can report progress for an activity
     * Note we don't perform any checks on whether the deliverable is the actual deliverable
     * of the activity.
     *
     * @param {Object<Deliverable | ODataDeliverable>} deliverable of the activity
     * @param {Object<Activity>} activity
     * @param {Membership} membership
     *
     * @returns {boolean}
     */
    function canReportProgressFor(deliverable, activity, membership) {
        if (!(activity instanceof SbActivity)) {
            throw new Error(
                "canReportProgressFor must be invoked with an instance of Activity"
            );
        }
        // check if user is in a responsible team (work, review or confirmation)
        //
        if (!activity.isAccessibleByTeam(membership.team)) {
            return false;
        }

        // user is in one of the responsible teams and there
        // is no area manager restrictions on the project
        //
        if (!membership.hasAreaManagerRestrictions) {
            return true;
        }

        // there are area manager restrictions
        // -> check if user is responsible manager of deliverable
        //
        const areaManagerOfActivity = _getAreaManagerUserName(deliverable);
        const isResponsibleAreaManager =
            membership.user.userName === areaManagerOfActivity;
        return !areaManagerOfActivity || isResponsibleAreaManager;
    }

    function _getAreaManagerUserName(deliverable) {
        if (deliverable instanceof SbDeliverable) {
            return deliverable.areaManagerUserName;
        } else {
            return deliverable.AREA_MANAGER_USER_NAME;
        }
    }

    function _fetchMembership(projectId, userId) {
        return $sbMembershipsApi.get(projectId, userId).then((membership) => {
            membership.team.project_id = projectId;
            return membership;
        });
    }

    function _parseToModel({ role, team, user, commercial_access }) {
        const membership = new Membership(role, team.has_area_restrictions);
        membership.setUser({
            displayName: user.name,
            initials: user.initials,
            userName: user.id,
        });
        membership.setTeam({
            id: team.id,
            name: team.name,
            color: team.color,
            isProjectTeam: team.is_project_team,
            projectId: team.project_id,
        });
        membership.setCommercialAccess(
            commercial_access.map((commercialAccessEntry) => {
                return {
                    team: commercialAccessEntry.on_team,
                    privilege: commercialAccessEntry.privilege,
                };
            })
        );

        return membership;
    }

    /**
     * @param {Object<Membership | undefined>} value
     */
    function _setCurrent(value) {
        _currentMembership = value;
    }

    function current() {
        return _currentMembership;
    }

    function currentTeam() {
        return _.get(_currentMembership, "team", {});
    }

    function currentUser() {
        return _.get(_currentMembership, "user", {});
    }

    function currentRole() {
        return _.get(_currentMembership, "role", {});
    }

    function _isCached() {
        return !_.isUndefined(_currentMembership);
    }
}
