import angular from "angular";
import _ from "lodash";
import htmlTemplate from "./choice_group.tmpl.html";

export default angular
    .module("sbApp.common.components.choiceGroup", [])
    .component("sbChoiceGroup", {
        replace: true,
        templateUrl: htmlTemplate,
        bindings: {
            choices: "<",
            onChoiceClicked: "&",
        },
        controller: function ChoiceGroupCtrl() {
            var vm = this;

            vm.$doCheck = $doCheck;
            vm.isClickEnabled = isClickEnabled;
            vm.onClick = onClick;

            var previousChoices;

            function $doCheck() {
                //$doCheck is called every digest cycle.
                //we need it because $onChanges does not check for nested changes but angular.equals does
                if (!angular.equals(previousChoices, vm.choices)) {
                    previousChoices = angular.copy(vm.choices);
                    var choices = !angular.isArray(vm.choices)
                        ? toArrayWithKey(vm.choices, "key")
                        : vm.choices;
                    vm.transformedChoices = transform(choices);
                }
            }

            function transform(choices) {
                return choices.map(function (choice) {
                    return {
                        key: choice.key,
                        customClass: choice.customClass,
                        disabled: choice.disabled || false,
                        loading: choice.loading || false,
                        icon1: choice.icon1,
                        icon2: choice.icon2,
                        image: choice.image,
                        title: choice.title,
                        titleValues: choice.titleValues,
                        description: choice.text,
                        descriptionValues: choice.textValues,
                        warning: choice.warning || false,
                        warningText: choice.warningText,
                        warningTextValues: choice.warningTextValues,
                        error: choice.error || false,
                        errorText: choice.errorText,
                        errorTextValues: choice.errorTextValues,
                    };
                });
            }

            function toArrayWithKey(obj, keyAs) {
                return _.values(
                    _.mapValues(obj, function (value, key) {
                        value[keyAs] = key;
                        return value;
                    })
                );
            }

            function isClickEnabled(choice) {
                return !choice.disabled && !choice.loading && !choice.error;
            }

            function onClick(choice, index) {
                if (!isClickEnabled(choice)) {
                    return;
                }
                vm.onChoiceClicked({
                    key: choice.key,
                    choice: vm.choices[index],
                });
            }
        },
    });
