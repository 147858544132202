import createjs from "createjs";

/**
 * Event that is dispatched when mouse leaves something
 * @constructs MouseInEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function MouseOutEvent() {
    createjs.Event.call(this, "mouseOut", true, true);
}

MouseOutEvent.prototype = Object.create(createjs.Event.prototype);

export default MouseOutEvent;
