export default function ProjectCardCtrl(
    $sbProjectApi,
    $sbPageNavigation,
    $sbCurrentProject,
    $timeout
) {
    "ngInject";

    var vm = this;

    vm.image = {
        url: undefined,
    };

    vm.selectProject = selectProject;
    vm.$onInit = function () {
        fetchProjectImage();
    };

    function fetchProjectImage() {
        const projectId = vm.project.id;
        const imageId = vm.project.imageId;

        return $sbProjectApi.fetchLogo(projectId, imageId).then((image) => {
            vm.image.url = image.dataUrl;
        });
    }

    function selectProject(project) {
        $sbCurrentProject.set(project);
        $timeout(() => $sbPageNavigation.goToLandingPage(project), 10);
    }
}
