import moment from "moment";
import _ from "lodash";

export default function TimeLineDetailCtrl(
    $sbDetailsOverlay,
    $stateParams,
    $sbInspectionsApi,
    $sbSiteInspections
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;
    vm.$onInit = $onInit;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.createdNotes = undefined;
    vm.resolvedNotes = undefined;
    vm.deliverables = undefined;

    vm.openOverlayFor = (id) => {
        $sbDetailsOverlay.toggleView("deliverable", id);
    };

    vm.parseDate = function (date) {
        return moment(date).format("llll");
    };

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onInit() {
        return fetchDetails({
            projectId: $stateParams.projectId,
            inspectionId: $stateParams.inspection,
        });
    }

    function fetchDetails({ projectId, inspectionId }) {
        return $sbInspectionsApi
            .get(projectId, inspectionId)
            .then($sbSiteInspections.parseServerInspectionDetails)
            .then(({ createdNotes, resolvedNotes, deliverables }) => {
                vm.createdNotes = createdNotes;
                vm.resolvedNotes = resolvedNotes;
                vm.deliverables = deliverables;
            });
    }
}
