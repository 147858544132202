import angular from "angular";
import issuesRouteConfig from "./issues.route";
import issuesCtrl from "./issues.controller";
import sbIssueRow from "./components/issue_row.component";
import IssueReportDialogConfig from "./dialogs/issue_report.config";
import DirectivesModule from "common/ui-elements/ui-elements";
import "common/services/services";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.issues", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        DirectivesModule.name,
        "sbApp.constants",
    ])
    .config(issuesRouteConfig)
    .config(IssueReportDialogConfig)
    .constant("ISSUE_REPORT_TYPE", {
        NOTES_AND_IMAGES: "NOTES_AND_IMAGES",
        CSV: "CSV",
    })
    .controller("IssuesCtrl", issuesCtrl)
    .component("sbIssueRow", sbIssueRow);
/* declaration */
