import _ from "lodash";

export default function ($q, $sbCurrentProject, $sbNoteTemplatesApi) {
    "ngInject";

    return {
        add,
        update,
        all,
        removeAll,
        remove,
    };

    function all(noteTemplateKey = "") {
        return $sbNoteTemplatesApi.get(
            $sbCurrentProject.pick("id"),
            noteTemplateKey
        );
    }

    function removeAll(noteTemplateKeys) {
        return $q.allSettled(
            noteTemplateKeys.map((noteTemplateKey) => remove(noteTemplateKey))
        );
    }

    function remove(noteTemplateKey) {
        return $sbNoteTemplatesApi.delete(
            $sbCurrentProject.pick("id"),
            noteTemplateKey
        );
    }

    function update(noteTemplateKey, data) {
        return $sbNoteTemplatesApi.update(
            $sbCurrentProject.pick("id"),
            noteTemplateKey,
            _.pick(data, ["code", "text", "type"])
        );
    }

    function add(data) {
        const projectId = $sbCurrentProject.pick("id");
        return $sbNoteTemplatesApi.create(
            projectId,
            _.pick(data, ["code", "text", "type"])
        );
    }
}
