import AuthenticationError from "common/errors/AuthenticationError";
import PresentableError from "common/errors/PresentableError";

export default function (
    $rootScope,
    $state,
    $sbAuth,
    intercomService,
    $sbBootstrapOverlay,
    APP_CATEGORY,
    $sbLocale,
    $sbErrorPresenter,
    $q,
    $log,
    $document,
    Analytics,
    $filter,
    $sbPageAccess,
    $sbCurrentProject,
    SABLONO_STATES
) {
    "ngInject";

    $rootScope.$on(
        "$stateChangeError",
        function (event, toState, toParams, fromState, fromParams, error) {
            $log.log("$stateChangedError", error, toState.name, toParams);

            if (error instanceof AuthenticationError) {
                $sbAuth.goToLoginState(toState.name, toParams);
            } else if (error instanceof PresentableError) {
                $sbErrorPresenter.showWithPreferredPresentationStyle(error);
            } else {
                $sbAuth.goToLoginState(toState.name, toParams);
            }
        }
    );

    $rootScope.$on(
        "$stateChangeSuccess",
        function (event, toState, toParams, fromState) {
            guard(event, toState, fromState);

            //attach the state data to the root scope
            if (toState.data) {
                var stateData = toState.data;

                // apply defaults here.
                stateData.managesOverlay = stateData.managesOverlay || false;

                $rootScope.state = stateData;

                if (!$rootScope.state.managesOverlay) {
                    //hide the bootstrapOverlay for states that do not manage it manually
                    $sbBootstrapOverlay.hide();
                }

                var title = stateData.title;

                var titleTranslation = $filter("translate")(title);
                var titleSuffix = $filter("translate")("SABLONO");
                if (titleTranslation !== title) {
                    $document[0].title = titleTranslation + " | " + titleSuffix;
                } else {
                    $log.error(
                        "Page title",
                        title,
                        "not translated for state",
                        toState.name
                    );
                }
            } else {
                $sbBootstrapOverlay.hide();
            }
            intercomService.update();

            if (
                $state.current &&
                fromState.gaPageTrack !== toState.gaPageTrack
            ) {
                Analytics.trackPageView(toState.gaPageTrack);
            }
        }
    );

    // set the initial language and locale of the application
    //
    $sbLocale.init();

    function guard(event, toState, fromState) {
        const currentProject = $sbCurrentProject.get();

        if (!currentProject) return;

        const privileges = currentProject.privileges;
        const isAccessible = $sbPageAccess.isAccessible(
            toState.name,
            privileges
        );

        if (isAccessible) return;

        if (toState === fromState) return;

        event.preventDefault();

        let redirectRoute;

        switch (toState.name) {
            case SABLONO_STATES.deliverablesProgress:
                redirectRoute = SABLONO_STATES.requestAccessDeliverables;
                break;
            case SABLONO_STATES.issues:
                redirectRoute = SABLONO_STATES.requestAccessNotes;
                break;
            case SABLONO_STATES.leanboard:
                redirectRoute = SABLONO_STATES.requestAccessLeanPlanning;
                break;
            case SABLONO_STATES.commercialDashboard:
                redirectRoute = SABLONO_STATES.requestAccessCommercialDashboard;
                break;
            case SABLONO_STATES.visualization:
                redirectRoute = SABLONO_STATES.requestAccessVisualTrackers;
                break;
            case SABLONO_STATES.checklistTemplatesList:
            case SABLONO_STATES.checklistTemplatesEditor:
                redirectRoute = SABLONO_STATES.requestAccessQAChecklists;
                break;
            case SABLONO_STATES.dashboardPro:
                redirectRoute =
                    SABLONO_STATES.requestAccessPerformanceDashboardPro;
                break;
            case SABLONO_STATES.commercialProDashboard:
                redirectRoute =
                    SABLONO_STATES.requestAccessCommercialProDashboard;
                break;
        }

        if (!redirectRoute) return;

        $state.go(redirectRoute, {}, { reload: true });
    }
}
