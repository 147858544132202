import WbsCommand from "./WBSCommand";
import StructureNode from "../node/StructureNode";

/**
 * Class for Command to add a child to a node
 * @param {WBS.NodeContainer} selectedNode - Currently Selected node to add Child to
 * @param {WBS.NodeManager} nodeManager - Manages all the Nodes
 * @constructs AddChildComment
 * @implements {WBS.WbsCommand}
 * @memberof WBS
 */
function AddChildCommand(selectedNode, nodeManager) {
    WbsCommand.call(
        this,
        selectedNode.stage.$sbErrorPresenter,
        selectedNode.stage.wbsApiService
    );

    /**
     *
     * @type {WBS.NodeManager}
     */
    this.nodeManager = nodeManager;

    /**
     * Nodo to which a child is added
     * @type {WBS.NodeContainer}
     */
    this.selectedNode = selectedNode;
}

/**
 * Setup prototypal inheritance.
 * AddChildCommand inherits from Command.
 *
 * @type {WBS.WbsCommand}
 */
AddChildCommand.prototype = Object.create(WbsCommand.prototype);

/**
 * add child to selected node
 */
AddChildCommand.prototype.execute = function () {
    var selectedNodeData = this.selectedNode.entity.getData();
    var sProjectId = "";
    var sParentId = "";

    if (selectedNodeData) {
        sProjectId = selectedNodeData.PROJECT_ID;
        sParentId = selectedNodeData.ID;
    } else {
        sProjectId = undefined;
        sParentId = undefined;
    }

    var cmd = this;

    function addNodeToUI(newElement) {
        const parsedElement = StructureNode.parseServerResponse(newElement);

        var childNode = new StructureNode(parsedElement, cmd.nodeManager);

        cmd.selectedNode.parent.createChild(childNode);
        cmd.selectedNode.parent.dispatchAddedToStage();

        var nodeCreatedEvent = new createjs.Event("nodeCreated", true, false);
        cmd.selectedNode.stage.dispatchEvent(nodeCreatedEvent);
    }

    // if the data object is valid for the server , call the server API
    // -> this is a developer case (maybe remove on production)
    if (sProjectId !== undefined) {
        this.wbsApiService
            .createNode(sProjectId, sParentId)
            .then(addNodeToUI)
            .catch(this.$sbErrorPresenter.catch);
    } else {
        addNodeToUI({
            d: {
                NAME: "not on server!",
            },
        });
    }
};

/**
 * @todo remove child from selected node
 */
AddChildCommand.prototype.undo = function () {};

/**
 * Readds child to selected node
 */
AddChildCommand.prototype.redo = function () {
    this.execute();
};

export default AddChildCommand;
