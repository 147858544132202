import _ from "lodash";
import ModeManager from "./mode_manager.class";

function ModeBuilder() {
    this.init();
}

ModeBuilder.create = function () {
    return new ModeBuilder();
};

ModeBuilder.prototype.init = function () {
    this.manager = new ModeManager();
};

ModeBuilder.prototype.onModeChange = function (fn) {
    this.manager.addTransitionHandler(fn);
    return this;
};

ModeBuilder.prototype.addMode = function (name, handlers) {
    var newMode = _.assign(
        {
            name: name,
        },
        handlers
    );

    this.manager.modes.push(newMode);

    return this;
};

ModeBuilder.prototype.setDefault = function (nameOfMode) {
    this.manager.default = nameOfMode;

    return this;
};

ModeBuilder.prototype.start = function () {
    this.manager.switchTo();
    return this.manager;
};

export default ModeBuilder;
