import Component from "./Component";

/**
 * a group of components
 * @param {String} id - id of the group
 * @param {Object} [properties] - optinal data set
 * @extends {Component}
 * @constructor
 */
function Group(id, properties) {
    Component.call(this, id, properties);

    this.components = [];
}

Group.prototype = Object.create(Component.prototype);

/**
 * Return the list of direct child components
 *
 * @returns {Array.<Component>} - the list of Components that need this component to be first
 */
Group.prototype.getChildren = function () {
    return this.components;
};

/**
 * Return the list of all child components by collecting everything the hierarchy down.
 *
 * @returns {Array.<Component>} - the list of Components that need this component to be first
 */
Group.prototype.getChildrenDeep = function () {
    var deepChildren = [];
    var todo = [].concat(this.getChildren());

    while (todo.length > 0) {
        var component = todo.pop();
        if (component.getChildren().length > 0) {
            todo = todo.concat(component.getChildren());
        }
        deepChildren.push(component);
    }
    return deepChildren;
};

/**
 * Check if the given component is an descendant of this by the hierarchy relation.
 *
 * @param {Component} component - the Component that could be an descendant or not
 * @returns {boolean} - true if the component is descendant, false if not
 */
Group.prototype.hasDescendant = function (component) {
    var descendants = [].concat(this.getChildren());

    while (descendants.length > 0) {
        var descendant = descendants.pop();
        if (component.selfById(descendant)) {
            return true;
        }
        descendants = descendants.concat(descendant.getChildren());
    }
    return false;
};

/**
 * Adds the component and publish an event
 *
 * @param {Component} component - Add this as the new child.
 */
Group.prototype.addChild = function (component) {
    // remove component from its current parent
    //
    var parent = component.getParent();
    if (parent) {
        parent.removeChild(component);
    }

    // add component as child and set parent
    this.components.push(component);
    component.parent = this;
};

/**
 * removes the component and publish an event
 *
 * @param {Component} component - Remove this child.
 */
Group.prototype.removeChild = function (component) {
    this.components = this.components.filter(component.notSelfById, component);

    component.parent = null;
};

export default Group;
