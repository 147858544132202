export default class ChecklistsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the checklist by id.
     *
     * @param activityId {string}    UUID
     * @param checklistId {integer}    The unique identifier of the checklist you are requesting a resource for.
     */
    get(activityId, checklistId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/activities/${activityId}/checklists/${checklistId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a signed checklist.
     *
     * @param activityId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.type {string}    Type of checklist
     * @param body.status {string}    If the checklist is a draft or final version.
     * @param body.checklist_items {array}    List of checklist items. The order of items matters.
     * @param [body.action] {string}    Action to confirm or reject a pair of checklists
     */
    create(activityId, { type, status, checklist_items, action }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/activities/${activityId}/checklists`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { type, status, checklist_items, action },
        });
    }

    /**
     * This endpoint allows to update some properties.
     *
     * @param activityId {string}    UUID
     * @param checklistId {integer}    The unique identifier of the checklist you are requesting a resource for.
     *
     * @param body {Object}    Request body
     * @param body.type {string}    Type of checklist
     * @param body.status {string}    If the checklist is a draft or final version.
     * @param body.checklist_items {array}    List of checklist items. The order of items matters.
     * @param [body.action] {string}    Action to confirm or reject a pair of checklists
     */
    update(activityId, checklistId, { type, status, checklist_items, action }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/activities/${activityId}/checklists/${checklistId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: { type, status, checklist_items, action },
        });
    }
}
