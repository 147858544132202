/**
 * The sb-Element-Selection-Checkbox is our custom checkbox implementation that is working nice
 * and smoothly with the SelectionProvider. We have our hover behaviour here and proper styling.
 */
import angular from "angular";
import htmlTemplate from "./sbEleSelectionCheckbox.html";

export default function () {
    return {
        restrict: "E",

        templateUrl: htmlTemplate, //HTML Template as String

        scope: {
            selectionModel: "=",
            template: "=",
        },

        link: function (scope, iElement) {
            // and the hover effect to the parent.
            //
            var $parentRow = angular.element(iElement.parent());
            $parentRow.addClass("sb-checkbox-parent-hover");
        },
    };
}
