export default function () {
    var //instance, , instance is not used in the current version. -> maybe needed later.
        curScope,
        callFn;

    /**
     * Initialize the service with the config cards scope.
     * The scope must have a update method to be triggered by this service.
     *
     * The last instantiation wins.
     *
     * @param {$scope} $scope - The config card directives scope.
     * @param {Function} fn - The function to call to update the config card.
     */
    var init = function ($scope, fn) {
        //instance = element;
        curScope = $scope;
        callFn = fn;
    };

    /**
     * Call this to update the note template config card.
     * The data is fetched from the server again.
     */
    var update = function () {
        if (!curScope) {
            return;
        }

        if (!callFn) {
            throw new Error(
                "The note templates config card MUST have a update method."
            );
        }

        if (angular.isFunction(callFn)) {
            callFn();
        } else {
            throw new Error(
                "The note templates config card function is not function."
            );
        }
    };

    /**
     * Destroys reference to instance.
     */
    var destroy = function () {
        curScope = null;
        //instance = null;
        callFn = null;
    };

    return {
        init: init,
        update: update,
        destroy: destroy,
    };
}
