import angular from "angular";
import activitiesListSelectorService from "./services/activities_list_selector.service";
import activitiesListSelectorComponent from "./activities_list_selector.component";
import activitiesListSelectorSearchbarComponent from "./sbActivitiesListSelectorSearchbar/activities_list_selector_searchbar.component";

export default angular
    .module("sbApp.common.components.activitiesListSelector", [])
    .factory("$sbActivitiesListSelector", activitiesListSelectorService)
    .component("sbActivitiesListSelector", activitiesListSelectorComponent)
    .component(
        "sbActivitiesListSelectorSearchbar",
        activitiesListSelectorSearchbarComponent
    );
/* declaration */
