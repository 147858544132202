import htmlTemplate from "./deliverable_workflow.html";
import DeliverableWorkflowCtrl from "./deliverable_workflow.controller";

export default {
    replace: true,
    templateUrl: htmlTemplate,
    bindings: {
        isProcessLoading: "<",
        process: "<",
        deliverable: "<",
        teams: "<",
        selectedTeam: "<",
        template: "<",
        onAssignProcess: "&",
        onSelectedTeamChange: "&",
        onStateChange: "&",
        onCreateNote: "&",
        onFillChecklist: "&",
        onRequestActivityUpdate: "&",
        onResetActivity: "&",
    },
    controller: DeliverableWorkflowCtrl,
};
