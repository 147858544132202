import ODataFilter from "../../../../common/services/oDataService/odata_filter";
import _ from "lodash";

export default function (noteTemplatesService) {
    "ngInject";

    var service = {
        requestCardData: _requestNoteTemplateConfigCardData,
        asTypesWithEntryList: _createFiltersMap,
        addAllCounts: _addAllCounts,
        updateQuantity: _updateQuantity,
    };

    // statics

    // list of supported category keys.
    var CATEGORY_OPTIONS = ["CREATOR_DB_NAME", "TYPE"];
    // property of the quantity or count value
    var QUANTITY_KEY = "QUANTITY";

    /**
     * Get the config card data for NoteTemplates
     *
     * @returns {$q} - json request
     * @private
     */
    function _requestNoteTemplateConfigCardData() {
        return noteTemplatesService.getNotes().then((note_templates) => {
            // get all creators
            const uniqCreators = _.chain(note_templates)
                .map((noteTemplate) => {
                    return _.pick(noteTemplate, [
                        "CREATOR_DB_NAME",
                        "CREATOR_DISPLAY_NAME",
                        "CREATOR_INITIALS",
                    ]);
                })
                .uniqBy("CREATOR_DB_NAME")
                .value();

            // all types
            const uniqNoteTypes = _.uniq(_.map(note_templates, "TYPE"));

            // create all combinations of creator and type
            //
            const combinations = _.flatMap(uniqCreators, function (creators) {
                return _.map(uniqNoteTypes, function (noteType) {
                    return Object.assign({}, creators, {
                        TYPE: noteType,
                    });
                });
            });
            return combinations;
        });
    }

    /**
     * The categories are (per definition) equal on every element in the server data list.
     *
     * @param {Array<Object>} serverData - List of all filter combinations provided by the server
     * @return {Array<String>} - list of all available categories in the server data list
     * @private
     */
    function _extractCategories(serverData) {
        if (serverData.length > 0) {
            var sampleElement = serverData[0];
            var categoryKeys = Object.keys(sampleElement).filter(
                function (key) {
                    if (CATEGORY_OPTIONS.indexOf(key) >= 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );
            return categoryKeys;
        }
        return [];
    }

    /**
     * Create an object with keys as available categories and an attached Map as all available options.
     *
     * @param {Array<Object>} serverData - List of permutations retrieved from the server.
     *
     * @returns {Object<String, Object<String,ODataFilter>>} - Object with category as key and a map with options -> amount as value
     * @private
     */
    function _createFiltersMap(serverData) {
        var categories = _extractCategories(serverData);
        var resultMap = {};

        categories.forEach(function (category) {
            resultMap[category] = _buildFilterOptionMap(serverData, category);
        });

        return resultMap;
    }

    /**
     * Add an ALL key to the the filter options. Count all quantities of the other
     * filter options per category and set this value as ALL quantity
     *
     * @param {Object<String, Object<String,ODataFilter>>} map - The filter options.
     * @returns {*} - the given map extend by an ALL value.
     * @private
     */
    function _addAllCounts(map) {
        Object.keys(map).forEach(function (filterKey) {
            var oDataFilterMap = map[filterKey];
            var allCount = 0;

            for (var filterOptionKey in oDataFilterMap) {
                allCount += oDataFilterMap[filterOptionKey].quantity();
            }

            oDataFilterMap.ALL = new ODataFilter(
                filterKey,
                Number.parseInt(allCount, 10)
            );
            if (filterKey === "CREATOR_DB_NAME") {
                oDataFilterMap.ALL.displayText("_EVERYBODY");
                oDataFilterMap.ALL.shorty(undefined);
            }
            if (filterKey === "TYPE") {
                oDataFilterMap.ALL.displayText("_ALL_TYPES");
            }
        });

        return map;
    }

    /**
     * Create a little map with all available options and the corresponding quantity of elements
     * from the servers full permutations list
     *
     * @param {Array<Object>} permutationList - the server list of all combinations
     * @param {String} categoryKey - the category type to create the list for.
     * @returns {Object<String,ODataFilter>} - key as category and value as quantity.
     * @private
     */
    function _buildFilterOptionMap(permutationList, categoryKey) {
        return permutationList.reduce(function (resultMap, entry) {
            _extendQuantityMap(resultMap, entry, categoryKey);
            return resultMap;
        }, {});
    }

    /**
     * Add another entry to the quantity map.
     *
     * @param {Object<String,ODataFilter>} quantityMap - Map to be extended by the key entry.
     * @param {Object} entry - complete element describing one combination of filters with quantity
     * @param {String} categoryKey - the category
     * @private
     */
    function _extendQuantityMap(quantityMap, entry, categoryKey) {
        // its something like quantityMap.CLAIM = ODataFilter()
        var filter = quantityMap[entry[categoryKey]];
        if (!filter) {
            quantityMap[entry[categoryKey]] = _createODataFilter(
                categoryKey,
                entry
            );
        } else {
            // add the quantity of the combination to the existing filter option
            filter.quantity(
                filter.quantity() + Number.parseInt(entry[QUANTITY_KEY], 10)
            );
        }
    }

    /**
     * Create proper filter options depending on the category type.
     * -> We have to handle different categories on different ways...
     *
     * @param {String} filterCategory - The category identifier
     * @param {Object} plainEntry - the entry describing the option (permutation server entry)
     * @returns {filter} - A filter option derived from the input parameter.
     * @private
     */
    function _createODataFilter(filterCategory, plainEntry) {
        var typeKey = plainEntry[filterCategory];
        var filter = new ODataFilter(
            typeKey,
            Number.parseInt(plainEntry[QUANTITY_KEY], 10)
        );
        if (filterCategory === "CREATOR_DB_NAME") {
            filter.displayText(plainEntry.CREATOR_DISPLAY_NAME);
            filter.shorty(plainEntry.CREATOR_INITIALS);
        }
        if (filterCategory === "DAYS_BETWEEN") {
            filter.displayText("_DAYS_BETWEEN_" + typeKey);
            filter.shorty("_DAYS_BETWEEN_SHORT_" + typeKey);
        }
        if (filterCategory === "TYPE") {
            filter.displayText("_" + typeKey);
        }
        return filter;
    }

    /*
     * Case 2 options: type and author
     *  # no selection  -> type     : CLAIM (3) , OBSTRUCTION (5)
     *                  -> auhtor   : MB(4) , SR (4)
     *
     *  # select CLAIM (3) -> all numbers except in "type" should be refreshed
     *      -> type : CLAIM(3) , OBSTRUCTION(5)
     *      -> author : MB(1)  , SR (2)
     *
     * So I think from the displayed data: each filter category (amount number) should reflect the
     * filtered state of all other available categories but ignore its own state.
     *
     */

    /**
     * Use a plain permuation list and a full filter state to compute matching quantities to
     * the filter selection.
     *
     * @param {Array} permutationList - Server List of all permutations of filter conditions
     * @param {Object<String,ODataFilterSet>} filterState - list of ODataFilterSets provided from the view model
     * @private
     *
     */
    function _updateQuantity(permutationList, filterState) {
        var categoryKey;

        // calculate the quantity one by one per category
        //
        for (categoryKey in filterState) {
            _calcQuantityCategory(permutationList, filterState, categoryKey);
        }

        // update the ALL count for all categories
        //
        for (categoryKey in filterState) {
            var filterOptionMap = filterState[categoryKey].items();
            var allCount = 0;
            for (var filterOptionKey in filterOptionMap) {
                allCount += filterOptionMap[filterOptionKey].quantity();
            }

            // handle the case that there is no card data at all.
            if (allCount !== 0) {
                filterOptionMap.ALL.quantity(allCount);
            }
        }
    }

    /**
     * For one given category reduce the permutation list by the filter condition and compute the quantities
     * based on this reduced list.
     *
     * @param {Array} permutationList - Server List of all permutations of filter conditions
     * @param {Object<String,ODataFilterSet>} filterState - list of ODataFilterSets provided from the view model
     * @param {String} categoryKey - one particular category to determine the new counts for
     * @private
     */
    function _calcQuantityCategory(permutationList, filterState, categoryKey) {
        var category = filterState[categoryKey].category();
        var filterConditions = [];

        // create a simple array representation of the filter conditions for better filter computation.
        //
        for (var filterKey in filterState) {
            var filterSet = filterState[filterKey];

            // filter for the category that is currently in focus has to be ignored.
            if (filterKey !== categoryKey) {
                filterConditions.push({
                    category: filterSet.category(),
                    selection: filterSet.selection(),
                });
            }
        }

        // remove things from the permutation that should be ignored for the given category
        //
        var filteredPermutations = permutationList.filter(function (e) {
            // remove all entries that are NOT matching the filter condition
            //

            // go through all filter pairs if some combination is not correct -> remove
            //
            return filterConditions.every(function (testFilter) {
                // ignore ALL , remove if there is a match on the filter condition
                return testFilter.selection === "ALL"
                    ? true
                    : testFilter.selection === e[testFilter.category];
            });
        });

        var newCount = _buildFilterOptionMap(filteredPermutations, category);

        // merge the current filters number
        //
        var existingFilterOptions = filterState[categoryKey].items();
        for (var entryKey in existingFilterOptions) {
            if (newCount[entryKey]) {
                var newQuantity = newCount[entryKey].quantity();
                existingFilterOptions[entryKey].quantity(newQuantity);
            } else {
                existingFilterOptions[entryKey].quantity(0);
            }
        }
    }

    return service;
}
