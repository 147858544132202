import WbsCommand from "./WBSCommand";
import DeleteNodeEvent from "../events/DeleteNodeEvent";
/**
 * Command to Remove selected node
 * @param {WBS.StructureNode} selectedNode - Currently Selected Node
 * @param {WBS.EditorStage} stage - Stage on which all Nodes are drawn
 * @constructs RemoveNodeCommand
 * @implements {WBS.WbsCommand}
 * @memberof WBS
 */
function RemoveNodeCommand(selectedNode, stage) {
    WbsCommand.call(this, stage.$sbErrorPresenter, stage.wbsApiService);

    /**
     * Node that is deleted
     * @type {WBS.StructureNode}
     */
    this.selectedNode = selectedNode;

    /**
     *
     * @type {WBS.EditorStage}
     */
    this.stage = stage;
}

/**
 * Setup prototypal inheritance.
 * RemoveNodeCommand inherits from WbsCommand.
 *
 * @type {WBS.WbsCommand}
 */
RemoveNodeCommand.prototype = Object.create(WbsCommand.prototype);

/**
 * removes selected Node
 */
RemoveNodeCommand.prototype.execute = function () {
    var cmd = this;
    var sNodeId = this.selectedNode.entity.getData().ID;
    var projectId = this.selectedNode.entity.getData().PROJECT_ID;
    cmd.stage.$mdDialog
        .show(
            cmd.stage.$mdDialog
                .confirm()
                .title("CONFIRM_DELETE_STRUCTURE_NODE_TITLE")
                .content("CONFIRM_DELETE_STRUCTURE_NODE_TEXT")
                .ok("YES")
        )
        .then(deleteNode);

    function deleteNode() {
        // if the data object is valid for the server , call the server API
        // -> this is a developer case (maybe remove on production)
        if (sNodeId !== undefined) {
            cmd.wbsApiService
                .deleteNode(projectId, sNodeId)
                .then(triggerUI)
                .catch(cmd.$sbErrorPresenter.catch);
        } else {
            triggerUI();
        }
    }

    function triggerUI() {
        var nodeEvent = new DeleteNodeEvent(cmd.selectedNode);
        cmd.stage.dispatchEvent(nodeEvent);
    }
};

/**
 * readds removed node
 */
RemoveNodeCommand.prototype.undo = function () {
    console.log(this.selectedNode);
};

RemoveNodeCommand.prototype.redo = function () {
    this.execute();
};

export default RemoveNodeCommand;
