import angular from "angular";

export default function AccountVerificationCtrl(
    $q,
    $scope,
    $state,
    $log,
    $stateParams,
    $sbAuth,
    $sbPasswordPolicy,
    isTokenValid
) {
    "ngInject";

    var vm = this;

    vm.token = undefined;
    vm.hasSuccess = false;
    vm.hasError = !isTokenValid;

    vm.passwordChange = {
        error: null,
        inProgress: false,
        policy: null,
    };

    vm.error = null;

    vm.submitPassword = submitPassword;
    vm.getErrorTitle = getErrorTitle;
    vm.getErrorText = getErrorText;

    activate();

    function activate() {
        return $sbAuth
            .getCurrentUser()
            .then(getPasswordPolicy)
            .catch(function (error) {
                $state.go("account.login");
            });
    }

    function getPasswordPolicy() {
        return $sbPasswordPolicy.getPolicy().then(setPolicy);
    }

    $scope.$watch(
        "accountVerify.form.newPassword.$$rawModelValue",
        function validateNewPassword(newPassword) {
            if (isNotEmptyString(newPassword)) {
                return;
            }

            vm.passwordChange.error = "";

            if (!hasPolicy) {
                return;
            }

            getPasswordPolicyValidationError(
                newPassword,
                vm.passwordChange.policy
            );
        }
    );

    /**
     * Check a given a password against a policy. Returns all translated errors as combined string.
     *
     * @param {String} password - password to check against policy.
     * @param {Object} policy - object containing password policy configuration.
     *
     * @return {Promise<String>} - Translated error message.
     */
    function getPasswordPolicyValidationError(password, policy) {
        return $sbPasswordPolicy
            .getPasswordErrorTranslated(password, policy)
            .then(function translateAndSetError(translatedError) {
                setPasswordError(translatedError.join(", "));
            });
    }

    function isNotEmptyString(string) {
        return !angular.isString(string) || string.length === 0;
    }

    function setPasswordError(error) {
        vm.passwordChange.error = error;
    }

    function setError(e) {
        vm.error = e;
        vm.hasError = true;
    }

    function getErrorTitle() {
        if (isTokenValid) {
            return "SIGNUP_ERROR_VERIFY_FAILED_TITLE";
        } else {
            return "SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TITLE";
        }
    }

    function getErrorText() {
        if (isTokenValid) {
            return "SIGNUP_ERROR_VERIFY_FAILED_TEXT";
        } else {
            return "SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TEXT";
        }
    }

    function hasPolicy() {
        return angular.isObject(vm.passwordChange.policy);
    }

    function setPolicy(passwordPolicy) {
        vm.passwordChange.policy = passwordPolicy;
    }

    function submitPassword(newPassword) {
        vm.passwordChange.inProgress = true;

        $sbAuth
            .completeNewPassword(newPassword)
            .then(function (response) {
                $log.debug(response);
                vm.hasSuccess = true;
            })
            .catch(function (error) {
                setError(error);
            })
            .finally(function () {
                vm.passwordChange.inProgress = false;
            });
    }
}
