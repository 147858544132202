import angular from "angular";
import html from "./busyIndication.html";

export default angular
    .module("sbApp.sbBusyIndicationDialog", [])
    .factory("$sbBusyIndication", function ($mdDialog) {
        "ngInject";
        return {
            show: show,
        };

        function show(promise, text, title, style) {
            var wrapped = false;
            if (!angular.isArray(promise)) {
                promise = [promise];
                wrapped = true;
            }

            return $mdDialog
                .show(
                    $mdDialog
                        .busyIndication()
                        .title(title)
                        .text(text)
                        .allPromises(promise)
                        .class(style)
                )
                .then(function (result) {
                    if (wrapped) {
                        // if we wrapped the promise in an array before -> we have to unwrap here
                        return result[0];
                    } else {
                        return result;
                    }
                });
        }
    })
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("busyIndication", {
            methods: [
                "ariaLabel",
                "title",
                "text",
                "allPromises",
                "stepText",
                "class",
            ],
            options: function busyDialog() {
                return {
                    templateUrl: html,
                    controller: busyIndicationCtrl,
                    controllerAs: "dialog",
                    bindToController: true,
                };
            },
        });

        /**
         * the dialog controller
         *
         * @param $mdDialog
         * @param $q
         * @param $window
         * @param $filter
         */
        function busyIndicationCtrl($mdDialog, $q, $window, $filter) {
            "ngInject";

            var vm = this;
            var times = [];
            var translateFilter = $filter("translate");

            // prevent closing the window! -> or at least ask if the user is sure.
            $window.onbeforeunload = function () {
                return translateFilter("DIALOG_PREVENT_CLOSING");
            };

            vm.totalPromises = vm.allPromises.length;
            vm.remainingEstimate = vm.allPromises.length;
            vm.remainingTimeEstimate = -1;

            function $onInit() {
                if (vm.allPromises) {
                    $q.all(_transform(vm.allPromises))
                        .then(_hide)
                        .catch(_reject);
                }
            }

            function _transform(promises) {
                return promises.map(function (promise) {
                    //Start time before request is made
                    var start = $window.performance.now();

                    return promise.then(function (originalPromiseResult) {
                        // update the time estimation with the execution time of this promise
                        _updateAverageRequestTime(
                            $window.performance.now() - start
                        );
                        return originalPromiseResult;
                    });
                });
            }

            function _updateAverageRequestTime(nextRequestTime) {
                // add the timing to the list of timings
                times.push(nextRequestTime);

                // get the current average
                var currentAveragePromiseTiming =
                    times.reduce(function (sum, a) {
                        return sum + a;
                    }, 0) / (times.length || 1);

                // set the estimates
                vm.remainingTimeEstimate = Math.floor(
                    (currentAveragePromiseTiming * vm.remainingEstimate) /
                        1000 /
                        60
                ); //seconds
                vm.remainingEstimate--;
            }

            function _hide(success) {
                // remove the unload check.
                $window.onbeforeunload = null;
                vm.allPromises = null;
                // close the dialog
                $mdDialog.hide(success);
            }

            function _reject(error) {
                // remove the unload check.
                $window.onbeforeunload = null;
                vm.allPromises = null;
                // close the dialog
                $mdDialog.cancel(error);
            }

            $onInit();
        }
    });
