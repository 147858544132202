import EditableElement from "./EditableElement";
import TextChangedEvent from "../events/TextChangedEvent";

/**
 * A Text Element that can be edited
 * @param {createjs.Text} textObject Object that stores the text
 * @constructs EditableText
 * @extends WBS.EditableElement
 * @memberof WBS
 */
function EditableText(textObject) {
    EditableElement.call(this);
    this.textObject = textObject || new createjs.Text();
    this.addChild(this.textObject);
    this.calcBoundingBox();
}

EditableText.prototype = Object.create(EditableElement.prototype);

EditableText.prototype.inputElement = null;

/**
 * Text that is Displayed in the Textfield
 * @type {string}
 */
EditableText.prototype.text = "";

/**
 * Setter for Text
 * @param {string} text - New Text
 */
EditableText.prototype.setText = function (text) {
    this.textObject.text = this.textWrap(text.trim());
    this.text = text;
};

/**
 * Getter for Text
 * @returns {string}
 */
EditableText.prototype.getText = function () {
    return this.text;
};

/**
 * Handles the focus of the Element
 * * Changes the TextDisplay Element to a Editable Element
 * @param {createjs.Event} event - FocusEvent
 * @private
 */
EditableText.prototype._onFocus = function (event) {
    EditableElement.prototype._onFocus.call(this, event);
    this.inputElement = this.createInputElement();
    this.addChild(this.inputElement);
    this.textObject.visible = false;
    this.parent.uncache();
};

/**
 * Handles the de-focus of the Element
 * * Hides Input Element
 * * Display TextDisplay Element
 * @param {Object} event - Blur Event
 * @private
 */
EditableText.prototype._onBlur = function (event) {
    EditableElement.prototype._onBlur.call(this, event);
    this.removeChild(this.inputElement);
    this.stage.removeDOMChild(this.inputElement.htmlElement);
    this.textObject.visible = true;
    this.parent.uncache();
    var newText = this.inputElement.htmlElement.value;
    var oldText = this.textObject.text;
    if (newText !== oldText) {
        //this.textObject.text = newText;
        var textChangedEvent = new TextChangedEvent(this, oldText, newText);
        this.dispatchEvent(textChangedEvent);
    }
};

/**
 * Calculates the Bounding Box
 */
EditableText.prototype.calcBoundingBox = function () {
    var height = this.height,
        width = this.width;

    var bounds = new createjs.Rectangle(0, 0, width, height);

    switch (this.textObject.textBaseline) {
        case "top":
            bounds.y = 0;
            break;
        case "middle":
            bounds.y = -height / 2;
            break;
        case "bottom":
            bounds.y = -height;
            break;
    }

    switch (this.textObject.textAlign) {
        case "start":
        case "left":
            bounds.x = 0;
            break;
        case "center":
            bounds.x = -width / 2;
            break;
        case "end":
        case "right":
            bounds.x = -width;
            break;
    }

    this.setBounds(bounds.x, bounds.y, bounds.width, bounds.height);
    this.createHitArea();
};

/**
 * Calculates how the text should be wrapped
 * @param {string} textString - Displayed text
 * @returns {string}
 */
EditableText.prototype.textWrap = function (textString) {
    this.textObject.text = textString;
    var width = this.textObject.getMeasuredWidth();
    var maxWidth = this.width;

    var ellipsis = "…";
    this.textObject.text = ellipsis;
    var ellipsisWidth = this.textObject.getMeasuredWidth();

    if (width <= maxWidth || width <= ellipsisWidth) {
        return textString;
    } else {
        while (width >= maxWidth - ellipsisWidth && textString.length > 2) {
            textString = textString.substring(0, textString.length - 2);
            this.textObject.text = textString;
            width = this.textObject.getMeasuredWidth();
        }

        return textString.trim() + ellipsis;
    }
};

/**
 * Creates the input Element
 * @returns {createjs.DOMElement}
 */
EditableText.prototype.createInputElement = function () {
    var input = document.createElement("input");
    input.type = "text";

    input.value = this.getText();

    var bounds = this.getBounds();

    input.style.display = "block";
    input.style.width = bounds.width + "px";
    input.style.height = bounds.height + "px";
    input.style.background = "none";
    input.style.border = "none";
    input.style.outline = "none";

    input.style.padding = "0";
    input.style.margin = "0";
    input.style.font =
        this.textObject.font || this.textSize + " " + this.textFont;
    input.style.color = "#FFF";

    //set text align of the input element
    switch (this.textObject.textAlign) {
        case "start":
            input.style.textAlign = "left";
            break;
        case "left":
            input.style.textAlign = "left";
            break;
        case "center":
            input.style.textAlign = "center";
            break;
        case "end":
            input.style.textAlign = "right";
            break;
        case "right":
            input.style.textAlign = "right";
            break;
    }

    //add to the dom stage
    this.stage.addDOMChild(input);

    var inputContainer = new createjs.DOMElement(input);

    inputContainer.x = bounds.x;
    inputContainer.y = bounds.y;

    //set focus on input element
    input.focus();

    return inputContainer;
};

/**
 * Creates Hit Area
 */
EditableText.prototype.createHitArea = function () {
    this._hitArea = new createjs.Shape();
    var g = this._hitArea.graphics.beginFill("#000");

    var bounds = this.getBounds() || new createjs.Rectangle(0, 0, 0, 0);

    g.drawRect(bounds.x, bounds.y, bounds.width, bounds.height);
};

export default EditableText;
