import angular from "angular";
import alertHtml from "./alert.html";
import confirmHtml from "./confirm.html";
import selectHtml from "./select.html";
import errorHtml from "./error.html";
import createNewProjectHtml from "../../states/projectSelection/CreateNewProjectTmpl.html";
import DialogsService from "./dialogs.service";

export default angular
    .module("sbApp.dialog", ["ngMaterial", "sbApp.services"])
    .factory("$sbDialog", DialogsService)
    .config(function ($mdDialogProvider) {
        $mdDialogProvider
            .addPreset("alert", {
                methods: [
                    "title",
                    "titleIcon",
                    "content",
                    "contentValues",
                    "ariaLabel",
                    "ok",
                    "class",
                ],
                options: [
                    "$mdDialog",
                    function alertDialog($mdDialog) {
                        return {
                            templateUrl: alertHtml,
                            controller: function mdDialogCtrl() {
                                this.hide = function () {
                                    $mdDialog.hide(true);
                                };
                            },
                            controllerAs: "dialog",
                            bindToController: true,
                        };
                    },
                ],
            })

            .addPreset("confirm", {
                methods: [
                    "title",
                    "titleIcon",
                    "content",
                    "contentValues",
                    "ariaLabel",
                    "ok",
                    "cancel",
                    "class",
                ],
                options: [
                    "$mdDialog",
                    function confirmDialog($mdDialog) {
                        return {
                            templateUrl: confirmHtml,
                            controller: function mdDialogCtrl() {
                                this.hide = function () {
                                    $mdDialog.hide(true);
                                };
                                this.abort = function () {
                                    $mdDialog.cancel();
                                };
                            },
                            controllerAs: "dialog",
                            bindToController: true,
                        };
                    },
                ],
            })

            // Displays a Radio group (selectOptions)
            // or a Checkbox list (checkboxOptions)
            // or both
            .addPreset("select", {
                methods: [
                    "title",
                    "titleIcon",
                    "class",
                    "content",
                    "contentValues",
                    "ariaLabel",
                    "selectOptions",
                    "selectedOption",
                    "secondSelectOptions",
                    "secondSelectedOption",
                    "checkboxOptions",
                    "ok",
                    "cancel",
                ],
                options: [
                    "$mdDialog",
                    function selectDialog($mdDialog) {
                        return {
                            templateUrl: selectHtml,
                            controller: function mdDialogCtrl() {
                                this.hide = function () {
                                    $mdDialog.hide(true);
                                };
                                this.abort = function () {
                                    $mdDialog.cancel();
                                };
                                this.select = function (
                                    selectedOption,
                                    checkboxOption,
                                    secondSelectedOption
                                ) {
                                    var option = {};
                                    if (selectedOption) {
                                        option.select = selectedOption;
                                    }
                                    if (checkboxOption) {
                                        option.checkbox = checkboxOption;
                                    }
                                    if (secondSelectedOption) {
                                        option.secondSelect =
                                            secondSelectedOption;
                                    }
                                    $mdDialog.hide(option);
                                };
                            },
                            controllerAs: "dialog",
                            bindToController: true,
                        };
                    },
                ],
            })
            .addPreset("error", {
                methods: [
                    "title",
                    "icon",
                    "content",
                    "contentValues",
                    "ariaLabel",
                    "actions",
                    "autoClose",
                ],
                options: [
                    "$mdDialog",
                    function selectDialog($mdDialog) {
                        return {
                            templateUrl: errorHtml,
                            controller: function mdDialogCtrl() {
                                this.hide = function () {
                                    $mdDialog.hide(true);
                                };
                                this.abort = function () {
                                    $mdDialog.cancel();
                                };
                                this.resolve = function (action) {
                                    $mdDialog.hide(action);
                                };
                            },
                            controllerAs: "dialog",
                            bindToController: true,
                        };
                    },
                ],
            })

            //Custom Dialogs
            //Create new project dialog
            .addPreset("createNewProject", {
                methods: [
                    "title",
                    "icon",
                    "type",
                    "projectName",
                    "projectCode",
                    "projectDescription",
                    "ariaLabel",
                    "actions",
                    "autoClose",
                    "clickOutsideToClose",
                    "escapeToClose",
                ],
                options: [
                    "$mdDialog",
                    function selectDialog($mdDialog) {
                        return {
                            templateUrl: createNewProjectHtml,
                            controller: function mdDialogCtrl() {
                                this.hide = function () {
                                    $mdDialog.hide(true);
                                };
                                this.abort = function ($event) {
                                    $mdDialog.cancel();
                                    if ($event) {
                                        $event.preventDefault();
                                    }
                                };
                                this.resolve = function (action) {
                                    $mdDialog.hide(action);
                                };

                                this.submit = function (action) {
                                    $mdDialog.hide(action);
                                };
                            },
                            controllerAs: "dialog",
                            bindToController: true,
                        };
                    },
                ],
            });
    });
