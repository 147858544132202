import angular from "angular";
import html from "./workflow_activity_detail.html";
import WorkflowDetailCtrl from "./workflow_activity_detail.controller";

export default {
    templateUrl: html,
    replace: true,
    bindings: {
        node: "<",
    },
    require: {
        treeCtrl: "^sbActivityTree",
    },
    controller: WorkflowDetailCtrl,
};
