import angular from "angular";
import commercialProRouteConfig from "./commercial-valuation.route";
import "angular-ui-router";
import "angular-material";
import CommercialValuationController from "./commercial-valuation.controller";

export default angular
    .module("sbApp.commercialValuation", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.directives",
    ])
    .config(commercialProRouteConfig)
    .controller("CommercialValuationCtrl", CommercialValuationController);
