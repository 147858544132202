import angular from "angular";

export default angular
    .module("sbApp.sbEquals", [])
    .directive("sbEquals", function equalsDirective() {
        /**
         * from stack overflow
         * http://stackoverflow.com/a/18014975
         */
        return {
            restrict: "A", // only activate on element attribute
            require: "?ngModel", // get a hold of NgModelController
            link: function (scope, elem, attrs, ngModel) {
                if (!ngModel) return; // do nothing if no ng-model

                var validate = function () {
                    // values
                    var val1 = ngModel.$viewValue;
                    var val2 = attrs.sbEquals;

                    // set validity
                    ngModel.$setValidity(
                        "equals",
                        !val1 || !val2 || val1 === val2
                    );
                };

                // watch own value and re-validate on change
                scope.$watch(attrs.ngModel, function () {
                    validate();
                });

                // observe the other value and re-validate on change
                attrs.$observe("equals", function () {
                    validate();
                });
            },
        };
    });
