import angular from "angular";
import ProjectSelectionCtrl from "./project_selection.controller";
import projectSelectionRoute from "./project_selection.route";
import "common/services/services";
import "common/ui-elements/directives/sbProjectEdit/project_edit.directive";
import project_cardComponent from "../../states/projectSelection/components/sbProjectCard/project_card.component";
import pendingInviteCardComponent from "../../states/projectSelection/components/sbPendingInviteCard/pending_invite_card.component";

export default angular
    .module("sbApp.ProjectSelectionCtrl", [
        "sbApp.services",
        "sbApp.common.components.projectCard",
        "ui.router",
        project_cardComponent.name,
        pendingInviteCardComponent.name,
    ])
    .config(projectSelectionRoute)
    .controller("ProjectSelectionCtrl", ProjectSelectionCtrl);
