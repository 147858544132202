import changePasswordTemplate from "./change_password.html";

export default function ($mdDialogProvider) {
    "ngInject";

    $mdDialogProvider.addPreset("promptPassword", {
        methods: [],
        options: function promptPassword($mdDialog, $log) {
            "ngInject";

            return {
                templateUrl: changePasswordTemplate,
                controller: ChooseProjectSourceDialogCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
            };

            function ChooseProjectSourceDialogCtrl() {
                "ngInject";
                var vm = this;
                vm.abort = $mdDialog.cancel;
                vm.confirm = function (r) {
                    $log.info(r);
                    return $mdDialog.hide(r);
                };
            }
        },
    });
}
