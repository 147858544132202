import DynamicVirtualRepeatLoader from "./virtual_repeat_loader.class";

export default function (noteTemplatesService, $sbErrorPresenter) {
    "ngInject";
    /**
     * NoteTemplates class to send paginated data upon request
     * by virutal repeater
     *
     * @param {string} filters - Filters to be applied in $filter param for service
     * @param {string} orderQuery - Sort order
     */
    var NoteTemplates = function (filters, orderQuery) {
        /**
         * The OData filter query string.
         * @type {string}
         * @private
         */
        this._filter = filters;

        /**
         * The OData order query string
         * @type {string}
         * @private
         */
        this._order = orderQuery;

        DynamicVirtualRepeatLoader.call(this);

        /**
         * Flag to bind the UI - > loading indication
         * @type {boolean}
         */
        this.isLoading = true;
    };

    NoteTemplates.prototype = Object.create(
        DynamicVirtualRepeatLoader.prototype
    );

    /**
     * Set or get the oder filter string. Should be an OData conform condition!
     *
     * @param {String} [newValue] - the filter string to set or nothing to get the string
     * @returns {NoteTemplates|String} - after set you get "this", for get you get the filter string.
     */
    NoteTemplates.prototype.filter = function (newValue) {
        if (arguments.length > 0) {
            this._filter = newValue;
            return this;
        } else {
            return this._filter;
        }
    };

    /**
     * Set or get the oder query string. Should be an OData conform condition!
     *
     * @param {String} [newValue] - the order string to set or nothing to get the string
     * @returns {NoteTemplates|String} - after set you get "this", for get you get the order string.
     */
    NoteTemplates.prototype.order = function (newValue) {
        if (arguments.length > 0) {
            this._order = newValue;
            return this;
        } else {
            return this._order;
        }
    };

    /**
     * @inheritDoc
     *
     * @param {Number} from - load data block from number
     * @param {Number} to - to number
     * @returns {$q} - the resolved OData request with the data array
     */
    NoteTemplates.prototype.loadData = function () {
        return noteTemplatesService
            .getNotes(this.filter(), this.order())
            .then(
                function (data) {
                    this.isLoading = false;
                    return data;
                }.bind(this)
            )
            .catch($sbErrorPresenter.catch);
    };

    /**
     * @inheritDoc
     *
     * @returns {$q} - the resolved OData request with the number of elements
     */
    NoteTemplates.prototype.loadCount = function () {
        return noteTemplatesService
            .getNotesCount(this.filter())
            .then(
                function (data) {
                    var number = Number.parseInt(data.data, 10);
                    if (number === 0) {
                        this.isLoading = false;
                        return 0;
                    }
                    return number + 5;
                }.bind(this)
            )
            .catch($sbErrorPresenter.catch);
    };

    /**
     * Set the isLoading flag to bind UI elements on it
     * Call the VRL reload function to update the elements and the element count to
     * the new filter and sort conditions.
     */
    NoteTemplates.prototype.reload = function () {
        this.isLoading = true;
        DynamicVirtualRepeatLoader.prototype.reload.apply(this);
    };

    return NoteTemplates;
}
