//
// CONTENT TO COPY TO BACKEND STARTS HERE !
//

/**
 * @class CalendarTimeShift - A class identifying a shift
 *
 * @property {String} start
 * @property {String} end
 * @property {Number} startAsFloat
 * @property {Number} endAsFloat
 * @property {Number} duration
 *
 * @param  {String} start - the start of the shift - e.g: "08:00"
 * @param  {String} end   - the end   of the shift - e.g: "12:00"
 */
function CalendarTimeShift(start, end) {
    this.start = start;
    this.end = end;

    // Converting start & end to float numbers for better management in the code. e.g: 15:30 -> 15,5 or 4:45 -> 4,75
    //
    this.startAsFloat = this.transformHourToFloat(this.start);
    this.endAsFloat = this.transformHourToFloat(this.end);

    // The duration of the shift
    //
    this.duration = this.endAsFloat - this.startAsFloat;
}

/**
 * RegExp to match a given time string. Matches hours and minutes in groups.
 * Valid matches go from  00:00 to 23:59.
 *
 * @type {RegExp}
 */
CalendarTimeShift.TIME_HOURS_MINUTES_REGEX =
    /^([2][0-3]|[01]?[0-9])[:]([0-5][0-9])?$/;

/**
 * Returns the amount of hours that can be still used on this shift.
 *
 * If the shift is in the past -> nothing can be used.
 * If the shift is completely in the future -> complete shift duration
 * If time is inside a shift -> remaining hours of the shift are returned.
 *
 * @param {Number} nowAsFloat - The hour to take in consideration
 * @returns {Number} Hours still remaining.
 */
CalendarTimeShift.prototype.hoursRemainingOnShift = function (nowAsFloat) {
    if (nowAsFloat > this.endAsFloat) {
        return 0;
    }
    //If the start is before the shift starts then the start is the start of the shift.
    var start = Math.max(nowAsFloat, this.startAsFloat);

    return this.endAsFloat - start;
};

/**
 * Returns the amount of hours that were already used on this shift.
 *
 * If the shift is in the future -> nothing can be used.
 * If the shift is completely in the past -> complete shift duration
 * If time is inside a shift -> passed hours in the shift are returned.
 *
 * @param {Number} nowAsFloat - The hour to take in consideration
 * @returns {Number} Hours used.
 */
CalendarTimeShift.prototype.hoursPassedOnShift = function (nowAsFloat) {
    if (nowAsFloat < this.startAsFloat) {
        return 0;
    }
    var end = Math.min(nowAsFloat, this.endAsFloat);

    return end - this.startAsFloat;
};

/**
 * Get the starting hours of the shift.
 *
 * @returns {Number}
 */
CalendarTimeShift.prototype.startingHours = function () {
    return this.hoursOf(this.start);
};

/**
 * Get the ending hours of the shift.
 *
 * @returns {Number}
 */
CalendarTimeShift.prototype.endingHours = function () {
    return this.hoursOf(this.end);
};

/**
 * Get the hours of the given time string.
 *
 * @param {String} timeString - 00:00 -> 23:59
 * @returns {Number}
 */
CalendarTimeShift.prototype.hoursOf = function (timeString) {
    var matchedTime =
        CalendarTimeShift.TIME_HOURS_MINUTES_REGEX.exec(timeString);
    return Number.parseInt(matchedTime[1]);
};

/**
 * Get the starting minutes of the shift.
 *
 * @returns {Number}
 */
CalendarTimeShift.prototype.startingMinutes = function () {
    return this.minutesOf(this.start);
};

/**
 * Get the ending minutes of the shift.
 *
 * @returns {Number}
 */
CalendarTimeShift.prototype.endingMinutes = function () {
    return this.minutesOf(this.end);
};

/**
 * Get the minutes of the given time string.
 *
 * @param {String} timeString - 00:00 -> 23:59
 * @returns {Number}
 */
CalendarTimeShift.prototype.minutesOf = function (timeString) {
    var matchedTime =
        CalendarTimeShift.TIME_HOURS_MINUTES_REGEX.exec(timeString);
    return Number.parseInt(matchedTime[2]);
};

/**
 * Transforms the hour passed in to a float value. e.g: 14:15 -> 14,25 or 04:30 -> 4,5
 *
 * @param  {String} timeString - The time formatted string in the format HH:mm form 00:00 to 23:59
 * @return {Number}               - The hour as a float number. e.g: 14:15 -> 14,25 or 04:30 -> 4,5
 */
CalendarTimeShift.prototype.transformHourToFloat = function (timeString) {
    var isValidTimeString =
        CalendarTimeShift.TIME_HOURS_MINUTES_REGEX.test(timeString);
    if (isValidTimeString) {
        var matchedTime =
            CalendarTimeShift.TIME_HOURS_MINUTES_REGEX.exec(timeString);
        var hours = Number.parseInt(matchedTime[1]);
        var minutes = Number.parseInt(matchedTime[2]);

        var timeAsFloat = hours + minutes / 60;

        // there is one special scenario that needs very specific handling
        // -->  24 doesn't exist in time calculations!
        //      after 23:59:59:9999 there will be 00:00:00 of the next day.
        //  BUT for our algorithm a shift end of 0 has to be interpreted as 24 in order to
        //  calculate the durations of that day properly.
        if (this.isFloatEquals(timeAsFloat, 0)) {
            timeAsFloat = 24;
        }

        return timeAsFloat;
    }

    throw Error("InvalidTimeString");
};

/**
 * Check if two 64bit Float aka Double numbers are equal to a certain tolerance
 * (to take rounding errors into account)
 *  --> see: https://github.com/sablono/sb-bimtime/pull/115
 *
 * @param {Number} lhs
 * @param {Number} rhs
 * @returns {Boolean}
 */
CalendarTimeShift.prototype.isFloatEquals = function (lhs, rhs) {
    return Math.abs(lhs - rhs) <= 1e-6;
};

CalendarTimeShift.prototype.getWorkingHours = function () {
    return this.endAsFloat - this.startAsFloat;
};

//
// CONTENT TO COPY TO BACKEND ENDS HERE !
//

export default CalendarTimeShift;
