import ConstantsModule from "./constants.module";

ConstantsModule.constant("LANGUAGES", [
    {
        abbreviation: "DE",
        language: "Deutsch (DE)",
        timezone: "Europe/Berlin",
    },
    {
        abbreviation: "EN",
        language: "English (EN)",
        timezone: "Europe/London",
    },
    {
        abbreviation: "ES",
        language: "Español (ES)",
        timezone: "Europe/Madrid",
    },
    {
        abbreviation: "FR",
        language: "Français (FR)",
        timezone: "Europe/Paris",
    },
]);
