import angular from "angular";
import templateEditorStateConfig from "./template_editor.route";
import TemplateEditorService from "./services/template_editor.service";
import TemplateEditorCtrl from "./template_editor.controller";
import GraphMlGeneratorService from "./services/graphml_generator.service";
import "angular-ui-router";
import "angular-material";
import "angular-messages";
import "./directives/sbDependencyContainer/sbDependencyContainer";
import "./directives/sbEditorTree/sbEditorTree";
import "./directives/sbGroupActivityRows/sbGroupActivityRows";
import "./dialogs/sbChangeParentDialog/sbChangeParentDialog";
import "./dialogs/sbExitEditDialog/sbExitEditDialog";
import "common/services/services";
import "common/toast/loadingToast";
import "common/intercom/intercomModule";

export default angular
    .module("sbApp.TemplateEditorCtrl", [
        "ui.router",
        "ngMaterial",
        "ngMessages",
        "sbApp.services",
        "sbApp.addTemplateDialog",
        "sbApp.sbBlurOnEnter",
        "sbApp.sbDependencyContainer",
        "sbApp.sbEditorTree",
        "sbApp.sbGroupActivityRows",
        "sbApp.sbChangeParentDialog",
        "sbApp.sbExitEditDialog",
        "sbApp.loadingToast",
        "sbApp.intercom",
    ])
    .constant("TEMPLATE_DIRECTIONS", {
        TOP: "template-view-go-top",
        RIGHT: "template-view-go-right",
        BOTTOM: "template-view-go-bottom",
        LEFT: "template-view-go-left",
        UNKNOWN: "template-view-go-center",
    })
    .constant("TEMPLATE_WORK_DONE_UNITS", [
        {
            value: "wd",
            translation: "_WORKDAYS",
        },
        {
            value: "d",
            translation: "_DAYS",
        },
        {
            value: "wh",
            translation: "_WORK_HOURS",
        },
        {
            value: "h",
            translation: "_HOURS",
        },
    ])
    .config(templateEditorStateConfig)
    .factory("templateEditorService", TemplateEditorService)
    .factory("$sbGraphMlGenerator", GraphMlGeneratorService)
    .controller("TemplateEditorCtrl", TemplateEditorCtrl);
