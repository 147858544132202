import _ from "lodash";

export default function visualizationSettingService() {
    "ngInject";

    const DEFAULT_VISUALIZATION_SETTING_MODEL = {
        showBorder: true,
    };

    const service = {
        model: DEFAULT_VISUALIZATION_SETTING_MODEL,
        hideBorder: _hideBorder,
        showBorder: _showBorder,
    };

    return service;

    function _hideBorder() {
        service.model = _.assign({}, service.model, {
            showBorder: false,
        });
        return service.model;
    }

    function _showBorder() {
        service.model = _.assign({}, service.model, {
            showBorder: true,
        });
        return service.model;
    }
}
