import GraphNode from "./graph_node.model";

//
// CONTENT TO COPY TO BACKEND STARTS HERE !
//

/**
 * Create simple graph class with nodes and edges
 *
 * @param nodes
 * @param edges
 * @constructor
 */
function SimpleGraph(nodes, edges) {
    this.nodes = [];
    this.idToNodesMap = {};

    // starting with empty graph
    //
    if (typeof nodes === "undefined" && typeof edges === "undefined") {
        return this;
    }

    // starting with initial values
    //
    this.addNodes(nodes);
    this.addEdges(edges);
}

SimpleGraph.prototype.addEdges = function (edges) {
    if (Array.isArray(edges)) {
        edges.forEach(function (edge) {
            this.addEdge(edge);
        }, this);
    }

    return this;
};

/**
 * Create multiple graph nodes from input objects.
 *
 * @param {Object[]} items
 *
 * @returns {SimpleGraph}
 */
SimpleGraph.prototype.addNodes = function (items) {
    if (Array.isArray(items)) {
        items.forEach(function (item) {
            this.addNode(item);
        }, this);
    }

    return this;
};

/**
 * Connects two nodes of a graph with a directed edge.
 *
 * @param {Object} item - Contains IDs of source and target of an edge.
 * @param {Object} item.source - ID of connected source node.
 * @param {Object} item.target - ID of connected target node.
 *
 * @returns {SimpleGraph}
 */
SimpleGraph.prototype.addEdge = function (item) {
    // get source and target node
    //
    var source = this.idToNodesMap[item.source];
    var target = this.idToNodesMap[item.target];

    var isValidEdge =
        typeof source !== "undefined" && typeof target !== "undefined";
    if (isValidEdge) {
        // update successor and predecessor of nodes
        //
        source.successors.push(target);
        target.predecessors.push(source);
    }

    return this;
};

/**
 * Create a graph node from a data item.
 *
 * @param {Object} item
 * @param {String} item.id - identifier of graph node
 *
 * @returns {SimpleGraph}
 */
SimpleGraph.prototype.addNode = function (item) {
    var node = new GraphNode(item, this);

    // add to nodes list
    //
    this.nodes.push(node);

    // add to id to nodes map
    //
    this.idToNodesMap[node.id] = node;

    return this;
};

SimpleGraph.prototype.numOfNodes = function () {
    return this.nodes.length;
};

/**
 * Traverse the graph in a topological order and checks if there are cycles in the graph: only access a node if all predecessors are already accessed -.
 *
 * @param {Function} forEachNode The function to execute for each node.
 *
 * @throws Error - cycle error
 */
SimpleGraph.prototype.traverse = function (forEachNode) {
    var sources = this.collectStartPoints();
    var nNodesVisited = this.topologicalTraverse(
        sources,
        "successors",
        "predecessors",
        forEachNode
    );

    if (nNodesVisited !== Object.keys(this.idToNodesMap).length) {
        /* eslint-disable angular/log */
        console.error(
            "More nodes traversed (" +
                nNodesVisited +
                ") than in graph (" +
                this.numOfNodes() +
                ") ."
        );
        /* eslint-enable angular/log */
        throw new Error("CyclicGraphException");
    }
};

SimpleGraph.prototype.traverseBackwards = function (forEachNode) {
    var sinks = this.collectEndPoints();
    var nNodesVisited = this.topologicalTraverse(
        sinks,
        "predecessors",
        "successors",
        forEachNode
    );

    if (nNodesVisited !== Object.keys(this.idToNodesMap).length) {
        /* eslint-disable angular/log */
        console.error(
            "More nodes traversed (" +
                nNodesVisited +
                ") than in graph (" +
                this.numOfNodes() +
                ") ."
        );
        /* eslint-enable angular/log */
        throw new Error("CyclicGraphException");
    }
};

SimpleGraph.prototype.topologicalTraverse = function (
    startNodes,
    traverseParam,
    checkParam,
    onEach
) {
    var queue = [].concat(startNodes);
    var numOfVisitedNodes = 0;
    onEach = onEach || function () {};

    while (queue.length) {
        var node = queue.shift();

        // visit node once
        //
        if (!node.isVisited) {
            node.isVisited = true;
            numOfVisitedNodes++;
            onEach(node);
        }

        // put connected nodes into queue
        //
        (node[traverseParam] || []).forEach(function (next) {
            var predecessorsOfNextNode = next[checkParam] || [];
            var allPreviousVisited = predecessorsOfNextNode.every(
                function (prev) {
                    return prev.isVisited;
                }
            );

            if (!next.isVisited && allPreviousVisited) {
                queue.push(next);
            }
        });
    }

    return numOfVisitedNodes;
};

SimpleGraph.prototype.hasCycle = function () {
    // while nodes left
    var nNodesVisited = this.topologicalTraverse(
        this.collectStartPoints(),
        "successors",
        "predecessors"
    );

    // check nNodesVisited vs all Nodes
    return nNodesVisited !== Object.keys(this.idToNodesMap).length;
};

/**
 * collectStartPoints - Get the elements without predecessors to start the calculation
 *
 * @return {Array}  - Array of starting nodes for the calculation
 */
SimpleGraph.prototype.collectStartPoints = function () {
    return this.nodes.filter(function (node) {
        return node.predecessors.length === 0;
    });
};

/**
 * collectStartPoints - Get the elements without predecessors to start the calculation
 *
 * @return {Array}  - Array of starting nodes for the calculation
 */
SimpleGraph.prototype.collectEndPoints = function () {
    return this.nodes.filter(function (node) {
        return node.successors.length === 0;
    });
};

//
// CONTENT TO COPY TO BACKEND ENDS HERE !
//

export default SimpleGraph;
