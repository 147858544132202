import templateHtml from "./diary_progress.html";
import _ from "lodash";

export default {
    name: "sbDiaryProgress",
    templateUrl: templateHtml,
    replace: true,
    bindings: {
        diaryEntry: "<",
        progressSnapshot: "<",
        isFinalisingEntry: "<",
        createProgressSnapshot: "&",
    },
    controller: ProgressCardController,
};

function ProgressCardController(
    $sbDetailsOverlay,
    $sbPagination,
    $filter,
    $sbTracking,
    $sbWeekCalendar,
    $sbErrorPresenter
) {
    "ngInject";
    var vm = this;

    var _naturalSort = $filter("naturalSort");

    vm.dataWasFetched = false;
    vm.hideRefreshButton = false;

    vm.pagination = {
        paginator: undefined,
        items: [],
        isNeeded: false,
        isNextDisabled: false,
        isPrevDisabled: false,
    };

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;

    vm.toggleActivities = toggleActivities;
    vm.showDeliverablesOverlay = showDeliverablesOverlay;
    vm.onGetNextPageClick = onGetNextPage;
    vm.onGetPrevPageClick = onGetPrevPage;
    vm.onGetFirstPageClick = onGetFirstPage;
    vm.onGetLastPageClick = onGetLastPage;
    vm.onRefreshData = onRefreshData;

    //////////

    function $onInit() {
        vm.hideRefreshButton = $sbWeekCalendar.selectedDateIsInTheFuture();
    }

    function $onChanges(changes) {
        if (_progressSnapshotBindingHasChanged(changes)) {
            vm.progressSnapshot = changes.progressSnapshot.currentValue;

            _updateViewModel();
            _initPagination();

            vm.dataWasFetched = true;
        }
    }

    function _initPagination() {
        var itemsForPagination = _naturalSort(
            vm.progressSnapshot.changes,
            "code"
        );
        var itemsPerPage = 8;

        vm.pagination.paginator = $sbPagination.getNewPaginator(
            itemsForPagination,
            itemsPerPage
        );

        vm.pagination.isNeeded =
            vm.pagination.paginator.getNumberOfItems() > itemsPerPage;

        onGetFirstPage();
        _updateButtonState();
    }

    function _progressSnapshotBindingHasChanged(changes) {
        return !_.isUndefined(_.get(changes, "progressSnapshot.currentValue"));
    }

    function _updateViewModel() {
        vm.hasProgressReported =
            vm.progressSnapshot.changes &&
            vm.progressSnapshot.changes.length > 0;
        vm.dataCreatedAt = vm.progressSnapshot.generatedAt.format("LLL");
    }

    function onGetFirstPage() {
        vm.pagination.items = vm.pagination.paginator.getFirstPage();

        _updateButtonState();
    }

    function onGetLastPage() {
        vm.pagination.items = vm.pagination.paginator.getLastPage();

        _updateButtonState();
    }

    function onGetNextPage() {
        if (vm.pagination.paginator.hasNextPage()) {
            vm.pagination.items = vm.pagination.paginator.getNextPage();
        }

        _updateButtonState();
    }

    function onGetPrevPage() {
        if (vm.pagination.paginator.hasPrevPage()) {
            vm.pagination.items = vm.pagination.paginator.getPrevPage();
        }

        _updateButtonState();
    }

    function _updateButtonState() {
        vm.pagination.isNextDisabled = !vm.pagination.paginator.hasNextPage();

        vm.pagination.isPrevDisabled = !vm.pagination.paginator.hasPrevPage();
    }

    function toggleActivities(deliverable) {
        deliverable.isActivitiesShowing = !deliverable.isActivitiesShowing;
    }

    function showDeliverablesOverlay($event, deliverable) {
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }

        $sbDetailsOverlay.toggleView("deliverable", deliverable.id);
    }

    function onRefreshData() {
        vm.isRefreshingData = true;

        return vm
            .createProgressSnapshot()
            .then(_updateViewModel)
            .then($sbTracking.siteDiary.onRefreshProgressData)
            .finally(function () {
                vm.isRefreshingData = false;
            })
            .catch($sbErrorPresenter.catch);
    }
}
