"use strict";

export default class ProjectSettingsService {
    constructor(
        $mdDialog,
        $state,
        $translate,
        $window,
        $sbAuth,
        $sbTracking,
        $sbProjectApi
    ) {
        "ngInject";
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.$translate = $translate;
        this.$window = $window;
        this.$sbAuth = $sbAuth;
        this.$sbProjectApi = $sbProjectApi;
        this.$sbTracking = $sbTracking;
    }

    prepareDeleteProjectDialog(projectName, projectId) {
        // project name
        return this.$mdDialog
            .confirmDestructiveAction()
            .class("sb-delete")
            .locals({
                i18n: {
                    dialogTitle: "CONFIRM_DELETE_PROJECT_MESSAGE",
                    warningParagraph: "DELETE_PROJECT_DIALOG_WARN_USER",
                    warningBannerMessage:
                        "DELETE_PROJECT_DIALOG_WARN_USER_INFO",
                    confirmationParagraph:
                        "DELETE_PROJECT_DIALOG_COPY_NAME_TO_DELETE",
                    confirmationInputLabel:
                        "DELETE_PROJECT_DIALOG_REPEAT_PROJECT_NAME_PLACEHOLDER",
                    confirmationBannerMessage:
                        "DELETE_PROJECT_DIALOG_WARN_USER_INFO",
                    alternativeActionText:
                        "DELETE_PROJECT_DIALOG_WARN_USER_ALT",
                },
                config: {
                    key: projectName,
                    skipPasswordStep: false,
                    hasAlternativeAction: true,
                },
                actions: {
                    confirmedAction: (dialog) => {
                        this.$sbTracking.projectSettings.changeStatus(
                            "Delete Project"
                        );

                        return this.$sbProjectApi
                            .erase(projectId)
                            .then(() => dialog.close())
                            .then(() =>
                                this.$state.go("sablono.projectSelection")
                            );
                    },
                    alternativeAction: (dialog) => {
                        this.$sbTracking.projectSettings.changeStatus(
                            "Request Project Export"
                        );

                        return this.$sbAuth.getCurrentUser().then((session) => {
                            return Promise.all([
                                this.$translate(
                                    "DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL_SUBJECT"
                                ),
                                this.$translate(
                                    "DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL",
                                    {
                                        projectId: projectId,
                                        username: session.name,
                                    }
                                ),
                            ]).then(([subject, body]) => {
                                const email = `mailto:support@sablono.com?subject=${subject}&body=${body}`;
                                this.$window.open(email, "_self");
                                dialog.close();
                            });
                        });
                    },
                },
            });
    }
}
