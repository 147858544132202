import angular from "angular";
import htmlTemplate from "./sbNoData.html";

/**
 * A basic directive to display an empty state.
 *
 * Give it a translation key for the title, message and a section.
 * You can transclude action buttons and they will be displayed at the bottom of the card.
 */
export default angular
    .module("sbApp.sbNoData", [])
    .directive("sbNoData", function () {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            transclude: true,
            scope: {
                cardHeader: "@?",
                icon: "@?",
                message: "@",
                url: "@?",
                trackClick: "<?",
                section: "&?",
            },
        };
    });
