import moment, { isMoment } from "moment";
import _ from "lodash";

export default function WorkflowGroupCtrl(
    ACTIVITY_TRANSITIONS,
    $filter,
    PADDING_SIZES,
    sbDeliverableWorkflowService,
    $scope
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      View model properties
    //
    /////////////////////

    vm.row = {};

    /////////////////////
    //
    //      Lifecycle Hooks
    //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
    //
    /////////////////////

    vm.$onChanges = function (changes) {
        vm.row = mapTo(vm.node);
        vm.row.isExpanded = _.get(changes, "expanded.currentValue");
    };

    // progress is the only property change that supports change detection
    // //
    $scope.$watch("$ctrl.node.progress", function (newProgress, oldProgress) {
        if (newProgress !== oldProgress) {
            vm.row.activities = {
                all: vm.node.getQuantityOfActivities(),
                done: vm.node.getQuantityOfActivitiesDone(),
            };
        }
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function mapTo(node) {
        return {
            padding:
                sbDeliverableWorkflowService.getParentCount(node) *
                    PADDING_SIZES.increment +
                PADDING_SIZES.initial +
                "px",
            hasChevronIcon: node.children && node.children.length > 0,
            hasTooltip: node.getActivities().length === 0,
            text: node.name,
            activities: {
                all: node.getQuantityOfActivities(),
                done: node.getQuantityOfActivitiesDone(),
            },
            dates: {
                isVisible: !!node.getMostReleventStartDate(),
                startLabel: "START_DATE",
                start: moment
                    .tz(node.getMostReleventStartDate(), node.timezone)
                    .format("L"),
                endLabel: "END_DATE",
                end: moment
                    .tz(node.getMostReleventEndDate(), node.timezone)
                    .format("L"),
            },
            tags: {
                claims: node._noteStatistic.openClaims,
                obstructions: node._noteStatistic.openObstructions,
                isBehind: node.isBehind(),
            },
            update: _deriveStateChangeInfo(node),
        };
    }

    function _deriveStateChangeInfo(node) {
        const stateChange = node.getLatestStateChange();
        if (stateChange) {
            return {
                hasUpdate: true,
                authorInitials: _.get(
                    stateChange,
                    "changedByUser.initials",
                    "??"
                ),
                authorName: _.get(
                    stateChange,
                    "changedByUser.displayName",
                    "User left the project"
                ),
                changedAt: _.get(stateChange, "changedAt"),
            };
        } else {
            return {
                hasUpdate: false,
            };
        }
    }
}
