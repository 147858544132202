export default function ImportCtrl($mdDialog, $state) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.uploadModel = undefined;

    vm.upload = upload;
    vm.handleWrongFileExtension = handleWrongFileExtension;
    vm.addFileNameToUploadModel = addFileNameToUploadModel;
    vm.setUploadModel = setUploadModel;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Event handler if you try to load a file with an unsupported extension
     *
     * @param $event The click event of the file chooser.
     */
    function handleWrongFileExtension($event) {
        $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_WRONG_EXTENSION_TITLE")
                .content("DIALOG_WRONG_EXTENSION_CONTENT")
                .targetEvent($event)
        );
    }

    /**
     * Change the file name of the source on the upload model
     *
     * @param fileName Name of the file that is in the file chooser currently.
     */
    function addFileNameToUploadModel(fileName) {
        vm.uploadModel.setSourceFileName(fileName);
    }

    /**
     * Set the upload model and file name to the vm.
     *
     * @param model The ComponentUploadModel to send.
     * @param fileName The matching file name.
     */
    function setUploadModel(model, fileName) {
        vm.uploadModel = model;
        addFileNameToUploadModel(fileName);
    }

    /**
     * Upload the model that is currently stored on the vm.
     * Actually it will send you to the upload state with proper event data.
     */
    function upload() {
        // go to upload state
        //
        $state.go("sablono.project.import.upload", {
            uploadModel: vm.uploadModel,
            previousState: $state.current.name,
        });
    }
}
