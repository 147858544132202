/**
 * @constructs accountVerificationService
 * @memberOf sbApp
 * @description Handles account verification with DB.
 *
 * @param {string} HATCHBUCK_CREATE_USER_URL    - hatchbuck URL
 * @param {Object} request                      - requestService for web requests
 *
 * @return {Object} hatchBuckService
 */
export default function accountVerificationService() {
    var service = {};

    return service;
}
