import angular from "angular";
import _ from "lodash";
import html from "./team_select.html";

export default angular
    .module("sbApp.common.components.teamSelect", [])
    .controller("TeamSelectCtrl", function TeamSelectController() {
        "ngInject";

        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        vm.NEW = "CREATE_NEW_TEAM";
        vm.UNRESTRICTED = "ALL_TEAMS";
        vm.MIXED_TEAM_ASSIGNMENT = "MIXED_TEAM_ASSIGNMENT";

        vm.selectedTeam = {};
        vm.label = undefined;
        vm.lastAssignment = vm.selectedTeam;
        vm.hasMultiple = false;
        vm.borderStyle;

        vm.onSelectTeam = onSelectTeam;
        vm.onSelectClose = onSelectClose;
        vm.onSelectOpen = onSelectOpen;
        vm.getTeamDisplayName = getTeamDisplayName;

        vm.isTeamCreationAvailable = angular.isFunction(vm.onTeamCreate);

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = function $onInit() {
            vm.availableTeams = angular.copy(vm.teams);
            vm.selectedTeam = calculateAssignment(
                vm.assignedId,
                vm.availableTeams
            );
            vm.borderStyle = deriveBorderStyle();
        };

        vm.$onChanges = function $onChanges(changes) {
            var teams = changes.teams;
            var assignedId = changes.assignedId;
            var teamsChanged =
                teams && teams.currentValue !== teams.previousValue;
            var assignmentChanged =
                assignedId &&
                assignedId.currentValue !== assignedId.previousValue;

            if (teamsChanged) {
                vm.availableTeams = angular.copy(teams.currentValue);
            }

            if (teamsChanged && !assignmentChanged) {
                // reset the selected team to the new team copy reference.
                //
                if (
                    _.isString(vm.selectedTeam) ||
                    vm.selectedTeam === null ||
                    _.isArray(vm.selectedTeam)
                ) {
                    vm.selectedTeam = calculateAssignment(
                        vm.selectedTeam,
                        vm.availableTeams
                    );
                } else {
                    vm.selectedTeam = calculateAssignment(
                        vm.selectedTeam.id,
                        vm.availableTeams
                    );
                }
            }

            if (assignmentChanged) {
                vm.selectedTeam = calculateAssignment(
                    assignedId.currentValue,
                    vm.availableTeams
                );
            }
            vm.borderStyle = deriveBorderStyle();
        };

        vm.$onDestroy = angular.noop;

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        function onSelectOpen() {
            vm.lastAssignment = vm.selectedTeam;
        }

        function deriveBorderStyle() {
            if (vm.selectedTeam === null || _.isString(vm.selectedTeam)) {
                return {};
            }

            if (_.isArray(vm.selectedTeam) && vm.selectedTeam.length > 1) {
                return {
                    "border-image":
                        "linear-gradient(to bottom," + _deriveColors() + ") 1",
                };
            }

            return { "border-color": _deriveColors() };
        }

        function _deriveColors() {
            if (_.isArray(vm.selectedTeam) && vm.selectedTeam.length === 1) {
                return vm.selectedTeam[0].color;
            }

            // This part calculates the gradient colors.
            // The desired format is "red 0% 50%, blue 50% 100%.
            if (_.isArray(vm.selectedTeam) && vm.selectedTeam.length > 1) {
                return vm.selectedTeam
                    .map((team, i) => {
                        const sizeOfSectionInPercentage =
                            100 / vm.selectedTeam.length; // ie. 50 for 2 sections
                        const percentageFrom =
                            sizeOfSectionInPercentage * i + "%"; // ie. first element goes from 0%
                        const percentageTo =
                            sizeOfSectionInPercentage * (i + 1) + "%"; // to 50%

                        return `${team.color} ${percentageFrom} ${percentageTo}`;
                    })
                    .join();
            }

            return vm.selectedTeam.color;
        }

        function onSelectClose() {}

        function calculateAssignment(assignedTeamID, teams) {
            if (_.isNumber(assignedTeamID) || _.isString(assignedTeamID)) {
                return getTeamByIdOrDefault(teams, assignedTeamID, null);
            }

            if (_.isArray(assignedTeamID)) {
                const assignedTeamIDs = assignedTeamID;

                if (vm.allowMultiple) {
                    vm.hasMultiple = true;
                    return _.intersectionWith(
                        teams,
                        assignedTeamID,
                        (teamA, teamId) => {
                            return teamA.id === teamId;
                        }
                    );
                } else {
                    if (assignedTeamIDs.length === 1) {
                        return getTeamByIdOrDefault(
                            teams,
                            assignedTeamIDs[0],
                            vm.UNRESTRICTED
                        );
                    }
                    vm.hasMultiple = true;
                    return vm.MIXED_TEAM_ASSIGNMENT;
                }
            }

            return vm.UNRESTRICTED;
        }

        function getTeamByIdOrDefault(teams, teamId, defaultValue) {
            return _.defaultTo(
                _.find(teams, {
                    id: teamId,
                }),
                defaultValue
            );
        }

        function getTeamDisplayName(team) {
            if (!team) return;
            if (team.isProjectTeam && vm.i18nProjectTeam) {
                return vm.i18nProjectTeam;
            } else {
                return team.getDisplayName();
            }
        }

        function onSelectTeam() {
            const newValue = vm.selectedTeam;
            const oldValue = vm.lastAssignment;

            if (vm.allowMultiple) {
                return _selectTeams(oldValue, newValue);
            }
            return _selectTeam(oldValue, newValue);
        }

        function _selectTeams(oldValue, newValue) {
            const shouldTriggerTeamCreation = _.find(newValue, (value) => {
                return value === vm.NEW;
            });

            if (shouldTriggerTeamCreation) {
                vm.selectedTeam = oldValue;
                return vm.onTeamCreate(
                    newValue.filter((team) => team !== vm.NEW)
                );
            }
            // reset to old selection until set new from outside
            //
            if (oldValue === vm.MIXED_TEAM_ASSIGNMENT) {
                vm.selectedTeam = oldValue;
            }

            return vm.onTeamSelect({
                team: newValue,
            });
        }

        function _selectTeam(oldValue, newValue) {
            switch (newValue) {
                case vm.NEW:
                    vm.selectedTeam = oldValue;
                    return vm.onTeamCreate();

                case vm.UNRESTRICTED:
                    return vm.onTeamSelect({
                        team: null,
                    });

                default:
                    // reset to old selection until set new from outside
                    //
                    if (oldValue === vm.MIXED_TEAM_ASSIGNMENT) {
                        vm.selectedTeam = oldValue;
                    }

                    return vm.onTeamSelect({
                        team: newValue,
                    });
            }
        }
    })
    .directive("sbTeamSelect", function () {
        return {
            templateUrl: html,
            controllerAs: "teamSelect",
            bindToController: true,
            scope: {
                teams: "<",
                assignedId: "<",
                showEverybodyOption: "<",
                errorMessage: "<",
                allowMultiple: "<?",
                i18nProjectTeam: "@?",
                i18nLabel: "@?",
                i18nUnassigned: "@?",
                onTeamCreate: "&?",
                onTeamSelect: "&?",
                ngDisabled: "<?",
            },
            compile: function (tElement, tAttrs) {
                const mdSelect = tElement.find("md-select");
                if (tAttrs.allowMultiple) {
                    mdSelect.attr("multiple", true);
                }
            },
            controller: "TeamSelectCtrl",
        };
    });
