export default function (module) {
    module.config(
        function (
            $stateProvider,
            $locationProvider,
            $urlRouterProvider,
            $urlMatcherFactoryProvider
        ) {
            $urlMatcherFactoryProvider.caseInsensitive(true);

            var startUrl = "/account/login";

            $urlRouterProvider.when("", startUrl);
            $urlRouterProvider.otherwise(startUrl);

            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false,
            });
        }
    );
}
