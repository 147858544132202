import PresentableError from "./PresentableError";

function CyclicGraphError() {
    this.message = "ERROR_EDGE_WOULD_INTRODUCE_CYCLE";
}

CyclicGraphError.prototype = Object.create(PresentableError.prototype);

CyclicGraphError.prototype.preferredPresentationStyle = function () {
    return PresentableError.presentationStyle.TOAST;
};

CyclicGraphError.prototype.shouldAutoClose = function () {
    return true;
};

export default CyclicGraphError;
