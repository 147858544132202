import stateTemplateHtml from "./template_editor.html";
import stateTemplateDetailViewHtml from "./template_editor_detail_view.html";
import "angular-ui-router";

export default function templateEditorStateConfig($stateProvider) {
    "ngInject";

    $stateProvider.state("sablono.project.templateEditor", {
        abstract: true,
        url: "/template/editor/{templateId:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}}",
        templateUrl: stateTemplateHtml,
        controller: "TemplateEditorCtrl",
        data: {
            title: "SECTION_TEMPLATE_EDITOR",
            category: "PLAN",
            hasBackButton: true,
            backButtonTarget: "sablono.project.templates",
            searchEnabled: true,
            searchPlaceholder:
                "INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATE_CONTENT_MESSAGE",
        },
        resolve: {
            templateId: function ($stateParams) {
                "ngInject";

                return $stateParams.templateId;
            },
            projectCalendar: function ($sbProject, $stateParams) {
                "ngInject";

                // TODO: Migration
                //return $sbProject.getCalendar($stateParams.projectId);
                return {
                    calendar: {},
                };
            },
            // project is required. This force to wait until project
            // is loaded in memory for this function to execute
            preloadedGraph: function (
                project,
                templateId,
                templateEditorService
            ) {
                "ngInject";

                return templateEditorService.getGraphFromLocalStoreOrServer(
                    templateId
                );
            },
            teams: function ($sbTeam, $stateParams) {
                "ngInject";

                return $sbTeam.getTeams($stateParams.projectId);
            },
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";

            intercomService.track(
                TRACKED_EVENTS.ENTERED_WORKFLOW_TEMPLATES_EDITOR_PAGE
            );
        },
    });
    $stateProvider.state("sablono.project.templateEditor.nodeDetailView", {
        url: "?nodeId",
        gaPageTrack: "/template/editor", // google analytics extension
        templateUrl: stateTemplateDetailViewHtml,
    });
}
