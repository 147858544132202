class JobsWebService {
    constructor($sbRequest, API_VERSION, $sbStorage, $timeout, $log) {
        "ngInject";
        this.$sbStorage = $sbStorage;
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
        this.$log = $log;
        this.$timeout = $timeout;
        this.STATE_CREATED = "created";
        this.STATE_STARTED = "started";
        this.STATE_SUCCEEDED = "succeeded";
        this.STATE_FAILED = "failed";
        this.JOB_DELAY_MULTIPLIER = 2;
        this.JOB_DELAY_IN_MS = 100;
        this.JOB_DELAY_MAXIMUM_MS = 2000;
    }

    /**
     * This endpoint returns status of a given job.
     *
     * @param jobId {string}        The unique identifier of the Asynchronous Job. UUID
     */
    get(jobId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/jobs/${jobId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    async poll(execute, { isFinished, delay }) {
        let numOfAttempts = 0;
        let result;

        do {
            numOfAttempts++;

            // delay execution and run task afterwards (next task will be executed after (delay + request duration))
            //
            await this.wait(delay(numOfAttempts));
            result = await execute(numOfAttempts);
        } while (isFinished(result));

        return result;
    }

    wait(ms = 0) {
        return this.$timeout(() => {
            this.$log.debug(
                `[${new Date().toISOString()}] Polling :: Waited ${ms} ms...`
            );
        }, ms);
    }

    /**
     * Query job until success or failure.
     *
     * @param {string} jobId
     * @returns {Promise<void>}
     */
    async waitFor(jobId) {
        const initialTime = Date.now();

        const result = await this.poll(() => this.get(jobId), {
            isFinished: (job) => {
                return (
                    job.state !== this.STATE_SUCCEEDED &&
                    job.state !== this.STATE_FAILED
                );
            },
            delay: (numOfAttempts) => this.delayWithUpperLimit(numOfAttempts),
        });

        this.$log.debug(
            `[${new Date().toISOString()}] Polling :: Finished Job (${jobId}) after ${
                Date.now() - initialTime
            }ms. Reason: ${result && result.state}`
        );

        // clean up job from storage
        this.removeJobFromStorage(jobId);

        return result;
    }

    delayWithUpperLimit(numOfAttempts) {
        const delayInMs =
            this.JOB_DELAY_IN_MS *
            Math.pow(this.JOB_DELAY_MULTIPLIER, numOfAttempts);

        const isGreaterThanAllowedDelay = delayInMs > this.JOB_DELAY_MAXIMUM_MS;
        if (isGreaterThanAllowedDelay) {
            return this.JOB_DELAY_MAXIMUM_MS;
        }
        return delayInMs;
    }

    removeJobFromStorage(jobId) {
        // Get jobs from storage
        let jobs = this.$sbStorage.get(this.$sbStorage.ASYNC_JOB_KEY) || [];

        // Remove the job
        jobs = jobs.filter((job) => job !== jobId);

        // Add to local storage
        return this.$sbStorage.store(
            this.$sbStorage.ASYNC_JOB_KEY,
            JSON.stringify(jobs)
        );
    }
}

export default JobsWebService;
