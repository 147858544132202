export default class ActivityTrackerWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the list of structures with an aggregation of included activities for a given project.
     *
     * @param projectId {string}            UUID
     * @param byStructureId {string}        Filter data to show deliverables with given structure. UUID
     * @param byProcessTemplateId {string}  Filter data to show deliverables with given process template. UUID
     * @param byDeliverableName             Filter by name of deliverables.
     * @param byDeliverableCode             Filter by code of deliverables.
     * @param byDeliverableDesc             Filter by description of deliverables.
     * @param byDeliverableType             Filter by type of deliverables.
     * @param bySearch                      Filter by either name,description or code of deliverables.
     * @param [limit] {number}              Number of aggregates to include in the requested look ahead program view.
     * @param [offset] {number}             A query parameter used to paginate through the items of a collection pointing to a specific position in the collection.
     */
    getStructureAggregates(
        projectId,
        {
            byStructureId,
            byProcessTemplateId,
            byDeliverableName,
            byDeliverableCode,
            byDeliverableDesc,
            byDeliverableType,
            bySearch,
            limit,
            offset,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/activity-tracker`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                limit,
                offset,
                by_structure_id: byStructureId,
                by_process_template_id: byProcessTemplateId,
                by_deliverable_name: byDeliverableName,
                by_deliverable_code: byDeliverableCode,
                by_deliverable_desc: byDeliverableDesc,
                by_deliverable_type: byDeliverableType,
                search: bySearch,
            },
        });
    }

    /**
     * This endpoint returns the list of deliverables with activities for a given structure
     *
     * @param projectId {string}            UUID
     * @param structureId {string}          UUID
     * @param byProcessTemplateId {string}  Filter data to show deliverables with given process template. UUID
     * @param byDeliverableName             Filter by name of deliverables.
     * @param byDeliverableCode             Filter by code of deliverables.
     * @param byDeliverableDesc             Filter by description of deliverables.
     * @param byDeliverableType             Filter by type of deliverables.
     * @param bySearch                      Filter by either name,description or code of deliverables.
     * @param [limit] {number}              Number of aggregates to include in the requested look ahead program view.
     * @param [offset] {number}             A query parameter used to paginate through the items of a collection pointing to a specific position in the collection.
     */
    getDeliverablesOfStructure(
        projectId,
        structureId,
        {
            byProcessTemplateId,
            byDeliverableName,
            byDeliverableCode,
            byDeliverableDesc,
            byDeliverableType,
            bySearch,
            limit,
            offset,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/activity-tracker/${structureId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                limit,
                offset,
                by_process_template_id: byProcessTemplateId,
                by_deliverable_name: byDeliverableName,
                by_deliverable_code: byDeliverableCode,
                by_deliverable_desc: byDeliverableDesc,
                by_deliverable_type: byDeliverableType,
                search: bySearch,
            },
        });
    }
}
