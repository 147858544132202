import angular from "angular";
import htmlTemplate from "./sb_file_input.html";
import FileInputController from "./sb_file_input.controller";
import "./sbOnChange/sbOnChange";

export default angular
    .module("sbApp.sbFileInput", ["sbApp.sbOnChange"])
    .directive("sbFileInput", function FileInputDirective() {
        return {
            restrict: "E",
            transclude: true,
            templateUrl: htmlTemplate,
            scope: {
                allowedExtensions: "=",
                sbFileChanged: "&",
                sbWrongExtension: "&",
                options: "=?",
            },
            controller: FileInputController,
            bindToController: true,
            controllerAs: "fileCtrl",
        };
    });
