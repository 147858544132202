export default class SuggestionsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns the list of activities and groups that match a given filter.
     * @param type {string}     The type of suggestion. "activity" or "group"
     * @param search {string}   A search term - it's up to the endpoint to decide how to interpret it.
     * @param excludeProcessTemplateId {string}   Id of the template to be excluded when searching for group suggestions
     */
    getCollection(type, search, projectId, byExcludeProcessTemplateId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/suggestions`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                type,
                search,
                by_project_id: projectId,
                by_exclude_process_template_id: byExcludeProcessTemplateId,
            },
        });
    }
}
