import _ from "lodash";

export default function ActivityDetailsUserInteractionService(
    $sbTracking,
    USER_INTERACTION
) {
    "ngInject";

    var userInteraction = [];

    var service = {
        changeDuration: addDurationAndRemoveEndDate,
        changeStartDate: addStartDateAndRemoveEndDate,
        changeEndDate: addEndDateAndRemoveStartDate,
        clear: clear,
        contains: contains,
        get: get,
        hasHappened: hasHappened,
        trackGA: trackChangedValues,
    };

    return service;

    function addDurationAndRemoveEndDate() {
        exchange(
            [USER_INTERACTION.DURATION, USER_INTERACTION.START_DATE],
            USER_INTERACTION.END_DATE
        );
    }

    function addStartDateAndRemoveEndDate() {
        exchange([USER_INTERACTION.START_DATE], USER_INTERACTION.END_DATE);
    }

    function addEndDateAndRemoveStartDate() {
        exchange([USER_INTERACTION.END_DATE], USER_INTERACTION.START_DATE);
    }

    function clear() {
        userInteraction = [];
    }

    function contains(change) {
        return _.indexOf(userInteraction, change) >= 0;
    }

    function get() {
        return userInteraction.slice(); // returns a shallow copy
    }

    function hasHappened() {
        return userInteraction.length > 0;
    }

    function exchange(newValues, removedValue) {
        userInteraction = _.chain(userInteraction)
            .concat(newValues)
            .without(removedValue)
            .uniq()
            .value();
    }

    function trackChangedValues() {
        if (service.contains(USER_INTERACTION.START_DATE)) {
            $sbTracking.leanBoard.activity.onSetLastPlannedStartDate();
        }
        if (service.contains(USER_INTERACTION.END_DATE)) {
            $sbTracking.leanBoard.activity.onSetLastPlannedDueDate();
        }
        if (service.contains(USER_INTERACTION.DURATION)) {
            $sbTracking.leanBoard.activity.onDurationChanged();
        }
    }
}
