import angular from "angular";

import imageWebService from "./image.api.service";

import ActivitiesWebService from "./activities.api.service";
import ActivityJobsWebService from "./activity_jobs.api.service";
import CalendarsWebService from "./calendars.api.service";
import ChecklistHandoverWebService from "./checklist_handover.api.service";
import ChecklistTemplatesWebService from "./checklist_templates.api.service";
import ChecklistsWebService from "./checklists.api.service";
import CostPackageMembershipsWebService from "./cost_package_memberships.api.service";
import CostPackagesWebService from "./cost_packages.api.service";
import CostPackageGroupsWebService from "./cost_package_groups.api.service";
import DeliverablesWebService from "./deliverables.api.service";
import DeliverableJobsWebService from "./deliverable_jobs.api.service";
import DeliverableNotesWebService from "./deliverable_notes.api.service";
import DependenciesWebService from "./dependencies.api.service";
import DependencyJobsWebService from "./dependency_jobs.api.service";
import DiaryEntriesWebService from "./diary_entries.api.service";
import FilesWebService from "./file.api.service";
import InspectionsWebService from "./inspection.api.service";
import LookAheadProgramWebService from "./look_ahead_program.api.service";
import LookAheadSessionsWebService from "./look_ahead_sessions.api.service";
import LookAheadJobsWebService from "./look_ahead_jobs.api.service";
import MembershipsWebService from "./memberships.api.service";
import MembershipInvitationsWebService from "./membership-invitations.api.service";
import NoteTemplatesWebService from "./note_templates.api.service";
import NotesWebService from "./notes.api.service";
import PerformanceWebService from "./performance.api.service";
import ProcessTemplateJobsWebService from "./process_template_jobs.api.service";
import ProcessTemplatesWebService from "./process_templates.api.service";
import ReportingActivitiesWebService from "./reporting_activities.api.service";
import ReportingDeliverablesWebService from "./reporting_deliverables.api.service";
import ReportingNotesWebService from "./reporting_notes.api.service";
import ReportingProjectsWebService from "./reporting_projects.api.service";
import SnapshotsWebService from "./snapshots.api.service";
import SchedulingWebService from "./scheduling.api.service";
import SourcesWebService from "./sources.api.service";
import StateChangesWebService from "./state_changes.api.service";
import StructureNodeJobsWebService from "./structure_node_jobs.api.service";
import StructureNodesWebService from "./structure_nodes.api.service";
import TeamsWebService from "./teams.api.service";
import UsersWebService from "./users.api.service";
import VisualizationsWebService from "./visualizations.api.service";
import WorkFeedWebService from "./work_feed.api.service";
import DeliverableProcessWebService from "./deliverable_process.api.service";
import JobsWebService from "./jobs.api.service";
import StorageService from "./storage.service";
import SuggestionsWebService from "./suggestions.api.service";
import ProjectsWebService from "./projects.api.service";
import ProjectSettingsWebService from "./project-settings.api.service";
import EulaWebService from "./eula.api.service";
import ActivityTrackerWebService from "./activity-tracker.api.service";
// pagination implementation
import LookAheadProgramPagingSource from "./pagination/look_ahead_program.paging_source";

export default angular
    .module("sbApp.api", [])
    .constant("API_VERSION", "v1")
    .value("LookAheadProgramPagingSource", LookAheadProgramPagingSource)
    .factory("$sbImageApi", imageWebService)
    .service("$sbActivitiesApi", ActivitiesWebService)
    .service("$sbActivityJobsApi", ActivityJobsWebService)
    .service("$sbActivityTrackerApi", ActivityTrackerWebService)
    .service("$sbCalendarsApi", CalendarsWebService)
    .service("$sbChecklistHandoverApi", ChecklistHandoverWebService)
    .service("$sbChecklistTemplatesApi", ChecklistTemplatesWebService)
    .service("$sbChecklistsApi", ChecklistsWebService)
    .service("$sbEulaApi", EulaWebService)
    .service("$sbCostPackageMembershipsApi", CostPackageMembershipsWebService)
    .service("$sbCostPackageGroupsApi", CostPackageGroupsWebService)
    .service("$sbCostPackagesApi", CostPackagesWebService)
    .service("$sbDeliverablesApi", DeliverablesWebService)
    .service("$sbDeliverableProcessApi", DeliverableProcessWebService)
    .service("$sbDeliverableJobsApi", DeliverableJobsWebService)
    .service("$sbDeliverableNotesApi", DeliverableNotesWebService)
    .service("$sbDependenciesApi", DependenciesWebService)
    .service("$sbDependencyJobsApi", DependencyJobsWebService)
    .service("$sbDiaryEntriesApi", DiaryEntriesWebService)
    .service("$sbFilesApi", FilesWebService)
    .service("$sbInspectionsApi", InspectionsWebService)
    .service("$sbLookAheadProgramApi", LookAheadProgramWebService)
    .service("$sbLookAheadJobsApi", LookAheadJobsWebService)
    .service("$sbLookAheadSessionsApi", LookAheadSessionsWebService)
    .service("$sbMembershipsApi", MembershipsWebService)
    .service("$sbMembershipInvitationsApi", MembershipInvitationsWebService)
    .service("$sbNoteTemplatesApi", NoteTemplatesWebService)
    .service("$sbNotesApi", NotesWebService)
    .service("$sbPerformanceApi", PerformanceWebService)
    .service("$sbProcessTemplatesApi", ProcessTemplatesWebService)
    .service("$sbProcessTemplateJobsApi", ProcessTemplateJobsWebService)
    .service("$sbReportingActivitiesApi", ReportingActivitiesWebService)
    .service("$sbReportingDeliverablesApi", ReportingDeliverablesWebService)
    .service("$sbReportingNotesApi", ReportingNotesWebService)
    .service("$sbReportingProjectsApi", ReportingProjectsWebService)
    .service("$sbProjectSettingsApi", ProjectSettingsWebService)
    .service("$sbSnapshotsApi", SnapshotsWebService)
    .service("$sbSourcesApi", SourcesWebService)
    .service("$sbSchedulingApi", SchedulingWebService)
    .service("$sbStateChangesApi", StateChangesWebService)
    .service("$sbStructureNodeJobsApi", StructureNodeJobsWebService)
    .service("$sbStructureNodesApi", StructureNodesWebService)
    .service("$sbSuggestionsApi", SuggestionsWebService)
    .service("$sbUsersApi", UsersWebService)
    .service("$sbTeamsApi", TeamsWebService)
    .service("$sbVisualizationsApi", VisualizationsWebService)
    .service("$sbWorkFeedApi", WorkFeedWebService)
    .service("$sbJobApi", JobsWebService)
    .service("$sbStorage", StorageService)
    .service("$sbProjectsApi", ProjectsWebService);
