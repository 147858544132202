import stateTemplateHtml from "./templates.html";
import "angular-ui-router";

export default function templatesStateConfig($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.templates", {
        templateUrl: stateTemplateHtml,
        controller: "TemplatesCtrl",
        controllerAs: "$ctrl",
        url: "/templates?init",
        gaPageTrack: "/templates", // google analytics extension
        data: {
            title: "SECTION_TEMPLATES_TITLE",
            category: "PLAN",
            searchEnabled: true,
            searchPlaceholder: "INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATES_MESSAGE",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            intercomService.track(
                TRACKED_EVENTS.ENTERED_WORKFLOW_TEMPLATES_PAGE
            );
        },
    });
}
