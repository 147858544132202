import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";
import ViewSelectionProvider from "./ViewSelectionProvider";

class DeliverableSelection extends ViewSelectionProvider {
    constructor() {
        super();
        this.projectId = null;
        this.odataFilterFactory = null;
        this.availableItems = null;
        this.size = null;
    }

    getSize() {
        if (this.isSelectAllActive()) {
            return this.size;
        }
        if (this.hasActiveSelections()) {
            return this.length;
        }
        return this.size || this.length;
    }

    // @override
    deselect(selectedObject) {
        if (this.isSelectAllActive() && this.hasAllSelectionsLocally()) {
            this.selectAllActive = false;
            this.length = 0;

            // select all in available items list except the one to deselect
            this.availableItems.forEach((item) => {
                if (item !== selectedObject) {
                    this.select(item);
                }
            });
        } else {
            super.deselect(selectedObject);
        }
    }

    /**
     * Will return the real selection of the selection provider if select all is NOT active.
     * Will return the local selection (this.availableItems) if select all is active -> can be undefined if not set
     *
     * @return {Array | undefined}
     */
    getLocalSelection() {
        if (this.isSelectAllActive()) {
            return this.availableItems || [];
        } else {
            return this.selections() || [];
        }
    }

    setAvailableItemsForSelection(availableItems) {
        this.availableItems = availableItems;
    }

    /**
     * Check if getLocalSelection will result in a valid selection state.
     *
     * @return {boolean}
     */
    hasAllSelectionsLocally() {
        if (this.isSelectAllActive() && !this.availableItems) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * create an odata $filter string that represents the current state of the selection.
     *
     * if the selection is in a select all state
     *      -> return the predefined filter string if available
     * otherwise create a concatenated ID filter string
     *
     * @return {string} the filter condition in OData language
     */
    createOdataFilterString() {
        if (this.isSelectAllActive() && this.odataFilterFactory) {
            return this.odataFilterFactory.get();
        } else {
            const localSelection = this.getLocalSelection();
            const quantity = localSelection.length;

            const odataFactory = localSelection.reduce(
                (factory, deliverable, index) => {
                    factory.eq("ID", deliverable.ID);
                    if (index + 1 < quantity) {
                        factory.or(); // chain with or
                    }
                    return factory;
                },
                new ODataFilterFactory()
            );

            return odataFactory.get();
        }
    }

    /**
     * Very often we allow service calls to accept either an array of entities with IDs or a string
     * representing a filter condition in odata syntax. Based on the configuration of this selection
     * model getPreferredCondition will give you one or the other.
     *
     * @return {Array | string}
     */
    getPreferredCondition() {
        if (this.isSelectAllActive()) {
            return this.createOdataFilterString();
        } else {
            return this.getLocalSelection();
        }
    }
}

export default DeliverableSelection;
