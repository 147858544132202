import _ from "lodash";
import templateHtml from "./commercial_header.html";
import CostGroup from "../../model/cost_group.class";

export default {
    name: "sbCommercialCardHeader",
    templateUrl: templateHtml,
    bindings: {
        costGroups: "<",
        selectedCostGroup: "<",
        onSelectCostGroup: "&",
        onDeleteCostGroup: "&",
        onFormInputChange: "&",
        onChangeReportedAt: "&",
        onEditMembership: "&",
        hasEditRights: "<",
        isLoading: "<",
        isUpdatingMembership: "<",
    },
    controllerAs: "commercialHeader",
    controller: CommercialHeaderCtrl,
};

function CommercialHeaderCtrl($scope, $sbTracking) {
    "ngInject";

    var vm = this;

    vm.data = {
        budget: {
            allocated: 0,
            started: 0,
            waitingForConfirmation: 0,
            done: 0,
        },
        weighting: {
            isValid: true,
            total: 100,
            remaining: 0,
        },
        currency: "",
        numberOfManagers: 0,
        numberOfReviewers: 0,
    };

    vm.currencies = [
        {
            icon: "mdi mdi-currency-eur",
            name: "EUR",
        },
        {
            icon: "mdi mdi-currency-usd",
            name: "USD",
        },
        {
            icon: "mdi mdi-currency-usd",
            name: "AUD",
        },
        {
            icon: "mdi mdi-currency-gbp",
            name: "GBP",
        },
    ];

    vm.costGroupSearchTerm = "";
    vm.chosenCurrency = vm.currencies[0];

    vm.$onChanges = $onChanges;
    vm.onCurrencyChanged = onCurrencyChanged;
    vm.clearSearchTerm = clearSearchTerm;

    // watcher
    //
    var costGroupWatcher = $scope.$watch(
        "commercialHeader.selectedCostGroup",
        function () {
            _updateData();
        },
        true
    );

    // unbind watcher
    //
    $scope.$on("$destroy", function () {
        costGroupWatcher();
    });

    //////////

    function _updateData() {
        vm.data = {
            budget: {
                allocated: vm.selectedCostGroup.calcDistributedCosts(),
                started: {
                    asBudget: vm.selectedCostGroup.calcBudgetFor(
                        CostGroup.BUDGET_TYPES.STARTED
                    ),
                    asPercentage: vm.selectedCostGroup.calcTotalPercentageFor(
                        CostGroup.BUDGET_TYPES.STARTED
                    ),
                    totalCount: vm.selectedCostGroup.calcTotalCountFor(
                        CostGroup.BUDGET_TYPES.STARTED
                    ),
                },
                waitingForConfirmation: {
                    asBudget: vm.selectedCostGroup.calcBudgetFor(
                        CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION
                    ),
                    asPercentage: vm.selectedCostGroup.calcTotalPercentageFor(
                        CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION
                    ),
                    totalCount: vm.selectedCostGroup.calcTotalCountFor(
                        CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION
                    ),
                },
                done: {
                    asBudget: vm.selectedCostGroup.calcBudgetFor(
                        CostGroup.BUDGET_TYPES.DONE
                    ),
                    asPercentage: vm.selectedCostGroup.calcTotalPercentageFor(
                        CostGroup.BUDGET_TYPES.DONE
                    ),
                    totalCount: vm.selectedCostGroup.calcTotalCountFor(
                        CostGroup.BUDGET_TYPES.DONE
                    ),
                },
            },
            weighting: {
                isValid: vm.selectedCostGroup.sumAllStageWeights() === 100,
                total: vm.selectedCostGroup.sumAllStageWeights(),
                remaining: 100 - vm.selectedCostGroup.sumAllStageWeights(),
            },
            currency: vm.selectedCostGroup.getCurrencySymbol(),
            numberOfManagers:
                vm.selectedCostGroup.membershipManager.collectAdminMemberships()
                    .length,
            numberOfReviewers:
                vm.selectedCostGroup.membershipManager.collectReadOnlyMemberships()
                    .length,
            costCompleted: vm.selectedCostGroup.calcPercentOfTotalBudgetSpent(),
        };
    }

    function $onChanges() {
        _initChosenCurrencyFrom(vm.selectedCostGroup);
    }

    function _initChosenCurrencyFrom(costGroup) {
        vm.chosenCurrency = _.find(vm.currencies, ["name", costGroup.currency]);
    }

    function _setCurrencyOnCostGroupAs(newCurrency) {
        $sbTracking.commercial.onCurrencyChange();
        vm.selectedCostGroup.setCurrency(newCurrency);
    }

    function onCurrencyChanged(newCurrency, costGroupForm) {
        _setCurrencyOnCostGroupAs(newCurrency);

        vm.onFormInputChange({
            form: costGroupForm,
        });
    }

    function clearSearchTerm() {
        vm.costGroupSearchTerm = "";
    }
}
