import _ from "lodash";

export default function CreateNewCostGroupCtrl(
    $scope,
    $sbActivities,
    $sbTemplate,
    $sbErrorPresenter,
    $mdDialog,
    $sbTracking
) {
    "ngInject";

    var vm = this;

    var _selectedActivities = [];

    // step to support a wizard like workflow
    vm.step = 1;

    // the data model that is created in the dialog
    vm.result = {
        name: undefined,
    };

    // activities to select from
    vm.availableActivities = [];

    // UI callbacks
    vm.submit = submit;
    vm.closeDialog = $mdDialog.cancel;
    vm.canTransitionTo = canTransitionTo;
    vm.onActivitySelectionChange = onActivitySelectionChange;
    $scope.trackDialogCloseEvent = trackDialogCloseEvent;

    activate();

    function activate() {
        return _loadAllDeliverableTypes();
    }

    function submit(group) {
        group.activities = _selectedActivities;
        $mdDialog.hide(group);
    }

    function canTransitionTo(step) {
        switch (step) {
            case 2:
                return _hasSelectedActivities(vm.availableActivities);
            default:
                return (
                    _isValidCostGroupName(vm.result.name) &&
                    _hasSelectedActivities(vm.availableActivities)
                );
        }
    }

    function trackDialogCloseEvent() {
        if (vm.step === 1) {
            $sbTracking.commercial.onCostGroupCreate(
                "Canceled before Activities selected"
            );
        }

        if (vm.step === 2) {
            $sbTracking.commercial.onCostGroupCreate(
                "Canceled before Name defined"
            );
        }
    }

    function onActivitySelectionChange(selectedActivities) {
        _selectedActivities = selectedActivities;
    }

    //////
    //  internals
    ///

    function _isValidCostGroupName(name) {
        return _.isString(name) && name.length <= 128 && name.length > 0;
    }

    function _hasSelectedActivities(activities) {
        return _selectedActivities.length > 0;
    }

    function _loadAllDeliverableTypes() {
        return $sbTemplate
            .getTemplates()
            .then(function _filterEmptyTemplates(templates) {
                vm.deliverableTypes = templates.filter(function (template) {
                    return template.ACTIVITIES_COUNT > 0;
                });
            })
            .then(() => _loadAllActivities())
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                $mdDialog.cancel();
            });
    }

    function _loadAllActivities() {
        vm.deliverableTypes.forEach((processTemplate) => {
            $sbActivities
                .getAllActivitiesByDeliverableTemplateID({
                    templateId: processTemplate.ID,
                })
                .then((activities) => {
                    _addActivities(processTemplate.NAME, activities);
                })
                .catch(function (error) {
                    $sbErrorPresenter.catch(error);
                    $mdDialog.cancel();
                });
        });
    }

    function _addActivities(processTemplateName, activities) {
        activities.forEach(
            (activity) => (activity.processTemplateName = processTemplateName)
        );
        vm.availableActivities = vm.availableActivities.concat(activities);
    }
}
