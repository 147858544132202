import angular from "angular";
import htmlTemplate from "./sbTemplateGroupEdit.html";

export default angular
    .module("sbApp.sbTemplateGroupEdit", [])
    .directive("sbTemplateGroupEdit", function (TEMPLATE_CONFIG) {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            controllerAs: "groupForm",
            scope: false,
            controller: function (
                $state,
                $scope,
                $filter,
                templateEditorService,
                $sbTeamDialogs
            ) {
                var vm = this;

                /////////////////////
                //
                //      SCOPE properties
                //
                /////////////////////

                //Only push element into DOM if data is available completely
                vm.dataIsAvailable = false;

                //Init working hours
                vm.totalWorkingHours = 0;

                //Template configuration items
                vm.CONFIG = TEMPLATE_CONFIG;

                //Retaining current state to ensure condition for displaying duration.
                //  DON'T BLAME MB for that!
                vm.currentState = $state.current.name;

                //Translate filter
                var translateFilter = $filter("translate");

                vm.getPluralizedTimeUnit = getPluralizedTimeUnit;
                vm.onTeamSelected = onTeamSelected;

                /////////////////////
                //
                //      IMPL
                //
                /////////////////////

                function onTeamSelected(team) {
                    var previousAssignedTeams =
                        $scope.template.ASSIGNED_TEAM_IDS;

                    var hasMixedTeamAssignments =
                        angular.isArray(previousAssignedTeams) &&
                        previousAssignedTeams.length > 1;
                    if (hasMixedTeamAssignments) {
                        $sbTeamDialogs
                            .openConfirmTeamOverwriteDialog()
                            .then(function (isConfirmed) {
                                if (isConfirmed) {
                                    assignTeamToTemplate(team, $scope.template);
                                    $scope.edit(
                                        $scope.template,
                                        $scope.sbTemplateGroupEdit
                                    );
                                }
                            });
                    } else {
                        assignTeamToTemplate(team, $scope.template);
                        $scope.edit(
                            $scope.template,
                            $scope.sbTemplateGroupEdit
                        );
                    }
                }

                function assignTeamToTemplate(team, template) {
                    var hasUnrestrictedAccess = team === null;
                    if (hasUnrestrictedAccess) {
                        template.ASSIGNED_TEAM_IDS = null;
                    } else {
                        // assign specific team
                        //
                        template.ASSIGNED_TEAM_IDS = [team.id];
                    }

                    return template;
                }

                function getPluralizedTimeUnit(numberOf, unitsOfTime) {
                    var TimeUnit = {
                        DAYS: "DAYS",
                        HOURS: "HOURS",
                    };

                    switch (unitsOfTime) {
                        case TimeUnit.HOURS:
                            return pluralizeTimeUnit(
                                numberOf,
                                "_HOUR",
                                "_HOURS"
                            );
                        case TimeUnit.DAYS:
                            return pluralizeTimeUnit(numberOf, "_DAY", "_DAYS");
                    }
                }

                function translate(numOfUnits, i18nKey) {
                    return numOfUnits + " " + translateFilter(i18nKey);
                }

                function pluralizeTimeUnit(numOfUnits, singularKey, pluralKey) {
                    if (numOfUnits === 1) {
                        return translate(numOfUnits, singularKey);
                    }
                    return translate(numOfUnits, pluralKey);
                }
            },
        };
    });
