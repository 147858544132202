import angular from "angular";
import _ from "lodash";
import moment from "moment";

export default function WeekCalendarService($sbTracking) {
    "ngInject";
    var service = {
        currentMonthText: undefined,
        diaryEntries: [],
        weekdays: [],
        currentWeek: undefined,
        init: init,
        goToPreviousWeek: goToPreviousWeek,
        goToNextWeek: goToNextWeek,
        goToToday: goToToday,
        goToDate: goToDate,
        getEventForDay: getEventForDay,
        isNonWorkingDay: isNonWorkingDay,
        selectDate: selectDate,
        selectedDateIsInTheFuture: selectedDateIsInTheFuture,
        selectedDateIsToday: selectedDateIsToday,
        updateDiaryEntriesWith: updateDiaryEntriesWith,
        // for unit tests
        _setCurrentCalendar: _setCurrentCalendar,
        _extractNonWorkingWeekdays: _extractNonWorkingWeekdays,
        _nonWorkingDays: undefined,
        _exceptionDates: undefined,
    };

    function init(calendar, date, diaryEntries) {
        date = moment(date);

        if (!date.isValid()) {
            date = moment().subtract(1, "day");
        }

        goToDate(date);

        service.diaryEntries = diaryEntries;
        service._nonWorkingDays = service._extractNonWorkingWeekdays(calendar);
        service._exceptionDates = calendar.exceptionDates;
    }

    function updateDiaryEntriesWith(entry) {
        var entryIndex = service.diaryEntries.indexOf(entry.id);

        if (entryIndex > -1) {
            service.diaryEntries[entryIndex] = entry;
        } else {
            service.diaryEntries.push(entry);
        }

        return entry;
    }

    function _extractNonWorkingWeekdays(calendar) {
        var nonWorkingDays = [];
        (calendar.DAYS || []).forEach(function (day, index) {
            // 0 is 7 in the calendar representation
            if (!day) nonWorkingDays.push(index || 7);
        });
        return nonWorkingDays;
    }

    function goToPreviousWeek() {
        $sbTracking.siteDiary.calendar.showPreviousDates();
        service.currentWeek--;
        service._setCurrentCalendar();
    }

    function goToNextWeek() {
        $sbTracking.siteDiary.calendar.showNextDates();
        service.currentWeek++;
        service._setCurrentCalendar();
    }

    function goToToday() {
        var today = moment().startOf("day");
        goToDate(today);
        selectDate(today);
    }

    function goToDate(date) {
        service.date = date;
        service.currentWeek = service.date.week();
        service._setCurrentCalendar();
    }

    function getEventForDay(date) {
        return _.find(service.diaryEntries, function (event) {
            return event.date.isSame(date, "day");
        });
    }

    function isNonWorkingDay(date) {
        var isNormalNonWorkingDay = !!_.find(
            service._nonWorkingDays,
            function (day) {
                // Note days of the week are from 0 - 6 in moment, but 1 - 7 in our backend
                //
                if (day === 7) {
                    day = 0;
                }

                return Number(day) === date.day();
            }
        );

        var isSpecialNonWorkingDay = !!_.find(
            service._exceptionDates,
            function (exceptionDate) {
                return date.isSame(exceptionDate, "day");
            }
        );

        return isNormalNonWorkingDay || isSpecialNonWorkingDay;
    }

    function selectDate(date) {
        service.date = date;
        $sbTracking.siteDiary.calendar.selectDate();
    }

    function selectedDateIsInTheFuture() {
        return moment(service.date).isAfter(moment(), "day");
    }

    function selectedDateIsToday() {
        return moment(service.date).isSame(moment(), "day");
    }

    function _setCurrentCalendar() {
        service.weekdays = _getWeekdays(service.currentWeek);
        service.currentMonthText = _getCurrentMonthText(service.weekdays);
    }

    // Gets every day of the week as a moment object
    //
    function _getWeekdays(weekOfYear) {
        var weekdays = [];

        for (var i = 1; i < 8; i++) {
            weekdays.push(moment(service.date).day(i).week(weekOfYear));
        }

        return weekdays;
    }

    function _getCurrentMonthText(weekdays) {
        var firstDay = _.head(weekdays);
        var lastDay = _.last(weekdays);

        if (firstDay.month() === lastDay.month()) {
            return firstDay.format("MMMM YYYY");
        } else {
            return (
                firstDay.format("MMM YYYY") + " - " + lastDay.format("MMM YYYY")
            );
        }
    }

    return service;
}
