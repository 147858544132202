/**
 *
 *
 * TODO: needs support for "any" and "custom" value
 *
 */
import _ from "lodash";
import ODataFilterFactory from "./odata_filter_factory.class";
import ViewFilter from "./odata_view_filter.class";

/**
 * View Filter for date values.
 * @param keyLabel
 * @param key
 * @param oDataProperty
 *
 * @returns {DateViewFilter}
 *
 * @constructor
 * @extends ViewFilter
 */
function DateViewFilter(keyLabel, key, oDataProperty) {
    ViewFilter.call(this, keyLabel, key);

    this.type = ViewFilter.TYPE.DATE;

    this.oDataProperty = oDataProperty;

    this.formatter = (date) => date.getTime() + "L";

    this.valueTo;
    this.valueFrom;

    this.translatable.noneValueTo = "_TO_DATE_NOT_SET";
    this.translatable.noneValueFrom = "_FROM_DATE_NOT_SET";

    return this;
}
/**
 * Create a date view filter.
 *
 * @param {String} keyLabel - the human readable name of the filter key
 * @param {String} key      - the internal key that is identifying the view filter
 * @param {String} oDataProperty    - the ODATA property that is represented by the filter.
 * @param {Array<Object>} options   - we might create a options class soon to specify the options in detail.
 * @param {Date} initFrom   - initial date from in the date picker if custom
 * @param {Date} initTo     - initial date to in the date picker if custom
 * @param {Function} formatter     - formatter to translate the js Date into odata value
 * @returns {DateViewFilter}
 */
DateViewFilter.createDateFilter = function createDateFilter(
    keyLabel,
    key,
    oDataProperty,
    options,
    initFrom,
    initTo,
    formatter = (date) => date.getTime() + "L"
) {
    return new DateViewFilter(keyLabel, key, oDataProperty)
        .setOptions(options)
        .setValueFrom(initFrom)
        .setValueTo(initTo)
        .setFormatter(formatter)
        .clear();
};

DateViewFilter.prototype = Object.create(ViewFilter.prototype);

DateViewFilter.prototype.isCustomOptionSupported = function () {
    return true;
};

DateViewFilter.prototype.setValueTo = function (newValueTo) {
    this.valueTo = newValueTo;
    this.value = [
        _.isDate(this.valueFrom) ? this.valueFrom.getTime() : undefined,
        _.isDate(this.valueTo) ? this.valueTo.getTime() : undefined,
    ];
    this.refreshState();

    return this;
};

DateViewFilter.prototype.setValueFrom = function (newValueFrom) {
    this.valueFrom = newValueFrom;
    this.value = [
        _.isDate(this.valueFrom) ? this.valueFrom.getTime() : undefined,
        _.isDate(this.valueTo) ? this.valueTo.getTime() : undefined,
    ];
    this.refreshState();

    return this;
};

DateViewFilter.prototype.isFilterInactive = function () {
    if (_.isArray(this.value)) {
        return this.value.some(function (v) {
            // every entry in the list has to be true like.
            return !v;
        });
    } else {
        return true;
    }
};

DateViewFilter.prototype.setFormatter = function (formatter) {
    this.formatter = formatter;
    return this;
};

DateViewFilter.prototype.refreshState = function () {
    // call super method of the inherited class
    //
    ViewFilter.prototype.refreshState.call(this);

    if (this.isFilterInactive()) {
        return;
    }

    // apply the special date state
    //
    var from = parseFloat(this.value[0]);
    var to = parseFloat(this.value[1]);

    if (from && to) {
        this.valueFrom = new Date(from);
        this.valueTo = new Date(to);
        this.setValueTranslatable([this.valueFrom, this.valueTo]);
    } else if (from) {
        this.valueFrom = new Date(from);
        this.valueTo = undefined;
        this.setValueTranslatable([
            this.valueFrom,
            this.translatable.noneValueTo,
        ]);
    } else if (to) {
        this.valueFrom = undefined;
        this.valueTo = new Date(to);
        this.setValueTranslatable([
            this.translatable.noneValueFrom,
            this.valueTo,
        ]);
    }
};

/**
 * @override
 * @param odataFactory
 * @returns {*}
 */
DateViewFilter.prototype.applyOdata = function (odataFactory) {
    if (this.isNullCondition()) {
        return odataFactory.eq(this.oDataProperty, null);
    } else {
        var options = this.collectActiveOptions();

        if (options && options.length > 0) {
            var innerFactory = new ODataFilterFactory();
            options.forEach(function (option, index) {
                if (index > 0) {
                    innerFactory.or();
                }
                option.odata(innerFactory);
            });

            return odataFactory.block(innerFactory);
        } else {
            // if an odata handler is applied, use it -> for e.g. range filter
            //
            var hasODataHandler = this.odata;
            if (hasODataHandler) {
                return this.odata(
                    odataFactory,
                    this.valueFrom.getTime(),
                    this.valueTo.getTime()
                );
            }

            //  this piece is replacing ViewFilter functionality
            //
            //  >>>>
            if (_.isDate(this.valueFrom) && _.isDate(this.valueTo)) {
                return odataFactory.between(
                    this.oDataProperty,
                    this.formatter(this.valueFrom),
                    this.formatter(this.valueTo)
                );
            } else if (_.isDate(this.valueFrom)) {
                return odataFactory.gt(
                    this.oDataProperty,
                    this.formatter(this.valueFrom)
                );
            } else if (_.isDate(this.valueTo)) {
                return odataFactory.lt(
                    this.oDataProperty,
                    this.formatter(this.valueTo)
                );
            } else {
                /* eslint-disable angular/log */
                console.warn(
                    "nothing on the date view filter",
                    this.valueFrom,
                    this.valueTo
                );
                /* eslint-enable angular/log */
                return odataFactory;
            }
            //
            //  <<<<
        }
    }
};

export default DateViewFilter;
