import stateTemplateHtml from "./noteTemplates.html";

export default function ($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.noteTemplates", {
        templateUrl: stateTemplateHtml,
        controller: "NoteTemplatesCtrl",
        controllerAs: "noteTemplates",
        url: "/templates/note?TYPE&CREATOR_DB_NAME",
        gaPageTrack: "/templates/note", // google analytics extension
        data: {
            title: "SECTION_NOTE_TEMPLATES_TITLE",
            category: "PLAN",
            searchEnabled: true,
            searchPlaceholder:
                "INFO_PLACEHOLDER_SEARCH_BAR_NOTE_TEMPLATES_MESSAGE",
        },
    });
}
