import angular from "angular";
import html from "./print_report.html";
import controller from "./print_report.controller";
import apiService from "./print_report_api.service";

export default angular
    .module("sbApp.sbPrintReportDialog", [
        "sbApp.common.components.choiceGroup",
    ])
    .config(registerDialog)
    .factory("sbPrintReportDialog", registerService())
    .factory("$sbPrintReportApi", apiService);

/////////////////////
//
//      IMPL
//
/////////////////////

function registerService() {
    return function sbPrintReportDialogService($mdDialog) {
        "ngInject";
        return {
            show: _show,
        };

        function _show(options) {
            return $mdDialog.show(
                $mdDialog
                    .printReport()
                    .oDataFilter(options.oDataFilter)
                    .filterDescription(options.description)
                    .typesDisplayed(options.types)
                    .templateId(options.templateId)
                    .deliverableSelection(options.deliverableSelection)
            );
        }
    };
}

function registerDialog($mdDialogProvider) {
    $mdDialogProvider.addPreset("printReport", {
        methods: [
            "oDataFilter",
            "filterDescription",
            "typesDisplayed",
            "templateId",
            "deliverableSelection",
        ],
        options: function printDialog() {
            return {
                templateUrl: html,
                controllerAs: "dialog",
                bindToController: true,
                controller: controller,
                clickOutsideToClose: true,
                escapeToClose: true,
            };
        },
    });
}
