import _ from "lodash";
import angular from "angular";

export default function BulkStateChangeCtrl(
    $sbMembership,
    $mdDialog,
    $mdToast,
    $filter,
    $sbErrorPresenter,
    $sbActivities,
    $translate,
    $sbBulkProcessOperationsService,
    PROGRESS_VALUES_CONFIG,
    $sce
) {
    "ngInject";
    var vm = this;
    var translate = $filter("translate");

    var _selectedActivities = [];

    vm.activities = [];

    vm.loading = true;
    vm.progressOptions = PROGRESS_VALUES_CONFIG;

    vm.submit = submit;
    vm.closeDialog = $mdDialog.cancel;
    vm.isActivitiesSelected = isActivitiesSelected;
    vm.isOptionSelected = isOptionSelected;
    vm.onStateChange = onStateChange;
    vm.onActivitySelectionChange = onActivitySelectionChange;

    //call $onInit manually because it is not automatically called in a dialog controller
    $onInit();

    function $onInit() {
        return $sbActivities
            .getAllActivitiesByDeliverableTemplateID({
                templateId: vm.templateId,
                activityFilter: _createActivityFilter(
                    $sbMembership.currentTeam()
                ),
            })
            .then(function (activities) {
                vm.activities = activities;
                vm.loading = false;
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                $mdDialog.cancel();
            });
    }

    function submit() {
        vm.loading = true;

        $translate(vm.selectedState.LABEL)
            .then(function (translatedLabel) {
                const state = $sce.valueOf(translatedLabel);

                return $sbBulkProcessOperationsService
                    .bulkStateChange(
                        _selectedActivities,
                        vm.deliverableSelection,
                        vm.selectedState.STATE_VALUE
                    )
                    .then(function (result) {
                        // show success toast
                        $mdToast.show(
                            $mdToast
                                .simple()
                                .content("INFO_BULK_PROGRESS_CHANGE_SUCCESS")
                                .contentValues({
                                    items: result.length,
                                    state,
                                })
                                .position("top right")
                        );
                    });
            })
            .catch(function (error) {
                return $sbErrorPresenter.catch(error);
            })
            .finally(function () {
                vm.loading = false;
                $mdDialog.hide();
            });
    }

    function onStateChange(option) {
        vm.selectedState = JSON.parse(option);
    }

    function isOptionSelected() {
        return !_.isUndefined(vm.selectedState);
    }

    function isActivitiesSelected() {
        return _selectedActivities.length > 0;
    }

    function onActivitySelectionChange(selectedActivities) {
        _selectedActivities = selectedActivities;
    }

    function _createActivityFilter(currentUserTeam) {
        return function (activity) {
            return activity.isAccessibleByTeam(currentUserTeam);
        };
    }
}
