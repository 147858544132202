import angular from "angular";
import htmlTemplate from "./sb_data_state.html";

export default function sbDataState() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        scope: {
            isLoading: "=",
            isEmpty: "=",
            isFiltered: "=",
            emptyCallback: "=",
        },
        link: function (scope) {},
        controllerAs: "dataState",
        controller: function sbDataStateCtrl($scope) {
            "ngInject";

            var vm = this;

            $scope.$watch("isLoading", function (isLoading) {
                vm.isLoading = isLoading;
            });
            $scope.$watch("isEmpty", function (isEmpty) {
                vm.isEmpty = isEmpty;
            });
            $scope.$watch("isFiltered", function (isFiltered) {
                vm.isFiltered = isFiltered;
            });

            /**
             * no data: not loading + empty + not filtered
             */
            vm.showNoData = function () {
                return !vm.isLoading && vm.isEmpty && !vm.isFiltered;
            };

            /**
             * loading: loading is true (other flags are ignored)
             */
            vm.showLoading = function () {
                return vm.isLoading;
            };

            /**
             * empty filtered: not loading + empty + filtered
             */
            vm.showFiltered = function () {
                return !vm.isLoading && vm.isEmpty && vm.isFiltered;
            };
        },
    };
}
