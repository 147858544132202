import angular from "angular";
import htmlTemplate from "./sb_filter_checkbox.html";
export default angular
    .module("sbApp.sbFilterCheckbox", [])
    .directive("sbFilterCheckbox", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "filterCheckbox",
            templateUrl: htmlTemplate,
            scope: {
                /**
                 * @typeof ViewFilter
                 */
                viewFilter: "=",

                // inform parent
                onChange: "=",
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "filterCheckbox.viewFilter.value",
                    function (newValue, oldValue) {
                        // support clear viewFilter from outside -> newValue is undefined
                        if (newValue === undefined && newValue !== oldValue) {
                            controller.activate();
                        }
                    }
                );
            },
            controller: function FilterCheckboxCtrl() {
                var vm = this;
                vm.deselectCheckboxes = deselectCheckboxes;
                vm.onCheckboxChange = onCheckboxChange;
                vm.activate = activate;

                activate();

                ///////////
                //
                //  IMPL
                //
                ///////////

                /**
                 * Check if we have to change allSelected status
                 */
                function onCheckboxChange(newOptions) {
                    vm.allSelected = _isAllSelected(newOptions);
                    vm.viewFilter.setOptions(newOptions);

                    if (vm.allSelected) {
                        vm.viewFilter.clear();
                    }
                    vm.onChange(vm.viewFilter.key, vm.viewFilter);
                }

                /**
                 * Deselect all checkboxes only if all is deselected
                 */
                function deselectCheckboxes() {
                    if (vm.allSelected) {
                        vm.viewFilter.clear();
                        vm.onChange(vm.viewFilter.key, vm.viewFilter);
                    }
                }

                /**
                 * Checks the status of all options and sets the value for AllSelected
                 */
                function _isAllSelected(checkboxes) {
                    var allTrue = checkboxes.every(function (item) {
                        return item.isActive === true;
                    });
                    var allFalse = checkboxes.every(function (item) {
                        return item.isActive === false;
                    });
                    return allTrue || allFalse;
                }

                function activate() {
                    vm.allSelected = _isAllSelected(vm.viewFilter.options);
                }
            },
        };
    });
