import angular from "angular";

var EXPANDED_ELEMENT_HEIGHT = 252;
var CONTAINER_MARGINS = 50;
var CLOSED_ELEMENT_HEIGHT = 50;

/**
 * Track multiple resizing events.
 */
var resizeTimeout;
/**
 * Set's DOM element's height property
 *
 * @param {HTMLElement} elementID - the DOM element
 * @param {String} height - the height to set
 */
function setElementHeight(elementID, height) {
    angular.element(elementID).css("height", height + "px");
}

/**
 * Checks if an element is inside the viewport, given the element's offset and our current
 * scroll state.
 *
 * @param {Integer} elementOffset - current element position
 * @param {Integer} myPosition - current scroll position
 * @param {Integer} viewportHeight - height of the parent container
 * @returns {Boolean}
 */
function isElementInViewport(elementOffset, myPosition, viewportHeight) {
    var isElementBelow = elementOffset + EXPANDED_ELEMENT_HEIGHT >= myPosition;
    var isElementAbove = elementOffset <= viewportHeight + myPosition;
    return isElementAbove && isElementBelow; //&& isElementNotInTheBottom);
}

/**
 * Closes all opened elements within the given container
 *
 * @param {String} container - parent container
 */
function closeAllElements(container) {
    angular.element(container).find("div").removeClass("opened");
}

/**
 * Adds "opened" class to Dom element
 *
 * @param {HTMLElement} elementID - the DOM element
 */
function openElement(elementID) {
    angular.element(elementID).addClass("opened");
}

export default function noteTemplateListManipulation(
    $window,
    $document,
    $timeout
) {
    "ngInject";

    return {
        /**
         * String of subset of EACM which restricts the directive to a specific directive declaration style.
         * If omitted, the defaults (elements and attributes) are used.
         *
         * @example <caption>Element Name</caption>
         * E - Element name (default): <my-directive></my-directive>
         * A - Attribute (default): <div my-directive="exp"></div>
         * C - Class: <div class="my-directive: exp;"></div>
         * M - Comment: <!-- directive: my-directive exp -->
         */
        restrict: "A",

        // template: htmlTemplate, //HTML Template as String

        //transclude: true,

        scope: {
            expandedRowId: "@",
            expandedRowIndex: "@",
            numItems: "@",
        },

        //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

        //compile : function(tElement, tAttrs) {},

        link: function (scope, element, attr) {
            // The ID's come from the noteTemplates.html. Keep in mind for future reusage
            var myViewport = $document[0].getElementById("notes-list");
            var myVirtualRepeater = $document[0].getElementById(
                "sb-virtual-repeater"
            );

            // These containers depend on virtual repeater structure
            var myScroller = angular.element(myVirtualRepeater).find("div")[0];

            // Variables to keep current state
            var myCurrentRow = null;
            var myCurrentScroll = 0;
            var myCurrentElementOffset = 0;

            // Init height of the container as soon as the link fucntion is on, so we can load the
            // virtual repeater properly
            var viewportHeight = myViewport.clientHeight - CONTAINER_MARGINS;
            setElementHeight(myVirtualRepeater, viewportHeight);

            /**
             * If there's an open item, checks if it's in the viewport and opens it.
             * If it's not in the viewport, closes all elements if the element is not
             * on the extreme of the list. This is why we are handling the extremes on click
             * and we don't want to close the element on scroll.
             *
             * @param {$event} e - scrolling event
             */
            function scrollListener(e) {
                myCurrentScroll = e.target.scrollTop;
                // if there's an opened item
                if (scope.expandedRowIndex) {
                    if (
                        isElementInViewport(
                            myCurrentElementOffset,
                            myCurrentScroll,
                            viewportHeight
                        )
                    ) {
                        // element in viewport
                        myCurrentRow = $document[0].getElementById(
                            "row-" + scope.expandedRowId
                        );
                        openElement(myCurrentRow);
                    } else {
                        // element is outside. Remove all opened states
                        closeAllElements(myVirtualRepeater);
                    }
                }
            }

            /**
             * Watches the value of the expanded row index, and changes the currentElement related
             * variables and position. If the element is in the last positions and overflows, it
             * translates the scroll so it can be correctly visualized.
             *
             * @param {Integer} value - The new index
             */
            function expandedElementChangedWatcher(value) {
                if (value) {
                    myCurrentElementOffset = value * CLOSED_ELEMENT_HEIGHT;
                    myCurrentRow = $document[0].getElementById(
                        "row-" + scope.expandedRowId
                    );
                    openElement(myCurrentRow);
                }
            }
            /**
             * To listen the resizing event. It only triggers if the height of the container is changed
             *
             */
            function resizerListener() {
                var newViewportHeight =
                    myViewport.clientHeight - CONTAINER_MARGINS;
                // only refresh if height has changed
                if (newViewportHeight !== viewportHeight) {
                    viewportHeight =
                        myViewport.clientHeight - CONTAINER_MARGINS;
                    setElementHeight(myVirtualRepeater, viewportHeight);
                    //Remove the timeout if window is still being resized
                    if (resizeTimeout) $timeout.cancel(resizeTimeout);
                    //Timeout method to ensure callback only once user stops resizing
                    resizeTimeout = $timeout(function () {
                        scope.$root.$broadcast("sb.global.window.resized");
                    }, 500);
                }
            }

            /**
             * Bind watchers and listeners
             *
             */
            angular.element(myScroller).on("scroll", scrollListener);
            attr.$observe("expandedRowIndex", expandedElementChangedWatcher);
            if (screen.width > 600) {
                angular.element($window).on("resize", resizerListener);
            }
        },

        //controller : function($scope, $element) {},
    };
}
