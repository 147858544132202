/**
 *
 * behavior:
 *  - on init:
 *      - URL or local storage will be used to determine the current filter state
 *      - set Overlay to initial value
 *      - set Chips to initial value
 *      - set URL to initial value (if from local store)
 *      - set local store to initial value (if from URL)
 *
 *  - overlay change:
 *      - update URL
 *      - update Chip
 *      - store in local store
 *
 *  - URL change:
 *      - no listening at all
 *      - the initial URL state is parsed into the active filter model
 *
 *  - Chip delete:
 *      - not enabled yet
 *      - Update URL
 *      - store in local store
 *      - Update Overlay
 *
 *  - supported filter
 *    - name
 *    - code
 *    - desc
 *    - progress
 *    - schedule
 *    - notes
 *    - start date
 *    - end data
 *    - workflow name
 *    - structure
 *    - stage + stage progress
 */
import angular from "angular";
import _ from "lodash";
import ViewFilter from "../../../services/oDataService/odata_view_filter.class";
import filterPanelTemplate from "./sbFilterPanel/filter_panel.html";

export default function FiltersCtrl(
    $log,
    $scope,
    EVENTS,
    $mdPanel,
    $translate,
    $sbViewFiltersService,
    $sbODataViewFilter
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.chips = [];
    vm.isDate = angular.isDate;
    vm.changeFilters = changeFilters;
    vm.setChipsFrom = setChipsFrom;
    vm.onRemoveChip = onRemoveChip;
    vm.clearAllViewFilters = clearAllViewFilters;
    vm.clearAndApplyAllViewFilters = clearAndApplyAllViewFilters;
    vm.openPanel = openPanel;
    vm.onChange = vm.onChange || angular.noop;
    vm._allowFilterClear = vm.allowFilterClear !== false; // disable filter only if explicitly set to "false"
    vm._allowAllFilterClear = vm.allowAllFilterClear !== false; // disable filter only if explicitly set to "false"

    vm.oDataQueryTheDialogWasOpenedWith = "";
    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $scope.$watch("filters.viewFilters", function () {
        vm.setChipsFrom(vm.viewFilters);
    });

    $scope.$on(EVENTS.ODATA_LIST__FILTERS_CLEAR, function () {
        vm.clearAndApplyAllViewFilters();
    });

    $scope.$on(EVENTS.GLOBAL_SEARCH_CHANGE, function (event, eventData) {
        vm.recentSearchTerm = eventData.searchTerm;

        var searchFilter = _.find(vm.viewFilters, ["key", "search"]);
        if (searchFilter) {
            if (eventData.searchTerm) {
                searchFilter.setValue(eventData.searchTerm);
            } else {
                searchFilter.clear();
            }
            changeFilters();
        }
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    // generate chips and keep synced

    activate();

    /**
     * Activate the controller -> set the initial filter state
     *
     */
    function activate() {
        setChipsFrom(vm.viewFilters || []);
    }

    function openPanel($event) {
        vm.oDataQueryTheDialogWasOpenedWith = getAsODataString(vm.viewFilters);
        return $mdPanel
            .open({
                attachTo: angular.element(document.body),
                controller: "FilterPanelCtrl",
                controllerAs: "$ctrl",
                templateUrl: filterPanelTemplate,
                focusOnOpen: false,
                zIndex: 4,
                propagateContainerEvents: false,
                clickOutsideToClose: true,
                escapeToClose: true,
                hasBackdrop: true,
                onDomRemoved: vm.changeFilters,
                animation: $mdPanel
                    .newPanelAnimation()
                    .openFrom($event.target)
                    .closeTo($event.target)
                    .duration(130)
                    .withAnimation($mdPanel.animation.FADE),
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo($event.target)
                    .addPanelPosition(
                        $mdPanel.xPosition.ALIGN_END,
                        $mdPanel.yPosition.BELOW
                    ),
                locals: {
                    filters: _getViewFilters(vm.viewFilters),
                    allowFilterClear: vm._allowFilterClear,
                    changeFilters: vm.changeFilters,
                    onClearAll: vm.clearAllViewFilters,
                },
            })
            .then(function (ref) {
                vm.filterPanelRef = ref;
            });
    }

    function setChipsFrom(viewFilters) {
        vm.chips = createChipsFromViewFilters(_getViewFilters(viewFilters));
    }

    function checkSearchTermInSync(viewFilters, value) {
        var searchFilter = _.find(viewFilters, ["key", "search"]);
        if (searchFilter && !searchFilter.is(value)) {
            $scope.$emit(EVENTS.GLOBAL_SEARCH_UNSET);
        }
    }

    function onRemoveChip(viewFilter) {
        if (viewFilter) {
            viewFilter.clear();
            vm.onChange();
            vm.isOpen = false;
            if (viewFilter.key === "search") {
                $scope.$emit(EVENTS.GLOBAL_SEARCH_UNSET);
            }
        } else {
            $log.warn("can not remove a chip that is no view filter!");
        }
    }

    function changeFilters() {
        if (vm.validateFilters && !vm.validateFilters(vm.viewFilters)) {
            return;
        }

        const currentFiltersToODataQuery = getAsODataString(vm.viewFilters);
        if (
            currentFiltersToODataQuery === vm.oDataQueryTheDialogWasOpenedWith
        ) {
            return;
        }

        setChipsFrom(vm.viewFilters);
        checkSearchTermInSync(vm.viewFilters, vm.recentSearchTerm);
        vm.onChange();
    }

    function clearAllViewFilters() {
        vm.viewFilters.forEach(function (viewFilter) {
            if (viewFilter.isDisplayable) {
                viewFilter.clear();
            }
        });
    }

    function clearAndApplyAllViewFilters() {
        clearAllViewFilters();
        setChipsFrom(vm.viewFilters);
        vm.isOpen = false;
        vm.onChange();
    }

    function createChipsFromViewFilters(filters) {
        // display all displayable chips which have values set
        //
        if (filters) {
            return $sbViewFiltersService.getChipsFromViewFilter(filters);
        }
    }

    function getAsODataString(viewFilters) {
        if (!viewFilters) {
            return "";
        }
        return $sbODataViewFilter.combinedOData(viewFilters).get();
    }

    function _getViewFilters(viewFilters) {
        return vm.disableProcessTemplates
            ? viewFilters.filter(
                  (filter) => filter.type !== ViewFilter.TYPE.WORKFLOW
              )
            : viewFilters;
    }
}
