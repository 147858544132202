import angular from "angular";
import htmlTemplate from "./sbGroupActivityRows.html";

export default angular
    .module("sbApp.sbGroupActivityRows", ["sbApp.services"])
    .directive("sbGroupActivityRows", function GroupActivityRowsDirective() {
        return {
            /**
             * String of subsPerchet of EACM which restricts the directive to a specific directive declaration style.
             * If omitted, the defaults (elements and attributes) are used.
             *
             * @example <caption>Element Name</caption>
             * E - Element name (default): <my-directive></my-directive>
             * A - Attribute (default): <div my-directive="exp"></div>
             * C - Class: <div class="my-directive: exp;"></div>
             * M - Comment: <!-- directive: my-directive exp -->
             */
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            scope: {
                detail: "=",
                enterNode: "=",
                deleteTree: "=",
                suggestions: "=",
                onSelectAutocomplete: "=",
                type: "=",
                onStateChange: "=",
                isEditable: "=",
            },

            controller: function GroupActivityRowsCtrl($scope, $timeout) {
                "ngInject";

                $scope.autoCompleteSelect = function (selectedElement) {
                    /*
                     * be aware!!! -> DANGER ahead!
                     *
                     * author: MB on 30.09.2015
                     *
                     * this a super dangerous and ugly work around to clear the auto-complete
                     * with $scope.$$childHead.$$nextSibling.$$childHead.$mdAutocompleteCtrl.clear();
                     * by using this approach the select event is triggered over and over again,
                     * so I need a check if there is really a selected element
                     *
                     * TODO - try to replace this clearing approach with a proper md-autocomplete function (if available)
                     */
                    if (selectedElement) {
                        $scope.onSelectAutocomplete(selectedElement);

                        // try to clear the auto complete
                        $timeout(function () {
                            $scope.selectedItem = undefined;
                            $scope.searchName = "";
                            $scope.$$childHead.$$nextSibling.$$childHead.$mdAutocompleteCtrl.clear(); // eslint-disable-line angular/no-private-call
                        }, 0);
                    }
                };

                $scope.onStateChanged = function (element) {
                    element.stateedit = false;
                    $scope.onStateChange(element);
                };

                /**
                 * This is BUGFIX-529 which prevents the user from navigation by entering
                 * a space in his activity-state naming.
                 *
                 * Also contains BUGFIX-824 which prevents the user to navigate by hitting
                 * enter. On enter press -> state change is triggered.
                 *
                 * The key-down event of space key is prevented and the whitespace is added
                 * programmatically.
                 *
                 * @param $event
                 * @param {Object} component
                 * @param {String} component.statename - The name which gets the whitespace appended.
                 */
                $scope.onKeyPressed = function ($event, component) {
                    var isSpaceKeyPressed = $event.which === 32;
                    var isEnterKeyPressed = $event.which === 13;

                    if (isSpaceKeyPressed || isEnterKeyPressed) {
                        $event.preventDefault();
                        $event.stopPropagation();
                    }

                    if (isSpaceKeyPressed) {
                        $scope.appendWhiteSpaceToStateName(component);
                    }

                    if (isEnterKeyPressed) {
                        $scope.onStateChanged(component);
                    }
                };

                $scope.appendWhiteSpaceToStateName = function (component) {
                    component.statename = component.statename + " ";
                };

                /**
                 * Trigger the search by calling the given search function
                 * AND
                 * Add a dummy blank element on top.
                 *
                 * @param {string} searchTerm - what you are looking for
                 * @returns {Array} - list of suggestions
                 */
                $scope.getSuggestion = function (searchTerm) {
                    return $scope
                        .suggestions(searchTerm)
                        .then(function (results) {
                            if (searchTerm) {
                                var result = [
                                    {
                                        NAME: searchTerm,
                                        newItem: true,
                                    },
                                ];
                                return result.concat(results);
                            } else {
                                return results;
                            }
                        });
                };
            },
        };
    });
