"use strict";

export default function ResetPasswordCtrl(
    $scope,
    $sbAuth,
    $sbPasswordPolicy,
    $stateParams,
    $state
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////
    var vm = this;

    var ERRORS = {
        UserNotFoundException: {
            title: "SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TITLE",
            text: "SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TEXT",
        },
        CodeMismatchException: {
            title: "SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TITLE",
            text: "SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TEXT",
        },
        ExpiredCodeException: {
            title: "SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TITLE",
            text: "SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TEXT",
        },
        GENERIC_ERROR: {
            title: "SECTION_RESET_PASSWORD_ERROR_TITLE",
            text: "SECTION_RESET_PASSWORD_ERROR_TEXT",
        },
    };

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.username = $stateParams.email;
    vm.isResetPasswordConfirmationInProgress = false;
    vm.passwordChange = {
        verificationCode: $stateParams.verificationCode,
    };
    vm.passwordPolicy = "";

    vm.currentError = null;

    vm.ERRORS = ERRORS;

    vm.onFormSubmit = onFormSubmit;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $scope.$watch(
        "confirmResetPasswordForm.newPassword.$$rawModelValue",
        _passwordWatcher
    );

    $scope.$on("$destroy", function () {
        _passwordWatcher();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    _activate();

    function hasPolicy() {
        return angular.isObject(vm.passwordPolicy);
    }

    function _passwordWatcher(newPassword) {
        if (!angular.isString(newPassword) || newPassword.length === 0) {
            return;
        }

        vm.passwordPatternError = "";

        if (!hasPolicy()) {
            return;
        }

        $sbPasswordPolicy
            .getPasswordErrorTranslated(newPassword, vm.passwordPolicy)
            .then(function (translatedError) {
                vm.passwordPatternError = translatedError.join(", ");
            });
    }

    function _setPolicy(passwordPolicy) {
        vm.passwordPolicy = passwordPolicy;
    }

    function _getPasswordPolicy() {
        return $sbPasswordPolicy.getPolicy().then(_setPolicy);
    }

    function _activate() {
        return _getPasswordPolicy();
    }

    function onFormSubmit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return handleResetPassword();
    }

    function handleResetPassword() {
        vm.isResetPasswordConfirmationInProgress = true;
        $sbAuth
            .resetPassword(
                vm.username,
                vm.passwordChange.verificationCode,
                vm.passwordChange.newPassword
            )
            .then(function () {
                // navigate to the state where you really wanted to go.
                $state.go(
                    "account.login",
                    {},
                    {
                        location: "replace",
                    }
                );
            })
            .catch(function (error) {
                // show error
                if (ERRORS[error.code]) {
                    vm.currentError = ERRORS[error.code];
                } else {
                    vm.currentError = ERRORS.GENERIC_ERROR;
                }
            })
            .finally(function () {
                // stop busy
                vm.isResetPasswordConfirmationInProgress = false;
            });
    }
}
