import ConfirmDestructiveActionDialogCtrl from "./confirm_destructive_action.controller";
import dialogTemplate from "./confirm_destructive_action.html";

export default function confirmDestructiveActionDialogConfig(
    $mdDialogProvider
) {
    "ngInject";
    $mdDialogProvider.addPreset("confirmDestructiveAction", {
        methods: ["class", "locals"],
        options: function () {
            return {
                controller: ConfirmDestructiveActionDialogCtrl,
                controllerAs: "$ctrl",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
