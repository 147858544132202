import _ from "lodash";
import CostGroupStage from "./cost_group_stage.class";
import CostGroupMembershipManager from "./cost_group_membership_manager.class";

function CostGroup(id, name) {
    this.id = id;
    this.name = name;

    /**
     * All the cost group stages of this group
     *
     * @type {Array<CostGroupStage>}
     *
     */
    this.stages = [];
    this.budget = 0;

    // default as EUR
    //
    this.currency = "EUR";

    /**
     * The manager of all the cost group memberships (read or write) of this cost group
     *
     * @type {CostGroupMembershipManager}
     */
    this.membershipManager = new CostGroupMembershipManager();
}

CostGroup.prototype.mapCurrencyAbbreviationToSymbol = function (abbreviation) {
    var abbreviationToSymbolMap = {
        EUR: "€",
        GBP: "₤",
        USD: "$",
        AUD: "$",
    };

    return abbreviationToSymbolMap[abbreviation] || "";
};

CostGroup.prototype.getCurrencySymbol = function () {
    return this.mapCurrencyAbbreviationToSymbol(this.currency);
};

CostGroup.BUDGET_TYPES = {
    STARTED: "started",
    WAITING_FOR_CONFIRMATION: "waitingForConfirmation",
    DONE: "done",
    TOTAL: "total",
};

/**
 * Set budget onto cost group
 *
 * @param {number} budget
 */
CostGroup.prototype.setBudget = function (budget) {
    if (budget < 0 || !budget) {
        budget = 0;
    }

    this.budget = budget;
};

/**
 * Set currency onto cost group
 *
 * @param {string} currency
 */
CostGroup.prototype.setCurrency = function (currency) {
    if (currency) {
        this.currency = currency;
    }
};

/**
 *
 * @param {CostGroupStage} stage
 */
CostGroup.prototype.addStage = function (stage) {
    if (stage instanceof CostGroupStage) {
        this.stages.push(stage);
    } else {
        throw new Error(
            "Argument to 'addStage' method must be an instance of CostGroupStage"
        );
    }
};

CostGroup.prototype.sumAllStageWeights = function () {
    return _.chain(this.stages)
        .filter(function isWeightANumber(stage) {
            return _.isNumber(stage.weight);
        })
        .reduce(function addWeightToSum(sum, stage) {
            return _.round((sum += stage.weight || 0), 2);
        }, 0)
        .value();
};

/**
 * Set stage cost for each stage in the cost group
 *
 * @returns undefined
 */
CostGroup.prototype.setStageCosts = function () {
    var _this = this;

    _.forEach(this.stages, function (stage) {
        stage.setCostFrom(_this.budget);
    });
};

/**
 * @param {string<started|waitingForConfirmation|done>} budgetType
 *
 * @returns number
 */
CostGroup.prototype.calcBudgetFor = function (budgetType) {
    return this.stages.reduce(function (sum, stage) {
        switch (budgetType) {
            case CostGroup.BUDGET_TYPES.STARTED:
                return (sum += stage.calcCostOfBudgetStarted());
            case CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION:
                return (sum += stage.calcCostOfBudgetWaitingForConfirmation());
            case CostGroup.BUDGET_TYPES.DONE:
                return (sum += stage.calcCostOfBudgetCompleted());
            default:
                return sum;
        }
    }, 0);
};

/**
 * @param {string<started|waitingForConfirmation|done|total>} budgetType
 *
 * @returns number
 */
CostGroup.prototype.calcTotalCountFor = function (budgetType) {
    return this.stages.reduce(function (sum, stage) {
        switch (budgetType) {
            case CostGroup.BUDGET_TYPES.STARTED:
                return (sum += stage.inProgress);
            case CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION:
                return (sum += stage.isWaitingForConfirmation);
            case CostGroup.BUDGET_TYPES.DONE:
                return (sum += stage.isDone);
            case CostGroup.BUDGET_TYPES.TOTAL:
                return (sum += stage.total);
            default:
                return sum;
        }
    }, 0);
};

/**
 * @param {string<started|waitingForConfirmation|done>} budgetType
 *
 * @returns number
 */
CostGroup.prototype.calcTotalPercentageFor = function (budgetType) {
    var activitiesForBudgetType = this.calcTotalCountFor(budgetType);
    var activitiesTotal = this.calcTotalCountFor(CostGroup.BUDGET_TYPES.TOTAL);

    if (activitiesTotal > 0) {
        return (activitiesForBudgetType / activitiesTotal) * 100;
    }

    return 0;
};

/**
 *
 * @returns number
 */
CostGroup.prototype.calcPercentOfTotalBudgetSpent = function () {
    var _this = this;

    return this.stages.reduce(function (sum, stage) {
        return (sum += stage.calcPercentageOfTotalBudgetSpent(_this.budget));
    }, 0);
};

/**
 * Returns the sum of every stage cost in the group
 *
 * @returns number
 */
CostGroup.prototype.calcDistributedCosts = function () {
    return this.stages.reduce(function (sum, stage) {
        return (sum += stage.cost);
    }, 0);
};

export default CostGroup;
