import angular from "angular";
import htmlTemplate from "./elastic_input.html";
export default angular
    .module("sbApp.sbElasticInput", [])
    .directive("sbElasticInput", function ($timeout) {
        return {
            restrict: "E",
            templateUrl: htmlTemplate,
            //bindToController: true,
            //controllerAs: 'elasticInput',
            //transclude: true,
            scope: {
                inputValue: "=",
                inputMaxLength: "=?",
                inputMinLength: "=?",
                tooltipContent: "@",
                editDisabled: "=",
                onChange: "=",
            },
            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],
            //compile : function(tElement, tAttrs) {},
            //controller: function ($scope, $element) {},
            link: function ($scope, $element) {
                $scope.inputMaxLength = $scope.inputMaxLength || 32;
                $scope.inputMinLength = $scope.inputMinLength || 3;

                var ruler = $element.find("span");
                var field = $element.find("input");

                var MAX_INPUT_WIDTH = 425;
                var INPUT_SAVE_TIMEOUT = 1000;

                var timeout;

                _adjustWidth();

                $scope.onValueChange = onValueChange;

                // With a delay timeout to save on change
                function onValueChange() {
                    if (timeout) $timeout.cancel(timeout);
                    if ($scope.elasticInput.$valid) {
                        timeout = $timeout(function () {
                            $scope.onChange($scope.tooltipContent);
                        }, INPUT_SAVE_TIMEOUT);
                    }
                }

                function _adjustWidth() {
                    var inputWidth;
                    if (
                        !$scope.inputValue ||
                        $scope.inputValue.length >= $scope.inputMaxLength
                    ) {
                        inputWidth = MAX_INPUT_WIDTH;
                    } else {
                        inputWidth = ruler[0].offsetWidth;
                    }
                    field.css("width", inputWidth + "px");
                }

                // This watcher also handles the first load and all width transitions
                $scope.$watch("inputValue", function () {
                    _adjustWidth();
                });
            },
        };
    });
