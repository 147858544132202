/**
 * Created by Sebastian on 15.09.2015.
 * @module intercom/events
 *
 * All events need a unique identifier for intercom API.
 * Here they are listed and used to setup angular constants
 * in the intercom module.
 */

var entered = "entered" + " ";

/**
 * Tracked Events Name
 */
export default /** @alias module:intercom/events */ {
    /** See naming conventions on: https://sablono.atlassian.net/wiki/display/TDEV/Intercom+Integration%3A+naming+convention */

    /** Event for entering different pages */
    ENTERED_WBS_EDITOR: entered + "project structure page",
    ENTERED_DELIVERABLES_PAGE: entered + "deliverables page",
    ENTERED_WORKFLOW_TEMPLATES_PAGE: entered + "workflow templates page",
    ENTERED_WORKFLOW_TEMPLATES_EDITOR_PAGE:
        entered + "workflow templates editor page",
    ENTERED_NOTES_PAGE: entered + "notes page",
    ENTERED_PROCESS_VIEWER_PAGE: entered + "process viewer page",
    ENTERED_TRACKER_PAGE: entered + "tracker page",
    ENTERED_NOTE_TEMPLATES_IMPORT_PAGE: entered + "note templates import page",
    ENTERED_NOTE_TEMPLATES_PAGE: entered + "note templates page",
    ENTERED_SITE_INSPECTION_TIMELINE_PAGE:
        entered + "site inspection timeline page",
    ENTERED_PROJECT_PROGRESS_PAGE: entered + "project progress page",
    ENTERED_PROJECT_TEAM_PAGE: entered + "project team page",
    ENTERED_PROJECT_SETTINGS_PAGE: entered + "project settings page",
    ENTERED_PROFILE_PAGE: entered + "profile page",
    ENTERED_DASHBOARD_PRO_PAGE: entered + "dashboard pro page",
    ENTERED_LEANBOARD_PAGE: entered + "leanboard page",
    ENTERED_EXCEL_IMPORT_PAGE: entered + "excel import page",
    ENTERED_MS_PROJECT_IMPORT_PAGE: entered + "msproject import page",
    ENTERED_EXCEL_IMPORT_PREVIEW_PAGE: entered + "excel import preview page",
    ENTERED_MS_PROJECT_IMPORT_PREVIEW_PAGE:
        entered + "msproject import preview page",
    ENTERED_VISUALIZATION_PAGE: entered + "visualization page",
    ENTERED_VISUALIZATION_LIST_PAGE: entered + "visualization list page",
    ENTERED_DELIVERABLES_LIST: entered + "new deliverables list",
    ENTERED_COMMERCIAL_PAGE: entered + "commercial page",
    ENTERED_COMMERCIAL_VALUATION_PAGE: entered + "commercial valuation",

    /** Event for successfully importing a ms project file */
    IMPORTED_PROJECT: "imported_project",
    IMPORTED_DEMO_PROJECT: "demo_created",
    PROJECT_CREATED: "project_created",

    ACTIVITY_DETAILS_OVERLAY_OPENED: "data_reviewed_on_tracker",

    /** Event for showing maintenance page */
    MAINTENANCE_SHOWN: "Maintenance_shown",

    // Intercom behavioural events
    DATA_REVIEWED_ON_TRACKER: "data_reviewed_on_tracker",
};
