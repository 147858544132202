import checklistTemplatesListHtml from "./states/list/list.html";
import checklistTemplatesEditorHtml from "./states/editor/editor.html";

export default function ($stateProvider, SABLONO_STATES) {
    "ngInject";

    $stateProvider.state(SABLONO_STATES.checklistTemplatesList, {
        url: "/list",
        templateUrl: checklistTemplatesListHtml,
        controller: "ChecklistTemplatesListCtrl",
        controllerAs: "list",
        gaPageTrack: "/checklistTemplates/list",
        data: {
            category: "PLAN", // REVIEW or WORK
            title: "CHECKLIST_TEMPLATE_PAGE_NAME",
        },
    });

    $stateProvider.state(SABLONO_STATES.checklistTemplatesEditor, {
        url: "/editor/:checklistTemplateId",
        templateUrl: checklistTemplatesEditorHtml,
        controller: "ChecklistTemplatesEditorCtrl",
        controllerAs: "editor",
        gaPageTrack: "/checklistTemplates/editor",
        data: {
            category: "PLAN", // REVIEW or WORK
            title: "CREATE_CHECKLIST_EDITOR_TITLE",
            hasBackButton: true,
            backButtonTarget: SABLONO_STATES.checklistTemplatesList,
        },
    });
}
