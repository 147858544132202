import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sbCalendarSelection.html";
/**
 * Angular Directive Documentation
 * https://docs.angularjs.org/guide/directive
 * https://docs.angularjs.org/api/ng/service/$compile
 */
export default angular
    .module("sbApp.sbCalendarSelection", [])
    .directive("sbCalendarSelection", function () {
        return {
            /**
             * String of subset of EACM which restricts the directive to a specific directive declaration style.
             * If omitted, the defaults (elements and attributes) are used.
             *
             * @example <caption>Element Name</caption>
             * E - Element name (default): <my-directive></my-directive>
             * A - Attribute (default): <div my-directive="exp"></div>
             * C - Class: <div class="my-directive: exp;"></div>
             * M - Comment: <!-- directive: my-directive exp -->
             */
            restrict: "E",

            templateUrl: htmlTemplate, //HTML Template as String

            //replace: true,

            //transclude: true,

            scope: {
                model: "=",
                //TODO: Check how angular material is doing it !!!
                parentForm: "=form",
                isAdmin: "=",
            },

            //require: 'siblingDirectiveName', // or // ['^parentDirectiveName', '?optionalDirectiveName', '?^optionalParent'],

            //compile : function(tElement, tAttrs) {},

            //link : function(scope, iElement, iAttrs, controller) {},

            controller: function ($scope, $sbTimezone) {
                $scope.weekdays = [
                    "MON",
                    "TUE",
                    "WED",
                    "THU",
                    "FRI",
                    "SAT",
                    "SUN",
                ];
                $scope.tick = 15;

                $scope.addTick = addTick;
                $scope.selectDay = selectDay;

                function selectDay($index) {
                    if ($scope.isAdmin) {
                        $scope.model.DAYS[($index + 1) % 7] =
                            !$scope.model.DAYS[($index + 1) % 7];
                    }
                }

                function addTick(hour, tick) {
                    return moment(hour, "HH:mm")
                        .add(tick, "minutes")
                        .format("HH:mm");
                }
            },
        };
    });
