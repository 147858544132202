import { find } from "lodash";

import LeanboardSession from "../model/lean_board_session.class";

export default class LookAheadSessionService {
    constructor($sbLookAheadSessionsApi, $sbCurrentProject, Analytics) {
        "ngInject";

        this.$sbLookAheadSessionsApi = $sbLookAheadSessionsApi;
        this.$sbCurrentProject = $sbCurrentProject;
        this.Analytics = Analytics;
        this._openSessions = [];
    }

    fetchAndSetOpenSessions() {
        return this.$sbLookAheadSessionsApi
            .getCollection(
                this.$sbCurrentProject.pick("id"),
                this.$sbLookAheadSessionsApi.STATUS_OPEN
            )
            .then((serverSessions) =>
                this._parseServerSessionsToViewModel(serverSessions)
            )
            .then((openSessions) => {
                this._openSessions = openSessions;
            });
    }

    findAndGetOpenSessionFor(currentUser) {
        return find(this._openSessions, function (openSession) {
            return openSession.isOpenFor(currentUser);
        });
    }

    createNewSession() {
        return this.fetchAndSetOpenSessions()
            .then(() => this._createNewSession())
            .then((response) => this._parseResponseToModel(response))
            .then((newSession) => {
                this._openSessions.push(newSession);
            });
    }

    closeOpenSession(sessionId) {
        return this.$sbLookAheadSessionsApi.update(
            this.$sbCurrentProject.pick("id"),
            sessionId,
            this.$sbLookAheadSessionsApi.ACTION_DISCARD
        );
    }

    closeOpenSessionAndReleaseChanges(sessionId) {
        this.Analytics.trackConversion("session released");
        return this.$sbLookAheadSessionsApi.update(
            this.$sbCurrentProject.pick("id"),
            sessionId,
            this.$sbLookAheadSessionsApi.ACTION_RELEASE
        );
    }
    _parseServerSessionsToViewModel(serverSessions) {
        return serverSessions.sessions.map((session) =>
            this._parseResponseToModel(session)
        );
    }
    _createNewSession() {
        return this.$sbLookAheadSessionsApi.create(
            this.$sbCurrentProject.pick("id")
        );
    }

    _parseResponseToModel(response) {
        return LeanboardSession.createFromServerObject(response);
    }
}
