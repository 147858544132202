import angular from "angular";
import templateHtml from "./sbChangeParentDialog.html";
import editorTreeModule from "../../directives/sbEditorTree/sbEditorTree";

export default angular
    .module("sbApp.sbChangeParentDialog", [editorTreeModule.name])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("sbChangeParentDialog", {
            methods: [
                "ariaLabel",
                "actions",
                "autoClose",
                "clickOutsideToClose",
                "escapeToClose",
                "class",
                // custom attributes
                //
                "treeRootGroup",
                "changeParentForNode",
            ],
            options: function selectDialog() {
                return {
                    templateUrl: templateHtml,
                    controllerAs: "$ctrl",
                    bindToController: true,
                    controller: ChangeParentCtrl,
                };
            },
        });
    });

function ChangeParentCtrl($mdDialog) {
    "ngInject";
    var vm = this;
    vm.selectedParent = vm.treeRootGroup;

    vm.filterFunction = function filterIt(component) {
        if (component.getData().category === "ACTIVITY") {
            return false;
        }

        // remove the children of the selected node
        //      -> you can not change the parent to a child of yourself
        //
        if (component.hasAncestor(vm.changeParentForNode)) {
            return false;
        }

        // remove self
        //
        return !component.selfById(vm.changeParentForNode);
    };

    vm.onNodeSelected = function (selection) {
        vm.selectedParent = selection;
    };

    vm.hide = function () {
        $mdDialog.hide(true);
    };
    vm.abort = $mdDialog.cancel;
    vm.resolve = $mdDialog.hide;

    vm.submit = function () {
        $mdDialog.hide(vm.selectedParent);
    };
}
