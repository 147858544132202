import angular from "angular";
import PickProjectMemberCtrl from "./pick_project_member_dialog.controller";
import dialogTemplate from "./pick_project_member_dialog.html";

export default function PickProjectMemberConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("pickProjectMember", {
        methods: ["title", "description", "action"],
        options: function () {
            return {
                controller: PickProjectMemberCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                templateUrl: dialogTemplate,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
