import _ from "lodash";
import moment from "moment";
import Group from "./sb_group.class";
import BaseComponent from "./sb_base_component.class";

/**
 * Create a deliverable
 *
 * @constructor
 * @extends Group
 */
function Deliverable(id, name, code) {
    Group.call(this, id, name, code);

    this.structureId = null;
    this.externalId = null;
    this.projectSourceId = null;

    /**
     * All the notes of the deliverable
     * @type {Array<Deliverable>}
     */
    this.successors = null;

    /**
     * All the notes of the deliverable
     * @type {Array<Deliverable>}
     */
    this.predecessors = null;
}

Deliverable.prototype = Object.create(Group.prototype);

Deliverable.ODATA_PROPERTY_MAP = {
    ID: "id",
    STRUCTURE_ID: "structureId",
    STRUCTURE: "structure",
    TEMPLATE_ID: "piTemplateId",
    PROJECT_ID: "projectId",
    EXTERNAL_ID: "externalId",
    PROJECT_SOURCE_PSEUDO_KEY: "projectSourceId",

    NAME: "name",
    DESC: "desc",
    CODE: "code",
    CATEGORY: "category",

    PLANNED_START: "plannedStart",
    PLANNED_END: "plannedEnd",
    EARLIEST_START: "earliestStart",
    EARLIEST_END: "earliestEnd",
    LATEST_START: "latestStart",
    LATEST_END: "latestEnd",

    SD: "startDate",
    CD: "endDate",
    IS_BEHIND: "scheduleState",

    PROGRESS: "progress",

    PROGRESS_CHANGE_TIME: "progressChangeTime",
    ISSUES_CHANGE_TIME: "issueChangeTime",
    LAST_INSPECTION: "latestInspectionTime",

    OBSTRUCTION_QUANTITY: "_noteStatistic.openObstructions",
    CLOSED_OBSTRUCTION_QUANTITY: "_noteStatistic.closedObstructions",
    CLAIM_QUANTITY: "_noteStatistic.openClaims",
    CLOSED_CLAIM_QUANTITY: "_noteStatistic.closedClaims",
    INFO_QUANTITY: "_noteStatistic.info",

    AREA_MANAGER_USER_NAME: "areaManagerUserName",
    TYPE_NAME: "typeName",
};

Deliverable.setLeadingDatesFromScheduleResult = function (deliverable) {
    const starts = [
        deliverable.earliestStart,
        deliverable.latestStart,
        deliverable.plannedStart,
    ];
    const ends = [
        deliverable.earliestEnd,
        deliverable.latestEnd,
        deliverable.plannedEnd,
    ];

    deliverable.startDate =
        _.head(starts.filter((m) => isValidMoment(m))) || null;
    deliverable.endDate = _.head(ends.filter((m) => isValidMoment(m))) || null;
};

Deliverable.prototype.isScheduled = function () {
    return isValidMoment(this.startDate) || isValidMoment(this.endDate);
};

function isValidMoment(d) {
    return moment.isMoment(d) && d.isValid();
}

Deliverable.createFromOdataObject = function (odataObject) {
    return Deliverable.createFromOdataObjectIntoInstance(
        odataObject,
        new Deliverable()
    );
};

Deliverable.createFromOdataObjectIntoInstance = function (
    odataObject,
    instance
) {
    var clonedData = _.clone(odataObject);
    instance.__odataSource = clonedData;

    // use the property map to translate odata keys to class keys
    //
    Object.keys(Deliverable.ODATA_PROPERTY_MAP).reduce(function (
        deliverable,
        odataProperty
    ) {
        var keyPath = Deliverable.ODATA_PROPERTY_MAP[odataProperty];
        var value = clonedData[odataProperty];

        // if the value is null -> keep the default value from the constructor
        if (_.isNull(value)) {
            return deliverable;
        }

        _.set(deliverable, keyPath, value);

        return deliverable;
    }, instance);

    // make the dates proper moment dates
    //
    instance.startDate = instance.toMomentOrNull(instance.startDate);
    instance.endDate = instance.toMomentOrNull(instance.endDate);

    instance.progressChangeTime = instance.toMomentOrNull(
        instance.progressChangeTime
    );
    instance.issueChangeTime = instance.toMomentOrNull(
        instance.issueChangeTime
    );
    instance.latestInspectionTime = instance.toMomentOrNull(
        instance.latestInspectionTime
    );

    // convert IS_BEHIND - default is 'unknown'
    //
    if (instance.scheduleState > 0) {
        instance.scheduleState = BaseComponent.SCHEDULE_BEHIND;
    }
    if (instance.scheduleState === 0) {
        instance.scheduleState = BaseComponent.SCHEDULE_ON_TIME;
    }

    return instance;
};

/**
 * Difference between end date and today minus open working days.
 *
 * @param {Object} deliverable
 * @param {Number} deliverable.remainingWorkingDays
 * @param {String} deliverable.plannedEnd
 *
 * @param {WorkingCalendar} calendar that is used as measure for days
 *
 * @param {Moment} compareDate
 *
 * @returns {Number}
 */
Deliverable.delayByDays = function (deliverable, calendar, compareDate) {
    deliverable.plannedEnd = parseFloat(deliverable.plannedEnd);
    compareDate = compareDate || moment();

    if (isNaN(deliverable.plannedEnd) || !calendar) {
        return deliverable.remainingWorkingDays;
    }

    var remainingWorkingHours =
        deliverable.remainingWorkingDays * calendar._workingHoursPerDay;
    var predictedEndDate = calendar.findEarliestEndForTask(
        compareDate,
        remainingWorkingHours
    );

    return (
        -1 *
        Math.round(
            moment(deliverable.plannedEnd).diff(predictedEndDate, "days", true)
        )
    );
};

/**
 * Checks if the given task can be completed before the given end date by comparing the remaining
 * working hours of the task to the open working hours in the calendar from now until the end date.
 *
 * @param {Object} deliverable
 * @param {Number} deliverable.remainingWorkingDays - remaining work in working days
 * @param {String} deliverable.plannedEnd - planned end date based in project timezone
 * @param {WorkingCalendar} calendar - the projects working calendar
 * @param {Moment} compareDate - a reference date based in project timezone (normally now)
 * @returns {boolean}
 */
Deliverable.isCompletable = function (deliverable, calendar, compareDate) {
    return Deliverable.delayByDays(deliverable, calendar, compareDate) <= 0;
};

export default Deliverable;
