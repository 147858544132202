import angular from "angular";
import htmlTemplate from "./checklist_template_row.html";
import ChecklistTemplateRowCtrl from "./checklist_template_row.controller";
export default {
    templateUrl: htmlTemplate,
    bindings: {
        template: "<",
        onClick: "&",
    },
    controllerAs: "row",
    controller: ChecklistTemplateRowCtrl,
};
