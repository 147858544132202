import PresentableError from "./PresentableError";

/**
 * Basic error to handle authentication errors.
 *
 * @constructor
 * @extends PresentableError
 * @param {String} message - Message as plain string or i18n key.
 */
function AuthenticationError(
    message = "ERROR_AUTHENTICATION_NO_SESSION_MESSAGE"
) {
    this.name = "AuthenticationError";
    this.message = message;
    this.title = "ERROR_AUTHENTICATION_NO_SESSION_TITLE";
}

AuthenticationError.prototype = Object.create(PresentableError.prototype);

/** @inheritdoc */ AuthenticationError.prototype.getIcon = function () {
    return "mdi mdi-account";
}; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ AuthenticationError.prototype.shouldAutoClose = function () {
    return true;
};

export default AuthenticationError;
