import angular from "angular";
import addUserToTeamDialog from "./dialogs/add_user_to_team.dialog";
import modifyPendingInvitationDialog from "./dialogs/modify_pending_invitaiton.dialog";
import editOrAddTeamDialog from "common/dialog/editOrAddTeam/edit_or_add_team.dialog";
import $sbServices from "common/services/services";
import intercomModule from "common/intercom/intercomModule";
import teamsRoute from "./teams.route";
import teamsCtrl from "./teams.controller";
import teamCard from "./components/team_card/team_card.component";
import teamCreate from "./components/team_create/team_create.component";
import userCard from "./components/user_card/user_card.component";
import userCreate from "./components/user_create/user_create.component";
import teamSelect from "./components/team_selection/team_selection.component";
import roleSelect from "./components/role_selection/role_selection.component";
import areaManagerCard from "./components/area_manager_card/area_manager_card.component";
import "angular-ui-router";
import invitedMemberCard from "./components/invited_member_card/invited_member_card.component";
// define module
//
export default angular
    .module("sbApp.ProjectTeamsModule", [
        "ui.router",
        $sbServices.name,
        addUserToTeamDialog.name,
        intercomModule.name,
        editOrAddTeamDialog.name,
        modifyPendingInvitationDialog.name,
    ])
    .config(teamsRoute)
    .component("sbTeamCard", teamCard)
    .component("sbTeamCreate", teamCreate)
    .component("sbTeamUserCard", userCard)
    .component("sbTeamUserCreate", userCreate)
    .component("sbTeamAreaManagerCard", areaManagerCard)
    .component("sbInvitedMemberCard", invitedMemberCard)
    .component("sbTeamSelection", teamSelect)
    .component("sbRoleSelection", roleSelect)
    .controller("TeamsCtrl", teamsCtrl);
