import createjs from "createjs";

/**
 * Event that is dispatched when a Node should be deleted
 * @param {WBS.StructureNode} node - Node that should be Deleted
 * @constructs DeletedNodeEvent
 * @extends createjs.Event
 * @memberof WBS
 */
function DeleteNodeEvent(node) {
    createjs.Event.call(this, "nodeDeleted", false, false);

    this.node = node;
}

DeleteNodeEvent.prototype = Object.create(createjs.Event.prototype);

export default DeleteNodeEvent;
