export default class CalendarsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a collection of calendars associated to a specific project.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/calendars`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns a specific calendar by their given key.
     *
     * @param projectId {string}    UUID
     * @param calendarId {integer}    The unique identifier of the calendar you are requesting a resource for.
     */
    get(projectId, calendarId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/calendars/${calendarId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint allows to modify an existing calendar.
     *
     * @param projectId {string}    UUID
     * @param calendarId {integer}    The unique identifier of the calendar you are requesting a resource for.
     *
     * @param body {Object}    Request body
     * @param body.working_shifts {array}    User defined working shifts.
     * @param body.off_days {array}    User defined non working days in the calendar.
     * @param body.working_days {array}    List representing working days per week. Starting on Monday and finishing on Sunday
     * @param body.timezone {string}    Timezone identifier of the calendar
     */
    update(
        projectId,
        calendarId,
        { working_shifts, off_days, working_days, timezone }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/calendars/${calendarId}`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "PUT",
            body: { working_shifts, off_days, working_days, timezone },
            header: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            transformRequest: [this.$sbRequest.transform.stringifyJSON],
        }).then((resp) => {
            const jobId = resp.getResponseHeader("x-job-id");
            const response = JSON.stringify(resp.responseText || '""');
            return { response, jobId };
        });
    }
}
