export default function DashboardProCtrl() {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    // schedule chart
}
