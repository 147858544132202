export default function ($timeout, $document) {
    "ngInject";
    var hiding = false;

    return {
        hide: hide,
    };

    function hide() {
        if (hiding) {
            return;
        }
        hiding = true;
        var bootstrapOverlay = $document[0].querySelector("#bootstrap-overlay");
        if (bootstrapOverlay) {
            bootstrapOverlay.classList.add("hide");
            $timeout(function () {
                bootstrapOverlay.parentNode.removeChild(bootstrapOverlay);
            }, 500);
        }
    }
}
