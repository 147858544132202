import ConstantsModule from "./constants.module";

ConstantsModule.constant("ACCEPTED_DATETIME_FORMATS", [
    "D/M/YY",
    "D/M/YY hh:mm",
    "D/MM/YY",
    "D/MM/YY hh:mm",
    "DD/M/YY",
    "DD/M/YY hh:mm",
    "DD/MM/YY",
    "DD/MM/YY hh:mm",
    "D/M/YYYY",
    "D/M/YYYY hh:mm",
    "DD/M/YYYY",
    "DD/M/YYYY hh:mm",
    "D/MM/YYYY",
    "D/MM/YYYY hh:mm",
    "DD/MM/YYYY",
    "DD/MM/YYYY hh:mm",
    "YYYY/M/D",
    "YYYY/M/D hh:mm",
    "YYYY/M/DD",
    "YYYY/M/DD hh:mm",
    "YYYY/MM/D",
    "YYYY/MM/D hh:mm",
    "YYYY/MM/DD",
    "YYYY/MM/DD hh:mm",
]);
