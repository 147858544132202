import PresentableError from "./PresentableError";

/**
 * Basic error if a request fails.
 *
 * @constructor
 * @extends PresentableError
 *
 * @param {XMLHttpRequest} request - Used XMLHttpRequest object
 */
function RequestError(request) {
    this.code = "REQUEST";
    this.title = "ERROR_REQUEST_TITLE";
    this.message = "ERROR_REQUEST_MESSAGE";
    this.request = request;
}

RequestError.prototype = Object.create(PresentableError.prototype); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ RequestError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.TOAST;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ RequestError.prototype.shouldAutoClose = function () {
    return true;
};

/**
 * Try to parse json or returns false
 *
 * @return {Object|false} Return the  parsed Object or false if an error occurred.
 */
RequestError.prototype.parseResponseAsJSON = function () {
    try {
        return JSON.parse(this.request.response);
    } catch (error) {
        return false;
    }
};

export default RequestError;
