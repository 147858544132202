export default class TeamsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the teams in the project.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/teams`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns the team by id.
     *
     * @param projectId {string}    UUID
     * @param teamId {integer}    The unique identifier of the team you are requesting a resource for.
     */
    get(projectId, teamId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/teams/${teamId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a new team in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.name {string}    The human readable name of the team.
     * @param [body.color] {string}    The hex color code.
     * @param [body.logo] {object}    The attached project team logo.
     * @param [body.address] {object}    General address information.
     * @param [body.contact] {object}    General information about a contactable person.
     */
    create(projectId, { name, color, logo, address, contact }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/teams`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { name, color, logo, address, contact },
        });
    }

    /**
     * This endpoint allows to update some properties.
     *
     * @param projectId {string}    UUID
     * @param teamId {integer}    The unique identifier of the team you are requesting a resource for.
     *
     * @param body {Object}    Request body
     * @param body.name {string}    The human readable name of the team.
     * @param [body.color] {string}    The hex color code.
     * @param [body.logo] {object}    The attached project team logo.
     * @param [body.address] {object}    General address information.
     * @param [body.contact] {object}    General information about a contactable person.
     */
    update(projectId, teamId, { name, color, logo, address, contact }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/teams/${teamId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: { name, color, logo, address, contact },
        });
    }

    /**
     * This endpoint allows to remove a team. Deleting a team is only possible if there are no memberships for this team.
     *
     * @param projectId {string}    UUID
     * @param teamId {integer}    The unique identifier of the team you are requesting a resource for.
     */
    delete(projectId, teamId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/teams/${teamId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
