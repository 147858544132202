import html from "./workflow_activity.html";
import WorkflowRowCtrl from "./workflow_activity.controller";

export default {
    templateUrl: html,
    replace: true,
    bindings: {
        node: "<",
    },
    require: {
        treeCtrl: "^sbActivityTree",
    },
    controller: WorkflowRowCtrl,
};
