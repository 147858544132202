import PresentableError from "./PresentableError";

/**
 * Error if a request is aborted
 *
 * @constructor
 * @extends PresentableError
 *
 * @param {XMLHttpRequest} request - Used XMLHttpRequest object
 */
function NewPasswordRequiredError() {
    this.code = "NEW_PASSWORD_REQUIRED";
    this.title = "NEW_PASSWORD_REQUIRED_TITLE";
    this.message = "NEW_PASSWORD_REQUIRED_MESSAGE";
}

NewPasswordRequiredError.prototype = Object.create(PresentableError.prototype); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ NewPasswordRequiredError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.NONE;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ NewPasswordRequiredError.prototype.shouldAutoClose =
    function () {
        return true;
    };

export default NewPasswordRequiredError;
