import angular from "angular";
import createNoteTemplateHtml from "./createNoteTemplate.html";
import "../../noteTemplates.module";

export default angular
    .module("sbApp.sbCreateNoteTemplateDialog", [
        "sbApp.services",
        "sbApp.NoteTemplatesModule",
    ])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("createNoteTemplate", {
            methods: [
                "ariaLabel",
                "actions",
                "autoClose",
                "clickOutsideToClose",
                "escapeToClose",
                "lastCreatedCodes",
            ],
            options: function selectDialog($mdDialog) {
                "ngInject";
                return {
                    templateUrl: createNoteTemplateHtml,
                    controller: function mdDialogCtrl(
                        $scope,
                        noteTemplatesService,
                        $sbCodeRecommendation,
                        $mdToast,
                        ISSUE_TEMPLATE_CONFIG,
                        TRAFFIC_LIGHT_MODES,
                        Analytics
                    ) {
                        "ngInject";

                        var controller = this;

                        $scope.newNoteTemplate = {
                            KEY: 0,
                            CODE: suggestCodeByType(
                                ISSUE_TEMPLATE_CONFIG.TYPES.DEFAULT
                            ),
                            TYPE: ISSUE_TEMPLATE_CONFIG.TYPES.DEFAULT,
                        };

                        $scope.noteCreated = [];
                        $scope.types = ISSUE_TEMPLATE_CONFIG.TYPES.OPTIONS;
                        $scope.TRAFFIC_LIGHT_MODES = TRAFFIC_LIGHT_MODES;

                        $scope.selectType = function (type) {
                            $scope.newNoteTemplate.TYPE = type.SERVER_NAME;
                            $scope.newNoteTemplate.CODE = suggestCodeByType(
                                type.SERVER_NAME
                            );
                        };

                        function suggestCodeByType(type) {
                            return $sbCodeRecommendation.getNextSuggestionFrom(
                                controller.lastCreatedCodes[type]
                            );
                        }

                        $scope.hide = closeAndReturnCreatedTemplates;
                        $scope.close = closeAndReturnCreatedTemplates;

                        function closeAndReturnCreatedTemplates() {
                            $mdDialog.hide($scope.noteCreated);
                        }

                        $scope.abort = function () {
                            $mdDialog.cancel($scope.noteCreated);
                        };

                        $scope.resolve = function (action) {
                            $mdDialog.hide(action);
                        };

                        $scope.submit = function (newNote) {
                            $mdDialog.hide({
                                newNote: newNote,
                                prevCreated: $scope.noteCreated,
                            });
                        };

                        $scope.computeLabel = function (key) {
                            var type = $scope.types[key];
                            if (type) {
                                return type.LABEL;
                            } else {
                                return "";
                            }
                        };

                        $scope.computeClass = function (key) {
                            var type = $scope.types[key];
                            if (type) {
                                return type.CLASS;
                            }
                        };

                        /**
                         * Sends the request to create a new note template and then empties the
                         * field and update the code
                         * @param {event} $event - event
                         * @param {object} form - form object
                         */
                        $scope.createNew = function ($event, form) {
                            Analytics.trackConversion("template created");

                            Analytics.trackEvent(
                                "Note templates",
                                "click",
                                "creation"
                            );
                            noteTemplatesService
                                .createNote($scope.newNoteTemplate)
                                .then(function showSuccessToast(createdNote) {
                                    $mdToast.show(
                                        $mdToast
                                            .simple()
                                            .content(
                                                "INFO_TEMPLATE_ADD_SUCCESS_TITLE"
                                            )
                                            .position("top right")
                                    );

                                    return createdNote;
                                })
                                .then(
                                    function saveCreatedNoteAndPrepareDialog(
                                        createdNote
                                    ) {
                                        var prevCode =
                                                $scope.newNoteTemplate.CODE,
                                            recommendedNextCode =
                                                $sbCodeRecommendation.getNextSuggestionFrom(
                                                    prevCode
                                                ),
                                            prevType =
                                                $scope.newNoteTemplate.TYPE;

                                        $scope.noteCreated.push(createdNote);
                                        controller.lastCreatedCodes[
                                            createdNote.TYPE
                                        ] = prevCode;

                                        $scope.newNoteTemplate = {
                                            KEY: 0,
                                            CODE: recommendedNextCode,
                                            TYPE: prevType,
                                        };

                                        if (form) {
                                            form.$setUntouched();
                                        }
                                    }
                                );
                        };

                        /**
                         * Used in the ng-keydown of the text area. Listen for a Shift+Enter. If that's so, it
                         * calls the $scope.createNew() function
                         * @param  {Object} $event - The event containing the key infos
                         * @param  {Object} form   - The form of the dialog. Used to check if all the fields are filled
                         */
                        $scope.shiftEnter = function ($event, form) {
                            if (!(form.$valid && $scope.newNoteTemplate.TYPE)) {
                                return;
                            }
                            if ($event.shiftKey && $event.keyCode === 13) {
                                $event.preventDefault();
                                $scope.createNew();
                            }
                        };
                    },
                    controllerAs: "dialog",
                    bindToController: true,
                };
            },
        });
    });
