/**
 *
 * MODULE SHORT DESCRIPTION:
 *
 * The element selection module is providing common designs and solutions to handle selection states.
 * Currently it is focusing on selection states on lists.
 *
 * - There is a sablono custom checkbox that works well with the SelectionProvider implementation
 *   and have our custom hover/show/hide approach implemented. Also common coloring is defined.
 *
 * - There is also a common toolbar to switch between a page dependent toolbar and a selection is active
 *   toolbar (red) to provide select all / deselect all and a counting. Also there is a common place for
 *   the delete operation on these selection.
 *
 */
import angular from "angular";
import sbEleSelectionCheckbox from "./sbEleSelectionCheckbox";
import sbEleSelectionSubbar from "./sbEleSelectionSubbar";

export default angular
    .module("sbApp.sbElementSelection", [
        // dependencies
    ])
    .directive("sbEleSelectionCheckbox", sbEleSelectionCheckbox)
    .directive("sbEleSelectionSubbar", sbEleSelectionSubbar);
