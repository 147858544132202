import angular from "angular";
import ActivitySetConfigurationCtrl from "./activity_set_configuration.controller";
import dialogTemplate from "./activity_set_configuration.html";

export default function ActivitySetConfigurationConfig($mdDialogProvider) {
    "ngInject";
    $mdDialogProvider.addPreset("activitySetConfiguration", {
        methods: ["class", "deliverableSelection", "templateId"],
        options: function () {
            return {
                controller: ActivitySetConfigurationCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
            };
        },
    });
}
