import angular from "angular";
import _ from "lodash";
import htmlTemplate from "./component_tag_cloud.html";

export default angular
    .module("sbApp.common.components.sbComponentTagCloud", [])
    .component("sbComponentTagCloud", {
        templateUrl: htmlTemplate,
        replace: true,
        bindings: {
            claimQuantity: "<",
            obstructionQuantity: "<",
            infoQuantity: "<",
            behindSchedule: "<",
            activityDelayed: "<",
            noProcess: "<",
            showQuantity: "<",
            onTime: "<",
            userDate: "<",
            userDateConflict: "<",
        },
        controller: function sbComponentTagCloudCtrl() {
            var TAG_PROPERTIES = {
                obstructionQuantity: {
                    translationTooltip: "_OPEN_OBSTRUCTIONS_N",
                    icon: "sb-obstruction mdi mdi-texture mdi-rotate-90",
                    classNames: "sb-tag-open-obstructions",
                },
                claimQuantity: {
                    translationTooltip: "_OPEN_CLAIMS_N",
                    icon: "sb-claim mdi mdi-alert",
                    classNames: "sb-tag-open-claims",
                },
                infoQuantity: {
                    translationTooltip: "_INFO_N",
                    icon: "sb-info mdi mdi-information",
                    classNames: "sb-tag-infos",
                },
                noProcess: {
                    translation: "_NO_PROCESS",
                    classNames: "sb-tag-unscheduled",
                },
                behindSchedule: {
                    translationTooltip:
                        "INFO_BEHIND_SCHEDULE_EXPLANATION_MESSAGE",
                    icon: "mdi mdi-calendar-clock",
                    classNames: "sb-tag-behind",
                },
                activityDelayed: {
                    translationTooltip: "_BEHIND_BASELINE_SCHEDULE",
                    icon: "mdi mdi-calendar-clock",
                    classNames: "sb-tag-behind",
                },
                onTime: {
                    translationTooltip: "_ON_SCHEDULE",
                    icon: "mdi mdi-calendar-clock",
                    classNames: "sb-tag-on-time",
                },
                userDate: {
                    translationTooltip: "INFO_USER_DEFINED_DATE",
                    classNames: "sb-tag-user-date",
                    icon: "mdi mdi-calendar-star",
                },
                userDateConflict: {
                    translationTooltip: "USER_DEFINED_DATE_CONFLICT",
                    classNames: "sb-tag-user-date-conflict",
                    icon: "mdi mdi-calendar-alert",
                },
            };

            var vm = this;
            vm.$onChanges = $onChanges;

            function $onChanges(changes) {
                // initialize the data object
                vm.data = vm.data || TAG_PROPERTIES;

                vm.showQuantity = !!vm.showQuantity;

                // update all the properties of the data object
                //
                _.forOwn(changes, function (change, key) {
                    if (change.currentValue !== change.previousValue) {
                        _.merge(
                            vm.data[key],
                            _setValue(change.currentValue, vm.showQuantity)
                        );
                    }
                });
            }

            function _setValue(value, showQuantity) {
                return {
                    value: value || 0,
                    translationValue: {
                        items: value,
                    },
                    isVisible: value > 0 || value === true,
                    showQuantity: showQuantity && angular.isNumber(value),
                };
            }
        },
    });
