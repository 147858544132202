export default class DeliverablesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the deliverables in the project.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/deliverables`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns the deliverable by id.
     *
     * @param projectId {string}    UUID
     * @param deliverableId {string}    The unique identifier of the deliverable you are requesting a resource for.
     */
    get(projectId, deliverableId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/deliverables/${deliverableId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * POST request to create a new deliverable with given name, code and description
     *
     * @param projectId {string}            UUID
     *
     * @param body {Object}                 Request body
     * @param body.name {!string}           Descriptive non-unique short name.
     * @param body.code {!string}           Unique project code for customers to identify deliverable.
     * @param [body.description] {!string}  Freeform text for additional comments and information.
     */
    create(projectId, { name, code, description }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/deliverables`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: {
                name,
                code,
                description,
            },
        });
    }

    /**
     * This endpoint allows to update some properties.
     *
     * @param projectId {string}    UUID
     * @param deliverableId {string}    The unique identifier of the deliverable you are requesting a resource for.
     *
     * @param body {Object}    Request body
     * @param [body.name] {!string}    User defined name of the deliverable.
     * @param [body.description] {!string}    User defined description of the deliverable.
     * @param [body.start_date] {!string}    User defined baseline start date
     * @param [body.end_date] {!string}    User defined baseline end date
     */
    update(
        projectId,
        deliverableId,
        { name, description, start_date, end_date }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/deliverables/${deliverableId}`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "PUT",
            body: JSON.stringify({ name, description, start_date, end_date }),
            header: {
                "Content-Type": "application/json",
            },
        }).then((resp) => {
            const jobId = resp.getResponseHeader("x-job-id");
            const response = JSON.parse(resp.responseText);
            return { response, jobId };
        });
    }
}
