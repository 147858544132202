import html from "./workflow_group.html";
import WorkflowGroupCtrl from "./workflow_group.controller";

export default {
    templateUrl: html,
    replace: true,
    bindings: {
        node: "<",
        expanded: "<",
    },
    require: {
        treeCtrl: "^sbActivityTree",
    },
    controller: WorkflowGroupCtrl,
};
