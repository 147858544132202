export default function datesService(
    $sbActivityJobsApi,
    $sbCurrentProject,
    $sbJobApi
) {
    "ngInject";
    return {
        overwriteUserDefinedDates: overwriteUserDefinedDates,
        forActivityBy: forActivityBy,
    };

    /**
     * send a post request to the backend, in order to override user defined dates
     * @param {String|String[]} deliverable_set
     * @param {String[]} activity_templates
     * @return {Promise}
     */
    function overwriteUserDefinedDates(deliverable_set, activity_templates) {
        return $sbActivityJobsApi
            .createModifyPropertiesJob($sbCurrentProject.pick("id"), {
                properties: {
                    baseline_schedule: {
                        constraints: {
                            start_date: "sb::date.not-set",
                            end_date: "sb::date.not-set",
                        },
                    },
                },
                deliverable_set,
                activity_templates,
            })
            .then(({ response, jobId }) => {
                if (jobId) {
                    // Wait until the job is done
                    return $sbJobApi.waitFor(jobId).then(() => response);
                }
                return response;
            });
    }

    function forActivityBy({ deliverableId, activityTemplateId }) {
        return overwriteUserDefinedDates([deliverableId], [activityTemplateId]);
    }
}
