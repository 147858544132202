import angular from "angular";

export default function FilterPanelCtrl(mdPanelRef) {
    "ngInject";

    const vm = this;

    vm.showAdvanced = false;
    vm.showAdvancedAvailable = false;
    vm.allowFiltersClear = vm.allowFiltersClear || true;

    vm.$onInit = $onInit;
    vm.hasFiltersToClear = () => vm.allowFilterClear && hasFiltersToClear;
    vm.closeBox = close;
    vm.isShowAdvancedOptionAvailable = isShowAdvancedOptionAvailable;

    function $onInit() {
        vm.showAdvancedAvailable = vm.isShowAdvancedOptionAvailable();
    }

    function isShowAdvancedOptionAvailable() {
        return vm.filters.some(function (viewFilter) {
            return !viewFilter.isInReducedFilters && viewFilter.isDisplayable;
        });
    }

    function close() {
        mdPanelRef && mdPanelRef.close();
    }

    function hasFiltersToClear(viewFilters) {
        return viewFilters.some(function (currentFilter) {
            const value = currentFilter.getValue();

            const hasAtLeastOneDefinedValue =
                value &&
                value.length > 0 &&
                value.some(function (value) {
                    return angular.isDefined(value);
                });

            return currentFilter.isDisplayable && hasAtLeastOneDefinedValue;
        });
    }
}
