import AuthError from "./AuthError";

class PasswordResetRequiredError extends AuthError {
    constructor(message = "") {
        super(message);
        this.code = "PasswordResetRequiredError";
        this.title = "LOGIN__PASSWORD_RESET_REQUIRED_TITLE";
        this.message = "LOGIN__PASSWORD_RESET_REQUIRED_MESSAGE";
    }
}

export default PasswordResetRequiredError;
