"use strict";

import StateTransitionPermissionMatrix from "./sb_state_transition_permission_matrix.class";

const NO_PERMISSIONS = [
    // not started, started, done, waiting for confirmation, rejected, confirmed
    //
    [0, 0, 0, 0, 0, 0], // not started -> to ?
    [0, 0, 0, 0, 0, 0], // started -> to ?
    [0, 0, 0, 0, 0, 0], // done -> to ?
    [0, 0, 0, 0, 0, 0], // waiting for confirmation -> to ?
    [0, 0, 0, 0, 0, 0], // rejected -> to ?
    [0, 0, 0, 0, 0, 0], // confirmed -> to ?
];

export default class ActivityStateTransitionPermissionMatrix extends StateTransitionPermissionMatrix {
    constructor(permissions) {
        const STATE_TO_INDEX = new Map([
            ["NOT_STARTED", 0],
            ["STARTED", 1],
            ["DONE", 2],
            ["WAITING_FOR_CONFIRMATION", 3],
            ["REJECTED", 4],
            ["CONFIRMED", 5],
        ]);

        const INDEX_TO_STATE = new Map([
            [0, "NOT_STARTED"],
            [1, "STARTED"],
            [2, "DONE"],
            [3, "WAITING_FOR_CONFIRMATION"],
            [4, "REJECTED"],
            [5, "CONFIRMED"],
        ]);

        super(permissions || NO_PERMISSIONS, INDEX_TO_STATE, STATE_TO_INDEX);
    }
}
