import _ from "lodash";
import html from "./activities_list_selector.html";
import naturalSort from "../../../filters/natural_sort.pojo";

export default {
    templateUrl: html,
    bindings: {
        activities: "<",
        initialSelection: "<?",
        titleTranslationKey: "@",
        onActivitySelect: "&?",
        onActivitySelectionChange: "&",
        disableMultiSelection: "<?",
        showProcessTemplate: "<?",
        showConfirmationTeam: "<?",
        hideTeam: "<",
        customColumnHeaders: "<?",
    },
    controller: function ActivitiesListSelectorCtrl(
        $scope,
        $sbActivitiesListSelector
    ) {
        "ngInject";
        var vm = this;

        vm.sortProperty = [];
        vm.areActivitiesSelected =
            $sbActivitiesListSelector.areActivitiesSelected;
        vm.areAllFilteredActivitiesSelected =
            $sbActivitiesListSelector.areAllFilteredActivitiesSelected;

        vm.wrappedActivities = [];

        vm.columnHeaders = {
            processTemplate: "_PROCESS_TEMPLATE",
            activityName: "ACTIVITIES_LIST_SELECTOR_HEADER_ACTIVITY",
            workTeam: "ACTIVITIES_LIST_SELECTOR_HEADER_TEAM",
            confirmingTeam: "ACTIVITIES_LIST_SELECTOR_HEADER_CONFIRMATION_TEAM",
        };

        vm.selectActivity = selectActivity;
        vm.toggleSelectAll = toggleSelectAll;
        vm.getSortedActivities = sortActivities;
        vm.toggleSortOrder = toggleSortOrder;
        vm.$onChanges = $onChanges;
        vm.$onInit = $onInit;

        $scope.$watch(
            function () {
                return $sbActivitiesListSelector.getFilteredActivities();
            },
            function (newActivities) {
                vm.wrappedActivities = newActivities;
            }
        );

        function $onChanges(changes) {
            if (changes.activities && !changes.activities.isFirstChange()) {
                const activities = changes.activities.currentValue;
                if (Array.isArray(activities)) {
                    setWrappedActivities(activities, vm.showProcessTemplate);
                }
            }
        }

        function $onInit() {
            setSortProperties(vm.showProcessTemplate);
            setWrappedActivities(vm.activities, vm.showProcessTemplate);
            setInitialSelection(vm.wrappedActivities, vm.initialSelection);

            if (vm.customColumnHeaders) {
                overrideDefaultHeaders(vm.customColumnHeaders);
            }
        }

        function overrideDefaultHeaders(customHeaders) {
            _.forOwn(customHeaders, function (value, key) {
                vm.columnHeaders[key] = value;
            });
        }

        function sortActivities(activities, sortProperties) {
            return [].concat(
                activities.sort(function (e1, e2) {
                    return sortProperties.reduce((compare, property) => {
                        if (compare !== 0) {
                            return compare;
                        }
                        return naturalSort(
                            _.get(e1, property, "").toString(),
                            _.get(e2, property, "").toString()
                        );
                    }, 0);
                })
            );
        }

        function selectActivity(activity) {
            // Unselect other when only one selection allowed
            if (vm.disableMultiSelection) {
                $sbActivitiesListSelector.unselectAllActivities();
            }

            $sbActivitiesListSelector.selectActivity(activity);

            if (vm.onActivitySelect) {
                vm.onActivitySelect({
                    activityData: {
                        selectedActivity: activity.model,
                    },
                });
            }

            vm.onActivitySelectionChange({
                selectedActivities:
                    $sbActivitiesListSelector.getSelectedActivityModels(),
            });
        }

        function toggleSelectAll() {
            if (vm.disableMultiSelection) {
                return;
            }

            if ($sbActivitiesListSelector.areActivitiesSelected()) {
                $sbActivitiesListSelector.unselectAllActivities();
            } else {
                $sbActivitiesListSelector.selectAllFilteredActivities();
            }

            vm.onActivitySelectionChange({
                selectedActivities:
                    $sbActivitiesListSelector.getSelectedActivityModels(),
            });
        }

        function setWrappedActivities(activities, showProcessTemplate) {
            const wrappedActivities =
                $sbActivitiesListSelector.setActivities(activities);
            if (showProcessTemplate) {
                wrappedActivities.forEach((activity) => {
                    activity.topologicalIndexSortPath =
                        activity.model.processTemplateName +
                        activity.topologicalIndex;
                    activity.displayPathSortPath =
                        activity.model.processTemplateName +
                        activity.displayPath;
                });
            }

            vm.wrappedActivities = vm.getSortedActivities(
                wrappedActivities,
                vm.sortProperty
            );
        }

        function setSortProperties(showProcessTemplate) {
            vm.sortProperty = ["topologicalIndex", "displayPath"];
            if (showProcessTemplate) {
                vm.sortProperty = [
                    "topologicalIndexSortPath",
                    "displayPathSortPath",
                ];
            }
        }

        function setInitialSelection(activities, initialSelection) {
            if (Array.isArray(initialSelection) && !vm.disableMultiSelection) {
                $sbActivitiesListSelector.selectActivities(initialSelection);
            }
        }

        function toggleSortOrder() {
            vm.sortProperty = vm.sortProperty.reverse();

            vm.wrappedActivities = vm.getSortedActivities(
                vm.wrappedActivities,
                vm.sortProperty
            );
        }
    },
};
