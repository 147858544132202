import angular from "angular";

import serviceModule from "../../../../common/services/services";
import apiModule from "../../../../common/services/api/api.module";

export default angular
    .module("sbApp.wbsApiService", [serviceModule.name, apiModule.name])
    .service(
        "wbsApiService",
        function wbsApiService(
            $sbStructure,
            $sbStructureNodesApi,
            $sbStructureNodeJobsApi
        ) {
            "ngInject";
            return {
                loadTree: loadTree,
                loadList: loadList,
                createNode: createNode,
                deleteNode: deleteNode,
                updateNode: updateNode,
                mergeTrees: mergeTrees,
            };

            /**
             * Creates a Node
             * @param {string} sProjectId Project Id for created Element
             * @param {string} sParent Parent of Created Element
             * @return {Promise}
             */
            function createNode(sProjectId, sParent) {
                const newNode = {
                    name: "new",
                    code: "N.N",
                };

                if (angular.isString(sParent)) {
                    newNode.parent_id = sParent;
                }

                return $sbStructureNodesApi.create(sProjectId, newNode);
            }

            /**
             * Deletes a Node with sNodeId
             * @param projectId {string}    UUID
             * @param {string} sNodeId Id of Node to be deleted
             * @return {Promise}
             */
            function deleteNode(projectId, sNodeId) {
                return $sbStructureNodesApi.delete(projectId, sNodeId);
            }

            /**
             * Updates given Node
             * @param projectId {string}    UUID
             * @param {Object} oNode Node to be updated
             * @return {Promise}
             */
            function updateNode(projectId, oNode) {
                return $sbStructureNodesApi.update(projectId, oNode.ID, {
                    code: oNode.CODE,
                    name: oNode.NAME,
                });
            }

            /**
             * Loads Structure tree for Project Id
             * @param {string} sProjectId ProjectId for Project to be loaded
             * @returns {Promise}
             */
            function loadTree(sProjectId) {
                return $sbStructure
                    .tree(sProjectId, "components")
                    .then(function (rootArray) {
                        return {
                            PROJECT_ID: sProjectId,
                            components: rootArray,
                        };
                    });
            }

            /**
             * Load the structure tree for the project id as list with plain (name,code,id) values
             *
             * @param sProjectId
             * @returns {*}
             */
            function loadList(sProjectId) {
                return $sbStructure.getSortedMappedList(sProjectId);
            }

            /**
             * Get the current tree and split the root nodes by source. Afterwards trigger the merge
             * algorithm based on the two subsets ( main tree and slave tree ) where the slave tree
             * is merged into the main tree.
             *
             * @param {String} projectId
             * @returns {Promise} A list of operations that should be executed in order to get the merged tree.
             */
            function mergeTrees(projectId) {
                return $sbStructureNodeJobsApi
                    .mergeCodes(projectId)
                    .then((response) => {
                        $sbStructure.clearCache();
                        return response;
                    });
            }
        }
    );
