import angular from "angular";

export default function fullscreenService($document, $rootScope, EVENTS) {
    "ngInject";

    var document = $document[0];
    var emitter = $rootScope.$new();

    // listen event on document instead of element to avoid firefox limitation
    // see https://developer.mozilla.org/en-US/docs/Web/Guide/API/DOM/Using_full_screen_mode
    $document.on(
        "fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange",
        function () {
            emitter.$emit(EVENTS.FULLSCREEN_MODE_CHANGE, isEnabled());
        }
    );

    return {
        enable: enable,
        cancel: cancel,
        isEnabled: isEnabled,
        toggleAll: toggleAll,
        isSupported: isSupported,
    };

    ////////////////////////////////
    //                            //
    //         PUBLIC API         //
    //                            //
    ////////////////////////////////

    ////////////////////////////////
    //                            //
    //         PRIVATE            //
    //                            //
    ////////////////////////////////

    function enable(enterFullscreen) {
        const element = document.documentElement;

        // based on
        //https://github.com/fabiobiondi/angular-fullscreen/blob/master/src/angular-fullscreen.js
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            // Safari temporary fix
            if (
                /Version\/[\d]{1,2}(\.[\d]{1,2}){1}(\.(\d){1,2}){0,1} Safari/.test(
                    navigator.userAgent
                )
            ) {
                element.webkitRequestFullscreen();
            } else {
                element.webkitRequestFullscreen(true);
            }
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }

        if (enterFullscreen) {
            enterFullscreen();
        }
    }

    function cancel(exitFullscreen) {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }

        if (exitFullscreen) {
            exitFullscreen();
        }
    }

    function isEnabled() {
        var fullscreenElement =
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement;
        return fullscreenElement ? true : false;
    }

    function toggleAll(enterFullscreen, exitFullscreen) {
        isEnabled() ? cancel(exitFullscreen) : enable(enterFullscreen);
    }

    function isSupported() {
        const element = document.documentElement;
        return (
            element.requestFullScreen ||
            element.mozRequestFullScreen ||
            element.webkitRequestFullscreen ||
            element.msRequestFullscreen
        );
    }
}
