import _ from "lodash";

export default function ($q, $filter, SbStructureNode, $sbStructureNodesApi) {
    "ngInject";

    // this cache is used to cache information as long as no page change occurred
    //
    let requestCache = {};

    ////////////////////////////////////
    //
    //      API
    //
    ////////////////////////////////////

    return {
        list: getList,
        tree: getTree,
        node: getNode,
        asMap: asMap,
        clearCache: clearCache,
        activateNode: activateNode,
        getSortedMappedList: getSortedMappedList,
    };

    ////////////////////////////////////
    //
    //      IMPL
    //
    ////////////////////////////////////

    function getTree(projectId, childrenPropertyName = "CHILDREN") {
        return getList(projectId).then(
            _buildTreeFromList(childrenPropertyName)
        );
    }

    function getNode(projectId, id) {
        return getList(projectId).then((nodes) => _.find(nodes, ["ID", id]));
    }

    function getList(projectId) {
        if (projectId) {
            if (!_isCached(projectId)) {
                const loadStructurePromise = $sbStructureNodesApi
                    .getCollection(projectId)
                    .then(({ structure_nodes }) => structure_nodes)
                    .then(_parse)
                    .then(_buildPathFromList)
                    .then(_sortNaturally);

                _storeInCache(projectId, loadStructurePromise);
            }
            return _getFromCache(projectId).then((nodes) => {
                // give out a copy of the data so stored values are not altered
                //
                return _.cloneDeep(nodes);
            });
        } else {
            return $q.reject(new Error("MissingRequiredProperty: projectId"));
        }
    }

    function asMap(projectId) {
        return getList(projectId).then((nodes) => {
            return new Map(nodes.map((item) => [item.ID, toDomain(item)]));
        });
    }

    function toDomain(dbNode) {
        return new SbStructureNode(
            dbNode.ID,
            dbNode.NAME,
            dbNode.CODE,
            dbNode.PARENT_PATH
        );
    }

    function activateNode(item) {
        if (!item || !item.ID) {
            return $q.reject(new Error("PROPER ITEM REQUIRED FOR CALL"));
        } else {
            return $sbStructureNodesApi.update(item.PROJECT_ID, item.ID, {
                code: item.CODE,
                name: item.NAME,
            });
        }
    }

    /**
     * Loads the list and does a natural sorting and then maps the result,
     * including the readable path
     * @param {String} projectId - Project Id
     * @returns {*}
     */
    function getSortedMappedList(projectId) {
        return getList(projectId).then(function (sortedNodes) {
            return sortedNodes.map(function (sNode) {
                return {
                    id: sNode.ID,
                    name: sNode.NAME,
                    label: sNode.PARENT_PATH.join(" / "),
                    code: sNode.CODE,
                    content: JSON.stringify([2, sNode.ID]),
                    isLeaf: sNode.LEFT_TREE_SPAN + 1 === sNode.RIGHT_TREE_SPAN,
                };
            });
        });
    }

    function _parse(entity) {
        if (_.isArray(entity)) {
            return entity.map(_parse);
        }
        return {
            ID: entity.id,
            PARENT_ID: entity.parent_id,
            PROJECT_ID: entity.project_id,
            NAME: entity.name,
            CODE: entity.code,
            LEFT_TREE_SPAN: entity.left_tree_span,
            RIGHT_TREE_SPAN: entity.right_tree_span,
            PROJECT_SOURCE_PSEUDO_KEY: entity.project_source_id,
            EXTERNAL_ID: entity.external_id,
        };
    }

    function _buildTreeFromList(childPropertyKey) {
        return function (nodes) {
            const roots = [];
            const idToNodeRegistry = {};

            nodes.forEach(function buildRegistry(node) {
                idToNodeRegistry[node.ID] = node;
                if (!node.PARENT_ID) {
                    roots.push(node);
                }
            });

            nodes.forEach(function addToParent(node) {
                // get parent
                const parent = idToNodeRegistry[node.PARENT_ID];
                if (parent) {
                    if (!parent[childPropertyKey]) {
                        parent[childPropertyKey] = [];
                    }
                    parent[childPropertyKey].push(node);
                }
            });

            return roots;
        };
    }

    function _sortNaturally(structureNodes) {
        const naturalSort = $filter("naturalSort");
        return naturalSort(structureNodes, "CODE");
    }

    function clearCache() {
        requestCache = {};
    }

    function _isCached(projectId) {
        return requestCache[projectId] !== undefined;
    }

    function _getFromCache(projectId) {
        return requestCache[projectId];
    }

    function _storeInCache(projectId, fetchPromise) {
        return (requestCache[projectId] = fetchPromise);
    }

    /**
     * Given a list of nodes with ID, PARENT_ID and NAME. It's adding a new property
     * "PARENT_PATH" to each node representing the path from itself to the root.
     *
     * Like: Node 12 / Building 2 / Area AE
     *
     * @param {Array} nodes
     * @returns {Array} the same nodes that are given into the function but with the additional property.
     * @private
     */
    function _buildPathFromList(nodes) {
        const queue = [];

        const nodesMap = nodes.reduce(function (map, node) {
            // create the map
            map[node.ID] = node;
            // add the additional property for the path and prepare the "recursive" calculation
            node.PARENT_PATH = [node.NAME];
            if (node.PARENT_ID) {
                node.PARENT_PATH.push(node.PARENT_ID);
                queue.push(node);
            }
            return map;
        }, {});

        // prepare "recursion"
        let nodeFromQueue = queue.pop();
        while (nodeFromQueue) {
            const parentIdOfPath = nodeFromQueue.PARENT_PATH.pop();
            const parentNode = nodesMap[parentIdOfPath];

            if (parentIdOfPath && parentNode) {
                nodeFromQueue.PARENT_PATH.push(parentNode.NAME);
                nodeFromQueue.PARENT_PATH.push(parentNode.PARENT_ID);
                queue.push(nodeFromQueue);
            }
            nodeFromQueue = queue.pop();
        }

        nodes.forEach(function (node) {
            node.PARENT_PATH = node.PARENT_PATH.reverse();
        });

        return nodes;
    }
}
