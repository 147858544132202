import angular from "angular";
import _ from "lodash";
import html from "./filter_sidenav.html";

export default angular
    .module("sbApp.common.components.filterSidenav", [])
    .component("sbFilterSidenav", {
        templateUrl: html,
        transclude: true,
        bindings: {
            activeFilterCount: "<",
        },
        controller: function FilterSidenavCtrl($mdSidenav) {
            "ngInject";
            var vm = this;

            vm.toggleFilterSidenav = toggleFilterSidenav;
            vm.isOpen = isOpen;

            var FILTER_SIDENAV = "sb-filter-sidenav";

            function toggleFilterSidenav() {
                if (isOpen()) {
                    $mdSidenav(FILTER_SIDENAV).close();
                } else {
                    $mdSidenav(FILTER_SIDENAV).open();
                }
            }

            function isOpen() {
                return $mdSidenav(FILTER_SIDENAV).isOpen();
            }
        },
    });
