import _ from "lodash";

export default function (
    EVENTS,
    $q,
    $rootScope,
    $sbDeliverablesApi,
    $sbDeliverableJobsApi
) {
    "ngInject";
    var DELIVERABLE_CHANGE_TYPES = {
        BASELINE: "baseline",
        PROGRESS: "progress",
        STRUCTURE: "structure",
    };

    var service = {
        DELIVERABLE_CHANGE_TYPES: DELIVERABLE_CHANGE_TYPES,
        createChangeWatcherFor: createChangeWatcherFor,
        emitChangeEvent: emitChangeEvent,
        createDeliverable: createDeliverable,
        removeDeliverables: removeDeliverables,
    };

    return service;

    /**
     *
     * @param {Array} types
     * @param {function} callback
     * @return {*}
     */
    function createChangeWatcherFor(types, callback) {
        return $rootScope.$on(
            EVENTS.DELIVERABLE__DATA_CHANGED,
            function ($event, deliverable, type) {
                if (_.isArray(types) && types.indexOf(type) > -1) {
                    callback($event, deliverable, type);
                }
            }
        );
    }

    function emitChangeEvent(type, deliverable) {
        if (deliverable) {
            var deliverableId = deliverable.id || deliverable.ID;

            $rootScope.$emit(
                EVENTS.DELIVERABLE__DATA_CHANGED,
                {
                    id: deliverableId,
                },
                type
            );
        }
    }

    /**
     * Creates a deliverable with name, code and description
     * @param item
     * @param projectId
     * @returns {*}
     */
    function createDeliverable(item, projectId) {
        if (!item || !item.NAME || !item.CODE || !projectId) {
            return $q.reject(new Error("PROPER PARAMETERS REQUIRED FOR CALL"));
        } else {
            return $sbDeliverablesApi
                .create(projectId, {
                    code: item.CODE,
                    name: item.NAME,
                    description: item.DESC,
                })
                .then(({ code, name }) => {
                    // map to old interface
                    return {
                        CODE: code,
                        NAME: name,
                    };
                });
        }
    }

    function removeDeliverables(deliverables, projectId) {
        if (angular.isArray(deliverables)) {
            const deliverable_set = deliverables.map(function (deliverable) {
                return deliverable.id;
            });
            return $sbDeliverableJobsApi
                .createRemoveJob(projectId, {
                    deliverable_set,
                })
                .then(({ response }) => response);
        }

        if (angular.isString(deliverables)) {
            return $sbDeliverableJobsApi
                .createRemoveJob(projectId, {
                    deliverable_set: deliverables,
                })
                .then(({ response }) => response);
        }

        return $q.reject(
            new Error(deliverables + " has to be an array or filter query.")
        );
    }
}
