import _ from "lodash";
import xmlEscape from "./xml-escape.service";

import graphmlDocumentTemplate from "./graphMlDocument.xml";
import graphmlNodeTemplate from "./graphMlNode.xml";
import graphmlEdgeTemplate from "./graphMlEdge.xml";

export default function GraphMlGenerator() {
    const graphmlDocument = _.template(graphmlDocumentTemplate);
    const graphmlNode = _.template(graphmlNodeTemplate);
    const graphmlEdge = _.template(graphmlEdgeTemplate);

    const service = {
        toGraphMlDocument: toGraphMlDocument,
    };

    return service;

    function toGraphMlDocument(templateId, data) {
        const allActivities = data.getAllActivities() || [];
        const allDependencies = data.getAllDependencies() || [];

        return getGraphMlDocument(templateId, {
            nodes: activitiesToGraphMl(allActivities),
            edges: dependenciesToGraphMl(allDependencies),
        });
    }

    function getGraphMlDocument(graphId, data) {
        const nodes = data.nodes || [];
        const edges = data.edges || [];

        return graphmlDocument({
            graphId: graphId,
            nodes: nodes,
            edges: edges,
        });
    }

    function activitiesToGraphMl(activities) {
        return activities
            .map(function (activity) {
                return {
                    id: activity.id,
                    name: xmlEscape(activity.name),
                    color: activity.color,
                };
            })
            .map(renderNode)
            .join("\n");
    }

    function dependenciesToGraphMl(dependencies) {
        return dependencies
            .map(function (dependency) {
                return {
                    source: dependency.source,
                    target: dependency.target,
                };
            })
            .map(renderEdge)
            .join("\n");
    }

    function renderEdge(dependency) {
        return graphmlEdge({
            dependency: dependency,
        });
    }

    function renderNode(activity) {
        return graphmlNode({
            activity: activity,
        });
    }
}
