export default class ImagesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
        this.CREATED_NOTE = "created_note";
        this.CLOSED_ISSUE = "closed_issue";
        this.MODIFIED_DELIVERABLE = "modified_deliverable";
    }

    /**
     * This endpoint returns a collection of inspections scoped to a specific project.
     *
     * @param projectId {string}    UUID
     * @param lastUpdatedAfter {string}    A query to filter all inspection which are last updated after and including this datetime.
     * @param lastUpdatedBefore {string}    A query to filter all inspection which are last updated after and including this datetime.
     * @param reporter {string}    A query to filter for all inspections reported by a certain user.
     * @param skip {number}   Exclude from a response the first N items of a resource collection.
     * @param top {number}    Include in a response only the first N items of a resource collection.
     */
    getCollection(
        projectId,
        { lastUpdatedAfter, lastUpdatedBefore, reporter, skip, top }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/inspections`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
            query: {
                last_updated_after: lastUpdatedAfter,
                last_updated_before: lastUpdatedBefore,
                reporter: reporter,
                skip: skip,
                top: top,
            },
        });
    }

    /**
     * This endpoint returns a specific inspection by their given key.
     *
     * @param projectId {string}    UUID
     * @param inspectionId {string}    UUID.
     */
    get(projectId, inspectionId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/inspections/${inspectionId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }
}
