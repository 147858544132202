export default function ($q, $translate) {
    "ngInject";

    // Regex to validate passwords as the server expects them
    // - from stackoverflow http://stackoverflow.com/a/1559788
    //
    const PASSWORD_POLICY_LAYOUT = {
        a: {
            langKey: "ERROR_MIN_ONE_LOWERCASE_LETTER_TITLE",
            regExp: "(?=.*[a-z])",
        },
        A: {
            langKey: "ERROR_MIN_ONE_UPPERCASE_LETTER_TITLE",
            regExp: "(?=.*[A-Z])",
        },
        1: {
            langKey: "ERROR_MIN_ONE_NUMBER_TITLE",
            regExp: "(?=.*[0-9])",
        },
    };

    const passwordPolicy = {
        minlength: 8,
        layout: ["A", "a", "1"],
        regexPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
    };

    return {
        getPasswordErrorTranslated: getPasswordErrorTranslated,
        getPolicy: getPolicy,
    };

    function getPolicy() {
        return $q.resolve(passwordPolicy);
    }

    /**
     * Test the password vs the policy and return all failed tests in a translated array of messages.
     *
     * @param {string} password - Password to be tested
     * @param {Object} police - Police where the Password is tested on
     * @returns {Promise<string>} promise with translated errors
     * @private
     */
    function getPasswordErrorTranslated(password, police) {
        const errors = police.layout
            .map(function (key) {
                return PASSWORD_POLICY_LAYOUT[key];
            })
            .filter(function (layout) {
                return !_buildRegExpForPassword(layout.regExp).test(password);
            })
            .map(function (layout) {
                return layout.langKey;
            })
            .map(function (langKey) {
                return $translate(langKey);
            });

        return $q.all(errors).then(function (translatedErrors) {
            return translatedErrors;
        });
    }

    /**
     * Build a regular expression from a string
     *
     * @param {string} regexp - String that represents regular Expression
     * @returns {RegExp}
     * @private
     */
    function _buildRegExpForPassword(regexp) {
        return new RegExp("^" + regexp + ".+$");
    }
}
