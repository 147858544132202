import Hammer from "hammer";
import RetinaStage from "../common/RetinaStage";
import Edge from "../edge/Edge";
import StructureNode from "../node/StructureNode";
import SettingsChangedEvent from "../events/SettingsChangedEvent";
import MouseInEvent from "../events/MouseInEvent";
import MouseOutEvent from "../events/MouseOutEvent";
import FocusEvent from "../events/FocusEvent";
import BlurEvent from "../events/BlurEvent";
/**
 * Root Level Container on which all Elements are displayed
 *
 * @constructs EditorStage
 * @extends WBS.RetinaStage
 * @memberof WBS
 */
function EditorStage() {
    // call constructor of parent class to set context and arguments
    //
    RetinaStage.apply(this, arguments);

    this.onTap = this._onTap.bind(this);
    this.onMouseMove = this._onMouseMove.bind(this);
    this.onMouseOut = this._onMouseOut.bind(this);
    this.onSettingsChanged = this._onSettingsChanged.bind(this);

    this.mousePosition = new createjs.Point(0, 0);

    // create a new hammer manager for more controlling and to be able to listen on pinch events
    //
    this.hammertime = new Hammer.Manager(this.canvas);

    var pinch = new Hammer.Pinch();
    var pan = new Hammer.Pan({
        threshold: 0,
    });
    var tap = new Hammer.Tap({
        pointers: 1,
        taps: 1,
        time: 250,
        threshold: 2,
    });

    this.hammertime.add([pan, pinch, tap]);
    this.hammertime.on("tap", this.onTap);

    this.on("settingsChanged", this.onSettingsChanged);

    this.canvas.addEventListener("mousemove", this.onMouseMove);

    this.canvas.addEventListener("mouseout", this.onMouseOut);

    this.changeSettings(this.defaultSettings);
    this.i18n = {};
}

EditorStage.prototype = Object.create(RetinaStage.prototype);

/**
 * Element the mouse is over
 * @type {WBS.StructureNode|WBS.Edge|null}
 */
EditorStage.prototype.crtMouseOverElement = null;

/**
 * Node that is selected
 * @type {WBS.StructureNode|null}
 */
EditorStage.prototype.crtSelectedNode = null;

/**
 * Edge that is selected
 * @type {WBS.Edge|null}
 */
EditorStage.prototype.crtSelectedEdge = null;

/**
 * Element that is Hovered
 * @type {WBS.StructureNode|WBS.Edge|null}
 */
EditorStage.prototype.crtFocusedElement = null;

/**
 *
 * @type {boolean}
 */
EditorStage.prototype.mouseMoved = false;

/**
 *
 * @type {boolean}
 */
EditorStage.prototype.mouseOnStage = false;

/**
 *
 * @type {Object}
 */
EditorStage.prototype.currentSettings = {};

/**
 * @default
 * @type {{hoverEnabled: boolean, selectEnabled: boolean, assignComponents: boolean, showEdges: boolean, modifyEdges: boolean, modifyNodes: boolean, modifyNodeDetails: boolean}}
 */
EditorStage.prototype.defaultSettings = {
    hoverEnabled: true,
    selectEnabled: false,
    assignComponents: false,
    showEdges: false,
    modifyEdges: false,
    modifyNodes: false,
    modifyNodeDetails: false,
};

/**
 * @default
 * @type {{hoverEnabled: boolean, selectEnabled: boolean, assignComponents: boolean, showEdges: boolean, modifyEdges: boolean, modifyNodes: boolean, modifyNodeDetails: boolean}}
 */
EditorStage.prototype.editModeSettings = {
    hoverEnabled: true,
    selectEnabled: true,
    assignComponents: false,
    showEdges: false,
    modifyEdges: false,
    modifyNodes: true,
    modifyNodeDetails: true,
};

/**
 * Called on every tick
 */
EditorStage.prototype.update = function () {
    if (this.currentSettings.hoverEnabled && this.mouseMoved) {
        this.updateMouse();
        this.mouseMoved = false;
    }

    RetinaStage.prototype.update.call(this);
};

/**
 * Dispatches Event when an Element is tapped
 * @param {WBS.StructureNode|WBS.Edge} element - Element which is tapped
 */
EditorStage.prototype.tapElement = function (element) {
    element.dispatchEvent("tap");
    this.setNeedsDisplay();
};

/**
 * Dispatches Event when mouse is over element
 * @param {WBS.StructureNode|WBS.Edge} element - Element which the mouse is over
 * @returns {boolean|undefined}
 */
EditorStage.prototype.mouseInElement = function (element) {
    if (!this.currentSettings.hoverEnabled) {
        return false;
    }
    this.mouseOutCrtMouseOverElement();
    this.crtMouseOverElement = element;
    element.dispatchEvent(new MouseInEvent());
    this.setNeedsDisplay();
};

/**
 * Dispatches Event when Mouse leaves Element
 * @param {WBS.StructureNode|WBS.Edge} element - Element which the mouse left
 */
EditorStage.prototype.mouseOutElement = function (element) {
    element.dispatchEvent(new MouseOutEvent());
    this.setNeedsDisplay();
};

/**
 * Dispatches Event when Mouse leaves current Mouse over Element
 */
EditorStage.prototype.mouseOutCrtMouseOverElement = function () {
    if (this.crtMouseOverElement) {
        var element = this.crtMouseOverElement;
        this.crtMouseOverElement = null;
        this.mouseOutElement(element);
    }
};

/**
 * Setter for i18n Model
 * @param {Object} i18nModel - new i18n Model
 */
EditorStage.prototype.setI18n = function (i18nModel) {
    this.i18n = i18nModel;
};

/**
 * Get i18n Text for given Key
 * @param {string} key - Key for i18n
 * @returns {string}
 */
EditorStage.prototype.getI18nFor = function (key) {
    var text = this.i18n[key];
    if (text !== undefined) {
        return text;
    } else {
        return key;
    }
};

/**
 * Selects a Node (selectableElement) if selection is enabled
 * @param {WBS.SelectableElement|WBS.StructureNode} selectableElement - Element to be selected
 * @return {boolean|undefined}
 */
EditorStage.prototype.selectNode = function (selectableElement) {
    if (!this.currentSettings.selectEnabled) {
        return false;
    }
    this.deselectCrtSelectedNode();
    this.deselectCrtSelectedEdge();
    this.crtSelectedNode = selectableElement;
    selectableElement.dispatchEvent("selected");
    this.setNeedsDisplay();
};

/**
 * Selects an Edge (selectableElement) if selection is enabled
 * @param {WBS.SelectableElement|WBS.Edge} selectableElement - Element to be selected
 * @return {boolean|undefined}
 */
EditorStage.prototype.selectEdge = function (selectableElement) {
    if (
        !this.currentSettings.selectEnabled ||
        !this.currentSettings.modifyEdges
    ) {
        return false;
    }
    this.deselectCrtSelectedEdge();
    this.crtSelectedEdge = selectableElement;
    selectableElement.dispatchEvent("selected");
    this.setNeedsDisplay();
};

/**
 * Deselect the current selected node
 */
EditorStage.prototype.deselectCrtSelectedNode = function () {
    if (this.crtSelectedNode) {
        this.deselectElement(this.crtSelectedNode);
        this.crtSelectedNode = null;
    }
};

/**
 * Deselect the current selected edge
 */
EditorStage.prototype.deselectCrtSelectedEdge = function () {
    if (this.crtSelectedEdge) {
        this.deselectElement(this.crtSelectedEdge);
        this.crtSelectedEdge = null;
    }
};

/**
 * Deselect selectableElement
 * @param {WBS.SelectableElement} selectableElement - Element to be deselected
 */
EditorStage.prototype.deselectElement = function (selectableElement) {
    selectableElement.dispatchEvent("deselected");
    this.setNeedsDisplay();
};

/**
 * Select the selectableElement
 * @param {WBS.EditableElement} editableElement - Element to be focussed
 */
EditorStage.prototype.focusElement = function (editableElement) {
    if (
        !this.currentSettings.modifyNodeDetails ||
        editableElement.parent.isProjectNode
    ) {
        return;
    }

    this.blurCrtFocusedElement();
    this.crtFocusedElement = editableElement;
    editableElement.dispatchEvent(new FocusEvent());
    this.setNeedsDisplay();
};

/**
 * Deselect the current selected element
 */
EditorStage.prototype.blurCrtFocusedElement = function () {
    if (this.crtFocusedElement) {
        this.blurElement(this.crtFocusedElement);
        this.crtFocusedElement = null;
    }
};

/**
 * Deselect selectableElement
 * @param {EditableElement} editableElement - Element to be defocused
 */
EditorStage.prototype.blurElement = function (editableElement) {
    editableElement.dispatchEvent(new BlurEvent());
    this.setNeedsDisplay();
};

/**
 * calculates the position of the pointer
 * @param {createjs.Event} event  contains information of the triggered event
 * @returns {createjs.Point}
 * @private
 */
EditorStage.prototype.getPointerPosition = function (event) {
    var pos = new createjs.Point(
        event.clientX || (event.center && event.center.x),
        event.clientY || (event.center && event.center.y)
    );
    return this.clientToStagePosition(pos);
};

/**
 * Convert "global" mouse position to relative position on stage
 * @param {createjs.Point} position - Globale Mouse position
 * @returns {createjs.Point}
 */
EditorStage.prototype.clientToStagePosition = function (position) {
    var boundingRect = this.canvas.getBoundingClientRect();

    position.x -= boundingRect.left;
    position.y -= boundingRect.top;
    return position;
};

/**
 * Helper method to get the element which lay under the give coordinates.
 * Checks if element has a direct parent, if so the highest parent will
 * be returned if not the element will be returned.
 * @param {number} x - x-Coordinate used for checking which elements lies under it
 * @param {number} y - y-Coordinate used for checking which elements lies under it
 * @returns {easeljs.DisplayObject|*} the element which lays under the given coordinates
 */
EditorStage.prototype.getTapableOrSelectableElementUnderPoint = function (
    x,
    y
) {
    var elem = this.stage.getObjectUnderPoint(x, y);

    while (elem && elem.tapable !== true && elem.selectable !== true) {
        elem = elem.parent;
    }
    return elem;
};

/**
 * Helper method to get the element which lay under the give coordinates.
 * Checks if element has a direct parent, if so the highest parent will
 * be returned if not the element will be returned.
 * @param {number} x - x-Coordinate used for checking which elements lies under it
 * @param {number} y - y-Coordinate used for checking which elements lies under it
 * @returns {easeljs.DisplayObject|*} the element which lays under the given coordinates
 */
EditorStage.prototype.getEditableElementUnderPoint = function (x, y) {
    if (!this.crtSelectedNode) {
        return null;
    }
    var position = this.crtSelectedNode.globalToLocal(
        x * this.pixelRatio,
        y * this.pixelRatio
    );
    var elem = this.crtSelectedNode.getObjectUnderPoint(position.x, position.y);

    while (elem && elem.editable !== true) {
        elem = elem.parent;
    }
    return elem;
};

/**
 * Helper method to get the element which lay under the give coordinates.
 * Checks if element has a direct parent, if so the highest parent will
 * be returned if not the element will be returned.
 * @param {number} x - x-Coordinate used for checking which elements lies under it
 * @param {number} y - y-Coordinate used for checking which elements lies under it
 * @returns {easeljs.DisplayObject|*} the element which lays under the given coordinates
 */
EditorStage.prototype.getHoverableElementUnderPoint = function (x, y) {
    var element = this.stage.getObjectUnderPoint(x, y);

    while (element && element.hoverable !== true) {
        element = element.parent;
    }
    return element;
};

/**
 * called every time you tap on the stage from hammerjs
 * @param {easeljs.Event} event - Event dispatched on Tap
 * @private
 */
EditorStage.prototype._onTap = function (event) {
    if (document.activeElement) {
        document.activeElement.blur();
    }
    if (event.tapCount === 1) {
        this.onSingleTap(event);
    } else if (event.tapCount === 2) {
        this.onDoubleTap(event);
    }
};

/**
 * Handles Single Tap on Element
 * @param {(WBS.TapableElement|WBS.SelectableElement)} element - Element taped on
 * @returns {boolean}
 */
EditorStage.prototype.onSingleTapOnElement = function (element) {
    if (element.tapable) {
        this.tapElement(element);
        return true;
    } else if (element.selectable) {
        if (element instanceof Edge && element !== this.crtSelectedEdge) {
            this.selectEdge(element);
        } else if (
            element instanceof StructureNode &&
            element !== this.crtSelectedNode
        ) {
            this.selectNode(element);
        }

        return true;
    }
    return false;
};

/**
 * Handles tap on background (or no tapable element)
 * @returns {boolean}
 */
EditorStage.prototype.onSingleTapOnNull = function () {
    this.deselectCrtSelectedNode();
    this.deselectCrtSelectedEdge();
    return true;
};

/**
 * called every time you tap on the stage with tapCount === 1
 * @param {createjs.Event} event - TapEvent
 */
EditorStage.prototype.onSingleTap = function (event) {
    //disabled if not in editor mode

    this.blurCrtFocusedElement();

    var position = this.getPointerPosition(event);

    var tapableOrSelectable = this.getTapableOrSelectableElementUnderPoint(
        position.x,
        position.y
    );
    if (tapableOrSelectable) {
        this.onSingleTapOnElement(tapableOrSelectable);
    } else {
        this.onSingleTapOnNull();
    }
};

/**
 * called every time you tap on the stage with tapCount === 2
 * @param {createjs.Event} event - TapEvent
 * @return {boolean|undefined}
 */
EditorStage.prototype.onDoubleTap = function (event) {
    //disabled if not in editor mode
    if (!this.currentSettings.selectEnabled) {
        return false;
    }

    var position = this.getPointerPosition(event);

    var editableElement = this.getEditableElementUnderPoint(
        position.x,
        position.y
    );
    if (editableElement) {
        if (editableElement !== this.crtFocusedElement) {
            this.focusElement(editableElement);
        }
    } else {
        this.blurCrtFocusedElement();
    }
};

/**
 * Handles mouseMove on Stage
 * @param {createjs.Event} event - MouseMove event
 * @private
 */
EditorStage.prototype._onMouseMove = function (event) {
    this.mouseOnStage = true;
    this.mouseMoved = true;
    this.mousePosition.setValues(event.pageX, event.pageY);
};

/**
 * Handles MouseLeave of Stage
 * @private
 */
EditorStage.prototype._onMouseOut = function () {
    this.mouseOnStage = false;
    this.mouseMoved = true;
};

/**
 * called every frame if the mouse moved since last frame
 */
EditorStage.prototype.updateMouse = function () {
    if (!this.mouseOnStage) {
        this.mouseOutCrtMouseOverElement();
    } else {
        var pos = this.clientToStagePosition(this.mousePosition);
        var element = this.getHoverableElementUnderPoint(pos.x, pos.y);

        //if no element found remove the mouseOver from the current element
        if (!element) {
            this.mouseOutCrtMouseOverElement();
        } else {
            //if the mouse ist crt not over this element mouse in the element
            if (element !== this.crtMouseOverElement) {
                this.mouseInElement(element);
            }
        }
    }
};

/**
 * Enables Editing Mode
 */
EditorStage.prototype.enableEditorMode = function () {
    this.changeSettings(this.editModeSettings);
};

/**
 * Disables Editing Mode
 */
EditorStage.prototype.disableEditorMode = function () {
    this.changeSettings(this.defaultSettings);
};

/**
 * Change Settings to new Settings
 * @param {Object} newSettings - New Settings for Stage
 */
EditorStage.prototype.changeSettings = function (newSettings) {
    var oldSettings = this.currentSettings;
    this.currentSettings = newSettings;
    var settingsChangedEvent = new SettingsChangedEvent(
        newSettings,
        oldSettings
    );
    this.dispatchEvent(settingsChangedEvent);
};

/**
 * Handles Change of Settings
 * @param {WBS.SettingsChangedEvent} settingsChangedEvent - Event dispatched on Setting Cange
 * @private
 */
EditorStage.prototype._onSettingsChanged = function (settingsChangedEvent) {
    if (!settingsChangedEvent.isEnabledAndHasChanged("selectEnabled")) {
        this.blurCrtFocusedElement();
        this.deselectCrtSelectedNode();
    }
};

export default EditorStage;
