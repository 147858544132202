import LeanBoardModelMapper from "./../services/lean_board_model.mapper";

// INFO: this class is intended to be consumed only in the activity details panel
class LeanboardSchedule {
    constructor() {
        // baseline (earliest > latest)
        this.baseline = {
            start: null,
            end: null,
            duration: null,
            durationUnit: "wd",
        };

        // panel top part will show those for modification
        //  should show UNSET if no look ahead was ever provided
        //  should show "dirty" state of active session
        //  should show latest look ahead otherwise
        //  should not be mixed with forecast
        this.sessionLookAhead = {
            start: null,
            end: null,
            duration: null,
        };

        // panel top part will show "dirty" indicator if published != session
        //  should show UNSET if no look ahead was ever provided
        //  should show latest look ahead otherwise
        this.publishedLookAhead = {
            start: null,
            end: null,
            duration: null,
        };

        // forecasted dates
        this.forecast = {
            start: null,
            end: null,
        };

        // actual
        this.actual = {
            started: null, // activity.schedule.actual.started
            finished: null, // activity.schedule.actual.finished
            confirmed: null, // activity.schedule.actual.confirmed
        };
    }

    hasChangedInCurrentSession() {
        const hasStartDateChanged = LeanBoardModelMapper.isDifferent(
            this.publishedLookAhead.start,
            this.sessionLookAhead.start
        );
        const hasEndDateChanged = LeanBoardModelMapper.isDifferent(
            this.publishedLookAhead.end,
            this.sessionLookAhead.end
        );

        return hasStartDateChanged || hasEndDateChanged;
    }
}

export default LeanboardSchedule;
