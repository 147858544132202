import _ from "lodash";

export default function ($q, $filter) {
    "ngInject";
    var naturalSort = $filter("naturalSort");

    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        reduceNumbersToRanges: reduceNumbersToRanges,
        buildComponentsFromAssignment: buildComponentsFromAssignment,
        asStructureList: asStructureList,
        makeString: makeString,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function makeString(value) {
        if (typeof value === "number" || typeof value === "string") {
            return value.toString();
        }
        return undefined;
    }

    /**
     * Compute ranges based on a series of numbers
     *
     * @param numbers
     * @param isSorted
     * @returns {Array}
     */
    function reduceNumbersToRanges(numbers, isSorted) {
        var ranges = [];
        if (numbers.length <= 0) return ranges;
        if (!isSorted) {
            numbers = numbers.sort();
        }

        function addRange(start, end) {
            if (start === end) {
                ranges.push(start);
            } else {
                ranges.push(start + "-" + end);
            }
        }

        var startNumber = numbers[0];
        var lastNumber = startNumber;

        for (var i = 1; i < numbers.length; i++) {
            var currentNumber = numbers[i];
            var dif = currentNumber - lastNumber;
            if (dif <= 1) {
                lastNumber = currentNumber;
            } else {
                addRange(startNumber, lastNumber);
                startNumber = lastNumber = currentNumber;
            }
        }

        addRange(startNumber, lastNumber);

        return ranges;
    }

    /**
     * Do everything that is related to structure
     *  # extract the structure names from excel raw data
     *  # check if there are invalid structure routes for components
     *  # append the structure to the components
     *  # create a preview structure model (tree structure)
     *   # generate codes 1.3.4.5 for the structure preview
     *
     * @return Promise
     */
    function buildComponentsFromAssignment(header, crtExcelData, levels) {
        var invalidStructureRows = [];

        // collect the table columns that are used for structure
        //
        var levelFields = levels
            .filter(function (level) {
                return crtExcelData.headers.indexOf(level.value) !== -1;
            })
            .map(function (level) {
                return crtExcelData.headers.indexOf(level.value);
            });

        var data = crtExcelData.data.map(
            function parseComponents(component, index) {
                // structure path as array
                //
                const structure = levelFields.map((levelField) =>
                    makeString(component[levelField])
                );

                // clean up (remove trailing 'null's) and mark 'null's in between as invalid structure
                //
                for (var i = structure.length - 1; i >= 0; i--) {
                    var structureNode = structure[i];
                    //if its empty
                    if (!structureNode) {
                        //and is the last element
                        if (structure.length - 1 === i) {
                            //remove the last element
                            structure.pop();
                        } else {
                            //its somewhere inside it is invalid
                            invalidStructureRows.push(index + 1);
                        }
                    }
                }

                var c = {
                    structure: structure,
                };

                // for all the headers -> map header column to header value.
                //
                return header.reduce(function parseObjectKeys(
                    componentMap,
                    header
                ) {
                    if (header.formatter) {
                        componentMap[header.as] = header.formatter(
                            component[header.dataField] ||
                                component[header.field]
                        );
                    } else {
                        componentMap[header.as] =
                            component[header.dataField] ||
                            component[header.field];
                    }
                    return componentMap;
                }, c);
            }
        );

        // exit here before continue.. because of invalid structure
        //
        if (invalidStructureRows.length > 0) {
            return $q.reject(invalidStructureRows);
        } else {
            return $q.resolve(data);
        }
    }

    function asStructureList(componentStructure) {
        var structuringNodes =
            findAllStructuringPathsInComponents(componentStructure);
        var structuringNodesMap = structuringNodes.entities;
        var structuringNodeList = structuringNodes.allList;

        // now we need codes based on the structure path -> like 1.2.3.4.5.6.x.y.z
        // where each structure level is sorted by name
        //
        sortKeysByNameAndSetNodeCode(structuringNodesMap, null);

        return structuringNodeList;
    }

    /**
     * Generate the code of the structuring nodes based on a sorted list of available names
     * in the specific structure.
     *
     *  Input map -> E.G.:
     *    {
     *      __node: { name: "House 1", ext: "somehtisng", parentExt: "foo"},
     *      Level1: {
     *          __node: {name: "Level1", ext: "lvel1Exteg", parentExt: "somehtisng"}
     *      },
     *      Level2: {
     *          __node: {name: "Level2", ext: "lvel2Exteg", parentExt: "somehtisng"}
     *      }
     *    }
     *
     *  Result: House 1 -> "1
     *            Level 1 -> "1.1"
     *            Level 2 -> "1.2"
     *
     * @param {string: {name: string, ext: string, __parent}} map
     * @param prefix
     */
    function sortKeysByNameAndSetNodeCode(map, prefix) {
        _.chain(Object.keys(map))
            .filter(function excludeObjectsThatAreNotNodes(key) {
                return key !== "__node";
            })
            .thru(function (items) {
                return naturalSort(items);
            })
            .forEach(function setIndexAsValueOfKey(key, index) {
                map[key].__node.code = prefix
                    ? prefix + "." + (index + 1)
                    : (index + 1).toString(); // codes should start with 1
                sortKeysByNameAndSetNodeCode(map[key], map[key].__node.code);
            })
            .value();
    }

    /**
     * Difficult stuff here.. TODO needs explanations.. TODO needs a class representation
     *
     * @param componentStructure
     * @returns {{entities: {__node: {name: string, ext: string}}, allList: Array}}
     */
    function findAllStructuringPathsInComponents(componentStructure) {
        // idea: create a map.
        //  # each map has a '__node' property that holds a reference to the specific node for this map
        //  # along with the '__node' property there will be strings that represent child nodes.
        //  # every string like 'House 1' will then reference a map that represents itself with children strings again.
        //  # E.G.:
        //    {
        //      __node: { name: "House 1", ext: "somehtisng", parentExt: "foo"},
        //      Level1: {
        //          __node: {name: "Level1", ext: "lvel1Exteg", parentExt: "somehtisng"}
        //      },
        //      Level2: {
        //          __node: {name: "Level2", ext: "lvel2Exteg", parentExt: "somehtisng"}
        //      }
        //    }
        //
        var entities = {
            __node: {
                name: "dummy root",
                ext: "",
            },
        };
        var allList = [];

        // create "keys" for every structure path -> actually a concat of all names
        // and store them in a map with a reference to the array of structure names.
        //
        componentStructure.forEach(function (c) {
            var entityMap = entities;
            var structureExt = null;

            c.structure.forEach(function (s) {
                // if the node doesn't exist yet
                if (!entityMap[s]) {
                    var newNode = {
                        name: s,
                        ext: entityMap.__node.ext + "" + s,
                        parentExt: entityMap.__node.ext,
                        __parent: entityMap.__node,
                    };

                    entityMap[s] = {
                        __node: newNode,
                    };
                    allList.push(newNode);
                }
                entityMap = entityMap[s];
                structureExt = entityMap.__node.ext;
            });

            c.structure = structureExt;
        });

        return {
            entities: entities,
            allList: allList,
        };
    }
}
