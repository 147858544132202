import _ from "lodash";
import Activity from "domain/sb_activity.class";
import Deliverable from "domain/sb_deliverable.class";

function Dependency(source, target) {
    this.source = source;
    this.target = target;
    this.derived = false;
}

/**
 * A network node with all incoming and outgoing dependencies of a central node
 *
 * @param {Object} deliverable
 * @constructor
 */
function DetailedConnection(deliverable) {
    this.center = deliverable;
    this.deliverableDependencies = [];
}

DetailedConnection.prototype.listPredecessors = function () {
    return _(this.deliverableDependencies)
        .filter(["target.id", this.center.id])
        .flatMap(function (dependency) {
            return dependency.activityDependencies;
        })
        .sortBy([
            "target.topologicalIndex",
            "source.topologicalIndex",
            "parent.source.name",
        ])
        .value();
};

DetailedConnection.prototype.listSuccessors = function () {
    return _(this.deliverableDependencies)
        .filter(["source.id", this.center.id])
        .flatMap(function (dependency) {
            return dependency.activityDependencies;
        })
        .sortBy([
            "source.topologicalIndex",
            "target.topologicalIndex",
            "parent.target.name",
        ])
        .value();
};

/**
 *  Add a dependency to the connection maintaining deliverable and activity
 *  dependencies.
 *
 * @param {Object} source
 * @param {string} source.activityId
 * @param {string} source.activityName
 * @param {number} source.activityIndex
 * @param {string} source.deliverableId
 * @param {string} source.deliverableName
 * @param {string} source.structure
 *
 * @param {Object} target - same as source
 * @param derived
 * @return {DetailedConnection}
 */
DetailedConnection.prototype.addDependency = function (
    source,
    target,
    derived
) {
    var deliverableDependency = _.find(
        this.deliverableDependencies,
        function (dependency) {
            var isSourceDeliverableEqual =
                dependency.source.id === source.deliverableId;
            var isTargetDeliverableEqual =
                dependency.target.id === target.deliverableId;
            return isSourceDeliverableEqual && isTargetDeliverableEqual;
        }
    );

    if (!deliverableDependency) {
        var sourceDeliverable = new Deliverable(
            source.deliverableId,
            source.deliverableName
        );
        sourceDeliverable.structure = source.structure;
        var targetDeliverable = new Deliverable(
            target.deliverableId,
            target.deliverableName
        );
        targetDeliverable.structure = target.structure;

        deliverableDependency = new DeliverableDependency(
            sourceDeliverable,
            targetDeliverable
        );
        this.deliverableDependencies.push(deliverableDependency);
    }

    var activityDependencyAlreadyExists = _.some(
        deliverableDependency.getActivityDependencies(),
        function (dependency) {
            var isSourceDeliverableEqual =
                dependency.source.id === source.activityId;
            var isTargetDeliverableEqual =
                dependency.target.id === target.activityId;
            return isSourceDeliverableEqual && isTargetDeliverableEqual;
        }
    );

    if (activityDependencyAlreadyExists) {
        return this;
    }

    var sourceActivity = new Activity(source.activityId, source.activityName);
    sourceActivity.topologicalIndex = source.activityIndex;
    sourceActivity.setCurrentState(source.state);
    var targetActivity = new Activity(target.activityId, target.activityName);
    targetActivity.topologicalIndex = target.activityIndex;
    targetActivity.setCurrentState(target.state);
    var activityDependency = new ActivityDependency(
        sourceActivity,
        targetActivity,
        derived
    );
    deliverableDependency.addDependency(activityDependency);

    return this;
};

DetailedConnection.prototype.removeDependency = function (activityDependency) {
    if (!(activityDependency instanceof ActivityDependency)) {
        throw new TypeError(
            'IllegalArgumentException: The argument is not of type "ActivityDependency".'
        );
    }

    var deliverableDependency = activityDependency.parent;
    var isDependencyExisting =
        this.deliverableDependencies.indexOf(deliverableDependency) > -1;
    var removedDependencies = [];

    if (isDependencyExisting) {
        if (activityDependency.isDerived()) {
            removedDependencies =
                deliverableDependency.getActivityDependencies();
            deliverableDependency.removeAllDependencies();
        } else {
            removedDependencies.push(activityDependency);
            deliverableDependency.removeDependency(activityDependency);
        }

        if (deliverableDependency.getActivityDependencies().length === 0) {
            _.remove(this.deliverableDependencies, deliverableDependency);
        }

        return removedDependencies;
    }
};

/**
 * Dependency between 2 deliverables
 *
 * @param {*} source
 * @param {*} target
 *
 * @constructor
 */
function DeliverableDependency(source, target) {
    Dependency.call(this, source, target);
    this.activityDependencies = [];
}

/**
 *
 * @param {ActivityDependency} dependency
 */
DeliverableDependency.prototype.addDependency = function (dependency) {
    if (!(dependency instanceof ActivityDependency)) {
        throw new TypeError(
            'IllegalArgumentException: The argument is not of type "ActivityDependency".'
        );
    }
    this.activityDependencies.push(dependency);
    dependency.parent = this;
    return this;
};

DeliverableDependency.prototype.removeDependency = function (dependency) {
    if (!(dependency instanceof ActivityDependency)) {
        throw new TypeError(
            'IllegalArgumentException: The argument is not of type "ActivityDependency".'
        );
    }
    _.remove(this.activityDependencies, dependency);
    dependency.parent = undefined;
    return this;
};

DeliverableDependency.prototype.removeAllDependencies = function () {
    this.activityDependencies.forEach(function (dependency) {
        dependency.parent = undefined;
    });
    this.activityDependencies = [];
};

DeliverableDependency.prototype.getActivityDependencies = function () {
    return this.activityDependencies;
};

DeliverableDependency.prototype.getNumberOfActivityDependencies = function () {
    return this.getActivityDependencies().length;
};

/**
 * Dependency between activities.
 *
 * @param {*} source
 * @param {*} target
 *
 * @param {boolean} derivedFromDeliverableDependency
 * @constructor
 */
function ActivityDependency(source, target, derivedFromDeliverableDependency) {
    Dependency.call(this, source, target);
    this._derivedFromDeliverableDependency = derivedFromDeliverableDependency;
}

ActivityDependency.prototype.isDerived = function () {
    return this._derivedFromDeliverableDependency || false;
};

ActivityDependency.prototype.isPartOfDeliverableDependency = function () {
    return this.parent instanceof DeliverableDependency;
};

ActivityDependency.prototype.getNumberOfSiblingDependencies = function () {
    var result = 0;

    if (this.isPartOfDeliverableDependency()) {
        result = this.parent.getNumberOfActivityDependencies() - 1;
    }
    return result;
};

export default {
    ActivityDependency: ActivityDependency,
    DeliverableDependency: DeliverableDependency,
    DetailedConnection: DetailedConnection,
};
