import angular from "angular";
import route from "./user_profile.route";
import ctrl from "./user_profile.controller";
import service from "./services/profile.service";
import changePasswordDialog from "./services/change_password.dialog";
import "moment";
import "angular-ui-router";
import "angular-messages";
import "angular-material";
import "configs/all";
import "common/services/services";
import "common/intercom/intercomModule";
import "common/ui-elements/directives/sbDynamicBoxContainer/sbDynamicBoxContainer";

// add the constants to the module!

export default angular
    .module("sbApp.userProfile", [
        "ui.router",
        "ngMaterial",
        "ngMessages",
        "sbApp.services",
        "sbApp.intercom",
        "sbApp.constants",
        "sbApp.sbDynamicBoxContainer",
    ])
    .config(route)
    .config(changePasswordDialog)
    .factory("profileService", service)
    .controller("UserProfileCtrl", ctrl);
