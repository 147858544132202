import angular from "angular";
import "angular-ui-router";
import "common/services/services";
import "./dialog/addTemplate";
import "common/ui-elements/directives/sbTemplateGroupEdit/sbTemplateGroupEdit";

export default angular
    .module("sbApp.addTemplateDialog", [
        "ui.router",
        "sbApp.services",
        "sbApp.sbAddTemplateDialog",
        "sbApp.sbTemplateGroupEdit",
    ])
    .factory(
        "addTemplateDialog",
        function addTemplateDialogFactory(
            $mdDialog,
            $sbErrorPresenter,
            $sbTemplate
        ) {
            /**
             * Open the dialog for creating a new template
             * @return {Promise}           - The promise of $mdDialog
             */
            function openDialogAdd() {
                return $mdDialog
                    .show(
                        $mdDialog
                            .addTemplate()

                            .clickOutsideToClose(true)
                            .escapeToClose(true)
                    )
                    .then(function (dialogResult) {
                        return $sbTemplate
                            .addTemplate(dialogResult)
                            .catch($sbErrorPresenter.catch);
                    });
            }

            return {
                openDialogAdd: openDialogAdd,
            };
        }
    );
