export default function VerifyEmailCtrl(
    $q,
    $scope,
    $stateParams,
    $sbAuth,
    Analytics
) {
    "ngInject";

    var vm = this;
    vm.hasSuccess = false;
    vm.isLoading = false;

    vm.resendVerificationEmail = resendVerificationEmail;

    var ERRORS = {
        UserNotFoundException: {
            title: "SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TITLE",
            text: "SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TEXT",
        },
        CodeMismatchException: {
            title: "SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TITLE",
            text: "SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TEXT",
        },
        ExpiredCodeException: {
            title: "SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TITLE",
            text: "SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TEXT",
            shouldAllowResend: true,
        },
        NotAuthorizedException: {
            title: "SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TITLE",
            text: "SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TEXT",
        },
        GENERIC_ERROR: {
            title: "SIGNUP_GENERIC_ERROR_TITLE",
            text: "SIGNUP_GENERIC_ERROR_TEXT",
        },
    };

    activate($stateParams.email, $stateParams.verificationCode);

    function activate(username, verificationCode) {
        vm.isLoading = true;
        vm.currentError = null;
        return _confirmSignup(username, verificationCode);
    }

    function _confirmSignup(username, verificationCode) {
        return $sbAuth
            .confirmSignUp(username, verificationCode)
            .then(_handleSuccess)
            .catch(_handleError)
            .finally(function () {
                vm.isLoading = false;
            });
    }

    function _handleSuccess() {
        Analytics.trackConversion("account activation");
        vm.hasSuccess = true;
    }

    function _handleError(error) {
        vm.hasSuccess = false;
        vm.currentError = ERRORS.GENERIC_ERROR;
        if (error && ERRORS[error.code]) {
            vm.currentError = ERRORS[error.code];
        }
    }

    function resendVerificationEmail() {
        $sbAuth
            .resendVerificationEmail($stateParams.email)
            .then(() => {
                vm.currentError.text = "SECTION_VERIFICATION_CODE_RESENT";
                vm.currentError.shouldAllowResend = false;
            })
            .catch(_handleError);
    }
}
