export default class ReportingProjectsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the projects which are enhanced by tracking measures like progress and quality.
     */
    getCollection() {
        const endpointUrl = `/api/${this.API_VERSION}/api/reporting/projects`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }
}
