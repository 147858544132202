import angular from "angular";
import htmlTemplate from "./stepper.html";

export default angular
    .module("sbApp.common.components.stepper", [])
    .component("sbStepper", {
        templateUrl: htmlTemplate,
        bindings: {
            steps: "<",
            activeStepIndex: "<",
        },
    });
