"use strict";

import NoteStateTransitionPermissionMatrix from "./sb_note_state_transition_permission_matrix.class";
import { get, isNumber } from "lodash";

// !IMPORTANT! do not use lodash "has" instead of the has function below!
// this custom has function is checking for a truthy value on the object, while lodash has is doing "hasOwnProperty" stuff.
const has = (object, path) => {
    return !!get(object, path);
};

const RESPONSIBLE_ONLY_WORKFLOW = 1;
const CONFIRMATION_WORKFLOW_RESPONSIBLE_TEAM = 2;
const CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM = 3;
const BLOCK_ALL_ACTIONS = 5;

// Note properties
const confirmationTeamIdProp = "CONFIRMATION_TEAM.id";
const responsibleTeamIdProp = "RESPONSIBLE_TEAM.id";

// Membership properties
const isProjectTeamProp = "team.isProjectTeam";
const teamIdProp = "team.id";

export default class NoteStateTransitionPermissionMatrixFactory {
    static chooseWorkflowBasedOn({ membership, note }) {
        const isConfirmationRequired =
            has(note, confirmationTeamIdProp) &&
            isNumber(get(note, confirmationTeamIdProp));

        if (isConfirmationRequired) {
            return NoteStateTransitionPermissionMatrixFactory.getConfirmationWorkflow(
                { membership, note }
            );
        } else {
            return NoteStateTransitionPermissionMatrixFactory.getResponsibleWorkflow(
                { membership, note }
            );
        }
    }

    static createFor({ workflow }) {
        if (!Array.isArray(workflow)) {
            return NoteStateTransitionPermissionMatrixFactory.mapWorkflowToMatrix(
                workflow
            );
        }

        const noPermissionsMatrix = new NoteStateTransitionPermissionMatrix();

        return workflow.reduce((combined, next) => {
            return combined.or(
                NoteStateTransitionPermissionMatrixFactory.mapWorkflowToMatrix(
                    next
                )
            );
        }, noPermissionsMatrix);
    }

    /*

    +---------------------------+--------------------------------+----------------------------+----------------------------+
    |                           | Note Needs confirmation        | Note needs NO confirmation | INFO Note                  |
    +---------------------------+--------------------------------+----------------------------+----------------------------+
    | User in responsible team  | open -> wfc && rejected -> wfc | open -> closed             | No interaction supported   |
    +---------------------------+--------------------------------+----------------------------+----------------------------+
    | User in confirmation team | wfc -> confirmed | rejected    | XXXXXXX CANT HAPPEN XXXXXX | XXXXXXX CANT HAPPEN XXXXXX |
    +---------------------------+--------------------------------+----------------------------+----------------------------+
    | User in Full Access team  | Both of the above              | Both of above              | No interaction supported   |
    +---------------------------+--------------------------------+----------------------------+----------------------------+
    * */

    static mapWorkflowToMatrix(workflow) {
        const noPermissionsMatrix = new NoteStateTransitionPermissionMatrix();
        switch (workflow) {
            case RESPONSIBLE_ONLY_WORKFLOW: {
                return noPermissionsMatrix.or(
                    new NoteStateTransitionPermissionMatrix([
                        // open, closed
                        //
                        [0, 1, 0, 0, 0], // open -> to ?
                        [0, 0, 0, 0, 0], // closed -> to ?
                        [0, 0, 0, 0, 0], // waiting for confirmation -> to ?
                        [0, 0, 0, 0, 0], // rejected -> to ?
                        [0, 0, 0, 0, 0], // confirmed -> to ?
                    ])
                );
            }
            case CONFIRMATION_WORKFLOW_RESPONSIBLE_TEAM: {
                return noPermissionsMatrix.or(
                    new NoteStateTransitionPermissionMatrix([
                        // open, closed, waiting for confirmation, rejected, confirmed
                        //
                        [0, 0, 1, 0, 0], // open -> to ?
                        [0, 0, 0, 0, 0], // closed -> to ?
                        [0, 0, 0, 0, 0], // waiting for confirmation -> to ?
                        [0, 0, 1, 0, 0], // rejected -> to ?
                        [0, 0, 0, 0, 0], // confirmed -> to ?
                    ])
                );
            }

            case CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM: {
                return noPermissionsMatrix.or(
                    new NoteStateTransitionPermissionMatrix([
                        // open, closed, waiting for confirmation, rejected, confirmed
                        //
                        [0, 0, 0, 0, 0], // open -> to ?
                        [0, 0, 0, 0, 0], // closed -> to ?
                        [0, 0, 0, 1, 1], // waiting for confirmation -> to ?
                        [0, 0, 0, 0, 0], // rejected -> to ?
                        [0, 0, 0, 1, 0], // confirmed -> to ?
                    ])
                );
            }
            case BLOCK_ALL_ACTIONS: {
                return noPermissionsMatrix;
            }
            default:
                return noPermissionsMatrix;
        }
    }

    static getConfirmationWorkflow({ membership, note }) {
        const hasFullAccessMembership = get(membership, isProjectTeamProp);
        if (hasFullAccessMembership) {
            // allow all actions
            return [
                CONFIRMATION_WORKFLOW_RESPONSIBLE_TEAM,
                CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM,
            ];
        }

        const assignedTeamId = get(note, responsibleTeamIdProp);
        const confirmationTeamId = get(note, confirmationTeamIdProp);
        const membershipTeamId = get(membership, teamIdProp);

        const hasWorkResponsibilities =
            assignedTeamId === membershipTeamId || assignedTeamId === null;
        const hasConfirmationResponsibilities =
            confirmationTeamId === membershipTeamId;
        if (hasWorkResponsibilities && hasConfirmationResponsibilities) {
            // allow combined actions
            return [
                CONFIRMATION_WORKFLOW_RESPONSIBLE_TEAM,
                CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM,
            ];
        }

        if (hasWorkResponsibilities) {
            // allow work state changes
            return CONFIRMATION_WORKFLOW_RESPONSIBLE_TEAM;
        }

        if (hasConfirmationResponsibilities) {
            // allow confirmation state changes
            return CONFIRMATION_WORKFLOW_CONFIRMATION_TEAM;
        }

        return BLOCK_ALL_ACTIONS;
    }

    static getResponsibleWorkflow({ membership, note }) {
        const hasFullAccessMembership = get(membership, isProjectTeamProp);
        if (hasFullAccessMembership) {
            // allow all actions
            return RESPONSIBLE_ONLY_WORKFLOW;
        }

        const assignedTeamId = get(note, responsibleTeamIdProp);
        const membershipTeamId = get(membership, teamIdProp);

        const hasWorkResponsibilities =
            assignedTeamId === membershipTeamId || assignedTeamId === null;
        if (hasWorkResponsibilities) {
            // allow work state changes
            return RESPONSIBLE_ONLY_WORKFLOW;
        }

        return BLOCK_ALL_ACTIONS;
    }
}
