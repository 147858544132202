import angular from "angular";
import siteDiaryRouteConfig from "./site_diary.route";
import SiteDiaryCtrl from "./states/site_diary.controller";
import SiteDiaryService from "./services/site_diary.service";
import WeekCalendarService from "./components/sbWeekCalendar/week_calendar.service";
import weekCalendarBodyComponent from "./components/sbWeekCalendar/sbWeekCalendarBody/week_calendar_body.component";
import weekCalendarHeaderComponent from "./components/sbWeekCalendar/sbWeekCalendarHeader/week_calendar_header.component";
import diaryEntryComponent from "./components/sbDiaryEntry/diary_entry.component";
import eventsCardComponent from "./components/sbDiaryEvents/diary_events.component";
import infoCardComponent from "./components/sbDiaryInfo/diary_info.component";
import machinesCardComponent from "./components/sbDiaryMachines/diary_machines.component";
import progressCardComponent from "./components/sbDiaryProgress/diary_progress.component";
import teamsCardComponent from "./components/sbDiaryTeams/diary_teams.component";
import DirectivesModule from "common/ui-elements/ui-elements";
import "angular-ui-router";
import "angular-material";

export default angular
    .module("sbApp.siteDiary", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        DirectivesModule.name,
    ])
    .config(siteDiaryRouteConfig)
    .factory("$sbSiteDiary", SiteDiaryService)
    .factory("$sbWeekCalendar", WeekCalendarService)
    .component(weekCalendarBodyComponent.name, weekCalendarBodyComponent)
    .component(weekCalendarHeaderComponent.name, weekCalendarHeaderComponent)
    .component(diaryEntryComponent.name, diaryEntryComponent)
    .component(eventsCardComponent.name, eventsCardComponent)
    .component(infoCardComponent.name, infoCardComponent)
    .component(machinesCardComponent.name, machinesCardComponent)
    .component(progressCardComponent.name, progressCardComponent)
    .component(teamsCardComponent.name, teamsCardComponent)
    .controller("SiteDiaryCtrl", SiteDiaryCtrl);
