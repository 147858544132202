export default function ProgressOptionsItemsCtrl($element) {
    "ngInject";
    var vm = this;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;

    vm.getIconClasses = getIconClasses;
    vm.handleClick = handleClick;

    function $onInit() {
        vm.parent.addChild(vm);
    }

    function $postLink() {
        vm.iconElement = $element.find("i")[0];
    }

    function handleClick() {
        var oldSelection = vm.parent.selected;
        vm.parent.onChange({
            oldValue: oldSelection,
            newValue: vm.key,
        });
    }

    function getIconClasses() {
        return vm.iconElement.className;
    }
}
