import _ from "lodash";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";

export default function (
    $sbReportingDeliverablesApi,
    $q,
    $sbMembership,
    SbDeliverable,
    SbActivity
) {
    "ngInject";

    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        searchDeliverablesWithActivitiesBy: searchDeliverablesWithActivitiesBy,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function searchDeliverablesWithActivitiesBy(
        projectId,
        searchText,
        structureMapper = _.identity
    ) {
        if (!_.isString(searchText) || searchText.length <= 0) {
            return $q.resolve([]);
        }

        const selects = ["ID", "STRUCTURE_ID", "PROJECT_ID", "NAME", "CODE"];
        const orderby = ["SD", "CD", "NAME"];
        const filter = new ODataFilterFactory().likeOneOf(
            ["NAME", "CODE", "DESC"],
            searchText
        );

        return $sbReportingDeliverablesApi
            .getCollection(projectId, {
                filter: filter.get(),
                select: selects.join(","),
                orderby: orderby.join(","),
                expand: "ACTIVITIES",
            })
            .then(({ deliverables = [] }) => {
                return deliverables.map((deliverableData) => {
                    const deliverable =
                        SbDeliverable.createFromOdataObject(deliverableData);
                    deliverable.structure = structureMapper.get(
                        deliverable.structureId
                    );
                    const activities = deliverableData.ACTIVITIES.map(
                        SbActivity.createFromOdataObject
                    );
                    deliverable.setChildren(
                        _.sortBy(activities, ["topologicalIndex", "name"])
                    );
                    return deliverable;
                });
            })
            .then((deliverables) => {
                return deliverables.filter(function (deliverable) {
                    deliverable.open = false;
                    deliverable.activities = deliverable
                        .getActivities()
                        .filter(canUserReportProgress);

                    return deliverable.activities.length > 0;
                });
            });
    }

    function canUserReportProgress(activity) {
        return $sbMembership.canReportProgressFor(
            {},
            activity,
            $sbMembership.current()
        );
    }
}
