import angular from "angular";
import htmlTemplate from "./recent_activities.tmpl.html";

export default angular
    .module("sbApp.common.components.recentActivities", [])
    .component("sbRecentActivities", {
        templateUrl: htmlTemplate,
        bindings: {
            activities: "<",
            onActivityClick: "&",
        },
        controller: angular.noop,
    });
