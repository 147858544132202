import "moment-timezone";
import "common/services/services";
import "common/intercom/intercomModule";

export default function ImportActivitiesCtrl(
    $sbTeam,
    SbTeam,
    $state,
    SABLONO_STATES,
    $sbImportActivities
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    init();

    async function init() {
        $sbImportActivities.openImportActivitiesDialog(
            navigateOnConfirmCallback,
            navigateOnCancelCallback
        );
    }

    function navigateOnCancelCallback() {
        $state.go("sablono.project.import.selection");
    }

    function navigateOnConfirmCallback() {
        $state.go(SABLONO_STATES.tracker);
    }
}
