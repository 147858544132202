export default function SketchVisualizationService(
    $q,
    $sbFileReader,
    $sbCurrentProject,
    $sbVisualizationsApi,
    $sbSvg
) {
    "ngInject";

    return {
        loadVisualization: (svgKey) => {
            // fetch data and svg
            //
            return $q
                .all({
                    visualization: $sbSvg.fetchVisualizationByKey(svgKey),
                    svg: $sbVisualizationsApi.getPlainSvg(
                        $sbCurrentProject.pick("id"),
                        svgKey
                    ),
                })
                .then(({ visualization, svg }) => {
                    visualization.svg = svg;
                    return visualization;
                });
        },
        fetchContentByMode: $sbSvg.fetchVisualizationContentByMode,
    };
}
