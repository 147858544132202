import angular from "angular";
import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import pck from "../../package.json";
import version from "constants/version.constant";
import environment from "constants/environment.constant";
import { aws } from "../scripts/globals";
import { Angular as AngularIntegration } from "@sentry/integrations";

var SENTRY_DSN = "https://9fab1139c77643ae87b646756639bf0b@sentry.io/226930";

console.debug("Install sentry.io with: %c" + SENTRY_DSN, "color: #FF4500"); // eslint-disable-line

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
        new AngularIntegration({
            angular: angular,
        }),
        new TracingIntegrations.BrowserTracing({
            tracingOrigins: ["app.sablono.com/api", "sablono.link/api"],
            beforeNavigate(context) {
                return {
                    ...context,
                    name: window.location.pathname
                        // taken from well maintained and used uuid npm package: https://github.com/uuidjs/uuid/blob/master/src/regex.js
                        // and made less strict on the 3rd 4 char long sequence
                        .replace(
                            /\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)/gi,
                            "/<uuid>"
                        )
                        .replace(/\/\d+/g, "/<digits>"),
                };
            },
            shouldCreateSpanForRequest: (url) => {
                // Do not create spans for outgoing requests to a `/health/` endpoint
                return !url.match(/\/health\/?$/);
            },
        }),
    ],
    tracesSampleRate: 0.1,
    release:
        aws.region === "eu-west-2"
            ? `${pck.name}-uk@${version}`
            : `${pck.name}@${version}`,
    environment: environment,
    ignoreErrors: [
        /ERROR_AUTHENTICATION_NO_SESSION/i,
        /ERROR_AUTHENTICATION_UNKNOWN/i,
        /ERROR_AUTHENTICATION_USER_PWD_CHANGE_MESSAGE/i,

        /ERROR_EDGE_WOULD_INTRODUCE_CYCLE/i,

        /ERROR_NO_SVG_CONF_FOUND/i,

        /ERROR_REQUEST_ABORTED_MESSAGE/i,
        /ERROR_REQUEST_TIMEOUT_MESSAGE/i,

        /ERROR_SVG_DOES_NOT_EXIST/i,
        /ERROR_USER_NOT_FOUND/i,
    ],
});
