import angular from "angular";
import _ from "lodash";
import dialogTemplate from "./create_new_items.tmpl.html";

export default angular
    .module("sbApp.sbCreateNewItemsDialog", ["sbApp.services"])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("createNewItems", {
            methods: [
                "title",
                "text",
                "ok",
                "cancel",
                "class",
                "ariaLabel",

                // callback on save (fn)
                "onSave",
                // callback on input change (fn)
                "customValidation",
                // message to show on custom validation
                "customValidationMessage",
                // config object with keys and values with all the fields to display (obj)
                "itemConfig",
                // label that needs to be initially focused (string)
                "focusOnField",
                // last suggestions (array of Key - suggestion)
                "lastInputSuggestions",
                // fields that need to be prefilled with suggestions (array of keys)
                "inputSuggestionFields",
            ],
            options: function CreateNewItemsDialog() {
                return {
                    templateUrl: dialogTemplate,
                    controller: CreateNewItemsDialogCtrl,
                    controllerAs: "$ctrl",
                    bindToController: true,
                };

                function CreateNewItemsDialogCtrl(
                    $mdDialog,
                    $timeout,
                    $sbCodeRecommendation,
                    $mdToast
                ) {
                    "ngInject";

                    var vm = this;

                    vm.item = {};

                    vm.closeDialog = closeDialog;
                    vm.saveAndClose = saveAndClose;
                    vm.saveAndClear = saveAndClear;
                    vm.onKeyDown = onKeyDown;
                    vm.validateForm = validateForm;

                    // id's for the buttons
                    vm.saveButton = "sb-save-button-id";
                    vm.saveAndCreateNewButton = "sb-save-and-new-button-id";

                    _activate();

                    function _activate() {
                        // Prefill fields
                        _prefillWithSuggestions();
                        _updateSuggestions();
                        validateForm(vm.item);
                    }

                    function _prefillWithSuggestions() {
                        _.forEach(vm.inputSuggestionFields, function (key) {
                            // If we already have something in the fields...
                            if (vm.lastInputSuggestions[key].length > 0) {
                                var nextInput =
                                    $sbCodeRecommendation.getNextSuggestionFrom(
                                        vm.lastInputSuggestions[key]
                                    );
                                vm.item[key] = nextInput;
                            }
                        });
                    }

                    function _updateSuggestions() {
                        _.forEach(vm.inputSuggestionFields, function (key) {
                            vm.lastInputSuggestions[key] = vm.item[key];
                        });
                    }

                    function _focusField(id) {
                        // we need the timeout for event synchronization
                        $timeout(function () {
                            var element = document.getElementById(id);
                            if (element) {
                                element.focus();
                                // If it is a focus tag, select all input
                                if (element.tagName === "INPUT") {
                                    element.select();
                                }
                            }
                        }, 0);
                    }

                    function validateForm(item) {
                        vm.customValidation(item).then(
                            function (validationResult) {
                                vm.newItemForm.$setValidity(
                                    "customValidation",
                                    validationResult
                                );
                            }
                        );
                    }

                    function saveAndClear(item) {
                        vm.isSaving = true;
                        // Save and reset
                        vm.onSave(item)
                            .then(function () {
                                _showSavedIndication();
                                // update suggestions with the new input
                                _updateSuggestions();
                                // empty the object and prefill fields for next one
                                vm.item = {};
                                _prefillWithSuggestions();
                                // Reset form and focus on default label
                                vm.newItemForm.$setPristine();
                                vm.newItemForm.$setUntouched();
                                _focusField(vm.focusOnField);
                                vm.isSaving = false;
                            })
                            .catch(function () {
                                $mdDialog.cancel();
                            });
                    }

                    function _showSavedIndication() {
                        vm.isSaved = true;
                        return $timeout(function () {
                            vm.isSaved = false;
                        }, 1500);
                    }

                    function saveAndClose(item) {
                        vm.isSaving = true;
                        vm.onSave(item)
                            .then(function () {
                                $mdToast.show(
                                    $mdToast
                                        .simple()
                                        .content(
                                            "DIALOG_CREATE_NEW_DELIVERABLE_SAVED"
                                        )
                                        .hideDelay(3000)
                                        .position("top right")
                                );
                                vm.isSaving = false;
                                $mdDialog.hide(item);
                            })
                            .catch(function () {
                                $mdDialog.cancel();
                            });
                    }

                    function closeDialog() {
                        $mdDialog.cancel();
                    }

                    /**
                     * Key event handler
                     * Behavior:
                     * - On Input fields, after Enter or Tab, go to the next field
                     * - On last input field,
                     *    - on Enter: save directly and create new
                     *    - on Tab: default behavior
                     */
                    function onKeyDown(event, index, isLast) {
                        if (event.code === "Enter" || event.code === "Tab") {
                            vm.newItemForm.$setDirty();
                            if (!isLast) {
                                event.preventDefault();
                                // Focus next field
                                var nextKey = _.keysIn(vm.itemConfig)[
                                    index + 1
                                ];
                                _focusField(nextKey);
                            } else {
                                // just for enter we go directly to save.
                                // tab will keep normal behavior
                                if (event.code === "Enter") {
                                    // Trigger Save button
                                    if (vm.newItemForm.$valid) {
                                        saveAndClear(vm.item);
                                    } else {
                                        // if form is not valid, iterate the fields, so go to first label
                                        var firstKey = _.keysIn(
                                            vm.itemConfig
                                        )[0];
                                        _focusField(firstKey);
                                    }
                                }
                            }
                        }
                    }
                } // end of ctrl
            },
        });
    });
