import _ from "lodash";
import moment, { isMoment } from "moment";

export default function datesService($log) {
    "ngInject";
    return {
        areAllSameDate: isAllSameDate,
        calcDateInfoForDeliverables: calcDateInformation,
        parseCalendarToWorkTimes: parseCalendarToWorkTimes,
        toTimeZoneShiftedJSDate: toTimeZoneShiftedJSDate,
        toTimeZoneShiftedMoment: toTimeZoneShiftedMoment,
        toMomentOrNull,
        anyToMoment: anyToMoment,
        durationBetweenStartAndEndDate: _durationBetweenStartAndEndDate,
        isValidDateValue: _isValidDateValue,
        momentOrNull: momentOrNull,
        isDateRangesIntersect: isDateRangesIntersect,
        getIntersectingDaysBetweenTimeRanges:
            getIntersectingDaysBetweenTimeRanges,
        getStartOfDayFromMoment: getStartOfDayFromMoment,
        getStartOfWorkingDayFromMoment: getStartOfWorkingDayFromMoment,
    };

    function parseCalendarToWorkTimes(calendar) {
        var workTimes = {
            morning: {
                hour: 5,
                minute: 0,
            },
            evening: {
                hour: 20,
                minute: 0,
            },
        };

        if (calendar && calendar.BLOCKS && angular.isArray(calendar.BLOCKS)) {
            var morningTime = calendar.BLOCKS[0][0].split(":");
            var eveningTime = calendar.BLOCKS[1][1].split(":");
            workTimes.morning.hour = Number.parseInt(morningTime[0]);
            workTimes.morning.minute = Number.parseInt(morningTime[1]);
            workTimes.evening.hour = Number.parseInt(eveningTime[0]);
            workTimes.evening.minute = Number.parseInt(eveningTime[1]);
        } else {
            $log.warn(
                "parseCalendarToWorkTimes - fallback to default values because calendar object has no BLOCKS"
            );
        }

        return workTimes;
    }

    function calcDateInformation(deliverables) {
        var isAllSameStartDate = isAllSameDate(deliverables, "plannedStart");
        var isAllSameCompletionDate = isAllSameDate(deliverables, "plannedEnd");

        // Calculates earliest and latest date, to show them as options in the dialog
        //
        var earliestDate = getEarliestDateFromDeliverables(
            deliverables,
            "plannedStart"
        );
        var latestDate = getLatestDateFromDeliverables(
            deliverables,
            "plannedEnd"
        );

        return {
            earliest: earliestDate,
            latest: latestDate,
            allSame: isAllSameStartDate && isAllSameCompletionDate,
        };
    }

    /**
     * Checks if all are the same date.
     * Check is based on unix timestamp in milliseconds.
     *
     * Accuracy is day based.
     *
     * @param unixTimesOrObjects - All values which can be transformed into an moment.
     *
     * @returns {boolean}
     */
    function isAllSameDate(unixTimesOrObjects, propertyKey) {
        if (_.isString(propertyKey) && _.isArray(unixTimesOrObjects)) {
            unixTimesOrObjects = _.map(unixTimesOrObjects, propertyKey);
        }

        return _.uniq(unixTimesOrObjects).length === 1;
    }

    function getEarliestDateFromDeliverables(deliverables, propertyKey) {
        var startDates = _.map(deliverables, propertyKey || "startDate");

        return _getEarliestDateFromDates(startDates);
    }

    function getLatestDateFromDeliverables(deliverables, propertyKey) {
        var endDates = _.map(deliverables, propertyKey || "endDate");

        return _getLatestDateFromDates(endDates);
    }

    /**
     * Gets the earliest moment in the array ignoring invalid moments.
     *
     * @param {moment.Moment[]} dates
     * @returns {moment.Moment}
     * @private
     */
    function _getEarliestDateFromDates(dates) {
        const validMoments = dates.filter((date) => date.isValid());
        if (validMoments.length === 0) {
            return moment.invalid();
        }

        return moment.min(validMoments);
    }

    /**
     * Gets the latest moment in the array ignoring invalid moments.
     *
     * @param {moment.Moment[]} dates
     * @returns {moment.Moment}
     * @private
     */
    function _getLatestDateFromDates(dates) {
        const validMoments = dates.filter((date) => date.isValid());
        if (validMoments.length === 0) {
            return moment.invalid();
        }

        return moment.max(validMoments);
    }

    /**
     * Convert the moment date-time to a JS Date and ignore the Timezone. This is important because this date picker
     * is supporting timezones but the md-datepicker is not. So the md-datepicker is displaying a JS Date in local TZ
     * but we want him to display the given moment dates in the specified timezone.
     *
     * So instead of
     *      moment.tz("2012-03-11 08:00:00", "America/Los_Angeles").toDate() --> will result in datepicker displays 2012-03-10 23:00:00
     *      we use
     *      new Date(moment.tz("2012-03-11 08:00:00", "America/Los_Angeles").format("LLL")) --> will result in datepicker displays 2012-03-11 08:00:00
     *
     * because we create the JS Date object without the timezone information.
     *
     * @param moment
     * @returns {Date}
     */
    function toTimeZoneShiftedJSDate(mDate) {
        if (mDate === null) {
            return null;
        }
        return new Date(
            mDate.year(),
            mDate.month(),
            mDate.date(),
            mDate.hours(),
            mDate.minutes()
        );
    }

    /**
     * See documentation of toTimeZoneShiftedJSDate -> similar but the other way around
     *
     * Basically we want to take year, month and day of the given date and create a moment
     * in the given timezone that has exactly the year, month and day values of the date.
     * Therefor we have to "ignore" the timezone of the date!
     *
     * @param {Date} date
     * @param {String} timezone
     * @returns {*}
     */
    function toTimeZoneShiftedMoment(date, timezone) {
        if (!date) {
            return null;
        }

        return moment.tz(
            {
                year: date.getFullYear(),
                month: date.getMonth(),
                day: date.getDate(),
                hours: date.getHours(),
                minutes: date.getMinutes(),
            },
            timezone
        );
    }

    /**
     * Can handle a moment a integer string (usually in ms) or a date string (30-12-2018)
     *
     * @param {moment|String|number} something
     */
    function anyToMoment(something) {
        if (isMoment(something)) {
            return something;
        } else if (isDateFloatAsString(something)) {
            return moment(parseFloat(something));
        } else if (typeof something === "string") {
            return moment(moment.utc(something).valueOf());
        } else {
            return moment(something);
        }
    }

    function isDateFloatAsString(input) {
        return typeof input === "string" && /^\d+\.?\d+$/.test(input);
    }

    function _durationBetweenStartAndEndDate(startDate, endDate, timeUnit) {
        if (!startDate || !endDate) {
            return 0;
        }

        var copyStartDate = moment(startDate);
        var copyEndDate = moment(endDate);

        return copyEndDate
            .startOf("day")
            .diff(copyStartDate.startOf("day"), timeUnit);
    }

    function momentOrNull(value) {
        return value !== null ? moment(value) : null;
    }

    function _isValidDateValue(value) {
        if (value === null) {
            return true;
        }

        return isMoment(value) && value.isValid();
    }

    /**
     * Checks if two date ranges intersect.
     * Array contains start and end date in this order: [start, end].
     *
     * @param {Array.<moment>} first - Range to compare to second.
     * @param {Array.<moment>} second - range to be compared to first.
     * @returns {boolean}
     */
    function isDateRangesIntersect(first, second) {
        var firstStart = moment(first[0]);
        var firstEnd = moment(first[1]);
        var secondStart = moment(second[0]);
        var secondEnd = moment(second[1]);

        var latestStartDate = moment.max(firstStart, secondStart);
        var earliestEndDate = moment.min(firstEnd, secondEnd);

        return latestStartDate.isSameOrBefore(earliestEndDate);
    }

    /**
     * Returns the intersecting days of two date ranges.
     * The steps are in days.
     *
     * Array contains start and end date in this order: [start, end].
     *
     * @param {Array.<moment>} first - Range to compare to second.
     * @param {Array.<moment>} second - range to be compared to first.
     * @param {'day' | 'week'} columnTimespan.
     * @returns {Array.<moment>} - The days intersecting.
     */
    function getIntersectingDaysBetweenTimeRanges(
        first,
        second,
        columnTimespan
    ) {
        var firstStart = moment(first[0]);
        var firstEnd = moment(first[1]);
        var secondStart = moment(second[0]);
        var secondEnd = moment(second[1]);

        var intersection = [];
        var currentColumnTimespan = firstStart;

        // is same or before on day scale
        while (currentColumnTimespan.isSameOrBefore(firstEnd, columnTimespan)) {
            // [] means inclusive for the edges. so if currentDay is same as start or end it's still between.
            if (
                currentColumnTimespan.isBetween(
                    secondStart,
                    secondEnd,
                    columnTimespan,
                    "[]"
                )
            ) {
                intersection.push(moment(currentColumnTimespan));
            }

            currentColumnTimespan = moment(currentColumnTimespan).add(
                1,
                columnTimespan
            );
        }

        return intersection;
    }

    function getStartOfDayFromMoment(day, calendar) {
        return moment.tz(day, calendar.tz()).startOf("day");
    }

    function getStartOfWorkingDayFromMoment(day, calendar) {
        var startOfWorkingDay = calendar.calcStartHourOfWorkday();

        return moment
            .tz(day, calendar.tz())
            .hour(startOfWorkingDay[0])
            .minute(startOfWorkingDay[1]);
    }

    function toMomentOrNull(dateISOString) {
        const date = moment(dateISOString, moment.ISO_8601, true);
        return date.isValid() ? date : null;
    }
}
