import htmlTemplate from "./sbNoteTemplateConfigCard.html";
import ODataFilterSet from "common/services/oDataService/odata_filter_set";

export default function NoteTemplateConfigCard(
    noteTemplatesService,
    noteTemplateConfigCardService,
    sbConfigCard
) {
    "ngInject";

    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        scope: {},
        link: function postLink(scope) {
            sbConfigCard.init(scope, scope.update);
        },
        controller: function NoteTemplateConfigCardCtrl(
            $scope,
            $state,
            ISSUE_TEMPLATE_CONFIG
        ) {
            "ngInject";

            var _rawData;

            $scope.ISSUE_TYPES_CONFIG = ISSUE_TEMPLATE_CONFIG.TYPES.OPTIONS;

            /**
             * list of available filters -> bound to the accordion panes
             * @type {Object<String,ODataFilterSet>}
             */
            $scope.filters = {};

            $scope.selection = {};

            /* handle selection events on the list items */
            $scope.filterItem = filterItem;
            /* determine the selected state */
            $scope.isSelected = isSelected;
            /* give me the selected display text of a particular category */
            $scope.getSelectionDisplayText = getSelectionDisplayText;
            /* refresh the complete config card with new server values */
            $scope.update = refreshCardData;
            /* Reverse the cards data (since it's coming the other way around) */
            $scope.reverse = reverse;

            _initFilters();

            function _initFilters() {
                noteTemplateConfigCardService
                    .requestCardData()
                    .then(function (rawData) {
                        _rawData = rawData;
                        return rawData;
                    })
                    .then(noteTemplateConfigCardService.asTypesWithEntryList)
                    .then(noteTemplateConfigCardService.addAllCounts)
                    .then(_setFilters);
            }

            function _setFilters(filterObject) {
                $scope.filters = {
                    TYPE: new ODataFilterSet("TYPE", "ALL", filterObject.TYPE),
                    CREATOR_DB_NAME: new ODataFilterSet(
                        "CREATOR_DB_NAME",
                        "ALL",
                        filterObject.CREATOR_DB_NAME
                    ),
                };

                // validate the current $state and set a corresponding selection
                //
                var stateParams = $state.params;
                for (var fKey in stateParams) {
                    if (stateParams[fKey] && $scope.filters[fKey]) {
                        $scope.filters[fKey].selection(stateParams[fKey]);
                    }
                }

                // current selected data
                //
                $scope.selection = {
                    TYPE: $scope.filters.TYPE.getSelectedItem(),
                    CREATOR_DB_NAME:
                        $scope.filters.CREATOR_DB_NAME.getSelectedItem(),
                };

                // change the quantity indication on the elements.
                //
                noteTemplateConfigCardService.updateQuantity(
                    _rawData,
                    $scope.filters
                );
            }

            /**
             * Is triggered on item click -> so changes the current selection in one category
             *
             * @param {String} item - the value of the new filter
             * @param {String} filterType - name of the property to filter
             */
            function filterItem(item, filterType) {
                $scope.filters[filterType].selection(item);
                $scope.selection[filterType] =
                    $scope.filters[filterType].getSelectedItem();

                // change the quantity indication on the elements.
                //
                noteTemplateConfigCardService.updateQuantity(
                    _rawData,
                    $scope.filters
                );

                // change the state params according to the current filter selection
                //
                var filter = $scope.filters;
                var prevStateParams = $state.params;

                for (var fKey in filter) {
                    var theFilter = filter[fKey];

                    if (theFilter.selection() !== "ALL") {
                        prevStateParams[fKey] = theFilter.selection();
                    } else {
                        prevStateParams[fKey] = undefined;
                    }
                }

                $state.go($state.current.name, prevStateParams, {
                    location: "replace",
                });
            }

            function isSelected(value, type) {
                var typeSelection = $scope.filters[type];
                return typeSelection && typeSelection.selection() === value;
            }

            function getSelectionDisplayText(type) {
                var typeSelection = $scope.filters[type];
                return (
                    typeSelection &&
                    typeSelection.getSelectedItem().displayText()
                );
            }

            function refreshCardData() {
                // new init -> maybe with flickering -> think about a merge approach.
                _initFilters();
            }

            /**
             * Method to reverse object properties, since we're getting ALL,ANYTIME etc. as last ones
             * but we need them to be the first in our list.
             * @param {Object} object - Our object which needs to be reversed and returned
             * @returns {Object|*}
             */
            function reverse(object) {
                if (object) {
                    var keys = Object.keys(object).reverse();
                    var o = new Object();
                    for (var i = 0; i < keys.length; i++) {
                        o[keys[i]] = object[keys[i]];
                    }
                    return o;
                }
            }
        },
    };
}
