import PresentableError from "../../errors/PresentableError";
import RequestError from "../../errors/RequestError";
import RequestTimeoutError from "../../errors/RequestTimeoutError";
import ParseableError from "../../errors/ParseableError";
import DataBaseNativeError from "../../errors/DataBaseNativeError";
import RequestAbortedError from "../../errors/RequestAbortedError";
import AuthenticationError from "../../errors/AuthenticationError";
import PayloadTooLargeError from "../../errors/PayloadTooLargeError";

export default function errorHandlerService() {
    // API
    //
    return {
        rethrowAsInterpretableDomainError: rethrowAsInterpretableDomainError,
    };

    /**
     * Handles general errors by re-throwing them as understandable errors, e.g. PresentableError.
     *
     * @param {Error} error
     * @throws {PresentableError}
     */

    async function rethrowAsInterpretableDomainError(error, xhrRequest) {
        // re-throw if this is already a presentable error
        //
        if (error instanceof PresentableError) {
            throw error;
        }

        // transform XHR aborted or timed out errors and re-throw
        //
        if (error && error.type === "abort") {
            throw new RequestAbortedError(xhrRequest);
        }

        if (error && error.type === "timeout") {
            throw new RequestTimeoutError(xhrRequest);
        }

        // throw authentication error on 401 Unauthorized
        //
        if (401 === Number.parseInt(xhrRequest.status, 10)) {
            throw new AuthenticationError();
        }

        if (error.responseType === "blob") {
            if (413 === Number.parseInt(xhrRequest.status, 10)) {
                throw new PayloadTooLargeError();
            }
            if (504 === Number.parseInt(xhrRequest.status, 10)) {
                throw new RequestTimeoutError(xhrRequest);
            }

            if (error.response.type === "application/json") {
                const jsonErrorString = await error.response.text();
                throw new ParseableError(
                    xhrRequest,
                    ParseableError.parseResponse(jsonErrorString)
                );
            }
        }

        // transform HANA error and re-throw
        //
        if (DataBaseNativeError.check(xhrRequest)) {
            throw new DataBaseNativeError(xhrRequest);
        }

        if (ParseableError.isRequestParseable(xhrRequest)) {
            throw new ParseableError(
                xhrRequest,
                ParseableError.parseResponse(xhrRequest.response)
            );
        }

        throw new RequestError(xhrRequest);
    }
}
