import templateHtml from "./activity_card.html";
import LeanBoardModelMapper from "../../services/lean_board_model.mapper";

export default {
    name: "sbActivityCard",
    templateUrl: templateHtml,
    bindings: {
        activity: "<",
        onActivityClick: "&",
    },
    controller: ActivityCardCtrl,
};

function ActivityCardCtrl($scope, $filter) {
    "ngInject";
    var vm = this;

    var _shadedHexColor = $filter("shadedHexColor");
    var _stripedBackground = $filter("stripedBackground");
    var _asStateIcon = $filter("asStateIcon");
    var _amDifference = $filter("amDifference");
    var _mathAbs = $filter("mathAbs");
    var _i18n = $filter("i18n");
    var _translate = $filter("translate");
    var _pickTextColorBasedOnBgColorW3C = $filter(
        "pickTextColorBasedOnBgColorW3C"
    );

    vm.$onInit = $onInit;

    // state is the only property change that supports change detection
    //
    $scope.$watch(
        "$ctrl.activity.state.current",
        function (newState, oldState) {
            if (newState !== oldState) {
                vm.card.icon = _asStateIcon(newState);
            }
        }
    );

    function $onInit() {
        if (vm.activity) {
            vm.activity.daysBehindBaseline =
                LeanBoardModelMapper.calculateDaysBehindBaseline(
                    vm.activity.calendar,
                    vm.activity.schedule.baseline.end,
                    vm.activity.endDate
                );

            vm.card = _toViewModel(vm.activity);
        }
    }

    function _toViewModel(activity) {
        const isForecasted = activity.isForecasted;
        const borderColor = _getCardBorderColor(activity);
        const adjustedColor = isForecasted
            ? _shadedHexColor(activity.workTeam.color, 0.4)
            : _shadedHexColor(activity.workTeam.color);

        return {
            id: activity.id
                ? activity.id
                : activity.templateId + activity.laneRecord.location,
            colors: {
                background: _getCardBackgroundColor(
                    adjustedColor,
                    isForecasted
                ),
                border: borderColor,
                fontColor: _pickTextColorBasedOnBgColorW3C(adjustedColor),
            },
            texts: {
                primary: activity.name,
                secondary: {
                    lateByDays: _mathAbs(
                        _amDifference(activity.endDate, null, "days")
                    ),
                    duration: activity.duration.value,
                    durationUnit: _translate(_i18n(activity.duration.unit)),
                    meanDuration: activity.displayedDuration.value,
                    meanDurationUnit: _translate(
                        _i18n(activity.displayedDuration.unit)
                    ),
                    team: _translate(activity.workTeam.getDisplayName()),
                    daysBehindBaseline: _getDaysBehindBaselineString(
                        activity.daysBehindBaseline
                    ),
                    topologicalIndex: activity.topologicalIndex,
                },
            },
            icon: _asStateIcon(activity.state.current),
            customClasses: _findCustomClasses(activity),
            badges: {
                infos: activity.noteStatistic.info,
                claims: activity.noteStatistic.openClaims,
                obstructions: activity.noteStatistic.openObstructions,
            },
            daysBehindBaseline: activity.daysBehindBaseline,
            canShowDaysBehindBaselineBadge: _canShowDaysBehindBaselineBadge(
                activity.daysBehindBaseline
            ),
        };
    }

    function _getCardBackgroundColor(color, isForecasted) {
        return _stripedBackground(color, isForecasted);
    }

    function _getCardBorderColor(activity) {
        if (activity.state.isCompleted()) {
            return _shadedHexColor(activity.workTeam.color, 0.7);
        }

        return _shadedHexColor(activity.workTeam.color, 0);
    }

    function _findCustomClasses(activity) {
        if (!activity.state.isCompleted()) {
            return activity.className + " sb-activity-card--not-completed";
        }

        return activity.className;
    }

    // show the badge if: days behind baseline could be calculated (i.e. not "-")
    //
    function _canShowDaysBehindBaselineBadge(daysBehindBaseline) {
        return daysBehindBaseline !== "-" && daysBehindBaseline !== 0;
    }

    function _getDaysBehindBaselineString(daysBehindBaseline) {
        var string = "";

        if (daysBehindBaseline <= 0) {
            string += "+";
            string += daysBehindBaseline * -1;
        } else {
            string += "-";
            string += daysBehindBaseline;
        }
        string += _translate("_wd_ABBREVIATION");

        return string;
    }
}
