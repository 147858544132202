import angular from "angular";
import moment from "moment";
import htmlTemplate from "./sb_filter_select.html";
export default angular
    .module("sbApp.sbFilterSelect", [])
    .directive("sbFilterSelect", function () {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "filterSelect",
            templateUrl: htmlTemplate,
            replace: true,
            scope: {
                /**
                 * @typeof ViewFilter
                 */
                viewFilter: "=",

                // inform parent
                onChange: "=",

                //
                placeholder: "=",
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "filterSelect.viewFilter.value",
                    function (newValue, oldValue) {
                        // support clear viewFilter from outside -> newValue is undefined
                        if (newValue === undefined && newValue !== oldValue) {
                            controller.activate();
                        }
                    }
                );
            },
            controller: function FilterSelectCtrl() {
                var vm = this;

                vm.activate = activate;
                vm.handleChange = handleChange;

                activate();

                ///////////
                //
                //  IMPL
                //
                ///////////

                function activate() {
                    var options = vm.viewFilter.collectActiveOptions();

                    if (options && options.length > 0) {
                        vm.tmp = {
                            option: options[0].name,
                        };
                    } else {
                        vm.tmp = {};
                    }
                }

                function handleChange(filterName) {
                    if (filterName) {
                        vm.viewFilter.setValue(filterName);
                    } else {
                        vm.viewFilter.clear();
                    }
                    vm.onChange(vm.viewFilter.key, vm.viewFilter);
                }
            },
        };
    });
