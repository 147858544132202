import templateHtml from "./deliverable_card.html";

export default {
    name: "sbDeliverableCard",
    templateUrl: templateHtml,
    bindings: {
        deliverable: "<",
        loading: "<",
        selected: "<",
    },
    controller: function (SbNote) {
        "ngInject";
        var vm = this;

        vm.NOTE = SbNote;
    },
    transclude: true,
};
