import angular from "angular";
import moment from "moment";
import _ from "lodash";
import CyclicGraphError from "../../errors/CyclicGraphError";

export default function (
    $q,
    $sbRequest,
    $sbTemplate,
    $sbProject,
    $sbCurrentProject,
    $sbDomain,
    $sbDates,
    $sbTeam,
    $sbDependencyJobsApi,
    SbActivity,
    Analytics,
    $sbStateChangesApi,
    $sbJobApi
) {
    "ngInject";

    var service = {
        recentActivities: [],
        isActivityInTimeRange: isActivityInTimeRange,
        getAllActivitiesByDeliverableTemplateID:
            getAllActivitiesByDeliverableTemplateID,
        edges: {
            create: createEdges,
        },
        odata: {
            create: angular.noop,
            updateState: updateActivityState,
            remove: angular.noop,
        },
        updateState: updateActivityState,
    };

    return service;

    /**
     * Create and persist a set of edges
     *
     * Can fail if one of these edges is introducing a cycle to the project
     *
     * @param {Object[]} edges - A list of all edges that should be created
     * @param {SbActivity} edges[].source - source of the edge
     * @param {SbActivity} edges[].target - target of the edge
     *
     * @return {$q|JSON|Promise<any>}
     */
    function createEdges(edges) {
        if (!_.isArray(edges) || edges.length < 1) {
            return $q.reject(new Error("Array of edges not specified."));
        }

        const edgeRequestBody = edges.map(function (edge) {
            return {
                source_activity_id: edge.source.id,
                target_activity_id: edge.target.id,
            };
        });

        return $sbDependencyJobsApi
            .createAddActivityDependencyJob(
                $sbCurrentProject.pick("id"),
                edgeRequestBody
            )
            .then(function ({ jobId }) {
                // if the job failed it is most likely caused by a cycle
                return $sbJobApi.waitFor(jobId).then((response) => {
                    if (response.state === $sbJobApi.STATE_FAILED) {
                        return $q.reject(new CyclicGraphError());
                    }
                });
            })
            .then(function () {
                return $sbProject.refreshCurrent();
            });
    }

    /**
     * Return all activities by given deliverable template id
     * @param options.templateId {String} - Template id
     * @param options.activityFilter {Function} - filter instances of SbActivity
     */
    function getAllActivitiesByDeliverableTemplateID(options) {
        var templateId = options.templateId;
        var filterActivities =
            options.activityFilter ||
            function () {
                return true;
            };

        return $q
            .all([
                $sbTemplate.getTemplateDetails(templateId),
                $sbTeam.getTeams($sbProject.getCurrentProjectId()),
            ])
            .then(function (results) {
                var data = results[0];
                var teams = results[1];
                var componentsData = {
                    teams: teams,
                };
                return $sbDomain.workflowFactory.createFromTemplateData(
                    data.components,
                    componentsData
                );
            })
            .then(function filterAllActivities(domainModel) {
                var allActivities = _.reduce(
                    domainModel,
                    function (allActivities, baseComponent) {
                        if (baseComponent instanceof SbActivity) {
                            allActivities.push(baseComponent);
                        } else {
                            allActivities = allActivities.concat(
                                baseComponent.getActivities()
                            );
                        }
                        return allActivities;
                    },
                    []
                );

                return _(allActivities)
                    .filter(filterActivities)
                    .sortBy(["topologicalIndex"])
                    .value();
            });
    }

    /**
     * Send a state change update for a single activity.
     *
     * @param {string} activityId - UUID
     * @param {Object} body
     * @param {string} body.state - One of [NOT_STARTED, STARTED, DONE, WAITING_FOR_CONFIRMATION, REJECTED, CONFIRMED]
     *
     * @returns {*}
     */
    function updateActivityState(activityId, { state }) {
        return $sbStateChangesApi.create(activityId, {
            state: state.toLowerCase(),
        });
    }

    function _isValidMoment(dateAsMoment) {
        return moment.isMoment(dateAsMoment) && dateAsMoment.isValid();
    }

    /**
     * Checks if the activity is in the given time range.
     *
     * @param {Activity} activity
     * @param {Array.<moment>} timeRange - Consists of start and end date of range. (Order is important!)
     * @returns {boolean}
     */
    function isActivityInTimeRange(activity, timeRange) {
        var start = activity.startDate;
        var end = activity.endDate;
        var isInRange = false;

        if (_isValidMoment(start) && _isValidMoment(end)) {
            isInRange = $sbDates.isDateRangesIntersect([start, end], timeRange);
        }

        return isInRange;
    }
}
