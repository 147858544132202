export default class MembershipsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the project memberships.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/memberships`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns the membership for a given user.
     *
     * @param projectId {string}    UUID
     * @param userId    {string}    The unique identifier of the user you are requesting the resource for.
     */
    get(projectId, userId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/memberships/${userId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a new membership in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.user_id      {string}    The unique identifier of the user.
     * @param body.team_id      {number}    The identifier of the team.
     * @param body.role_name    {string}    The human readable name of the role
     */
    create(projectId, { user_id, team_id, role_name }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/memberships`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { user_id, team_id, role_name },
        });
    }

    /**
     * This endpoint creates commercial access privileges for a user.
     *
     * @param projectId         {string}    UUID
     * @param userId            {string}    The unique identifier of the user.
     * @param body              {Object}    Request body.
     * @param body.team_id      {number}    The identifier of the team.
     * @param body.privilege    {string}    The privilege to grant, either manager or reviewer.
     */
    createCommercialPrivileges(projectId, userId, { teamId, privilege }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/memberships/${userId}/commercial-access`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: {
                on_team: teamId,
                privilege,
            },
        });
    }

    /**
     * This endpoint allows to modify the membership. When changing roles keep in mind that always one admin membership has to be available in each project.
     *
     * @param projectId {string}    UUID
     * @param userId    {string}    The unique identifier of the user you are requesting the resource for.
     *
     * @param body {Object}    Request body
     * @param [body.role_name]  {string}    The human readable name of the role
     * @param [body.team_id]    {number}    The unique platform wide id
     * @param [body.substitute] {object}    An optional user that can be activated to take over the responsibilities of the main user while he is unavailable. (Leave)
     */
    update(projectId, userId, { role_name, team_id, substitute }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/memberships/${userId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: { role_name, team_id, substitute },
        });
    }

    /**
     * This endpoint allows to revoke a membership.
     *
     * @param projectId {string}    UUID
     * @param userId    {string}    The unique identifier of the user you are requesting the resource for.
     */
    delete(projectId, userId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/memberships/${userId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }

    /** This endpoint deletes commercial access privileges for a user.
     *
     * @param projectId         {string}    UUID
     * @param userId            {string}    The unique identifier of the user.
     * @param teamId            {number}    The identifier of the team.
     */
    deleteCommercialPrivileges(projectId, userId, teamId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/memberships/${userId}/commercial-access/${teamId}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "DELETE",
        });
    }
}
