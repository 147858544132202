import ConstantsModule from "./constants.module";

ConstantsModule.constant("TRAFFIC_LIGHT_MODES", {
    ISSUE_TYPE: "ISSUE_TYPE",
    PROGRESS_VALUE: "PROGRESS_VALUE",
});

ConstantsModule.constant("DATE_TYPES", {
    START_AND_DUE_DATE: "START_AND_DUE_DATE",
    START_DATE: "START_DATE",
    DUE_DATE: "DUE_DATE",
});

ConstantsModule.constant("ACTIVITY_CONF_PROPERTIES_VALUES", {
    DELETION_STATUS: "not applicable",
});

ConstantsModule.constant("PROJECT_SCHEDULING_MODES", {
    EDGES: "EDGES",
    DATES: "DATES",
});

ConstantsModule.constant("SYSTEM_STATUS", {
    OPERATIONAL: "GREEN",
    MAINTENANCE: "RED",
});

/**
 * Enum string values
 * @enum {string}
 */
ConstantsModule.constant("ENTITY_TYPE", {
    ACTIVITY_TYPE: "ACTIVITY",
    DELIVERABLE_TYPE: "DELIVERABLE",
});
