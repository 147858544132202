import "core-js/features/number/parse-int";
import "core-js/features/number/is-nan";
import _ from "lodash";

export default class VirtualPagination {
    /**
     *
     * @param pager {Pager}
     * @param pageSize {number|string}
     * @param prefetchDistance {number|string}
     */
    constructor(pager, { pageSize, prefetchDistance }) {
        this.pager = pager;
        this.total = pager.totalCount();
        this.pageSize = Number.parseInt(pageSize, 10);
        this.prefetchDistance = Number.parseInt(prefetchDistance, 10);
        this.numberOfPages = Math.ceil(this.total / this.pageSize);

        this.range = {
            pageIndex: 1,
            from: 0,
            to: this.pageSize,
        };

        this.listeners = [];
    }

    emit(event) {
        this.listeners.forEach((listener) => {
            if (event instanceof Error) {
                listener.onError(event);
            }
        });
    }

    addEventListener(listener) {
        this.listeners.push(listener);
    }

    /**
     * Get items at given page number.
     *
     * @param pageNumber {number|string}
     * @returns {Object[]}
     */
    getPageAt(pageNumber) {
        this.pageNumber = pageNumber;

        this.moveRangeToCurrentPage();

        return this.getItemsInRange(this.range);
    }

    getCurrentPage() {
        return this.getItemsInRange(this.range);
    }

    getNextPage() {
        this.range.pageIndex += 1;
        this.moveRangeBy(this.pageSize);

        // if we are getting closer to needing more data
        //
        const isAllLoadedAlready =
            this.pager.currentPage.total === this.pager.localCount();
        const nextPageExceedsPrefetched =
            this.range.to >= this.pager.localCount() - this.prefetchDistance;
        if (!isAllLoadedAlready && nextPageExceedsPrefetched) {
            this.pager.nextPage().catch((err) => this.emit(err));
        }

        return this.getItemsInRange(this.range);
    }

    getPrevPage() {
        this.range.pageIndex -= 1;
        this.moveRangeBy(-1 * this.pageSize);
        return this.getItemsInRange(this.range);
    }

    hasNextPage() {
        return this.range.to < this.total;
    }

    hasPrevPage() {
        return this.range.from > 0;
    }

    get pageNumber() {
        return this.range.pageIndex;
    }

    /**
     * @private
     * @param range
     * @returns {unknown[]}
     */
    getItemsInRange(range) {
        return _.slice(this.pager.allLocalItems(), range.from, range.to);
    }

    /**
     * @public
     * @returns {unknown[]}
     */
    getAllLocalItems() {
        return this.pager.allLocalItems();
    }

    /**
     * @private
     */
    moveRangeToCurrentPage() {
        this.resetRange();

        for (let i = 1; i < this.range.pageIndex; i++) {
            this.moveRangeBy(this.pageSize);
        }
    }

    /**
     *
     * @private
     * @param pageNumber {number|string|undefined}
     *
     * @returns {void}
     */
    set pageNumber(pageNumber) {
        pageNumber = Number.parseInt(pageNumber, 10);

        // fallback to first page
        if (Number.isNaN(pageNumber) || pageNumber < 1) {
            pageNumber = 1;
        }

        // fallback to last page
        if (pageNumber > this.numberOfPages) {
            pageNumber = this.numberOfPages;
        }

        // save the current page
        this.range.pageIndex = pageNumber;
    }

    /**
     * @private
     * @param increment {number}
     */
    moveRangeBy(increment) {
        this.range.from += increment;
        this.range.to += increment;
    }

    /**
     * @private
     */
    resetRange() {
        this.range.from = 0;
        this.range.to = this.pageSize;
    }
}
