/*
  global AWS_COGNITO_REGION,
         AWS_COGNITO_USER_POOL_ID,
         AWS_COGNITO_APP_CLIENT_ID,
         IS_PRODUCTION_BUILD
 */

/**
 * These globals are environment dependent and are set through
 * the DefinePlugin in "webpack.dev.js", "webpack.test.js" and "webpack.prod.js".
 *
 * @type {{AWS_COGNITO_REGION, AWS_COGNITO_APP_CLIENT_ID, AWS_COGNITO_USER_POOL_ID}}
 */
export const aws = {
    region: AWS_COGNITO_REGION,
    cognito: {
        userPoolId: AWS_COGNITO_USER_POOL_ID,
        appClientId: AWS_COGNITO_APP_CLIENT_ID,
    },
};

export const general = {
    isProductionBuild: IS_PRODUCTION_BUILD,
};
