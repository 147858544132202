import angular from "angular";
import {
    activity_state_not_applicable,
    activity_state_not_started,
    activity_state_started,
    activity_state_completed,
    activity_state_rejected,
    activity_state_icon_not_started,
    activity_state_icon_started,
    activity_state_icon_wfc,
    activity_state_icon_done,
    activity_scheduling_behind,
    activity_scheduling_on_time,
    note_color_quality_issue,
    note_color_obstruction,
} from "../../../colorpalette";

export default /*ngInject*/ function () {
    /**
     * CONSTANTS
     */

    var COLORS = [
        "#3366cc",
        "#dc3912",
        "#FFFF41",
        "#109618",
        "#990099",
        "#0099c6",
        "#dd4477",
        "#66aa00",
        "#b82e2e",
        "#316395",
        "#994499",
        "#22aa99",
        "#aaaa11",
        "#6633cc",
        "#e67300",
        "#8b0707",
        "#651067",
        "#329262",
        "#5574a6",
        "#3b3eac",
    ];

    var PROGRESS_COLORS = {
        BEHIND_SCHEDULE: activity_scheduling_behind,
        ON_TIME: activity_scheduling_on_time,

        HAS_CLAIMS: note_color_quality_issue,
        HAS_OBSTRUCTIONS: note_color_obstruction,
        HAS_CLAIMS_OUTLINE: note_color_quality_issue,

        PENDING: activity_state_icon_not_started,
        IN_PROGRESS: activity_state_icon_started,
        DONE: activity_state_icon_done,
        WAITING_FOR_CONFIRMATION: activity_state_icon_wfc,
    };

    var LEGEND_KEYS = {
        BEHIND_SCHEDULE: "_BEHIND_SCHEDULE",
        ON_TIME: "_ON_SCHEDULE",
        HAS_CLAIMS: "_OPEN_CLAIMS",
        HAS_OBSTRUCTIONS: "_OPEN_OBSTRUCTIONS",
        WAITING_FOR_CONFIRMATION: "_WAITING_FOR_CONFIRMATION",
    };

    var TASK_CLASSES = {
        IN_PROGRESS: "sb-task-in-progress",
        PENDING: "sb-task-not-started",
        DONE: "sb-task-done",
        BEHIND_SCHEDULE: "sb-task-behind",
        ON_TIME: "sb-task-on-time",
        UNKNOWN: "sb-task-unknown",
        HAS_CLAIMS: "sb-task-has-claims",
        HAS_OBSTRUCTIONS: " sb-task-has-obstructions",
    };

    var PROGRESS_TO_COLOR_MAP = {
        0: PROGRESS_COLORS.PENDING,
        50: PROGRESS_COLORS.IN_PROGRESS,
        100: PROGRESS_COLORS.DONE,
    };

    var PROGRESS_TO_TASK_CLASS_MAP = {
        0: TASK_CLASSES.PENDING,
        50: TASK_CLASSES.IN_PROGRESS,
        100: TASK_CLASSES.DONE,
    };

    /**
     * This service generates colors
     */
    var service = {
        color: {
            STAGE_REACHED_FALLBACK: "#000000",
            STAGE_PATTERN_COLOR: "#B3B3B3",
            TRANSPARENT: "#00000000", // the last two zeroes do the trick
            WAITING_FOR_CONFIRMATION:
                getStatusColors().waiting_for_confirmation,
            fromStatus: toStatusColor,
            fromProgress: toProgressColor,
            random: getRandomColor,
            behindSchedule: getStatusColors().behind,
            onTime: getStatusColors().onTime,
            withIssues: getStatusColors().claims,
            withObstructions: getStatusColors().obstructions,
            outline: {
                withClaims: getStatusColors().claims_outline,
                withObstructions: getStatusColors().obstructions_outline,
            },
            visualization: {
                stages: {
                    NOT_APPLICABLE: activity_state_not_applicable,
                    NOT_STARTED: activity_state_not_started,
                    STARTED_OR_AVAILABLE: activity_state_started,
                    COMPLETED_OR_WAITING_FOR_CONFIRMATION:
                        activity_state_completed,
                    REJECTED: activity_state_rejected,
                },
            },
        },
        classes: {
            fromStatus: toStatusClasses,
            fromProgress: toProgressClass,
        },

        getLegendKeyFromColor: getLegendKeyFromColor,
        toHexColor: toHexColor,
        hexToRgba: hexToRgba,
        stripHash: stripLeadingHash,
    };

    return service;

    function stripLeadingHash(color) {
        if (angular.isString(color)) {
            var isStartingWithHash = color.indexOf("#") === 0;
            if (isStartingWithHash) {
                return color.substr(1);
            }
        }
        return color;
    }

    function toHexColor(color) {
        if (!angular.isString(color)) {
            return;
        }
        var tmpColor = color;
        var startsWithHash = tmpColor.charAt(0) === "#";

        if (startsWithHash) {
            var hashLessColor = tmpColor.slice(1, tmpColor.length);
            if (_isValidColor(hashLessColor)) {
                return tmpColor;
            }
        } else {
            if (_isValidColor(tmpColor)) {
                return "#" + tmpColor;
            }
        }
    }

    function _isValidColor(color) {
        return color.match(/^([0-9a-f]{3}|[0-9a-f]{6})$/i);
    }

    function getRandomColor() {
        return COLORS[~~(Math.random() * 100) % COLORS.length];
    }

    /**
     * getStatusColors - Returns the colors of the states in which a deliverable can be
     *
     * @return {Object}  - All the colors of the states in which a deliverable can be
     */
    function getStatusColors() {
        return {
            behind: PROGRESS_COLORS.BEHIND_SCHEDULE,
            onTime: PROGRESS_COLORS.ON_TIME,
            claims: PROGRESS_COLORS.HAS_CLAIMS,
            obstructions: PROGRESS_COLORS.HAS_OBSTRUCTIONS,
            claims_outline: PROGRESS_COLORS.HAS_CLAIMS_OUTLINE,
            obstructions_outline: PROGRESS_COLORS.HAS_OBSTRUCTIONS,
            waiting_for_confirmation: PROGRESS_COLORS.WAITING_FOR_CONFIRMATION,
        };
    }

    function getLegendKeyFromColor(color) {
        var availableColors = getStatusColors(),
            key = "";
        switch (color) {
            case availableColors.behind:
                key = LEGEND_KEYS.BEHIND_SCHEDULE;
                break;
            case availableColors.onTime:
                key = LEGEND_KEYS.ON_TIME;
                break;
            case availableColors.claims:
            case availableColors.claims_outline:
                key = LEGEND_KEYS.HAS_CLAIMS;
                break;
            case availableColors.obstructions:
            case availableColors.obstructions_outline:
                key = LEGEND_KEYS.HAS_OBSTRUCTIONS;
                break;
            case availableColors.waiting_for_confirmation:
                key = LEGEND_KEYS.WAITING_FOR_CONFIRMATION;
                break;
        }
        return key;
    }

    /**
     * Return a color representing the progress -> is used to set the color of the task bars.
     *
     * //param {Number} progress - How much progress is done so far
     * @param {boolean} behind - If given task is behind
     * @param {boolean} hasIssues - If given task has issues
     * @returns {*}
     * @private
     */
    function toStatusColor(behind, hasIssues) {
        var statusColors = getStatusColors();
        if (behind === 1) {
            return statusColors.behind;
        } else {
            // If it's on time but has open issues, the task will be yellow.
            if (hasIssues) {
                return statusColors.claims;
            } else {
                // Green if on time, without issues
                return statusColors.onTime;
            }
        }
    }

    /**
     * Return the classes representing the progress -> is used to set the color of the task progress bars.
     *
     * @param {Number} progress - How much progress is done so far
     * @param {Number} claims - How many open claims there are
     * @param {Number} obstructions - How many open obstructions there are
     * @param {Boolean} behind - If given task is behind
     * @returns {*}
     * @private
     */
    function toStatusClasses(progress, behind, claims, obstructions) {
        return (
            toProgressClass(progress) +
            " " +
            _toBehindClass(behind) +
            " " +
            toStatsClass(claims, obstructions)
        );
    }

    function toProgressClass(progress) {
        return (
            PROGRESS_TO_TASK_CLASS_MAP[progress] ||
            PROGRESS_TO_TASK_CLASS_MAP[50]
        );
    }

    function toProgressColor(progress) {
        return PROGRESS_TO_COLOR_MAP[progress] || PROGRESS_TO_COLOR_MAP[50];
    }

    function _toBehindClass(behind) {
        if (behind === 1 || behind === true) {
            return TASK_CLASSES.BEHIND_SCHEDULE;
        } else if (behind === 0 || behind === false) {
            return TASK_CLASSES.ON_TIME;
        } else {
            return TASK_CLASSES.UNKNOWN;
        }
    }

    function toStatsClass(claims, obstructions) {
        if (claims + obstructions === 0) {
            return "";
        }
        var classes = "";
        if (claims > 0) {
            classes += TASK_CLASSES.HAS_CLAIMS;
        }
        if (obstructions > 0) {
            classes += " " + TASK_CLASSES.HAS_OBSTRUCTIONS;
        }
        return classes;
    }

    function hexToRgba(hexColor, opacity) {
        hexColor = hexColor.replace("#", "");
        opacity = opacity || 100;
        var r = Number.parseInt(hexColor.substring(0, 2), 16);
        var g = Number.parseInt(hexColor.substring(2, 4), 16);
        var b = Number.parseInt(hexColor.substring(4, 6), 16);

        return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
    }
}
