import _ from "lodash";

export default function (
    $sbInspectionsApi,
    $sbTeam,
    $sbCurrentProject,
    SbDeliverable
) {
    "ngInject";

    function parseServerInspection(inspection) {
        const resolvedIssues = _.find(inspection.metrics, [
            "name",
            "closed_issues_count",
        ]);
        const createdNotes = _.find(inspection.metrics, [
            "name",
            "created_notes_count",
        ]);
        const modifiedDeliverables = _.find(inspection.metrics, [
            "name",
            "modified_deliverables_count",
        ]);

        const inspectionModel = _parseServerInspectionBase(inspection);
        inspectionModel.RESOLVED_ISSUES_COUNT = Number.parseInt(
            resolvedIssues.value
        );
        inspectionModel.CREATED_NOTES_COUNT = Number.parseInt(
            createdNotes.value
        );
        inspectionModel.MODIFIED_DELIVERABLES_COUNT = Number.parseInt(
            modifiedDeliverables.value
        );

        return inspectionModel;
    }

    function _parseServerInspectionBase(inspection) {
        const authorDisplayName = _.get(inspection, "created.by.name");
        let authorName;
        let company;
        if (authorDisplayName) {
            let tmp = authorDisplayName.split("(");
            authorName = tmp[0];
            company = tmp.length > 1 ? tmp[1].split(")")[0] : "";
        }
        return {
            ID: inspection.id,
            TYPE: inspection.type,
            CREATED_AT: inspection.created.at,
            AUTHOR_EMAIL: inspection.created.by.email,
            AUTHOR_INITIALS: inspection.created.by.initials,
            AUTHOR_DISPLAY_NAME: inspection.created.by.name,
            AUTHOR_SHORT: authorName,
            COMPANY: company,
            UPLOADED_AT: _.get(inspection, "last_updated.at"),
        };
    }

    function parseServerInspectionDetails(inspection) {
        const odataObject = _parseServerInspectionBase(inspection);
        odataObject.createdNotes = [];
        odataObject.resolvedNotes = [];
        odataObject.deliverables = [];

        const projectId = $sbCurrentProject.pick("id");
        return $sbTeam.getTeams(projectId).then((teams) => {
            inspection.update_details.forEach((detail) => {
                switch (detail.type) {
                    case $sbInspectionsApi.CREATED_NOTE:
                        odataObject.createdNotes.push(
                            mapInspectionIssueToOldModel(detail, teams)
                        );
                        break;
                    case $sbInspectionsApi.CLOSED_ISSUE:
                        odataObject.resolvedNotes.push(
                            mapInspectionIssueToOldModel(detail, teams)
                        );
                        break;
                    case $sbInspectionsApi.MODIFIED_DELIVERABLE:
                        odataObject.deliverables.push(
                            mapInspectionDeliverableToOldModel(detail)
                        );
                        break;
                }
            });
            return odataObject;
        });
    }

    function mapInspectionDeliverableToOldModel({ data }) {
        const domain = new SbDeliverable(data.id, data.name, data.code);
        domain.desc = data.description;
        return domain;
    }

    function mapInspectionIssueToOldModel({ data }, teams) {
        return {
            // general info
            ISSUE_ID: data.id,
            ISSUE_STATUS: {
                resolved: "D",
                open: "A",
                info: "A",
            }[data.status],
            ISSUE_TYPE: {
                info: "INFO",
                obstruction: "OBSTRUCTION",
                quality_issue: "CLAIM",
            }[data.type],
            ISSUE_TEXT: data.text,
            ISSUE_NAME: data.code,

            // last update
            LAST_UPDATE_TIME: _.get(data, "last_updated.at"),
            LAST_UPDATER_DISPLAY_NAME: _.get(data, "last_updated.by.name"),
            LAST_UPDATER_INITIALS: _.get(data, "last_updated.by.initials"),

            // deliverable
            ROOT_COMPONENT_ID: _.get(data, "deliverable.id"),
            ROOT_COMPONENT_CODE: _.get(data, "deliverable.code"),
            ROOT_COMPONENT_NAME: _.get(data, "deliverable.name"),
            ROOT_COMPONENT_DESC: _.get(data, "deliverable.description"),

            // activity or group
            REF_COMPONENT_ID: _.get(data, "activity.id"),
            REF_COMPONENT_NAME: _.get(data, "activity.name"),
            REF_COMPONENT_DESC: _.get(data, "activity.description"),

            RESPONSIBLE_TEAM: $sbTeam.findTeamById(
                teams,
                _.get(data, "assigned_team.id", null)
            ),
        };
    }

    return {
        parseServerInspection: parseServerInspection,
        parseServerInspectionDetails: parseServerInspectionDetails,
    };
}
