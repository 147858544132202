import Component from "./Component";
import Activity from "./Activity";

/**
 * @typedef Edge
 * @type {Object}
 * @property {(string|number)} source - The source of the edge
 * @property {(string|number)} target - The target of the edge
 */

/**
 *  Create a graph representation for components and edges
 *
 * @param {Object[]} components - The components of the graph as simple objects.
 * @param {string} components.id - The id of the component.
 *
 * @param {Edge[]} edges - The edges that should be added between the components
 *
 * @class
 * @constructor
 */
function Graph(components, edges) {
    /**
     * The root component of the graph,
     * which has no parent.
     *
     * @member {Component}
     */
    this.rootComponent = null;

    /**
     * A object literal map which holds all components of the graph.
     * The component id is mapped to the actual component.
     *
     * @member {Object<String, Component>}
     */
    this.components = {};

    // if components are given - include them
    //
    if (components) {
        // create the component map
        //
        components.forEach(function (element) {
            this.components[element.id] = this._createNewElement(
                element.id,
                element
            );
        }, this);

        // setup the parent - child relation
        //
        Object.keys(this.components).forEach(function (id) {
            var currentComponent = this.getComponent(id),
                parentRef = this.getComponent(
                    currentComponent.properties.parentId
                );

            currentComponent.setParent(parentRef);

            // if it has no parent it must be root
            //
            if (!parentRef && this.rootComponent === null) {
                this.rootComponent = currentComponent;
            }
        }, this);

        // connect the edges -> create predecessor and successor maps
        //
        if (edges) {
            edges.forEach(function (edge) {
                var source = this.getComponent(edge.source),
                    target = this.getComponent(edge.target);

                target.addPredecessor(source);
            }, this);
        }
    }

    this.refreshCodes();
}

/**
 * Compare function based on Component's
 *
 * @param {Component} first - first element for compare
 * @param {Component} second - second element for compare
 * @returns {number} greater, lesser or equal
 *
 * @private
 */
Graph._sortByName = function (first, second) {
    var aWithID = first.getData().name.toLowerCase() + first.getData().id;
    var bWithID = second.getData().name.toLowerCase() + second.getData().id;
    if (aWithID > bWithID) {
        return 1;
    } else if (aWithID < bWithID) {
        return -1;
    } else {
        return 0;
    }
};

/**
 * Creates a new Graph node
 *
 * @param {string} id - id of the new node
 * @param {Object} data - data of the new node
 * @returns {Component}
 * @private
 */
Graph.prototype._createNewElement = function (id, data) {
    return new Component(id, data);
};

/**
 * Getter for a component reference.
 *
 * @param {(string|number)} id - The id of the component.
 * @returns {Component} The reference to the component or undefined.
 */
Graph.prototype.getComponent = function (id) {
    return this.components[id];
};

/**
 * Add a component to the component map of the graph.
 * The id is the key for the component inside the map.
 *
 * Only components of type "Component" are added to the map.
 * This is checked via instanceof.
 *
 * @param {(number|string)} id - The ID of the new component.
 * @param {Component} component - The component object.
 */
Graph.prototype.addComponent = function (id, component) {
    // only add components of the right type to the map
    //
    if (component instanceof Component) {
        this.components[id] = component;
    }
    this.refreshCodes();
};

/**
 * Adds an array of components to the components map hold by the graph.
 * It simply iterates over the array and add component to the map.
 *
 * There is no check for duplicate IDs in the map.
 *
 * @param {Component[]} components - List of components to be added to the graph.
 */
Graph.prototype.addComponents = function (components) {
    components.forEach(function (element) {
        this.components[element.id] = element;
    }, this);
    this.refreshCodes();
};

/**
 * Creates a new component with the given id and data and puts it as child
 * under the component with the given parentId.
 *
 * @param {(string|number)} id - The id for the newly created component.
 * @param {(string|number)} parentId - The id of the parent component.
 * @param {Object} data - Simple object literal holding additional information.
 *
 * @returns {Component} The newly created component object.
 */
Graph.prototype.newComponentToParent = function (id, parentId, data) {
    var newComponent = this._createNewElement(id, data),
        parentComponent = this.getComponent(parentId);

    newComponent.setParent(parentComponent);
    this.addComponent(id, newComponent);

    return newComponent;
};

/**
 * Remove a component from the graph by disconnecting all of its references.
 *
 * @param {(string|number)} id - The id for the node to be removed.
 * @returns {{removedComponents: Array.<Component>, removedInterDependencies: Array.<Edge>}}
 */
Graph.prototype.removeNode = function (id) {
    var component = this.getComponent(id);
    var removedComponents = [component].concat(component.getChildrenDeep());

    // disconnect the component from the parent
    //
    component.setParent(null);

    // Collect all edges between the removed part and the other part

    /**
     * Check if given component is not part of the removed components list.
     *
     * @private
     *
     * @param {Component} element - Component to check against.
     * @returns {boolean} Returns true if component is not in removed list.
     */
    function _notInRemovedList(element) {
        return removedComponents.indexOf(element) < 0;
    }

    /**
     * Remove the all predecessors from the given element by calling
     * the returned function with a component as parameter.
     *
     * The given component is hold in the context of the outer function
     * and can not be changed.
     *
     * @private
     *
     * @param {Component} that - The component where the predecessors should be removed from.
     * @returns {Function} Inner function to actually remove the predecessor.
     */
    function _removePredecessors(that) {
        return function (element) {
            return that.removePredecessor(element);
        };
    }

    /**
     * Same as _removePredecessors for successors.
     *
     * @private
     *
     * @param {Component} that -  The component where the successors should be removed from.
     * @returns {Function} Inner function to actually remove the successor.
     */
    function _removeSuccessors(that) {
        return function (element) {
            return that.removeSuccessor(element);
        };
    }

    // check all predecessors and all successors of the removed components
    // -> if the dependent components are not in the removed list -> remove them and store them
    //
    var interDependencies = removedComponents.reduce(function (
        interDependenciesInner,
        element
    ) {
        var ingoingInterDep = element
            .getPredecessors()
            .filter(_notInRemovedList)
            .map(_removePredecessors(element));
        var outgoingInterDep = element
            .getSuccessors()
            .filter(_notInRemovedList)
            .map(_removeSuccessors(element));

        return interDependenciesInner
            .concat(ingoingInterDep)
            .concat(outgoingInterDep);
    }, []);

    // remove from the graph
    //
    removedComponents.forEach(function (element) {
        delete this.components[element.id];
    }, this);

    this.refreshCodes();

    return {
        removedComponents: removedComponents,
        removedInterDependencies: interDependencies,
    };
};

/**
 * Add a new dependency by setting the target component
 * as successor of source component.
 *
 * If no Components are given it's searching for them by id.
 *
 * @param {Component|number|string} source - Component gets target component as successor.
 * @param {Component|number|string} target - Successor component of source component.
 * @returns {Number}
 */
Graph.prototype.addDependency = function (source, target) {
    if (!(source instanceof Component)) {
        source = this.getComponent(source);
    }

    if (!(target instanceof Component)) {
        target = this.getComponent(target);
    }

    // one direction is enough - the other direction is maintained by this call
    //
    return source.addSuccessor(target);
};

Graph.prototype.deleteDependency = function (source, target) {
    if (!(source instanceof Component)) {
        source = this.getComponent(source);
    }

    if (!(target instanceof Component)) {
        target = this.getComponent(target);
    }
    return source.removeSuccessor(target);
};

Graph.prototype.getComponents = function () {
    return Object.keys(this.components).map(function (c) {
        return this.components[c];
    }, this);
};

/**
 * Append a graph to be part of this graph.
 *
 * @param {Graph} otherGraph - The graph to merge into this.
 * @param {(string|number)} parentId - Component id, where new graph root is merged under as child.
 */
Graph.prototype.merge = function (otherGraph, parentId) {
    // shift all components from otherGraph to this one
    //
    this.addComponents(otherGraph.getComponents());

    // set parent of copied root
    //
    var otherGraphRootId = otherGraph.getRoot().id;
    this.getComponent(otherGraphRootId).setParent(this.getComponent(parentId));

    this.refreshCodes();
};

/**
 * Getter for root component of graph.
 * Currently the graph maintains only one root component,
 * but in future it could be that there are more than one.
 *
 * @returns {?Component} The root component or null.
 */
Graph.prototype.getRoot = function () {
    return this.rootComponent;
};

/**
 * Getter for all predecessors of the component with the given id.
 *
 * @param {(string|number)} id - Id of a component of the graph.
 * @returns {Array.<Component>} If the given id is not found an empty array is returned, else it contains the predecessors.
 */
Graph.prototype.getPredecessors = function (id) {
    var component = this.getComponent(id);

    if (typeof component === "undefined") {
        return [];
    }

    // return a new array
    //
    return [].concat(component.getPredecessors());
};

/**
 * Collect all edges of the graph as objects with source and target
 * while source and target are components of the graph.
 *
 * @returns Array<Object> list of edge objects
 */
Graph.prototype.collectEdges = function () {
    // for all components of the graph pick up their successors and create objects
    // from these pairs.
    return this.getComponents().reduce(function (list, component) {
        return list.concat(
            component.getSuccessors().map(function (successor) {
                return {
                    source: component,
                    target: successor,
                };
            })
        );
    }, []);
};

/**
 * Getter for all successors of the component with the given id.
 *
 * @param {(string|number)} id - Id of a component of the graph.
 * @returns {Array.<Component>} If the given id is not found an empty array is returned, else it contains the successors.
 */
Graph.prototype.getSuccessors = function (id) {
    var component = this.getComponent(id);

    if (typeof component === "undefined") {
        return [];
    }

    // return a new array
    //
    return [].concat(component.getSuccessors());
};

/**
 * Return all child component data from the component with the given id.
 *
 * @param {(number|string)} id - The component id to search for.
 * @returns {Object[]} Properties objects from components.
 */
Graph.prototype.getChildComponentsData = function (id) {
    var sortedSet = this.getComponent(id)
        .getChildren()
        .sort(Graph._sortByName)
        .map(function (element) {
            return element.getData();
        });
    return sortedSet;
};

/**
 * Set the parent relationship between node and parent
 *
 * @param {Component|String} node - a node or a node id
 * @param {Component|String} parent - the new parent or parent id of the node
 */
Graph.prototype.changeParent = function (node, parent) {
    if (!(node instanceof Component)) {
        node = this.getComponent(node);
    }
    if (!(parent instanceof Component)) {
        parent = this.getComponent(parent);
    }
    node.setParent(parent);
    this.refreshCodes();
};

/**
 * Compute all the codes for all components in the hierarchy. Start with the root and take is as base code
 */
Graph.prototype.refreshCodes = function () {
    var root = this.rootComponent;
    var todo = [root];
    var current = todo.pop();

    function _updateCode(component, index) {
        component.getData().code =
            component.getParent().getData().code + "." + (index + 1);
    }

    function _addToQueue(component) {
        todo.push(component);
    }

    while (current) {
        current.getChildren().sort(Graph._sortByName);
        current.getChildren().forEach(_updateCode);
        current.getChildren().forEach(_addToQueue);
        current = todo.pop();
    }
};

/**
 * Produces a JSON representation of the graph.
 * Returns an object literal with two array containing information
 * about components and edges.
 *
 * @returns {{components: Array, edges: Array}}
 */
Graph.prototype.toJSON = function () {
    var edges = [],
        components = Object.keys(this.components).map(function (id) {
            const component = this.getComponent(id);
            const data = component.getData();

            if (component instanceof Activity) {
                data.isActivity = true;
            } else {
                data.isActivity = false;
            }

            data.parentId = component.getParent()
                ? component.getParent().id
                : undefined;

            // create the edges as well
            //
            if (component.hasSuccessors()) {
                component.getSuccessors().forEach(function (successor) {
                    edges.push({
                        target: successor.id,
                        source: component.id,
                    });
                });
            }

            return data;
        }, this);

    return {
        components: components,
        edges: edges,
    };
};

export default Graph;
