import htmlTemplate from "./sb_select_file.html";

export default function sbSelectFile() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        scope: {
            tutorialLink: "<?",
            selectFileSummary: "<?",
        },
        controllerAs: "selectFile",
        bindToController: true,
        controller: function ($rootScope) {
            "ngInject";

            /////////////////////
            //
            //      Direct variables
            //
            /////////////////////

            const vm = this;
            vm.openFileChooser = _openFileChooser;

            function _openFileChooser($event) {
                $rootScope.$broadcast("sbFileInput.open", $event);
            }
        },
    };
}
