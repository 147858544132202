import angular from "angular";
import htmlTemplate from "./checklist_details_panel.html";

export default angular
    .module("sbApp.sbChecklistDetails", [])
    .directive("sbChecklistDetails", function () {
        return {
            restrict: "E",
            replace: true,
            bindToController: true,
            controllerAs: "$ctrl",
            templateUrl: htmlTemplate,
            scope: {
                activityChecklist: "<",
                deliverable: "<",
                activity: "<",
                viewTypeComingFrom: "<",
                onClose: "&",
            },
            controller: function ChecklistDetailsCtrl(
                $sbCurrentProject,
                $sbStructure
            ) {
                "ngInject";
                const vm = this;
                vm.activate = activate;

                function activate() {
                    const el = document.getElementById(
                        "angular-checklist-viewer"
                    );
                    const checklistIdsToView = pickChecklistsFromModel(
                        vm.activityChecklist
                    );

                    el.activityId = vm.activity.id;
                    el.activityName = vm.activity.name;
                    el.activityState = vm.activity.state.current.toLowerCase();

                    el.deliverableName = vm.deliverable.name;
                    el.deliverableId = vm.deliverable.id;
                    el.viewTypeComingFrom = vm.viewTypeComingFrom;
                    el.isRejectedSequence =
                        vm.activityChecklist.sequenceStatus === "rejected";
                    el.teamBasedColumns =
                        vm.activityChecklist.qualityPipeline.teams.map(
                            (team) => team.id
                        );

                    el.checklistIds = checklistIdsToView;
                    if (checklistIdsToView.length === 0) {
                        el.checklistTemplateId = pickChecklistTemplateFromModel(
                            vm.activityChecklist
                        );
                    }

                    $sbStructure
                        .node(
                            $sbCurrentProject.pick("id"),
                            vm.deliverable.structureId
                        )
                        .then(
                            (node) =>
                                (el.location = node?.PARENT_PATH.join(" / ")) ||
                                ""
                        );
                }

                function pickChecklistsFromModel(activityChecklist) {
                    const checklistIds = [];
                    if (
                        activityChecklist.executionChecklist &&
                        activityChecklist.executionChecklist.type !== "template"
                    ) {
                        checklistIds.push(
                            activityChecklist.executionChecklist.id
                        );
                    }
                    if (activityChecklist.confirmationChecklist) {
                        checklistIds.push(
                            activityChecklist.confirmationChecklist.id
                        );
                    }
                    if (activityChecklist.reviewChecklists) {
                        activityChecklist.reviewChecklists.forEach(
                            (checklist) => {
                                checklistIds.push(checklist.id);
                            }
                        );
                    }
                    return checklistIds.filter((id) => id);
                }

                function pickChecklistTemplateFromModel(activityChecklist) {
                    return activityChecklist.templateChecklist.id;
                }
            },
            link: function (scope, element, attrs, controller) {
                scope.$watch(
                    "$ctrl.activityChecklist",
                    function (activityChecklist) {
                        if (activityChecklist) {
                            controller.activate();
                        }
                    }
                );
            },
        };
    });
