//
// CONTENT TO COPY TO BACKEND STARTS HERE !
//

/**
 * GraphNode - Initialize the graph node adding the needed properties
 *
 * @param  {item} item - The data for the graph node.
 * @param  {item} item.id - The identifier for the graph node.
 *
 * @param  {SimpleGraph} graph - The corresponding graph
 *
 * @constructor
 */
function GraphNode(item, graph) {
    this.id = item.id;
    this.successors = [];
    this.predecessors = [];
    this.graph = graph;

    this.data = item;

    this.isVisited = false;
}

//
// CONTENT TO COPY TO BACKEND ENDS HERE !
//

export default GraphNode;
