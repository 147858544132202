import "./services/qrCardService/qrCardService";
export default function WbsCtrl(
    $scope,
    $mdDialog,
    $mdToast,
    Analytics,
    qrCardService,
    $sbStructureNodeJobsApi,
    QR_CODE_TEMPLATE,
    $sbErrorPresenter,
    $sbCurrentProject,
    wbsApiService
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var dialogSecondaryOptions = [
        {
            description: "QR_DIALOG_STRUCTURE_ALL_TEXT",
            id: "ALL",
        },
        {
            description: "QR_DIALOG_STRUCTURE_SELECTION_TEXT",
            id: "SELECTION",
        },
        {
            description: "QR_DIALOG_LAST_LEVEL_TEXT",
            id: "LEAFS",
        },
    ];

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.isMergeStructurePossible = true;
    vm.isPdfPrintingProgress = false;

    vm.openQrDialog = openQrDialog;
    vm.openCodeOverwriteWarningDialog = openCodeOverwriteWarningDialog;
    vm.onMergeProjectStructure = onMergeProjectStructure;

    vm.$onInit = _activate;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function _activate() {
        vm.projectID = $sbCurrentProject.pick("id");
    }

    function onMergeProjectStructure() {
        const busyDialog = $mdDialog
            .busyIndication()
            .title("DIALOG_MERGE_STRUCTURE_LOADING_TITLE")
            .text("DIALOG_MERGE_STRUCTURE_LOADING_TEXT")
            .allPromises([wbsApiService.mergeTrees(vm.projectID)]);

        return $mdDialog
            .show(busyDialog)
            .then(() => $scope.$broadcast("reloadEditor"))
            .then(_showSuccess)
            .catch(function (error) {
                if (error) {
                    $sbErrorPresenter.catch(error);
                }
            });
    }

    function _showSuccess() {
        const successToast = $mdToast
            .simple()
            .content("DIALOG_MERGE_STRUCTURE_SUCCESS_TITLE");

        $mdToast.show(successToast);
    }

    function openQrDialog($event) {
        const dialogConfig = $mdDialog
            .select()
            .title("QR_DIALOG_TITLE")
            .ok("_CONTINUE")
            .selectOptions(QR_CODE_TEMPLATE)
            .targetEvent($event)
            .selectedOption(QR_CODE_TEMPLATE[0])
            .secondSelectedOption(dialogSecondaryOptions[0])
            .secondSelectOptions(dialogSecondaryOptions);

        return $mdDialog
            .show(dialogConfig)
            .then(printQrCodesFor)
            .catch((error) => {
                if (!error) return;
                $sbErrorPresenter.catch(error);
            });
    }

    function printQrCodesFor(selectedOption) {
        const sProjectId = vm.projectID;
        const sLayout = selectedOption.select.type;
        const sFilterType = selectedOption.secondSelect.id;

        return collectItemsToPrint(sProjectId, sFilterType).then((items) => {
            if (items.length > selectedOption.select.maxDeliverables) {
                return _promptPrintToLarge(
                    selectedOption.select.maxDeliverables,
                    sLayout
                );
            } else {
                return printQrCodesForItems(sProjectId, sLayout, items);
            }
        });
    }

    function printQrCodesForItems(sProjectId, sLayout, items) {
        vm.isPdfPrintingProgress = true;
        console.log({ sProjectId, sLayout, items }); // eslint-disable-line angular/log

        return qrCardService
            .printStructureNodesToPdf(
                sProjectId,
                sLayout,
                items.map((n) => n.id)
            )
            .then(_onPrintSuccess)
            .finally(() => (vm.isPdfPrintingProgress = false));
    }

    function askForSelection(sProjectId) {
        return wbsApiService
            .loadList(sProjectId)
            .then((list) =>
                $mdDialog
                    .multiSelectionList()
                    .title("QR_DIALOG_SELECT_STRUCTURE_TITLE")
                    .list(list)
                    .listDisplayKeys(["code", "label"])
            )
            .then((dialog) => $mdDialog.show(dialog));
    }

    function _promptPrintToLarge(maxNumberOfItems, layoutType) {
        Analytics.trackEvent(
            "sb_known_errors",
            "export_report",
            "Structure QR Codes | " + layoutType
        );
        $mdDialog.show(
            $mdDialog
                .alert()
                .contentValues({
                    max: maxNumberOfItems,
                })
                .title("ERROR_REPORT_SIZE_LIMIT_TITLE")
                .content("QR_DIALOG_WBS_ERROR_REPORT_SIZE_MESSAGE")
        );
    }

    function collectItemsToPrint(sProjectId, sFilterType) {
        switch (sFilterType) {
            case "SELECTION": {
                return askForSelection(sProjectId);
            }
            case "LEAFS": {
                return wbsApiService
                    .loadList(sProjectId)
                    .then((list) => list.filter((n) => n.isLeaf));
            }
            case "ALL": {
                return wbsApiService.loadList(sProjectId);
            }
            default: {
                return Promise.reject(new Error("Unknown filter type"));
            }
        }
    }

    function _onPrintSuccess() {
        vm.isPdfPrintingProgress = false;
        return $mdToast.show(
            $mdToast
                .simple()
                .content("PDF_GEN_PROGRESS")
                .position("top right")
                .hideDelay(2000)
        );
    }

    /**
     * Wrapper function to call code generation service.
     *
     * @returns {Object.<String, String>} The generated codes with componentId as Key and code as value.
     */
    function generateCodes() {
        return $sbStructureNodeJobsApi
            .generateCodes(vm.projectID)
            .then(({ structure_codes }) => {
                $scope.$broadcast("codeChanged", structure_codes);
                showCodeGenerationSuccessToast();
            })
            .catch($sbErrorPresenter.catch);
    }

    /**
     * Show a toast if component code generation was successful.
     */
    function showCodeGenerationSuccessToast() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("CODE_GENERATION_SUCCESS_MESSAGE")
                .position("top right")
                .hideDelay(1000)
        );
    }

    /**
     * Open up a confirmation dialog for the user to accept overwrite
     * of component codes. Returns to normal screen if user did not allow
     * overwrite.
     *
     * @param {Object} $event - see angular docs
     */
    function openCodeOverwriteWarningDialog($event) {
        $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .title("CODE_GENERATION_CONFIRM_DIALOG_TITLE")
                    .titleIcon("mdi mdi-auto-fix")
                    .content("CODE_GENERATION_CONFIRM_DIALOG_CONTENT")
                    .cancel("NO")
                    .ok("YES")
                    .targetEvent($event)
            )
            .then(generateCodes);
    }
}
