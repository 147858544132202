export default class DiaryEntriesWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;
    }

    /**
     * This endpoint returns a list representation of the project diary entries.
     *
     * @param projectId {string}    UUID
     */
    getCollection(projectId) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/diary-entries`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint returns the diary by date.
     *
     * @param projectId {string}    UUID
     * @param diaryDate {string}    The unique identifier of the diaryDa you are requesting a resource for.
     */
    get(projectId, diaryDate) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/diary-entries/${diaryDate}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "GET",
        });
    }

    /**
     * This endpoint creates a new diary entry in the project for a given day.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     * @param body.date {string}    A date string in the format YYYY-MM-DD
     */
    create(projectId, { date }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/diary-entries`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: { date },
        });
    }

    /**
     * This endpoint allows to update some properties.
     *
     * @param projectId {string}    UUID
     * @param diaryDate {string}    The unique identifier of the diaryDa you are requesting a resource for.
     *
     * @param body {Object}    Request body
     * @param [body.is_finalized] {boolean}    If true marks this entry as 'final' or 'closed' which will lead to all following update attempts are denied.
     * @param [body.department] {string}    Name of the section, department, company or owner of the project
     * @param [body.machines] {string}    Text describing the machines situation on that day.
     * @param [body.events] {string}    Text describing special events or situations on that day.
     * @param [body.working_time] {object}    The actual working times of that day.
     * @param [body.weather] {object}    The actual weather conditions on that day
     * @param [body.teams] {array}    List of teams with their availability on that day.
     */
    update(
        projectId,
        diaryDate,
        {
            is_finalized,
            department,
            machines,
            events,
            working_time,
            weather,
            teams,
        }
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/diary-entries/${diaryDate}`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "PUT",
            body: {
                is_finalized,
                department,
                machines,
                events,
                working_time,
                weather,
                teams,
            },
        });
    }
}
