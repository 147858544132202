import _ from "lodash";
import moment from "moment";
import CostGroup from "../model/cost_group.class";
import CostGroupStage from "../model/cost_group_stage.class";
import CostGroupMembership from "../model/cost_group_membership.class";
import CombinedCostGroupStage from "../model/combined_cost_group_stage.class";

export default function CommercialApiService(
    $sbCostPackagesApi,
    $sbCostPackageMembershipsApi,
    $sbPerformanceApi,
    $sbCurrentProject,
    $log
) {
    "ngInject";

    /////////////////////
    //
    //      API
    //
    /////////////////////

    var service = {
        createCostGroup: createCostGroup,
        getAllCostGroups: getAllCostGroups,
        getCostGroup: getCostGroup,
        getCostGroupStages: getCostGroupStages,
        syncCostGroup: syncCostGroup,
        deleteCostGroup: deleteCostGroup,
        getMembershipsOfCostGroup: getMembershipsOfCostGroup,
        createMembershipOnCostGroup: createMembershipOnCostGroup,
        deleteMembershipFromCostGroup: deleteMembershipOnCostGroup,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function createCostGroup(name, templateStageIds) {
        return _create(name, templateStageIds)
            .then(_parseCreateResponseToModel)
            .then((costGroup) =>
                _getAndSetStageStats(costGroup.stages, moment()).then(
                    () => costGroup
                )
            );
    }

    function getAllCostGroups(projectId) {
        return _getAllCostPackages(projectId).then(({ cost_packages }) =>
            cost_packages.map(_parseCreateResponseToModel)
        );
    }

    function getCostGroup(costGroupId) {
        return $sbCostPackagesApi
            .get(costGroupId)
            .then((cost_package) => _parseCreateResponseToModel(cost_package));
    }

    function getMembershipsOfCostGroup(id) {
        return $sbCostPackageMembershipsApi
            .getCollection(id)
            .then(({ cost_package_memberships }) => {
                return cost_package_memberships.map((rawMembership) => {
                    return new CostGroupMembership(
                        rawMembership.user_id,
                        rawMembership.role === "manager"
                    );
                });
            });
    }

    function deleteCostGroup(costGroupId) {
        return $sbCostPackagesApi.delete(costGroupId);
    }

    function _parseCreateResponseToModel(result) {
        const costGroup = new CostGroup(result.id, result.name);
        costGroup.setBudget(result.budget);
        costGroup.setCurrency(result.currency);

        result.activity_templates.forEach((item) =>
            costGroup.addStage(_parseResponseItemToModel(item))
        );

        if (!_.isEmpty(result.activity_template_groups)) {
            result.activity_template_groups.forEach((item) =>
                costGroup.addStage(_parseMultipleResponseItemsToModel(item))
            );
        }

        return costGroup;
    }

    function _parseResponseItemToModel(item) {
        const costGroupStage = new CostGroupStage(
            item.id,
            item.name,
            item.weight
        );
        costGroupStage.setNamePrefix((item.group || []).join(" > "));
        costGroupStage.setConfirmable(
            Number.isInteger(item.confirmation_team_id)
        );

        costGroupStage.setProcessTemplate(item.process_template);
        return costGroupStage;
    }

    function _parseMultipleResponseItemsToModel(activityTemplateGroup) {
        const costGroupStage = new CombinedCostGroupStage(
            activityTemplateGroup.id,
            activityTemplateGroup.name,
            activityTemplateGroup.weight
        );

        costGroupStage.setChildCostGroupStages(
            activityTemplateGroup.activity_templates.map((activityTemplate) =>
                _parseResponseItemToModel(activityTemplate)
            )
        );
        return costGroupStage;
    }

    /**
     * Create a cost group with stages for a project
     *
     * @param {string} name
     * @param {Array<string>} stageTemplateIds
     *
     * @returns {string} id
     * @private
     */
    function _create(name, stageTemplateIds) {
        return $sbCostPackagesApi.create({
            name,
            budget: 100000, // default
            currency: "EUR", // default
            activity_templates: stageTemplateIds.map((templateId) => {
                return {
                    id: templateId,
                    weight: 0,
                };
            }),
        });
    }

    /**
     * Get all of the cost packages for a project
     *
     * @param {string} projectId
     *
     * @returns {Promise<Array<Object>>}
     * @private
     */
    function _getAllCostPackages(projectId) {
        return $sbCostPackagesApi.getCollection({ projectId });
    }

    function syncCostGroup(costGroup) {
        // Separate the individual activity templates and the ones merged as group
        //
        const { stagesWithChildren, stagesWithoutChildren } = _.groupBy(
            costGroup.stages,
            (stage) =>
                stage.childCostGroupStages
                    ? "stagesWithChildren"
                    : "stagesWithoutChildren"
        );

        const mapToIdAndWeight = (stages) =>
            (stages || []).map(({ id, weight }) => ({
                id,
                weight,
            }));

        return $sbCostPackagesApi.update(costGroup.id, {
            name: costGroup.name,
            budget: costGroup.budget,
            currency: costGroup.currency,
            activity_templates: mapToIdAndWeight(stagesWithoutChildren),
            activity_template_groups: mapToIdAndWeight(stagesWithChildren),
        });
    }

    /**
     *
     *
     * @param {CostGroup} costGroup
     * @param {moment.Moment} asReportedAt
     * @return {Promise<Array<CostGroupStage>>}
     */
    function getCostGroupStages(costGroup, asReportedAt) {
        return _getAndSetStageStats(costGroup.stages, asReportedAt);
    }

    /**
     * Get stats for each cost group stage
     *
     * @param {Array<CostGroupStage>} costGroupStages
     * @param {moment.Moment} asReportedAt
     *
     * @returns {Promise<Array<CostGroupStage>>}
     *
     * @private
     */
    function _getAndSetStageStats(costGroupStages, asReportedAt = moment()) {
        const projectId = $sbCurrentProject.pick("id");
        const activityTemplateIds = costGroupStages
            .reduce((activityStages, stage) => {
                if (stage instanceof CombinedCostGroupStage) {
                    activityStages = activityStages.concat(
                        stage.childCostGroupStages
                    );
                } else {
                    activityStages.push(stage);
                }
                return activityStages;
            }, [])
            .map(({ id }) => id);

        return $sbPerformanceApi
            .getCollection(projectId, {
                date_range: {
                    start_date: Number.NEGATIVE_INFINITY.toString(),
                    end_date: asReportedAt.endOf("day").toISOString(),
                },
                requests: [
                    {
                        metric: {
                            expression: "sb::count-activity",
                        },
                        dimension: {
                            name: "sb::activity-template-id",
                            filter: {
                                operator: "IN_LIST",
                                expression: activityTemplateIds,
                            },
                        },
                        pivot: {
                            metric: {
                                expression: "sb::count-activity",
                            },
                            dimension: {
                                name: "sb::current-state",
                            },
                        },
                    },
                ],
            })
            .then((result) => {
                costGroupStages.forEach((stage) => {
                    if (stage instanceof CombinedCostGroupStage) {
                        const childStagesWithStats =
                            stage.childCostGroupStages.map((childStages) =>
                                _setStatsForStageFromResults(
                                    childStages,
                                    result,
                                    asReportedAt
                                )
                            );
                        stage.setChildCostGroupStages(childStagesWithStats);
                    } else {
                        _setStatsForStageFromResults(
                            stage,
                            result,
                            asReportedAt
                        );
                    }
                });
                return costGroupStages;
            });
    }

    function _setStatsForStageFromResults(stage, result, asReportedAt) {
        const report = result[stage.id];
        if (report) {
            stage.setStats(
                {
                    started: report.started,
                    rejected: report.rejected,
                    waitingForConfirmation: report.waiting_for_confirmation,
                    done: report.done,
                    confirmed: report.confirmed,
                    total: report.total,
                },
                asReportedAt
            );
        }
        return stage;
    }

    function createMembershipOnCostGroup(
        costGroupPackageId,
        memberDbName,
        canWrite
    ) {
        return $sbCostPackageMembershipsApi.create(costGroupPackageId, {
            user_id: memberDbName,
            role: canWrite ? "manager" : "viewer",
        });
    }

    function deleteMembershipOnCostGroup(costGroupPackageId, memberDbName) {
        return $sbCostPackageMembershipsApi.delete(
            costGroupPackageId,
            memberDbName
        );
    }
}
