import angular from "angular";
import _ from "lodash";
import moment from "moment";
import CostGroup from "../model/cost_group.class";
import CostGroupMembership from "../model/cost_group_membership.class";

export default function CommercialService(
    $sbCommercialApi,
    $sbCostPackageGroupsApi,
    $q,
    $translate,
    $sbLocale
) {
    "ngInject";

    /////////////////////
    //
    //      API
    //
    /////////////////////

    var service = {
        costGroups: [],
        fetchAllCostGroupsByProject: fetchAllCostGroupsByProject,
        loadCostGroupStages: loadCostGroupStages,
        loadCostGroupMembership: loadCostGroupMembership,
        updateCostGroupMembership: updateCostGroupMembership,
        createCostGroup: createCostGroup,
        updateCostGroup: updateCostGroup,
        deleteCostGroup: deleteCostGroup,
        generateDataForCsvExport: generateDataForCsvExport,
        ungroupActivities: ungroupActivities,
        combineActivities: combineActivities,
        fetchCostGroup: fetchCostGroup,
    };

    return service;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function createCostGroup(costGroupData) {
        return $sbCommercialApi
            .createCostGroup(costGroupData.name, costGroupData.stages)
            .then(addCostGroup)
            .then(function (newCostGroup) {
                return loadCostGroupMembership(newCostGroup.id);
            });
    }

    function addCostGroup(newCostGroup) {
        service.costGroups = service.costGroups.concat([newCostGroup]);
        return newCostGroup;
    }

    function updateCostGroup(costGroupData) {
        return $sbCommercialApi.syncCostGroup(costGroupData);
    }

    function updateServiceCostGroups(costGroup, costGroupStages) {
        costGroup.stages = costGroupStages;
        return costGroup;
    }

    function deleteCostGroup(costGroup) {
        var backUp = angular.copy(costGroup);
        var oldIndex = _.findIndex(service.costGroups, ["id", backUp.id]);

        removeCostGroupById(costGroup.id);

        return $sbCommercialApi
            .deleteCostGroup(costGroup.id)
            .catch(function (error) {
                service.costGroups.splice(oldIndex, 0, backUp);
                return $q.reject(error);
            });
    }

    function removeCostGroupById(costGroupId) {
        service.costGroups = service.costGroups.filter(function (group) {
            return group.id !== costGroupId;
        });
    }

    /**
     * Load the cost group stage values into the service model for the given cost group id
     *
     * @param costGroup
     * @param asReportedAt
     * @return {Promise<Array<CostGroupStage>>}
     */
    function loadCostGroupStages(costGroup, asReportedAt) {
        asReportedAt = asReportedAt || moment();

        return $sbCommercialApi
            .getCostGroupStages(costGroup, asReportedAt)
            .then(function (costGroupStages) {
                return updateServiceCostGroups(costGroup, costGroupStages);
            });
    }

    function loadCostGroupMembership(id) {
        return $sbCommercialApi
            .getMembershipsOfCostGroup(id)
            .then(function (memberships) {
                var costGroup = _.find(service.costGroups, ["id", id]);
                if (!_.isUndefined(costGroup)) {
                    costGroup.membershipManager.add(memberships);
                }
                return costGroup;
            });
    }

    function updateCostGroupMembership(
        costGroup,
        selectedManagers,
        selectedReviewers
    ) {
        var currentMemberships = costGroup.membershipManager.getMemberships();

        var desiredMemberships = _getMembershipsBasedOnUserSelection(
            selectedManagers,
            selectedReviewers
        );

        // add brand new users first
        //
        return _deriveAndCreateNewMemberships(
            costGroup,
            currentMemberships,
            desiredMemberships
        )
            .then(function () {
                // determine which users have had their roles switched
                //
                return _deriveMovedMemberships(
                    currentMemberships,
                    desiredMemberships
                );
            })
            .then(function (movedMemberships) {
                // delete the old roles for these users
                //
                return _deletePreviousRoles(costGroup, movedMemberships);
            })
            .then(function (movedMemberships) {
                // create the new roles for these users
                //
                return _createNewRoles(costGroup, movedMemberships);
            })
            .then(function () {
                // remove any user no longer in the new membership
                //
                return _deriveAndRemoveOldMemberships(
                    costGroup,
                    currentMemberships,
                    desiredMemberships
                );
            })
            .then(function () {
                // return the updated membership to the caller
                //
                return desiredMemberships;
            });
    }

    function _getMembershipsBasedOnUserSelection(
        selectedManagers,
        selectedReviewers
    ) {
        var managers = selectedManagers.map(function (manager) {
            return new CostGroupMembership(manager.username, true);
        });

        var reviewers = selectedReviewers.map(function (reviewer) {
            return new CostGroupMembership(reviewer.username, false);
        });

        return managers.concat(reviewers);
    }

    function _deriveAndCreateNewMemberships(
        costGroup,
        currentMemberships,
        desiredMemberships
    ) {
        var newMemberships = _.differenceBy(
            desiredMemberships,
            currentMemberships,
            "user"
        );

        var apiCalls = newMemberships.map(function (membership) {
            return $sbCommercialApi.createMembershipOnCostGroup(
                costGroup.id,
                membership.user,
                membership.isAllowedToModify ? 1 : 0
            );
        });

        return $q.all(apiCalls);
    }

    function _deriveMovedMemberships(currentMemberships, desiredMemberships) {
        return _.intersectionWith(
            desiredMemberships,
            currentMemberships,
            function hasMembershipChanged(desired, current) {
                return (
                    desired.user === current.user &&
                    desired.isAllowedToModify !== current.isAllowedToModify
                );
            }
        );
    }

    function _deletePreviousRoles(costGroup, movedMemberships) {
        var apiCalls = movedMemberships.map(function (membership) {
            return $sbCommercialApi.deleteMembershipFromCostGroup(
                costGroup.id,
                membership.user
            );
        });

        return $q.all(apiCalls).then(function () {
            return movedMemberships;
        });
    }

    function _createNewRoles(costGroup, movedMemberships) {
        var apiCalls = movedMemberships.map(function (membership) {
            return $sbCommercialApi.createMembershipOnCostGroup(
                costGroup.id,
                membership.user,
                membership.isAllowedToModify ? 1 : 0
            );
        });

        return $q.all(apiCalls);
    }

    function _deriveAndRemoveOldMemberships(
        costGroup,
        currentMemberships,
        desiredMemberships
    ) {
        var oldMemberships = _.differenceBy(
            currentMemberships,
            desiredMemberships,
            "user"
        );

        var apiCalls = oldMemberships.map(function (membership) {
            return $sbCommercialApi.deleteMembershipFromCostGroup(
                costGroup.id,
                membership.user
            );
        });

        return $q.all(apiCalls);
    }

    function fetchAllCostGroupsByProject(projectId) {
        return $sbCommercialApi.getAllCostGroups(projectId).then(setCostGroups);
    }

    function setCostGroups(costGroups) {
        return (service.costGroups = costGroups);
    }

    function generateDataForCsvExport(costGroup, reportedAsAt) {
        var dateOfGeneration = moment().format("ll");
        var figuresUpToDate = reportedAsAt.format("ll");

        var columnNames = _getColumnNames(costGroup.currency);

        var overview = _getCostGroupData(
            costGroup,
            dateOfGeneration,
            figuresUpToDate
        );
        var stages = _getStagesData(
            costGroup,
            dateOfGeneration,
            figuresUpToDate
        );

        return {
            columnNames: columnNames,
            rows: [overview].concat(stages),
            fileName:
                costGroup.name.replace(/ /g, "_") +
                "-" +
                reportedAsAt.format("ll").replace(/ /g, "-"),
        };
    }

    function _getColumnNames(currency) {
        var columnNames = $translate.instant(
            [
                "COST_GROUP_CSV_COLUMN_WORK_PACKAGE",
                "COST_GROUP_CSV_COLUMN_BUDGET_SHARE",
                "COST_GROUP_CSV_COLUMN_BUDGET_ALLOCATED",
                "COST_GROUP_CSV_COLUMN_STARTED",
                "COST_GROUP_CSV_COLUMN_PERCENT_STARTED",
                "COST_GROUP_CSV_COLUMN_TOTAL_STARTED",
                "COST_GROUP_CSV_COLUMN_WAITING_FOR_CONFIRMATION",
                "COST_GROUP_CSV_COLUMN_PERCENT_WAITING_FOR_CONFIRMATION",
                "COST_GROUP_CSV_COLUMN_TOTAL_WAITING_FOR_CONFIRMATION",
                "COST_GROUP_CSV_COLUMN_FINISHED_AND_CONFIRMED",
                "COST_GROUP_CSV_COLUMN_PERCENT_FINISHED_AND_CONFIRMED",
                "COST_GROUP_CSV_COLUMN_TOTAL_FINISHED_AND_CONFIRMED",
                "COST_GROUP_CSV_COLUMN_PERCENT_COST_GROUP_BUDGET_SPENT",
                "COST_GROUP_CSV_COLUMN_DATE_OF_GENERATION",
                "COST_GROUP_CSV_COLUMN_FIGURES_UP_TO_DATE",
            ],
            {
                currency: currency,
            }
        );

        return _.values(columnNames);
    }

    function _getCostGroupData(costGroup, dateOfGeneration, figuresUpToDate) {
        var data = {
            activity: costGroup.name,
            budgetShare: costGroup.sumAllStageWeights(),
            budgetAllocated: costGroup.calcDistributedCosts(),
            started: costGroup.calcBudgetFor(CostGroup.BUDGET_TYPES.STARTED),
            percentStarted: costGroup.calcTotalPercentageFor(
                CostGroup.BUDGET_TYPES.STARTED
            ),
            totalStarted: costGroup.calcTotalCountFor(
                CostGroup.BUDGET_TYPES.STARTED
            ),
            waitingForConfirmation: costGroup.calcBudgetFor(
                CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION
            ),
            percentWaitingForConfirmation: costGroup.calcTotalPercentageFor(
                CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION
            ),
            totalWaitingForConfirmation: costGroup.calcTotalCountFor(
                CostGroup.BUDGET_TYPES.WAITING_FOR_CONFIRMATION
            ),
            finishedAndConfirmed: costGroup.calcBudgetFor(
                CostGroup.BUDGET_TYPES.DONE
            ),
            percentFinishedAndConfirmed: costGroup.calcTotalPercentageFor(
                CostGroup.BUDGET_TYPES.DONE
            ),
            totalFinishedAndConfirmed: costGroup.calcTotalCountFor(
                CostGroup.BUDGET_TYPES.DONE
            ),
            percentCostGroupBudgetSpent:
                costGroup.calcPercentOfTotalBudgetSpent(),
            dateOfGeneration: dateOfGeneration,
            figuresUpToDate: figuresUpToDate,
        };

        _formatNumberValuesOn(data);

        return data;
    }

    function _getStagesData(costGroup, dateOfGeneration, figuresUpToDate) {
        return costGroup.stages.map(function (stage) {
            var data = {
                activity: stage.name,
                budgetShare: stage.weight,
                budgetAllocated: stage.cost,
                started: stage.calcCostOfBudgetStarted(),
                percentStarted: stage.getStateAsPercentageOfTotal(
                    stage.inProgress
                ),
                totalStarted: stage.inProgress,
                waitingForConfirmation:
                    stage.calcCostOfBudgetWaitingForConfirmation(),
                percentWaitingForConfirmation:
                    stage.getStateAsPercentageOfTotal(
                        stage.isWaitingForConfirmation
                    ),
                totalWaitingForConfirmation: stage.isWaitingForConfirmation,
                finishedAndConfirmed: stage.calcCostOfBudgetCompleted(),
                percentFinishedAndConfirmed: stage.getStateAsPercentageOfTotal(
                    stage.isDone
                ),
                totalFinishedAndConfirmed: stage.isDone,
                percentCostGroupBudgetSpent:
                    stage.calcPercentageOfTotalBudgetSpent(costGroup.budget),
                dateOfGeneration: dateOfGeneration,
                figuresUpToDate: figuresUpToDate,
            };

            _formatNumberValuesOn(data);

            return data;
        });
    }

    function _formatNumberValuesOn(data) {
        return _.forOwn(data, function (value, key) {
            if (_.isNumber(value)) {
                // two decimals
                //
                data[key] = _.round(data[key], 2);

                // replace "." with "," if necessary
                //
                data[key] = $sbLocale.internationaliseNumber(data[key]);
            }
        });
    }

    function ungroupActivities(item, costGroup) {
        return Promise.all(
            item.stage.childCostGroupStages.map((childCostGroupStage) =>
                $sbCostPackageGroupsApi.delete(
                    costGroup.id,
                    item.stage.id,
                    childCostGroupStage.id
                )
            )
        );
    }

    function combineActivities({ combinedStages, costGroup }) {
        return $sbCostPackageGroupsApi.create(costGroup.id, {
            name: combinedStages.name,
            activity_templates: combinedStages.activities,
        });
    }

    function fetchCostGroup(costGroupId) {
        removeCostGroupById(costGroupId);
        return $sbCommercialApi.getCostGroup(costGroupId).then(addCostGroup);
    }
}
